import React from "react";
import { Icon, Menu } from "semantic-ui-react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function NavBarSecondaryLarge() {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const locationPage = location?.substring(location?.lastIndexOf("/") + 1);
  const [page, setPage] = useState(locationPage);
  const { transaction } = useSelector((state) => state.transaction);
  let { id } = useParams();

  function handleMenuClick(page) {
    setPage(page);
    navigate(`/transactions/${id}/${page}`);
  }

  return (
    <div id="secondary-nav">
      <Menu vertical className="sidebar-menu">
        {transaction && (
          <>
            <Menu.Item style={{ wordBreak: "break-word", color: "#333333" }}>
              <h4 className="tiny bottom margin text-medium  tiny top margin">
                {transaction.agentRepresents}
              </h4>
              <p className="zero bottom margin">
                {transaction.client?.firstName} {transaction.client?.lastName}
              </p>
              {transaction.clientSecondaryExists && (
                <p className="zero bottom margin">
                  {transaction.clientSecondary?.firstName}{" "}
                  {transaction.clientSecondary?.lastName}
                </p>
              )}
              {transaction.address && (
                <p className="zero bottom margin">
                  {transaction.address.street}
                </p>
              )}
            </Menu.Item>
            <br />
          </>
        )}
        <Menu.Item
          name="Overview"
          active={page === "overview"}
          onClick={() => handleMenuClick("overview")}
          data-test="overview-trans-nav"
        >
          Overview
          <Icon name="folder outline" />
        </Menu.Item>
        <Menu.Item
          name="Parties"
          active={page === "parties"}
          onClick={() => handleMenuClick("parties")}
          data-test="parties-trans-nav"
        >
          Parties <Icon name="user outline" />
        </Menu.Item>
        <Menu.Item
          name="Documents"
          active={page === "documents"}
          onClick={() => handleMenuClick("documents")}
          data-test="documents-trans-nav"
        >
          Documents <Icon name="file outline" />
        </Menu.Item>
        {/* <Menu.Item
          name="deadline"
          active={page === "deadlines"}
          onClick={() => handleMenuClick("deadlines")}
          data-test="tasks-trans-nav"
        >
          Deadlines <Icon name="check square outline" />
        </Menu.Item> */}
        <Menu.Item
          name="tasks"
          active={page === "tasks"}
          onClick={() => handleMenuClick("tasks")}
          data-test="tasks-trans-nav"
        >
          Tasks <Icon name="list alternate outline" />
        </Menu.Item>
        <Menu.Item
          name="emails"
          active={page === "emails"}
          onClick={() => handleMenuClick("emails")}
        >
          Emails <Icon name="mail outline" />
        </Menu.Item>
        {/* <Menu.Item
          name="Calendar"
          active={page === "calendar"}
          onClick={() => handleMenuClick("calendar")}
          data-test="calendar-trans-nav"
        >
          Calendar <Icon name="calendar outline" />
        </Menu.Item> */}
        <Menu.Item
          name="History"
          active={page === "history"}
          onClick={() => handleMenuClick("history")}
        >
          History <Icon name="history" />
        </Menu.Item>
      </Menu>
    </div>
  );
}
