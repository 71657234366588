import React from "react";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/common/modals/modalSlice";
import { useMediaQuery } from "react-responsive";
import ClausesList from "./clausesComponents/ClausesList";

export default function ClausesDashboard() {
  const dispatch = useDispatch();
  const { clauses } = useSelector((state) => state.clauses);
  const userClauses = clauses?.filter((clause) => clause.type === "user");
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <div className="main-page-wrapper">
      <Grid centered stackable className="small bottom margin">
        <Grid.Column width={10}>
          <Header color="blue" as="h2">
            My Clauses
          </Header>
          <p color="grey">
            Create your own clauses that can be copied and pasted into forms.
          </p>
          <Segment clearing>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className={isMobile ? null : "float-right"}>
                    <Button
                      icon="plus"
                      size="small"
                      className={isMobile ? "fluid" : null}
                      onClick={() =>
                        dispatch(
                          openModal({
                            modalType: "ClausesEdit",
                          })
                        )
                      }
                      content="Add Clause"
                      color="blue"
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider hidden clearing className="large top margin" />
            <Grid className="mini horizontal margin">
              {userClauses?.length > 0 ? (
                <ClausesList
                  clauses={userClauses}
                  column={clauses.column}
                  direction={clauses.direction}
                  key={clauses.id}
                />
              ) : (
                <p className="medium bottom margin">
                  You can add your own clauses by clicking "Add Clause".
                </p>
              )}
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}
