export function amendExtendContract2023() {
    return [   //2023
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
   ,
   {
       page: 0,
       name: "Date", 
       fontSize: 10,
       type: "date",
       top: 201.12,
       left: 399,
       width: 169.92,
       height: 16.44
    }
    ,
    {
       page: 0,
       name: "Contract between", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 219.4,
       left: 244.32,
       width: 160.08,
       height: 14.28
    }
    ,
    {
       page: 0,
       name: "Seller and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 236.88,
       left: 50.76,
       width: 449.88,
       height: 16.44
    }
    ,
    {
       page: 0,
       name: "Buyer relating to", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 257.76,
       left: 50.76,
       width: 449.88,
       height: 16.44
    }
    ,
    {
       page: 0,
       name: "undefined", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 275.04,
       left: 426.12,
       width: 145.08,
       height: 16.44
    }
    ,
    {
       page: 0,
       name: "Text2", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 309.601,
       left: 51.0548,
       width: 522.4382,
       height: 84.182
    }
    ,
    {
       page: 0,
       name: "Property", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 394.92,
       left: 96.48,
       width: 433.56,
       height: 16.44
    }
    ,
    {
       page: 0,
       name: "Time of Day Deadline_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 529.08,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_2", 
       isText: true,
       type: "checkbox",
       top: 530.04,
       left: 525.305,
       width: 10.975,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_3", 
       isText: true,
       type: "checkbox",
       top: 530.04,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date or DeadlineAlternative Earnest Money Deadline", 
       fontSize: 9,
       type: "date",
       top: 543.48,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Alternative Earnest Money Deadline_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 543.48,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_4", 
       isText: true,
       type: "checkbox",
       top: 544.44,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_5", 
       isText: true,
       type: "checkbox",
       top: 544.44,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date Record Title Deadline and Tax Certificate", 
       fontSize: 9,
       type: "date",
       top: 570.84,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Text3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 570.84,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_6", 
       isText: true,
       type: "checkbox",
       top: 571.8,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_7", 
       isText: true,
       type: "checkbox",
       top: 571.8,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date Record Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 585.24,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "Text12", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 585.24,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_8", 
       isText: true,
       type: "checkbox",
       top: 586.2,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_9", 
       isText: true,
       type: "checkbox",
       top: 586.2,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date OffRecord Title Deadline", 
       fontSize: 9,
       type: "date",
       top: 599.4,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Text11", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 599.4,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_10", 
       isText: true,
       type: "checkbox",
       top: 600.6,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_11", 
       isText: true,
       type: "checkbox",
       top: 600.6,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date OffRecord Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 613.8,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Text10", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 613.8,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_12", 
       isText: true,
       type: "checkbox",
       top: 614.76,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_13", 
       isText: true,
       type: "checkbox",
       top: 614.76,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date Title Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 628.2,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "Text9", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 628.2,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_14", 
       isText: true,
       type: "checkbox",
       top: 629.16,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_15", 
       isText: true,
       type: "checkbox",
       top: 629.16,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date Third Party Right to PurchaseApprove Deadline", 
       fontSize: 9,
       type: "date",
       top: 642.36,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Text8", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 642.36,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_16", 
       isText: true,
       type: "checkbox",
       top: 643.32,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_17", 
       isText: true,
       type: "checkbox",
       top: 643.32,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date Association Documents Deadline", 
       fontSize: 9,
       type: "date",
       top: 669.96,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "Text7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 669.96,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_18", 
       isText: true,
       type: "checkbox",
       top: 670.92,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_19", 
       isText: true,
       type: "checkbox",
       top: 670.92,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date Association Documents Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 684.12,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Text6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 684.12,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_20", 
       isText: true,
       type: "checkbox",
       top: 685.08,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_21", 
       isText: true,
       type: "checkbox",
       top: 685.08,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date Sellers Property Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 711.48,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Text5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 711.48,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_22", 
       isText: true,
       type: "checkbox",
       top: 712.68,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_23", 
       isText: true,
       type: "checkbox",
       top: 712.68,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date LeadBased Paint Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 725.88,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Text4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 725.88,
       left: 374.64,
       width: 138.12,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "undefined_24", 
       isText: true,
       type: "checkbox",
       top: 726.84,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "undefined_25", 
       isText: true,
       type: "checkbox",
       top: 726.84,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date New Loan Application Deadline", 
       fontSize: 9,
       type: "date",
       top: 104.728,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text13", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 104.728,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_26", 
       isText: true,
       type: "checkbox",
       top: 105,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_27", 
       isText: true,
       type: "checkbox",
       top: 105,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date New Loan Terms Deadline", 
       fontSize: 9,
       type: "date",
       top: 118.473,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text14", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 118.473,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_28", 
       isText: true,
       type: "checkbox",
       top: 119.16,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_29", 
       isText: true,
       type: "checkbox",
       top: 119.16,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date New Loan Availability Deadline", 
       fontSize: 9,
       type: "date",
       top: 132.6,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text15", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 132.873,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_30", 
       isText: true,
       type: "checkbox",
       top: 133.56,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_31", 
       isText: true,
       type: "checkbox",
       top: 133.56,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Buyers Credit Information Deadline", 
       fontSize: 9,
       type: "date",
       top: 146.76,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text16", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 146.619,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_32", 
       isText: true,
       type: "checkbox",
       top: 147.96,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_33", 
       isText: true,
       type: "checkbox",
       top: 147.96,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Disapproval of Buyers Credit Information Deadline", 
       fontSize: 9,
       type: "date",
       top: 161.16,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text17", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 161.019,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_34", 
       isText: true,
       type: "checkbox",
       top: 162.12,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_35", 
       isText: true,
       type: "checkbox",
       top: 162.12,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Existing Loan Deadline", 
       fontSize: 9,
       type: "date",
       top: 175.56,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text18", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 175.419,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_36", 
       isText: true,
       type: "checkbox",
       top: 176.52,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_37", 
       isText: true,
       type: "checkbox",
       top: 176.52,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Existing Loan Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 189.72,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text19", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 190.474,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_38", 
       isText: true,
       type: "checkbox",
       top: 190.68,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_39", 
       isText: true,
       type: "checkbox",
       top: 190.68,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Loan Transfer Approval Deadline", 
       fontSize: 9,
       type: "date",
       top: 204.12,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text20", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 204.219,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_40", 
       isText: true,
       type: "checkbox",
       top: 205.08,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_41", 
       isText: true,
       type: "checkbox",
       top: 205.08,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Seller or Private Financing Deadline", 
       fontSize: 9,
       type: "date",
       top: 218.28,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text21", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 217.965,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_42", 
       isText: true,
       type: "checkbox",
       top: 219.24,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_43", 
       isText: true,
       type: "checkbox",
       top: 219.24,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Appraisal Deadline", 
       fontSize: 9,
       type: "date",
       top: 245.88,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text22", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 246.11,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_44", 
       isText: true,
       type: "checkbox",
       top: 246.84,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_45", 
       isText: true,
       type: "checkbox",
       top: 246.84,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Appraisal Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 260.04,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text23", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 260.51,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_46", 
       isText: true,
       type: "checkbox",
       top: 261,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_47", 
       isText: true,
       type: "checkbox",
       top: 261,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Appraisal Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 274.44,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text24", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 274.256,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_48", 
       isText: true,
       type: "checkbox",
       top: 275.4,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_49", 
       isText: true,
       type: "checkbox",
       top: 275.4,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date New ILC or New Survey Deadline", 
       fontSize: 9,
       type: "date",
       top: 301.8,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text25", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 302.401,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_50", 
       isText: true,
       type: "checkbox",
       top: 302.76,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_51", 
       isText: true,
       type: "checkbox",
       top: 302.76,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date New ILC or New Survey Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 316.2,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text26", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 316.147,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_52", 
       isText: true,
       type: "checkbox",
       top: 317.16,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_53", 
       isText: true,
       type: "checkbox",
       top: 317.16,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date New ILC or New Survey Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 330.36,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text27", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 329.892,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_54", 
       isText: true,
       type: "checkbox",
       top: 331.32,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_55", 
       isText: true,
       type: "checkbox",
       top: 331.32,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Water Rights Examination Deadline", 
       fontSize: 9,
       type: "date",
       top: 372.12,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text29", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 372.438,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_58", 
       isText: true,
       type: "checkbox",
       top: 373.08,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_59", 
       isText: true,
       type: "checkbox",
       top: 373.08,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Mineral Rights Examination Deadline", 
       fontSize: 9,
       type: "date",
       top: 386.52,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text30", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 386.838,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_60", 
       isText: true,
       type: "checkbox",
       top: 387.48,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_61", 
       isText: true,
       type: "checkbox",
       top: 387.48,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Inspection Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 400.68,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text31", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 400.584,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_62", 
       isText: true,
       type: "checkbox",
       top: 401.88,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_63", 
       isText: true,
       type: "checkbox",
       top: 401.88,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Inspection Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 415.08,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text32", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 415.638,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_64", 
       isText: true,
       type: "checkbox",
       top: 416.04,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_65", 
       isText: true,
       type: "checkbox",
       top: 416.04,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Inspection Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 429.48,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text33", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 430.038,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_66", 
       isText: true,
       type: "checkbox",
       top: 430.44,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_67", 
       isText: true,
       type: "checkbox",
       top: 430.44,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Property Insurance Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 443.64,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text34", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 444.438,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_68", 
       isText: true,
       type: "checkbox",
       top: 444.6,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_69", 
       isText: true,
       type: "checkbox",
       top: 444.6,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Due Diligence Documents Delivery Deadline", 
       fontSize: 9,
       type: "date",
       top: 458.04,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text35", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 458.184,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_70", 
       isText: true,
       type: "checkbox",
       top: 459,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_71", 
       isText: true,
       type: "checkbox",
       top: 459,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Due Diligence Documents Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 472.2,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text36", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 472.584,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_72", 
       isText: true,
       type: "checkbox",
       top: 473.16,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_73", 
       isText: true,
       type: "checkbox",
       top: 473.16,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Due Diligence Documents Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 486.6,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text37", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 486.329,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_74", 
       isText: true,
       type: "checkbox",
       top: 487.56,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_75", 
       isText: true,
       type: "checkbox",
       top: 487.56,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Environmental Inspection Objection Deadline CBS2 3 4", 
       fontSize: 9,
       type: "date",
       top: 501,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text38", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 501.384,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_76", 
       isText: true,
       type: "checkbox",
       top: 501.96,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_77", 
       isText: true,
       type: "checkbox",
       top: 501.96,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date ADA Evaluation Termination Deadline CBS2 3 4", 
       fontSize: 9,
       type: "date",
       top: 515.16,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text39", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 515.13,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_78", 
       isText: true,
       type: "checkbox",
       top: 516.12,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_79", 
       isText: true,
       type: "checkbox",
       top: 516.12,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Conditional Sale Deadline", 
       fontSize: 9,
       type: "date",
       top: 529.56,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text40", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 529.53,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_80", 
       isText: true,
       type: "checkbox",
       top: 530.52,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_81", 
       isText: true,
       type: "checkbox",
       top: 530.52,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date LeadBased Paint Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 543.72,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text41", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 543.275,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_82", 
       isText: true,
       type: "checkbox",
       top: 544.68,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_83", 
       isText: true,
       type: "checkbox",
       top: 544.68,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Estoppel Statements Deadline CBS2 3 4", 
       fontSize: 9,
       type: "date",
       top: 558.12,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text42", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 557.021,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_84", 
       isText: true,
       type: "checkbox",
       top: 559.08,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_85", 
       isText: true,
       type: "checkbox",
       top: 559.08,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Estoppel Statements Termination Deadline CBS2 3 4", 
       fontSize: 9,
       type: "date",
       top: 572.28,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text43", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 572.075,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_86", 
       isText: true,
       type: "checkbox",
       top: 573.24,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_87", 
       isText: true,
       type: "checkbox",
       top: 573.24,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Closing Date", 
       fontSize: 9,
       type: "date",
       top: 599.88,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Text44", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 600.221,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_88", 
       isText: true,
       type: "checkbox",
       top: 600.84,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_89", 
       isText: true,
       type: "checkbox",
       top: 600.84,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Date Possession Date", 
       fontSize: 9,
       type: "date",
       top: 614.04,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Text45", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 613.966,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_90", 
       isText: true,
       type: "checkbox",
       top: 615,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_91", 
       isText: true,
       type: "checkbox",
       top: 615,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "Text46", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 628.366,
       left: 375.402,
       width: 138.218,
       height: 12.426
    }
    ,
    {
       page: 1,
       name: "undefined_92", 
       isText: true,
       type: "checkbox",
       top: 629.4,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_93", 
       isText: true,
       type: "checkbox",
       top: 629.4,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "45Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 642.84,
       left: 49.92,
       width: 21.00,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: " 17Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 642.84,
       left: 72.24,
       width: 31.08,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Possession TimeRow1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 642.84,
       left: 104.64,
       width: 205.56,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Closing and PossessionRow4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 642.84,
       left: 311.52,
       width: 61.80,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Closing and PossessionRow4_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 642.84,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_94", 
       isText: true,
       type: "checkbox",
       top: 643.8,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_95", 
       isText: true,
       type: "checkbox",
       top: 643.8,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "45Row2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 657,
       left: 49.92,
       width: 21.00,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: " 17Row2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 657,
       left: 72.24,
       width: 31.08,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Possession TimeRow2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 657,
       left: 104.64,
       width: 205.56,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Closing and PossessionRow5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 657,
       left: 311.52,
       width: 61.80,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Closing and PossessionRow5_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 657.0,
       left: 375.402,
       width: 138.218,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "undefined_96", 
       isText: true,
       type: "checkbox",
       top: 657.96,
       left: 525.96,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 1,
       name: "undefined_97", 
       isText: true,
       type: "checkbox",
       top: 657.96,
       left: 560.76,
       width: 10.32,
       height: 10.56
    }
    ,
    {
       page: 2,
       name: "Text47", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 80.819,
       left: 49.0911,
       width: 519.8199,
       height: 94.654
    }
    ,
    {
       page: 2,
       name: "Text48", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 187.855,
       left: 47.782,
       width: 519.165,
       height: 315.238
    }
    ,
    {
       page: 2,
       name: "Date_2", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 10,
       top: 538.04,
       left: 311.4,
       width: 94.822,
       height: 12.36
    }
    ,
    {
       page: 2,
       name: "Time", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 538.04,
       left: 471.48,
       width: 94.92,
       height: 12.36
    }
    ,
    {
       page: 2,
       name: "Buyers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 583.465,
       left: 108.72,
       width: 180.00,
       height: 14.695
    }
    ,
    {
       page: 2,
       name: "Buyers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 583.465,
       left: 369.72,
       width: 180.00,
       height: 14.695
    }
    ,
    {
       page: 2,
       name: "Sellers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 667.335,
       left: 107.52,
       width: 180.00,
       height: 13.385
    }
    ,
    {
       page: 2,
       name: "Sellers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 666.026,
       left: 368.52,
       width: 180.00,
       height: 14.694
    }
    ] }
    