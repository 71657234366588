import React from "react";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/common/modals/modalSlice";
import { useMediaQuery } from "react-responsive";
import EmailTemplatesList from "./emailTemplatesComponents/EmailTemplatesList";
import { fetchEmailTemplatesFromDb } from "../../app/firestore/firestoreService";
import { fetchEmailTemplates } from "./emailTemplatesSlice";
import LoadingComponent from "../../app/layout/LoadingComponent";
import useFirestoreCollectionNoAsync from "../../app/hooks/useFirestoreCollectionNoAsync";

export default function EmailTemplatesDashboard() {
  const dispatch = useDispatch();
  const { emailTemplates } = useSelector((state) => state.emailTemplates);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  useFirestoreCollectionNoAsync({
    query: () => fetchEmailTemplatesFromDb(),
    data: (emailTemplates) => dispatch(fetchEmailTemplates(emailTemplates)),
    deps: [dispatch],
  });

  if (!emailTemplates) {
    return <LoadingComponent />;
  }

  return (
    <div className="main-page-wrapper">
      <Grid centered stackable className="small bottom margin">
        <Grid.Column width={10}>
          <Header color="blue" as="h2">
            Email Templates
          </Header>
          <p color="grey">
            Create templates for emails to send to parties in your transactions.
          </p>
          <Segment clearing>
            <Grid.Column width={10} className="large bottom margin">
              <div className={isMobile ? null : "float-right"}>
                <Button
                  to="#"
                  icon="plus"
                  size="small"
                  className={isMobile ? "fluid" : null}
                  onClick={() =>
                    dispatch(
                      openModal({
                        modalType: "EmailTemplatesEdit",
                        modalProps: { emailTemplate: null },
                      })
                    )
                  }
                  content="Add Template"
                  color="blue"
                />
              </div>
            </Grid.Column>
            <br />
            <Divider hidden className="large vertical margin" />
            <Grid.Column width={10}>
              {emailTemplates?.length > 0 ? (
                <EmailTemplatesList
                  emailTemplates={emailTemplates}
                  // column={emailTemplates.column}
                  // direction={emailTemplates.direction}
                />
              ) : (
                <p>There are no email templates.</p>
              )}
            </Grid.Column>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}
