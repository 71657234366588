import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  Header,
  Input,
  Segment,
} from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { updateDocInDb } from "../../../app/firestore/firestoreService";

export default function DocRenameModal({ doc }) {
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const dispatch = useDispatch();
  const [textInput, setTextInput] = useState(doc.name);
  const inputRef = useRef(null);

  function handleTextInputChange(event) {
    setTextInput(event.target.value);
  }

  function handleSubmit() {
    try {
      updateDocInDb(doc.id, { name: textInput }, true);
      dispatch(
        closeModal({
          modalType: "DocRenameModal",
        })
      );
    } catch (error) {
      throw error;
    }
  }

  return (
    <>
      <ModalWrapper size="small">
        <Segment>
          <Grid>
            <Grid.Column>
              <Header size="large" color="blue">
                Rename Document
              </Header>
              <Divider />
              <Input
                fluid
                ref={inputRef}
                value={textInput}
                onChange={handleTextInputChange}
              ></Input>
              <Divider />
              <div>
                <Button
                  primary
                  type="submit"
                  onClick={() => handleSubmit()}
                  floated={isMobile ? null : "right"}
                  content="Submit"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  type="button"
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "DocRenameModal",
                      })
                    )
                  }
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Segment>
      </ModalWrapper>
    </>
  );
}
