import { addDays, format } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Card } from "semantic-ui-react";
import TaskActionButtons from "../tasks/taskComponents/TaskActionButtons";

export function TcCalendar({ tasksByDay }) {
  const navigate = useNavigate();

  function handleGoToTransaction(transactionId) {
    navigate(`/transactions/${transactionId}/overview`);
  }

  return (
    <Grid style={{ minHeight: "100px" }} stackable columns={4}>
      {tasksByDay.map((day, index) => (
        <Grid.Column
          key={"key1" + index}
          width={4}
          className={
            index === 0
              ? "background-orange"
              : index === 1
              ? "background-blue"
              : "background-white"
          }
          style={{
            paddingTop: "4px",
            boxShadow: "0 0 0 1px #d4d4d5",
          }}
        >
          <p className="inline large bottom margin bold text-medium small right margin">
            {format(addDays(new Date(), index - 1), "EEEE")}
          </p>
          {index === 0 && (
            <span className="float-left text-medium text orange">
              Yesterday
            </span>
          )}
          {index === 1 && (
            <span className="float-left text-medium text blue">Today</span>
          )}
          <span className="float-right bold text-medium">
            {format(addDays(new Date(), index - 1), "d")}
          </span>
          {Object.values(day).map((value, index) => (
            <div key={"key2" + index} className="medium bottom margin">
              <Card
                fluid
                onClick={() => handleGoToTransaction(value.transactionId)}
              >
                <Card.Content>
                  {/* <Popup content='Add users to your feed' trigger={<Card.Header className="text-normal cursor-pointer" onClick={() => handleGoToTransaction(value.transactionId)}>{value.address}</Card.Header>} /> */}
                  <Card.Header className="text-normal">
                    {value.address}
                  </Card.Header>
                  {value.tasks.map((task) => (
                    <>
                      <div className="zero bottom margin bold">
                        <TaskActionButtons task={task} textStyle={true} />
                      </div>
                    </>
                  ))}
                  {value.agentName && (
                    <Card.Meta>
                      {value.agentRepresents === "Buyer"
                        ? `BA: ${value.agentName}`
                        : `LA: ${value.agentName}`}
                    </Card.Meta>
                  )}
                  <Card.Meta>
                    {value.closingDateTime &&
                      `Closing: ${format(
                        new Date(value.closingDateTime),
                        "M/d/yyyy"
                      )}`}
                  </Card.Meta>
                </Card.Content>
              </Card>
            </div>
          ))}
        </Grid.Column>
      ))}
    </Grid>
  );
}
