export function leadBasedPaintDisclosureSalesWritable1() {

      return [   //2024 Release 2024-01-23 12:45:05
      {
         page: 0,
         name: "Attachment to Contract to Buy and Sell Real Estate for the Property known as", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 125.76,
         left: 36,
         width: 540.24,
         height: 13.44
      }
      ,
      {
         page: 0,
         name: "Seller has no knowledge of any leadbased paint andor leadbased paint hazards present in the housing", 
         isText: false,
         type: "checkbox",
         top: 398.52,
         left: 54.72,
         width: 9.96,
         height: 9.96
      }
      ,
      {
         page: 0,
         name: "Seller has knowledge of leadbased paint andor leadbased paint hazards present in the housing explain", 
         isText: false,
         type: "checkbox",
         top: 416.04,
         left: 54.72,
         width: 9.96,
         height: 9.96
      }
      ,
      {
         page: 0,
         name: "1", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 426.36,
         left: 72,
         width: 504.24,
         height: 10.68
      }
      ,
      {
         page: 0,
         name: "2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 441.775,
         left: 72.0,
         width: 504.24,
         height: 13.440
      }
      ,
      {
         page: 0,
         name: "Seller has no reports or records pertaining to leadbased paint andor leadbased paint hazards in the housing", 
         isText: false,
         type: "checkbox",
         top: 478.08,
         left: 54.72,
         width: 9.96,
         height: 9.96
      }
      ,
      {
         page: 0,
         name: "Seller has provided Buyer with all available records and reports pertaining to leadbased paint andor leadbased paint hazards", 
         isText: false,
         type: "checkbox",
         top: 495.6,
         left: 54.72,
         width: 9.96,
         height: 9.96
      }
      ,
      {
         page: 0,
         name: "in the housing list documents below 1", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 520.56,
         left: 72,
         width: 504.24,
         height: 13.44
      }
      ,
      {
         page: 0,
         name: "in the housing list documents below 2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 538.08,
         left: 72,
         width: 504.24,
         height: 13.44
      }
      ,
      {
         page: 0,
         name: "Buyers Acknowledgment", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 556.08,
         left: 34.56,
         width: 543.12,
         height: 13.44
      }
      ,
      {
         page: 0,
         name: "Obtain a risk assessment or an inspection of the Property for the presence of leadbased paint andor leadbased paint hazards", 
         isText: false,
         type: "checkbox",
         top: 682.56,
         left: 59.28,
         width: 9.96,
         height: 9.96
      }
      ,
      {
         page: 0,
         name: "Waive the opportunity to conduct a risk assessment or inspection for the presence of leadbased paint andor leadbased paint", 
         isText: false,
         type: "checkbox",
         top: 705.48,
         left: 59.28,
         width: 9.96,
         height: 9.96
      }
      ,
      {
         page: 1,
         name: "SellerName1", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 96.284,
         left: 70.1994,
         width: 144.2076,
         height: 11.498
      }
      ,
      {
         page: 1,
         name: "BuyerName1", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 97.902,
         left: 342.403,
         width: 174.317,
         height: 12.153
      }
      ,
      {
         page: 1,
         name: "SellerName2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 134.073,
         left: 70.1994,
         width: 144.2076,
         height: 12.153
      }
      ,
      {
         page: 1,
         name: "BuyerName2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 134.382,
         left: 342.403,
         width: 174.317,
         height: 12.153
      }
      ,
      {
         page: 1,
         name: "signature agentListing", 
         type: "signature",
         fontName: "Helvetica",
         fontSize: 10,
         top: 154.473,
         left: 37.3093,
         width: 163.0907,
         height: 13.491
      }
      ,
      {
         page: 1,
         name: "Date_3", 
         fontSize: 9,
         type: "date",
         top: 154.844,
         left: 211.582,
         width: 63.698,
         height: 14.116
      }
      ,
      {
         page: 1,
         name: "signature agentColist", 
         type: "signature",
         fontName: "Helvetica",
         fontSize: 10,
         top: 187.201,
         left: 37.3093,
         width: 160.4727,
         height: 13.491
      }
      ,
      {
         page: 1,
         name: "Date_6", 
         fontSize: 9,
         type: "date",
         top: 186.262,
         left: 211.493,
         width: 62.346,
         height: 16.080
      }
      ] }
