import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";

export default function FallbackComponent() {
  const navigate = useNavigate();

  function handleGoBack() {
    navigate("/");
  }
  return (
    <div
      className="hero-background-img"
      style={{ backgroundImage: "url('/assets/img-background-blue.jpeg')" }}
    >
      <Container style={{ height: "100vh" }}>
        <Grid>
          <Grid.Row>
            <div className="medium top margin" style={{ width: "100%" }}>
              <Image
                src="/assets/logo-transactioner-white-small.png"
                style={{ float: "left" }}
              />
            </div>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              mobile={16}
              tablet={7}
              computer={7}
              className="mobile hidden tablet hidden"
            >
              <Image
                src="/assets/img-laptop-login.png"
                fluid
                alt="laptop contract"
                style={{ maxWidth: "540px" }}
              />
            </Grid.Column>
            <Grid.Column
              className="large top margin"
              floated="left"
              mobile={16}
              tablet={16}
              computer={8}
              textAlign="center"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Segment style={{ padding: "2.2em" }}>
                <Image
                  centered
                  src="/assets/logo-transactioner-color-small.png"
                  width="110"
                  alt="laptop contract"
                  style={{ marginTop: "16px" }}
                />
                <Header
                  color="blue"
                  as="h1"
                  textAlign="center"
                  className="text-big small top margin"
                >
                  Oops...
                </Header>
                <Divider />
                <div style={{ color: "dimgray" }}>
                  <p className="text-medium medium bottom margin">
                    Looks like something went wrong wrong.
                  </p>
                  <p className="text-medium medium bottom margin">
                    Please reach out to help@transactioner.com.
                  </p>
                </div>
                <Divider className="medium top margin large bottom margin" />
                <Button primary size="large" onClick={() => handleGoBack()}>
                  Go Back
                </Button>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
}
