/* eslint-disable no-useless-computed-key */
import _ from "lodash";
import { startOfDay, startOfYear, subDays } from "date-fns";
import {
  autoAddFormsToTransaction,
  convertAddressStreetAndUnit,
  createInitialSigningDetails,
  createSearchArray,
  createSuggestedAnnots,
  createTransactionFields,
  docSigningComplete,
  partyIsAgent,
  partyIsMyCoAgent,
  partyIsMyTc,
  personExists,
  updateFormListForBrokerage,
} from "../common/util/util";
import { app } from "../config/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  Timestamp,
  doc,
  addDoc,
  getDocs,
  updateDoc,
  query,
  orderBy,
  where,
  deleteDoc,
  serverTimestamp,
  increment,
  limit,
  startAfter,
  writeBatch,
  limitToLast,
  endBefore,
  arrayUnion,
  arrayRemove,
  getDoc,
  setDoc,
  or,
  and,
  onSnapshot,
} from "firebase/firestore";
import {
  functionAddCoAgentIdToTransaction,
  functionAddTcIdToTransaction,
  functionLinkUserToParty,
  functionRemoveCoAgentIdFromTransaction,
  functionRemoveTcIdFromTransaction,
} from "./functionsService";
import { subMinutes } from "date-fns";

const db = getFirestore(app);
const auth = getAuth(app);

let authClaims = {};
onAuthStateChanged(auth, (user) => {
  if (user) {
    auth.currentUser.getIdTokenResult().then((userClaim) => {
      authClaims = userClaim?.claims;
    });
  }
});

export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}

///////////////////////////////////////////////////////////////
/////////////////////  TRANSACTIONS  //////////////////////////
///////////////////////////////////////////////////////////////

export function fetchTransActiveFromDb(id = null) {
  let queryRef = query(
    collection(db, "transactions"),
    where("active", "==", true)
  );
  if (authClaims?.r === "a" && authClaims?.a) {
    queryRef = query(queryRef, where("userId", "in", authClaims.a));
  } else if (authClaims?.r === "t") {
    queryRef = query(queryRef, where("tcId", "==", auth.currentUser.uid));
  } else if (id) {
    queryRef = query(
      queryRef,
      where("userId", "==", id),
      where("managerId", "==", auth.currentUser.uid)
    );
  } else {
    queryRef = query(
      queryRef,
      or(
        where("userId", "==", auth.currentUser.uid),
        where("coAgentId", "==", auth.currentUser.uid)
      )
    );
  }
  return query(queryRef, orderBy("updatedAt", "desc"));
}

export function fetchTransactionsForUserFromDb() {
  return query(
    collection(db, "transactions"),
    where("sharingWith", "array-contains", auth.currentUser.email)
  );
}

export function fetchTransactionFromDb(transactionId) {
  // SHOULDN'T THIS HAVE MORE SECURITY?
  const docRef = doc(db, "transactions", transactionId);
  return docRef;
}

export function fetchTransactionsStatusFromDb(
  limitNumber,
  direction,
  referenceSnapshot = null,
  searchTerms = null,
  id = null,
  status = "Complete"
) {
  let q = query(collection(db, "transactions"));
  if (authClaims?.r === "a" && authClaims?.a) {
    q = query(q, where("userId", "in", authClaims.a));
  } else if (authClaims?.r === "t") {
    q = query(q, where("tcId", "==", auth.currentUser.uid));
  } else if (id) {
    q = query(
      q,
      where("userId", "==", id),
      where("managerId", "==", auth.currentUser.uid)
    );
  } else {
    q = query(
      q,
      or(
        where("userId", "==", auth.currentUser.uid),
        where("coAgentId", "==", auth.currentUser.uid)
      )
    );
  }
  if (searchTerms) {
    q = query(q, where("searchArray", "array-contains-any", searchTerms));
  }
  q = query(q, where("status", "==", status));
  q = query(q, orderBy("updatedAt", "desc"));

  if (referenceSnapshot && direction === "next") {
    q = query(q, startAfter(referenceSnapshot), limit(limitNumber));
  }
  // if (referenceSnapshot && direction === 'initial') {
  //   // q = query(q, startAt(referenceSnapshot), limit(limitNumber))
  //   q = query(q, limit(limitNumber))
  // }
  if ((!referenceSnapshot && direction === "next") || direction === "initial") {
    q = query(q, limit(limitNumber));
  }
  if (referenceSnapshot && direction === "previous") {
    q = query(q, endBefore(referenceSnapshot), limitToLast(limitNumber));
  }
  if (!referenceSnapshot && direction === "previous") {
    q = query(q, limitToLast(limitNumber));
  }
  return q;
}

export function fetchTransactionsAllFromDb(
  limitNumber,
  direction,
  referenceSnapshot = null,
  searchTerms = null,
  id = null
) {
  let q = query(collection(db, "transactions"));
  if (authClaims?.r === "a" && authClaims?.a) {
    q = query(q, where("userId", "in", authClaims.a));
  } else if (authClaims?.r === "t") {
    q = query(q, where("tcId", "==", auth.currentUser.uid));
  } else if (id) {
    q = query(
      q,
      where("userId", "==", id),
      where("managerId", "==", auth.currentUser.uid)
    );
  } else {
    q = query(
      q,
      or(
        where("userId", "==", auth.currentUser.uid),
        where("coAgentId", "==", auth.currentUser.uid)
      )
    );
  }
  if (searchTerms) {
    q = query(q, where("searchArray", "array-contains-any", searchTerms));
  }
  q = query(q, orderBy("updatedAt", "desc"));

  if (referenceSnapshot && direction === "next") {
    q = query(q, startAfter(referenceSnapshot), limit(limitNumber));
  }
  // if (referenceSnapshot && direction === 'initial') {
  //   // q = query(q, startAt(referenceSnapshot), limit(limitNumber))
  //   q = query(q, limit(limitNumber))
  // }
  if ((!referenceSnapshot && direction === "next") || direction === "initial") {
    q = query(q, limit(limitNumber));
  }
  if (referenceSnapshot && direction === "previous") {
    q = query(q, endBefore(referenceSnapshot), limitToLast(limitNumber));
  }
  if (!referenceSnapshot && direction === "previous") {
    q = query(q, limitToLast(limitNumber));
  }
  return q;
}

export async function addTransactionToDb(
  transaction,
  people,
  forms,
  currentUserProfile,
  mlsData,
  agentsForAssistant
) {
  const batch = writeBatch(db);
  const transactionId = doc(collection(db, "transactions")).id;

  let agentAndAssistantProfiles = {
    createdBy: currentUserProfile.role,
  };
  if (currentUserProfile.role === "assistant" && transaction.agentId) {
    const agent = agentsForAssistant.filter(
      (agent) => agent.userId === transaction.agentId
    )?.[0];
    Object.assign(agentAndAssistantProfiles, {
      hasAssistant: true,
      assistantProfile: currentUserProfile,
      agentProfile: agent || {},
      managerId: agent.managerId || "",
      userId: agent.userId || "",
    });
  } else {
    Object.assign(agentAndAssistantProfiles, {
      hasAssistant: false,
      assistantProfile: {},
      agentProfile: currentUserProfile,
      managerId: currentUserProfile.managerId || "",
      userId: currentUserProfile.userId || "",
    });
  }
  Object.assign(transaction, agentAndAssistantProfiles);

  transaction["id"] = transactionId;
  transaction["clientSecondaryExists"] = transaction.clientSecondary.exists;

  // Add MLS data to transaction
  // Note: Address and pic already added in form
  let listingAgentPartyId = "";
  if (mlsData) {
    if (mlsData.propertyDetails) {
      transaction.propertyDetails = mlsData.propertyDetails;
    }
    if (
      mlsData.listingAgent &&
      mlsData.listingAgent?.email &&
      mlsData.listingAgent?.firstName &&
      mlsData.listingAgent?.lastName &&
      transaction.agentRepresents === "Buyer"
    ) {
      if (mlsData.listingAgent.email) {
        mlsData.listingAgent.email = mlsData.listingAgent.email.toLowerCase();
      } else {
        mlsData.listingAgent.email = "";
      }
      listingAgentPartyId = doc(collection(db, "parties")).id;
      batch.set(doc(db, "parties", listingAgentPartyId), {
        ...mlsData.listingAgent,
        userId: transaction["userId"],
        id: listingAgentPartyId,
        managerId: transaction.managerId,
        type: "Party",
        role: "Listing Agent",
        transactionId: transactionId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    }
  }
  let owner1PartyId = "";
  let owner2PartyId = "";

  if (transaction.agentRepresents === "Buyer") {
    if (transaction.owner?.entityName || transaction.owner?.lastName) {
      owner1PartyId = doc(collection(db, "parties")).id;
      batch.set(doc(db, "parties", owner1PartyId), {
        ...transaction.owner,
        userId: transaction["userId"],
        id: owner1PartyId,
        managerId: transaction.managerId,
        type: "Party",
        role: "Seller",
        transactionId: transactionId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      if (transaction.owner2?.entityName || transaction.owner2?.lastName) {
        owner2PartyId = doc(collection(db, "parties")).id;
        batch.set(doc(db, "parties", owner2PartyId), {
          ...transaction.owner2,
          userId: transaction["userId"],
          id: owner2PartyId,
          managerId: transaction.managerId,
          type: "Party",
          role: "Seller 2",
          transactionId: transactionId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      }
    }
  }

  if (transaction.agentRepresents === "Buyer") {
    transaction.status = "Active Buyer";
    transaction.client.role = "Buyer";
    if (transaction.clientSecondaryExists) {
      transaction.clientSecondary.role = "Buyer 2";
    }
  } else {
    transaction.status = "Active Listing";
    transaction.client.role = "Seller";
    if (transaction.clientSecondaryExists) {
      transaction.clientSecondary.role = "Seller 2";
    }
  }

  if (currentUserProfile.role === "agent") {
    if (!personExists(people, transaction.client?.email)) {
      transaction.client.type = "Client";
      const personId = doc(collection(db, "people")).id;
      batch.set(doc(db, "people", personId), {
        ...transaction.client,
        userId: transaction["userId"],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    }
    if (
      transaction.clientSecondaryExists &&
      transaction.clientSecondary?.email
    ) {
      if (!personExists(people, transaction.clientSecondary?.email)) {
        transaction.clientSecondary.type = "Client";
        const personId = doc(collection(db, "people")).id;
        batch.set(doc(db, "people", personId), {
          ...transaction.clientSecondary,
          userId: transaction["userId"],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      }
    }
  }

  transaction["searchArray"] = createSearchArray(transaction);

  delete transaction.owner;
  delete transaction.owner2;
  delete transaction.addressGoogle;
  delete transaction.clientSelected;
  delete transaction.clientSecondarySelected;

  const partyId = doc(collection(db, "parties")).id;
  transaction.client["id"] = partyId;
  batch.set(doc(db, "parties", partyId), {
    ...transaction.client,
    userId: transaction["userId"],
    id: partyId,
    managerId: transaction.managerId,
    type: "Client",
    transactionId: transactionId,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
  if (transaction.clientSecondaryExists) {
    const party2Id = doc(collection(db, "parties")).id;
    transaction.clientSecondary["id"] = party2Id;
    batch.set(doc(db, "parties", party2Id), {
      ...transaction.clientSecondary,
      userId: transaction["userId"],
      managerId: transaction.managerId,
      id: party2Id,
      type: "Client",
      transactionId: transactionId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  }
  batch.set(doc(db, "transactions", transactionId), {
    ...transaction,
    userId: transaction["userId"],
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
  await batch.commit();
  if (mlsData?.listingAgent?.email && transaction.agentRepresents === "Buyer") {
    functionLinkUserToParty({
      partyId: listingAgentPartyId,
      party: mlsData.listingAgent,
    });
  }
  const formsForBrokerage = updateFormListForBrokerage(
    forms,
    currentUserProfile
  );
  autoAddFormsToTransaction(transaction, formsForBrokerage, currentUserProfile);
  return transactionId;
}

export function updateTransactionInDb(
  transactionId,
  transactionFields,
  updateSearchArray = false
) {
  if (updateSearchArray) {
    transactionFields["searchArray"] = createSearchArray(transactionFields);
  }
  if (
    transactionFields.status === "Complete" ||
    transactionFields.status === "Archived"
  ) {
    transactionFields["active"] = false;
  } else {
    transactionFields["active"] = true;
  }
  const docRef = doc(db, "transactions", transactionId);

  return updateDoc(docRef, {
    ...transactionFields,
    updatedAt: serverTimestamp(),
  });
}

// This is needed for pagination so can update without changing order with updatedAt
export function updateTransStatusInDb(transactionId, transactionFields) {
  const docRef = doc(db, "transactions", transactionId);
  return updateDoc(docRef, {
    ...transactionFields,
  });
}

export function addTransClientInDb(transaction, clientForm) {
  const batch = writeBatch(db);
  const partyId = doc(collection(db, "parties")).id;
  clientForm["id"] = partyId;
  const transactionFields = createTransactionFields(transaction, clientForm);
  batch.set(doc(db, "parties", partyId), {
    ...clientForm,
    id: partyId,
    transactionId: transaction.id,
    userId: transaction.userId,
    tcId: transaction.tcId || null,
    managerId: transaction.managerId || "",
    coAgentId: transaction.coAgentId || "",
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
  const docRef = doc(db, "transactions", transaction.id);
  batch.update(docRef, {
    ...transactionFields,
    updatedAt: serverTimestamp(),
  });
  return batch.commit();
}

export function updateTransClientInDb(transaction, clientForm) {
  const batch = writeBatch(db);
  const transactionFields = createTransactionFields(transaction, clientForm);
  const partyRef = doc(db, "parties", clientForm.id);
  batch.update(partyRef, {
    ...clientForm,
    updatedAt: serverTimestamp(),
  });
  const transactionRef = doc(db, "transactions", transaction.id);
  batch.update(transactionRef, {
    ...transactionFields,
    updatedAt: serverTimestamp(),
  });
  return batch.commit();
}

export function updateTransFieldsInDb(transactionId, transactionFields) {
  const docRef = doc(db, "transactions", transactionId);
  return updateDoc(docRef, {
    ...transactionFields,
    updatedAt: serverTimestamp(),
  });
}

export function updateTransUpdatedInDb(transactionId) {
  const docRef = doc(db, "transactions", transactionId);
  return updateDoc(docRef, {
    updatedAt: serverTimestamp(),
  });
}

export async function updateTransAddSharingInDb(docId, party, transaction) {
  let partyRoleCamel = _.camelCase(party.role);
  const docRef = doc(db, "transactions", docId);
  let emailParty = party.email;
  let emailTc = party.email;
  let emailCoAgent = party.email;
  let newFields = {
    [`sharingWithRole.${partyRoleCamel}`]: party.email,
    mlsNumbers: transaction.mlsNumbers || [],
  };
  if (
    partyIsAgent(party.role) &&
    party.hasTransactionCoordinator &&
    party.transactionCoordinator?.email
  ) {
    newFields[`sharingWithRole.otherTransactionCoordinator`] =
      party.transactionCoordinator.email;
    emailTc = party.transactionCoordinator.email;
  }
  if (partyIsAgent(party.role) && party.hasCoAgent && party.coAgent?.email) {
    newFields[`sharingWithRole.otherCoAgent`] = party.coAgent.email;
    emailCoAgent = party.coAgent.email;
  }
  newFields["sharingWith"] = arrayUnion(emailParty, emailTc, emailCoAgent);
  return updateDoc(docRef, newFields);
}

export async function updateTransRemoveSharingInDb(docId, party, transaction) {
  let partyRoleCamel = _.camelCase(party.role);
  const docRef = doc(db, "transactions", docId);
  let emailParty = party.email;
  let emailTc = party.email;
  let emailCoAgent = party.email;
  let newFields = {
    [`sharingWithRole.${partyRoleCamel}`]: party.email,
    mlsNumbers: transaction.mlsNumbers || [],
  };
  if (
    partyIsAgent(party.role) &&
    party.hasTransactionCoordinator &&
    party.transactionCoordinator?.email
  ) {
    newFields[`sharingWithRole.otherTransactionCoordinator`] =
      party.transactionCoordinator.email;
    emailTc = party.transactionCoordinator.email;
  }
  if (partyIsAgent(party.role) && party.hasCoAgent && party.coAgent?.email) {
    newFields[`sharingWithRole.otherCoAgent`] = party.coAgent.email;
    emailCoAgent = party.coAgent.email;
  }
  newFields["sharingWith"] = arrayUnion(emailParty, emailTc, emailCoAgent);
  return updateDoc(docRef, newFields);
}

export async function deleteTransactionInDb(transactionId) {
  const batch = writeBatch(db);

  // COULD ALSO REMOVE TRANSACTION ID FROM CLIENTS
  // const tasksRef = collection(db, "tasks");
  // const queryTasks = query(
  //   tasksRef,
  //   where("transactionId", "==", transactionId)
  // );
  // const snapshotTasks = await getDocs(queryTasks);
  // snapshotTasks.forEach((task) => {
  //   batch.delete(doc(db, "tasks", task.id));
  // });

  const partiesRef = collection(db, "parties");
  const queryParties = query(
    partiesRef,
    where("transactionId", "==", transactionId)
  );
  const snapshotParties = await getDocs(queryParties);
  snapshotParties.forEach((party) => {
    batch.delete(doc(db, "parties", party.id));
  });

  // const docsRef = collection(db, "documents");
  // const queryDocs = query(docsRef, where("transactionId", "==", transactionId));
  // const snapshotDocs = await getDocs(queryDocs);
  // snapshotDocs.forEach((document) => {
  //   batch.delete(doc(db, "documents", document.id));
  //   // const annotsRef = collection(db, "documents");
  //   // const queryDocs = query(docsRef, where("transactionId", "==", transactionId));
  //   // const snapshotDocs = await getDocs(queryDocs);
  //   // snapshotDocs.forEach((document) => {
  //   //   batch.delete(doc(db, "documents", document.id));
  //   // });
  //   // deleteDocFromStorage(document.docRef);
  // });

  const transactionRef = doc(db, "transactions", transactionId);
  batch.delete(transactionRef);
  return batch.commit();
}

///////////////////////////////////////////////////////////////
/////////////////////  PARTIES  //////////////////////////
///////////////////////////////////////////////////////////////

export function fetchPartiesFromDb(transactionId) {
  let queryRef = collection(db, "parties");
  if (authClaims?.r === "a" && authClaims?.a) {
    queryRef = query(queryRef, where("userId", "in", authClaims.a));
  } else if (authClaims?.r === "t") {
    queryRef = query(queryRef, where("tcId", "==", auth.currentUser.uid));
  } else if (authClaims?.r === "m") {
    queryRef = query(queryRef, where("managerId", "==", auth.currentUser.uid));
  } else {
    queryRef = query(
      queryRef,
      or(
        where("userId", "==", auth.currentUser.uid),
        where("coAgentId", "==", auth.currentUser.uid)
      )
    );
  }
  return query(queryRef, where("transactionId", "==", transactionId));
}

export function fetchPartiesForUserFromDb() {
  let queryRef = collection(db, "parties");
  return query(
    queryRef,
    or(
      where("email", "==", auth.currentUser.email),
      where("transactionCoordinator.email", "==", auth.currentUser.email),
      where("coAgent.email", "==", auth.currentUser.email)
    )
  );
}

export function fetchPartiesForPartyFromDb(transactionId) {
  let queryRef = collection(db, "parties");
  return query(queryRef, where("transactionId", "==", transactionId));
}

export function fetchPartyFromDb(partyId) {
  const partyRef = doc(db, "parties", partyId);
  return partyRef;
}

export async function getPartyFromDb(partyId) {
  const partyRef = doc(db, "parties", partyId);
  const snapshot = await getDoc(partyRef);
  return snapshot.data();
}

export async function addPartyToDb(party, transaction, allParties) {
  const partyId = doc(collection(db, "parties")).id;
  await setDoc(doc(db, "parties", partyId), {
    ...party,
    id: partyId,
    transactionId: transaction.id,
    userId: transaction.userId,
    tcId: transaction.tcId || "",
    managerId: transaction.managerId || "",
    coAgentId: transaction.coAgentId || "",
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });

  // When adding agent see if already a TransActioner user and add isUser and userId to party
  // (so we know they are a user when sharing docs)
  if (partyIsAgent(party.role)) {
    functionLinkUserToParty({ partyId: partyId, party: party });
  }
  // When adding my TC add the id and other info of the TC to the transaction so my TC can access it
  if (partyIsMyTc(party.role)) {
    functionAddTcIdToTransaction({ party: party });
  }
  // When adding my TC add the id and other info of the TC to the transaction so my TC can access it
  if (partyIsMyCoAgent(party.role)) {
    functionAddCoAgentIdToTransaction({ party: party });
  }
  return;
}

export async function updatePartyInDb(party, partyFields) {
  const docRef = doc(db, "parties", party.id);
  updateDoc(docRef, {
    ...partyFields,
    updatedAt: serverTimestamp(),
  });
  if (partyIsAgent(party.role)) {
    functionLinkUserToParty({ partyId: party.id, party: partyFields });
  }
  // Not needed since we no longer allow emails to be changed
  // if (partyIsMyTc(partyFields.role) && party.email !== partyFields.email) {
  //   functionAddTcIdToTransaction({ party: partyFields });
  // }
  return;
}

export function updateListingAgentPartyInDb(transaction, mlsData, parties) {
  if (transaction.agentRepresents !== "Buyer") {
    return;
  }
  if (!mlsData) {
    return;
  }
  if (!mlsData.listingAgent) {
    return;
  }

  const existingListingAgentParty = parties.filter(
    (party) => party.role === "Listing Agent"
  )?.[0];
  if (existingListingAgentParty?.id) {
    try {
      deletePartyInDb(existingListingAgentParty);
    } catch (error) {
      console.log("Problem deleting LA, igorning and carry on");
    }
  }
  if (mlsData.listingAgent.email) {
    mlsData.listingAgent.email = mlsData.listingAgent.email.toLowerCase();
  } else {
    mlsData.listingAgent.email = "";
  }

  addPartyToDb(
    {
      ...mlsData.listingAgent,
      type: "Party",
      role: "Listing Agent",
    },
    transaction
  );

  return;
}

export function updateOwnersPartyInDb(transaction, parties) {
  if (!transaction) {
    return;
  }
  if (transaction.agentRepresents !== "Buyer") {
    return;
  }
  if (!(transaction.owner?.entityName || transaction.owner?.lastName)) {
    return;
  }

  const ownerParty = parties?.filter((party) => party.role === "Seller")[0];
  const owner2Party = parties?.filter((party) => party.role === "Seller2")[0];

  if (ownerParty) {
    updatePartyInDb(ownerParty, transaction.owner);
  } else {
    try {
      const owner1PartyId = doc(collection(db, "parties")).id;
      addPartyToDb(
        {
          ...transaction.owner,
          id: owner1PartyId,
          type: "Party",
          role: "Seller",
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        },
        transaction
      );
    } catch (error) {
      console.log("Error adding owner1 ", error.message);
    }
  }

  if (transaction.owner2?.entityName || transaction.owner2?.lastName) {
    if (owner2Party) {
      updatePartyInDb(owner2Party, transaction.owner2);
    } else {
      try {
        console.log(
          "No 2nd owner so create a new party:::",
          transaction.owner2
        );
        const owner2PartyId = doc(collection(db, "parties")).id;
        addPartyToDb(
          {
            ...transaction.owner2,
            id: owner2PartyId,
            type: "Party",
            role: "Seller 2",
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
          transaction
        );
      } catch (error) {
        console.log("Error adding owner2 ", error.message);
      }
    }
  }
  return;
}

export function deletePartyInDb(party) {
  const docRef = doc(db, "parties", party.id);
  deleteDoc(docRef);
  if (partyIsMyTc(party.role)) {
    functionRemoveTcIdFromTransaction({ party: party });
  }
  if (partyIsMyCoAgent(party.role)) {
    functionRemoveCoAgentIdFromTransaction({ party: party });
  }
  return;
}

///////////////////////////////////////////////////////////////
/////////////////////////  PEOPLE  ////////////////////////////
///////////////////////////////////////////////////////////////

export function fetchPeopleFromDb() {
  const peopleRef = collection(db, "people");
  return query(
    peopleRef,
    where("userId", "==", auth.currentUser.uid),
    orderBy("updatedAt", "desc")
  );
}

export function addPersonToDb(person) {
  const user = auth.currentUser;
  const peopleRef = collection(db, "people");
  return addDoc(peopleRef, {
    ...person,
    userId: user.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function updatePersonInDb(personId, personFields) {
  const docRef = doc(db, "people", personId);
  return updateDoc(docRef, {
    ...personFields,
    updatedAt: serverTimestamp(),
  });
}

export function deletePersonInDb(personId) {
  const docRef = doc(db, "people", personId);
  return deleteDoc(docRef);
}

///////////////////////////////////////////////////////////////
/////////////////////////  TASKS  /////////////////////////////
///////////////////////////////////////////////////////////////

export function fetchTasksUpcomingFromDb(id = null) {
  let queryRef = query(collection(db, "tasks"));
  if (authClaims?.r === "a" && authClaims?.a) {
    queryRef = query(queryRef, where("userId", "in", authClaims.a));
  } else if (authClaims?.r === "t") {
    // queryRef = query(queryRef, where("accessIds", "array-contains", auth.currentUser.uid));
    queryRef = query(queryRef, where("tcId", "==", auth.currentUser.uid));
  } else if (id) {
    queryRef = query(
      queryRef,
      where("userId", "==", id),
      where("managerId", "==", auth.currentUser.uid)
    );
  } else {
    queryRef = query(
      queryRef,
      or(
        where("userId", "==", auth.currentUser.uid),
        where("coAgentId", "==", auth.currentUser.uid)
      )
    );
  }
  return query(
    queryRef,
    where("end", ">=", startOfDay(subDays(new Date(), 1))),
    orderBy("end"),
    limit(400)
  );
}

export function fetchTasksTransFromDb(transactionId) {
  let queryRef = collection(db, "tasks");
  if (authClaims?.r === "a" && authClaims?.a) {
    queryRef = query(queryRef, where("userId", "in", authClaims.a));
  } else if (authClaims?.r === "t") {
    queryRef = query(queryRef, where("tcId", "==", auth.currentUser.uid));
  } else if (authClaims?.r === "m") {
    queryRef = query(queryRef, where("managerId", "==", auth.currentUser.uid));
  } else {
    queryRef = query(
      queryRef,
      or(
        where("userId", "==", auth.currentUser.uid),
        where("coAgentId", "==", auth.currentUser.uid)
      )
    );
  }
  return query(
    queryRef,
    where("transactionId", "==", transactionId),
    orderBy("end")
  );
}

export function fetchTasksForPartyFromDb(party) {
  // const partyRoleCamel = _.camelCase(party.role);
  const docsRef = collection(db, "tasks");
  return query(
    docsRef,
    where("transactionId", "==", party.transactionId),
    where("sharingWith", "array-contains", auth.currentUser.email)
    // where(`sharingWithRole.${partyRoleCamel}`, "==", party.email)
  );
}

export function addTaskToDb(task, transaction) {
  const tasksRef = collection(db, "tasks");
  return addDoc(tasksRef, {
    ...task,
    status: "Active",
    userId: transaction.userId,
    transactionId: transaction.id,
    transactionTitle: transaction.title,
    archived: false,
    tcId: transaction.tcId || "",
    managerId: transaction.managerId || "",
    coAgentId: transaction.coAgentId || "",
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function addTasksFromDocToDb(tasks, tasksTransAll, transaction) {
  const batch = writeBatch(db);
  tasks.forEach((task) => {
    const foundTask = tasksTransAll.filter(
      (taskitem) => taskitem.title === task.title
    )[0];
    const taskId = foundTask ? foundTask.id : doc(collection(db, "tasks")).id;
    batch.set(doc(db, "tasks", taskId), {
      ...task,
      start: task.end,
      userId: transaction.userId,
      tcId: transaction.tcId || "",
      managerId: transaction.managerId || "",
      coAgentId: transaction.coAgentId || "",
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  });
  return batch.commit();
}

export function updateTaskInDb(taskId, taskFields) {
  const docRef = doc(db, "tasks", taskId);
  return updateDoc(docRef, {
    ...taskFields,
    updatedAt: serverTimestamp(),
  });
}

export async function getTasksForTransaction(transactionId) {
  return new Promise(async function (resolve, reject) {
    onSnapshot(fetchTasksTransFromDb(transactionId), (snapshot) => {
      const tasks = snapshot.docs.map((doc) => dataFromSnapshot(doc));
      resolve(tasks);
    });
  });
}

export async function updateTaskAddSharingInDb(taskId, party, transaction) {
  let partyRoleCamel = _.camelCase(party.role);
  const taskRef = doc(db, "tasks", taskId);
  let emailParty = party.email;
  let emailTc = party.email;
  let emailCoAgent = party.email;
  let newFields = {
    [`sharingWithRole.${partyRoleCamel}`]: party.email,
    mlsNumbers: transaction.mlsNumbers || [],
  };
  if (
    partyIsAgent(party.role) &&
    party.hasTransactionCoordinator &&
    party.transactionCoordinator?.email
  ) {
    newFields[`sharingWithRole.otherTransactionCoordinator`] =
      party.transactionCoordinator.email;
    emailTc = party.transactionCoordinator.email;
  }
  if (partyIsAgent(party.role) && party.hasCoAgent && party.coAgent?.email) {
    newFields[`sharingWithRole.otherCoAgent`] = party.coAgent.email;
    emailCoAgent = party.coAgent.email;
  }
  newFields["sharingWith"] = arrayUnion(emailParty, emailTc, emailCoAgent);
  return updateDoc(taskRef, newFields);
}

export async function updateTaskRemoveSharingInDb(docId, party, transaction) {
  let partyRoleCamel = _.camelCase(party.role);
  const docRef = doc(db, "tasks", docId);
  let emailParty = party.email;
  let emailTc = party.email;
  let emailCoAgent = party.email;
  let newFields = {
    [`sharingWithRole.${partyRoleCamel}`]: "",
    mlsNumbers: transaction.mlsNumbers || [],
  };
  if (
    partyIsAgent(party.role) &&
    party.hasTransactionCoordinator &&
    party.transactionCoordinator?.email
  ) {
    newFields[`sharingWithRole.otherTransactionCoordinator`] = "";
    emailTc = party.transactionCoordinator.email;
  }
  if (partyIsAgent(party.role) && party.hasCoAgent && party.coAgent?.email) {
    newFields[`sharingWithRole.otherCoAgent`] = "";
    emailCoAgent = party.coAgent.email;
  }
  newFields["sharingWith"] = arrayRemove(emailParty, emailTc, emailCoAgent);
  return updateDoc(docRef, newFields);
}

export async function archiveTasksFromDocInDb(docId) {
  const user = auth.currentUser;
  const q = query(
    collection(db, "tasks"),
    where("docId", "==", docId),
    where("userId", "==", user.uid)
  );
  const snapshot = await getDocs(query(q));
  snapshot.forEach((doc) => {
    updateTaskInDb(doc.id, { status: "Archived" });
  });
  return;
}

export async function deleteTasksFromDocInDb(docId) {
  const user = auth.currentUser;
  const q = query(
    collection(db, "tasks"),
    where("docId", "==", docId),
    where("userId", "==", user.uid)
  );
  const snapshot = await getDocs(query(q));
  if (snapshot.exists) {
    snapshot.forEach((doc) => {
      deleteTaskInDb(doc.id);
    });
  }
  return;
}

export function deleteTaskInDb(taskId) {
  const docRef = doc(db, "tasks", taskId);
  return deleteDoc(docRef);
}

///////////////////////////////////////////////////////////////
//////////////////////  DOCUMENTS  ////////////////////////////
///////////////////////////////////////////////////////////////

export function fetchDocsUpcomingFromDb(id) {
  let queryRef = query(collection(db, "documents"));
  if (authClaims?.r === "a" && authClaims?.a) {
    queryRef = query(queryRef, where("userId", "in", authClaims.a));
  } else if (authClaims?.r === "t") {
    queryRef = query(queryRef, where("tcId", "==", auth.currentUser.uid));
  } else if (id) {
    queryRef = query(
      queryRef,
      where("userId", "==", id),
      where("managerId", "==", auth.currentUser.uid)
    );
  } else {
    queryRef = query(
      queryRef,
      or(
        where("userId", "==", auth.currentUser.uid),
        where("coAgentId", "==", auth.currentUser.uid)
      )
    );
  }
  return query(
    queryRef,
    where("status", "==", "Awaiting Signature"),
    where("updatedAt", ">", startOfDay(subDays(new Date(), 30))),
    orderBy("updatedAt", "desc")
  );
}

export function fetchDocsTransFromDb(transactionId) {
  let queryRef = collection(db, "documents");
  if (authClaims?.r === "a" && authClaims?.a) {
    queryRef = query(queryRef, where("userId", "in", authClaims.a));
  } else if (authClaims?.r === "t") {
    queryRef = query(queryRef, where("tcId", "==", auth.currentUser.uid));
  } else if (authClaims?.r === "m") {
    queryRef = query(queryRef, where("managerId", "==", auth.currentUser.uid));
  } else {
    queryRef = query(
      queryRef,
      or(
        where("userId", "==", auth.currentUser.uid),
        where("coAgentId", "==", auth.currentUser.uid)
      )
    );
  }
  return query(
    queryRef,
    where("transactionId", "==", transactionId),
    orderBy("updatedAt", "desc")
  );
}

export function fetchDocFromDb(docId) {
  const docRef = doc(db, "documents", docId);
  return docRef;
}

export function fetchDocsToSignFromDb(docList) {
  const docsRef = collection(db, "documents");
  return query(docsRef, where("id", "in", docList));
}

export function fetchDocsForPartyFromDb(party) {
  // const partyRoleCamel = _.camelCase(party.role);
  const docsRef = collection(db, "documents");
  return query(
    docsRef,
    where("transactionId", "==", party.transactionId),
    where("sharingWith", "array-contains", auth.currentUser.email)
    // where(`sharingWithRole.${partyRoleCamel}`, "==", party.email)
  );
}

export function addDocToDb(document, docId) {
  const docRef = doc(db, "documents", docId);
  return setDoc(docRef, {
    ...document,
    id: docId,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export async function addUploadedDocToDb(
  transaction,
  docPath,
  docId,
  name,
  filetypeMIME,
  filesize,
  fileUrl
) {
  const period = name.lastIndexOf(".");
  const nameWithoutExtension = name.substring(0, period);
  const type = name.substring(period + 1);
  const docRef = doc(db, "documents", docId);
  return setDoc(docRef, {
    annotsInProgressSuggestedAdded: true,
    annotsToSign: {},
    annotsByAgent: {},
    mlsNumber: transaction.mlsNumber || "",
    userId: transaction.userId,
    tcId: transaction.tcId || "",
    managerId: transaction.managerId || "",
    coAgentId: transaction.coAgentId || "",
    hasFormFieldValues: false,
    hasFormFieldValuesForCopy: false,
    userEmail: transaction.agentEmail || transaction.agentProfile?.email || "",
    userFullname:
      transaction.agentName ||
      transaction.agentProfile?.firstName +
        " " +
        transaction.agentProfile?.lastName ||
      "",
    userFirstName:
      transaction.agentFirstName || transaction.agentProfile?.firstName || "",
    userLastName:
      transaction.agentLastName || transaction.agentProfile?.lastName || "",
    docRef: docPath,
    signed: false,
    transactionId: transaction.id,
    transactionTitle: transaction.title,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    title: nameWithoutExtension,
    name: nameWithoutExtension,
    filetype: type,
    filetypeMIME: filetypeMIME,
    filesize: filesize,
    fileUrl: fileUrl,
    status: "In Progress",
    pdfBurnVersion: 2,
    hasSigningVerification: true,
  });
}

export async function updateDocInDb(docId, documentFields, noUpdatedAt = null) {
  const docRef = doc(db, "documents", docId);
  if (noUpdatedAt) {
    return updateDoc(docRef, {
      ...documentFields,
    });
  } else {
    return updateDoc(docRef, {
      ...documentFields,
      updatedAt: serverTimestamp(),
    });
  }
}

export async function updateDocAddSharingInDb(docId, party, transaction) {
  let partyRoleCamel = _.camelCase(party.role);
  const docRef = doc(db, "documents", docId);
  let emailParty = party.email;
  let emailTc = party.email;
  let emailCoAgent = party.email;
  let newFields = {
    [`sharingWithRole.${partyRoleCamel}`]: party.email,
    mlsNumbers: transaction.mlsNumbers || [],
  };
  if (
    partyIsAgent(party.role) &&
    party.hasTransactionCoordinator &&
    party.transactionCoordinator?.email
  ) {
    newFields[`sharingWithRole.otherTransactionCoordinator`] =
      party.transactionCoordinator.email;
    emailTc = party.transactionCoordinator.email;
  }
  if (partyIsAgent(party.role) && party.hasCoAgent && party.coAgent?.email) {
    newFields[`sharingWithRole.otherCoAgent`] = party.coAgent.email;
    emailCoAgent = party.coAgent.email;
  }
  newFields["sharingWith"] = arrayUnion(emailParty, emailTc, emailCoAgent);
  return updateDoc(docRef, newFields);
}

export async function updateDocRemoveSharingInDb(docId, party, transaction) {
  let partyRoleCamel = _.camelCase(party.role);
  const docRef = doc(db, "documents", docId);
  let emailParty = party.email;
  let emailTc = party.email;
  let emailCoAgent = party.email;
  let newFields = {
    [`sharingWithRole.${partyRoleCamel}`]: "",
    mlsNumbers: transaction.mlsNumbers || [],
  };
  if (
    partyIsAgent(party.role) &&
    party.hasTransactionCoordinator &&
    party.transactionCoordinator?.email
  ) {
    newFields[`sharingWithRole.otherTransactionCoordinator`] = "";
    emailTc = party.transactionCoordinator.email;
  }
  if (partyIsAgent(party.role) && party.hasCoAgent && party.coAgent?.email) {
    newFields[`sharingWithRole.otherCoAgent`] = "";
    emailCoAgent = party.coAgent.email;
  }
  newFields["sharingWith"] = arrayRemove(emailParty, emailTc, emailCoAgent);
  return updateDoc(docRef, newFields);
}

export async function updateDocSentForSigningInDb(
  document,
  note,
  finalSignerList,
  transaction
) {
  const batch = writeBatch(db);

  let annots = _.cloneDeep(document.annotsInProgress);
  let annotsToSign = document.annotsToSign
    ? _.cloneDeep(document.annotsToSign)
    : {};
  let annotsByAgent = document.annotsByAgent
    ? _.cloneDeep(document.annotsByAgent)
    : {};
  const annotsInProgressForCopy = _.cloneDeep(document.annotsInProgress);
  const formFieldValuesForCopy = document.signingSentOutAt
    ? _.cloneDeep(document.formFieldValuesForCopy)
    : _.cloneDeep(document.formFieldValues);
  if (annots?.length > 0) {
    annots.forEach((annot) => {
      if (annot.agentsField && document.pdfBurnVersion) {
        annotsByAgent[annot.uniqueId] = annot;
      }
      if (!annot.agentsField) {
        annot.signerEmail = "";
        let signerFromList = finalSignerList.filter(
          (signer) => signer.role === annot.signerRole
        )[0];
        // Signer was unchecked from sending so don't create annot
        if (!signerFromList) {
          return;
        }
        if (signerFromList && signerFromList.email) {
          annot.signerEmail = signerFromList.email;
        }
        if (document.pdfBurnVersion) {
          annotsToSign[annot.uniqueId] = annot;
        } else {
          // Initial docId may be incorrect if auto added annot from CO forms
          const annotId = doc(
            collection(db, "documents", document.id, "annots")
          ).id;
          annot.id = annotId;
          annot.docId = document.id;
          annot.transactionId = transaction.id;
          annot.userId = transaction.userId;
          annot.active = true;
          annot.createdAt = serverTimestamp();
          annot.updatedAt = serverTimestamp();

          batch.set(
            doc(db, "documents", document.id, "annots", annotId),
            annot
          );
        }
      }
    });
  }

  let signingDetails = _.cloneDeep(document.signingDetails) || {};
  let signerEmails = [];
  let sharingWithRole = _.cloneDeep(document.sharingWithRole) || {};
  const finalSignerListCopy = _.cloneDeep(finalSignerList);
  if (finalSignerListCopy) {
    finalSignerListCopy.forEach((signer) => {
      const signerRoleCamel = _.camelCase(signer.role);
      signingDetails[signerRoleCamel] = signer;
      signingDetails[signerRoleCamel].sentOut = true;
      signingDetails[signerRoleCamel].sentOutAt = new Date();
      signingDetails[signerRoleCamel].signed = false;
      signingDetails[signerRoleCamel].signedAt = null;
      signerEmails = [...signerEmails, signer.email];
      sharingWithRole[signerRoleCamel] = signer.email;
    });
  }

  let formFieldValues = document.formFieldValues || {};
  let hasFormFieldValues = true;
  if (!document.pdfBurnVersion) {
    formFieldValues = {};
    hasFormFieldValues = false;
  }
  const documentFields = {
    note: note || "",
    id: document.id,
    annotsInProgress: [],
    annotsInProgressForCopy: annotsInProgressForCopy,
    annotsToSign: annotsToSign || {},
    annotsByAgent: annotsByAgent || {},
    hasFormFieldValues: hasFormFieldValues,
    formFieldValues: formFieldValues,
    hasFormFieldValuesForCopy: false,
    formFieldValuesForCopy: formFieldValuesForCopy || {},
    signingDetails: signingDetails,
    signingDetailsHistory: [signingDetails],
    signingRequestedFor: signerEmails,
    signingVerificationEnabled: true,
    sharingWith: arrayUnion(...signerEmails),
    sharingWithRole: sharingWithRole,
    status: "Awaiting Signature",
    subStatus: "Awaiting Signatures",
    signingSentOutAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  };
  const docRef = doc(db, "documents", document.id);
  batch.update(docRef, {
    ...documentFields,
    updatedAt: serverTimestamp(),
  });
  const transactionFields = {
    sharingWith: arrayUnion(...signerEmails),
    sharingWithRole: sharingWithRole,
    updatedAt: serverTimestamp(),
  };
  const transactionRef = doc(db, "transactions", transaction.id);
  batch.update(transactionRef, {
    ...transactionFields,
  });
  return await batch.commit();
}

export async function updateDocSigningInDb(doc, signer) {
  let signingDetailsCopy = _.cloneDeep(doc.signingDetails);
  const signerRoleCamel = _.camelCase(signer.role);
  signingDetailsCopy[signerRoleCamel].signed = true;
  signingDetailsCopy[signerRoleCamel].signedAt = new Date();
  let signingRequestedFor = doc.signingRequestedFor.filter(
    (email) => email !== signer.email
  );
  let documentFields = {};
  if (doc.annotsToSign) {
    Object.entries(doc.annotsToSign).forEach(([key, value]) => {
      if (
        value.signerRole === signer.role &&
        (value.type === "date" ||
          value.type === "checkbox" ||
          value.type === "text") &&
        value.signed !== true
      ) {
        documentFields[`annotsToSign.${value.uniqueId}.signed`] = true;
        documentFields[`annotsToSign.${value.uniqueId}.signedAt`] = new Date();
      }
    });
  }
  documentFields["signingDetails"] = signingDetailsCopy;
  documentFields["signingRequestedFor"] = signingRequestedFor;
  const signingComplete = docSigningComplete(signingDetailsCopy);

  if (signingComplete) {
    documentFields["status"] = "Complete";
    documentFields["statusPrevious"] = "Complete";
    documentFields["subStatus"] = "Signing Complete";
    documentFields["signingDetailsHistory"] = [
      ...doc.signingDetailsHistory,
      signingDetailsCopy,
    ];
  }

  return updateDocInDb(doc.id, documentFields);
}

export function deleteDocInDb(docId) {
  const docRef = doc(db, "documents", docId);
  return deleteDoc(docRef);
}

///////////////////////////////////////////////////////////////
//////////////////////  FORMS  ////////////////////////////
///////////////////////////////////////////////////////////////

export function fetchStateFormsFromDb(currentUserProfile) {
  const agentState = currentUserProfile?.state
    ? currentUserProfile.state
    : "Colorado";
  console.log(agentState);

  const formsRef = collection(db, `forms${agentState}`);
  return query(formsRef);
}

export async function getFormFieldConversionsFromDb(formId, transaction) {
  const agentState = transaction?.agentProfile?.state
    ? transaction?.agentProfile?.state
    : "Colorado";
  const fieldConversionsRef = collection(
    db,
    `forms${agentState}`,
    formId,
    "fieldConversions"
  );
  const snapshot = await getDocs(fieldConversionsRef);
  return snapshot.docs.map((doc) => doc.data());
}

export async function getFormTasksFromDb(formId, transaction) {
  const agentState = transaction?.agentProfile?.state
    ? transaction?.agentProfile?.state
    : "Colorado";
  const formTasksRef = collection(
    db,
    `forms${agentState}`,
    formId,
    "tasksToExtract"
  );
  const snapshot = await getDocs(formTasksRef);
  return snapshot.docs.map((doc) => doc.data());
}

export async function addUploadedFormToDb(transaction, docPath, docId, form) {
  let suggestedAnnots = [];
  let signingDetails = {};
  if (form.annotsInProgressSuggested) {
    suggestedAnnots = createSuggestedAnnots(transaction, form);
    signingDetails = createInitialSigningDetails(transaction, form);
  }
  const docRef = doc(db, "documents", docId);
  return setDoc(docRef, {
    ...form,
    mlsNumber: transaction.mlsNumber || "",
    annotsInProgress: suggestedAnnots,
    annotsInProgressSuggestedAdded: false,
    annotsToSign: {},
    annotsByAgent: {},
    signingDetails: signingDetails,
    hasFormFieldValues: true,
    hasFormFieldValuesForCopy: false,
    userId: transaction.userId,
    tcId: transaction.tcId || "",
    managerId: transaction.managerId || "",
    coAgentId: transaction.coAgentId || "",
    userEmail: transaction.agentEmail || transaction.agentProfile?.email || "",
    userFullname:
      transaction.agentName ||
      transaction.agentProfile?.firstName +
        " " +
        transaction.agentProfile?.lastName ||
      "",
    userFirstName:
      transaction.agentFirstName || transaction.agentProfile?.firstName || "",
    userLastName:
      transaction.agentLastName || transaction.agentProfile?.lastName || "",
    docRef: docPath,
    id: docId,
    signed: false,
    formId: form.id,
    transactionId: transaction.id,
    transactionTitle: transaction.title,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    status: "In Progress",
    subStatus: "Filling Out Form",
    name: form.name ? form.name : form.title,
    filetype: "pdf",
    filetypeMIME: "application/pdf",
    pdfBurnVersion: 2,
    hasSigningVerification: true,
  });
}

///////////////////////////////////////////////////////////////
/////////////////////////  EMAILS  ////////////////////////////
///////////////////////////////////////////////////////////////

export function fetchEmailsFromDb(transactionId) {
  const emailsRef = collection(db, "emailHistory");
  return query(emailsRef, where("userId", "==", auth.currentUser.uid),
    where("transactionId", "==", transactionId));
}

export function addEmailToDb(values, emailFields) {
  const emailId = doc(collection(db, "emailHistory")).id;
  return setDoc(doc(db, "emailHistory", emailId), {
    ...values,
    ...emailFields,
    id: emailId,
    userId: auth.currentUser.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

///////////////////////////////////////////////////////////////
///////////////////  FORM TEMPLATES  //////////////////////////
///////////////////////////////////////////////////////////////

export function fetchFormTemplatesFromDb() {
  const formTemplatesRef = collection(db, "formTemplates");
  return query(
    formTemplatesRef,
    where("userId", "==", auth.currentUser.uid), // templates not shared with asst/tcs
    orderBy("title", "desc")
  );
}
export function copyFormTemplateToDb(formTemplate) {
  const templateId = doc(collection(db, "formTemplates")).id;
  return setDoc(doc(db, "formTemplates", templateId), {
    ...formTemplate,
    id: templateId,
    userId: auth.currentUser.uid, // templates not shared with asst/tcs
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function addFormTemplateToDb(formTemplates, currentUserProfile) {
  const batch = writeBatch(db);
  formTemplates.forEach((template) => {
    const templateId = doc(collection(db, "formTemplates")).id;
    const isShowAgentNotSetOnForm =
      !template.showSellerAgent && !template.showBuyerAgent;
    batch.set(doc(db, "formTemplates", templateId), {
      userId: currentUserProfile.userId,
      hasFormFieldValues: false,
      title: template.title,
      name: template.title,
      id: templateId,
      showSellerAgent:
        isShowAgentNotSetOnForm || (template.showSellerAgent ? true : false),
      showBuyerAgent:
        isShowAgentNotSetOnForm || (template.showBuyerAgent ? true : false),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  });
  return batch.commit();
}

export function updateFormTemplateInDb(formTemplateId, formTemplateFields) {
  const docRef = doc(db, "formTemplates", formTemplateId);
  return updateDoc(docRef, {
    ...formTemplateFields,
    updatedAt: serverTimestamp(),
  });
}

export function deleteFormTemplateInDb(formTemplateId) {
  const docRef = doc(db, "formTemplates", formTemplateId);
  return deleteDoc(docRef);
}

///////////////////////////////////////////////////////////////
//////////////////  DEADLINE TEMPLATES  ///////////////////////
///////////////////////////////////////////////////////////////

export function fetchDeadlineTemplatesFromDb() {
  const deadlineTemplatesRef = collection(db, "deadlineTemplates");
  return query(
    deadlineTemplatesRef,
    where("userId", "==", auth.currentUser.uid)
  );
}
export function copyDeadlineTemplateToDb(deadlineTemplate) {
  const templateId = doc(collection(db, "deadlineTemplates")).id;
  return setDoc(doc(db, "deadlineTemplates", templateId), {
    ...deadlineTemplate,
    id: templateId,
    userId: auth.currentUser.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function addDeadlineTemplateToDb(deadlineTemplate) {
  const templateId = doc(collection(db, "deadlineTemplates")).id;
  return setDoc(doc(db, "deadlineTemplates", templateId), {
    ...deadlineTemplate,
    id: templateId,
    title: deadlineTemplate.name,
    name: deadlineTemplate.name,
    userId: auth.currentUser.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function updateDeadlineTemplateInDb(
  deadlineTemplateId,
  deadlineTemplateFields
) {
  const docRef = doc(db, "deadlineTemplates", deadlineTemplateId);
  return updateDoc(docRef, {
    ...deadlineTemplateFields,
    updatedAt: serverTimestamp(),
  });
}

export function deleteDeadlineTemplateInDb(deadlineTemplateId) {
  const docRef = doc(db, "deadlineTemplates", deadlineTemplateId);
  return deleteDoc(docRef);
}

///////////////////////////////////////////////////////////////
/////////////////////  EMAIL TEMPLATES  ///////////////////////
///////////////////////////////////////////////////////////////

export function fetchEmailTemplatesFromDb() {
  const emailTemplatesRef = collection(db, "emailTemplatesUsers");
  return query(emailTemplatesRef, where("userId", "==", auth.currentUser.uid));
}
export function copyEmailTemplateToDb(emailTemplate) {
  const templateId = doc(collection(db, "emailTemplatesUsers")).id;
  return setDoc(doc(db, "emailTemplatesUsers", templateId), {
    ...emailTemplate,
    id: templateId,
    userId: auth.currentUser.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function addEmailTemplateToDb(
  emailTemplate,
  toRoles,
  ccRoles,
  bccRoles
) {
  const templateId = doc(collection(db, "emailTemplatesUsers")).id;
  return setDoc(doc(db, "emailTemplatesUsers", templateId), {
    ...emailTemplate,
    to: toRoles,
    cc: ccRoles,
    bcc: bccRoles,
    id: templateId,
    title: emailTemplate.name,
    name: emailTemplate.name,
    userId: auth.currentUser.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function renameEmailTemplateInDb(emailTemplateId, emailTemplateFields) {
  const docRef = doc(db, "emailTemplatesUsers", emailTemplateId);
  return updateDoc(docRef, {
    ...emailTemplateFields,
    updatedAt: serverTimestamp(),
  });
}

export function updateEmailTemplateInDb(
  emailTemplateId,
  emailTemplateFields,
  toRoles,
  ccRoles,
  bccRoles
) {
  const docRef = doc(db, "emailTemplatesUsers", emailTemplateId);
  return updateDoc(docRef, {
    ...emailTemplateFields,
    to: toRoles,
    cc: ccRoles,
    bcc: bccRoles,
    updatedAt: serverTimestamp(),
  });
}

export function deleteEmailTemplateInDb(emailTemplateId) {
  const docRef = doc(db, "emailTemplatesUsers", emailTemplateId);
  return deleteDoc(docRef);
}

///////////////////////////////////////////////////////////////
/////////////////////  TASK TEMPLATES  ////////////////////////
///////////////////////////////////////////////////////////////

export function fetchTaskTemplatesFromDb() {
  const taskTemplatesRef = collection(db, "taskTemplates");
  return query(taskTemplatesRef, where("userId", "==", auth.currentUser.uid));
}

export function copyTaskTemplateToDb(taskTemplate) {
  const templateId = doc(collection(db, "taskTemplates")).id;
  return setDoc(doc(db, "taskTemplates", templateId), {
    ...taskTemplate,
    id: templateId,
    userId: auth.currentUser.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function addTaskTemplateToDb(taskTemplate) {
  const templateId = doc(collection(db, "taskTemplates")).id;
  return setDoc(doc(db, "taskTemplates", templateId), {
    ...taskTemplate,
    id: templateId,
    title: taskTemplate.title,
    name: taskTemplate.title,
    userId: auth.currentUser.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function addTaskTemplateItemToDb(item) {
  const templateId = doc(collection(db, "taskTemplates")).id;
  return setDoc(doc(db, "taskTemplates", templateId), {
    ...item,
    id: templateId,
    title: item.title,
    name: item.title,
    userId: auth.currentUser.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function updateTaskTemplateInDb(taskTemplateFields) {
  const docRef = doc(db, "taskTemplates", taskTemplateFields.id);
  return updateDoc(docRef, {
    ...taskTemplateFields,
    updatedAt: serverTimestamp(),
  });
}

export function deleteTaskTemplateInDb(taskTemplateId) {
  const docRef = doc(db, "taskTemplates", taskTemplateId);
  return deleteDoc(docRef);
}

///////////////////////////////////////////////////////////////
///////////////////////  CLAUSES  /////////////////////////////
///////////////////////////////////////////////////////////////

export function fetchClausesFromDb(currentUserProfile) {
  let queryRef = collection(db, "clauses");
  const brokerage = currentUserProfile?.brokerageForms
    ? currentUserProfile.brokerageForms
    : "NA";
  return query(
    queryRef,
    or(
      where("userId", "==", auth.currentUser.uid),
      and(where("brokerage", "==", brokerage), where("type", "==", "legal"))
    ),
    orderBy("title", "asc")
  );
}

export function addClauseToDb(clause) {
  const clauseId = doc(collection(db, "clauses")).id;
  return setDoc(doc(db, "clauses", clauseId), {
    ...clause,
    id: clauseId,
    title: clause.title,
    name: clause.title,
    userId: auth.currentUser.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function updateClauseInDb(clauseId, clauseFields) {
  const docRef = doc(db, "clauses", clauseId);
  return updateDoc(docRef, {
    ...clauseFields,
    updatedAt: serverTimestamp(),
  });
}

export function deleteClauseInDb(clauseId) {
  const docRef = doc(db, "clauses", clauseId);
  return deleteDoc(docRef);
}

///////////////////////////////////////////////////////////////
////////////////////////  HISTORY  ////////////////////////////
///////////////////////////////////////////////////////////////

export function fetchHistoryFromDb(id) {
  console.log("FETCH");
  const historyRef = collection(db, "history");
  return query(
    historyRef,
    where("transactionId", "==", id),
    orderBy("createdAt", "desc")
  );
}

export function addHistoryToDb(
  transactionId,
  party,
  action,
  documentName,
  toParty
) {
  const historyId = doc(collection(db, "history")).id;
  return setDoc(doc(db, "history", historyId), {
    transactionId: transactionId,
    role: party?.role || "",
    firstName: party?.firstName || "",
    lastName: party?.lastName || "",
    action: action || "",
    toRole: toParty?.role || "",
    toFirstName: toParty?.firstName || "",
    toLastName: toParty?.lastName || "",
    document: documentName || "",
    id: historyId,
    userId: auth.currentUser.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

///////////////////////////////////////////////////////////////
/////////////////////////  ANNOTS  ////////////////////////////
///////////////////////////////////////////////////////////////

export async function fetchDocAnnotsAllFromDb(docId) {
  const q = query(
    collection(db, "documents", docId, "annots"),
    where("userId", "==", auth.currentUser.uid)
  );
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => doc.data());
}

export function fetchDocAnnotsFromDb(docId, signerRole) {
  const annotsRef = collection(db, "documents", docId, "annots");
  return query(
    annotsRef,
    where("signerRole", "==", signerRole),
    where("active", "!=", false)
  );
}

export function addAnnotToDb(annot, annotId, docId) {
  const annotRef = doc(db, "documents", docId, "annots", annotId);
  return setDoc(annotRef, {
    ...annot,
    id: annotId,
    docId: docId,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function updateAnnotInDb(annotId, docId, annotFields) {
  const docRef = doc(db, "documents", docId, "annots", annotId);
  return updateDoc(docRef, {
    ...annotFields,
    updatedAt: serverTimestamp(),
  });
}

export function updateAnnotsForDocInDb(annots, docId, annotFields) {
  annots.forEach((annot) => {
    updateAnnotInDb(annot.id, docId, annotFields);
  });
  return;
}

export function updateAnnotSignedInDb(annotId, docId, annotFields) {
  const docRef = doc(db, "documents", docId, "annots", annotId);
  return updateDoc(docRef, {
    ...annotFields,
    signed: true,
    signedAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export async function deleteDocAnnotsInDb(docId) {
  const user = auth.currentUser;
  const q = query(
    collection(db, "documents", docId, "annots"),
    where("docId", "==", docId),
    where("userId", "==", user.uid)
  );
  const snapshot = await getDocs(query(q));
  snapshot.forEach((annot) => {
    deleteAnnotInDb(docId, annot.id);
  });
  return;
}

export function deleteAnnotInDb(docId, annotId) {
  const docRef = doc(db, "documents", docId, "annots", annotId);
  return deleteDoc(docRef);
}

///////////////////////////////////////////////////////////////
//////////////////////////  USERS  ////////////////////////////
///////////////////////////////////////////////////////////////

export function getUserProfile(userId) {
  const docRef = doc(db, "users", userId);
  return docRef;
}

export async function getUserProfileFromDb(userId) {
  const partyRef = doc(db, "users", userId);
  const snapshot = await getDoc(partyRef);
  return snapshot.data();
}

export async function getUserProfileByEmailFromDb(email) {
  const q = query(collection(db, "users"), where("email", "==", email));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => doc.data());
}

export function fetchAgentsForAssistantFromDb() {
  let queryRef = query(collection(db, "users"));
  if (authClaims?.r === "a" && authClaims?.a) {
    return query(queryRef, where("userId", "in", authClaims.a));
  } else return query(queryRef, where("userId", "==", "111111"));
}

export function fetchAgentsForManagerFromDb() {
  let queryRef = query(collection(db, "users"));
  return query(queryRef, where("managerId", "==", auth.currentUser.uid));
}

export function addPartyUserToDb(partyInfo) {
  const user = auth.currentUser;
  const userRef = doc(db, "users", user.uid);
  return setDoc(userRef, {
    displayName: user.displayName || "",
    email: user.email,
    firstName: partyInfo?.partyFirstName || "",
    lastName: partyInfo?.partyLastName || "",
    providerId: user.providerId,
    id: user.uid,
    userId: user.uid,
    type: "party",
    numLogins: 1,
    lastSignIn: serverTimestamp(),
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function updateLastSignIn(uid) {
  const docRef = doc(db, "users", uid);
  return updateDoc(docRef, {
    lastSignIn: serverTimestamp(),
    numLogins: increment(1),
  });
}

export async function updateUserProfileInDb(profile) {
  const docRef = doc(db, "users", auth.currentUser.uid);
  return await updateDoc(docRef, {
    ...profile,
    updatedAt: serverTimestamp(),
  });
}

export async function updateProfileFieldsInDb(userId, profileFields) {
  const docRef = doc(db, "users", userId);
  return await updateDoc(docRef, {
    ...profileFields,
    updatedAt: serverTimestamp(),
  });
}

///////////////////////////////////////////////////////////////
//////////////////////  MANAGERS  /////////////////////////////
///////////////////////////////////////////////////////////////

export function fetchTransActiveForManagerFromDb() {
  let queryRef = query(
    collection(db, "transactions"),
    where("active", "==", true),
    where("managerId", "==", auth.currentUser.uid)
  );
  return query(queryRef);
}

export function fetchTransClosedForManagerFromDb() {
  let queryRef = query(
    collection(db, "transactions"),
    where("status", "==", "Complete"),
    where("managerId", "==", auth.currentUser.uid),
    where("closingDateTime", ">", startOfYear(new Date()))
  );
  return query(queryRef);
}

///////////////////////////////////////////////////////////////
//////////////////  PROCESSING FORMS  /////////////////////////
///////////////////////////////////////////////////////////////

export async function getStateFormByTitleFromDb(title, currentUserProfile) {
  const agentState = currentUserProfile.state || "Colorado";
  const q = query(
    collection(db, `forms${agentState}`),
    where("title", "==", title)
  );
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => doc.data())[0];
}

export async function updateStateFormInDb(
  formId,
  documentFields,
  currentUserProfile
) {
  const agentState = currentUserProfile.state || "Colorado";
  const docRef = doc(db, `forms${agentState}`, formId);
  return updateDoc(docRef, {
    ...documentFields,
  });
}

///////////////////////////////////////////////////////////////
//////////////////////////  EMAIL  ////////////////////////////
///////////////////////////////////////////////////////////////

export async function sendOfferSubmittedEmail(
  sender,
  recipient,
  transaction,
  document
) {
  if (process.env.REACT_APP_ENABLE_EMAILS === "true") {
    console.log("Emails are live for this environment");
    if (
      sender?.isTester !== true &&
      (transaction?.agentEmail || transaction.agentProfile?.email)
    ) {
      console.log("Emails are live for this user");
      const transactionAddress = transaction?.address
        ? convertAddressStreetAndUnit(transaction.address)
        : "";
      sendEmail(
        ["liz@transactioner.com"],
        ["john@transactioner.com"],
        "offerSubmittedToAdmin",
        {
          senderFirstName: sender.firstName,
          senderLastName: sender.lastName,
          senderEmail: sender.email,
          recipientFirstName: recipient.firstName,
          recipientLastName: recipient.lastName,
          recipientEmail: recipient.email,
          transactionAddress: transactionAddress,
          documentName: document.name || "",
        }
      );
    } else {
      console.log("No emails for this user");
    }
  } else {
    console.log("No emails for this environment");
  }
}

export async function sendDocSignedEmail(sender, transaction, document) {
  if (process.env.REACT_APP_ENABLE_EMAILS === "true") {
    console.log("Emails are live for this environment");
    let agentEmail = transaction.agentEmail
      ? transaction.agentEmail
      : transaction.agentProfile?.email
      ? transaction.agentProfile?.email
      : "";
    const agentIsTester = transaction.agentProfile?.isTester ? true : false;
    if (sender?.isTester !== true && agentEmail && !agentIsTester) {
      console.log("Emails are live for this user");
      const urlApp =
        process.env.REACT_APP_ENVIRONMENT === "development"
          ? "http://localhost:3000"
          : "https://app.transactioner.com";
      let assistantEmail = "";
      if (transaction.assistantEmail) {
        assistantEmail = transaction.assistantEmail;
      }
      const transactionAddress = transaction?.address
        ? convertAddressStreetAndUnit(transaction.address)
        : "";
      const agentFirstName = transaction.agentName
        ? transaction.agentName.split(" ")[0]
        : transaction.agentProfile?.firstName
        ? transaction.agentProfile.firstName
        : "";

      sendEmail([agentEmail], [assistantEmail], "signingCompleteToAgent", {
        recipientFirstName: agentFirstName,
        loginUrlUser: urlApp,
        senderFirstName: sender.firstName,
        senderLastName: sender.lastName,
        senderEmail: sender.email,
        transactionAddress: transactionAddress,
        documentName: document.name || "",
      });
      if (transaction.hasTc && transaction.transactionCoordinator?.email) {
        sendEmail(
          [transaction.transactionCoordinator.email],
          [""],
          "signingCompleteToAgent",
          {
            recipientFirstName:
              transaction.transactionCoordinator.firstName || "",
            loginUrlUser: urlApp,
            senderFirstName: sender.firstName,
            senderLastName: sender.lastName,
            senderEmail: sender.email,
            transactionAddress: transactionAddress,
            documentName: document.name || "",
          }
        );
      }
    } else {
      console.log("No emails for this user");
    }
  } else {
    console.log("No emails for this environment");
  }
}

export async function sendDocSharingEmail(
  partyList,
  type,
  sender,
  transaction
) {
  if (process.env.REACT_APP_ENABLE_EMAILS === "true") {
    console.log("Emails are live for this environment");
    if (sender?.isTester !== true) {
      console.log("Emails are live for this user");
      const sendingTimeThreshold = subMinutes(new Date(), 10);
      const urlApp =
        process.env.REACT_APP_ENVIRONMENT === "development"
          ? "http://localhost:3000"
          : "https://app.transactioner.com";
      partyList.forEach(async (party) => {
        if (
          !party.lastEmailSentAt ||
          new Date(party.lastEmailSentAt) < sendingTimeThreshold
        ) {
          let loginUrlParty = `${urlApp}/partyLogin?partyFirstName=${
            party.firstName || ""
          }&partyLastName=${party.lastName || ""}&agentName=${
            sender.firstName + "%20" + sender.lastName
          }`;
          const loginUrlUser = urlApp;
          const userType = partyIsAgent(party.role) ? "Agent" : "Client";
          let assistantEmail = "";
          if (party.hasAssistant && party.assistant?.email) {
            assistantEmail = party.assistant?.email;
            loginUrlParty = loginUrlParty + "&assistant=true";
          }
          const transactionAddress = transaction?.address
            ? convertAddressStreetAndUnit(transaction.address)
            : "";
          let partyLoginUrlAllButTc = loginUrlParty;
          if (partyIsAgent(party.role) && party.isUser && party.isLinked) {
            partyLoginUrlAllButTc = loginUrlUser;
          }

          sendEmail([party.email], [assistantEmail], `${type}Doc${userType}`, {
            recipientFirstName: party.firstName || "",
            loginUrlParty: partyLoginUrlAllButTc,
            loginUrlUser: loginUrlUser,
            senderFirstName: sender.firstName || "",
            senderLastName: sender.lastName || "",
            senderEmail: sender.email || "",
            transactionAddress: transactionAddress || "",
          });
          if (
            partyIsAgent(party.role) &&
            party.hasTransactionCoordinator &&
            party.transactionCoordinator?.email
          ) {
            let partyLoginUrlTc = loginUrlParty;
            if (party.isLinked && party.transactionCoordinator.isUser) {
              partyLoginUrlTc = loginUrlUser;
            }
            sendEmail(
              [party.transactionCoordinator?.email],
              [""],
              `${type}Doc${userType}`,
              {
                recipientFirstName:
                  party.transactionCoordinator?.firstName || "",
                loginUrlParty: partyLoginUrlTc,
                loginUrlUser: loginUrlUser,
                senderFirstName: sender.firstName || "",
                senderLastName: sender.lastName || "",
                senderEmail: sender.email || "",
                transactionAddress: transactionAddress || "",
              }
            );
          }
          if (
            partyIsAgent(party.role) &&
            party.hasCoAgent &&
            party.coAgent?.email
          ) {
            let partyLoginUrlCoAgent = loginUrlParty;
            if (party.isLinked && party.coAgent?.isUser) {
              partyLoginUrlCoAgent = loginUrlUser;
            }

            sendEmail([party.coAgent?.email], [""], `${type}Doc${userType}`, {
              recipientFirstName: party.coAgent?.firstName || "",
              loginUrlParty: partyLoginUrlCoAgent,
              loginUrlUser: loginUrlUser,
              senderFirstName: sender.firstName || "",
              senderLastName: sender.lastName || "",
              senderEmail: sender.email || "",
              transactionAddress: transactionAddress || "",
            });
          }
          updatePartyInDb(party, {
            lastEmailSentAt: new Date(),
          });
        }
      });
    } else {
      console.log("No emails for this user");
    }
  } else {
    console.log("No emails for this environment");
  }
}

export async function sendTransactionEmail(
  values,
  emailFields,
  sender,
  transaction
) {
  if (process.env.REACT_APP_ENABLE_EMAILS === "true") {
    console.log("Emails are live for this environment");
    if (sender?.isTester !== true) {
      console.log("Emails are live for this user");

      const to = emailFields.toEmails ? emailFields.toEmails.toString() : null;
      const cc = emailFields.ccEmails ? emailFields.ccEmails.toString() : null;
      const bcc = emailFields.bccEmails
        ? emailFields.bccEmails.toString()
        : null;
      let replyTo = "";
      let from = "";
      if (transaction.agentProfile?.email) {
        replyTo = transaction.agentProfile.email;
        from = `${transaction.agentProfile.firstName} ${transaction.agentProfile.lastName} <help@transactioner.com>`;
      } else {
        replyTo = sender.email;
        from = `${sender.firstName} ${sender.lastName} <${sender.email}>`;
      }

      const mailRef = collection(db, "mail");
      const mailOptions = {
        to: to,
        cc: cc,
        bcc: bcc,
        from: from,
        replyTo: replyTo,
        message: {
          subject: values.subject,
          text: values.message,
        },
        createdAt: serverTimestamp(),
      };
      try {
        if (process.env.REACT_APP_ENVIRONMENT === "development") {
          console.log("EMAIL THAT WOULD BE SENT: ", mailOptions);
          return;
        } else {
          return addDoc(mailRef, mailOptions);
        }
      } catch (error) {
        throw error;
      }
    } else {
      console.log("No emails for this user");
    }
  } else {
    console.log("No emails for this environment");
  }
}

export async function sendEmail(email, assistantEmail, templateName, data) {
  const mailRef = collection(db, "mail");
  let mailOptions = {};
  if (process.env.REACT_APP_ENVIRONMENT === "development") {
    console.log("EMAIL WOULD GO TO: ", email, assistantEmail);
    mailOptions = {
      to: "emaildev@transactioner.com",
      template: {
        name: templateName,
        data: data,
      },
      createdAt: serverTimestamp(),
    };
    if (!assistantEmail.includes("")) {
      mailOptions["cc"] = "emaildev@transactioner.com";
    }
  } else {
    mailOptions = {
      to: email,
      bcc: "emailprod@transactioner.com",
      template: {
        name: templateName,
        data: data,
      },
      createdAt: serverTimestamp(),
    };
    if (!assistantEmail.includes("")) {
      mailOptions["cc"] = assistantEmail;
    }
  }
  try {
    return addDoc(mailRef, mailOptions);
  } catch (error) {
    throw error;
  }
}
