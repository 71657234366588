export function sellerAdvisoryResidentialDC924() {
return [   //2024 Release 2024-09-17 18:26:07
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 48.72,
   left: 270.6,
   width: 184.2,
   height: 10.20
}
,
{
   page: 0,
   name: "without right of transfer All rights reserved", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 135.84,
   width: 174.72,
   height: 10.20
}
,
{
   page: 0,
   name: "Text4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 12,
   top: 153.273,
   left: 179.037,
   width: 353.983,
   height: 15.419
}
,
{
   page: 0,
   name: "Text5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 12,
   top: 180.11,
   left: 179.037,
   width: 353.983,
   height: 15.418
}
,
{
   page: 0,
   name: "Text6", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 12,
   top: 207.601,
   left: 179.037,
   width: 353.983,
   height: 15.418
}
,
{
   page: 0,
   name: "Text7", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 12,
   top: 233.128,
   left: 179.037,
   width: 353.983,
   height: 15.418
}
,
{
   page: 1,
   name: "undefined_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 48.72,
   left: 270.6,
   width: 184.2,
   height: 10.20
}
,
{
   page: 1,
   name: "without right of transfer All rights reserved_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 135.84,
   width: 174.72,
   height: 10.20
}
,
{
   page: 2,
   name: "undefined_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 48.72,
   left: 270.6,
   width: 184.2,
   height: 10.20
}
,
{
   page: 2,
   name: "without right of transfer All rights reserved_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 135.84,
   width: 174.72,
   height: 10.20
}
,
{
   page: 3,
   name: "undefined_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 48.72,
   left: 270.6,
   width: 184.2,
   height: 10.20
}
,
{
   page: 3,
   name: "without right of transfer All rights reserved_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 135.84,
   width: 174.72,
   height: 10.20
}
,
{
   page: 4,
   name: "undefined_5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 47.52,
   left: 270.6,
   width: 184.2,
   height: 11.40
}
,
{
   page: 4,
   name: "signature Broker", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 287.8,
   left: 72.0,
   width: 136.92,
   height: 15.56
}
,
{
   page: 4,
   name: "Date_3", 
   fontSize: 9,
   type: "date",
   top: 287.8,
   left: 216.36,
   width: 71.28,
   height: 15.56
}
,
{
   page: 4,
   name: "signature CoBroker", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 287.8,
   left: 320.4,
   width: 136.92,
   height: 15.56
}
,
{
   page: 4,
   name: "Date_6", 
   fontSize: 9,
   type: "date",
   top: 287.8,
   left: 464.76,
   width: 71.28,
   height: 15.56
}
,
{
   page: 4,
   name: "without right of transfer All rights reserved_5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 135.84,
   width: 174.72,
   height: 10.20
}
] }
