export function contracttoBuyandSellLand() {
   return [   //2023
   {
      page: 0,
      name: "Property with No Residences", 
      isText: false,
      type: "checkbox",
      top: 168.84,
      left: 231,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 0,
      name: "Property with Residences Residential Addendum Attached", 
      isText: false,
      type: "checkbox",
      top: 182.52,
      left: 151.32,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 198,
      left: 477.12,
      width: 94.92,
      height: 20.16
   }
   ,
   {
      page: 0,
      name: "Buyer", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 303.84,
      left: 117.12,
      width: 415.44,
      height: 20.16
   }
   ,
   {
      page: 0,
      name: "Joint Tenants", 
      isText: false,
      type: "checkbox",
      top: 328.92,
      left: 272.04,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "Tenants In Common", 
      isText: false,
      type: "checkbox",
      top: 328.92,
      left: 354.36,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "Other", 
      isText: false,
      type: "checkbox",
      top: 343.8,
      left: 76.2,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "BuyerTitleOther", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 339.6,
      left: 117.36,
      width: 437.40,
      height: 14.16
   }
   ,
   {
      page: 0,
      name: "Seller", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 375.6,
      left: 115.44,
      width: 394.56,
      height: 20.16
   }
   ,
   {
      page: 0,
      name: "CountyAddress", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 411.6,
      left: 423.6,
      width: 142.92,
      height: 20.16
   }
   ,
   {
      page: 0,
      name: "known as", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 496.32,
      left: 114.24,
      width: 442.56,
      height: 20.16
   }
   ,
   {
      page: 0,
      name: "legalDescription", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 448.746,
      left: 60.151,
      width: 496.585,
      height: 44.915
   }
   ,
   {
      page: 0,
      name: "ta_inclusions", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 597.691,
      left: 61.1058,
      width: 518.5442,
      height: 121.2973
   }
   ,
   {
      page: 1,
      name: "Deeded Water Rights The following legally described water rights", 
      isText: false,
      type: "checkbox",
      top: 411.72,
      left: 107.88,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 1,
      name: "deed at Closing", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 513.12,
      left: 347.52,
      width: 152.04,
      height: 12.00
   }
   ,
   {
      page: 1,
      name: "Other Rights Relating to Water The following rights relating to water not included in  271 273", 
      isText: false,
      type: "checkbox",
      top: 540.12,
      left: 107.88,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 1,
      name: "Well Rights Seller agrees to supply required information to Buyer about the well Buyer understands that if the", 
      isText: false,
      type: "checkbox",
      top: 657,
      left: 107.88,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 1,
      name: "undefined_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 731.04,
      left: 295.2,
      width: 244.8,
      height: 12.00
   }
   ,
   {
      page: 1,
      name: "ta_encumberedInclusions", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 84.975,
      left: 61.1058,
      width: 515.6802,
      height: 67.830
   }
   ,
   {
      page: 1,
      name: "ta_leasedItems", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 204.323,
      left: 62.0606,
      width: 513.7704,
      height: 75.467
   }
   ,
   {
      page: 1,
      name: "ta_exclusions", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 296.936,
      left: 60.151,
      width: 516.635,
      height: 99.337
   }
   ,
   {
      page: 1,
      name: "ta_deededWaterRights", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 423.922,
      left: 59.1963,
      width: 517.5897,
      height: 85.015
   }
   ,
   {
      page: 1,
      name: "ta_otherWaterRights", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 564.274,
      left: 59.1963,
      width: 516.6347,
      height: 88.835
   }
   ,
   {
      page: 2,
      name: "Water Stock Certificates The water stock certificates to be transferred at Closing are as follows", 
      isText: false,
      type: "checkbox",
      top: 51.24,
      left: 107.88,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 2,
      name: "Does", 
      isText: false,
      type: "checkbox",
      top: 285.24,
      left: 234.84,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 2,
      name: "Does Not have a Right to Terminate if examination of the Water", 
      isText: false,
      type: "checkbox",
      top: 285.24,
      left: 278.76,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsTime of Day Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 449.4,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "DateAlternative Earnest Money Deadline", 
      fontSize: 9,
      type: "date",
      top: 463.8,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsAlternative Earnest Money Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 463.8,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "DateRecord Title Deadline and Tax Certificate", 
      fontSize: 9,
      type: "date",
      top: 493.08,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsRecord Title Deadline and Tax Certificate", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 493.08,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "DateRecord Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 507.48,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsRecord Title Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 507.48,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "DateOffRecord Title Deadline", 
      fontSize: 9,
      type: "date",
      top: 522.12,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsOffRecord Title Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 522.12,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "DateOffRecord Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 536.52,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsOffRecord Title Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 536.52,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "DateTitle Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 551.16,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsTitle Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 551.16,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "DateThird Party Right to PurchaseApprove Deadline", 
      fontSize: 9,
      type: "date",
      top: 565.8,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsThird Party Right to PurchaseApprove Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 565.8,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "DateAssociation Documents Deadline", 
      fontSize: 9,
      type: "date",
      top: 594.84,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsAssociation Documents Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 594.84,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "DateAssociation Documents Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 609.48,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsAssociation Documents Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 609.48,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "DateSellers Property Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 638.52,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsSellers Property Disclosure Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 638.52,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "DateLeadBased Paint Disclosure Deadline if Residential Addendum attached", 
      fontSize: 9,
      type: "date",
      top: 653.16,
      left: 318.96,
      width: 92.52,
      height: 19.80
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsLeadBased Paint Disclosure Deadline if Residential Addendum attached", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 653.16,
      left: 413.52,
      width: 155.40,
      height: 19.80
   }
   ,
   {
      page: 2,
      name: "DateNew Loan Application Deadline", 
      fontSize: 9,
      type: "date",
      top: 688.92,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsNew Loan Application Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 688.92,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "DateNew Loan Terms Deadline", 
      fontSize: 9,
      type: "date",
      top: 703.32,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsNew Loan Terms Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 703.32,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "DateNew Loan Availability Deadline", 
      fontSize: 9,
      type: "date",
      top: 717.96,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsNew Loan Availability Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 717.96,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 2,
      name: "DateBuyers Credit Information Deadline", 
      fontSize: 9,
      type: "date",
      top: 732.36,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsBuyers Credit Information Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 732.36,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 2,
      name: "ta_waterStockCerts", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 63.015,
      left: 59.1963,
      width: 504.2227,
      height: 55.418
   }
   ,
   {
      page: 2,
      name: "ta_waterSewerTaps", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 144.172,
      left: 58.2415,
      width: 506.1325,
      height: 64.010
   }
   ,
   {
      page: 2,
      name: "ta_growingCrops", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 324.625,
      left: 55.3772,
      width: 510.9058,
      height: 65.920
   }
   ,
   {
      page: 3,
      name: "DateDisapproval of Buyers Credit Information Deadline", 
      fontSize: 9,
      type: "date",
      top: 73.32,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsDisapproval of Buyers Credit Information Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 73.32,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "DateExisting Loan Deadline", 
      fontSize: 9,
      type: "date",
      top: 87.72,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsExisting Loan Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 87.72,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateExisting Loan Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 102.36,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsExisting Loan Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 102.36,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "DateLoan Transfer Approval Deadline", 
      fontSize: 9,
      type: "date",
      top: 116.76,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsLoan Transfer Approval Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 116.76,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateSeller or Private Financing Deadline", 
      fontSize: 9,
      type: "date",
      top: 131.4,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsSeller or Private Financing Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 131.4,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateAppraisal Deadline", 
      fontSize: 9,
      type: "date",
      top: 160.44,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsAppraisal Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 160.44,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateAppraisal Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 175.08,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsAppraisal Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 175.08,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateAppraisal Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 189.72,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsAppraisal Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 189.72,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "DateNew ILC or New Survey Deadline", 
      fontSize: 9,
      type: "date",
      top: 218.76,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsNew ILC or New Survey Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 218.76,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "DateNew ILC or New Survey Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 233.16,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsNew ILC or New Survey Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 233.16,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateNew ILC or New Survey Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 247.8,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsNew ILC or New Survey Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 247.8,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateWater Rights Examination Deadline", 
      fontSize: 9,
      type: "date",
      top: 276.84,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsWater Rights Examination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 276.84,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateMineral Rights Examination Deadline", 
      fontSize: 9,
      type: "date",
      top: 291.48,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsMineral Rights Examination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 291.48,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "DateInspection Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 305.88,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsInspection Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 305.88,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateInspection Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 320.52,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsInspection Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 320.52,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateInspection Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 335.16,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsInspection Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 335.16,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "DateProperty Insurance Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 349.56,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsProperty Insurance Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 349.56,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateDue Diligence Documents Delivery Deadline", 
      fontSize: 9,
      type: "date",
      top: 364.2,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsDue Diligence Documents Delivery Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 364.2,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateDue Diligence Documents Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 378.84,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsDue Diligence Documents Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 378.84,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "DateDue Diligence Documents Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 393.24,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsDue Diligence Documents Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 393.24,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateEnvironmental Inspection Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 407.88,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsEnvironmental Inspection Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 407.88,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "DateADA Evaluation Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 422.28,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsADA Evaluation Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 422.28,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateConditional Sale Deadline", 
      fontSize: 9,
      type: "date",
      top: 436.92,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsConditional Sale Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 436.92,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateLeadBased Paint Termination Deadline if Residential Addendum attached", 
      fontSize: 9,
      type: "date",
      top: 451.8,
      left: 318.96,
      width: 92.52,
      height: 19.56
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsLeadBased Paint Termination Deadline if Residential Addendum attached", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 451.8,
      left: 413.52,
      width: 155.40,
      height: 19.56
   }
   ,
   {
      page: 3,
      name: "DateEstoppel Statements Deadline", 
      fontSize: 9,
      type: "date",
      top: 472.68,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsEstoppel Statements Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 472.68,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateEstoppel Statements Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 487.32,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsEstoppel Statements Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 487.32,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "DateClosing Date", 
      fontSize: 9,
      type: "date",
      top: 516.36,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsClosing Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 516.36,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DatePossession Date", 
      fontSize: 9,
      type: "date",
      top: 531,
      left: 318.72,
      width: 93.00,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsPossession Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 531,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsPossession Time", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 545.4,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateAcceptance Deadline Date", 
      fontSize: 9,
      type: "date",
      top: 560.04,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsAcceptance Deadline Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 560.04,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsAcceptance Deadline Time", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 574.68,
      left: 413.28,
      width: 155.88,
      height: 13.08
   }
   ,
   {
      page: 3,
      name: "Acceptance Deadline TimeRow1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 589.08,
      left: 111.84,
      width: 205.32,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateRow36", 
      fontSize: 9,
      type: "date",
      top: 589.08,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsRow36", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 589.08,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Acceptance Deadline TimeRow2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 603.72,
      left: 111.84,
      width: 205.32,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "DateRow37", 
      fontSize: 9,
      type: "date",
      top: 603.72,
      left: 318.72,
      width: 93.00,
      height: 13.32
   }
   ,
   {
      page: 3,
      name: "Deadline DetailsRow37", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 603.72,
      left: 413.28,
      width: 155.88,
      height: 13.32
   }
   ,
   {
      page: 4,
      name: "Deadlines If any deadline falls on a Saturday Sunday or federal or Colorado state holiday Holiday such deadline", 
      isText: false,
      type: "checkbox",
      top: 124.92,
      left: 116.52,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 4,
      name: "checked the deadline will not be extended", 
      isText: false,
      type: "checkbox",
      top: 124.92,
      left: 77.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 4,
      name: "Amount_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 201.765,
      left: 312.042,
      width: 86.118,
      height: 12.365
   }
   ,
   {
      page: 4,
      name: "Earnest Money", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 216.075,
      left: 415.042,
      width: 86.118,
      height: 12.365
   }
   ,
   {
      page: 4,
      name: "New Loan", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 230.76,
      left: 415.042,
      width: 86.118,
      height: 12.365
   }
   ,
   {
      page: 4,
      name: "Assumption Balance", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 245.16,
      left: 415.042,
      width: 86.118,
      height: 12.365
   }
   ,
   {
      page: 4,
      name: "Private Financing", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 259.8,
      left: 415.042,
      width: 86.118,
      height: 12.365
   }
   ,
   {
      page: 4,
      name: "Seller Financing", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 274.44,
      left: 415.042,
      width: 86.118,
      height: 12.365
   }
   ,
   {
      page: 4,
      name: " 477", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 288.84,
      left: 127.44,
      width: 52.92,
      height: 13.32
   }
   ,
   {
      page: 4,
      name: "Seller Financing7", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 288.84,
      left: 181.92,
      width: 117.24,
      height: 13.32
   }
   ,
   {
      page: 4,
      name: "7_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 288.84,
      left: 300.72,
      width: 100.44,
      height: 13.32
   }
   ,
   {
      page: 4,
      name: "7_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 288.84,
      left: 402.72,
      width: 98.28,
      height: 13.32
   }
   ,
   {
      page: 4,
      name: " 478", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 303.48,
      left: 127.44,
      width: 52.92,
      height: 13.32
   }
   ,
   {
      page: 4,
      name: "Seller Financing8", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 303.48,
      left: 181.92,
      width: 117.24,
      height: 13.32
   }
   ,
   {
      page: 4,
      name: "8", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 303.48,
      left: 300.72,
      width: 100.44,
      height: 13.32
   }
   ,
   {
      page: 4,
      name: "8_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 303.48,
      left: 402.72,
      width: 98.28,
      height: 13.32
   }
   ,
   {
      page: 4,
      name: "fill_27", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 318.12,
      left: 413.4,
      width: 87.6,
      height: 13.08
   }
   ,
   {
      page: 4,
      name: "fill_29", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 332.4,
      left: 309.48,
      width: 91.80,
      height: 10.8
   }
   ,
   {
      page: 4,
      name: "fill_30", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 332.4,
      left: 413.28,
      width: 87.84,
      height: 10.8
   }
   ,
   {
      page: 4,
      name: "Seller Concession", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 347.76,
      left: 350.28,
      width: 125.64,
      height: 12.48
   }
   ,
   {
      page: 4,
      name: "undefined_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 418.8,
      left: 414.48,
      width: 148.92,
      height: 12.48
   }
   ,
   {
      page: 4,
      name: "Earnest Money_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 432,
      left: 195.84,
      width: 305.16,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "or as otherwise agreed in writing between the parties to allow disbursement by Closing Company at Closing OR SUCH", 
      isText: false,
      type: "checkbox",
      top: 140.52,
      left: 428.76,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "funds that are immediately verifiable and available in an amount not less than the amount stated as Cash at Closing in  41", 
      isText: false,
      type: "checkbox",
      top: 140.52,
      left: 472.44,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "453", 
      isText: false,
      type: "checkbox",
      top: 260.52,
      left: 77.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "Loan Limitations Buyer may purchase the Property using any of the following types of loans", 
      isText: false,
      type: "checkbox",
      top: 259.56,
      left: 159.96,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 5,
      name: "undefined_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 258,
      left: 204.96,
      width: 348.00,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "per", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 287.76,
      left: 292.08,
      width: 89.40,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "including", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 287.76,
      left: 395.52,
      width: 135.12,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "principal and interest presently at the rate of", 
      isText: false,
      type: "checkbox",
      top: 330.84,
      left: 173.16,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "indicated", 
      isText: false,
      type: "checkbox",
      top: 330.84,
      left: 73.8,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "Property Insurance Premium and", 
      isText: false,
      type: "checkbox",
      top: 329.88,
      left: 356.52,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 5,
      name: "per annum and also including escrow for the following as", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 300.96,
      left: 251.76,
      width: 47.40,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "undefined_5", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 328.32,
      left: 373.68,
      width: 190.32,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "At the time of assumption the new interest rate will", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 342,
      left: 293.88,
      width: 64.44,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "per annum and the new payment will not exceed", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 355.2,
      left: 119.04,
      width: 47.52,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "per_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 355.2,
      left: 382.08,
      width: 59.40,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "principal", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 355.2,
      left: 456,
      width: 80.16,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "undefined_6", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 381.6,
      left: 496.2,
      width: 69.84,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "or if any other terms or provisions of the loan change Buyer has the Right to Terminate under  241 on or before Closing", 
      isText: false,
      type: "checkbox",
      top: 423.72,
      left: 104.04,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "release from liability will be evidenced by delivery", 
      isText: false,
      type: "checkbox",
      top: 423.72,
      left: 145.56,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "Will Not be released from liability on said loan If applicable compliance with the requirements for", 
      isText: false,
      type: "checkbox",
      top: 437.16,
      left: 285.24,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "at Closing", 
      isText: false,
      type: "checkbox",
      top: 437.16,
      left: 506.76,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "in an amount not to exceed", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 461.04,
      left: 73.2,
      width: 169.44,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "undefined_7", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 461.04,
      left: 360.96,
      width: 81.96,
      height: 12.48
   }
   ,
   {
      page: 5,
      name: "471", 
      isText: false,
      type: "checkbox",
      top: 559.8,
      left: 79.8,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "Seller Financing If Buyer is to pay all or any portion of the Purchase Price with Seller financing", 
      isText: false,
      type: "checkbox",
      top: 559.8,
      left: 129.24,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 5,
      name: "days", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 557.28,
      left: 516.24,
      width: 35.76,
      height: 12.48
   }
   ,
   {
      page: 7,
      name: "Cost of Appraisal Cost of the Appraisal to be obtained after the date of this Contract must be timely paid by", 
      isText: false,
      type: "checkbox",
      top: 167.16,
      left: 123,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 7,
      name: "company lenders agent or all three", 
      isText: false,
      type: "checkbox",
      top: 167.16,
      left: 73.8,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 8,
      name: "company to furnish the owners title insurance policy at Sellers expense On or before Record Title Deadline Seller must", 
      isText: false,
      type: "checkbox",
      top: 247.08,
      left: 107.88,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 8,
      name: "insurance policy to be issued and delivered to Buyer as soon as practicable at or after Closing", 
      isText: false,
      type: "checkbox",
      top: 281.64,
      left: 245.88,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 8,
      name: "company to furnish the owners title insurance policy at Buyers expense On or before Record Title Deadline Buyer must", 
      isText: false,
      type: "checkbox",
      top: 308.28,
      left: 109.8,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 8,
      name: "ExtendedCoverage OEC If the Title Commitment is to contain OEC it will commit to delete or insure over the standard", 
      isText: false,
      type: "checkbox",
      top: 367.8,
      left: 377.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 8,
      name: "Will Not contain Owners", 
      isText: false,
      type: "checkbox",
      top: 367.8,
      left: 418.68,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 8,
      name: "OneHalf by Buyer and OneHalf by Seller", 
      isText: false,
      type: "checkbox",
      top: 435,
      left: 275.88,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 8,
      name: "Other_2", 
      isText: false,
      type: "checkbox",
      top: 434.04,
      left: 328.2,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 8,
      name: "Buyer_2", 
      isText: false,
      type: "checkbox",
      top: 448.68,
      left: 306.36,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 8,
      name: "Regardless of whether the Contract requires OEC the Title Insurance Commitment may not provide OEC or delete or", 
      isText: false,
      type: "checkbox",
      top: 448.68,
      left: 80.04,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 8,
      name: "undefined_8", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 445.92,
      left: 351.6,
      width: 214.44,
      height: 13.68
   }
   ,
   {
      page: 9,
      name: "that affect the Property Tax Certificate must be delivered to Buyer on or before Record Title Deadline If the Property is", 
      isText: false,
      type: "checkbox",
      top: 418.68,
      left: 277.08,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 9,
      name: "Buyer for the Property listing any special taxing districts", 
      isText: false,
      type: "checkbox",
      top: 418.68,
      left: 325.08,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 10,
      name: "Does_2", 
      isText: false,
      type: "checkbox",
      top: 466.2,
      left: 228.36,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 10,
      name: "Does Not have a Right to Terminate if examination of the Mineral", 
      isText: false,
      type: "checkbox",
      top: 466.2,
      left: 272.04,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 10,
      name: "New Improvement Location Certificate New ILC or", 
      isText: false,
      type: "checkbox",
      top: 520.92,
      left: 89.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 10,
      name: "New Survey in the form of", 
      isText: false,
      type: "checkbox",
      top: 534.12,
      left: 89.16,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 10,
      name: "undefined_9", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 531.6,
      left: 216.72,
      width: 347.40,
      height: 12.48
   }
   ,
   {
      page: 10,
      name: "Seller_2", 
      isText: false,
      type: "checkbox",
      top: 560.28,
      left: 276.84,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 10,
      name: "Buyer will order the New ILC or New Survey The New", 
      isText: false,
      type: "checkbox",
      top: 560.28,
      left: 324.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 10,
      name: "Seller_3", 
      isText: false,
      type: "checkbox",
      top: 606.36,
      left: 133.32,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 10,
      name: "Buyer or", 
      isText: false,
      type: "checkbox",
      top: 606.36,
      left: 181.08,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 10,
      name: "tf_paymentNewILC",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 618.423,
      left: 69.8738,
      width: 493.7472,
      height: 17.181
   }
   ,
   {
      page: 10,
      name: "will", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 651.84,
      left: 248.16,
      width: 297.72,
      height: 12.48
   }
   ,
   {
      page: 12,
      name: "Will", 
      isText: false,
      type: "checkbox",
      top: 359.64,
      left: 402.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "Will Not assume the", 
      isText: false,
      type: "checkbox",
      top: 359.64,
      left: 444.84,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "Will_2", 
      isText: false,
      type: "checkbox",
      top: 492.6,
      left: 130.92,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "Will Not assume the debt on the Encumbered Inclusions  252 Encumbered Inclusions", 
      isText: false,
      type: "checkbox",
      top: 492.6,
      left: 173.16,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "All contracts relating to the operation maintenance and management of the Property", 
      isText: false,
      type: "checkbox",
      top: 579.48,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "Property tax bills for the last", 
      isText: false,
      type: "checkbox",
      top: 595.8,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "Asbuilt construction plans to the Property and the tenant improvements including architectural", 
      isText: false,
      type: "checkbox",
      top: 611.88,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "years", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 590.16,
      left: 282,
      width: 75,
      height: 15.60
   }
   ,
   {
      page: 12,
      name: "A list of all Inclusions to be conveyed to Buyer", 
      isText: false,
      type: "checkbox",
      top: 654.6,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "Operating statements for the past", 
      isText: false,
      type: "checkbox",
      top: 670.92,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "A rent roll accurate and correct to the date of this Contract", 
      isText: false,
      type: "checkbox",
      top: 687,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "A schedule of any tenant improvement work Seller is obligated to complete but has not yet completed", 
      isText: false,
      type: "checkbox",
      top: 703.32,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 12,
      name: "years_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 665.28,
      left: 300,
      width: 75,
      height: 15.60
   }
   ,
   {
      page: 12,
      name: "ta_dueDiligenceDocs", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 136.146,
      left: 67.2003,
      width: 507.8197,
      height: 79.600
   }
   ,
   {
      page: 12,
      name: "ta_occupancyAgreements", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 254.838,
      left: 68.073,
      width: 504.329,
      height: 76.109
   }
   ,
   {
      page: 12,
      name: "ta_leasedItemsDocs", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 384.002,
      left: 69.8185,
      width: 503.4565,
      height: 67.382
   }
   ,
   {
      page: 12,
      name: "ta_encumberedInclusionsDocuments", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 504.439,
      left: 71.564,
      width: 501.711,
      height: 46.436
   }
   ,
   {
      page: 13,
      name: "All insurance policies pertaining to the Property and copies of any claims which have been made for", 
      isText: false,
      type: "checkbox",
      top: 51.24,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 13,
      name: "years_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 61.92,
      left: 138.96,
      width: 72.60,
      height: 12.48
   }
   ,
   {
      page: 13,
      name: "Soils reports surveys and engineering reports or data pertaining to the Property if not delivered", 
      isText: false,
      type: "checkbox",
      top: 80.76,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 13,
      name: "Any and all existing documentation and reports regarding Phase I and II environmental reports", 
      isText: false,
      type: "checkbox",
      top: 110.28,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 13,
      name: "Any Americans with Disabilities Act reports studies or surveys concerning the compliance of the", 
      isText: false,
      type: "checkbox",
      top: 179.4,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 13,
      name: "All permits licenses and other building or use authorizations issued by any governmental authority", 
      isText: false,
      type: "checkbox",
      top: 208.92,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 13,
      name: "Other_3", 
      isText: false,
      type: "checkbox",
      top: 251.4,
      left: 149.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 13,
      name: "Seller_4", 
      isText: false,
      type: "checkbox",
      top: 544.68,
      left: 399.24,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 13,
      name: "Buyer will order or", 
      isText: false,
      type: "checkbox",
      top: 544.68,
      left: 447.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 13,
      name: "undefined_10", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 581.76,
      left: 76.8,
      width: 487.56,
      height: 12.48
   }
   ,
   {
      page: 13,
      name: "days Extended", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 661.2,
      left: 368.4,
      width: 127.56,
      height: 12.48
   }
   ,
   {
      page: 13,
      name: "Seller_5", 
      isText: false,
      type: "checkbox",
      top: 690.12,
      left: 473.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 13,
      name: "Buyer_3", 
      isText: false,
      type: "checkbox",
      top: 690.12,
      left: 522.12,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 13,
      name: "ta_10_6_1_4_13_other", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 264.438,
      left: 79.4185,
      width: 482.5105,
      height: 67.382
   }
   ,
   {
      page: 14,
      name: "undefined_11", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 117.84,
      left: 244.56,
      width: 320.04,
      height: 12.48
   }
   ,
   {
      page: 14,
      name: "Does_3", 
      isText: false,
      type: "checkbox",
      top: 189.48,
      left: 464.28,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 14,
      name: "Does Not", 
      isText: false,
      type: "checkbox",
      top: 189.48,
      left: 508.92,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 14,
      name: "There is No Well Buyer", 
      isText: false,
      type: "checkbox",
      top: 215.88,
      left: 203.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 14,
      name: "Does_4", 
      isText: false,
      type: "checkbox",
      top: 215.88,
      left: 327,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 14,
      name: "Does Not acknowledge receipt of a copy of", 
      isText: false,
      type: "checkbox",
      top: 215.88,
      left: 371.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Are", 
      isText: false,
      type: "checkbox",
      top: 88.68,
      left: 413.16,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Are Not executed with", 
      isText: false,
      type: "checkbox",
      top: 88.68,
      left: 453.24,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "undefined_12", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 138.84,
      left: 325.2,
      width: 239.88,
      height: 12.36
   }
   ,
   {
      page: 15,
      name: "special warranty deed", 
      isText: false,
      type: "checkbox",
      top: 247.32,
      left: 63,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "general warranty deed", 
      isText: false,
      type: "checkbox",
      top: 247.32,
      left: 174.12,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "bargain and sale deed", 
      isText: false,
      type: "checkbox",
      top: 247.32,
      left: 286.92,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "quit claim deed", 
      isText: false,
      type: "checkbox",
      top: 247.32,
      left: 397.32,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "deed Seller provided another deed is not selected", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 258,
      left: 226.44,
      width: 140.04,
      height: 12.36
   }
   ,
   {
      page: 15,
      name: "personal representatives deed", 
      isText: false,
      type: "checkbox",
      top: 260.52,
      left: 63,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "must execute and deliver a good and sufficient special warranty deed to Buyer at Closing", 
      isText: false,
      type: "checkbox",
      top: 260.52,
      left: 207.96,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Buyer_4", 
      isText: false,
      type: "checkbox",
      top: 432.6,
      left: 93.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Seller_6", 
      isText: false,
      type: "checkbox",
      top: 432.6,
      left: 151.32,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_2", 
      isText: false,
      type: "checkbox",
      top: 432.6,
      left: 207.24,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Other_4", 
      isText: false,
      type: "checkbox",
      top: 432.6,
      left: 413.88,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "undefined_13", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 430.2,
      left: 457.92,
      width: 99.96,
      height: 12.36
   }
   ,
   {
      page: 15,
      name: "Buyer_5", 
      isText: false,
      type: "checkbox",
      top: 500.28,
      left: 192.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Seller_7", 
      isText: false,
      type: "checkbox",
      top: 500.28,
      left: 255.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_3", 
      isText: false,
      type: "checkbox",
      top: 500.28,
      left: 311.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "NA", 
      isText: false,
      type: "checkbox",
      top: 500.28,
      left: 518.28,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Buyer_6", 
      isText: false,
      type: "checkbox",
      top: 530.76,
      left: 192.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Seller_8", 
      isText: false,
      type: "checkbox",
      top: 530.76,
      left: 255.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_4", 
      isText: false,
      type: "checkbox",
      top: 530.76,
      left: 311.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "NA_2", 
      isText: false,
      type: "checkbox",
      top: 530.76,
      left: 518.28,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Buyer_7", 
      isText: false,
      type: "checkbox",
      top: 582.12,
      left: 192.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Seller_9", 
      isText: false,
      type: "checkbox",
      top: 582.12,
      left: 255.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_5", 
      isText: false,
      type: "checkbox",
      top: 582.12,
      left: 311.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "NA_3", 
      isText: false,
      type: "checkbox",
      top: 582.12,
      left: 518.28,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Buyer_8", 
      isText: false,
      type: "checkbox",
      top: 610.44,
      left: 192.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Seller_10", 
      isText: false,
      type: "checkbox",
      top: 610.44,
      left: 255.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_6", 
      isText: false,
      type: "checkbox",
      top: 610.44,
      left: 311.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "NA_4", 
      isText: false,
      type: "checkbox",
      top: 610.44,
      left: 518.28,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Buyer_9", 
      isText: false,
      type: "checkbox",
      top: 640.92,
      left: 192.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Seller_11", 
      isText: false,
      type: "checkbox",
      top: 640.92,
      left: 255.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_7", 
      isText: false,
      type: "checkbox",
      top: 640.92,
      left: 311.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "NA_5", 
      isText: false,
      type: "checkbox",
      top: 640.92,
      left: 518.28,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Buyer_10", 
      isText: false,
      type: "checkbox",
      top: 671.64,
      left: 192.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Seller_12", 
      isText: false,
      type: "checkbox",
      top: 671.64,
      left: 255.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_8", 
      isText: false,
      type: "checkbox",
      top: 671.64,
      left: 311.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "NA_6", 
      isText: false,
      type: "checkbox",
      top: 671.64,
      left: 518.28,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Buyer_11", 
      isText: false,
      type: "checkbox",
      top: 714.84,
      left: 192.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "Seller_13", 
      isText: false,
      type: "checkbox",
      top: 714.84,
      left: 255.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_9", 
      isText: false,
      type: "checkbox",
      top: 714.84,
      left: 311.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 15,
      name: "NA_7", 
      isText: false,
      type: "checkbox",
      top: 714.84,
      left: 518.28,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "for", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 63.6,
      left: 80.64,
      width: 85.08,
      height: 10.8
   }
   ,
   {
      page: 16,
      name: "Water StockCertificates", 
      isText: false,
      type: "checkbox",
      top: 77.64,
      left: 73.8,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Augmentation Membership", 
      isText: false,
      type: "checkbox",
      top: 90.84,
      left: 73.8,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Water District", 
      isText: false,
      type: "checkbox",
      top: 77.64,
      left: 237.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Small Domestic Water Company", 
      isText: false,
      type: "checkbox",
      top: 90.84,
      left: 237.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Buyer_12", 
      isText: false,
      type: "checkbox",
      top: 117.24,
      left: 192.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Seller_14", 
      isText: false,
      type: "checkbox",
      top: 117.24,
      left: 255.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "OneHalf by Buyer and OneHalf by Seller_10", 
      isText: false,
      type: "checkbox",
      top: 117.24,
      left: 311.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "NA_8", 
      isText: false,
      type: "checkbox",
      top: 117.24,
      left: 518.28,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Buyer_13", 
      isText: false,
      type: "checkbox",
      top: 146.76,
      left: 192.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Seller_15", 
      isText: false,
      type: "checkbox",
      top: 146.76,
      left: 255.72,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "OneHalf by Buyer and OneHalf by Seller_11", 
      isText: false,
      type: "checkbox",
      top: 146.76,
      left: 311.64,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "NA_9", 
      isText: false,
      type: "checkbox",
      top: 146.76,
      left: 518.28,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "IS a foreign person for purposes of US income taxation If the box in this Section is not checked Seller", 
      isText: false,
      type: "checkbox",
      top: 212.04,
      left: 108.12,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Taxes for the Calendar Year Immediately Preceding Closing", 
      isText: false,
      type: "checkbox",
      top: 388.44,
      left: 77.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Most Recent Mill Levy and Most Recent Assessed Valuation", 
      isText: false,
      type: "checkbox",
      top: 401.64,
      left: 77.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Other_5", 
      isText: false,
      type: "checkbox",
      top: 414.84,
      left: 77.4,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "undefined_14", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 414,
      left: 121.44,
      width: 429.96,
      height: 10.8
   }
   ,
   {
      page: 16,
      name: "Rents Actually Received", 
      isText: false,
      type: "checkbox",
      top: 430.92,
      left: 207.24,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Accrued At Closing Seller will transfer or credit to", 
      isText: false,
      type: "checkbox",
      top: 430.92,
      left: 335.16,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Buyer_14", 
      isText: false,
      type: "checkbox",
      top: 588.36,
      left: 477,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "Seller_16", 
      isText: false,
      type: "checkbox",
      top: 588.36,
      left: 527.16,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 16,
      name: "per day or any", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 705.6,
      left: 439.44,
      width: 62.76,
      height: 10.8
   }
   ,
   {
      page: 16,
      name: "ta_otherProrations", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 480.875,
      left: 55.8548,
      width: 512.1832,
      height: 44.691
   }
   ,
   {
      page: 16,
      name: "tf_assocAssessments",
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 643.203,
      left: 73.3094,
      width: 488.6196,
      height: 14.145
   }
   
   ,
   {
      page: 17,
      name: "Buyer will be paid to Seller and retained by Seller It is agreed that the Earnest Money is not a penalty and the parties", 
      isText: false,
      type: "checkbox",
      top: 703.8,
      left: 109.8,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 19,
      name: "undefined_15", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 159.84,
      left: 73.2,
      width: 450.0,
      height: 10.68
   }
   ,
   {
      page: 19,
      name: "ta_additionalProvisions", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 405.82,
      left: 38.4002,
      width: 532.2558,
      height: 346.656
   }
   ,
   {
      page: 20,
      name: "Buyers Name", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 328.32,
      left: 108.24,
      width: 165.00,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "Buyers Name_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 328.32,
      left: 380.28,
      width: 164.88,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "undefined_16", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 403.08,
      left: 129.12,
      width: 153.72,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "undefined_17", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 403.08,
      left: 399.12,
      width: 153.00,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "Phone No 1", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 426.12,
      left: 129.12,
      width: 153.72,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "Phone No 2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 449.16,
      left: 129.12,
      width: 153.72,
      height: 22.20
   }
   ,
   {
      page: 20,
      name: "Phone No 1_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 426.24,
      left: 399.12,
      width: 153.00,
      height: 22.32
   }
   ,
   {
      page: 20,
      name: "Phone No 2_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 449.16,
      left: 399.12,
      width: 153.00,
      height: 22.20
   }
   ,
   {
      page: 20,
      name: "Email Address", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 471.96,
      left: 128.4,
      width: 154.44,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "Email Address_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 471.96,
      left: 398.4,
      width: 153.72,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "Sellers Name", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 530.88,
      left: 107.04,
      width: 165.00,
      height: 16.68
   }
   ,
   {
      page: 20,
      name: "Sellers Name_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 525.12,
      left: 383.28,
      width: 160.80,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "undefined_18", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 599.64,
      left: 129.12,
      width: 153.72,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "undefined_19", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 599.64,
      left: 399.12,
      width: 153.00,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "Phone No 1_3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 622.68,
      left: 129.12,
      width: 153.72,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "Phone No 2_3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 645.72,
      left: 129.12,
      width: 153.72,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "Phone No 1_4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 622.8,
      left: 399.12,
      width: 153.00,
      height: 22.32
   }
   ,
   {
      page: 20,
      name: "Phone No 2_4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 645.72,
      left: 399.12,
      width: 153.00,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "Email Address_3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 668.76,
      left: 128.4,
      width: 154.44,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "Email Address_4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 668.76,
      left: 398.4,
      width: 153.72,
      height: 22.44
   }
   ,
   {
      page: 20,
      name: "ta_docsPartOfContract", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 76.8,
      left: 59.3457,
      width: 499.0923,
      height: 114.51
   }
   ,
   {
      page: 20,
      name: "ta_docsNotPartContract", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 216.437,
      left: 59.3457,
      width: 498.2193,
      height: 75.237
   }
   ,
   {
      page: 21,
      name: "Does_5", 
      isText: false,
      type: "checkbox",
      top: 120.6,
      left: 83.88,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the", 
      isText: false,
      type: "checkbox",
      top: 120.6,
      left: 128.52,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "Buyers Agent", 
      isText: false,
      type: "checkbox",
      top: 184.2,
      left: 193.8,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "TransactionBroker in this transaction", 
      isText: false,
      type: "checkbox",
      top: 184.2,
      left: 279.24,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
      isText: false,
      type: "checkbox",
      top: 201.72,
      left: 48.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "Listing Brokerage Firm", 
      isText: false,
      type: "checkbox",
      top: 237.96,
      left: 84.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "Buyer_15", 
      isText: false,
      type: "checkbox",
      top: 237.96,
      left: 210.36,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "Other_6", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 234.695,
      left: 292.8,
      width: 264.96,
      height: 14.225
   }
   ,
   {
      page: 21,
      name: "Brokerage Firms Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 319.2,
      left: 174,
      width: 378.12,
      height: 14.16
   }
   ,
   {
      page: 21,
      name: "Brokerage Firms License 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 334.08,
      left: 174,
      width: 378.12,
      height: 14.16
   }
   ,
   {
      page: 21,
      name: "Brokerage Firms License 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 349.2,
      left: 174,
      width: 378.12,
      height: 14.16
   }
   ,
   {
      page: 21,
      name: "Brokerage Firms License 3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 364.08,
      left: 174,
      width: 378.12,
      height: 14.16
   }
   ,
   {
      page: 21,
      name: "Date 1", 
      fontSize: 9,
      type: "date",
      top: 423.12,
      left: 174,
      width: 378.12,
      height: 14.16
   }
   ,
   {
      page: 21,
      name: "Date 2", 
      fontSize: 9,
      type: "date",
      top: 438.24,
      left: 174,
      width: 378.12,
      height: 14.16
   }
   ,
   {
      page: 21,
      name: "Date 3", 
      fontSize: 9,
      type: "date",
      top: 453.12,
      left: 174,
      width: 378.12,
      height: 14.16
   }
   ,
   {
      page: 21,
      name: "Date 4", 
      fontSize: 9,
      type: "date",
      top: 468.24,
      left: 173.28,
      width: 378.84,
      height: 14.16
   }
   ,
   {
      page: 21,
      name: "Does_6", 
      isText: false,
      type: "checkbox",
      top: 539.64,
      left: 87.48,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 21,
      name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm", 
      isText: false,
      type: "checkbox",
      top: 539.64,
      left: 136.44,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 21,
      name: "Sellers Agent", 
      isText: false,
      type: "checkbox",
      top: 621.72,
      left: 192.84,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "TransactionBroker in this transaction_2", 
      isText: false,
      type: "checkbox",
      top: 621.72,
      left: 276.36,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "Customer Broker has no brokerage relationship with Seller See A for Brokers brokerage relationship with Buyer", 
      isText: false,
      type: "checkbox",
      top: 644.76,
      left: 48.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "Seller_17", 
      isText: false,
      type: "checkbox",
      top: 669.48,
      left: 84.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "Buyer_16", 
      isText: false,
      type: "checkbox",
      top: 669.48,
      left: 133.08,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "Other_7", 
      isText: false,
      type: "checkbox",
      top: 669.48,
      left: 183.24,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 21,
      name: "undefined_20", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 666.96,
      left: 227.04,
      width: 335.04,
      height: 12.48
   }
   ,
   {
      page: 21,
      name: "signature_brokerBuyer", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 386.62,
      left: 173.673,
      width: 150.000,
      height: 22.00
   }
   ,
   {
      page: 21,
      name: "date_brokerBuyerSign", 
      fontSize: 9,
      type: "date",
      top: 385.874,
      left: 399.711,
      width: 150.000,
      height: 22.000
   }
   ,
   {
      page: 22,
      name: "Brokerage Firms Name_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 50.76,
      left: 174,
      width: 378.12,
      height: 14.28
   }
   ,
   {
      page: 22,
      name: "Brokerage Firms License 1_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 65.88,
      left: 174,
      width: 378.12,
      height: 14.28
   }
   ,
   {
      page: 22,
      name: "Brokerage Firms License 2_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 80.76,
      left: 174,
      width: 378.12,
      height: 14.28
   }
   ,
   {
      page: 22,
      name: "Brokerage Firms License 3_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 95.88,
      left: 174,
      width: 378.12,
      height: 14.28
   }
   ,
   {
      page: 22,
      name: "Date 1_2", 
      fontSize: 9,
      type: "date",
      top: 154.92,
      left: 174,
      width: 378.12,
      height: 14.28
   }
   ,
   {
      page: 22,
      name: "Date 2_2", 
      fontSize: 9,
      type: "date",
      top: 169.8,
      left: 174,
      width: 378.12,
      height: 14.28
   }
   ,
   {
      page: 22,
      name: "Date 3_2", 
      fontSize: 9,
      type: "date",
      top: 184.92,
      left: 174,
      width: 378.12,
      height: 14.28
   }
   ,
   {
      page: 22,
      name: "Date 4_2", 
      fontSize: 9,
      type: "date",
      top: 199.8,
      left: 173.28,
      width: 378.84,
      height: 14.28
   }
   
   ] }