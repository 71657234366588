import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Confirm, Dropdown } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import {
  addItemToTaskTemplate,
  deleteItemInTaskTemplate,
} from "./taskTemplatesSlice";

export default function TaskTemplatesActionButtons({
  item,
  setInitialValues,
  setItemModal,
}) {
  const dispatch = useDispatch();
  const { taskTemplate } = useSelector((state) => state.taskTemplates);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleEdit() {
    setInitialValues(item);
    const itemId = taskTemplate.items.findIndex((object) => {
      return object === item;
    });
    setItemModal({ open: true, new: false, editingId: itemId });
  }

  function handleCopy() {
    dispatch(addItemToTaskTemplate(item));
  }

  function handleDelete() {
    const itemId = taskTemplate.items.findIndex((object) => {
      return object === item;
    });
    dispatch(deleteItemInTaskTemplate(itemId));
    setConfirmOpen(false);
  }

  return (
    <div className={isMobile ? null : "text align right"}>
      <Dropdown
        button
        icon="chevron down"
        text={isMobile ? "Actions " : null}
        className={isMobile ? "button icon" : "button mini icon"}
        direction={isMobile ? "right" : "left"}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleEdit()}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => handleCopy()}>Copy</Dropdown.Item>
          <Dropdown.Item onClick={() => setConfirmOpen(true)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleDelete()}
      ></Confirm>
    </div>
  );
}
