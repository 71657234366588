export function residentialListingExclusiveRighttoLease() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 87.84,
   left: 67.68,
   width: 200.16,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 112", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 87.84,
   left: 334.08,
   width: 200.16,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 113", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 98.64,
   left: 58.68,
   width: 95.40,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 114", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 98.64,
   left: 183.6,
   width: 95.4,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 115", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 98.64,
   left: 293.76,
   width: 18.00,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 116", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 140.32,
   left: 102.6,
   width: 477.9,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 117", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 160.84,
   left: 106.38,
   width: 474.12,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 118", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 184.0,
   left: 243.36,
   width: 86.04,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 119", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 248.14,
   left: 383.76,
   width: 48.60,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 120", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 300.6,
   left: 199.8,
   width: 45.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 121", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 433.08,
   left: 211.5,
   width: 45.0,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 122", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 487.8,
   left: 402.48,
   width: 52.02,
   height: 13.5
}
,
{
   page: 0,
   name: "Check Box 13", 
   isText: false,
   type: "checkbox",
   top: 679.68,
   left: 107.28,
   width: 8.28,
   height: 8.25
}
,
{
   page: 0,
   name: "Check Box 14", 
   isText: false,
   type: "checkbox",
   top: 679.68,
   left: 138.96,
   width: 8.28,
   height: 8.25
}
,
{
   page: 0,
   name: "Text Field 3012", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 724.389,
   left: 110.642,
   width: 32.292,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 3013", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 724.389,
   left: 226.712,
   width: 32.292,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 3014", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 724.389,
   left: 110.642,
   width: 32.292,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 3015", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 724.389,
   left: 226.712,
   width: 32.292,
   height: 13.500
}
,
{
   page: 2,
   name: "Text Field 148", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 649.88,
   left: 100.08,
   width: 480.42,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 149", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 670.76,
   left: 102.08,
   width: 207.52,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 150", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 670.76,
   left: 372.98,
   width: 207.52,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 152", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 691.64,
   left: 47.54,
   width: 207.52,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 151", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 691.64,
   left: 286.56,
   width: 293.94,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 3016", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 724.389,
   left: 110.642,
   width: 32.292,
   height: 13.500
}
,
{
   page: 2,
   name: "Text Field 3017", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 724.389,
   left: 226.712,
   width: 32.292,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 157", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 26.48,
   left: 117.0,
   width: 204.84,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 158", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 26.48,
   left: 360.72,
   width: 219.78,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 160", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 47.36,
   left: 102.08,
   width: 203.92,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 159", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 47.36,
   left: 345.6,
   width: 234.9,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 136", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 109.0,
   left: 102.08,
   width: 478.42,
   height: 13.5
}
,
{
   page: 3,
   name: "Text Field 139", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 129.88,
   left: 61.92,
   width: 129.61,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 138", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 129.88,
   left: 211.68,
   width: 129.61,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 137", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 129.88,
   left: 375.66,
   width: 204.84,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 140", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.52,
   left: 54.0,
   width: 46.08,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 141", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.52,
   left: 129.6,
   width: 174.4,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 142", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.52,
   left: 363.6,
   width: 43.2,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 147", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.52,
   left: 437.76,
   width: 142.74,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 143", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 211.4,
   left: 31.5,
   width: 272.5,
   height: 13.5
}
,
{
   page: 3,
   name: "Text Field 145", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 211.4,
   left: 341.29,
   width: 239.21,
   height: 13.5
}
,
{
   page: 3,
   name: "Text Field 144", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 231.28,
   left: 31.5,
   width: 272.5,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 146", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 231.28,
   left: 341.29,
   width: 239.21,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 3018", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 724.389,
   left: 110.642,
   width: 32.292,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 3019", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 724.389,
   left: 226.712,
   width: 32.292,
   height: 13.500
}
] }
