import React from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Header, Segment } from "semantic-ui-react";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import {
  convertAddressFull,
  partyIsAgent,
} from "../../../app/common/util/util";
import { convertFullName } from "../../../app/common/util/util";

export default function PersonView({ person }) {
  const dispatch = useDispatch();
  return (
    <ModalWrapper>
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Header size="large" color="blue">
            {convertFullName(person)}
          </Header>
          <Divider />
          <Header color="blue">Role</Header>
          <p>{person.type}</p>
          {(person.email || person.phone) && (
            <Header color="blue">Contact Info</Header>
          )}
          {person.email && <p>Email: {person.email}</p>}
          {person.phone && <p>Phone: {person.phone}</p>}

          {(person.isTrust || person.entityName) && (
            <>
              <Header color="blue">Acting As</Header>
              {person.isTrust && <p>Is Trust: Yes</p>}
              {person.entityName && <p>Entity Name: {person.entityName}</p>}
            </>
          )}
          {person.companyName && (
            <>
              <Header color="blue">Company Name</Header>
              <p>{person.companyName}</p>
            </>
          )}
          {(person.address?.street ||
            person.address?.city ||
            person.address?.state) && (
            <>
              <Header color="blue">Address</Header>
              <p>{convertAddressFull(person.address)}</p>
            </>
          )}

          {partyIsAgent(person.type) &&
            (person.brokerLicenseNumber ||
              person.brokerageName ||
              person.brokerageId ||
              person.brokerageLicenseNumber) && (
              <>
                <Header color="blue">Agent Info</Header>
                {person.brokerLicenseNumber && (
                  <p>Agent License Number: {person.brokerLicenseNumber}</p>
                )}
                {person.brokerageName && (
                  <p>Brokerage Name: {person.brokerageName}</p>
                )}
                {person.brokerageId && (
                  <p>Brokerage ID: {person.brokerageId}</p>
                )}
                {person.brokerageLicenseNumber && (
                  <p>
                    Brokerage License Number: {person.brokerageLicenseNumber}
                  </p>
                )}
              </>
            )}
          {person.hasAssistant &&
            (person.assistant?.firstName ||
              person.assistant?.lastName ||
              person.assistant?.email ||
              person.assistant?.phone) && (
              <>
                <Header color="blue">Assistant Info</Header>
                {(person.assistant?.firstName ||
                  person.assistant?.lastName) && (
                  <p>Name: {convertFullName(person.assistant)}</p>
                )}
                {person.assistant?.email && (
                  <p>Email: {person.assistant?.email}</p>
                )}
                {person.assistant?.phone && (
                  <p>Phone: {person.assistant?.phone}</p>
                )}
              </>
            )}
          <Divider />
          <Button
            primary
            onClick={() =>
              dispatch(
                closeModal({
                  modalType: "PersonView",
                })
              )
            }
            to="#"
            type="button"
            floated="right"
            content="Close"
          />
        </div>
      </Segment>
    </ModalWrapper>
  );
}
