import React, { useCallback, useState } from "react";
import { Grid, Header, Icon, Message } from "semantic-ui-react";
import { addUploadedDocToTransaction } from "../../../../app/firestore/firebaseService";
import { updateTransUpdatedInDb } from "../../../../app/firestore/firestoreService";
import { useDropzone } from "react-dropzone";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../app/common/modals/modalSlice";

export default function DocFileUpload({ setOpenDocUploadModal, status }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const { transaction } = useSelector((state) => state.transaction);

  const dropzoneStyles = {
    textAlign: "center",
    cursor: "pointer",
    paddingTop: "36px",
    paddingBottom: "36px",
  };

  const dropzoneActive = {
    border: "dashed 3px #eee",
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const firstFile = acceptedFiles?.[0];
      if (!firstFile) {
        return;
      }
      setLoading(true);
      setError(null);
      setMessage(null);
      await Promise.all(
        acceptedFiles.map(async (file) => {
          await addUploadedDocToTransaction(
            URL.createObjectURL(file),
            file.name,
            file.type,
            file.size,
            transaction
          );
          return updateTransUpdatedInDb(transaction.id);
        })
      )
        .then(() => {
          console.log("All images uploaded");
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);
        });
      setLoading(false);
      if (setOpenDocUploadModal) {
        setOpenDocUploadModal(false);
      }
      dispatch(
        closeModal({
          modalType: "DocAddDocumentsModal",
        })
      );
    },
    [dispatch, setOpenDocUploadModal, transaction]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid>
      <Grid.Column width={16}>
        <div
          {...getRootProps()}
          style={
            isDragActive
              ? { ...dropzoneStyles, ...dropzoneActive }
              : dropzoneStyles
          }
        >
          <input {...getInputProps()} />
          <Icon name="computer" size="huge" color="grey" />
          <Header color="grey" style={{ marginTop: "6px" }}>
            Drag and drop files here
            <br />
            or click to select a file
          </Header>
        </div>
        {loading ? <LoadingComponent /> : ""}
        {(error || message) && (
          <Message
            negative
            status={error ? "error" : "success"}
            w={250}
            borderRadius={5}
            m={2}
          >
            {/* <AlertIcon /> */}
            <Message.Header w={200}>{error || message}</Message.Header>
          </Message>
        )}
      </Grid.Column>
    </Grid>
  );
}
