import { Form, Formik } from "formik";
import React from "react";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { updateUserProfileInDb } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import FormAddress from "../../app/common/form/FormAddress";
import { useMediaQuery } from "react-responsive";
import MyTextArea from "../../app/common/form/MyTextArea";

export default function ProfilePage() {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  let initialValues = currentUserProfile;

  const validationSchema = Yup.object({
    lastName: Yup.string().required("You must provide a last name"),
  });

  return (
    <div className="main-page-wrapper">
      <Grid centered stackable className="small bottom margin">
        <Grid.Column width={10}>
          <Header color="blue" as="h2">
            My Profile
          </Header>
          {/* {currentUserProfile.isAdmin && (
            <Segment>
              <h3 color="red">Admin Buttons</h3>
              <Button color="red" onClick={() => checkFontSizeGtHeight()}>
                Check Font Size GT Height
              </Button>
            </Segment>
          )} */}
          <Segment clearing>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  await updateUserProfileInDb(values);
                  setSubmitting(false);
                  toast.success("Profile successfully updated");
                } catch (error) {
                  toast.error(error.message);
                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting, dirty, isValid }) => (
                <Form className="ui form medium margin bottom">
                  <Header color="blue">Name & Contact Info</Header>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={16} computer={5}>
                        <MyTextInput
                          name={"firstName"}
                          placeholder={"First name"}
                          data-test="firstName"
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} computer={5}>
                        <MyTextInput
                          name={"middleName"}
                          placeholder={"Middle name"}
                          data-test="middleName"
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} computer={5}>
                        <MyTextInput
                          name={"lastName"}
                          placeholder={"Last name"}
                          data-test="lastName"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="zero top padding">
                      <Grid.Column mobile={16} computer={5}>
                        <MyTextInput
                          name={"phone"}
                          placeholder="Phone"
                          data-test="phone"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider />
                  <Header color="blue">Brokerage Address</Header>
                  <FormAddress />
                  <br />
                  <Divider />
                  <Header color="blue">Agent/Broker Details</Header>
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <MyTextInput
                          name="brokerLicenseNumber"
                          label="Agent License Number"
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <MyTextInput
                          name="brokerNrdsId"
                          label="Agent NRDS ID"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="zero top padding">
                      <Grid.Column width={5}>
                        <MyTextInput
                          name="brokerageName"
                          label="Brokerage Name"
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <MyTextInput name="brokerageId" label="Brokerage ID" />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <MyTextInput
                          name="brokerageLicenseNumber"
                          label="Brokerage License Number"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider />
                  <Header color="blue">Email Signature Field</Header>
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column width={9}>
                        <MyTextArea
                          name="emailSigField"
                          label="Email Sig Field"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <br />
                  <br />
                  <Button
                    loading={isSubmitting}
                    disabled={!dirty || isSubmitting}
                    type="submit"
                    floated={isMobile ? "left" : "right"}
                    primary
                    content="Submit"
                    className={isMobile ? "fluid large" : "large"}
                  />
                </Form>
              )}
            </Formik>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}
