import React from "react";
import { FormFieldCheckboxDisplay } from "./FormFieldCheckboxDisplay";
import { FormFieldDateDisplay } from "./FormFieldDateDisplay";
import { FormFieldSignatureDisplay } from "./FormFieldSignatureDisplay";
import { FormFieldTextareaDisplay } from "./FormFieldTextareaDisplay";
import { FormFieldTextDisplay } from "./FormFieldTextDisplay";
import { FormFieldLogoDisplay } from "./FormFieldLogoDisplay";

export default function FormFieldSelector({ formField, pageScale }) {
  if (formField.type === "text" && formField.value !== "") {
    return <FormFieldTextDisplay formField={formField} pageScale={pageScale} />;
  }
  if (formField.type === "textarea") {
    return (
      <FormFieldTextareaDisplay formField={formField} pageScale={pageScale} />
    );
  }
  if (formField.type === "date") {
    return <FormFieldDateDisplay formField={formField} pageScale={pageScale} />;
  }
  if (
    formField.type === "checkbox" &&
    (formField.value === true || formField.value === "X")
  ) {
    return (
      <FormFieldCheckboxDisplay formField={formField} pageScale={pageScale} />
    );
  }
  if (formField.type === "signature" || formField.type === "initials") {
    return (
      <FormFieldSignatureDisplay formField={formField} pageScale={pageScale} />
    );
  }
  if (formField.type === "logo") {
    return <FormFieldLogoDisplay formField={formField} pageScale={pageScale} />;
  }
  return null;
}
