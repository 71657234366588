import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  annots: [],
  agentAnnots: [],
  annotsToSign: [],
  nextAnnotToSign: {},
  selectedSigner: {},
  pageScalePrepare: 1.25,
  pageScaleFill: 1.5,
  pageScaleSign: 1.25,
  pageScaleView: 1.5,
  editMode: "signer",
  activeAnnotType: "signature",
  selectedAnnot: {},
  signerListPossible: [],
  signerListDisplay: [],
  annotsSigningStatus: {},
  autoNextAnnot: true,
};

const annotSlice = createSlice({
  name: "annot",
  initialState,
  reducers: {
    fetchAnnots(state, action) {
      state.annots = action.payload;
      state.agentAnnots = action.payload.filter(
        (annot) => annot.agentsField === true
      );
    },
    fetchAnnotsToSign(state, action) {
      state.annotsToSign = action.payload;
    },
    findNextAnnotToSign(state, action) {
      state.nextAnnotToSign = action.payload;
    },
    addAnnot(state, action) {
      state.annots = [...state.annots, action.payload];
    },
    updateAnnot(state, action) {
      let updatedAnnot = state.annots.filter(
        (annot) => annot.uniqueId === action.payload.annot.uniqueId
      )[0];
      Object.keys(action.payload.newFields).forEach((key) => {
        updatedAnnot[key] = action.payload.newFields[key];
      });
      state.annots = [
        ...state.annots.filter(
          (annot) => annot.uniqueId !== action.payload.annot.uniqueId
        ),
        updatedAnnot,
      ];
      state.selectedAnnot = updatedAnnot;
    },
    deleteAnnot(state, action) {
      state.annots = [
        ...state.annots.filter(
          (annot) => annot.uniqueId !== action.payload.uniqueId
        ),
      ];
    },
    clearAnnots(state, action) {
      state.annots = [];
    },
    selectDocAnnot(state, action) {
      state.selectedAnnot = action.payload;
    },
    setAnnotsSigningStatus(state, action) {
      state.annotsSigningStatus = action.payload;
    },
    clearSelectedAnnot(state, action) {
      state.selectedAnnot = {};
    },
    changeSelectedSigner(state, action) {
      state.selectedSigner = action.payload;
    },
    changePageScalePrepare(state, action) {
      state.pageScalePrepare = action.payload;
    },
    changePageScaleFill(state, action) {
      state.pageScaleFill = action.payload;
    },
    changePageScaleSign(state, action) {
      state.pageScaleSign = action.payload;
    },
    changePageScaleView(state, action) {
      state.pageScaleView = action.payload;
    },
    changeEditMode(state, action) {
      state.editMode = action.payload;
    },
    changeActiveAnnotType(state, action) {
      state.activeAnnotType = action.payload;
    },
    setSignerListPossible(state, action) {
      state.signerListPossible = action.payload;
    },
    setSignerListDisplay(state, action) {
      state.signerListDisplay = action.payload;
    },
    addToSignerList(state, action) {
      action.payload.signer["index"] = action.payload.index;
      state.signerListDisplay = _.sortBy(
        [...state.signerListDisplay, action.payload.signer],
        "index"
      );
    },
    removeFromSignerList(state, action) {
      state.signerListDisplay = state.signerListDisplay.filter(
        (signer) => signer.role !== action.payload.role
      );
    },
    removeAnnotsFromSigner(state, action) {
      state.annots = state.annots.filter(
        (annot) => annot.signerRole !== action.payload.role
      );
    },
    autoGoToNextAnnot(state, action) {
      state.autoNextAnnot = action.payload;
    },
  },
});

export const {
  fetchAnnots,
  fetchAnnotsToSign,
  addAnnot,
  updateAnnot,
  clearAnnots,
  clearSelectedAnnot,
  deleteAnnot,
  changeSelectedSigner,
  changePageScalePrepare,
  changePageScaleFill,
  changePageScaleSign,
  changePageScaleView,
  changeEditMode,
  changeActiveAnnotType,
  selectDocAnnot,
  setAnnotsSigningStatus,
  setSignerListPossible,
  setSignerListDisplay,
  addToSignerList,
  removeFromSignerList,
  removeAnnotsFromSigner,
  findNextAnnotToSign,
  autoGoToNextAnnot,
} = annotSlice.actions;

export default annotSlice.reducer;
