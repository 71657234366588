import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import { sortTransActiveBuyer } from "../../transactionSlice";
import TransactionActiveBuyerListItem from "./TransactionActiveBuyerListItem";

export default function TransactionActiveBuyerList({
  transactions,
  column,
  direction,
}) {
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);

  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell></Table.HeaderCell>
            {currentUserProfile?.role !== "agent" && (
              <>
                <Table.HeaderCell
                sorted={column === "agnetFirstName" ? direction : null}
                onClick={() => dispatch(sortTransActiveBuyer("agentFirstName"))}
                >
                  Agent First Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "agentLastName" ? direction : null}
                  onClick={() => dispatch(sortTransActiveBuyer("agentLastName"))}
                >
                  Agent Last Name
                </Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell
              sorted={column === "title" ? direction : null}
              onClick={() => dispatch(sortTransActiveBuyer("title"))}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "client.firstName" ? direction : null}
              onClick={() => dispatch(sortTransActiveBuyer("client.firstName"))}
            >
              Client&nbsp;&nbsp;(Primary)
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "clientSecondary.firstName" ? direction : null}
              onClick={() =>
                dispatch(sortTransActiveBuyer("clientSecondary.firstName"))
              }
            >
              Client&nbsp;&nbsp;(Secondary)
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "createdAt" ? direction : null}
              onClick={() => dispatch(sortTransActiveBuyer("createdAt"))}
            >
              Active For
            </Table.HeaderCell>

            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {transactions &&
            transactions.length !== 0 &&
            transactions.map((transaction) => (
              <TransactionActiveBuyerListItem
                transaction={transaction}
                key={transaction.id}
              />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
