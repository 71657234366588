import { Formik, Form } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  Header,
  Segment,
  Label,
} from "semantic-ui-react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import {
  addEmailTemplateToDb,
  updateEmailTemplateInDb,
} from "../../../app/firestore/firestoreService";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";

export default function EmailTemplatesEdit({ emailTemplate }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  const allPartyRoles = [
    "Buyer",
    "Buyer 2",
    "Seller",
    "Seller 2",
    "Listing Agent",
    "Buyer Agent",
    "TC (Mine)",
    "CoAgent (Mine)",
    "Buyer Lender",
    "Escrow Officer",
    "Title Company",
    "Me (Agent)",
  ];

  let initialValues = emailTemplate
    ? emailTemplate
    : {
        name: "",
        title: "",
        subject: "",
        from: "",
        message: "",
      };

  const [toRoles, setToRoles] = useState(emailTemplate ? emailTemplate.to : []);
  const [ccRoles, setCcRoles] = useState(emailTemplate ? emailTemplate.cc : []);
  const [bccRoles, setBccRoles] = useState(
    emailTemplate ? emailTemplate.bcc : []
  );

  function handleToClick(role) {
    if (toRoles.includes(role)) {
      setToRoles(toRoles.filter((toRole) => toRole !== role));
    } else {
      setToRoles([...toRoles, role]);
    }
  }

  function handleCcClick(role) {
    if (ccRoles.includes(role)) {
      setCcRoles(ccRoles.filter((ccRole) => ccRole !== role));
    } else {
      setCcRoles([...ccRoles, role]);
    }
  }

  function handleBccClick(role) {
    if (bccRoles.includes(role)) {
      setBccRoles(bccRoles.filter((bccRole) => bccRole !== role));
    } else {
      setBccRoles([...bccRoles, role]);
    }
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("You must provide a name"),
  });

  return (
    <ModalWrapper size="fullscreen">
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                emailTemplate
                  ? await updateEmailTemplateInDb(
                      values.id,
                      values,
                      toRoles,
                      ccRoles,
                      bccRoles
                    )
                  : await addEmailTemplateToDb(
                      values,
                      toRoles,
                      ccRoles,
                      bccRoles
                    );
                setSubmitting(false);
                toast.success("Email template successfully updated");
                dispatch(
                  closeModal({
                    modalType: "EmailTemplatesEdit",
                  })
                );
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid, values }) => (
              <Form className="ui form" autoComplete="off">
                <Header size="large" color="blue">
                  {emailTemplate
                    ? "Edit Email Template"
                    : "Create Email Template"}
                </Header>
                <Divider />
                <Grid>
                  <Grid.Row className="small vertical padding">
                    <Grid.Column width={16}>
                      <Header
                        as="h5"
                        color="blue"
                        className="mini bottom margin"
                      >
                        Template Name
                      </Header>
                      <MyTextInput
                        name="name"
                        className="small bottom margin"
                      />
                    </Grid.Column>
                    <Grid.Column width={16} className="small bottom margin">
                      <Header
                        as="h5"
                        color="blue"
                        className="small bottom margin"
                      >
                        Recipients
                      </Header>
                      <div className="mini bottom margin">
                        <b>To:</b>{" "}
                        {allPartyRoles.map((role) => (
                          <Label
                            key={role}
                            style={{
                              cursor: "pointer",
                              marginBottom: "2px",
                            }}
                            color={toRoles.includes(role) ? "blue" : null}
                            onClick={() => handleToClick(role)}
                          >
                            {role}
                          </Label>
                        ))}
                      </div>
                      <div className="mini bottom margin">
                        <b>cc:</b>{" "}
                        {allPartyRoles.map((role) => (
                          <Label
                            key={role}
                            style={{
                              cursor: "pointer",
                              marginBottom: "2px",
                            }}
                            color={ccRoles.includes(role) ? "blue" : null}
                            onClick={() => handleCcClick(role)}
                          >
                            {role}
                          </Label>
                        ))}
                      </div>
                      <div className="mini bottom margin">
                        <b>bcc:</b>{" "}
                        {allPartyRoles.map((role) => (
                          <Label
                            key={role}
                            style={{
                              cursor: "pointer",
                              marginBottom: "2px",
                            }}
                            color={bccRoles.includes(role) ? "blue" : null}
                            onClick={() => handleBccClick(role)}
                          >
                            {role}
                          </Label>
                        ))}
                      </div>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Header
                        as="h5"
                        color="blue"
                        className="mini bottom margin"
                      >
                        Subject
                      </Header>
                      <MyTextInput
                        name="subject"
                        className="small bottom margin"
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Header
                        as="h5"
                        color="blue"
                        className="mini bottom margin"
                      >
                        Message
                      </Header>
                      <MyTextArea
                        name="message"
                        className="small bottom margin"
                        rows="12"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Divider className="medium top margin" />
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  floated={isMobile ? null : "right"}
                  primary
                  content="Submit"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "emailTemplatesEdit",
                      })
                    )
                  }
                  to="#"
                  type="button"
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </ModalWrapper>
  );
}
