import React from "react";
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";
import LoginForm from "./LoginForm";

export default function LoginPage() {
  return (
    <>
      <div className="background-lightblue-gradiant">
        <Container style={{ height: "100vh" }}>
          <Grid>
            <Grid.Row>
              <div className="medium top margin" style={{ width: "100%" }}>
                <Image
                  src="/assets/logo-small.png"
                  style={{ float: "left", width: "240px" }}
                />
              </div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                className="large top margin"
                mobile={16}
                tablet={16}
                computer={7}
              >
                <Segment style={{ marginTop: "40px" }}>
                  <Image
                    centered
                    src="/assets/logo-transactioner-color-small.png"
                    width="110"
                    alt="laptop contract"
                    style={{ marginTop: "16px" }}
                  />
                  <Header
                    color="blue"
                    as="h1"
                    textAlign="center"
                    className="text-big small top margin"
                  >
                    Welcome Back
                  </Header>
                  <Divider className="medium top margin large bottom margin" />
                  <LoginForm />
                </Segment>
              </Grid.Column>
              <Grid.Column computer={9} className="mobile hidden tablet hidden">
                <Image
                  float="right"
                  src="/assets/img-laptop-login.png"
                  fluid
                  alt="laptop contract"
                  style={{ maxWidth: "600px", marginLeft: "100px" }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </>
  );
}
