export function earnestMoneyReceipt() {
return [
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date", 
   top: 153.84,
   left: 398.52,
   width: 174.00,
   height: 16.44
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "to be held by Earnest Money Holder in its trust account, on behalf of both Seller and Buyer", 
   top: 176.88,
   left: 447.6,
   width: 68.64,
   height: 16.44
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "pursuant to the Contract to Buy and Sell Real Estate described below  Until Closing Instructions have been signed by Buyer, Seller", 
   top: 193.022,
   left: 58.56,
   width: 132.12,
   height: 13.336
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "1", 
   top: 264.60,
   left: 180.84,
   width: 371.88,
   height: 16.44
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "2", 
   top: 282.60,
   left: 180.84,
   width: 371.88,
   height: 16.44
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "3", 
   top: 300.60,
   left: 180.84,
   width: 371.88,
   height: 16.44
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date of Closing Instructions", 
   top: 318.60,
   left: 180.12,
   width: 372.60,
   height: 16.44
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Zip", 
   top: 364.08,
   left: 527.52,
   width: 44.88,
   height: 16.44
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "State", 
   top: 364.08,
   left: 437.4,
   width: 85.68,
   height: 16.44
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "City", 
   top: 364.08,
   left: 320.4,
   width: 112.56,
   height: 16.44
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Earnest Money Holder", 
   top: 410.04,
   left: 146.76,
   width: 425.76,
   height: 16.44
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "signature",
   name: "Signature", 
   top: 442.765,
   left: 68.4,
   width: 238.56,
   height: 19.475
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Title", 
   top: 442.765,
   left: 320.4,
   width: 153.0,
   height: 19.475
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date_2", 
   top: 442.765,
   left: 486.72,
   width: 85.80,
   height: 19.475
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Street Address", 
   top: 364.08,
   left: 108.84,
   width: 207.12,
   height: 16.44
}
] }
