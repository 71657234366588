export function disclosureofBuyerBrokerCompensation92324() {
return [   //2024 Release 2024-09-28 11:01:29
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "Property Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 209.76,
   left: 147.88,
   width: 412.702,
   height: 12.705
}
,
{
   page: 0,
   name: "Sellers", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 236.36,
   left: 104.92,
   width: 417.12,
   height: 13.36
}
,
{
   page: 0,
   name: "Seller is agreeable to paying buyer broker compensation of up to", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 278.76,
   left: 427.88,
   width: 54.00,
   height: 12.36
}
,
{
   page: 0,
   name: "purchase price or", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 293.56,
   left: 156.56,
   width: 85.12,
   height: 12.36
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 180.001,
   left: 90.4913,
   width: 108.7637,
   height: 14.800
}
] }
