import _ from "lodash";
import { updateDocInDb } from "../../../app/firestore/firestoreService";

export function goToNextAnnot(nextAnnot) {
  if ((nextAnnot?.page || nextAnnot?.page === 0) && nextAnnot?.y) {
    const section = document.querySelector(
      `#page${nextAnnot.page}-y${nextAnnot.y.toString().split(".")[0]}`
    );
    // Chrome does not allow multiple scrolling with behavior smooth
    section && section.scrollIntoView({ behavior: "auto", block: "center" });
  }
  return nextAnnot;
}

export function updateAgentAnnotSigned(annotsInProgress) {
  let newAnnotsInProgress = _.cloneDeep(annotsInProgress);
  for (let i = 0; i < newAnnotsInProgress.length; i++) {
    if (
      newAnnotsInProgress[i].agentsField &&
      newAnnotsInProgress[i].signed !== true
    ) {
      newAnnotsInProgress[i].signed = true;
      newAnnotsInProgress[i].signedAt = new Date();
    }
  }
  return newAnnotsInProgress;
}

export function updateFirstAnnotSigned(
  doc,
  selectedAnnot,
  signedFontFamily,
  values,
  party,
  ipAddress
) {
  let selectedAnnotCopy = _.cloneDeep(selectedAnnot);
  selectedAnnotCopy["font"] = signedFontFamily;
  if (selectedAnnot.type === "signature") {
    selectedAnnotCopy["text"] = values.signedFullName;
  } else if (selectedAnnot.type === "initials") {
    selectedAnnotCopy["text"] = values.signedInitials;
  }
  selectedAnnotCopy["signed"] = true;
  selectedAnnotCopy["signedAt"] = new Date();
  selectedAnnotCopy["signedFontFamily"] = signedFontFamily;
  selectedAnnotCopy["signedFullName"] = values.signedFullName;
  selectedAnnotCopy["signedInitials"] = values.signedInitials;
  selectedAnnotCopy["signingDetailsConfirmed"] = true;
  selectedAnnotCopy["signerIpAddress"] = ipAddress;
  let updatedFields = {};
  updatedFields[`annotsToSign.${selectedAnnotCopy.uniqueId}`] =
    selectedAnnotCopy;
  Object.entries(doc.annotsToSign).forEach(([key, value]) => {
    if (
      value.signerRole === party.role &&
      value.type === "date" &&
      value.signed !== true
    ) {
      updatedFields[`annotsToSign.${value.uniqueId}.signed`] = true;
      updatedFields[`annotsToSign.${value.uniqueId}.signedAt`] = new Date();
    }
  });
  return updateDocInDb(doc.id, updatedFields);
}

export function updateTextAndCheckAnnotsSigned(annotsInProgress, party) {
  let newAnnotsInProgress = _.cloneDeep(annotsInProgress);
  for (let i = 0; i < newAnnotsInProgress.length; i++) {
    if (
      newAnnotsInProgress[i].signerRole === party.role &&
      (newAnnotsInProgress[i].type === "text" ||
        newAnnotsInProgress[i].type === "checkbox") &&
      newAnnotsInProgress[i].signed !== true
    ) {
      newAnnotsInProgress[i].signed = true;
      newAnnotsInProgress[i].signedAt = new Date();
    }
  }
  return newAnnotsInProgress;
}

export function updateTextAnnotSigned(doc, selectedAnnot, values) {
  let selectedAnnotCopy = _.cloneDeep(selectedAnnot);
  // selectedAnnotCopy["signed"] = true;
  // selectedAnnotCopy["signedAt"] = new Date();
  selectedAnnotCopy["text"] = values.text;
  let updatedFields = {};
  updatedFields[`annotsToSign.${selectedAnnotCopy.uniqueId}`] =
    selectedAnnotCopy;
  return updateDocInDb(doc.id, updatedFields);
}

export function updateCheckboxAnnotSigned(doc, selectedAnnot, values) {
  let selectedAnnotCopy = _.cloneDeep(selectedAnnot);
  // selectedAnnotCopy["signed"] = true;
  // selectedAnnotCopy["signedAt"] = new Date();
  selectedAnnotCopy["text"] = values.text;
  let updatedFields = {};
  updatedFields[`annotsToSign.${selectedAnnotCopy.uniqueId}`] =
    selectedAnnotCopy;
  return updateDocInDb(doc.id, updatedFields);
}

export function updateSignatureAnnotSigned(
  doc,
  selectedAnnot,
  values,
  ipAddress
) {
  let selectedAnnotCopy = _.cloneDeep(selectedAnnot);
  selectedAnnotCopy["font"] = values.signedFontFamily;
  if (selectedAnnot.type === "signature") {
    selectedAnnotCopy["text"] = values.signedFullName;
  } else if (selectedAnnot.type === "initials") {
    selectedAnnotCopy["text"] = values.signedInitials;
  }
  selectedAnnotCopy["signed"] = true;
  selectedAnnotCopy["signedAt"] = new Date();
  selectedAnnotCopy["signedFontFamily"] = values.signedFontFamily;
  selectedAnnotCopy["signedFullName"] = values.signedFullName;
  selectedAnnotCopy["signedInitials"] = values.signedInitials;
  selectedAnnotCopy["signingDetailsConfirmed"] = true;
  selectedAnnotCopy["signerIpAddress"] = ipAddress;
  let updatedFields = {};
  debugger;
  updatedFields[`annotsToSign.${selectedAnnotCopy.uniqueId}`] =
    selectedAnnotCopy;
  return updateDocInDb(doc.id, updatedFields);
}

export function updateAnnotSigningStatus(annotsToSign) {
  let annotsSigningStatus = {
    annotsToSign: [],
    annotsSigned: [],
    annotsTotalToSign: [],
    nextAnnotToSign: {},
  };
  annotsToSign.forEach((annot) => {
    if (annot.type === "signature" || annot.type === "initials") {
      annotsSigningStatus.annotsTotalToSign = [
        ...annotsSigningStatus.annotsTotalToSign,
        annot,
      ];
      if (annot.signed) {
        annotsSigningStatus.annotsSigned = [
          ...annotsSigningStatus.annotsSigned,
          annot,
        ];
      } else {
        annotsSigningStatus.annotsToSign = [
          ...annotsSigningStatus.annotsToSign,
          annot,
        ];
      }
    }
  });
  const annotsToSignFiltered = annotsToSign.filter(
    (annot) =>
      !annot.signed && (annot.type === "signature" || annot.type === "initials")
  );
  annotsSigningStatus.nextAnnotToSign = _.sortBy(annotsToSignFiltered, [
    "page",
    "y",
  ])[0];
  return annotsSigningStatus;
}
