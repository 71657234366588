import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { Grid } from "semantic-ui-react";
import FormAddress from "./FormAddress";
import MyPlaceInput from "./MyPlaceInput";

export default function FormAddressAutofillGoogle() {
  function Watcher() {
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      if (values.addressGoogle?.streetNumber) {
        const street = values.addressGoogle.streetNumber
          ? values.addressGoogle.streetNumber + " " + values.addressGoogle.route
          : "";
        setFieldValue("address.street", street);
        const unit = values.addressGoogle.subpremise
          ? values.addressGoogle.subpremise
          : "";
        setFieldValue("address.unit", unit);
        const city = values.addressGoogle.locality
          ? values.addressGoogle.locality
          : "";
        setFieldValue("address.city", city);
        const state = values.addressGoogle.administrative_area_level_1
          ? values.addressGoogle.administrative_area_level_1
          : "";
        setFieldValue("address.state", state);
        const zipcode = values.addressGoogle.postal_code
          ? values.addressGoogle.postal_code
          : "";
        setFieldValue("address.zipcode", zipcode);
        const county = values.addressGoogle.administrative_area_level_2
          ? values.addressGoogle.administrative_area_level_2
          : "";
        setFieldValue("propertyDetails.county", county);
        setFieldValue("addressGoogle.streetNumber", "");
      }
    });
    return null;
  }

  return (
    <>
      <Grid>
        <Grid.Row className="small top padding mini bottom padding">
          <Grid.Column mobile={16} computer={8}>
            <Watcher />
            <MyPlaceInput
              name="addressGoogle"
              placeholder="Search for address to autofill..."
              options={{
                componentRestrictions: { country: ["us"] },
                fields: ["address_components", "geometry"],
                // location: new google.maps.LatLng(values.city.latLng),
                // radius: 1000,
                types: ["address"],
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <FormAddress />
    </>
  );
}
