import { v4 as uuid } from "uuid";
import {
  convertFullName,
  convertInitials,
} from "../../../../../app/common/util/util";
import { format } from "date-fns";

export function createSigningParties(transaction, parties) {
  let possibleSigners = [];
  let displaySigners = [];
  if (transaction.agentRepresents === "Buyer") {
    let buyer = transaction?.client || { role: "Buyer" };
    buyer["index"] = 0;
    let buyer2 = transaction?.clientSecondary?.role
      ? transaction?.clientSecondary
      : { role: "Buyer 2" };
    buyer2["index"] = 1;
    let seller = parties.filter((party) => party.role === "Seller")[0] || {
      role: "Seller",
    };
    seller["index"] = 2;
    let seller2 = parties.filter((party) => party.role === "Seller 2")[0] || {
      role: "Seller 2",
    };
    seller2["index"] = 3;
    let listingAgent = parties.filter(
      (party) => party.role === "Listing Agent"
    )[0] || { role: "Listing Agent" };
    listingAgent["index"] = 4;
    let title = parties.filter(
      (party) => party.role === "Title Company"
    )[0] || {
      role: "Title Company",
    };
    title["index"] = 5;
    let other = { role: "Other" };
    other["index"] = 6;
    possibleSigners = [
      buyer,
      buyer2,
      seller,
      seller2,
      listingAgent,
      title,
      other,
    ];
    // possibleSigners = [buyer, buyer2, seller, seller2, other];
    if (transaction.clientSecondaryExists) {
      displaySigners = [buyer, buyer2];
    } else {
      displaySigners = [buyer];
    }
  } else if (transaction.agentRepresents === "Seller") {
    let seller = transaction?.client || { role: "Seller" };
    seller["index"] = 0;
    let seller2 = transaction?.clientSecondary?.role
      ? transaction?.clientSecondary
      : { role: "Seller 2" };
    seller2["index"] = 1;
    let buyer = parties.filter((party) => party.role === "Buyer")[0] || {
      role: "Buyer",
    };
    buyer["index"] = 2;
    let buyer2 = parties.filter((party) => party.role === "Buyer 2")[0] || {
      role: "Buyer 2",
    };
    buyer2["index"] = 3;
    let buyerAgent = parties.filter(
      (party) => party.role === "Buyer Agent"
    )[0] || { role: "Buyer Agent" };
    buyerAgent["index"] = 4;
    let title = parties.filter(
      (party) => party.role === "Title Company"
    )[0] || {
      role: "Title Company",
    };
    buyerAgent["index"] = 5;
    let other = { role: "Other" };
    other["index"] = 6;
    possibleSigners = [
      seller,
      seller2,
      buyer,
      buyer2,
      buyerAgent,
      title,
      other,
    ];
    // possibleSigners = [seller, seller2, buyer, buyer2, other];
    if (transaction.clientSecondaryExists) {
      displaySigners = [seller, seller2];
    } else {
      displaySigners = [seller];
    }
  }
  return { possibleSigners: possibleSigners, displaySigners: displaySigners };
}

export function createSignerListFromAnnots(annots) {
  let signerList = [];
  if (annots.length > 0) {
    annots.forEach((annot) => {
      if (!signerList.includes(annot.signerRole)) {
        signerList = [...signerList, annot.signerRole];
      }
    });
  }
  return signerList;
}

export function createInitialAnnotSize(annotType) {
  switch (annotType) {
    case "signature":
      return { width: 120, height: 20 };
    case "initials":
      return { width: 30, height: 20 };
    case "date":
      return { width: 60, height: 20 };
    case "text":
      return { width: 160, height: 20 };
    case "checkbox":
      return { width: 15, height: 12 };
    case "strikethrough":
      return { width: 20, height: 2 };
    default:
      return { width: 100, height: 20 };
  }
}

export function createAnnotColor(role, transaction) {
  if (transaction.agentRepresents === "Buyer") {
    switch (role) {
      case "Buyer":
        // green
        return "103, 211, 189";
      case "Buyer 2":
        // blue
        return "93, 164, 252";
      case "Seller":
        // orange
        return "242, 113, 28";
      case "Seller 2":
        // red
        return "211, 103, 114";
      case "Listing Agent":
        // violet
        return "191, 103, 211";
      case "Title":
        // yellow
        return "251, 189, 8";
      case "Title Company":
        // yellow
        return "251, 189, 8";
      case "Other":
        // pink
        return "224, 57, 151";
      default:
        // green
        return "103, 211, 189";
    }
  } else {
    switch (role) {
      case "Buyer":
        // green
        return "103, 211, 189";
      case "Buyer 2":
        // blue
        return "93, 164, 252";
      case "Seller":
        // orange
        return "242, 113, 28";
      case "Seller 2":
        // red
        return "211, 103, 114";
      case "Buyer Agent":
        // violet
        return "191, 103, 211";
      case "Title Company":
        // yellow
        return "251, 189, 8";
      case "Other":
        // pink
        return "224, 57, 151";
      default:
        // green
        return "103, 211, 189";
    }
  }
}

export function createSignerIndex(role, transaction) {
  if (transaction.agentRepresents === "Buyer") {
    switch (role) {
      case "Buyer":
        // green
        return 0;
      case "Buyer 2":
        // blue
        return 1;
      case "Seller":
        // orange
        return 2;
      case "Seller 2":
        // red
        return 3;
      case "Listing Agent":
        // violet
        return 4;
      case "Title Company":
        // violet
        return 5;
      case "Other":
        // yellow
        return 6;
      default:
        // green
        return 0;
    }
  } else {
    switch (role) {
      case "Buyer":
        // green
        return 0;
      case "Buyer 2":
        // blue
        return 1;
      case "Seller":
        // orange
        return 2;
      case "Seller 2":
        // red
        return 3;
      case "Buyer Agent":
        // violet
        return 4;
      case "Title Company":
        // yellow
        return 5;
      case "Other":
        // yellow
        return 6;
      default:
        // green
        return 0;
    }
  }
}

export function createSignerColor(role, transaction) {
  if (transaction.agentRepresents === "Buyer") {
    switch (role) {
      case "Buyer":
        return "teal";
      case "Buyer 2":
        return "blue";
      case "Seller":
        return "orange";
      case "Seller 2":
        return "red";
      case "Listing Agent":
        return "violet";
      case "Title Company":
        return "yellow";
      case "Other":
        return "pink";
      default:
        return "teal";
    }
  } else {
    switch (role) {
      case "Buyer":
        return "teal";
      case "Buyer 2":
        return "blue";
      case "Seller":
        return "orange";
      case "Seller 2":
        return "red";
      case "Buyer Agent":
        return "violet";
      case "Title Company":
        return "yellow";
      case "Other":
        return "pink";
      default:
        return "teal";
    }
  }
}

export const annotTextSizeOptions = [
  { key: "7", value: "7", text: "7" },
  { key: "9", value: "9", text: "9" },
  { key: "10", value: "10", text: "10" },
  { key: "12", value: "12", text: "12" },
  { key: "14", value: "14", text: "14" },
  { key: "16", value: "16", text: "16" },
  { key: "18", value: "18", text: "18" },
  { key: "20", value: "20", text: "20" },
  { key: "24", value: "24", text: "24" },
  { key: "28", value: "28", text: "28" },
  { key: "32", value: "32", text: "32" },
  { key: "40", value: "40", text: "40" },
];

export function convertAnnotFields(
  editMode,
  activeAnnotType,
  currentUserProfile
) {
  let text = "";
  let fontFamily = "lucida console";
  let readOnly = false;
  let agentsField = false;
  let required = true;
  let fontSize = "14";

  if (editMode === "agent" && activeAnnotType !== "strikethrough") {
    readOnly = true;
    agentsField = true;
    required = false;
    if (activeAnnotType === "signature") {
      text = convertFullName(currentUserProfile);
    }
    if (activeAnnotType === "initials") {
      text = convertInitials(currentUserProfile);
    }
    if (activeAnnotType === "checkbox") {
      text = "checked";
      fontSize = "12";
    }
    if (activeAnnotType === "date") {
      text = format(new Date(), "MM/dd/yyyy");
    }
  }
  if (activeAnnotType === "strikethrough") {
    readOnly = true;
    agentsField = true;
    required = false;
    text = "___";
  }
  if (activeAnnotType === "text") {
    fontSize = "12";
  }
  if (activeAnnotType === "date") {
    fontSize = "10";
  }
  if (activeAnnotType === "signature" || activeAnnotType === "initials") {
    fontSize = "12";
    fontFamily = "brush script mt, cursive";
  }
  return {
    text: text,
    readOnly: readOnly,
    agentsField: agentsField,
    required: required,
    fontFamily: fontFamily,
    fontSize: fontSize,
  };
}

export function addAnnotToCanvas(
  canvas,
  event,
  activeAnnotType,
  doc,
  selectedSigner,
  currentUserProfile,
  currentUserIpAddress,
  transaction,
  pageScale,
  editMode
) {
  const rect = canvas.getBoundingClientRect();
  const x = (event.clientX - rect.left) / pageScale;
  const y = (event.clientY - rect.top) / pageScale;
  // Needs to start with letter because of DOM events while signing
  const uniqueId = "u-" + uuid();
  const initialSize = createInitialAnnotSize(activeAnnotType);
  const convertedField = convertAnnotFields(
    editMode,
    activeAnnotType,
    currentUserProfile
  );

  let annot = {
    uniqueId: uniqueId,
    x: x,
    y: y,
    width: initialSize.width,
    height: initialSize.height,
    docId: doc.id,
    page: parseInt(canvas.id.split("-")[1], 10),
    type: activeAnnotType,
    signerRole: selectedSigner.role,
    required: convertedField.required,
    readOnly: convertedField.readOnly,
    agentsField: convertedField.agentsField,
    fontSize: convertedField.fontSize,
    fontColor: "blue",
    fontFamily: convertedField.fontFamily,
    text: convertedField.text,
    createdAt: format(new Date(), "MM/dd/yyyy h:mm aa"),
    createdByFirstName: currentUserProfile.firstName || "",
    createdByLastName: currentUserProfile.lastName || "",
  };

  if (convertedField.agentsField) {
    annot["signerRole"] =
      transaction.agentRepresents === "Buyer" ? "Buyer Agent" : "Listing Agent";
  }

  if (
    convertedField.agentsField &&
    (activeAnnotType === "signature" || activeAnnotType === "initials")
  ) {
    annot["signed"] = true;
    annot["signedAt"] = new Date();
    annot["signerEmail"] = currentUserProfile.email || "";
    annot["signerIpAddress"] = currentUserIpAddress || "";
  }

  return annot;
}
