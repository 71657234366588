export function referralAgreementOriginatingAgentHSPR() {
    return [   //2023
    {
       page: 0,
       name: "Phone Number", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 239.04,
       left: 155.88,
       width: 110.88,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Email", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 239.04,
       left: 298.92,
       width: 245.16,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Brokerage  Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 262.8,
       left: 182.04,
       width: 362.16,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Originating Brokerage Tax ID", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 286.8,
       left: 231.84,
       width: 309.48,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Percent of GCI gross commission agreed upon", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 311.04,
       left: 329.76,
       width: 210.12,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Receiving Agent", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 335.28,
       left: 166.92,
       width: 373.68,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Phone Number_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 359.04,
       left: 155.64,
       width: 111.00,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Email_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 359.04,
       left: 298.68,
       width: 245.28,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Brokerage  Address_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 382.8,
       left: 186.24,
       width: 356.28,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Listing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 430.8,
       left: 196.44,
       width: 64.20,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Buyer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 430.8,
       left: 300.96,
       width: 64.20,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Client Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 455.52,
       left: 140.4,
       width: 402.96,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 479.52,
       left: 120.24,
       width: 420.48,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Home Phone", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 503.52,
       left: 141.6,
       width: 122.64,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Cell Phone", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 503.52,
       left: 323.64,
       width: 216.00,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Email_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 527.28,
       left: 107.76,
       width: 420.48,
       height: 14.411
    }
    ,
    {
       page: 0,
       name: "Date_3", 
       fontSize: 9,
       type: "date",
       top: 620.632,
       left: 109.004,
       width: 122.640,
       height: 14.412
    }
    ,
    {
       page: 0,
       name: "signature OriginatingAgent", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 576.384,
       left: 79.2004,
       width: 150.0006,
       height: 14.411
    }
    ,
{
   page: 0,
   name: "OriginatingAgent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 215.728,
   left: 172.146,
   width: 369.928,
   height: 14.411
}
,
{
   page: 0,
   name: "Text33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 616.585,
   left: 354.765,
   width: 150.000,
   height: 17.418
}
] }

     