export function buyerAdvisoryResidentialDC924() {
return [   //2024 Release 2024-09-17 18:20:47
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 47.411,
   left: 245.64,
   width: 234.12,
   height: 11.509
}
,
{
   page: 0,
   name: "without right of transfer All rights reserved", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 149.28,
   width: 147.84,
   height: 10.20
}
,
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 168.983,
   left: 179.346,
   width: 348.274,
   height: 16.309
}
,
{
   page: 0,
   name: "Text2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 195.819,
   left: 179.346,
   width: 348.274,
   height: 16.309
}
,
{
   page: 0,
   name: "Text3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 222.965,
   left: 179.346,
   width: 348.274,
   height: 16.309
}
,
{
   page: 1,
   name: "undefined_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 47.411,
   left: 245.64,
   width: 234.12,
   height: 11.509
}
,
{
   page: 1,
   name: "without right of transfer All rights reserved_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 149.28,
   width: 147.84,
   height: 10.20
}
,
{
   page: 2,
   name: "undefined_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 48.72,
   left: 245.64,
   width: 234.12,
   height: 10.20
}
,
{
   page: 2,
   name: "without right of transfer All rights reserved_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 149.28,
   width: 147.84,
   height: 10.20
}
,
{
   page: 3,
   name: "undefined_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 48.72,
   left: 245.64,
   width: 234.12,
   height: 10.20
}
,
{
   page: 3,
   name: "without right of transfer All rights reserved_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 149.28,
   width: 147.84,
   height: 10.20
}
,
{
   page: 4,
   name: "undefined_5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 48.72,
   left: 245.64,
   width: 234.12,
   height: 10.20
}
,
{
   page: 4,
   name: "without right of transfer All rights reserved_5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 149.28,
   width: 147.84,
   height: 10.20
}
,
{
   page: 5,
   name: "undefined_6", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 48.72,
   left: 245.64,
   width: 234.12,
   height: 10.20
}
,
{
   page: 5,
   name: "without right of transfer All rights reserved_6", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 149.28,
   width: 147.84,
   height: 10.20
}
,
{
   page: 6,
   name: "undefined_7", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 47.52,
   left: 245.64,
   width: 234.12,
   height: 11.40
}
,
{
   page: 6,
   name: "signature Broker", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 645.28,
   left: 72.0,
   width: 126.0,
   height: 15.68
}
,
{
   page: 6,
   name: "Date_5", 
   fontSize: 9,
   type: "date",
   top: 645.28,
   left: 205.44,
   width: 76.68,
   height: 15.68
}
,
{
   page: 6,
   name: "signature CoBroker", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 645.28,
   left: 292.08,
   width: 126.00,
   height: 15.68
}
,
{
   page: 6,
   name: "Date_6", 
   fontSize: 9,
   type: "date",
   top: 645.28,
   left: 425.52,
   width: 76.80,
   height: 15.68
}
,
{
   page: 6,
   name: "without right of transfer All rights reserved_7", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 744.36,
   left: 149.28,
   width: 147.84,
   height: 10.20
}
] }
