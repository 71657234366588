export function cBSalesAssociateElectionPersonalTransactions() {
    return [   //2023
    {
       page: 0,
       name: "Broker Buying Full Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 189.432,
       left: 146.803,
       width: 104.680,
       height: 13.053
    }
    ,
    {
       page: 0,
       name: "Checkbox Purchase", 
       isText: false,
       type: "checkbox",
       top: 204.792,
       left: 94.0149,
       width: 10.2401,
       height: 10.758
    }
    ,
    {
       page: 0,
       name: "Checkbox Sell", 
       isText: false,
       type: "checkbox",
       top: 204.916,
       left: 151.97,
       width: 10.24,
       height: 10.758
    }
    ,
    {
       page: 0,
       name: "and hereby elects the following option regarding", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 222.043,
       left: 145.2,
       width: 214.92,
       height: 10.157
    }
    ,
    {
       page: 0,
       name: "Checkbox Option 1", 
       isText: false,
       type: "checkbox",
       top: 250.057,
       left: 105.989,
       width: 10.240,
       height: 10.758
    }
    ,
    {
       page: 0,
       name: "Checkbox Option 2", 
       isText: false,
       type: "checkbox",
       top: 344.312,
       left: 105.989,
       width: 10.240,
       height: 10.757
    }
    ,
    {
       page: 0,
       name: "Checkbox Option 3", 
       isText: false,
       type: "checkbox",
       top: 406.821,
       left: 106.971,
       width: 10.240,
       height: 10.757
    }
    ,
    {
       page: 0,
       name: "Sales Associate Signature", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 12,
       top: 524.209,
       left: 72.6981,
       width: 213.6219,
       height: 15.545
    }
    ,
    {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 523.935,
       left: 324.0,
       width: 180.24,
       height: 15.545
    }
    ,
    {
       page: 0,
       name: "Branch Manager Signature", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 575.918,
       left: 73.0254,
       width: 213.6216,
       height: 15.545
    }
    ,
    {
       page: 0,
       name: "Date_2", 
       fontSize: 9,
       type: "date",
       top: 574.673,
       left: 324.0,
       width: 180.24,
       height: 16.527
    }
    ] }
    
