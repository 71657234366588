export function assignmentofLease() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
      page: 0,
      name: "Date of Assignment", 
      fontSize: 9,
      type: "date",
      top: 179.244,
      left: 154.4,
      width: 290.197,
      height: 16.691
   }
   ,
   {
      page: 0,
      name: "Assignor (Landlord)", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 200.18,
      left: 154.4,
      width: 376.364,
      height: 16.691
   }
   ,
   {
      page: 0,
      name: "Assignee (Buyer)", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 221.117,
      left: 154.4,
      width: 376.047,
      height: 16.691
   }
   ,
   {
      page: 0,
      name: "Assignee (Buyer) Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 242.053,
      left: 154.4,
      width: 375.796,
      height: 16.691
   }
   ,
   {
      page: 0,
      name: "Property Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 262.99,
      left: 154.4,
      width: 375.796,
      height: 16.691
   }
   ,
   {
      page: 0,
      name: "Tenant Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 283.927,
      left: 154.4,
      width: 375.795,
      height: 16.691
   }
   ,
   {
      page: 0,
      name: "Date of Lease", 
      fontSize: 9,
      type: "text",
      top: 304.863,
      left: 154.4,
      width: 290.197,
      height: 16.691
   }
   ,
   {
      page: 0,
      name: "Security Deposit", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 326.455,
      left: 154.4,
      width: 290.197,
      height: 16.690
   }
   ,
   {
      page: 0,
      name: "Sign Name (Assignor)", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 499.8,
      left: 50.4,
      width: 216.12,
      height: 16.691
   }
   ,
   {
      page: 0,
      name: "Print Name (Assignor)", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 499.8,
      left: 302.4,
      width: 216.12,
      height: 16.691
   }
   ,
   {
      page: 0,
      name: "Sign Name (Assignee)", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 580.846,
      left: 51.4,
      width: 216.12,
      height: 16.691
   }
   ,
   {
      page: 0,
      name: "Print Name (Assignee)", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 580.32,
      left: 301.4,
      width: 216.12,
      height: 16.691
   }
   ] }
   