import { formatDistance } from "date-fns";
import React from "react";
import { Table } from "semantic-ui-react";
import TaskTemplatesActionButtons from "../TaskTemplatesActionButtons";

export default function TaskTemplatesListItem({
  taskTemplate,
  actionButtonMode,
}) {
  return (
    <Table.Row key={taskTemplate.id}>
      <Table.Cell>{taskTemplate.title}</Table.Cell>
      <Table.Cell collapsing>
        <p>
          {taskTemplate.updatedAt && taskTemplate.updatedAt instanceof Date
            ? formatDistance(taskTemplate.updatedAt, new Date(), {
                addSuffix: true,
              })
            : ""}
        </p>
      </Table.Cell>
      <Table.Cell collapsing>
        <TaskTemplatesActionButtons
          taskTemplate={taskTemplate}
          actionButtonMode={actionButtonMode}
        />
      </Table.Cell>
    </Table.Row>
  );
}
