export function addendumRegardingResidentialLeases() {
return [   //2024 Release 2024-02-19 17:39:04
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 96.153,
   left: 241.32,
   width: 316.80,
   height: 11.367
}
,
{
   page: 0,
   name: "1 a Buyer has received a copy of all Residential Leases", 
   isText: false,
   type: "checkbox",
   top: 174.905,
   left: 30.7396,
   width: 8.5200,
   height: 9.783
}
,
{
   page: 0,
   name: "2 a Buyer has received a copy of all Residential Leases", 
   isText: false,
   type: "checkbox",
   top: 228.905,
   left: 29.5558,
   width: 9.7038,
   height: 9.783
}
,
{
   page: 0,
   name: "3 a Buyer has received a copy of all Residential Leases", 
   isText: false,
   type: "checkbox",
   top: 262.057,
   left: 68.4806,
   width: 11.4794,
   height: 9.783
}
,
{
   page: 0,
   name: "b Buyer has not received a copy of all Residential Leases Seller shall provide a copy of the", 
   isText: false,
   type: "checkbox",
   top: 273.729,
   left: 68.3601,
   width: 11.5999,
   height: 9.191
}
,
{
   page: 0,
   name: "refunded to Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 292.481,
   left: 101.88,
   width: 24.00,
   height: 11.599
}
,
{
   page: 0,
   name: "b Buyer has not received a copy of all Residential Leases Seller shall provide a copy of theNo 2", 
   isText: false,
   type: "checkbox",
   top: 312.234,
   left: 69.5962,
   width: 10.8875,
   height: 9.191
}
,
{
   page: 0,
   name: "refunded to BuyerNo 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 327.72,
   left: 100.979,
   width: 476.410,
   height: 9.039
}
,
{
   page: 0,
   name: "Explain if any of the above is not accurate attach additional sheets if necessary 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 465.484,
   left: 478.92,
   width: 91.08,
   height: 10.712
}
,
{
   page: 0,
   name: "Explain if any of the above is not accurate attach additional sheets if necessary 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 476.773,
   left: 83.88,
   width: 486.12,
   height: 9.403
}
,
{
   page: 0,
   name: "Explain if any of the above is not accurate attach additional sheets if necessary 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 487.073,
   left: 83.88,
   width: 486.12,
   height: 9.403
}
,
{
   page: 0,
   name: "3 Explain if any of the above is not accurate attach additional sheets if necessary 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 498.012,
   left: 83.6552,
   width: 486.1198,
   height: 9.403
}
] }
