import React, { useEffect } from "react";
import _ from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Header,
  Segment,
  Table,
  Icon,
  Input,
} from "semantic-ui-react";
import { addFormsToTransaction } from "../../../../app/firestore/firebaseService";
import { updateTransUpdatedInDb } from "../../../../app/firestore/firestoreService";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { closeModal } from "../../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../../app/common/modals/modalWrapper";
import {
  createFormsToAddDisplayLists,
  searchFilter,
} from "../../../../app/common/util/util";
import { useMediaQuery } from "react-responsive";

export default function DocSelectFormsModal() {
  const dispatch = useDispatch();
  const [formList, setFormList] = useState();
  const [formListTemplates, setFormListTemplates] = useState();
  const [formListSuggested, setFormListSuggested] = useState();
  const [formsToAdd, setFormsToAdd] = useState([]);
  const [formTemplatesToAdd, setFormTemplatesToAdd] = useState([]);
  const [formsAlreadyAdded, setFormsAlreadyAdded] = useState([]);

  const { docsTransActive, forms } = useSelector((state) => state.doc);
  const { transaction } = useSelector((state) => state.transaction);
  const { formTemplates } = useSelector((state) => state.formTemplates);

  const { currentUserProfile } = useSelector((state) => state.profile);
  const { loading } = useSelector((state) => state.async);
  const [showTemplates, setShowTemplates] = useState(false);
  const [newDocumentsLoading, setNewDocumentsLoading] = useState(false);

  const [searchTerms, setSearchTerms] = useState("");
  const formsFilteredOnSearch = searchFilter(formList, searchTerms);

  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  const handleShowTemplates = () => {
    setShowTemplates(!showTemplates);
  };

  useEffect(() => {
    let formsAdded = [];
    docsTransActive.docs.forEach((doc) => {
      formsAdded = [...formsAdded, doc.title];
    });
    setFormsAlreadyAdded(formsAdded);
    const formDisplayLists = createFormsToAddDisplayLists(
      transaction,
      forms,
      formsAdded,
      currentUserProfile
    );
    setFormListSuggested(formDisplayLists.formsSuggested);
    setFormListTemplates(
      transaction.agentRepresents === "Buyer"
        ? formTemplates.filter((template) => template.showBuyerAgent === true)
        : formTemplates.filter((template) => template.showSellerAgent === true)
    );
    setFormList(formDisplayLists.allOtherForms);
    return () => {};
  }, [
    docsTransActive.docs,
    forms,
    transaction,
    formTemplates,
    currentUserProfile,
  ]);

  function handleAddForm(formTitle) {
    setFormsToAdd(
      formsToAdd.includes(formTitle)
        ? formsToAdd.filter((e) => e !== formTitle)
        : [...formsToAdd, formTitle]
    );
  }
  function handleAddTemplate(formId) {
    setFormTemplatesToAdd(
      formTemplatesToAdd.includes(formId)
        ? formTemplatesToAdd.filter((e) => e !== formId)
        : [...formTemplatesToAdd, formId]
    );
  }

  async function handleDone() {
    if (formsToAdd.length > 0 || formTemplatesToAdd.length > 0) {
      try {
        setNewDocumentsLoading(true);
        let formsAdded = [];
        formsToAdd.forEach((formTitle) => {
          const formFullDoc = formList.filter(
            (form) => form.title === formTitle
          )[0];
          const formFullDocSuggested = formListSuggested.filter(
            (form) => form.title === formTitle
          )[0];

          if (formFullDoc) {
            formsAdded = [...formsAdded, formFullDoc];
          } else if (formFullDocSuggested) {
            formsAdded = [...formsAdded, formFullDocSuggested];
          }
        });
        formTemplatesToAdd.forEach((formId) => {
          const formFullDocTemplate = formListTemplates.filter(
            (form) => form.id === formId
          )[0];
          const formTitle = formFullDocTemplate.title;
          let formDoc = formList.filter((form) => form.title === formTitle)[0];
          if (formFullDocTemplate.id) {
            const formDocCopy = _.cloneDeep(formDoc) || {};
            formDocCopy.name = formFullDocTemplate.name;

            if (formFullDocTemplate.formFieldValues) {
              formDocCopy.formFieldValues = formFullDocTemplate.formFieldValues;
              formDocCopy.hasFormFieldValues = true;
            } else {
              formDocCopy.hasFormFieldValues = false;
            }
            formsAdded = [...formsAdded, formDocCopy];
          }
        });
        await addFormsToTransaction(
          transaction,
          formsAdded,
          currentUserProfile
        );
        updateTransUpdatedInDb(transaction.id);
        dispatch(
          closeModal({
            modalType: "DocSelectFormsModal",
          })
        );
        setNewDocumentsLoading(false);
      } catch (error) {
        setNewDocumentsLoading(false);
        throw error;
      }
    } else {
      dispatch(
        closeModal({
          modalType: "DocSelectFormsModal",
        })
      );
    }
  }

  if (loading || newDocumentsLoading)
    return <LoadingComponent content="Loading documents..." />;

  return (
    <>
      <ModalWrapper style={{ backgroundColor: "#f9fafb" }}>
        <Segment clearing style={{ backgroundColor: "#f9fafb" }}>
          <Header color="blue" size="large">
            Select Forms to Add
            {!isMobile && (
              <Button
                primary
                floated="right"
                content="Done"
                loading={loading}
                onClick={() => handleDone()}
              ></Button>
            )}
          </Header>
          <Divider />
          <Input
            type="text"
            fluid
            placeholder="Search by document name or category type"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          ></Input>
          <Table compact>
            <Table.Header className="mobile hidden">
              <Table.Row
                className="small-header"
                style={{ backgroundColor: "#f9fafb !important" }}
              >
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {formListTemplates && formListTemplates?.length !== 0 && (
                <>
                  <Table.Row
                    style={{ backgroundColor: "#f0f7fe", color: "#2185d0" }}
                    onClick={() => handleShowTemplates()}
                  >
                    <Table.Cell style={{ cursor: "pointer" }}>
                      <Icon
                        name={showTemplates ? "angle down" : "angle right"}
                        size="small"
                        style={{ marginLeft: "3px", marginBottom: "3px" }}
                      />
                      Templates
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  {showTemplates && (
                    <>
                      {formListTemplates.map((form) => (
                        <Table.Row key={form.id}>
                          <Table.Cell>{form.name}</Table.Cell>
                          <Table.Cell>
                            {formTemplatesToAdd.includes(form.id) ? (
                              <Button
                                floated={isMobile ? null : "right"}
                                size={isMobile ? "medium" : "mini"}
                                primary
                                onClick={() => handleAddTemplate(form.id)}
                                content="Remove"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <Button
                                floated={isMobile ? null : "right"}
                                size={isMobile ? "medium" : "mini"}
                                onClick={() => handleAddTemplate(form.id)}
                                content="Add"
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </>
                  )}
                </>
              )}

              {formListSuggested && formListSuggested?.length !== 0 && (
                <>
                  <Table.Row
                    style={{ backgroundColor: "#f0f7fe", color: "#2185d0" }}
                  >
                    <Table.Cell>Suggested Forms</Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  {formListSuggested.map((form) => (
                    <Table.Row key={form.id}>
                      <Table.Cell>{form.title}</Table.Cell>
                      <Table.Cell>
                        {formsToAdd.includes(form.title) ? (
                          <Button
                            floated={isMobile ? null : "right"}
                            size={isMobile ? "medium" : "mini"}
                            primary
                            onClick={() => handleAddForm(form.title)}
                            content="Remove"
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <Button
                            floated={isMobile ? null : "right"}
                            size={isMobile ? "medium" : "mini"}
                            onClick={() => handleAddForm(form.title)}
                            content="Add"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        {formsAlreadyAdded.includes(form.title) && (
                          <p
                            className={
                              isMobile
                                ? "text blue small right margin"
                                : "text blue float right small right margin"
                            }
                            style={{ paddingTop: "5px" }}
                          >
                            Already Added
                          </p>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </>
              )}
              <Table.Row
                style={{ backgroundColor: "#f0f7fe", color: "#2185d0" }}
              >
                <Table.Cell>All Forms</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              {formsFilteredOnSearch &&
                formsFilteredOnSearch?.length !== 0 &&
                formsFilteredOnSearch.map((form) => (
                  <Table.Row key={form.id}>
                    <Table.Cell>{form.title}</Table.Cell>
                    <Table.Cell>
                      {formsToAdd.includes(form.title) ? (
                        <Button
                          floated={isMobile ? null : "right"}
                          size={isMobile ? "medium" : "mini"}
                          primary
                          onClick={() => handleAddForm(form.title)}
                          content="Remove"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Button
                          floated={isMobile ? null : "right"}
                          size={isMobile ? "medium" : "mini"}
                          onClick={() => handleAddForm(form.title)}
                          content="Add"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      {formsAlreadyAdded.includes(form.title) && (
                        <p
                          className={
                            isMobile
                              ? "text blue small right margin"
                              : "text blue float right small right margin"
                          }
                          style={{ paddingTop: "5px" }}
                        >
                          Already Added
                        </p>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <Button
            primary
            floated={isMobile ? null : "right"}
            content="Done"
            loading={loading}
            onClick={() => handleDone()}
            className={isMobile ? "fluid" : null}
          ></Button>
        </Segment>
      </ModalWrapper>
    </>
  );
}
