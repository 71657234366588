export function leadBasedPaintObligationsofSeller() {
return [   //2024 Release 2024-03-13 11:52:05
{
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 134,
      height: 20,
    }
    ,
    {
   page: 0,
   name: "Street Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 599.236,
   left: 170.64,
   width: 387.60,
   height: 16.364
}
] }
