export function addendumSaleofOtherPropertybyBuyer() {
return [   //2024 Release 2024-02-18 23:09:40
{
   page: 0,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 160.09,
   left: 54.4896,
   width: 504.1204,
   height: 9.96
}
,
{
   page: 0,
   name: "Address on or before", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 225.36,
   left: 68.1697,
   width: 500.0023,
   height: 9.96
}
,
{
   page: 0,
   name: "Contingency is not satisfied or waived by Buyer by the above date the contract will terminate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 236.28,
   left: 174,
   width: 200.4,
   height: 9.96
}
,
{
   page: 0,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 236.28,
   left: 394.68,
   width: 40.56,
   height: 9.96
}
,
{
   page: 0,
   name: "terminate automatically and the earnest money will be refunded to Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 333.36,
   left: 145.32,
   width: 99.24,
   height: 9.96
}
,
{
   page: 0,
   name: "All notices and waivers must be in writing and are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 368.299,
   left: 504.859,
   width: 64.680,
   height: 9.960
}
] }
