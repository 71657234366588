export function residentialAddendumtoContracttoBuySellRealEstate() {
return [
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date", 
   top: 178.08,
   left: 398.64,
   width: 174.00,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 9,
   type: "date",
   name: "Contract to Buy and Sell Real Estate Land between Seller and Buyer Contract dated", 
   top: 224.04,
   left: 505.44,
   width: 67.20,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 8,
   type: "date",
   name: "Contract to Buy and Sell Real Estate Commercial between Seller and Buyer Contract dated", 
   top: 246.96,
   left: 534.84,
   width: 60.80,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "known as", 
   top: 278.902,
   left: 90.96,
   width: 436.68,
   height: 13.178
}
,
{
   page: 0,
   type: "checkbox",
   name: "Conventional", 
   top: 386.16,
   left: 115.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "FHA", 
   top: 386.16,
   left: 158.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "VA", 
   top: 386.16,
   left: 194.4,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Bond", 
   top: 386.16,
   left: 238.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Buyer may purchase the Property using any of the following types of loan", 
   top: 374.76,
   left: 565.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Other", 
   top: 385.08,
   left: 280.08,
   width: 94.44,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "to pay those closing costs and fees that Buyer is not allowed by law to pay not to exceed", 
   top: 408.12,
   left: 411.36,
   width: 161.16,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "setting forth the appraised value of the Property of not less than", 
   top: 511.56,
   left: 313.68,
   width: 69.84,
   height: 10.20
}
,
{
   page: 0,
   type: "checkbox",
   name: "Source of Potable Water Residential Land and Residential Improvements Only Buyer", 
   top: 627.72,
   left: 525.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Does", 
   top: 627.72,
   left: 565.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "potable water for the Property", 
   top: 650.64,
   left: 177.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "There is No Well Buyer", 
   top: 650.64,
   left: 298.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Does_2", 
   top: 650.64,
   left: 339.36,
   width: 10.08,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box5", 
   top: 225.165,
   left: 100.8,
   width: 9.819,
   height: 9.163
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box6", 
   top: 248.074,
   left: 101.455,
   width: 9.164,
   height: 9.163
}
// ,
// {
//    page: 1,
//    fontName: "Helvetica",
//    fontSize: 9,
//    type: "date",
//    name: "Date_2", 
//    top: 408,
//    left: 240,
//    width: 70,
//    height: 16.32
// }
// ,
// {
//    page: 1,
//    fontName: "Helvetica",
//    fontSize: 9,
//    type: "date",
//    name: "Date_3", 
//    top: 408,
//    left: 510,
//    width: 70,
//    height: 16.32
// }
// ,
// {
//    page: 1,
//    fontName: "Helvetica",
//    fontSize: 9,
//    type: "date",
//    name: "Date_4", 
//    top: 454.40,
//    left: 240,
//    width: 70,
//    height: 16.32
// }
// ,
// {
//    page: 1,
//    fontName: "Helvetica",
//    fontSize: 9,
//    type: "date",
//    name: "Date_5", 
//    top: 454.40,
//    left: 510,
//    width: 70,
//    height: 16.32
// }
// ,
// {
//    page: 1,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Signature1", 
//    top: 392.729,
//    left: 50.4002,
//    width: 174.6008,
//    height: 32.073
// }
// ,
// {
//    page: 1,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Signature2", 
//    top: 434.893,
//    left: 50.4002,
//    width: 174.2188,
//    height: 33.382
// }
// ,
// {
//    page: 1,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Signature3", 
//    top: 393.729,
//    left: 328.583,
//    width: 174.583,
//    height: 32.727
// }
// ,
// {
//    page: 1,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Signature4", 
//    top: 438,
//    left: 328.583,
//    width: 175.237,
//    height: 33.382
// }
] }
