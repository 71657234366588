export const deadlineTemplatesFormFieldMapping = {
  "Alternative Earnest Money Deadline":
    "DateAlternative Earnest Money Deadline",
  "Record Title Deadline (and Tax Certificate)":
    "DateRecord Title Deadline and Tax Certificate",
  "Record Title Objection Deadline": "DateRecord Title Objection Deadline",
  "Off-Record Title Deadline": "DateOffRecord Title Deadline",
  "Off-Record Title Objection Deadline":
    "DateOffRecord Title Objection Deadline",
  "Title Resolution Deadline": "DateTitle Resolution Deadline",
  "Third Party Right to Purchase/Approve Deadline":
    "DateThird Party Right to PurchaseApprove Deadline",
    "Association Documents Deadline": "DateAssociation Documents Deadline",
    "Association Documents Termination Deadline": "DateAssociation Documents Termination Deadline",
    "Seller's Property Disclosure Deadline": "DateSellers Property Disclosure Deadline",
    "Lead-Based Paint Disclosure Deadline": "DateLeadBased Paint Disclosure Deadline",
    "New Loan Application Deadline": "DateNew Loan Application Deadline",
    "New Loan Terms Deadline": "DateNew Loan Terms Deadline",
    "New Loan Availability Deadline": "DateNew Loan Availability Deadline",
    "Buyer's Credit Information Deadline": "DateBuyers Credit Information Deadline",
    "Disapproval of Buyer's Credit Information Deadline": "DateDisapproval of Buyers Credit Information Deadline",
    "Existing Loan Deadline": "DateExisting Loan Deadline",
    "Existing Loan Termination Deadline": "DateExisting Loan Termination Deadline",
    "Loan Transfer Approval Deadline": "DateLoan Transfer Approval Deadline",
    "Seller or Private Financing Deadline": "DateSeller or Private Financing Deadline",
    "Appraisal Deadline": "DateAppraisal Deadline",
    "Appraisal Objection Deadline": "DateAppraisal Objection Deadline",
    "Appraisal Resolution Deadline": "DateAppraisal Resolution Deadline",
    "New ILC or New Survey Deadline": "DateNew ILC or New Survey Deadline",
    "New ILC or New Survey Objection Deadline": "DateNew ILC or New Survey Objection Deadline",
    "New ILC or New Survey Resolution Deadline": "DateNew ILC or New Survey Resolution Deadline",
    "Water Rights Examination Deadline": "DateWater Rights Examination Deadline",
    "Mineral Rights Examination Deadline": "DateMineral Rights Examination Deadline",
    "Inspection Termination Deadline": "DateInspection Termination Deadline",
    "Inspection Objection Deadline": "DateInspection Objection Deadline",
    "Inspection Resolution Deadline": "DateInspection Resolution Deadline",
    "Property Insurance Termination Deadline": "DateProperty Insurance Termination Deadline",
    "Due Diligence Documents Delivery Deadline": "DateDue Diligence Documents Delivery Deadline",
    "Due Diligence Documents Objection Deadline": "DateDue Diligence Documents Objection Deadline",
    "Due Diligence Documents Resolution Deadline": "DateDue Diligence Documents Resolution Deadline",
    "Conditional Sale Deadline": "DateConditional Sale Deadline",
    "Lead Based Paint Termination Deadline": "DateLeadBased Paint Termination Deadline",
    "Possession Date": "DatePossession Date",
    "Acceptance Deadline Date": "DateAcceptance Deadline Date",
  "Closing Date": "DateClosing Date",
};
