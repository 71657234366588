import React from "react";
import { Button, Divider, Segment } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalSlice";

export default function TermsConditions() {
  const dispatch = useDispatch();

  return (
    <>
      <Segment>
        <h1>TransActioner Terms of Use</h1>
        <p>Last Updated: October 30, 2023</p>
        <p>
          Welcome to TransActioner, the online real estate contracts software
          website and service, operated by Lean MVP LLC (“we” or “us”). In order
          to use TransActioner at TransActioner.com or our affiliated
          applications or websites, including without limitation any of our
          blogs (collectively the “Site”) and make use of the services offered
          by us (collectively with the Site, the “Services”), you must agree to
          be bound by these Terms of Use (the “Terms”), and our Privacy Policy,
          whether or not you register as a member (a "Member"). If you object to
          anything in these Terms or the Privacy Policy, you are prohibited from
          using the Services. These Terms and Privacy Policy are subject to
          change by us at any time, effective upon posting through the Services;
          by accessing the Services after any such change, you accept the Terms
          as modified. You acknowledge and agree that we shall not be liable to
          you or any third party for any such modification, change, suspension
          or discontinuance.
        </p>

        <h3>Eligibility</h3>
        <p>
          Adult Users Only. The Services are not intended for individuals under
          18 years of age. If you are under 18, you are strictly prohibited from
          using the Services, and you may not access any features that allow you
          to provide information to us or communicate and share information with
          other users. Accordingly, you represent and warrant that you are 18
          years of age or older and that you have the right, authority and
          capacity to enter agree to and abide by these Terms.
        </p>
        <h3>Void Where Prohibited</h3>
        <p>
          Void Where Prohibited. You are responsible for ensuring that your use
          of the Services complies with all laws, rules and regulations
          applicable to you. These Terms are void and use of the Services is not
          permitted where such use is prohibited.
        </p>
        <h3>Personal Safety</h3>
        <p>
          Personal Safety. Your security and safety are paramount to us. You
          should be aware that some of your profile information as a Member
          shall be public, could be indexed by Internet search engines and may
          be used for any purpose. We do not and cannot assure that it is safe
          for you to have direct contact with any other individual that you come
          into contact with through the Services. If you believe that any
          individual is harassing you or is otherwise using personal information
          about you for unlawful purposes, we encourage you to first inform
          local law enforcement authorities and then to contact us at
          info@TransActioner.com , so that we may take appropriate action to
          block further use of the Services by any individuals who may have
          accessed them for improper purposes.
        </p>

        <h3>Accurate Profile Information:</h3>
        <p>
          You agree that, as a Member, you will represent yourself accurately
          and completely in the information presented in your Member profile.
          For Real Estate agents, this includes an active real estate license in
          the state you are representing, and the brokerage where your real
          estate license is registered.
        </p>

        <h3>Prohibited Activities</h3>
        <p>
          When using TransActioner, you must adhere to the following guidelines
          and restrictions. Failure to comply with these rules may result in the
          termination of your account and access to the Service, and in some
          cases, legal action may be taken:
          <ol>
            <li>
              <strong>Illegal Activity:</strong> You shall not use TransActioner
              for any unlawful purpose or engage in any activity that violates
              local, state, federal, or international laws and regulations. This
              includes but is not limited to activities such as fraud, identity
              theft, money laundering, and any other illegal activities.
            </li>
            <li>
              <strong>Infringement of Rights:</strong> You must not post, share,
              or distribute content that infringes on the intellectual property
              rights, including copyrights, trademarks, or patents, of any third
              party. Respect the rights of others and ensure that your content
              is either original or properly licensed.
            </li>
            <li>
              <strong>Unauthorized Access:</strong> You are prohibited from
              attempting to access TransActioner's systems, user accounts, or
              data without proper authorization. This includes but is not
              limited to hacking, reverse engineering, or any unauthorized
              attempt to gain access to our platform.
            </li>
            <li>
              <strong>Harassment and Harm:</strong> You must not use the Service
              for any form of harassment, hate speech, bullying, or threats
              towards other users or any individuals. Promoting or glorifying
              harm, violence, self-harm, or suicide is strictly prohibited.
            </li>
            <li>
              <strong>Spam and Phishing:</strong> Any form of spam, phishing, or
              unsolicited commercial communication on TransActioner is strictly
              prohibited. This includes sending mass messages, deceptive links,
              or fraudulent schemes to other users.
            </li>
            <li>
              <strong>Disruption of Service:</strong> You shall not engage in
              any activity that disrupts or interferes with the proper
              functioning of TransActioner. This includes the use of bots,
              viruses, malware, or any action that could cause harm to our
              systems or other users.
            </li>
            <li>
              <strong>Impersonation:</strong> Impersonating another person or
              entity, whether it be a user, staff member, or any third party, is
              strictly prohibited. You must provide accurate and truthful
              information about your identity.
            </li>
            <li>
              <strong>Adult Content:</strong> TransActioner may have guidelines
              on the type of content allowed on the platform. Posting or sharing
              explicit adult content may be restricted or subject to specific
              guidelines.
            </li>
            <li>
              <strong>Solicitation:</strong> Using the Service to solicit or
              promote illegal or unethical activities, including but not limited
              to gambling, illegal drugs, or other prohibited goods or services,
              is not allowed.
            </li>
            <li>
              <strong>Harm to Minors:</strong> TransActioner is intended for
              users over the age of 18. Do not use the Service to engage with or
              harm minors in any way, including sharing inappropriate content or
              trying to gather personal information from minors.
            </li>
            <li>
              <strong>False Information:</strong> Providing false or misleading
              information, including false identity or qualifications, is not
              allowed.
            </li>
            <li>
              <strong>Malicious Software:</strong> You shall not upload, post,
              or transmit any malicious software, viruses, or any code designed
              to damage, disrupt, or limit the functionality of the Service.
            </li>
          </ol>
          TransActioner reserves the right to enforce these prohibitions and
          take appropriate action, which may include suspension or termination
          of your account, removal of content, and legal action if necessary. We
          encourage you to report any violations of these rules that you come
          across while using the Service.
        </p>
        <h2>Privacy and Data</h2>
        <p>
          Your privacy is important to us. This section outlines how
          TransActioner collects, uses, and protects your personal information.
          By using our Service, you agree to the following:
        </p>

        <h3>Information Collection</h3>
        <p>
          TransActioner may collect and store the following types of
          information:
        </p>
        <ul>
          <li>
            <strong>Personal Information:</strong> When you create an account or
            use our Service, we may collect personal information such as your
            name, email address, and other details you provide.
          </li>
          <li>
            <strong>Usage Data:</strong> We collect data about how you use our
            Service, including your interactions, preferences, and other usage
            patterns. This helps us improve our services.
          </li>
          <li>
            <strong>Cookies and Tracking:</strong> TransActioner uses cookies
            and similar tracking technologies to enhance your experience and for
            analytical purposes. You can manage cookie preferences through your
            browser settings.
          </li>
        </ul>

        <h3>Use of Information</h3>
        <p>
          We use the collected information for various purposes, including but
          not limited to:
        </p>
        <ul>
          <li>
            <strong>Service Improvement:</strong> Your information helps us
            enhance the quality and functionality of TransActioner, personalize
            your experience, and respond to your needs and preferences.
          </li>
          <li>
            <strong>Communication:</strong> We may use your contact information
            to send you important updates, announcements, and service-related
            messages.
          </li>
          <li>
            <strong>Analytics:</strong> Data is used for analytical and research
            purposes to understand how our Service is used and make informed
            decisions for its improvement.
          </li>
        </ul>

        <h3>Data Security</h3>
        <p>
          TransActioner takes data security seriously and employs various
          measures to protect your information. While we strive to protect your
          data, it's important to understand that no online service is
          completely secure. Please take steps to safeguard your account
          information and notify us of any unauthorized access.
        </p>

        <h3>Third-Party Services</h3>
        <p>
          TransActioner may integrate with third-party services, applications,
          or websites. These third parties may have their own privacy policies
          and practices, which are not covered by our Privacy Policy. Please
          review their policies to understand how your data is handled when
          interacting with these services.
        </p>

        <h3>Changes to Privacy Policy</h3>
        <p>
          TransActioner may update its Privacy Policy as needed. We will notify
          users of any changes through the Service or other communication
          methods. Continued use of our Service after the effective date of the
          revised Privacy Policy constitutes your acceptance of the changes.
        </p>

        <p>
          If you have questions or concerns regarding your privacy or data on
          TransActioner, please contact us at info@transactioner.com.
        </p>

        <h2>User Responsibility for Accuracy and Correctness</h2>
        <p>
          TransActioner provides software, documents, and forms for your
          convenience, but it is essential to understand that the use of these
          tools remains the sole responsibility of the user. While we make every
          effort to ensure the quality and functionality of the software and the
          accuracy of the documents and forms, there are limitations to what we
          can provide. Here's what you need to know:
        </p>

        <ol>
          <li>
            <strong>User Accountability:</strong> You, as the user, are
            responsible for the accuracy and correctness of any documents,
            forms, or data generated using our software. This includes, but is
            not limited to, legal documents, reports, contracts, or any other
            content created or modified through the use of TransActioner.
          </li>
          <li>
            <strong>Independent Legal Advice:</strong> Our software, documents,
            and forms are not a substitute for professional advice. If your use
            of TransActioner involves legal matters, we strongly recommend
            seeking independent legal counsel to ensure that the content you
            create or modify complies with applicable laws and regulations.
          </li>
          <li>
            <strong>Content Review:</strong> Before using or relying on any
            documents, forms, or content generated through TransActioner, it is
            your responsibility to thoroughly review and verify the information
            for accuracy and correctness. This includes confirming that the
            content is suitable for your specific needs and circumstances.
          </li>
          <li>
            <strong>User-Generated Content:</strong> Any information or content
            you input into TransActioner is your responsibility. We do not
            verify the accuracy of the data you provide. You are responsible for
            the truthfulness and legality of the information you enter.
          </li>
          <li>
            <strong>Updates and Changes:</strong> TransActioner may update and
            modify its software, documents, and forms over time. It is your
            responsibility to ensure that you are using the most current and
            up-to-date version of the software and content.
          </li>
          <li>
            <strong>Legal Compliance:</strong> The user is responsible for
            ensuring that any content generated through TransActioner complies
            with all relevant laws, regulations, and requirements in your
            jurisdiction.
          </li>
        </ol>

        <p>
          TransActioner disclaims any liability for errors, inaccuracies, or
          omissions in the software, documents, forms, or any other content
          provided through the Service. Our tools are designed to be helpful,
          but their use requires diligence and validation on your part.
        </p>

        <p>
          By using TransActioner, you acknowledge and accept these
          responsibilities for ensuring the accuracy and correctness of your use
          of the software, documents, and forms provided on the site. We
          encourage you to exercise caution, seek legal advice when necessary,
          and double-check all content created or modified through our Service
          to ensure its accuracy and compliance with applicable laws and
          regulations.
        </p>

        <h3>Code of Conduct.</h3>
        <p>
          By accessing the Services, you agree to the following code of conduct:
        </p>
        <ul>
          <li>You will always obey the law.</li>
          <li>
            You will not publish, display or use (hereinafter "post") any
            defamatory, abusive, obscene, profane, threatening, harassing,
            sexually oriented, racially offensive, discriminatory or otherwise
            offensive or illegal material.
          </li>
          <li>You will treat all fellow Members with dignity and respect.</li>
          <li>
            You will notify us promptly of any Member's violation of these
            Terms.
          </li>

          <li>You will not impersonate any person or entity.</li>

          <li>
            You will not express or imply that any statements you make are
            endorsed by us without specific prior written consent.
          </li>
          <li>
            You will not use manual or automatic processes to retrieve, index,
            datamine, or in any way reproduce or circumvent the navigational
            structure or presentation of the Services or their contents.
          </li>
          <li>
            You will not remove any copyright, trademark or other proprietary
            rights notices displayed through the Services.
          </li>
          <li>
            You will not post, distribute or reproduce in any way any
            copyrighted material, trademarks, or other proprietary information
            without obtaining the prior consent of the owner of such proprietary
            rights.
          </li>
          <li>
            You will not interfere with or disrupt the Services or the servers
            or networks connected to the Services.
          </li>
          <li>
            You will not post, email or otherwise transmit any material that
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer software or hardware or telecommunications
            equipment.
          </li>
          <li>
            You will not forge headers or otherwise manipulate identifiers in
            order to disguise the origin of any information transmitted through
            the Services.
          </li>
          <li>
            You will not "frame" or "mirror" any part of the Services without
            our prior written authorization. You also shall not use metatags or
            code or other devices containing any reference to the Services in
            order to direct any person to any other website for any purpose.
          </li>
          <li>
            You will not modify, adapt, sublicense, translate, sell, reverse
            engineer, decipher, decompile or otherwise disassemble any portion
            of the Services or any software used on or for the Services, or
            cause or assist others to do so.
          </li>
        </ul>

        <h3>Reservation of Rights:</h3>
        <p>
          You agree that we, at our sole discretion and at any time, for any
          reason or no reason whatsoever, can deny or block any user from the
          Services and terminate any user's account if he/she is a Member. You
          agree that we have the right to request complete, accurate and current
          information confirming eligibility for subscription and/or membership
          and you agree to provide that information in response to such a
          request.
        </p>

        <h3>Rights to Disclose and Privacy</h3>
        <h3>Disclosure by Law:</h3>
        <p>
          As stated in our Privacy Policy, you acknowledge and agree that we
          have the right to disclose information you provide if required to do
          so by law at the request of a third party, or if we, in our sole
          discretion, believe that disclosure is reasonable to (1) comply with
          the law, request or orders from law enforcement, or any legal process
          (whether or not such disclosure is required by applicable law); (2)
          protect or defend our, or a third party's, rights or property; or (3)
          protect someone's health or safety, such as when harm or violence
          against any person (including a user or Member) is threatened.
        </p>

        <h3>Right to Review Content; No Duty to Monitor:</h3>
        <p>
          You acknowledge and agree that we have no duty whatsoever to
          prescreen, control, monitor or edit the content posted by Members and
          we are not liable for content that is provided by others. However, you
          understand and agree that we may, but are not required to, review,
          edit and delete any content, email, messages, photos, dates or
          profiles that, in our sole judgment and discretion: (i) violate these
          Terms; (ii) may be offensive, disturbing, unsafe, or illegal; or (iii)
          may violate any rights of other Members or third parties.
        </p>

        <h3>Right to Conduct Research; Informed Consent:</h3>
        <p>
          By accessing the Services, you agree to allow us to anonymously
          collect and use information from you and your experience with the
          Services to conduct research about the Services and to improve the
          products and user experience therein. All such information collection
          and use will be in accordance with our Privacy Policy.
        </p>

        <h3>Account Security</h3>
        <p>
          User Obligations You agree that you are responsible for maintaining
          the confidentiality and security of the username and password that you
          designate during the process of becoming a Member, and you are fully
          responsible for anything that occurs under your username and password.
          You agree to immediately notify us of any unauthorized use of your
          username or password or any other breach of security and ensure that
          you exit from your account at the end of each session. You agree that
          we will not be liable for any loss or damage arising from your failure
          to comply with this provision. You agree to use extra care when
          accessing your account from a public or shared computer, so that
          others are not able to view or record your password or other personal
          information. If you share your computer with others, you may wish to
          consider disabling your auto-sign in feature.
        </p>

        <h3>Our Obligations:</h3>
        <p>
          We use industry-standard techniques to safeguard your account
          information. However, perfect security is not possible on the
          Internet, and therefore we cannot and do not guarantee that
          information provided to us will not become public under any
          circumstances. This is particularly true for any information you
          provide on public areas of the website, including public profile
          information, with is fully public, may be indexed by Internet search
          engines and may be used for any purpose.
        </p>

        <h3>Proprietary Rights; Use License</h3>
        <h3>Ownership:</h3>
        <p>
          We and our licensors own and retain all proprietary rights in the
          Services. The Services may contain the copyrighted material,
          trademarks, and other proprietary information of us and our licensors
          (the "Site Content"). Except for Site Content that is in the public
          domain or for which permission has been provided, you may not copy,
          modify, publish, transmit, distribute, perform, display, or sell any
          Site Content.
        </p>

        <h3>Use License:</h3>
        <p>
          Subject to these Terms, we grant to you a limited, revocable,
          non-exclusive, fully paid license to access the Site Content, for the
          sole and limited purpose of facilitating your use of the Services.
        </p>

        <h3>Membership:</h3>
        <p>
          You may become a Member at no cost. As a Member, you will have the
          ability to use some, but not all, of the available features of the
          Services. In order to access additional features, you may be required
          to become a paying subscriber.
        </p>

        <h3>Subscriptions and Billing:</h3>
        <p>
          Subscription Policies that are disclosed to you in subscribing to the
          Services are deemed part of these Terms. For the purposes of these
          Terms, the term "Member" includes subscribers, unless usage indicates
          otherwise. From time to time, we may remove the profiles of
          non-subscribers.
        </p>

        <h3>Billing:</h3>
        <p>
          We use a third-party payment processor (the "Payment Processor") to
          bill you through an online account (your " Billing Account") for use
          of the Services as a subscriber. The processing of payments will be
          subject to the terms, conditions and privacy policies of the Payment
          Processor in addition to these Terms. We are not responsible for error
          by the Payment Processor. By subscribing, you agree to pay us, through
          the Payment Processor, all charges at the prices then in effect for
          any use of the Services in accordance with the subscription plan that
          you have selected using your Billing Account, and you authorize us,
          through the Payment Processor, to charge your chosen payment provider
          (your "Payment Method"). You agree to make payment using that selected
          Payment Method. We reserve the right to correct any errors or mistakes
          that it makes even if it has already requested or received payment.
        </p>

        <h3>Payment Method:</h3>
        <p>
          The terms of your payment will be based on your Payment Method and may
          be determined by agreements between you and the financial institution,
          credit card issuer or other provider of your chosen Payment Method. If
          we, through the Payment Processor, do not receive payment from you,
          you agree to pay all amounts due on your Billing Account upon demand.
          You agree that we may accumulate charges incurred and submit them as
          one or more aggregate charges during or at the end of each billing
          cycle. Your charges may be payable in advance, in arrears, per usage,
          or as otherwise described when you initially subscribed to the
          Services.
        </p>

        <h3>Recurring Billing and Automatic Renewal:</h3>
        <p>
          Subscription plans to the Services may consist of an initial period,
          for which there is a one-time charge, followed by recurring period
          charges as agreed to by you. By choosing a recurring subscription
          plan, you acknowledge that your subscription has an initial and
          recurring payment feature and you accept responsibility for all
          recurring charges prior to cancellation. Unless you opt out of
          auto-renewal, which can be done through your Account Settings, your
          subscription to the Services will be automatically extended for
          successive renewal periods of the same duration as the subscription
          term originally selected, at the same subscription rate.
        </p>

        <p>
          Please note, if you deactivate your account during a paid subscription
          you forfeit the remainder of your subscription and cannot be refunded.
          Also, any refund granted for a subscription purchased beyond 120 days
          will be issued by check refund.
        </p>

        <p>
          Free Trials and Other Promotions. Any free trial or other promotion
          that provides subscriber-level access to the Services must be used
          within the specified time of the trial. You must cancel your
          subscription before the end of the trial period in order to avoid
          being charged a subscription fee. If you cancel prior to the end of
          the trial period and are inadvertently charged for a subscription,
          please contact us at info@transactioner.com.
        </p>

        <h3>Mobile Applications:</h3>
        <p>
          Mobile functionality of the Services may be provided for free, but
          please be aware that your carrier's normal rates and fees, such as
          message and data rates, may still apply and you are solely responsible
          for those fees. To the extent necessary, in the event you change or
          deactivate your mobile telephone number, you agree that you will
          update your account information within 48 hours to ensure that your
          messages are not sent to the person who acquires your old number.
        </p>

        <h3>Interaction with Us or Our Agents:</h3>
        <p>
          We may provide assistance and guidance to users and Members through
          our employees or agents. When communicating with those individuals,
          you may not be abusive, obscene, profane, offensive, sexist,
          threatening, harassing, racially offensive, or otherwise behave
          inappropriately. If we feel that your behavior toward any of our
          customer care representatives or other employees is at any time
          threatening or offensive, we reserve the right to immediately
          terminate your membership.
        </p>

        <h3>Termination</h3>
        <p>
          You may terminate your membership and/or subscription at any time, for
          any reason, by following the instructions in your Settings or email
          notice of termination to info@transactioner.com. We, at our sole
          discretion, may terminate your membership and/or subscription for any
          reason or no reason. We are not required to provide you notice prior
          to terminating your membership and/or subscription. We are not
          required, and may be prohibited, from disclosing a reason for the
          termination of your account.
        </p>

        <p>
          If you terminate your subscription, your subscription will remain
          active until the end of your then-current subscription period (that
          is, the subscription period through which you had paid prior to your
          termination). If we terminate your membership because you have
          breached these Terms, you will not be entitled to any refund of unused
          subscription fees.
        </p>

        <h3>Disclaimers/Limitations</h3>
        <ul>
          <li>
            We are not responsible for any incorrect or inaccurate content
            posted through the Services, whether caused by users, Members or by
            any of the equipment or programming associated with or utilized in
            the Services.
          </li>
          <li>
            We assume no responsibility for any error, omission, interruption,
            deletion, defect, delay in operation or transmission, communications
            line failure, theft or destruction or unauthorized access to, or
            alteration of, user or Member communications.
          </li>
          <li>
            We are not responsible for any problems or technical malfunction of
            any telephone network or lines, computer online systems, servers or
            providers, computer equipment, software, failure of email or players
            on account of technical problems or traffic congestion on the
            Internet or at any website or combination thereof, including injury
            or damage to users and/or Members or to any other person's computer
            related to or resulting from participating or downloading materials
            in connection with the Services.
          </li>
          <li>
            Under no circumstances will we or any of our affiliates,
            advertisers, promoters or distribution partners be responsible for
            any loss or damage, including personal injury or death, resulting
            from use of the Services, any content posted on the Services or
            transmitted to Members, or any interactions between users, whether
            online or offline.
          </li>
          <li>
            We provide the SERVICES "AS IS, AS AVAILABLE," without any warranty
            or condition of any kind (express, implied or statutory) AND YOUR
            ACCESS OF the site IS AT YOUR OWN RISK. We do not warrant that our
            services will meet your requirements or result in any particular
            outcome, or that the operation will be uninterrupted or error-free.
            To the fullest extent allowed by law, we specifically disclaim any
            implied warranties of title, merchantability, non-infringement and
            fitness for a particular purpose. SOME STATES DO NOT ALLOW THE
            DISCLAIMER OF IMPLIED WARRANTIES, SO THE FOREGOING DISCLAIMER MAY
            NOT APPLY TO YOU.
          </li>
          <li>
            We shall not be liable to you for any DIRECT, special, incidental,
            indirect, punitive or consequential damages (even if you have been
            advised us of the possibility of such damages). Our cumulative
            liability relating to your use of the SERVICES (regardless of the
            basis), shall not exceed the greater of (a) amounts that you paid to
            us in the previous twelve months and (b) $100. Some states do not
            allow the exclusion or limitation of incidental or consequential
            damages, so the above limitation or exclusion may not apply to you.
          </li>
        </ul>

        <h3>Third Party Links:</h3>
        <p>
          You may be provided links to other websites or resources through the
          Services. Because we have no control over such sites and resources,
          you acknowledge and agree that we are not responsible for the
          availability of such external sites or resources, and do not endorse
          and are not responsible or liable for any content, advertising,
          products or other materials on or available from such sites or
          resources. You further acknowledge and agree that we shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with the use of, or
          reliance upon, any such content, goods or services available on or
          through any such site or resource. You represent and warrant that you
          are not located in, under the control of, or a national or resident of
          any country which the United States has (a) embargoed goods, (b)
          identified as a "Specially Designated National," or (c) placed on the
          Commerce Department's Table of Deny Orders. Registration for, and use
          of, the Services are void where prohibited. To the extent that the
          Services are not legal in your jurisdiction, you may not use the
          Services; the Services may not be used where prohibited by law.
        </p>

        <h3>Arbitration and Governing Law:</h3>
        <p>
          The exclusive means of resolving any dispute or claim arising out of
          or relating to these Terms (including any alleged breach thereof), the
          Services, or the Site shall be BINDING ARBITRATION administered by the
          American Arbitration Association. The one exception to the exclusivity
          of arbitration is that you have the right to bring an individual claim
          against us in a small-claims court of competent jurisdiction. But
          whether you choose arbitration or small-claims court, you may not
          under any circumstances commence or maintain against us any class
          action, class arbitration, or other representative action or
          proceeding.
        </p>

        <p>
          By using the Site or the Services in any manner, you agree to the
          above arbitration agreement. In doing so, YOU GIVE UP YOUR RIGHT TO GO
          TO COURT to assert or defend any claims between you and us (except for
          matters that may be taken to small-claims court). YOU ALSO GIVE UP
          YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS PROCEEDING.
          Your rights will be determined by a NEUTRAL ARBITRATOR, NOT A JUDGE OR
          JURY. You are entitled to a fair hearing before the arbitrator. The
          arbitrator can grant any relief that a court can, but you should note
          that arbitration proceedings are usually simpler and more streamlined
          than trials and other judicial proceedings. Decisions by the
          arbitrator are enforceable in court and may be overturned by a court
          only for very limited reasons.
        </p>

        <p>
          Any proceeding to enforce this arbitration agreement, including any
          proceeding to confirm, modify, or vacate an arbitration award, may be
          commenced in any court of competent jurisdiction. In the event that
          this arbitration agreement is for any reason held to be unenforceable,
          any litigation against us (except for small-claims court actions) may
          be commenced only in the federal or state courts located in New York,
          New York. You hereby irrevocably consent to the jurisdiction of those
          courts for such purposes.
        </p>

        <p>
          These Terms, and any dispute between you and us, shall be governed by
          the laws of the state of New York without regard to principles of
          conflicts of law, provided that this arbitration agreement shall be
          governed by the Federal Arbitration Act.
        </p>

        <h3>Miscellaneous</h3>
        <h3>Indemnity by You:</h3>
        <p>
          You agree to indemnify and hold us, our subsidiaries, affiliates,
          officers, agents, and other partners and employees, harmless from any
          loss, liability, claim, or demand, including reasonable attorney's
          fees, made by any third party due to or arising out of your use of the
          Services in violation of these Terms and/or arising from a breach of
          these Terms and/or any breach of your representations and warranties
          set forth above.
        </p>

        <h3>Entire Agreement; Interpretation:</h3>
        <p>
          These Terms contain the entire agreement between you and us regarding
          the use of the Services. If any provision of these Terms is held to be
          invalid, illegal or unenforceable in any respect, that provision shall
          be limited or eliminated to the minimum extent necessary so that these
          Terms shall otherwise remain in full force and effect and enforceable.
          The section and paragraph headings in these Terms are for convenience
          only and shall not affect the interpretation of these Terms. You agree
          that, except as otherwise expressly provided in these Terms there
          shall be no third party beneficiaries.
        </p>

        <h3>Survival:</h3>
        <p>
          Even after your membership or subscription is terminated, or your use
          of the Services discontinues, these Terms will remain in effect. All
          terms that by their nature may survive termination of these Terms
          shall be deemed to survive such termination.
        </p>

        <h3>Waiver:</h3>
        <p>
          Our failure to enforce any part of these Terms shall not constitute a
          waiver of our right to later enforce that or any other part of these
          Terms. Waiver of compliance in any particular instance does not mean
          that we will do so in the future. In order for any waiver of
          compliance with these Terms to be binding, we must provide you with
          written notice of such waiver, provided by one of our authorized
          representatives.
        </p>

        <h3>Relationship of the Parties:</h3>
        <p>
          You and we are independent contractors, and no agency, partnership,
          joint venture, employee-employer or franchisor-franchisee relationship
          is intended or created by these Terms.
        </p>

        <h3>Special State Terms:</h3>
        <p>
          The following provisions are added to this Agreement for subscribers
          residing in Arizona, California, Connecticut, Illinois, Iowa,
          Minnesota, New York, North Carolina, Ohio and Wisconsin: You, the
          buyer, may cancel this agreement, without penalty or obligation, at
          any time prior to midnight of the third business day following the
          original date of this contract, excluding Sundays and holidays. To
          cancel this agreement, email a dated notice which states that you, the
          buyer, are canceling this agreement, or words of similar effect. This
          notice shall be sent to: info@transactioner.com. Please include your
          username and email address in any correspondence or your refund may be
          delayed. If you cancel, we will return, within thirty days of the date
          on which you give notice of cancellation, any payments you have made.
          If you send or deliver the notice to cancel your subscription
          agreement within such three day period, we will refund the full amount
          of your subscription.
        </p>

        <p>
          In the event that you die before the end of your subscription period,
          your estate shall be entitled to a refund of that portion of any
          payment you had made for your subscription which is allocable to the
          period after your death. In the event that you become disabled (such
          that you are unable to use the Services) before the end of your
          subscription period, you shall be entitled to a refund of that portion
          of any payment you had made for your subscription which is allocable
          to the period after your disability by providing the company notice at
          the same address as listed above.
        </p>

        <p>
          Please contact us at info@transactioner.com with any questions
          regarding these Terms. Transactioner.com is a service mark of Lean MVP
          LLC, all rights reserved. I HAVE READ THESE TERMS AND AGREE TO ALL OF
          THE PROVISIONS CONTAINED ABOVE.
        </p>
        <br />
        <Divider hidden />
        <Button
          onClick={() => dispatch(closeModal({ modalType: "TermsConditions" }))}
          fluid
          primary
          size="large"
          content="Close"
        />
      </Segment>
    </>
  );
}
