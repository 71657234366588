export function leadBasedPaintWarning() {
return [   //2024 Release 2024-06-07 09:34:34
{
   page: 0,
   name: "hazards is recommended prior to purchase", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 222.72,
   left: 221.28,
   width: 349.56,
   height: 16.80
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 240,
   left: 221.28,
   width: 349.56,
   height: 14.52
}
,
{
   page: 0,
   name: "Explain", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 314.16,
   left: 144.12,
   width: 431.40,
   height: 16.80
}
,
{
   page: 0,
   name: "List documents", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 416.76,
   left: 180.96,
   width: 393.24,
   height: 16.80
}
,
{
   page: 0,
   name: "Check Box88", 
   isText: false,
   type: "checkbox",
   top: 296.51,
   left: 57.6003,
   width: 15.3855,
   height: 15.386
}
,
{
   page: 0,
   name: "Check Box89", 
   isText: false,
   type: "checkbox",
   top: 332.511,
   left: 57.6003,
   width: 15.3855,
   height: 15.386
}
,
{
   page: 0,
   name: "Check Box90", 
   isText: false,
   type: "checkbox",
   top: 380.293,
   left: 57.6003,
   width: 15.3855,
   height: 15.386
}
,
{
   page: 0,
   name: "Check Box91", 
   isText: false,
   type: "checkbox",
   top: 434.62,
   left: 57.6003,
   width: 15.3855,
   height: 15.386
}
,
{
   page: 0,
   name: "signature agent initials", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 662.712,
   left: 49.0911,
   width: 43.9632,
   height: 16.764
}
,
{
   page: 1,
   name: "1_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 57.426,
   left: 221.88,
   width: 338.52,
   height: 16.374
}
,
{
   page: 1,
   name: "2_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 74.28,
   left: 221.76,
   width: 338.64,
   height: 14.52
}
,
{
   page: 1,
   name: "signature Agent", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 223.851,
   left: 36.0,
   width: 178.2,
   height: 17.029
}
,
{
   page: 1,
   name: "Date_3", 
   fontSize: 9,
   type: "date",
   top: 223.196,
   left: 219.12,
   width: 73.56,
   height: 17.684
}
,
{
   page: 1,
   name: "signature Agent_2", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 223.851,
   left: 324.0,
   width: 178.32,
   height: 17.029
}
,
{
   page: 1,
   name: "Date_6", 
   fontSize: 9,
   type: "date",
   top: 222.542,
   left: 507.24,
   width: 68.04,
   height: 18.338
}
] }
