import React from "react";
import { isSameDay } from "date-fns";
import { Table, Popup, Icon } from "semantic-ui-react";
import { dateFormatDeadline } from "../../../../app/common/util/util";
import TaskActionButtons from "../../taskComponents/TaskActionButtons";

export default function TaskTransActiveWithDateListItem({ task }) {
  return (
    <Table.Row
      key={task.id}
      className={
        task.status === "Complete"
          ? "background-green"
          : isSameDay(task.end, new Date())
          ? "background-blue"
          : // : isPast(task.end)
            // ? "background-yellow"
            ""
      }
    >
      <Table.Cell>{task.end && dateFormatDeadline(task.end)}</Table.Cell>
      <Table.Cell>
        {task.title}{" "}
        {task.description && (
          <Popup
            trigger={
              <Icon
                name="info"
                color="blue"
                circular
                size="small"
                style={{ marginLeft: "3px", marginBottom: "3px" }}
              />
            }
          >
            {task.description}
          </Popup>
        )}
      </Table.Cell>
      <Table.Cell>
        <TaskActionButtons task={task} />
      </Table.Cell>
    </Table.Row>
  );
}
