export function disclosureofFamilialandBeneficialInterest() {
return [   //2024 Release 2024-02-20 14:16:48
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 131.04,
   width: 462.96,
   height: 18.00
}
,
{
   page: 0,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 279.565,
   left: 36.28,
   width: 10.08,
   height: 15.642
}
,
{
   page: 0,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 298.92,
   left: 306.33,
   width: 133.59,
   height: 11.455
}
,
{
   page: 0,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 316.92,
   left: 160.56,
   width: 68.40,
   height: 11.455
}
,
{
   page: 0,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 334.92,
   left: 117.36,
   width: 166.20,
   height: 11.455
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 354.445,
   left: 36.28,
   width: 10.08,
   height: 15.642
}
,
{
   page: 0,
   name: "Text Field 29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 373.28,
   left: 305.0,
   width: 115.687,
   height: 11.455
}
,
{
   page: 0,
   name: "Text Field 32", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 391.0,
   left: 138.55,
   width: 63.33,
   height: 11.455
}
,
{
   page: 0,
   name: "Text Field 35", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 391.28,
   left: 390.24,
   width: 154.44,
   height: 11.455
}
,
{
   page: 0,
   name: "Text Field 36", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.8,
   left: 50.4,
   width: 166.2,
   height: 11.455
}
,
{
   page: 0,
   name: "Check Box 8", 
   isText: false,
   type: "checkbox",
   top: 447.325,
   left: 36.28,
   width: 10.08,
   height: 15.642
}
,
{
   page: 0,
   name: "Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 466.16,
   left: 305.0,
   width: 115.687,
   height: 11.455
}
,
{
   page: 0,
   name: "Text Field 33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 484.16,
   left: 139.085,
   width: 63.330,
   height: 11.455
}
,
{
   page: 0,
   name: "Text Field 37", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 484.44,
   left: 342.467,
   width: 161.533,
   height: 11.455
}
,
{
   page: 0,
   name: "signature Text Field 46", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 682.9465,
   left: 36.28,
   width: 178.06,
   height: 11.4545
}
,
{
   page: 0,
   name: "date Text Field 40", 
   fontSize: 9,
   type: "date",
   top: 682.9465,
   left: 216.75,
   width: 86.03,
   height: 11.4545
}
,
{
   page: 0,
   name: "signature Text Field 47", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 682.9465,
   left: 309.0,
   width: 178.06,
   height: 11.4545
}
,
{
   page: 0,
   name: "date Text Field 43", 
   fontSize: 9,
   type: "date",
   top: 682.9465,
   left: 489.47,
   width: 86.03,
   height: 11.4545
}
] }
