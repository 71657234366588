export function contracttoBuyandSellIncome() {
    return [   //2023
    {
       page: 0,
       name: "14 Units", 
       isText: false,
       type: "checkbox",
       top: 159.72,
       left: 217.92,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Larger than 14 Units", 
       isText: false,
       type: "checkbox",
       top: 159.72,
       left: 284.16,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 183.36,
       left: 434.76,
       width: 138.00,
       height: 10.80
    }
    ,
    {
       page: 0,
       name: "Buyer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 285.24,
       left: 138,
       width: 349.32,
       height: 10.80
    }
    ,
    {
       page: 0,
       name: "Joint Tenants", 
       isText: false,
       type: "checkbox",
       top: 297.84,
       left: 194.16,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Tenants In Common", 
       isText: false,
       type: "checkbox",
       top: 297.84,
       left: 271.68,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "No Assignability This Contract IS NOT assignable by Buyer unless otherwise specified in Additional Provisions", 
       isText: false,
       type: "checkbox",
       top: 297.84,
       left: 379.2,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Other", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 296.76,
       left: 420.6,
       width: 152.04,
       height: 10.80
    }
    ,
    {
       page: 0,
       name: "Seller", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 325.8,
       left: 136.32,
       width: 351.00,
       height: 10.8
    }
    ,
    {
       page: 0,
       name: "Property The Property is the following legally described real estate in the County of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 348.84,
       left: 443.88,
       width: 83.88,
       height: 10.80
    }
    ,
    {
       page: 0,
       name: "Text47", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 12,
       top: 370.474,
       left: 72.6549,
       width: 498.7661,
       height: 56.292
    }
    ,
    {
       page: 0,
       name: "known as", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 429.24,
       left: 96.24,
       width: 476.40,
       height: 10.80
    }
    ,
    {
       page: 0,
       name: "including", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 538.68,
       left: 94.08,
       width: 34.56,
       height: 11.193
    }
    ,
    {
       page: 0,
       name: "Solar Panels", 
       isText: false,
       type: "checkbox",
       top: 539.76,
       left: 462.48,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Water", 
       isText: false,
       type: "checkbox",
       top: 539.76,
       left: 535.32,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Security Systems", 
       isText: false,
       type: "checkbox",
       top: 551.28,
       left: 98.04,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Satellite Systems including satellite dishes Leased items should be listed under  257", 
       isText: false,
       type: "checkbox",
       top: 551.28,
       left: 193.92,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Text48", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 654.548,
       left: 72.0003,
       width: 506.6207,
       height: 55.6369
    }
    ,
    {
       page: 0,
       name: "If the box is checked Buyer and Seller have concurrently entered into a separate agreement for additional personal", 
       isText: false,
       type: "checkbox",
       top: 712.44,
       left: 105,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 1,
       name: "Text49", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 92.291,
       left: 72.6549,
       width: 488.9471,
       height: 34.037
    }
    ,
    {
       page: 1,
       name: "Note to Buyer If exact rights to the parking and storage facilities is a concern to Buyer Buyer should investigate", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 160.8,
       left: 50.4,
       width: 144.12,
       height: 10.8
    }
    ,
    {
       page: 1,
       name: "undefined", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 160.8,
       left: 440.04,
       width: 132.60,
       height: 10.8
    }
    ,
    {
       page: 1,
       name: "Text50", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 12,
       top: 206.183,
       left: 73.3094,
       width: 486.3296,
       height: 48.436
    }
    ,
    {
       page: 1,
       name: "Text51", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 12,
       top: 265.092,
       left: 74.6185,
       width: 485.0205,
       height: 32.727
    }
    ,
    {
       page: 1,
       name: "Check Box84", 
       isText: false,
       type: "checkbox",
       top: 311.563,
       left: 51.9272,
       width: 9.5999,
       height: 9.164
    }
    ,
    {
       page: 1,
       name: "Text52", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 12,
       top: 322.692,
       left: 73.3094,
       width: 489.6026,
       height: 33.382
    }
    ,
    {
       page: 1,
       name: "Other Rights Relating to Water The following rights relating to water not included in  271 273 and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 356.52,
       left: 375,
       width: 107.64,
       height: 10.80
    }
    ,
    {
       page: 1,
       name: "Check Box85", 
       isText: false,
       type: "checkbox",
       top: 369.163,
       left: 51.0544,
       width: 9.6000,
       height: 9.163
    }
    ,
    {
       page: 1,
       name: "Text53", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 392.729,
       left: 72.6549,
       width: 485.6751,
       height: 44.509
    }
    ,
    {
       page: 1,
       name: "Check Box86", 
       isText: false,
       type: "checkbox",
       top: 438.544,
       left: 51.0544,
       width: 9.1636,
       height: 8.291
    }
    ,
    {
       page: 1,
       name: "274", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 505.167,
       left: 50.4,
       width: 90.0,
       height: 11.673
    }
    ,
    {
       page: 1,
       name: "Check Box83", 
       isText: false,
       type: "checkbox",
       top: 518.835,
       left: 51.4908,
       width: 9.1636,
       height: 10.037
    }
    ,
    {
       page: 1,
       name: "Text54", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 530.839,
       left: 72.6549,
       width: 482.4021,
       height: 28.800
    }
    ,
    {
       page: 1,
       name: "Does", 
       isText: false,
       type: "checkbox",
       top: 599.04,
       left: 267.84,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 1,
       name: "Does Not have a Right to Terminate if examination of the Water", 
       isText: false,
       type: "checkbox",
       top: 599.04,
       left: 306.72,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 1,
       name: "Date or DeadlineTime of Day Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 674.843,
       left: 351.84,
       width: 146.134,
       height: 11.557
    }
    ,
    {
       page: 1,
       name: "Date or DeadlineAlternative Earnest Money Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 687.36,
       left: 351.84,
       width: 146.134,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "DateField Date or DeadlineAlternative Earnest Money Deadline", 
       fontSize: 9,
       type: "date",
       top: 686.82,
       left: 498.838,
       width: 79.916,
       height: 11.0405
    }
    ,
    {
       page: 1,
       name: "Date or DeadlineRecord Title Deadline and Tax Certificate", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 711.36,
       left: 351.84,
       width: 146.134,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "DateField Date or DeadlineRecord Title Deadline and Tax Certificate", 
       fontSize: 9,
       type: "date",
       top: 710.8205,
       left: 498.838,
       width: 79.916,
       height: 11.0400
    }
    ,
    {
       page: 1,
       name: "Date or DeadlineRecord Title Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 723.36,
       left: 351.84,
       width: 146.134,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "DateField Date or DeadlineRecord Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 722.8205,
       left: 498.838,
       width: 79.916,
       height: 11.0400
    }
    ,
    {
       page: 2,
       name: "OffRecord Title Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 58.996,
       left: 359.84,
       width: 142.233,
       height: 10.604
    }
    ,
    {
       page: 2,
       name: "DateField OffRecord Title Deadline", 
       fontSize: 9,
       type: "date",
       top: 58.473,
       left: 503.355,
       width: 75.906,
       height: 11.476
    }
    ,
    {
       page: 2,
       name: "OffRecord Title Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 70.036,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField OffRecord Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 70.036,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Title Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 82.036,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Title Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 82.036,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Third Party Right to PurchaseApprove Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 94.036,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Third Party Right to PurchaseApprove Deadline", 
       fontSize: 9,
       type: "date",
       top: 94.036,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Association Documents Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 118.036,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Association Documents Deadline", 
       fontSize: 9,
       type: "date",
       top: 118.036,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Association Documents Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 130.036,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Association Documents Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 130.036,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Sellers Property Disclosure Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 154.036,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Sellers Property Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 154.036,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "LeadBased Paint Disclosure Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 166.036,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField LeadBased Paint Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 166.036,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "New Loan Application Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 190.036,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField New Loan Application Deadline", 
       fontSize: 9,
       type: "date",
       top: 190.036,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "New Loan Terms Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 202.036,
       left: 359.84,
       width: 142.233,
       height: 11.16
    }
    ,
    {
       page: 2,
       name: "DateField New Loan Terms Deadline", 
       fontSize: 9,
       type: "date",
       top: 202.036,
       left: 503.355,
       width: 75.906,
       height: 11.160
    }
    ,
    {
       page: 2,
       name: "New Loan Availability Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 214.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField New Loan Availability Deadline", 
       fontSize: 9,
       type: "date",
       top: 214.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Buyers Credit Information Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 226.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Buyers Credit Information Deadline", 
       fontSize: 9,
       type: "date",
       top: 226.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Disapproval of Buyers Credit Information Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 238.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Disapproval of Buyers Credit Information Deadline", 
       fontSize: 9,
       type: "date",
       top: 238.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Existing Loan Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 250.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Existing Loan Deadline", 
       fontSize: 9,
       type: "date",
       top: 250.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Existing Loan Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 262.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Existing Loan Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 262.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Loan Transfer Approval Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 274.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Loan Transfer Approval Deadline", 
       fontSize: 9,
       type: "date",
       top: 274.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Seller or Private Financing Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 286.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Seller or Private Financing Deadline", 
       fontSize: 9,
       type: "date",
       top: 286.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Appraisal Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 310.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Appraisal Deadline", 
       fontSize: 9,
       type: "date",
       top: 310.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Appraisal Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 322.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Appraisal Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 322.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Appraisal Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 334.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Appraisal Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 334.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "New ILC or New Survey Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 358.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField New ILC or New Survey Deadline", 
       fontSize: 9,
       type: "date",
       top: 358.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "New ILC or New Survey Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 370.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField New ILC or New Survey Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 370.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "New ILC or New Survey Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 382.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField New ILC or New Survey Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 382.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Water Rights Examination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 406.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Water Rights Examination Deadline", 
       fontSize: 9,
       type: "date",
       top: 406.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Mineral Rights Examination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 418.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Mineral Rights Examination Deadline", 
       fontSize: 9,
       type: "date",
       top: 418.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Inspection Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 430.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Inspection Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 430.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Inspection Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 442.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Inspection Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 442.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Inspection Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 454.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Inspection Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 454.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Property Insurance Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 466.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Property Insurance Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 466.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Due Diligence Documents Delivery Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 478.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Due Diligence Documents Delivery Deadline", 
       fontSize: 9,
       type: "date",
       top: 478.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Due Diligence Documents Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 490.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Due Diligence Documents Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 490.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Due Diligence Documents Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 502.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Due Diligence Documents Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 502.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Environmental Inspection Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 514.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Environmental Inspection Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 514.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "ADA Evaluation Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 526.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField ADA Evaluation Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 526.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Conditional Sale Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 538.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Conditional Sale Deadline", 
       fontSize: 9,
       type: "date",
       top: 538.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "LeadBased Paint Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 550.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField LeadBased Paint Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 550.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Estoppel Statements Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 562.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Estoppel Statements Deadline", 
       fontSize: 9,
       type: "date",
       top: 562.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Estoppel Statements Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 574.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Estoppel Statements Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 574.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Closing Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 598.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Closing Date", 
       fontSize: 9,
       type: "date",
       top: 598.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Possession Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 610.156,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Possession Date", 
       fontSize: 9,
       type: "date",
       top: 610.156,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Possession Time", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 622,
       left: 359.84,
       width: 142.233,
       height: 11.16
    }
    ,
    {
       page: 2,
       name: "Acceptance Deadline Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 634.276,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "DateField Acceptance Deadline Date", 
       fontSize: 9,
       type: "date",
       top: 634.276,
       left: 503.355,
       width: 75.906,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Acceptance Deadline Time", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 646,
       left: 359.84,
       width: 142.233,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Will", 
       isText: false,
       type: "checkbox",
       top: 226.08,
       left: 88.92,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 3,
       name: "Will Not be extended to the next day that is not a Saturday Sunday or Holiday Should neither box be checked", 
       isText: false,
       type: "checkbox",
       top: 226.08,
       left: 124.92,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 3,
       name: "Amount", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 301.44,
       left: 380.291,
       width: 89.400,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "fill_25", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 313.44,
       left: 481.8,
       width: 89.4,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "fill_27", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 325.44,
       left: 481.8,
       width: 89.4,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "fill_29", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 337.44,
       left: 481.8,
       width: 89.4,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "fill_31", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 349.2,
       left: 481.8,
       width: 89.4,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "7_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 361.2,
       left: 482.4,
       width: 89.4,
       height: 11.160
    }
    ,
    {
       page: 3,
       name: "fill_32", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 397.2,
       left: 481.8,
       width: 89.4,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "fill_34", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 409.32,
       left: 380.291,
       width: 89.40,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "fill_35", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 410.16,
       left: 481.92,
       width: 89.40,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "Concession may be used for any Buyer fee cost charge or expenditure to the extent the amount is allowed by the Buyers lender", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 427.32,
       left: 368.28,
       width: 69.84,
       height: 12.065
    }
    ,
    {
       page: 3,
       name: "Earnest Money Holder in its trust account on behalf of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 496.44,
       left: 432.12,
       width: 109.92,
       height: 12.938
    }
    ,
    {
       page: 3,
       name: "both Seller and Buyer The Earnest Money deposit must be tendered by Buyer with this Contract unless the parties mutually agree", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 507.96,
       left: 143.76,
       width: 199.92,
       height: 12.502
    }
    ,
    {
       page: 4,
       name: "Available Funds Buyer represents that Buyer as of the date of this Contract", 
       isText: false,
       type: "checkbox",
       top: 173.4,
       left: 462,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "Does_2", 
       isText: false,
       type: "checkbox",
       top: 173.4,
       left: 502.2,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "172", 
       isText: false,
       type: "checkbox",
       top: 276.96,
       left: 51,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "Conventional", 
       isText: false,
       type: "checkbox",
       top: 276.96,
       left: 127.32,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "FHA", 
       isText: false,
       type: "checkbox",
       top: 276.96,
       left: 167.52,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "VA", 
       isText: false,
       type: "checkbox",
       top: 276.96,
       left: 200.88,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "Bond", 
       isText: false,
       type: "checkbox",
       top: 276.96,
       left: 242.64,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "If either or both of the FHA or VA boxes are checked and Buyer closes the transaction using one of those loan types Seller agrees", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 276.36,
       left: 284.28,
       width: 288.36,
       height: 10.32
    }
    ,
    {
       page: 4,
       name: "Loan Estimate  Monthly Payment and Loan Costs Buyer is advised to review the terms conditions and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 299.4,
       left: 411.24,
       width: 161.40,
       height: 10.32
    }
    ,
    {
       page: 4,
       name: "set forth in  41 Price and Terms presently payable at", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 368.04,
       left: 282.96,
       width: 70.08,
       height: 11.629
    }
    ,
    {
       page: 4,
       name: "including principal and interest", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 368.04,
       left: 370.08,
       width: 80.16,
       height: 11.193
    }
    ,
    {
       page: 4,
       name: "presently at the rate of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 379.56,
       left: 142.2,
       width: 39.84,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "per annum and also including escrow for the following as indicated", 
       isText: false,
       type: "checkbox",
       top: 380.4,
       left: 470.4,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "Real Estate Taxes", 
       isText: false,
       type: "checkbox",
       top: 380.4,
       left: 565.32,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "Property Insurance Premium", 
       isText: false,
       type: "checkbox",
       top: 391.92,
       left: 182.16,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "Mortgage Insurance Premium and", 
       isText: false,
       type: "checkbox",
       top: 391.92,
       left: 347.76,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "At the time of assumption the new interest rate will", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 391.32,
       left: 361.08,
       width: 211.56,
       height: 11.629
    }
    ,
    {
       page: 4,
       name: "per annum and the new payment will not exceed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 402.36,
       left: 294.48,
       width: 64.80,
       height: 12.065
    }
    ,
    {
       page: 4,
       name: "interest plus escrow if any If the actual principal balance of the existing loan at Closing is less than the Assumption Balance which", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 413.88,
       left: 97.44,
       width: 39.84,
       height: 11.193
    }
    ,
    {
       page: 4,
       name: "per", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 413.88,
       left: 355.92,
       width: 65.04,
       height: 11.193
    }
    ,
    {
       page: 4,
       name: "principal and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 413.88,
       left: 439.8,
       width: 79.92,
       height: 12.502
    }
    ,
    {
       page: 4,
       name: "provisions of the loan change Buyer has the Right to Terminate under  241 on or before Closing Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 436.92,
       left: 411.96,
       width: 64.80,
       height: 12.065
    }
    ,
    {
       page: 4,
       name: "Seller_2", 
       isText: false,
       type: "checkbox",
       top: 461.04,
       left: 99.24,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "Will_2", 
       isText: false,
       type: "checkbox",
       top: 461.04,
       left: 135.96,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "from liability will be evidenced by delivery", 
       isText: false,
       type: "checkbox",
       top: 472.56,
       left: 231.36,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "on or before Loan Transfer Approval Deadline", 
       isText: false,
       type: "checkbox",
       top: 472.56,
       left: 449.04,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "letter of commitment from lender Any cost payable for release of liability will be paid by", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 483.48,
       left: 412.2,
       width: 106.56,
       height: 10.32
    }
    ,
    {
       page: 4,
       name: "Seller or Private Financing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 494.52,
       left: 111.36,
       width: 65.04,
       height: 11.629
    }
    ,
    {
       page: 4,
       name: "Seller Financing If Buyer is to pay all or any portion of the Purchase Price with Seller financing", 
       isText: false,
       type: "checkbox",
       top: 564.48,
       left: 537,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "198", 
       isText: false,
       type: "checkbox",
       top: 576,
       left: 51,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 4,
       name: "days before Seller or", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 574.92,
       left: 440.04,
       width: 45.12,
       height: 12.502
    }
    ,
    {
       page: 6,
       name: "and option of proceeding with the consummation of this Contract without regard to the amount of the appraised valuation The", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 103.44,
       left: 313.92,
       width: 69.84,
       height: 12.065
    }
    ,
    {
       page: 6,
       name: "Buyer_2", 
       isText: false,
       type: "checkbox",
       top: 288.48,
       left: 536.52,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 6,
       name: "Seller The cost of the Appraisal may include any and all fees paid to the appraiser appraisal management company lenders", 
       isText: false,
       type: "checkbox",
       top: 300,
       left: 51,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 7,
       name: "Check Box81", 
       isText: false,
       type: "checkbox",
       top: 357.817,
       left: 50.1817,
       width: 10.9091,
       height: 10.037
    }
    ,
    {
       page: 7,
       name: "and delivered to Buyer as soon as practicable at or after Closing", 
       isText: false,
       type: "checkbox",
       top: 392.4,
       left: 154.44,
       width: 9.96,
       height: 9.84
    }
    ,
    {
       page: 7,
       name: "Check Box82", 
       isText: false,
       type: "checkbox",
       top: 415.417,
       left: 51.4908,
       width: 10.9091,
       height: 10.037
    }
    ,
    {
       page: 7,
       name: "Extended Coverage OEC If the Title Commitment is to contain OEC it will commit to delete or insure over the standard exceptions", 
       isText: false,
       type: "checkbox",
       top: 461.52,
       left: 411.12,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 7,
       name: "Will Not contain Owners", 
       isText: false,
       type: "checkbox",
       top: 461.52,
       left: 452.4,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 7,
       name: "assessments and unredeemed tax sales prior to the year of Closing Any additional premium expense to obtain OEC will be paid by", 
       isText: false,
       type: "checkbox",
       top: 519.12,
       left: 51,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 7,
       name: "Buyer_3", 
       isText: false,
       type: "checkbox",
       top: 519.12,
       left: 93.72,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 7,
       name: "OneHalf by Buyer and OneHalf by Seller", 
       isText: false,
       type: "checkbox",
       top: 519.12,
       left: 137.16,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 7,
       name: "Other_2", 
       isText: false,
       type: "checkbox",
       top: 519.12,
       left: 338.88,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 7,
       name: "Regardless of whether the Contract requires OEC the Title Insurance Commitment may not provide OEC or delete or insure over", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 518.04,
       left: 378.12,
       width: 129.84,
       height: 11.193
    }
    ,
    {
       page: 8,
       name: "Seller_3", 
       isText: false,
       type: "checkbox",
       top: 449.52,
       left: 290.88,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 8,
       name: "Buyer for the Property listing any special taxing districts", 
       isText: false,
       type: "checkbox",
       top: 449.52,
       left: 333.84,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 9,
       name: "Does_3", 
       isText: false,
       type: "checkbox",
       top: 449.52,
       left: 245.28,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 9,
       name: "Does Not have a Right to Terminate if examination of the Mineral", 
       isText: false,
       type: "checkbox",
       top: 449.52,
       left: 287.28,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 9,
       name: "New Improvement Location Certificate New ILC or 2", 
       isText: false,
       type: "checkbox",
       top: 496.08,
       left: 316.8,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 9,
       name: "New Survey in the form of", 
       isText: false,
       type: "checkbox",
       top: 507.6,
       left: 51,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 9,
       name: "New ILC or New Survey If the box is checked 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 506.52,
       left: 176.28,
       width: 215.04,
       height: 11.629
    }
    ,
    {
       page: 9,
       name: "Seller_4", 
       isText: false,
       type: "checkbox",
       top: 519.12,
       left: 309.84,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 9,
       name: "Buyer will order the New ILC or New Survey The", 
       isText: false,
       type: "checkbox",
       top: 519.12,
       left: 353.52,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 9,
       name: "Seller_5", 
       isText: false,
       type: "checkbox",
       top: 564.96,
       left: 102.36,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 9,
       name: "Buyer or", 
       isText: false,
       type: "checkbox",
       top: 564.96,
       left: 145.8,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 9,
       name: "Delivery of New ILC or New Survey Buyer Seller the issuer of the Title Commitment or the provider of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 609.96,
       left: 237.96,
       width: 104.88,
       height: 10.32
    }
    ,
    {
       page: 11,
       name: "Text1", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 79.418,
       left: 146.618,
       width: 431.999,
       height: 37.527
    }
    ,
    {
       page: 11,
       name: "Buyer on or before Due Diligence Documents Delivery Deadline Buyer", 
       isText: false,
       type: "checkbox",
       top: 138.84,
       left: 359.52,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 11,
       name: "Will_3", 
       isText: false,
       type: "checkbox",
       top: 138.84,
       left: 395.76,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 11,
       name: "documents creating the encumbrance to Buyer on or before Due Diligence Documents Delivery Deadline Buyer", 
       isText: false,
       type: "checkbox",
       top: 196.44,
       left: 510.36,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 11,
       name: "Will_4", 
       isText: false,
       type: "checkbox",
       top: 196.44,
       left: 544.68,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 11,
       name: "Check Box65", 
       isText: false,
       type: "checkbox",
       top: 253.964,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "Check Box67", 
       isText: false,
       type: "checkbox",
       top: 277.091,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "All contracts relating to the operation maintenance and management of the", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 275.444,
       left: 365.16,
       width: 45.48,
       height: 11.236
    }
    ,
    {
       page: 11,
       name: "Check Box68", 
       isText: false,
       type: "checkbox",
       top: 288.218,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "Check Box69", 
       isText: false,
       type: "checkbox",
       top: 322.691,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "Check Box71", 
       isText: false,
       type: "checkbox",
       top: 335.255,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "A list of all Inclusions to be conveyed to Buyer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 333.48,
       left: 383.16,
       width: 63.48,
       height: 10.80
    }
    ,
    {
       page: 11,
       name: "Check Box72", 
       isText: false,
       type: "checkbox",
       top: 346.473,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "Check Box73", 
       isText: false,
       type: "checkbox",
       top: 357.382,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "Check Box74", 
       isText: false,
       type: "checkbox",
       top: 380.291,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "years", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 390.84,
       left: 165,
       width: 20.64,
       height: 10.80
    }
    ,
    {
       page: 11,
       name: "Check Box75", 
       isText: false,
       type: "checkbox",
       top: 402.982,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "Check Box76", 
       isText: false,
       type: "checkbox",
       top: 426.546,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "Check Box77", 
       isText: false,
       type: "checkbox",
       top: 484.582,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "Check Box78", 
       isText: false,
       type: "checkbox",
       top: 507.055,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "Check Box79", 
       isText: false,
       type: "checkbox",
       top: 541.964,
       left: 140.509,
       width: 10.255,
       height: 9.164
    }
    ,
    {
       page: 11,
       name: "Text80", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 12,
       top: 553.308,
       left: 56.2908,
       width: 524.0712,
       height: 53.672
    }
    ,
    {
       page: 12,
       name: "Seller_6", 
       isText: false,
       type: "checkbox",
       top: 127.32,
       left: 407.28,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 12,
       name: "Buyer will order or provide", 
       isText: false,
       type: "checkbox",
       top: 127.32,
       left: 450.96,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 12,
       name: "Buyer Environmental Inspection In addition Buyer at Buyers expense may also conduct an", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 149.28,
       left: 406.44,
       width: 166.20,
       height: 10.80
    }
    ,
    {
       page: 12,
       name: "Seller_7", 
       isText: false,
       type: "checkbox",
       top: 161.88,
       left: 126.12,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 12,
       name: "applicable ASTM E1527 standard practices for Environmental Site Assessments andor", 
       isText: false,
       type: "checkbox",
       top: 161.88,
       left: 171.12,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 12,
       name: "Objection Deadline and if such Extended Environmental Inspection Objection Deadline extends beyond the Closing Date the", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 218.52,
       left: 275.16,
       width: 122.04,
       height: 10.80
    }
    ,
    {
       page: 12,
       name: "Seller_8", 
       isText: false,
       type: "checkbox",
       top: 242.64,
       left: 338.4,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 12,
       name: "Buyer must pay the cost for such Phase II", 
       isText: false,
       type: "checkbox",
       top: 242.64,
       left: 384.96,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 12,
       name: "the Right to Terminate under  241 effective upon Sellers receipt of Buyers Notice to Terminate on or before Conditional Sale", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 343.255,
       left: 221.88,
       width: 296.76,
       height: 12.545
    }
    ,
    {
       page: 12,
       name: "Does_4", 
       isText: false,
       type: "checkbox",
       top: 403.44,
       left: 483.12,
       width: 9.96,
       height: 9.84
    }
    ,
    {
       page: 12,
       name: "Does Not", 
       isText: false,
       type: "checkbox",
       top: 403.44,
       left: 523.56,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 12,
       name: "There is No Well  Buyer", 
       isText: false,
       type: "checkbox",
       top: 426.48,
       left: 106.2,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 12,
       name: "Does_5", 
       isText: false,
       type: "checkbox",
       top: 426.48,
       left: 224.4,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 12,
       name: "Does Not acknowledge receipt of a copy of the current well permit", 
       isText: false,
       type: "checkbox",
       top: 426.48,
       left: 263.28,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 13,
       name: "Seller will sign and complete all customary or reasonably required documents at or before Closing", 
       isText: false,
       type: "checkbox",
       top: 526.08,
       left: 430.56,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 13,
       name: "additional information and documents required by Closing Company that will be necessary to complete this transaction Buyer and", 
       isText: false,
       type: "checkbox",
       top: 526.08,
       left: 467.76,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 13,
       name: "Disclosure of Settlement Costs Buyer and Seller acknowledge that costs quality and extent of service vary between", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 570.84,
       left: 304.92,
       width: 199.92,
       height: 11.193
    }
    ,
    {
       page: 13,
       name: "TRANSFER OF TITLE Subject to Buyers compliance with the terms and provisions of this Contract including the tender", 
       isText: false,
       type: "checkbox",
       top: 664.68,
       left: 565.32,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 13,
       name: "of any payment due at Closing Seller must execute and deliver the following good and sufficient deed to Buyer at Closing", 
       isText: false,
       type: "checkbox",
       top: 676.2,
       left: 141.72,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 13,
       name: "bargain and sale deed", 
       isText: false,
       type: "checkbox",
       top: 676.2,
       left: 251.28,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 13,
       name: "quit claim deed", 
       isText: false,
       type: "checkbox",
       top: 676.2,
       left: 358.32,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 13,
       name: "personal representatives deed", 
       isText: false,
       type: "checkbox",
       top: 676.2,
       left: 440.64,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 13,
       name: "Check Box64", 
       isText: false,
       type: "checkbox",
       top: 688.144,
       left: 51.9272,
       width: 8.2908,
       height: 8.7272
    }
    ,
    {
       page: 13,
       name: "sufficient special warranty deed to Buyer at Closing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 686.64,
       left: 65.52,
       width: 140.16,
       height: 12.5018
    }
    ,
    {
       page: 14,
       name: "Buyer_4", 
       isText: false,
       type: "checkbox",
       top: 173.88,
       left: 488.28,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Seller_9", 
       isText: false,
       type: "checkbox",
       top: 173.88,
       left: 537.24,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_2", 
       isText: false,
       type: "checkbox",
       top: 185.4,
       left: 51,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Other_3", 
       isText: false,
       type: "checkbox",
       top: 185.4,
       left: 252.96,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Association Fees and Required Disbursements At least fourteen days prior to Closing Date Seller agrees to", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 183.055,
       left: 294.36,
       width: 278.28,
       height: 11.629
    }
    ,
    {
       page: 14,
       name: "Seller_10", 
       isText: false,
       type: "checkbox",
       top: 243.12,
       left: 51,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_3", 
       isText: false,
       type: "checkbox",
       top: 243.12,
       left: 94.44,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "NA", 
       isText: false,
       type: "checkbox",
       top: 243.12,
       left: 296.4,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Buyer_5", 
       isText: false,
       type: "checkbox",
       top: 233.143,
       left: 536.76,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Buyer_6", 
       isText: false,
       type: "checkbox",
       top: 254.64,
       left: 395.52,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Seller_11", 
       isText: false,
       type: "checkbox",
       top: 254.64,
       left: 439.44,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer", 
       isText: false,
       type: "checkbox",
       top: 254.64,
       left: 481.92,
       width: 9.96,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "NA_2", 
       isText: false,
       type: "checkbox",
       top: 265.92,
       left: 154.68,
       width: 9.96,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Buyer_7", 
       isText: false,
       type: "checkbox",
       top: 300.48,
       left: 63.48,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Seller_12", 
       isText: false,
       type: "checkbox",
       top: 300.48,
       left: 108.6,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_4", 
       isText: false,
       type: "checkbox",
       top: 300.48,
       left: 152.04,
       width: 9.96,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "NA_3", 
       isText: false,
       type: "checkbox",
       top: 300.48,
       left: 353.76,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Other Fees Any other fee listed in the Status Letter as required to be paid at Closing will be paid by", 
       isText: false,
       type: "checkbox",
       top: 312,
       left: 565.32,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Seller_13", 
       isText: false,
       type: "checkbox",
       top: 323.52,
       left: 82.2,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_5", 
       isText: false,
       type: "checkbox",
       top: 323.52,
       left: 125.64,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "NA_4", 
       isText: false,
       type: "checkbox",
       top: 323.52,
       left: 327.36,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Buyer_8", 
       isText: false,
       type: "checkbox",
       top: 335.04,
       left: 414.48,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Seller_14", 
       isText: false,
       type: "checkbox",
       top: 335.04,
       left: 462.24,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "OneHalf by", 
       isText: false,
       type: "checkbox",
       top: 335.04,
       left: 508.2,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "NA_5", 
       isText: false,
       type: "checkbox",
       top: 346.56,
       left: 183.36,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Buyer_9", 
       isText: false,
       type: "checkbox",
       top: 369.6,
       left: 51,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Seller_15", 
       isText: false,
       type: "checkbox",
       top: 369.6,
       left: 96.12,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_6", 
       isText: false,
       type: "checkbox",
       top: 369.6,
       left: 139.56,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "NA_6", 
       isText: false,
       type: "checkbox",
       top: 369.6,
       left: 341.52,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Buyer_10", 
       isText: false,
       type: "checkbox",
       top: 392.4,
       left: 485.88,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Seller_16", 
       isText: false,
       type: "checkbox",
       top: 392.4,
       left: 536.52,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_7", 
       isText: false,
       type: "checkbox",
       top: 403.92,
       left: 51,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "NA_7", 
       isText: false,
       type: "checkbox",
       top: 403.92,
       left: 250.32,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Water StockCertificates", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 425.88,
       left: 55.44,
       width: 60.24,
       height: 12.065
    }
    ,
    {
       page: 14,
       name: "undefined_7", 
       isText: false,
       type: "checkbox",
       top: 438.48,
       left: 73.56,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Water District", 
       isText: false,
       type: "checkbox",
       top: 438.48,
       left: 231.12,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Augmentation Membership", 
       isText: false,
       type: "checkbox",
       top: 450,
       left: 73.56,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Small Domestic Water Company", 
       isText: false,
       type: "checkbox",
       top: 450,
       left: 231.12,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Check Box63", 
       isText: false,
       type: "checkbox",
       top: 449.89,
       left: 411.054,
       width: 9.163,
       height: 9.163
    }
    ,
    {
       page: 14,
       name: "NA_8", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 449.4,
       left: 424.44,
       width: 148.32,
       height: 10.32
    }
    ,
    {
       page: 14,
       name: "Buyer_11", 
       isText: false,
       type: "checkbox",
       top: 461.083,
       left: 177.36,
       width: 8.968,
       height: 10.277
    }
    ,
    {
       page: 14,
       name: "Seller_17", 
       isText: false,
       type: "checkbox",
       top: 461.52,
       left: 222.72,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Utility Transfer Fees Utility transfer fees can change Any fees to transfer utilities from Seller to Buyer must be", 
       isText: false,
       type: "checkbox",
       top: 461.52,
       left: 266.16,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "undefined_8", 
       isText: false,
       type: "checkbox",
       top: 461.52,
       left: 465.36,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Buyer_12", 
       isText: false,
       type: "checkbox",
       top: 484.56,
       left: 85.8,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Seller_18", 
       isText: false,
       type: "checkbox",
       top: 484.56,
       left: 130.92,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_9", 
       isText: false,
       type: "checkbox",
       top: 484.56,
       left: 174.48,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "NA_9", 
       isText: false,
       type: "checkbox",
       top: 484.56,
       left: 376.08,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "IS a foreign", 
       isText: false,
       type: "checkbox",
       top: 530.4,
       left: 514.2,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Taxes for the Calendar Year Immediately Preceding Closing", 
       isText: false,
       type: "checkbox",
       top: 703.56,
       left: 190.08,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Most Recent Mill Levy", 
       isText: false,
       type: "checkbox",
       top: 703.56,
       left: 466.08,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "Other_4", 
       isText: false,
       type: "checkbox",
       top: 726.6,
       left: 141,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 14,
       name: "undefined_9", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 726.0,
       left: 182.52,
       width: 264.00,
       height: 12.0655
    }
    ,
    {
       page: 15,
       name: "Rents Actually Received", 
       isText: false,
       type: "checkbox",
       top: 58.44,
       left: 238.8,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 15,
       name: "Accrued At Closing Seller will transfer or credit", 
       isText: false,
       type: "checkbox",
       top: 58.44,
       left: 361.92,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 15,
       name: "to Buyer the security deposits for all Leases assigned to Buyer or any remainder after lawful deductions and notify all tenants in", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 91.8,
       left: 488.88,
       width: 84.96,
       height: 13.058
    }
    ,
    {
       page: 15,
       name: "Buyer_13", 
       isText: false,
       type: "checkbox",
       top: 161.88,
       left: 403.2,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 15,
       name: "Seller Except however any", 
       isText: false,
       type: "checkbox",
       top: 161.88,
       left: 448.56,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 15,
       name: "there are no unpaid regular or special assessments against the Property except the current regular assessments and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 206.88,
       left: 50.4,
       width: 150.0,
       height: 12.185
    }
    ,
    {
       page: 15,
       name: "If Seller after Closing occurs fails to deliver possession as specified Seller will be subject to eviction and will be additionally", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 265.2,
       left: 377.4,
       width: 69.24,
       height: 12.185
    }
    ,
    {
       page: 15,
       name: "Does Not represent that Buyer will occupy the Property as Buyers principal residence", 
       isText: false,
       type: "checkbox",
       top: 300.48,
       left: 80.52,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 15,
       name: "If the box is checked Buyer and Seller agree to execute a PostClosing Occupancy Agreement", 
       isText: false,
       type: "checkbox",
       top: 318,
       left: 51,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 16,
       name: "Check Box62", 
       isText: false,
       type: "checkbox",
       top: 266.181,
       left: 51.0544,
       width: 10.4727,
       height: 8.727
    }
    ,
    {
       page: 17,
       name: "Electronic Delivery Electronic Delivery of documents and notice may be delivered by 1 email at the email address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 403.604,
       left: 408.6,
       width: 165.0,
       height: 12.501
    }
    ,
    {
       page: 17,
       name: "Text55", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 664.367,
       left: 108.655,
       width: 467.348,
       height: 79.8545
    }
    ,
    {
       page: 18,
       name: "Section29Part2", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 56.945,
       left: 44.0712,
       width: 501.3838,
       height: 68.946
    }
    ,
    {
       page: 18,
       name: "Section3011", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 175.854,
       left: 71.2529,
       width: 501.3841,
       height: 42.327
    }
    ,
    {
       page: 18,
       name: "Text56", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 230.837,
       left: 72.0003,
       width: 501.3837,
       height: 42.328
    }
    ,
    {
       page: 18,
       name: "Buyers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 312.24,
       left: 121.68,
       width: 182.64,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Buyers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 312.24,
       left: 393.24,
       width: 184.32,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Date 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 385.2,
       left: 135.84,
       width: 168.48,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Date 1_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 385.2,
       left: 410.52,
       width: 167.04,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Date 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 397.2,
       left: 135.84,
       width: 168.48,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Date 2_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 397.2,
       left: 410.52,
       width: 167.04,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Date 3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 409.2,
       left: 135.84,
       width: 168.48,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Date 3_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 409.2,
       left: 410.52,
       width: 167.04,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Date 4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 421.2,
       left: 135.84,
       width: 168.48,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Date 4_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 421.2,
       left: 410.52,
       width: 167.04,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Email Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 433.2,
       left: 135.12,
       width: 169.20,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Email Address_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 433.2,
       left: 409.8,
       width: 167.76,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Sellers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 480,
       left: 121.68,
       width: 182.64,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Sellers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 480,
       left: 393.24,
       width: 184.32,
       height: 11.4
    }
    ,
    {
       page: 18,
       name: "Date 1_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 552.48,
       left: 135.84,
       width: 168.48,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Date 1_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 552.48,
       left: 410.52,
       width: 167.04,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Date 2_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 564.48,
       left: 135.84,
       width: 168.48,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Date 2_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 564.48,
       left: 410.52,
       width: 167.04,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Date 3_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 576.48,
       left: 135.84,
       width: 168.48,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Date 3_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 576.48,
       left: 410.52,
       width: 167.04,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Date 4_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 588.48,
       left: 135.84,
       width: 168.48,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Date 4_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 588.48,
       left: 410.52,
       width: 167.04,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Email Address_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 600.48,
       left: 135.12,
       width: 169.20,
       height: 11.40
    }
    ,
    {
       page: 18,
       name: "Email Address_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 600.48,
       left: 409.8,
       width: 167.76,
       height: 11.40
    }
    ,
    {
       page: 19,
       name: "Does_6", 
       isText: false,
       type: "checkbox",
       top: 59.4,
       left: 89.4,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the Earnest", 
       isText: false,
       type: "checkbox",
       top: 59.4,
       left: 129.72,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Buyers Agent", 
       isText: false,
       type: "checkbox",
       top: 129,
       left: 199.2,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "TransactionBroker in this transaction", 
       isText: false,
       type: "checkbox",
       top: 129,
       left: 282,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
       isText: false,
       type: "checkbox",
       top: 152.28,
       left: 56.28,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Listing Brokerage Firm", 
       isText: false,
       type: "checkbox",
       top: 175.8,
       left: 309.12,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Buyer_14", 
       isText: false,
       type: "checkbox",
       top: 175.8,
       left: 427.44,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Other_5", 
       isText: false,
       type: "checkbox",
       top: 175.8,
       left: 471.6,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "undefined_10", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 174.12,
       left: 512.64,
       width: 65.28,
       height: 11.40
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms Name 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 244.364,
       left: 181.08,
       width: 401.52,
       height: 11.400
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms License 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 256.8,
       left: 181.08,
       width: 401.52,
       height: 11.4
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms Name 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 268.8,
       left: 181.08,
       width: 401.52,
       height: 11.4
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms License 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 280.8,
       left: 181.08,
       width: 401.52,
       height: 11.4
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms License 3", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 304.8,
       left: 181.08,
       width: 288.938,
       height: 11.4
    }
    ,
    {
       page: 19,
       name: "Text61", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 10,
       top: 304.801,
       left: 472.584,
       width: 108.655,
       height: 10.691
    }
    ,
    {
       page: 19,
       name: "Date 1_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 339.404,
       left: 181.08,
       width: 401.52,
       height: 11.400
    }
    ,
    {
       page: 19,
       name: "Date 2_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 351.84,
       left: 181.08,
       width: 401.52,
       height: 11.40
    }
    ,
    {
       page: 19,
       name: "Date 3_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 363.84,
       left: 181.08,
       width: 401.52,
       height: 11.40
    }
    ,
    {
       page: 19,
       name: "Date 4_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 375.84,
       left: 181.08,
       width: 401.52,
       height: 11.40
    }
    ,
    {
       page: 19,
       name: "Date 5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 387.84,
       left: 181.08,
       width: 401.52,
       height: 11.40
    }
    ,
    {
       page: 19,
       name: "Date 6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 399.84,
       left: 181.08,
       width: 401.52,
       height: 11.40
    }
    ,
    {
       page: 19,
       name: "Does_7", 
       isText: false,
       type: "checkbox",
       top: 468,
       left: 89.4,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the Earnest_2", 
       isText: false,
       type: "checkbox",
       top: 468,
       left: 129.72,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Sellers Agent", 
       isText: false,
       type: "checkbox",
       top: 537.6,
       left: 198,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "TransactionBroker in this transaction_2", 
       isText: false,
       type: "checkbox",
       top: 537.6,
       left: 276.72,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Customer Broker has no brokerage relationship with Seller See  A for Brokers brokerage relationship with Buyer", 
       isText: false,
       type: "checkbox",
       top: 560.88,
       left: 56.28,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Seller_19", 
       isText: false,
       type: "checkbox",
       top: 584.4,
       left: 315.36,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Buyer_15", 
       isText: false,
       type: "checkbox",
       top: 584.4,
       left: 359.04,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "Other_6", 
       isText: false,
       type: "checkbox",
       top: 584.4,
       left: 404.16,
       width: 9.84,
       height: 9.84
    }
    ,
    {
       page: 19,
       name: "undefined_11", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 582.72,
       left: 445.56,
       width: 132.36,
       height: 11.40
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms Name 1_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 653.4,
       left: 181.08,
       width: 397.20,
       height: 11.4
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms License 1_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 665.4,
       left: 181.08,
       width: 397.20,
       height: 11.4
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms Name 2_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 677.4,
       left: 181.08,
       width: 397.20,
       height: 11.4
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms License 2_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 689.4,
       left: 181.08,
       width: 397.20,
       height: 11.4
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms License 2_3", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 713.4,
       left: 186.36,
       width: 286.56,
       height: 11.4
    }
    ,
    {
       page: 19,
       name: "Brokerage Firms License 4", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 10,
       top: 713.4,
       left: 477.72,
       width: 100.56,
       height: 11.4
    }
    ,
    {
       page: 20,
       name: "1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 69.24,
       left: 181.08,
       width: 397.20,
       height: 11.40
    }
    ,
    {
       page: 20,
       name: "2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 81.24,
       left: 181.08,
       width: 397.20,
       height: 11.40
    }
    ,
    {
       page: 20,
       name: "3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 93.24,
       left: 181.08,
       width: 397.20,
       height: 11.40
    }
    ,
    {
       page: 20,
       name: "4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 105.24,
       left: 181.08,
       width: 397.20,
       height: 11.40
    }
    ,
    {
       page: 20,
       name: "5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 117.24,
       left: 181.08,
       width: 397.20,
       height: 11.40
    }
    ,
    {
       page: 20,
       name: "6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 129.24,
       left: 180.36,
       width: 397.92,
       height: 11.40
    }
    ] }
    