import { isPast, isSameDay, parseISO } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { Icon, Label, Popup, Table } from "semantic-ui-react";
import {
  convertFullName,
  convertPartiesAbleToShare,
  convertRoleToSharingDisplay,
  convertSharingWithToColor,
  dateFormatDeadline,
  truncateText,
} from "../../../../app/common/util/util";
import DocActionButtons from "../../docComponents/DocActionButtons";

export default function DocTransAwaitingSignatureListItem({ doc }) {
  const { transaction, allParties } = useSelector((state) => state.transaction);

  return (
    <Table.Row
      key={doc.id}
      className={
        (isSameDay(parseISO(doc.signingDueAt), new Date()) &&
          "background-blue") ||
        (isPast(parseISO(doc.signingDueAt)) && "background-yellow") ||
        ""
      }
    >
      <Table.Cell>
        <Icon name="file pdf outline" size="large" />
        &nbsp;
        {truncateText(doc.name, 80)}
      </Table.Cell>
      <Table.Cell>
        <span>
          {doc.signingSentOutAt && dateFormatDeadline(doc.signingSentOutAt)}
        </span>
      </Table.Cell>
      <Table.Cell>
        {convertPartiesAbleToShare(allParties, transaction.agentRepresents).map(
          (party) => (
            <React.Fragment key={party.role}>
              {party.email && (
                <Popup
                  trigger={
                    <Label
                      key={party.role}
                      style={{ cursor: "pointer", marginBottom: "2px" }}
                      color={convertSharingWithToColor(doc, party)}
                    >
                      {convertRoleToSharingDisplay(party.role)}
                    </Label>
                  }
                >
                  <p>
                    {party.role}:
                    <br />
                    {convertFullName(party)}
                  </p>
                </Popup>
              )}
            </React.Fragment>
          )
        )}
      </Table.Cell>

      <Table.Cell>
        <DocActionButtons doc={doc} />
      </Table.Cell>
    </Table.Row>
  );
}
