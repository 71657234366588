export function addendumShortSale() {
return [   //2024 Release 2024-02-18 23:22:31
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 102.24,
   left: 36.0,
   width: 540.12,
   height: 13.20
}
,
{
   page: 0,
   name: "earnest money will be refunded to Buyer Seller must notify Buyer immediately if Lienholders Consent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 374.571,
   left: 85.9104,
   width: 279.5996,
   height: 11.959
}
] }
