import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { format } from "date-fns";
import { Rnd } from "react-rnd";

export default function DocFillAgentAnnotField({ annot }) {
  const { pageScaleFill } = useSelector((state) => state.annot);
  const pageScale = pageScaleFill;
  const ref = useRef();

  const [annotPosition] = useState({
    x: annot.x,
    y: annot.y,
  });
  const [annotPositionDisplay, setAnnotPositionDisplay] = useState({
    x: annot.x,
    y: annot.y,
  });

  useEffect(() => {
    const newAnnotPosition = {
      x: annotPosition.x * pageScale,
      y: annotPosition.y * pageScale,
    };
    setAnnotPositionDisplay(newAnnotPosition);
  }, [pageScale, annotPosition.x, annotPosition.y]);

  const color = "232, 232, 232";

  return (
    <Rnd
      key={annot.id}
      width={annot.width * pageScale}
      height={annot.height * pageScale}
      disableDragging={true}
      position={{
        x: annotPositionDisplay.x,
        y: annotPositionDisplay.y,
      }}
    >
      <div
        ref={ref}
        id={annot.uniqueId}
        style={{
          width: annot.width * pageScale,
          height: annot.height * pageScale,
          minWidth: 6 * pageScale,
          minHeight: 2 * pageScale,
          maxWidth: 960 * pageScale,
          maxHeight: 960 * pageScale,
          position: "absolute",
          display: "inline-block",
          zIndex: 2,
          // Needed to align text properly
          lineHeight: 0.6,
          whiteSpace: "nowrap",
        }}
      >
        <div
          className="annot-outer-wrapper"
          style={{ backgroundColor: `rgba(${color}, 0.2)` }}
        >
          <div
            className="annot-inner-wrapper"
            style={{
              boxShadow: `rgb(${color}) 0px 0px 0px 1px`,
              zIndex: 5,
            }}
          >
            {annot.type === "checkbox" && (
              <Icon
                name="check"
                style={{
                  fontSize: `${annot.fontSize * pageScale}px`,
                  color: `${annot.fontColor}`,
                  textAlign: "left",
                  height: "unset",
                }}
              />
            )}
            {annot.type !== "checkbox" && (
              <span
                style={{
                  fontSize: `${annot.fontSize * pageScale}px`,
                  color: `${annot.fontColor}`,
                  fontFamily: `${annot.fontFamily}`,
                }}
              >
                {annot.type === "date" ? (
                  format(new Date(), "MM/dd/yyyy")
                ) : (
                  <>{annot.text}</>
                )}
              </span>
            )}
          </div>
        </div>
      </div>
    </Rnd>
  );
}
