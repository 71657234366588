export function legalDescriptionSupplement() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 91.63,
   left: 31.5,
   width: 504.0,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 114.04,
   left: 31.5,
   width: 515.7,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 137.45,
   left: 280.8,
   width: 199.008,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 7", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 183.6,
   left: 31.5,
   width: 549.0,
   height: 449.28
}
] }
