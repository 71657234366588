import React from "react";
import { Grid, Button, Input } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import CalendarDisplay from "../../../../app/common/calendar/Calendar";
import { searchFilter } from "../../../../app/common/util/util";

export default function TaskTransCalendarMonthly() {
  const { tasksTransWithDateAll } = useSelector((state) => state.task);
  let { id } = useParams();
  const [searchTerms, setSearchTerms] = useState("");
  const tasks = searchFilter(tasksTransWithDateAll, searchTerms);

  return (
    <div className="secondary-page-wrapper">
      <Grid stackable>
        <Grid.Column computer={5}>
          <Input
            type="text"
            fluid
            placeholder="Search by name or status"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          ></Input>
        </Grid.Column>
        <Grid.Column computer={5}>
          <Button.Group fluid size="small">
            <Button as={Link} to={`/transactions/${id}/tasks`}>
              List
            </Button>
            <Button active as={Link} to="">
              Calendar
            </Button>
            <Button as={Link} to={`/transactions/${id}/tasksSharing`}>
              Sharing
            </Button>
          </Button.Group>
        </Grid.Column>
        <Grid.Column computer={16} tablet={16}>
          <CalendarDisplay tasks={tasks} allowEdit={true} />
        </Grid.Column>
      </Grid>
    </div>
  );
}
