export function openListingAddendumtoListingContract() {
    return [   //2023
  {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 240,
      height: 36,
    }
   ,
   {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 168.033,
       left: 398.52,
       width: 174.00,
       height: 17.967
    }
    ,
    {
       page: 0,
       name: "Exclusive RighttoSell Listing Contract Seller Listing Contract", 
       isText: false,
       type: "checkbox",
       top: 223.92,
       left: 51.12,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 0,
       name: "Exclusive RighttoLease Listing Contract Landlord Listing Contract", 
       isText: false,
       type: "checkbox",
       top: 241.44,
       left: 51.12,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 0,
       name: "dated", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 9,
       top: 262,
       left: 73.4727,
       width: 76,
       height: 11.16
    }
    ,
    {
       page: 0,
       name: "known as No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 280.473,
       left: 108.12,
       width: 464.28,
       height: 17.967
    }
    ,
    {
       page: 0,
       name: "Sale Commission 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 567.84,
       left: 226.08,
       width: 31.32,
       height: 11.16
    }
    ,
    {
       page: 0,
       name: "of the gross purchase price or 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 567.84,
       left: 405.36,
       width: 167.04,
       height: 11.16
    }
    ,
    {
       page: 0,
       name: "paid a fee equal to 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 603.84,
       left: 138.36,
       width: 33.60,
       height: 11.16
    }
    ,
    {
       page: 0,
       name: "of the gross rent under the lease or 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 603.84,
       left: 339.96,
       width: 139.44,
       height: 11.16
    }
    ,
    {
       page: 0,
       name: "payable as follows", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 615.84,
       left: 131.4,
       width: 204.96,
       height: 11.16
    }
    ,
    {
       page: 0,
       name: "Other Compensation", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 627.84,
       left: 192.6,
       width: 379.92,
       height: 11.16
    }
    ,
    {
       page: 0,
       name: "Text52", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 685.485,
       left: 463.417,
       width: 18.567,
       height: 14.1453
    }
    ,
    {
       page: 0,
       name: "Will", 
       isText: false,
       type: "checkbox",
       top: 725.52,
       left: 84.96,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 0,
       name: "WillNot", 
       isText: false,
       type: "checkbox",
       top: 725.52,
       left: 124.68,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 1,
       name: "AddtlAmendments", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 236.159,
       left: 49.7453,
       width: 526.4927,
       height: 85.915
    }
    ,
    {
       page: 1,
       name: "Seller", 
       isText: false,
       type: "checkbox",
       top: 358.2,
       left: 90,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 1,
       name: "Landlord", 
       isText: false,
       type: "checkbox",
       top: 358.2,
       left: 132.36,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 1,
       name: "Owners Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 394.219,
       left: 121.44,
       width: 184.68,
       height: 16.901
    }
    ,
    {
       page: 1,
       name: "Brokers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 395.253,
       left: 391.32,
       width: 184.80,
       height: 15.867
    }
   //  ,
   //  {
   //     page: 1,
   //     name: "Owners Signature", 
   //     type: "signature",
   //     fontName: "Helvetica",
   //     fontSize: 10,
   //     top: 418.8,
   //     left: 50.4,
   //     width: 198.84,
   //     height: 28.8
   //  }
   //  ,
   //  {
   //     page: 1,
   //     name: "Date_2", 
   //     fontSize: 9,
   //     type: "date",
   //     top: 418.8,
   //     left: 249.129,
   //     width: 56.991,
   //     height: 28.8
   //  }
    ,
    {
       page: 1,
       name: "Brokers Signature", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 418.8,
       left: 320.28,
       width: 198.96,
       height: 28.8
    }
    ,
    {
       page: 1,
       name: "Date_3", 
       fontSize: 9,
       type: "date",
       top: 418.8,
       left: 519.564,
       width: 56.556,
       height: 28.8
    }
    ,
    {
       page: 1,
       name: "Brokerage Firms Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 467.36,
       left: 390.6,
       width: 185.52,
       height: 16.72
    }
    ,
    {
      page: 1,
      name: "Owner 2 Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 482,
      left: 120,
      width: 184.68,
      height: 16.901
   }
    ] }
    