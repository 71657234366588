import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Confirm, Dropdown } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { openModal } from "../../app/common/modals/modalSlice";
import {
  copyFormTemplateToDb,
  deleteFormTemplateInDb,
  updateFormTemplateInDb,
} from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function FormTemplatesActionButtons({ formTemplate }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleFillOutForm() {
    updateFormTemplateInDb(formTemplate.id);
    navigate(`/formTemplates/${formTemplate.id}/fill`);
  }

  async function handleCopy() {
    copyFormTemplateToDb(formTemplate);
  }

  function handleRename() {
    dispatch(
      openModal({
        modalType: "FormTemplateRenameModal",
        modalProps: { formTemplate: formTemplate },
      })
    );
  }

  async function handleDelete() {
    try {
      await deleteFormTemplateInDb(formTemplate.id);
      toast.success("Document successfully deleted");
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  }

  return (
    <div className={isMobile ? null : "text align right"}>
      <Dropdown
        button
        icon="chevron down"
        text={isMobile ? "Actions " : null}
        className={isMobile ? "button icon" : "button mini icon"}
        direction={isMobile ? "right" : "left"}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleFillOutForm()}>
            Fill Out
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleCopy()}>
            Copy Template
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleRename()}>Rename</Dropdown.Item>
          <Dropdown.Item onClick={() => setConfirmOpen(true)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleDelete()}
      ></Confirm>
    </div>
  );
}
