export function supplement() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 91.63,
   left: 31.5,
   width: 504.0,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 114.04,
   left: 31.5,
   width: 515.7,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 137.45,
   left: 280.8,
   width: 199.008,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 160.525,
   left: 211.4,
   width: 369.1,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 183.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 206.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 229.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 252.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 275.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 298.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 321.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 25", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 344.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 367.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 390.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 413.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 436.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 459.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 482.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 32", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 505.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 529.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 551.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 35", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 574.56,
   left: 31.5,
   width: 549.0,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 36", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 597.52,
   left: 31.5,
   width: 549.0,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 37", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 620.48,
   left: 31.5,
   width: 549.0,
   height: 13.50
}
] }
