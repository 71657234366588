import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { Button, Popup, Icon } from "semantic-ui-react";
import { functionFetchLegalAddressData } from "../../../app/firestore/functionsService";

export default function TransactionImportLegalAddressForm({
  setLegalAddressData,
  address,
}) {
  const [importLegalAddressStatus, setImportLegalAddressStatus] =
    useState("Initial");
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  async function handleLegalAddressImport() {
    if (
      address &&
      address.street &&
      address.city &&
      address.state &&
      address.zipcode
    ) {
      setImportLegalAddressStatus("Loading");
      try {
        const returnedLegalAddressData = await functionFetchLegalAddressData(
          address
        );

        setImportLegalAddressStatus("Complete");
        setLegalAddressData(returnedLegalAddressData);
        toast.success("Legal Description imported successfully");
      } catch (error) {
        setImportLegalAddressStatus("Error");
        toast.error(
          `Error when importing legal description data. Please check the address and try again or manually enter the legal description.`
        );
      }
    } else {
      toast.error("Please ensure the full address is entered.");
    }
  }

  return (
    <>
      <Button
        primary
        loading={importLegalAddressStatus === "Loading"}
        disabled={importLegalAddressStatus === "Loading"}
        className={isMobile ? "fluid" : null}
        style={{ marginTop: "20px" }}
        onClick={(e) => {
          e.preventDefault();
          handleLegalAddressImport();
        }}
      >
        {importLegalAddressStatus === "Initial" ||
        importLegalAddressStatus === "Error"
          ? "Import Legal Description"
          : `Import ${importLegalAddressStatus}`}
      </Button>
      <Popup
        flowing
        size="large"
        trigger={
          <Icon
            name="info"
            color="blue"
            circular
            inverted
            size="small"
            style={{ marginLeft: "3px", marginBottom: "3px" }}
          />
        }
      >
        <p className="bold text blue mini bottom margin">
          Legal Description Import
        </p>
        <p className="text-dark text-normal mini bottom margin">
          You can import the legal description from public records by entering
          the full address to enable the import button.
          <br />
          Be sure to verify the import contains the full correct legal
          description, as some public data records have incomplete legal
          descriptions.
        </p>
      </Popup>
    </>
  );
}
