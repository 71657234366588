export function rEMAXWireFraudAlert() {
    return [   //2023
    {
       page: 0,
       name: "Broker",
       type: "signature",
       fontName: "Helvetica",
       fontSize: 14,
       top: 523.44,
       left: 107.76,
       width: 232.56,
       height: 16.80
    }
    ] }
    