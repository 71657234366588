export function contracttoBuyandSellCOForeclosureProtectionAct() {
return [
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date", 
   top: 185.76,
   left: 434.64,
   width: 138.00,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Buyer", 
   top: 287.64,
   left: 138,
   width: 349.2,
   height: 10.56
}
,
{
   page: 0,
   type: "checkbox",
   name: "No Assignability This Contract IS NOT assignable by Buyer unless otherwise specified in Additional Provisions", 
   top: 300.644,
   left: 194.16,
   width: 9.96,
   height: 9.960
}
,
{
   page: 0,
   type: "checkbox",
   name: "Tenants In Common", 
   top: 300.644,
   left: 271.8,
   width: 9.96,
   height: 9.960
}
,
{
   page: 0,
   type: "checkbox",
   name: "undefined", 
   top: 300.644,
   left: 379.32,
   width: 9.96,
   height: 9.960
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Other", 
   top: 299.564,
   left: 420.72,
   width: 151.80,
   height: 10.560
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller", 
   top: 328,
   left: 136.32,
   width: 350.88,
   height: 10.560
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Property The Property is the following legally described real estate in the County of", 
   top: 351.24,
   left: 444.12,
   width: 83.40,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text2", 
   top: 375.288,
   left: 56.5818,
   width: 515.3952,
   height: 53.887
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "known as", 
   top: 431.76,
   left: 96.24,
   width: 476.28,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "including", 
   top: 541.08,
   left: 94.2,
   width: 34.44,
   height: 10.32
}
,
{
   page: 0,
   type: "checkbox",
   name: "Satellite Systems including satellite dishes Leased items should be listed under  257", 
   top: 542.28,
   left: 462.6,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "in kitchen appliances sprinkler systems and controls builtin vacuum systems including accessories and garage door openers", 
   top: 542.28,
   left: 535.44,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Leased Items If any additional items are attached to the Property after the date of this Contract such additional items are also", 
   top: 553.80,
   left: 98.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "remote controls If checked the following are owned by the Seller and included", 
   top: 553.80,
   left: 194.564,
   width: 9.436,
   height: 9.96
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text28", 
   top: 645.6410,
   left: 114.152,
   width: 458.181,
   height: 68.5964
}
,
{
   page: 0,
   type: "checkbox",
   name: "property outside of this Contract", 
   top: 714.72,
   left: 105.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text3", 
   top: 80.061,
   left: 142.032,
   width: 440.722,
   height: 48.884
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Note to Buyer If exact rights to the parking and storage facilities is a concern to Buyer Buyer should investigate", 
   top: 160.80,
   left: 50.4,
   width: 144.0,
   height: 10.68
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined_2", 
   top: 160.80,
   left: 440.28,
   width: 132.24,
   height: 10.68
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text30", 
   top: 206.5,
   left: 73.3088,
   width: 504.2602,
   height: 45.556
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text33", 
   top: 263,
   left: 73.6937,
   width: 502.1653,
   height: 37.178
}
,
{
   page: 1,
   type: "checkbox",
   name: "Check Box35", 
   top: 311.563,
   left: 50.7925,
   width: 9.9491,
   height: 10.472
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text32", 
   top: 322.559,
   left: 73.8325,
   width: 503.2125,
   height: 31.942
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Other Rights Relating to Water The following rights relating to water not included in  271 273 and", 
   top: 356.40,
   left: 374.88,
   width: 107.76,
   height: 10.68
}
,
{
   page: 1,
   type: "checkbox",
   name: "Check Box36", 
   top: 369.162,
   left: 51.3162,
   width: 9.4254,
   height: 9.426
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text34", 
   top: 391.155,
   left: 75.4034,
   width: 499.5476,
   height: 41.891
}
,
{
   page: 1,
   type: "checkbox",
   name: "Check Box37", 
   top: 437.235,
   left: 51.3162,
   width: 8.9018,
   height: 10.472
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "274", 
   top: 505.92,
   left: 50.4,
   width: 90.0,
   height: 10.68
}
,
{
   page: 1,
   type: "checkbox",
   name: "Check Box39", 
   top: 518.398,
   left: 51.3162,
   width: 9.4254,
   height: 9.949
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text38", 
   top: 528.347,
   left: 104.203,
   width: 470.224,
   height: 36.655
}
,
{
   page: 1,
   type: "checkbox",
   name: "Does", 
   top: 599.16,
   left: 267.84,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   type: "checkbox",
   name: "Does Not have a Right to Terminate if examination of the Water", 
   top: 599.16,
   left: 306.84,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   name: "Date or DeadlineTime of Day Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 675.12,
   left: 374.107,
   width: 205.373,
   height: 11.04
}
,
{
   page: 1,
   name: "Date or DeadlineAlternative Earnest Money Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 687.12,
   left: 371.8,
   width: 139.673,
   height: 11
}
,
{
   page: 1,
   name: "DateField Date or DeadlineAlternative Earnest Money Deadline", 
   fontSize: 9,
   type: "date",
   top: 687.12,
   left: 512.932,
   width: 65.695,
   height: 11.0401
}
,
{
   page: 1,
   name: "Date or DeadlineRecord Title Deadline and Tax Certificate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 710.7698,
   left: 371.8,
   width: 139.673,
   height: 11.04
}
,
{
   page: 1,
   name: "DateField Date or DeadlineRecord Title Deadline and Tax Certificate", 
   fontSize: 9,
   type: "date",
   top: 710.7698,
   left: 512.932,
   width: 65.695,
   height: 11.0400
}
,
{
   page: 1,
   name: "Date or DeadlineRecord Title Objection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 722.7698,
   left: 371.8,
   width: 139.673,
   height: 11.04
}
,
{
   page: 1,
   name: "DateField Date or DeadlineRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 722.7698,
   left: 512.932,
   width: 65.695,
   height: 11.0400
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "OffRecord Title Deadline", 
   top: 58.56,
   left: 365.8,
   width: 146.398,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField OffRecord Title Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 58.126,
   left: 512.52,
   width: 66.108,
   height: 9.649
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "OffRecord Title Objection Deadline", 
   top: 70.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField OffRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 70.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Title Resolution Deadline", 
   top: 82.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Title Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 82.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Third Party Right to PurchaseApprove Deadline", 
   top: 94.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Third Party Right to PurchaseApprove Deadline", 
   fontSize: 9,
   type: "date",
   top: 94.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Association Documents Deadline", 
   top: 118.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Association Documents Deadline", 
   fontSize: 9,
   type: "date",
   top: 118.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Association Documents Termination Deadline", 
   top: 130.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Association Documents Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 130.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Sellers Property Disclosure Deadline", 
   top: 154.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Sellers Property Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 154.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "LeadBased Paint Disclosure Deadline", 
   top: 166.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField LeadBased Paint Disclosure Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 166.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "New Loan Application Deadline", 
   top: 190.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField New Loan Application Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 190.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "New Loan Terms Deadline", 
   top: 202.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField New Loan Terms Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 202.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "New Loan Availability Deadline", 
   top: 214.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField New Loan Availability Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 214.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Buyers Credit Information Deadline", 
   top: 226.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Buyers Credit Information Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 226.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Disapproval of Buyers Credit Information Deadline", 
   top: 238.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Disapproval of Buyers Credit Information Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 238.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Existing Loan Deadline", 
   top: 250.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Existing Loan Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 250.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Existing Loan Termination Deadline", 
   top: 262.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Existing Loan Termination Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 262.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Loan Transfer Approval Deadline", 
   top: 274.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Loan Transfer Approval Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 274.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller or Private Financing Deadline", 
   top: 286.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Seller or Private Financing Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 286.562,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Appraisal Deadline", 
   top: 310.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Appraisal Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 310.56,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Appraisal Objection Deadline", 
   top: 322.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Appraisal Objection Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 322.56,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Appraisal Resolution Deadline", 
   top: 334.56,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Appraisal Resolution Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 334.56,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "New ILC or New Survey Deadline", 
   top: 358.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField New ILC or New Survey Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 358.68,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "New ILC or New Survey Objection Deadline", 
   top: 370.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField New ILC or New Survey Objection Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 370.68,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "New ILC or New Survey Resolution Deadline", 
   top: 382.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField New ILC or New Survey Resolution Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 9,
   top: 382.68,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Water Rights Examination Deadline", 
   top: 406.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Water Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 406.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Mineral Rights Examination Deadline", 
   top: 418.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Mineral Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 418.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Inspection Termination Deadline", 
   top: 430.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Inspection Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 430.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Inspection Objection Deadline", 
   top: 442.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Inspection Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 442.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Inspection Resolution Deadline", 
   top: 454.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Inspection Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 454.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Property Insurance Termination Deadline", 
   top: 466.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Property Insurance Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 466.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Due Diligence Documents Delivery Deadline", 
   top: 478.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Due Diligence Documents Delivery Deadline", 
   fontSize: 9,
   type: "date",
   top: 478.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Due Diligence Documents Objection Deadline", 
   top: 490.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Due Diligence Documents Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 490.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Due Diligence Documents Resolution Deadline", 
   top: 502.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Due Diligence Documents Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 502.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Conditional Sale Deadline", 
   top: 514.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Conditional Sale Deadline", 
   fontSize: 9,
   type: "date",
   top: 514.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "LeadBased Paint Termination Deadline", 
   top: 526.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField LeadBased Paint Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 526.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Closing Date", 
   top: 550.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Closing Date", 
   fontSize: 9,
   type: "date",
   top: 550.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Possession Date", 
   top: 562.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Possession Date", 
   fontSize: 9,
   type: "date",
   top: 562.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Possession Time", 
   top: 574.68,
   left: 365.8,
   width: 202,
   height: 11.04
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Acceptance Deadline Date", 
   top: 586.68,
   left: 365.8,
   width: 146.398,
   height: 11.04
}
,
{
   page: 2,
   name: "DateField Acceptance Deadline Date", 
   fontSize: 9,
   type: "date",
   top: 586.682,
   left: 512.52,
   width: 66.108,
   height: 11.040
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Acceptance Deadline Time", 
   top: 598.68,
   left: 365.8,
   width: 202,
   height: 11.04
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Acceptance Deadline TimeRow1", 
   top: 610.68,
   left: 129.16,
   width: 234,
   height: 11.04
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Text40", 
   top: 610.68,
   left: 365.8,
   width: 202,
   height: 11.04
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Acceptance Deadline TimeRow2", 
   top: 622.68,
   left: 129.16,
   width: 234,
   height: 11.04
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Text41", 
   top: 622.68,
   left: 365.8,
   width: 202,
   height: 11.04
}
,
{
   page: 3,
   type: "checkbox",
   name: "Will", 
   top: 197.04,
   left: 89.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "Will Not be extended to the next day that is not a Saturday Sunday or Holiday Should neither box be checked", 
   top: 197.04,
   left: 125.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Amount", 
   top: 272.3,
   left: 380.64,
   width: 91.309,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_25", 
   top: 285.00,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_27", 
   top: 297.00,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_29", 
   top: 309.00,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_31", 
   top: 321.00,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Earnest Money",  // actually Seller Financing
   top: 332.6,
   left: 482.858,
   width: 88.876,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_32", 
   top: 369.12,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_34", 
   top: 381.12,
   left: 379.8,
   width: 91.92,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_35", 
   top: 381.12,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Concession may be used for any Buyer fee cost charge or expenditure to the extent the amount is allowed by the Buyers lender", 
   top: 397,
   left: 368.04,
   width: 69.84,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Earnest Money Holder in its trust account on behalf of", 
   top: 466.5,
   left: 432.24,
   width: 109.80,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "both Seller and Buyer The Earnest Money deposit must be tendered by Buyer with this Contract unless the parties mutually agree", 
   top: 478,
   left: 143.52,
   width: 199.68,
   height: 11.04
}
,
{
   page: 4,
   type: "checkbox",
   name: "Available Funds Buyer represents that Buyer as of the date of this Contract", 
   top: 173.52,
   left: 462,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Does_2", 
   top: 173.52,
   left: 502.2,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "172", 
   top: 277.08,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Conventional", 
   top: 277.08,
   left: 127.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "FHA", 
   top: 277.08,
   left: 167.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "VA", 
   top: 277.08,
   left: 201,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Bond", 
   top: 277.08,
   left: 242.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "If either or both of the FHA or VA boxes are checked and Buyer closes the transaction using one of those loan types Seller agrees", 
   top: 276.24,
   left: 284.16,
   width: 288.36,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Loan Estimate  Monthly Payment and Loan Costs Buyer is advised to review the terms conditions and", 
   top: 299.16,
   left: 411.36,
   width: 161.16,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "set forth in  41 Price and Terms presently payable at", 
   top: 367,
   left: 282.84,
   width: 69.96,
   height: 11.04
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "per", 
   top: 367,
   left: 369.84,
   width: 79.92,
   height: 11.04
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "presently at the rate of", 
   top: 378.393,
   left: 142.08,
   width: 39.84,
   height: 11.367
}
,
{
   page: 4,
   type: "checkbox",
   name: "per annum and also including escrow for the following as indicated", 
   top: 380.64,
   left: 470.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Real Estate Taxes", 
   top: 380.64,
   left: 565.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Property Insurance Premium", 
   top: 392.16,
   left: 182.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Mortgage Insurance Premium and", 
   top: 392.16,
   left: 347.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "At the time of assumption the new interest rate will", 
   top: 391.32,
   left: 360.96,
   width: 211.56,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "per annum and the new payment will not exceed", 
   top: 402.480,
   left: 294.72,
   width: 64.80,
   height: 10.6
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "interest plus escrow if any If the actual principal balance of the existing loan at Closing is less than the Assumption Balance which", 
   top: 413.8,
   left: 97.2,
   width: 39.96,
   height: 10.8
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "per_2", 
   top: 413.8,
   left: 355.32,
   width: 64.80,
   height: 10.2
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "principal and", 
   top: 414.0,
   left: 439.08,
   width: 79.92,
   height: 10.2
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "provisions of the loan change Buyer has the Right to Terminate under  241 on or before Closing Date", 
   top: 436.92,
   left: 411.6,
   width: 65.04,
   height: 10.32
}
,
{
   page: 4,
   type: "checkbox",
   name: "Seller_2", 
   top: 461.16,
   left: 99.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Will_2", 
   top: 461.16,
   left: 135.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "from liability will be evidenced by delivery", 
   top: 472.68,
   left: 231.36,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "on or before Loan Transfer Approval Deadline", 
   top: 472.68,
   left: 449.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "in an amount", 
   top: 483.24,
   left: 412.32,
   width: 106.32,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller or Private Financing", 
   top: 494.40,
   left: 111.24,
   width: 64.80,
   height: 10.32
}
,
{
   page: 4,
   type: "checkbox",
   name: "Seller Financing If Buyer is to pay all or any portion of the Purchase Price with Seller financing", 
   top: 564.60,
   left: 536.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "198", 
   top: 576.12,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller will deliver the proposed Seller financing documents to the other party on or before", 
   top: 574.92,
   left: 439.8,
   width: 45.0,
   height: 10.32
}
,
{
   page: 6,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "and option of proceeding with the consummation of this Contract without regard to the amount of the appraised valuation The", 
   top: 103.320,
   left: 314.04,
   width: 69.96,
   height: 11.04
}
,
{
   page: 6,
   type: "checkbox",
   name: "Buyer_2", 
   top: 288.48,
   left: 536.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 6,
   type: "checkbox",
   name: "Seller The cost of the Appraisal may include any and all fees paid to the appraiser appraisal management company lenders", 
   top: 300.00,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Check Box44", 
   top: 357.119,
   left: 51.3162,
   width: 9.4254,
   height: 10.996
}
,
{
   page: 7,
   type: "checkbox",
   name: "an Abstract of Title certified to a current date Seller will cause the title insurance policy to be issued", 
   top: 392.64,
   left: 154.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Check Box45", 
   top: 415.242,
   left: 51.3162,
   width: 9.9490,
   height: 10.473
}
,
{
   page: 7,
   type: "checkbox",
   name: "Will_3", 
   top: 461.64,
   left: 411.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Will Not contain Owners", 
   top: 461.64,
   left: 452.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Buyer_3", 
   top: 519.12,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Seller_3", 
   top: 519.12,
   left: 93.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller", 
   top: 519.12,
   left: 137.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Other_2", 
   top: 519.12,
   left: 339,
   width: 10.08,
   height: 9.96
}
,
{
   page: 7,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Regardless of whether the Contract requires OEC the Title Insurance Commitment may not provide OEC or delete or insure over", 
   top: 517.92,
   left: 378,
   width: 129.84,
   height: 10.32
}
,
{
   page: 8,
   type: "checkbox",
   name: "Seller_4", 
   top: 449.64,
   left: 291.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 8,
   type: "checkbox",
   name: "Buyer for the Property listing any special taxing districts", 
   top: 449.64,
   left: 333.96,
   width: 10.08,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "Does_3", 
   top: 449.64,
   left: 245.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "Does Not have a Right to Terminate if examination of the Mineral", 
   top: 449.64,
   left: 287.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "New Improvement Location Certificate New ILC or 2", 
   top: 496.08,
   left: 316.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "New Survey in the form of", 
   top: 507.60,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "New ILC or New Survey If the box is checked 1", 
   top: 506.40,
   left: 176.16,
   width: 215.04,
   height: 11.04
}
,
{
   page: 9,
   type: "checkbox",
   name: "Seller_5", 
   top: 519.12,
   left: 309.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "Buyer will order the New ILC or New Survey The", 
   top: 521.166,
   left: 353.76,
   width: 7.914,
   height: 7.914
}
,
{
   page: 9,
   type: "checkbox",
   name: "Seller_6", 
   top: 565.08,
   left: 102.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "Buyer or", 
   top: 565.08,
   left: 145.92,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Text4", 
   top: 563.509,
   left: 198.999,
   width: 377.212,
   height: 37.721
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Delivery of New ILC or New Survey Buyer Seller the issuer of the Title Commitment or the provider of", 
   top: 609.960,
   left: 237.72,
   width: 104.88,
   height: 11.04
}
,
{
   page: 10,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "CBSF1_9", 
   top: 719.3564,
   left: 91.3744,
   width: 486.3056,
   height: 31.3636
}
,
{
   page: 11,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Text46", 
   top: 80.116,
   left: 145.047,
   width: 429.380,
   height: 37.178
}
,
{
   page: 11,
   type: "checkbox",
   name: "Will_4", 
   top: 139.08,
   left: 359.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Will Not assume the Sellers obligations", 
   top: 139.08,
   left: 395.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Will_5", 
   top: 196.56,
   left: 510.36,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Will_6", 
   top: 196.56,
   left: 544.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Text47", 
   top: 241.396,
   left: 141.381,
   width: 433.570,
   height: 46.079
}
,
{
   page: 11,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "the Right to Terminate under  241 effective upon Sellers receipt of Buyers Notice to Terminate on or before Conditional Sale", 
   top: 436.92,
   left: 221.88,
   width: 296.64,
   height: 10.68
}
,
{
   page: 11,
   type: "checkbox",
   name: "Does_4", 
   top: 495.60,
   left: 483.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Does Not", 
   top: 495.60,
   left: 523.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "There is No Well  Buyer", 
   top: 518.64,
   left: 106.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Does_5", 
   top: 518.64,
   left: 224.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Does Not acknowledge receipt of a copy of the current well permit", 
   top: 518.64,
   left: 263.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "and the Homeowner Warning Notice HWN65810 has not been translated to the Sellers principal language prior to Seller signing", 
   top: 345.84,
   left: 223.32,
   width: 151.20,
   height: 10.32
}
,
{
   page: 12,
   type: "checkbox",
   name: "Seller will sign and complete all customary or reasonably required documents at or before Closing", 
   top: 526.08,
   left: 430.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "additional information and documents required by Closing Company that will be necessary to complete this transaction Buyer and", 
   top: 526.08,
   left: 467.76,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Disclosure of Settlement Costs Buyer and Seller acknowledge that costs quality and extent of service vary between", 
   top: 570.960,
   left: 304.92,
   width: 199.68,
   height: 11.04
}
,
{
   page: 12,
   type: "checkbox",
   name: "TRANSFER OF TITLE Subject to Buyers compliance with the terms and provisions of this Contract including the tender", 
   top: 664.56,
   left: 565.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "of any payment due at Closing Seller must execute and deliver the following good and sufficient deed to Buyer at Closing", 
   top: 676.08,
   left: 141.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "bargain and sale deed", 
   top: 676.08,
   left: 251.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "quit claim deed", 
   top: 676.08,
   left: 358.56,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "personal representatives deed", 
   top: 676.08,
   left: 440.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "Check Box57", 
   top: 687.0080,
   left: 51.3162,
   width: 9.9490,
   height: 10.9968
}
,
{
   page: 12,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "sufficient special warranty deed to Buyer at Closing", 
   top: 686.4000,
   left: 65.52,
   width: 139.80,
   height: 10.8437
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_4", 
   top: 174.00,
   left: 488.04,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_7", 
   top: 174.00,
   left: 537.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_2", 
   top: 185.520,
   left: 51.12,
   width: 9.96,
   height: 10.484
}
,
{
   page: 13,
   type: "checkbox",
   name: "Other_3", 
   top: 185.52,
   left: 252.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Association Fees and Required Disbursements At least fourteen days prior to Closing Date Seller agrees to", 
   top: 184.8,
   left: 294.36,
   width: 278.16,
   height: 10.2
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_5", 
   top: 231.48,
   left: 536.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_8", 
   top: 243.00,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_3", 
   top: 243.00,
   left: 94.56,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA", 
   top: 243.00,
   left: 296.4,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_6", 
   top: 254.52,
   left: 395.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_9", 
   top: 254.52,
   left: 439.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer", 
   top: 254.52,
   left: 482.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_2", 
   top: 266.04,
   left: 154.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_7", 
   top: 300.48,
   left: 63.6,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_10", 
   top: 300.48,
   left: 108.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_4", 
   top: 300.48,
   left: 152.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_3", 
   top: 300.48,
   left: 354.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Other Fees Any other fee listed in the Status Letter as required to be paid at Closing will be paid by", 
   top: 312.00,
   left: 565.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_11", 
   top: 323.52,
   left: 82.2,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_5", 
   top: 323.52,
   left: 125.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_4", 
   top: 323.52,
   left: 327.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_8", 
   top: 335.04,
   left: 414.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_12", 
   top: 335.04,
   left: 462.36,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by", 
   top: 335.04,
   left: 508.2,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_5", 
   top: 346.56,
   left: 183.36,
   width: 9.96,
   height: 10.08
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_9", 
   top: 369.60,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_13", 
   top: 369.60,
   left: 96.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_6", 
   top: 369.60,
   left: 139.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_6", 
   top: 369.60,
   left: 341.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_10", 
   top: 392.64,
   left: 486.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_14", 
   top: 392.64,
   left: 536.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_7", 
   top: 404.16,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_7", 
   top: 404.16,
   left: 250.44,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Water StockCertificates", 
   top: 426.000,
   left: 55.44,
   width: 60.00,
   height: 11.04
}
,
{
   page: 13,
   type: "checkbox",
   name: "undefined_3", 
   top: 438.60,
   left: 73.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Water District", 
   top: 438.60,
   left: 231.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Augmentation Membership", 
   top: 450.12,
   left: 73.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Small Domestic Water Company", 
   top: 450.12,
   left: 231.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Check Box56", 
   top: 450.326,
   left: 410.529,
   width: 10.473,
   height: 9.949
}
,
{
   page: 13,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "NA_8", 
   top: 449.4,
   left: 424.56,
   width: 148.08,
   height: 10.2
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_11", 
   top: 461.64,
   left: 177.6,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_15", 
   top: 461.64,
   left: 222.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Utility Transfer Fees Utility transfer fees can change Any fees to transfer utilities from Seller to Buyer must be", 
   top: 461.64,
   left: 266.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "undefined_4", 
   top: 461.64,
   left: 465.6,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_12", 
   top: 484.68,
   left: 85.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_16", 
   top: 484.68,
   left: 130.92,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_9", 
   top: 484.68,
   left: 174.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_9", 
   top: 484.68,
   left: 376.44,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "IS a foreign", 
   top: 530.64,
   left: 514.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Taxes for the Calendar Year Immediately Preceding Closing", 
   top: 703.56,
   left: 189.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Most Recent Mill Levy", 
   top: 703.56,
   left: 466.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Other_4", 
   top: 726.60,
   left: 141.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined_5", 
   top: 725.88,
   left: 182.4,
   width: 264.12,
   height: 10.20
}
,
{
   page: 14,
   type: "checkbox",
   name: "Rents Actually Received", 
   top: 58.56,
   left: 238.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 14,
   type: "checkbox",
   name: "Accrued At Closing Seller will transfer or credit", 
   top: 58.56,
   left: 362.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 14,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Final Settlement Unless otherwise specified in Additional Provisions these prorations are final", 
   top: 91.80,
   left: 488.76,
   width: 84.84,
   height: 10.32
}
,
{
   page: 14,
   type: "checkbox",
   name: "Buyer_13", 
   top: 162.00,
   left: 403.44,
   width: 9.96,
   height: 9.96
}
,
{
   page: 14,
   type: "checkbox",
   name: "Seller Except however any", 
   top: 162.00,
   left: 448.92,
   width: 9.96,
   height: 9.96
}
,
{
   page: 14,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Association Assessments are subject to change as provided in the Governing Documents", 
   top: 206.880,
   left: 50.4,
   width: 149.88,
   height: 11.04
}
,
{
   page: 14,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "notwithstanding  33 Day from Possession Date and Possession Time until possession is delivered", 
   top: 265.20,
   left: 377.52,
   width: 69.12,
   height: 10.32
}
,
{
   page: 14,
   type: "checkbox",
   name: "Does Not represent that Buyer will occupy the Property as Buyers principal residence", 
   top: 300.60,
   left: 80.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 15,
   type: "checkbox",
   name: "Check Box55", 
   top: 266.006,
   left: 51.8398,
   width: 8.9018,
   height: 9.949
}
,
{
   page: 16,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Electronic Delivery Electronic Delivery of documents and notice may be delivered by 1 email at the email address", 
   top: 403.92,
   left: 408.72,
   width: 164.88,
   height: 10.32
}
,
{
   page: 16,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Text48", 
   top: 675.5470,
   left: 52.9335,
   width: 522.0175,
   height: 71.6794
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "More Provisions Added Field", 
   top: 58,
   left: 73.3088,
   width: 497.4532,
   height: 68
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Text49", 
   top: 149.759,
   left: 73.3088,
   width: 497.4532,
   height: 59.171
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Text50", 
   top: 217.832,
   left: 73.3088,
   width: 493.7872,
   height: 61.265
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined_6", 
   top: 320.76,
   left: 434.924,
   width: 119.596,
   height: 12.72
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "WORKING FOR", 
   top: 409.08,
   left: 86.4,
   width: 270.24,
   height: 12.72
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "CANNOT ASK YOU TO SIGN OR HAVE YOU SIGN ANY DEED OR ANY OTHER", 
   top: 422.88,
   left: 179.76,
   width: 253.44,
   height: 12.72
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "OF CANCELLATION FORM FOR AN EXPLANATION OF THIS RIGHT", 
   top: 541.68,
   left: 50.4,
   width: 180.12,
   height: 12.72
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Buyers Name", 
   top: 660.84,
   left: 121.8,
   width: 182.52,
   height: 12.72
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Buyers Name_2", 
   top: 660.84,
   left: 393.24,
   width: 184.20,
   height: 12.72
}
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Buyers Signature", 
//    top: 698.64,
//    left: 50.4,
//    width: 193.32,
//    height: 12.72
// }
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "text",
//    name: "Date_2", 
//    top: 698.64,
//    left: 248.4,
//    width: 55.92,
//    height: 12.72
// }
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Buyers Signature_2", 
//    top: 698.64,
//    left: 324.84,
//    width: 187.20,
//    height: 12.72
// }
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "text",
//    name: "Date_3", 
//    top: 698.64,
//    left: 516.72,
//    width: 60.72,
//    height: 12.72
// }
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "1", 
   top: 57.72,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "1_2", 
   top: 57.72,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "2", 
   top: 69.72,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "2_2", 
   top: 69.72,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "3", 
   top: 81.72,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "3_2", 
   top: 81.72,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "4", 
   top: 93.72,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "4_2", 
   top: 93.72,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Email Address", 
   top: 105.72,
   left: 135.24,
   width: 169.08,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Email Address_2", 
   top: 105.72,
   left: 409.8,
   width: 167.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Sellers Name", 
   top: 152.64,
   left: 121.8,
   width: 182.52,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Sellers Name_2", 
   top: 152.64,
   left: 393.24,
   width: 184.20,
   height: 11.40
}
// ,
// {
//    page: 18,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Sellers Signature", 
//    top: 189.96,
//    left: 50.4,
//    width: 200.509,
//    height: 11.40
// }
// ,
// {
//    page: 18,
//    fontName: "Helvetica",
//    fontSize: 12,
//    type: "text",
//    name: "Text51", 
//    top: 190.079,
//    left: 251.868,
//    width: 52.364,
//    height: 12.044
// }
// ,
// {
//    page: 18,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Sellers Signature_2", 
//    top: 189.960,
//    left: 324.84,
//    width: 194.477,
//    height: 11.924
// }
// ,
// {
//    page: 18,
//    fontName: "Helvetica",
//    fontSize: 12,
//    type: "text",
//    name: "Text52", 
//    top: 190.079,
//    left: 519.445,
//    width: 57.077,
//    height: 11.520
// }
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 1", 
   top: 225.0,
   left: 135.96,
   width: 168.36,
   height: 11.4
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 1_2", 
   top: 225.0,
   left: 410.52,
   width: 166.92,
   height: 11.4
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 2", 
   top: 237.0,
   left: 135.96,
   width: 168.36,
   height: 11.4
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 2_2", 
   top: 237.0,
   left: 410.52,
   width: 166.92,
   height: 11.4
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 3", 
   top: 249.0,
   left: 135.96,
   width: 168.36,
   height: 11.4
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 3_2", 
   top: 249.0,
   left: 410.52,
   width: 166.92,
   height: 11.4
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 4", 
   top: 261.0,
   left: 135.96,
   width: 168.36,
   height: 11.4
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 4_2", 
   top: 261.0,
   left: 410.52,
   width: 166.92,
   height: 11.4
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Email Address_3", 
   top: 273.0,
   left: 135.24,
   width: 169.08,
   height: 11.4
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Email Address_4", 
   top: 273.0,
   left: 409.8,
   width: 167.64,
   height: 11.4
}
,
{
   page: 18,
   type: "checkbox",
   name: "Does_6", 
   top: 404.76,
   left: 89.76,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the Earnest", 
   top: 404.76,
   left: 130.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Buyers Agent", 
   top: 474.36,
   left: 199.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "TransactionBroker in this transaction", 
   top: 474.36,
   left: 282.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
   top: 497.76,
   left: 56.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Listing Brokerage Firm", 
   top: 521.28,
   left: 309.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Buyer_14", 
   top: 521.28,
   left: 427.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Other_5", 
   top: 521.28,
   left: 472.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined_7", 
   top: 519.36,
   left: 512.88,
   width: 65.04,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms Name 1", 
   top: 590.04,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms License 1", 
   top: 602.04,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms Name 2", 
   top: 614.04,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms License 2", 
   top: 626.04,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "signature",
   name: "BrokerSignature", 
   top: 648.349,
   left: 180.96,
   width: 321.524,
   height: 12.971
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Text53", 
   top: 648.260,
   left: 503.213,
   width: 79.069,
   height: 12.567
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 1_3", 
   top: 684.96,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 2_3", 
   top: 696.96,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 3_3", 
   top: 708.96,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 4_3", 
   top: 720.96,
   left: 180.24,
   width: 402.36,
   height: 11.40
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "2_3", 
   top: 58.2,
   left: 180.96,
   width: 401.64,
   height: 11.4
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "3_3", 
   top: 70.2,
   left: 180.96,
   width: 401.64,
   height: 11.4
}
,
{
   page: 19,
   type: "checkbox",
   name: "Does_7", 
   top: 138.48,
   left: 89.76,
   width: 9.96,
   height: 9.96
}
,
{
   page: 19,
   type: "checkbox",
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the Earnest_2", 
   top: 138.48,
   left: 130.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 19,
   type: "checkbox",
   name: "Sellers Agent", 
   top: 207.96,
   left: 198.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 19,
   type: "checkbox",
   name: "TransactionBroker in this transaction_2", 
   top: 207.96,
   left: 276.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 19,
   type: "checkbox",
   name: "Customer  Broker has no brokerage relationship with Seller See  A for Brokers brokerage relationship with Buyer", 
   top: 231.48,
   left: 56.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 19,
   type: "checkbox",
   name: "Seller_17", 
   top: 255.00,
   left: 315.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 19,
   type: "checkbox",
   name: "Buyer_15", 
   top: 255.00,
   left: 359.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 19,
   type: "checkbox",
   name: "Other_6", 
   top: 255.00,
   left: 404.4,
   width: 9.96,
   height: 9.96
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined_8", 
   top: 253.08,
   left: 445.8,
   width: 132.12,
   height: 11.40
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms Name 1_2", 
   top: 323.64,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms License 1_2", 
   top: 335.64,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms Name 2_2", 
   top: 347.76,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms License 2_2", 
   top: 359.76,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "signature",
   name: "Brokerage Firms License 3_2", 
   top: 383.76,
   left: 180.96,
   width: 330.055,
   height: 11.40
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 12,
   type: "date",
   name: "Text54", 
   top: 383.824,
   left: 510.544,
   width: 67.548,
   height: 12.044
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 1_4", 
   top: 418.8,
   left: 180.96,
   width: 397.08,
   height: 11.4
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 2_4", 
   top: 430.8,
   left: 180.96,
   width: 397.08,
   height: 11.4
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 3_4", 
   top: 442.8,
   left: 180.96,
   width: 397.08,
   height: 11.4
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 4_4", 
   top: 454.8,
   left: 180.96,
   width: 397.08,
   height: 11.4
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 5", 
   top: 466.8,
   left: 180.96,
   width: 397.08,
   height: 11.4
}
,
{
   page: 19,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 6", 
   top: 478.8,
   left: 180.24,
   width: 397.80,
   height: 11.4
}
] }
