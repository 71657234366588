export function sellersPropertyDisclosureLand2024() {
   return [   //2024 Release 2023-12-19 07:47:51
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 346.08,
      left: 96.24,
      width: 453.00,
      height: 14.88
   }
   ,
   {
      page: 0,
      name: "Property", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 363.36,
      left: 111.6,
      width: 437.64,
      height: 14.88
   }
   ,
   {
      page: 0,
      name: "Seller", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 380.64,
      left: 100.56,
      width: 448.68,
      height: 14.88
   }
   ,
   {
      page: 0,
      name: "Year Seller Acquired Property", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 397.68,
      left: 198.96,
      width: 350.28,
      height: 14.88
   }
   ,
   {
      page: 0,
      name: "undefined", 
      isText: true,
      fontSize: 10,
      type: "checkbox",
      top: 558.84,
      left: 227.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 0,
      name: "Comments", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 536.76,
      left: 247.2,
      width: 327.72,
      height: 55.08
   }
   ,
   {
      page: 0,
      name: "Flooding or drainage2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 593.4,
      left: 68.88,
      width: 148.68,
      height: 26.04
   }
   ,
   {
      page: 0,
      name: "undefined_2", 
      isText: true,
      type: "checkbox",
      top: 601.08,
      left: 227.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 0,
      name: "Comments_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 593.4,
      left: 246.96,
      width: 328.20,
      height: 26.04
   }
   ,
   {
      page: 0,
      name: "Flooding or drainage3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 620.76,
      left: 68.64,
      width: 149.16,
      height: 19.80
   }
   ,
   {
      page: 0,
      name: "undefined_3", 
      isText: true,
      type: "checkbox",
      top: 625.32,
      left: 227.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 0,
      name: "Comments_3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 620.76,
      left: 246.72,
      width: 328.68,
      height: 19.80
   }
   ,
   {
      page: 0,
      name: "undefined_4", 
      isText: true,
      type: "checkbox",
      top: 697.32,
      left: 227.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 0,
      name: "Comments_4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 687.96,
      left: 246.96,
      width: 328.20,
      height: 29.04
   }
   ,
   {
      page: 0,
      name: "Drainage retention ponds5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 718.68,
      left: 68.64,
      width: 149.16,
      height: 20.52
   }
   ,
   {
      page: 0,
      name: "undefined_5", 
      isText: true,
      type: "checkbox",
      top: 723.72,
      left: 227.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 0,
      name: "Comments_5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 718.68,
      left: 246.72,
      width: 328.68,
      height: 20.52
   }
   ,
   {
      page: 1,
      name: "Public", 
      isText: false,
      type: "checkbox",
      top: 90.36,
      left: 159.72,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "Community", 
      isText: false,
      type: "checkbox",
      top: 90.36,
      left: 203.64,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "Well", 
      isText: false,
      type: "checkbox",
      top: 90.36,
      left: 267.48,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "Shared Well", 
      isText: false,
      type: "checkbox",
      top: 90.36,
      left: 305.64,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "Other", 
      isText: false,
      type: "checkbox",
      top: 90.36,
      left: 371.16,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "None", 
      isText: false,
      type: "checkbox",
      top: 90.36,
      left: 412.68,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "Is", 
      isText: false,
      type: "checkbox",
      top: 105.72,
      left: 302.28,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "undefined_6", 
      isText: false,
      type: "checkbox",
      top: 105.72,
      left: 332.52,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "Is Not attached Well Permit", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 98.771,
      left: 466.92,
      width: 106.178,
      height: 15.709
   }
   ,
   {
      page: 1,
      name: "Are", 
      isText: false,
      type: "checkbox",
      top: 121.32,
      left: 143.64,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "Are not attached Shared Well Agreement", 
      isText: false,
      type: "checkbox",
      top: 121.32,
      left: 178.2,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "Yes_3", 
      isText: false,
      type: "checkbox",
      top: 121.32,
      left: 352.68,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "No", 
      isText: false,
      type: "checkbox",
      top: 121.32,
      left: 387.72,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 1,
      name: "undefined_7", 
      isText: false,
      type: "checkbox",
      top: 242.04,
      left: 219,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 1,
      name: "Comments_6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 227.16,
      left: 239.52,
      width: 330.36,
      height: 42.60
   }
   ,
   {
      page: 1,
      name: "undefined_8", 
      isText: true,
      type: "checkbox",
      top: 283.56,
      left: 219.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 1,
      name: "Comments_7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 274.08,
      left: 239.28,
      width: 330.84,
      height: 30.60
   }
   ,
   {
      page: 1,
      name: "undefined_9", 
      isText: true,
      type: "checkbox",
      top: 324.84,
      left: 219.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 1,
      name: "Comments_8", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 308.28,
      left: 239.52,
      width: 330.36,
      height: 44.28
   }
   ,
   {
      page: 1,
      name: "undefined_10", 
      isText: true,
      type: "checkbox",
      top: 366.36,
      left: 219.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 1,
      name: "Comments_9", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 356.88,
      left: 239.28,
      width: 330.84,
      height: 30.60
   }
   ,
   {
      page: 1,
      name: "undefined_11", 
      isText: true,
      type: "checkbox",
      top: 402.6,
      left: 219.96,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 1,
      name: "Comments_10", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 393.12,
      left: 239.28,
      width: 330.84,
      height: 30.36
   }
   ,
   {
      page: 1,
      name: "undefined_12", 
      isText: true,
      type: "checkbox",
      top: 440.52,
      left: 219.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 1,
      name: "Comments_11", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 426.84,
      left: 239.52,
      width: 330.36,
      height: 38.52
   }
   ,
   {
      page: 1,
      name: "undefined_13", 
      isText: true,
      type: "checkbox",
      top: 478.92,
      left: 219.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 1,
      name: "Comments_12", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 469.44,
      left: 239.28,
      width: 330.84,
      height: 30.48
   }
   ,
   {
      page: 1,
      name: "undefined_14", 
      isText: true,
      type: "checkbox",
      top: 517.08,
      left: 219.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 1,
      name: "Comments_13", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 503.4,
      left: 239.52,
      width: 330.36,
      height: 38.52
   }
   ,
   {
      page: 1,
      name: "undefined_15", 
      isText: true,
      type: "checkbox",
      top: 555.48,
      left: 219.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 1,
      name: "Comments_14", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 546,
      left: 239.28,
      width: 330.84,
      height: 30.48
   }
   ,
   {
      page: 1,
      name: "undefined_16", 
      isText: true,
      type: "checkbox",
      top: 591.24,
      left: 219.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 1,
      name: "Comments_15", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 581.76,
      left: 239.28,
      width: 330.84,
      height: 30.24
   }
   ,
   {
      page: 1,
      name: "undefined_17", 
      isText: true,
      type: "checkbox",
      top: 627,
      left: 219.96,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 1,
      name: "Comments_16", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 617.52,
      left: 239.28,
      width: 330.84,
      height: 30.48
   }
   ,
   {
      page: 1,
      name: "Grandfathered conditions or uses12", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 653.76,
      left: 60,
      width: 150.12,
      height: 30.48
   }
   ,
   {
      page: 1,
      name: "undefined_18", 
      isText: true,
      type: "checkbox",
      top: 663.24,
      left: 219.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 1,
      name: "Comments_17", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 653.76,
      left: 239.28,
      width: 330.84,
      height: 30.48
   }
   ,
   {
      page: 1,
      name: "Grandfathered conditions or uses13", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 687.72,
      left: 60.24,
      width: 149.64,
      height: 52.44
   }
   ,
   {
      page: 1,
      name: "undefined_19", 
      isText: true,
      type: "checkbox",
      top: 708.6,
      left: 219.96,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 1,
      name: "Comments_18", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 687.72,
      left: 239.52,
      width: 330.36,
      height: 52.44
   }
   ,
   {
      page: 2,
      name: "undefined_20", 
      isText: true,
      type: "checkbox",
      top: 112.2,
      left: 218.52,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 2,
      name: "Comments_19", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 94.2,
      left: 238.08,
      width: 336.84,
      height: 46.92
   }
   ,
   {
      page: 2,
      name: "undefined_21", 
      isText: true,
      type: "checkbox",
      top: 159.24,
      left: 218.52,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_20", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 142.92,
      left: 238.08,
      width: 336.84,
      height: 43.56
   }
   ,
   {
      page: 2,
      name: "undefined_22", 
      isText: true,
      type: "checkbox",
      top: 204.12,
      left: 218.52,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_21", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 188.28,
      left: 238.08,
      width: 336.84,
      height: 42.84
   }
   ,
   {
      page: 2,
      name: "undefined_23", 
      isText: true,
      type: "checkbox",
      top: 246.84,
      left: 218.52,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_22", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 232.92,
      left: 238.08,
      width: 336.84,
      height: 38.52
   }
   ,
   {
      page: 2,
      name: "undefined_24", 
      isText: true,
      type: "checkbox",
      top: 287.16,
      left: 218.52,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_23", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 273.24,
      left: 238.08,
      width: 336.84,
      height: 38.52
   }
   ,
   {
      page: 2,
      name: "undefined_25", 
      isText: true,
      type: "checkbox",
      top: 327.48,
      left: 218.52,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_24", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 313.56,
      left: 238.08,
      width: 336.84,
      height: 39.00
   }
   ,
   {
      page: 2,
      name: "undefined_26", 
      isText: true,
      type: "checkbox",
      top: 368.28,
      left: 218.52,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_25", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 354.36,
      left: 238.08,
      width: 336.84,
      height: 39.00
   }
   ,
   {
      page: 2,
      name: "undefined_27", 
      isText: false,
      type: "checkbox",
      top: 407.88,
      left: 217.56,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 2,
      name: "Comments_26", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 395.16,
      left: 238.08,
      width: 336.84,
      height: 38.52
   }
   ,
   {
      page: 2,
      name: "Any limitations on parking or access due to size number of vehicles or type of vehicles in the past year9", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 435.48,
      left: 62.64,
      width: 145.80,
      height: 38.52
   }
   ,
   {
      page: 2,
      name: "undefined_28", 
      isText: true,
      type: "checkbox",
      top: 449.16,
      left: 218.52,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_27", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 435.48,
      left: 238.08,
      width: 336.84,
      height: 38.52
   }
   ,
   {
      page: 2,
      name: "Any limitations on parking or access due to size number of vehicles or type of vehicles in the past year10", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 475.56,
      left: 62.4,
      width: 146.28,
      height: 29.16
   }
   ,
   {
      page: 2,
      name: "undefined_29", 
      isText: true,
      type: "checkbox",
      top: 484.92,
      left: 218.52,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_28", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 475.56,
      left: 237.84,
      width: 337.32,
      height: 29.16
   }
   ,
   {
      page: 2,
      name: "undefined_30", 
      isText: true,
      type: "checkbox",
      top: 605.64,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_29", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 578.04,
      left: 242.64,
      width: 332.04,
      height: 66.36
   }
   ,
   {
      page: 2,
      name: "undefined_31", 
      isText: true,
      type: "checkbox",
      top: 660.84,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_30", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 646.2,
      left: 242.64,
      width: 332.04,
      height: 40.2
   }
   ,
   {
      page: 2,
      name: "undefined_32", 
      isText: true,
      type: "checkbox",
      top: 699.72,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 2,
      name: "Comments_31", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 687.622,
      left: 242.4,
      width: 332.52,
      height: 32.978
   }
   ,
   {
      page: 3,
      name: "undefined_33", 
      isText: true,
      type: "checkbox",
      top: 65.16,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_34", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 49.08,
      left: 242.88,
      width: 331.32,
      height: 43.08
   }
   ,
   {
      page: 3,
      name: "undefined_35", 
      isText: true,
      type: "checkbox",
      top: 112.92,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_36", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 94.92,
      left: 242.88,
      width: 331.32,
      height: 46.92
   }
   ,
   {
      page: 3,
      name: "undefined_37", 
      isText: true,
      type: "checkbox",
      top: 157.32,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_38", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 143.64,
      left: 242.88,
      width: 331.32,
      height: 38.52
   }
   ,
   {
      page: 3,
      name: "undefined_39", 
      isText: true,
      type: "checkbox",
      top: 198.12,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_40", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 184.44,
      left: 242.88,
      width: 331.32,
      height: 38.52
   }
   ,
   {
      page: 3,
      name: "undefined_41", 
      isText: true,
      type: "checkbox",
      top: 238.2,
      left: 223.08,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 3,
      name: "undefined_42", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 224.76,
      left: 242.88,
      width: 331.32,
      height: 38.04
   }
   ,
   {
      page: 3,
      name: "undefined_43", 
      isText: true,
      type: "checkbox",
      top: 278.76,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_44", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 264.6,
      left: 242.88,
      width: 331.32,
      height: 39.24
   }
   ,
   {
      page: 3,
      name: "undefined_45", 
      isText: true,
      type: "checkbox",
      top: 319.56,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_46", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 305.64,
      left: 242.88,
      width: 331.32,
      height: 38.52
   }
   ,
   {
      page: 3,
      name: "undefined_47", 
      isText: true,
      type: "checkbox",
      top: 364.2,
      left: 223.08,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 3,
      name: "undefined_48", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 345.96,
      left: 242.88,
      width: 331.32,
      height: 47.64
   }
   ,
   {
      page: 3,
      name: "undefined_49", 
      isText: true,
      type: "checkbox",
      top: 418.44,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_50", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 395.4,
      left: 242.88,
      width: 331.32,
      height: 57.0
   }
   ,
   {
      page: 3,
      name: "undefined_51", 
      isText: true,
      type: "checkbox",
      top: 467.88,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_52", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 454.2,
      left: 242.88,
      width: 331.32,
      height: 38.28
   }
   ,
   {
      page: 3,
      name: "undefined_53", 
      isText: true,
      type: "checkbox",
      top: 518.28,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_54", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 494.28,
      left: 242.88,
      width: 331.32,
      height: 58.92
   }
   ,
   {
      page: 3,
      name: "undefined_55", 
      isText: true,
      type: "checkbox",
      top: 570.12,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_56", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 555,
      left: 242.88,
      width: 331.32,
      height: 41.16
   }
   ,
   {
      page: 3,
      name: "Odors16", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 597.96,
      left: 62.64,
      width: 150.12,
      height: 66.36
   }
   ,
   {
      page: 3,
      name: "undefined_57", 
      isText: true,
      type: "checkbox",
      top: 625.56,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_58", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 597.96,
      left: 242.88,
      width: 331.32,
      height: 66.36
   }
   ,
   {
      page: 3,
      name: "Odors17", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 666.12,
      left: 62.64,
      width: 150.12,
      height: 51.48
   }
   ,
   {
      page: 3,
      name: "undefined_59", 
      isText: true,
      type: "checkbox",
      top: 686.52,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 3,
      name: "undefined_60", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 666.12,
      left: 242.88,
      width: 331.32,
      height: 51.48
   }
   ,
   {
      page: 4,
      name: "undefined_61", 
      isText: true,
      type: "checkbox",
      top: 117.24,
      left: 263.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 4,
      name: "Comments_32", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 104.76,
      left: 283.2,
      width: 291.72,
      height: 35.88
   }
   ,
   {
      page: 4,
      name: "undefined_62", 
      isText: true,
      type: "checkbox",
      top: 164.76,
      left: 263.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 4,
      name: "Comments_33", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 142.44,
      left: 283.2,
      width: 291.72,
      height: 55.56
   }
   ,
   {
      page: 4,
      name: "undefined_63", 
      isText: true,
      type: "checkbox",
      top: 221.88,
      left: 263.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 4,
      name: "Comments_34", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 199.8,
      left: 283.2,
      width: 291.72,
      height: 55.08
   }
   ,
   {
      page: 4,
      name: "undefined_64", 
      isText: true,
      type: "checkbox",
      top: 352.92,
      left: 263.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 4,
      name: "Comments_35", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 316.68,
      left: 283.2,
      width: 291.72,
      height: 83.64
   }
   ,
   {
      page: 4,
      name: "Has the Association made demand or commenced a lawsuit against a builder or contractor alleging defective construction of improvements of the Association Property common area or property owned or controlled by the Association but outside the Sellers Property or unit5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 402.12,
      left: 69.12,
      width: 184.20,
      height: 38.76
   }
   ,
   {
      page: 4,
      name: "undefined_65", 
      isText: true,
      type: "checkbox",
      top: 416.04,
      left: 263.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 4,
      name: "Comments_36", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 402.12,
      left: 283.2,
      width: 291.72,
      height: 38.76
   }
   ,
   {
      page: 4,
      name: "Has the Association made demand or commenced a lawsuit against a builder or contractor alleging defective construction of improvements of the Association Property common area or property owned or controlled by the Association but outside the Sellers Property or unit6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 442.68,
      left: 69.12,
      width: 184.20,
      height: 38.52
   }
   ,
   {
      page: 4,
      name: "undefined_66", 
      isText: true,
      type: "checkbox",
      top: 456.6,
      left: 263.4,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 4,
      name: "Comments_37", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 442.68,
      left: 283.2,
      width: 291.72,
      height: 38.52
   }
   ,
   {
      page: 4,
      name: "Owners Association 1", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 550.92,
      left: 69.12,
      width: 184.20,
      height: 51.72
   }
   ,
   {
      page: 4,
      name: "undefined_67", 
      isText: true,
      type: "checkbox",
      top: 566.52,
      left: 263.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 4,
      name: "Contact Information", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 541.32,
      left: 283.2,
      width: 291.72,
      height: 61.32
   }
   ,
   {
      page: 4,
      name: "Owners Association 2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 614.04,
      left: 69.12,
      width: 184.20,
      height: 38.04
   }
   ,
   {
      page: 4,
      name: "undefined_68", 
      isText: true,
      type: "checkbox",
      top: 622.68,
      left: 263.4,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 4,
      name: "Contact Information_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 604.44,
      left: 283.2,
      width: 291.72,
      height: 47.64
   }
   ,
   {
      page: 4,
      name: "Owners Association 3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 668.88,
      left: 68.88,
      width: 184.68,
      height: 28.80
   }
   ,
   {
      page: 4,
      name: "undefined_69", 
      isText: true,
      type: "checkbox",
      top: 670.2,
      left: 263.4,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 4,
      name: "Contact Information_3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 653.88,
      left: 283.2,
      width: 291.72,
      height: 43.80
   }
   ,
   {
      page: 4,
      name: "Owners Association 4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 708.72,
      left: 68.76,
      width: 184.92,
      height: 24.72
   }
   ,
   {
      page: 4,
      name: "undefined_70", 
      isText: true,
      type: "checkbox",
      top: 711,
      left: 263.4,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 4,
      name: "Contact Information_4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 701.52,
      left: 282.96,
      width: 292.20,
      height: 30.36
   }
   ,
   {
      page: 5,
      name: "undefined_71", 
      isText: true,
      type: "checkbox",
      top: 114.6,
      left: 223.08,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 5,
      name: "Comments_38", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 93.72,
      left: 242.64,
      width: 332.28,
      height: 52.68
   }
   ,
   {
      page: 5,
      name: "undefined_72", 
      isText: true,
      type: "checkbox",
      top: 164.04,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 5,
      name: "Comments_39", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 148.2,
      left: 242.64,
      width: 332.28,
      height: 42.84
   }
   ,
   {
      page: 5,
      name: "undefined_73", 
      isText: false,
      type: "checkbox",
      top: 205.8,
      left: 222.12,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 5,
      name: "Comments_40", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 192.84,
      left: 242.64,
      width: 332.28,
      height: 39.00
   }
   ,
   {
      page: 5,
      name: "undefined_74", 
      isText: false,
      type: "checkbox",
      top: 248.76,
      left: 222.12,
      width: 11.28,
      height: 11.28
   }
   ,
   {
      page: 5,
      name: "Comments_41", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 233.64,
      left: 242.64,
      width: 332.28,
      height: 43.08
   }
   ,
   {
      page: 5,
      name: "undefined_75", 
      isText: true,
      type: "checkbox",
      top: 292.2,
      left: 223.08,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 5,
      name: "Comments_42", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 278.52,
      left: 242.64,
      width: 332.28,
      height: 38.52
   }
   ,
   {
      page: 5,
      name: "undefined_76", 
      isText: true,
      type: "checkbox",
      top: 339.48,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 5,
      name: "Comments_43", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 318.84,
      left: 242.64,
      width: 332.28,
      height: 52.44
   }
   ,
   {
      page: 5,
      name: "Property is subject to Deed Restrictions other recorded document restrictions or Affordable Housing Restrictions7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 374.88,
      left: 62.4,
      width: 150.84,
      height: 30.24
   }
   ,
   {
      page: 5,
      name: "undefined_77", 
      isText: true,
      type: "checkbox",
      top: 384.36,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 5,
      name: "Comments_44", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 374.88,
      left: 242.4,
      width: 332.76,
      height: 30.24
   }
   ,
   {
      page: 5,
      name: "Property is subject to Deed Restrictions other recorded document restrictions or Affordable Housing Restrictions8", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 408.12,
      left: 62.4,
      width: 150.84,
      height: 29.16
   }
   ,
   {
      page: 5,
      name: "undefined_78", 
      isText: true,
      type: "checkbox",
      top: 417.72,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 5,
      name: "Comments_45", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 408.12,
      left: 242.4,
      width: 332.76,
      height: 29.16
   }
   ,
   {
      page: 5,
      name: "GENERAL  Other Information9", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 462.36,
      left: 62.64,
      width: 150.36,
      height: 87.96
   }
   ,
   {
      page: 5,
      name: "undefined_79", 
      isText: true,
      type: "checkbox",
      top: 501,
      left: 223.08,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 5,
      name: "Comments_46", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 462.36,
      left: 242.64,
      width: 332.28,
      height: 87.96
   }
   ,
   {
      page: 5,
      name: "undefined_80", 
      isText: true,
      type: "checkbox",
      top: 681.72,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 5,
      name: "Comments_47", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 650.52,
      left: 242.88,
      width: 331.56,
      height: 73.56
   }
   ,
   {
      page: 6,
      name: "undefined_81", 
      isText: true,
      type: "checkbox",
      top: 69.96,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 6,
      name: "undefined_82", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 49.08,
      left: 242.64,
      width: 332.04,
      height: 52.92
   }
   ,
   {
      page: 6,
      name: "undefined_83", 
      isText: true,
      type: "checkbox",
      top: 128.28,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 6,
      name: "undefined_84", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 103.8,
      left: 242.64,
      width: 332.04,
      height: 60.12
   }
   ,
   {
      page: 6,
      name: "State", 
      isText: false,
      type: "checkbox",
      top: 179.16,
      left: 68.76,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 6,
      name: "BLM", 
      isText: false,
      type: "checkbox",
      top: 179.16,
      left: 114.6,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 6,
      name: "undefined_85", 
      isText: true,
      type: "checkbox",
      top: 185.4,
      left: 223.08,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 6,
      name: "undefined_86", 
      isText: false,
      type: "checkbox",
      top: 191.64,
      left: 68.52,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 6,
      name: "Private", 
      isText: false,
      type: "checkbox",
      top: 191.64,
      left: 116.04,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 6,
      name: "Other_2", 
      isText: false,
      type: "checkbox",
      top: 191.64,
      left: 162.36,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 6,
      name: "Federal", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 201.24,
      left: 65.76,
      width: 144.00,
      height: 11.64
   }
   ,
   {
      page: 6,
      name: "undefined_87", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 165.72,
      left: 242.64,
      width: 332.04,
      height: 50.28
   }
   ,
   {
      page: 6,
      name: "Any land leased from others State BLM Federal Private Other5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 217.8,
      left: 62.64,
      width: 150.36,
      height: 40.68
   }
   ,
   {
      page: 6,
      name: "undefined_88", 
      isText: true,
      type: "checkbox",
      top: 232.68,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 6,
      name: "undefined_89", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 217.8,
      left: 242.64,
      width: 332.04,
      height: 40.68
   }
   ,
   {
      page: 6,
      name: "Any land leased from others State BLM Federal Private Other6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 262.32,
      left: 62.4,
      width: 150.84,
      height: 30.36
   }
   ,
   {
      page: 6,
      name: "undefined_90", 
      isText: true,
      type: "checkbox",
      top: 271.8,
      left: 223.08,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 6,
      name: "undefined_91", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 260.858,
      left: 242.4,
      width: 332.52,
      height: 33.382
   }
   ,
   {
      page: 6,
      name: "undefined_92", 
      isText: true,
      type: "checkbox",
      top: 370.68,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 6,
      name: "Comments_48", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 355.08,
      left: 242.64,
      width: 332.28,
      height: 42.12
   }
   ,
   {
      page: 6,
      name: "undefined_93", 
      isText: true,
      type: "checkbox",
      top: 417.48,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 6,
      name: "Comments_49", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 399,
      left: 242.64,
      width: 332.28,
      height: 47.88
   }
   ,
   {
      page: 6,
      name: "undefined_94", 
      isText: true,
      type: "checkbox",
      top: 466.92,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 6,
      name: "Comments_50", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 448.68,
      left: 242.64,
      width: 332.28,
      height: 47.40
   }
   ,
   {
      page: 6,
      name: "undefined_95", 
      isText: true,
      type: "checkbox",
      top: 511.8,
      left: 223.08,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 6,
      name: "Comments_51", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 497.88,
      left: 242.64,
      width: 332.28,
      height: 38.52
   }
   ,
   {
      page: 6,
      name: "undefined_96", 
      isText: true,
      type: "checkbox",
      top: 552.84,
      left: 223.08,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 6,
      name: "Comments_52", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 538.2,
      left: 242.64,
      width: 332.28,
      height: 40.44
   }
   ,
   {
      page: 6,
      name: "Have herbicides been applied6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 580.44,
      left: 62.64,
      width: 150.36,
      height: 39.24
   }
   ,
   {
      page: 6,
      name: "undefined_97", 
      isText: true,
      type: "checkbox",
      top: 594.6,
      left: 223.08,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 6,
      name: "Comments_53", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 580.44,
      left: 242.64,
      width: 332.28,
      height: 39.24
   }
   ,
   {
      page: 6,
      name: "Have herbicides been applied7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 623.64,
      left: 62.4,
      width: 150.84,
      height: 30.36
   }
   ,
   {
      page: 6,
      name: "undefined_98", 
      isText: true,
      type: "checkbox",
      top: 633,
      left: 223.08,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 6,
      name: "Comments_54", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 621.676,
      left: 242.4,
      width: 332.76,
      height: 32.324
   }
   ,
   {
      page: 7,
      name: "undefined_99", 
      isText: true,
      type: "checkbox",
      top: 125.64,
      left: 231.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 7,
      name: "Comments_55", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 104.28,
      left: 251.76,
      width: 325.56,
      height: 53.40
   }
   ,
   {
      page: 7,
      name: "undefined_100", 
      isText: true,
      type: "checkbox",
      top: 175.8,
      left: 231.96,
      width: 9.36,
      height: 9.6
   }
   ,
   {
      page: 7,
      name: "Comments_56", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 159.48,
      left: 251.76,
      width: 325.56,
      height: 43.56
   }
   ,
   {
      page: 7,
      name: "Conservation easement3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 204.84,
      left: 49.2,
      width: 172.68,
      height: 38.04
   }
   ,
   {
      page: 7,
      name: "undefined_101", 
      isText: true,
      type: "checkbox",
      top: 218.28,
      left: 231.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 7,
      name: "Comments_57", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 204.84,
      left: 251.76,
      width: 325.56,
      height: 38.04
   }
   ,
   {
      page: 7,
      name: "Conservation easement4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 244.68,
      left: 49.2,
      width: 172.68,
      height: 39.48
   }
   ,
   {
      page: 7,
      name: "undefined_102", 
      isText: true,
      type: "checkbox",
      top: 258.84,
      left: 231.96,
      width: 9.36,
      height: 9.60
   }
   ,
   {
      page: 7,
      name: "Comments_58", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 244.68,
      left: 251.76,
      width: 325.56,
      height: 39.48
   }
   ,
   {
      page: 7,
      name: "Seller Name 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 470.269,
      left: 80.16,
      width: 219.84,
      height: 13.691
   }
   ,
   {
      page: 7,
      name: "Seller Name 1_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 468.305,
      left: 375.72,
      width: 199.80,
      height: 15.655
   }
   ,
   {
      page: 8,
      name: "Buyer Name 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 161.76,
      left: 81.24,
      width: 220.20,
      height: 15.00
   }
   ,
   {
      page: 8,
      name: "Buyer Name 1_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 161.106,
      left: 376.8,
      width: 200.16,
      height: 14.999
   }
   ] }
   