export function buyersRemovalofConditionNotice() {
return [   //2024 Release 2024-02-20 14:43:32
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 131.04,
   width: 462.96,
   height: 15.382
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 159.765,
   left: 36.28,
   width: 10.08,
   height: 15.642
}
,
{
   page: 0,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 225.915,
   left: 36.28,
   width: 10.08,
   height: 15.642
}
,
{
   page: 0,
   name: "Check Box 4", 
   isText: false,
   type: "checkbox",
   top: 279.565,
   left: 36.28,
   width: 10.08,
   height: 15.642
}
,
{
   page: 0,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 305.4,
   left: 36.5,
   width: 178.06,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 305.4,
   left: 309.0,
   width: 178.06,
   height: 12.764
}
,
{
   page: 0,
   name: "date Text Field 11", 
   fontSize: 9,
   type: "date",
   top: 305.4,
   left: 490.47,
   width: 88.03,
   height: 12.764
}
,
{
   page: 0,
   name: "signature Text Field 27", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 12,
   top: 344.079,
   left: 309.0,
   width: 266.5,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 381.0,
   left: 36.5,
   width: 178.06,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 456.2,
   left: 36.5,
   width: 178.06,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 535.441,
   left: 149.76,
   width: 64.80,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 535.441,
   left: 276.6,
   width: 30.84,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 35", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 535.441,
   left: 340.56,
   width: 77.76,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 36", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 535.441,
   left: 440.64,
   width: 25.92,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 561.0,
   left: 36.5,
   width: 178.06,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 25", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 561.0,
   left: 309.0,
   width: 178.06,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 665.8,
   left: 36.5,
   width: 178.06,
   height: 12.764
}
,
{
   page: 0,
   name: "date Text Field 12", 
   fontSize: 9,
   type: "date",
   top: 665.8,
   left: 216.97,
   width: 88.03,
   height: 12.764
}
,
{
   page: 0,
   name: "signature Text Field 32", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 12,
   top: 703.56,
   left: 36.5,
   width: 266.5,
   height: 12.7636
}
] }
