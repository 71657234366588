import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Confirm, Dropdown } from "semantic-ui-react";
import {
  copyForm,
  duplicateDocAndAnnots,
} from "../../../app/firestore/firebaseService";
import {
  deleteDocAnnotsInDb,
  deleteDocInDb,
  deleteTasksFromDocInDb,
  fetchDocAnnotsAllFromDb,
  updateDocInDb,
} from "../../../app/firestore/firestoreService";
import { useMediaQuery } from "react-responsive";
import { openModal } from "../../../app/common/modals/modalSlice";
import {
  docIsTypeImage,
  docIsTypePdf,
  docSigningVerificationEnabled,
  downloadNonPdf,
  downloadPdf,
  downloadSigningCertificatePdf,
} from "../../../app/common/util/util";

export default function DocActionButtons({ doc }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { transaction } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { forms } = useSelector((state) => state.doc);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  let { id } = useParams();

  const docIsPdf = docIsTypePdf(doc);
  const docIsImage = docIsTypeImage(doc);

  function handleGoToTransaction() {
    navigate(`/transactions/${doc.transactionId}/overview`);
  }

  function handleView() {
    // if (doc.shared && doc.subStatus === "Newly Shared") {
    //   updateDocInDb(doc.id, { subStatus: "Received" });
    // }
    if (docIsPdf) {
      navigate(`/transactions/${doc.transactionId}/documents/${doc.id}/view`);
    } else if (docIsImage) {
      dispatch(
        openModal({
          modalType: "DocViewImageFileModal",
          modalProps: { doc: doc },
        })
      );
    } else {
      toast.error("Error with viewing document.");
    }
  }

  function handleFillOutForm() {
    updateDocInDb(doc.id, { subStatus: "Filling Out Form" });
    navigate(`/transactions/${doc.transactionId}/documents/${doc.id}/fill`);
  }

  function handlePrepareSignatures() {
    updateDocInDb(doc.id, { subStatus: "Preparing Signatures" });
    navigate(`/transactions/${doc.transactionId}/documents/${doc.id}/prepare`);
  }

  async function handleDownload() {
    // if (doc.shared && doc.subStatus === "Newly Shared") {
    //   updateDocInDb(doc.id, { subStatus: "Received" });
    // }
    if (docIsPdf) {
      downloadPdf(doc, transaction, currentUserProfile);
    } else if (doc.filetypeMIME) {
      downloadNonPdf(doc);
    } else {
      toast.error("Error with downloading document.");
    }
  }

  async function handleDownloadSigningCertificate() {
    downloadSigningCertificatePdf(doc, currentUserProfile);
  }

  async function handleCopy() {
    if (doc.isStateForm) {
      const originalForm = forms.filter((form) => form.id === doc.formId)[0];
      if (originalForm) {
        copyForm(transaction, doc, originalForm);
      }
    }
  }

  async function handleDuplicate() {
    let annots = [];
    if (!doc.pdfBurnVersion) {
      annots = await fetchDocAnnotsAllFromDb(doc.id);
    }
    duplicateDocAndAnnots(transaction, doc, annots);
  }

  async function handleSigningStatus() {
    try {
      const newStatus = doc.signingComplete === true ? false : true;
      await updateDocInDb(
        doc.id,
        {
          signingComplete: newStatus,
        },
        true
      );
      toast.success("Signing status updated");
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  }

  async function handleArchive() {
    try {
      await updateDocInDb(doc.id, {
        archived: true,
      });
      // await archiveTasksFromDocInDb(doc.id);
      toast.success("Document successfully archived");
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  }

  async function handleUnarchive() {
    try {
      await updateDocInDb(doc.id, {
        archived: false,
      });
      toast.success("Document successfully unarchived");
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  }

  function handleExtractTasksFromForm() {
    dispatch(
      openModal({
        modalType: "DocExtractDeadlines",
        modalProps: { doc: doc },
      })
    );
  }

  function handleRename() {
    dispatch(
      openModal({
        modalType: "DocRenameModal",
        modalProps: { doc: doc },
      })
    );
  }

  async function handleDelete() {
    try {
      if (!doc.pdfBurnVersion) {
        await deleteDocAnnotsInDb(doc.id);
      }
      await deleteDocInDb(doc.id);
      if (!doc.pdfBurnVersion || (!doc.isStateForm && !doc.formId)) {
        console.log("THIS WOULD DELETE THE PDF!!!!!!");
        // deleteDocFromStorage(doc.docRef);
      }
      await deleteTasksFromDocInDb(doc.id);
      toast.success("Document successfully deleted");
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className={isMobile ? null : "text align right"}>
      <Dropdown
        button
        icon="chevron down"
        text={isMobile ? "Actions " : null}
        className={isMobile ? "button icon" : "button mini icon"}
        direction={isMobile ? "right" : "left"}
      >
        <Dropdown.Menu>
          {!id && (
            <Dropdown.Item onClick={() => handleGoToTransaction()}>
              Go To Transaction
            </Dropdown.Item>
          )}
          {(docIsPdf || docIsImage) && (
            <Dropdown.Item onClick={() => handleView()}>View</Dropdown.Item>
          )}
          {docIsPdf &&
            doc.status === "In Progress" &&
            doc.isStateForm &&
            !doc.shared &&
            !doc.signingComplete && (
              <Dropdown.Item onClick={() => handleFillOutForm()}>
                Fill Out
              </Dropdown.Item>
            )}
          {docIsPdf && !doc.signingSentOutAt && (
            <Dropdown.Item onClick={() => handlePrepareSignatures()}>
              Prepare Signatures
            </Dropdown.Item>
          )}
          {doc.signingComplete === true ? (
            <Dropdown.Item onClick={() => handleSigningStatus()}>
              Undo Signing Complete
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => handleSigningStatus()}>
              Mark Signing Complete
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => handleDownload()}>
            Download
          </Dropdown.Item>
          {docSigningVerificationEnabled(doc, currentUserProfile) && (
            <Dropdown.Item onClick={() => handleDownloadSigningCertificate()}>
              Signing Certificate
            </Dropdown.Item>
          )}
          {docIsPdf &&
            (!doc.isStateForm || (doc.isStateForm && doc.hasTasks)) && (
              <Dropdown.Item onClick={() => handleExtractTasksFromForm()}>
                Extract Deadlines
              </Dropdown.Item>
            )}
          {docIsPdf && doc.isStateForm && (
            <Dropdown.Item onClick={() => handleCopy()}>
              Copy Form
            </Dropdown.Item>
          )}
          {docIsPdf && doc.status !== "Awaiting Signature" && (
            <Dropdown.Item onClick={() => handleDuplicate()}>
              Duplicate
            </Dropdown.Item>
          )}

          {doc.archived === true ? (
            <Dropdown.Item onClick={() => handleUnarchive()}>
              Unarchive
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => handleArchive()}>
              Archive
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => handleRename()}>Rename</Dropdown.Item>
          <Dropdown.Item onClick={() => setConfirmOpen(true)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleDelete()}
      ></Confirm>
    </div>
  );
}
