import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Confirm, Dropdown } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { openModal } from "../../app/common/modals/modalSlice";
import {
  copyDeadlineTemplateToDb,
  deleteDeadlineTemplateInDb,
} from "../../app/firestore/firestoreService";

export default function DeadlineTemplatesActionButtons({
  deadlineTemplate,
  actionButtonMode,
}) {
  const dispatch = useDispatch();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleEdit() {
    dispatch(
      openModal({
        modalType: "DeadlineTemplatesEdit",
        modalProps: { deadlineTemplate: deadlineTemplate },
      })
    );
  }

  async function handleCopy() {
    copyDeadlineTemplateToDb(deadlineTemplate);
  }

  function handleChoose() {
    dispatch(
      openModal({
        modalType: "DeadlineTemplatesApply",
        modalProps: {
          deadlineTemplate: deadlineTemplate,
        },
      })
    );
  }

  function handleRename() {
    dispatch(
      openModal({
        modalType: "DeadlineTemplatesRename",
        modalProps: { deadlineTemplate: deadlineTemplate },
      })
    );
  }

  async function handleDelete() {
    try {
      await deleteDeadlineTemplateInDb(deadlineTemplate.id);
      toast.success("Template successfully deleted");
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  }

  if (actionButtonMode === "apply") {
    return (
      <div className={isMobile ? null : "text align right"}>
        <Button primary onClick={() => handleChoose()}>
          Choose
        </Button>
      </div>
    );
  } else {
    return (
      <div className={isMobile ? null : "text align right"}>
        <Dropdown
          button
          icon="chevron down"
          text={isMobile ? "Actions " : null}
          className={isMobile ? "button icon" : "button mini icon"}
          direction={isMobile ? "right" : "left"}
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleEdit()}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => handleCopy()}>Copy</Dropdown.Item>
            <Dropdown.Item onClick={() => handleRename()}>Rename</Dropdown.Item>
            <Dropdown.Item onClick={() => setConfirmOpen(true)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Confirm
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={() => handleDelete()}
        ></Confirm>
      </div>
    );
  }
}
