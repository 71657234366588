import React from "react";
import { Grid, Card, Icon, Divider } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  convertAddressFull,
  convertAddressToLink,
  convertContactsToDisplay,
  convertFullName,
  convertPhoneToLink,
  hidePartyContactDetails,
} from "../../../app/common/util/util";

export default function PartyContacts() {
  const { allParties, transaction } = useSelector((state) => state.transaction);
  const { party } = useSelector((state) => state.party);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const userParty = party;

  if (!allParties) {
    return <LoadingComponent content="Loading contacts..." />;
  }

  const partiesToDisplay = convertContactsToDisplay(
    allParties,
    transaction.agentRepresents,
    party
  );

  const agentEmail = transaction.agentEmail
    ? transaction.agentEmail
    : transaction.agentProfile?.email
    ? transaction.agentProfile?.email
    : "";

  return (
    <Grid stackable className={isMobile ? "small padding" : "big padding"}>
      <Grid.Column computer={16} tablet={16}>
        <div style={{ padding: "0px 30px" }}>
          <h2>Contacts</h2>
          <Divider />

          <Card.Group stackable className="medium top margin">
            {transaction?.agentName && (
              <Card
                color="blue"
                className="tiny top margin background-blue"
                key={transaction?.agentName}
              >
                <Card.Content extra>
                  <Card.Header>{transaction?.agentName}</Card.Header>
                  <p>My Agent</p>
                  {agentEmail && (
                    <div className="small top margin">
                      <Card.Meta>
                        <div className="tiny bottom margin">
                          <Icon name="mail" />
                          <span className="tiny left margin">
                            <a href={`mailto:${agentEmail}`}>{agentEmail}</a>
                          </span>
                          <br />
                        </div>
                      </Card.Meta>
                    </div>
                  )}
                  {transaction?.agentPhone && (
                    <Card.Meta>
                      <div className="tiny bottom margin">
                        <Icon name="phone" />
                        <span className="tiny left margin">
                          <a
                            href={`tel:${convertPhoneToLink(
                              transaction.agentPhone
                            )}`}
                          >
                            {transaction.agentPhone}
                          </a>
                        </span>
                        <br />
                      </div>
                    </Card.Meta>
                  )}
                </Card.Content>
              </Card>
            )}
            {partiesToDisplay &&
              partiesToDisplay.map((party) => (
                <Card className="tiny top margin" key={party.role}>
                  <Card.Content extra>
                    <Card.Header>{convertFullName(party)}</Card.Header>
                    {party.isTrust && <p>{party.entityName}</p>}
                    <p>{party.role}</p>
                    <Card.Meta>
                      {party.companyName && <p>Company: {party.companyName}</p>}
                    </Card.Meta>
                    {!hidePartyContactDetails(userParty, party) && (
                      <>
                        <Card.Meta>
                          {party.address && party.address.street && (
                            <p>
                              Address: &nbsp;
                              <a
                                target="NEW"
                                href={`http://maps.google.com/?q=${convertAddressToLink(
                                  convertAddressFull(party.address)
                                )}`}
                                style={{ color: "#224C98" }}
                              >
                                {convertAddressFull(party.address)}
                              </a>
                            </p>
                          )}
                        </Card.Meta>
                        {party.email && (
                          <div className="small top margin">
                            <Card.Meta>
                              <div className="tiny bottom margin">
                                <Icon name="mail" />
                                <span className="tiny left margin">
                                  <a href={`mailto:${party.email}`}>
                                    {party.email}
                                  </a>
                                </span>
                                <br />
                              </div>
                            </Card.Meta>
                          </div>
                        )}
                        {party.phone && (
                          <Card.Meta>
                            <div className="tiny bottom margin">
                              <Icon name="phone" />
                              <span className="tiny left margin">
                                <a
                                  href={`tel:${convertPhoneToLink(
                                    party.phone
                                  )}`}
                                >
                                  {party.phone}
                                </a>
                              </span>
                              <br />
                            </div>
                          </Card.Meta>
                        )}
                        {party.fax && (
                          <Card.Meta>
                            <div className="tiny bottom margin">
                              <Icon name="fax" />
                              <span className="tiny left margin">
                                {party.fax}
                              </span>
                              <br />
                            </div>
                          </Card.Meta>
                        )}
                      </>
                    )}
                  </Card.Content>
                  {party.hasAssistant && (
                    <>
                      <Card.Content extra>
                        <h4 className="tiny bottom margin">Assistant</h4>
                        <p>
                          {party.assistant?.firstName}{" "}
                          {party.assistant?.lastName}
                        </p>
                        {party.assistant?.email && (
                          <div className="tiny bottom margin">
                            <Icon name="mail" />
                            <span className="tiny left margin">
                              <a href={`mailto:${party.assistant?.email}`}>
                                {party.assistant?.email}
                              </a>
                            </span>
                            <br />
                          </div>
                        )}
                        {party.assistant?.phone && (
                          <div className="tiny bottom margin">
                            <Icon name="phone" />
                            <span className="tiny left margin">
                              <a
                                href={`tel:${convertPhoneToLink(
                                  party.assistant?.phone
                                )}`}
                              >
                                {party.assistant?.phone}
                              </a>
                            </span>
                            <br />
                          </div>
                        )}
                      </Card.Content>
                    </>
                  )}
                  {party.hasTransactionCoordinator && (
                    <>
                      <Card.Content extra>
                        <h4 className="tiny bottom margin">
                          Transaction Coordinator
                        </h4>
                        <p>
                          {party.transactionCoordinator?.firstName}{" "}
                          {party.transactionCoordinator?.lastName}
                        </p>
                        {party.transactionCoordinator?.email && (
                          <div className="tiny bottom margin">
                            <Icon name="mail" />
                            <span className="tiny left margin">
                              <a
                                href={`mailto:${party.transactionCoordinator?.email}`}
                              >
                                {party.transactionCoordinator?.email}
                              </a>
                            </span>
                            <br />
                          </div>
                        )}
                        {party.transactionCoordinator?.phone && (
                          <div className="tiny bottom margin">
                            <Icon name="phone" />
                            <span className="tiny left margin">
                              <a
                                href={`tel:${convertPhoneToLink(
                                  party.transactionCoordinator?.phone
                                )}`}
                              >
                                {party.transactionCoordinator?.phone}
                              </a>
                            </span>
                            <br />
                          </div>
                        )}
                      </Card.Content>
                    </>
                  )}
                  {party.hasCoAgent && (
                    <>
                      <Card.Content extra>
                        <h4 className="tiny bottom margin">CoAgent</h4>
                        <p>
                          {party.coAgent?.firstName} {party.coAgent?.lastName}
                        </p>
                        {party.coAgent?.email && (
                          <div className="tiny bottom margin">
                            <Icon name="mail" />
                            <span className="tiny left margin">
                              <a href={`mailto:${party.coAgent?.email}`}>
                                {party.coAgent?.email}
                              </a>
                            </span>
                            <br />
                          </div>
                        )}
                        {party.coAgent?.phone && (
                          <div className="tiny bottom margin">
                            <Icon name="phone" />
                            <span className="tiny left margin">
                              <a
                                href={`tel:${convertPhoneToLink(
                                  party.coAgent?.phone
                                )}`}
                              >
                                {party.coAgent?.phone}
                              </a>
                            </span>
                            <br />
                          </div>
                        )}
                      </Card.Content>
                    </>
                  )}
                </Card>
              ))}
          </Card.Group>
        </div>
      </Grid.Column>
    </Grid>
  );
}
