import { formatDistance } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Table } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import {
  convertFileTypeToIcon,
  docIsTypeImage,
  docIsTypePdf,
  downloadNonPdf,
  downloadPdf,
} from "../../../app/common/util/util";
import { addHistoryToDb } from "../../../app/firestore/firestoreService";
import { openModal } from "../../../app/common/modals/modalSlice";

export default function PartyDocViewListItem({ doc }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { party } = useSelector((state) => state.party);
  const { transaction } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleView() {
    addHistoryToDb(doc.transactionId, party, "viewed", doc.name);
    if (docIsTypeImage(doc)) {
      dispatch(
        openModal({
          modalType: "DocViewImageFileModal",
          modalProps: { doc: doc },
        })
      );
    } else {
      navigate(`/party/${party.id}/documents/${doc.id}/view`);
    }
  }

  function handleFill() {
    navigate(`/party/${party.id}/documents/${doc.id}/fill`);
  }

  async function handleDownload() {
    addHistoryToDb(doc.transactionId, party, "downloaded", doc.name);
    if (docIsTypePdf(doc)) {
      downloadPdf(doc, transaction, currentUserProfile);
    } else if (doc.filetypeMIME) {
      downloadNonPdf(doc);
    } else {
      toast.error("Error with downloading document.");
    }
  }

  return (
    <Table.Row key={doc.id}>
      <Table.Cell>
        <Icon
          name={`file ${convertFileTypeToIcon(doc)} outline`}
          size="large"
        />
        &nbsp;
        {doc.name}
      </Table.Cell>
      <Table.Cell>
        <p>
          {doc.updatedAt && doc.updatedAt instanceof Date
            ? formatDistance(doc.updatedAt, new Date(), { addSuffix: true })
            : ""}
        </p>
      </Table.Cell>
      <Table.Cell>
        <Button
          floated={isMobile ? null : "right"}
          className={isMobile ? "fluid medium bottom margin" : null}
          size="small"
          onClick={() => handleDownload()}
        >
          Download
        </Button>
        {doc.isFillableByClient &&
          doc.subStatus !== "Awaiting Signatures" &&
          doc.status !== "Complete" &&
          party.role.startsWith("Seller") && (
            <Button
              primary
              floated={isMobile ? null : "right"}
              className={isMobile ? "fluid medium bottom margin" : null}
              size="small"
              onClick={() => handleFill()}
            >
              Fill Out
            </Button>
          )}
        {(docIsTypePdf(doc) || docIsTypeImage(doc)) && (
          <Button
            floated={isMobile ? null : "right"}
            className={isMobile ? "fluid medium bottom margin" : null}
            size="small"
            onClick={() => handleView()}
          >
            View
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  );
}
