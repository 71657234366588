export function gTARHistoricZoning() {
return [   //2024 Release 2024-04-18 19:56:46
{
   page: 0,
   name: "ADDRESS", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 85.891,
   left: 98.04,
   width: 452.771,
   height: 15.709
}
,
{
   page: 0,
   name: "Zoning and as such is subject to certain historicconservation guidelines  Buyer acknowledges that these", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 125.466,
   left: 252.12,
   width: 153.60,
   height: 15.054
}
,
{
   page: 0,
   name: "is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 230.04,
   left: 48.96,
   width: 91.80,
   height: 11.64
}
,
{
   page: 0,
   name: "attached", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 230.04,
   left: 177.48,
   width: 24.48,
   height: 11.64
}
] }
