export function exclusiveRighttoSell() {
return [
{
   page: 0,
   type: "checkbox",
   name: "Seller Check", 
   top: 211.32,
   left: 166.44,
   width: 11.04,
   height: 11.04
}
,
{
   page: 0,
   type: "checkbox",
   name: "TB Check", 
   top: 211.32,
   left: 289.32,
   width: 11.04,
   height: 11.04
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date", 
   top: 228.24,
   left: 416.52,
   width: 156.00,
   height: 16.68
}
,
{
   page: 0,
   type: "checkbox",
   name: "2 1 Check", 
   top: 316.92,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "2 2 Check", 
   top: 374.52,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller", 
   top: 431.28,
   left: 137.16,
   width: 435.36,
   height: 10.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firm", 
   top: 442.80,
   left: 181.92,
   width: 390.60,
   height: 16.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Broker", 
   top: 460.32,
   left: 143.28,
   width: 429.24,
   height: 16.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Property County", 
   top: 477.72,
   left: 451.44,
   width: 75.72,
   height: 16.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Property Description", 
   top: 497.228,
   left: 48.2628,
   width: 526.3682,
   height: 58.907
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Common Address", 
   top: 559.32,
   left: 113.64,
   width: 458.76,
   height: 16.68
}
,
{
   page: 0,
   type: "checkbox",
   name: "3 5 Check", 
   top: 624.00,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "3 6 2 Check", 
   top: 704.639,
   left: 68.8195,
   width: 9.9600,
   height: 9.960
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "3 7 Listing Period", 
   top: 56.28,
   left: 412.56,
   width: 150.00,
   height: 11.40
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 9,
   type: "date",
   name: "3 7 Date", 
   top: 69,
   left: 483.6,
   width: 88.32,
   height: 10
}
,
{
   page: 1,
   type: "checkbox",
   name: "3 9 2 Will", 
   top: 196.56,
   left: 333.36,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   type: "checkbox",
   name: "3 9 2 Will Not", 
   top: 196.56,
   left: 371.04,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   type: "checkbox",
   name: "4 3 1 2 Check", 
   top: 403.635,
   left: 104.819,
   width: 9.961,
   height: 9.960
}
,
{
   page: 2,
   type: "checkbox",
   name: "5 8 Will", 
   top: 173.52,
   left: 194.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 2,
   type: "checkbox",
   name: "5 8 Will Not", 
   top: 173.52,
   left: 233.04,
   width: 9.96,
   height: 9.96
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 1 1 Percetage", 
   top: 345.84,
   left: 235.08,
   width: 26.88,
   height: 10.68
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 1 1 Dollars", 
   top: 345.84,
   left: 407.4,
   width: 164.88,
   height: 10.68
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 1 1 Percentage Brokerage", 
   top: 357.36,
   left: 533.04,
   width: 34.32,
   height: 10.68
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 1 1 Dollars Brokerage", 
   top: 368.88,
   left: 170.88,
   width: 91.08,
   height: 10.56
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 1 2 Percentage", 
   top: 380.28,
   left: 547.56,
   width: 20.04,
   height: 10.68
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 1 2 Dollars", 
   top: 391.80,
   left: 207.6,
   width: 265.68,
   height: 10.68
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 1 2 Method", 
   top: 403.32,
   left: 96.48,
   width: 381.96,
   height: 10.68
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 1 2 Percentage Brokerage", 
   top: 414.84,
   left: 386.28,
   width: 39.72,
   height: 10.68
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 1 2 Gross Rent", 
   top: 414.840,
   left: 515.16,
   width: 74.722,
   height: 11.491
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 1 3 Other Compensation", 
   top: 437.76,
   left: 237.6,
   width: 271.92,
   height: 10.68
}
,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "7 2 3 Calendar Days", 
   top: 506.76,
   left: 399.12,
   width: 24.96,
   height: 10.68
}
,
{
   page: 2,
   type: "checkbox",
   name: "7 2 3 Will", 
   top: 531.00,
   left: 346.92,
   width: 9.96,
   height: 9.96
}
,
{
   page: 2,
   type: "checkbox",
   name: "7 2 3 Will Not", 
   top: 531.00,
   left: 385.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 1 Will", 
   top: 93.00,
   left: 195.6,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 1 Will Not", 
   top: 93.00,
   left: 232.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 1 Will 02", 
   top: 93.00,
   left: 437.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 1 Will Not 02", 
   top: 93.00,
   left: 473.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 9,
   type: "text",
   name: "9 1 2 Exceptions", 
   top: 138.00,
   left: 456.48,
   width: 115.80,
   height: 10.56
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 1 4 Will", 
   top: 162.00,
   left: 233.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 1 4 Will Not", 
   top: 162.00,
   left: 271.2,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 1 5 Will", 
   top: 173.52,
   left: 229.44,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 1 5 Will Not", 
   top: 173.52,
   left: 266.76,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 2 1 Elec Lock", 
   top: 208.08,
   left: 141.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 2 1 Manual", 
   top: 208.08,
   left: 339.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 2 1 Other", 
   top: 220.147,
   left: 140.82,
   width: 9.959,
   height: 9.960
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "9 2 1 Access", 
   top: 218.40,
   left: 156.96,
   width: 415.56,
   height: 10.56
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "9 2 1 Other Instructions", 
   top: 229.92,
   left: 220.08,
   width: 352.44,
   height: 10.56
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 2 2 Actively Licensed", 
   top: 265.56,
   left: 141.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 2 2 Licensed Appraiser", 
   top: 265.56,
   left: 339.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 2 2 Unlicensed Broker Assistant", 
   top: 277.08,
   left: 141.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "Unlicensed Inspectors", 
   top: 277.08,
   left: 339.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "9 2 2 Other", 
   top: 288.48,
   left: 141.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "9 2 2 Other Field", 
   top: 287.40,
   left: 187.44,
   width: 119.64,
   height: 10.56
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 9,
   type: "textarea",
   name: "9 3 1 Marketing Tasks", 
   top: 321.211,
   left: 49.0739,
   width: 537.3191,
   height: 71.074
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "9 3 2 Exceptions", 
   top: 401.920,
   left: 49.4795,
   width: 537.3185,
   height: 70.262
}
,
{
   page: 3,
   type: "checkbox",
   name: "10 3 Is", 
   top: 622.44,
   left: 372.36,
   width: 9.554,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "10 3 Is Not", 
   top: 622.846,
   left: 402.269,
   width: 10.771,
   height: 9.554
}
,
{
   page: 3,
   type: "checkbox",
   name: "10 3 Has", 
   top: 633.96,
   left: 417,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "10 3 Has Not", 
   top: 633.96,
   left: 454.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "11 1 Price US", 
   top: 184.32,
   left: 159.36,
   width: 89.16,
   height: 10.68
}
,
{
   page: 4,
   type: "checkbox",
   name: "11 2 Cash 01", 
   top: 197.04,
   left: 140.4,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "11 2 Conventional 01", 
   top: 197.04,
   left: 186.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "11 2 FHA", 
   top: 197.04,
   left: 267.36,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "11 2 VA", 
   top: 197.04,
   left: 312.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "11 2 Other", 
   top: 197.04,
   left: 351,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "11 2 Other Financing", 
   top: 195.84,
   left: 398.16,
   width: 174.36,
   height: 10.68
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "11 3 Loan Discount Points", 
   top: 207.36,
   left: 203.64,
   width: 368.88,
   height: 10.68
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "11 4 Buyers Closing Costs", 
   top: 218.88,
   left: 470.76,
   width: 56.40,
   height: 10.68
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "11 4 Not allowed to pay", 
   top: 230.40,
   left: 244.8,
   width: 327.36,
   height: 10.56
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "11 5 Earnest Money", 
   top: 241.80,
   left: 380.28,
   width: 57.24,
   height: 10.68
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 9,
   type: "text",
   name: "11 5 Form", 
   top: 241.80,
   left: 497.64,
   width: 76.88,
   height: 10.68
}
,
{
   page: 4,
   type: "checkbox",
   name: "11 6 Cashier's", 
   top: 254.52,
   left: 408.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "11 6 Electronic", 
   top: 266.04,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "11 6 Closing Co Check", 
   top: 266.04,
   left: 529.2,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "11 7 Is", 
   top: 335.04,
   left: 243.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "13 1 Number", 
   top: 518.88,
   left: 179.28,
   width: 35.04,
   height: 10.20
}
,
{
   page: 4,
   type: "checkbox",
   name: "13 1 None", 
   top: 531.48,
   left: 272.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "13 1 Solar", 
   top: 531.48,
   left: 315.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "13 1 Water Softeners", 
   top: 531.48,
   left: 387.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "13 1 Security System", 
   top: 531.48,
   left: 474,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "13 1 Satellite", 
   top: 531.48,
   left: 565.2,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "13 1 3 Other Inclusions", 
   top: 646.650,
   left: 50.0384,
   width: 528.3666,
   height: 33.932
}
,
{
   page: 4,
   name: "13_encumberedInclusions",
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 714.6899,
   left: 48.6749,
   width: 527.5271,
   height: 30.3104
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "13 1 6 1 Leased", 
   top: 139.723,
   left: 50.4233,
   width: 532.9857,
   height: 55.102
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "13 2 Exclusions", 
   top: 242.879,
   left: 50.8082,
   width: 530.6768,
   height: 86.280
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "13 3 Trade fixtures", 
   top: 333.36,
   left: 345.48,
   width: 226.80,
   height: 10.68
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "13 3 Exceptions", 
   top: 356.28,
   left: 299.28,
   width: 273.00,
   height: 10.68
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "13 4 Parking", 
   top: 390.84,
   left: 86.4,
   width: 427.56,
   height: 10.68
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "13 4 Storage", 
   top: 402.36,
   left: 268.56,
   width: 249.72,
   height: 10.68
}
,
{
   page: 5,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "13 5 Check", 
   top: 425.171,
   left: 65.28,
   width: 16,
   height: 16
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "13 5 1 Deeded Water Rights", 
   top: 441.877,
   left: 51.1931,
   width: 537.9899,
   height: 80.891
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "13 5 1 Deed", 
   top: 528.84,
   left: 396.84,
   width: 85.68,
   height: 10.68
}
,
{
   page: 5,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "13 5 2 Check", 
   top: 537.565,
   left: 67.5895,
   width: 16,
   height: 16
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "13 5 2 Other Rights", 
   top: 564.279,
   left: 50.4233,
   width: 534.9107,
   height: 74.732
}
,
{
   page: 5,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "13 5 3 Check", 
   top: 638.182,
   left: 65.6649,
   width: 16,
   height: 16
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "13 5 3 Stock Certs", 
   top: 643.44,
   left: 286.32,
   width: 140.04,
   height: 10.68
}
,
{
   page: 5,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "13 5 4 Check", 
   top: 656.118,
   left: 65.6649,
   width: 16,
   height: 16
}
,
{
   page: 5,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "13 5 4 Water Stock Certs", 
   top: 674.3630,
   left: 50.8082,
   width: 535.2948,
   height: 63.9553
}
,
{
   page: 6,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "13 6 Growing Crops", 
   top: 69.669,
   left: 50.0384,
   width: 531.4466,
   height: 68.189
}
,
{
   page: 6,
   type: "checkbox",
   name: "14 3 Special Warranty", 
   top: 209.04,
   left: 479.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 6,
   type: "checkbox",
   name: "14 3 General Warranty", 
   top: 220.56,
   left: 84.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 6,
   type: "checkbox",
   name: "14 3 bargain and sale deed", 
   top: 220.56,
   left: 202.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 6,
   type: "checkbox",
   name: "14 3 quit claim deed", 
   top: 220.56,
   left: 315.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 6,
   type: "checkbox",
   name: "14 3 personal representatives deed", 
   top: 220.56,
   left: 404.065,
   width: 9.575,
   height: 9.96
}
,
{
   page: 6,
   type: "checkbox",
   name: "14 3 Other 01", 
   top: 220.686,
   left: 551.024,
   width: 9.575,
   height: 9.960
}
,
{
   page: 6,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "14 3 Other Warranty", 
   top: 230.88,
   left: 50.4,
   width: 149.88,
   height: 10.32
}
,
{
   page: 6,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "14 4 encumbrances", 
   top: 299.88,
   left: 50.4,
   width: 129.96,
   height: 10.32
}
,
{
   page: 6,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "14 5 tenancies", 
   top: 357.967,
   left: 50.4233,
   width: 530.6767,
   height: 56.257
}
,
{
   page: 6,
   type: "checkbox",
   name: "15 Abstract", 
   top: 439.56,
   left: 317.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 6,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "16 Association Dues", 
   top: 473.64,
   left: 159,
   width: 71.52,
   height: 10.32
}
,
{
   page: 6,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "16 Association Term", 
   top: 473.64,
   left: 248.16,
   width: 54.36,
   height: 10.32
}
,
{
   page: 6,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "16 Exceptions", 
   top: 485.16,
   left: 303.36,
   width: 160.80,
   height: 10.32
}
,
{
   page: 6,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "17 Possession", 
   top: 520.20,
   left: 397.2,
   width: 174.96,
   height: 10.32
}
,
{
   page: 6,
   type: "checkbox",
   name: "18 2 1 Agrees", 
   top: 648.00,
   left: 321.84,
   width: 9.96,
   height: 9.96
}
,
{
   page: 6,
   type: "checkbox",
   name: "18 2 1 Does Not", 
   top: 648.00,
   left: 369.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "20 option 1", 
   top: 369.48,
   left: 65.04,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "20 Option 2", 
   top: 369.48,
   left: 205.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "20 Option 3", 
   top: 381.00,
   left: 389.4,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "20 Option 3 Comments", 
   top: 379.80,
   left: 430.8,
   width: 141.6,
   height: 10.68
}
,
{
   page: 8,
   fontName: "Helvetica",
   fontSize: 9,
   type: "textarea",
   name: "28 Additional Provisions", 
   top: 208.622,
   left: 46.9591,
   width: 535,
   height: 137.609
}
,
{
   page: 8,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "29 Attachments", 
   top: 358.737,
   left: 46.1893,
   width: 532.7157,
   height: 102.771
}
,
{
   page: 8,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "31 2 Other Method", 
   top: 565.80,
   left: 235.44,
   width: 190.08,
   height: 10.32
}
// ,
// {
//    page: 9,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Sellers Signature", 
//    top: 191.28,
//    left: 50.4,
//    width: 175.32,
//    height: 10.32
// }
// ,
// {
//    page: 9,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "text",
//    name: "Date_2", 
//    top: 191.28,
//    left: 230.28,
//    width: 55.08,
//    height: 10.32
// }
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "signature",
   name: "Broker Signature", 
   top: 191.28,
   left: 302.4,
   width: 175.32,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 9,
   type: "date",
   name: "Date_3", 
   top: 191.28,
   left: 482.28,
   width: 70,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Street Address", 
   top: 214.32,
   left: 50.4,
   width: 234.96,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firm Street Address", 
   top: 214.32,
   left: 302.16,
   width: 240.00,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "City State Zip", 
   top: 237.36,
   left: 50.4,
   width: 234.96,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firm City State Zip", 
   top: 237.36,
   left: 302.16,
   width: 240.00,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Phone No", 
   top: 260.4,
   left: 50.4,
   width: 234.96,
   height: 10.2
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Broker Phone No", 
   top: 260.4,
   left: 302.16,
   width: 240.00,
   height: 10.2
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Fax No", 
   top: 283.32,
   left: 50.4,
   width: 234.96,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Broker Fax No", 
   top: 283.32,
   left: 302.16,
   width: 240.00,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Email Address", 
   top: 306.36,
   left: 50.4,
   width: 234.96,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Broker Email Address", 
   top: 306.36,
   left: 302.16,
   width: 240.00,
   height: 10.32
}
// ,
// {
//    page: 9,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Sellers Signature_2", 
//    top: 352.32,
//    left: 50.4,
//    width: 182.88,
//    height: 10.32
// }
// ,
// {
//    page: 9,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "text",
//    name: "Date_4", 
//    top: 352.32,
//    left: 237.84,
//    width: 47.52,
//    height: 10.32
// }
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Street Address_2", 
   top: 375.36,
   left: 50.4,
   width: 234.96,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "City State Zip_2", 
   top: 398.28,
   left: 50.4,
   width: 234.96,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Phone No_2", 
   top: 421.32,
   left: 50.4,
   width: 234.96,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Fax No_2", 
   top: 444.36,
   left: 50.4,
   width: 234.96,
   height: 10.32
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Email Address_2", 
   top: 467.28,
   left: 50.4,
   width: 234.96,
   height: 10.32
}
] }
