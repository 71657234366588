import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	visible: false
}

const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setVisible(state, action) {
			state.visible = action.payload
    }
  }
});

export const { setVisible } = navSlice.actions

export default navSlice.reducer