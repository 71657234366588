export function blankAddendum() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 131.04,
   width: 462.96,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 116.76,
   left: 277.2,
   width: 218.16,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 137.76,
   left: 36.5,
   width: 480.46,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 158.76,
   left: 36.5,
   width: 496.3,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 179.76,
   left: 279.2,
   width: 196.0,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 200.76,
   left: 214.56,
   width: 360.94,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 221.76,
   left: 36.5,
   width: 539.5,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 8", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 12,
   top: 255.06,
   left: 36.5,
   width: 539.5,
   height: 351.00
}
] }
