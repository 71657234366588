export function appraisalValueObjectionNotice() {
return [
   {
      page: 0,
      name: "Logo",
      isText: false,
      type: "logo",
      top: 28.673,
      left: 53,
      width: 240,
      height: 36
   }
   ,
   {
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date",   //form date
   top: 160.68,
   left: 398.64,
   width: 174.00,
   height: 12.00
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",   //
   name: "Buyer relating to the sale and purchase of the", 
   top: 183.72,
   left: 324.24,
   width: 108.84,
   height: 12.00
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "between",  //seller line 1
   top: 183.72,
   left: 473.88,
   width: 1, // 98.76, avoid errors if used
   height: 12.00
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Property known as", 
   top: 195.56,   // address
   left: 50.4,
   width: 329.12,
   height: 10.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller and",    //buyer
   top: 206.56,
   left: 50.36,
   width: 329.28,
   height: 10.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Terms used herein shall have the same meaning as in the Contract", 
   top: 219.08,
   left: 130.68,
   width: 396.96,
   height: 10.68
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box1", 
   top: 300.654,
   left: 185.018,
   width: 9.600,
   height: 10.473
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box2", 
   top: 301.09,
   left: 212.509,
   width: 8.290,
   height: 9.60
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box3", 
   top: 312.872,
   left: 225.163,
   width: 9.164,
   height: 9.600
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box4", 
   top: 312.436,
   left: 251.781,
   width: 9.600,
   height: 11.345
}
] }