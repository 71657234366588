export function unimprovedPropertyContract() {
return [   //2024 Release 2024-02-18 22:02:08
{
   page: 0,
   name: "1 PARTIES The parties to this contract are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 91.199,
   left: 278.817,
   width: 241.474,
   height: 11.548
}
,
{
   page: 0,
   name: "and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 102.132,
   left: 79.8,
   width: 354.84,
   height: 12.082
}
,
{
   page: 0,
   name: "2 PROPERTY Lot", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 129.239,
   left: 145.56,
   width: 145.20,
   height: 11.548
}
,
{
   page: 0,
   name: "Block", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 129.249,
   left: 328.08,
   width: 229.233,
   height: 11.538
}
,
{
   page: 0,
   name: "Addition", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 140.172,
   left: 57.12,
   width: 458.215,
   height: 12.615
}
,
{
   page: 0,
   name: "City of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 152.706,
   left: 93.24,
   width: 204.48,
   height: 11.306
}
,
{
   page: 0,
   name: "County of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 152.239,
   left: 355.841,
   width: 201.079,
   height: 12.082
}
,
{
   page: 0,
   name: "Texas known as", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 164.886,
   left: 141.12,
   width: 415.80,
   height: 10.937
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 240.239,
   left: 468.36,
   width: 88.56,
   height: 11.015
}
,
{
   page: 0,
   name: "Third Party Financing Addendum", 
   isText: false,
   type: "checkbox",
   top: 272.239,
   left: 316.414,
   width: 9.385,
   height: 9.401
}
,
{
   page: 0,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 283.526,
   left: 76.8338,
   width: 9.4629,
   height: 9.463
}
,
{
   page: 0,
   name: "Seller Financing Addendum", 
   isText: false,
   type: "checkbox",
   top: 284.706,
   left: 251.867,
   width: 8.852,
   height: 9.400
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 284.533,
   left: 468.36,
   width: 88.56,
   height: 11.014
}
,
{
   page: 0,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 296.893,
   left: 468.36,
   width: 89.88,
   height: 11.014
}
,
{
   page: 0,
   name: "Sellers", 
   isText: false,
   type: "checkbox",
   top: 376.279,
   left: 142.707,
   width: 8.852,
   height: 9.401
}
,
{
   page: 0,
   name: "Buyers expense an owners policy of", 
   isText: false,
   type: "checkbox",
   top: 376.28,
   left: 171.6,
   width: 9.919,
   height: 9.40
}
,
{
   page: 0,
   name: "i will not be amended or deleted from the title policy or", 
   isText: false,
   type: "checkbox",
   top: 400.946,
   left: 58.5593,
   width: 9.9193,
   height: 9.401
}
,
{
   page: 0,
   name: "ii will be amended to read shortages in area at the expense of", 
   isText: false,
   type: "checkbox",
   top: 415.253,
   left: 58.5593,
   width: 9.9192,
   height: 9.934
}
,
{
   page: 0,
   name: "within the time required Seller may terminate this contract or exercise Sellers remedies under", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 439.067,
   left: 335.123,
   width: 38.160,
   height: 10.894
}
,
{
   page: 0,
   name: "as earnest money to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 485.506,
   left: 153.745,
   width: 250.535,
   height: 11.548
}
,
{
   page: 0,
   name: "the other party in writing before entering into a contract of sale Disclose if applicable", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 486.317,
   left: 499.936,
   width: 58.304,
   height: 12.057
}
,
{
   page: 0,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 495.986,
   left: 73.7563,
   width: 307.8377,
   height: 11.228
}
,
{
   page: 0,
   name: "earnest money of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 495.904,
   left: 445.355,
   width: 112.732,
   height: 11.643
}
,
{
   page: 0,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 506.904,
   left: 196.483,
   width: 95.400,
   height: 10.895
}
,
{
   page: 0,
   name: "agent at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 538.964,
   left: 341.748,
   width: 100.932,
   height: 10.510
}
,
{
   page: 0,
   name: "3 days", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 549.129,
   left: 92.4541,
   width: 36.0659,
   height: 10.894
}
,
{
   page: 0,
   name: "4 days", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 701.7978,
   left: 71.2761,
   width: 38.1599,
   height: 10.8940
}
,
{
   page: 1,
   name: "Contract Concerning", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 32.962,
   left: 130.08,
   width: 319.058,
   height: 11.598
}
,
{
   page: 1,
   name: "Buyer", 
   isText: false,
   type: "checkbox",
   top: 161.28,
   left: 310.359,
   width: 10.986,
   height: 9.934
}
,
{
   page: 1,
   name: "Seller", 
   isText: false,
   type: "checkbox",
   top: 161.813,
   left: 361.759,
   width: 9.919,
   height: 9.401
}
,
{
   page: 1,
   name: "title insurance Title Policy issued by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 171.531,
   left: 260.457,
   width: 298.262,
   height: 11.962
}
,
{
   page: 1,
   name: "i 1", 
   isText: false,
   type: "checkbox",
   top: 328.826,
   left: 70.9326,
   width: 9.9193,
   height: 9.401
}
,
{
   page: 1,
   name: "ii 2", 
   isText: false,
   type: "checkbox",
   top: 340.133,
   left: 70.9326,
   width: 9.9192,
   height: 9.934
}
,
{
   page: 1,
   name: "is", 
   isText: false,
   type: "checkbox",
   top: 339.386,
   left: 430.64,
   width: 10.68,
   height: 9.334
}
,
{
   page: 1,
   name: "is not subject to", 
   isText: false,
   type: "checkbox",
   top: 339.386,
   left: 479.493,
   width: 9.080,
   height: 9.334
}
,
{
   page: 1,
   name: "1 Within", 
   isText: false,
   type: "checkbox",
   top: 481.373,
   left: 58.0136,
   width: 9.9193,
   height: 8.867
}
,
{
   page: 1,
   name: "Title Company Sellers existing survey of the Property and a Residential Real Property Affidavit", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 482.252,
   left: 125.76,
   width: 32.64,
   height: 9.828
}
,
{
   page: 1,
   name: "Sellers_2", 
   isText: false,
   type: "checkbox",
   top: 540.639,
   left: 268.747,
   width: 10.453,
   height: 9.401
}
,
{
   page: 1,
   name: "Buyers expense no later than 3 days prior to Closing Date", 
   isText: false,
   type: "checkbox",
   top: 540.106,
   left: 323.76,
   width: 10.573,
   height: 9.934
}
,
{
   page: 1,
   name: "2 Within", 
   isText: false,
   type: "checkbox",
   top: 560.359,
   left: 58.5471,
   width: 9.9193,
   height: 9.401
}
,
{
   page: 1,
   name: "at Buyers expense Buyer is deemed to receive the survey on the date of actual receipt or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 559.825,
   left: 124.68,
   width: 34.68,
   height: 10.775
}
,
{
   page: 1,
   name: "3 Within", 
   isText: false,
   type: "checkbox",
   top: 588.692,
   left: 58.5471,
   width: 9.5058,
   height: 10.468
}
,
{
   page: 1,
   name: "furnish a new survey to Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 589.759,
   left: 125.52,
   width: 34.68,
   height: 10.241
}
,
{
   page: 1,
   name: "undefined_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 656.929,
   left: 70.4073,
   width: 484.0797,
   height: 11.961
}
,
{
   page: 1,
   name: "Buyer must object the earlier of i the Closing Date or ii", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 668.119,
   left: 369.48,
   width: 34.92,
   height: 11.428
}
,
{
   page: 2,
   name: "Contract Concerning_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.36,
   left: 129.08,
   width: 323.64,
   height: 13.20
}
,
{
   page: 2,
   name: "Texas Agricultural Development District For additional information contact the Texas_2", 
   isText: false,
   type: "checkbox",
   top: 162.479,
   left: 455.44,
   width: 10.986,
   height: 9.401
}
,
{
   page: 2,
   name: "is_2", 
   isText: false,
   type: "checkbox",
   top: 162.479,
   left: 480.587,
   width: 9.386,
   height: 9.401
}
,
{
   page: 3,
   name: "Contract Concerning_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.624,
   left: 130.08,
   width: 319.713,
   height: 13.936
}
,
{
   page: 3,
   name: " 1", 
   isText: false,
   type: "checkbox",
   top: 53.646,
   left: 418.626,
   width: 10.986,
   height: 9.401
}
,
{
   page: 3,
   name: "is not 2", 
   isText: false,
   type: "checkbox",
   top: 53.646,
   left: 454.773,
   width: 9.386,
   height: 9.401
}
,
{
   page: 3,
   name: "following specific repairs and treatments-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 219.324,
   left: 293.817,
   width: 262.257,
   height: 12.162
}
,
{
   page: 3,
   name: "undefined_12-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 230.304,
   left: 88.5758,
   width: 467.5592,
   height: 12.049
}
,
{
   page: 3,
   name: "undefined_12-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 240.538,
   left: 88.8903,
   width: 467.5597,
   height: 10.791
}
,
{
   page: 3,
   name: "1 Buyer accepts the Property As Is", 
   isText: false,
   type: "checkbox",
   top: 389.004,
   left: 60.2609,
   width: 10.1464,
   height: 8.801
}
,
{
   page: 3,
   name: "2 Buyer accepts the Property As Is provided Seller at Sellers expense shall complete the", 
   isText: false,
   type: "checkbox",
   top: 399.151,
   left: 60.2609,
   width: 10.1464,
   height: 9.334
}
,
{
   page: 3,
   name: "following specific repairs and treatments", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 409.857,
   left: 303.327,
   width: 250.936,
   height: 12.162
}
,
{
   page: 3,
   name: "undefined_12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 420.837,
   left: 91.1673,
   width: 463.1567,
   height: 12.049
}
,
{
   page: 3,
   name: "Texas Agricultural Development District For additional information contact the Texas-1", 
   isText: false,
   type: "checkbox",
   top: 601.756,
   left: 124.421,
   width: 10.986,
   height: 9.401
}
,
{
   page: 3,
   name: "-1", 
   isText: false,
   type: "checkbox",
   top: 601.756,
   left: 159.568,
   width: 9.386,
   height: 9.401
}
,
{
   page: 3,
   name: "Texas Agricultural Development District For additional information contact the Texas_1", 
   isText: false,
   type: "checkbox",
   top: 620.756,
   left: 124.421,
   width: 10.986,
   height: 9.401
}
,
{
   page: 3,
   name: "is_2-2", 
   isText: false,
   type: "checkbox",
   top: 620.756,
   left: 159.568,
   width: 9.386,
   height: 9.401
}
,
{
   page: 3,
   name: "Texas Agricultural Development District For additional information contact the Texas-3", 
   isText: false,
   type: "checkbox",
   top: 639.756,
   left: 124.421,
   width: 10.986,
   height: 9.401
}
,
{
   page: 3,
   name: "is_2-3", 
   isText: false,
   type: "checkbox",
   top: 639.756,
   left: 159.568,
   width: 9.386,
   height: 9.401
}
,
{
   page: 3,
   name: "Texas Agricultural Development District For additional information contact the Texas-4", 
   isText: false,
   type: "checkbox",
   top: 656.947,
   left: 124.421,
   width: 10.986,
   height: 9.401
}
,
{
   page: 3,
   name: "is_2-4", 
   isText: false,
   type: "checkbox",
   top: 656.947,
   left: 159.568,
   width: 9.386,
   height: 9.401
}
,
{
   page: 3,
   name: "Texas Agricultural Development District For additional information contact the Texas-5", 
   isText: false,
   type: "checkbox",
   top: 675.947,
   left: 124.421,
   width: 10.986,
   height: 9.401
}
,
{
   page: 3,
   name: "is_2-5", 
   isText: false,
   type: "checkbox",
   top: 675.947,
   left: 159.568,
   width: 9.386,
   height: 9.401
}
,
{
   page: 3,
   name: "Texas Agricultural Development District For additional information contact the Texas-6", 
   isText: false,
   type: "checkbox",
   top: 693.947,
   left: 124.421,
   width: 10.986,
   height: 9.401
}
,
{
   page: 3,
   name: "is_2-6", 
   isText: false,
   type: "checkbox",
   top: 693.947,
   left: 159.568,
   width: 9.386,
   height: 9.401
}
,
{
   page: 3,
   name: "Texas Agricultural Development District For additional information contact the Texas-7", 
   isText: false,
   type: "checkbox",
   top: 712.947,
   left: 124.421,
   width: 10.986,
   height: 9.401
}
,
{
   page: 3,
   name: "is_2-7", 
   isText: false,
   type: "checkbox",
   top: 712.947,
   left: 159.568,
   width: 9.386,
   height: 9.401
}
,
{
   page: 3,
   name: "Texas Agricultural Development District For additional information contact the Texas-7a", 
   isText: false,
   type: "checkbox",
   top: 712.947,
   left: 385.421,
   width: 10.986,
   height: 9.401
}
,
{
   page: 3,
   name: "is_2-7b", 
   isText: false,
   type: "checkbox",
   top: 712.947,
   left: 436.568,
   width: 9.386,
   height: 9.401
}
,
{
   page: 3,
   name: "Texas Agricultural Development District For additional information contact the Texas-8", 
   isText: false,
   type: "checkbox",
   top: 723.947,
   left: 124.421,
   width: 10.986,
   height: 9.401
}
,
{
   page: 3,
   name: "is_2-8", 
   isText: false,
   type: "checkbox",
   top: 723.947,
   left: 160.568,
   width: 9.386,
   height: 8.9549
}
,
{
   page: 3,
   name: "Disclose-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 732.2622,
   left: 536.685,
   width: 25.843,
   height: 10.2750
}
,
{
   page: 3,
   name: "Disclose 2-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 743.9479,
   left: 74.006,
   width: 487.325,
   height: 9.4563
}
,
{
   page: 4,
   name: "Page 5 of 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 29.826,
   left: 130.08,
   width: 320.495,
   height: 14.801
}
,
{
   page: 4,
   name: "Disclose", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 112.784,
   left: 354.122,
   width: 203.847,
   height: 10.275
}
,
{
   page: 4,
   name: "Disclose 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 122.506,
   left: 78.2523,
   width: 478.5197,
   height: 11.420
}
,
{
   page: 4,
   name: "A The closing of the sale will be on or before", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 165.66,
   left: 294.775,
   width: 130.440,
   height: 13.282
}
,
{
   page: 4,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 167.194,
   left: 443.135,
   width: 29.040,
   height: 12.214
}
,
{
   page: 4,
   name: "Text2-4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 404.957,
   left: 354.208,
   width: 202.357,
   height: 12.801
}
,
{
   page: 4,
   name: "Text2-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 414.363,
   left: 65.0705,
   width: 489.1755,
   height: 12.801
}
,
{
   page: 4,
   name: "escrow fee and other expenses payable by Seller under this contract", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 482.87,
   left: 356.378,
   width: 85.560,
   height: 11.895
}
,
{
   page: 5,
   name: "Page 6 of 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.521,
   left: 130.08,
   width: 319.713,
   height: 13.039
}
,
{
   page: 6,
   name: "Contract Concerning_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.071,
   left: 129.207,
   width: 323.640,
   height: 14.362
}
,
{
   page: 6,
   name: "when mailed to handdelivered at or transmitted by fax or electronic transmission as follows", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 83.728,
   left: 138.272,
   width: 165.249,
   height: 13.422
}
,
{
   page: 6,
   name: "at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 105.042,
   left: 65.105,
   width: 238.375,
   height: 14.392
}
,
{
   page: 6,
   name: "Text3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 127.552,
   left: 145.0,
   width: 21.41,
   height: 14.530
}
,
{
   page: 6,
   name: "Text4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 127.401,
   left: 174.635,
   width: 128.898,
   height: 15.063
}
,
{
   page: 6,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 152.081,
   left: 138.853,
   width: 164.680,
   height: 15.403
}
,
{
   page: 6,
   name: "undefined_17-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 178.158,
   left: 138.901,
   width: 165.208,
   height: 15.705
}
,
{
   page: 6,
   name: "undefined_17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 206.003,
   left: 65.8368,
   width: 235.6542,
   height: 15.705
}
,
{
   page: 6,
   name: "undefined_16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 84.307,
   left: 394.767,
   width: 160.491,
   height: 12.843
}
,
{
   page: 6,
   name: "at_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 104.671,
   left: 323.076,
   width: 233.197,
   height: 14.635
}
,
{
   page: 6,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 128.34,
   left: 400.704,
   width: 21.944,
   height: 13.996
}
,
{
   page: 6,
   name: "Text8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 127.421,
   left: 428.849,
   width: 127.846,
   height: 14.915
}
,
{
   page: 6,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 152.954,
   left: 394.813,
   width: 161.497,
   height: 14.530
}
,
{
   page: 6,
   name: "undefined_18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 178.398,
   left: 395.64,
   width: 162.339,
   height: 14.832
}
,
{
   page: 6,
   name: "undefined_18-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 206.439,
   left: 321.382,
   width: 234.043,
   height: 14.203
}
,
{
   page: 6,
   name: "Third Party Financing Addendum_2", 
   isText: false,
   type: "checkbox",
   top: 267.884,
   left: 67.8723,
   width: 11.5200,
   height: 9.401
}
,
{
   page: 6,
   name: "Seller Financing Addendum_2", 
   isText: false,
   type: "checkbox",
   top: 286.418,
   left: 67.8723,
   width: 11.5200,
   height: 8.867
}
,
{
   page: 6,
   name: "Addendum for Property Subject to", 
   isText: false,
   type: "checkbox",
   top: 308.018,
   left: 67.8723,
   width: 11.5200,
   height: 8.867
}
,
{
   page: 6,
   name: "Buyers Temporary Residential Lease", 
   isText: false,
   type: "checkbox",
   top: 343.071,
   left: 67.8723,
   width: 11.5200,
   height: 9.934
}
,
{
   page: 6,
   name: "Sellers Temporary Residential Lease", 
   isText: false,
   type: "checkbox",
   top: 361.071,
   left: 67.8723,
   width: 11.5200,
   height: 9.934
}
,
{
   page: 6,
   name: "Addendum for Reservation of Oil Gas", 
   isText: false,
   type: "checkbox",
   top: 378.657,
   left: 67.8723,
   width: 11.5200,
   height: 10.468
}
,
{
   page: 6,
   name: "Addendum for BackUp Contract", 
   isText: false,
   type: "checkbox",
   top: 415.724,
   left: 68.4059,
   width: 10.9864,
   height: 9.401
}
,
{
   page: 6,
   name: "Addendum Concerning Right to", 
   isText: false,
   type: "checkbox",
   top: 433.724,
   left: 67.8723,
   width: 11.5200,
   height: 9.401
}
,
{
   page: 6,
   name: "PID", 
   isText: false,
   type: "checkbox",
   top: 462.374,
   left: 68.9141,
   width: 11.5200,
   height: 9.401
}
,
{
   page: 6,
   name: "Addendum for Coastal Area Property", 
   isText: false,
   type: "checkbox",
   top: 267.351,
   left: 315.246,
   width: 10.986,
   height: 9.934
}
,
{
   page: 6,
   name: "Environmental Assessment Threatened or", 
   isText: false,
   type: "checkbox",
   top: 285.284,
   left: 314.712,
   width: 12.054,
   height: 9.935
}
,
{
   page: 6,
   name: "Addendum for Property Located Seaward", 
   isText: false,
   type: "checkbox",
   top: 330.764,
   left: 314.712,
   width: 10.453,
   height: 9.401
}
,
{
   page: 6,
   name: "Addendum for Sale of Other Property by", 
   isText: false,
   type: "checkbox",
   top: 362.271,
   left: 315.246,
   width: 10.986,
   height: 9.934
}
,
{
   page: 6,
   name: "Addendum for Property in a Propane Gas", 
   isText: false,
   type: "checkbox",
   top: 396.591,
   left: 315.779,
   width: 10.453,
   height: 9.934
}
,
{
   page: 6,
   name: "Other list", 
   isText: false,
   type: "checkbox",
   top: 425.031,
   left: 314.712,
   width: 11.520,
   height: 9.934
}
,
{
   page: 6,
   name: "System Service Area", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 423.718,
   left: 399.312,
   width: 152.400,
   height: 13.568
}
,
{
   page: 6,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 434.464,
   left: 335.592,
   width: 216.120,
   height: 12.968
}
,
{
   page: 6,
   name: "Attorney is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 565.685,
   left: 125.278,
   width: 165.120,
   height: 14.362
}
,
{
   page: 6,
   name: "undefined_21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 602.791,
   left: 61.6909,
   width: 227.6401,
   height: 14.361
}
,
{
   page: 6,
   name: "Text16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 638.837,
   left: 132.357,
   width: 21.944,
   height: 15.064
}
,
{
   page: 6,
   name: "Text17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 638.303,
   left: 158.036,
   width: 132.392,
   height: 15.598
}
,
{
   page: 6,
   name: "Text18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 678.596,
   left: 131.484,
   width: 22.478,
   height: 15.064
}
,
{
   page: 6,
   name: "Text19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 678.063,
   left: 157.163,
   width: 131.858,
   height: 15.597
}
,
{
   page: 6,
   name: "Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 716.1968,
   left: 125.956,
   width: 165.120,
   height: 14.3610
}
,
{
   page: 6,
   name: "Attorney is_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 566.151,
   left: 382.811,
   width: 174.000,
   height: 13.828
}
,
{
   page: 6,
   name: "undefined_22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 603.664,
   left: 321.284,
   width: 236.400,
   height: 13.294
}
,
{
   page: 6,
   name: "Text20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 638.303,
   left: 391.671,
   width: 21.411,
   height: 15.064
}
,
{
   page: 6,
   name: "Text21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 637.77,
   left: 416.816,
   width: 140.929,
   height: 16.13
}
,
{
   page: 6,
   name: "Text22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 678.596,
   left: 391.798,
   width: 21.411,
   height: 15.597
}
,
{
   page: 6,
   name: "Text23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 677.529,
   left: 416.477,
   width: 140.929,
   height: 16.131
}
,
{
   page: 6,
   name: "Email_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 716.9698,
   left: 384.556,
   width: 174.000,
   height: 13.8281
}
,
{
   page: 7,
   name: "Contract Address 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 29.91,
   left: 128.363,
   width: 323.640,
   height: 14.362
}
,
{
   page: 7,
   name: "EXECUTED the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 223.73,
   left: 134.032,
   width: 42.480,
   height: 13.294
}
,
{
   page: 7,
   name: "day of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 223.385,
   left: 215.512,
   width: 193.320,
   height: 13.828
}
,
{
   page: 7,
   name: "20_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 223.263,
   left: 430.432,
   width: 34.800,
   height: 13.828
}
,
{
   page: 8,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.745,
   left: 127.179,
   width: 323.640,
   height: 13.826
}
,
{
   page: 8,
   name: "Other Broker Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 135.132,
   left: 49.5655,
   width: 163.8425,
   height: 16.119
}
,
{
   page: 8,
   name: "License No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 135.132,
   left: 216.715,
   width: 72.544,
   height: 16.119
}
,
{
   page: 8,
   name: "Buyer only as Buyers agent", 
   isText: false,
   type: "checkbox",
   top: 171.877,
   left: 113.033,
   width: 10.986,
   height: 9.934
}
,
{
   page: 8,
   name: "Seller as Listing Brokers subagent", 
   isText: false,
   type: "checkbox",
   top: 185.743,
   left: 112.553,
   width: 10.986,
   height: 10.468
}
,
{
   page: 8,
   name: "Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 210.253,
   left: 50.0991,
   width: 168.5249,
   height: 15.052
}
,
{
   page: 8,
   name: "License No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 209.186,
   left: 219.796,
   width: 69.463,
   height: 16.652
}
,
{
   page: 8,
   name: "Team Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 240.748,
   left: 49.6662,
   width: 239.4348,
   height: 16.142
}
,
{
   page: 8,
   name: "Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 269.266,
   left: 50.0991,
   width: 161.3789,
   height: 15.585
}
,
{
   page: 8,
   name: "Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 269.266,
   left: 213.717,
   width: 75.542,
   height: 15.585
}
,
{
   page: 8,
   name: "Licensed Supervisor of Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 297.466,
   left: 50.0991,
   width: 168.9389,
   height: 16.119
}
,
{
   page: 8,
   name: "License No_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 296.932,
   left: 220.743,
   width: 68.516,
   height: 16.653
}
,
{
   page: 8,
   name: "Other Brokers Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 326.08,
   left: 50.0991,
   width: 161.9249,
   height: 15.051
}
,
{
   page: 8,
   name: "Phone_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 325.012,
   left: 213.73,
   width: 75.529,
   height: 16.119
}
,
{
   page: 8,
   name: "City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 352.679,
   left: 50.0991,
   width: 132.3709,
   height: 16.652
}
,
{
   page: 8,
   name: "State", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 352.145,
   left: 184.176,
   width: 33.433,
   height: 17.186
}
,
{
   page: 8,
   name: "Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 351.612,
   left: 219.314,
   width: 69.945,
   height: 17.719
}
,
{
   page: 8,
   name: "Listing Broker Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 135.666,
   left: 307.379,
   width: 181.335,
   height: 15.585
}
,
{
   page: 8,
   name: "License No_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 135.132,
   left: 491.487,
   width: 74.732,
   height: 16.119
}
,
{
   page: 8,
   name: "Seller and Buyer as an intermediary", 
   isText: false,
   type: "checkbox",
   top: 172.41,
   left: 369.779,
   width: 11.520,
   height: 9.401
}
,
{
   page: 8,
   name: "Seller only as Sellers agent", 
   isText: false,
   type: "checkbox",
   top: 186.277,
   left: 369.833,
   width: 10.986,
   height: 9.934
}
,
{
   page: 8,
   name: "Listing Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 209.186,
   left: 307.379,
   width: 183.829,
   height: 16.652
}
,
{
   page: 8,
   name: "License No_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 208.652,
   left: 493.448,
   width: 72.771,
   height: 16.653
}
,
{
   page: 8,
   name: "Team Name 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 239.693,
   left: 308.669,
   width: 257.574,
   height: 16.870
}
,
{
   page: 8,
   name: "Listing Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 268.732,
   left: 308.379,
   width: 176.057,
   height: 16.119
}
,
{
   page: 8,
   name: "Phone_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 268.199,
   left: 486.142,
   width: 81.077,
   height: 16.652
}
,
{
   page: 8,
   name: "Licensed Supervisor of Listing Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 298.0,
   left: 308.379,
   width: 184.177,
   height: 15.051
}
,
{
   page: 8,
   name: "License No_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 297.466,
   left: 493.728,
   width: 73.491,
   height: 15.585
}
,
{
   page: 8,
   name: "Listing Brokers Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 326.08,
   left: 308.379,
   width: 179.512,
   height: 15.051
}
,
{
   page: 8,
   name: "Phone_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 326.08,
   left: 489.063,
   width: 78.156,
   height: 15.051
}
,
{
   page: 8,
   name: "City_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 353.746,
   left: 308.379,
   width: 158.638,
   height: 15.585
}
,
{
   page: 8,
   name: "State_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 353.212,
   left: 469.79,
   width: 33.009,
   height: 16.119
}
,
{
   page: 8,
   name: "Zip_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 353.212,
   left: 504.504,
   width: 62.715,
   height: 16.653
}
,
{
   page: 8,
   name: "Selling Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 383.589,
   left: 308.379,
   width: 189.232,
   height: 15.051
}
,
{
   page: 8,
   name: "License No_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 382.426,
   left: 498.783,
   width: 68.436,
   height: 15.585
}
,
{
   page: 8,
   name: "Team Name 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 410.513,
   left: 308.149,
   width: 258.178,
   height: 15.923
}
,
{
   page: 8,
   name: "Selling Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 440.693,
   left: 308.379,
   width: 179.859,
   height: 14.518
}
,
{
   page: 8,
   name: "Phone_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 440.693,
   left: 489.944,
   width: 77.275,
   height: 14.518
}
,
{
   page: 8,
   name: "Licensed Supervisor of Selling Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 466.946,
   left: 308.379,
   width: 189.165,
   height: 16.532
}
,
{
   page: 8,
   name: "License No_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 466.946,
   left: 499.783,
   width: 67.436,
   height: 15.999
}
,
{
   page: 8,
   name: "Selling Associates Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 496.213,
   left: 308.379,
   width: 258.840,
   height: 14.398
}
,
{
   page: 8,
   name: "City_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 517.76,
   left: 308.379,
   width: 147.891,
   height: 15.051
}
,
{
   page: 8,
   name: "State_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 517.76,
   left: 457.443,
   width: 32.871,
   height: 15.051
}
,
{
   page: 8,
   name: "Zip_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 517.76,
   left: 491.486,
   width: 75.733,
   height: 15.051
}
,
{
   page: 8,
   name: "Text 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 588.907,
   left: 440.094,
   width: 131.545,
   height: 11.474
}
,
{
   page: 8,
   name: "Text 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 597.577,
   left: 51.5653,
   width: 201.1827,
   height: 11.717
}
,
{
   page: 9,
   name: "Address of Property_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.296,
   left: 129.652,
   width: 323.640,
   height: 12.932
}
,
{
   page: 9,
   name: "is acknowledged", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 87.935,
   left: 106.492,
   width: 98.520,
   height: 12.933
}
,
{
   page: 9,
   name: "Option Fee in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 88.762,
   left: 336.652,
   width: 217.440,
   height: 12.932
}
,
{
   page: 9,
   name: "Seller or Listing Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 114.588,
   left: 51.8923,
   width: 248.1597,
   height: 14.000
}
,
{
   page: 9,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 114.589,
   left: 312.892,
   width: 243.120,
   height: 13.999
}
,
{
   page: 9,
   name: "is acknowledged_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 168.749,
   left: 105.772,
   width: 99.000,
   height: 12.398
}
,
{
   page: 9,
   name: "Earnest Money in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 168.215,
   left: 343.132,
   width: 213.240,
   height: 12.932
}
,
{
   page: 9,
   name: "Escrow Agent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 194.334,
   left: 51.6523,
   width: 137.0657,
   height: 14.534
}
,
{
   page: 9,
   name: "Received by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 194.334,
   left: 190.717,
   width: 109.095,
   height: 14.534
}
,
{
   page: 9,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 218.413,
   left: 51.6523,
   width: 306.7197,
   height: 16.134
}
,
{
   page: 9,
   name: "City_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 245.255,
   left: 51.4123,
   width: 166.1847,
   height: 12.933
}
,
{
   page: 9,
   name: "State_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 244.722,
   left: 220.13,
   width: 30.281,
   height: 13.466
}
,
{
   page: 9,
   name: "Zip_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 243.532,
   left: 252.411,
   width: 105.961,
   height: 14.001
}
,
{
   page: 9,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 194.869,
   left: 312.772,
   width: 156.474,
   height: 13.999
}
,
{
   page: 9,
   name: "DateTime", 
   fontSize: 9,
   type: "date",
   top: 194.869,
   left: 470.712,
   width: 85.060,
   height: 13.999
}
,
{
   page: 9,
   name: "Phone_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 219.48,
   left: 367.252,
   width: 189.120,
   height: 15.067
}
,
{
   page: 9,
   name: "Fax", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 242.588,
   left: 367.252,
   width: 189.120,
   height: 15.600
}
,
{
   page: 9,
   name: "Escrow Agent_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 315.882,
   left: 51.6523,
   width: 125.8607,
   height: 13.466
}
,
{
   page: 9,
   name: "Received by_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 315.882,
   left: 179.513,
   width: 120.299,
   height: 14.000
}
,
{
   page: 9,
   name: "Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 339.428,
   left: 51.6523,
   width: 306.7197,
   height: 15.599
}
,
{
   page: 9,
   name: "City_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 363.068,
   left: 51.4123,
   width: 165.1177,
   height: 16.134
}
,
{
   page: 9,
   name: "State_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 363.068,
   left: 217.996,
   width: 35.083,
   height: 15.600
}
,
{
   page: 9,
   name: "Zip_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 363.068,
   left: 254.011,
   width: 104.361,
   height: 16.134
}
,
{
   page: 9,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 316.415,
   left: 312.772,
   width: 163.762,
   height: 12.933
}
,
{
   page: 9,
   name: "Date_2", 
   fontSize: 9,
   type: "date",
   top: 316.415,
   left: 478.533,
   width: 77.239,
   height: 12.933
}
,
{
   page: 9,
   name: "Phone_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 339.962,
   left: 367.252,
   width: 189.120,
   height: 15.600
}
,
{
   page: 9,
   name: "Fax_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 363.068,
   left: 367.252,
   width: 189.120,
   height: 15.600
}
,
{
   page: 9,
   name: "is acknowledged_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 411.668,
   left: 108.892,
   width: 91.440,
   height: 15.600
}
,
{
   page: 9,
   name: "additional Earnest Money in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 413.148,
   left: 385.852,
   width: 165.960,
   height: 15.067
}
,
{
   page: 9,
   name: "Escrow Agent_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 439.388,
   left: 51.6523,
   width: 140.2677,
   height: 15.600
}
,
{
   page: 9,
   name: "Received by_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 439.388,
   left: 193.919,
   width: 105.893,
   height: 15.600
}
,
{
   page: 9,
   name: "Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 465.068,
   left: 51.6523,
   width: 306.7197,
   height: 15.600
}
,
{
   page: 9,
   name: "City_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 488.828,
   left: 51.4123,
   width: 162.4497,
   height: 15.480
}
,
{
   page: 9,
   name: "State_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 489.362,
   left: 215.861,
   width: 35.617,
   height: 14.946
}
,
{
   page: 9,
   name: "Zip_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 488.828,
   left: 253.478,
   width: 104.894,
   height: 15.480
}
,
{
   page: 9,
   name: "Email Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 439.388,
   left: 312.772,
   width: 152.205,
   height: 15.600
}
,
{
   page: 9,
   name: "DateTime_2", 
   fontSize: 9,
   type: "date",
   top: 439.388,
   left: 466.443,
   width: 89.329,
   height: 15.600
}
,
{
   page: 9,
   name: "Phone_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 465.068,
   left: 367.252,
   width: 189.120,
   height: 15.600
}
,
{
   page: 9,
   name: "Fax_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 488.828,
   left: 367.252,
   width: 189.120,
   height: 15.480
}
] }
