export function addendumRegardingFixtureLeases() {
return [   //2024 Release 2024-02-19 07:33:00
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 118.401,
   left: 241.32,
   width: 316.80,
   height: 13.151
}
,
{
   page: 0,
   name: "solar panels", 
   isText: false,
   type: "checkbox",
   top: 189.561,
   left: 76.1281,
   width: 11.2719,
   height: 10.279
}
,
{
   page: 0,
   name: "propane tanks", 
   isText: false,
   type: "checkbox",
   top: 190.152,
   left: 160.4,
   width: 10.68,
   height: 9.688
}
,
{
   page: 0,
   name: "water softener", 
   isText: false,
   type: "checkbox",
   top: 189.864,
   left: 258.84,
   width: 10.80,
   height: 8.976
}
,
{
   page: 0,
   name: "security system", 
   isText: false,
   type: "checkbox",
   top: 189.864,
   left: 356.96,
   width: 10.68,
   height: 8.976
}
,
{
   page: 0,
   name: "4 security system", 
   isText: false,
   type: "checkbox",
   top: 190.078,
   left: 459.66,
   width: 10.68,
   height: 9.688
}
,
{
   page: 0,
   name: "collectively the Leased Fixtures All rights to the Leased Fixtures are governed by Fixture Leases", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 189.713,
   left: 474.229,
   width: 103.536,
   height: 11.255
}
,
{
   page: 0,
   name: "solar panels no", 
   isText: false,
   type: "checkbox",
   top: 236.346,
   left: 83.394,
   width: 11.2719,
   height: 9.567
}
,
{
   page: 0,
   name: "propane tanks no", 
   isText: false,
   type: "checkbox",
   top: 236.937,
   left: 196.666,
   width: 10.680,
   height: 8.976
}
,
{
   page: 0,
   name: "water softener no", 
   isText: false,
   type: "checkbox",
   top: 236.937,
   left: 321.106,
   width: 10.800,
   height: 8.976
}
,
{
   page: 0,
   name: "security system no", 
   isText: false,
   type: "checkbox",
   top: 236.937,
   left: 451.226,
   width: 10.680,
   height: 8.976
}
,
{
   page: 0,
   name: "4 security system no", 
   isText: false,
   type: "checkbox",
   top: 251.863,
   left: 83.9259,
   width: 10.6800,
   height: 8.976
}
,
{
   page: 0,
   name: "of any cost necessary to assume or receive an assignment of the Fixture Leases and Seller shall", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 250.504,
   left: 99.32,
   width: 237.629,
   height: 10.663
}
,
{
   page: 0,
   name: "Buyer shall pay the first", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 252.096,
   left: 480.88,
   width: 98.481,
   height: 10.784
}
,
{
   page: 0,
   name: "will", 
   isText: false,
   type: "checkbox",
   top: 307.984,
   left: 204.496,
   width: 12.824,
   height: 9.576
}
,
{
   page: 0,
   name: "will not remove the Leased Fixtures covered by the Fixture", 
   isText: false,
   type: "checkbox",
   top: 307.984,
   left: 250.824,
   width: 13.416,
   height: 9.576
}
,
{
   page: 0,
   name: "1 Buyer has received a copy of all Fixture Leases Buyer has agreed to assume", 
   isText: false,
   type: "checkbox",
   top: 377.024,
   left: 46.6963,
   width: 12.7037,
   height: 9.576
}
,
{
   page: 0,
   name: "2 Buyer has not received a copy of all Fixture Leases Buyer has agreed to assume Seller shall_0", 
   isText: false,
   type: "checkbox",
   top: 390.832,
   left: 47.1044,
   width: 13.2956,
   height: 10.168
}
,
{
   page: 0,
   name: "2 Buyer has not received a copy of all Fixture Leases Buyer has agreed to assume Seller shall_1", 
   isText: false,
   type: "checkbox",
   top: 437.534,
   left: 45.3522,
   width: 13.2956,
   height: 10.168
}
,
{
   page: 0,
   name: "of any cost necessary to assume or receive an assignment of the Fixture Leases and Seller shall no 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 447.503,
   left: 454.166,
   width: 126.486,
   height: 12.801
}
,
{
   page: 0,
   name: "of any cost necessary to assume or receive an assignment of the Fixture Leases and Seller shall no 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 459.477,
   left: 83.167,
   width: 492.688,
   height: 12.089
}
] }
