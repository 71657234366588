export function exclusiveRighttoBuyCB2024() {
return [   //2024 Release 2024-06-17 10:58:54
{
   page: 0,
   name: "BUYER AGENCY", 
   isText: false,
   type: "checkbox",
   top: 255.48,
   left: 158.04,
   width: 12.96,
   height: 11.28
}
,
{
   page: 0,
   name: "TRANSACTIONBROKERAGE", 
   isText: false,
   type: "checkbox",
   top: 255.48,
   left: 301.8,
   width: 12.96,
   height: 11.28
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 271.2,
   left: 447.12,
   width: 103.80,
   height: 16.56
}

,
{
   page: 0,
   name: "Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 502.56,
   left: 128.64,
   width: 435.72,
   height: 16.56
}
,
{
   page: 0,
   name: "Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 538.56,
   left: 132.48,
   width: 434.76,
   height: 16.56
}
,
{
   page: 0,
   name: "If this box is checked Buyer authorizes Broker to negotiate a", 
   isText: false,
   type: "checkbox",
   top: 709.56,
   left: 112.68,
   width: 10.56,
   height: 9.36
}
,
{
   page: 0,
   name: "Text1", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 586.475,
   left: 66.1094,
   width: 508.0376,
   height: 51.455
}
,
{
   page: 1,
   name: "on of the Purchase of Property or Lease of Property or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 33.6,
   left: 426.48,
   width: 144.60,
   height: 12.96
}

,
{
   page: 1,
   name: "n the completion of any Purchase or Lease for wh", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 60,
   left: 93.12,
   width: 161.80,
   height: 12.96
}
,
{
   page: 1,
   name: "uded If any dead", 
   isText: false,
   type: "checkbox",
   top: 215.4,
   left: 503.64,
   width: 10.56,
   height: 9.36
}
,
{
   page: 1,
   name: "extended to the next day that is not a Saturday Sunday or Holiday Should neither box be checked the", 
   isText: false,
   type: "checkbox",
   top: 215.4,
   left: 460.2,
   width: 10.56,
   height: 9.36
}
,
{
   page: 1,
   name: "treat the se", 
   isText: false,
   type: "checkbox",
   top: 501,
   left: 129.24,
   width: 10.56,
   height: 9.36
}
,
{
   page: 2,
   name: "Success Fee Commission Brokerage Firm wi", 
   isText: false,
   type: "checkbox",
   top: 528.36,
   left: 110.04,
   width: 10.56,
   height: 9.36
}
,
{
   page: 2,
   name: "of the purchase price plus 259 but not", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 542.4,
   left: 246.96,
   width: 42.72,
   height: 12.48
}
,
{
   page: 2,
   name: "except as provided in  7112", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 557.28,
   left: 95.4,
   width: 72.24,
   height: 12.48
}
,
{
   page: 2,
   name: "See  21 Additional Provisions or", 
   isText: false,
   type: "checkbox",
   top: 578.04,
   left: 233.4,
   width: 10.56,
   height: 9.36
}
,
{
   page: 2,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 578.04,
   left: 423.48,
   width: 10.56,
   height: 9.36
}
,
{
   page: 2,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 587.76,
   left: 92.16,
   width: 471.48,
   height: 12.48
}
,
{
   page: 2,
   name: "Retainer Fee Buyer wi", 
   isText: false,
   type: "checkbox",
   top: 725.64,
   left: 107.16,
   width: 10.56,
   height: 9.36
}
,
{
   page: 2,
   name: "due and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 721.68,
   left: 471.12,
   width: 61.08,
   height: 12.48
}
,
{
   page: 2,
   name: "Will", 
   isText: false,
   type: "checkbox",
   top: 737.64,
   left: 374.28,
   width: 10.56,
   height: 9.36
}
,
{
   page: 2,
   name: "Will Not be credited against", 
   isText: false,
   type: "checkbox",
   top: 737.64,
   left: 422.04,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "714", 
   isText: false,
   type: "checkbox",
   top: 52.92,
   left: 107.16,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 48.72,
   left: 229.92,
   width: 334.80,
   height: 12.72
}
,
{
   page: 3,
   name: "per square", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 92.88,
   left: 165.72,
   width: 58.56,
   height: 12.72
}
,
{
   page: 3,
   name: "or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 92.88,
   left: 277.44,
   width: 69.48,
   height: 12.72
}
,
{
   page: 3,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 92.88,
   left: 364.56,
   width: 205.56,
   height: 12.72
}
,
{
   page: 3,
   name: "Adjusted Amount", 
   isText: false,
   type: "checkbox",
   top: 125.16,
   left: 210.84,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "See  21 Additional Provisions or_2", 
   isText: false,
   type: "checkbox",
   top: 125.16,
   left: 405.72,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 120.96,
   left: 458.88,
   width: 107.04,
   height: 12.72
}
,
{
   page: 3,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 136.8,
   left: 148.08,
   width: 417.84,
   height: 12.72
}
,
{
   page: 3,
   name: "If the Lease executed after the date of this", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 192.48,
   left: 252.72,
   width: 119.52,
   height: 12.72
}
,
{
   page: 3,
   name: "rm", 
   isText: false,
   type: "checkbox",
   top: 224.28,
   left: 393.72,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "Will_2", 
   isText: false,
   type: "checkbox",
   top: 224.28,
   left: 441.48,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 264.24,
   left: 76.08,
   width: 482.88,
   height: 12.72
}
,
{
   page: 3,
   name: "731", 
   isText: false,
   type: "checkbox",
   top: 300.36,
   left: 98.28,
   width: 11.04,
   height: 9.84
}
,
{
   page: 3,
   name: "732", 
   isText: false,
   type: "checkbox",
   top: 353.16,
   left: 98.28,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "733", 
   isText: false,
   type: "checkbox",
   top: 393.24,
   left: 98.28,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "calendar days after the Listing Period expires", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 484.8,
   left: 294,
   width: 45.96,
   height: 12.72
}
,
{
   page: 3,
   name: "However Buyer", 
   isText: false,
   type: "checkbox",
   top: 530.04,
   left: 148.92,
   width: 11.04,
   height: 9.84
}
,
{
   page: 3,
   name: "Will_3", 
   isText: false,
   type: "checkbox",
   top: 530.04,
   left: 195.96,
   width: 11.04,
   height: 9.84
}
,
{
   page: 3,
   name: "ng Contract Buyer represents that Buyer", 
   isText: false,
   type: "checkbox",
   top: 696.12,
   left: 430.2,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "Is", 
   isText: false,
   type: "checkbox",
   top: 696.12,
   left: 465.48,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "Property Buyer further represents that Buyer", 
   isText: false,
   type: "checkbox",
   top: 723.72,
   left: 264.84,
   width: 10.56,
   height: 9.36
}
,
{
   page: 3,
   name: "Has", 
   isText: false,
   type: "checkbox",
   top: 723.72,
   left: 313.08,
   width: 10.56,
   height: 9.36
}
,
{
   page: 4,
   name: "Broker through Brokerage Firm has access to the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 308.16,
   left: 80.64,
   width: 262.92,
   height: 11.52
}
,
{
   page: 4,
   name: "tten consent of Buyer", 
   isText: false,
   type: "checkbox",
   top: 363.96,
   left: 298.68,
   width: 10.56,
   height: 9.36
}
,
{
   page: 4,
   name: "Does Not have Buyer", 
   isText: false,
   type: "checkbox",
   top: 363.96,
   left: 348.84,
   width: 10.56,
   height: 9.36
}
,
{
   page: 4,
   name: "Text2", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 333.82,
   left: 72.6549,
   width: 501.4921,
   height: 25.927
}
,
{
   page: 6,
   name: "undefined_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 431.88,
   left: 104.64,
   width: 456.12,
   height: 13.32
}
,
{
   page: 6,
   name: "Text3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 598.257,
   left: 62.8366,
   width: 526.3654,
   height: 158.1463
}
,
{
   page: 7,
   name: "or_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 233.04,
   left: 385.92,
   width: 169.56,
   height: 12.48
}
,
{
   page: 7,
   name: "signature buyerbroker", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 631.32,
   left: 58.08,
   width: 183.60,
   height: 14.76
}
,
{
   page: 7,
   name: "Date buyerbrokersign", 
   fontSize: 9,
   type: "date",
   top: 630.36,
   left: 272.88,
   width: 103.56,
   height: 14.76
}
,
{
   page: 7,
   name: "NRT Colorado LLC dba Coldwell Banker Realty", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 656.04,
   left: 188.64,
   width: 262.92,
   height: 14.76
}
,
{
   page: 7,
   name: "Brokerage Firm Address 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 686.64,
   left: 188.64,
   width: 262.92,
   height: 13.68
}
,
{
   page: 7,
   name: "Brokerage Firm Address 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 700.92,
   left: 188.64,
   width: 262.92,
   height: 14.76
}
,
{
   page: 7,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 716.28,
   left: 188.64,
   width: 262.92,
   height: 14.52
}
,
{
   page: 7,
   name: "Text4", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 82.473,
   left: 56.9457,
   width: 517.2013,
   height: 37.709
}
] }
