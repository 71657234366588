import { Formik, Form } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  Header,
  Segment,
  Table,
  Modal,
  Popup,
  Icon,
} from "semantic-ui-react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { closeModal } from "../../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../../app/common/modals/modalWrapper";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import MyNumberInput from "../../../../app/common/form/MyNumberInput";
import MyTextArea from "../../../../app/common/form/MyTextArea";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import {
  addItemToTaskTemplate,
  updateItemInTaskTemplate,
  updateTaskTemplateTitle,
} from "../taskTemplatesSlice";
import {
  addTaskTemplateToDb,
  updateTaskTemplateInDb,
} from "../../../../app/firestore/firestoreService";
import TaskTemplatesItemActionButtons from "../TaskTemplatesItemActionButtons";

export default function TaskTemplatesEdit({ newTemplate = false }) {
  const dispatch = useDispatch();
  const { taskTemplate } = useSelector((state) => state.taskTemplates);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const [itemModal, setItemModal] = useState({});

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    days: "",
    beforeOnAfter: "",
    dateType: "",
    assignedTo: "Me",
  });

  const initialValuesTemplate = taskTemplate
    ? taskTemplate
    : {
        title: "",
        items: [],
      };

  const validationSchemaTemplate = Yup.object({
    title: Yup.string().required("You must provide a name"),
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("You must provide a name"),
  });

  function handleAddItem() {
    setInitialValues({
      name: "",
      description: "",
      days: "",
      beforeOnAfter: "",
      dateType: "",
      assignedTo: "",
    });
    setItemModal({ open: true, new: true });
  }

  return (
    <>
      <ModalWrapper size="large">
        <Segment clearing>
          <div className="medium horizontal margin small top margin">
            <Header size="large" color="blue">
              Create Task Template
            </Header>
            <Divider />
            <Formik
              enableReinitialize
              initialValues={initialValuesTemplate}
              validationSchema={validationSchemaTemplate}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  newTemplate
                    ? addTaskTemplateToDb(values)
                    : updateTaskTemplateInDb(values);
                  toast.success("Task template successfully updated");
                  dispatch(
                    closeModal({
                      modalType: "TaskTemplatesEdit",
                    })
                  );
                } catch (error) {
                  toast.error(error.message);
                }
              }}
            >
              {({ isSubmitting, dirty, isValid, values }) => (
                <Form className="ui form" autoComplete="off">
                  <Grid>
                    <Grid.Row className="small vertical padding">
                      <Grid.Column mobile={16} computer={6}>
                        <Header
                          as="h5"
                          color="blue"
                          className="mini bottom margin"
                        >
                          Template Name
                        </Header>
                        <MyTextInput
                          name="title"
                          onBlur={() =>
                            dispatch(updateTaskTemplateTitle(values.title))
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Table>
                    <Table.Header className="white-blue-table-header">
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Days</Table.HeaderCell>
                        <Table.HeaderCell>Before/On/After</Table.HeaderCell>
                        <Table.HeaderCell>Reference Date</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {taskTemplate?.items &&
                        taskTemplate?.items?.length > 0 &&
                        taskTemplate.items.map((item) => (
                          <Table.Row key={item.name}>
                            <>
                              <Table.Cell>
                                {item.name}
                                {item.description && (
                                  <Popup
                                    size="small"
                                    trigger={
                                      <Icon
                                        name="info"
                                        color="blue"
                                        circular
                                        inverted
                                        size="tiny"
                                        style={{
                                          marginLeft: "6px",
                                          marginBottom: "2px",
                                          verticalAlign: "bottom",
                                          cursor: "pointer",
                                        }}
                                      />
                                    }
                                  >
                                    {item.description}
                                  </Popup>
                                )}
                              </Table.Cell>
                              <Table.Cell>{item.days}</Table.Cell>
                              <Table.Cell>{item.beforeOnAfter}</Table.Cell>
                              <Table.Cell>{item.dateType}</Table.Cell>
                              <Table.Cell>
                                <TaskTemplatesItemActionButtons
                                  item={item}
                                  setInitialValues={setInitialValues}
                                  setItemModal={setItemModal}
                                />
                              </Table.Cell>
                            </>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                  <Button
                    type="button"
                    className="medium top margin"
                    primary
                    onClick={() => handleAddItem()}
                    content="Add Task"
                  />
                  <Divider className="medium top margin" />
                  <Button
                    floated={isMobile ? null : "right"}
                    primary
                    type="submit"
                    content="Submit"
                    className={isMobile ? "fluid medium bottom margin" : null}
                  />
                  <Button
                    type="button"
                    onClick={() =>
                      dispatch(
                        closeModal({
                          modalType: "TaskTemplatesEdit",
                        })
                      )
                    }
                    to="#"
                    floated={isMobile ? null : "right"}
                    content="Cancel"
                    className={isMobile ? "fluid medium bottom margin" : null}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Segment>
      </ModalWrapper>
      <Modal
        closeIcon
        open={itemModal?.open}
        onClose={() => setItemModal({ open: false, new: null })}
        size="large"
      >
        <Modal.Content>
          {" "}
          <Segment clearing>
            <div className="medium horizontal margin small top margin">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    itemModal.new
                      ? dispatch(addItemToTaskTemplate(values))
                      : dispatch(
                          updateItemInTaskTemplate({
                            index: itemModal.editingId,
                            item: values,
                          })
                        );
                    setSubmitting(false);
                    setItemModal({ open: false, new: null });
                    toast.success("Task successfully updated");
                  } catch (error) {
                    toast.error(error.message);
                    setSubmitting(false);
                  }
                }}
              >
                {({ isSubmitting, dirty, isValid, values }) => (
                  <Form className="ui form" autoComplete="off">
                    <Header size="large" color="blue">
                      {itemModal?.new ? "Create Task" : "Edit Task"}
                    </Header>
                    <Divider />
                    <Grid>
                      <Grid.Row className="small vertical padding">
                        <Grid.Column mobile={16} computer={6}>
                          <MyTextInput name="name" label="Name" />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={10}>
                          <MyTextArea
                            rows={4}
                            name="description"
                            label="Description"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className="small vertical padding">
                        <Grid.Column mobile={16} computer={2}>
                          <MyNumberInput name="days" label="Days" />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={4}>
                          <MySelectInput
                            name="beforeOnAfter"
                            label="Before/On/After"
                            placeholder="Select time reference"
                            options={[
                              {
                                key: "Before",
                                text: "Before",
                                value: "Before",
                              },
                              { key: "On", text: "On", value: "On" },
                              { key: "After", text: "After", value: "After" },
                            ]}
                          />
                        </Grid.Column>

                        <Grid.Column mobile={16} computer={4}>
                          <MySelectInput
                            name="dateType"
                            label="Reference Date"
                            placeholder="Select reference date"
                            options={[
                              {
                                key: "",
                                text: "",
                                value: "",
                              },
                              {
                                key: "Contract Date",
                                text: "Contract Date",
                                value: "Contract Date",
                              },
                              {
                                key: "Closing Date",
                                text: "Closing Date",
                                value: "Closing Date",
                              },
                            ]}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Divider className="medium top margin" />
                    <Button
                      loading={isSubmitting}
                      disabled={!dirty || isSubmitting}
                      type="submit"
                      floated={isMobile ? null : "right"}
                      primary
                      content={itemModal?.new ? "Add" : "Save"}
                      className={isMobile ? "fluid medium bottom margin" : null}
                    />
                    <Button
                      disabled={isSubmitting}
                      onClick={() => setItemModal({ open: false, new: null })}
                      to="#"
                      type="button"
                      floated={isMobile ? null : "right"}
                      content="Cancel"
                      className={isMobile ? "fluid medium bottom margin" : null}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </Segment>
        </Modal.Content>
      </Modal>
    </>
  );
}
