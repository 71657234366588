export function appendixAResidentialPropertyCondition() {
return [   //2024 Release 2024-02-27 22:49:02
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 233.016,
   left: 212.17,
   width: 356.83,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 247.342,
   left: 43.0,
   width: 526.0,
   height: 13.500
}
,
{
   page: 0,
   name: "Check Box 110", 
   isText: false,
   type: "checkbox",
   top: 260.842,
   left: 97.21,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 260.842,
   left: 148.934,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 215", 
   isText: false,
   type: "checkbox",
   top: 368.5,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 216", 
   isText: false,
   type: "checkbox",
   top: 368.5,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 217", 
   isText: false,
   type: "checkbox",
   top: 368.5,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 218", 
   isText: false,
   type: "checkbox",
   top: 368.5,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 219", 
   isText: false,
   type: "checkbox",
   top: 383.75,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 220", 
   isText: false,
   type: "checkbox",
   top: 383.75,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 221", 
   isText: false,
   type: "checkbox",
   top: 383.75,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 222", 
   isText: false,
   type: "checkbox",
   top: 383.75,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 223", 
   isText: false,
   type: "checkbox",
   top: 399.0,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 224", 
   isText: false,
   type: "checkbox",
   top: 399.0,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 225", 
   isText: false,
   type: "checkbox",
   top: 399.0,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 226", 
   isText: false,
   type: "checkbox",
   top: 399.0,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 1020", 
   isText: false,
   type: "checkbox",
   top: 414.625,
   left: 113.426,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 1019", 
   isText: false,
   type: "checkbox",
   top: 414.625,
   left: 170.15,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 1018", 
   isText: false,
   type: "checkbox",
   top: 414.625,
   left: 212.954,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 227", 
   isText: false,
   type: "checkbox",
   top: 414.25,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 228", 
   isText: false,
   type: "checkbox",
   top: 414.25,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 229", 
   isText: false,
   type: "checkbox",
   top: 414.25,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 230", 
   isText: false,
   type: "checkbox",
   top: 414.25,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 231", 
   isText: false,
   type: "checkbox",
   top: 429.5,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 235", 
   isText: false,
   type: "checkbox",
   top: 429.5,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 239", 
   isText: false,
   type: "checkbox",
   top: 429.5,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 243", 
   isText: false,
   type: "checkbox",
   top: 429.5,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 1017", 
   isText: false,
   type: "checkbox",
   top: 445.375,
   left: 121.642,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 1016", 
   isText: false,
   type: "checkbox",
   top: 445.375,
   left: 177.86,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 232", 
   isText: false,
   type: "checkbox",
   top: 444.75,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 236", 
   isText: false,
   type: "checkbox",
   top: 444.75,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 240", 
   isText: false,
   type: "checkbox",
   top: 444.75,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 244", 
   isText: false,
   type: "checkbox",
   top: 444.75,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 233", 
   isText: false,
   type: "checkbox",
   top: 460.0,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 237", 
   isText: false,
   type: "checkbox",
   top: 460.0,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 241", 
   isText: false,
   type: "checkbox",
   top: 460.0,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 245", 
   isText: false,
   type: "checkbox",
   top: 460.0,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 234", 
   isText: false,
   type: "checkbox",
   top: 475.25,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 238", 
   isText: false,
   type: "checkbox",
   top: 475.25,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 242", 
   isText: false,
   type: "checkbox",
   top: 475.25,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 246", 
   isText: false,
   type: "checkbox",
   top: 475.25,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 247", 
   isText: false,
   type: "checkbox",
   top: 490.5,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 255", 
   isText: false,
   type: "checkbox",
   top: 490.5,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 263", 
   isText: false,
   type: "checkbox",
   top: 490.5,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 271", 
   isText: false,
   type: "checkbox",
   top: 490.5,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 1013", 
   isText: false,
   type: "checkbox",
   top: 505.75,
   left: 119.642,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 1014", 
   isText: false,
   type: "checkbox",
   top: 505.75,
   left: 170.758,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 1015", 
   isText: false,
   type: "checkbox",
   top: 505.75,
   left: 221.875,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 249", 
   isText: false,
   type: "checkbox",
   top: 505.75,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 257", 
   isText: false,
   type: "checkbox",
   top: 505.75,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 265", 
   isText: false,
   type: "checkbox",
   top: 505.75,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 273", 
   isText: false,
   type: "checkbox",
   top: 505.75,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 1010", 
   isText: false,
   type: "checkbox",
   top: 521.25,
   left: 161.15,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 1011", 
   isText: false,
   type: "checkbox",
   top: 521.25,
   left: 217.892,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 1012", 
   isText: false,
   type: "checkbox",
   top: 521.25,
   left: 260.25,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 251", 
   isText: false,
   type: "checkbox",
   top: 521.0,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 259", 
   isText: false,
   type: "checkbox",
   top: 521.0,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 267", 
   isText: false,
   type: "checkbox",
   top: 521.0,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 275", 
   isText: false,
   type: "checkbox",
   top: 521.0,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 253", 
   isText: false,
   type: "checkbox",
   top: 536.25,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 261", 
   isText: false,
   type: "checkbox",
   top: 536.25,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 269", 
   isText: false,
   type: "checkbox",
   top: 536.25,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 277", 
   isText: false,
   type: "checkbox",
   top: 536.25,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 248", 
   isText: false,
   type: "checkbox",
   top: 551.5,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 256", 
   isText: false,
   type: "checkbox",
   top: 551.5,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 264", 
   isText: false,
   type: "checkbox",
   top: 551.5,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 272", 
   isText: false,
   type: "checkbox",
   top: 551.5,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 250", 
   isText: false,
   type: "checkbox",
   top: 566.75,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 258", 
   isText: false,
   type: "checkbox",
   top: 566.75,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 266", 
   isText: false,
   type: "checkbox",
   top: 566.75,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 274", 
   isText: false,
   type: "checkbox",
   top: 566.75,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 107", 
   isText: false,
   type: "checkbox",
   top: 581.75,
   left: 125.25,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 108", 
   isText: false,
   type: "checkbox",
   top: 581.75,
   left: 181.75,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 109", 
   isText: false,
   type: "checkbox",
   top: 581.75,
   left: 224.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 252", 
   isText: false,
   type: "checkbox",
   top: 582.0,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 260", 
   isText: false,
   type: "checkbox",
   top: 582.0,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 268", 
   isText: false,
   type: "checkbox",
   top: 582.0,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 276", 
   isText: false,
   type: "checkbox",
   top: 582.0,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 254", 
   isText: false,
   type: "checkbox",
   top: 597.25,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 262", 
   isText: false,
   type: "checkbox",
   top: 597.25,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 270", 
   isText: false,
   type: "checkbox",
   top: 597.25,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 278", 
   isText: false,
   type: "checkbox",
   top: 597.25,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 280", 
   isText: false,
   type: "checkbox",
   top: 612.5,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 287", 
   isText: false,
   type: "checkbox",
   top: 612.5,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 294", 
   isText: false,
   type: "checkbox",
   top: 612.5,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 301", 
   isText: false,
   type: "checkbox",
   top: 612.5,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 104", 
   isText: false,
   type: "checkbox",
   top: 628.5,
   left: 105.818,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 105", 
   isText: false,
   type: "checkbox",
   top: 628.5,
   left: 158.676,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 106", 
   isText: false,
   type: "checkbox",
   top: 628.5,
   left: 220.75,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 282", 
   isText: false,
   type: "checkbox",
   top: 627.75,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 289", 
   isText: false,
   type: "checkbox",
   top: 627.75,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 296", 
   isText: false,
   type: "checkbox",
   top: 627.75,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 303", 
   isText: false,
   type: "checkbox",
   top: 627.75,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 102", 
   isText: false,
   type: "checkbox",
   top: 643.75,
   left: 116.034,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 103", 
   isText: false,
   type: "checkbox",
   top: 643.75,
   left: 172.892,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 284", 
   isText: false,
   type: "checkbox",
   top: 643.0,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 291", 
   isText: false,
   type: "checkbox",
   top: 643.0,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 298", 
   isText: false,
   type: "checkbox",
   top: 643.0,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 305", 
   isText: false,
   type: "checkbox",
   top: 643.0,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 279", 
   isText: false,
   type: "checkbox",
   top: 658.25,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 286", 
   isText: false,
   type: "checkbox",
   top: 658.25,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 293", 
   isText: false,
   type: "checkbox",
   top: 658.25,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 300", 
   isText: false,
   type: "checkbox",
   top: 658.25,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 281", 
   isText: false,
   type: "checkbox",
   top: 673.5,
   left: 368.823,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 288", 
   isText: false,
   type: "checkbox",
   top: 673.5,
   left: 426.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 295", 
   isText: false,
   type: "checkbox",
   top: 673.5,
   left: 483.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 302", 
   isText: false,
   type: "checkbox",
   top: 673.5,
   left: 540.75,
   width: 14.359,
   height: 11.323
}
,
{
   page: 0,
   name: "Check Box 283", 
   isText: false,
   type: "checkbox",
   top: 688.75,
   left: 368.823,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 290", 
   isText: false,
   type: "checkbox",
   top: 688.75,
   left: 426.75,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 297", 
   isText: false,
   type: "checkbox",
   top: 688.75,
   left: 483.75,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 304", 
   isText: false,
   type: "checkbox",
   top: 688.75,
   left: 540.75,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 285", 
   isText: false,
   type: "checkbox",
   top: 704.0,
   left: 368.823,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 292", 
   isText: false,
   type: "checkbox",
   top: 704.0,
   left: 426.75,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 299", 
   isText: false,
   type: "checkbox",
   top: 704.0,
   left: 483.75,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 306", 
   isText: false,
   type: "checkbox",
   top: 704.0,
   left: 540.75,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 99", 
   isText: false,
   type: "checkbox",
   top: 720.125,
   left: 118.25,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 100", 
   isText: false,
   type: "checkbox",
   top: 720.125,
   left: 168.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 101", 
   isText: false,
   type: "checkbox",
   top: 720.125,
   left: 216.75,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 1021", 
   isText: false,
   type: "checkbox",
   top: 720.125,
   left: 278.25,
   width: 9.216,
   height: 9.396
}
,
{
   page: 0,
   name: "Check Box 307", 
   isText: false,
   type: "checkbox",
   top: 719.25,
   left: 368.823,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 308", 
   isText: false,
   type: "checkbox",
   top: 719.25,
   left: 426.75,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 309", 
   isText: false,
   type: "checkbox",
   top: 719.25,
   left: 483.75,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 0,
   name: "Check Box 3010", 
   isText: false,
   type: "checkbox",
   top: 719.25,
   left: 540.75,
   width: 14.359,
   height: 11.3227
}
,
{
   page: 1,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 21.526,
   left: 212.17,
   width: 356.83,
   height: 11.000
}
,
{
   page: 1,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 33.852,
   left: 43.0,
   width: 526.0,
   height: 11.000
}
,
{
   page: 1,
   name: "Check Box 310", 
   isText: false,
   type: "checkbox",
   top: 82.5,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 318", 
   isText: false,
   type: "checkbox",
   top: 82.5,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 417", 
   isText: false,
   type: "checkbox",
   top: 82.5,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 429", 
   isText: false,
   type: "checkbox",
   top: 82.5,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 312", 
   isText: false,
   type: "checkbox",
   top: 97.75,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 320", 
   isText: false,
   type: "checkbox",
   top: 97.75,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 419", 
   isText: false,
   type: "checkbox",
   top: 97.75,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 431", 
   isText: false,
   type: "checkbox",
   top: 97.75,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 314", 
   isText: false,
   type: "checkbox",
   top: 113.0,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 322", 
   isText: false,
   type: "checkbox",
   top: 113.0,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 422", 
   isText: false,
   type: "checkbox",
   top: 113.0,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 434", 
   isText: false,
   type: "checkbox",
   top: 113.0,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 316", 
   isText: false,
   type: "checkbox",
   top: 128.25,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 324", 
   isText: false,
   type: "checkbox",
   top: 128.25,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 425", 
   isText: false,
   type: "checkbox",
   top: 128.25,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 437", 
   isText: false,
   type: "checkbox",
   top: 128.25,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 441", 
   isText: false,
   type: "checkbox",
   top: 143.608,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 449", 
   isText: false,
   type: "checkbox",
   top: 143.608,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 442", 
   isText: false,
   type: "checkbox",
   top: 143.608,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 450", 
   isText: false,
   type: "checkbox",
   top: 143.608,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 443", 
   isText: false,
   type: "checkbox",
   top: 158.858,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 451", 
   isText: false,
   type: "checkbox",
   top: 158.858,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 444", 
   isText: false,
   type: "checkbox",
   top: 158.858,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 452", 
   isText: false,
   type: "checkbox",
   top: 158.858,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 445", 
   isText: false,
   type: "checkbox",
   top: 174.108,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 453", 
   isText: false,
   type: "checkbox",
   top: 174.108,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 446", 
   isText: false,
   type: "checkbox",
   top: 174.108,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 454", 
   isText: false,
   type: "checkbox",
   top: 174.108,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 447", 
   isText: false,
   type: "checkbox",
   top: 189.358,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 455", 
   isText: false,
   type: "checkbox",
   top: 189.358,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 448", 
   isText: false,
   type: "checkbox",
   top: 189.358,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 456", 
   isText: false,
   type: "checkbox",
   top: 189.358,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 457", 
   isText: false,
   type: "checkbox",
   top: 204.608,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 465", 
   isText: false,
   type: "checkbox",
   top: 204.608,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 458", 
   isText: false,
   type: "checkbox",
   top: 204.608,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 466", 
   isText: false,
   type: "checkbox",
   top: 204.608,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 459", 
   isText: false,
   type: "checkbox",
   top: 219.858,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 467", 
   isText: false,
   type: "checkbox",
   top: 219.858,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 460", 
   isText: false,
   type: "checkbox",
   top: 219.858,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 468", 
   isText: false,
   type: "checkbox",
   top: 219.858,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 211", 
   isText: false,
   type: "checkbox",
   top: 235.03,
   left: 154.142,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 210", 
   isText: false,
   type: "checkbox",
   top: 235.03,
   left: 198.875,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 214", 
   isText: false,
   type: "checkbox",
   top: 235.03,
   left: 242.608,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 461", 
   isText: false,
   type: "checkbox",
   top: 235.108,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 469", 
   isText: false,
   type: "checkbox",
   top: 235.108,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 462", 
   isText: false,
   type: "checkbox",
   top: 235.108,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 470", 
   isText: false,
   type: "checkbox",
   top: 235.108,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 155", 
   isText: false,
   type: "checkbox",
   top: 250.875,
   left: 154.142,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 156", 
   isText: false,
   type: "checkbox",
   top: 250.875,
   left: 198.875,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 157", 
   isText: false,
   type: "checkbox",
   top: 250.875,
   left: 242.608,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 463", 
   isText: false,
   type: "checkbox",
   top: 250.358,
   left: 369.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 471", 
   isText: false,
   type: "checkbox",
   top: 250.358,
   left: 426.75,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 464", 
   isText: false,
   type: "checkbox",
   top: 250.358,
   left: 484.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Check Box 472", 
   isText: false,
   type: "checkbox",
   top: 250.358,
   left: 541.395,
   width: 14.359,
   height: 11.977
}
,
{
   page: 1,
   name: "Text Field 61", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 289.502,
   left: 36.3226,
   width: 540.0004,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 303.002,
   left: 36.3226,
   width: 540.0004,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 54", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 316.502,
   left: 36.3226,
   width: 540.0004,
   height: 13.500
}
,
{
   page: 1,
   name: "Check Box 188", 
   isText: false,
   type: "checkbox",
   top: 357.172,
   left: 202.0,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 189", 
   isText: false,
   type: "checkbox",
   top: 357.172,
   left: 267.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 190", 
   isText: false,
   type: "checkbox",
   top: 357.172,
   left: 340.0,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 191", 
   isText: false,
   type: "checkbox",
   top: 357.172,
   left: 398.75,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 358", 
   isText: false,
   type: "checkbox",
   top: 357.172,
   left: 443.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 192", 
   isText: false,
   type: "checkbox",
   top: 368.87,
   left: 52.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 193", 
   isText: false,
   type: "checkbox",
   top: 368.87,
   left: 112.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 194", 
   isText: false,
   type: "checkbox",
   top: 368.87,
   left: 218.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 195", 
   isText: false,
   type: "checkbox",
   top: 368.87,
   left: 261.25,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 473", 
   isText: false,
   type: "checkbox",
   top: 368.87,
   left: 321.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 361", 
   isText: false,
   type: "checkbox",
   top: 360.517,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 362", 
   isText: false,
   type: "checkbox",
   top: 360.517,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 359", 
   isText: false,
   type: "checkbox",
   top: 386.926,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 360", 
   isText: false,
   type: "checkbox",
   top: 386.926,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 197", 
   isText: false,
   type: "checkbox",
   top: 396.354,
   left: 137.108,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 198", 
   isText: false,
   type: "checkbox",
   top: 396.354,
   left: 179.75,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Check Box 474", 
   isText: false,
   type: "checkbox",
   top: 396.354,
   left: 218.0,
   width: 9.216,
   height: 9.396
}
,
{
   page: 1,
   name: "Text Field 28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 425.532,
   left: 255.5,
   width: 217.5,
   height: 13.500
}
,
{
   page: 1,
   name: "Check Box 363", 
   isText: false,
   type: "checkbox",
   top: 444.032,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 364", 
   isText: false,
   type: "checkbox",
   top: 444.032,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 379", 
   isText: false,
   type: "checkbox",
   top: 468.787,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 380", 
   isText: false,
   type: "checkbox",
   top: 468.787,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 381", 
   isText: false,
   type: "checkbox",
   top: 484.639,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 382", 
   isText: false,
   type: "checkbox",
   top: 484.639,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 365", 
   isText: false,
   type: "checkbox",
   top: 503.139,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 366", 
   isText: false,
   type: "checkbox",
   top: 503.139,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 367", 
   isText: false,
   type: "checkbox",
   top: 528.893,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 368", 
   isText: false,
   type: "checkbox",
   top: 528.893,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 383", 
   isText: false,
   type: "checkbox",
   top: 553.853,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 384", 
   isText: false,
   type: "checkbox",
   top: 553.853,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 369", 
   isText: false,
   type: "checkbox",
   top: 573.353,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 370", 
   isText: false,
   type: "checkbox",
   top: 573.353,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 385", 
   isText: false,
   type: "checkbox",
   top: 612.413,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 386", 
   isText: false,
   type: "checkbox",
   top: 612.413,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 387", 
   isText: false,
   type: "checkbox",
   top: 627.913,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 388", 
   isText: false,
   type: "checkbox",
   top: 627.913,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 389", 
   isText: false,
   type: "checkbox",
   top: 643.413,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 390", 
   isText: false,
   type: "checkbox",
   top: 643.413,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 371", 
   isText: false,
   type: "checkbox",
   top: 661.8,
   left: 524.059,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 372", 
   isText: false,
   type: "checkbox",
   top: 661.8,
   left: 552.52,
   width: 12.416,
   height: 11.354
}
,
{
   page: 1,
   name: "Check Box 373", 
   isText: false,
   type: "checkbox",
   top: 689.161,
   left: 524.059,
   width: 12.416,
   height: 11.3539
}
,
{
   page: 1,
   name: "Check Box 374", 
   isText: false,
   type: "checkbox",
   top: 689.161,
   left: 552.52,
   width: 12.416,
   height: 11.3539
}
,
{
   page: 2,
   name: "Text Field 66", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 21.526,
   left: 212.17,
   width: 356.83,
   height: 11.000
}
,
{
   page: 2,
   name: "Text Field 67", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 33.852,
   left: 43.0,
   width: 526.0,
   height: 11.000
}
,
{
   page: 2,
   name: "Text Field 29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 70.854,
   left: 248.5,
   width: 55.0,
   height: 13.500
}
,
{
   page: 2,
   name: "Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 84.302,
   left: 176.0,
   width: 53.0,
   height: 13.500
}
,
{
   page: 2,
   name: "Check Box 391", 
   isText: false,
   type: "checkbox",
   top: 98.802,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 392", 
   isText: false,
   type: "checkbox",
   top: 98.802,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 395", 
   isText: false,
   type: "checkbox",
   top: 114.25,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 396", 
   isText: false,
   type: "checkbox",
   top: 114.25,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 128.672,
   left: 435.75,
   width: 53.00,
   height: 13.500
}
,
{
   page: 2,
   name: "Check Box 397", 
   isText: false,
   type: "checkbox",
   top: 129.698,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 398", 
   isText: false,
   type: "checkbox",
   top: 129.698,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 399", 
   isText: false,
   type: "checkbox",
   top: 145.146,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 401", 
   isText: false,
   type: "checkbox",
   top: 145.146,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 400", 
   isText: false,
   type: "checkbox",
   top: 160.594,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 402", 
   isText: false,
   type: "checkbox",
   top: 160.594,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 407_0", 
   isText: false,
   type: "checkbox",
   top: 178.905,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 408_0", 
   isText: false,
   type: "checkbox",
   top: 178.905,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 403", 
   isText: false,
   type: "checkbox",
   top: 201.853,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 404", 
   isText: false,
   type: "checkbox",
   top: 201.853,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 409_0", 
   isText: false,
   type: "checkbox",
   top: 233.0,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4010", 
   isText: false,
   type: "checkbox",
   top: 233.0,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4011", 
   isText: false,
   type: "checkbox",
   top: 247.811,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4012", 
   isText: false,
   type: "checkbox",
   top: 247.811,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4013", 
   isText: false,
   type: "checkbox",
   top: 264.259,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4014", 
   isText: false,
   type: "checkbox",
   top: 264.259,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4015", 
   isText: false,
   type: "checkbox",
   top: 279.707,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4016", 
   isText: false,
   type: "checkbox",
   top: 279.707,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4017", 
   isText: false,
   type: "checkbox",
   top: 295.155,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4018", 
   isText: false,
   type: "checkbox",
   top: 295.155,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4019", 
   isText: false,
   type: "checkbox",
   top: 310.603,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4020", 
   isText: false,
   type: "checkbox",
   top: 310.603,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4021", 
   isText: false,
   type: "checkbox",
   top: 326.051,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4022", 
   isText: false,
   type: "checkbox",
   top: 326.051,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4033", 
   isText: false,
   type: "checkbox",
   top: 342.499,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4034", 
   isText: false,
   type: "checkbox",
   top: 342.499,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4023", 
   isText: false,
   type: "checkbox",
   top: 367.845,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4027", 
   isText: false,
   type: "checkbox",
   top: 367.845,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4024", 
   isText: false,
   type: "checkbox",
   top: 383.293,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4028", 
   isText: false,
   type: "checkbox",
   top: 383.293,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4025", 
   isText: false,
   type: "checkbox",
   top: 398.741,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4029", 
   isText: false,
   type: "checkbox",
   top: 398.741,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4026", 
   isText: false,
   type: "checkbox",
   top: 414.189,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4030", 
   isText: false,
   type: "checkbox",
   top: 414.189,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4031", 
   isText: false,
   type: "checkbox",
   top: 429.637,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4032", 
   isText: false,
   type: "checkbox",
   top: 429.637,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4035", 
   isText: false,
   type: "checkbox",
   top: 446.358,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4036", 
   isText: false,
   type: "checkbox",
   top: 446.358,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 2045", 
   isText: false,
   type: "checkbox",
   top: 456.832,
   left: 337.108,
   width: 9.216,
   height: 9.396
}
,
{
   page: 2,
   name: "Check Box 2046", 
   isText: false,
   type: "checkbox",
   top: 456.832,
   left: 378.108,
   width: 9.216,
   height: 9.396
}
,
{
   page: 2,
   name: "Check Box 4037", 
   isText: false,
   type: "checkbox",
   top: 488.872,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4038", 
   isText: false,
   type: "checkbox",
   top: 488.872,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4039", 
   isText: false,
   type: "checkbox",
   top: 515.82,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4040", 
   isText: false,
   type: "checkbox",
   top: 515.82,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4041", 
   isText: false,
   type: "checkbox",
   top: 540.768,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4042", 
   isText: false,
   type: "checkbox",
   top: 540.768,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Text Field 32", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 567.845,
   left: 137.0,
   width: 53.0,
   height: 13.500
}
,
{
   page: 2,
   name: "Text Field 33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 567.845,
   left: 293.108,
   width: 55.000,
   height: 13.500
}
,
{
   page: 2,
   name: "Check Box 4049", 
   isText: false,
   type: "checkbox",
   top: 563.216,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4050", 
   isText: false,
   type: "checkbox",
   top: 563.216,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 2061", 
   isText: false,
   type: "checkbox",
   top: 582.147,
   left: 164.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 2,
   name: "Check Box 2062", 
   isText: false,
   type: "checkbox",
   top: 582.147,
   left: 224.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 2,
   name: "Check Box 2063", 
   isText: false,
   type: "checkbox",
   top: 582.147,
   left: 287.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 2,
   name: "Check Box 2064", 
   isText: false,
   type: "checkbox",
   top: 594.432,
   left: 318.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 2,
   name: "Check Box 2065", 
   isText: false,
   type: "checkbox",
   top: 594.432,
   left: 363.0,
   width: 9.216,
   height: 9.396
}
,
{
   page: 2,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 603.828,
   left: 186.0,
   width: 53.0,
   height: 13.500
}
,
{
   page: 2,
   name: "Text Field 35", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 603.828,
   left: 320.108,
   width: 126.392,
   height: 13.500
}
,
{
   page: 2,
   name: "Text Field 36", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 615.916,
   left: 312.0,
   width: 126.392,
   height: 13.500
}
,
{
   page: 2,
   name: "Check Box 4043", 
   isText: false,
   type: "checkbox",
   top: 631.416,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4044", 
   isText: false,
   type: "checkbox",
   top: 631.416,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4045", 
   isText: false,
   type: "checkbox",
   top: 648.864,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4046", 
   isText: false,
   type: "checkbox",
   top: 648.864,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4047", 
   isText: false,
   type: "checkbox",
   top: 676.739,
   left: 526.75,
   width: 11.107,
   height: 12.175
}
,
{
   page: 2,
   name: "Check Box 4048", 
   isText: false,
   type: "checkbox",
   top: 676.739,
   left: 553.875,
   width: 11.107,
   height: 12.175
}
,
{
   page: 3,
   name: "Text Field 68", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 21.526,
   left: 212.17,
   width: 356.83,
   height: 11.000
}
,
{
   page: 3,
   name: "Text Field 69", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 33.852,
   left: 43.0,
   width: 526.0,
   height: 11.000
}
,
{
   page: 3,
   name: "Check Box 407_1", 
   isText: false,
   type: "checkbox",
   top: 74.25,
   left: 525.375,
   width: 12.416,
   height: 11.893
}
,
{
   page: 3,
   name: "Check Box 408_1", 
   isText: false,
   type: "checkbox",
   top: 74.25,
   left: 553.5,
   width: 12.416,
   height: 11.893
}
,
{
   page: 3,
   name: "Check Box 409_1", 
   isText: false,
   type: "checkbox",
   top: 102.198,
   left: 525.375,
   width: 12.416,
   height: 11.893
}
,
{
   page: 3,
   name: "Check Box 410", 
   isText: false,
   type: "checkbox",
   top: 102.198,
   left: 553.5,
   width: 12.416,
   height: 11.893
}
,
{
   page: 3,
   name: "Text Field 37", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 111.0,
   left: 157.608,
   width: 54.562,
   height: 13.5
}
,
{
   page: 3,
   name: "Text Field 38", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 111.0,
   left: 279.0,
   width: 187.5,
   height: 13.5
}
,
{
   page: 3,
   name: "Check Box 2076", 
   isText: false,
   type: "checkbox",
   top: 125.0,
   left: 164.784,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 2077", 
   isText: false,
   type: "checkbox",
   top: 125.0,
   left: 225.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 2078", 
   isText: false,
   type: "checkbox",
   top: 125.0,
   left: 288.5,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 2079", 
   isText: false,
   type: "checkbox",
   top: 151.635,
   left: 147.784,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 2080", 
   isText: false,
   type: "checkbox",
   top: 151.635,
   left: 198.784,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 2081", 
   isText: false,
   type: "checkbox",
   top: 151.635,
   left: 261.784,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 2082", 
   isText: false,
   type: "checkbox",
   top: 151.635,
   left: 315.0,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 411", 
   isText: false,
   type: "checkbox",
   top: 144.182,
   left: 525.375,
   width: 12.416,
   height: 11.893
}
,
{
   page: 3,
   name: "Check Box 412", 
   isText: false,
   type: "checkbox",
   top: 144.182,
   left: 553.5,
   width: 12.416,
   height: 11.893
}
,
{
   page: 3,
   name: "Text Field 39", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.031,
   left: 127.0,
   width: 339.5,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 40", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 174.0,
   left: 164.5,
   width: 54.562,
   height: 13.5
}
,
{
   page: 3,
   name: "Text Field 41", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 174.0,
   left: 336.023,
   width: 54.562,
   height: 13.5
}
,
{
   page: 3,
   name: "Check Box 415", 
   isText: false,
   type: "checkbox",
   top: 218.0,
   left: 525.375,
   width: 12.416,
   height: 11.893
}
,
{
   page: 3,
   name: "Check Box 416", 
   isText: false,
   type: "checkbox",
   top: 218.0,
   left: 553.5,
   width: 12.416,
   height: 11.893
}
,
{
   page: 3,
   name: "Check Box 413", 
   isText: false,
   type: "checkbox",
   top: 237.366,
   left: 525.375,
   width: 12.416,
   height: 11.893
}
,
{
   page: 3,
   name: "Check Box 414", 
   isText: false,
   type: "checkbox",
   top: 237.366,
   left: 553.5,
   width: 12.416,
   height: 11.893
}
,
{
   page: 3,
   name: "Text Field 42", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 308.756,
   left: 36.0,
   width: 540.0,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 43", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 332.64,
   left: 36.0,
   width: 540.0,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 59", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 356.524,
   left: 36.0,
   width: 540.0,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 60", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 380.408,
   left: 36.0,
   width: 540.0,
   height: 13.500
}
,
{
   page: 3,
   name: "Check Box 2084", 
   isText: false,
   type: "checkbox",
   top: 444.104,
   left: 283.614,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 2083", 
   isText: false,
   type: "checkbox",
   top: 444.104,
   left: 329.0,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Text Field 44", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 441.0,
   left: 452.5,
   width: 26.0,
   height: 13.5
}
] }
