export function wireAdvisory() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Property Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 75.88,
   left: 162.72,
   width: 308.16,
   height: 13.50
}
] }
