import { createSlice } from "@reduxjs/toolkit";
import { abbreviateDirection } from "../../app/common/util/util";
import _ from "lodash";

const initialState = {
  currentUserProfile: null,
  agentUserProfile: null,
  currentUserIpAddress: null,
  agentsForManagerActive: { agents: [], column: "", direction: "" },
  agentsForManagerAll: { agents: [], column: "", direction: "" },
  agentsForAssistant: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    listenToCurrentUserProfile(state, action) {
      state.currentUserProfile = action.payload;
    },
    getCurrentUserIpAddress(state, action) {
      state.currentUserIpAddress = action.payload;
    },
    fetchAgentUserProfile(state, action) {
      state.agentUserProfile = action.payload;
    },
    resetAgentUserProfile(state, action) {
      state.agentUserProfile = null;
    },
    fetchAgentsForAssistant(state, action) {
      state.agentsForAssistant = _.sortBy(action.payload, "firstName");
    },
    fetchAgentsForManager(state, action) {
      if (
        state.agentsForManagerActive.column &&
        state.agentsForManagerActive.direction
      ) {
        state.agentsForManagerActive.agents = _.orderBy(
          action.payload.agents.filter(
            (agents) =>
              agents.managerActive !== false &&
              agents.managerId === action.payload.managerId
          ),
          state.agentsForManagerActive.column,
          abbreviateDirection(state.agentsForManagerActive.direction)
        );
      } else {
        state.agentsForManagerActive.agents = action.payload.agents.filter(
          (agents) =>
            agents.managerActive !== false &&
            agents.managerId === action.payload.managerId
        );
      }
      if (
        state.agentsForManagerAll.column &&
        state.agentsForManagerAll.direction
      ) {
        state.agentsForManagerAll.agents = _.orderBy(
          action.payload.agents,
          state.agentsForManagerAll.column,
          abbreviateDirection(state.agentsForManagerAll.direction)
        );
      } else {
        state.agentsForManagerAll.agents = action.payload.agents;
      }
    },
    sortAgentsForManagerActive(state, action) {
      if (state.agentsForManagerActive.column === action.payload) {
        state.agentsForManagerActive = {
          tasks: state.agentsForManagerActive.agents.slice().reverse(),
          column: action.payload,
          direction:
            state.agentsForManagerActive.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.agentsForManagerActive = {
          tasks: _.sortBy(state.tagentsForManagerActive.agents, [
            action.payload,
          ]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    sortAgentsForManagerAll(state, action) {
      if (state.agentsForManagerAll.column === action.payload) {
        state.agentsForManagerAll = {
          tasks: state.agentsForManagerAll.agents.slice().reverse(),
          column: action.payload,
          direction:
            state.agentsForManagerAll.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.agentsForManagerAll = {
          tasks: _.sortBy(state.tagentsForManagerAll.agents, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
  },
});

export const {
  listenToCurrentUserProfile,
  getCurrentUserIpAddress,
  fetchAgentUserProfile,
  resetAgentUserProfile,
  fetchAgentsForAssistant,
  fetchAgentsForManager,
  sortAgentsForManagerActive,
  sortAgentsForManagerAll,
} = profileSlice.actions;

export default profileSlice.reducer;
