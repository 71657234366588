export function additionalDesignatedBrokerDC616() {
return [   //2024 Release 2024-09-17 18:20:45
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 47.52,
   left: 283.08,
   width: 159.36,
   height: 11.40
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 174.28,
   left: 252.0,
   width: 280.92,
   height: 15.44
}
,
{
   page: 0,
   name: "2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 203.56,
   left: 252.0,
   width: 280.92,
   height: 15.44
}
,
{
   page: 0,
   name: "3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 232.84,
   left: 252.0,
   width: 280.92,
   height: 15.44
}
,
{
   page: 0,
   name: "Additional Designated Broker 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 262.12,
   left: 252.0,
   width: 280.92,
   height: 15.44
}
,
{
   page: 0,
   name: "Date Effective", 
   fontSize: 9,
   type: "date",
   top: 291.64,
   left: 252.0,
   width: 280.92,
   height: 15.44
}
,
{
   page: 0,
   name: "signature Designated Broker", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 496.72,
   left: 72.0,
   width: 216.12,
   height: 15.44
}
,
{
   page: 0,
   name: "signature Additional Designated Broker", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 496.72,
   left: 324.0,
   width: 216.12,
   height: 15.44
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 728.2909,
   left: 411.96,
   width: 125.52,
   height: 15.3491
}
] }
