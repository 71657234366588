import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useLocation, useParams } from "react-router-dom";
import { DocFillDashboard } from "../docComponents/docFill/DocFillDashboard";
import { DocPrepareDashboard } from "../docComponents/docPrepare/DocPrepareDashboard";
import { DocViewDashboard } from "../docComponents/docView/DocViewDashboard";
import { getDocAndUrl } from "../docSlice";
import { getDocDownloadUrl } from "../../../app/firestore/firebaseService";
import { clearAnnots, fetchAnnots } from "../../../app/annots/annotSlice";

export default function DocEditPage() {
  const dispatch = useDispatch();
  const { docsTrans, docsTransShared, doc, docUrl } = useSelector(
    (state) => state.doc
  );
  let { docId } = useParams();
  const location = useLocation();
  const lastPath = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    if (docsTrans?.length > 0 || docsTransShared?.docs?.length > 0) {
      const allDocs = [...docsTrans, ...docsTransShared?.docs];
      const newDoc = allDocs.filter((doc) => doc.id === docId)[0];
      if (newDoc) {
        getDocDownloadUrl(newDoc.docRef).then((url) => {
          dispatch(getDocAndUrl({ doc: newDoc, docUrl: url }));
        });
      }
    }
  }, [doc.docRef, doc.formFieldValues, docsTrans, docsTransShared.docs, docId, dispatch]);

  useEffect(() => {
    if (doc.annotsInProgress) {
      dispatch(fetchAnnots(doc.annotsInProgress));
    } else {
      dispatch(clearAnnots());
    }
  }, [doc.annotsInProgress, dispatch]);

  // doc.id !== docId is for switching documents and making sure we
  // have new document loaded in store
  if (!doc.id || !doc.docRef || !lastPath || doc.id !== docId || !docUrl) {
    return <LoadingComponent />;
  }

  return (
    <>
      {lastPath === "fill" && <DocFillDashboard />}
      {lastPath === "prepare" && <DocPrepareDashboard />}
      {lastPath === "view" && <DocViewDashboard />}
    </>
  );
}
