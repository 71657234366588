export function terminationAgreementSellerDC523() {
return [   //2024 Release 2024-09-17 18:40:59
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "THIS FORM HAS NOT BEEN APPROVED BY THE COLORADO REAL ESTATE COMMISSION IT WAS PREPARED BY", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 49.92,
   left: 324.72,
   width: 203.28,
   height: 13.56
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 130.04,
   left: 216.0,
   width: 159.36,
   height: 14.44
}
,
{
   page: 0,
   name: "SELLER LISTING CONTRACT TERMINATION AGREEMENT 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 153.32,
   left: 216.0,
   width: 318.24,
   height: 14.44
}
,
{
   page: 0,
   name: "SELLER LISTING CONTRACT TERMINATION AGREEMENT 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 176.6,
   left: 216.0,
   width: 318.24,
   height: 14.2
}
,
{
   page: 0,
   name: "SELLER LISTING CONTRACT TERMINATION AGREEMENT 4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 199.64,
   left: 216.0,
   width: 318.24,
   height: 14.44
}
,
{
   page: 0,
   name: "Seller Listing Contract Date 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 222.92,
   left: 216.0,
   width: 318.24,
   height: 14.20
}
,
{
   page: 0,
   name: "Seller Listing Contract Date 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 245.96,
   left: 216.0,
   width: 318.24,
   height: 14.44
}
,
{
   page: 0,
   name: "signature BROKERAGE FIRM AND BROKER", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 620.04,
   left: 324.0,
   width: 213.6,
   height: 15.44
}
,
{
   page: 0,
   name: "signature CoBroker", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 673.56,
   left: 324.0,
   width: 213.6,
   height: 15.44
}
,
{
   page: 0,
   name: "use by", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 729.7092,
   left: 426.48,
   width: 118.276,
   height: 13.9308
}
] }
