export function contracttoBuyandSellIncomeResidential2023() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 206,
      height: 35,
    }
    ,
   {
      page: 0,
      name: "14 Units", 
      isText: false,
      type: "checkbox",
      top: 159.48,
      left: 250.44,
      width: 11.04,
      height: 10.92
   }
   ,
   {
      page: 0,
      name: "Larger than 14 Units", 
      isText: false,
      type: "checkbox",
      top: 159.48,
      left: 325.56,
      width: 10.92,
      height: 10.92
   }
   ,
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 184.2,
      left: 462,
      width: 118.08,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "Buyer", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 279.72,
      left: 114,
      width: 380.16,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "Joint Tenants", 
      isText: false,
      type: "checkbox",
      top: 295.68,
      left: 209.76,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 0,
      name: "Tenants In Common", 
      isText: false,
      type: "checkbox",
      top: 295.68,
      left: 291.48,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 0,
      name: "cb_buyerOther", 
      isText: false,
      type: "checkbox",
      top: 295.68,
      left: 402.6,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 0,
      name: "tf_OtherBuyer", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 293.04,
      left: 446.64,
      width: 130.08,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "Seller", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 325.68,
      left: 112.32,
      width: 374.52,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "tf_county", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 352.8,
      left: 417.72,
      width: 157.08,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "tf_knowsAsAddr", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 426.24,
      left: 123.24,
      width: 456.72,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "tf_includingRemotes", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 552.84,
      left: 109.32,
      width: 73.20,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "Solar Panels", 
      isText: false,
      type: "checkbox",
      top: 568.8,
      left: 66.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 0,
      name: "Water Softeners", 
      isText: false,
      type: "checkbox",
      top: 568.8,
      left: 142.92,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 0,
      name: "Security Systems", 
      isText: false,
      type: "checkbox",
      top: 568.8,
      left: 240,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 0,
      name: "Satellite Systems including satellite dishes Leased", 
      isText: false,
      type: "checkbox",
      top: 568.8,
      left: 336.24,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 0,
      name: "ta_propertyLegal2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 379.432,
      left: 65.5137,
      width: 512.0743,
      height: 44.702
   }
   ,
   {
      page: 0,
      name: "ta_otherInclusionsz", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 677.037,
      left: 61.4953,
      width: 512.0737,
      height: 73.9339
   }
   ,
   {
      page: 1,
      name: "If the box is checked Buyer and Seller have concurrently entered into a separate agreement for additional personal property", 
      isText: false,
      type: "checkbox",
      top: 56.64,
      left: 73.68,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 1,
      name: "undefined_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 221.76,
      left: 65.76,
      width: 489.48,
      height: 10.56
   }
   ,
   {
      page: 1,
      name: "undefined_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 248.28,
      left: 65.76,
      width: 489.48,
      height: 10.56
   }
   ,
   {
      page: 1,
      name: "Water RightsWell Rights", 
      isText: false,
      type: "checkbox",
      top: 477,
      left: 80.88,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 1,
      name: "Deeded Water Rights The following legally described water rights", 
      isText: false,
      type: "checkbox",
      top: 492.48,
      left: 103.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 1,
      name: "deed at Closing", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 560.836,
      left: 335.4,
      width: 176.76,
      height: 11.804
   }
   ,
   {
      page: 1,
      name: "Other Rights Relating to Water The following rights relating to water not included in  271 273 and 274", 
      isText: false,
      type: "checkbox",
      top: 578.52,
      left: 103.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 1,
      name: "Well Rights Seller agrees to supply required information to Buyer about the well Buyer understands that if the", 
      isText: false,
      type: "checkbox",
      top: 666.84,
      left: 103.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 1,
      name: "undefined_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 734.88,
      left: 152.28,
      width: 155.40,
      height: 10.56
   }
   ,
   {
      page: 1,
      name: "ta_encumberedInclusionsz", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 116.656,
      left: 60.849,
      width: 512.074,
      height: 65.848
   }
   ,
   {
      page: 1,
      name: "ta_leasedItems", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 303.072,
      left: 62.544,
      width: 512.074,
      height: 69.580
   }
   ,
   {
      page: 1,
      name: "ta_exclusions2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 385.621,
      left: 64.2391,
      width: 512.0739,
      height: 87.616
   }
   ,
   {
      page: 1,
      name: "ta_deededWaterRights", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 504.865,
      left: 66.5561,
      width: 512.0739,
      height: 54.031
   }
   ,
   {
      page: 1,
      name: "ta_otherRightsRelatingWater", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 601.781,
      left: 67.6905,
      width: 512.0745,
      height: 62.738
   }
   ,
   {
      page: 2,
      name: "Water Stock Certificates The water stock certificates to be transferred at Closing are as follows", 
      isText: false,
      type: "checkbox",
      top: 56.88,
      left: 103.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 2,
      name: "Conveyance If Buyer is to receive any rights to water pursuant to  272 Other Rights Relating to Water", 
      isText: false,
      type: "checkbox",
      top: 114.36,
      left: 103.44,
      width: 9.72,
      height: 9.84
   }
   ,
   {
      page: 2,
      name: "Water Rights Review Buyer", 
      isText: false,
      type: "checkbox",
      top: 160.68,
      left: 103.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 2,
      name: "Does", 
      isText: false,
      type: "checkbox",
      top: 160.68,
      left: 247.68,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 2,
      name: "Does Not have a Right to Terminate if examination of the", 
      isText: false,
      type: "checkbox",
      top: 160.68,
      left: 291.48,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsTime of Day DL", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 250.08,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Date Deadline DetailsAlternative Earnest Money Deadline", 
      fontSize: 9,
      type: "date",
      top: 262.08,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsAlternative Earnest Money Deadline_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 262.08,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "tf_offRecTitle", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 310.775,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_titleob", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 322.592,
      left: 432.0,
      width: 138.48,
      height: 11.041
   }
   ,
   {
      page: 2,
      name: "Date Deadline DetailsRecord Title Deadline and Tax Certificate", 
      fontSize: 9,
      type: "date",
      top: 286.08,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsRecord Title Deadline and Tax Certificate", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 286.08,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Date Deadline DetailsRecord Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 298.08,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Deadline DetailsRecord Title Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 298.08,
      left: 432.0,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Date OffRecord Title Deadline", 
      fontSize: 9,
      type: "date",
      top: 310.56,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Date OffRecord Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 322.56,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Date Title Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 334.56,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Date Third Party Right to PurchaseApprove Deadline", 
      fontSize: 9,
      type: "date",
      top: 346.56,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "tf_titleresol", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 334.304,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_thirdparty", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 345.499,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "Date Association Documents Deadline", 
      fontSize: 9,
      type: "date",
      top: 370.56,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Date Association Documents Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 382.56,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "tf_assocDoc", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 370.304,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_assocDocTerm", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 382.121,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "Date Sellers Property Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 406.56,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "Date LeadBased Paint Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 418.56,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "tf_spd", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 406.926,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_lbp", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 418.121,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "Date New Loan Application Deadline", 
      fontSize: 9,
      type: "date",
      top: 442.68,
      left: 355.56,
      width: 75.36,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "Date New Loan Terms Deadline", 
      fontSize: 9,
      type: "date",
      top: 454.68,
      left: 355.56,
      width: 75.36,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "Date New Loan Availability Deadline", 
      fontSize: 9,
      type: "date",
      top: 466.68,
      left: 355.56,
      width: 75.36,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "date Buyers Credit Information Deadline", 
      fontSize: 9,
      type: "date",
      top: 478.68,
      left: 355.56,
      width: 75.36,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "date Disapproval of Buyers Credit Information Deadline", 
      fontSize: 9,
      type: "date",
      top: 490.68,
      left: 355.56,
      width: 75.36,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "date Existing Loan Deadline", 
      fontSize: 9,
      type: "date",
      top: 502.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Existing Loan Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 514.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Loan Transfer Approval Deadline", 
      fontSize: 9,
      type: "date",
      top: 526.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Seller or Private Financing Deadline", 
      fontSize: 9,
      type: "date",
      top: 538.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "tf_newloanappl", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 443.082,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_newloanterm", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 455.521,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "date_Appraisal Deadline", 
      fontSize: 9,
      type: "date",
      top: 562.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Appraisal Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 574.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Appraisal Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 586.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "tf_newloanavail", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 466.448,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_buyercredit", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 478.643,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "date_New ILC or New Survey Deadline", 
      fontSize: 9,
      type: "date",
      top: 610.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_New ILC or New Survey Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 622.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_New ILC or New Survey Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 634.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "tf_disaprcredit", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 490.569,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_existloan", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 502.765,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "date_Water Rights Examination Deadline", 
      fontSize: 9,
      type: "date",
      top: 658.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Mineral Rights Examination Deadline", 
      fontSize: 9,
      type: "date",
      top: 670.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Inspection Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 682.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Inspection Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 694.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Inspection Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 706.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Property Insurance Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 718.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "date_Due Diligence Documents Delivery Deadline", 
      fontSize: 9,
      type: "date",
      top: 730.68,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 2,
      name: "ta_waterStockCerts", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 67.211,
      left: 74.8246,
      width: 501.5004,
      height: 45.945
   }
   ,
   {
      page: 2,
      name: "tf_Existing Loan Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 515.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_Loan Transfer Approval Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 527.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_Seller or Private Financing Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 539.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_Appraisal Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 563.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_Appraisal Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 575.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_Appraisal Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 587.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_New ILC or New Survey Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 611.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_New ILC or New Survey Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 623.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_New ILC or New Survey Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 635.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_Water Rights Examination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 659.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_Mineral Rights Examination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 671.276,
      left: 432.0,
      width: 138.48,
      height: 11.040
   }
   ,
   {
      page: 2,
      name: "tf_Inspection Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 683.276,
      left: 432.0,
      width: 138.48,
      height: 11.0403
   }
   ,
   {
      page: 2,
      name: "tf_Inspection Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 695.2763,
      left: 432.0,
      width: 138.48,
      height: 11.0400
   }
   ,
   {
      page: 2,
      name: "tf_Inspection Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 707.2763,
      left: 432.0,
      width: 138.48,
      height: 11.0400
   }
   ,
   {
      page: 2,
      name: "tf_Property Insurance Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 719.2763,
      left: 432.0,
      width: 138.48,
      height: 11.0400
   }
   ,
   {
      page: 2,
      name: "tf_Due Diligence Documents Delivery Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 731.2763,
      left: 432.0,
      width: 138.48,
      height: 11.0400
   }
   ,
   {
      page: 3,
      name: "DateDue Diligence Documents Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 69.24,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailDue Diligence Documents Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 69.24,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DateDue Diligence Documents Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 81.24,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailDue Diligence Documents Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 81.24,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DateEnvironmental Inspection Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 93.24,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailEnvironmental Inspection Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 93.24,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DateADA Evaluation Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 105.24,
      left: 355.56,
      width: 75.36,
      height: 10.80
   }
   ,
   {
      page: 3,
      name: "Deadline DetailADA Evaluation Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 105.24,
      left: 432,
      width: 138.48,
      height: 10.80
   }
   ,
   {
      page: 3,
      name: "DateConditional Sale Deadline", 
      fontSize: 9,
      type: "date",
      top: 117,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailConditional Sale Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 117,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DateLeadBased Paint Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 129,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailLeadBased Paint Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 129,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DateEstoppel Statements Deadline", 
      fontSize: 9,
      type: "date",
      top: 141,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailEstoppel Statements Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 141,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DateEstoppel Statements Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 153,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailEstoppel Statements Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 153,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DateClosing Date", 
      fontSize: 9,
      type: "date",
      top: 177,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailClosing Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 177,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DatePossession Date", 
      fontSize: 9,
      type: "date",
      top: 189,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailPossession Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 189,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailPossession Time", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 201,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DateAcceptance Deadline Date", 
      fontSize: 9,
      type: "date",
      top: 213,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailAcceptance Deadline Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 213,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailAcceptance Deadline Time", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 225,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "tf_itemnum48", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 237,
      left: 51.36,
      width: 32.88,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "tf27Row1x", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 237,
      left: 85.44,
      width: 48.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Acceptance Deadline TimeRow1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 237,
      left: 134.88,
      width: 219.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DateRow15", 
      fontSize: 9,
      type: "date",
      top: 237,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailRow15", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 237,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "tf_itemrow49", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 249,
      left: 51.36,
      width: 32.88,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "27Row2x", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 249,
      left: 85.44,
      width: 48.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Acceptance Deadline TimeRow2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 249,
      left: 134.88,
      width: 219.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "DateRow16", 
      fontSize: 9,
      type: "date",
      top: 249,
      left: 355.56,
      width: 75.36,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadline DetailRow16", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 249,
      left: 432,
      width: 138.48,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "Deadlines If any deadline falls on a Saturday Sunday or federal or Colorado state holiday Holiday such", 
      isText: false,
      type: "checkbox",
      top: 505.8,
      left: 106.92,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 3,
      name: "Will Not be extended to the next day that is not a Saturday Sunday or Holiday Should neither box be", 
      isText: false,
      type: "checkbox",
      top: 505.8,
      left: 148.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 3,
      name: "fill_58", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 597.24,
      left: 453.24,
      width: 89.40,
      height: 14.16
   }
   ,
   {
      page: 3,
      name: "fill_60", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 612.6,
      left: 453.24,
      width: 89.40,
      height: 14.4
   }
   ,
   {
      page: 3,
      name: "fill_62", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 628.08,
      left: 453.24,
      width: 89.40,
      height: 14.40
   }
   ,
   {
      page: 3,
      name: "fill_64", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 643.56,
      left: 453.24,
      width: 89.40,
      height: 14.40
   }
   ,
   {
      page: 3,
      name: "fill_65", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 659.04,
      left: 453.24,
      width: 89.40,
      height: 14.40
   }
   ,
   {
      page: 3,
      name: "tf477", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 675.64,
      left: 129.84,
      width: 53.28,
      height: 14.694
   }
   ,
   {
      page: 3,
      name: "Seller Financing7", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 675.506,
      left: 184.68,
      width: 156.00,
      height: 14.694
   }
   ,
   {
      page: 3,
      name: "fill_66b", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 674.64,
      left: 352.288,
      width: 90.392,
      height: 15.938
   }
   ,
   {
      page: 3,
      name: "fill_67", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 675.64,
      left: 452.88,
      width: 89.64,
      height: 14.40
   }
   ,
   {
      page: 3,
      name: "tf478", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 693.0219,
      left: 129.84,
      width: 53.28,
      height: 14.0722
   }
   ,
   {
      page: 3,
      name: "Seller Financing8", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 693.2658,
      left: 184.68,
      width: 156.00,
      height: 14.6942
   }
   ,
   {
      page: 3,
      name: "fill_68", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 693.4,
      left: 451.92,
      width: 90.60,
      height: 14.4
   }
   ,
   {
      page: 3,
      name: "fill_69", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 710.04,
      left: 453.24,
      width: 89.40,
      height: 14.16
   }
   ,
   {
      page: 3,
      name: "tf_extra", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 693.5604,
      left: 353.047,
      width: 90.392,
      height: 13.7780
   }
   ,
   {
      page: 3,
      name: "fill_71", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 725.28,
      left: 351.24,
      width: 91.56,
      height: 14.40
   }
   ,
   {
      page: 3,
      name: "fill_72", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 725.28,
      left: 453.24,
      width: 89.40,
      height: 14.40
   }
   ,
   {
      page: 3,
      name: "tf_amountPurchase", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 580.711,
      left: 351.3,
      width: 90.392,
      height: 15.938
   }
   ,
   {
      page: 4,
      name: "Seller Concession At Closing Seller will credit to Buyer", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 54.48,
      left: 341.88,
      width: 125.64,
      height: 12.00
   }
   ,
   {
      page: 4,
      name: "Earnest Money The Earnest Money set forth in this Section in the form of a", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 127.56,
      left: 406.2,
      width: 170.76,
      height: 12.00
   }
   ,
   {
      page: 4,
      name: "will be payable to and held by", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 140.52,
      left: 191.88,
      width: 184.92,
      height: 12.00
   }
   ,
   {
      page: 4,
      name: "are immediately verifiable and available in an amount not less than the amount stated as Cash at Closing in  41", 
      isText: false,
      type: "checkbox",
      top: 532.32,
      left: 414.12,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 4,
      name: "Does Not have funds that", 
      isText: false,
      type: "checkbox",
      top: 532.32,
      left: 457.8,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 4,
      name: "Loan Limitations Buyer may purchase the Property using any of the following types of loans", 
      isText: false,
      type: "checkbox",
      top: 642.12,
      left: 146.16,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 4,
      name: "If either or both of the FHA or VA boxes are checked and Buyer closes the transaction using one of those loan types Seller", 
      isText: false,
      type: "checkbox",
      top: 642.12,
      left: 66.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 4,
      name: "VA", 
      isText: false,
      type: "checkbox",
      top: 642.12,
      left: 190.68,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 4,
      name: "Bond", 
      isText: false,
      type: "checkbox",
      top: 642.12,
      left: 228.72,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 4,
      name: "undefined_5", 
      isText: false,
      type: "checkbox",
      top: 642.12,
      left: 274.8,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 4,
      name: "Other_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 639.72,
      left: 318.6,
      width: 216.36,
      height: 12.00
   }
   ,
   {
      page: 4,
      name: "agrees to pay those closing costs and fees that Buyer is not allowed by law to pay not to exceed", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 666,
      left: 451.2,
      width: 90.24,
      height: 12
   }
   ,
   {
      page: 5,
      name: "in  41 Price and Terms presently payable at", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 54.12,
      left: 261.84,
      width: 62.16,
      height: 12.36
   }
   ,
   {
      page: 5,
      name: "per", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 54.12,
      left: 341.64,
      width: 106.92,
      height: 12.36
   }
   ,
   {
      page: 5,
      name: "presently at the rate of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 67.44,
      left: 157.32,
      width: 51.00,
      height: 12.12
   }
   ,
   {
      page: 5,
      name: "including principal and interest", 
      isText: false,
      type: "checkbox",
      top: 69.96,
      left: 495.84,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 5,
      name: "Property Insurance Premium", 
      isText: false,
      type: "checkbox",
      top: 83.16,
      left: 98.16,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 5,
      name: "per annum and also including escrow for the following as indicated", 
      isText: false,
      type: "checkbox",
      top: 83.16,
      left: 246,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 5,
      name: "At the time of assumption the new interest", 
      isText: false,
      type: "checkbox",
      top: 83.16,
      left: 416.04,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 5,
      name: "undefined_6", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 80.4,
      left: 431.88,
      width: 138.60,
      height: 12.36
   }
   ,
   {
      page: 5,
      name: "Buyer agrees to pay a loan transfer fee not to exceed", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 93.72,
      left: 300.36,
      width: 94.56,
      height: 12.36
   }
   ,
   {
      page: 5,
      name: "rate will not exceed", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 106.92,
      left: 147.84,
      width: 42.00,
      height: 12.36
   }
   ,
   {
      page: 5,
      name: "per annum and the new payment will not exceed", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 106.92,
      left: 406.68,
      width: 69.36,
      height: 12.36
   }
   ,
   {
      page: 5,
      name: "per_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 106.92,
      left: 496.2,
      width: 77.64,
      height: 12.36
   }
   ,
   {
      page: 5,
      name: "Balance which causes the amount of cash required from Buyer at Closing to be increased by more than", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 133.44,
      left: 487.44,
      width: 62.52,
      height: 12.36
   }
   ,
   {
      page: 5,
      name: "any other terms or provisions of the loan change Buyer has the Right to Terminate under  241 on or before Closing Date", 
      isText: false,
      type: "checkbox",
      top: 162.72,
      left: 111.24,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 5,
      name: "release from liability will be evidenced by delivery", 
      isText: false,
      type: "checkbox",
      top: 162.72,
      left: 152.76,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 5,
      name: "Will Not be released from liability on said loan If applicable compliance with the requirements for", 
      isText: false,
      type: "checkbox",
      top: 175.92,
      left: 276.24,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 5,
      name: "at Closing of an", 
      isText: false,
      type: "checkbox",
      top: 175.92,
      left: 496.32,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 5,
      name: "appropriate letter of commitment from lender Any cost payable for release of liability will be paid by", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 186.48,
      left: 472.68,
      width: 111.96,
      height: 12.36
   }
   ,
   {
      page: 5,
      name: "in an amount not to exceed", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 199.8,
      left: 183.12,
      width: 94.56,
      height: 12.0
   }
   ,
   {
      page: 5,
      name: "licensed Colorado attorney or licensed mortgage loan originator Brokers should not prepare or advise the parties on the specifics of", 
      isText: false,
      type: "checkbox",
      top: 281.04,
      left: 490.08,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 5,
      name: "Seller_2", 
      isText: false,
      type: "checkbox",
      top: 281.04,
      left: 539.64,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 5,
      name: "will deliver the proposed Seller financing documents to the other party on or before", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 291.48,
      left: 411.72,
      width: 45.12,
      height: 12.36
   }
   ,
   {
      page: 6,
      name: "privilege and option of proceeding with the consummation of this Contract without regard to the amount of the appraised", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 523.374,
      left: 356.64,
      width: 74.28,
      height: 10.266
   }
   ,
   {
      page: 7,
      name: "Buyer_2", 
      isText: false,
      type: "checkbox",
      top: 56.88,
      left: 66.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 7,
      name: "Seller The cost of the Appraisal may include any and all fees paid to the appraiser appraisal management", 
      isText: false,
      type: "checkbox",
      top: 56.88,
      left: 113.4,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 8,
      name: "company to furnish the owners title insurance policy at Sellers expense On or before Record Title Deadline Seller must", 
      isText: false,
      type: "checkbox",
      top: 137.88,
      left: 98.88,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 8,
      name: "policy to be issued and delivered to Buyer as soon as practicable at or after Closing", 
      isText: false,
      type: "checkbox",
      top: 172.44,
      left: 234,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 8,
      name: "company to furnish the owners title insurance policy at Buyers expense On or before Record Title Deadline Buyer must furnish", 
      isText: false,
      type: "checkbox",
      top: 199.44,
      left: 98.88,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 8,
      name: "Coverage OEC If the Title Commitment is to contain OEC it will commit to delete or insure over the standard exceptions which", 
      isText: false,
      type: "checkbox",
      top: 249.48,
      left: 366.24,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 8,
      name: "Will Not contain Owners Extended", 
      isText: false,
      type: "checkbox",
      top: 249.48,
      left: 407.76,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 8,
      name: "taxes assessments and unredeemed tax sales prior to the year of Closing Any additional premium expense to obtain OEC will be", 
      isText: false,
      type: "checkbox",
      top: 315.48,
      left: 149.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 8,
      name: "Regardless of whether the Contract requires OEC the Title Insurance Commitment may not provide OEC or delete or insure", 
      isText: false,
      type: "checkbox",
      top: 315.48,
      left: 99.96,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 8,
      name: "OneHalf by Buyer and OneHalf by Seller", 
      isText: false,
      type: "checkbox",
      top: 315.48,
      left: 197.16,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 8,
      name: "undefined_7", 
      isText: false,
      type: "checkbox",
      top: 315.48,
      left: 402.12,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 8,
      name: "Other_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 312.72,
      left: 443.16,
      width: 132.60,
      height: 12.36
   }
   ,
   {
      page: 9,
      name: "for the Metropolitan District if any is", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 282.48,
      left: 226.68,
      width: 339.48,
      height: 12.36
   }
   ,
   {
      page: 9,
      name: "metropolitan districts that affect the Property Tax Certificate must be delivered to Buyer on or before Record Title Deadline", 
      isText: false,
      type: "checkbox",
      top: 302.28,
      left: 276.48,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 9,
      name: "Buyer for the Property listing any special taxing or", 
      isText: false,
      type: "checkbox",
      top: 302.28,
      left: 324.24,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 10,
      name: "Does_2", 
      isText: false,
      type: "checkbox",
      top: 318.48,
      left: 220.2,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 10,
      name: "Does Not have a Right to Terminate if examination of the Mineral Rights", 
      isText: false,
      type: "checkbox",
      top: 318.48,
      left: 264,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 10,
      name: "New Improvement Location Certificate New ILC or", 
      isText: false,
      type: "checkbox",
      top: 377.52,
      left: 84.96,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 10,
      name: "New Survey in the form of", 
      isText: false,
      type: "checkbox",
      top: 390.72,
      left: 84.12,
      width: 9.84,
      height: 9.72
   }
   ,
   {
      page: 10,
      name: "is required and the following will apply", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 388.08,
      left: 210.84,
      width: 201.36,
      height: 12.24
   }
   ,
   {
      page: 10,
      name: "Seller_3", 
      isText: false,
      type: "checkbox",
      top: 408,
      left: 270.24,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 10,
      name: "Buyer will order the New ILC or New Survey The New ILC", 
      isText: false,
      type: "checkbox",
      top: 408,
      left: 318.24,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 10,
      name: "Seller_4", 
      isText: false,
      type: "checkbox",
      top: 459.84,
      left: 85.92,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 10,
      name: "Buyer or", 
      isText: false,
      type: "checkbox",
      top: 459.84,
      left: 133.68,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 10,
      name: "undefined_8", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 457.2,
      left: 192.84,
      width: 373.68,
      height: 12.24
   }
   ,
   {
      page: 10,
      name: "of title if an Abstract of Title and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 487.68,
      left: 204.12,
      width: 368.04,
      height: 12.24
   }
   ,
   {
      page: 11,
      name: "ta_occAgreements", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 693.812,
      left: 77.3504,
      width: 506.2096,
      height: 56.166
   }
   ,
   {
      page: 12,
      name: "Will", 
      isText: false,
      type: "checkbox",
      top: 82.92,
      left: 449.04,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "Will Not assume", 
      isText: false,
      type: "checkbox",
      top: 82.92,
      left: 490.56,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "Will_2", 
      isText: false,
      type: "checkbox",
      top: 156.24,
      left: 88.2,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "Will Not assume the debt on the Encumbered Inclusions  254 Encumbered Inclusions", 
      isText: false,
      type: "checkbox",
      top: 156.24,
      left: 129.72,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "All contracts relating to the operation maintenance and management of the Property", 
      isText: false,
      type: "checkbox",
      top: 202.68,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "Property tax bills for the last", 
      isText: false,
      type: "checkbox",
      top: 218.16,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "Asbuilt construction plans to the Property and the tenant improvements including architectural electrical", 
      isText: false,
      type: "checkbox",
      top: 233.76,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "years", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 215.52,
      left: 271.32,
      width: 59.40,
      height: 12.36
   }
   ,
   {
      page: 12,
      name: "A list of all Inclusions to be conveyed to Buyer", 
      isText: false,
      type: "checkbox",
      top: 272.16,
      left: 139.44,
      width: 9.72,
      height: 9.84
   }
   ,
   {
      page: 12,
      name: "Operating statements for the past", 
      isText: false,
      type: "checkbox",
      top: 287.76,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "A rent roll accurate and correct to the date of this Contract", 
      isText: false,
      type: "checkbox",
      top: 303.24,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "A schedule of any tenant improvement work Seller is obligated to complete but has not yet completed", 
      isText: false,
      type: "checkbox",
      top: 318.72,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "years_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 285,
      left: 289.68,
      width: 54.36,
      height: 12.36
   }
   ,
   {
      page: 12,
      name: "All insurance policies pertaining to the Property and copies of any claims which have been made for the", 
      isText: false,
      type: "checkbox",
      top: 345.72,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "past", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 356.28,
      left: 125.76,
      width: 70.44,
      height: 12.36
   }
   ,
   {
      page: 12,
      name: "Soils reports surveys and engineering reports or data pertaining to the Property if not delivered earlier", 
      isText: false,
      type: "checkbox",
      top: 375.96,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "Any and all existing documentation and reports regarding Phase I and II environmental reports", 
      isText: false,
      type: "checkbox",
      top: 402.96,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "Any Americans with Disabilities Act reports studies or surveys concerning the compliance of the", 
      isText: false,
      type: "checkbox",
      top: 464.52,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "All permits licenses and other building or use authorizations issued by any governmental authority", 
      isText: false,
      type: "checkbox",
      top: 491.52,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "Other_4", 
      isText: false,
      type: "checkbox",
      top: 530.04,
      left: 139.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 12,
      name: "ta_other_1061413a", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 541.433,
      left: 90.4114,
      width: 493.1486,
      height: 71.093
   }
   ,
   {
      page: 13,
      name: "Seller_5", 
      isText: false,
      type: "checkbox",
      top: 135.48,
      left: 384.84,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 13,
      name: "Buyer will order or provide", 
      isText: false,
      type: "checkbox",
      top: 135.48,
      left: 432.6,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 13,
      name: "Buyer Environmental Inspection In addition Buyer at Buyers expense may also conduct", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 159.6,
      left: 433.44,
      width: 142.56,
      height: 12.0
   }
   ,
   {
      page: 13,
      name: "the applicable ASTM E1527 standard practices for Environmental Site Assessments andor", 
      isText: false,
      type: "checkbox",
      top: 175.2,
      left: 189.72,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 13,
      name: "Seller_6", 
      isText: false,
      type: "checkbox",
      top: 175.2,
      left: 141.96,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 13,
      name: "Inspection Objection Deadline and if such Extended Environmental Inspection Objection Deadline extends beyond the", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 239.04,
      left: 354.6,
      width: 72.72,
      height: 12.00
   }
   ,
   {
      page: 13,
      name: "Seller_7", 
      isText: false,
      type: "checkbox",
      top: 267.72,
      left: 426.84,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 13,
      name: "Buyer must pay the", 
      isText: false,
      type: "checkbox",
      top: 267.72,
      left: 474.6,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 13,
      name: "Buyer has the Right to Terminate under  241 effective upon Sellers receipt of Buyers Notice to Terminate on or before", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 388.32,
      left: 207.36,
      width: 364.68,
      height: 12.00
   }
   ,
   {
      page: 13,
      name: "Does_3", 
      isText: false,
      type: "checkbox",
      top: 461.04,
      left: 457.8,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 13,
      name: "Does Not", 
      isText: false,
      type: "checkbox",
      top: 461.04,
      left: 501.6,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 13,
      name: "There is No Well", 
      isText: false,
      type: "checkbox",
      top: 487.56,
      left: 137.16,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 13,
      name: "Does_4", 
      isText: false,
      type: "checkbox",
      top: 487.56,
      left: 260.76,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 13,
      name: "Does Not acknowledge receipt of a copy of the current well permit", 
      isText: false,
      type: "checkbox",
      top: 487.56,
      left: 304.56,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Are", 
      isText: false,
      type: "checkbox",
      top: 106.92,
      left: 400.32,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Are Not executed with this", 
      isText: false,
      type: "checkbox",
      top: 106.92,
      left: 439.8,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "undefined_9", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 154.756,
      left: 315.36,
      width: 209.64,
      height: 11.804
   }
   ,
   {
      page: 15,
      name: "special warranty deed", 
      isText: false,
      type: "checkbox",
      top: 264.24,
      left: 59.4,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "general warranty deed", 
      isText: false,
      type: "checkbox",
      top: 264.24,
      left: 176.76,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "bargain and sale deed", 
      isText: false,
      type: "checkbox",
      top: 264.24,
      left: 296.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "quit claim deed", 
      isText: false,
      type: "checkbox",
      top: 264.24,
      left: 413.04,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "deed Seller provided another deed is", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 274.614,
      left: 229.56,
      width: 182.52,
      height: 12.426
   }
   ,
   {
      page: 15,
      name: "personal representatives deed", 
      isText: false,
      type: "checkbox",
      top: 277.44,
      left: 59.4,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "not selected must execute and deliver a good and sufficient special warranty deed to Buyer at Closing Unless otherwise", 
      isText: false,
      type: "checkbox",
      top: 277.44,
      left: 212.04,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Buyer_3", 
      isText: false,
      type: "checkbox",
      top: 463.32,
      left: 112.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Seller_8", 
      isText: false,
      type: "checkbox",
      top: 463.32,
      left: 162,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_2", 
      isText: false,
      type: "checkbox",
      top: 463.32,
      left: 209.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Other_5", 
      isText: false,
      type: "checkbox",
      top: 463.32,
      left: 414.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "undefined_10", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 461.116,
      left: 458.4,
      width: 105.6,
      height: 11.804
   }
   ,
   {
      page: 15,
      name: "Buyer_4", 
      isText: false,
      type: "checkbox",
      top: 520.08,
      left: 193.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Seller_9", 
      isText: false,
      type: "checkbox",
      top: 520.08,
      left: 243,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_3", 
      isText: false,
      type: "checkbox",
      top: 520.08,
      left: 290.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "NA", 
      isText: false,
      type: "checkbox",
      top: 520.08,
      left: 495.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Buyer_5", 
      isText: false,
      type: "checkbox",
      top: 550.56,
      left: 193.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Seller_10", 
      isText: false,
      type: "checkbox",
      top: 550.56,
      left: 243,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_4", 
      isText: false,
      type: "checkbox",
      top: 550.56,
      left: 290.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "NA_2", 
      isText: false,
      type: "checkbox",
      top: 550.56,
      left: 495.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Buyer_6", 
      isText: false,
      type: "checkbox",
      top: 581.04,
      left: 193.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Seller_11", 
      isText: false,
      type: "checkbox",
      top: 581.04,
      left: 243,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_5", 
      isText: false,
      type: "checkbox",
      top: 581.04,
      left: 290.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "NA_3", 
      isText: false,
      type: "checkbox",
      top: 581.04,
      left: 495.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Buyer_7", 
      isText: false,
      type: "checkbox",
      top: 624.84,
      left: 193.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Seller_12", 
      isText: false,
      type: "checkbox",
      top: 624.84,
      left: 243,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_6", 
      isText: false,
      type: "checkbox",
      top: 624.84,
      left: 290.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "NA_4", 
      isText: false,
      type: "checkbox",
      top: 624.84,
      left: 495.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Buyer_8", 
      isText: false,
      type: "checkbox",
      top: 655.08,
      left: 193.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Seller_13", 
      isText: false,
      type: "checkbox",
      top: 655.08,
      left: 243,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_7", 
      isText: false,
      type: "checkbox",
      top: 655.08,
      left: 290.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "NA_5", 
      isText: false,
      type: "checkbox",
      top: 655.08,
      left: 495.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Buyer_9", 
      isText: false,
      type: "checkbox",
      top: 685.56,
      left: 193.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Seller_14", 
      isText: false,
      type: "checkbox",
      top: 685.56,
      left: 243,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_8", 
      isText: false,
      type: "checkbox",
      top: 685.56,
      left: 290.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "NA_6", 
      isText: false,
      type: "checkbox",
      top: 685.56,
      left: 495.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Buyer_10", 
      isText: false,
      type: "checkbox",
      top: 716.16,
      left: 193.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "Seller_15", 
      isText: false,
      type: "checkbox",
      top: 716.16,
      left: 243,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "OneHalf by Buyer and OneHalf by Seller_9", 
      isText: false,
      type: "checkbox",
      top: 716.16,
      left: 290.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 15,
      name: "NA_7", 
      isText: false,
      type: "checkbox",
      top: 716.16,
      left: 495.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Buyer_11", 
      isText: false,
      type: "checkbox",
      top: 69.96,
      left: 193.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Seller_16", 
      isText: false,
      type: "checkbox",
      top: 69.96,
      left: 243,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "OneHalf by Buyer and OneHalf by Seller_10", 
      isText: false,
      type: "checkbox",
      top: 69.96,
      left: 290.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "NA_8", 
      isText: false,
      type: "checkbox",
      top: 69.96,
      left: 495.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "undefined_11", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 97.8,
      left: 70.8,
      width: 79.68,
      height: 12.24
   }
   ,
   {
      page: 16,
      name: "Water StockCertificates", 
      isText: false,
      type: "checkbox",
      top: 117.72,
      left: 114.96,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Augmentation Membership", 
      isText: false,
      type: "checkbox",
      top: 134.88,
      left: 114.96,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Water District", 
      isText: false,
      type: "checkbox",
      top: 117.72,
      left: 272.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "undefined_12", 
      isText: false,
      type: "checkbox",
      top: 134.88,
      left: 272.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Small Domestic Water Company", 
      isText: true,
      type: "checkbox",
      top: 135.84,
      left: 442.08,
      width: 7.80,
      height: 8.04
   }
   ,
   {
      page: 16,
      name: "undefined_13", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 132.36,
      left: 456.96,
      width: 115.20,
      height: 12.24
   }
   ,
   {
      page: 16,
      name: "Buyer_12", 
      isText: false,
      type: "checkbox",
      top: 165.48,
      left: 193.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Seller_17", 
      isText: false,
      type: "checkbox",
      top: 165.48,
      left: 243,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "OneHalf by Buyer and OneHalf by Seller_11", 
      isText: false,
      type: "checkbox",
      top: 165.48,
      left: 290.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "NA_9", 
      isText: false,
      type: "checkbox",
      top: 165.48,
      left: 495.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Buyer_13", 
      isText: false,
      type: "checkbox",
      top: 195.96,
      left: 193.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Seller_18", 
      isText: false,
      type: "checkbox",
      top: 195.96,
      left: 243,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "OneHalf by Buyer and OneHalf by Seller_12", 
      isText: false,
      type: "checkbox",
      top: 195.96,
      left: 290.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "NA_10", 
      isText: false,
      type: "checkbox",
      top: 195.96,
      left: 495.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "IS a foreign", 
      isText: false,
      type: "checkbox",
      top: 251.76,
      left: 497.64,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Taxes for the Calendar Year Immediately Preceding Closing", 
      isText: false,
      type: "checkbox",
      top: 443.52,
      left: 66.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Most Recent Mill Levy and Most Recent Assessed Valuation adjusted by any applicable qualifying seniors property tax", 
      isText: false,
      type: "checkbox",
      top: 456.84,
      left: 66.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Other_6", 
      isText: false,
      type: "checkbox",
      top: 483.24,
      left: 66.36,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "undefined_14", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 480.6,
      left: 110.28,
      width: 241.08,
      height: 12.24
   }
   ,
   {
      page: 16,
      name: "Rents Actually Received", 
      isText: false,
      type: "checkbox",
      top: 500.52,
      left: 202.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Accrued At Closing Seller will transfer or credit to Buyer", 
      isText: false,
      type: "checkbox",
      top: 500.52,
      left: 326.52,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Other Prorations Water and sewer charges propane interest on continuing loan and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 536.4,
      left: 448.68,
      width: 120.24,
      height: 12.24
   }
   ,
   {
      page: 16,
      name: "Buyer_14", 
      isText: false,
      type: "checkbox",
      top: 622.8,
      left: 446.28,
      width: 9.84,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "Seller Except", 
      isText: false,
      type: "checkbox",
      top: 622.8,
      left: 495.84,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 16,
      name: "assessments and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 673.2,
      left: 135.6,
      width: 437.4,
      height: 12.24
   }
   ,
   {
      page: 17,
      name: "202 If Seller is in Default for payment of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 53.851,
      left: 252.84,
      width: 47.64,
      height: 12.629
   }
   ,
   {
      page: 17,
      name: "Buyer represents that Buyer will occupy the Property as Buyers principal residence unless the following box is checked then", 
      isText: false,
      type: "checkbox",
      top: 96.36,
      left: 90.48,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 17,
      name: "Buyer_15", 
      isText: false,
      type: "checkbox",
      top: 109.68,
      left: 59.4,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 18,
      name: "Buyer will be paid to Seller and retained by Seller It is agreed that the Earnest Money is not a penalty and the parties agree the", 
      isText: false,
      type: "checkbox",
      top: 72.36,
      left: 103.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 19,
      name: "undefined_15", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 194.52,
      left: 435.12,
      width: 135.12,
      height: 10.56
   }
   ,
   {
      page: 19,
      name: "ta_additionalProvisions", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 448.14,
      left: 38.7892,
      width: 534.8198,
      height: 303.082
   }
   ,
   {
      page: 20,
      name: "Buyers Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 276.36,
      left: 116.28,
      width: 171.24,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "Buyers Name2z", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 276.36,
      left: 387.12,
      width: 188.52,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "Addressbuyer1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 322.92,
      left: 116.28,
      width: 171.24,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "Addressbuyer2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 322.92,
      left: 361.92,
      width: 213.72,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "Phonebuyer2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 336.48,
      left: 361.68,
      width: 213.96,
      height: 12.84
   }
   ,
   {
      page: 20,
      name: "Phonebuyer1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 336.48,
      left: 116.28,
      width: 171.24,
      height: 12.84
   }
   ,
   {
      page: 20,
      name: "emailbuyer1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 349.92,
      left: 116.28,
      width: 171.24,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "Emailbuyer2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 349.92,
      left: 361.66,
      width: 213.98,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "Sellers Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 432.24,
      left: 116.28,
      width: 171.24,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "Sellers Name2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 432.24,
      left: 386.16,
      width: 189.48,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "addressseller1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 478.68,
      left: 116.28,
      width: 171.24,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "Addressseller2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 478.68,
      left: 361.92,
      width: 213.72,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "Phoneseller2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 492.24,
      left: 361.68,
      width: 213.96,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "phoneseller1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 492.24,
      left: 116.28,
      width: 171.24,
      height: 12.96
   }
   ,
   {
      page: 20,
      name: "emailseller1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 505.8,
      left: 116.28,
      width: 171.24,
      height: 12.84
   }
   ,
   {
      page: 20,
      name: "Emailseller2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 505.8,
      left: 355.44,
      width: 220.20,
      height: 12.84
   }
   ,
   {
      page: 20,
      name: "Does_5", 
      isText: false,
      type: "checkbox",
      top: 671.4,
      left: 98.64,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 20,
      name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the", 
      isText: false,
      type: "checkbox",
      top: 671.4,
      left: 152.16,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 20,
      name: "Buyers Agent", 
      isText: false,
      type: "checkbox",
      top: 733.32,
      left: 198.72,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 20,
      name: "TransactionBroker in this transaction", 
      isText: false,
      type: "checkbox",
      top: 733.32,
      left: 282.96,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 20,
      name: "ta_postOcc", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 95.997,
      left: 56.0067,
      width: 519.2703,
      height: 82.910
   }
   ,
   {
      page: 20,
      name: "ta_docsNotPart", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 191.296,
      left: 55.8359,
      width: 520.5141,
      height: 52.435
   }
   ,
   {
      page: 21,
      name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
      isText: false,
      type: "checkbox",
      top: 56.88,
      left: 56.64,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "Listing Brokerage Firm", 
      isText: false,
      type: "checkbox",
      top: 88.44,
      left: 125.88,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "Buyer_16", 
      isText: false,
      type: "checkbox",
      top: 88.44,
      left: 247.44,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "Other_7", 
      isText: false,
      type: "checkbox",
      top: 88.44,
      left: 295.56,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "buyerbrokerpaidbyother", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 85.68,
      left: 340.92,
      width: 223.08,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "Brokerage Firms Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 150.96,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "Brokerage Firms License1a", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 164.16,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "Brokerage Firms License2a", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 177.48,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "Brokerage Firms License3a", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 190.68,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "tf_brokerBuyerBrokerageAddress", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 243.72,
      left: 181.942,
      width: 389.760,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "tf_brokerbuyerphone", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 256.92,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "tf_brokerbuyeremail", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 270,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "Does_6", 
      isText: false,
      type: "checkbox",
      top: 319.2,
      left: 89.64,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the Earnest", 
      isText: false,
      type: "checkbox",
      top: 319.2,
      left: 134.16,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "Sellers Agent", 
      isText: false,
      type: "checkbox",
      top: 386.52,
      left: 196.68,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "TransactionBroker in this transaction_2", 
      isText: false,
      type: "checkbox",
      top: 386.52,
      left: 279.24,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "Customer Broker has no brokerage relationship with Seller See  A for Brokers brokerage relationship with Buyer", 
      isText: false,
      type: "checkbox",
      top: 402.48,
      left: 56.64,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "Seller_19", 
      isText: false,
      type: "checkbox",
      top: 434.28,
      left: 125.88,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "Buyer_17", 
      isText: false,
      type: "checkbox",
      top: 434.28,
      left: 172.92,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "Other_8", 
      isText: false,
      type: "checkbox",
      top: 434.28,
      left: 221.28,
      width: 9.72,
      height: 9.72
   }
   ,
   {
      page: 21,
      name: "tf_brokersellerpaidother", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 431.52,
      left: 266.4,
      width: 223.32,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "Brokerage Firms Name2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 502.8,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "BrokeragesellerFirmsLicense2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 516,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "Brokerage Firmsname2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 529.08,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "Brokerage Firms License3xx", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 542.28,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "tf_brokerselleraddress", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 595.32,
      left: 181.942,
      width: 389.760,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "tf_brokersellerphone", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 608.64,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "tf_brokerSellerEmail", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 621.84,
      left: 181.32,
      width: 389.76,
      height: 12.36
   }
   ,
   {
      page: 21,
      name: "date_brokerBuyerSignDate", 
      fontSize: 9,
      type: "date",
      top: 216.169,
      left: 397.532,
      width: 98.063,
      height: 12.982
   }
   ,
   {
      page: 21,
      name: "date_brokerSellerDatex", 
      fontSize: 9,
      type: "date",
      top: 569.128,
      left: 396.91,
      width: 94.953,
      height: 12.360
   }
   ,
   {
      page: 21,
      name: "BrokersSellerSignature", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 568.884,
      left: 182.214,
      width: 186.002,
      height: 12.360
   }
   ,
   {
      page: 21,
      name: "BrokersBuyerSignature", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 216.48,
      left: 181.592,
      width: 197.820,
      height: 12.36
   }
   ] }
   