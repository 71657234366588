import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Divider, Grid, Icon, Popup, Button } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { openModal } from "../../../app/common/modals/modalSlice";
import {
  convertAddressFull,
  convertFullName,
  convertPhoneToLink,
} from "../../../app/common/util/util";
import { updatePartyInDb } from "../../../app/firestore/firestoreService";
import { toast } from "react-toastify";

export default function TransactionParties() {
  const dispatch = useDispatch();
  const { transaction, parties, transClients } = useSelector(
    (state) => state.transaction
  );
  const { people } = useSelector((state) => state.people);
  const [viewMore, setViewMore] = useState(false);

  if (!transaction || !transClients)
    return <LoadingComponent content="Loading details..." />;

  function handleEditClient(client) {
    dispatch(
      openModal({
        modalType: "TransactionClientEditForm",
        modalProps: {
          client: client,
          transaction: transaction,
        },
      })
    );
  }

  function handleAddClient(client) {
    dispatch(
      openModal({
        modalType: "TransactionClientAddForm",
        modalProps: {
          client: client,
          transaction: transaction,
          people: people,
        },
      })
    );
  }

  function handleEditParty(party) {
    dispatch(
      openModal({
        modalType: "TransactionPartyForm",
        modalProps: { party: party, transaction: transaction },
      })
    );
  }

  function handleRemoveLink(party) {
    try {
      updatePartyInDb(party, {
        isLinked: false,
        linkedTransactionId: "",
        linkedUserId: "",
        email: party.email,
        role: party.role,
      });
      toast.success("Link removed");
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className="secondary-page-wrapper">
      {transaction && (
        <>
          <Grid>
            <Grid.Column width={16}>
              <div className="flex">
                <h3 className="tiny right margin tiny bottom margin">
                  Client{transClients.length > 1 ? "s" : ""}
                </h3>
                {!transaction.clientSecondaryExists && (
                  <Icon
                    name="add circle"
                    color="blue"
                    link
                    onClick={() => handleAddClient()}
                    style={{ paddingTop: "5px" }}
                  />
                )}
              </div>
              <Divider className="mini top margin mini bottom margin" />
              <br />
              <Card.Group stackable>
                {transClients.map((client) => (
                  <Card key={client.role} className="tiny top margin">
                    <Card.Content extra>
                      <Icon
                        name="edit"
                        color="blue"
                        className="float-right"
                        link
                        onClick={() => handleEditClient(client)}
                      />
                      <Card.Header>{convertFullName(client)}</Card.Header>
                      {client.isTrust && <p>{client.entityName}</p>}
                      <p>{client.role}</p>
                      <Card.Meta>
                        {client.address && client.address.street && (
                          <p>Address: {convertAddressFull(client.address)}</p>
                        )}
                      </Card.Meta>
                      {client.email && (
                        <div className="tiny bottom margin small top margin">
                          <Icon name="mail" />
                          <span className="tiny left margin">
                            <a href={`mailto:${client.email}`}>
                              {client.email}
                            </a>
                          </span>
                          <br />
                        </div>
                      )}
                      {client.phone && (
                        <div className="tiny bottom margin">
                          <Icon name="phone" />
                          <span className="tiny left margin">
                            <a href={`tel:${convertPhoneToLink(client.phone)}`}>
                              {client.phone}
                            </a>
                          </span>
                          <br />
                        </div>
                      )}
                      {client.fax && (
                        <div className="tiny bottom margin">
                          <Icon name="fax" />
                          <span className="tiny left margin">{client.fax}</span>
                          <br />
                        </div>
                      )}
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            </Grid.Column>

            <Grid.Column width={16}>
              <div className="flex">
                <h3 className="tiny right margin  tiny bottom margin">
                  Other Parties
                </h3>
                <Icon
                  name="add circle"
                  color="blue"
                  link
                  onClick={() => handleEditParty()}
                  style={{ paddingTop: "5px" }}
                />
              </div>
              <Popup
                flowing
                size="large"
                trigger={
                  <Button basic size="mini" icon labelPosition="left">
                    <Icon basic="true" name="info" />
                    Info on adding Transaction Coordinators
                  </Button>
                }
              >
                <p className="bold text blue mini bottom margin">
                  How to Add Transaction Coordinators
                </p>
                <p className="text-dark text-normal small vertical margin">
                  <span className="bold">Your Transaction Coordinator: </span>
                  <br />
                  Add a new party and select the role "TC (Mine)". Your TC will
                  then have access to this transaction including all documents
                  and deadlines.
                </p>
                <p className="text-dark text-normal mini bottom margin">
                  <span className="bold">
                    The Other Agent's Transaction Coordinator:{" "}
                  </span>
                  <br />
                  When adding the other side's agent as a party, click the "This
                  party has a Transaction Coordinator" checkbox and add the TC's
                  information (including their email).
                  <br />
                  Their TC will have then have access to the documents you share
                  with their agent.
                </p>
              </Popup>
              <Divider className="small top margin mini bottom margin" />
              <br />
              <Card.Group stackable>
                {parties &&
                  parties.map((party) => (
                    <Card className="tiny top margin" key={party.role}>
                      <Card.Content extra>
                        <Icon
                          name="edit"
                          color="blue"
                          className="float-right"
                          link
                          onClick={() => handleEditParty(party)}
                        />
                        <Card.Header>{convertFullName(party)}</Card.Header>
                        {party.isTrust && <p>{party.entityName}</p>}
                        <p>{party.role}</p>
                        {party.isLinked && (
                          <div className="box-blue tiny padding small bottom margin">
                            <p className="zero bottom margin text blue">
                              Transaction is Linked to this Agent &nbsp;&nbsp;
                              <Icon
                                name="trash"
                                color="blue"
                                link
                                onClick={() => handleRemoveLink(party)}
                              />
                            </p>
                          </div>
                        )}
                        <Card.Meta>
                          {party.companyName && (
                            <p>Company: {party.companyName}</p>
                          )}
                        </Card.Meta>
                        <Card.Meta>
                          {party.address && party.address.street && (
                            <p>Address: {convertAddressFull(party.address)}</p>
                          )}
                        </Card.Meta>
                        {(party.brokerLicenseNumber ||
                          party.brokerageName ||
                          party.brokerageId ||
                          party.brokerageLicenseNumber) && (
                          <>
                            <p
                              style={{ color: "#0074cc", cursor: "pointer" }}
                              onClick={() => setViewMore(!viewMore)}
                            >
                              More Details
                            </p>
                            {viewMore && (
                              <>
                                <Card.Meta>
                                  {party.brokerLicenseNumber && (
                                    <p>
                                      Agent License #:{" "}
                                      {party.brokerLicenseNumber}
                                    </p>
                                  )}
                                </Card.Meta>
                                <Card.Meta>
                                  {party.brokerageName && (
                                    <p>Brokerage: {party.brokerageName}</p>
                                  )}
                                </Card.Meta>
                                <Card.Meta>
                                  {party.brokerageId && (
                                    <p>Brokerage ID: {party.brokerageId}</p>
                                  )}
                                </Card.Meta>
                                <Card.Meta>
                                  {party.brokerageLicenseNumber && (
                                    <p>
                                      Brokerage Licence #:{" "}
                                      {party.brokerageLicenseNumber}
                                    </p>
                                  )}
                                </Card.Meta>
                              </>
                            )}
                          </>
                        )}

                        {party.email && (
                          <div className="small top margin">
                            <Card.Meta>
                              <div className="tiny bottom margin">
                                <Icon name="mail" />
                                <span className="tiny left margin">
                                  <a href={`mailto:${party.email}`}>
                                    {party.email}
                                  </a>
                                </span>
                                <br />
                              </div>
                            </Card.Meta>
                          </div>
                        )}
                        {party.phone && (
                          <Card.Meta>
                            <div className="tiny bottom margin">
                              <Icon name="phone" />
                              <span className="tiny left margin">
                                <a
                                  href={`tel:${convertPhoneToLink(
                                    party.phone
                                  )}`}
                                >
                                  {party.phone}
                                </a>
                              </span>
                              <br />
                            </div>
                          </Card.Meta>
                        )}
                        {party.fax && (
                          <Card.Meta>
                            <div className="tiny bottom margin">
                              <Icon name="fax" />
                              <span className="tiny left margin">
                                {party.fax}
                              </span>
                              <br />
                            </div>
                          </Card.Meta>
                        )}
                      </Card.Content>
                      {party.hasAssistant && (
                        <>
                          <Card.Content extra>
                            <h4 className="tiny bottom margin">Assistant</h4>
                            <p>
                              {party.assistant?.firstName}{" "}
                              {party.assistant?.lastName}
                            </p>
                            {party.assistant?.email && (
                              <div className="tiny bottom margin">
                                <Icon name="mail" />
                                <span className="tiny left margin">
                                  <a href={`mailto:${party.assistant?.email}`}>
                                    {party.assistant?.email}
                                  </a>
                                </span>
                                <br />
                              </div>
                            )}
                            {party.assistant?.phone && (
                              <div className="tiny bottom margin">
                                <Icon name="phone" />
                                <span className="tiny left margin">
                                  <a
                                    href={`tel:${convertPhoneToLink(
                                      party.assistant?.phone
                                    )}`}
                                  >
                                    {party.assistant?.phone}
                                  </a>
                                </span>
                                <br />
                              </div>
                            )}
                          </Card.Content>
                        </>
                      )}
                      {party.hasCoAgent && (
                        <>
                          <Card.Content extra>
                            <h4 className="tiny bottom margin">CoAgent</h4>
                            <p>
                              {party.coAgent?.firstName}{" "}
                              {party.coAgent?.lastName}
                            </p>
                            {party.coAgent?.email && (
                              <div className="tiny bottom margin">
                                <Icon name="mail" />
                                <span className="tiny left margin">
                                  <a href={`mailto:${party.coAgent?.email}`}>
                                    {party.coAgent?.email}
                                  </a>
                                </span>
                                <br />
                              </div>
                            )}
                            {party.coAgent?.phone && (
                              <div className="tiny bottom margin">
                                <Icon name="phone" />
                                <span className="tiny left margin">
                                  <a
                                    href={`tel:${convertPhoneToLink(
                                      party.coAgent?.phone
                                    )}`}
                                  >
                                    {party.coAgent?.phone}
                                  </a>
                                </span>
                                <br />
                              </div>
                            )}
                          </Card.Content>
                        </>
                      )}
                      {party.hasTransactionCoordinator && (
                        <>
                          <Card.Content extra>
                            <h4 className="tiny bottom margin">
                              Transaction Coordinator
                            </h4>
                            <p>
                              {party.transactionCoordinator?.firstName}{" "}
                              {party.transactionCoordinator?.lastName}
                            </p>
                            {party.transactionCoordinator?.email && (
                              <div className="tiny bottom margin">
                                <Icon name="mail" />
                                <span className="tiny left margin">
                                  <a
                                    href={`mailto:${party.transactionCoordinator?.email}`}
                                  >
                                    {party.transactionCoordinator?.email}
                                  </a>
                                </span>
                                <br />
                              </div>
                            )}
                            {party.transactionCoordinator?.phone && (
                              <div className="tiny bottom margin">
                                <Icon name="phone" />
                                <span className="tiny left margin">
                                  <a
                                    href={`tel:${convertPhoneToLink(
                                      party.transactionCoordinator?.phone
                                    )}`}
                                  >
                                    {party.transactionCoordinator?.phone}
                                  </a>
                                </span>
                                <br />
                              </div>
                            )}
                          </Card.Content>
                        </>
                      )}
                    </Card>
                  ))}
              </Card.Group>
            </Grid.Column>
          </Grid>
        </>
      )}
    </div>
  );
}
