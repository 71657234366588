export function noticeToTerminate() {
    return [
        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date",
            top: 155.04,
            left: 398.64,
            width: 174.00,
            height: 16.32
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "This Notice terminates the contract dated",
            top: 178.08,
            left: 217.56,
            width: 139.08,
            height: 16.32
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "between",
            top: 178.08,
            left: 394.8,
            width: 177.84,
            height: 16.32
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "12",
            top: 195.24,
            left: 50.4,
            width: 144.12,
            height: 16.32
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Seller and",
            top: 195.24,
            left: 246.36,
            width: 294.84,
            height: 16.32
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "relating to the sale and purchase of the Property known as",
            top: 212.52,
            left: 287.28,
            width: 285.36,
            height: 16.32
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "14",
            top: 229.80,
            left: 50.4,
            width: 144.12,
            height: 16.32
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined",
            top: 296.28,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_2",
            top: 296.28,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_3",
            top: 311.16,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "Due Diligence Documents Leases  10",
            top: 311.16,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_4",
            top: 326.04,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_5",
            top: 326.04,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_6",
            top: 341.04,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "Environmental ADA Evaluation  10 CBS2 3 4",
            top: 341.04,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_7",
            top: 356.04,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_8",
            top: 356.04,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_9",
            top: 370.92,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_10",
            top: 370.92,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_11",
            top: 385.80,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "Methamphetamine Laboratory  10 CBS1 2 F1",
            top: 385.80,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "Record Title OffRecord Title or Tax Certificate  8",
            top: 400.68,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "Estoppel Statements  11 CBS2 3 4",
            top: 400.68,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_12",
            top: 415.56,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_13",
            top: 415.56,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_14",
            top: 430.56,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "Damage Inclusions and Services  18",
            top: 430.56,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_15",
            top: 445.44,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_16",
            top: 445.44,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_17",
            top: 460.32,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Other",
            top: 459.6,
            left: 104.28,
            width: 195.24,
            height: 10.2
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_18",
            top: 460.32,
            left: 314.88,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_19",
            top: 475.20,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Other_2",
            top: 474.48,
            left: 104.28,
            width: 474.36,
            height: 10.20
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_20",
            top: 531.12,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_21",
            top: 546.00,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_22",
            top: 560.88,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "undefined_23",
            top: 575.76,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "Other_3",
            top: 590.64,
            left: 57.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "undefined_24",
            top: 600.96,
            left: 56.4,
            width: 513.84,
            height: 10.68
        },

        {
            page: 0,
            type: "checkbox",
            name: "Buyer",
            top: 638.64,
            left: 154.68,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            type: "checkbox",
            name: "Seller",
            top: 638.64,
            left: 228.12,
            width: 9.96,
            height: 9.96
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Name 1",
            top: 667.32,
            left: 86.4,
            width: 214.92,
            height: 16.32
        },

        {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Name 1_2",
            top: 667.32,
            left: 347.52,
            width: 228.72,
            height: 16.32
        }
    ];
}
