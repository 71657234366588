import { format } from "date-fns";
import React, { useRef } from "react";

export default function AnnotFieldDateSigned({ annot, pageScale }) {
  const ref = useRef(null);

  return (
    <div
      ref={ref}
      id={"page" + annot.page + "-y" + annot.y.toString().split(".")[0]}
      style={{
        width: annot.width * pageScale,
        height: annot.height * pageScale,
        minWidth: 12 * pageScale,
        minHeight: 12 * pageScale,
        maxWidth: 960 * pageScale,
        maxHeight: 960 * pageScale,
        position: "absolute",
        display: "inline-block",
        zIndex: 2,
        top: annot.y * pageScale,
        left: annot.x * pageScale,
        lineHeight: 0.6,
        whiteSpace: "nowrap",
      }}
    >
      <div className="annot-outer-wrapper">
        <div className="annot-inner-wrapper">
          <span
            style={{
              fontSize: `${annot.fontSize * pageScale}px`,
              color: "#0037b8",
              fontFamily: `${annot.fontFamily}`,
            }}
          >
            {format(new Date(), "MM/dd/yyyy")}
          </span>
        </div>
      </div>
    </div>
  );
}
