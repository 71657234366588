import _ from "lodash";
import { createSignerIndex } from "../annots/annotUtils";

export function roleIsOtherAgentsClients(role, transaction) {
  if (transaction.agentRepresents === "Buyer") {
    return role === "Seller" || role === "Seller 2";
  } else {
    return role === "Buyer" || role === "Buyer 2";
  }
}

export function createSignerLists(doc, allParties, transaction) {
  let allPartiesCopy = JSON.parse(JSON.stringify(allParties));
  let signerList = [];
  let signerLists = {};
  doc.annotsInProgress &&
    doc.annotsInProgress.forEach((annot) => {
      if (
        // if annot signer doesn't exist in signerList or it's the other agent's clients
        !signerList.filter((signer) => signer.role === annot.signerRole)[0] &&
        !roleIsOtherAgentsClients(annot.signerRole, transaction) &&
        !annot.agentsField
      ) {
        let signerInfo = [];
        if (
          // if signer info already exists
          allPartiesCopy.filter((party) => party.role === annot.signerRole)[0]
        ) {
          // add signer info to docSigners
          signerInfo = allPartiesCopy.filter(
            (party) => party.role === annot.signerRole
          )[0];
        } else {
          // add signer role only to docSigners
          signerInfo = { role: annot.signerRole };
        }
        signerInfo["index"] = createSignerIndex(signerInfo.role, transaction);
        signerList = [...signerList, signerInfo];
      }
    });
  signerLists["possible"] = _.sortBy(
    signerList.filter(
      (signer) => signer.firstName && signer.lastName && signer.email
    ),
    "index"
  );
  signerLists["notPossible"] = _.sortBy(
    signerList.filter(
      (signer) => !(signer.firstName && signer.lastName && signer.email)
    ),
    "index"
  );
  return signerLists;
}

export function checkSignerMissingInfo(finalSignerList) {
  let signerIsMissingInfo = false;
  finalSignerList.forEach((signer) => {
    if (!(signer.firstName && signer.lastName && signer.email)) {
      signerIsMissingInfo = true;
    }
  });
  return signerIsMissingInfo;
}
