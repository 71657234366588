import React from "react";
import { Table } from "semantic-ui-react";
import PeopleActionButtons from "./PeopleActionButtons";

export default function PeopleListItem({ person }) {
  return (
    <Table.Row key={person.id}>
      <Table.Cell>{person.firstName}</Table.Cell>
      <Table.Cell>{person.lastName}</Table.Cell>
      <Table.Cell>{person.email}</Table.Cell>
      <Table.Cell>{person.phone}</Table.Cell>
      <Table.Cell>{person.type}</Table.Cell>
      <Table.Cell>
        <PeopleActionButtons person={person} />
      </Table.Cell>
    </Table.Row>
  );
}
