export function amendExtendWithoutDates() {
    return [   //2023
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
    {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 202.429,
       left: 399.0,
       width: 169.92,
       height: 15.131
    }
    ,
    {
       page: 0,
       name: "Contract between", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 218.4,
       left: 244.32,
       width: 160.08,
       height: 14.28
    }
    ,
    {
       page: 0,
       name: "Seller and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 237.535,
       left: 50.76,
       width: 449.88,
       height: 15.785
    }
    ,
    {
       page: 0,
       name: "Buyer relating to", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 257.76,
       left: 50.76,
       width: 449.88,
       height: 16.44
    }
    ,
    {
       page: 0,
       name: "undefined", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 276.349,
       left: 426.12,
       width: 145.08,
       height: 15.131
    }
    ,
    {
       page: 0,
       name: "Property", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 394.92,
       left: 96.48,
       width: 433.56,
       height: 16.44
    }
    ,
    {
       page: 0,
       name: "Text49", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 309.601,
       left: 51.0548,
       width: 525.0562,
       height: 84.837
    }
    ,
    {
       page: 0,
       name: "Text50", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 509.893,
       left: 51.0548,
       width: 523.7472,
       height: 234.0738
    }
    ,
    {
       page: 1,
       name: "Date_2", 
       fontSize: 9,
       type: "date",
       top: 530.68,
       left: 312.4,
       width: 96.131,
       height: 13.014
    }
    ,
    {
       page: 1,
       name: "Time", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 530.68,
       left: 471.48,
       width: 94.92,
       height: 12.36
    }
    ,
    {
       page: 1,
       name: "Buyers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 575.691,
       left: 108.72,
       width: 179.88,
       height: 15.109
    }
    ,
    {
       page: 1,
       name: "Buyers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 575.691,
       left: 369.72,
       width: 179.88,
       height: 15.109
    }
    ,
    {
       page: 1,
       name: "Sellers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 656.942,
       left: 107.64,
       width: 179.88,
       height: 16.418
    }
    ,
    {
       page: 1,
       name: "Sellers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 657.596,
       left: 368.64,
       width: 179.88,
       height: 15.764
    }
    ,
    {
       page: 1,
       name: "Text51", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 83.782,
       left: 49.7457,
       width: 521.1293,
       height: 410.147
    }
    ] }
    