export function billofSalePPA() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 200,
      height: 30,
    }
    ,
   {
      page: 0,
      name: "Dollars", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 128.565,
      left: 255.859,
      width: 259.342,
      height: 10.680
   }
   ,
   {
      page: 0,
      name: "Buyer the", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 141.12,
      left: 181.2,
      width: 186.168,
      height: 10.163
   }
   ,
   {
      page: 0,
      name: "receipt of which is hereby acknowledged does sell assign transfer and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 141.12,
      left: 411.84,
      width: 65.28,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "convey to Buyer the following personal property located at", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 154.192,
      left: 72.0,
      width: 290.219,
      height: 9.128
   }
   ,
   {
      page: 0,
      name: "undefined", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 177.093,
      left: 72.5896,
      width: 477.1754,
      height: 10.043
   }
   ,
   {
      page: 0,
      name: "PropertyLegalName", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 198.015,
      left: 70.4113,
      width: 481.1017,
      height: 46.899
   }
   ,
   {
      page: 0,
      name: "Text15", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 256.922,
      left: 70.0367,
      width: 482.4023,
      height: 194.224
   }
   ,
   {
      page: 0,
      name: "The personal property is in an As Is condition Where Is and With All Faults without any", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 461.56,
      left: 382.2,
      width: 158.04,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "representations or warranties of any kind except", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 473.08,
      left: 72.0,
      width: 72.0,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Text16", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 497.326,
      left: 73.3094,
      width: 469.3116,
      height: 89.981
   }
   ,
   {
      page: 0,
      name: "date", 
      type: "date",
      fontName: "Helvetica",
      fontSize: 10,
      top: 619.895,
      left: 357.375,
      width: 87.216,
      height: 12.039
   }
   ] }
   