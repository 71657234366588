import React from "react";
import { Button, Grid, Input } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { searchFilter } from "../../../../app/common/util/util";
import TaskTransSharingList from "./TaskTransSharingList";

export default function TaskTransSharingDashboard() {
  const { tasksTransWithDateAll } = useSelector((state) => state.task);
  let { id } = useParams();
  const [searchTerms, setSearchTerms] = useState("");
  const deadlines = searchFilter(tasksTransWithDateAll, searchTerms);

  return (
    <div className="secondary-page-wrapper">
      <Grid stackable>
        <Grid.Column computer={5}>
          <Input
            type="text"
            fluid
            placeholder="Search by name or status"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          ></Input>
        </Grid.Column>
        <Grid.Column computer={5}>
          <Button.Group fluid size="small">
            <Button as={Link} to={`/transactions/${id}/tasks`}>
              List
            </Button>
            <Button as={Link} to={`/transactions/${id}/tasksCalendar`}>
              Calendar
            </Button>
            <Button active as={Link} to="">
              Sharing
            </Button>
          </Button.Group>
        </Grid.Column>
        <Grid.Column computer={6}></Grid.Column>
        <Grid.Column computer={16}>
          <h3>Shareable Deadlines</h3>
          {deadlines?.length > 0 ? (
            <TaskTransSharingList deadlines={deadlines} />
          ) : (
            <p>There are no shareable deadlines for this transaction</p>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
}
