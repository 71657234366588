import React from "react";
import * as Sentry from "@sentry/react";
import NavBar from "../../features/nav/NavBar";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import LoadingComponent from "./LoadingComponent";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import TransactionActiveDashboard from "../../features/transactions/transactionActive/TransactionActiveDashboard";
import ModalManager from "../common/modals/ModalManager";
import DocTransActiveDashboard from "../../features/docs/docTrans/docTransActive/DocTransActiveDashboard";
import TransactionAllDashboard from "../../features/transactions/transactionAll/TransactionAllDashboard";
import PrivacyPolicy from "../../features/home/legal/PrivacyPolicy";
import TermsConditions from "../../features/home/legal/TermsConditions";
import ErrorComponent from "../common/errors/ErrorComponent";
import NavBarSecondary from "../../features/nav/NavBarSecondary";
import DocEditPage from "../../features/docs/docEdit/DocEditPage";
import TransactionOverview from "../../features/transactions/transactionOverview/TransactionOverview";
import TransactionAllOverview from "../../features/transactions/transactionOverview/TransactionAllOverview";
import TransactionParties from "../../features/transactions/transactionParties/TransactionParties";
import AccountPage from "../../features/auth/accountPage";
import ProfilePage from "../../features/profiles/ProfilePage";
import TransactionData from "../firestore/TransactionData";
import OverallData from "../firestore/OverallData";
import PeopleDashboard from "../../features/people/PeopleDashboard";
import NavBarParty from "../../features/nav/NavBarParty";
import PartyOverview from "../../features/parties/partyOverview/PartyOverview";
import PartyLoginPageOld from "../../features/parties/PartyLoginPageOld";
import PartyData from "../firestore/PartyData";
import FallbackComponent from "./FallbackComponent";
import ReactGA from "react-ga4";
import PartyDocEditPage from "../../features/parties/partyDocEdit/PartyDocEditPage";
import PartyCalendar from "../../features/parties/partyCalendar/PartyCalendar";
import FormTemplatesDashboard from "../../features/formTemplates/FormTemplatesDashboard";
import FormTemplateEditPage from "../../features/formTemplates/formTemplatesFill/FormTemplateEditPage";
import AdminPage from "../../features/auth/AdminPage";
import PartyContacts from "../../features/parties/partyContacts/PartyContacts";
import TcOverview from "../../features/tcs/TcOverview";
import ManagerData from "../firestore/ManagerData";
import ManagerAgentsActiveOverview from "../../features/managers/ManagerAgentsActiveOverview";
import ManagerAgentsAllOverview from "../../features/managers/ManagerAgentsAllOverview";
import ManagerAgentData from "../firestore/ManagerAgentData";
import PartyAllData from "../firestore/PartyAllData";
import PartyAllOverview from "../../features/parties/partyAllOverview/PartyAllOverview";
import NavBarPartyAll from "../../features/nav/NavBarPartyAll";
import PartyLoginPage from "../../features/parties/partyLogin/PartyLoginPage";
import PartyChangePassword from "../../features/parties/partyLogin/PartyChangePassword";
import PaymentsDashboard from "../../features/payments/PaymentsDashboard";
import DeadlineTemplatesDashboard from "../../features/deadlineTemplates/DeadlineTemplatesDashboard";
import ClausesDashboard from "../../features/clauses/ClausesDashboard";
import HistoryDashboard from "../../features/history/HistoryDashboard";
import TaskTemplatesDashboard from "../../features/tasks/taskTemplates/TaskTemplatesDashboard";
import TaskTransActiveTaskDashboard from "../../features/tasks/taskTrans/taskTransList/TaskTransListDashboard";
import TaskTransSharingDashboard from "../../features/tasks/taskTrans/taskTransSharing/TaskTransSharingDashboard";
import TaskTransCalendarMonthly from "../../features/tasks/taskTrans/taskTransCalendar/TaskTransCalendarMonthly";
import TaskAllCalendarMonthly from "../../features/tasks/taskAll/TaskAllCalendarMonthly";
import EmailTemplatesDashboard from "../../features/emailTemplates/EmailTemplatesDashboard";
import EmailDashboard from "../../features/emails/EmailDashboard";
import EmailHistoryPage from "../../features/emails/emailComponents/EmailHistoryPage";
import ManagerAnalyticsDashboard from "../../features/managers/managerAnalytics/ManagerAnalyticsDashboard";
import TransactionCompleteDashboard from "../../features/transactions/transactionComplete/TransactionCompleteDashboard";

ReactGA.initialize("G-JN8T55W77W");

function MainLayout() {
  return (
    <>
      <OverallData />
      <NavBar />
      <Outlet />
    </>
  );
}

function TransWithNavLayout() {
  return (
    <>
      <TransactionData />
      <NavBarSecondary />
      <Outlet />
    </>
  );
}

function PartyLayout() {
  return (
    <>
      <PartyData />
      <NavBarParty />
      <Outlet />
    </>
  );
}

function PartyAllLayout() {
  return (
    <>
      <PartyAllData />
      <NavBarPartyAll />
      <Outlet />
    </>
  );
}

function ManagerLayout() {
  return (
    <>
      <ManagerData />
      <NavBar />
      <Outlet />
    </>
  );
}

function ManagerAgentLayout() {
  return (
    <>
      <ManagerAgentData />
      <NavBar />
      <Outlet />
    </>
  );
}

function App() {
  const { initialized } = useSelector((state) => state.async);
  const { authenticated } = useSelector((state) => state.auth);
  const { currentUserProfile } = useSelector((state) => state.profile);

  const PrivateRoute = ({ children }) => {
    return authenticated ? children : <Navigate to="/" />;
  };

  const AdminRoute = ({ children }) => {
    return currentUserProfile?.isAdmin ? children : <Navigate to="/overview" />;
  };

  const myFallback = <FallbackComponent />;

  if (!initialized) return <LoadingComponent content="Loading app..." />;

  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <ToastContainer position="top-right" hideProgressBar />
      <ModalManager />
      <Routes>
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<TermsConditions />} />
          <Route path="error" element={<ErrorComponent />} />
          <Route path="partyLogin" element={<PartyLoginPage />} />
          <Route path="party/:partyId">
            <Route index element={<PartyLoginPageOld />} />
            <Route element={authenticated && <PartyLayout />}>
              <Route path="calendar" element={<PartyCalendar />} />
              <Route path="contacts" element={<PartyContacts />} />
              <Route path="documents">
                <Route index element={<PartyOverview />} />
                <Route path=":docId">
                  <Route path="view" element={<PartyDocEditPage />} />
                  <Route path="sign" element={<PartyDocEditPage />} />
                  <Route path="fill" element={<PartyDocEditPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={authenticated && <PartyAllLayout />}>
            <Route
              path="overviewParty"
              element={
                <PrivateRoute>
                  <PartyAllOverview />
                </PrivateRoute>
              }
            />
            <Route
              path="partyAccount"
              element={
                <PrivateRoute>
                  <PartyChangePassword />
                </PrivateRoute>
              }
            />
          </Route>
          <Route element={authenticated && <ManagerAgentLayout />}>
            <Route
              path="agent/:agentId/transactions"
              element={
                <PrivateRoute>
                  <TransactionActiveDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="agent/:agentId/transactionsAll"
              element={
                <PrivateRoute>
                  <TransactionAllDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="agent/:agentId/transactionsComplete"
              element={
                <PrivateRoute>
                  <TransactionCompleteDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="agent/:agentId/overview"
              element={
                <PrivateRoute>
                  <TransactionAllOverview />
                </PrivateRoute>
              }
            />
          </Route>
          <Route element={authenticated && <ManagerLayout />}>
            <Route
              path="overviewManager"
              element={
                <PrivateRoute>
                  <ManagerAgentsActiveOverview />
                </PrivateRoute>
              }
            />
            <Route
              path="overviewManagerAll"
              element={
                <PrivateRoute>
                  <ManagerAgentsAllOverview />
                </PrivateRoute>
              }
            />
            <Route
              path="analyticsManager"
              element={
                <PrivateRoute>
                  <ManagerAnalyticsDashboard />
                </PrivateRoute>
              }
            />
          </Route>
          <Route element={authenticated && <MainLayout />}>
            <Route
              path="overview"
              element={
                <PrivateRoute>
                  <TransactionAllOverview />
                </PrivateRoute>
              }
            />
            <Route
              path="overviewTc"
              element={
                <PrivateRoute>
                  <TcOverview />
                </PrivateRoute>
              }
            />
            <Route
              path="transactionsAll"
              element={
                <PrivateRoute>
                  <TransactionAllDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="transactionsComplete"
              element={
                <PrivateRoute>
                  <TransactionCompleteDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/calendar"
              element={
                <PrivateRoute>
                  <TaskAllCalendarMonthly />
                </PrivateRoute>
              }
            />
            <Route
              path="/people"
              element={
                <PrivateRoute>
                  <PeopleDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/account"
              element={
                <PrivateRoute>
                  <AccountPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/clauses"
              element={
                <PrivateRoute>
                  <ClausesDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="admin"
              element={
                <AdminRoute>
                  <AdminPage />
                </AdminRoute>
              }
            />
            <Route
              path="/profile/:id"
              element={
                <PrivateRoute>
                  <ProfilePage />
                </PrivateRoute>
              }
            />
            <Route path="/formTemplates">
              <Route
                index
                element={
                  <PrivateRoute>
                    <FormTemplatesDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path=":formId/fill"
                element={
                  <PrivateRoute>
                    <FormTemplateEditPage />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/deadlineTemplates">
              <Route
                index
                element={
                  <PrivateRoute>
                    <DeadlineTemplatesDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path=":formId/fill"
                element={
                  <PrivateRoute>
                    <FormTemplateEditPage />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/taskTemplates">
              <Route
                index
                element={
                  <PrivateRoute>
                    <TaskTemplatesDashboard />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/emailTemplates">
              <Route
                index
                element={
                  <PrivateRoute>
                    <EmailTemplatesDashboard />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/payments"
              element={
                <PrivateRoute>
                  <PaymentsDashboard />
                </PrivateRoute>
              }
            />
            <Route path="transactions">
              <Route
                index
                element={
                  <PrivateRoute>
                    <TransactionActiveDashboard />
                  </PrivateRoute>
                }
              />
              <Route path=":id">
                <Route element={<TransWithNavLayout />}>
                  <Route
                    path="overview"
                    element={
                      <PrivateRoute>
                        <TransactionOverview />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="parties"
                    element={
                      <PrivateRoute>
                        <TransactionParties />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="tasks"
                    element={
                      <PrivateRoute>
                        <TaskTransActiveTaskDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="tasksCalendar"
                    element={
                      <PrivateRoute>
                        <TaskTransCalendarMonthly />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="tasksSharing"
                    element={
                      <PrivateRoute>
                        <TaskTransSharingDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="emails"
                    element={
                      <PrivateRoute>
                        <EmailDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="emailsHistory"
                    element={
                      <PrivateRoute>
                        <EmailHistoryPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="history"
                    element={
                      <PrivateRoute>
                        <HistoryDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route path="documents">
                    <Route
                      index
                      element={
                        <PrivateRoute>
                          <DocTransActiveDashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route path=":docId">
                      <Route
                        path="fill"
                        element={
                          <PrivateRoute>
                            <DocEditPage />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="prepare"
                        element={
                          <PrivateRoute>
                            <DocEditPage />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="view"
                        element={
                          <PrivateRoute>
                            <DocEditPage />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="process"
                        element={
                          <PrivateRoute>
                            <DocEditPage />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Sentry.ErrorBoundary>
  );
}

export default App;
