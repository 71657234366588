export function sellersPropertyDisclosureSupplementAdditionalStructure() {
   return [   //2024 Release 2023-12-27 13:54:00
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 240,
      height: 36,
    }
    ,
    {
      page: 0,
      name: "Sellers Property Disclosure Residential", 
      isText: false,
      type: "checkbox",
      top: 255.72,
      left: 175.8,
      width: 12.24,
      height: 12.24
   }
   ,
   {
      page: 0,
      name: "Sellers Property Disclosure Land", 
      isText: false,
      type: "checkbox",
      top: 280.68,
      left: 175.8,
      width: 12.24,
      height: 12.24
   }
   ,
   {
      page: 0,
      name: "Sellers Property Disclosure Commercial", 
      isText: false,
      type: "checkbox",
      top: 305.64,
      left: 175.8,
      width: 12.24,
      height: 12.24
   }
   ,
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 526.284,
      left: 109.2,
      width: 440.04,
      height: 14.316
   }
   ,
   {
      page: 0,
      name: "Property", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 545.226,
      left: 114.24,
      width: 435.00,
      height: 15.054
   }
   ,
   {
      page: 0,
      name: "Seller", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 566.797,
      left: 109.2,
      width: 440.04,
      height: 14.403
   }
   ,
   {
      page: 0,
      name: "Residential Dwelling", 
      isText: false,
      type: "checkbox",
      top: 590.04,
      left: 193.8,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "Barn", 
      isText: false,
      type: "checkbox",
      top: 590.04,
      left: 312.6,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "Detached Garage", 
      isText: false,
      type: "checkbox",
      top: 590.04,
      left: 364.92,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "Additional Structure Type", 
      isText: false,
      type: "checkbox",
      top: 609.96,
      left: 97.8,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "Other", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 605.397,
      left: 145.8,
      width: 405.0,
      height: 14.403
   }
   ,
   {
      page: 0,
      name: "Additional Structure DescriptionName", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 623.124,
      left: 239.28,
      width: 309.96,
      height: 16.356
   }
   ,
   {
      page: 0,
      name: "Year Built", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 643.044,
      left: 126.72,
      width: 424.92,
      height: 16.356
   }
   ,
   {
      page: 1,
      name: "Check Box229", 
      isText: false,
      type: "checkbox",
      top: 184.966,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 167.16,
      left: 215.76,
      width: 359.16,
      height: 50.28
   }
   ,
   {
      page: 1,
      name: "Check Box230", 
      isText: false,
      type: "checkbox",
      top: 231.843,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 219.24,
      left: 215.76,
      width: 359.16,
      height: 40.20
   }
   ,
   {
      page: 1,
      name: "Check Box231", 
      isText: false,
      type: "checkbox",
      top: 270.954,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 262.44,
      left: 215.52,
      width: 359.64,
      height: 29.88
   }
   ,
   {
      page: 1,
      name: "Check Box232", 
      isText: false,
      type: "checkbox",
      top: 308.367,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 295.32,
      left: 215.76,
      width: 359.16,
      height: 40.20
   }
   ,
   {
      page: 1,
      name: "Check Box233", 
      isText: false,
      type: "checkbox",
      top: 351.035,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 337.32,
      left: 215.76,
      width: 359.16,
      height: 40.20
   }
   ,
   {
      page: 1,
      name: "Check Box234", 
      isText: false,
      type: "checkbox",
      top: 388.146,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 379.2,
      left: 215.52,
      width: 359.64,
      height: 29.28
   }
   ,
   {
      page: 1,
      name: "Check Box235", 
      isText: false,
      type: "checkbox",
      top: 418.7,
      left: 192.414,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 1,
      name: "Comments_7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 410.76,
      left: 215.52,
      width: 359.64,
      height: 29.28
   }
   ,
   {
      page: 1,
      name: "Check Box236", 
      isText: false,
      type: "checkbox",
      top: 450.649,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_8", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 442.32,
      left: 215.52,
      width: 359.64,
      height: 29.28
   }
   ,
   {
      page: 1,
      name: "Subfloors9", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 473.76,
      left: 62.4,
      width: 119.16,
      height: 29.28
   }
   ,
   {
      page: 1,
      name: "Check Box237", 
      isText: false,
      type: "checkbox",
      top: 481.249,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_9", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 473.76,
      left: 215.52,
      width: 359.64,
      height: 29.28
   }
   ,
   {
      page: 1,
      name: "Subfloors10", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 504.84,
      left: 62.16,
      width: 119.64,
      height: 20.04
   }
   ,
   {
      page: 1,
      name: "Check Box238", 
      isText: false,
      type: "checkbox",
      top: 508.594,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_10", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 504.84,
      left: 215.28,
      width: 360.12,
      height: 20.04
   }
   ,
   {
      page: 1,
      name: "Check Box239", 
      isText: false,
      type: "checkbox",
      top: 591.28,
      left: 192.414,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 1,
      name: "Comments_11", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 583.32,
      left: 215.52,
      width: 359.64,
      height: 29.28
   }
   ,
   {
      page: 1,
      name: "Check Box240", 
      isText: false,
      type: "checkbox",
      top: 622.485,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_12", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 614.88,
      left: 215.52,
      width: 359.64,
      height: 29.28
   }
   ,
   {
      page: 1,
      name: "Check Box241", 
      isText: false,
      type: "checkbox",
      top: 654.132,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_13", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 646.44,
      left: 215.52,
      width: 359.64,
      height: 29.28
   }
   ,
   {
      page: 1,
      name: "Check Box242", 
      isText: false,
      type: "checkbox",
      top: 685.685,
      left: 192.414,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Comments_14", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 678,
      left: 215.52,
      width: 359.64,
      height: 29.28
   }
   ,
   {
      page: 1,
      name: "Check Box243", 
      isText: false,
      type: "checkbox",
      top: 717.5875,
      left: 192.414,
      width: 12.240,
      height: 12.2400
   }
   ,
   {
      page: 1,
      name: "Comments_15", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 709.56,
      left: 215.52,
      width: 359.64,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "6_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 48.72,
      left: 62.28,
      width: 119.40,
      height: 24.48
   }
   ,
   {
      page: 2,
      name: "Check Box244", 
      isText: false,
      type: "checkbox",
      top: 54.148,
      left: 191.763,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "undefined_17", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 48.72,
      left: 215.4,
      width: 359.88,
      height: 24.48
   }
   ,
   {
      page: 2,
      name: "7_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 74.76,
      left: 62.4,
      width: 119.16,
      height: 27.00
   }
   ,
   {
      page: 2,
      name: "Check Box245", 
      isText: false,
      type: "checkbox",
      top: 81.493,
      left: 191.763,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "undefined_19", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 74.76,
      left: 215.52,
      width: 359.64,
      height: 27.00
   }
   ,
   {
      page: 2,
      name: "Transferable", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 162.12,
      left: 67.2,
      width: 135.0,
      height: 11.64
   }
   ,
   {
      page: 2,
      name: "Check Box312", 
      isText: false,
      type: "checkbox",
      top: 177.741,
      left: 122.56,
      width: 10.80,
      height: 10.800
   }
   ,
   {
      page: 2,
      name: "Check Box313", 
      isText: false,
      type: "checkbox",
      top: 177.741,
      left: 160.829,
      width: 10.800,
      height: 10.800
   }
   ,
   {
      page: 2,
      name: "Check Box246", 
      isText: false,
      type: "checkbox",
      top: 164.83,
      left: 223.666,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 2,
      name: "Comments_16", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 152.52,
      left: 247.2,
      width: 327.48,
      height: 39.24
   }
   ,
   {
      page: 2,
      name: "Check Box247", 
      isText: false,
      type: "checkbox",
      top: 206.8,
      left: 223.666,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 2,
      name: "Comments_17", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 193.56,
      left: 247.2,
      width: 327.48,
      height: 40.20
   }
   ,
   {
      page: 2,
      name: "Age", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 249.72,
      left: 67.2,
      width: 139.44,
      height: 11.64
   }
   ,
   {
      page: 2,
      name: "undefined_24", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 265.56,
      left: 87,
      width: 121.44,
      height: 11.64
   }
   ,
   {
      page: 2,
      name: "Check Box248", 
      isText: false,
      type: "checkbox",
      top: 251.771,
      left: 223.666,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "Comments_18", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 235.56,
      left: 247.2,
      width: 327.48,
      height: 45.96
   }
   ,
   {
      page: 2,
      name: "Roof material Age11", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 8,
      top: 283.32,
      left: 62.4,
      width: 150.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box249", 
      isText: false,
      type: "checkbox",
      top: 291.439,
      left: 223.666,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "Comments_19", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 283.32,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box250", 
      isText: false,
      type: "checkbox",
      top: 403.006,
      left: 180.997,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "Age If Known", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 395.16,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_20", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 395.16,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box251", 
      isText: false,
      type: "checkbox",
      top: 434.023,
      left: 180.997,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "Age If Known_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 426.72,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_21", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 426.72,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box252", 
      isText: false,
      type: "checkbox",
      top: 465.321,
      left: 180.997,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "Age If Known_3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 458.28,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_22", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 458.28,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box253", 
      isText: false,
      type: "checkbox",
      top: 497.828,
      left: 180.997,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "Age If Known_4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 489.84,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_23", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 489.84,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box254", 
      isText: false,
      type: "checkbox",
      top: 528.777,
      left: 180.997,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "Age If Known_5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 521.28,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_24", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 521.28,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box255", 
      isText: false,
      type: "checkbox",
      top: 559.982,
      left: 180.997,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "Age If Known_6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 552.84,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_25", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 552.84,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box256", 
      isText: false,
      type: "checkbox",
      top: 591.582,
      left: 180.997,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "Age If Known_7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 584.4,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_26", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 584.4,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box257", 
      isText: false,
      type: "checkbox",
      top: 624.089,
      left: 180.997,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 2,
      name: "Age If Known_8", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 615.96,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_27", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 615.96,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box258", 
      isText: false,
      type: "checkbox",
      top: 656.34,
      left: 180.997,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 2,
      name: "Age If Known_9", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 647.52,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_28", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 647.52,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box259", 
      isText: false,
      type: "checkbox",
      top: 687.591,
      left: 180.997,
      width: 12.240,
      height: 12.2405
   }
   ,
   {
      page: 2,
      name: "Age If Known_10", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 679.08,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_29", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 679.08,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Check Box260", 
      isText: false,
      type: "checkbox",
      top: 718.2853,
      left: 180.997,
      width: 12.240,
      height: 12.2400
   }
   ,
   {
      page: 2,
      name: "Age If Known_11", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 710.64,
      left: 201.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 2,
      name: "Comments_30", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 710.64,
      left: 246.96,
      width: 327.96,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Check Box261", 
      isText: false,
      type: "checkbox",
      top: 61.961,
      left: 181.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "undefined_38", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 49.08,
      left: 202.08,
      width: 46.92,
      height: 39.96
   }
   ,
   {
      page: 3,
      name: "Refrigerator", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 49.767,
      left: 251.564,
      width: 321.045,
      height: 37.309
   }
   ,
   {
      page: 3,
      name: "Owned", 
      isText: false,
      type: "checkbox",
      top: 105.24,
      left: 83.16,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 3,
      name: "Leased", 
      isText: false,
      type: "checkbox",
      top: 105.24,
      left: 129.96,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 3,
      name: "Check Box262", 
      isText: false,
      type: "checkbox",
      top: 99.071,
      left: 181.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "undefined_40", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 90.92,
      left: 201.84,
      width: 47.40,
      height: 28.80
   }
   ,
   {
      page: 3,
      name: "12", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 89.695,
      left: 251.564,
      width: 321.045,
      height: 29.454
   }
   ,
   {
      page: 3,
      name: "Owned_2", 
      isText: false,
      type: "checkbox",
      top: 136.92,
      left: 78.36,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 3,
      name: "Leased_2", 
      isText: false,
      type: "checkbox",
      top: 136.92,
      left: 125.4,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 3,
      name: "Check Box263", 
      isText: false,
      type: "checkbox",
      top: 130.974,
      left: 181.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "undefined_42", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 122.36,
      left: 201.84,
      width: 47.40,
      height: 28.80
   }
   ,
   {
      page: 3,
      name: "13", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 121.895,
      left: 251.564,
      width: 321.045,
      height: 29.454
   }
   ,
   {
      page: 3,
      name: "Check Box264", 
      isText: false,
      type: "checkbox",
      top: 161.574,
      left: 181.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "undefined_44", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 154.04,
      left: 201.84,
      width: 47.40,
      height: 28.80
   }
   ,
   {
      page: 3,
      name: "14", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 153.575,
      left: 251.564,
      width: 321.045,
      height: 29.454
   }
   ,
   {
      page: 3,
      name: "Trash compactor16", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 185.52,
      left: 62.4,
      width: 110.28,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Check Box265", 
      isText: false,
      type: "checkbox",
      top: 193.477,
      left: 181.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "undefined_46", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 185.48,
      left: 201.84,
      width: 47.40,
      height: 28.80
   }
   ,
   {
      page: 3,
      name: "15", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 185.015,
      left: 251.564,
      width: 321.045,
      height: 29.454
   }
   ,
   {
      page: 3,
      name: "Trash compactor17", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 216.6,
      left: 62.16,
      width: 110.76,
      height: 20.04
   }
   ,
   {
      page: 3,
      name: "Check Box266", 
      isText: false,
      type: "checkbox",
      top: 220.17,
      left: 181.346,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 3,
      name: "undefined_48", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 216.6,
      left: 201.6,
      width: 47.88,
      height: 20.04
   }
   ,
   {
      page: 3,
      name: "D", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 216.393,
      left: 251.226,
      width: 321.111,
      height: 17.869
   }
   ,
   {
      page: 3,
      name: "Owned_3", 
      isText: false,
      type: "checkbox",
      top: 332.76,
      left: 87.48,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 3,
      name: "Leased_3", 
      isText: false,
      type: "checkbox",
      top: 332.76,
      left: 134.28,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 3,
      name: "Check Box267", 
      isText: false,
      type: "checkbox",
      top: 325.342,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_12", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 317.88,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_31", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 317.88,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Battery", 
      isText: false,
      type: "checkbox",
      top: 364.44,
      left: 78.6,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 3,
      name: "Hardwire", 
      isText: false,
      type: "checkbox",
      top: 364.44,
      left: 126.12,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 3,
      name: "Check Box268", 
      isText: false,
      type: "checkbox",
      top: 357.546,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_13", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 349.44,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_32", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 349.44,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Battery_2", 
      isText: false,
      type: "checkbox",
      top: 395.88,
      left: 78.6,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 3,
      name: "Hardwire_2", 
      isText: false,
      type: "checkbox",
      top: 395.88,
      left: 126.12,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 3,
      name: "Check Box269", 
      isText: false,
      type: "checkbox",
      top: 389.146,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_14", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 381,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_33", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 381,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Check Box270", 
      isText: false,
      type: "checkbox",
      top: 420.398,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_15", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 412.56,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_34", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 412.56,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Check Box271", 
      isText: false,
      type: "checkbox",
      top: 451.951,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_16", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 444,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_35", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 444,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Check Box272", 
      isText: false,
      type: "checkbox",
      top: 483.202,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_17", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 475.56,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_36", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 475.56,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Check Box273", 
      isText: false,
      type: "checkbox",
      top: 515.058,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_18", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 507.12,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_37", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 507.12,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Check Box274", 
      isText: false,
      type: "checkbox",
      top: 545.705,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_19", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 538.68,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_38", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 538.68,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "remoteopeners", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 593.88,
      left: 129.72,
      width: 45.00,
      height: 11.16
   }
   ,
   {
      page: 3,
      name: "Check Box275", 
      isText: false,
      type: "checkbox",
      top: 582.165,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_20", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 570.36,
      left: 211.2,
      width: 46.68,
      height: 37.56
   }
   ,
   {
      page: 3,
      name: "Comments_39", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 570.36,
      left: 260.64,
      width: 314.28,
      height: 37.56
   }
   ,
   {
      page: 3,
      name: "Check Box276", 
      isText: false,
      type: "checkbox",
      top: 617.323,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_21", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 609.72,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_40", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 609.72,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Check Box277", 
      isText: false,
      type: "checkbox",
      top: 648.574,
      left: 189.346,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 3,
      name: "Age If Known_22", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 641.28,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_41", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 641.28,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Inwall speakers12", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 8,
      top: 672.72,
      left: 62.4,
      width: 119.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Check Box278", 
      isText: false,
      type: "checkbox",
      top: 680.43,
      left: 189.346,
      width: 12.240,
      height: 12.2397
   }
   ,
   {
      page: 3,
      name: "Age If Known_23", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 672.72,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_42", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 672.72,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Inwall speakers13", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 8,
      top: 704.28,
      left: 62.4,
      width: 119.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Check Box279", 
      isText: false,
      type: "checkbox",
      top: 712.03,
      left: 189.346,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 3,
      name: "Age If Known_24", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 704.28,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 3,
      name: "Comments_43", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 704.28,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Check Box280", 
      isText: false,
      type: "checkbox",
      top: 133.229,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_25", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 120.84,
      left: 211.2,
      width: 46.68,
      height: 40.20
   }
   ,
   {
      page: 4,
      name: "Comments_44", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 120.84,
      left: 260.64,
      width: 314.28,
      height: 40.20
   }
   ,
   {
      page: 4,
      name: "Check Box281", 
      isText: false,
      type: "checkbox",
      top: 170.991,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_26", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 162.72,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Comments_45", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 162.72,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "undefined_64", 
      isText: false,
      type: "checkbox",
      top: 209.16,
      left: 87.24,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 4,
      name: "undefined_65", 
      isText: false,
      type: "checkbox",
      top: 209.16,
      left: 134.28,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 4,
      name: "Check Box282", 
      isText: false,
      type: "checkbox",
      top: 207.404,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_27", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 194.28,
      left: 211.2,
      width: 46.68,
      height: 40.20
   }
   ,
   {
      page: 4,
      name: "Comments_46", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 194.28,
      left: 260.64,
      width: 314.28,
      height: 40.20
   }
   ,
   {
      page: 4,
      name: "Owned_4", 
      isText: false,
      type: "checkbox",
      top: 251.16,
      left: 87.72,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 4,
      name: "Leased_4", 
      isText: false,
      type: "checkbox",
      top: 251.16,
      left: 134.28,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 4,
      name: "Check Box283", 
      isText: false,
      type: "checkbox",
      top: 244.515,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_28", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 236.16,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Comments_47", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 236.16,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Check Box284", 
      isText: false,
      type: "checkbox",
      top: 280.673,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_29", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 267.72,
      left: 211.2,
      width: 46.68,
      height: 40.20
   }
   ,
   {
      page: 4,
      name: "Comments_48", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 267.72,
      left: 260.64,
      width: 314.28,
      height: 40.20
   }
   ,
   {
      page: 4,
      name: "Electric Wiring or Panel19", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 309.6,
      left: 62.4,
      width: 119.16,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Check Box285", 
      isText: false,
      type: "checkbox",
      top: 317.18,
      left: 189.81,
      width: 12.24,
      height: 12.24
   }
   ,
   {
      page: 4,
      name: "Age If Known_30", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 309.6,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Comments_49", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 309.6,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Electric Wiring or Panel20", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 341.04,
      left: 62.4,
      width: 119.16,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Check Box286", 
      isText: false,
      type: "checkbox",
      top: 348.733,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_31", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 341.04,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Comments_50", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 341.04,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Check Box287", 
      isText: false,
      type: "checkbox",
      top: 449.649,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_32", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 442.8,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Comments_51", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 442.8,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Amps", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 496.2,
      left: 67.2,
      width: 108.0,
      height: 9.72
   }
   ,
   {
      page: 4,
      name: "Check Box288", 
      isText: false,
      type: "checkbox",
      top: 484.505,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_33", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 476.64,
      left: 210.96,
      width: 47.16,
      height: 30.48
   }
   ,
   {
      page: 4,
      name: "Comments_52", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 476.64,
      left: 260.4,
      width: 314.76,
      height: 30.48
   }
   ,
   {
      page: 4,
      name: "Check Box289", 
      isText: false,
      type: "checkbox",
      top: 518.709,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_34", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 510.48,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Comments_53", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 510.48,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Electric Provider", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 553.499,
      left: 63.9446,
      width: 115.1614,
      height: 22.741
   }
   ,
   {
      page: 4,
      name: "Check Box290", 
      isText: false,
      type: "checkbox",
      top: 553.82,
      left: 189.81,
      width: 12.24,
      height: 12.24
   }
   ,
   {
      page: 4,
      name: "Age If Known_35", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 542.04,
      left: 211.2,
      width: 46.68,
      height: 37.08
   }
   ,
   {
      page: 4,
      name: "Comments_54", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 542.04,
      left: 260.64,
      width: 314.28,
      height: 37.08
   }
   ,
   {
      page: 4,
      name: "CableTV provider", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 592.16,
      left: 63.9446,
      width: 115.1614,
      height: 22.741
   }
   ,
   {
      page: 4,
      name: "Check Box291", 
      isText: false,
      type: "checkbox",
      top: 592.535,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_36", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 580.92,
      left: 211.2,
      width: 46.68,
      height: 36.84
   }
   ,
   {
      page: 4,
      name: "Comments_55", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 580.92,
      left: 260.64,
      width: 314.28,
      height: 36.84
   }
   ,
   {
      page: 4,
      name: "Sellers Internet Provider", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 630.04,
      left: 63.9446,
      width: 115.1614,
      height: 22.741
   }
   ,
   {
      page: 4,
      name: "Check Box292", 
      isText: false,
      type: "checkbox",
      top: 630.646,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_37", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 619.56,
      left: 211.2,
      width: 46.68,
      height: 37.08
   }
   ,
   {
      page: 4,
      name: "Comments_56", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 619.56,
      left: 260.64,
      width: 314.28,
      height: 37.08
   }
   ,
   {
      page: 4,
      name: "Sellers Internet Provider27", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 658.44,
      left: 62.4,
      width: 119.16,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Check Box293", 
      isText: false,
      type: "checkbox",
      top: 665.804,
      left: 189.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 4,
      name: "Age If Known_38", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 658.44,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 4,
      name: "Comments_57", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 658.44,
      left: 260.4,
      width: 314.76,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box294", 
      isText: false,
      type: "checkbox",
      top: 114.348,
      left: 189.112,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_39", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 105.96,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_58", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 105.96,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box295", 
      isText: false,
      type: "checkbox",
      top: 145.902,
      left: 189.112,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_40", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 137.52,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_59", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 137.52,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box296", 
      isText: false,
      type: "checkbox",
      top: 177.502,
      left: 189.112,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_41", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 169.08,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_60", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 169.08,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "sumpPumpsNumber", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 217.744,
      left: 64.1132,
      width: 112.4778,
      height: 13.118
   }
   ,
   {
      page: 5,
      name: "Check Box297", 
      isText: false,
      type: "checkbox",
      top: 210.707,
      left: 189.112,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_42", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 202.8,
      left: 210.96,
      width: 47.16,
      height: 30.36
   }
   ,
   {
      page: 5,
      name: "Comments_61", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 202.8,
      left: 260.4,
      width: 315.48,
      height: 30.36
   }
   ,
   {
      page: 5,
      name: "Recycle pump", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 252.631,
      left: 62.16,
      width: 119.64,
      height: 13.769
   }
   ,
   {
      page: 5,
      name: "Check Box298", 
      isText: false,
      type: "checkbox",
      top: 243.609,
      left: 189.112,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_43", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 236.52,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_62", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 236.52,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box299", 
      isText: false,
      type: "checkbox",
      top: 276.767,
      left: 189.112,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_44", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 268.08,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_63", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 268.08,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Lifts or Hoists7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 299.16,
      left: 62.16,
      width: 119.64,
      height: 19.80
   }
   ,
   {
      page: 5,
      name: "Check Box300", 
      isText: false,
      type: "checkbox",
      top: 301.554,
      left: 189.112,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_45", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 299.16,
      left: 210.72,
      width: 47.64,
      height: 19.80
   }
   ,
   {
      page: 5,
      name: "Comments_64", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 299.16,
      left: 260.16,
      width: 315.96,
      height: 19.80
   }
   ,
   {
      page: 5,
      name: "Lifts or Hoists8", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 320.28,
      left: 62.16,
      width: 119.64,
      height: 20.04
   }
   ,
   {
      page: 5,
      name: "Check Box301", 
      isText: false,
      type: "checkbox",
      top: 323.039,
      left: 189.112,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_46", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 320.28,
      left: 210.72,
      width: 47.64,
      height: 20.04
   }
   ,
   {
      page: 5,
      name: "Comments_65", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 320.28,
      left: 260.16,
      width: 315.96,
      height: 20.04
   }
   ,
   {
      page: 5,
      name: "Check Box302", 
      isText: false,
      type: "checkbox",
      top: 429.815,
      left: 188.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_47", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 421.56,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_66", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 421.56,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box303", 
      isText: false,
      type: "checkbox",
      top: 460.717,
      left: 188.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_48", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 453.12,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_67", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 453.12,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box304", 
      isText: false,
      type: "checkbox",
      top: 492.573,
      left: 188.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_49", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 484.68,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_68", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 484.68,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box305", 
      isText: false,
      type: "checkbox",
      top: 524.126,
      left: 188.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_50", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 516.24,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_69", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 516.24,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box306", 
      isText: false,
      type: "checkbox",
      top: 554.982,
      left: 188.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_51", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 547.8,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_70", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 547.8,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box307", 
      isText: false,
      type: "checkbox",
      top: 587.327,
      left: 188.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_52", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 579.36,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_71", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 579.36,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box308", 
      isText: false,
      type: "checkbox",
      top: 619.229,
      left: 188.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_53", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 610.92,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_72", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 610.92,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box309", 
      isText: false,
      type: "checkbox",
      top: 650.481,
      left: 188.81,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 5,
      name: "Age If Known_54", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 642.48,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_73", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 642.48,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box310", 
      isText: false,
      type: "checkbox",
      top: 682.383,
      left: 188.81,
      width: 12.24,
      height: 12.2399
   }
   ,
   {
      page: 5,
      name: "Age If Known_55", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 674.04,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_74", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 674.04,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Check Box311", 
      isText: false,
      type: "checkbox",
      top: 714.2853,
      left: 188.81,
      width: 12.24,
      height: 12.2400
   }
   ,
   {
      page: 5,
      name: "Age If Known_56", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 705.6,
      left: 210.96,
      width: 47.16,
      height: 29.28
   }
   ,
   {
      page: 5,
      name: "Comments_75", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 705.6,
      left: 260.4,
      width: 315.48,
      height: 29.28
   }
   ,
   {
      page: 6,
      name: "Check Box214", 
      isText: false,
      type: "checkbox",
      top: 57.403,
      left: 189.461,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 6,
      name: "undefined_97", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 49.92,
      left: 210.96,
      width: 47.16,
      height: 28.80
   }
   ,
   {
      page: 6,
      name: "Heating Stove", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 49.102,
      left: 261.143,
      width: 312.077,
      height: 27.000
   }
   ,
   {
      page: 6,
      name: "Check Box215", 
      isText: false,
      type: "checkbox",
      top: 88.545,
      left: 189.461,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 6,
      name: "undefined_99", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 81.6,
      left: 210.96,
      width: 47.16,
      height: 28.8
   }
   ,
   {
      page: 6,
      name: "12_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 81.196,
      left: 261.143,
      width: 312.077,
      height: 27.000
   }
   ,
   {
      page: 6,
      name: "Fuel tanks13", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 112.08,
      left: 62.4,
      width: 119.16,
      height: 29.28
   }
   ,
   {
      page: 6,
      name: "Check Box216", 
      isText: false,
      type: "checkbox",
      top: 120.448,
      left: 189.461,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 6,
      name: "undefined_101", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 113.04,
      left: 210.96,
      width: 47.16,
      height: 28.80
   }
   ,
   {
      page: 6,
      name: "13_Comments", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 112.102,
      left: 261.143,
      width: 312.077,
      height: 27.000
   }
   ,
   {
      page: 6,
      name: "Fuel tanks14", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 143.16,
      left: 62.16,
      width: 119.64,
      height: 18.738
   }
   ,
   {
      page: 6,
      name: "Check Box217", 
      isText: false,
      type: "checkbox",
      top: 146.793,
      left: 189.461,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 6,
      name: "undefined_103", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 143.16,
      left: 210.72,
      width: 47.64,
      height: 20.04
   }
   ,
   {
      page: 6,
      name: "14Row1", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 143.455,
      left: 261.143,
      width: 312.077,
      height: 18.545
   }
   ,
   {
      page: 6,
      name: "Type", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 232.2,
      left: 91.2,
      width: 108.0,
      height: 9.72
   }
   ,
   {
      page: 6,
      name: "Fuel", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 243,
      left: 86.4,
      width: 112.56,
      height: 11.16
   }
   ,
   {
      page: 6,
      name: "Type_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 259.08,
      left: 91.2,
      width: 108.0,
      height: 11.16
   }
   ,
   {
      page: 6,
      name: "Fuel_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 270.84,
      left: 86.4,
      width: 112.56,
      height: 11.16
   }
   ,
   {
      page: 6,
      name: "Check Box218", 
      isText: false,
      type: "checkbox",
      top: 246.515,
      left: 217.108,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 6,
      name: "Age If Known_57", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 221.4,
      left: 238.08,
      width: 42.36,
      height: 65.16
   }
   ,
   {
      page: 6,
      name: "Comments_76", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 221.4,
      left: 283.2,
      width: 292.44,
      height: 65.16
   }
   ,
   {
      page: 6,
      name: "Type_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 301.32,
      left: 91.2,
      width: 108.0,
      height: 11.16
   }
   ,
   {
      page: 6,
      name: "Fuel_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 313.32,
      left: 86.4,
      width: 112.56,
      height: 11.16
   }
   ,
   {
      page: 6,
      name: "Check Box219", 
      isText: false,
      type: "checkbox",
      top: 302.856,
      left: 217.108,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 6,
      name: "Age If Known_58", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 288.36,
      left: 238.08,
      width: 42.36,
      height: 42.60
   }
   ,
   {
      page: 6,
      name: "Comments_77", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 288.36,
      left: 283.2,
      width: 292.44,
      height: 42.60
   }
   ,
   {
      page: 6,
      name: "Type_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 345.48,
      left: 91.2,
      width: 108.0,
      height: 11.16
   }
   ,
   {
      page: 6,
      name: "Fuel_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 359.16,
      left: 86.4,
      width: 112.56,
      height: 11.16
   }
   ,
   {
      page: 6,
      name: "Check Box220", 
      isText: false,
      type: "checkbox",
      top: 346.78,
      left: 217.108,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 6,
      name: "Age If Known_59", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 332.76,
      left: 238.08,
      width: 42.36,
      height: 42.12
   }
   ,
   {
      page: 6,
      name: "Comments_78", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 332.76,
      left: 283.2,
      width: 292.44,
      height: 42.12
   }
   ,
   {
      page: 6,
      name: "Date_2", 
      fontSize: 9,
      type: "date",
      top: 398.52,
      left: 88.92,
      width: 112.56,
      height: 10.92
   }
   ,
   {
      page: 6,
      name: "Do not know", 
      isText: false,
      type: "checkbox",
      top: 413.4,
      left: 67.8,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 6,
      name: "Check Box221", 
      isText: false,
      type: "checkbox",
      top: 394.61,
      left: 217.108,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 6,
      name: "Age If Known_60", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 376.68,
      left: 238.08,
      width: 42.36,
      height: 50.52
   }
   ,
   {
      page: 6,
      name: "Comments_79", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 376.68,
      left: 283.2,
      width: 292.44,
      height: 50.52
   }
   ,
   {
      page: 6,
      name: "Owned_5", 
      isText: false,
      type: "checkbox",
      top: 443.88,
      left: 115.32,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 6,
      name: "Leased_5", 
      isText: false,
      type: "checkbox",
      top: 443.88,
      left: 161.88,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 6,
      name: "Check Box222", 
      isText: false,
      type: "checkbox",
      top: 436.93,
      left: 217.108,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 6,
      name: "Age If Known_61", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 428.88,
      left: 237.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 6,
      name: "Comments_80", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 428.88,
      left: 282.96,
      width: 292.92,
      height: 29.28
   }
   ,
   {
      page: 6,
      name: "Interior", 
      isText: false,
      type: "checkbox",
      top: 474.36,
      left: 67.8,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 6,
      name: "Exterior", 
      isText: false,
      type: "checkbox",
      top: 474.36,
      left: 115.32,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 6,
      name: "Type_5", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 484.68,
      left: 90.48,
      width: 108.00,
      height: 11.16
   }
   ,
   {
      page: 6,
      name: "Check Box223", 
      isText: false,
      type: "checkbox",
      top: 473.087,
      left: 217.108,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 6,
      name: "Age If Known_62", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 460.44,
      left: 238.08,
      width: 42.36,
      height: 40.20
   }
   ,
   {
      page: 6,
      name: "Comments_81", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 460.44,
      left: 283.2,
      width: 292.44,
      height: 40.20
   }
   ,
   {
      page: 6,
      name: "21_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 514.489,
      left: 64.2,
      width: 142.165,
      height: 14.591
   }
   ,
   {
      page: 6,
      name: "Check Box224", 
      isText: false,
      type: "checkbox",
      top: 510.547,
      left: 217.108,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 6,
      name: "Age If Known_63", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 502.32,
      left: 237.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 6,
      name: "Comments_82", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 502.32,
      left: 282.96,
      width: 292.92,
      height: 29.28
   }
   ,
   {
      page: 6,
      name: "Fuel Provider22", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 533.88,
      left: 62.4,
      width: 146.28,
      height: 29.28
   }
   ,
   {
      page: 6,
      name: "Check Box225", 
      isText: false,
      type: "checkbox",
      top: 541.101,
      left: 217.108,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 6,
      name: "Age If Known_64", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 533.88,
      left: 237.84,
      width: 42.84,
      height: 29.28
   }
   ,
   {
      page: 6,
      name: "Comments_83", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 533.88,
      left: 282.96,
      width: 292.92,
      height: 29.28
   }
   ,
   {
      page: 6,
      name: "Check Box226", 
      isText: false,
      type: "checkbox",
      top: 645.97,
      left: 169.789,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 6,
      name: "Age If Known_65", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 633.48,
      left: 194.08,
      width: 42.60,
      height: 38.658
   }
   ,
   {
      page: 6,
      name: "Comments_84", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 633.48,
      left: 239.44,
      width: 333.96,
      height: 38.389
   }
   ,
   {
      page: 6,
      name: "Check Box227", 
      isText: false,
      type: "checkbox",
      top: 683.034,
      left: 169.789,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 6,
      name: "Age If Known_66", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 675.24,
      left: 193.84,
      width: 43.08,
      height: 27.9779
   }
   ,
   {
      page: 6,
      name: "Comments_85", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 675.24,
      left: 239.2,
      width: 333.96,
      height: 27.4402
   }
   ,
   {
      page: 6,
      name: "Check Box228", 
      isText: false,
      type: "checkbox",
      top: 714.6343,
      left: 169.789,
      width: 12.240,
      height: 12.2400
   }
   ,
   {
      page: 6,
      name: "Age If Known_67", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 706.8,
      left: 193.84,
      width: 43.08,
      height: 27.9709
   }
   ,
   {
      page: 6,
      name: "Comments_86", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 706.8,
      left: 239.2,
      width: 333.96,
      height: 27.3164
   }
   ,
   {
      page: 7,
      name: "Check Box207", 
      isText: false,
      type: "checkbox",
      top: 57.403,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "undefined_116", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 49.92,
      left: 196.84,
      width: 43.08,
      height: 28.80
   }
   ,
   {
      page: 7,
      name: "4_7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 49.516,
      left: 243.074,
      width: 326.908,
      height: 27.240
   }
   ,
   {
      page: 7,
      name: "Check Box208", 
      isText: false,
      type: "checkbox",
      top: 88.654,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "undefined_118", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 81.6,
      left: 196.84,
      width: 43.08,
      height: 28.8
   }
   ,
   {
      page: 7,
      name: "5_7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 80.956,
      left: 243.074,
      width: 326.908,
      height: 27.240
   }
   ,
   {
      page: 7,
      name: "Check Box209", 
      isText: false,
      type: "checkbox",
      top: 119.906,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "undefined_120", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 113.04,
      left: 196.84,
      width: 43.08,
      height: 28.80
   }
   ,
   {
      page: 7,
      name: "6_7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 112.636,
      left: 243.074,
      width: 326.908,
      height: 27.240
   }
   ,
   {
      page: 7,
      name: "Check Box210", 
      isText: false,
      type: "checkbox",
      top: 151.506,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "undefined_122", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 144.72,
      left: 196.84,
      width: 43.08,
      height: 28.80
   }
   ,
   {
      page: 7,
      name: "7_6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 144.076,
      left: 243.074,
      width: 326.908,
      height: 27.240
   }
   ,
   {
      page: 7,
      name: "Check Box211", 
      isText: false,
      type: "checkbox",
      top: 183.013,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "undefined_124", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 176.16,
      left: 196.84,
      width: 43.08,
      height: 28.80
   }
   ,
   {
      page: 7,
      name: "8_6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 175.756,
      left: 243.074,
      width: 326.908,
      height: 27.240
   }
   ,
   {
      page: 7,
      name: "Check Box212", 
      isText: false,
      type: "checkbox",
      top: 214.66,
      left: 173.393,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 7,
      name: "undefined_126", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 207.84,
      left: 196.84,
      width: 43.08,
      height: 28.80
   }
   ,
   {
      page: 7,
      name: "9_5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 207.196,
      left: 243.074,
      width: 326.908,
      height: 27.240
   }
   ,
   {
      page: 7,
      name: "Check Box213", 
      isText: false,
      type: "checkbox",
      top: 246.213,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "undefined_128", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 239.28,
      left: 196.84,
      width: 43.08,
      height: 28.80
   }
   ,
   {
      page: 7,
      name: "10_5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 238.876,
      left: 243.074,
      width: 326.908,
      height: 27.240
   }
   ,
   {
      page: 7,
      name: "Check Box206", 
      isText: false,
      type: "checkbox",
      top: 277.86,
      left: 173.393,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 7,
      name: "undefined_130", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 270.72,
      left: 196.84,
      width: 43.08,
      height: 28.80
   }
   ,
   {
      page: 7,
      name: "11_4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 270.316,
      left: 243.074,
      width: 326.908,
      height: 27.240
   }
   ,
   {
      page: 7,
      name: "Irrigation pump12", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 301.32,
      left: 71.08,
      width: 92.28,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Check Box205", 
      isText: false,
      type: "checkbox",
      top: 308.971,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "undefined_132", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 302.4,
      left: 196.84,
      width: 43.08,
      height: 28.8
   }
   ,
   {
      page: 7,
      name: "extra_Comments_11_5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 301.477,
      left: 243.074,
      width: 326.908,
      height: 27.240
   }
   ,
   {
      page: 7,
      name: "Irrigation pump13", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 332.88,
      left: 71.08,
      width: 92.28,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Check Box204", 
      isText: false,
      type: "checkbox",
      top: 341.223,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "undefined_134", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 333.84,
      left: 196.84,
      width: 43.08,
      height: 28.80
   }
   ,
   {
      page: 7,
      name: "extra_Comments_11_6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 332.874,
      left: 243.074,
      width: 326.908,
      height: 27.240
   }
   ,
   {
      page: 7,
      name: "Check Box203", 
      isText: false,
      type: "checkbox",
      top: 446.743,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "Age If Known_68", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 438.84,
      left: 196.84,
      width: 43.08,
      height: 29.88
   }
   ,
   {
      page: 7,
      name: "Comments_87", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 438.84,
      left: 242.2,
      width: 326.908,
      height: 29.88
   }
   ,
   {
      page: 7,
      name: "Check Box202", 
      isText: false,
      type: "checkbox",
      top: 479.947,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "Age If Known_69", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 471.6,
      left: 196.84,
      width: 43.08,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Comments_88", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 471.6,
      left: 242.2,
      width: 326.908,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Check Box201", 
      isText: false,
      type: "checkbox",
      top: 511.501,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "Age If Known_70", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 503.16,
      left: 196.84,
      width: 43.08,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Comments_89", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 503.16,
      left: 242.2,
      width: 326.908,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Check Box200", 
      isText: false,
      type: "checkbox",
      top: 543.101,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "Age If Known_71", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 534.72,
      left: 196.84,
      width: 43.08,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Comments_90", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 534.72,
      left: 242.2,
      width: 326.908,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Irrigation system18", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 566.28,
      left: 71.08,
      width: 92.28,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Check Box199", 
      isText: false,
      type: "checkbox",
      top: 574.352,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "Age If Known_72", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 566.28,
      left: 196.84,
      width: 43.08,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Comments_91", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 566.28,
      left: 242.2,
      width: 326.908,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Irrigation system19", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 597.84,
      left: 71.08,
      width: 92.28,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Check Box198", 
      isText: false,
      type: "checkbox",
      top: 605.604,
      left: 173.393,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 7,
      name: "Age If Known_73", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 597.84,
      left: 196.84,
      width: 43.08,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Comments_92", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 597.84,
      left: 242.2,
      width: 326.908,
      height: 29.28
   }
   ,
   {
      page: 7,
      name: "Number of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 698.4179,
      left: 117.28,
      width: 70.162,
      height: 12.2221
   }
   ,
   {
      page: 7,
      name: "Fuel type", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 711.0689,
      left: 111.535,
      width: 75.907,
      height: 11.5711
   }
   ,
   {
      page: 7,
      name: "Capacity", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 723.48,
      left: 108.931,
      width: 78.511,
      height: 10.92
   }
   ,
   {
      page: 7,
      name: "Check Box197", 
      isText: false,
      type: "checkbox",
      top: 706.8682,
      left: 198.134,
      width: 12.240,
      height: 12.2400
   }
   ,
   {
      page: 7,
      name: "Age If Known_74", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 689.16,
      left: 219.88,
      width: 42.12,
      height: 49.80
   }
   ,
   {
      page: 7,
      name: "Comments_93", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 689.16,
      left: 264.76,
      width: 302.071,
      height: 49.80
   }
   ,
   {
      page: 8,
      name: "Owned_6", 
      isText: false,
      type: "checkbox",
      top: 63.48,
      left: 90.4,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 8,
      name: "Leased_6", 
      isText: false,
      type: "checkbox",
      top: 63.48,
      left: 137.2,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 8,
      name: "Check Box180", 
      isText: false,
      type: "checkbox",
      top: 57.054,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_143", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 49.92,
      left: 213.64,
      width: 42.60,
      height: 28.80
   }
   ,
   {
      page: 8,
      name: "21_3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 50.825,
      left: 258.914,
      width: 316.397,
      height: 26.008
   }
   ,
   {
      page: 8,
      name: "Owned_7", 
      isText: false,
      type: "checkbox",
      top: 95.16,
      left: 90.4,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 8,
      name: "Leased_7", 
      isText: false,
      type: "checkbox",
      top: 95.16,
      left: 137.2,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 8,
      name: "Check Box181", 
      isText: false,
      type: "checkbox",
      top: 88.654,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_145", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 81.6,
      left: 213.64,
      width: 42.60,
      height: 28.8
   }
   ,
   {
      page: 8,
      name: "22_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 81.156,
      left: 258.914,
      width: 316.397,
      height: 26.008
   }
   ,
   {
      page: 8,
      name: "water shutoffLocation", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 125.051,
      left: 67.5195,
      width: 115.9605,
      height: 22.658
   }
   ,
   {
      page: 8,
      name: "Check Box182", 
      isText: false,
      type: "checkbox",
      top: 124.765,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_147", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 112.2,
      left: 213.88,
      width: 42.12,
      height: 37.458
   }
   ,
   {
      page: 8,
      name: "23_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 113.196,
      left: 258.914,
      width: 316.397,
      height: 35.171
   }
   ,
   {
      page: 8,
      name: "Check Box183", 
      isText: false,
      type: "checkbox",
      top: 160.574,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_149", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 153.6,
      left: 213.64,
      width: 42.60,
      height: 28.8
   }
   ,
   {
      page: 8,
      name: "24_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 154.396,
      left: 258.914,
      width: 316.397,
      height: 26.008
   }
   ,
   {
      page: 8,
      name: "H", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 205.908,
      left: 68.8549,
      width: 113.7931,
      height: 11.530
   }
   ,
   {
      page: 8,
      name: "1_8", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 229.337,
      left: 68.8549,
      width: 113.7931,
      height: 11.530
   }
   ,
   {
      page: 8,
      name: "Check Box184", 
      isText: false,
      type: "checkbox",
      top: 207.498,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_151", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 184.2,
      left: 213.88,
      width: 42.12,
      height: 57.687
   }
   ,
   {
      page: 8,
      name: "25_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 185.196,
      left: 258.914,
      width: 316.397,
      height: 55.462
   }
   ,
   {
      page: 8,
      name: "Check Box185", 
      isText: false,
      type: "checkbox",
      top: 253.375,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_153", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 245.72,
      left: 213.64,
      width: 42.60,
      height: 28.80
   }
   ,
   {
      page: 8,
      name: "26_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 246.276,
      left: 258.914,
      width: 316.397,
      height: 26.008
   }
   ,
   {
      page: 8,
      name: "well pump date1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 345.175,
      left: 88.4037,
      width: 90.8403,
      height: 9.567
   }
   ,
   {
      page: 8,
      name: "Check Box186", 
      isText: false,
      type: "checkbox",
      top: 285.928,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_155", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 278.16,
      left: 213.64,
      width: 42.60,
      height: 28.80
   }
   ,
   {
      page: 8,
      name: "27", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 277.956,
      left: 258.914,
      width: 316.397,
      height: 26.008
   }
   ,
   {
      page: 8,
      name: "Well Pump", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 318.6,
      left: 68.4618,
      width: 110.7822,
      height: 11.16
   }
   ,
   {
      page: 8,
      name: "GPM", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 330.665,
      left: 92.3527,
      width: 86.8913,
      height: 11.815
   }
   ,
   {
      page: 8,
      name: "Check Box187", 
      isText: false,
      type: "checkbox",
      top: 327.597,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_157", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 308.76,
      left: 213.88,
      width: 42.12,
      height: 49.458
   }
   ,
   {
      page: 8,
      name: "28", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 309.756,
      left: 258.914,
      width: 316.397,
      height: 46.953
   }
   ,
   {
      page: 8,
      name: "Text196", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 373.063,
      left: 69.0815,
      width: 75.7785,
      height: 12.885
   }
   ,
   {
      page: 8,
      name: "Check Box188", 
      isText: false,
      type: "checkbox",
      top: 369.265,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_159", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 361.16,
      left: 213.64,
      width: 42.60,
      height: 28.80
   }
   ,
   {
      page: 8,
      name: "29", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 361.956,
      left: 258.914,
      width: 316.397,
      height: 26.008
   }
   ,
   {
      page: 8,
      name: "Check Box189", 
      isText: false,
      type: "checkbox",
      top: 400.819,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_161", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 392.84,
      left: 213.64,
      width: 42.60,
      height: 28.80
   }
   ,
   {
      page: 8,
      name: "30", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 393.396,
      left: 258.914,
      width: 316.397,
      height: 26.008
   }
   ,
   {
      page: 8,
      name: "Supplemental water purchased in past 2 years31", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 424.32,
      left: 65.08,
      width: 119.16,
      height: 29.28
   }
   ,
   {
      page: 8,
      name: "Check Box190", 
      isText: false,
      type: "checkbox",
      top: 432.419,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_163", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 424.28,
      left: 213.64,
      width: 42.60,
      height: 28.80
   }
   ,
   {
      page: 8,
      name: "Galvanized pipe", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 425.423,
      left: 258.914,
      width: 316.397,
      height: 26.007
   }
   ,
   {
      page: 8,
      name: "Supplemental water purchased in past 2 yearsRow2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 455.88,
      left: 65.08,
      width: 119.16,
      height: 29.28
   }
   ,
   {
      page: 8,
      name: "Check Box191", 
      isText: false,
      type: "checkbox",
      top: 464.321,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_165", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 455.96,
      left: 213.64,
      width: 42.60,
      height: 28.80
   }
   ,
   {
      page: 8,
      name: "31Row1", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 457.189,
      left: 258.914,
      width: 316.397,
      height: 26.007
   }
   ,
   {
      page: 8,
      name: "Supplemental water purchased in past 2 yearsRow3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 487.44,
      left: 65.08,
      width: 119.16,
      height: 27.978
   }
   ,
   {
      page: 8,
      name: "Check Box192", 
      isText: false,
      type: "checkbox",
      top: 495.573,
      left: 192.785,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "undefined_167", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 488.4,
      left: 213.64,
      width: 42.60,
      height: 26.847
   }
   ,
   {
      page: 8,
      name: "31Row2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 485.476,
      left: 258.914,
      width: 316.397,
      height: 26.008
   }
   ,
   {
      page: 8,
      name: "Check Box193", 
      isText: false,
      type: "checkbox",
      top: 601.999,
      left: 247.709,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "Comments_94", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 579.24,
      left: 269.76,
      width: 305.16,
      height: 59.40
   }
   ,
   {
      page: 8,
      name: "Source of Water the same as specified in SPD NOTE If the Source of Water is different Seller should supply a completed Source of Water Addendum for this Additional Structure2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 642.72,
      left: 62.4,
      width: 177.72,
      height: 30.48
   }
   ,
   {
      page: 8,
      name: "Check Box194", 
      isText: false,
      type: "checkbox",
      top: 651.178,
      left: 247.709,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 8,
      name: "Comments_95", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 642.72,
      left: 269.52,
      width: 305.64,
      height: 30.48
   }
   ,
   {
      page: 8,
      name: "Source of Water the same as specified in SPD NOTE If the Source of Water is different Seller should supply a completed Source of Water Addendum for this Additional Structure3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 676.56,
      left: 62.4,
      width: 177.72,
      height: 29.28
   }
   ,
   {
      page: 8,
      name: "Check Box195", 
      isText: false,
      type: "checkbox",
      top: 684.383,
      left: 247.709,
      width: 12.240,
      height: 12.2399
   }
   ,
   {
      page: 8,
      name: "Comments_96", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 676.56,
      left: 269.52,
      width: 305.64,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Check Box166", 
      isText: false,
      type: "checkbox",
      top: 102.978,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_97", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 95.16,
      left: 261.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Check Box167", 
      isText: false,
      type: "checkbox",
      top: 135.182,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_98", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 126.72,
      left: 261.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Lift station sewage ejector pump3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 158.28,
      left: 65.16,
      width: 167.40,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Check Box168", 
      isText: false,
      type: "checkbox",
      top: 166.085,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_99", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 158.28,
      left: 261.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Lift station sewage ejector pump4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 189.48,
      left: 64.92,
      width: 167.88,
      height: 17.847
   }
   ,
   {
      page: 9,
      name: "Check Box169", 
      isText: false,
      type: "checkbox",
      top: 193.128,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_100", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 189.48,
      left: 261.72,
      width: 309.042,
      height: 19.80
   }
   ,
   {
      page: 9,
      name: "Public", 
      isText: false,
      type: "checkbox",
      top: 267.0,
      left: 70.8,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 9,
      name: "Septic System", 
      isText: false,
      type: "checkbox",
      top: 279.0,
      left: 70.8,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 9,
      name: "undefined_175", 
      isText: false,
      type: "checkbox",
      top: 291.0,
      left: 70.8,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 9,
      name: "Community", 
      isText: false,
      type: "checkbox",
      top: 267.0,
      left: 144.48,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 9,
      name: "None", 
      isText: false,
      type: "checkbox",
      top: 279.0,
      left: 143.52,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 9,
      name: "Other_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 302.938,
      left: 65.6425,
      width: 164.7165,
      height: 13.764
   }
   ,
   {
      page: 9,
      name: "Tank", 
      isText: false,
      type: "checkbox",
      top: 367.56,
      left: 101.52,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 9,
      name: "Leach", 
      isText: false,
      type: "checkbox",
      top: 367.56,
      left: 140.88,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 9,
      name: "Lagoon", 
      isText: false,
      type: "checkbox",
      top: 367.56,
      left: 183.84,
      width: 8.88,
      height: 8.88
   }
   ,
   {
      page: 9,
      name: "Check Box170", 
      isText: false,
      type: "checkbox",
      top: 307.065,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_101", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 250.44,
      left: 262.2,
      width: 309.042,
      height: 126.156
   }
   ,
   {
      page: 9,
      name: "Sewer service provider", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 395.16,
      left: 70.2,
      width: 153.96,
      height: 11.16
   }
   ,
   {
      page: 9,
      name: "Check Box171", 
      isText: false,
      type: "checkbox",
      top: 389.1,
      left: 240.849,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 9,
      name: "Comments_102", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 381.0,
      left: 261.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Sewer line scoped", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 424.44,
      left: 91.92,
      width: 130.56,
      height: 10.92
   }
   ,
   {
      page: 9,
      name: "Check Box172", 
      isText: false,
      type: "checkbox",
      top: 421.002,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_103", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 412.56,
      left: 261.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Individual Use Permit issued", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 467.64,
      left: 70.2,
      width: 126.0,
      height: 11.16
   }
   ,
   {
      page: 9,
      name: "Check Box173", 
      isText: false,
      type: "checkbox",
      top: 455.509,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_104", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 444.12,
      left: 262.2,
      width: 309.042,
      height: 35.64
   }
   ,
   {
      page: 9,
      name: "If a septic system date of latest inspection", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 494.04,
      left: 70.2,
      width: 153.96,
      height: 11.16
   }
   ,
   {
      page: 9,
      name: "Check Box174", 
      isText: false,
      type: "checkbox",
      top: 489.364,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_105", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 481.56,
      left: 261.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "If a septic system date of latest pumping", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 525.72,
      left: 70.2,
      width: 153.96,
      height: 11.16
   }
   ,
   {
      page: 9,
      name: "Check Box175", 
      isText: false,
      type: "checkbox",
      top: 520.616,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_106", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 513.12,
      left: 261.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Check Box176", 
      isText: false,
      type: "checkbox",
      top: 552.518,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_107", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 544.56,
      left: 261.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Gray water storageuse12", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 576.12,
      left: 65.16,
      width: 167.40,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Check Box177", 
      isText: false,
      type: "checkbox",
      top: 583.769,
      left: 240.849,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 9,
      name: "Comments_108", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 576.12,
      left: 261.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Check Box178", 
      isText: false,
      type: "checkbox",
      top: 686.638,
      left: 234.849,
      width: 12.240,
      height: 12.2403
   }
   ,
   {
      page: 9,
      name: "Comments_109", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 678.72,
      left: 259.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 9,
      name: "Check Box179", 
      isText: false,
      type: "checkbox",
      top: 718.5407,
      left: 234.849,
      width: 12.240,
      height: 12.2400
   }
   ,
   {
      page: 9,
      name: "Comments_110", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 710.28,
      left: 259.96,
      width: 309.042,
      height: 29.28
   }
   ,
   {
      page: 10,
      name: "Check Box165", 
      isText: false,
      type: "checkbox",
      top: 57.469,
      left: 239.565,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "undefined_187", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 49.92,
      left: 264.96,
      width: 311.16,
      height: 28.80
   }
   ,
   {
      page: 10,
      name: "Floors4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 80.52,
      left: 63.4,
      width: 167.535,
      height: 27.971
   }
   ,
   {
      page: 10,
      name: "Check Box164", 
      isText: false,
      type: "checkbox",
      top: 88.128,
      left: 239.565,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "undefined_189", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 80.6,
      left: 264.96,
      width: 311.16,
      height: 28.8
   }
   ,
   {
      page: 10,
      name: "Floors5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 112.08,
      left: 63.4,
      width: 167.535,
      height: 26.662
   }
   ,
   {
      page: 10,
      name: "Check Box163", 
      isText: false,
      type: "checkbox",
      top: 120.855,
      left: 239.565,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "undefined_191", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 113.04,
      left: 264.96,
      width: 311.16,
      height: 27.491
   }
   ,
   {
      page: 10,
      name: "Check Box151", 
      isText: false,
      type: "checkbox",
      top: 243.328,
      left: 220.583,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "Comments_111", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 235.44,
      left: 242.4,
      width: 332.76,
      height: 29.28
   }
   ,
   {
      page: 10,
      name: "Check Box152", 
      isText: false,
      type: "checkbox",
      top: 288.147,
      left: 220.583,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "Comments_112", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 267,
      left: 242.64,
      width: 332.28,
      height: 55.32
   }
   ,
   {
      page: 10,
      name: "Check Box153", 
      isText: false,
      type: "checkbox",
      top: 332.002,
      left: 220.583,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "Comments_113", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 324.12,
      left: 242.4,
      width: 332.76,
      height: 27.316
   }
   ,
   {
      page: 10,
      name: "Check Box154", 
      isText: false,
      type: "checkbox",
      top: 365.383,
      left: 220.583,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "Comments_114", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 356.6,
      left: 242.4,
      width: 332.76,
      height: 30.24
   }
   ,
   {
      page: 10,
      name: "Check Box155", 
      isText: false,
      type: "checkbox",
      top: 398.765,
      left: 220.583,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "Comments_115", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 390.96,
      left: 242.4,
      width: 332.76,
      height: 29.28
   }
   ,
   {
      page: 10,
      name: "Notice of ADA complaint or report6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 422.52,
      left: 62.4,
      width: 150.84,
      height: 29.28
   }
   ,
   {
      page: 10,
      name: "Check Box156", 
      isText: false,
      type: "checkbox",
      top: 430.184,
      left: 220.583,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "Comments_116", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 422.52,
      left: 242.4,
      width: 332.76,
      height: 29.28
   }
   ,
   {
      page: 10,
      name: "Notice of ADA complaint or report7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 454.08,
      left: 62.4,
      width: 150.84,
      height: 29.28
   }
   ,
   {
      page: 10,
      name: "Check Box157", 
      isText: false,
      type: "checkbox",
      top: 462.257,
      left: 220.583,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "Comments_117", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 454.08,
      left: 242.4,
      width: 332.76,
      height: 29.28
   }
   ,
   {
      page: 10,
      name: "Check Box158", 
      isText: false,
      type: "checkbox",
      top: 560.13,
      left: 220.583,
      width: 12.240,
      height: 12.24
   }
   ,
   {
      page: 10,
      name: "Comments_118", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 543.24,
      left: 242.64,
      width: 332.28,
      height: 47.64
   }
   ,
   {
      page: 10,
      name: "Check Box159", 
      isText: false,
      type: "checkbox",
      top: 602.985,
      left: 220.583,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "Comments_119", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 594.48,
      left: 242.4,
      width: 332.76,
      height: 30.24
   }
   ,
   {
      page: 10,
      name: "Check Box160", 
      isText: false,
      type: "checkbox",
      top: 643.257,
      left: 220.583,
      width: 12.240,
      height: 12.240
   }
   ,
   {
      page: 10,
      name: "Comments_120", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 627.96,
      left: 242.64,
      width: 332.28,
      height: 44.52
   }
   ,
   {
      page: 10,
      name: "Radon mitigation system installed on Property Provide all information known by Seller about the radon mitigation system4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 674.16,
      left: 62.4,
      width: 150.84,
      height: 29.28
   }
   ,
   {
      page: 10,
      name: "Check Box161", 
      isText: false,
      type: "checkbox",
      top: 682.185,
      left: 220.583,
      width: 12.240,
      height: 12.2399
   }
   ,
   {
      page: 10,
      name: "Comments_121", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 674.16,
      left: 242.4,
      width: 332.76,
      height: 29.28
   }
   ,
   {
      page: 10,
      name: "Radon mitigation system installed on Property Provide all information known by Seller about the radon mitigation system5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 705.72,
      left: 62.4,
      width: 150.84,
      height: 29.28
   }
   ,
   {
      page: 10,
      name: "Check Box162", 
      isText: false,
      type: "checkbox",
      top: 713.2941,
      left: 220.583,
      width: 12.240,
      height: 12.2400
   }
   ,
   {
      page: 10,
      name: "Comments_122", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 705.72,
      left: 242.4,
      width: 332.76,
      height: 29.28
   }
   ,
   {
      page: 11,
      name: "undefined_204", 
      isText: false,
      type: "checkbox",
      top: 121.08,
      left: 221.4,
      width: 12.24,
      height: 12.24
   }
   ,
   {
      page: 11,
      name: "Comments_123", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 106.2,
      left: 242.64,
      width: 332.28,
      height: 41.836
   }
   ,
   {
      page: 11,
      name: "Check Box146", 
      isText: false,
      type: "checkbox",
      top: 174.419,
      left: 221.4,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 11,
      name: "Comments_124", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 151.8,
      left: 242.64,
      width: 332.28,
      height: 59.531
   }
   ,
   {
      page: 11,
      name: "Check Box147", 
      isText: false,
      type: "checkbox",
      top: 224.165,
      left: 221.4,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 11,
      name: "Comments_125", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 215.0,
      left: 242.4,
      width: 332.76,
      height: 30.12
   }
   ,
   {
      page: 11,
      name: "Check Box148", 
      isText: false,
      type: "checkbox",
      top: 262.783,
      left: 221.4,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 11,
      name: "Comments_126", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 249.24,
      left: 242.64,
      width: 332.28,
      height: 38.891
   }
   ,
   {
      page: 11,
      name: "undefined_208", 
      isText: false,
      type: "checkbox",
      top: 300.6,
      left: 221.4,
      width: 12.24,
      height: 12.24
   }
   ,
   {
      page: 11,
      name: "Comments_127", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 291.68,
      left: 242.4,
      width: 332.76,
      height: 30.00
   }
   ,
   {
      page: 11,
      name: "M_General_Extra1", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 325.8,
      left: 62.4,
      width: 150.84,
      height: 29.28
   }
   ,
   {
      page: 11,
      name: "Check Box149", 
      isText: false,
      type: "checkbox",
      top: 334.474,
      left: 221.4,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 11,
      name: "Comments_128", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 325.8,
      left: 242.4,
      width: 332.76,
      height: 29.28
   }
   ,
   {
      page: 11,
      name: "Smoking inside improvements including garages unfinished space or detached buildings on Property7", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 357.36,
      left: 62.4,
      width: 150.84,
      height: 29.28
   }
   ,
   {
      page: 11,
      name: "Check Box150", 
      isText: false,
      type: "checkbox",
      top: 365.238,
      left: 221.4,
      width: 12.24,
      height: 12.240
   }
   ,
   {
      page: 11,
      name: "Comments_129", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 357.36,
      left: 242.4,
      width: 332.76,
      height: 27.971
   }
   ,
   {
      page: 11,
      name: "Sellers Name 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 534.76,
      left: 87.36,
      width: 185.04,
      height: 15.00
   }
   ,
   {
      page: 11,
      name: "Sellers Name 1_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 534.105,
      left: 375.48,
      width: 184.80,
      height: 15.655
   }
   ,
   {
      page: 11,
      name: "Buyers Name 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 639.371,
      left: 88.56,
      width: 184.80,
      height: 16.309
   }
   ,
   {
      page: 11,
      name: "Buyers Name 1_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 638.025,
      left: 376.56,
      width: 184.80,
      height: 15.655
   }
   ] }
   