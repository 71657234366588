import React from "react";
import { useField } from "formik";
import { FormField, Label, Segment, List } from "semantic-ui-react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

export default function MyPlaceInput({ label, options, ...props }) {
  const [field, meta, helpers] = useField(props);

  function handleSelect(address) {
    geocodeByAddress(address)
      .then((results) => {
        let streetNumber = "";
        let route = "";
        let neighborhood = "";
        let locality = "";
        let administrative_area_level_1 = "";
        let administrative_area_level_2 = "";
        let postal_code = "";
        results[0].address_components.forEach((item) => {
          if (item.types.includes("street_number")) {
            streetNumber = item.short_name;
          }
          if (item.types.includes("route")) {
            route = item.short_name;
          }
          if (item.types.includes("neighborhood")) {
            neighborhood = item.short_name;
          }
          if (item.types.includes("locality")) {
            locality = item.short_name;
          }
          if (item.types.includes("administrative_area_level_1")) {
            administrative_area_level_1 = item.short_name;
          }
          if (item.types.includes("administrative_area_level_2")) {
            administrative_area_level_2 = item.short_name;
          }
          if (item.types.includes("postal_code")) {
            postal_code = item.short_name;
          }
        });
        helpers.setValue({
          address,
          streetNumber,
          neighborhood,
          route,
          locality,
          administrative_area_level_1,
          administrative_area_level_2,
          postal_code,
        });
      })
      .catch((error) => helpers.setError(error));
  }

  return (
    <PlacesAutocomplete
      value={field?.value?.address || ""}
      onChange={(value) => helpers.setValue({ address: value })}
      onSelect={(value) => handleSelect(value)}
      searchOptions={options}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <FormField error={meta.touched && !!meta.error}>
          <input
            {...getInputProps({
              name: field.name,
              ...props,
            })}
          />
          {meta.touched && meta.error ? (
            <Label basic color="red">
              {meta.error["address"]}
            </Label>
          ) : null}
          {suggestions?.length > 0 && (
            <Segment
              loading={loading}
              style={{
                marginTop: 0,
                position: "absolute",
                zIndex: 1000,
                width: "100%",
              }}
            >
              <List selection>
                {suggestions.map((suggestion) => (
                  <List.Item
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.placeId}
                  >
                    <List.Header>
                      {suggestion.formattedSuggestion.mainText}
                    </List.Header>
                    <List.Description>
                      {suggestion.formattedSuggestion.secondaryText}
                    </List.Description>
                  </List.Item>
                ))}
              </List>
            </Segment>
          )}
        </FormField>
      )}
    </PlacesAutocomplete>
  );
}
