export function waiverofWarranty() {
return [   //2024 Release 2024-06-06 19:29:00
{
   page: 0,
   name: "address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 251.684,
   left: 79.08,
   width: 448.44,
   height: 14.607
}
,
{
   page: 0,
   name: "dated", 
   fontSize: 9,
   type: "date",
   top: 277.844,
   left: 106.8,
   width: 103.56,
   height: 14.607
}
,
{
   page: 0,
   name: "sellers", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 277.844,
   left: 251.52,
   width: 276.00,
   height: 14.607
}
,
{
   page: 0,
   name: "buyers", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 304.004,
   left: 156.36,
   width: 293.16,
   height: 14.607
}
] }
