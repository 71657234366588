export function newHomeContractCompletedConstruction() {
return [   //2024 Release 2024-02-19 08:00:23
{
   page: 0,
   name: "TUNITY", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 85.325,
   left: 286.751,
   width: 267.375,
   height: 11.469
}
,
{
   page: 0,
   name: "1 PARTIES The parties to this contract are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 97.325,
   left: 134.28,
   width: 299.76,
   height: 11.915
}
,
{
   page: 0,
   name: "to sell and convey to Buyer and Buyer agrees to buy from Seller the Property defined below", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 122.884,
   left: 159.36,
   width: 141.12,
   height: 11.356
}
,
{
   page: 0,
   name: "Block", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 123.443,
   left: 330.84,
   width: 222.84,
   height: 10.797
}
,
{
   page: 0,
   name: "2 PROPERTY Lot", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 132.884,
   left: 67.56,
   width: 438.60,
   height: 11.356
}
,
{
   page: 0,
   name: "City of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 143.325,
   left: 100.2,
   width: 200.28,
   height: 11.915
}
,
{
   page: 0,
   name: "Addition", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 142.767,
   left: 352.68,
   width: 201.00,
   height: 12.473
}
,
{
   page: 0,
   name: "County of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 153.325,
   left: 154.92,
   width: 398.76,
   height: 11.915
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 233.403,
   left: 447.48,
   width: 110.64,
   height: 10.797
}
,
{
   page: 0,
   name: "Third Party Financing Addendum", 
   isText: false,
   type: "checkbox",
   top: 268.243,
   left: 321.719,
   width: 9.003,
   height: 8.877
}
,
{
   page: 0,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 280.044,
   left: 83.1596,
   width: 8.4986,
   height: 8.498
}
,
{
   page: 0,
   name: "Seller Financing Addendum", 
   isText: false,
   type: "checkbox",
   top: 280.243,
   left: 255.4,
   width: 10.121,
   height: 8.877
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 281.722,
   left: 447.48,
   width: 110.64,
   height: 10.238
}
,
{
   page: 0,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 292.042,
   left: 447.48,
   width: 110.64,
   height: 10.238
}
,
{
   page: 0,
   name: "Residential", 
   isText: false,
   type: "checkbox",
   top: 351.894,
   left: 55.4377,
   width: 8.4985,
   height: 8.498
}
,
{
   page: 0,
   name: "Fixture", 
   isText: false,
   type: "checkbox",
   top: 377.894,
   left: 55.4377,
   width: 8.4985,
   height: 8.498
}
,
{
   page: 0,
   name: "Natural", 
   isText: false,
   type: "checkbox",
   top: 413.894,
   left: 55.4377,
   width: 8.4985,
   height: 8.498
}
,
{
   page: 0,
   name: "Seller has", 
   isText: false,
   type: "checkbox",
   top: 449.894,
   left: 68.4377,
   width: 8.4985,
   height: 8.498
}
,
{
   page: 0,
   name: "Seller has not", 
   isText: false,
   type: "checkbox",
   top: 463.894,
   left: 68.4377,
   width: 8.4985,
   height: 8.498
}
,
{
   page: 0,
   name: "of which the license holder or the license holders spouse parent or child is a beneficiary to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 486.781,
   left: 396.939,
   width: 37.116,
   height: 10.118
}
,
{
   page: 0,
   name: "5 EARNEST MONEY W ith in 3 d ays a f te r t h e Ef f e c ti v e D at e  B u ye r must deliver", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 542.609,
   left: 193.223,
   width: 209.347,
   height: 10.118
}
,
{
   page: 0,
   name: "money", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 541.521,
   left: 495.331,
   width: 62.265,
   height: 10.118
}
,
{
   page: 0,
   name: "notify the other party in writing before entering into a contract of sale Disclose if applicable", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 551.116,
   left: 80.1115,
   width: 188.8995,
   height: 11.948
}
,
{
   page: 0,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 550.877,
   left: 332.1,
   width: 91.841,
   height: 11.307
}
,
{
   page: 0,
   name: "days after the Effective Date of this contract If Buyer fails to deliver the earnest money", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 562.969,
   left: 86.2002,
   width: 81.7518,
   height: 10.870
}
,
{
   page: 0,
   name: "as earnest money to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 584.181,
   left: 346.506,
   width: 96.381,
   height: 10.631
}
,
{
   page: 0,
   name: "Buyer shall deliver additional earnest money of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 594.435,
   left: 98.3849,
   width: 38.1601,
   height: 10.557
}
,
{
   page: 0,
   name: "within 3 days", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 737.4343,
   left: 82.053,
   width: 38.160,
   height: 10.5570
}
,
{
   page: 1,
   name: "Contract Concerning", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.692,
   left: 129.08,
   width: 322.318,
   height: 12.428
}
,
{
   page: 1,
   name: "Sellers", 
   isText: false,
   type: "checkbox",
   top: 192.813,
   left: 319.17,
   width: 9.284,
   height: 9.477
}
,
{
   page: 1,
   name: "Buyers expense an owner policy of", 
   isText: false,
   type: "checkbox",
   top: 193.254,
   left: 376.53,
   width: 9.284,
   height: 10.036
}
,
{
   page: 1,
   name: "A TITLE POLICY Seller shall furnish to Buyer at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 204.212,
   left: 285.532,
   width: 265.731,
   height: 10.238
}
,
{
   page: 1,
   name: "i will not be amended or deleted from the title policy or", 
   isText: false,
   type: "checkbox",
   top: 360.692,
   left: 82.5533,
   width: 8.0621,
   height: 10.638
}
,
{
   page: 1,
   name: "ii will be amended to read shortages in area at the expense of", 
   isText: false,
   type: "checkbox",
   top: 373.352,
   left: 81.9116,
   width: 8.7255,
   height: 10.058
}
,
{
   page: 1,
   name: "Buyer", 
   isText: false,
   type: "checkbox",
   top: 373.331,
   left: 438.448,
   width: 9.327,
   height: 10.079
}
,
{
   page: 1,
   name: "Seller", 
   isText: false,
   type: "checkbox",
   top: 372.933,
   left: 484.332,
   width: 10.402,
   height: 10.036
}
,
{
   page: 1,
   name: "1 Within", 
   isText: false,
   type: "checkbox",
   top: 512.093,
   left: 68.6632,
   width: 9.3061,
   height: 9.633
}
,
{
   page: 1,
   name: "days after the Effective Date of this contract Seller shall furnish to Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 510.867,
   left: 136.703,
   width: 39.720,
   height: 11.839
}
,
{
   page: 1,
   name: "Sellers_2", 
   isText: false,
   type: "checkbox",
   top: 577.695,
   left: 387.806,
   width: 10.402,
   height: 10.036
}
,
{
   page: 1,
   name: "Buyers expense no", 
   isText: false,
   type: "checkbox",
   top: 577.093,
   left: 443.522,
   width: 10.446,
   height: 10.595
}
,
{
   page: 1,
   name: "2 Within", 
   isText: false,
   type: "checkbox",
   top: 598.935,
   left: 69.126,
   width: 9.2844,
   height: 10.036
}
,
{
   page: 1,
   name: "survey at Buyers expense Buyer is deemed to receive the survey on the date of actual", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 598.532,
   left: 136.857,
   width: 47.979,
   height: 12.440
}
,
{
   page: 1,
   name: "3 Within", 
   isText: false,
   type: "checkbox",
   top: 628.935,
   left: 68.6849,
   width: 9.2844,
   height: 10.595
}
,
{
   page: 1,
   name: "shall furnish a new survey to Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 629.232,
   left: 136.617,
   width: 46.331,
   height: 11.160
}
,
{
   page: 1,
   name: "activity", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 679.645,
   left: 139.317,
   width: 412.981,
   height: 11.384
}
,
{
   page: 1,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 690.166,
   left: 80.8987,
   width: 472.0803,
   height: 10.557
}
,
{
   page: 1,
   name: "Buyer must object the earlier of i the Closing Date or ii", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 699.4612,
   left: 379.608,
   width: 36.309,
   height: 11.8689
}
,
{
   page: 2,
   name: "Contract Concerning_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.544,
   left: 130.08,
   width: 321.052,
   height: 12.576
}
,
{
   page: 2,
   name: "is", 
   isText: false,
   type: "checkbox",
   top: 212.107,
   left: 486.435,
   width: 10.680,
   height: 9.995
}
,
{
   page: 2,
   name: "is not", 
   isText: false,
   type: "checkbox",
   top: 211.107,
   left: 516.795,
   width: 10.143,
   height: 9.995
}
,
{
   page: 3,
   name: "Contract Concerning_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.751,
   left: 130.08,
   width: 322.573,
   height: 11.810
}
,
{
   page: 3,
   name: "following specific repairs and treatments 1-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 218.571,
   left: 349.307,
   width: 207.852,
   height: 9.550
}
,
{
   page: 3,
   name: "following specific repairs and treatments 2-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 227.572,
   left: 97.0326,
   width: 460.3134,
   height: 9.737
}
,
{
   page: 3,
   name: "following specific repairs and treatments 2-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 238.237,
   left: 97.0326,
   width: 460.3134,
   height: 9.737
}
,
{
   page: 3,
   name: "1Buyer accepts the Property As Is", 
   isText: false,
   type: "checkbox",
   top: 382.242,
   left: 66.9578,
   width: 9.5622,
   height: 8.318
}
,
{
   page: 3,
   name: "2Buyer accepts the Property As Is provided Seller at Sellers expense shall complete the", 
   isText: false,
   type: "checkbox",
   top: 392.283,
   left: 66.3989,
   width: 10.1211,
   height: 8.877
}
,
{
   page: 3,
   name: "following specific repairs and treatments 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 405.07,
   left: 289.049,
   width: 261.826,
   height: 10.442
}
,
{
   page: 3,
   name: "following specific repairs and treatments 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 414.517,
   left: 80.4889,
   width: 471.0191,
   height: 11.075
}
,
{
   page: 3,
   name: "1 as shown in the attached specifications", 
   isText: false,
   type: "checkbox",
   top: 513.61,
   left: 67.119,
   width: 9.2192,
   height: 10.036
}
,
{
   page: 3,
   name: "2 as follows", 
   isText: false,
   type: "checkbox",
   top: 523.889,
   left: 66.5384,
   width: 9.7998,
   height: 9.499
}
,
{
   page: 3,
   name: "a Exterior walls of improved living areas insulated with", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 535.489,
   left: 391.136,
   width: 166.320,
   height: 9.957
}
,
{
   page: 3,
   name: "insulation to a thickness of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 546.408,
   left: 254.816,
   width: 52.800,
   height: 9.398
}
,
{
   page: 3,
   name: "inches which yields an RValue of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 546.408,
   left: 482.816,
   width: 74.640,
   height: 9.398
}
,
{
   page: 3,
   name: "b Walls in other areas of the home insulated with", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 557.209,
   left: 116.305,
   width: 188.627,
   height: 9.204
}
,
{
   page: 3,
   name: "Exterior Walls", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 566.979,
   left: 360.527,
   width: 197.965,
   height: 9.957
}
,
{
   page: 3,
   name: "insulation to a thickness of_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 577.575,
   left: 254.816,
   width: 50.640,
   height: 9.957
}
,
{
   page: 3,
   name: "inches which yields an RValue of_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 577.502,
   left: 481.169,
   width: 76.920,
   height: 9.398
}
,
{
   page: 3,
   name: "walls", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 587.09,
   left: 116.374,
   width: 188.626,
   height: 10.47
}
,
{
   page: 3,
   name: "c Ceilings in improved living areas  insulated with", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 597.295,
   left: 362.762,
   width: 195.960,
   height: 10.955
}
,
{
   page: 3,
   name: "insulation to a thickness of 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 608.211,
   left: 254.868,
   width: 52.800,
   height: 11.677
}
,
{
   page: 3,
   name: "inches which yields an RValue of_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 608.94,
   left: 483.449,
   width: 74.640,
   height: 10.001
}
,
{
   page: 3,
   name: "walls 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 619.723,
   left: 117.374,
   width: 188.626,
   height: 9.837
}
,
{
   page: 3,
   name: "d Floors of improved living areas not applied to a slab foundation insulated with", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 629.504,
   left: 517.903,
   width: 37.756,
   height: 9.905
}
,
{
   page: 3,
   name: "insulation to a thickness of 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 640.496,
   left: 117.336,
   width: 187.146,
   height: 9.957
}
,
{
   page: 3,
   name: "insulation to a thickness of_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 639.474,
   left: 447.273,
   width: 41.640,
   height: 10.560
}
,
{
   page: 3,
   name: "which yields an RValue of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 651.663,
   left: 223.978,
   width: 189.338,
   height: 9.979
}
,
{
   page: 3,
   name: "e Other insulated areas insulated with", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 662.023,
   left: 306.95,
   width: 173.606,
   height: 10.030
}
,
{
   page: 3,
   name: "thickness of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 670.706,
   left: 179.063,
   width: 52.920,
   height: 11.514
}
,
{
   page: 3,
   name: "inches which yields an RValue of_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 671.802,
   left: 412.728,
   width: 140.032,
   height: 10.396
}
,
{
   page: 4,
   name: "Contract Concerning_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.115,
   left: 130.08,
   width: 321.685,
   height: 12.005
}
,
{
   page: 4,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required-1", 
   isText: false,
   type: "checkbox",
   top: 221.244,
   left: 134.899,
   width: 9.843,
   height: 9.477
}
,
{
   page: 4,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the parties-2", 
   isText: false,
   type: "checkbox",
   top: 221.803,
   left: 170.793,
   width: 9.865,
   height: 8.918
}
,
{
   page: 4,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required-3", 
   isText: false,
   type: "checkbox",
   top: 240.023,
   left: 135.899,
   width: 9.843,
   height: 9.477
}
,
{
   page: 4,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the parties-4", 
   isText: false,
   type: "checkbox",
   top: 240.582,
   left: 171.793,
   width: 9.865,
   height: 8.918
}
,
{
   page: 4,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required-5", 
   isText: false,
   type: "checkbox",
   top: 258.023,
   left: 135.899,
   width: 9.843,
   height: 9.477
}
,
{
   page: 4,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the parties-6", 
   isText: false,
   type: "checkbox",
   top: 258.582,
   left: 171.793,
   width: 9.865,
   height: 8.918
}
,
{
   page: 4,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required-7", 
   isText: false,
   type: "checkbox",
   top: 276.023,
   left: 135.899,
   width: 9.843,
   height: 9.477
}
,
{
   page: 4,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the parties-8", 
   isText: false,
   type: "checkbox",
   top: 276.582,
   left: 171.793,
   width: 9.865,
   height: 8.918
}
,
{
   page: 4,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required-9", 
   isText: false,
   type: "checkbox",
   top: 294.023,
   left: 136.345,
   width: 9.397,
   height: 9.477
}
,
{
   page: 4,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the parties-10", 
   isText: false,
   type: "checkbox",
   top: 294.582,
   left: 171.793,
   width: 9.865,
   height: 8.918
}
,
{
   page: 4,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required-111", 
   isText: false,
   type: "checkbox",
   top: 311.023,
   left: 135.899,
   width: 9.843,
   height: 9.477
}
,
{
   page: 4,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the parties-12", 
   isText: false,
   type: "checkbox",
   top: 311.582,
   left: 171.793,
   width: 9.865,
   height: 8.918
}
,
{
   page: 4,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required-13", 
   isText: false,
   type: "checkbox",
   top: 330.023,
   left: 134.899,
   width: 9.843,
   height: 9.477
}
,
{
   page: 4,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the parties-14", 
   isText: false,
   type: "checkbox",
   top: 330.582,
   left: 170.793,
   width: 9.865,
   height: 8.918
}
,
{
   page: 4,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required_0", 
   isText: false,
   type: "checkbox",
   top: 330.257,
   left: 416.399,
   width: 9.843,
   height: 9.477
}
,
{
   page: 4,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the parties_0", 
   isText: false,
   type: "checkbox",
   top: 329.816,
   left: 478.293,
   width: 9.865,
   height: 8.918
}
,
{
   page: 4,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required-15", 
   isText: false,
   type: "checkbox",
   top: 349.023,
   left: 134.899,
   width: 9.843,
   height: 9.477
}
,
{
   page: 4,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the parties-16", 
   isText: false,
   type: "checkbox",
   top: 349.582,
   left: 170.793,
   width: 9.865,
   height: 8.918
}
,
{
   page: 4,
   name: "Disclose 2-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 369.326,
   left: 80.4216,
   width: 465.4334,
   height: 10.739
}
,
{
   page: 4,
   name: "in an amount not exceeding", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 412.114,
   left: 428.532,
   width: 87.299,
   height: 9.957
}
,
{
   page: 4,
   name: "Disclose 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 520.158,
   left: 389.567,
   width: 158.812,
   height: 9.769
}
,
{
   page: 4,
   name: "Disclose 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 528.783,
   left: 79.7665,
   width: 467.6635,
   height: 10.739
}
,
{
   page: 4,
   name: "A The closing of the sale will be on or before", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 568.072,
   left: 301.477,
   width: 139.192,
   height: 12.638
}
,
{
   page: 4,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 567.999,
   left: 461.564,
   width: 28.953,
   height: 12.711
}
,
{
   page: 5,
   name: "Contract Concerning_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.106,
   left: 130.08,
   width: 319.787,
   height: 13.014
}
,
{
   page: 5,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required_1", 
   isText: false,
   type: "checkbox",
   top: 72.079,
   left: 337.399,
   width: 9.843,
   height: 9.477
}
,
{
   page: 5,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the parties_1", 
   isText: false,
   type: "checkbox",
   top: 71.638,
   left: 480.293,
   width: 9.865,
   height: 8.918
}
,
{
   page: 5,
   name: "Text2-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 261.362,
   left: 467.439,
   width: 83.937,
   height: 11.217
}
,
{
   page: 5,
   name: "Text2-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 271.118,
   left: 64.6413,
   width: 486.7347,
   height: 10.771
}
,
{
   page: 5,
   name: "Text2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 280.69,
   left: 64.6493,
   width: 486.7347,
   height: 10.771
}
,
{
   page: 5,
   name: "bSeller shall also pay an amount not to exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 352.416,
   left: 356.68,
   width: 81.049,
   height: 9.624
}
,
{
   page: 6,
   name: "Contract Concerning_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 29.955,
   left: 129.08,
   width: 322.318,
   height: 13.165
}
,
{
   page: 6,
   name: "at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 654.021,
   left: 136.296,
   width: 161.538,
   height: 13.651
}
,
{
   page: 6,
   name: "undefined_16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 667.609,
   left: 63.8303,
   width: 233.9887,
   height: 14.431
}
,
{
   page: 6,
   name: "Text3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 681.314,
   left: 142.337,
   width: 21.451,
   height: 15.852
}
,
{
   page: 6,
   name: "Text4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 680.314,
   left: 169.046,
   width: 128.733,
   height: 16.485
}
,
{
   page: 6,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 695.906,
   left: 132.428,
   width: 166.626,
   height: 17.5285
}
,
{
   page: 6,
   name: "Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 712.3189,
   left: 132.483,
   width: 166.258,
   height: 14.4310
}
,
{
   page: 6,
   name: "Email-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 737.7145,
   left: 63.8498,
   width: 234.5062,
   height: 14.4310
}
,
{
   page: 6,
   name: "at_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 653.12,
   left: 389.587,
   width: 162.827,
   height: 14.432
}
,
{
   page: 6,
   name: "undefined_17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 666.803,
   left: 319.911,
   width: 231.970,
   height: 14.990
}
,
{
   page: 6,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 681.796,
   left: 397.283,
   width: 21.451,
   height: 15.852
}
,
{
   page: 6,
   name: "Text8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 681.462,
   left: 423.843,
   width: 127.736,
   height: 15.704
}
,
{
   page: 6,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 696.464,
   left: 390.932,
   width: 161.608,
   height: 16.4115
}
,
{
   page: 6,
   name: "Email_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 712.7507,
   left: 391.108,
   width: 161.104,
   height: 14.5050
}
,
{
   page: 6,
   name: "Email_2-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 737.1731,
   left: 320.18,
   width: 232.474,
   height: 14.5050
}
,
{
   page: 7,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.78,
   left: 130.08,
   width: 321.052,
   height: 12.34
}
,
{
   page: 7,
   name: "Third Party Financing Addendum_2", 
   isText: false,
   type: "checkbox",
   top: 108.252,
   left: 65.1525,
   width: 9.8215,
   height: 9.826
}
,
{
   page: 7,
   name: "Seller Financing Addendum_2", 
   isText: false,
   type: "checkbox",
   top: 124.07,
   left: 65.1525,
   width: 10.4674,
   height: 10.571
}
,
{
   page: 7,
   name: "Addendum for Property Subject to", 
   isText: false,
   type: "checkbox",
   top: 141.645,
   left: 65.2944,
   width: 9.8432,
   height: 10.249
}
,
{
   page: 7,
   name: "Buyers Temporary Residential Lease", 
   isText: false,
   type: "checkbox",
   top: 174.972,
   left: 65.1987,
   width: 9.8434,
   height: 10.623
}
,
{
   page: 7,
   name: "Loan Assumption Addendum", 
   isText: false,
   type: "checkbox",
   top: 192.495,
   left: 65.4581,
   width: 9.2843,
   height: 10.063
}
,
{
   page: 7,
   name: "Addendum for Sale of Other Property by", 
   isText: false,
   type: "checkbox",
   top: 211.749,
   left: 65.39,
   width: 10.4023,
   height: 10.132
}
,
{
   page: 7,
   name: "Addendum for Reservation of Oil Gas", 
   isText: false,
   type: "checkbox",
   top: 235.845,
   left: 65.3624,
   width: 10.4022,
   height: 10.295
}
,
{
   page: 7,
   name: "Addendum for BackUp Contract", 
   isText: false,
   type: "checkbox",
   top: 259.613,
   left: 65.6897,
   width: 9.8432,
   height: 10.295
}
,
{
   page: 7,
   name: "Addendum Concerning Right to", 
   isText: false,
   type: "checkbox",
   top: 278.458,
   left: 65.4581,
   width: 9.8432,
   height: 9.641
}
,
{
   page: 7,
   name: "Addendum Residential", 
   isText: false,
   type: "checkbox",
   top: 299.332,
   left: 65.8811,
   width: 9.8433,
   height: 10.295
}
,
{
   page: 7,
   name: "Addendum Fixture", 
   isText: false,
   type: "checkbox",
   top: 318.177,
   left: 65.6495,
   width: 9.8433,
   height: 9.641
}
,
{
   page: 7,
   name: "Addendum for Coastal Area Property", 
   isText: false,
   type: "checkbox",
   top: 107.714,
   left: 330.89,
   width: 10.402,
   height: 9.477
}
,
{
   page: 7,
   name: "Environmental Assessment Threatened or", 
   isText: false,
   type: "checkbox",
   top: 123.235,
   left: 329.772,
   width: 10.402,
   height: 10.036
}
,
{
   page: 7,
   name: "Sellers Temporary Residential Lease", 
   isText: false,
   type: "checkbox",
   top: 163.275,
   left: 329.331,
   width: 10.961,
   height: 10.036
}
,
{
   page: 7,
   name: "Short Sale Addendum", 
   isText: false,
   type: "checkbox",
   top: 179.914,
   left: 329.331,
   width: 10.402,
   height: 9.477
}
,
{
   page: 7,
   name: "Addendum for Property Located Seaward", 
   isText: false,
   type: "checkbox",
   top: 201.874,
   left: 329.89,
   width: 9.843,
   height: 9.477
}
,
{
   page: 7,
   name: "Addendum for Property in a Propane Gas", 
   isText: false,
   type: "checkbox",
   top: 224.756,
   left: 330.331,
   width: 10.402,
   height: 10.595
}
,
{
   page: 7,
   name: "PID", 
   isText: false,
   type: "checkbox",
   top: 257.355,
   left: 329.89,
   width: 10.402,
   height: 10.036
}
,
{
   page: 7,
   name: "Other list", 
   isText: false,
   type: "checkbox",
   top: 297.72,
   left: 329.522,
   width: 10.402,
   height: 10.036
}
,
{
   page: 7,
   name: "System Service Area", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 292.11,
   left: 414.732,
   width: 152.400,
   height: 11.637
}
,
{
   page: 7,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 302.711,
   left: 351.012,
   width: 216.120,
   height: 12.877
}
,
{
   page: 7,
   name: "Attorney is 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 395.978,
   left: 133.165,
   width: 168.842,
   height: 14.377
}
,
{
   page: 7,
   name: "Attorney is 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 436.266,
   left: 68.0053,
   width: 234.0017,
   height: 15.569
}
,
{
   page: 7,
   name: "Text12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 476.151,
   left: 139.398,
   width: 22.569,
   height: 15.294
}
,
{
   page: 7,
   name: "Text13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 474.475,
   left: 165.667,
   width: 136.282,
   height: 16.970
}
,
{
   page: 7,
   name: "Text14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 517.461,
   left: 140.59,
   width: 22.01,
   height: 16.411
}
,
{
   page: 7,
   name: "Text15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 518.02,
   left: 166.859,
   width: 135.164,
   height: 16.411
}
,
{
   page: 7,
   name: "undefined_19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 566.765,
   left: 133.165,
   width: 168.842,
   height: 16.202
}
,
{
   page: 7,
   name: "Attorney is 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 397.542,
   left: 394.674,
   width: 164.427,
   height: 13.892
}
,
{
   page: 7,
   name: "Attorney is 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 437.458,
   left: 327.795,
   width: 231.306,
   height: 13.818
}
,
{
   page: 7,
   name: "Text16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 475.593,
   left: 400.031,
   width: 22.009,
   height: 15.852
}
,
{
   page: 7,
   name: "Text17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 475.034,
   left: 425.741,
   width: 133.232,
   height: 16.969
}
,
{
   page: 7,
   name: "Text18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 517.538,
   left: 401.031,
   width: 22.010,
   height: 15.852
}
,
{
   page: 7,
   name: "Text19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 516.976,
   left: 426.299,
   width: 133.233,
   height: 16.970
}
,
{
   page: 7,
   name: "undefined_20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 567.712,
   left: 394.047,
   width: 166.320,
   height: 15.569
}
,
{
   page: 8,
   name: "Address of Property_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.132,
   left: 130.08,
   width: 321.052,
   height: 13.254
}
,
{
   page: 8,
   name: "BROKER FILL IN THE DATE OF FINAL ACCEPTANCE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 216.222,
   left: 144.214,
   width: 46.680,
   height: 14.451
}
,
{
   page: 8,
   name: "20_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 215.442,
   left: 230.734,
   width: 169.800,
   height: 14.451
}
,
{
   page: 8,
   name: "Effective Date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 215.663,
   left: 422.734,
   width: 25.440,
   height: 15.010
}
,
{
   page: 9,
   name: "Address of Property_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.03,
   left: 130.08,
   width: 321.685,
   height: 13.09
}
,
{
   page: 9,
   name: "Other Broker Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 98.841,
   left: 50.0669,
   width: 159.4091,
   height: 16.125
}
,
{
   page: 9,
   name: "License No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 98.841,
   left: 211.08,
   width: 74.067,
   height: 16.125
}
,
{
   page: 9,
   name: "Buyer only as Buyers agent", 
   isText: false,
   type: "checkbox",
   top: 131.27,
   left: 109.587,
   width: 10.445,
   height: 10.058
}
,
{
   page: 9,
   name: "Seller as Listing Brokers subagent", 
   isText: false,
   type: "checkbox",
   top: 145.692,
   left: 109.107,
   width: 10.424,
   height: 10.036
}
,
{
   page: 9,
   name: "Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 157.288,
   left: 50.0669,
   width: 159.2891,
   height: 17.599
}
,
{
   page: 9,
   name: "License No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 157.288,
   left: 210.96,
   width: 74.187,
   height: 17.599
}
,
{
   page: 9,
   name: "Team Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 186.346,
   left: 49.4655,
   width: 233.3375,
   height: 17.599
}
,
{
   page: 9,
   name: "Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 217.331,
   left: 50.0669,
   width: 145.1951,
   height: 16.124
}
,
{
   page: 9,
   name: "Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 217.331,
   left: 196.866,
   width: 88.281,
   height: 16.124
}
,
{
   page: 9,
   name: "Licensed Supervisor of Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 244.012,
   left: 50.0669,
   width: 155.9361,
   height: 17.242
}
,
{
   page: 9,
   name: "License No_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 244.012,
   left: 207.607,
   width: 77.540,
   height: 17.242
}
,
{
   page: 9,
   name: "Other Brokers Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 272.37,
   left: 50.0669,
   width: 146.8331,
   height: 15.565
}
,
{
   page: 9,
   name: "Phone_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 272.37,
   left: 198.505,
   width: 86.642,
   height: 15.565
}
,
{
   page: 9,
   name: "City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 299.607,
   left: 50.0669,
   width: 132.6511,
   height: 15.007
}
,
{
   page: 9,
   name: "State", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 299.607,
   left: 184.881,
   width: 35.674,
   height: 15.007
}
,
{
   page: 9,
   name: "Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 300.048,
   left: 222.16,
   width: 62.987,
   height: 15.007
}
,
{
   page: 9,
   name: "Listing Broker Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 99.4,
   left: 302.907,
   width: 170.541,
   height: 15.007
}
,
{
   page: 9,
   name: "License No_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 98.841,
   left: 475.052,
   width: 82.255,
   height: 15.566
}
,
{
   page: 9,
   name: "Seller and Buyer as an intermediary", 
   isText: false,
   type: "checkbox",
   top: 133.292,
   left: 362.986,
   width: 10.961,
   height: 10.036
}
,
{
   page: 9,
   name: "Seller only as Sellers agent", 
   isText: false,
   type: "checkbox",
   top: 147.692,
   left: 363.065,
   width: 10.402,
   height: 10.036
}
,
{
   page: 9,
   name: "Associates Name_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 157.288,
   left: 302.907,
   width: 171.498,
   height: 17.599
}
,
{
   page: 9,
   name: "License No_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 157.288,
   left: 476.009,
   width: 81.298,
   height: 17.599
}
,
{
   page: 9,
   name: "Team Name 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 188.346,
   left: 302.214,
   width: 255.673,
   height: 16.967
}
,
{
   page: 9,
   name: "Listing Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 217.258,
   left: 302.274,
   width: 156.213,
   height: 16.123
}
,
{
   page: 9,
   name: "Phone_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 217.258,
   left: 460.091,
   width: 96.583,
   height: 16.123
}
,
{
   page: 9,
   name: "Licensed Supervisor of Listing Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 243.938,
   left: 302.274,
   width: 171.419,
   height: 16.683
}
,
{
   page: 9,
   name: "License No_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 243.938,
   left: 475.297,
   width: 81.377,
   height: 16.683
}
,
{
   page: 9,
   name: "Listing Brokers Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 271.178,
   left: 302.274,
   width: 156.450,
   height: 16.684
}
,
{
   page: 9,
   name: "Phone_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 271.178,
   left: 460.328,
   width: 96.346,
   height: 16.684
}
,
{
   page: 9,
   name: "City_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 299.415,
   left: 302.274,
   width: 152.770,
   height: 15.566
}
,
{
   page: 9,
   name: "State_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 299.415,
   left: 456.089,
   width: 37.231,
   height: 15.566
}
,
{
   page: 9,
   name: "Zip_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 299.417,
   left: 494.924,
   width: 61.750,
   height: 15.564
}
,
{
   page: 9,
   name: "Selling Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 327.214,
   left: 302.274,
   width: 171.501,
   height: 15.007
}
,
{
   page: 9,
   name: "License No_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 326.655,
   left: 475.379,
   width: 81.295,
   height: 16.125
}
,
{
   page: 9,
   name: "Team Name 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 353.562,
   left: 302.212,
   width: 255.043,
   height: 15.639
}
,
{
   page: 9,
   name: "Selling Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 381.185,
   left: 302.274,
   width: 167.432,
   height: 15.565
}
,
{
   page: 9,
   name: "Phone_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 381.185,
   left: 471.311,
   width: 85.363,
   height: 15.565
}
,
{
   page: 9,
   name: "Licensed Supervisor of Selling Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 408.276,
   left: 302.274,
   width: 170.342,
   height: 14.448
}
,
{
   page: 9,
   name: "License No_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 408.276,
   left: 474.221,
   width: 82.453,
   height: 15.007
}
,
{
   page: 9,
   name: "Selling Associates Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 437.073,
   left: 302.274,
   width: 254.400,
   height: 13.889
}
,
{
   page: 9,
   name: "City_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 462.636,
   left: 302.274,
   width: 146.571,
   height: 15.007
}
,
{
   page: 9,
   name: "State_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 462.636,
   left: 451.008,
   width: 33.370,
   height: 15.007
}
,
{
   page: 9,
   name: "Zip_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 462.077,
   left: 485.982,
   width: 70.692,
   height: 15.566
}
,
{
   page: 9,
   name: "when the Listing Brokers fee is received Escrow agent is authorized and directed to pay Other Broker from_1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 539.036,
   left: 434.326,
   width: 130.052,
   height: 11.136
}
,
{
   page: 9,
   name: "when the Listing Brokers fee is received Escrow agent is authorized and directed to pay Other Broker from_0", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 547.321,
   left: 51.4294,
   width: 202.2016,
   height: 11.136
}
,
{
   page: 10,
   name: "Address of Property 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 28.925,
   left: 129.022,
   width: 323.584,
   height: 12.961
}
,
{
   page: 10,
   name: "is acknowledged", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 109.753,
   left: 106.287,
   width: 98.520,
   height: 14.167
}
,
{
   page: 10,
   name: "Option Fee in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 109.195,
   left: 336.447,
   width: 217.440,
   height: 14.485
}
,
{
   page: 10,
   name: "Seller or Listing Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 146.713,
   left: 51.687,
   width: 248.160,
   height: 14.167
}
,
{
   page: 10,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 147.272,
   left: 312.687,
   width: 243.120,
   height: 13.608
}
,
{
   page: 10,
   name: "is acknowledged_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 198.835,
   left: 105.567,
   width: 113.040,
   height: 14.725
}
,
{
   page: 10,
   name: "Earnest Money in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 199.393,
   left: 356.967,
   width: 199.200,
   height: 14.167
}
,
{
   page: 10,
   name: "Escrow Agent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 227.113,
   left: 51.447,
   width: 138.039,
   height: 14.167
}
,
{
   page: 10,
   name: "Received by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 226.555,
   left: 190.85,
   width: 108.757,
   height: 14.725
}
,
{
   page: 10,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 225.996,
   left: 312.567,
   width: 137.329,
   height: 15.284
}
,
{
   page: 10,
   name: "DateTime", 
   fontSize: 9,
   type: "date",
   top: 225.996,
   left: 450.701,
   width: 104.866,
   height: 15.284
}
,
{
   page: 10,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 252.793,
   left: 51.8931,
   width: 306.7199,
   height: 14.167
}
,
{
   page: 10,
   name: "Phone_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 253.911,
   left: 367.047,
   width: 189.120,
   height: 13.049
}
,
{
   page: 10,
   name: "City_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 277.356,
   left: 51.207,
   width: 164.331,
   height: 15.284
}
,
{
   page: 10,
   name: "State_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 277.356,
   left: 216.902,
   width: 35.602,
   height: 15.284
}
,
{
   page: 10,
   name: "Zip_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 277.356,
   left: 254.427,
   width: 103.740,
   height: 15.284
}
,
{
   page: 10,
   name: "Fax", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 278.027,
   left: 367.047,
   width: 189.120,
   height: 14.167
}
,
{
   page: 10,
   name: "Escrow Agent_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 351.313,
   left: 51.447,
   width: 145.305,
   height: 14.167
}
,
{
   page: 10,
   name: "Received by_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 350.755,
   left: 198.116,
   width: 101.491,
   height: 14.725
}
,
{
   page: 10,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 351.313,
   left: 312.567,
   width: 146.544,
   height: 14.167
}
,
{
   page: 10,
   name: "Date_2", 
   fontSize: 9,
   type: "date",
   top: 350.755,
   left: 461.593,
   width: 93.974,
   height: 14.725
}
,
{
   page: 10,
   name: "Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 376.435,
   left: 51.447,
   width: 306.720,
   height: 14.725
}
,
{
   page: 10,
   name: "Phone_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 376.993,
   left: 367.047,
   width: 189.120,
   height: 14.167
}
,
{
   page: 10,
   name: "City_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 402.115,
   left: 51.207,
   width: 162.654,
   height: 14.725
}
,
{
   page: 10,
   name: "State_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 402.115,
   left: 215.226,
   width: 37.837,
   height: 14.725
}
,
{
   page: 10,
   name: "Zip_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 402.115,
   left: 254.986,
   width: 103.181,
   height: 14.725
}
,
{
   page: 10,
   name: "Fax_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 402.115,
   left: 367.047,
   width: 189.120,
   height: 14.725
}
,
{
   page: 10,
   name: "is acknowledged_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 455.635,
   left: 105.567,
   width: 98.880,
   height: 14.725
}
,
{
   page: 10,
   name: "additional Earnest Money in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 456.193,
   left: 390.327,
   width: 165.840,
   height: 14.167
}
,
{
   page: 10,
   name: "Escrow Agent_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 483.475,
   left: 51.447,
   width: 149.217,
   height: 14.605
}
,
{
   page: 10,
   name: "Received by_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 483.475,
   left: 202.587,
   width: 97.020,
   height: 14.605
}
,
{
   page: 10,
   name: "Email Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 483.913,
   left: 312.567,
   width: 151.301,
   height: 14.167
}
,
{
   page: 10,
   name: "DateTime_2", 
   fontSize: 9,
   type: "date",
   top: 483.913,
   left: 464.674,
   width: 90.893,
   height: 14.167
}
,
{
   page: 10,
   name: "Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 509.035,
   left: 51.447,
   width: 306.720,
   height: 14.725
}
,
{
   page: 10,
   name: "Phone_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 509.593,
   left: 367.047,
   width: 189.120,
   height: 14.167
}
,
{
   page: 10,
   name: "City_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 535.832,
   left: 51.207,
   width: 159.860,
   height: 13.608
}
,
{
   page: 10,
   name: "State_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 535.832,
   left: 214.108,
   width: 39.514,
   height: 13.608
}
,
{
   page: 10,
   name: "Zip_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 535.273,
   left: 256.104,
   width: 102.063,
   height: 14.167
}
,
{
   page: 10,
   name: "Fax_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 535.273,
   left: 367.047,
   width: 189.120,
   height: 14.167
}
] }
