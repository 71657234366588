import React from "react";
import { Table } from "semantic-ui-react";
import DeadlineTemplatesListItem from "./DeadlineTemplatesListItem";

export default function DeadlineTemplatesList({
  deadlineTemplates,
  column,
  direction,
  actionButtonMode,
}) {
  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell sorted={column === "title" ? direction : null}>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "updatedAt" ? direction : null}
            >
              Last Updated
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {deadlineTemplates &&
            deadlineTemplates.length !== 0 &&
            deadlineTemplates.map((deadlineTemplate) => (
              <DeadlineTemplatesListItem
                deadlineTemplate={deadlineTemplate}
                actionButtonMode={actionButtonMode}
                key={deadlineTemplate.id}
              />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
