export function rBCommissionsDisclosure1023() {
   return [   //2023
   {
      page: 0,
      name: "Sellers acknowledge that this may reduce the number of potential buyers for their property", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 586.2,
      left: 182.04,
      width: 369.84,
      height: 15.12
   }
   ,
   {
      page: 0,
      name: "I understand the above information I am a", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 634.6,
      left: 36.0,
      width: 192.0,
      height: 15.12
   }
   ,
   {
      page: 0,
      name: "Print Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 667.48,
      left: 36.0,
      width: 192.0,
      height: 15.12
   }
   ,
   {
      page: 0,
      name: "Print Name_2", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 713.04,
      left: 36.0,
      width: 212.41,
      height: 15.7745
   }
   ,
   {
      page: 0,
      name: "Check Box1", 
      isText: false,
      type: "checkbox",
      top: 612.657,
      left: 230.746,
      width: 14.727,
      height: 16.036
   }
   ,
   {
      page: 0,
      name: "Check Box2", 
      isText: false,
      type: "checkbox",
      top: 612.657,
      left: 280.638,
      width: 14.727,
      height: 16.036
   }
   ,
   {
      page: 0,
      name: "dateBrokerSigns", 
      fontSize: 9,
      type: "date",
      top: 714.1122,
      left: 258.547,
      width: 112.036,
      height: 15.4546
   }
   ] }
   