export function gTAREarnestMoneyInstructions() {
return [   //2024 Release 2024-04-18 19:56:46
{
   page: 0,
   name: "Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 78.237,
   left: 104.728,
   width: 210.873,
   height: 14.145
}
,
{
   page: 0,
   name: "below completed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 280.8,
   left: 272.16,
   width: 264.12,
   height: 11.4
}
,
{
   page: 0,
   name: "below completed_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 470.76,
   left: 264,
   width: 263.88,
   height: 11.28
}
,
{
   page: 0,
   name: "Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 80.88,
   left: 358.44,
   width: 203.531,
   height: 10.331
}
,
{
   page: 0,
   name: "as the Earnest Money has been delivered to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 144.36,
   left: 474.24,
   width: 66.00,
   height: 11.64
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 305.88,
   left: 324,
   width: 88.08,
   height: 11.64
}
,
{
   page: 0,
   name: "Date_2", 
   fontSize: 9,
   type: "date",
   top: 495.72,
   left: 324,
   width: 93.6,
   height: 11.64
}
,
{
   page: 0,
   name: "Print Name Sellers BrokerAssociate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 533.64,
   left: 72,
   width: 214.56,
   height: 11.64
}
,
{
   page: 0,
   name: "Name of Company or Party", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 621.24,
   left: 85.56,
   width: 213.60,
   height: 11.64
}
,
{
   page: 0,
   name: "signature Buyer Broker", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 306.02,
   left: 71.8145,
   width: 213.5995,
   height: 11.64
}
,
{
   page: 0,
   name: "name Buyer Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 344.639,
   left: 72.5879,
   width: 209.6291,
   height: 11.640
}
,
{
   page: 0,
   name: "Print Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 687.24,
   left: 85.56,
   width: 208.20,
   height: 11.64
}
,
{
   page: 0,
   name: "Title", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 720.12,
   left: 85.56,
   width: 213.60,
   height: 11.64
}
,
{
   page: 0,
   name: "Text42", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 154.819,
   left: 186.546,
   width: 155.237,
   height: 14.145
}
,
{
   page: 0,
   name: "Text43", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 493.22,
   left: 72.6549,
   width: 212.1821,
   height: 13.491
}
,
{
   page: 0,
   name: "Text44", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 102.146,
   left: 157.092,
   width: 428.837,
   height: 15.454
}
,
{
   page: 0,
   name: "Check Box45", 
   isText: false,
   type: "checkbox",
   top: 158.401,
   left: 68.7276,
   width: 12.1090,
   height: 12.763
}
,
{
   page: 0,
   name: "Check Box46", 
   isText: false,
   type: "checkbox",
   top: 158.401,
   left: 109.964,
   width: 12.109,
   height: 12.763
}
,
{
   page: 0,
   name: "Check Box47", 
   isText: false,
   type: "checkbox",
   top: 158.401,
   left: 145.31,
   width: 12.109,
   height: 12.763
}
,
{
   page: 0,
   name: "Check Box48", 
   isText: false,
   type: "checkbox",
   top: 205.855,
   left: 68.7276,
   width: 12.1090,
   height: 12.763
}
,
{
   page: 0,
   name: "Check Box49", 
   isText: false,
   type: "checkbox",
   top: 242.837,
   left: 149.237,
   width: 12.109,
   height: 12.764
}
,
{
   page: 0,
   name: "Check Box50", 
   isText: false,
   type: "checkbox",
   top: 255.001,
   left: 149.237,
   width: 12.109,
   height: 12.764
}
,
{
   page: 0,
   name: "Check Box51", 
   isText: false,
   type: "checkbox",
   top: 268.056,
   left: 149.237,
   width: 12.109,
   height: 12.763
}
,
{
   page: 0,
   name: "Check Box52", 
   isText: false,
   type: "checkbox",
   top: 395.347,
   left: 72.0003,
   width: 12.1091,
   height: 14.073
}
,
{
   page: 0,
   name: "Check Box53", 
   isText: false,
   type: "checkbox",
   top: 443.129,
   left: 150.892,
   width: 12.109,
   height: 14.073
}
,
{
   page: 0,
   name: "Check Box54", 
   isText: false,
   type: "checkbox",
   top: 461.457,
   left: 150.892,
   width: 12.109,
   height: 14.072
}
] }
