import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  Header,
  Segment,
  Tab,
  Input,
} from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { searchFilter } from "../../../app/common/util/util";

export default function ClausesView() {
  const dispatch = useDispatch();
  const { clauses } = useSelector((state) => state.clauses);
  const [searchTerms, setSearchTerms] = useState("");

  const legalClauses = clauses?.filter((clause) => clause.type === "legal");
  const clausesFilteredOnSearch = searchFilter(legalClauses, searchTerms);

  const userClauses = clauses?.filter((clause) => clause.type === "user");
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  if (!clauses) {
    return <LoadingComponent />;
  }

  const panes = [
    {
      menuItem: "Legal",
      render: () => (
        <Tab.Pane>
          <Segment>
            <Input
              type="text"
              fluid
              placeholder="Search by clause name, description, or category"
              value={searchTerms}
              label="Search"
              onChange={(e) => setSearchTerms(e.target.value)}
            ></Input>
          </Segment>
          <Segment style={{ overflow: "auto", maxHeight: 600 }}>
            <Grid stackable>
              {legalClauses?.length === 0 && (
                <Grid.Row className="small top padding">
                  <Grid.Column width={3}>
                    <b>Clause</b>
                  </Grid.Column>
                  <Grid.Column width={13}>
                    Reach out to your brokerage to send info@Transactioner.com
                    their legal clauses to be used by users within your
                    brokerage.
                  </Grid.Column>
                </Grid.Row>
              )}
              {clausesFilteredOnSearch &&
                clausesFilteredOnSearch?.length !== 0 &&
                clausesFilteredOnSearch.map((clause) => (
                  // {legalClauses?.length !== 0 &&
                  //   legalClauses.map((clause) => (
                  <Grid.Row key={clause.id} className="small top padding">
                    <Grid.Column width={2}>
                      <b>{clause.title}</b>
                    </Grid.Column>
                    <Grid.Column width={14}>{clause.text}</Grid.Column>
                  </Grid.Row>
                ))}
            </Grid>
          </Segment>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "User",
      render: () => (
        <Tab.Pane>
          {" "}
          <Segment style={{ overflow: "auto", maxHeight: 600 }}>
            <Grid stackable>
              {userClauses?.length === 0 && (
                <Grid.Row className="small top padding">
                  <Grid.Column width={3}>
                    <b>Clause</b>
                  </Grid.Column>
                  <Grid.Column width={13}>
                    You can add your own clauses by closing this window and
                    going to the menu in the upper right corner and clicking "My
                    Clauses"
                  </Grid.Column>
                </Grid.Row>
              )}
              {userClauses?.length !== 0 &&
                userClauses.map((clause) => (
                  <Grid.Row key={clause.id} className="small top padding">
                    <Grid.Column width={2}>
                      <b>{clause.title}</b>
                    </Grid.Column>
                    <Grid.Column width={14} style={{ whiteSpace: "pre-line" }}>
                      {clause.text}
                    </Grid.Column>
                  </Grid.Row>
                ))}
            </Grid>
          </Segment>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <ModalWrapper>
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Header size="large" color="blue">
            Legal & User Clauses
          </Header>
          <Divider />
          To add a clause to your document: 1) Highlight the text below and copy
          it 2) Close this window 3) Paste the copied text into desired section
          of the document
          <Divider hidden />
          <Tab panes={panes} />
          <br />
          <Button
            onClick={() =>
              dispatch(
                closeModal({
                  modalType: "ClausesView",
                })
              )
            }
            type="button"
            floated={isMobile ? null : "right"}
            content="Close"
            className={isMobile ? "fluid medium bottom margin" : null}
          />
        </div>
      </Segment>
    </ModalWrapper>
  );
}
