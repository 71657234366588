export function conditionedonSalePresentlyUnderContract() {
return [   //2024 Release 2024-02-20 14:35:46
{
   page: 0,
   name: "Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 161.28,
   left: 31.5,
   width: 544.644,
   height: 10.882
}
,
{
   page: 0,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 175.376,
   left: 31.5,
   width: 544.644,
   height: 10.882
}
,
{
   page: 0,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 201.21,
   left: 31.5,
   width: 544.644,
   height: 10.882
}
,
{
   page: 0,
   name: "Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 203.848,
   left: 31.5,
   width: 544.644,
   height: 10.882
}
,
{
   page: 0,
   name: "Text Field 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 271.602,
   left: 31.5,
   width: 193.86,
   height: 10.882
}
] }
