export function homeWarrantyInspectionFormHSPR() {
   return [   //2023
   {
      page: 0,
      name: "Date Contract", 
      fontSize: 9,
      type: "text",
      top: 245.64,
      left: 202.32,
      width: 110.40,
      height: 15.60
   }
   ,
   {
      page: 0,
      name: "Signature Agent", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 529.397,
      left: 307.68,
      width: 143.52,
      height: 16.243
   }
   ,
   {
      page: 0,
      name: "Date_2", 
      fontSize: 9,
      type: "date",
      top: 528.742,
      left: 458.4,
      width: 88.32,
      height: 16.898
   }
   ,
   {
      page: 0,
      name: "propertyAddress", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 262.474,
      left: 84.4367,
      width: 436.0373,
      height: 15.454
   }
   ] }
   