import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "semantic-ui-react";
import { closeModal } from "./modalSlice";

export default function ModalWrapper({ children, size, header, actions }) {
  const dispatch = useDispatch();

  return (
    <Modal
      closeIcon
      open={true}
      onClose={() => dispatch(closeModal())}
      size={size}
    >
      {header && (
        <Modal.Header style={{ textAlign: "center" }}>{header}</Modal.Header>
      )}
      <Modal.Content>{children}</Modal.Content>
      {actions && <Modal.Actions>{actions}</Modal.Actions>}
    </Modal>
  );
}
