export function affiliatedBusinessDisclosureCB_DP() {
    return [   //2024 Release 2024-01-05 10:37:29
    {
        page: 0,
        name: "Logo",
        isText: false,
        fontSize: 10,
        type: "logo",
        top: 28.673,
        left: 52.9457,
        width: 288.0003,
        height: 43.2,
      }
     ,
     {
       page: 0,
       name: "Common property address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 140.662,
       left: 182.858,
       width: 370.942,
       height: 13.942
    }
    ,
    {
       page: 0,
       name: "SellerName1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 215.728,
       left: 71.3457,
       width: 203.6733,
       height: 14.800
    }
    ,
    {
       page: 0,
       name: "BuyerName1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 215.728,
       left: 344.692,
       width: 203.673,
       height: 14.800
    }
    ,
    {
       page: 0,
       name: "SellerName2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 251.874,
       left: 72.0003,
       width: 203.6727,
       height: 15.455
    }
    ,
    {
       page: 0,
       name: "BuyerName2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 251.765,
       left: 344.692,
       width: 203.673,
       height: 16.109
    }
    ] }
    
