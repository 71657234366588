export function newHomeConstruction() {
return [   //2024 Release 2024-02-20 15:17:30
{
   page: 0,
   name: "Disclosure Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 12.96,
   left: 127.44,
   width: 466.56,
   height: 12.60
}
,
{
   page: 0,
   name: "Disclosure Box 1", 
   isText: false,
   type: "checkbox",
   top: 143.104,
   left: 49.9725,
   width: 6.8400,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 2", 
   isText: false,
   type: "checkbox",
   top: 197.248,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 3", 
   isText: false,
   type: "checkbox",
   top: 223.384,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 4", 
   isText: false,
   type: "checkbox",
   top: 249.52,
   left: 49.968,
   width: 6.840,
   height: 6.84
}
,
{
   page: 0,
   name: "Disclosure Box 5", 
   isText: false,
   type: "checkbox",
   top: 264.712,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 6", 
   isText: false,
   type: "checkbox",
   top: 297.472,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 7", 
   isText: false,
   type: "checkbox",
   top: 334.336,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 8", 
   isText: false,
   type: "checkbox",
   top: 349.695,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Text Field 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 401.472,
   left: 152.64,
   width: 156.96,
   height: 13.500
}
,
{
   page: 0,
   name: "Disclosure Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 401.472,
   left: 438.75,
   width: 138.33,
   height: 13.500
}
,
{
   page: 0,
   name: "Disclosure Box 9", 
   isText: false,
   type: "checkbox",
   top: 500.944,
   left: 68.04,
   width: 6.84,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 561.352,
   left: 157.68,
   width: 155.88,
   height: 13.500
}
,
{
   page: 0,
   name: "Disclosure Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 561.352,
   left: 442.842,
   width: 134.000,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 186", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 15.12,
   left: 111.96,
   width: 467.28,
   height: 13.50
}
,
{
   page: 1,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 127.2,
   left: 75.6,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 9", 
   isText: false,
   type: "checkbox",
   top: 127.344,
   left: 349.632,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 1", 
   isText: false,
   type: "checkbox",
   top: 137.28,
   left: 75.6,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 10", 
   isText: false,
   type: "checkbox",
   top: 137.28,
   left: 349.632,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 147.36,
   left: 75.6,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 11", 
   isText: false,
   type: "checkbox",
   top: 147.216,
   left: 349.632,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 12", 
   isText: false,
   type: "checkbox",
   top: 157.152,
   left: 349.632,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 4", 
   isText: false,
   type: "checkbox",
   top: 177.168,
   left: 75.6,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 5", 
   isText: false,
   type: "checkbox",
   top: 187.392,
   left: 75.6,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 13", 
   isText: false,
   type: "checkbox",
   top: 187.104,
   left: 349.632,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 6", 
   isText: false,
   type: "checkbox",
   top: 197.4,
   left: 75.6,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Text Field 18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 198.462,
   left: 97.992,
   width: 204.840,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 14", 
   isText: false,
   type: "checkbox",
   top: 197.184,
   left: 349.632,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 7", 
   isText: false,
   type: "checkbox",
   top: 207.408,
   left: 75.6,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Text Field 19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 208.47,
   left: 97.992,
   width: 204.840,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 15", 
   isText: false,
   type: "checkbox",
   top: 207.264,
   left: 349.632,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 8", 
   isText: false,
   type: "checkbox",
   top: 217.416,
   left: 75.6,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 218.478,
   left: 97.992,
   width: 204.840,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 16", 
   isText: false,
   type: "checkbox",
   top: 217.344,
   left: 349.632,
   width: 19.728,
   height: 9.612
}
,
{
   page: 1,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 243.552,
   left: 31.5,
   width: 515.7,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 259.392,
   left: 48.96,
   width: 500.472,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 395.8,
   left: 326.16,
   width: 174.96,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 416.072,
   left: 156.6,
   width: 420.84,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 431.193,
   left: 56.52,
   width: 520.92,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 446.313,
   left: 56.52,
   width: 520.92,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 462.04,
   left: 56.52,
   width: 349.331,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 462.04,
   left: 406.16,
   width: 114.32,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 462.04,
   left: 521.48,
   width: 55.96,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.104,
   left: 209.016,
   width: 129.384,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 544.544,
   left: 241.92,
   width: 71.28,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 554.128,
   left: 54.72,
   width: 156.24,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 620.364,
   left: 261.0,
   width: 219.96,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 650.124,
   left: 58.76,
   width: 521.74,
   height: 13.500
}
,
{
   page: 2,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 15.12,
   left: 111.96,
   width: 467.28,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 187", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 35.0,
   left: 149.68,
   width: 321.12,
   height: 13.5
}
,
{
   page: 2,
   name: "Text Field 22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 86.76,
   left: 145.8,
   width: 80.28,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 396.36,
   left: 99.0,
   width: 474.84,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 451.44,
   left: 376.2,
   width: 108.0,
   height: 13.50
}
,
{
   page: 2,
   name: "Check Box 17", 
   isText: false,
   type: "checkbox",
   top: 663.104,
   left: 99.216,
   width: 9.216,
   height: 9.396
}
,
{
   page: 2,
   name: "Check Box 18", 
   isText: false,
   type: "checkbox",
   top: 677.144,
   left: 99.216,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Text Field 25", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.76,
   left: 111.96,
   width: 467.28,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 316.64,
   left: 76.5,
   width: 505.25,
   height: 13.50
}
,
{
   page: 3,
   name: "Check Box 19", 
   isText: false,
   type: "checkbox",
   top: 556.274,
   left: 77.04,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 20", 
   isText: false,
   type: "checkbox",
   top: 594.295,
   left: 77.04,
   width: 9.216,
   height: 9.396
}
,
{
   page: 4,
   name: "Text Field 28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 15.12,
   left: 111.96,
   width: 467.28,
   height: 13.50
}
,
{
   page: 4,
   name: "Check Box 31", 
   isText: false,
   type: "checkbox",
   top: 74.36,
   left: 77.04,
   width: 9.216,
   height: 9.396
}
,
{
   page: 4,
   name: "Check Box 32", 
   isText: false,
   type: "checkbox",
   top: 88.4,
   left: 77.04,
   width: 9.216,
   height: 9.396
}
,
{
   page: 4,
   name: "Text Field 29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 243.968,
   left: 186.408,
   width: 66.816,
   height: 13.500
}
,
{
   page: 4,
   name: "Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 513.392,
   left: 55.44,
   width: 526.31,
   height: 13.500
}
,
{
   page: 4,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 526.208,
   left: 55.44,
   width: 526.31,
   height: 13.500
}
,
{
   page: 4,
   name: "Text Field 32", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 539.168,
   left: 55.44,
   width: 526.31,
   height: 13.500
}
,
{
   page: 4,
   name: "Text Field 33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 551.984,
   left: 55.44,
   width: 526.31,
   height: 13.500
}
,
{
   page: 4,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 564.998,
   left: 55.44,
   width: 526.31,
   height: 13.500
}
,
{
   page: 4,
   name: "Text Field 35", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 577.958,
   left: 55.44,
   width: 526.31,
   height: 13.500
}
,
{
   page: 4,
   name: "Text Field 36", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 590.774,
   left: 55.44,
   width: 526.31,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 37", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.76,
   left: 111.96,
   width: 467.28,
   height: 13.50
}
,
{
   page: 5,
   name: "date Text Field 1010", 
   fontSize: 9,
   type: "date",
   top: 509.689,
   left: 373.5,
   width: 114.75,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 1015", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 509.689,
   left: 501.625,
   width: 75.095,
   height: 13.500
}
,
{
   page: 5,
   name: "Check Box 33", 
   isText: false,
   type: "checkbox",
   top: 523.189,
   left: 48.944,
   width: 9.216,
   height: 9.396
}
,
{
   page: 5,
   name: "Check Box 34", 
   isText: false,
   type: "checkbox",
   top: 523.189,
   left: 86.76,
   width: 9.216,
   height: 9.396
}
,
{
   page: 5,
   name: "Text Field 106", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 575.129,
   left: 51.552,
   width: 235.008,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 107", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 575.129,
   left: 321.48,
   width: 255.24,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 108", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 649.009,
   left: 51.552,
   width: 235.008,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 109", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 649.009,
   left: 321.48,
   width: 255.24,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 73", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.76,
   left: 111.96,
   width: 467.28,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 1025", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 49.25,
   left: 51.552,
   width: 235.008,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 1026", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 49.25,
   left: 321.48,
   width: 255.24,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 1023", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 122.56,
   left: 51.552,
   width: 235.008,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 1024", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 122.56,
   left: 321.48,
   width: 255.24,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 103", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 195.706,
   left: 330.64,
   width: 126.20,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 1019", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 195.706,
   left: 471.6,
   width: 105.75,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 145", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 261.12,
   left: 205.065,
   width: 75.060,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 146", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 261.12,
   left: 413.424,
   width: 35.136,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 147", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 270.912,
   left: 87.984,
   width: 35.136,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 148", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 293.88,
   left: 417.528,
   width: 38.232,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 149", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 303.392,
   left: 104.625,
   width: 38.007,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 150", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 314.112,
   left: 420.336,
   width: 38.232,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 104", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 324.238,
   left: 103.68,
   width: 38.515,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 105", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 332.988,
   left: 258.84,
   width: 85.68,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 133", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 409.08,
   left: 31.0563,
   width: 261.5287,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 134", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 409.08,
   left: 318.672,
   width: 259.964,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 135", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 440.176,
   left: 31.6557,
   width: 261.5283,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 136", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 440.176,
   left: 319.276,
   width: 259.964,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 131", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 470.692,
   left: 31.2868,
   width: 261.5282,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 132", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 470.692,
   left: 318.904,
   width: 259.964,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 137", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 501.208,
   left: 31.2868,
   width: 261.5282,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 138", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 501.208,
   left: 318.904,
   width: 259.964,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 301", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 532.724,
   left: 31.6557,
   width: 261.5283,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 302", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 532.724,
   left: 319.276,
   width: 259.964,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 303", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 564.24,
   left: 30.672,
   width: 182.613,
   height: 13.50
}
,
{
   page: 6,
   name: "date Text Field 205", 
   fontSize: 9,
   type: "date",
   top: 564.24,
   left: 217.285,
   width: 79.899,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 304", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 564.24,
   left: 318.672,
   width: 182.613,
   height: 13.50
}
,
{
   page: 6,
   name: "date Text Field 206", 
   fontSize: 9,
   type: "date",
   top: 564.24,
   left: 505.285,
   width: 77.369,
   height: 13.50
}
] }
