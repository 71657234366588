export function addendumThirdPartyFinancing() {
return [   //2024 Release 2024-02-19 07:49:43
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 141.654,
   left: 55.1441,
   width: 504.1209,
   height: 13.885
}
,
{
   page: 0,
   name: "1 Conventional Financing", 
   isText: false,
   type: "checkbox",
   top: 224.618,
   left: 56.88,
   width: 12.1636,
   height: 10.167
}
,
{
   page: 0,
   name: "1A(1)", 
   isText: false,
   type: "checkbox",
   top: 237.971,
   left: 85.9964,
   width: 11.6400,
   height: 10.167
}
,
{
   page: 0,
   name: "Principal Amount", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 238.593,
   left: 374.225,
   width: 101.815,
   height: 11.160
}
,
{
   page: 0,
   name: "Number of Years", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 250.134,
   left: 291.709,
   width: 37.789,
   height: 12.208
}
,
{
   page: 0,
   name: "Percent 0", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 250.331,
   left: 514.504,
   width: 29.095,
   height: 11.160
}
,
{
   page: 0,
   name: " of Years 0", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 261.851,
   left: 219.12,
   width: 45.24,
   height: 11.160
}
,
{
   page: 0,
   name: "Percent 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 271.353,
   left: 351.524,
   width: 41.258,
   height: 11.683
}
,
{
   page: 0,
   name: "1A(2)", 
   isText: false,
   type: "checkbox",
   top: 286.233,
   left: 87.0436,
   width: 11.1164,
   height: 9.120
}
,
{
   page: 0,
   name: "Amount 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 286.855,
   left: 389.793,
   width: 87.196,
   height: 10.767
}
,
{
   page: 0,
   name: " of Years 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 296.17,
   left: 302.673,
   width: 62.324,
   height: 11.684
}
,
{
   page: 0,
   name: "Percent 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 308.018,
   left: 101.095,
   width: 31.404,
   height: 11.160
}
,
{
   page: 0,
   name: " of Years 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 307.495,
   left: 267.382,
   width: 46.124,
   height: 11.160
}
,
{
   page: 0,
   name: "Percent 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 319.222,
   left: 402.793,
   width: 52.800,
   height: 11.160
}
,
{
   page: 0,
   name: "1B", 
   isText: false,
   type: "checkbox",
   top: 331.211,
   left: 56.3564,
   width: 12.1636,
   height: 11.215
}
,
{
   page: 0,
   name: "Amount 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 343.759,
   left: 94.6806,
   width: 94.6584,
   height: 11.684
}
,
{
   page: 0,
   name: " of Years 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 343.08,
   left: 375.304,
   width: 48.317,
   height: 11.16
}
,
{
   page: 0,
   name: "1C", 
   isText: false,
   type: "checkbox",
   top: 367.091,
   left: 57.4037,
   width: 11.6399,
   height: 10.167
}
,
{
   page: 0,
   name: "Section Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 368.19,
   left: 280.854,
   width: 103.167,
   height: 10.636
}
,
{
   page: 0,
   name: "Amount 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 379.244,
   left: 94.6915,
   width: 105.8185,
   height: 10.636
}
,
{
   page: 0,
   name: " of Years 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 389.542,
   left: 113.785,
   width: 49.200,
   height: 11.160
}
,
{
   page: 0,
   name: "Percent4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 390.067,
   left: 336.076,
   width: 44.400,
   height: 11.160
}
,
{
   page: 0,
   name: " of Years 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 400.207,
   left: 88.6865,
   width: 40.3285,
   height: 11.229
}
,
{
   page: 0,
   name: "Percent 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 410.76,
   left: 204.23,
   width: 38.76,
   height: 11.16
}
,
{
   page: 0,
   name: "1D", 
   isText: false,
   type: "checkbox",
   top: 425.128,
   left: 57.9273,
   width: 9.0218,
   height: 10.167
}
,
{
   page: 0,
   name: "Amount 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 424.637,
   left: 444.556,
   width: 105.797,
   height: 11.684
}
,
{
   page: 0,
   name: " of Year 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 436.179,
   left: 481.723,
   width: 38.586,
   height: 11.683
}
,
{
   page: 0,
   name: "Percent 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 446.978,
   left: 224.978,
   width: 37.953,
   height: 11.684
}
,
{
   page: 0,
   name: " of Years 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 447.461,
   left: 395.826,
   width: 39.240,
   height: 11.160
}
,
{
   page: 0,
   name: "Percent 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 469.257,
   left: 85.7458,
   width: 43.3082,
   height: 11.527
}
,
{
   page: 0,
   name: "1E", 
   isText: false,
   type: "checkbox",
   top: 480.96,
   left: 56.88,
   width: 11.64,
   height: 10.691
}
,
{
   page: 0,
   name: "Amount 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 482.27,
   left: 484.353,
   width: 69.600,
   height: 11.683
}
,
{
   page: 0,
   name: " of Years 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 494.761,
   left: 479.704,
   width: 40.431,
   height: 10.637
}
,
{
   page: 0,
   name: "Percent 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 504.415,
   left: 227.607,
   width: 28.320,
   height: 11.040
}
,
{
   page: 0,
   name: " of Years 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 504.612,
   left: 391.19,
   width: 42.316,
   height: 11.160
}
,
{
   page: 0,
   name: "Percent 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 515.224,
   left: 513.554,
   width: 29.215,
   height: 11.040
}
,
{
   page: 0,
   name: "1F", 
   isText: false,
   type: "checkbox",
   top: 538.92,
   left: 56.4873,
   width: 11.5091,
   height: 10.691
}
,
{
   page: 0,
   name: "Amount 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 551.018,
   left: 411.414,
   width: 79.603,
   height: 11.564
}
,
{
   page: 0,
   name: "Percent 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 563.041,
   left: 451.047,
   width: 34.320,
   height: 11.160
}
,
{
   page: 0,
   name: " of Years 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 573.197,
   left: 148.625,
   width: 41.880,
   height: 11.160
}
,
{
   page: 0,
   name: "Percent 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 586.178,
   left: 274.964,
   width: 25.680,
   height: 11.160
}
,
{
   page: 0,
   name: "Will", 
   isText: false,
   type: "checkbox",
   top: 586.742,
   left: 526.559,
   width: 10.592,
   height: 9.207
}
,
{
   page: 0,
   name: "will not", 
   isText: false,
   type: "checkbox",
   top: 600.709,
   left: 88.8436,
   width: 10.5927,
   height: 10.691
}
,
{
   page: 0,
   name: "1G", 
   isText: false,
   type: "checkbox",
   top: 615.984,
   left: 58.4746,
   width: 9.0218,
   height: 10.168
}
,
{
   page: 0,
   name: "Name of Lender", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 614.493,
   left: 426.863,
   width: 126.553,
   height: 11.685
}
,
{
   page: 0,
   name: "Amount 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 625.776,
   left: 331.204,
   width: 70.209,
   height: 11.789
}
,
{
   page: 0,
   name: " of Years 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 624.777,
   left: 443.16,
   width: 39.215,
   height: 12.313
}
,
{
   page: 0,
   name: "Percent 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 636.835,
   left: 212.525,
   width: 23.487,
   height: 11.683
}
,
{
   page: 0,
   name: " of Years", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 637.318,
   left: 386.405,
   width: 34.208,
   height: 11.160
}
,
{
   page: 0,
   name: "Percent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 651.308,
   left: 264.296,
   width: 25.697,
   height: 10.898
}
,
{
   page: 0,
   name: "Does", 
   isText: false,
   type: "checkbox",
   top: 650.457,
   left: 405.391,
   width: 10.593,
   height: 10.691
}
,
{
   page: 0,
   name: "does not", 
   isText: false,
   type: "checkbox",
   top: 649.828,
   left: 448.391,
   width: 10.593,
   height: 11.320
}
,
{
   page: 1,
   name: "2A This contract is subject to Buyer obtaining Buyer Approval", 
   isText: false,
   type: "checkbox",
   top: 97.74,
   left: 82.1021,
   width: 11.7168,
   height: 12.24
}
,
{
   page: 1,
   name: " of Days", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 109.831,
   left: 393.204,
   width: 37.069,
   height: 10.636
}
,
{
   page: 1,
   name: "2A This contract is not subject to Buyer obtaining Buyer Approval", 
   isText: false,
   type: "checkbox",
   top: 178.389,
   left: 83.9964,
   width: 10.9963,
   height: 10.691
}
,
{
   page: 1,
   name: "Amount", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 360.32,
   left: 75.9044,
   width: 128.3696,
   height: 11.76
}
,
{
   page: 1,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 48.574,
   left: 56.88,
   width: 498.12,
   height: 15.768
}
] }
