export function sewerTreatmentSystemsAddendum() {
return [   //2024 Release 2024-06-06 18:26:28
{
   page: 0,
   name: "Property Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 102.8,
   left: 51.96,
   width: 297.633,
   height: 14.073
}
,
{
   page: 0,
   name: "City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 102.8,
   left: 352.789,
   width: 118.091,
   height: 14.073
}
,
{
   page: 0,
   name: "State", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 102.8,
   left: 476.04,
   width: 29.291,
   height: 14.073
}
,
{
   page: 0,
   name: "Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 102.8,
   left: 510.491,
   width: 53.029,
   height: 14.073
}
,
{
   page: 0,
   name: "Private Utility", 
   isText: false,
   type: "checkbox",
   top: 476.92,
   left: 68.64,
   width: 10.80,
   height: 10.80
}
,
{
   page: 0,
   name: "Onsite System", 
   isText: false,
   type: "checkbox",
   top: 476.92,
   left: 156.12,
   width: 10.80,
   height: 10.80
}
,
{
   page: 0,
   name: "None", 
   isText: false,
   type: "checkbox",
   top: 476.92,
   left: 246.48,
   width: 10.80,
   height: 10.80
}
,
{
   page: 0,
   name: "Not Known", 
   isText: false,
   type: "checkbox",
   top: 476.92,
   left: 297.12,
   width: 10.80,
   height: 10.80
}
,
{
   page: 0,
   name: "Check Box4", 
   isText: false,
   type: "checkbox",
   top: 516.784,
   left: 73.6549,
   width: 14.0727,
   height: 12.109
}
,
{
   page: 0,
   name: "Check Box5", 
   isText: false,
   type: "checkbox",
   top: 516.784,
   left: 121.255,
   width: 14.073,
   height: 12.109
}
,
{
   page: 0,
   name: "Check Box6", 
   isText: false,
   type: "checkbox",
   top: 516.784,
   left: 165.492,
   width: 14.072,
   height: 12.109
}
,
{
   page: 0,
   name: "Septic Tank wfield lines", 
   isText: false,
   type: "checkbox",
   top: 621.76,
   left: 66.0,
   width: 10.8,
   height: 10.80
}
,
{
   page: 0,
   name: "Aerobic Treatment Unit", 
   isText: false,
   type: "checkbox",
   top: 621.76,
   left: 201.0,
   width: 10.8,
   height: 10.80
}
,
{
   page: 0,
   name: "Septic tank wOxidation Pond", 
   isText: false,
   type: "checkbox",
   top: 621.76,
   left: 333.48,
   width: 10.80,
   height: 10.80
}
,
{
   page: 0,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 621.76,
   left: 493.68,
   width: 10.80,
   height: 10.80
}
,
{
   page: 0,
   name: "Not Known_3", 
   isText: false,
   type: "checkbox",
   top: 635.08,
   left: 66.0,
   width: 10.8,
   height: 10.80
}
,
{
   page: 0,
   name: "5 What is the approximate age of the system", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 716.52,
   left: 265.56,
   width: 120.60,
   height: 11.52
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 716.52,
   left: 397.44,
   width: 32.64,
   height: 11.52
}
,
{
   page: 0,
   name: "Not Known_5", 
   isText: false,
   type: "checkbox",
   top: 717.28,
   left: 439.08,
   width: 10.80,
   height: 10.80
}
,
{
   page: 0,
   name: "Check Box18", 
   isText: false,
   type: "checkbox",
   top: 675.803,
   left: 83.4731,
   width: 13.4182,
   height: 12.109
}
,
{
   page: 0,
   name: "Check Box22", 
   isText: false,
   type: "checkbox",
   top: 675.803,
   left: 133.492,
   width: 13.418,
   height: 12.109
}
,
{
   page: 0,
   name: "Check Box23", 
   isText: false,
   type: "checkbox",
   top: 675.803,
   left: 178.001,
   width: 13.418,
   height: 12.109
}
,
{
   page: 1,
   name: "The original permit was issued by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 69.825,
   left: 254.52,
   width: 146.684,
   height: 10.997
}
,
{
   page: 1,
   name: "date on", 
   fontSize: 9,
   type: "date",
   top: 69.48,
   left: 413.538,
   width: 55.474,
   height: 11.651
}
,
{
   page: 1,
   name: "Not Known_6", 
   isText: false,
   type: "checkbox",
   top: 71.44,
   left: 507.48,
   width: 10.80,
   height: 10.80
}
,
{
   page: 1,
   name: "The system was last inspected by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 111.0,
   left: 255.415,
   width: 170.945,
   height: 11.52
}
,
{
   page: 1,
   name: "date on_2", 
   fontSize: 9,
   type: "date",
   top: 111,
   left: 441.12,
   width: 62.64,
   height: 11.52
}
,
{
   page: 1,
   name: "Not Known_7", 
   isText: false,
   type: "checkbox",
   top: 125.2,
   left: 106.44,
   width: 10.80,
   height: 10.8
}
,
{
   page: 1,
   name: "If yes on what date was the inspection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 214.2,
   left: 297.96,
   width: 219.12,
   height: 12.96
}
,
{
   page: 1,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 246.1,
   left: 266.135,
   width: 255.174,
   height: 12.72
}
,
{
   page: 1,
   name: "Check Box7", 
   isText: false,
   type: "checkbox",
   top: 285.883,
   left: 295.828,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box8", 
   isText: false,
   type: "checkbox",
   top: 285.883,
   left: 336.983,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box9", 
   isText: false,
   type: "checkbox",
   top: 285.968,
   left: 372.223,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Text18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 300.763,
   left: 249.673,
   width: 245.114,
   height: 12.065
}
,
{
   page: 1,
   name: "Check Box19", 
   isText: false,
   type: "checkbox",
   top: 347.072,
   left: 118.977,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box20", 
   isText: false,
   type: "checkbox",
   top: 347.072,
   left: 169.449,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box21", 
   isText: false,
   type: "checkbox",
   top: 346.158,
   left: 215.176,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box10", 
   isText: false,
   type: "checkbox",
   top: 408.176,
   left: 84.0535,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box11", 
   isText: false,
   type: "checkbox",
   top: 408.176,
   left: 185.569,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box12", 
   isText: false,
   type: "checkbox",
   top: 408.262,
   left: 259.819,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box13", 
   isText: false,
   type: "checkbox",
   top: 408.433,
   left: 352.359,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box14", 
   isText: false,
   type: "checkbox",
   top: 409.433,
   left: 481.055,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box15", 
   isText: false,
   type: "checkbox",
   top: 422.638,
   left: 84.3953,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box16", 
   isText: false,
   type: "checkbox",
   top: 421.895,
   left: 225.322,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box17", 
   isText: false,
   type: "checkbox",
   top: 422.066,
   left: 421.866,
   width: 10.800,
   height: 10.800
}
,
{
   page: 1,
   name: "Text22", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 9,
   top: 472.917,
   left: 88.7117,
   width: 454.5463,
   height: 25.676
}
,
{
   page: 1,
   name: "Metal", 
   isText: false,
   type: "checkbox",
   top: 522.4,
   left: 84.84,
   width: 10.80,
   height: 10.8
}
,
{
   page: 1,
   name: "Concrete", 
   isText: false,
   type: "checkbox",
   top: 522.4,
   left: 137.76,
   width: 10.80,
   height: 10.8
}
,
{
   page: 1,
   name: "Fiberglass", 
   isText: false,
   type: "checkbox",
   top: 522.4,
   left: 204.96,
   width: 10.80,
   height: 10.8
}
,
{
   page: 1,
   name: "Other_2", 
   isText: false,
   type: "checkbox",
   top: 522.4,
   left: 275.52,
   width: 10.80,
   height: 10.8
}
,
{
   page: 1,
   name: "Not Known_9", 
   isText: false,
   type: "checkbox",
   top: 522.4,
   left: 328.32,
   width: 10.80,
   height: 10.8
}
,
{
   page: 1,
   name: "If yes where is it located", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 572.64,
   left: 226.08,
   width: 268.32,
   height: 12.96
}
,
{
   page: 1,
   name: "Check Box24", 
   isText: false,
   type: "checkbox",
   top: 152.473,
   left: 255.856,
   width: 14.072,
   height: 14.073
}
,
{
   page: 1,
   name: "Check Box25", 
   isText: false,
   type: "checkbox",
   top: 166.546,
   left: 294.856,
   width: 14.073,
   height: 14.073
}
,
{
   page: 1,
   name: "Check Box26", 
   isText: false,
   type: "checkbox",
   top: 199.346,
   left: 333.165,
   width: 14.073,
   height: 14.072
}
,
{
   page: 1,
   name: "Check Box27", 
   isText: false,
   type: "checkbox",
   top: 199.346,
   left: 367.856,
   width: 14.073,
   height: 14.072
}
,
{
   page: 1,
   name: "Check Box28", 
   isText: false,
   type: "checkbox",
   top: 199.346,
   left: 402.547,
   width: 14.073,
   height: 14.072
}
,
{
   page: 1,
   name: "Check Box29", 
   isText: false,
   type: "checkbox",
   top: 547.548,
   left: 289.238,
   width: 12.763,
   height: 12.109
}
,
{
   page: 1,
   name: "Check Box30", 
   isText: false,
   type: "checkbox",
   top: 547.548,
   left: 332.82,
   width: 12.763,
   height: 12.109
}
,
{
   page: 1,
   name: "Check Box31", 
   isText: false,
   type: "checkbox",
   top: 547.548,
   left: 372.093,
   width: 12.763,
   height: 12.109
}
,
{
   page: 1,
   name: "Check Box32", 
   isText: false,
   type: "checkbox",
   top: 599.912,
   left: 246.765,
   width: 14.072,
   height: 14.727
}
,
{
   page: 1,
   name: "Check Box33", 
   isText: false,
   type: "checkbox",
   top: 599.912,
   left: 288.001,
   width: 14.073,
   height: 14.727
}
,
{
   page: 1,
   name: "Check Box34", 
   isText: false,
   type: "checkbox",
   top: 599.912,
   left: 326.62,
   width: 14.072,
   height: 14.727
}
] }
