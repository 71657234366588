export function fortCollinsOccupancyDisclosure() {
return [   //2024 Release 2024-03-09 08:21:46
{
   page: 0,
   name: "Property Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 391.4,
   left: 153.12,
   width: 246.84,
   height: 14.553
}
,
{
   page: 0,
   name: "Date of Sale", 
   fontSize: 9,
   type: "date",
   top: 389.56,
   left: 472.68,
   width: 60.12,
   height: 14.553
}
,
{
   page: 0,
   name: "Seller Names", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 428.32,
   left: 140.04,
   width: 402.72,
   height: 14.553
}
,
{
   page: 0,
   name: "Buyer Names", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 484.12,
   left: 140.16,
   width: 402.84,
   height: 14.553
}
,
{
   page: 0,
   name: "Address if different than property address 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 539.8,
   left: 297.24,
   width: 245.28,
   height: 14.553
}
,
{
   page: 0,
   name: "Address if different than property address 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 567.52,
   left: 295.2,
   width: 245.4,
   height: 14.553
}
] }
