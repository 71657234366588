export function singleFamilyHomeownersAssociation() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 124.56,
   left: 31.5,
   width: 548.1,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 148.752,
   left: 31.5,
   width: 548.1,
   height: 13.500
}
] }
