import React from "react";
import { Button, Divider, Segment } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalSlice";

export default function PrivacyPolicy() {
  const dispatch = useDispatch();

  return (
    <>
      <Segment>
        <h1>Our Privacy Policy</h1>
        <p>
          Lean MVP LLC respects the privacy of its users and has developed this
          Privacy Policy to demonstrate its commitment to protecting your
          privacy. This Privacy Policy describes the information we collect, how
          that information may be used, with whom it may be shared, and your
          choices about such uses and disclosures. We encourage you to read this
          Privacy Policy carefully when using our website, application, or
          services or transacting business with us. By using our website and/or
          application, you are accepting the practices described in this Privacy
          Policy.
        </p>
        <p>
          If you have any questions about our privacy practices, please refer to
          the end of this Privacy Policy for information on how to contact us.
        </p>
        <p>
          <u>In General.</u> We may collect information that can identify you
          such as your name and email address ("personal information") and other
          information that does not identify you. We may collect this
          information through a website or a mobile application. When you
          provide personal information through our website, the information may
          be sent to servers located in the United States and other countries
          around the world.
        </p>
        <h3>Information You Provide</h3>
        <p>
          We may collect and store any personal information you enter on our
          website or on a mobile application, or provide to us in some other
          manner. This includes but is not limited to identifying information,
          such as your name, address, email address and telephone number, and,
          if you transact business with us, financial information such as your
          payment method (valid credit card number, type, expiration date or
          other financial information). You may also provide us information
          about your interests and activities, your gender and age, and other
          demographic information such as your hometown or your username.
        </p>
        <h3> Information About Others</h3>
        <p>
          We may collect and store personal information about other people that
          you provide to us, such as their name, address and email address. If
          you use our website to send others (friends, relatives, colleagues,
          etc.) a product as a gift, we may store your personal information, and
          the personal information of each such recipient in order to process
          those requests or facilitate future activities. Use of cookies and
          other technologies to collect information. We may use various
          technologies to collect information from your device and about your
          activities on our site or application.
        </p>
        <h3>Information Collected Automatically</h3>
        <p>
          We automatically collect information from your browser or device when
          you visit our website or application. This information could include
          your IP address, device ID, your browser type and language, access
          times, the content of any undeleted cookies that your browser
          previously accepted from us (see "Cookies" below), and the referring
          website address.
        </p>
        <h3>Cookies and Use of Cookie Data</h3>
        <p>
          When you visit our website or application, we may assign your device
          one or more cookies, to facilitate access to our site and to
          personalize your online experience. Through the use of a cookie, we
          also may automatically collect information about your online activity
          on our site, such as the pages you visit, the time and date of your
          visits, the links you click, and the searches you conduct on our site.
          During your visits to other websites or applications where we
          advertise, we (or third parties) may use certain data collected on our
          site to show you advertisements from us or our affiliates. Most
          browsers automatically accept cookies, but you can usually modify your
          browser setting to decline cookies. If you choose to decline cookies,
          please note that you may not be able to sign in or use some of the
          interactive features offered on our site.
        </p>
        <h3>Other Technologies</h3>
        <p>
          We may use standard Internet technology, such as web beacons and other
          similar technologies, to track your use of our site. We also may
          include web beacons in advertisements or email messages to determine
          whether messages have been opened and acted upon. The information we
          obtain in this manner enables us to customize the services we offer
          our website or application visitors to deliver targeted advertisements
          and to measure the overall effectiveness of our online advertising,
          content, programming or other activities.
        </p>
        <h3>How We Use the Information We Collect</h3>
        <p>
          <u>In General.</u> We may use information that we collect about you
          to:
        </p>
        <ul>
          <li>deliver our products and services, and manage our business;</li>
          <li>manage your account and provide you with customer support;</li>
          <li>perform research and analysis;</li>
          <li>
            communicate with you about products or services that may be of
            interest to you.
          </li>
          <li>
            develop and display content and advertising tailored to your
            interests on our site and other sites, including providing our
            advertisements to you when you visit other sites;
          </li>
          <li>
            perform ad tracking and website or mobile application analytics;
          </li>
          <li>
            verify your eligibility and deliver prizes in connection with
            contests and sweepstakes;
          </li>
          <li>enforce or exercise any rights in our terms and conditions;</li>
          <li>
            perform functions as otherwise described to you at the time of
            collection; and
          </li>
        </ul>
        <p>
          <u>Job applicants.</u> If your personal information is submitted
          through our website when applying for a position with our company, the
          information will be used solely in connection with considering and
          acting upon your application. We may retain your personal information,
          but only for the purpose of considering your application for current
          or future available positions. Please review "Your choices about
          collection and use of your information" below.
        </p>
        <p>
          In all circumstances, we may perform these functions directly or use a
          third party vendor to perform these functions on our behalf who will
          be obligated to use your personal information only to perform services
          for us. Also, if you access our website or mobile applications from a
          third party social platform, such as Facebook, we may share
          non-personal information with that platform to the extent permitted by
          your agreement with it and its privacy settings.
        </p>
        <h3>With Whom We Share Your Information</h3>
        <p>
          <u>Personal information.</u> We do not share your personal information
          with others except as indicated in this Privacy Policy or when we
          inform you and give you an opportunity to opt out of having your
          personal information shared. We may share personal information with:
        </p>
        <ul>
          <li>
            Service providers: We may share information, including personal
            information, with third parties that perform certain services on our
            behalf. These services may include fulfilling orders, providing
            customer service and marketing assistance, performing business and
            sales analysis, ad tracking and analytics, member screenings,
            supporting our website functionality, and supporting contests,
            sweepstakes, surveys and other features offered through our site. We
            may also share your name, contact information and credit card
            information with our service providers who process credit card
            payments. These service providers may have access to personal
            information needed to perform their functions but are not permitted
            to share or use such information for any other purposes.
          </li>
          <li>
            Other Situations. We also may disclose your information, including
            personal information: o In response to a subpoena or similar
            investigative demand, a court order, or a request for cooperation
            from a law enforcement or other government agency; to establish or
            exercise our legal rights; to defend against legal claims; or as
            otherwise required by law. In such cases, we may raise or waive any
            legal objection or right available to us. o When we believe
            disclosure is appropriate in connection with efforts to investigate,
            prevent, or take other action regarding illegal activity, suspected
            fraud or other wrongdoing; to protect and defend the rights,
            property or safety of our company, our users, our employees, or
            others; to comply with applicable law or cooperate with law
            enforcement; or to enforce our website terms and conditions or other
            agreements or policies. o In connection with a substantial corporate
            transaction, such as the sale of our business, a divestiture,
            merger, consolidation, or asset sale, or in the unlikely event of
            bankruptcy. Aggregated and/or non-personal information. We may share
            non-personal information we collect under any of the above
            circumstances. We may also share it to analyze and report on
            advertising you see. We may combine non-personal information we
            collect with additional non-personal information collected from
            other sources.
          </li>
        </ul>
        <h3>Do Not Track Disclosure</h3>
        <p>
          Do Not Track (“DNT”) is a privacy preference that users can set in
          their web browsers. DNT is a way for users to inform websites and
          services that they do not want certain information about their webpage
          visits collected over time and across websites or online services. We
          are committed to providing you with meaningful choices about the
          information we collect and that is why we provide the opt-out links in
          the Privacy Policy. However, we do not recognize or respond to any DNT
          signals as the Internet industry works toward defining exactly what
          DNT means, what it means to comply with DNT, and a common approach to
          responding to DNT.
        </p>
        <p>
          <b>Third-party websites</b>
        </p>
        <p>
          There are a number of places on our website where you may click on a
          link to access other websites that do not operate under this Privacy
          Policy. For example, if you click on an advertisement on our website,
          you may be taken to a website that we do not control. These
          third-party websites may independently solicit and collect
          information, including personal information, from you and, in some
          instances, provide us with information about your activities on those
          websites. We recommend that you consult the privacy statements of all
          third-party websites you visit by clicking on the "privacy" link
          typically located at the bottom of the webpage you are visiting.
        </p>
        <p>
          <b>How you can access your information</b>
        </p>
        <p>
          If you have an online account with us, you have the ability to review
          and update your personal information online by logging into your
          account and clicking on your account settings. If you have an online
          account with us, you also may close your account at any time by
          visiting your account settings. If you close your account, we may
          still retain certain information associated with your account for
          analytical purposes and recordkeeping integrity, as well as to prevent
          fraud, collect any fees owed, enforce our terms and conditions, take
          actions we deem necessary to protect the integrity of our website or
          our users, or take other actions otherwise permitted by law. In
          addition, if certain information has already been provided to third
          parties as described in this Privacy Policy, retention of that
          information will be subject to those third parties' policies.
        </p>
        <p>
          <b>Your choices about collection and use of your information</b>
        </p>
        <ul>
          <li>
            You can choose not to provide us with certain information, but that
            may result in you being unable to use certain features of our site
            and application because such information may be required in order
            for you to register as a member; purchase products or services;
            participate in a contest, promotion, survey, or sweepstakes; ask a
            question; or initiate other transactions.
          </li>
          <li>
            When you register on our site, you consent to receive email messages
            from us. At any time you can choose to no longer receive commercial
            or promotional emails from us by visiting your account settings
            page. You also will be given the opportunity, in any commercial
            email that we send to you, to opt out of receiving such messages in
            the future. It may take up to 10 days for us to process an opt-out
            request. We may send you transactional and relationship emails, such
            as service announcements, administrative notices, and surveys,
            without offering you the opportunity to opt out of receiving them.
            Please note that changing information in your account, or otherwise
            opting out of receipt of promotional email communications, will only
            affect future activities or communications from us. If we have
            already provided your information to a third party (such as a
            service provider) before you have changed your preferences or
            updated your information, you may have to change you preferences
            directly with that third party.
          </li>
          <li>
            You can also control information collected by cookies. See “cookies”
            below for information about declining or deleting cookies.
          </li>
        </ul>
        <p>
          <b>How we protect your personal information</b>
        </p>
        <p>
          We take appropriate security measures (including physical, electronic
          and procedural measures) to help safeguard your personal information
          from unauthorized access and disclosure. However, no system can be
          completely secure. Therefore, although we take steps to secure your
          information, we do not promise, and you should not expect, that your
          personal information, searches, or other communications will always
          remain secure. Users should also take care with how they handle and
          disclose their personal information and should avoid sending personal
          information through insecure email. Please refer to the Federal Trade
          Commission's website athttp://www.ftc.gov/bcp/menus/consumer/data.shtm
          for information about how to protect yourself against identity theft.
        </p>
        <h3>Information You Provide About Yourself While Using Our Service</h3>
        <p>
          We provide areas on our site where you can post information about
          yourself and others and communicate with others or upload content such
          as photographs. Such postings are governed by our Terms of Use. In
          addition, such postings may appear on other websites or when searches
          are executed on the subject of your posting. Also, whenever you
          voluntarily disclose personal information on publicly-viewable web
          pages, that information may be publicly available and can be collected
          and used by others. For example, if you post your email address, you
          may receive unsolicited messages. We cannot control who reads your
          posting or what other users may do with the information you
          voluntarily post, so we encourage you to exercise discretion and
          caution with respect to your personal information.
        </p>
        <h3>Rights to the Deletion of Personal Information</h3>
        <p>
          You can request your account, as well as all your data, to be deleted
          by sending us an email to team@gofindfriends.com.
        </p>
        <h3>Children's Privacy</h3>
        <p>
          Although our website is a general audience site, we restrict the use
          of our service to individuals age 18 and above. We do not knowingly
          collect personal information from children under the age of 13, and we
          will take appropriate measure to stop, delete all information
          regarding anyone under 13 years of age, and may prosecute users who
          use this data.
        </p>
        <h3>Visiting Our Websites from Outside the United States</h3>
        <p>
          If you are visiting our website from outside the United States, please
          be aware that your information may be transferred to, stored, and
          processed in the United States where our servers are located and our
          central database is operated. By using our services, you understand
          that your information may be transferred to our facilities and those
          third parties with whom we share it as described in this privacy
          policy.
        </p>
        <h3>No Rights of Third Parties</h3>
        <p>
          This Privacy Policy does not create rights enforceable by third
          parties or require disclosure of any personal information relating to
          users of the website.
        </p>
        <h3>Changes to this Privacy Policy</h3>
        <p>
          We will occasionally update this Privacy Policy. When we post changes
          to this Privacy Policy, we will revise the "last updated" date at the
          top of this Privacy Policy. We recommend that you check our site from
          time to time to inform yourself of any changes in this Privacy Policy
          or any of our other policies. How to contact us: If you have any
          questions about this Privacy Policy, please contact us by email at
          team@gofindfriends.com.
          <br />
        </p>
        <p>
          <u>Cookies.</u> A cookie is a small text file that is stored on a
          user's device for record keeping purposes. Cookies can be either
          session cookies or persistent cookies. A session cookie expires when
          you close your browser and is used to make it easier for you to
          navigate our site. A persistent cookie remains on your device for an
          extended period of time. Cookies on our site do not link to or store
          your personal information.For example, when you sign in to our
          website, we will record your user or member ID and the name associated
          with your user or member ID in the cookie file on your computer. We
          also may record your password in this cookie file, if you indicated
          that you would like your password saved for automatic sign-in. For
          security purposes, we encrypt account-related data that we store in
          such cookies. In the case of sites and services that do not use a user
          or member ID, the cookie will contain a unique identifier. We may
          allow our service providers to serve cookies from our website or
          application to allow them to assist us in various activities, such as
          doing analysis and research on the effectiveness of our site, content
          and advertising.
        </p>
        <p>
          You may delete or decline cookies by changing your browser settings.
          (Click "Help" in the toolbar of most browsers for instructions.) If
          you do so, some of the features and services of our website may not
          function properly.
        </p>
        <p>
          We may allow third-parties, including advertising companies, analytics
          companies, and ad networks, to display advertisements on our site.
          These companies may use tracking technologies, such as cookies, to
          collect information about users who view or interact with their
          advertisements or our website or mobile applications. We do not
          provide any personal information to these third parties, but they may
          collect information about where you, or others who are using your
          device, saw and/or clicked on the advertisements they deliver (such as
          click stream information, browser type, time and date, subject of
          advertisements clicked or scrolled over, etc.), and possibly associate
          this information with your subsequent visits to the advertised
          websites or other data they have collected. They also may combine this
          information with personal information they collect from you to provide
          advertisements about goods and services likely to be of greater
          interest to you. The collection and use of that information is subject
          to the third-party's privacy policy. Some of these third-party
          advertising companies may be advertising networks that are members of
          the Network Advertising Initiative, which offers a single location to
          opt out of ad targeting from member companies and provides information
          about this behavioral advertising practice
          (www.networkadvertising.org). This policy covers the use of cookies by
          us and does not cover the use of cookies by any advertisers.
        </p>
        <p>
          <u>Opting-Out of Advertising Cookies.</u> You may opt out of our data
          collection for use by third party ad companies to provide targeted
          GoFindFriends advertising to you when you visit other sites. Cookies
          are unique to each device. Therefore, you must opt out separately on
          all devices you use. If you delete all of your cookies (specifically,
          the "Opt-Out" cookie), you will have to go through the opt-out process
          again to reactivate your opted-out status.
        </p>
        <br />
        <Divider hidden />
        <Button
          onClick={() => dispatch(closeModal({ modalType: "PrivacyPolicy" }))}
          fluid
          primary
          size="large"
          content="Close"
        />
      </Segment>
    </>
  );
}
