export function gTARSupplement() {
return [   //2024 Release 2024-04-18 20:17:13
{
   page: 0,
   name: "Seller and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 115.942,
   left: 67.44,
   width: 413.52,
   height: 12.818
}
,
{
   page: 0,
   name: "Relating to the following described real estate located in", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 134.182,
   left: 67.44,
   width: 433.44,
   height: 12.818
}
,
{
   page: 0,
   name: "County Oklahoma at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 150.993,
   left: 299.04,
   width: 144.48,
   height: 14.247
}
,
{
   page: 0,
   name: "Legal Description or Property Address 1", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 12,
   top: 184.56,
   left: 67.44,
   width: 468.48,
   height: 383.293
}
] }
