export function buySellAgreement2019() {
   return [   //2024 Release 2024-01-12 18:52:36
   {
      page: 0,
      name: "Listing Firm", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 42.84,
      left: 29.64,
      width: 233.40,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Selling Firm", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 42.84,
      left: 348.24,
      width: 233.40,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Sellers Designated Agent Name License Number", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 76.971,
      left: 29.64,
      width: 168.60,
      height: 13.909
   }
   ,
   {
      page: 0,
      name: "Listing License Num", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 75.928,
      left: 200.292,
      width: 67.527,
      height: 14.145
   }
   ,
   {
      page: 0,
      name: "Check Box12", 
      isText: false,
      type: "checkbox",
      top: 75.48,
      left: 299.51,
      width: 11.52,
      height: 11.52
   }
   ,
   {
      page: 0,
      name: "Buyers Designated Agent Name License Number", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 77.625,
      left: 348.24,
      width: 168.60,
      height: 13.255
   }
   ,
   {
      page: 0,
      name: "Buyer License Num", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 75.618,
      left: 519.6,
      width: 67.527,
      height: 14.146
   }
   ,
   {
      page: 0,
      name: "Brokerage Name License Number", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 111.84,
      left: 29.64,
      width: 168.60,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Brokerage Seller Lic Num", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 110.295,
      left: 200.946,
      width: 67.527,
      height: 14.145
   }
   ,
   {
      page: 0,
      name: "Brokerage Name License Number_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 110.24,
      left: 348.24,
      width: 168.60,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Brokerage Buyer Lic Num", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 109.964,
      left: 519.6,
      width: 67.527,
      height: 14.146
   }
   ,
   {
      page: 0,
      name: "Phone Number", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 145.625,
      left: 29.64,
      width: 83.378,
      height: 13.255
   }
   ,
   {
      page: 0,
      name: "Office", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 146.28,
      left: 115.0,
      width: 85.2,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Fax", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 146.28,
      left: 201.927,
      width: 61.113,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Phone Number_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 145.625,
      left: 348.24,
      width: 73.56,
      height: 13.255
   }
   ,
   {
      page: 0,
      name: "Office_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 146.28,
      left: 425.6,
      width: 85.2,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Fax_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 146.28,
      left: 517.254,
      width: 64.386,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Email Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 180.84,
      left: 29.64,
      width: 233.40,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Email Address_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 180.84,
      left: 348.24,
      width: 233.52,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Delivered by Designated Agent to", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 215.28,
      left: 29.64,
      width: 316.32,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Day", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 215.28,
      left: 350.76,
      width: 54.60,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 215.28,
      left: 410.16,
      width: 58.08,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Time", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 215.28,
      left: 473.04,
      width: 59.28,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "AMPM", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 215.28,
      left: 537.12,
      width: 43.08,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Comments", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 249.84,
      left: 29.64,
      width: 550.56,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Received by Designated Agent", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 284.28,
      left: 29.64,
      width: 315.72,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Day_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 284.28,
      left: 350.16,
      width: 54.60,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Date_2", 
      fontSize: 9,
      type: "date",
      top: 284.28,
      left: 409.56,
      width: 57.96,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Time_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 284.28,
      left: 472.32,
      width: 59.40,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "AMPM_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 284.28,
      left: 536.52,
      width: 43.68,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Date 3zero", 
      fontSize: 9,
      type: "date",
      top: 344.44,
      left: 80.64,
      width: 183.48,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Property address street city state zip", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 378.186,
      left: 143.534,
      width: 412.680,
      height: 11.705
   }
   ,
   {
      page: 0,
      name: "Legal Description", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 390.48,
      left: 73.92,
      width: 166.80,
      height: 12.48
   }
   ,
   {
      page: 0,
      name: "Parish", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 390.36,
      left: 263.04,
      width: 83.28,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "Louisiana", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 390.36,
      left: 383.04,
      width: 122.40,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "on lands and grounds measuring approximately", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 403.8,
      left: 137.88,
      width: 417.12,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "or as per record title including all buildings structures component parts and all installed builtin permanently", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 467.0,
      left: 54.0,
      width: 189.0,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "undefined", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 466.88,
      left: 456.48,
      width: 99.96,
      height: 12.48
   }
   ,
   {
      page: 0,
      name: "remain with the property but are not to be considered as part of the Sale Price and have no value 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 599.24,
      left: 493.68,
      width: 55.56,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "remain with the property but are not to be considered as part of the Sale Price and have no value 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 612.44,
      left: 54.0,
      width: 500.52,
      height: 12.36
   }
   ,
   {
      page: 0,
      name: "remain with the property but are not to be considered as part of the Sale Price and have no value 3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 625.52,
      left: 54.0,
      width: 500.52,
      height: 12.48
   }
   ,
   {
      page: 0,
      name: "remain with the property but are not to be considered as part of the Sale Price and have no value 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 638.72,
      left: 54.0,
      width: 500.52,
      height: 12.60
   }
   ,
   {
      page: 0,
      name: "referred", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 704.96,
      left: 54.0,
      width: 500.52,
      height: 12.48
   }
   ,
   {
      page: 0,
      name: "undefined_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 718.16,
      left: 54.0,
      width: 500.52,
      height: 12.48
   }
   ,
   {
      page: 0,
      name: "legalDescriptionMulti", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 8,
      top: 416.947,
      left: 51.7093,
      width: 526,
      height: 54
   }
   ,
   {
      page: 1,
      name: "Text13a", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 22.091,
      left: 54.3275,
      width: 403.1995,
      height: 16.036
   }
   ,
   {
      page: 1,
      name: "Date 3a", 
      fontSize: 9,
      type: "date",
      top: 21.791,
      left: 460.045,
      width: 95.236,
      height: 16.691
   }
   ,
   {
      page: 1,
      name: "MINERAL RIGHTS If the SELLER transfers any mineral rights they are to be transferred without warranty", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 72.96,
      left: 54,
      width: 44.4,
      height: 12.00
   }
   ,
   {
      page: 1,
      name: "PRICE The Property will be sold and purchased subject to title and zoning restrictions servitudes of record and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 125.52,
      left: 302.28,
      width: 255.60,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "law or ordinances affecting the Property for the sum of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 138.72,
      left: 54,
      width: 266.76,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "Dollars", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 138.72,
      left: 363.6,
      width: 105.6,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "ACT OF SALE The Act of Sale is to be executed before a settlement agent or Notary Public to be chosen by the", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 178.68,
      left: 108.72,
      width: 138.96,
      height: 12.00
   }
   ,
   {
      page: 1,
      name: "20", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 178.32,
      left: 264.72,
      width: 27.84,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "Check Box14", 
      isText: false,
      type: "checkbox",
      top: 302.263,
      left: 53.4002,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box15", 
      isText: false,
      type: "checkbox",
      top: 334.336,
      left: 53.0548,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box16", 
      isText: false,
      type: "checkbox",
      top: 392.591,
      left: 53.4002,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box17", 
      isText: false,
      type: "checkbox",
      top: 424.7,
      left: 53.4002,
      width: 11.5200,
      height: 11.52
   }
   ,
   {
      page: 1,
      name: "FINANCED SALE This sale is conditioned upon the ability of BUYER to borrow with this Property as", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 450.72,
      left: 170.76,
      width: 277.92,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "of the Sale", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 450.72,
      left: 466.08,
      width: 27.84,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "per annum interest and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 464.04,
      left: 404.88,
      width: 27.72,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "Price by a mortgage loan or loans at an initial interest rate not to exceed", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 477.24,
      left: 281.76,
      width: 27.84,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "Check Box18", 
      isText: false,
      type: "checkbox",
      top: 521.537,
      left: 53.4002,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box19", 
      isText: false,
      type: "checkbox",
      top: 520.882,
      left: 304.747,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box20", 
      isText: false,
      type: "checkbox",
      top: 540.519,
      left: 52.7457,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box21", 
      isText: false,
      type: "checkbox",
      top: 539.864,
      left: 304.092,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box22", 
      isText: false,
      type: "checkbox",
      top: 558.846,
      left: 52.7457,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box23", 
      isText: false,
      type: "checkbox",
      top: 559.501,
      left: 304.747,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box24", 
      isText: false,
      type: "checkbox",
      top: 575.864,
      left: 52.7457,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box25", 
      isText: false,
      type: "checkbox",
      top: 577.828,
      left: 305.056,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "Check Box26", 
      isText: false,
      type: "checkbox",
      top: 596.192,
      left: 53.4002,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 1,
      name: "VA Guaranteed Mortgage", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 594.72,
      left: 96.24,
      width: 205.56,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "of the loan amount", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 622.68,
      left: 306.84,
      width: 27.84,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "The BUYER agrees to pay discount points not to exceed", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 635.88,
      left: 175.2,
      width: 378.12,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "Other financing conditions", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 649.08,
      left: 54,
      width: 500.52,
      height: 12.36
   }
   ,
   {
      page: 2,
      name: "Text13b", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 30.4,
      left: 54.3275,
      width: 403.1995,
      height: 14.727
   }
   ,
   {
      page: 2,
      name: "Date 3b", 
      fontSize: 9,
      type: "date",
      top: 29.373,
      left: 461.354,
      width: 93.927,
      height: 15.873
   }
   ,
   {
      page: 2,
      name: "proceed with the loan approval process within", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 110.52,
      left: 267.36,
      width: 22.20,
      height: 12.36
   }
   ,
   {
      page: 2,
      name: "Check Box27", 
      isText: false,
      type: "checkbox",
      top: 350.082,
      left: 117.51,
      width: 11.52,
      height: 11.520
   }
   ,
   {
      page: 2,
      name: "Check Box28", 
      isText: false,
      type: "checkbox",
      top: 350.391,
      left: 329.001,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 2,
      name: "provide the SELLER with a copy of the appraisal within", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 403.32,
      left: 312,
      width: 55.56,
      height: 12.36
   }
   ,
   {
      page: 2,
      name: "undefined_7", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 403.32,
      left: 375,
      width: 22.2,
      height: 12.36
   }
   ,
   {
      page: 2,
      name: "along with the BUYERS written request for the SELLER to reduce the Sale Price Within", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 416.52,
      left: 469.2,
      width: 55.56,
      height: 12.36
   }
   ,
   {
      page: 2,
      name: "undefined_8", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 416.52,
      left: 532.44,
      width: 22.08,
      height: 12.36
   }
   ,
   {
      page: 2,
      name: "undefined_9", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 518.76,
      left: 59.52,
      width: 150.12,
      height: 10.68
   }
   ,
   {
      page: 2,
      name: "or", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 518.76,
      left: 224.16,
      width: 27.72,
      height: 10.68
   }
   ,
   {
      page: 2,
      name: "Cash", 
      isText: false,
      type: "checkbox",
      top: 532.68,
      left: 54,
      width: 8.52,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 532.32,
      left: 102.36,
      width: 144.72,
      height: 12.36
   }
   ,
   {
      page: 2,
      name: "Certified Funds", 
      isText: false,
      type: "checkbox",
      top: 532.68,
      left: 272.76,
      width: 8.52,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "of the Sale Price to be paid in the form of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 532.32,
      left: 362.88,
      width: 111.12,
      height: 12.36
   }
   ,
   {
      page: 2,
      name: "Check", 
      isText: false,
      type: "checkbox",
      top: 548.76,
      left: 54,
      width: 8.52,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 548.4,
      left: 103.08,
      width: 144.48,
      height: 12.36
   }
   ,
   {
      page: 2,
      name: "Electronic Transfer EFT", 
      isText: false,
      type: "checkbox",
      top: 548.76,
      left: 272.76,
      width: 8.52,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "undefined_10", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 548.4,
      left: 405.84,
      width: 49.80,
      height: 12.36
   }
   ,
   {
      page: 2,
      name: "Listing Broker", 
      isText: false,
      type: "checkbox",
      top: 564.96,
      left: 185.64,
      width: 8.52,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "Selling Broker", 
      isText: false,
      type: "checkbox",
      top: 564.96,
      left: 263.64,
      width: 8.52,
      height: 10.92
   }
   ,
   {
      page: 2,
      name: "Third Party", 
      isText: false,
      type: "checkbox",
      top: 564.96,
      left: 342.84,
      width: 8.52,
      height: 10.92
   }
   ,
   {
      page: 3,
      name: "Text13c", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 22.4,
      left: 54.3275,
      width: 403.1995,
      height: 14.727
   }
   ,
   {
      page: 3,
      name: "Date 3c", 
      fontSize: 9,
      type: "date",
      top: 21.082,
      left: 459.989,
      width: 94.582,
      height: 16.036
   }
   ,
   {
      page: 3,
      name: "A new home construction addendum with additional terms and conditions is attached", 
      isText: false,
      type: "checkbox",
      top: 605.52,
      left: 54,
      width: 8.52,
      height: 11.04
   }
   ,
   {
      page: 3,
      name: "There is no new home construction addendum", 
      isText: false,
      type: "checkbox",
      top: 624,
      left: 54,
      width: 8.52,
      height: 11.04
   }
   ,
   {
      page: 4,
      name: "Text13d", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 16.091,
      left: 54.3275,
      width: 403.1995,
      height: 16.036
   }
   ,
   {
      page: 4,
      name: "Date 3d", 
      fontSize: 9,
      type: "date",
      top: 15.736,
      left: 459.972,
      width: 95.236,
      height: 17.346
   }
   ,
   {
      page: 4,
      name: "The BUYER shall have an inspection and due diligence period of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 56.04,
      left: 358.56,
      width: 27.72,
      height: 15.72
   }
   ,
   {
      page: 4,
      name: "There isare", 
      isText: false,
      type: "checkbox",
      top: 537.6,
      left: 54,
      width: 8.52,
      height: 11.04
   }
   ,
   {
      page: 4,
      name: "private SepticWater Addendum inspections shall include only the systems supplying service to the primary", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 535.309,
      left: 121.44,
      width: 55.68,
      height: 14.411
   }
   ,
   {
      page: 4,
      name: "undefined_15", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 534.655,
      left: 183.24,
      width: 22.20,
      height: 15.065
   }
   ,
   {
      page: 4,
      name: "There isare_2", 
      isText: false,
      type: "checkbox",
      top: 595.8,
      left: 54,
      width: 8.52,
      height: 11.04
   }
   ,
   {
      page: 4,
      name: "the attached private SepticWater Addendum inspections shall include only those systems supplying service to", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 593.509,
      left: 125.64,
      width: 55.56,
      height: 14.411
   }
   ,
   {
      page: 4,
      name: "undefined_16", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 592.855,
      left: 188.4,
      width: 22.08,
      height: 15.065
   }
   ,
   {
      page: 4,
      name: "There is NO private septictreatment systems servicing only the primary residence", 
      isText: false,
      type: "checkbox",
      top: 646.44,
      left: 54,
      width: 8.52,
      height: 11.04
   }
   ,
   {
      page: 4,
      name: "Check Box29", 
      isText: false,
      type: "checkbox",
      top: 675.701,
      left: 335.547,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 4,
      name: "Check Box30", 
      isText: false,
      type: "checkbox",
      top: 677.01,
      left: 373.129,
      width: 11.520,
      height: 11.52
   }
   ,
   {
      page: 4,
      name: "sale at a cost not to exceed", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 691.375,
      left: 187.32,
      width: 88.92,
      height: 15.065
   }
   ,
   {
      page: 4,
      name: "Check Box31", 
      isText: false,
      type: "checkbox",
      top: 695.0285,
      left: 341.401,
      width: 11.520,
      height: 11.5200
   }
   ,
   {
      page: 4,
      name: "Check Box32", 
      isText: false,
      type: "checkbox",
      top: 695.6831,
      left: 417.984,
      width: 11.520,
      height: 11.5200
   }
   ,
   {
      page: 4,
      name: "Warranty will be ordered by", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 707.9345,
      left: 178.44,
      width: 372.48,
      height: 13.2655
   }
   ,
   {
      page: 5,
      name: "Text13e", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 29.4,
      left: 54.3275,
      width: 403.1995,
      height: 14.727
   }
   ,
   {
      page: 5,
      name: "Date 3etssdfasd", 
      fontSize: 9,
      type: "date",
      top: 28.445,
      left: 458.953,
      width: 95.891,
      height: 15.382
   }
   ,
   {
      page: 5,
      name: "Check Box33", 
      isText: false,
      type: "checkbox",
      top: 181.863,
      left: 53.7093,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 5,
      name: "Check Box34", 
      isText: false,
      type: "checkbox",
      top: 227.336,
      left: 53.7457,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 5,
      name: "Check Box35", 
      isText: false,
      type: "checkbox",
      top: 325.209,
      left: 53.4002,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 5,
      name: "partiesw", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 456.96,
      left: 54,
      width: 161.16,
      height: 12.36
   }
   ,
   {
      page: 5,
      name: "thex", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 456.96,
      left: 222.48,
      width: 27.84,
      height: 12.36
   }
   
   ,
   {
      page: 6,
      name: "Text13ebxtw", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 27.054,
      left: 54.3275,
      width: 403.1995,
      height: 14.073
   }
   ,
   {
      page: 6,
      name: "Date 3ebxt", 
      fontSize: 9,
      type: "date",
      top: 27.591,
      left: 458.28,
      width: 97.20,
      height: 13.418
   }
   ,
   {
      page: 6,
      name: "ADDITIONAL TERMS AND CONDITIONS 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 562.2,
      left: 54,
      width: 500.52,
      height: 12.24
   }
   ,
   {
      page: 6,
      name: "ADDITIONAL TERMS AND CONDITIONS 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 575.28,
      left: 54,
      width: 500.52,
      height: 12.36
   }
   ,
   {
      page: 6,
      name: "ADDITIONAL TERMS AND CONDITIONS 3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 588.6,
      left: 54,
      width: 500.52,
      height: 12.36
   }
   ,
   {
      page: 6,
      name: "ADDITIONAL TERMS AND CONDITIONS 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 601.8,
      left: 54,
      width: 500.52,
      height: 12.36
   }
   ,
   {
      page: 6,
      name: "ADDITIONAL TERMS AND CONDITIONS 5", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 615,
      left: 54,
      width: 500.52,
      height: 12.36
   }
   ,
   {
      page: 6,
      name: "ADDITIONAL TERMS AND CONDITIONS 6", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 628.2,
      left: 54,
      width: 500.52,
      height: 12.36
   }
   ,
   {
      page: 7,
      name: "Text13f", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 23.745,
      left: 54.3275,
      width: 403.1995,
      height: 15.382
   }
   ,
   {
      page: 7,
      name: "Date 3f", 
      fontSize: 9,
      type: "date",
      top: 22.936,
      left: 457.571,
      width: 97.200,
      height: 16.036
   }
   ,
   {
      page: 7,
      name: "Contingency for Sale of the BUYERS Other Property Addendum", 
      isText: false,
      type: "checkbox",
      top: 287.64,
      left: 54.0,
      width: 11.52,
      height: 11.52
   }
   ,
   {
      page: 7,
      name: "Condominium Addendum", 
      isText: false,
      type: "checkbox",
      top: 306.12,
      left: 54.0,
      width: 11.52,
      height: 11.52
   }
   ,
   {
      page: 7,
      name: "FHA Amendatory Clause", 
      isText: false,
      type: "checkbox",
      top: 324.72,
      left: 54.0,
      width: 11.52,
      height: 11.52
   }
   ,
   {
      page: 7,
      name: "1_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 322.833,
      left: 398.52,
      width: 148.92,
      height: 14.487
   }
   ,
   {
      page: 7,
      name: "New Construction Addendum", 
      isText: false,
      type: "checkbox",
      top: 343.2,
      left: 54.0,
      width: 11.52,
      height: 11.52
   }
   ,
   {
      page: 7,
      name: "Private WaterSewerage Addendum", 
      isText: false,
      type: "checkbox",
      top: 287.64,
      left: 376.0,
      width: 11.52,
      height: 11.52
   }
   ,
   {
      page: 7,
      name: "Deposit Addendum", 
      isText: false,
      type: "checkbox",
      top: 309.12,
      left: 376.0,
      width: 11.52,
      height: 11.52
   }
   ,
   {
      page: 7,
      name: "Check Box36", 
      isText: false,
      type: "checkbox",
      top: 327.827,
      left: 375.747,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 7,
      name: "Check Box37", 
      isText: false,
      type: "checkbox",
      top: 345.809,
      left: 375.783,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 7,
      name: "2_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 340.658,
      left: 398.52,
      width: 148.92,
      height: 15.142
   }
   ,
   {
      page: 8,
      name: "Text13g", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 21.127,
      left: 54.3275,
      width: 403.1995,
      height: 18.000
   }
   ,
   {
      page: 8,
      name: "Date 3g", 
      fontSize: 9,
      type: "date",
      top: 20.936,
      left: 457.862,
      width: 97.200,
      height: 18.000
   }
   ,
   {
      page: 8,
      name: "Check Box38", 
      isText: false,
      type: "checkbox",
      top: 64.353,
      left: 53.0911,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 8,
      name: "The SELLER further authorizes his or her agent to electronically deliver notices and other communications to", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 111.24,
      left: 248.52,
      width: 300.12,
      height: 18.60
   }
   ,
   {
      page: 8,
      name: "Check Box39", 
      isText: false,
      type: "checkbox",
      top: 135.044,
      left: 53.4002,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 8,
      name: "The authorization contained in this Section is not an authorization for the Buyers agent to communicate directly", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 182.76,
      left: 252.36,
      width: 294.48,
      height: 18.60
   }
   ,
   {
      page: 8,
      name: "Acceptance of this offer must be communicated to the offering party by the deadline stated on line 374 to be", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 348.098,
      left: 257.76,
      width: 77.88,
      height: 15.982
   }
   ,
   {
      page: 8,
      name: "20_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 347.444,
      left: 355.44,
      width: 27.72,
      height: 16.636
   }
   ,
   {
      page: 8,
      name: "at", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 348.098,
      left: 403.32,
      width: 27.72,
      height: 15.982
   }
   ,
   {
      page: 8,
      name: "AM", 
      isText: false,
      type: "checkbox",
      top: 354.64,
      left: 435.16,
      width: 9.36,
      height: 9.36
   }
   ,
   {
      page: 8,
      name: "PM", 
      isText: false,
      type: "checkbox",
      top: 354.64,
      left: 462.72,
      width: 9.36,
      height: 9.36
   }
   ,
   {
      page: 8,
      name: "NOON The", 
      isText: false,
      type: "checkbox",
      top: 354.64,
      left: 491.4,
      width: 9.36,
      height: 9.36
   }
   ,
   {
      page: 8,
      name: "Check Box40", 
      isText: false,
      type: "checkbox",
      top: 424.551,
      left: 54.0911,
      width: 9.3600,
      height: 9.360
   }
   ,
   {
      page: 8,
      name: "Check Box41", 
      isText: false,
      type: "checkbox",
      top: 425.206,
      left: 108.037,
      width: 9.360,
      height: 9.359
   }
   ,
   {
      page: 8,
      name: "AM_2", 
      isText: false,
      type: "checkbox",
      top: 425.52,
      left: 255.8,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "PM_2", 
      isText: false,
      type: "checkbox",
      top: 425.52,
      left: 277.88,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "Check Box42", 
      isText: false,
      type: "checkbox",
      top: 424.278,
      left: 316.22,
      width: 9.36,
      height: 9.360
   }
   ,
   {
      page: 8,
      name: "Check Box43", 
      isText: false,
      type: "checkbox",
      top: 424.587,
      left: 371.893,
      width: 9.360,
      height: 9.360
   }
   ,
   {
      page: 8,
      name: "AM_3", 
      isText: false,
      type: "checkbox",
      top: 425.52,
      left: 510.68,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "PM_3", 
      isText: false,
      type: "checkbox",
      top: 425.52,
      left: 533.64,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "Print BuyersSellers Full Name First Middle Last", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 438.273,
      left: 54.0,
      width: 238.92,
      height: 15.327
   }
   ,
   {
      page: 8,
      name: "Print BuyersSellers Full Name First Middle Last_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 437.618,
      left: 319.8,
      width: 233.52,
      height: 15.982
   }
   ,
   {
      page: 8,
      name: "toggle_8", 
      isText: false,
      type: "checkbox",
      top: 491.64,
      left: 180.12,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "Check Box44", 
      isText: false,
      type: "checkbox",
      top: 491.719,
      left: 214.692,
      width: 7.920,
      height: 7.920
   }
   ,
   {
      page: 8,
      name: "Check Box45", 
      isText: false,
      type: "checkbox",
      top: 492.028,
      left: 428.42,
      width: 7.92,
      height: 7.920
   }
   ,
   {
      page: 8,
      name: "toggle_9", 
      isText: false,
      type: "checkbox",
      top: 492.64,
      left: 460.88,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "NOON", 
      isText: false,
      type: "checkbox",
      top: 492.64,
      left: 487.64,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "Check Box48", 
      isText: false,
      type: "checkbox",
      top: 527.464,
      left: 123.473,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 8,
      name: "Check Box49", 
      isText: false,
      type: "checkbox",
      top: 526.773,
      left: 207.837,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 8,
      name: "Check Box50", 
      isText: false,
      type: "checkbox",
      top: 527.119,
      left: 352.492,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 8,
      name: "Check Box46", 
      isText: false,
      type: "checkbox",
      top: 584.755,
      left: 52.0911,
      width: 11.5200,
      height: 11.520
   }
   ,
   {
      page: 8,
      name: "Check Box47", 
      isText: false,
      type: "checkbox",
      top: 584.719,
      left: 106.691,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 8,
      name: "AM_4", 
      isText: false,
      type: "checkbox",
      top: 585.96,
      left: 255.8,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "PM_4", 
      isText: false,
      type: "checkbox",
      top: 585.96,
      left: 277.88,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "Check Box51", 
      isText: false,
      type: "checkbox",
      top: 584.101,
      left: 316.874,
      width: 11.520,
      height: 11.520
   }
   ,
   {
      page: 8,
      name: "Check Box52", 
      isText: false,
      type: "checkbox",
      top: 584.41,
      left: 371.129,
      width: 11.520,
      height: 11.52
   }
   ,
   {
      page: 8,
      name: "AM_5", 
      isText: false,
      type: "checkbox",
      top: 585.96,
      left: 513.44,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "PM_5", 
      isText: false,
      type: "checkbox",
      top: 585.96,
      left: 535.4,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "Print BuyersSellers Full Name First Middle Last_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 598.058,
      left: 54.0,
      width: 238.92,
      height: 15.982
   }
   ,
   {
      page: 8,
      name: "Print BuyersSellers Full Name First Middle Last_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 598.058,
      left: 319.8,
      width: 233.52,
      height: 15.982
   }
   ,
   {
      page: 8,
      name: "toggle_15", 
      isText: false,
      type: "checkbox",
      top: 653.08,
      left: 180.12,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "Check Box53", 
      isText: false,
      type: "checkbox",
      top: 653.356,
      left: 216.819,
      width: 7.920,
      height: 7.920
   }
   ,
   {
      page: 8,
      name: "Check Box54", 
      isText: false,
      type: "checkbox",
      top: 652.083,
      left: 401.929,
      width: 7.920,
      height: 7.920
   }
   ,
   {
      page: 8,
      name: "Check Box55", 
      isText: false,
      type: "checkbox",
      top: 652.774,
      left: 429.766,
      width: 7.920,
      height: 7.920
   }
   ,
   {
      page: 8,
      name: "NOON_2", 
      isText: false,
      type: "checkbox",
      top: 653.08,
      left: 455.92,
      width: 7.92,
      height: 7.92
   }
   ,
   {
      page: 8,
      name: "Presented By 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 469.966,
      left: 55.6366,
      width: 235.7454,
      height: 16.109
   }
   ,
   {
      page: 8,
      name: "Date Presented1", 
      fontSize: 9,
      type: "date",
      top: 470.62,
      left: 319.42,
      width: 128.399,
      height: 15.455
   }
   ,
   {
      page: 8,
      name: "Text79", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 469.966,
      left: 462.0,
      width: 92.4,
      height: 15.454
   }
   ,
   {
      page: 8,
      name: "Prseented by 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 630.33,
      left: 53.0184,
      width: 239.6736,
      height: 15.455
   }
   ,
   {
      page: 8,
      name: "Date Presented2", 
      fontSize: 9,
      type: "date",
      top: 630.33,
      left: 320.074,
      width: 133.636,
      height: 14.80
   }
   ,
   {
      page: 8,
      name: "Text82", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 629.021,
      left: 462.0,
      width: 91.745,
      height: 16.764
   }
   ] }
   