export function vacantLotorTract() {
return [   //2024 Release 2024-02-27 13:28:02
{
   page: 0,
   name: "Disclosure Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 12.96,
   left: 127.44,
   width: 466.56,
   height: 12.60
}
,
{
   page: 0,
   name: "Disclosure Box 1", 
   isText: false,
   type: "checkbox",
   top: 143.104,
   left: 49.9725,
   width: 6.8400,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 2", 
   isText: false,
   type: "checkbox",
   top: 197.248,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 3", 
   isText: false,
   type: "checkbox",
   top: 223.384,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 4", 
   isText: false,
   type: "checkbox",
   top: 249.52,
   left: 49.968,
   width: 6.840,
   height: 6.84
}
,
{
   page: 0,
   name: "Disclosure Box 5", 
   isText: false,
   type: "checkbox",
   top: 264.712,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 6", 
   isText: false,
   type: "checkbox",
   top: 297.472,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 7", 
   isText: false,
   type: "checkbox",
   top: 334.336,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 8", 
   isText: false,
   type: "checkbox",
   top: 349.695,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Text Field 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 401.472,
   left: 152.64,
   width: 163.505,
   height: 13.500
}
,
{
   page: 0,
   name: "Disclosure Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 401.472,
   left: 438.75,
   width: 144.221,
   height: 13.500
}
,
{
   page: 0,
   name: "Disclosure Box 9", 
   isText: false,
   type: "checkbox",
   top: 500.944,
   left: 68.04,
   width: 6.84,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 561.352,
   left: 157.68,
   width: 155.88,
   height: 13.500
}
,
{
   page: 0,
   name: "Disclosure Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 561.352,
   left: 442.842,
   width: 137.273,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 174", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 15.552,
   left: 111.888,
   width: 466.992,
   height: 13.500
}
,
{
   page: 1,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 132.936,
   left: 107.496,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 7", 
   isText: false,
   type: "checkbox",
   top: 132.936,
   left: 271.512,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 1", 
   isText: false,
   type: "checkbox",
   top: 144.888,
   left: 107.496,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 8", 
   isText: false,
   type: "checkbox",
   top: 144.96,
   left: 271.512,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 156.84,
   left: 107.496,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 9", 
   isText: false,
   type: "checkbox",
   top: 156.984,
   left: 271.512,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 5", 
   isText: false,
   type: "checkbox",
   top: 168.792,
   left: 107.496,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Text Field 18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 168.067,
   left: 127.08,
   width: 134.771,
   height: 10.921
}
,
{
   page: 1,
   name: "Check Box 10", 
   isText: false,
   type: "checkbox",
   top: 169.008,
   left: 271.512,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 6", 
   isText: false,
   type: "checkbox",
   top: 180.852,
   left: 107.496,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Text Field 19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 180.436,
   left: 127.08,
   width: 134.771,
   height: 10.921
}
,
{
   page: 1,
   name: "Check Box 11", 
   isText: false,
   type: "checkbox",
   top: 181.032,
   left: 271.512,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 4", 
   isText: false,
   type: "checkbox",
   top: 192.912,
   left: 107.496,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Text Field 17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 192.496,
   left: 127.08,
   width: 134.771,
   height: 10.921
}
,
{
   page: 1,
   name: "Check Box 12", 
   isText: false,
   type: "checkbox",
   top: 192.858,
   left: 271.512,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 230.072,
   left: 31.5,
   width: 520.308,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 251.744,
   left: 49.104,
   width: 500.544,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 395.6,
   left: 327.067,
   width: 175.133,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 415.04,
   left: 151.56,
   width: 428.94,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 5", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 431.011,
   left: 50.515,
   width: 529.985,
   height: 55.391
}
,
{
   page: 1,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 487.184,
   left: 50.4,
   width: 304.331,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 487.184,
   left: 356.04,
   width: 142.92,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 487.184,
   left: 500.924,
   width: 79.576,
   height: 13.500
}
,
{
   page: 1,
   name: "Check Box 35", 
   isText: false,
   type: "checkbox",
   top: 549.888,
   left: 49.104,
   width: 8.2656,
   height: 6.765
}
,
{
   page: 1,
   name: "Check Box 36", 
   isText: false,
   type: "checkbox",
   top: 613.5,
   left: 49.104,
   width: 8.2656,
   height: 6.765
}
,
{
   page: 1,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 621.883,
   left: 49.536,
   width: 358.704,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 151", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 658.695,
   left: 204.22,
   width: 132.30,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 153", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 666.86,
   left: 238.712,
   width: 70.258,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 152", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 679.978,
   left: 49.536,
   width: 156.384,
   height: 10.882
}
,
{
   page: 2,
   name: "Text Field 173", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.552,
   left: 111.888,
   width: 466.992,
   height: 13.500
}
,
{
   page: 2,
   name: "Text Field 154", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 66.244,
   left: 256.32,
   width: 154.80,
   height: 11.536
}
,
{
   page: 2,
   name: "Text Field 155", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 101.76,
   left: 49.68,
   width: 525.466,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 278.736,
   left: 67.536,
   width: 512.964,
   height: 12.191
}
,
{
   page: 2,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 293.547,
   left: 68.073,
   width: 511.311,
   height: 14.145
}
,
{
   page: 2,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 317.216,
   left: 340.312,
   width: 240.188,
   height: 13.500
}
,
{
   page: 2,
   name: "Text Field 22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 333.568,
   left: 67.5,
   width: 513.0,
   height: 14.809
}
,
{
   page: 2,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 368.349,
   left: 134.28,
   width: 185.40,
   height: 12.191
}
,
{
   page: 2,
   name: "Text Field 26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 432.2,
   left: 139.896,
   width: 70.128,
   height: 13.5
}
,
{
   page: 2,
   name: "Text Field 27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 671.764,
   left: 84.24,
   width: 480.24,
   height: 11.536
}
,
{
   page: 3,
   name: "Text Field 28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 15.552,
   left: 111.888,
   width: 466.992,
   height: 13.500
}
,
{
   page: 3,
   name: "Check Box 13", 
   isText: false,
   type: "checkbox",
   top: 336.32,
   left: 89.784,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 14", 
   isText: false,
   type: "checkbox",
   top: 381.24,
   left: 89.784,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 17", 
   isText: false,
   type: "checkbox",
   top: 553.56,
   left: 89.9048,
   width: 9.2160,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 18", 
   isText: false,
   type: "checkbox",
   top: 567.4,
   left: 89.9048,
   width: 9.2160,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 27", 
   isText: false,
   type: "checkbox",
   top: 582.24,
   left: 89.9048,
   width: 9.2160,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 29", 
   isText: false,
   type: "checkbox",
   top: 593.636,
   left: 464.801,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 30", 
   isText: false,
   type: "checkbox",
   top: 593.636,
   left: 490.161,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 31", 
   isText: false,
   type: "checkbox",
   top: 605.032,
   left: 496.377,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 32", 
   isText: false,
   type: "checkbox",
   top: 605.032,
   left: 534.638,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 33", 
   isText: false,
   type: "checkbox",
   top: 628.48,
   left: 182.281,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 34", 
   isText: false,
   type: "checkbox",
   top: 628.48,
   left: 220.441,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Text Field 97", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 627.428,
   left: 306.121,
   width: 38.384,
   height: 13.500
}
,
{
   page: 3,
   name: "Check Box 28", 
   isText: false,
   type: "checkbox",
   top: 642.62,
   left: 89.9048,
   width: 9.2160,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 39", 
   isText: false,
   type: "checkbox",
   top: 657.7,
   left: 89.9048,
   width: 9.2160,
   height: 9.396
}
,
{
   page: 3,
   name: "Text Field 168", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 655.648,
   left: 145.44,
   width: 433.114,
   height: 14.155
}
,
{
   page: 4,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 15.192,
   left: 112.032,
   width: 466.992,
   height: 13.500
}
,
{
   page: 4,
   name: "Text Field 135", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 132.21,
   left: 171.92,
   width: 69.84,
   height: 13.50
}
,
{
   page: 4,
   name: "Text Field 32", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.665,
   left: 55.44,
   width: 525.06,
   height: 66.519
}
,
{
   page: 5,
   name: "Text Field 39", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 15.192,
   left: 111.938,
   width: 466.992,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 67", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 393.04,
   left: 434.16,
   width: 131.04,
   height: 13.50
}
,
{
   page: 5,
   name: "Text Field 114", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 403.54,
   left: 46.4175,
   width: 131.0405,
   height: 13.50
}
,
{
   page: 5,
   name: "Check Box 37", 
   isText: false,
   type: "checkbox",
   top: 407.54,
   left: 181.44,
   width: 9.216,
   height: 9.396
}
,
{
   page: 5,
   name: "Check Box 38", 
   isText: false,
   type: "checkbox",
   top: 407.54,
   left: 219.904,
   width: 9.216,
   height: 9.396
}
,
{
   page: 5,
   name: "Text Field 41", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 454.95,
   left: 31.79,
   width: 199.33,
   height: 13.50
}
,
{
   page: 5,
   name: "Text Field 100", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 454.95,
   left: 319.015,
   width: 199.330,
   height: 13.50
}
,
{
   page: 5,
   name: "Text Field 65", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 517.637,
   left: 31.79,
   width: 199.33,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 102", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 517.637,
   left: 319.015,
   width: 199.330,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 110", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 578.743,
   left: 31.79,
   width: 199.33,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 1018", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 578.743,
   left: 319.015,
   width: 199.330,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 112", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 641.518,
   left: 31.79,
   width: 199.33,
   height: 13.500
}
,
{
   page: 5,
   name: "Text Field 1020", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 641.518,
   left: 319.015,
   width: 199.330,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 136", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 15.192,
   left: 111.938,
   width: 466.992,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 164", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 61.643,
   left: 331.794,
   width: 124.686,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 165", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 61.643,
   left: 472.32,
   width: 103.69,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 105", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 174.596,
   left: 31.79,
   width: 261.86,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 1012", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 174.596,
   left: 320.538,
   width: 261.860,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 106", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 205.396,
   left: 31.79,
   width: 261.86,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 1023", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 205.396,
   left: 320.538,
   width: 261.860,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 107", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 236.8,
   left: 31.79,
   width: 261.86,
   height: 13.5
}
,
{
   page: 6,
   name: "Text Field 1014", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 236.8,
   left: 320.538,
   width: 261.860,
   height: 13.5
}
,
{
   page: 6,
   name: "Text Field 108", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 268.601,
   left: 31.79,
   width: 261.86,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 1015", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 268.601,
   left: 320.538,
   width: 261.860,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 109", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 299.402,
   left: 31.79,
   width: 261.86,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 1016", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 299.402,
   left: 320.538,
   width: 261.860,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 1010", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 330.202,
   left: 31.79,
   width: 179.17,
   height: 13.500
}
,
{
   page: 6,
   name: "Date Text Field 1013", 
   fontSize: 9,
   type: "date",
   top: 330.202,
   left: 214.233,
   width: 79.417,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 1017", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 330.202,
   left: 320.538,
   width: 179.170,
   height: 13.500
}
,
{
   page: 6,
   name: "Date Text Field 1022", 
   fontSize: 9,
   type: "date",
   top: 330.202,
   left: 502.981,
   width: 79.417,
   height: 13.500
}
, 
{
   page: 6,
   name: "Logo",
   isText: false,
   fontSize: 10,
   type: "logo",
   top: 350,
   left: 32,
   width: 200,
   height: 30,
}
] }
