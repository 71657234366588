import { onSnapshot } from "firebase/firestore";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchDocsForParty,
  fetchParty,
  fetchTasksForParty,
} from "../../features/parties/partySlice";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncSlice";
import {
  dataFromSnapshot,
  fetchDocsForPartyFromDb,
  fetchPartiesForPartyFromDb,
  fetchPartyFromDb,
  fetchTasksForPartyFromDb,
  fetchTransactionFromDb,
} from "./firestoreService";
import {
  fetchTransaction,
  fetchTransactionParties,
} from "../../features/transactions/transactionSlice";

export default function PartyData() {
  const dispatch = useDispatch();
  const { partyId } = useParams();

  useEffect(() => {
    dispatch(asyncActionStart());
    const unsubscribe = onSnapshot(
      fetchPartyFromDb(partyId),
      (snapshot) => {
        if (!snapshot.exists) {
          dispatch(
            asyncActionError({
              code: "not-found",
              message: "Could not find document",
            })
          );
          return;
        }
        const partyData = snapshot.data();
        dispatch(fetchParty(partyData));
        onSnapshot(
          fetchDocsForPartyFromDb(partyData),
          (snapshot) => {
            const docs = snapshot.docs.map((doc) => dataFromSnapshot(doc));
            dispatch(fetchDocsForParty({ docs: docs, email: partyData.email }));
            dispatch(asyncActionFinish());
          },
          (error) => dispatch(asyncActionError(error))
        );
        onSnapshot(
          fetchTasksForPartyFromDb(partyData),
          (snapshot) => {
            const docs = snapshot.docs.map((doc) => dataFromSnapshot(doc));
            dispatch(fetchTasksForParty(docs));
            dispatch(asyncActionFinish());
          },
          (error) => dispatch(asyncActionError(error))
        );
        onSnapshot(
          fetchTransactionFromDb(partyData.transactionId),
          (snapshot) => {
            const transaction = snapshot.data();
            dispatch(fetchTransaction(transaction));
            dispatch(asyncActionFinish());
          },
          (error) => dispatch(asyncActionError(error))
        );
        onSnapshot(
          fetchPartiesForPartyFromDb(partyData.transactionId),
          (snapshot) => {
            const docs = snapshot.docs.map((doc) => dataFromSnapshot(doc));
            dispatch(fetchTransactionParties(docs));
            dispatch(asyncActionFinish());
          },
          (error) => dispatch(asyncActionError(error))
        );
      },
      (error) => dispatch(asyncActionError())
    );
    return () => {
      unsubscribe();
    };
  }, [dispatch, partyId]);

  return <></>;
}
