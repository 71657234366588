import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Table } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { addHistoryToDb } from "../../../app/firestore/firestoreService";

export default function PartyDocSignListItem({ doc }) {
  const navigate = useNavigate();
  const { party } = useSelector((state) => state.party);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleSignClick() {
    addHistoryToDb(doc.transactionId, party, "opened for signing", doc.name);
    navigate(`/party/${party.id}/documents/${doc.id}/sign`);
  }

  return (
    <Table.Row
      key={doc.id}
      onClick={() => handleSignClick()}
      className="cursor-pointer"
    >
      <Table.Cell style={{ minWidth: "300px" }}>
        <Icon name="file pdf outline" size="large" />
        &nbsp;
        {doc.name}
      </Table.Cell>
      <Table.Cell>
        {doc.note ? doc.note : "Please sign this document."}
      </Table.Cell>
      <Table.Cell>
        <Button
          inverted
          floated={isMobile ? null : "right"}
          className={isMobile ? "fluid medium bottom margin" : null}
        >
          Sign
        </Button>
      </Table.Cell>
    </Table.Row>
  );
}
