import React from "react";
import { Table } from "semantic-ui-react";
import DocAllAwaitingSignatureListItem from "./DocAllAwaitingSignatureListItem";

export default function DocAllAwaitingSignatureList({ docs }) {
  return (
    <>
      <Table compact>
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Transaction</Table.HeaderCell>
            <Table.HeaderCell>Sent Out</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {docs?.length !== 0 &&
            docs.map((doc) => (
              <DocAllAwaitingSignatureListItem doc={doc} key={doc.id} />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
