import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Header, Segment } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import DeadlineTemplatesList from "./DeadlineTemplatesList";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";

export default function DeadlineTemplatesSelect() {
  const dispatch = useDispatch();
  const { deadlineTemplates } = useSelector((state) => state.deadlineTemplates);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleClose() {
    dispatch(
      closeModal({
        modalType: "DeadlineTemplatesSelect",
      })
    );
  }

  return (
    <>
      <ModalWrapper style={{ backgroundColor: "#f9fafb" }}>
        <Segment clearing style={{ backgroundColor: "#f9fafb" }}>
          <Header color="blue" size="large">
            Select Deadline Template to Apply
          </Header>
          <Divider />{" "}
          {deadlineTemplates?.length > 0 ? (
            <DeadlineTemplatesList
              deadlineTemplates={deadlineTemplates}
              column={deadlineTemplates.column}
              direction={deadlineTemplates.direction}
              actionButtonMode="apply"
            />
          ) : (
            <p>There are no deadline templates.</p>
          )}
          <Button
            floated={isMobile ? null : "right"}
            content="Close"
            onClick={() => handleClose()}
            className={isMobile ? "fluid" : null}
          ></Button>
        </Segment>
      </ModalWrapper>
    </>
  );
}
