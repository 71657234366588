import React from "react";
import { useDispatch } from "react-redux";
import useFirestoreCollection from "../hooks/useFirestoreCollection";
import { fetchTransactionsForUserFromDb, fetchPartiesForUserFromDb } from "./firestoreService";
import { fetchPartiesForUser, fetchTransactionsForUser } from "../../features/parties/partySlice";

export default function PartyAllData() {
  const dispatch = useDispatch();

  useFirestoreCollection({
    query: () => fetchPartiesForUserFromDb(),
    data: (parties) => dispatch(fetchPartiesForUser({parties: parties})),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchTransactionsForUserFromDb(),
    data: (transactions) => dispatch(fetchTransactionsForUser(transactions)),
    deps: [dispatch],
  });

  return <></>;
}