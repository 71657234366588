export function cBExclusiveRighttoSell2023() {
    return [   //2023
    {
      page: 0,
      name: "logo",
      isText: false,
      type: "logo",
      top: 28.673,
      left: 53,
      width: 253,
      height: 43.2
   }
   ,
   {
       page: 0,
       name: "SELLER AGENCY", 
       isText: false,
       type: "checkbox",
       top: 216.6,
       left: 173.88,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "TRANSACTIONBROKERAGE", 
       isText: false,
       type: "checkbox",
       top: 216.6,
       left: 286.44,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "date_contractDate", 
       fontSize: 9,
       type: "date",
       top: 241.44,
       left: 441.36,
       width: 120.00,
       height: 14.64
    }
    ,
    {
       page: 0,
       name: "MultiplePerson Firm If this box is checked Broker as defined below is the individual designated by Brokerage Firm", 
       isText: false,
       type: "checkbox",
       top: 332.52,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "OnePerson Firm If this box is checked Broker as defined below is a brokerage firm with only one licensed person", 
       isText: false,
       type: "checkbox",
       top: 405.48,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "undefined_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 455.519,
       left: 107.04,
       width: 455.04,
       height: 14.640
    }
    ,
    {
       page: 0,
       name: "undefined_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 486,
       left: 121.68,
       width: 442.56,
       height: 14.64
    }
    ,
    {
       page: 0,
       name: "Colorado", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 501.6,
       left: 419.28,
       width: 107.40,
       height: 14.64
    }
    ,
    {
       page: 0,
       name: "undefined_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 555.6,
       left: 140.88,
       width: 422.52,
       height: 14.64
    }
    ,
    {
       page: 0,
       name: "Affordable Housing If this box is checked Seller represents to the best of Sellers actual knowledge the Property IS", 
       isText: false,
       type: "checkbox",
       top: 618.12,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "If this box is checked Seller authorizes Broker to negotiate a lease of the Property Lease of the Property or", 
       isText: false,
       type: "checkbox",
       top: 710.52,
       left: 97.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "ta_propertyLegal", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 516.58,
       left: 76.4941,
       width: 492.3729,
       height: 38.098
    }
    ,
    {
       page: 0,
       name: "tf_brokerageFirm", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 470.706,
       left: 144.684,
       width: 419.070,
       height: 14.119
    }
    ,
    {
       page: 1,
       name: "and continues through", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 67.92,
       left: 384.24,
       width: 95.64,
       height: 12.24
    }
    ,
    {
       page: 1,
       name: "and any", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 80.88,
       left: 420.24,
       width: 100.44,
       height: 12.24
    }
    ,
    {
       page: 1,
       name: "not a Saturday Sunday or Holiday Should neither box be checked the deadline will not be extended", 
       isText: false,
       type: "checkbox",
       top: 234.36,
       left: 338.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Will", 
       isText: false,
       type: "checkbox",
       top: 234.36,
       left: 376.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "buyer as a customer", 
       isText: false,
       type: "checkbox",
       top: 474.84,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "offers on the Property and whether the offers were obtained by Broker a broker within Brokerage Firm or by another", 
       isText: false,
       type: "checkbox",
       top: 255.48,
       left: 171,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Will Not disclose to prospective buyers and cooperating brokers the existence of", 
       isText: false,
       type: "checkbox",
       top: 255.48,
       left: 218.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "of the gross purchase price or 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 421.68,
       left: 185.76,
       width: 45.12,
       height: 12.48
    }
    ,
    {
       page: 2,
       name: "in US dollars", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 421.68,
       left: 424.48,
       width: 69.96,
       height: 12.48
    }
    ,
    {
       page: 2,
       name: "of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 434.88,
       left: 508.8,
       width: 47.52,
       height: 12.24
    }
    ,
    {
       page: 2,
       name: "in US dollars_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 447.84,
       left: 204.72,
       width: 57.48,
       height: 11.825
    }
    ,
    {
       page: 2,
       name: "of the", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 462,
       left: 495.6,
       width: 47.4,
       height: 12.48
    }
    ,
    {
       page: 2,
       name: "in US dollars payable as follows", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 475.44,
       left: 226.68,
       width: 60.00,
       height: 12.48
    }
    ,
    {
       page: 2,
       name: "Brokerage Firm agrees to contribute from the Lease", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 488.64,
       left: 92.4,
       width: 266.76,
       height: 12.48
    }
    ,
    {
       page: 2,
       name: "of the gross rent or", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 501.84,
       left: 305.04,
       width: 54.96,
       height: 12.48
    }
    ,
    {
       page: 2,
       name: "in US dollars_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 501.84,
       left: 456.24,
       width: 52.68,
       height: 12.48
    }
    ,
    {
       page: 2,
       name: "calendar days after the Listing Period expires", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 607.44,
       left: 354.96,
       width: 40.20,
       height: 12.48
    }
    ,
    {
       page: 2,
       name: "Holdover Period 1 to anyone with whom Broker negotiated and 2 whose name was submitted in writing to Seller by", 
       isText: false,
       type: "checkbox",
       top: 633,
       left: 423.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Brokerage Firm under this  723 if a commission is earned by another licensed brokerage firm acting pursuant to an", 
       isText: false,
       type: "checkbox",
       top: 633,
       left: 383.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "ta_otherCompensation", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 528.607,
       left: 80.8717,
       width: 494.5653,
       height: 27.077
    }
    ,
    {
       page: 3,
       name: "Will_2", 
       isText: false,
       type: "checkbox",
       top: 194.04,
       left: 154.2,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will Not be submitted to one or more MLS and", 
       isText: false,
       type: "checkbox",
       top: 194.04,
       left: 194.04,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will_3", 
       isText: false,
       type: "checkbox",
       top: 194.04,
       left: 406.92,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will Not be submitted to one", 
       isText: false,
       type: "checkbox",
       top: 194.04,
       left: 446.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will_4", 
       isText: false,
       type: "checkbox",
       top: 291.72,
       left: 184.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will Not be displayed on the Internet", 
       isText: false,
       type: "checkbox",
       top: 291.72,
       left: 224.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will_5", 
       isText: false,
       type: "checkbox",
       top: 309,
       left: 182.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will Not be displayed on the Internet_2", 
       isText: false,
       type: "checkbox",
       top: 309,
       left: 222.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Electronic Lock Box", 
       isText: false,
       type: "checkbox",
       top: 353.4,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Manual Lock Box", 
       isText: false,
       type: "checkbox",
       top: 353.4,
       left: 302.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "undefined_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 364.08,
       left: 138.24,
       width: 414.96,
       height: 14.64
    }
    ,
    {
       page: 3,
       name: "undefined_6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 379.68,
       left: 162.96,
       width: 389.88,
       height: 14.64
    }
    ,
    {
       page: 3,
       name: "Actively Licensed Real Estate Brokers", 
       isText: false,
       type: "checkbox",
       top: 426.84,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Unlicensed Broker Assistants", 
       isText: false,
       type: "checkbox",
       top: 442.44,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Other", 
       isText: false,
       type: "checkbox",
       top: 457.8,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Licensed Appraisers", 
       isText: false,
       type: "checkbox",
       top: 426.84,
       left: 302.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Unlicensed Inspectors", 
       isText: false,
       type: "checkbox",
       top: 442.44,
       left: 302.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "undefined_7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 453.12,
       left: 166.32,
       width: 382.44,
       height: 14.64
    }
    ,
    {
       page: 3,
       name: "ta_marketExcept", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 250.72,
       left: 76.3593,
       width: 503.5797,
       height: 24.024
    }
    ,
    {
       page: 3,
       name: "ta_marketingTasks", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 495.207,
       left: 68.7205,
       width: 503.5795,
       height: 127.761
    }
    ,
    {
       page: 3,
       name: "ta_mediaExcept", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 633.19,
       left: 69.6881,
       width: 503.5799,
       height: 48.003
    }
    ,
    {
       page: 3,
       name: "cb_propertyAccessOther", 
       isText: false,
       type: "checkbox",
       top: 368.725,
       left: 121.634,
       width: 10.080,
       height: 10.080
    }
    ,
    {
       page: 4,
       name: "Is", 
       isText: false,
       type: "checkbox",
       top: 151.08,
       left: 333.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Is Not currently a party to any listing agreement", 
       isText: false,
       type: "checkbox",
       top: 151.08,
       left: 362.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Has", 
       isText: false,
       type: "checkbox",
       top: 164.28,
       left: 393.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Has Not received a list of", 
       isText: false,
       type: "checkbox",
       top: 164.28,
       left: 431.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "undefined_8", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 376.56,
       left: 136.08,
       width: 99.84,
       height: 12.72
    }
    ,
    {
       page: 4,
       name: "undefined_9", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 392.16,
       left: 387.6,
       width: 190.08,
       height: 12.72
    }
    ,
    {
       page: 4,
       name: "Cash", 
       isText: false,
       type: "checkbox",
       top: 394.92,
       left: 119.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Conventional", 
       isText: false,
       type: "checkbox",
       top: 394.92,
       left: 168.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "FHA", 
       isText: false,
       type: "checkbox",
       top: 394.92,
       left: 251.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "VA", 
       isText: false,
       type: "checkbox",
       top: 394.92,
       left: 300.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Other_2", 
       isText: false,
       type: "checkbox",
       top: 394.92,
       left: 343.56,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "undefined_10", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 407.52,
       left: 180.24,
       width: 397.44,
       height: 12.72
    }
    ,
    {
       page: 4,
       name: "that Buyer is", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 422.88,
       left: 447.36,
       width: 72.48,
       height: 12.72
    }
    ,
    {
       page: 4,
       name: "undefined_11", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 436.32,
       left: 263.76,
       width: 312.48,
       height: 12.48
    }
    ,
    {
       page: 4,
       name: "undefined_12", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 466.8,
       left: 89.28,
       width: 230.04,
       height: 12.48
    }
    ,
    {
       page: 4,
       name: "Cashiers Check at Sellers expense", 
       isText: false,
       type: "checkbox",
       top: 486.36,
       left: 381.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Funds Electronically Transferred Wire Transfer to an account specified by Seller at Sellers expense or", 
       isText: false,
       type: "checkbox",
       top: 499.8,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Closing Companys Trust Account Check Wire and other frauds occur in real estate transactions Any time Seller is", 
       isText: false,
       type: "checkbox",
       top: 513,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "IS a foreign person for purposes of US income taxation and authorizes", 
       isText: false,
       type: "checkbox",
       top: 579.72,
       left: 273.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "tf_earnestMoney", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 452.478,
       left: 359.107,
       width: 72.480,
       height: 12.720
    }
    ,
    {
       page: 5,
       name: "by the Seller and included leased items should be listed under 1316 Leased Items", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 93.84,
       left: 328.56,
       width: 32.52,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "remote controls If checked the following are owned", 
       isText: false,
       type: "checkbox",
       top: 109.56,
       left: 494.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Satellite Systems including satellite dishes If any additional items are", 
       isText: false,
       type: "checkbox",
       top: 109.56,
       left: 448.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "attached to the Property after the date of this Seller Listing Contract such additional items are also included", 
       isText: false,
       type: "checkbox",
       top: 123,
       left: 90.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Security Systems", 
       isText: false,
       type: "checkbox",
       top: 123,
       left: 179.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "undefined_13", 
       isText: false,
       type: "checkbox",
       top: 123,
       left: 269.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "ta_encumberedInclusions", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 437.934,
       left: 72.4812,
       width: 500.8268,
       height: 82.854
    }
    ,
    {
       page: 5,
       name: "ta_leasedItemsPart", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 573.96,
       left: 78.874,
       width: 500.826,
       height: 67.216
    }
    ,
    {
       page: 5,
       name: "ta_exclusions", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 689.426,
       left: 81.1295,
       width: 500.8265,
       height: 62.0034
    }
    ,
    {
       page: 5,
       name: "ta_otherInclusions", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 214.501,
       left: 74.2427,
       width: 500.8263,
       height: 190.201
    }
    ,
    {
       page: 6,
       name: "undefined_14", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 107.04,
       left: 363.6,
       width: 205.08,
       height: 12.48
    }
    ,
    {
       page: 6,
       name: "and the use or ownership of the following storage facilities", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 150.439,
       left: 78.48,
       width: 467.64,
       height: 12.761
    }
    ,
    {
       page: 6,
       name: "135 Water RightsWell Rights", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 177.27,
       left: 78.48,
       width: 488.893,
       height: 16.65
    }
    ,
    {
       page: 6,
       name: "Deeded Water Rights The following legally described water rights", 
       isText: false,
       type: "checkbox",
       top: 214.92,
       left: 99.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "Other Rights Relating to Water The following rights relating to water not included in  1351 1353 and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 274.32,
       left: 378,
       width: 135.12,
       height: 12.48
    }
    ,
    {
       page: 6,
       name: "1354", 
       isText: false,
       type: "checkbox",
       top: 292.2,
       left: 99.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "Well Rights The Well Permit  is", 
       isText: false,
       type: "checkbox",
       top: 350.28,
       left: 99.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "cb_waterCert", 
       isText: false,
       type: "checkbox",
       top: 365.88,
       left: 99.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "tf_wellPermit", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 347.76,
       left: 256.32,
       width: 169.92,
       height: 12.48
    }
    ,
    {
       page: 6,
       name: "general warranty deed", 
       isText: false,
       type: "checkbox",
       top: 552.6,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "bargain and sale deed", 
       isText: false,
       type: "checkbox",
       top: 552.6,
       left: 194.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "quit claim deed", 
       isText: false,
       type: "checkbox",
       top: 552.6,
       left: 304.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "personal representatives deed", 
       isText: false,
       type: "checkbox",
       top: 552.6,
       left: 390.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "special warranty deed", 
       isText: false,
       type: "checkbox",
       top: 539.16,
       left: 450.185,
       width: 10.735,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "deed If title will be conveyed using a special warranty deed or a general warranty deed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 563.28,
       left: 95.04,
       width: 129.96,
       height: 12.48
    }
    ,
    {
       page: 6,
       name: "If the Property has been or will be subject to any governmental liens for special improvements installed at the time of signing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 652.32,
       left: 78.48,
       width: 495.72,
       height: 10.80
    }
    ,
    {
       page: 6,
       name: "tf_tradeFixtures", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 81.337,
       left: 80.0588,
       width: 485.7652,
       height: 14.044
    }
    ,
    {
       page: 6,
       name: "ta_deededWaterRights", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 226.214,
       left: 68.3297,
       width: 509.7453,
       height: 47.407
    }
    ,
    {
       page: 6,
       name: "ta_otherRightsRelatingWater", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 314.01,
       left: 70.4148,
       width: 509.7452,
       height: 33.332
    }
    ,
    {
       page: 6,
       name: "ta_waterStockCertificates", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 377.868,
       left: 69.3297,
       width: 509.7453,
       height: 34.375
    }
    ,
    {
       page: 6,
       name: "ta_growingCropss", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 423.306,
       left: 70.4148,
       width: 509.7452,
       height: 48.971
    }
    ,
    {
       page: 6,
       name: "cb_conveyOther", 
       isText: false,
       type: "checkbox",
       top: 565.392,
       left: 78.9594,
       width: 10.0800,
       height: 10.080
    }
    ,
    {
       page: 6,
       name: "ta_tenancies", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 698.5615,
       left: 68.4745,
       width: 509.7445,
       height: 52.0471
    }
    ,
    {
       page: 7,
       name: "16 ASSOCIATION ASSESSMENTS Seller represents that the amount of the regular owners association assessment is currently", 
       isText: false,
       type: "checkbox",
       top: 93,
       left: 344.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "Property except the current regular assessments and except", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 119.28,
       left: 175.92,
       width: 47.40,
       height: 12.48
    }
    ,
    {
       page: 7,
       name: "per", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 119.28,
       left: 238.56,
       width: 57.72,
       height: 12.48
    }
    ,
    {
       page: 7,
       name: "Seller agrees to promptly request the owners association to deliver to buyer before date of closing a current statement of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 132.48,
       left: 303.12,
       width: 272.40,
       height: 12.48
    }
    ,
    {
       page: 7,
       name: "cb_spdAgrees", 
       isText: false,
       type: "checkbox",
       top: 332.52,
       left: 278.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "cb_spd_notAgree", 
       isText: false,
       type: "checkbox",
       top: 332.52,
       left: 329.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "tf_possession", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 188.143,
       left: 60.1646,
       width: 511.3104,
       height: 13.134
    }
    ,
    {
       page: 8,
       name: "If no box is checked in this Section choice 1 paid to Seller in its entirety applies Any forfeiture of payment under this", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 120.24,
       left: 126,
       width: 432.48,
       height: 12.72
    }
    ,
    {
       page: 8,
       name: "cb_forfeitOther", 
       isText: false,
       type: "checkbox",
       top: 122.738,
       left: 82.2437,
       width: 10.0800,
       height: 10.080
    }
    ,
    {
       page: 8,
       name: "cb_forfeitDivided", 
       isText: false,
       type: "checkbox",
       top: 95.924,
       left: 82.0145,
       width: 10.0800,
       height: 10.080
    }
    ,
    {
       page: 8,
       name: "cb_forfeitSeller", 
       isText: false,
       type: "checkbox",
       top: 82.469,
       left: 82.3054,
       width: 10.0800,
       height: 10.080
    }
    ,
    {
       page: 9,
       name: "or Seller or any individual named in this Seller Listing Contract to receive documents or notices for such party at the", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 718.8,
       left: 305.52,
       width: 270.12,
       height: 12.48
    }
    ,
    {
       page: 9,
       name: "ta_attachments", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 514.21,
       left: 48.24,
       width: 519.066,
       height: 75.317
    }
    ,
    {
       page: 9,
       name: "ta_additionalProvisions2", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 93.906,
       left: 48.1413,
       width: 519.0657,
       height: 408.482
    }
    ,
   //  {
      //  ,
      //  {
      //     page: 10,
      //     name: "Sellers Signature", 
      //     type: "signature",
      //     fontName: "Helvetica",
      //     fontSize: 10,
      //     top: 333.6,
      //     left: 45.24,
      //     width: 179.88,
      //     height: 14.88
      //  }
      //  ,
      //  {
      //     page: 10,
      //     name: "Date", 
      //     fontSize: 9,
      //     type: "date",
      //     top: 333.6,
      //     left: 229.68,
      //     width: 30.60,
      //     height: 14.88
      //  }
      //  ,
       {
          page: 10,
          name: "Street Address", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 364.8,
          left: 49.68,
          width: 215.16,
          height: 14.64
       }
       ,
       {
          page: 10,
          name: "City State Zip", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 395.76,
          left: 45.12,
          width: 215.16,
          height: 14.88
       }
       ,
       {
          page: 10,
          name: "Phone No", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 426.72,
          left: 45.12,
          width: 215.16,
          height: 14.88
       }
       ,
       {
          page: 10,
          name: "Email Address", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 457.68,
          left: 45.12,
          width: 215.16,
          height: 14.88
       }
      //  ,
      //  {
      //     page: 10,
      //     name: "Sellers Signature Date", 
      //     type: "signature",
      //     fontName: "Helvetica",
      //     fontSize: 10,
      //     top: 519.6,
      //     left: 45.12,
      //     width: 215.16,
      //     height: 14.88
      //  }
       ,
       {
          page: 10,
          name: "Street Address_2", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 550.8,
          left: 45.12,
          width: 215.16,
          height: 14.64
       }
       ,
       {
          page: 10,
          name: "City State Zip_2", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 581.76,
          left: 45.12,
          width: 215.16,
          height: 14.88
       }
       ,
       {
          page: 10,
          name: "Phone No_2", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 612.72,
          left: 45.12,
          width: 215.16,
          height: 14.88
       }
       ,
       {
          page: 10,
          name: "Email Address_2", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 643.68,
          left: 45.12,
          width: 215.16,
          height: 14.88
       }
       ,
       {
          page: 10,
          name: "Brokers Signature", 
          type: "signature",
          fontName: "Helvetica",
          fontSize: 10,
          top: 333.6,
          left: 301.68,
          width: 154.6, //175.44,
          height: 15
       }
       ,
       {
          page: 10,
          name: "Date_2", 
          fontSize: 9,
          type: "date",
          top: 333.6,
          left: 463.589,
          width: 53.25,
          height: 14.88
       }
       ,
       {
          page: 10,
          name: "Brokerage Firm Street Address", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 364.8,
          left: 301.68,
          width: 215.16,
          height: 14.64
       }
       ,
       {
          page: 10,
          name: "Brokerage Firm City State Zip", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 395.76,
          left: 301.68,
          width: 215.16,
          height: 14.88
       }
       ,
       {
          page: 10,
          name: "Broker Phone No", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 426.72,
          left: 301.68,
          width: 215.16,
          height: 14.88
       }
       ,
       {
          page: 10,
          name: "Broker Email Address", 
          type: "text",
          fontName: "Helvetica",
          fontSize: 10,
          top: 457.68,
          left: 301.68,
          width: 215.16,
          height: 14.88
       }
       ] }
    