import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
  TextArea,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { createSignerLists } from "./sendForSigningUtils";
import ModalWrapper from "../../../../../app/common/modals/modalWrapper";
import { createSignerColor } from "../annots/annotUtils";
import { closeModal } from "../../../../../app/common/modals/modalSlice";
import { uploadBlobToStorage } from "../../../../../app/firestore/firebaseService";
import { fillAndFlattenPdf } from "../../../../../app/pdfLib/pdfLib";
import {
  addHistoryToDb,
  sendDocSharingEmail,
  updateDocSentForSigningInDb,
} from "../../../../../app/firestore/firestoreService";

export default function SendForSigning() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { doc } = useSelector((state) => state.doc);
  const { transaction, transClients, parties, allParties } = useSelector(
    (state) => state.transaction
  );
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [processingForms, setProcessingForms] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const [signerListPossible, setSignerListPossible] = useState([]);
  const [signerListNotPossible, setSignerListNotPossible] = useState([]);
  const [signerListFinal, setSignerListFinal] = useState([]);
  const [note, setNote] = useState("");

  useEffect(() => {
    const newSignerLists = createSignerLists(doc, allParties, transaction);
    setSignerListPossible(newSignerLists.possible);
    setSignerListNotPossible(newSignerLists.notPossible);
    setSignerListFinal(newSignerLists.possible);
  }, [doc, transaction, parties, transClients, allParties, dispatch]);

  function handleToggleSigner(party) {
    if (!signerListFinal?.includes(party)) {
      setSignerListFinal([...signerListFinal, party]);
    } else {
      setSignerListFinal(
        signerListFinal.filter((signer) => signer.role !== party.role)
      );
    }
  }

  function createSignerInfo(signer, includeEmail) {
    let signerInfo = signer.role;
    if (signer.firstName && signer.lastName) {
      signerInfo = signerInfo + ": " + signer.firstName + " " + signer.lastName;
    }
    if (signer.email && includeEmail) {
      signerInfo = signerInfo + " (" + signer.email + ")";
    }
    return signerInfo;
  }

  async function handleSendForSigning() {
    setProcessingForms(true);
    try {
      if (!doc.pdfBurnVersion) {
        const pdfBytes = await fillAndFlattenPdf(
          doc,
          transaction,
          currentUserProfile
        );
        await uploadBlobToStorage(doc.docRef, pdfBytes);
      }
      updateDocSentForSigningInDb(doc, note, signerListFinal, transaction);
      const agentProfile = transaction.agentProfile?.lastName
        ? transaction.agentProfile
        : currentUserProfile;
      sendDocSharingEmail(
        signerListFinal,
        "signing",
        agentProfile,
        transaction
      );
      signerListFinal &&
        signerListFinal.forEach((signer) => {
          if (signer.email) {
            addHistoryToDb(
              transaction.id,
              currentUserProfile,
              "sent for signing",
              doc.name,
              signer
            );
          }
        });
      setProcessingForms(false);
      toast.success("Document successfully sent for signing");
      navigate(`/transactions/${transaction.id}/documents`);
      dispatch(
        closeModal({
          modalType: "SendForSigning",
        })
      );
    } catch (error) {
      toast.error(error.message);
      setProcessingForms(false);
    }
  }

  function handleChange(e) {
    setNote(e.target.value);
  }

  function handleCancel() {
    dispatch(
      closeModal({
        modalType: "SendForSigning",
      })
    );
  }

  return (
    <ModalWrapper size="tiny">
      <Segment>
        <Grid>
          <Grid.Column width={16} className="zero bottom padding">
            <Header as="h2" color="blue" className="zero bottom margin">
              Who should we email for signatures?
            </Header>
            <Divider className="zero bottom margin" />
          </Grid.Column>
          <Grid.Column width={16}>
            {signerListPossible &&
            signerListPossible.length === 0 &&
            signerListNotPossible.length === 0 ? (
              <Grid.Column width={16}>
                <h3 className="text red tiny bottom margin">
                  Alert: There are no signable fields
                </h3>
                <p className="text red">
                  There are no signable fields added to this document so it
                  can't be sent out for signing. Please go back and drag
                  signable fields onto the document or share the document
                  instead of requesting signatures.
                </p>
              </Grid.Column>
            ) : (
              <p className="text-muted">
                Deselect anyone that you don't want to email about signing this
                form.
              </p>
            )}
            {signerListPossible?.map((signer) => (
              <Message
                key={signer.role}
                color={createSignerColor(signer.role, transaction)}
                onClick={() => handleToggleSigner(signer)}
                style={{ cursor: "pointer" }}
              >
                {signerListFinal &&
                signerListFinal.filter((e) => e.role === signer.role).length >
                  0 ? (
                  <Icon name="check square outline" size="large" />
                ) : (
                  <Icon name="square outline" size="large" />
                )}
                {createSignerInfo(signer, true)}
              </Message>
            ))}
            <Header as="h3" color="blue">
              Add a note (optional)
            </Header>
            <Form>
              <TextArea
                rows={4}
                value={note}
                onChange={(e) => handleChange(e)}
              />
            </Form>
          </Grid.Column>

          {signerListNotPossible && signerListNotPossible.length !== 0 && (
            <Grid.Column width={16}>
              <h3 className="text red tiny bottom margin">
                Alert: Missing Info
              </h3>
              <p className="text red">
                We can't email the following people because they are missing
                either a first name, last name, or email. If you want to email
                them go back to the 'Parties' page and add the missing info and
                then come back here.
              </p>
              {signerListNotPossible?.map((signer) => (
                <Message
                  key={signer.role}
                  color={createSignerColor(signer.role, transaction)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon name="square outline" size="large" />
                  {createSignerInfo(signer, true)}
                </Message>
              ))}
            </Grid.Column>
          )}

          <Grid.Column width={16} className="zero top padding">
            <Divider className="zero top margin" />
            {signerListFinal &&
              signerListFinal.length !== 0 &&
              (processingForms ? (
                <Button
                  primary
                  loading
                  floated={isMobile ? null : "right"}
                  className={isMobile ? "fluid medium bottom margin" : null}
                >
                  Loading
                </Button>
              ) : (
                <Button
                  floated={isMobile ? null : "right"}
                  primary
                  onClick={() => handleSendForSigning()}
                  disabled={!doc || processingForms}
                  className={isMobile ? "fluid medium bottom margin" : null}
                >
                  Submit
                </Button>
              ))}

            <Button
              floated={isMobile ? null : "right"}
              onClick={() => handleCancel()}
              className={isMobile ? "fluid medium bottom margin" : null}
            >
              Cancel
            </Button>
          </Grid.Column>
        </Grid>
      </Segment>
    </ModalWrapper>
  );
}
