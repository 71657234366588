export function exclusiveRighttoBuy2024Aug() {
return [   //2024 Release 2024-08-01 13:43:40
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "BUYER AGENCY", 
   isText: false,
   type: "checkbox",
   top: 232.44,
   left: 158.04,
   width: 12.24,
   height: 12.24
}
,
{
   page: 0,
   name: "TRANSACTIONBROKERAGE", 
   isText: false,
   type: "checkbox",
   top: 232.44,
   left: 301.8,
   width: 12.24,
   height: 12.24
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 264.567,
   left: 426.12,
   width: 125.04,
   height: 12.753
}
,
{
   page: 0,
   name: "MultiplePerson Firm If this box is checked Broker as defined below is the individual designated by", 
   isText: false,
   type: "checkbox",
   top: 363.72,
   left: 90.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "OnePerson Firm If this box is checked Broker as defined below is a brokerage firm with only one licensed", 
   isText: false,
   type: "checkbox",
   top: 449.64,
   left: 90.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 508.24,
   left: 126.0,
   width: 435.0,
   height: 12.68
}
,
{
   page: 0,
   name: "Brokerage Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 525.76,
   left: 171.6,
   width: 390.0,
   height: 12.68
}
,
{
   page: 0,
   name: "Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 543.28,
   left: 130.44,
   width: 430.08,
   height: 12.68
}
,
{
   page: 0,
   name: "Text46", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 575.348,
   left: 67.4185,
   width: 506.0745,
   height: 104.473
}
,
{
   page: 1,
   name: "means any agreement between a landlord and the Buyer to create a tenancy or leasehold interest in the Property", 
   isText: false,
   type: "checkbox",
   top: 36.36,
   left: 112.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Listing Period The Listing Period of this Buyer Listing Contract begins on", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 66.48,
   left: 401.04,
   width: 129.96,
   height: 12.36
}
,
{
   page: 1,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 92.88,
   left: 94.8,
   width: 144.96,
   height: 12.36
}
,
{
   page: 1,
   name: "eg three days after MEC the first day is excluded and the last day is included If any deadline falls on a Saturday", 
   isText: false,
   type: "checkbox",
   top: 266.28,
   left: 407.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "that is not a Saturday Sunday or Holiday Should neither box be checked the deadline will not be extended", 
   isText: false,
   type: "checkbox",
   top: 266.28,
   left: 364.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "seller as a customer", 
   isText: false,
   type: "checkbox",
   top: 548.76,
   left: 125.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Success Fee Brokerage Firm will be paid as follows", 
   isText: false,
   type: "checkbox",
   top: 576.12,
   left: 106.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "of the purchase price or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 593.28,
   left: 237.96,
   width: 50.04,
   height: 12.36
}
,
{
   page: 2,
   name: "except as provided in", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 593.28,
   left: 405.72,
   width: 64.92,
   height: 12.36
}
,
{
   page: 2,
   name: "See  21 Additional Provisions or", 
   isText: false,
   type: "checkbox",
   top: 628.68,
   left: 223.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 641.88,
   left: 90.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 639.36,
   left: 134.16,
   width: 429.96,
   height: 12.36
}
,
{
   page: 2,
   name: "Hourly Fee Brokerage Firm will be paid", 
   isText: false,
   type: "checkbox",
   top: 733.56,
   left: 104.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "per hour for time spent by Broker pursuant to this Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 731.04,
   left: 293.88,
   width: 50.04,
   height: 12.36
}
,
{
   page: 3,
   name: "This hourly fee is payable to Brokerage Firm upon receipt", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 32.4,
   left: 269.88,
   width: 60.12,
   height: 13.32
}
,
{
   page: 3,
   name: "Retainer Fee Buyer will pay Brokerage Firm a nonrefundable retainer fee of", 
   isText: false,
   type: "checkbox",
   top: 68.28,
   left: 104.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "due and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 64.8,
   left: 441,
   width: 75,
   height: 13.32
}
,
{
   page: 3,
   name: "Will", 
   isText: false,
   type: "checkbox",
   top: 81.48,
   left: 347.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Will Not be credited against other fees", 
   isText: false,
   type: "checkbox",
   top: 81.48,
   left: 394.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Other Compensation", 
   isText: false,
   type: "checkbox",
   top: 113.88,
   left: 104.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 110.4,
   left: 214.8,
   width: 349.92,
   height: 13.32
}
,
{
   page: 3,
   name: "per square", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.96,
   left: 164.04,
   width: 65.04,
   height: 13.32
}
,
{
   page: 3,
   name: "or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.96,
   left: 275.4,
   width: 90.0,
   height: 13.32
}
,
{
   page: 3,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.96,
   left: 381.24,
   width: 185.04,
   height: 13.32
}
,
{
   page: 3,
   name: "See  21 Additional Provisions or_2", 
   isText: false,
   type: "checkbox",
   top: 199.32,
   left: 201,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Other_2", 
   isText: false,
   type: "checkbox",
   top: 199.32,
   left: 373.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 195.96,
   left: 422.16,
   width: 99.96,
   height: 13.32
}
,
{
   page: 3,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 215.28,
   left: 144.6,
   width: 425.04,
   height: 13.32
}
,
{
   page: 3,
   name: "If the Lease", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 262.32,
   left: 132.96,
   width: 380.04,
   height: 12.36
}
,
{
   page: 3,
   name: "Will_2", 
   isText: false,
   type: "checkbox",
   top: 291.24,
   left: 463.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Will Not be", 
   isText: false,
   type: "checkbox",
   top: 291.24,
   left: 505.32,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 328.32,
   left: 78.6,
   width: 480.0,
   height: 13.32
}
,
{
   page: 3,
   name: "Sellers Brokerage Firm or Seller May Pay Buyer IS Obligated to Pay Broker is authorized and instructed to", 
   isText: false,
   type: "checkbox",
   top: 365.4,
   left: 95.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Buyer Will Pay Buyer is obligated to pay Brokerage Firms Success Fee Brokerage Firm is NOT entitled to", 
   isText: false,
   type: "checkbox",
   top: 422.28,
   left: 95.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "calendar days after the Listing Period expires Holdover Period 1 if the Property is one on", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 507.36,
   left: 127.08,
   width: 50.04,
   height: 12.36
}
,
{
   page: 3,
   name: "Will_3", 
   isText: false,
   type: "checkbox",
   top: 536.28,
   left: 261.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Will Not owe the Brokerage Firms Success Fee under", 
   isText: false,
   type: "checkbox",
   top: 536.28,
   left: 306.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Is", 
   isText: false,
   type: "checkbox",
   top: 683.64,
   left: 324.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Is Not currently a party to any agreement with any", 
   isText: false,
   type: "checkbox",
   top: 683.64,
   left: 356.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Has", 
   isText: false,
   type: "checkbox",
   top: 710.04,
   left: 58.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Has Not received a list of any Submitted Property pursuant to a previous listing agreement to purchase or lease", 
   isText: false,
   type: "checkbox",
   top: 710.04,
   left: 99.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Buyers identity to third parties without prior written consent of Buyer", 
   isText: false,
   type: "checkbox",
   top: 445.56,
   left: 296.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Does Not have Buyers permission to disclose", 
   isText: false,
   type: "checkbox",
   top: 445.56,
   left: 341.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Text47", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 265.092,
   left: 66.7639,
   width: 508.6921,
   height: 83.528
}
,
{
   page: 4,
   name: "Text48", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 386.184,
   left: 80.5094,
   width: 473.3466,
   height: 11.527
}
,
{
   page: 4,
   name: "Text49", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 413.02,
   left: 80.5094,
   width: 494.9466,
   height: 32.473
}
,
{
   page: 5,
   name: "undefined_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 636.48,
   left: 285.24,
   width: 279.96,
   height: 12.36
}
,
{
   page: 5,
   name: "Text50", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 109.31,
   left: 58.9093,
   width: 517.8567,
   height: 368.256
}
,
{
   page: 5,
   name: "Text51", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 490.911,
   left: 57.6003,
   width: 516.5467,
   height: 37.055
}
,
{
   page: 6,
   name: "buyer1name", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 9,
   top: 179.494,
   left: 92.4977,
   width: 201.8623,
   height: 19.415
}
,
{
   page: 6,
   name: "Date brokerSigns", 
   fontSize: 9,
   type: "date",
   top: 202.44,
   left: 467.52,
   width: 89.28,
   height: 10.56
}
,
{
   page: 6,
   name: "Street Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 223.08,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 6,
   name: "Brokerage Firm Street Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 223.08,
   left: 310.08,
   width: 240.00,
   height: 10.56
}
,
{
   page: 6,
   name: "City State Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 243.72,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 6,
   name: "Brokerage Firm City State Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 243.72,
   left: 310.08,
   width: 240.00,
   height: 10.56
}
,
{
   page: 6,
   name: "Phone No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 264.6,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 6,
   name: "Broker Phone No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 264.6,
   left: 310.08,
   width: 240.00,
   height: 10.56
}
,
{
   page: 6,
   name: "Fax No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 285.24,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 6,
   name: "Broker Fax No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 285.24,
   left: 310.08,
   width: 240.00,
   height: 10.56
}
,
{
   page: 6,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 305.88,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 6,
   name: "Broker Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 305.88,
   left: 310.08,
   width: 240.00,
   height: 10.56
}
,
{
   page: 6,
   name: "signature cobrokerSig", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 359.4,
   left: 308.52,
   width: 152.837,
   height: 10.905
}
,
{
   page: 6,
   name: "Street Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 380.04,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 6,
   name: "City State Zip_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 400.68,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 6,
   name: "Phone No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 421.56,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 6,
   name: "Fax No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 442.2,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 6,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 462.84,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 6,
   name: "Buyer 3 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 492.84,
   left: 105.36,
   width: 187.20,
   height: 10.56
}
,
{
   page: 6,
   name: "brokerageFirm", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 9,
   top: 177.494,
   left: 383.03,
   width: 190.735,
   height: 19.415
}
,
{
   page: 6,
   name: "Buyer 4 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 545.64,
   left: 102.48,
   width: 190.08,
   height: 10.56
}
,
{
   page: 6,
   name: "signature brokerSig", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 202.787,
   left: 310.484,
   width: 152.182,
   height: 9.906
}
,
{
   page: 6,
   name: "Date cobrokerSigns", 
   fontSize: 9,
   type: "date",
   top: 359.4,
   left: 467.52,
   width: 89.28,
   height: 10.56
}
,
{
   page: 6,
   name: "buyer2name", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 9,
   top: 335.092,
   left: 90.9822,
   width: 203.0188,
   height: 20.382
}
] }
