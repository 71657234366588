export function brokerageDisclosuretoBuyer() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
      page: 0,
      name: "below is for a specific property described as", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 508.724,
      left: 57.6,
      width: 495.24,
      height: 14.596
   }
   ,
   {
      page: 0,
      name: "or real estate which substantially meets the following requirements", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 536.989,
      left: 57.6,
      width: 490.68,
      height: 15.251
   }
   ,
   {
      page: 0,
      name: "Check Box1", 
      isText: false,
      type: "checkbox",
      top: 621.003,
      left: 58.2546,
      width: 9.2727,
      height: 9.216
   }
   ,
   {
      page: 0,
      name: "Check Box2", 
      isText: false,
      type: "checkbox",
      top: 687.112,
      left: 58.0364,
      width: 9.2727,
      height: 9.216
   }
   ,
   {
      page: 1,
      name: "Check Box3", 
      isText: false,
      type: "checkbox",
      top: 78.275,
      left: 58.2546,
      width: 9.2160,
      height: 9.216
   }
   ,
   {
      page: 1,
      name: "Check Box4", 
      isText: false,
      type: "checkbox",
      top: 78.275,
      left: 189.164,
      width: 9.216,
      height: 9.216
   }
   ,
   {
      page: 1,
      name: "Check Box5", 
      isText: false,
      type: "checkbox",
      top: 78.275,
      left: 258.546,
      width: 9.216,
      height: 9.216
   }
   ,
   {
      page: 1,
      name: "Check Box7", 
      isText: false,
      type: "checkbox",
      top: 89.839,
      left: 200.073,
      width: 9.216,
      height: 9.216
   }
   ,
   {
      page: 1,
      name: "Check Box6", 
      isText: false,
      type: "checkbox",
      top: 89.402,
      left: 282.328,
      width: 9.216,
      height: 9.216
   }
   ,
   {
      page: 1,
      name: "Check Box8", 
      isText: false,
      type: "checkbox",
      top: 121.911,
      left: 58.2546,
      width: 9.2160,
      height: 9.216
   }
   ,
   {
      page: 1,
      name: "Check Box9", 
      isText: false,
      type: "checkbox",
      top: 165.457,
      left: 58.2546,
      width: 9.2160,
      height: 9.216
   }
   ,
   {
      page: 1,
      name: "Text119", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 438.547,
      left: 85.7458,
      width: 187.9642,
      height: 14.800
   }
   ,
   {
      page: 1,
      name: "Text120", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 439.856,
      left: 340.365,
      width: 204.328,
      height: 12.837
   }
   ,
   {
      page: 1,
      name: "date Broker provided", 
      fontSize: 9,
      type: "date",
      top: 487.025,
      left: 72.36,
      width: 147.24,
      height: 13.135
   }
   ,
   {
      page: 1,
      name: "Buyer with", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 485.455,
      left: 292.92,
      width: 281.848,
      height: 14.705
   }
   ,
   {
      page: 1,
      name: "and retained a copy for Brokers records", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 509.509,
      left: 188.142,
      width: 195.698,
      height: 14.051
   }
   ,
   {
      page: 1,
      name: "Brokerage Firms Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 533.029,
      left: 160.08,
      width: 383.76,
      height: 14.706
   }
   ,
   {
      page: 1,
      name: "signature broker", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 560.293,
      left: 57.6003,
      width: 177.4907,
      height: 16.764
   }
   ,
   {
      page: 1,
      name: "date brokerSign", 
      fontSize: 9,
      type: "date",
      top: 560.293,
      left: 237.074,
      width: 74.727,
      height: 17.419
   }
   ,
   {
      page: 1,
      name: "Text125", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 579.275,
      left: 89.6731,
      width: 242.2909,
      height: 10.873
   }
   ,
   {
      page: 1,
      name: "signature cobroker", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 609.385,
      left: 57.6003,
      width: 177.4907,
      height: 16.109
   }
   ,
   {
      page: 1,
      name: "date cobrokerSign", 
      fontSize: 9,
      type: "date",
      top: 610.039,
      left: 238.383,
      width: 76.690,
      height: 15.455
   }
   ,
   {
      page: 1,
      name: "Text126", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 627.366,
      left: 104.073,
      width: 229.201,
      height: 12.837
   }
   ] }
   