import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clauses: [],
};

const clausesSlice = createSlice({
  name: "clauses",
  initialState,
  reducers: {
    fetchClauses(state, action) {
      state.clauses = action.payload;
    },
  },
});

export const { fetchClauses } = clausesSlice.actions;

export default clausesSlice.reducer;
