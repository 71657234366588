import { addDays, format, isSameDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, Card } from "semantic-ui-react";
import TaskActionButtons from "../taskComponents/TaskActionButtons";

export function TaskAllCalendarWeekly() {
  const navigate = useNavigate();
  const { transActive } = useSelector((state) => state.transaction);
  const { tasksAllWithDateUpcoming } = useSelector((state) => state.task);
  const [tasksForDisplay, setTasksForDisplay] = useState([{}, {}, {}, {}]);

  useEffect(() => {
    if (tasksAllWithDateUpcoming?.length > 0) {
      let tasksByDay = [];
      [0, 1, 2, 3].forEach((day) => {
        tasksByDay[day] = tasksAllWithDateUpcoming.filter((task) =>
          isSameDay(task.end, addDays(new Date(), day - 1))
        );
      });
      let dayDisplay = [{}, {}, {}, {}];
      tasksByDay.forEach((day, index) => {
        const dayIndex = index;
        day.forEach((task) => {
          if (dayDisplay[dayIndex]?.[task.transactionId]?.["tasks"]) {
            dayDisplay[dayIndex][task.transactionId]["tasks"].push(task);
          } else {
            const transaction = transActive.filter(
              (trans) => trans.id === task.transactionId
            )[0];
            dayDisplay[dayIndex][task.transactionId] = {
              address: transaction?.address?.street || "",
              agentName: transaction?.agentName,
              agentRepresents: transaction?.agentRepresents,
              title: transaction?.title,
              transactionId: task.transactionId,
              closingDateTime: transaction?.closingDateTime,
              tasks: [task],
            };
          }
        });
      });
      setTasksForDisplay(dayDisplay);
    }
  }, [tasksAllWithDateUpcoming, transActive]);

  function handleGoToTransaction(transactionId) {
    navigate(`/transactions/${transactionId}/overview`);
  }

  return (
    <Grid style={{ minHeight: "100px" }} stackable columns={4}>
      {tasksForDisplay.map((day, index) => (
        <Grid.Column
          key={"key1" + index}
          width={4}
          className={
            index === 0
              ? "background-orange"
              : index === 1
              ? "background-blue"
              : "background-white"
          }
          style={{
            paddingTop: "4px",
            boxShadow: "0 0 0 1px #d4d4d5",
          }}
        >
          <p className="inline large bottom margin bold small right margin">
            {format(addDays(new Date(), index - 1), "EEEE")}
          </p>
          {index === 0 && (
            <span className="float-left text orange">Yesterday</span>
          )}
          {index === 1 && <span className="float-left text blue">Today</span>}
          <span className="float-right bold">
            {format(addDays(new Date(), index - 1), "d")}
          </span>
          {Object.values(day).map((value, index) => (
            <div key={"key2" + index} className="medium bottom margin">
              <Card
                fluid
                onClick={() => handleGoToTransaction(value.transactionId)}
              >
                <Card.Content>
                  {/* <Popup content='Add users to your feed' trigger={<Card.Header className="text-normal cursor-pointer" onClick={() => handleGoToTransaction(value.transactionId)}>{value.address}</Card.Header>} /> */}
                  <Card.Header className="text-normal">
                    {value.address}
                  </Card.Header>
                  {value.tasks.map((task) => (
                    <div key={task.id}>
                      <div className="zero bottom margin bold">
                        <TaskActionButtons task={task} textStyle={true} />
                      </div>
                    </div>
                  ))}
                  {value.agentName && (
                    <Card.Meta>
                      {value.agentRepresents === "Buyer"
                        ? `BA: ${value.agentName}`
                        : `LA: ${value.agentName}`}
                    </Card.Meta>
                  )}
                  <Card.Meta>
                    {value.closingDateTime &&
                      `Closing: ${format(
                        new Date(value.closingDateTime),
                        "M/d/yyyy"
                      )}`}
                  </Card.Meta>
                </Card.Content>
              </Card>
            </div>
          ))}
        </Grid.Column>
      ))}
    </Grid>
  );
}
