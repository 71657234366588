export function newHomeContractIncompleteConstruction() {
return [   //2024 Release 2024-02-19 08:05:33
{
   page: 0,
   name: "1 PARTIES The parties to this contract are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 80.393,
   left: 279.625,
   width: 270.947,
   height: 11.936
}
,
{
   page: 0,
   name: "Seller and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 92.12,
   left: 127.615,
   width: 245.716,
   height: 10.947
}
,
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 111.116,
   left: 144.283,
   width: 79.873,
   height: 11.863
}
,
{
   page: 0,
   name: "Text3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 111.116,
   left: 256.916,
   width: 59.884,
   height: 11.863
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 111.546,
   left: 326.465,
   width: 223.800,
   height: 11.961
}
,
{
   page: 0,
   name: "Addition City of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 123.228,
   left: 143.666,
   width: 168.846,
   height: 10.894
}
,
{
   page: 0,
   name: "Text4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 123.443,
   left: 369.065,
   width: 105.714,
   height: 10.552
}
,
{
   page: 0,
   name: "Text5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 131.925,
   left: 59.3813,
   width: 364.7307,
   height: 12.518
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 203.919,
   left: 443.974,
   width: 106.680,
   height: 11.428
}
,
{
   page: 0,
   name: "Third Party Financing Addendum", 
   isText: false,
   type: "checkbox",
   top: 236.4,
   left: 313.254,
   width: 9.612,
   height: 9.333
}
,
{
   page: 0,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 248.173,
   left: 74.3708,
   width: 9.9965,
   height: 9.462
}
,
{
   page: 0,
   name: "Seller Financing Addendum", 
   isText: false,
   type: "checkbox",
   top: 247.865,
   left: 249.027,
   width: 9.080,
   height: 9.335
}
,
{
   page: 0,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 247.679,
   left: 443.44,
   width: 106.68,
   height: 11.428
}
,
{
   page: 0,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 259.36,
   left: 443.44,
   width: 106.68,
   height: 11.454
}
,
{
   page: 0,
   name: "Seller is 1", 
   isText: false,
   type: "checkbox",
   top: 333.212,
   left: 141.255,
   width: 9.080,
   height: 9.335
}
,
{
   page: 0,
   name: "Seller is not 1", 
   isText: false,
   type: "checkbox",
   top: 332.747,
   left: 168.482,
   width: 9.612,
   height: 9.333
}
,
{
   page: 0,
   name: "Seller (1)", 
   isText: false,
   type: "checkbox",
   top: 355.48,
   left: 61.1341,
   width: 9.0800,
   height: 9.335
}
,
{
   page: 0,
   name: "Seller (2)", 
   isText: false,
   type: "checkbox",
   top: 369.48,
   left: 61.1341,
   width: 9.0800,
   height: 9.335
}
,
{
   page: 0,
   name: "days 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 389.626,
   left: 334.034,
   width: 37.135,
   height: 11.042
}
,
{
   page: 0,
   name: "5 undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 441.202,
   left: 149.793,
   width: 227.159,
   height: 10.265
}
,
{
   page: 0,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 439.633,
   left: 471.987,
   width: 82.800,
   height: 13.436
}
,
{
   page: 0,
   name: "as earnest money to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 451.283,
   left: 73.2209,
   width: 249.4001,
   height: 11.428
}
,
{
   page: 0,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 451.566,
   left: 391.013,
   width: 93.065,
   height: 10.679
}
,
{
   page: 0,
   name: "escrow agent  at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 462.381,
   left: 140.858,
   width: 94.723,
   height: 11.359
}
,
{
   page: 0,
   name: "Buyer shall deliver additional earnest money of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 483.158,
   left: 339.655,
   width: 98.185,
   height: 11.599
}
,
{
   page: 0,
   name: "notify the other party in writing before entering into a contract of sale Disclose if applicable", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 493.442,
   left: 90.4031,
   width: 35.5699,
   height: 11.042
}
,
{
   page: 0,
   name: "within the time required Seller may terminate this contract or exercise Sellers remedies under", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 628.526,
   left: 74.2799,
   width: 39.4691,
   height: 11.918
}
,
{
   page: 1,
   name: "Contract Concerning", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.451,
   left: 130.08,
   width: 303.24,
   height: 12.203
}
,
{
   page: 1,
   name: "Sellers", 
   isText: false,
   type: "checkbox",
   top: 68.125,
   left: 312.435,
   width: 9.386,
   height: 9.934
}
,
{
   page: 1,
   name: "Buyers expense an owner policy", 
   isText: false,
   type: "checkbox",
   top: 68.658,
   left: 370.395,
   width: 9.386,
   height: 9.401
}
,
{
   page: 1,
   name: "of title insurance Title Policy issued by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 78.378,
   left: 266.67,
   width: 195.015,
   height: 11.962
}
,
{
   page: 1,
   name: "i will not be amended or deleted from the title policy or", 
   isText: false,
   type: "checkbox",
   top: 237.298,
   left: 73.7014,
   width: 9.3857,
   height: 9.401
}
,
{
   page: 1,
   name: "ii will be amended to read shortages in area at the expense of", 
   isText: false,
   type: "checkbox",
   top: 247.258,
   left: 73.7014,
   width: 9.9193,
   height: 9.401
}
,
{
   page: 1,
   name: "Buyer", 
   isText: false,
   type: "checkbox",
   top: 247.792,
   left: 430.395,
   width: 9.386,
   height: 8.867
}
,
{
   page: 1,
   name: "Seller", 
   isText: false,
   type: "checkbox",
   top: 247.792,
   left: 474.795,
   width: 8.852,
   height: 8.867
}
,
{
   page: 1,
   name: "1At least", 
   isText: false,
   type: "checkbox",
   top: 406.281,
   left: 59.7825,
   width: 8.3186,
   height: 9.401
}
,
{
   page: 1,
   name: "new survey to Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 407.695,
   left: 134.462,
   width: 45.436,
   height: 9.826
}
,
{
   page: 1,
   name: "2At least", 
   isText: false,
   type: "checkbox",
   top: 426.373,
   left: 60.2489,
   width: 8.8522,
   height: 8.869
}
,
{
   page: 1,
   name: "new survey Buyer is deemed to receive the survey on the date of actual receipt or the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 426.72,
   left: 132.324,
   width: 38.847,
   height: 10.895
}
,
{
   page: 1,
   name: "or activity", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 474.466,
   left: 527.14,
   width: 31.82,
   height: 10.361
}
,
{
   page: 1,
   name: "undefined_9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 484.542,
   left: 72.3272,
   width: 487.4948,
   height: 11.016
}
,
{
   page: 1,
   name: "Buyer must object the earlier of i the Closing Date or ii", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 494.32,
   left: 369.545,
   width: 36.557,
   height: 11.55
}
,
{
   page: 1,
   name: "is", 
   isText: false,
   type: "checkbox",
   top: 703.7481,
   left: 447.721,
   width: 9.079,
   height: 7.4919
}
,
{
   page: 1,
   name: "is not", 
   isText: false,
   type: "checkbox",
   top: 703.439,
   left: 471.587,
   width: 8.546,
   height: 8.801
}
,
{
   page: 2,
   name: "Page 3 of 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.691,
   left: 130.08,
   width: 303.894,
   height: 11.429
}
,
{
   page: 2,
   name: "change orders or items selected by Buyer which exceed the allowances specified in the-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 719.7394,
   left: 285.25,
   width: 277.505,
   height: 11.2409
}
,
{
   page: 2,
   name: "Construction Documents will be paid by Buyer as follows-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 728.3214,
   left: 80.6984,
   width: 481.5886,
   height: 11.8170
}
,
{
   page: 2,
   name: "Construction Documents will be paid by Buyer as follows-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 737.8681,
   left: 80.9324,
   width: 472.1536,
   height: 12.4460
}
,
{
   page: 3,
   name: "Contract Concerning_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.172,
   left: 130.08,
   width: 303.894,
   height: 10.948
}
,
{
   page: 3,
   name: "change orders or items selected by Buyer which exceed the allowances specified in the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 167.943,
   left: 359.559,
   width: 196.366,
   height: 11.870
}
,
{
   page: 3,
   name: "Construction Documents will be paid by Buyer as follows", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 177.783,
   left: 69.4655,
   width: 485.9915,
   height: 11.817
}
,
{
   page: 3,
   name: "or will not in Sellers judgment adversely affect the marketability of the Property Buyer will", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 246.932,
   left: 202.439,
   width: 38.640,
   height: 10.241
}
,
{
   page: 3,
   name: "days after notice from Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 255.772,
   left: 397.119,
   width: 42.000,
   height: 10.241
}
,
{
   page: 3,
   name: "accordance", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 275.699,
   left: 403.033,
   width: 155.476,
   height: 10.241
}
,
{
   page: 3,
   name: "Documents", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 285.172,
   left: 83.119,
   width: 20.727,
   height: 10.241
}
,
{
   page: 3,
   name: "1 as shown in the attached specifications", 
   isText: false,
   type: "checkbox",
   top: 419.695,
   left: 57.3544,
   width: 10.4529,
   height: 9.401
}
,
{
   page: 3,
   name: "2 as follows", 
   isText: false,
   type: "checkbox",
   top: 428.935,
   left: 57.888,
   width: 9.9193,
   height: 9.401
}
,
{
   page: 3,
   name: "a Exterior walls of improved living areas insulated with", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 439.655,
   left: 380.922,
   width: 166.702,
   height: 9.881
}
,
{
   page: 3,
   name: "inches 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 449.71,
   left: 244.38,
   width: 52.113,
   height: 9.347
}
,
{
   page: 3,
   name: "inches which yields an RValue of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 449.429,
   left: 469.024,
   width: 78.600,
   height: 9.347
}
,
{
   page: 3,
   name: "insulation to a thickness of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 458.954,
   left: 105.184,
   width: 443.565,
   height: 9.640
}
,
{
   page: 3,
   name: "b Walls in other areas of the home insulated with", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 468.204,
   left: 350.05,
   width: 199.156,
   height: 10.466
}
,
{
   page: 3,
   name: "insulation to a thickness of_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 477.615,
   left: 246.184,
   width: 46.440,
   height: 9.761
}
,
{
   page: 3,
   name: "inches which yields an RValue of_2_0", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 477.444,
   left: 467.704,
   width: 80.193,
   height: 11.050
}
,
{
   page: 3,
   name: "inches which yields an RValue of_2_1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 486.35,
   left: 105.976,
   width: 443.467,
   height: 9.741
}
,
{
   page: 3,
   name: "c Ceilings in improved living areas  insulated with", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 495.734,
   left: 352.864,
   width: 195.688,
   height: 10.002
}
,
{
   page: 3,
   name: "insulation to a thickness of 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 504.682,
   left: 246.184,
   width: 52.800,
   height: 10.294
}
,
{
   page: 3,
   name: "inches which yields an RValue of_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 503.786,
   left: 470.257,
   width: 78.295,
   height: 11.190
}
,
{
   page: 3,
   name: "insulation to a thickness of 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 515.608,
   left: 106.777,
   width: 440.936,
   height: 8.889
}
,
{
   page: 3,
   name: "insulation to a thickness of_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 524.444,
   left: 509.083,
   width: 40.778,
   height: 9.519
}
,
{
   page: 3,
   name: "RValue 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 533.15,
   left: 107.453,
   width: 190.942,
   height: 10.32
}
,
{
   page: 3,
   name: "d Floors of improved living areas not applied to a slab foundation insulated with", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 532.541,
   left: 456.609,
   width: 59.095,
   height: 10.001
}
,
{
   page: 3,
   name: "RValue2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 541.352,
   left: 243.038,
   width: 305.488,
   height: 10.974
}
,
{
   page: 3,
   name: "e Other insulated areas insulated with", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 552.354,
   left: 297.824,
   width: 180.982,
   height: 9.665
}
,
{
   page: 3,
   name: "thickness of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 559.63,
   left: 171.064,
   width: 47.684,
   height: 10.973
}
,
{
   page: 3,
   name: "inches which yields an RValue of_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 562.222,
   left: 395.221,
   width: 152.640,
   height: 9.035
}
,
{
   page: 3,
   name: "is-1", 
   isText: false,
   type: "checkbox",
   top: 633.982,
   left: 120.481,
   width: 9.079,
   height: 7.492
}
,
{
   page: 3,
   name: "is not-1", 
   isText: false,
   type: "checkbox",
   top: 633.673,
   left: 155.347,
   width: 8.546,
   height: 8.801
}
,
{
   page: 3,
   name: "is-2", 
   isText: false,
   type: "checkbox",
   top: 652.982,
   left: 120.981,
   width: 9.079,
   height: 7.492
}
,
{
   page: 3,
   name: "is not-2", 
   isText: false,
   type: "checkbox",
   top: 652.673,
   left: 155.847,
   width: 8.546,
   height: 8.801
}
,
{
   page: 3,
   name: "is-3", 
   isText: false,
   type: "checkbox",
   top: 671.982,
   left: 120.981,
   width: 9.079,
   height: 7.492
}
,
{
   page: 3,
   name: "is not-3", 
   isText: false,
   type: "checkbox",
   top: 671.673,
   left: 155.847,
   width: 8.546,
   height: 8.801
}
,
{
   page: 3,
   name: "is-4", 
   isText: false,
   type: "checkbox",
   top: 688.982,
   left: 120.981,
   width: 9.079,
   height: 7.4919
}
,
{
   page: 3,
   name: "is not-4", 
   isText: false,
   type: "checkbox",
   top: 688.673,
   left: 155.847,
   width: 8.546,
   height: 8.8009
}
,
{
   page: 3,
   name: "is-5", 
   isText: false,
   type: "checkbox",
   top: 706.982,
   left: 120.981,
   width: 9.079,
   height: 7.4919
}
,
{
   page: 3,
   name: "is not-5", 
   isText: false,
   type: "checkbox",
   top: 706.6729,
   left: 155.847,
   width: 8.546,
   height: 8.8010
}
,
{
   page: 3,
   name: "is-6", 
   isText: false,
   type: "checkbox",
   top: 726.982,
   left: 120.981,
   width: 9.079,
   height: 7.4919
}
,
{
   page: 3,
   name: "is not-6", 
   isText: false,
   type: "checkbox",
   top: 726.6729,
   left: 155.847,
   width: 8.546,
   height: 8.8010
}
,
{
   page: 3,
   name: "is-7", 
   isText: false,
   type: "checkbox",
   top: 743.982,
   left: 120.981,
   width: 9.079,
   height: 7.4919
}
,
{
   page: 3,
   name: "is not-7", 
   isText: false,
   type: "checkbox",
   top: 743.6729,
   left: 155.847,
   width: 8.546,
   height: 8.8010
}
,
{
   page: 3,
   name: "is-7a", 
   isText: false,
   type: "checkbox",
   top: 744.982,
   left: 379.981,
   width: 9.079,
   height: 7.4919
}
,
{
   page: 3,
   name: "is not-7a", 
   isText: false,
   type: "checkbox",
   top: 744.6729,
   left: 434.847,
   width: 8.546,
   height: 8.8010
}
,
{
   page: 4,
   name: "Contract Concerning_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.47,
   left: 130.08,
   width: 301.931,
   height: 12.184
}
,
{
   page: 4,
   name: "is-8", 
   isText: false,
   type: "checkbox",
   top: 52.691,
   left: 124.981,
   width: 9.079,
   height: 7.492
}
,
{
   page: 4,
   name: "is not-8", 
   isText: false,
   type: "checkbox",
   top: 52.382,
   left: 159.847,
   width: 8.546,
   height: 8.801
}
,
{
   page: 4,
   name: "acknowledged by Seller and Buyers agreement to pay Seller-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 70.807,
   left: 73.8017,
   width: 480.7543,
   height: 10.601
}
,
{
   page: 4,
   name: "terminate this contract by giving notice of termination to Seller within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 107.906,
   left: 426.396,
   width: 98.348,
   height: 11.084
}
,
{
   page: 4,
   name: "acknowledged by Seller and Buyers agreement to pay Seller_0", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 203.791,
   left: 279.951,
   width: 271.375,
   height: 10.601
}
,
{
   page: 4,
   name: "acknowledged by Seller and Buyers agreement to pay Seller_1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 214.264,
   left: 73.7678,
   width: 478.8672,
   height: 10.601
}
,
{
   page: 4,
   name: "A The closing of the sale will be on or before", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 254.101,
   left: 292.851,
   width: 131.640,
   height: 13.082
}
,
{
   page: 4,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 257.168,
   left: 444.531,
   width: 29.280,
   height: 10.948
}
,
{
   page: 4,
   name: "upon closing and", 
   isText: false,
   type: "checkbox",
   top: 441.45,
   left: 465.468,
   width: 10.453,
   height: 9.401
}
,
{
   page: 4,
   name: "according to a temporary residential lease form promulgated by TREC or other written", 
   isText: false,
   type: "checkbox",
   top: 450.81,
   left: 118.428,
   width: 10.453,
   height: 9.401
}
,
{
   page: 4,
   name: "Text2-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 631.238,
   left: 349.374,
   width: 207.543,
   height: 12.401
}
,
{
   page: 4,
   name: "Text2-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 642.965,
   left: 60.3941,
   width: 495.6189,
   height: 10.463
}
,
{
   page: 4,
   name: "Text2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 653.524,
   left: 60.6576,
   width: 495.6184,
   height: 9.808
}
,
{
   page: 4,
   name: "bSeller shall also pay an amount not to exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 716.914,
   left: 352.473,
   width: 87.469,
   height: 10.653
}
,
{
   page: 5,
   name: "Contract Concerning_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.92,
   left: 130.08,
   width: 302.007,
   height: 11.236
}
,
{
   page: 6,
   name: "Contract Concerning_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 30.904,
   left: 130.08,
   width: 303.24,
   height: 12.216
}
,
{
   page: 6,
   name: "at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 287.284,
   left: 132.087,
   width: 162.066,
   height: 14.559
}
,
{
   page: 6,
   name: "undefined_18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 321.294,
   left: 60.9891,
   width: 235.4729,
   height: 15.334
}
,
{
   page: 6,
   name: "Text16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 357.927,
   left: 141.051,
   width: 21.410,
   height: 14.531
}
,
{
   page: 6,
   name: "Text17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 357.927,
   left: 167.196,
   width: 128.806,
   height: 14.531
}
,
{
   page: 6,
   name: "Text19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 394.012,
   left: 133.814,
   width: 163.497,
   height: 14.530
}
,
{
   page: 6,
   name: "Email-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 430.69,
   left: 133.087,
   width: 164.684,
   height: 15.334
}
,
{
   page: 6,
   name: "Email-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 474.137,
   left: 61.6959,
   width: 233.8721,
   height: 15.334
}
,
{
   page: 6,
   name: "at_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 288.645,
   left: 387.415,
   width: 161.304,
   height: 13.543
}
,
{
   page: 6,
   name: "undefined_19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 321.732,
   left: 317.535,
   width: 231.256,
   height: 14.896
}
,
{
   page: 6,
   name: "Text20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 357.03,
   left: 395.18,
   width: 21.41,
   height: 15.064
}
,
{
   page: 6,
   name: "Text21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 357.03,
   left: 421.883,
   width: 129.035,
   height: 15.693
}
,
{
   page: 6,
   name: "Text23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 394.004,
   left: 389.13,
   width: 161.954,
   height: 14.072
}
,
{
   page: 6,
   name: "Email_2-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 429.936,
   left: 389.069,
   width: 161.933,
   height: 16.059
}
,
{
   page: 6,
   name: "Email_2-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 473.145,
   left: 317.811,
   width: 231.122,
   height: 16.059
}
,
{
   page: 6,
   name: "Third Party Financing Addendum_2", 
   isText: false,
   type: "checkbox",
   top: 546.574,
   left: 61.3181,
   width: 9.9258,
   height: 9.401
}
,
{
   page: 6,
   name: "Seller Financing Addendum_2", 
   isText: false,
   type: "checkbox",
   top: 562.952,
   left: 61.3829,
   width: 10.9153,
   height: 9.605
}
,
{
   page: 6,
   name: "Addendum for Property Subject to", 
   isText: false,
   type: "checkbox",
   top: 579.305,
   left: 61.3181,
   width: 9.9258,
   height: 9.927
}
,
{
   page: 6,
   name: "Buyers Temporary Residential Lease", 
   isText: false,
   type: "checkbox",
   top: 612.758,
   left: 61.8453,
   width: 9.3793,
   height: 11.514
}
,
{
   page: 6,
   name: "Loan Assumption Addendum", 
   isText: false,
   type: "checkbox",
   top: 629.866,
   left: 61.3117,
   width: 10.9736,
   height: 9.933
}
,
{
   page: 6,
   name: "Addendum for Sale of Other Property", 
   isText: false,
   type: "checkbox",
   top: 650.21,
   left: 61.8452,
   width: 9.3922,
   height: 9.407
}
,
{
   page: 6,
   name: "Addendum for Reservation of Oil Gas", 
   isText: false,
   type: "checkbox",
   top: 674.222,
   left: 61.8453,
   width: 8.8714,
   height: 9.049
}
,
{
   page: 6,
   name: "Addendum for BackUp Contract", 
   isText: false,
   type: "checkbox",
   top: 697.688,
   left: 61.3245,
   width: 9.9194,
   height: 10.456
}
,
{
   page: 6,
   name: "Addendum Concerning Right to", 
   isText: false,
   type: "checkbox",
   top: 716.211,
   left: 61.8389,
   width: 9.4050,
   height: 10.443
}
,
{
   page: 6,
   name: "Addendum for Coastal Area Property", 
   isText: false,
   type: "checkbox",
   top: 547.374,
   left: 311.638,
   width: 8.852,
   height: 8.867
}
,
{
   page: 6,
   name: "Environmental Assessment Threatened or", 
   isText: false,
   type: "checkbox",
   top: 565.267,
   left: 311.638,
   width: 9.386,
   height: 9.934
}
,
{
   page: 6,
   name: "Sellers Temporary Residential Lease", 
   isText: false,
   type: "checkbox",
   top: 594.534,
   left: 311.638,
   width: 9.386,
   height: 8.867
}
,
{
   page: 6,
   name: "Short Sale Addendum", 
   isText: false,
   type: "checkbox",
   top: 608.88,
   left: 311.105,
   width: 10.452,
   height: 9.401
}
,
{
   page: 6,
   name: "Addendum for Property Located Seaward", 
   isText: false,
   type: "checkbox",
   top: 631.201,
   left: 311.638,
   width: 10.453,
   height: 9.401
}
,
{
   page: 6,
   name: "Addendum for Property in a Propane Gas", 
   isText: false,
   type: "checkbox",
   top: 652.734,
   left: 311.638,
   width: 10.453,
   height: 8.334
}
,
{
   page: 6,
   name: "Check Box15", 
   isText: false,
   type: "checkbox",
   top: 677.776,
   left: 311.625,
   width: 8.297,
   height: 10.530
}
,
{
   page: 6,
   name: "PID", 
   isText: false,
   type: "checkbox",
   top: 702.111,
   left: 311.563,
   width: 8.298,
   height: 10.530
}
,
{
   page: 6,
   name: "Other list", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 702.401,
   left: 394.615,
   width: 145.156,
   height: 11.027
}
,
{
   page: 6,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 712.374,
   left: 332.651,
   width: 207.120,
   height: 12.268
}
,
{
   page: 7,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 28.735,
   left: 130.08,
   width: 303.24,
   height: 14.452
}
,
{
   page: 7,
   name: "Attorney is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 99.62,
   left: 123.266,
   width: 166.680,
   height: 12.628
}
,
{
   page: 7,
   name: "undefined_22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 127.913,
   left: 60.2663,
   width: 229.6797,
   height: 13.695
}
,
{
   page: 7,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 160.26,
   left: 129.88,
   width: 23.011,
   height: 13.996
}
,
{
   page: 7,
   name: "Text8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 159.727,
   left: 156.609,
   width: 135.061,
   height: 15.063
}
,
{
   page: 7,
   name: "Text9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 193.783,
   left: 130.535,
   width: 21.944,
   height: 13.996
}
,
{
   page: 7,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 193.783,
   left: 156.68,
   width: 135.06,
   height: 13.996
}
,
{
   page: 7,
   name: "Email_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 226.393,
   left: 123.266,
   width: 166.680,
   height: 13.695
}
,
{
   page: 7,
   name: "Attorney is_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 99.62,
   left: 386.786,
   width: 166.088,
   height: 12.628
}
,
{
   page: 7,
   name: "undefined_24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 127.913,
   left: 321.266,
   width: 231.608,
   height: 13.695
}
,
{
   page: 7,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 159.727,
   left: 393.463,
   width: 21.943,
   height: 15.063
}
,
{
   page: 7,
   name: "Text12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 159.193,
   left: 419.608,
   width: 132.505,
   height: 15.597
}
,
{
   page: 7,
   name: "Text13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 192.836,
   left: 395.427,
   width: 21.943,
   height: 15.597
}
,
{
   page: 7,
   name: "Text14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 192.836,
   left: 422.105,
   width: 132.505,
   height: 15.597
}
,
{
   page: 7,
   name: "Email_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 227.795,
   left: 386.786,
   width: 167.346,
   height: 12.533
}
,
{
   page: 7,
   name: "BROKER FILL IN THE DATE OF FINAL ACCEPTANCE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 315.215,
   left: 141.12,
   width: 47.28,
   height: 13.346
}
,
{
   page: 7,
   name: "20_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 315.749,
   left: 228.36,
   width: 172.44,
   height: 12.812
}
,
{
   page: 7,
   name: "Effective Date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 315.215,
   left: 423.534,
   width: 34.440,
   height: 13.346
}
,
{
   page: 8,
   name: "Address of Property_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 28.255,
   left: 130.08,
   width: 302.585,
   height: 13.865
}
,
{
   page: 8,
   name: "Other Broker Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 128.935,
   left: 50.4,
   width: 158.949,
   height: 15.465
}
,
{
   page: 8,
   name: "License No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 128.401,
   left: 210.52,
   width: 74.426,
   height: 16.533
}
,
{
   page: 8,
   name: "Buyer only as Buyers agent", 
   isText: false,
   type: "checkbox",
   top: 167.693,
   left: 110.987,
   width: 9.386,
   height: 8.867
}
,
{
   page: 8,
   name: "Seller as Listing Brokers subagent", 
   isText: false,
   type: "checkbox",
   top: 181.026,
   left: 110.507,
   width: 9.386,
   height: 10.468
}
,
{
   page: 8,
   name: "Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 196.789,
   left: 50.4,
   width: 160.429,
   height: 15.398
}
,
{
   page: 8,
   name: "License No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 197.323,
   left: 212.535,
   width: 72.945,
   height: 14.865
}
,
{
   page: 8,
   name: "Team Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 224.684,
   left: 51.3497,
   width: 233.0843,
   height: 15.398
}
,
{
   page: 8,
   name: "Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 252.121,
   left: 50.4,
   width: 154.258,
   height: 15.999
}
,
{
   page: 8,
   name: "Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 252.121,
   left: 205.83,
   width: 79.65,
   height: 15.999
}
,
{
   page: 8,
   name: "Licensed Supervisor of Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 281.709,
   left: 50.4,
   width: 160.843,
   height: 15.465
}
,
{
   page: 8,
   name: "License No_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 281.709,
   left: 212.949,
   width: 72.531,
   height: 15.465
}
,
{
   page: 8,
   name: "Other Brokers Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 309.375,
   left: 50.4,
   width: 154.805,
   height: 15.999
}
,
{
   page: 8,
   name: "Phone_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 308.841,
   left: 206.377,
   width: 79.103,
   height: 16.533
}
,
{
   page: 8,
   name: "City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 338.109,
   left: 50.4,
   width: 134.505,
   height: 15.465
}
,
{
   page: 8,
   name: "State", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 338.109,
   left: 186.077,
   width: 33.129,
   height: 15.465
}
,
{
   page: 8,
   name: "Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 338.109,
   left: 220.912,
   width: 64.568,
   height: 15.465
}
,
{
   page: 8,
   name: "Listing Broker Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 128.401,
   left: 303.24,
   width: 166.091,
   height: 15.999
}
,
{
   page: 8,
   name: "License No_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 128.401,
   left: 470.503,
   width: 87.137,
   height: 15.999
}
,
{
   page: 8,
   name: "Seller and Buyer as an intermediary", 
   isText: false,
   type: "checkbox",
   top: 167.693,
   left: 364.361,
   width: 9.385,
   height: 8.867
}
,
{
   page: 8,
   name: "Seller only as Sellers agent", 
   isText: false,
   type: "checkbox",
   top: 181.559,
   left: 363.347,
   width: 9.919,
   height: 9.401
}
,
{
   page: 8,
   name: "Listing Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 196.255,
   left: 303.24,
   width: 172.32,
   height: 15.399
}
,
{
   page: 8,
   name: "License No_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 196.255,
   left: 477.266,
   width: 80.374,
   height: 15.983
}
,
{
   page: 8,
   name: "Team Name 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 224.338,
   left: 303.024,
   width: 254.029,
   height: 15.398
}
,
{
   page: 8,
   name: "Listing Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 253.508,
   left: 303.24,
   width: 163.573,
   height: 15.466
}
,
{
   page: 8,
   name: "Phone_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 252.975,
   left: 469.053,
   width: 88.587,
   height: 15.999
}
,
{
   page: 8,
   name: "Licensed Supervisor of Listing Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 281.242,
   left: 303.24,
   width: 172.254,
   height: 15.465
}
,
{
   page: 8,
   name: "License No_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 281.242,
   left: 477.199,
   width: 80.441,
   height: 15.465
}
,
{
   page: 8,
   name: "Listing Brokers Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 308.375,
   left: 303.24,
   width: 168.71,
   height: 15.999
}
,
{
   page: 8,
   name: "Phone_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 308.375,
   left: 473.655,
   width: 83.985,
   height: 15.999
}
,
{
   page: 8,
   name: "City_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 338.176,
   left: 303.24,
   width: 155.97,
   height: 14.398
}
,
{
   page: 8,
   name: "State_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 337.642,
   left: 460.382,
   width: 36.973,
   height: 14.932
}
,
{
   page: 8,
   name: "Zip_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 337.642,
   left: 498.527,
   width: 59.113,
   height: 14.932
}
,
{
   page: 8,
   name: "Selling Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 367.029,
   left: 303.24,
   width: 179.323,
   height: 14.398
}
,
{
   page: 8,
   name: "License No_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 367.029,
   left: 483.202,
   width: 74.438,
   height: 14.398
}
,
{
   page: 8,
   name: "Team Name 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 393.866,
   left: 303.024,
   width: 254.029,
   height: 15.398
}
,
{
   page: 8,
   name: "Selling Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 422.162,
   left: 303.24,
   width: 174.845,
   height: 15.465
}
,
{
   page: 8,
   name: "Phone_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 422.162,
   left: 479.79,
   width: 77.85,
   height: 15.465
}
,
{
   page: 8,
   name: "Licensed Supervisor of Selling Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 450.829,
   left: 303.24,
   width: 174.988,
   height: 15.998
}
,
{
   page: 8,
   name: "License No_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 450.829,
   left: 479.4,
   width: 78.24,
   height: 15.465
}
,
{
   page: 8,
   name: "Selling Associates Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 480.629,
   left: 303.24,
   width: 254.40,
   height: 14.398
}
,
{
   page: 8,
   name: "City_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 508.296,
   left: 303.24,
   width: 147.891,
   height: 14.398
}
,
{
   page: 8,
   name: "State_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 508.296,
   left: 451.236,
   width: 38.116,
   height: 14.931
}
,
{
   page: 8,
   name: "Zip_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 508.296,
   left: 490.524,
   width: 67.116,
   height: 14.931
}
,
{
   page: 8,
   name: "price", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 588.113,
   left: 438.657,
   width: 126.218,
   height: 11.779
}
,
{
   page: 8,
   name: "price when the Listing Brokers fee is received Escrow agent is authorized and directed to pay Other Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 595.791,
   left: 55.0136,
   width: 204.1094,
   height: 11.780
}
,
{
   page: 9,
   name: "Address of Property_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 29.869,
   left: 130.08,
   width: 302.585,
   height: 13.251
}
,
{
   page: 9,
   name: "is acknowledged", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 90.561,
   left: 107.52,
   width: 134.64,
   height: 14.853
}
,
{
   page: 9,
   name: "Option Fee in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 91.389,
   left: 370.68,
   width: 194.52,
   height: 14.852
}
,
{
   page: 9,
   name: "Seller or Listing Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 128.229,
   left: 52.92,
   width: 248.16,
   height: 14.318
}
,
{
   page: 9,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 127.161,
   left: 313.92,
   width: 243.12,
   height: 14.853
}
,
{
   page: 9,
   name: "is acknowledged_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 174.602,
   left: 106.68,
   width: 136.401,
   height: 13.785
}
,
{
   page: 9,
   name: "Earnest Money in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 174.309,
   left: 382.44,
   width: 174.96,
   height: 13.251
}
,
{
   page: 9,
   name: "Escrow Agent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 200.961,
   left: 52.68,
   width: 147.497,
   height: 14.853
}
,
{
   page: 9,
   name: "Received by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 200.961,
   left: 201.643,
   width: 99.197,
   height: 14.853
}
,
{
   page: 9,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 237.415,
   left: 52.68,
   width: 306.72,
   height: 13.785
}
,
{
   page: 9,
   name: "City_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 266.721,
   left: 52.44,
   width: 162.984,
   height: 14.853
}
,
{
   page: 9,
   name: "State_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 266.721,
   left: 216.889,
   width: 35.617,
   height: 14.853
}
,
{
   page: 9,
   name: "Zip_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 266.188,
   left: 253.972,
   width: 105.428,
   height: 15.386
}
,
{
   page: 9,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 201.428,
   left: 313.8,
   width: 143.135,
   height: 14.852
}
,
{
   page: 9,
   name: "DateTime", 
   fontSize: 9,
   type: "date",
   top: 201.495,
   left: 457.867,
   width: 98.933,
   height: 14.852
}
,
{
   page: 9,
   name: "Phone_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 237.415,
   left: 368.28,
   width: 189.12,
   height: 13.785
}
,
{
   page: 9,
   name: "Fax", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 267.721,
   left: 368.28,
   width: 189.12,
   height: 14.319
}
,
{
   page: 9,
   name: "Escrow Agent_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 337.269,
   left: 52.68,
   width: 144.83,
   height: 14.318
}
,
{
   page: 9,
   name: "Received by_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 337.269,
   left: 198.442,
   width: 102.398,
   height: 14.318
}
,
{
   page: 9,
   name: "Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 362.881,
   left: 52.68,
   width: 306.72,
   height: 14.319
}
,
{
   page: 9,
   name: "City_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 388.561,
   left: 52.44,
   width: 162.45,
   height: 14.319
}
,
{
   page: 9,
   name: "State_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 388.561,
   left: 216.356,
   width: 35.737,
   height: 14.319
}
,
{
   page: 9,
   name: "Zip_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 388.561,
   left: 253.559,
   width: 105.841,
   height: 14.319
}
,
{
   page: 9,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 338.269,
   left: 313.8,
   width: 168.564,
   height: 13.251
}
,
{
   page: 9,
   name: "Date_2", 
   fontSize: 9,
   type: "date",
   top: 338.269,
   left: 483.296,
   width: 73.504,
   height: 13.785
}
,
{
   page: 9,
   name: "Phone_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 362.881,
   left: 368.28,
   width: 189.12,
   height: 14.319
}
,
{
   page: 9,
   name: "Fax_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 389.095,
   left: 368.28,
   width: 189.12,
   height: 13.785
}
,
{
   page: 9,
   name: "is acknowledged_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 444.601,
   left: 106.68,
   width: 120.12,
   height: 14.853
}
,
{
   page: 9,
   name: "additional Earnest Money in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 445.135,
   left: 412.2,
   width: 143.16,
   height: 14.852
}
,
{
   page: 9,
   name: "Escrow Agent_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 472.028,
   left: 52.68,
   width: 151.232,
   height: 14.732
}
,
{
   page: 9,
   name: "Received by_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 472.028,
   left: 205.378,
   width: 95.462,
   height: 14.732
}
,
{
   page: 9,
   name: "Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 498.655,
   left: 52.68,
   width: 306.72,
   height: 13.785
}
,
{
   page: 9,
   name: "City_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 524.335,
   left: 52.44,
   width: 164.051,
   height: 14.319
}
,
{
   page: 9,
   name: "State_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 524.335,
   left: 217.956,
   width: 34.017,
   height: 14.319
}
,
{
   page: 9,
   name: "Zip_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 524.335,
   left: 252.905,
   width: 106.495,
   height: 13.785
}
,
{
   page: 9,
   name: "Email Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 472.975,
   left: 313.8,
   width: 139.785,
   height: 13.785
}
,
{
   page: 9,
   name: "DateTime_2", 
   fontSize: 9,
   type: "date",
   top: 472.975,
   left: 454.518,
   width: 102.282,
   height: 13.785
}
,
{
   page: 9,
   name: "Phone_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 498.655,
   left: 368.28,
   width: 189.12,
   height: 13.785
}
,
{
   page: 9,
   name: "Fax_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 524.335,
   left: 368.28,
   width: 189.12,
   height: 13.785
}
] }
