import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { useNavigate } from "react-router-dom";
import {
  addHistoryToDb,
  sendDocSignedEmail,
  updateAnnotsForDocInDb,
  updateDocSigningInDb,
} from "../../../app/firestore/firestoreService";
import { addAnnotsAndFlattenPdf } from "../../../app/pdfLib/pdfLib";
import { uploadBlobToStorage } from "../../../app/firestore/firebaseService";

export default function PartyDocSignComplete() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { annotsToSign, selectedSigner } = useSelector((state) => state.annot);
  const { doc } = useSelector((state) => state.doc);
  const { party } = useSelector((state) => state.party);
  const { transaction } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const [processingForm, setProcessingForm] = useState();

  async function handleSubmit() {
    setProcessingForm(true);
    try {
      await updateDocSigningInDb(doc, selectedSigner);
      if (!doc.pdfBurnVersion) {
        const annotsToAddToPdf = annotsToSign.filter(
          (annot) => annot.signed || annot.type === "date"
        );
        const pdfBytes = await addAnnotsAndFlattenPdf(
          annotsToAddToPdf,
          doc,
          currentUserProfile
        );
        updateAnnotsForDocInDb(annotsToSign, doc.id, { active: false });
        await uploadBlobToStorage(doc.docRef, pdfBytes);
      }
      sendDocSignedEmail(party, transaction, doc);
      addHistoryToDb(transaction.id, party, "signed", doc.name);
      toast.success("Document successfully signed!");
    } catch (error) {
      setProcessingForm(false);
      toast.error(error.message);
    }
    setProcessingForm(false);
    dispatch(
      closeModal({
        modalType: "PartyDocSignComplete",
      })
    );
    navigate(`/party/${party.id}/documents`);
  }

  function handleCancel() {
    dispatch(
      closeModal({
        modalType: "PartyDocSignComplete",
      })
    );
  }

  return (
    <ModalWrapper size="small">
      <Segment>
        <Grid>
          <Grid.Column width={16} className="zero bottom padding">
            <Header as="h2" color="blue" className="zero bottom margin">
              All Signatures are Complete for this Document
            </Header>
            <Divider className="zero bottom margin" />
          </Grid.Column>
          <Grid.Column width={16}>
            <Header as="h4">
              You can submit the form now or go back to it, but be sure to hit
              the "Submit Form" button in the bottom right corner when you are
              done.
            </Header>
          </Grid.Column>

          <Grid.Column width={16} className="zero top padding">
            <Divider className="zero top margin" />
            {processingForm ? (
              <Button
                color="orange"
                loading
                floated={isMobile ? null : "right"}
                className={isMobile ? "fluid medium bottom margin" : null}
              >
                Loading
              </Button>
            ) : (
              <Button
                color="orange"
                floated={isMobile ? null : "right"}
                className={isMobile ? "fluid medium bottom margin" : null}
                content="Submit Form"
                onClick={() => handleSubmit()}
              />
            )}

            <Button
              floated={isMobile ? null : "right"}
              onClick={() => handleCancel()}
              className={isMobile ? "fluid medium bottom margin" : null}
            >
              Go Back To Form
            </Button>
          </Grid.Column>
        </Grid>
      </Segment>
    </ModalWrapper>
  );
}
