import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Confirm, Dropdown, Button } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import _ from "lodash";
import {
  addTaskTemplateToDb,
  addTaskToDb,
  deleteTaskTemplateInDb,
} from "../../../app/firestore/firestoreService";
import { closeModal, openModal } from "../../../app/common/modals/modalSlice";
import { fetchTaskTemplate } from "./taskTemplatesSlice";
import { addDays, subDays } from "date-fns";

export default function TaskTemplatesItemActionButtons({
  taskTemplate,
  actionButtonMode,
}) {
  const dispatch = useDispatch();
  const { transaction } = useSelector((state) => state.transaction);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleEdit() {
    dispatch(fetchTaskTemplate(taskTemplate));
    dispatch(
      openModal({
        modalType: "TaskTemplatesEdit",
        modalProps: { newTemplate: false },
      })
    );
  }

  function handleChoose() {
    if (taskTemplate?.items?.length > 0) {
      taskTemplate.items.forEach((task) => {
        let taskCopy = _.cloneDeep(task);
        taskCopy["category"] = "Task";
        taskCopy["title"] = taskCopy.name;
        taskCopy["end"] = "";
        // NEED TO DETERMINE TASK DEADLINE HERE
        if (task.dateType && task.beforeOnAfter) {
          // Get Actual Date
          let dateTypeConverted = "";
          let taskDeadlineDate = "";
          if (task.dateType) {
            dateTypeConverted =
              _.lowerCase(task.dateType.split(" ")[0]) + "DateTime";
            taskDeadlineDate = transaction[dateTypeConverted];
          }
          if (taskDeadlineDate) {
            if (task.beforeOnAfter === "Before" && task.days) {
              taskCopy["end"] = subDays(taskDeadlineDate, task.days);
            } else if (task.beforeOnAfter === "On") {
              taskCopy["end"] = taskDeadlineDate;
            } else if (task.beforeOnAfter === "After" && task.days) {
              taskCopy["end"] = addDays(taskDeadlineDate, task.days);
            }
          }
        }
        taskCopy["start"] = taskCopy["end"];
        addTaskToDb(taskCopy, transaction);
      });
    }
    dispatch(
      closeModal({
        modalType: "TaskTemplatesEdit",
      })
    );
  }

  function handleCopy() {
    addTaskTemplateToDb(taskTemplate);
  }

  function handleDelete() {
    deleteTaskTemplateInDb(taskTemplate.id);
  }

  if (actionButtonMode === "apply") {
    return (
      <div className={isMobile ? null : "text align right"}>
        <Button primary onClick={() => handleChoose()}>
          Choose
        </Button>
      </div>
    );
  } else {
    return (
      <div className={isMobile ? null : "text align right"}>
        <Dropdown
          button
          icon="chevron down"
          text={isMobile ? "Actions " : null}
          className={isMobile ? "button icon" : "button mini icon"}
          direction={isMobile ? "right" : "left"}
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleEdit()}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => handleCopy()}>Copy</Dropdown.Item>
            <Dropdown.Item onClick={() => setConfirmOpen(true)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Confirm
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={() => handleDelete()}
        ></Confirm>
      </div>
    );
  }
}
