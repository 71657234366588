import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import {
  functionLinkTransactionWithAgent,
  functionShareWithAgent,
} from "../../../app/firestore/functionsService";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import {
  addHistoryToDb,
  sendDocSharingEmail,
  sendOfferSubmittedEmail,
  updateDocAddSharingInDb,
  updateDocInDb,
  updatePartyInDb,
  updateTransAddSharingInDb,
} from "../../../app/firestore/firestoreService";
import { partyIsAgent } from "../../../app/common/util/util";
import { serverTimestamp } from "firebase/firestore";
import _ from "lodash";

export default function DocShareAgentLinking({ doc, party, transaction }) {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const dispatch = useDispatch();

  let initialValues = transaction;

  const agentProfile = transaction.agentProfile?.lastName
    ? transaction.agentProfile
    : currentUserProfile;
  const sharedByName = agentProfile.firstName + " " + agentProfile.lastName;

  function handleShareWithoutLinking() {
    try {
      updateDocAddSharingInDb(doc.id, party, transaction);
      updateTransAddSharingInDb(transaction.id, party, transaction);
      updatePartyInDb(party, { declinedLinking: true });
    } catch (error) {
      throw error;
    }
    sendDocSharingEmail([party], "sharing", agentProfile, transaction);
    addHistoryToDb(
      transaction.id,
      currentUserProfile,
      "shared",
      doc.name,
      party
    );
    sendOfferAlertEmailToAdmin(party, doc);
    toast.success("Document shared without linking transaction.");
    dispatch(
      closeModal({
        modalType: "DocShareAgentLinking",
      })
    );
  }

  function sendOfferAlertEmailToAdmin(party, doc) {
    if (
      partyIsAgent(party.role) &&
      doc.title === "Contract to Buy and Sell, Residential"
    ) {
      sendOfferSubmittedEmail(agentProfile, party, transaction, doc);
    }
  }

  return (
    <>
      <ModalWrapper size="small">
        <Segment>
          <Grid>
            <Grid.Column>
              <Header size="large" color="blue">
                Link Transaction
              </Header>
              <Divider />
              <p className="text-large text blue bold ">
                Good news! {party.firstName} {party.lastName} is also using
                TransActioner.
              </p>
              <p className="text-medium small bottom margin">
                We can link the transactions so douments show up automatically
                in their transaction. Please make sure the MLS number(s) and
                legal description below are correct and then hit the "Link
                Transaction" button.
              </p>
              <p className="text-medium tiny bottom margin">
                If we aren't able to link the transaction based on the info
                below, then you can click the "Share Without Linking" button and
                we'll email the agent a link to where the document can be
                viewed.
              </p>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    if (
                      !values.mlsNumbers?.[0] &&
                      !values.mlsNumbers?.[1] &&
                      !values.propertyDetails?.legalDescription
                    ) {
                      toast.error(
                        "Please enter mls numbers or legal description"
                      );
                    } else {
                      const result = await functionLinkTransactionWithAgent({
                        party: party,
                        values: values,
                      });
                      if (result?.error) {
                        toast.error(result.error);
                      } else {
                        setSubmitting(false);
                        await updatePartyInDb(party, {
                          isLinked: true,
                          linkedTransactionId: result.transactionId,
                        });
                        functionShareWithAgent({
                          doc: doc,
                          party: {
                            ...party,
                            linkedTransactionId: result.transactionId,
                          },
                          sharedBy: sharedByName,
                        });
                        updateDocInDb(doc.id, {
                          sentToAgent: true,
                          sentToAgentId: party.id,
                          sentDocIsDirty: false,
                          sentToAgentAt: serverTimestamp(),
                        });
                        updateDocAddSharingInDb(doc.id, party, transaction);
                        updateTransAddSharingInDb(
                          transaction.id,
                          party,
                          transaction
                        );
                        const partyCopy = _.cloneDeep(party);
                        partyCopy.isLinked = true;
                        partyCopy.linkedTransactionId = result.transactionId;
                        sendDocSharingEmail(
                          [partyCopy],
                          "sharing",
                          agentProfile,
                          transaction
                        );
                        addHistoryToDb(
                          transaction.id,
                          currentUserProfile,
                          "shared",
                          doc.name,
                          party
                        );
                        sendOfferAlertEmailToAdmin(party, doc);
                        toast.success("Transaction successfully linked");
                        dispatch(
                          openModal({
                            modalType: "DocShareAgentLinkSuccessfull",
                          })
                        );
                      }
                    }
                  } catch (error) {
                    toast.error(error.message);
                    setSubmitting(false);
                  }
                }}
              >
                {({ isSubmitting, dirty, isValid }) => (
                  <Form className="ui form" autoComplete="off">
                    <Grid stackable>
                      <Grid.Column mobile={16} computer={16}>
                        <Grid className="zero vertical padding zero top margin">
                          <Grid.Row className="zero bottom padding">
                            <Grid.Column mobile={16} computer={8}>
                              <MyTextInput name="mlsNumbers[0]" label="MLS #" />
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={8}>
                              <MyTextInput
                                name="mlsNumbers[1]"
                                label="MLS # (secondary)"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={16}>
                              <MyTextArea
                                rows="3"
                                name="propertyDetails.legalDescription"
                                label="Legal description"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    </Grid>
                    <Divider className="medium top margin" />
                    <Button
                      loading={isSubmitting}
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      floated={isMobile ? null : "right"}
                      primary
                      content="Link Transaction"
                      className={isMobile ? "fluid medium bottom margin" : null}
                    />
                    <Button
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => handleShareWithoutLinking()}
                      to="#"
                      type="submit"
                      floated={isMobile ? null : "right"}
                      content="Share Without Linking"
                      className={isMobile ? "fluid medium bottom margin" : null}
                    />
                    <Button
                      disabled={isSubmitting}
                      onClick={() =>
                        dispatch(
                          closeModal({
                            modalType: "DocShareAgentLinking",
                          })
                        )
                      }
                      to="#"
                      type="button"
                      floated={isMobile ? null : "right"}
                      content="Cancel"
                      className={isMobile ? "fluid medium" : null}
                    />
                  </Form>
                )}
              </Formik>
            </Grid.Column>
          </Grid>
        </Segment>
      </ModalWrapper>
    </>
  );
}
