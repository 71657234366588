import React from "react";
import { useNavigate } from "react-router-dom";
import { Image, Table } from "semantic-ui-react";
import TransactionActionButtons from "../transactionComponents/TransactionActionButtons";
import { convertAddressFull } from "../../../app/common/util/util";
import { format } from "date-fns";
import { useSelector } from "react-redux";

export default function TransactionCompleteListItem({ transaction }) {
  const navigate = useNavigate();
  const { currentUserProfile } = useSelector((state) => state.profile);

  function handleRowClick() {
    navigate(`/transactions/${transaction.id}/overview`);
  }

  return (
    <Table.Row
      key={transaction.id}
      onClick={() => handleRowClick()}
      style={{ cursor: "pointer" }}
    >
      <Table.Cell style={{ padding: "0px" }}>
        {transaction.pic ? (
          <Image style={{ width: "90px" }} src={transaction.pic} />
        ) : (
          <Image
            src="/assets/placeholder-house.png"
            style={{ width: "90px" }}
            rounded
          />
        )}
      </Table.Cell>
      {currentUserProfile?.role !== "agent" && (
        <>
          <Table.Cell>
            {transaction.agentFirstName || transaction.agentProfile?.firstName}
          </Table.Cell>
          <Table.Cell>
            {transaction.agentLastName || transaction.agentProfile?.lastName}
          </Table.Cell>
        </>
      )}
      <Table.Cell>{transaction.title}</Table.Cell>
      <Table.Cell>
        {transaction.client?.firstName} {transaction.client?.lastName}
      </Table.Cell>
      <Table.Cell>
        {transaction.clientSecondary?.firstName}{" "}
        {transaction.clientSecondary?.lastName}
      </Table.Cell>
      <Table.Cell>{transaction.agentRepresents}</Table.Cell>
      <Table.Cell>{convertAddressFull(transaction.address)}</Table.Cell>
      <Table.Cell>
        {transaction.closingDateTime &&
          format(transaction.closingDateTime, "MM/dd/yyyy")}
      </Table.Cell>
      <Table.Cell>{transaction.status}</Table.Cell>
      <Table.Cell
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <TransactionActionButtons transaction={transaction} />
      </Table.Cell>
    </Table.Row>
  );
}
