import React from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import NavBarSecondaryLarge from "./NavBarSecondaryLarge";

export default function NavBarSecondary() {
  const location = useLocation().pathname;
  const locationPage = location?.substring(location?.lastIndexOf("/") + 1);

  const isMobile = useMediaQuery({ query: "(min-width:768px)" });

  if (
    locationPage === "prepare" ||
    locationPage === "fill" ||
    locationPage === "view" ||
    locationPage === "process"
  ) {
    return <></>;
  }

  return <div>{isMobile && <NavBarSecondaryLarge />}</div>;
}
