import React from "react";
import { Container, Grid, Header, Image, Segment } from "semantic-ui-react";
import { useSearchParams } from "react-router-dom";
import RegisterFormClient from "../auth/RegisterFormClient";

export default function PartyLoginPageOld() {
  const [searchParams] = useSearchParams();

  return (
    <>
      <div
        className="hero-background-img"
        style={{ backgroundImage: "url('/assets/img-background-blue.jpeg')" }}
      >
        <Container style={{ height: "100vh" }}>
          <Grid>
            <Grid.Row>
              <div className="medium top margin" style={{ width: "100%" }}>
                <Image
                  src="/assets/logo-transactioner-white-small.png"
                  style={{ float: "left" }}
                />
              </div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                floated="left"
                mobile={16}
                tablet={7}
                computer={7}
                className="mobile hidden tablet hidden"
              >
                <Image
                  src="/assets/img-laptop-login.png"
                  fluid
                  alt="laptop contract"
                  style={{ maxWidth: "540px" }}
                />
              </Grid.Column>
              <Grid.Column
                className="large top margin"
                floated="left"
                mobile={16}
                tablet={16}
                computer={8}
                textAlign="center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Segment style={{ padding: "2.2em" }}>
                  <Image
                    centered
                    src="/assets/logo-transactioner-color-small.png"
                    width="90"
                    alt="laptop contract"
                    style={{ marginTop: "2px" }}
                  />
                  <p className="text darkgrey tiny top margin large bottom margin">
                    Modern Real Estate Transaction Management
                  </p>
                  <Header
                    color="blue"
                    as="h1"
                    textAlign="center"
                    className="text-big small top margin"
                  >
                    Welcome {searchParams.get("signerName")}
                  </Header>
                  <div style={{ color: "dimgray" }}>
                    <p className="text-medium medium bottom margin">
                      {searchParams.get("agentName")
                        ? searchParams.get("agentName")
                        : "Your real estate agent"}{" "}
                      is sharing documents with you.{" "}
                    </p>
                    {searchParams.get("assistant") === "true" ? (
                      <>
                        <p className="text-medium medium bottom margin">
                          Please log in using the email address where you
                          received the link and create a new password if you
                          haven't used our site before.
                        </p>
                        <p className="text-medium medium bottom margin">
                          Note: If you're an assistant, please use the email of
                          the person you're assisting.
                        </p>
                      </>
                    ) : (
                      <p className="text-medium medium bottom margin">
                        Please log in using the email address where you received
                        the link and create a new password if you haven't used
                        our site before.
                      </p>
                    )}
                  </div>
                  <RegisterFormClient />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </>
  );
}
