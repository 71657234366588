export function uSDARuralHousingLoan() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 88.0,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 139.24,
   left: 438.415,
   width: 59.530,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.8,
   left: 454.32,
   width: 27.72,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 549.902,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 564.402,
   left: 31.536,
   width: 549.000,
   height: 13.500
}
] }
