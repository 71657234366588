export function farmRanchRecLandExhibitB() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 91.63,
   left: 31.5,
   width: 504.0,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 114.04,
   left: 31.5,
   width: 515.7,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 137.45,
   left: 280.8,
   width: 199.008,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 160.525,
   left: 211.4,
   width: 369.1,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 183.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 19", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 214.56,
   left: 34.56,
   width: 541.98,
   height: 419.94
}
,
{
   page: 0,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 666.588,
   left: 32.672,
   width: 179.086,
   height: 12.437
}
,
{
   page: 0,
   name: "Text Field 38", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 667.12,
   left: 210.6,
   width: 54.72,
   height: 13.50
}
,
{
   page: 0,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 667.143,
   left: 341.767,
   width: 179.086,
   height: 12.437
}
,
{
   page: 0,
   name: "Text Field 40", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 667.12,
   left: 521.82,
   width: 54.72,
   height: 13.50
}
,
{
   page: 0,
   name: "Text12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 694.2366,
   left: 31.7816,
   width: 179.0864,
   height: 12.4374
}
,
{
   page: 0,
   name: "Text Field 39", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 695.128,
   left: 210.6,
   width: 54.72,
   height: 13.500
}
,
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 693.8381,
   left: 341.767,
   width: 179.086,
   height: 12.4375
}
,
{
   page: 0,
   name: "Text Field 41", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 695.128,
   left: 521.82,
   width: 54.72,
   height: 13.500
}
] }
