export function gTARConventionalLoan() {
return [   //2024 Release 2024-04-18 19:56:46
{
   page: 0,
   name: "Buyer acknowledges that all explanations representations and disclosures relating to the terms and conditions of the loans", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 103.2,
   left: 35.76,
   width: 540.72,
   height: 9.6
}
,
{
   page: 0,
   name: "Private Mortgage Insurance PMI if applicable and the Buyers Earnest Money deposit shall remain refundable to the Buyer until", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 166.68,
   left: 453.36,
   width: 97.92,
   height: 9.60
}
,
{
   page: 0,
   name: "paragraph of the contract if left blank the Financing Deadline If the Buyer i is unable to secure financing acceptable to the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 187.32,
   left: 50.16,
   width: 40.32,
   height: 9.60
}
,
{
   page: 0,
   name: "1", 
   isText: true,
   type: "checkbox",
   top: 319.2,
   left: 86.4,
   width: 10.2,
   height: 9.6
}
,
{
   page: 0,
   name: "Review and approval of Buyers credit worthiness income and funds necessary to Close", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 319.2,
   left: 100.8,
   width: 25.68,
   height: 9.6
}
,
{
   page: 0,
   name: "5 ADDITIONAL PROVISIONS", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 590.16,
   left: 172.56,
   width: 403.92,
   height: 9.60
}
,
{
   page: 0,
   name: "1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 600.48,
   left: 50.16,
   width: 526.32,
   height: 9.48
}
,
{
   page: 0,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 610.68,
   left: 50.16,
   width: 526.32,
   height: 9.60
}
,
{
   page: 0,
   name: "3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 621,
   left: 50.16,
   width: 526.32,
   height: 9.48
}
,
{
   page: 0,
   name: "4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 631.2,
   left: 50.16,
   width: 526.32,
   height: 9.6
}
] }
