import React from "react";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import CalendarAll from "../../../app/common/calendar/CalendarAll";

export default function TaskAllCalendarMonthly() {
  const { tasksAllWithDateUpcoming } = useSelector((state) => state.task);

  return (
    <div className="main-page-wrapper">
      <Grid>
        <Grid.Column computer={16} tablet={16}>
          <CalendarAll tasksUpcoming={tasksAllWithDateUpcoming} />
        </Grid.Column>
      </Grid>
    </div>
  );
}
