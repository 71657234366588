export function listingAgreementAmendorExtend() {
return [   //2024 Release 2024-02-27 21:55:46
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 131.04,
   width: 462.96,
   height: 18.00
}
,
{
   page: 0,
   name: "Check Box 9", 
   isText: false,
   type: "checkbox",
   top: 144.607,
   left: 44.7536,
   width: 10.0800,
   height: 15.642
}
,
{
   page: 0,
   name: "Check Box 10", 
   isText: false,
   type: "checkbox",
   top: 162.445,
   left: 44.7536,
   width: 10.0800,
   height: 15.642
}
,
{
   page: 0,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 179.28,
   left: 65.87,
   width: 100.45,
   height: 15.382
}
,
{
   page: 0,
   name: "Text Field 47", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 197.28,
   left: 48.24,
   width: 124.429,
   height: 12.764
}
,
{
   page: 0,
   name: "Text Field 48", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 220.6,
   left: 77.76,
   width: 242.36,
   height: 13.418
}
,
{
   page: 0,
   name: "Text Field 49", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 220.6,
   left: 321.12,
   width: 105.56,
   height: 13.418
}
,
{
   page: 0,
   name: "Text Field 50", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 220.6,
   left: 427.68,
   width: 46.723,
   height: 13.418
}
,
{
   page: 0,
   name: "Text Field 51", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 220.6,
   left: 475.403,
   width: 56.677,
   height: 13.418
}
,
{
   page: 0,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 291.0,
   left: 284.842,
   width: 290.658,
   height: 14.727
}
,
{
   page: 0,
   name: "Text Field 29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 308.0,
   left: 246.24,
   width: 329.26,
   height: 14.727
}
,
{
   page: 0,
   name: "Text Field 35", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 338.14,
   left: 89.53,
   width: 485.97,
   height: 92.464
}
,
{
   page: 0,
   name: "Text Field 52", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 479.16,
   left: 36.28,
   width: 178.06,
   height: 14.727
}
,
{
   page: 0,
   name: "Text Field 41", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 480.124,
   left: 309.0,
   width: 229.191,
   height: 14.727
}
,
{
   page: 0,
   name: "Text3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 479.82,
   left: 539.348,
   width: 51.818,
   height: 14.728
}
,
{
   page: 0,
   name: "Text Field 53", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 514.572,
   left: 36.28,
   width: 178.06,
   height: 14.727
}
,
{
   page: 0,
   name: "Text Field 42", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 514.572,
   left: 309.0,
   width: 228.536,
   height: 14.727
}
,
{
   page: 0,
   name: "Text4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 514.202,
   left: 538.693,
   width: 52.473,
   height: 14.728
}
,
{
   page: 0,
   name: "signature Text Field 54", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 12,
   top: 549.984,
   left: 36.28,
   width: 178.06,
   height: 14.727
}
,
{
   page: 0,
   name: "Date Text Field 40", 
   fontSize: 9,
   type: "date",
   top: 549.984,
   left: 214.75,
   width: 88.03,
   height: 14.727
}
,
{
   page: 0,
   name: "Text Field 43", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 549.984,
   left: 309.0,
   width: 266.5,
   height: 14.727
}
,
{
   page: 0,
   name: "Text Field 44", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 585.396,
   left: 309.0,
   width: 266.5,
   height: 14.727
}
,
{
   page: 0,
   name: "Text Field 45", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 619.808,
   left: 309.0,
   width: 266.5,
   height: 14.727
}
,
{
   page: 0,
   name: "Text Field 46", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 655.221,
   left: 309.0,
   width: 266.5,
   height: 14.727
}
, 
{
   page: 0,
   name: "Logo",
   isText: false,
   fontSize: 10,
   type: "logo",
   top: 685,
   left: 309.0,
   width: 200,
   height: 30,
}
] }
