import React from "react";
import { Form, Formik } from "formik";
import ModalWrapper from "../../app/common/modals/modalWrapper";
import * as Yup from "yup";
import { Button, Divider, Header, Label, Segment } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalSlice";
import { resetPassword } from "../../app/firestore/firebaseService";
import { toast } from "react-toastify";
import MyEmailInput from "../../app/common/form/MyEmailInput";

export default function ResetPasswordForm() {
  const dispatch = useDispatch();

  return (
    <ModalWrapper size="mini">
      <Segment>
        <Header color="blue" as="h1">
          Password Reset
        </Header>
        <Divider />
        <p>
          Please enter your email address and we'll email you link to reset your
          password.
        </p>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object({
            email: Yup.string().required().email(),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              await resetPassword(values.email);
              setSubmitting(false);
              toast.success("Password reset emailed to you.");
              dispatch(closeModal());
            } catch (error) {
              setErrors({ auth: "Email does not exist" });
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => (
            <Form className="ui form">
              <MyEmailInput name="email" placeholder="Email address" />
              {errors.auth && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.auth}
                />
              )}
              <Button
                primary
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                fluid
                size="large"
                content="Submit"
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </ModalWrapper>
  );
}
