export function buyersTemporaryResidentialLease() {
return [   //2024 Release 2024-02-19 07:36:13
{
   page: 0,
   name: "1 PARTIES The parties to this Lease are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 103.349,
   left: 253.109,
   width: 318.360,
   height: 9.960
}
,
{
   page: 0,
   name: "Landlord and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 112.8,
   left: 126,
   width: 396,
   height: 9.96
}
,
{
   page: 0,
   name: "and Tenant as Buyer known as", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 140.28,
   left: 206.76,
   width: 363.24,
   height: 9.96
}
,
{
   page: 0,
   name: "address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 152.789,
   left: 48.3696,
   width: 470.6404,
   height: 9.960
}
,
{
   page: 0,
   name: "3 TERM The term of this Lease commences", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 167.88,
   left: 265.56,
   width: 196.44,
   height: 9.96
}
,
{
   page: 0,
   name: "4 RENTAL Rental will be", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 195.36,
   left: 180.36,
   width: 92.64,
   height: 9.96
}
,
{
   page: 0,
   name: "pay to Landlord the full amount of rental of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 206.28,
   left: 279.36,
   width: 92.64,
   height: 9.96
}
,
{
   page: 0,
   name: "5 DEPOSIT Tenant has paid to Landlord", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 255.84,
   left: 256.8,
   width: 133.2,
   height: 9.96
}
,
{
   page: 0,
   name: "6 UTILITIES Tenant shall pay all utility connections deposits and charges except", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 349.32,
   left: 460.32,
   width: 109.68,
   height: 9.96
}
,
{
   page: 0,
   name: "which Landlord shall pay", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 360.36,
   left: 47.88,
   width: 387.00,
   height: 9.96
}
,
{
   page: 0,
   name: "8 PETS Tenant may not keep pets on the Property except", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 404.28,
   left: 337.68,
   width: 230.40,
   height: 9.96
}
,
{
   page: 0,
   name: "Text 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 518.844,
   left: 177.019,
   width: 391.815,
   height: 12.612
}
,
{
   page: 0,
   name: "Text 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 531.598,
   left: 47.9184,
   width: 521.3576,
   height: 10.624
}
,
{
   page: 0,
   name: "Text 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 541.793,
   left: 47.9333,
   width: 521.8547,
   height: 10.625
}
,
{
   page: 0,
   name: "Text 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 552.54,
   left: 47.9684,
   width: 521.8546,
   height: 11.122
}
,
{
   page: 0,
   name: "Text 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 563.385,
   left: 47.9248,
   width: 521.6082,
   height: 10.378
}
,
{
   page: 0,
   name: "Text 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 574.306,
   left: 47.9114,
   width: 522.3526,
   height: 10.625
}
,
{
   page: 0,
   name: "Text 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 585.241,
   left: 47.6693,
   width: 522.8507,
   height: 10.868
}
,
{
   page: 0,
   name: "Text 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 596.259,
   left: 47.8005,
   width: 522.8505,
   height: 12.117
}
,
{
   page: 0,
   name: "Initialed for identification by Landlord", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 760.7824,
   left: 222.6,
   width: 51.214,
   height: 10.4576
}
,
{
   page: 0,
   name: "and Tenant", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 761.2799,
   left: 335.897,
   width: 52.524,
   height: 9.9601
}
,
{
   page: 1,
   name: "Buyers Temporary Residential Lease", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 34.464,
   left: 188.68,
   width: 291.72,
   height: 13.056
}
,
{
   page: 1,
   name: "19HOLDING OVER Any possession by Tenant after termination creates a tenancy at sufferance and will not", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 247.065,
   left: 343.08,
   width: 38.708,
   height: 9.215
}
,
{
   page: 1,
   name: "To Landlord 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 490.799,
   left: 133.073,
   width: 166.647,
   height: 13.801
}
,
{
   page: 1,
   name: "To Tenant 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 489.31,
   left: 380.111,
   width: 192.009,
   height: 14.545
}
,
{
   page: 1,
   name: "To Landlord 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 510.289,
   left: 51.3963,
   width: 248.3237,
   height: 14.311
}
,
{
   page: 1,
   name: "To Tenant 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 510.31,
   left: 307.891,
   width: 265.718,
   height: 14.545
}
,
{
   page: 1,
   name: "To Landlord 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 528.665,
   left: 51.6299,
   width: 246.0901,
   height: 14.055
}
,
{
   page: 1,
   name: "To Tenant 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 528.665,
   left: 307.869,
   width: 264.974,
   height: 14.310
}
,
{
   page: 1,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 553.544,
   left: 140.724,
   width: 23.193,
   height: 11.229
}
,
{
   page: 1,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 552.054,
   left: 171.632,
   width: 127.980,
   height: 12.208
}
,
{
   page: 1,
   name: "Text8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 553.054,
   left: 388.924,
   width: 23.192,
   height: 12.208
}
,
{
   page: 1,
   name: "Text9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 551.565,
   left: 416.341,
   width: 157.017,
   height: 13.697
}
,
{
   page: 1,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 574.597,
   left: 140.724,
   width: 23.682,
   height: 11.229
}
,
{
   page: 1,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 573.597,
   left: 171.376,
   width: 126.747,
   height: 12.463
}
,
{
   page: 1,
   name: "Text12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 574.107,
   left: 388.924,
   width: 23.682,
   height: 12.208
}
,
{
   page: 1,
   name: "Text13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 571.173,
   left: 417.409,
   width: 156.526,
   height: 13.441
}
,
{
   page: 1,
   name: "Facsimile", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 591.315,
   left: 105.36,
   width: 193.214,
   height: 13.134
}
,
{
   page: 1,
   name: "Facsimile_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 590.826,
   left: 360.07,
   width: 212.217,
   height: 12.864
}
] }
