import React from "react";
import { Table } from "semantic-ui-react";
import ManagerAgentActionButtons from "./ManagerAgentsActionButtons";

export default function ManagerAgentsListItem({ agent }) {

  return (
    <Table.Row
      key={agent.id}
    >
      <Table.Cell>{agent.firstName}</Table.Cell>
      <Table.Cell>{agent.lastName}</Table.Cell>
      <Table.Cell>{agent.email}</Table.Cell>
      <Table.Cell>{agent.phone}</Table.Cell>
      <Table.Cell>
        <ManagerAgentActionButtons agent={agent} />
      </Table.Cell>
    </Table.Row>
  );
}