export function farmandRanch() {
return [   //2024 Release 2024-02-20 14:00:46
{
   page: 0,
   name: "Disclosure Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 12.96,
   left: 132.48,
   width: 461.52,
   height: 12.60
}
,
{
   page: 0,
   name: "Disclosure Box 10", 
   isText: false,
   type: "checkbox",
   top: 143.104,
   left: 49.9725,
   width: 6.8400,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 11", 
   isText: false,
   type: "checkbox",
   top: 197.248,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 12", 
   isText: false,
   type: "checkbox",
   top: 223.384,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 13", 
   isText: false,
   type: "checkbox",
   top: 249.52,
   left: 49.968,
   width: 6.840,
   height: 6.84
}
,
{
   page: 0,
   name: "Disclosure Box 14", 
   isText: false,
   type: "checkbox",
   top: 264.712,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 15", 
   isText: false,
   type: "checkbox",
   top: 297.472,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 16", 
   isText: false,
   type: "checkbox",
   top: 334.336,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Box 17", 
   isText: false,
   type: "checkbox",
   top: 349.695,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Text Field 14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 401.472,
   left: 152.64,
   width: 156.96,
   height: 13.500
}
,
{
   page: 0,
   name: "Disclosure Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 401.472,
   left: 438.75,
   width: 138.33,
   height: 13.500
}
,
{
   page: 0,
   name: "Disclosure Box 18", 
   isText: false,
   type: "checkbox",
   top: 500.944,
   left: 68.04,
   width: 6.84,
   height: 6.840
}
,
{
   page: 0,
   name: "Disclosure Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 561.352,
   left: 157.68,
   width: 155.88,
   height: 13.500
}
,
{
   page: 0,
   name: "Disclosure Text Field 28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 561.352,
   left: 442.842,
   width: 134.000,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 308", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 27.952,
   left: 155.349,
   width: 416.151,
   height: 12.240
}
,
{
   page: 1,
   name: "Check Box 1", 
   isText: false,
   type: "checkbox",
   top: 167.656,
   left: 96.76,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Check Box 6", 
   isText: false,
   type: "checkbox",
   top: 167.728,
   left: 330.832,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 177.369,
   left: 96.76,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Check Box 7", 
   isText: false,
   type: "checkbox",
   top: 177.369,
   left: 330.832,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 187.016,
   left: 96.76,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Check Box 8", 
   isText: false,
   type: "checkbox",
   top: 187.088,
   left: 330.832,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Check Box 4", 
   isText: false,
   type: "checkbox",
   top: 196.304,
   left: 96.76,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Check Box 9", 
   isText: false,
   type: "checkbox",
   top: 196.232,
   left: 330.832,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Text Field 108", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 192.496,
   left: 340.56,
   width: 203.238,
   height: 12.240
}
,
{
   page: 1,
   name: "Check Box 5", 
   isText: false,
   type: "checkbox",
   top: 205.945,
   left: 96.76,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Check Box 10", 
   isText: false,
   type: "checkbox",
   top: 205.963,
   left: 330.832,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Text Field 109", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 201.226,
   left: 340.56,
   width: 203.238,
   height: 12.240
}
,
{
   page: 1,
   name: "Text Field 110", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 234.096,
   left: 42.336,
   width: 502.848,
   height: 12.240
}
,
{
   page: 1,
   name: "Text Field 111", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 246.336,
   left: 55.8,
   width: 489.384,
   height: 12.240
}
,
{
   page: 1,
   name: "Text Field 112", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 382.64,
   left: 174.906,
   width: 151.344,
   height: 12.24
}
,
{
   page: 1,
   name: "Text Field 113", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 396.752,
   left: 76.176,
   width: 493.920,
   height: 12.240
}
,
{
   page: 1,
   name: "Text Field 114", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 413.432,
   left: 76.032,
   width: 416.448,
   height: 12.240
}
,
{
   page: 1,
   name: "Text Field 115", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 425.355,
   left: 397.584,
   width: 173.916,
   height: 12.240
}
,
{
   page: 1,
   name: "Text Field 116", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 436.224,
   left: 76.032,
   width: 495.468,
   height: 12.240
}
,
{
   page: 1,
   name: "Check Box 11", 
   isText: false,
   type: "checkbox",
   top: 468.024,
   left: 96.76,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Text Field 117", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 547.272,
   left: 96.176,
   width: 352.024,
   height: 12.240
}
,
{
   page: 1,
   name: "Check Box 12", 
   isText: false,
   type: "checkbox",
   top: 539.936,
   left: 96.904,
   width: 6.192,
   height: 6.264
}
,
{
   page: 1,
   name: "Text Field 118", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 634.496,
   left: 379.08,
   width: 186.75,
   height: 12.240
}
,
{
   page: 2,
   name: "Text Field 307", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 27.952,
   left: 155.349,
   width: 416.151,
   height: 12.240
}
,
{
   page: 2,
   name: "Check Box 27", 
   isText: false,
   type: "checkbox",
   top: 285.84,
   left: 99.36,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 99", 
   isText: false,
   type: "checkbox",
   top: 294.48,
   left: 99.36,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 103", 
   isText: false,
   type: "checkbox",
   top: 285.84,
   left: 277.92,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 105", 
   isText: false,
   type: "checkbox",
   top: 294.48,
   left: 277.92,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 104", 
   isText: false,
   type: "checkbox",
   top: 285.84,
   left: 456.48,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 1010", 
   isText: false,
   type: "checkbox",
   top: 294.48,
   left: 456.48,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 100", 
   isText: false,
   type: "checkbox",
   top: 304.12,
   left: 99.36,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 101", 
   isText: false,
   type: "checkbox",
   top: 312.76,
   left: 99.36,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 106", 
   isText: false,
   type: "checkbox",
   top: 304.12,
   left: 277.92,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 102", 
   isText: false,
   type: "checkbox",
   top: 321.4,
   left: 99.36,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 107", 
   isText: false,
   type: "checkbox",
   top: 321.4,
   left: 277.92,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 1011", 
   isText: false,
   type: "checkbox",
   top: 312.76,
   left: 456.48,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Text Field 380", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 341.68,
   left: 76.32,
   width: 495.18,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 381", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 353.92,
   left: 76.32,
   width: 495.18,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 382", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 381.84,
   left: 76.32,
   width: 495.18,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 383", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 394.08,
   left: 76.32,
   width: 495.18,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 435", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 423.2,
   left: 76.32,
   width: 495.18,
   height: 12.24
}
,
{
   page: 2,
   name: "Check Box 109", 
   isText: false,
   type: "checkbox",
   top: 438.392,
   left: 222.48,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 108", 
   isText: false,
   type: "checkbox",
   top: 438.392,
   left: 256.32,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Text Field 384", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 458.784,
   left: 272.88,
   width: 94.32,
   height: 12.240
}
,
{
   page: 2,
   name: "Text Field 132", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 504.44,
   left: 311.094,
   width: 100.746,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 131", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 516.96,
   left: 219.6,
   width: 102.6,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 129", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 527.488,
   left: 76.32,
   width: 324.72,
   height: 12.240
}
,
{
   page: 2,
   name: "Check Box 30", 
   isText: false,
   type: "checkbox",
   top: 543.752,
   left: 76.616,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 29", 
   isText: false,
   type: "checkbox",
   top: 543.752,
   left: 210.68,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Text Field 130", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 539.44,
   left: 246.96,
   width: 44.80,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 133", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 577.4,
   left: 339.48,
   width: 102.33,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 134", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 605.28,
   left: 83.844,
   width: 102.276,
   height: 12.24
}
,
{
   page: 2,
   name: "Check Box 34", 
   isText: false,
   type: "checkbox",
   top: 625.04,
   left: 328.32,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 35", 
   isText: false,
   type: "checkbox",
   top: 625.04,
   left: 360.936,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Text Field 135", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 631.04,
   left: 455.4,
   width: 101.88,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 136", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 648.52,
   left: 140.04,
   width: 36.00,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 138", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 639.92,
   left: 266.04,
   width: 35.64,
   height: 12.24
}
,
{
   page: 2,
   name: "Text Field 137", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 658.4,
   left: 76.104,
   width: 35.640,
   height: 12.24
}
,
{
   page: 2,
   name: "Check Box 31", 
   isText: false,
   type: "checkbox",
   top: 677.512,
   left: 129.298,
   width: 6.265,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 32", 
   isText: false,
   type: "checkbox",
   top: 677.512,
   left: 159.84,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 33", 
   isText: false,
   type: "checkbox",
   top: 677.48,
   left: 190.44,
   width: 6.264,
   height: 6.336
}
,
{
   page: 2,
   name: "Check Box 36", 
   isText: false,
   type: "checkbox",
   top: 706.024,
   left: 76.688,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Text Field 309", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 27.952,
   left: 155.349,
   width: 416.151,
   height: 12.240
}
,
{
   page: 3,
   name: "Text Field 421", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 57.04,
   left: 212.84,
   width: 97.48,
   height: 12.24
}
,
{
   page: 3,
   name: "Check Box 122", 
   isText: false,
   type: "checkbox",
   top: 73.88,
   left: 77.04,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 123", 
   isText: false,
   type: "checkbox",
   top: 86.76,
   left: 77.04,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 124", 
   isText: false,
   type: "checkbox",
   top: 105.12,
   left: 180.0,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 125", 
   isText: false,
   type: "checkbox",
   top: 105.12,
   left: 237.24,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Text Field 304", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 264.88,
   left: 173.24,
   width: 35.28,
   height: 12.24
}
,
{
   page: 3,
   name: "Check Box 41", 
   isText: false,
   type: "checkbox",
   top: 386.625,
   left: 94.76,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 42", 
   isText: false,
   type: "checkbox",
   top: 436.513,
   left: 94.76,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 43", 
   isText: false,
   type: "checkbox",
   top: 510.944,
   left: 110.312,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 44", 
   isText: false,
   type: "checkbox",
   top: 511.088,
   left: 136.704,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 45", 
   isText: false,
   type: "checkbox",
   top: 525.928,
   left: 110.312,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 46", 
   isText: false,
   type: "checkbox",
   top: 525.928,
   left: 136.704,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 47", 
   isText: false,
   type: "checkbox",
   top: 545.696,
   left: 110.312,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 48", 
   isText: false,
   type: "checkbox",
   top: 545.696,
   left: 136.704,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 49", 
   isText: false,
   type: "checkbox",
   top: 565.536,
   left: 110.312,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 50", 
   isText: false,
   type: "checkbox",
   top: 565.536,
   left: 136.704,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 51", 
   isText: false,
   type: "checkbox",
   top: 581.088,
   left: 110.312,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 52", 
   isText: false,
   type: "checkbox",
   top: 581.088,
   left: 136.704,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 53", 
   isText: false,
   type: "checkbox",
   top: 595.928,
   left: 110.312,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 54", 
   isText: false,
   type: "checkbox",
   top: 595.928,
   left: 136.704,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 55", 
   isText: false,
   type: "checkbox",
   top: 652.41,
   left: 97.76,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Text Field 146", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 648.8,
   left: 135.792,
   width: 34.560,
   height: 12.24
}
,
{
   page: 3,
   name: "Check Box 56", 
   isText: false,
   type: "checkbox",
   top: 661.907,
   left: 97.76,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Text Field 147", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 658.53,
   left: 136.224,
   width: 34.128,
   height: 12.24
}
,
{
   page: 3,
   name: "Check Box 57", 
   isText: false,
   type: "checkbox",
   top: 671.267,
   left: 97.76,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Text Field 148", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 667.935,
   left: 136.224,
   width: 34.128,
   height: 12.240
}
,
{
   page: 3,
   name: "Check Box 61", 
   isText: false,
   type: "checkbox",
   top: 691.13,
   left: 97.56,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 62", 
   isText: false,
   type: "checkbox",
   top: 691.13,
   left: 124.2,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 82", 
   isText: false,
   type: "checkbox",
   top: 690.184,
   left: 540.672,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 58", 
   isText: false,
   type: "checkbox",
   top: 700.49,
   left: 76.76,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 64", 
   isText: false,
   type: "checkbox",
   top: 709.49,
   left: 97.488,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Check Box 65", 
   isText: false,
   type: "checkbox",
   top: 709.49,
   left: 135.288,
   width: 6.264,
   height: 6.336
}
,
{
   page: 3,
   name: "Text Field 142", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 705.88,
   left: 214.12,
   width: 34.56,
   height: 12.24
}
,
{
   page: 4,
   name: "Text Field 305", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 27.952,
   left: 155.349,
   width: 416.151,
   height: 12.240
}
,
{
   page: 4,
   name: "Check Box 112", 
   isText: false,
   type: "checkbox",
   top: 59.04,
   left: 93.76,
   width: 6.264,
   height: 6.336
}
,
{
   page: 4,
   name: "Check Box 113", 
   isText: false,
   type: "checkbox",
   top: 71.144,
   left: 93.76,
   width: 6.264,
   height: 6.336
}
,
{
   page: 4,
   name: "Text Field 386", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 67.24,
   left: 136.8,
   width: 434.7,
   height: 12.24
}
,
{
   page: 4,
   name: "Text Field 434", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 80.48,
   left: 305.68,
   width: 26.64,
   height: 12.24
}
,
{
   page: 4,
   name: "Text Field 385", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 185.51,
   left: 172.8,
   width: 26.64,
   height: 12.24
}
,
{
   page: 4,
   name: "Text Field 361", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.16,
   left: 537.12,
   width: 34.56,
   height: 12.24
}
,
{
   page: 4,
   name: "Text Field 229", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 296.72,
   left: 480.744,
   width: 76.248,
   height: 12.24
}
,
{
   page: 4,
   name: "Text Field 228", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 309.068,
   left: 89.28,
   width: 467.784,
   height: 12.240
}
,
{
   page: 4,
   name: "Text Field 153", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 598.4,
   left: 139.592,
   width: 35.514,
   height: 12.24
}
,
{
   page: 5,
   name: "Text Field 306", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 27.952,
   left: 155.349,
   width: 416.151,
   height: 12.240
}
,
{
   page: 5,
   name: "Check Box 114", 
   isText: false,
   type: "checkbox",
   top: 165.8,
   left: 154.56,
   width: 6.264,
   height: 6.336
}
,
{
   page: 5,
   name: "Check Box 115", 
   isText: false,
   type: "checkbox",
   top: 165.8,
   left: 178.23,
   width: 6.264,
   height: 6.336
}
,
{
   page: 5,
   name: "Text Field 387", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 198.496,
   left: 215.48,
   width: 31.77,
   height: 12.240
}
,
{
   page: 5,
   name: "Text Field 366", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 235.332,
   left: 152.4,
   width: 90.72,
   height: 12.240
}
,
{
   page: 5,
   name: "Text Field 367", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 261.992,
   left: 387.36,
   width: 31.77,
   height: 12.240
}
,
{
   page: 5,
   name: "Text Field 365", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 297.562,
   left: 372.52,
   width: 90.72,
   height: 12.240
}
,
{
   page: 5,
   name: "Text Field 232", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 371.112,
   left: 286.992,
   width: 31.824,
   height: 12.240
}
,
{
   page: 5,
   name: "Check Box 68", 
   isText: false,
   type: "checkbox",
   top: 490.56,
   left: 76.224,
   width: 6.264,
   height: 6.336
}
,
{
   page: 5,
   name: "Check Box 69", 
   isText: false,
   type: "checkbox",
   top: 500.776,
   left: 76.224,
   width: 6.264,
   height: 6.336
}
,
{
   page: 5,
   name: "Check Box 70", 
   isText: false,
   type: "checkbox",
   top: 509.992,
   left: 76.224,
   width: 6.264,
   height: 6.336
}
,
{
   page: 5,
   name: "Check Box 71", 
   isText: false,
   type: "checkbox",
   top: 520.064,
   left: 76.224,
   width: 6.264,
   height: 6.336
}
,
{
   page: 5,
   name: "Check Box 72", 
   isText: false,
   type: "checkbox",
   top: 530.28,
   left: 76.224,
   width: 6.264,
   height: 6.336
}
,
{
   page: 5,
   name: "Check Box 73", 
   isText: false,
   type: "checkbox",
   top: 542.496,
   left: 76.125,
   width: 6.264,
   height: 6.336
}
,
{
   page: 5,
   name: "Text Field 157", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 538.544,
   left: 134.68,
   width: 374.40,
   height: 12.240
}
,
{
   page: 5,
   name: "Text Field 158", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 556.264,
   left: 493.22,
   width: 71.98,
   height: 12.240
}
,
{
   page: 5,
   name: "Text Field 159", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 569.504,
   left: 63.0,
   width: 502.2,
   height: 12.240
}
,
{
   page: 5,
   name: "Check Box 74", 
   isText: false,
   type: "checkbox",
   top: 630.848,
   left: 76.76,
   width: 6.264,
   height: 6.336
}
,
{
   page: 6,
   name: "Text Field 3010", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 27.952,
   left: 155.349,
   width: 416.151,
   height: 12.240
}
,
{
   page: 6,
   name: "Text Field 2012", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 81.92,
   left: 152.92,
   width: 36.00,
   height: 12.24
}
,
{
   page: 6,
   name: "Text Field 2013", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 120.379,
   left: 176.512,
   width: 71.280,
   height: 12.240
}
,
{
   page: 6,
   name: "Text Field 390", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 182.333,
   left: 218.906,
   width: 148.294,
   height: 12.241
}
,
{
   page: 6,
   name: "Text Field 388", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 205.84,
   left: 231.384,
   width: 66.816,
   height: 12.24
}
,
{
   page: 6,
   name: "Text Field 389", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 205.84,
   left: 314.5,
   width: 49.248,
   height: 12.24
}
,
{
   page: 6,
   name: "Text Field 2011", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 236.288,
   left: 380.24,
   width: 36.00,
   height: 12.240
}
,
{
   page: 6,
   name: "Check Box 95", 
   isText: false,
   type: "checkbox",
   top: 376.134,
   left: 111.88,
   width: 6.264,
   height: 6.336
}
,
{
   page: 6,
   name: "Check Box 96", 
   isText: false,
   type: "checkbox",
   top: 376.08,
   left: 215.152,
   width: 6.264,
   height: 6.336
}
,
{
   page: 6,
   name: "Text Field 165", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.384,
   left: 158.157,
   width: 400.608,
   height: 12.240
}
,
{
   page: 6,
   name: "Text Field 166", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 439.128,
   left: 62.64,
   width: 498.501,
   height: 12.240
}
,
{
   page: 6,
   name: "Text Field 167", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 451.12,
   left: 62.64,
   width: 498.501,
   height: 12.241
}
,
{
   page: 6,
   name: "Text Field 169", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 529.883,
   left: 281.565,
   width: 71.280,
   height: 12.240
}
,
{
   page: 7,
   name: "Text Field 310", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 27.952,
   left: 155.349,
   width: 416.151,
   height: 12.240
}
,
{
   page: 8,
   name: "Text Field 311", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 27.952,
   left: 155.349,
   width: 416.151,
   height: 12.240
}
,
{
   page: 8,
   name: "Check Box 118", 
   isText: false,
   type: "checkbox",
   top: 123.64,
   left: 63.368,
   width: 6.192,
   height: 6.264
}
,
{
   page: 8,
   name: "Check Box 119", 
   isText: false,
   type: "checkbox",
   top: 163.536,
   left: 63.368,
   width: 6.192,
   height: 6.264
}
,
{
   page: 8,
   name: "Text Field 413", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 249.488,
   left: 124.56,
   width: 172.80,
   height: 12.240
}
,
{
   page: 8,
   name: "Text Field 414", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 269.648,
   left: 63.0,
   width: 229.5,
   height: 12.240
}
,
{
   page: 8,
   name: "Text Field 415", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 269.648,
   left: 327.6,
   width: 241.56,
   height: 12.240
}
,
{
   page: 8,
   name: "Text Field 4010", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 331.44,
   left: 63.0,
   width: 229.5,
   height: 12.24
}
,
{
   page: 8,
   name: "Text Field 418", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 331.44,
   left: 327.6,
   width: 241.56,
   height: 12.24
}
,
{
   page: 8,
   name: "Text Field 417", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 393.764,
   left: 63.0,
   width: 229.5,
   height: 12.240
}
,
{
   page: 8,
   name: "Text Field 419", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 393.764,
   left: 327.6,
   width: 241.56,
   height: 12.240
}
,
{
   page: 8,
   name: "Date Text Field 408", 
   fontSize: 9,
   type: "date",
   top: 454.08,
   left: 354.96,
   width: 101.16,
   height: 12.24
}
,
{
   page: 8,
   name: "Text Field 409", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 454.08,
   left: 470.34,
   width: 101.16,
   height: 12.24
}
,
{
   page: 8,
   name: "Text Field 407", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 512.92,
   left: 124.2,
   width: 172.8,
   height: 12.24
}
,
{
   page: 8,
   name: "Text Field 403", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 532.56,
   left: 62.64,
   width: 234.72,
   height: 12.24
}
,
{
   page: 8,
   name: "Text Field 406", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 532.56,
   left: 327.24,
   width: 244.26,
   height: 12.24
}
,
{
   page: 8,
   name: "Text Field 401", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 593.64,
   left: 62.64,
   width: 234.72,
   height: 12.24
}
,
{
   page: 8,
   name: "Text Field 404", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 593.64,
   left: 327.24,
   width: 244.26,
   height: 12.24
}
,
{
   page: 8,
   name: "Text Field 402", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 656.68,
   left: 62.64,
   width: 234.72,
   height: 12.24
}
,
{
   page: 8,
   name: "Text Field 405", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 656.68,
   left: 327.24,
   width: 244.26,
   height: 12.24
}
,
{
   page: 9,
   name: "Text Field 420", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 26.952,
   left: 155.349,
   width: 416.151,
   height: 12.240
}
,
{
   page: 9,
   name: "Text Field 393", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 76.88,
   left: 37.4643,
   width: 246.7297,
   height: 13.50
}
,
{
   page: 9,
   name: "Text Field 394", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 76.88,
   left: 326.601,
   width: 244.331,
   height: 13.50
}
,
{
   page: 9,
   name: "Text Field 395", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 107.976,
   left: 38.0298,
   width: 246.7302,
   height: 13.500
}
,
{
   page: 9,
   name: "Text Field 396", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 107.976,
   left: 327.168,
   width: 244.332,
   height: 13.500
}
,
{
   page: 9,
   name: "Text Field 391", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 138.492,
   left: 37.6818,
   width: 246.7302,
   height: 13.500
}
,
{
   page: 9,
   name: "Text Field 392", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 138.492,
   left: 326.819,
   width: 244.332,
   height: 13.500
}
,
{
   page: 9,
   name: "Text Field 397", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 169.008,
   left: 37.6818,
   width: 246.7302,
   height: 13.500
}
,
{
   page: 9,
   name: "Text Field 398", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 169.008,
   left: 326.819,
   width: 244.332,
   height: 13.500
}
,
{
   page: 9,
   name: "Text Field 301", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 200.524,
   left: 38.0298,
   width: 246.7302,
   height: 13.500
}
,
{
   page: 9,
   name: "Text Field 302", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 200.524,
   left: 327.168,
   width: 244.332,
   height: 13.500
}
,
{
   page: 9,
   name: "Text Field 3012", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 232.04,
   left: 40.5,
   width: 164.361,
   height: 13.50
}
,
{
   page: 9,
   name: "Date Text Field 208", 
   fontSize: 9,
   type: "date",
   top: 232.04,
   left: 204.861,
   width: 79.899,
   height: 13.50
}
,
{
   page: 9,
   name: "Text Field 3011", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 232.04,
   left: 327.24,
   width: 164.361,
   height: 13.50
}
,
{
   page: 9,
   name: "Date Text Field 207", 
   fontSize: 9,
   type: "date",
   top: 232.04,
   left: 491.601,
   width: 79.899,
   height: 13.50
}
] }
