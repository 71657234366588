export function brokerageFirmCompensationAgreement() {
return [   //2024 Release 2024-07-29 11:54:04
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 133.922,
   left: 215.822,
   width: 137.218,
   height: 13.798
}
,
{
   page: 0,
   name: "SellerBrokerName", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 159.791,
   left: 216.178,
   width: 300.251,
   height: 14.511
}
,
{
   page: 0,
   name: "Seller Brokerage Firm 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 187.74,
   left: 216,
   width: 299.537,
   height: 13.62
}
,
{
   page: 0,
   name: "Buyer Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 213.372,
   left: 216.178,
   width: 300.607,
   height: 14.868
}
,
{
   page: 0,
   name: "Buyer Brokerage Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 240.253,
   left: 216,
   width: 300.429,
   height: 14.867
}
,
{
   page: 0,
   name: "Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 267.116,
   left: 216,
   width: 300.785,
   height: 15.046
}
,
{
   page: 0,
   name: "of the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 464.637,
   left: 450,
   width: 52.074,
   height: 11.756
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 477.833,
   left: 358.982,
   width: 52.320,
   height: 11.287
}
,
{
   page: 0,
   name: "However Buyer Broker must", 
   isText: false,
   type: "checkbox",
   top: 466.488,
   left: 433.284,
   width: 10.470,
   height: 9.984
}
,
{
   page: 0,
   name: "tender the Real Estate Contract and Buyer Brokerage Firm must continue to work with Buyer through Closing", 
   isText: false,
   type: "checkbox",
   top: 479.49,
   left: 337.842,
   width: 11.118,
   height: 9.984
}
] }
