export function nativeAmericanHomeLoan() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 96.76,
   left: 201.6,
   width: 374.4,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 189.0,
   left: 53.0,
   width: 91.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 292.32,
   left: 460.08,
   width: 43.20,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 578.88,
   left: 282.4,
   width: 113.6,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 220.5,
   left: 513.0,
   width: 60.12,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 305.4,
   left: 177.12,
   width: 396.00,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 316.9,
   left: 36.0,
   width: 537.12,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 328.4,
   left: 36.0,
   width: 537.12,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 340.25,
   left: 36.0,
   width: 537.12,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 36", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 12,
   top: 643.08,
   left: 36.0,
   width: 145.44,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 19", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 643.08,
   left: 216.0,
   width: 72.72,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 35", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 12,
   top: 643.08,
   left: 322.7,
   width: 145.44,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 20", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 643.08,
   left: 500.4,
   width: 72.72,
   height: 13.50
}
] }
