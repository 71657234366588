export function residentialAddendumtoContract2024() {
    return [   //2024 Release 2023-12-18 13:17:14
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 192.96,
       left: 405.48,
       width: 154.92,
       height: 12.84
    }
    ,
    {
       page: 0,
       name: "part of the following contract that is checked", 
       isText: false,
       type: "checkbox",
       top: 248.28,
       left: 86.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Contract to Buy and Sell Real Estate Land between Seller and Buyer Contract dated", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 245.28,
       left: 466.56,
       width: 105.00,
       height: 12.84
    }
    ,
    {
       page: 0,
       name: "undefined", 
       isText: false,
       type: "checkbox",
       top: 289.56,
       left: 86.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Contract to Buy and Sell Real Estate Commercial between Seller and Buyer Contract dated", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 286.56,
       left: 496.56,
       width: 80.04,
       height: 12.84
    }
    ,
    {
       page: 0,
       name: "known as", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 314.16,
       left: 112.56,
       width: 410.04,
       height: 12.84
    }
    ,
    {
       page: 0,
       name: "Conventional", 
       isText: false,
       type: "checkbox",
       top: 427.08,
       left: 99.96,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "FHA", 
       isText: false,
       type: "checkbox",
       top: 427.08,
       left: 181.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "VA", 
       isText: false,
       type: "checkbox",
       top: 427.08,
       left: 226.2,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Bond", 
       isText: false,
       type: "checkbox",
       top: 427.08,
       left: 264.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "If either or both of the FHA or VA boxes are checked and Buyer closes the transaction using one of those loan types", 
       isText: false,
       type: "checkbox",
       top: 427.08,
       left: 311.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Other", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 424.08,
       left: 355.44,
       width: 215.04,
       height: 12.84
    }
    ,
    {
       page: 0,
       name: "Seller agrees to pay those closing costs and fees that Buyer is not allowed by law to pay not to exceed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 451.68,
       left: 513.96,
       width: 60.00,
       height: 12.84
    }
    ,
    {
       page: 0,
       name: "Direct Endorsement lender setting forth the appraised value of the Property of not less than", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 579.84,
       left: 473.52,
       width: 90.00,
       height: 12.84
    }
    ,
    {
       page: 1,
       name: "undefined_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 194.88,
       left: 90.24,
       width: 469.92,
       height: 12.84
    }
    ,
    {
       page: 1,
       name: "Seller", 
       isText: false,
       type: "checkbox",
       top: 215.88,
       left: 278.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Buyer for the Property listing any special", 
       isText: false,
       type: "checkbox",
       top: 215.88,
       left: 327.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Does", 
       isText: false,
       type: "checkbox",
       top: 371.64,
       left: 474.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Does Not", 
       isText: false,
       type: "checkbox",
       top: 371.64,
       left: 519,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "There is No Well Buyer", 
       isText: false,
       type: "checkbox",
       top: 399,
       left: 220.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Does_2", 
       isText: false,
       type: "checkbox",
       top: 399,
       left: 343.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Does Not acknowledge receipt of a copy of", 
       isText: false,
       type: "checkbox",
       top: 399,
       left: 388.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Buyer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 488.16,
       left: 79.32,
       width: 390.00,
       height: 14.771
    }
    ,
    {
       page: 2,
       name: "Buyer_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 548.4,
       left: 79.32,
       width: 390.00,
       height: 14.771
    }
    ,
    {
       page: 2,
       name: "Seller_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 608.88,
       left: 77.64,
       width: 390.00,
       height: 14.771
    }
    ,
    {
       page: 2,
       name: "Seller_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 669.12,
       left: 77.64,
       width: 390.00,
       height: 14.771
    }
    ] }
    