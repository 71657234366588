import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  Header,
  Menu,
  Segment,
} from "semantic-ui-react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  convertFullName,
  convertInitials,
} from "../../../../app/common/util/util";
import ModalWrapper from "../../../../app/common/modals/modalWrapper";
import { updateAnnotSignedInDb } from "../../../../app/firestore/firestoreService";
import {
  autoGoToNextAnnot,
  changeSelectedSigner,
} from "../../../../app/annots/annotSlice";
import { closeModal } from "../../../../app/common/modals/modalSlice";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { updateFirstAnnotSigned } from "../partyDocSignUtils";

export default function AnnotSignatureModal({ ipAddress }) {
  const dispatch = useDispatch();
  const { selectedAnnot, selectedSigner } = useSelector((state) => state.annot);
  const { doc } = useSelector((state) => state.doc);
  const { party } = useSelector((state) => state.party);
  const [signedFullName, setSignedFullName] = useState("");
  const [signedFontFamily, setSignedFontFamily] = useState(
    "brush script mt, cursive"
  );

  let initialValues = {
    signedFullName:
      selectedSigner.signedFullName || convertFullName(selectedSigner),
    signedInitials:
      selectedSigner.signedInitials || convertInitials(selectedSigner),
    signedFontFamily:
      selectedSigner.signedFontFamily || "brush script mt, cursive",
  };

  function handleFontFamilyClick(fontName) {
    setSignedFontFamily(fontName);
  }

  function Watcher() {
    const { values } = useFormikContext();
    useEffect(() => {
      values.signedFullName && setSignedFullName(values.signedFullName);
    }, [values.signedFullName]);
    return null;
  }

  const validationSchema = Yup.object({
    signedFullName: Yup.string().required("Name is required"),
    signedInitials: Yup.string().required("Initials are required"),
  });

  return (
    <ModalWrapper size="tiny">
      <Segment>
        <Grid>
          <Grid.Column width="16">
            <Header size="huge" color="blue">
              Confirm Signing Details
            </Header>
            <Divider />
            <p>Please confirm your full name, initials, and signature style.</p>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  values.signedFontFamily = signedFontFamily;
                  values.signingDetailsConfirmed = true;
                  dispatch(autoGoToNextAnnot(true));
                  if (doc.pdfBurnVersion) {
                    updateFirstAnnotSigned(
                      doc,
                      selectedAnnot,
                      signedFontFamily,
                      values,
                      party,
                      ipAddress
                    );
                  } else {
                    updateAnnotSignedInDb(selectedAnnot.id, doc.id, values);
                  }
                  const newSelectedSigner = { ...selectedSigner, ...values };
                  dispatch(changeSelectedSigner(newSelectedSigner));
                  setSubmitting(false);
                  dispatch(
                    closeModal({
                      modalType: "AnnotSignatureModal",
                    })
                  );
                } catch (error) {
                  toast.error(error.message);
                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting, dirty, isValid, values }) => (
                <Form className="ui form">
                  <Watcher />
                  <Grid>
                    <Grid.Column width={16}>
                      <p className="mini margin bottom">Full Name</p>
                      <MyTextInput
                        fluid="true"
                        className="tiny bottom margin"
                        placeholder="Full name"
                        name="signedFullName"
                      />
                      <p className="mini margin bottom">Initials</p>
                      <MyTextInput
                        fluid="true"
                        className="tiny bottom margin"
                        placeholder="Initials"
                        name="signedInitials"
                      />

                      <div className="large bottom margin">
                        <p className="small top margin mini bottom margin">
                          Signature Style
                        </p>
                        <Menu
                          vertical
                          fluid
                          style={{ backgroundColor: "#F7F7F7 !important" }}
                        >
                          <Menu.Item
                            name="brushScriptMt"
                            active={
                              signedFontFamily === "brush script mt, cursive"
                            }
                            onClick={() =>
                              handleFontFamilyClick("brush script mt, cursive")
                            }
                            style={{
                              fontFamily: "brush script mt, cursive",
                              wordBreak: "break-word",
                            }}
                          >
                            <p className="text-large">
                              <span className="small right margin">
                                {signedFullName}
                              </span>{" "}
                            </p>
                          </Menu.Item>
                          <Menu.Item
                            name="Parisienne"
                            active={signedFontFamily === "Parisienne"}
                            onClick={() => handleFontFamilyClick("Parisienne")}
                            style={{
                              fontFamily: "Parisienne",
                              wordBreak: "break-word",
                            }}
                          >
                            <p className="text-large">
                              <span className="small right margin">
                                {signedFullName}
                              </span>{" "}
                            </p>
                          </Menu.Item>
                          <Menu.Item
                            name="LaBelleAurore"
                            active={signedFontFamily === "LaBelleAurore"}
                            onClick={() =>
                              handleFontFamilyClick("LaBelleAurore")
                            }
                            style={{
                              fontFamily: "LaBelleAurore",
                              wordBreak: "break-word",
                            }}
                          >
                            <p className="text-large">
                              <span className="small right margin">
                                {signedFullName}
                              </span>{" "}
                            </p>
                          </Menu.Item>
                        </Menu>
                      </div>
                    </Grid.Column>
                  </Grid>
                  <Divider />
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type="submit"
                    floated="right"
                    primary
                    content="Submit"
                  />
                </Form>
              )}
            </Formik>
          </Grid.Column>
        </Grid>
      </Segment>
    </ModalWrapper>
  );
}
