import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Table, Input } from "semantic-ui-react";
import { searchFilter } from "../../app/common/util/util";
import { Link } from "react-router-dom";
import { sortAgentsForManagerAll } from "../profiles/profileSlice";
import ManagerAgentsListItem from "./ManagerAgentsListItem";

export default function ManagerAgentsAllOverview() {
  const dispatch = useDispatch();
  const { agentsForManagerAll } = useSelector((state) => state.profile);
  const [searchTerms, setSearchTerms] = useState("");
  const agents = searchFilter(agentsForManagerAll?.agents, searchTerms);

  function handleSort(column) {
    dispatch(sortAgentsForManagerAll(column));
  }

  const column = agentsForManagerAll?.column;
  const direction = agentsForManagerAll?.direction;

  return (
    <div className="main-page-wrapper">
      <>
        <Grid stackable className="small bottom margin">
          <Grid.Column computer={16} className="small bottom margin">
            <h3
              className="zero bottom margin"
              style={{ position: "absolute", bottom: "0" }}
            >
              Manager Agent List
            </h3>
          </Grid.Column>
          <Grid.Column computer={8}>
            <Input
              type="text"
              fluid
              placeholder="Search by name"
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
            ></Input>
          </Grid.Column>
          <Grid.Column computer={3} tablet={4}>
            <Button.Group fluid size="small">
              <Button as={Link} to={`/overviewManager/`}>
                Active
              </Button>
              <Button active as={Link} to="">
                All
              </Button>
            </Button.Group>
          </Grid.Column>
          <Grid.Column computer={16} className="small bottom margin">
            <Table compact sortable className="mini top margin">
              <Table.Header className="mobile hidden">
                <Table.Row className="small-header">
                  <Table.HeaderCell
                    sorted={column === "title" ? direction : null}
                    onClick={() => handleSort("First Name")}
                  >
                    First Name
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ cursor: "default" }}>
                    Last Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === "updatedAt" ? direction : null}
                    onClick={() => handleSort("updatedAt")}
                  >
                    Email
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === "updatedAt" ? direction : null}
                    onClick={() => handleSort("updatedAt")}
                  >
                    Phone
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {agents &&
                  agents.length !== 0 &&
                  agents.map((agent) => (
                    <ManagerAgentsListItem agent={agent} key={agent.id} />
                  ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid>
      </>
    </div>
  );
}
