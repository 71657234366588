import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { addDays, isSameDay } from "date-fns";
import { TcCalendar } from "./TcCalendar";

export default function TcOverview() {
  const { transActive } = useSelector((state) => state.transaction);
  const { tasksAllWithDateUpcoming } = useSelector((state) => state.task);
  const [tasksForDisplay, setTasksForDisplay] = useState([{}, {}, {}, {}]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tasksAllWithDateUpcoming.length > 0) {
      let tasksByDay = [];
      [0, 1, 2, 3].forEach((day) => {
        tasksByDay[day] = tasksAllWithDateUpcoming.filter((task) =>
          isSameDay(task.end, addDays(new Date(), day - 1))
        );
      });
      let dayDisplay = [{}, {}, {}, {}];
      tasksByDay.forEach((day, index) => {
        const dayIndex = index;
        day.forEach((task) => {
          if (dayDisplay[dayIndex]?.[task.transactionId]?.["tasks"]) {
            dayDisplay[dayIndex][task.transactionId]["tasks"].push(task);
          } else {
            const transaction = transActive.filter(
              (trans) => trans.id === task.transactionId
            )[0];
            dayDisplay[dayIndex][task.transactionId] = {
              address: transaction?.address?.street || "",
              agentName: transaction?.agentName,
              agentRepresents: transaction?.agentRepresents,
              title: transaction?.title,
              transactionId: task.transactionId,
              closingDateTime: transaction?.closingDateTime,
              tasks: [task],
            };
          }
        });
      });
      setTasksForDisplay(dayDisplay);
    }
    setLoading(true);
  }, [tasksAllWithDateUpcoming, transActive]);

  if (!loading) return <LoadingComponent content="Loading details..." />;

  return (
    <div className="main-page-wrapper">
      <Grid className="medium bottom margin">
        <Grid.Column computer={16}>
          <h3 style={{ marginBottom: "36px" }}>Calendar</h3>
          <div className="small horizontal padding">
            <TcCalendar tasksByDay={tasksForDisplay} />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}
