import React, { forwardRef, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Header,
  Segment,
  Table,
  Icon,
  Popup,
} from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  addTasksFromDocToDb,
  getFormTasksFromDb,
} from "../../../app/firestore/firestoreService";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { functionGetDeadlinesFromPdf } from "../../../app/firestore/functionsService";
import { extractPdfDeadlinesFromTable } from "../../../app/common/util/util";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default function DocExtractDeadlines({ doc }) {
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState();
  const { tasksTransAll } = useSelector((state) => state.task);
  const { transaction } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [extractingDeadlines, setExtractingDeadlines] = useState(true);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  useEffect(() => {
    async function extractDeadlinesFromUploadedPdf() {
      const deadlineTableArray = await functionGetDeadlinesFromPdf(doc.docRef);
      const deadlines = extractPdfDeadlinesFromTable(deadlineTableArray);
      let newTasks = [];
      const assignedTo =
        transaction.agentRepresents === "Buyer"
          ? "Buyer Agent"
          : "Listing Agent";
      Object.entries(deadlines).forEach(([key, value]) => {
        let timestamp = new Date(value);
        const newTask = {
          uniqueId: key,
          end: timestamp,
          title: key,
          assignedTo: assignedTo,
          docId: doc.id,
          docTitle: doc.title,
          category: "Deadline",
          transactionId: transaction.id,
          transactionTitle: transaction.title,
          status: "Active",
          active: true,
        };
        newTasks = [...newTasks, newTask];
      });
      newTasks = _.sortBy(newTasks, "end");
      newTasks.forEach((task, index) => {
        task.uniqueId = index;
      });
      setTasks(newTasks);
      setExtractingDeadlines(false);
    }
    function extractDeadlinesFromUploadedForm() {
      let newTasks = [];
      let formFieldValues = doc.formFieldValues;
      if (!doc.pdfBurnVersion) {
        formFieldValues = doc.formFieldValuesForCopy;
      }
      const assignedTo =
        transaction.agentRepresents === "Buyer"
          ? "Buyer Agent"
          : "Listing Agent";
      getFormTasksFromDb(doc.formId, transaction).then((tasks) => {
        tasks.forEach((task, index) => {
          let timestamp = null;
          if (formFieldValues && formFieldValues[task.pdfFieldName]) {
            timestamp = formFieldValues[task.pdfFieldName].toDate();
          } else if (formFieldValues && formFieldValues[task.pdfFieldName]) {
            timestamp = formFieldValues[task.pdfFieldName].toDate();
          }
          if (timestamp) {
            const newTask = {
              uniqueId: index,
              end: timestamp,
              title: task.title,
              assignedTo: assignedTo,
              docId: doc.id,
              docTitle: doc.title,
              category: "Deadline",
              transactionId: transaction.id,
              transactionTitle: transaction.title,
              status: "Active",
              active: true,
            };
            newTasks = [...newTasks, newTask];
          }
        });
        newTasks = _.sortBy(newTasks, "end");
        newTasks.forEach((task, index) => {
          task.uniqueId = index;
        });
        setTasks(newTasks);
        setExtractingDeadlines(false);
      });
    }
    if (doc.formId) {
      extractDeadlinesFromUploadedForm();
    } else {
      extractDeadlinesFromUploadedPdf();
    }
  }, [doc, currentUserProfile.id, transaction]);

  function handleToggleTask(taskUniqueId) {
    const index = tasks.findIndex((task) => task.uniqueId === taskUniqueId);
    let taskToUpdate = tasks[index];
    taskToUpdate["active"] = !taskToUpdate["active"];
    setTasks([
      ...tasks.filter((task) => task.uniqueId !== taskUniqueId),
      taskToUpdate,
    ]);
  }

  function changeTaskDate(date, taskUniqueId) {
    const index = tasks.findIndex((task) => task.uniqueId === taskUniqueId);
    let taskToUpdate = tasks[index];
    taskToUpdate["end"] = date;
    setTasks([
      ...tasks.filter((task) => task.uniqueId !== taskUniqueId),
      taskToUpdate,
    ]);
  }

  async function handleDone() {
    const tasksToAdd = tasks.filter((task) => task.active === true);
    if (tasksToAdd.length > 0) {
      try {
        await addTasksFromDocToDb(
          tasksToAdd,
          tasksTransAll?.tasks,
          transaction
        );
        toast.success("Deadlines successfully added");
        dispatch(
          closeModal({
            modalType: "DocExtractDeadlinesFromPdf",
          })
        );
      } catch (error) {
        toast.error(error.message);
        throw error;
      }
    } else {
      dispatch(
        closeModal({
          modalType: "DocExtractDeadlinesFromPdf",
        })
      );
    }
  }

  const ExtractedDateInput = forwardRef(({ value, onClick }, ref) => (
    <Button primary onClick={onClick} ref={ref} icon labelPosition="right">
      {value}
      <Icon name="edit" />
    </Button>
  ));

  if (extractingDeadlines) {
    return <LoadingComponent content="Extracting deadlines from PDF..." />;
  }

  return (
    <ModalWrapper style={{ backgroundColor: "#f9fafb" }}>
      <Segment clearing style={{ backgroundColor: "#f9fafb" }}>
        <Header color="blue" size="large">
          {tasks?.length > 0
            ? "Add Deadlines to Transaction"
            : "No Deadlines Found"}
        </Header>
        <Divider />{" "}
        {tasks && tasks.length > 0 ? (
          <>
            <span>
              You can edit the deadline dates and remove any unwanted deadlines.
              Deadlines will change existing deadlines automatically. Check the
              "Deadlines" section to share with parties.
              <br />
              <br />
              <span className="text blue">
                Note: If you are extracting from an uploaded document, be sure
                to check and verify all extracted dates.
              </span>
            </span>{" "}
            <Table compact>
              <Table.Header className="mobile hidden">
                <Table.Row className="small-header-grey">
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>
                    Date {"  "}
                    <Popup
                      flowing
                      size="large"
                      trigger={
                        <Icon
                          name="info"
                          color="blue"
                          circular
                          inverted
                          size="small"
                          style={{ marginLeft: "3px", marginBottom: "3px" }}
                        />
                      }
                    >
                      <p className="bold text blue mini bottom margin">
                        Date Extraction
                      </p>
                      {tasks?.length !== 0 && (
                        <p className="text-dark text-normal mini bottom margin">
                          You can select on the date below to change it.
                        </p>
                      )}
                    </Popup>
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {tasks?.length !== 0 &&
                  _.sortBy(tasks, "uniqueId").map((task) => (
                    <Table.Row key={task.uniqueId}>
                      <Table.Cell>{task.title}</Table.Cell>
                      <Table.Cell>
                        <DatePicker
                          showIcon
                          selected={task.end}
                          onChange={(date) =>
                            changeTaskDate(date, task.uniqueId)
                          }
                          customInput={<ExtractedDateInput />}
                          dateFormat="MMMM d, yyyy"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          floated={isMobile ? null : "right"}
                          color={task.active ? "grey" : "blue"}
                          onClick={() => handleToggleTask(task.uniqueId)}
                          content={task.active ? "Remove" : "Add"}
                          style={{ cursor: "pointer" }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            <Button
              primary
              floated={isMobile ? null : "right"}
              content="Add/Change Deadlines"
              onClick={() => handleDone()}
              className={isMobile ? "fluid" : null}
            ></Button>
          </>
        ) : (
          <p>There are no deadline to extract in this document.</p>
        )}
      </Segment>
    </ModalWrapper>
  );
}
