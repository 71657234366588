export function terminationAgreementBuyerDC523() {
return [   //2024 Release 2024-09-17 18:40:36
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "THIS FORM HAS NOT BEEN APPROVED BY THE COLORADO REAL ESTATE COMMISSION IT WAS PREPARED BY", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 49.92,
   left: 375.6,
   width: 101.52,
   height: 13.56
}
,
{
   page: 0,
   name: "DATE", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 131.04,
   left: 216.0,
   width: 186.24,
   height: 13.44
}
,
{
   page: 0,
   name: "BUYER LISTING CONTRACT TERMINATION AGREEMENT 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 154.32,
   left: 216.0,
   width: 323.16,
   height: 13.44
}
,
{
   page: 0,
   name: "BUYER LISTING CONTRACT TERMINATION AGREEMENT 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 177.6,
   left: 216.0,
   width: 323.16,
   height: 13.2
}
,
{
   page: 0,
   name: "BUYER LISTING CONTRACT TERMINATION AGREEMENT 4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 200.64,
   left: 216.0,
   width: 323.16,
   height: 13.44
}
,
{
   page: 0,
   name: "Buyer Listing Contract Date 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 223.92,
   left: 216.0,
   width: 323.16,
   height: 13.20
}
,
{
   page: 0,
   name: "Buyer Listing Contract Date 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 246.96,
   left: 216.0,
   width: 323.16,
   height: 13.44
}
,
{
   page: 0,
   name: "signature BROKERAGE FIRM AND BROKER", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 594.16,
   left: 324.0,
   width: 208.2,
   height: 15.44
}
,
{
   page: 0,
   name: "signature CoBroker", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 647.92,
   left: 324.0,
   width: 208.2,
   height: 15.44
}
,
{
   page: 0,
   name: "use by", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 730.3637,
   left: 426.48,
   width: 98.64,
   height: 13.2763
}
] }
