export function cBCExclusiveRighttoBuy2023() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      type: "logo",
      top: 28.673,
      left: 53,
      width: 253,
      height: 43.2
   }
   ,
   {
      page: 0,
      name: "BUYER AGENCY", 
      isText: false,
      type: "checkbox",
      top: 239.64,
      left: 158.28,
      width: 12.96,
      height: 11.28
   }
   ,
   {
      page: 0,
      name: "TRANSACTIONBROKERAGE", 
      isText: false,
      type: "checkbox",
      top: 239.64,
      left: 302.04,
      width: 12.96,
      height: 11.28
   }
   ,
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 259.009,
      left: 447.36,
      width: 103.80,
      height: 12.911
   }
   ,
   {
      page: 0,
      name: "MultiplePerson Firm If this box", 
      isText: false,
      type: "checkbox",
      top: 336.84,
      left: 90.36,
      width: 11.04,
      height: 9.84
   }
   ,
   {
      page: 0,
      name: "OnePerson Firm If this box", 
      isText: false,
      type: "checkbox",
      top: 413.88,
      left: 90.36,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 0,
      name: "Buyer", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 479.048,
      left: 130.88,
      width: 435.72,
      height: 13.432
   }
   ,
   {
      page: 0,
      name: "Brokerage Firm", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 496.68,
      left: 174.24,
      width: 393.24,
      height: 13.432
   }
   ,
   {
      page: 0,
      name: "Broker", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 514.44,
      left: 132.72,
      width: 434.76,
      height: 13.432
   }
   ,
   {
      page: 0,
      name: "ta_property", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 558.888,
      left: 77.0232,
      width: 494.1878,
      height: 52.529
   }
   ,
   {
      page: 0,
      name: "If this box is checked Buyer authorizes Broker to negotiate a", 
      isText: false,
      type: "checkbox",
      top: 682.44,
      left: 112.92,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 0,
      name: "date_listingBegins", 
      fontSize: 9,
      type: "date",
      top: 721.3316,
      left: 426.72,
      width: 116.76,
      height: 11.8684
   }
   ,
   {
      page: 1,
      name: "date_endListingPeriod", 
      fontSize: 9,
      type: "date",
      top: 34.681,
      left: 95.88,
      width: 105.72,
      height: 12.119
   }
   ,
   {
      page: 1,
      name: "s not a Saturday Sunday or Holiday Should neither box be checked the", 
      isText: false,
      type: "checkbox",
      top: 189.24,
      left: 460.44,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 1,
      name: "ncluded If any deadl", 
      isText: false,
      type: "checkbox",
      top: 189.24,
      left: 503.88,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 1,
      name: "treat the sel", 
      isText: false,
      type: "checkbox",
      top: 472.92,
      left: 129.761,
      width: 10.039,
      height: 9.36
   }
   ,
   {
      page: 2,
      name: "Success Fee Commission Brokerage Firm wi", 
      isText: false,
      type: "checkbox",
      top: 486.6,
      left: 110.28,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 2,
      name: "CBfeeField", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 498.282,
      left: 428.502,
      width: 31.115,
      height: 12.696
   }
   ,
   {
      page: 2,
      name: "except as provided in  7112", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 512.4,
      left: 219.84,
      width: 97.80,
      height: 12.48
   }
   ,
   {
      page: 2,
      name: "See  21 Additional Provisions or", 
      isText: false,
      type: "checkbox",
      top: 534.36,
      left: 233.64,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 2,
      name: "Other", 
      isText: false,
      type: "checkbox",
      top: 534.36,
      left: 423.72,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 2,
      name: "undefined", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 544.08,
      left: 92.4,
      width: 471.48,
      height: 12.48
   }
   ,
   {
      page: 2,
      name: "Hourly Fee Brokerage Firm wi", 
      isText: false,
      type: "checkbox",
      top: 641.88,
      left: 108.6,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 2,
      name: "per hour for time spent by Broker pursuant to", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 637.92,
      left: 316.32,
      width: 55.32,
      height: 12.48
   }
   ,
   {
      page: 2,
      name: "Th", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 651.84,
      left: 394.8,
      width: 82.44,
      height: 12.48
   }
   ,
   {
      page: 2,
      name: "Retainer Fee Buyer wil", 
      isText: false,
      type: "checkbox",
      top: 687.24,
      left: 107.4,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 2,
      name: "due and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 683.28,
      left: 471.36,
      width: 61.08,
      height: 12.48
   }
   ,
   {
      page: 2,
      name: "Will", 
      isText: false,
      type: "checkbox",
      top: 699.24,
      left: 374.52,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 2,
      name: "Will Not be credited against", 
      isText: false,
      type: "checkbox",
      top: 699.24,
      left: 422.28,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 2,
      name: "Other Compensation", 
      isText: false,
      type: "checkbox",
      top: 727.32,
      left: 107.4,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 2,
      name: "undefined_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 723.36,
      left: 230.16,
      width: 334.92,
      height: 12.48
   }
   ,
   {
      page: 2,
      name: "of the purchase pr", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 498.282,
      left: 246.04,
      width: 43.52,
      height: 12.696
   }
   ,
   {
      page: 3,
      name: "per square", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 60.96,
      left: 165.96,
      width: 58.56,
      height: 12.72
   }
   ,
   {
      page: 3,
      name: "or", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 59.442,
      left: 276.536,
      width: 70.399,
      height: 14.247
   }
   ,
   {
      page: 3,
      name: "undefined_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 60.96,
      left: 364.8,
      width: 205.8,
      height: 12.72
   }
   ,
   {
      page: 3,
      name: "CBfee721", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 75.017,
      left: 241.422,
      width: 33.699,
      height: 12.696
   }
   ,
   {
      page: 3,
      name: "Adjusted Amount", 
      isText: false,
      type: "checkbox",
      top: 93.24,
      left: 211.08,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 3,
      name: "See  21 Additional Provisions or_2", 
      isText: false,
      type: "checkbox",
      top: 93.24,
      left: 405.96,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 3,
      name: "undefined_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 89.04,
      left: 459.12,
      width: 107.16,
      height: 12.72
   }
   ,
   {
      page: 3,
      name: "undefined_5", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 105.12,
      left: 148.32,
      width: 417.96,
      height: 12.72
   }
   ,
   {
      page: 3,
      name: "CBfee723other", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 119.402,
      left: 251.726,
      width: 35.250,
      height: 13.213
   }
   ,
   {
      page: 3,
      name: "If the Lease executed after the date of this", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 160.8,
      left: 252.96,
      width: 119.64,
      height: 12.72
   }
   ,
   {
      page: 3,
      name: "ocated Brokerage Firm", 
      isText: false,
      type: "checkbox",
      top: 192.6,
      left: 393.96,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 3,
      name: "Will_2", 
      isText: false,
      type: "checkbox",
      top: 192.6,
      left: 441.72,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 3,
      name: "undefined_6", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 232.56,
      left: 76.32,
      width: 482.76,
      height: 12.72
   }
   ,
   {
      page: 3,
      name: "731", 
      isText: false,
      type: "checkbox",
      top: 268.44,
      left: 98.52,
      width: 11.04,
      height: 9.84
   }
   ,
   {
      page: 3,
      name: "CBfee731", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 289.975,
      left: 151.449,
      width: 34.216,
      height: 12.696
   }
   ,
   {
      page: 3,
      name: "732", 
      isText: false,
      type: "checkbox",
      top: 321.48,
      left: 98.52,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 3,
      name: "733", 
      isText: false,
      type: "checkbox",
      top: 361.56,
      left: 98.52,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 3,
      name: "CBfee733", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 381.982,
      left: 92.5234,
      width: 31.1156,
      height: 14.247
   }
   ,
   {
      page: 3,
      name: "calendar days after the List", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 453.12,
      left: 294.24,
      width: 45.96,
      height: 12.48
   }
   ,
   {
      page: 3,
      name: "However Buyer", 
      isText: false,
      type: "checkbox",
      top: 498.36,
      left: 149.16,
      width: 11.04,
      height: 9.84
   }
   ,
   {
      page: 3,
      name: "Will_3", 
      isText: false,
      type: "checkbox",
      top: 498.36,
      left: 196.2,
      width: 11.04,
      height: 9.84
   }
   ,
   {
      page: 3,
      name: "ng Contract Buyer represents that Buyer", 
      isText: false,
      type: "checkbox",
      top: 664.44,
      left: 430.44,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 3,
      name: "Is", 
      isText: false,
      type: "checkbox",
      top: 664.44,
      left: 465.72,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 3,
      name: "Property Buyer further represents that Buyer", 
      isText: false,
      type: "checkbox",
      top: 692.04,
      left: 265.08,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 3,
      name: "Has", 
      isText: false,
      type: "checkbox",
      top: 692.04,
      left: 313.32,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 4,
      name: "ta_brokerageServices", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 230.43,
      left: 80.6722,
      width: 494.1878,
      height: 52.529
   }
   ,
   {
      page: 4,
      name: "Broker through Brokerage Firm has access to the", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 320.4,
      left: 80.88,
      width: 263.16,
      height: 11.52
   }
   ,
   {
      page: 4,
      name: "ta_mls", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 346.2,
      left: 81.7148,
      width: 494.1872,
      height: 28.028
   }
   ,
   {
      page: 4,
      name: "tten consent of Buyer", 
      isText: false,
      type: "checkbox",
      top: 376.44,
      left: 298.92,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 4,
      name: "Does Not have Buyer", 
      isText: false,
      type: "checkbox",
      top: 376.44,
      left: 349.08,
      width: 10.56,
      height: 9.36
   }
   ,
   {
      page: 6,
      name: "ta_additionalProvisions", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 233.6,
      left: 65.4696,
      width: 507.7414,
      height: 523.2577
   }
   ,
   {
      page: 7,
      name: "ta_attachments", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 46.757,
      left: 56.8615,
      width: 516.6035,
      height: 38.976
   }
   ,
   {
      page: 7,
      name: "or_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 197.64,
      left: 386.16,
      width: 169.80,
      height: 12.12
   }
   ,
   {
      page: 7,
      name: "tf_buyer1_signareafullname", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 507.155,
      left: 93.7679,
      width: 281.2281,
      height: 11.599
   }
   ,
   {
      page: 7,
      name: "tf_buyer2_signareafullname", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 544.794,
      left: 93.7679,
      width: 281.2281,
      height: 11.598
   }
   ,
   {
      page: 7,
      name: "sig_brokersignature", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 594.36,
      left: 58.32,
      width: 318.24,
      height: 12.12
   }
   ,
   {
      page: 7,
      name: "Date_4", 
      fontSize: 9,
      type: "date",
      top: 594.36,
      left: 402.24,
      width: 66.60,
      height: 12.12
   }
   ,
   {
      page: 7,
      name: "undefined_7", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 608.04,
      left: 188.88,
      width: 262.92,
      height: 12.12
   }
   ,
   {
      page: 7,
      name: "s Name 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 621.48,
      left: 188.88,
      width: 262.92,
      height: 12.12
   }
   ,
   {
      page: 7,
      name: "Brokerage Firm Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 634.2,
      left: 188.88,
      width: 262.92,
      height: 12.12
   }
   ,
   {
      page: 7,
      name: "s Name 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 646.92,
      left: 188.88,
      width: 262.92,
      height: 12.12
   }
   ,
   {
      page: 7,
      name: "Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 659.64,
      left: 188.88,
      width: 262.92,
      height: 12.12
   }
   ] }
   