import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Header, Input } from "semantic-ui-react";
import { openModal } from "../../../../app/common/modals/modalSlice";
import { searchFilter } from "../../../../app/common/util/util";
import DocTransActiveList from "./DocTransActiveList";
import { useMediaQuery } from "react-responsive";

export default function DocTransActiveDashboard() {
  const dispatch = useDispatch();
  const { docsTransActive, docsTransArchived, docsTransShared } = useSelector(
    (state) => state.doc
  );
  const [searchTerms, setSearchTerms] = useState("");
  const docs = searchFilter(docsTransActive?.docs, searchTerms);
  const docsArchived = searchFilter(docsTransArchived?.docs, searchTerms);
  const docsShared = searchFilter(docsTransShared?.docs, searchTerms);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <div className="secondary-page-wrapper">
      <Grid stackable>
        <Grid.Column computer={8}>
          <Input
            type="text"
            fluid
            placeholder="Search by document name or status"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          ></Input>
        </Grid.Column>
        <Grid.Column width={8}>
          <div className={isMobile ? null : "float-right"}>
            <Button
              to="#"
              icon="plus"
              size="small"
              className={isMobile ? "fluid" : null}
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: "DocAddDocumentsModal",
                  })
                )
              }
              content="Add Documents"
              color="blue"
            />
          </div>
        </Grid.Column>

        <Grid.Column computer={16}>
          {docsArchived?.length > 0 && (
            <Header className="zero bottom margin">Active</Header>
          )}
          {docs?.length > 0 ? (
            <DocTransActiveList
              docs={docs}
              column={docsTransActive.column}
              direction={docsTransActive.direction}
            />
          ) : (
            <p>There are no active documents</p>
          )}
        </Grid.Column>
        {docsShared?.length > 0 && (
          <Grid.Column computer={16}>
            <Header className="zero bottom margin">Shared</Header>
            <DocTransActiveList
              docs={docsShared}
              column={docsTransShared.column}
              direction={docsTransShared.direction}
              shared={true}
            />
          </Grid.Column>
        )}
        {docsArchived?.length > 0 && (
          <Grid.Column computer={16}>
            <Header className="zero bottom margin">Archived</Header>
            <DocTransActiveList
              docs={docsArchived}
              column={docsTransArchived.column}
              direction={docsTransArchived.direction}
              archived={true}
            />
          </Grid.Column>
        )}
      </Grid>
    </div>
  );
}
