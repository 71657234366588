export function sellersTemporaryResidentialLease() {
return [   //2024 Release 2024-02-18 22:10:14
{
   page: 0,
   name: "1 PARTIES The parties to this Lease are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 101.88,
   left: 256.08,
   width: 313.92,
   height: 9.96
}
,
{
   page: 0,
   name: "Landlord and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 112.8,
   left: 130.44,
   width: 395.04,
   height: 9.96
}
,
{
   page: 0,
   name: "and Tenant as Seller known as", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 142.8,
   left: 210.48,
   width: 359.52,
   height: 9.96
}
,
{
   page: 0,
   name: "address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 153.84,
   left: 52.32,
   width: 466.20,
   height: 9.96
}
,
{
   page: 0,
   name: "funded and terminates", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 183.84,
   left: 170.16,
   width: 113.40,
   height: 9.96
}
,
{
   page: 0,
   name: "4 RENTAL  Tenant shall pay to Landlord as rental", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 202.8,
   left: 308.16,
   width: 63.84,
   height: 9.96
}
,
{
   page: 0,
   name: "5 DEPOSIT Tenant shall pay to Landlord at the time of funding of the sale", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 254.88,
   left: 433.2,
   width: 78.12,
   height: 9.96
}
,
{
   page: 0,
   name: "6 UTILITIES Tenant shall pay all utility charges except", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 317.88,
   left: 331.08,
   width: 238.92,
   height: 9.96
}
,
{
   page: 0,
   name: "8 PETS Tenant may not keep pets on the Property except", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 377.88,
   left: 342.24,
   width: 226.80,
   height: 9.96
}
,
{
   page: 0,
   name: "Text 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 477.438,
   left: 182.916,
   width: 386.984,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 488.856,
   left: 51.0867,
   width: 518.7673,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 499.279,
   left: 51.0868,
   width: 518.7672,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 510.447,
   left: 51.459,
   width: 518.022,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 520.491,
   left: 51.4516,
   width: 518.0224,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 532.024,
   left: 51.0719,
   width: 518.7671,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 543.191,
   left: 51.0718,
   width: 518.7672,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 554.374,
   left: 51.0868,
   width: 518.7672,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 564.798,
   left: 51.0868,
   width: 518.7662,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 576.586,
   left: 51.1962,
   width: 518.5198,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 588.243,
   left: 51.0793,
   width: 518.7667,
   height: 11.773
}
,
{
   page: 0,
   name: "Text 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 599.776,
   left: 51.4441,
   width: 518.7669,
   height: 11.773
}
,
{
   page: 0,
   name: "Initialed for identification by Landlord", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 760.5354,
   left: 222.6,
   width: 58.14,
   height: 10.7046
}
,
{
   page: 0,
   name: "and Tenant", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 760.5355,
   left: 342.343,
   width: 59.251,
   height: 10.7045
}
,
{
   page: 1,
   name: "Page 2 of 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 33.948,
   left: 189.72,
   width: 286.92,
   height: 13.572
}
,
{
   page: 1,
   name: "Any possession by Tenant after termination creates a tenancy at sufferance and will not operate to renew or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 248.749,
   left: 239.29,
   width: 33.18,
   height: 9.960
}
,
{
   page: 1,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 484.595,
   left: 125.36,
   width: 178.80,
   height: 11.885
}
,
{
   page: 1,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 484.106,
   left: 392.148,
   width: 183.412,
   height: 12.374
}
,
{
   page: 1,
   name: "To Landlord 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 503.226,
   left: 50.0,
   width: 254.16,
   height: 12.374
}
,
{
   page: 1,
   name: "To Tenant 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 503.715,
   left: 323.988,
   width: 251.572,
   height: 11.885
}
,
{
   page: 1,
   name: "To Landlord 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 521.736,
   left: 50.0,
   width: 254.16,
   height: 12.864
}
,
{
   page: 1,
   name: "To Tenant 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 521.716,
   left: 323.988,
   width: 251.572,
   height: 12.884
}
,
{
   page: 1,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 541.357,
   left: 133.828,
   width: 23.193,
   height: 11.229
}
,
{
   page: 1,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 539.379,
   left: 163.459,
   width: 141.381,
   height: 13.717
}
,
{
   page: 1,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 541.357,
   left: 397.538,
   width: 23.682,
   height: 11.718
}
,
{
   page: 1,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 541.612,
   left: 430.445,
   width: 145.104,
   height: 11.974
}
,
{
   page: 1,
   name: "Text8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 559.92,
   left: 133.828,
   width: 23.193,
   height: 11.719
}
,
{
   page: 1,
   name: "Text9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 557.942,
   left: 163.438,
   width: 140.891,
   height: 13.697
}
,
{
   page: 1,
   name: "Text12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 559.431,
   left: 397.538,
   width: 23.682,
   height: 12.697
}
,
{
   page: 1,
   name: "Text13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 558.196,
   left: 431.425,
   width: 145.104,
   height: 13.443
}
,
{
   page: 1,
   name: "Facsimile", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 575.846,
   left: 96.8,
   width: 207.36,
   height: 13.114
}
,
{
   page: 1,
   name: "Facsimile_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 575.846,
   left: 370.908,
   width: 204.652,
   height: 13.354
}
] }
