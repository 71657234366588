export function addendumSellerFinancing() {
return [   //2024 Release 2024-02-19 07:46:21
{
   page: 0,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 129.634,
   left: 34.56,
   width: 540.12,
   height: 11.843
}
,
{
   page: 0,
   name: "Seller within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 263.29,
   left: 128.28,
   width: 45.72,
   height: 9.84
}
,
{
   page: 0,
   name: "credit report", 
   isText: false,
   type: "checkbox",
   top: 262.28,
   left: 402.12,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "verification", 
   isText: false,
   type: "checkbox",
   top: 262.28,
   left: 484.44,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "verification  of  funds  on deposit in financial institutions", 
   isText: false,
   type: "checkbox",
   top: 274.32,
   left: 245.837,
   width: 10.363,
   height: 8.40
}
,
{
   page: 0,
   name: "current financial statement  and", 
   isText: false,
   type: "checkbox",
   top: 284.36,
   left: 65.88,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "undefined", 
   isText: false,
   type: "checkbox",
   top: 283.36,
   left: 356.76,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "Buyer hereby", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 284.64,
   left: 397.156,
   width: 155.281,
   height: 9.96
}
,
{
   page: 0,
   name: "authorizes any credit reporting agency to furnish copies of Buyers credit", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 296.29,
   left: 66.3168,
   width: 485.8382,
   height: 9.96
}
,
{
   page: 0,
   name: "C PROMISSORY NOTE The promissory note in the amount of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 433.08,
   left: 384.24,
   width: 76.32,
   height: 9.96
}
,
{
   page: 0,
   name: "of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 455.674,
   left: 78.5232,
   width: 26.3998,
   height: 9.960
}
,
{
   page: 0,
   name: "1 In one payment due", 
   isText: false,
   type: "checkbox",
   top: 540.36,
   left: 65.88,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "with interest payable", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 540.13,
   left: 203.367,
   width: 219.600,
   height: 9.96
}
,
{
   page: 0,
   name: "at maturity", 
   isText: false,
   type: "checkbox",
   top: 550.28,
   left: 206.04,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "monthly", 
   isText: false,
   type: "checkbox",
   top: 550.28,
   left: 280.8,
   width: 10.8,
   height: 8.40
}
,
{
   page: 0,
   name: "quarterly check one box only", 
   isText: false,
   type: "checkbox",
   top: 550.28,
   left: 340.32,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "2 In monthly installments of", 
   isText: false,
   type: "checkbox",
   top: 570.36,
   left: 65.88,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 569.64,
   left: 245.16,
   width: 83.52,
   height: 9.96
}
,
{
   page: 0,
   name: "including interest", 
   isText: false,
   type: "checkbox",
   top: 569.36,
   left: 336.48,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "plus interest check", 
   isText: false,
   type: "checkbox",
   top: 569.36,
   left: 445.44,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "one box only beginning", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 581.29,
   left: 223.69,
   width: 120.72,
   height: 9.96
}
,
{
   page: 0,
   name: "monthly thereafter for", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 591.84,
   left: 208.92,
   width: 73.08,
   height: 9.96
}
,
{
   page: 0,
   name: "3 Interest only in monthly installments for the first", 
   isText: false,
   type: "checkbox",
   top: 622.32,
   left: 65.88,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "months and thereafter in", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 621.72,
   left: 347.76,
   width: 69.48,
   height: 9.96
}
,
{
   page: 0,
   name: "installments of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 632.64,
   left: 181.56,
   width: 100.56,
   height: 9.96
}
,
{
   page: 0,
   name: "including interest_2", 
   isText: false,
   type: "checkbox",
   top: 633.36,
   left: 285.96,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "plus interest check one box", 
   isText: false,
   type: "checkbox",
   top: 633.36,
   left: 394.44,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "only beginning", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 643.8,
   left: 179.88,
   width: 115.92,
   height: 9.96
}
,
{
   page: 0,
   name: "thereafter for", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 654.84,
   left: 164.52,
   width: 70.32,
   height: 9.96
}
,
{
   page: 0,
   name: "a Consent Not Required The Property may be sold conveyed or leased without the", 
   isText: false,
   type: "checkbox",
   top: 712.32,
   left: 79.32,
   width: 10.80,
   height: 8.40
}
,
{
   page: 1,
   name: "Address of Property_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 52.672,
   left: 37.44,
   width: 531.12,
   height: 12.725
}
,
{
   page: 1,
   name: "b Consent Required If all or any part of the Property is sold conveyed leased for a", 
   isText: false,
   type: "checkbox",
   top: 95.184,
   left: 68.6,
   width: 10.8,
   height: 8.400
}
,
{
   page: 1,
   name: "2", 
   isText: false,
   type: "checkbox",
   top: 220.929,
   left: 408.6,
   width: 10.8,
   height: 8.400
}
,
{
   page: 1,
   name: "b Consent Required If all or any part of the Property is sold conveyed leased for a NO 3", 
   isText: false,
   type: "checkbox",
   top: 220.929,
   left: 460.6,
   width: 10.8,
   height: 8.400
}
,
{
   page: 1,
   name: "a Escrow Not Required Buyer shall furnish Seller before each years ad valorem taxes", 
   isText: false,
   type: "checkbox",
   top: 270.28,
   left: 73.8768,
   width: 10.2432,
   height: 8.40
}
,
{
   page: 1,
   name: "b Escrow Required With each installment Buyer shall deposit in escrow with Seller a pro rata", 
   isText: false,
   type: "checkbox",
   top: 312.36,
   left: 73.44,
   width: 10.68,
   height: 8.40
}
,
{
   page: 1,
   name: "b Consent Required If all or any part of the Property is sold conveyed leased for a No 7", 
   isText: false,
   type: "checkbox",
   top: 409.721,
   left: 104.6,
   width: 10.8,
   height: 8.400
}
,
{
   page: 1,
   name: "b Consent Required If all or any part of the Property is sold conveyed leased for a No 8", 
   isText: false,
   type: "checkbox",
   top: 409.721,
   left: 151.6,
   width: 10.8,
   height: 8.400
}
,
{
   page: 1,
   name: "b Consent Required If all or any part of the Property is sold conveyed leased for a No 4", 
   isText: false,
   type: "checkbox",
   top: 411.433,
   left: 406.312,
   width: 10.088,
   height: 7.688
}
,
{
   page: 1,
   name: "b Consent Required If all or any part of the Property is sold conveyed leased for a No 5", 
   isText: false,
   type: "checkbox",
   top: 410.721,
   left: 457.6,
   width: 10.8,
   height: 8.400
}
] }
