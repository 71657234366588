import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { Divider, Grid } from "semantic-ui-react";
import AnnotTypeMenu from "./annots/AnnotTypeMenu";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addAnnotToCanvas } from "./annots/annotUtils";
import DocPrepareActionButtons from "./DocPrepareActionButtons";
import DocPrepareSignerList from "./signerList/DocPrepareSignerList";
import AnnotEditButtons from "./annots/AnnotEditButtons";
import AnnotDraggableField from "./annots/AnnotDraggableField";
import { createSigningParties } from "./annots/annotUtils";
import { updateDocInDb } from "../../../../app/firestore/firestoreService";
import FormFieldSelector from "./formFields/FormFieldSelector";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import {
  addAnnot,
  changeSelectedSigner,
  selectDocAnnot,
  setSignerListDisplay,
  setSignerListPossible,
} from "../../../../app/annots/annotSlice";
import {
  createSuggestedAnnots,
  getAndSavePdfDimensions,
  getFormFieldValues,
} from "../../../../app/common/util/util";
import DocViewAgentAnnotField from "../docView/DocViewAgentAnnotField";
import DocViewSignedAnnotField from "../docView/DocViewSignedAnnotField";
import { getCurrentUserIpAddress } from "../../../profiles/profileSlice";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

export function DocPrepareDashboard() {
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(null);
  const {
    annots,
    pageScalePrepare,
    activeAnnotType,
    selectedSigner,
    editMode,
  } = useSelector((state) => state.annot);
  const { doc, docUrl } = useSelector((state) => state.doc);
  const { currentUserProfile, currentUserIpAddress } = useSelector(
    (state) => state.profile
  );
  const { transClients, transaction, parties } = useSelector(
    (state) => state.transaction
  );
  const [formFieldValues, setFormFieldValues] = useState();
  const [updatedSuggestedAnnots, setUpdatedSuggestedAnnots] = useState(false);
  const [pageDimensions, setPageDimensions] = useState(
    doc.dimensions || {
      width: 612,
      height: 792,
    }
  );
  const pageScale = pageScalePrepare;

  useEffect(() => {
    if (currentUserProfile?.hasSigningVerification && !currentUserIpAddress)
      axios.get("https://api.ipify.org?format=json").then((res) => {
        dispatch(getCurrentUserIpAddress(res?.data?.ip));
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let partiesCopy = JSON.parse(JSON.stringify(parties));
    let transactionCopy = JSON.parse(JSON.stringify(transaction));
    const signingParties = createSigningParties(transactionCopy, partiesCopy);
    dispatch(
      setSignerListPossible(_.sortBy(signingParties.possibleSigners, "index"))
    );
    if (doc.signerListInProgress?.length > 0) {
      dispatch(setSignerListDisplay(doc.signerListInProgress));
    } else {
      dispatch(
        setSignerListDisplay(_.sortBy(signingParties.displaySigners, "index"))
      );
    }
    if (
      doc.selectedSignerInProgress &&
      !_.isEmpty(doc.selectedSignerInProgress)
    ) {
      dispatch(changeSelectedSigner(doc.selectedSignerInProgress));
    } else {
      dispatch(changeSelectedSigner(transClients[0]));
    }
  }, [doc, dispatch, parties, transClients, transaction]);

  useEffect(() => {}, [pageScalePrepare, annots]);

  useEffect(() => {
    getFormFieldValues(doc, transaction, setFormFieldValues);
  }, [doc, transaction]);

  useEffect(() => {
    if (
      doc &&
      doc.annotsInProgressSuggestedAdded === false &&
      updatedSuggestedAnnots === false &&
      !doc.shared
    ) {
      let suggestedAnnots = [];
      if (doc?.annotsInProgressSuggested) {
        suggestedAnnots = createSuggestedAnnots(transaction, doc);
        setUpdatedSuggestedAnnots(true);
        updateDocInDb(
          doc.id,
          {
            annotsInProgress: suggestedAnnots,
            annotsInProgressSuggestedAdded: true,
          },
          false
        );
      }
    }
  }, [doc, updatedSuggestedAnnots, transaction]);

  async function onDocumentLoadSuccess(pdfObject) {
    getAndSavePdfDimensions(
      doc,
      pdfObject,
      setNumPages,
      setPageDimensions,
      "agent"
    );
  }

  function Canvas(props) {
    const canvasRef = useRef(null);
    useEffect(() => {
      const canvas = canvasRef.current;
      canvas.getContext("2d");
      canvas.ondrop = function (e) {
        e.preventDefault();
        const annot = addAnnotToCanvas(
          canvas,
          e,
          activeAnnotType,
          doc,
          selectedSigner,
          currentUserProfile,
          currentUserIpAddress,
          transaction,
          pageScale,
          editMode
        );
        dispatch(addAnnot(annot));
        dispatch(selectDocAnnot(annot));
      };
      canvas.ondragover = function (e) {
        e.stopPropagation();
        e.preventDefault();
      };
    }, []);
    return <canvas ref={canvasRef} {...props} />;
  }

  return (
    <div>
      <div className="pdf-left-panel-wrapper large padding">
        <Grid>
          <Grid.Column width={16}>
            <div className="large vertical margin">
              <p
                style={{
                  color: "#9a9a9a",
                  marginBottom: "3px",
                  marginTop: "30px",
                }}
              >
                Signer
              </p>
              <Divider className="zero top margin" />
              <DocPrepareSignerList />
            </div>
            <AnnotEditButtons />
          </Grid.Column>
        </Grid>
      </div>
      <div className="pdf-annot-menu-wrapper" id="annot-menu">
        <AnnotTypeMenu />
      </div>
      <div
        className="pdf-prepare-wrapper"
        style={{
          backgroundColor: "#f3f6f8",
        }}
      >
        <div className="pdf-document-wrapper">
          <div
            style={{
              position: "relative",
            }}
          >
            <Document
              file={docUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <div style={{ height: window.innerHeight }}>
                  <LoadingComponent />
                </div>
              }
              options={options}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div
                  className="pdf-prepare-page-wrapper large vertical padding"
                  key={`page-${index}`}
                  id={`page-${index}`}
                >
                  <div className="pdf-page-number">
                    Page {index + 1}/{numPages}
                  </div>
                  <div
                    className="pdf-page-container"
                    style={{
                      height: `${pageDimensions.height * pageScale}px`,
                      width: `${pageDimensions.width * pageScale}px`,
                    }}
                  >
                    <Page
                      scale={pageScale}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                    <Canvas
                      className="pdf-canvas"
                      id={`canvas-${index}`}
                      style={{
                        width: `${pageDimensions.width * pageScale}px`,
                        height: `${pageDimensions.height * pageScale}px`,
                      }}
                    />
                    {numPages &&
                      formFieldValues?.map((formField) => (
                        <React.Fragment key={formField.name}>
                          {formField.page === index && (
                            <FormFieldSelector
                              formField={formField}
                              pageScale={pageScale}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    {numPages &&
                      annots?.map((annot) => (
                        <React.Fragment key={annot.uniqueId}>
                          {annot.page === index && (
                            <AnnotDraggableField
                              annot={annot}
                              pageScale={pageScale}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    {numPages &&
                      doc.pdfBurnVersion &&
                      doc.annotsByAgent &&
                      Object.entries(doc.annotsByAgent).map(([key, value]) => (
                        <React.Fragment key={key}>
                          {value.page === index && (
                            <DocViewAgentAnnotField
                              annot={value}
                              pageScale={pageScale}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    {numPages &&
                      doc.pdfBurnVersion &&
                      doc.annotsToSign &&
                      Object.entries(doc.annotsToSign).map(([key, value]) => (
                        <React.Fragment key={key}>
                          {value.page === index && (
                            <>
                              {value.signed === true && (
                                <DocViewSignedAnnotField
                                  annot={value}
                                  pageScale={pageScale}
                                />
                              )}
                            </>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              ))}
            </Document>
          </div>
        </div>
      </div>
      <Grid className="zero horizontal margin zero horizontal padding">
        <Grid.Column width={16} className="zero horizontal padding">
          <DocPrepareActionButtons />
        </Grid.Column>
      </Grid>
    </div>
  );
}
