import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import { fillAndFlattenPdf } from "../../../../app/pdfLib/pdfLib";
import { updateDocInDb } from "../../../../app/firestore/firestoreService";
import download from "downloadjs";
import { changePageScaleFill } from "../../../../app/annots/annotSlice";
import { openModal } from "../../../../app/common/modals/modalSlice";

export default function DocFillActionButtons() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { doc } = useSelector((state) => state.doc);
  const { pageScaleFill } = useSelector((state) => state.annot);
  const { transaction } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);

  const [processing, setProcessing] = useState(false);

  function closeDocument() {
    navigate(`/transactions/${doc.transactionId}/documents`);
  }

  async function saveAnnots() {
    toast.success("Document successfully saved");
  }

  async function downloadPdf() {
    setProcessing(true);
    const pdfBytes = await fillAndFlattenPdf(
      doc,
      transaction,
      currentUserProfile
    );
    const newDocName = doc.name.replace(/\./g, "-");
    download(pdfBytes, newDocName, "application/pdf");
    setProcessing(false);
  }

  function prepareForSigning() {
    updateDocInDb(doc.id, { subStatus: "Preparing Signatures" });
    navigate(`/transactions/${doc.transactionId}/documents/${doc.id}/prepare`);
  }

  function handleZoomIn() {
    if (pageScaleFill < 4) {
      dispatch(changePageScaleFill(pageScaleFill * 1.25));
    }
  }

  function handleZoomOut() {
    if (pageScaleFill > 0.5) {
      dispatch(changePageScaleFill(pageScaleFill / 1.25));
    }
  }

  function handleAddClause() {
    dispatch(
      openModal({
        modalType: "ClausesView",
      })
    );
  }

  function handleDeadlineTemplate() {
    dispatch(
      openModal({
        modalType: "DeadlineTemplatesSelect",
      })
    );
  }

  return (
    <>
      <div
        className="large horizontal padding background-white"
        style={{
          top: "auto",
          bottom: "0",
          position: "fixed",
          width: "100%",
          height: "80px",
          borderTop: "1px solid #e8e8e8",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            float: "left",
          }}
        >
          {processing ? (
            <Button loading style={{ marginRight: "24px" }} content="Loading" />
          ) : (
            <Button
              disabled={processing}
              onClick={() => downloadPdf()}
              style={{ marginRight: "24px" }}
              content="Download"
            />
          )}

          <Icon
            size="large"
            color="grey"
            name="minus circle"
            onClick={() => handleZoomOut()}
            style={{ cursor: "pointer" }}
          />
          <p
            className="zero bottom margin"
            style={{
              marginLeft: "3px",
              marginRight: "6px",
              fontWeight: "bold",
            }}
          >
            {Math.round(pageScaleFill * 100)}%
          </p>
          <Icon
            size="large"
            color="grey"
            name="plus circle"
            onClick={() => handleZoomIn()}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div
          className="large right margin"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            float: "right",
          }}
        >
          <Button
            onClick={() => closeDocument()}
            content="Close"
            style={{ marginRight: "10px" }}
          />
          <Button
            primary
            onClick={() => handleAddClause()}
            content="Clauses"
            style={{ marginRight: "10px" }}
          />
          {doc.canApplyDeadlinesTemplate && (
            <Button
              primary
              onClick={() => handleDeadlineTemplate()}
              content="Apply Deadline Template"
              style={{ marginRight: "10px" }}
            />
          )}
          <Button
            primary
            onClick={() => saveAnnots()}
            content="Save"
            style={{ marginRight: "10px" }}
          />
          <Button
            className="dark-blue"
            onClick={() => prepareForSigning()}
            content="Prepare For Signing"
          />
        </div>
      </div>
    </>
  );
}
