import { createSlice } from '@reduxjs/toolkit'
import _ from "lodash";

const initialState = {
  people: []
}

const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    fetchPeople(state, action) {
      state.people = action.payload
    },
    sortPeople(state, action) {
      if (state.column === action.payload) {
        state.people = state.people.slice().reverse()
        state.column = action.payload
        state.direction = state.direction === 'ascending' ? 'descending' : 'ascending'
      } else {
        state.people = _.sortBy(state.people, [action.payload])
        state.column = action.payload
        state.direction = 'ascending'
      }
    },
  }
});

export const { fetchPeople, sortPeople } = peopleSlice.actions

export default peopleSlice.reducer