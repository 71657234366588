export function newConstructionAddendum() {
return [   //2024 Release 2024-06-07 09:24:57
{
        page: 0,
        name: "Logo",
        isText: false,
        fontSize: 10,
        type: "logo",
        top: 28.673,
        left: 52.9457,
        width: 288.0003,
      height: 43.2,
      }
    ,
    {
   page: 0,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 142.037,
   left: 73.6549,
   width: 18.0000,
   height: 17.345
}
,
{
   page: 0,
   name: "Check Box2", 
   isText: false,
   type: "checkbox",
   top: 170.183,
   left: 73.6549,
   width: 16.0363,
   height: 16.690
}
,
{
   page: 0,
   name: "Check Box3", 
   isText: false,
   type: "checkbox",
   top: 225.165,
   left: 74.3094,
   width: 16.0364,
   height: 16.691
}
,
{
   page: 0,
   name: "Check Box4", 
   isText: false,
   type: "checkbox",
   top: 267.401,
   left: 399.965,
   width: 14.073,
   height: 14.073
}
,
{
   page: 0,
   name: "Check Box5", 
   isText: false,
   type: "checkbox",
   top: 267.401,
   left: 452.329,
   width: 14.073,
   height: 14.073
}
,
{
   page: 0,
   name: "Check Box6", 
   isText: false,
   type: "checkbox",
   top: 296.201,
   left: 399.965,
   width: 14.073,
   height: 14.073
}
,
{
   page: 0,
   name: "Check Box7", 
   isText: false,
   type: "checkbox",
   top: 296.201,
   left: 452.329,
   width: 14.073,
   height: 14.073
}
,
{
   page: 0,
   name: "onOrBefore", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 363.72,
   left: 108,
   width: 180,
   height: 12.36
}
,
{
   page: 0,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 363.72,
   left: 304.56,
   width: 18.00,
   height: 12.36
}
,
{
   page: 0,
   name: "Names", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 460.44,
   left: 119.52,
   width: 420.24,
   height: 12.36
}
,
{
   page: 0,
   name: "Physical Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 488.04,
   left: 161.28,
   width: 378.12,
   height: 12.36
}
,
{
   page: 0,
   name: "Check Box8", 
   isText: false,
   type: "checkbox",
   top: 515.13,
   left: 289.001,
   width: 15.382,
   height: 16.036
}
,
{
   page: 0,
   name: "Check Box9", 
   isText: false,
   type: "checkbox",
   top: 515.13,
   left: 342.674,
   width: 15.382,
   height: 16.036
}
,
{
   page: 0,
   name: "License Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 543.12,
   left: 157.92,
   width: 204.12,
   height: 12.36
}
,
{
   page: 0,
   name: "Inspection and Final Walk Through of New Construction Within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 584.52,
   left: 434.28,
   width: 42.00,
   height: 12.36
}
,
{
   page: 0,
   name: "Utilities for inspections shall be provided by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 653.52,
   left: 285.48,
   width: 160.68,
   height: 12.36
}
,
{
   page: 1,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 290.28,
   left: 83.7819,
   width: 211.8551,
   height: 12.36
}
] }
