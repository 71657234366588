import React from "react";
import { Segment } from "semantic-ui-react";
import ModalWrapper from "../../../../app/common/modals/modalWrapper";
import DocFileUpload from "./DocFileUpload";

export default function DocUploadModal() {
  return (
    <>
      <ModalWrapper>
        <Segment placeholder>
          <DocFileUpload status={"In Progress"} />
        </Segment>
      </ModalWrapper>
    </>
  );
}
