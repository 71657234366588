export function depositAddendum() {
return [   //2024 Release 2024-06-07 08:59:13
{
   page: 0,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 50.011,
   left: 286.255,
   width: 294.500,
   height: 12.182
}
,
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 466.046,
   left: 154.41,
   width: 380.911,
   height: 14.404
}
,
{
   page: 0,
   name: "Print Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 537.549,
   left: 101.84,
   width: 193.20,
   height: 12.480
}
,
{
   page: 0,
   name: "Print Name_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 538.2,
   left: 307.64,
   width: 198.72,
   height: 12.48
}
,
{
   page: 0,
   name: "Print Name_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 645.48,
   left: 101.84,
   width: 191.88,
   height: 12.48
}
,
{
   page: 0,
   name: "Print Name_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 645.36,
   left: 313.52,
   width: 187.68,
   height: 12.48
}
] }
