export function manufacturedHomeAmendExtend2024() {
   return [   //2024 Release 2024-01-13 13:15:12
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 240,
      height: 36,
    }
    ,
    {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 152.52,
      left: 465,
      width: 94.92,
      height: 12.24
   }
   ,
   {
      page: 0,
      name: "This agreement amends the contract dated", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 176.215,
      left: 313.018,
      width: 155.782,
      height: 11.585
   }
   ,
   {
      page: 0,
      name: "SellerAllNames", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 188.64,
      left: 50.64,
      width: 472.56,
      height: 12.24
   }
   ,
   {
      page: 0,
      name: "BuyerAllNames", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 204,
      left: 50.4,
      width: 472.44,
      height: 12.24
   }
   ,
   {
      page: 0,
      name: "known as No", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 240.36,
      left: 108.36,
      width: 420.00,
      height: 12.24
   }
   ,
   {
      page: 0,
      name: "Date contractDate", 
      fontSize: 9,
      type: "date",
      top: 175.385,
      left: 242.804,
      width: 67.636,
      height: 12.185
   }
   ,
   {
      page: 0,
      name: "or DeadlineTime of Day Deadline_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 362.76,
      left: 401.04,
      width: 174.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAlternative Earnest Money Deadline", 
      fontSize: 9,
      type: "date",
      top: 375.96,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "or DeadlineAlternative Earnest Money Deadline_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 375.96,
      left: 401.04,
      width: 174.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date UCC and Certificate of Title Deadline", 
      fontSize: 9,
      type: "date",
      top: 401.16,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date UCC and Certificate of Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 414.36,
      left: 333.6,
      width: 66.12,
      height: 11.88
   }
   ,
   {
      page: 0,
      name: "Date UCC and Certificate of Title Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 427.32,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Lease Delivery Deadline", 
      fontSize: 9,
      type: "date",
      top: 452.52,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Lot Owner Approval Deadline", 
      fontSize: 9,
      type: "date",
      top: 465.72,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Lease Review Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 478.92,
      left: 333.6,
      width: 66.12,
      height: 11.88
   }
   ,
   {
      page: 0,
      name: "Date Sellers Property Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 503.88,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date LeadBased Paint Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 517.08,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date New Loan Application Deadline", 
      fontSize: 9,
      type: "date",
      top: 542.28,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date New Loan Terms Deadline", 
      fontSize: 9,
      type: "date",
      top: 555.48,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date New Loan Availability Deadline", 
      fontSize: 9,
      type: "date",
      top: 568.68,
      left: 333.6,
      width: 66.12,
      height: 11.88
   }
   ,
   {
      page: 0,
      name: "Date Seller or Private Financing Deadline", 
      fontSize: 9,
      type: "date",
      top: 581.64,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Appraisal Deadline", 
      fontSize: 9,
      type: "date",
      top: 606.84,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Appraisal Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 620.04,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Appraisal Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 633.24,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Inspection Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 658.44,
      left: 333.6,
      width: 66.12,
      height: 11.88
   }
   ,
   {
      page: 0,
      name: "Date Inspection Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 671.4,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Inspection Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 684.6,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Home Insurance Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 697.8,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Due Diligence Documents Delivery Deadline", 
      fontSize: 9,
      type: "date",
      top: 711,
      left: 333.6,
      width: 66.12,
      height: 11.88
   }
   ,
   {
      page: 0,
      name: "Date Due Diligence Documents Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 723.96,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Date Due Diligence Documents Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 737.16,
      left: 333.6,
      width: 66.12,
      height: 12.12
   }
   ,
   {
      page: 0,
      name: "Text158", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 400.496,
      left: 403.745,
      width: 169.375,
      height: 11.397
   }
   ,
   {
      page: 0,
      name: "Text159", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 413.984,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text160", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 427.729,
      left: 403.745,
      width: 169.375,
      height: 11.397
   }
   ,
   {
      page: 0,
      name: "Text161", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 452.602,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text162", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 466.348,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text163", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 504.311,
      left: 403.745,
      width: 169.375,
      height: 11.397
   }
   ,
   {
      page: 0,
      name: "Text164", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 517.402,
      left: 403.745,
      width: 169.375,
      height: 11.397
   }
   ,
   {
      page: 0,
      name: "Text165", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 542.93,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text166", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 555.366,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text167", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 568.766,
      left: 403.745,
      width: 169.375,
      height: 11.397
   }
   ,
   {
      page: 0,
      name: "Text168", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 581.203,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text169", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 606.73,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text170", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 620.512,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text171", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 633.257,
      left: 403.745,
      width: 169.375,
      height: 11.397
   }
   ,
   {
      page: 0,
      name: "Text172", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 658.785,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text173", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 671.221,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text174", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 684.967,
      left: 403.745,
      width: 169.375,
      height: 11.396
   }
   ,
   {
      page: 0,
      name: "Text175", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 698.0576,
      left: 403.745,
      width: 169.375,
      height: 11.3964
   }
   ,
   {
      page: 0,
      name: "Text176", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 711.1487,
      left: 403.745,
      width: 169.375,
      height: 11.3963
   }
   ,
   {
      page: 0,
      name: "Text177", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 723.8941,
      left: 403.745,
      width: 169.375,
      height: 11.3964
   }
   ,
   {
      page: 0,
      name: "Text178", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 478.649,
      left: 403.745,
      width: 169.375,
      height: 11.397
   }
   ,
   {
      page: 0,
      name: "Text179", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 736.801,
      left: 403.745,
      width: 169.375,
      height: 11.3964
   }
   ,
   {
      page: 1,
      name: "Event_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 232.483,
      left: 48.3059,
      width: 531.4131,
      height: 85.734
   }
   ,
   {
      page: 1,
      name: "Date or DeadlineConditional Sale Deadline", 
      fontSize: 9,
      type: "date",
      top: 80.52,
      left: 333.6,
      width: 61.56,
      height: 11.88
   }
   ,
   {
      page: 1,
      name: "or DeadlineConditional Sale Deadline_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 80.52,
      left: 396.48,
      width: 183.24,
      height: 11.88
   }
   ,
   {
      page: 1,
      name: "Date or DeadlineLeadBased Paint Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 93.48,
      left: 333.6,
      width: 61.56,
      height: 12.12
   }
   ,
   {
      page: 1,
      name: "or DeadlineLeadBased Paint Termination Deadline_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 93.48,
      left: 396.48,
      width: 183.24,
      height: 12.12
   }
   ,
   {
      page: 1,
      name: "AdditionalAmendments", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 329.105,
      left: 50.4327,
      width: 528.4453,
      height: 209.497
   }
   ,
   {
      page: 1,
      name: "SellerNameOne", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 681.558,
      left: 121.483,
      width: 179.640,
      height: 11.040
   }
   ,
   {
      page: 1,
      name: "SellerName2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 680.587,
      left: 387.719,
      width: 185.280,
      height: 12.1201
   }
   ,
   {
      page: 1,
      name: "Date or DeadlineClosing Date", 
      fontSize: 9,
      type: "date",
      top: 118.68,
      left: 333.6,
      width: 61.56,
      height: 12.12
   }
   ,
   {
      page: 1,
      name: "or DeadlineClosing Date_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 118.68,
      left: 396.48,
      width: 183.24,
      height: 12.12
   }
   ,
   {
      page: 1,
      name: "Date or DeadlinePossession Date", 
      fontSize: 9,
      type: "date",
      top: 131.88,
      left: 333.6,
      width: 61.56,
      height: 12.12
   }
   ,
   {
      page: 1,
      name: "or DeadlinePossession Date_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 131.88,
      left: 396.48,
      width: 183.24,
      height: 12.12
   }
   ,
   {
      page: 1,
      name: "or DeadlinePossession Time_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 145.08,
      left: 396.48,
      width: 183.24,
      height: 12.12
   }
   ,
   {
      page: 1,
      name: "Date or DeadlineAcceptance Deadline Date", 
      fontSize: 9,
      type: "date",
      top: 158.28,
      left: 333.6,
      width: 61.56,
      height: 11.88
   }
   ,
   {
      page: 1,
      name: "or DeadlineAcceptance Deadline Date_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 158.28,
      left: 396.48,
      width: 183.24,
      height: 11.88
   }
   ,
   {
      page: 1,
      name: "or DeadlineAcceptance Deadline Time_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 171.24,
      left: 396.48,
      width: 183.24,
      height: 12.12
   }
   ,
   {
      page: 1,
      name: "31Row1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 184.44,
      left: 51.6,
      width: 37.56,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: " 24Row1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 184.44,
      left: 90.48,
      width: 39.24,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Acceptance Deadline TimeRow1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 184.44,
      left: 131.04,
      width: 201.24,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Date or DeadlineRow9", 
      fontSize: 9,
      type: "date",
      top: 184.44,
      left: 333.6,
      width: 61.56,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "or DeadlineRow9_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 184.44,
      left: 396.48,
      width: 183.24,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "31Row2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 196.92,
      left: 51.6,
      width: 37.56,
      height: 11.64
   }
   ,
   {
      page: 1,
      name: " 24Row2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 196.92,
      left: 90.48,
      width: 39.24,
      height: 11.64
   }
   ,
   {
      page: 1,
      name: "Acceptance Deadline TimeRow2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 196.92,
      left: 131.04,
      width: 201.24,
      height: 11.64
   }
   ,
   {
      page: 1,
      name: "Date or DeadlineRow10", 
      fontSize: 9,
      type: "date",
      top: 196.92,
      left: 333.6,
      width: 61.56,
      height: 11.64
   }
   ,
   {
      page: 1,
      name: "or DeadlineRow10_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 196.92,
      left: 396.48,
      width: 183.24,
      height: 11.64
   }
   ,
   {
      page: 1,
      name: "Date AcceptAmend", 
      fontSize: 9,
      type: "date",
      top: 577.178,
      left: 314.16,
      width: 80.16,
      height: 11.662
   }
   ,
   {
      page: 1,
      name: "undefined", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 577.178,
      left: 396.72,
      width: 179.76,
      height: 11.662
   }
   ,
   {
      page: 1,
      name: "Buyers Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 610.887,
      left: 119.28,
      width: 179.64,
      height: 13.233
   }
   ,
   {
      page: 1,
      name: "Buyers Name_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 611.411,
      left: 389.04,
      width: 185.28,
      height: 12.709
   }
   ] }
   