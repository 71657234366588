import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchDocsUpcoming,
  fetchStateForms,
} from "../../features/docs/docSlice";
import { fetchFormTemplates } from "../../features/formTemplates/formTemplatesSlice";
import { fetchTasksUpcoming } from "../../features/tasks/taskSlice";
import { fetchTransActive } from "../../features/transactions/transactionSlice";
import useFirestoreCollection from "../hooks/useFirestoreCollection";
import {
  fetchDocsUpcomingFromDb,
  fetchStateFormsFromDb,
  fetchTasksUpcomingFromDb,
  fetchTransActiveFromDb,
  fetchFormTemplatesFromDb,
  getUserProfile,
  fetchDeadlineTemplatesFromDb,
} from "./firestoreService";
import { fetchAgentUserProfile } from "../../features/profiles/profileSlice";
import useFirestoreDoc from "../hooks/useFirestoreDoc";
import { fetchDeadlineTemplates } from "../../features/deadlineTemplates/deadlineTemplatesSlice";

export default function ManagerAgentData() {
  const dispatch = useDispatch();
  let { agentId } = useParams();

  useFirestoreCollection({
    query: () => fetchTransActiveFromDb(agentId),
    data: (transactions) => dispatch(fetchTransActive(transactions)),
    deps: [dispatch, agentId],
  });

  useFirestoreDoc({
    query: () => getUserProfile(agentId),
    data: (profile) => dispatch(fetchAgentUserProfile(profile)),
    deps: [dispatch, agentId],
  });

  useFirestoreCollection({
    query: () => fetchTasksUpcomingFromDb(agentId),
    data: (tasks) => dispatch(fetchTasksUpcoming(tasks)),
    deps: [dispatch, agentId],
  });

  useFirestoreCollection({
    query: () => fetchDocsUpcomingFromDb(agentId),
    data: (docs) => dispatch(fetchDocsUpcoming(docs)),
    deps: [dispatch, agentId],
  });

  useFirestoreCollection({
    query: () => fetchStateFormsFromDb(),
    data: (forms) => dispatch(fetchStateForms(forms)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchFormTemplatesFromDb(),
    data: (formTemplates) => dispatch(fetchFormTemplates(formTemplates)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchDeadlineTemplatesFromDb(),
    data: (deadlineTemplates) =>
      dispatch(fetchDeadlineTemplates(deadlineTemplates)),
    deps: [dispatch],
  });

  return <></>;
}
