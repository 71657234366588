import { formatDistance } from "date-fns";
import React from "react";
import { Icon, Table } from "semantic-ui-react";
import FormTemplatesActionButtons from "../FormTemplatesActionButtons";

export default function FormTemplatesListItem({ formTemplate }) {
  return (
    <Table.Row key={formTemplate.id}>
      <Table.Cell>
        <Icon name="file pdf outline" size="large" />
        &nbsp;
        {formTemplate.name}
      </Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell>
        <p>
          {formTemplate.updatedAt && formTemplate.updatedAt instanceof Date
            ? formatDistance(formTemplate.updatedAt, new Date(), {
                addSuffix: true,
              })
            : ""}
        </p>
      </Table.Cell>
      <Table.Cell>
        <FormTemplatesActionButtons formTemplate={formTemplate} />
      </Table.Cell>
    </Table.Row>
  );
}
