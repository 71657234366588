export function contracttoBuyandSellResidential() {
return [
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date", 
   top: 169.68,
   left: 434.64,
   width: 138.00,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Buyer", 
   top: 271.44,
   left: 138,
   width: 349.2,
   height: 10.56
}
,
{
   page: 0,
   type: "checkbox",
   name: "No Assignability This Contract IS NOT assignable by Buyer unless otherwise specified in Additional Provisions", 
   top: 284.04,
   left: 194.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Tenants In Common", 
   top: 284.04,
   left: 271.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "undefined", 
   top: 284.04,
   left: 379.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Other", 
   top: 282.96,
   left: 420.72,
   width: 151.80,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller", 
   top: 312.00,
   left: 136.32,
   width: 350.88,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Property The Property is the following legally described real estate in the County of", 
   top: 335.04,
   left: 444.12,
   width: 83.40,
   height: 10.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text1", 
   top: 345.602,
   left: 152.509,
   width: 420.744,
   height: 65.454
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "known as", 
   top: 412.942,
   left: 96.24,
   width: 476.28,
   height: 13.178
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "including", 
   top: 523.953,
   left: 94.2,
   width: 34.44,
   height: 12.414
}
,
{
   page: 0,
   type: "checkbox",
   name: "Satellite Systems including satellite dishes Leased items should be listed under  257", 
   top: 526.20,
   left: 462.6,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "in kitchen appliances sprinkler systems and controls builtin vacuum systems including accessories and garage door openers", 
   top: 526.20,
   left: 535.44,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Leased Items If any additional items are attached to the Property after the date of this Contract such additional items are also", 
   top: 537.72,
   left: 98.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "remote controls If checked the following are owned by the Seller and included", 
   top: 537.72,
   left: 194.04,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "CBS1", 
   top: 631.330,
   left: 112.058,
   width: 464.968,
   height: 61.048
}
,
{
   page: 0,
   type: "checkbox",
   name: "property outside of this Contract", 
   top: 698.64,
   left: 105.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text2", 
   top: 90.982,
   left: 53.8034,
   width: 514.9986,
   height: 37.310
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Note to Buyer If exact rights to the parking and storage facilities is a concern to Buyer Buyer should investigate", 
   top: 160.80,
   left: 50.4,
   width: 144.0,
   height: 10.68
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined_2", 
   top: 160.80,
   left: 440.28,
   width: 132.24,
   height: 10.68
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Text3", 
   top: 206.837,
   left: 73.1784,
   width: 492.3516,
   height: 43.200
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 9,
   type: "textarea",
   name: "Text4", 
   top: 264.438,
   left: 73.702,
   width: 489.864,
   height: 35.345
}
,
{
   page: 1,
   type: "checkbox",
   name: "Check Box23", 
   top: 311.039,
   left: 51.3162,
   width: 9.4254,
   height: 10.996
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Text5", 
   top: 323.606,
   left: 75.4034,
   width: 490.6456,
   height: 32.989
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Other Rights Relating to Water The following rights relating to water not included in  271 273 and", 
   top: 357.447,
   left: 374.88,
   width: 107.76,
   height: 10.680
}
,
{
   page: 1,
   type: "checkbox",
   name: "Check Box24", 
   top: 368.639,
   left: 51.3162,
   width: 9.4254,
   height: 10.472
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text6", 
   top: 392.202,
   left: 76.9742,
   width: 495.8818,
   height: 47.127
}
,
{
   page: 1,
   type: "checkbox",
   name: "Check Box32", 
   top: 437.563,
   left: 51.3818,
   width: 9.8182,
   height: 10.473
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "274", 
   top: 505.92,
   left: 50.4,
   width: 90.0,
   height: 10.68
}
,
{
   page: 1,
   type: "checkbox",
   name: "Check Box33", 
   top: 518.727,
   left: 51.0545,
   width: 9.1637,
   height: 9.818
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Text7", 
   top: 528.347,
   left: 74.9452,
   width: 498.4348,
   height: 36.655
}
,
{
   page: 1,
   type: "checkbox",
   name: "Does", 
   top: 599.16,
   left: 267.84,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   type: "checkbox",
   name: "Does Not have a Right to Terminate if examination of the Water", 
   top: 599.16,
   left: 306.84,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   name: "Date or DeadlineTime of Day Deadline", 
   fontSize: 9,
   type: "text",
   top: 675.637,
   left: 365.8,
   width: 209,
   height: 10.523
}
,
{
   page: 1,
   name: "Date or DeadlineAlternative Earnest Money Deadline", 
   fontSize: 9,
   type: "text",
   top: 687.12,
   left: 365.8,
   width: 139.155,
   height: 10.5227
}
,
{
   page: 1,
   name: "DateField Date or DeadlineAlternative Earnest Money Deadline", 
   fontSize: 9,
   type: "date",
   top: 687.1,
   left: 505.357,
   width: 73.700,
   height: 10
}
,
{
   page: 1,
   name: "Date or DeadlineRecord Title Deadline and Tax Certificate", 
   fontSize: 9,
   type: "text",
   top: 711.12,
   left: 365.8,
   width: 139.155,
   height: 10.5227
}
,
{
   page: 1,
   name: "DateField Date or DeadlineRecord Title Deadline and Tax Certificate", 
   fontSize: 9,
   type: "date",
   top: 711.1,
   left: 505.357,
   width: 73.700,
   height: 10
}
,
{
   page: 1,
   name: "Date or DeadlineRecord Title Objection Deadline", 
   fontSize: 9,
   type: "text",
   top: 723.12,
   left: 365.8,
   width: 139.155,
   height: 10.5227
}
,
{
   page: 1,
   name: "DateField Date or DeadlineRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 723.1,
   left: 505.357,
   width: 73.700,
   height: 10
}
,
{
   page: 2,
   name: "OffRecord Title Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 59.077,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField OffRecord Title Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 59.492,
   left: 508.607,
   width: 69.834,
   height: 10.006
}
,
{
   page: 2,
   name: "OffRecord Title Objection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 70.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField OffRecord Title Objection Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 70.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Title Resolution Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 82.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Title Resolution Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 82.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Third Party Right to PurchaseApprove Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 94.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Third Party Right to PurchaseApprove Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 94.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Association Documents Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 118.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Association Documents Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 118.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Association Documents Termination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 130.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Association Documents Termination Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 130.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Sellers Property Disclosure Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 154.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Sellers Property Disclosure Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 154.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "LeadBased Paint Disclosure Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 166.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField LeadBased Paint Disclosure Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 166.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "New Loan Application Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.032,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField New Loan Application Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.032,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "New Loan Terms Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 202.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField New Loan Terms Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 202.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "New Loan Availability Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 214.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField New Loan Availability Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 214.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Buyers Credit Information Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 226.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Buyers Credit Information Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 226.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Disapproval of Buyers Credit Information Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 238.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Disapproval of Buyers Credit Information Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 238.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Existing Loan Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 250.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Existing Loan Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 250.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Existing Loan Termination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 262.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Existing Loan Termination Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 262.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Loan Transfer Approval Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 274.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Loan Transfer Approval Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 274.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Seller or Private Financing Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 286.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Seller or Private Financing Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 286.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Appraisal Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 310.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Appraisal Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 310.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Appraisal Objection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 322.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Appraisal Objection Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 322.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Appraisal Resolution Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 334.458,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Appraisal Resolution Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 334.458,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "New ILC or New Survey Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 358.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField New ILC or New Survey Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 358.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "New ILC or New Survey Objection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 370.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField New ILC or New Survey Objection Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 370.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "New ILC or New Survey Resolution Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 382.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField New ILC or New Survey Resolution Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 382.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Water Rights Examination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 406.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Water Rights Examination Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 406.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Mineral Rights Examination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 418.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Mineral Rights Examination Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 418.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Inspection Termination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 430.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Inspection Termination Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 430.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Inspection Objection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 442.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Inspection Objection Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 442.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Inspection Resolution Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 454.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Inspection Resolution Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 454.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Property Insurance Termination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 466.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Property Insurance Termination Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 466.578,
   left: 508.09,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Due Diligence Documents Delivery Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 478.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Due Diligence Documents Delivery Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 478.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Due Diligence Documents Objection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 490.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Due Diligence Documents Objection Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 490.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Due Diligence Documents Resolution Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 502.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Due Diligence Documents Resolution Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 502.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Conditional Sale Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 514.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Conditional Sale Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 514.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "LeadBased Paint Termination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 526.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField LeadBased Paint Termination Deadline", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 526.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Closing Date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 550.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Closing Date", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 550.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Possession Date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 562.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Possession Date", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 562.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Possession Time", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 575,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "Acceptance Deadline Date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 586.578,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 2,
   name: "DateField Acceptance Deadline Date", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 586.578,
   left: 508.607,
   width: 69.834,
   height: 10.005
}
,
{
   page: 2,
   name: "Acceptance Deadline Time", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 599.203,
   left: 371.8,
   width: 135.017,
   height: 10.523
}
,
{
   page: 3,
   type: "checkbox",
   name: "Will", 
   top: 197.04,
   left: 89.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   type: "checkbox",
   name: "Will Not be extended to the next day that is not a Saturday Sunday or Holiday Should neither box be checked", 
   top: 197.04,
   left: 125.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Earnest Money", 
   top: 272.956,
   left: 381.796,
   width: 88.353,
   height: 11.040
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_25", 
   top: 285.00,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_27", 
   top: 297.00,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_29", 
   top: 309.00,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_31", 
   top: 321.00,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Cash at Closing", 
   top: 332.989,
   left: 481.287,
   width: 90.447,
   height: 10.517
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_32", 
   top: 369.12,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_34", 
   top: 381.12,
   left: 379.8,
   width: 91.92,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "fill_35", 
   top: 381.12,
   left: 481.8,
   width: 89.64,
   height: 11.04
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Concession may be used for any Buyer fee cost charge or expenditure to the extent the amount is allowed by the Buyers lender", 
   top: 398.400,
   left: 368.04,
   width: 69.84,
   height: 10.975
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Earnest Money Holder in its trust account on behalf of", 
   top: 467.400,
   left: 432.24,
   width: 109.80,
   height: 10.647
}
,
{
   page: 3,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "both Seller and Buyer The Earnest Money deposit must be tendered by Buyer with this Contract unless the parties mutually agree", 
   top: 478.920,
   left: 143.52,
   width: 199.68,
   height: 11.302
}
,
{
   page: 4,
   type: "checkbox",
   name: "Available Funds Buyer represents that Buyer as of the date of this Contract", 
   top: 173.52,
   left: 462,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Does_2", 
   top: 173.52,
   left: 502.2,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "171", 
   top: 277.08,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Conventional", 
   top: 277.08,
   left: 127.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "FHA", 
   top: 277.08,
   left: 167.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "VA", 
   top: 277.08,
   left: 201,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Bond", 
   top: 277.08,
   left: 242.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "If either or both of the FHA or VA boxes are checked and Buyer closes the transaction using one of those loan types Seller agrees", 
   top: 276.24,
   left: 284.16,
   width: 288.36,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Loan Estimate  Monthly Payment and Loan Costs Buyer is advised to review the terms conditions and", 
   top: 299.16,
   left: 411.36,
   width: 161.16,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "set forth in  41 Price and Terms presently payable at", 
   top: 367.92,
   left: 282.84,
   width: 69.96,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "per", 
   top: 367.92,
   left: 369.84,
   width: 79.92,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "presently at the rate of", 
   top: 379.44,
   left: 142.08,
   width: 39.84,
   height: 10.32
}
,
{
   page: 4,
   type: "checkbox",
   name: "per annum and also including escrow for the following as indicated", 
   top: 380.64,
   left: 470.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Real Estate Taxes", 
   top: 380.64,
   left: 565.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Property Insurance Premium", 
   top: 392.16,
   left: 182.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Mortgage Insurance Premium and", 
   top: 392.16,
   left: 347.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "At the time of assumption the new interest rate will", 
   top: 391.32,
   left: 360.96,
   width: 211.56,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "per annum and the new payment will not exceed", 
   top: 402.48,
   left: 294.72,
   width: 64.80,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "interest plus escrow if any If the actual principal balance of the existing loan at Closing is less than the Assumption Balance which", 
   top: 414.0,
   left: 97.2,
   width: 39.96,
   height: 10.2
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "per_2", 
   top: 414.0,
   left: 355.32,
   width: 64.80,
   height: 10.2
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "principal and", 
   top: 414.0,
   left: 439.08,
   width: 79.92,
   height: 10.2
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "provisions of the loan change Buyer has the Right to Terminate under  241 on or before Closing Date", 
   top: 436.92,
   left: 411.6,
   width: 65.04,
   height: 10.32
}
,
{
   page: 4,
   type: "checkbox",
   name: "Seller_2", 
   top: 461.16,
   left: 99.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "Will_2", 
   top: 461.16,
   left: 135.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "from liability will be evidenced by delivery", 
   top: 472.68,
   left: 231.36,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "on or before Loan Transfer Approval Deadline", 
   top: 472.68,
   left: 449.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "in an amount", 
   top: 483.24,
   left: 412.32,
   width: 106.32,
   height: 10.32
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller or Private Financing", 
   top: 494.40,
   left: 111.24,
   width: 64.92,
   height: 10.32
}
,
{
   page: 4,
   type: "checkbox",
   name: "Seller Financing If Buyer is to pay all or any portion of the Purchase Price with Seller financing", 
   top: 564.60,
   left: 536.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   type: "checkbox",
   name: "197", 
   top: 576.12,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller will deliver the proposed Seller financing documents to the other party on or before", 
   top: 574.92,
   left: 439.8,
   width: 45.0,
   height: 10.32
}
,
{
   page: 6,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "and option of proceeding with the consummation of this Contract without regard to the amount of the appraised valuation The", 
   top: 103.32,
   left: 313.68,
   width: 69.84,
   height: 10.32
}
,
{
   page: 6,
   type: "checkbox",
   name: "Buyer_2", 
   top: 288.48,
   left: 536.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 6,
   type: "checkbox",
   name: "Seller The cost of the Appraisal may include any and all fees paid to the appraiser appraisal management company lenders", 
   top: 300.00,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Check Box34", 
   top: 357.709,
   left: 51.0545,
   width: 9.8182,
   height: 10.145
}
,
{
   page: 7,
   type: "checkbox",
   name: "an Abstract of Title certified to a current date Seller will cause the title insurance policy to be issued", 
   top: 392.64,
   left: 154.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Check Box35", 
   top: 415.309,
   left: 50.7272,
   width: 10.1455,
   height: 9.491
}
,
{
   page: 7,
   type: "checkbox",
   name: "Will_3", 
   top: 461.64,
   left: 411.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Will Not contain Owners", 
   top: 461.64,
   left: 452.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Buyer_3", 
   top: 519.12,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Seller_3", 
   top: 519.12,
   left: 93.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller", 
   top: 519.12,
   left: 137.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 7,
   type: "checkbox",
   name: "Other_2", 
   top: 519.12,
   left: 339,
   width: 10.08,
   height: 9.96
}
,
{
   page: 7,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Regardless of whether the Contract requires OEC the Title Insurance Commitment may not provide OEC or delete or insure over", 
   top: 517.92,
   left: 378,
   width: 129.84,
   height: 10.32
}
,
{
   page: 8,
   type: "checkbox",
   name: "Seller_4", 
   top: 449.64,
   left: 291.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 8,
   type: "checkbox",
   name: "Buyer for the Property listing any special taxing districts", 
   top: 449.64,
   left: 333.96,
   width: 10.08,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "Does_3", 
   top: 449.64,
   left: 245.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "Does Not have a Right to Terminate if examination of the Mineral", 
   top: 449.64,
   left: 287.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "New Improvement Location Certificate New ILC or 2", 
   top: 496.08,
   left: 317.204,
   width: 9.436,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "New Survey in the form of", 
   top: 507.60,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "New ILC or New Survey If the box is checked 1", 
   top: 506.40,
   left: 175.636,
   width: 215.040,
   height: 10.32
}
,
{
   page: 9,
   type: "checkbox",
   name: "Seller_5", 
   top: 519.12,
   left: 309.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "Buyer will order the New ILC or New Survey The", 
   top: 519.12,
   left: 353.76,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "Seller_6", 
   top: 565.08,
   left: 102.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   type: "checkbox",
   name: "Buyer or", 
   top: 565.08,
   left: 145.92,
   width: 9.96,
   height: 9.96
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text25", 
   top: 565.002,
   left: 201.076,
   width: 373.351,
   height: 34.560
}
,
{
   page: 9,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Delivery of New ILC or New Survey Buyer Seller the issuer of the Title Commitment or the provider of", 
   top: 609.960,
   left: 237.72,
   width: 104.88,
   height: 11.367
}
,
{
   page: 10,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "CBS1_9", 
   top: 721.451,
   left: 91.898,
   width: 485.782,
   height: 29.269
}
,
{
   page: 11,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text14", 
   top: 80.116,
   left: 145.897,
   width: 429.381,
   height: 37.178
}
,
{
   page: 11,
   type: "checkbox",
   name: "Will_4", 
   top: 139.08,
   left: 359.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Will Not assume the Sellers obligations", 
   top: 139.08,
   left: 395.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Will_5", 
   top: 196.56,
   left: 510.36,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Will_6", 
   top: 196.56,
   left: 544.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text15", 
   top: 240.348,
   left: 142.429,
   width: 434.616,
   height: 46.080
}
,
{
   page: 11,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "the Right to Terminate under  241 effective upon Sellers receipt of Buyers Notice to Terminate on or before Conditional Sale", 
   top: 436.92,
   left: 221.88,
   width: 296.64,
   height: 10.68
}
,
{
   page: 11,
   type: "checkbox",
   name: "Does_4", 
   top: 495.60,
   left: 483.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Does Not", 
   top: 495.60,
   left: 523.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "There is No Well  Buyer", 
   top: 518.64,
   left: 106.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Does_5", 
   top: 518.64,
   left: 224.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 11,
   type: "checkbox",
   name: "Does Not acknowledge receipt of a copy of the current well permit", 
   top: 518.64,
   left: 263.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "Seller will sign and complete all customary or reasonably required documents at or before Closing", 
   top: 341.52,
   left: 430.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "additional information and documents required by Closing Company that will be necessary to complete this transaction Buyer and", 
   top: 341.52,
   left: 467.76,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Disclosure of Settlement Costs Buyer and Seller acknowledge that costs quality and extent of service vary between", 
   top: 386.40,
   left: 304.68,
   width: 200.04,
   height: 10.32
}
,
{
   page: 12,
   type: "checkbox",
   name: "TRANSFER OF TITLE Subject to Buyers compliance with the terms and provisions of this Contract including the tender", 
   top: 480.12,
   left: 565.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "of any payment due at Closing Seller must execute and deliver the following good and sufficient deed to Buyer at Closing", 
   top: 491.64,
   left: 141.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "bargain and sale deed", 
   top: 491.64,
   left: 251.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "quit claim deed", 
   top: 491.64,
   left: 358.56,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "personal representatives deed", 
   top: 491.64,
   left: 440.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "Check Box36", 
   top: 502.693,
   left: 50.8082,
   width: 10.3926,
   height: 10.008
}
,
{
   page: 12,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "sufficient special warranty deed to Buyer at Closing", 
   top: 501.960,
   left: 65.52,
   width: 139.80,
   height: 13.462
}
,
{
   page: 12,
   type: "checkbox",
   name: "to be paid at Closing except as otherwise provided herein However if Buyers loan specified in 453 Loan Limitations prohibits", 
   top: 676.56,
   left: 488.04,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "Seller_7", 
   top: 676.56,
   left: 537.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "Buyer from paying for any of the fees contained in this Section the fees will be paid for by Seller", 
   top: 688.08,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   type: "checkbox",
   name: "Closing Services Fee The fee for real estate closing services must be paid at Closing by", 
   top: 688.08,
   left: 252.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 12,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Association Fees and Required Disbursements At least fourteen days prior to Closing Date Seller agrees to", 
   top: 687.24,
   left: 294.36,
   width: 278.16,
   height: 10.32
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_4", 
   top: 58.56,
   left: 536.88,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_8", 
   top: 70.08,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_2", 
   top: 70.08,
   left: 94.56,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA", 
   top: 70.08,
   left: 296.4,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_5", 
   top: 81.48,
   left: 395.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_9", 
   top: 81.48,
   left: 439.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer", 
   top: 81.48,
   left: 482.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_2", 
   top: 93.00,
   left: 154.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_6", 
   top: 127.56,
   left: 63.6,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_10", 
   top: 127.56,
   left: 108.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_3", 
   top: 127.56,
   left: 152.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_3", 
   top: 127.56,
   left: 354.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Other Fees Any other fee listed in the Status Letter as required to be paid at Closing will be paid by", 
   top: 139.08,
   left: 565.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_11", 
   top: 150.48,
   left: 82.2,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_4", 
   top: 150.48,
   left: 125.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_4", 
   top: 150.48,
   left: 327.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_7", 
   top: 162.00,
   left: 414.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_12", 
   top: 162.00,
   left: 462.36,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by", 
   top: 162.00,
   left: 508.2,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_5", 
   top: 173.52,
   left: 183.36,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_8", 
   top: 196.56,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_13", 
   top: 196.56,
   left: 96.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_5", 
   top: 196.56,
   left: 139.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_6", 
   top: 196.56,
   left: 341.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_9", 
   top: 219.48,
   left: 486.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_14", 
   top: 219.48,
   left: 536.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_6", 
   top: 231.00,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_7", 
   top: 231.00,
   left: 250.44,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Water StockCertificates", 
   top: 252.840,
   left: 55.44,
   width: 60.00,
   height: 11.891
}
,
{
   page: 13,
   type: "checkbox",
   name: "undefined_3", 
   top: 265.56,
   left: 73.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Water District", 
   top: 265.56,
   left: 231.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Augmentation Membership", 
   top: 277.08,
   left: 73.68,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Small Domestic Water Company", 
   top: 277.08,
   left: 231.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Check Box37", 
   top: 277.521,
   left: 411.084,
   width: 9.623,
   height: 10.007
}
,
{
   page: 13,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "NA_8", 
   top: 276.24,
   left: 424.56,
   width: 148.08,
   height: 10.32
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_10", 
   top: 288.48,
   left: 177.6,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_15", 
   top: 288.48,
   left: 222.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Utility Transfer Fees Utility transfer fees can change Any fees to transfer utilities from Seller to Buyer must be", 
   top: 288.48,
   left: 266.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "undefined_4", 
   top: 288.48,
   left: 465.6,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_11", 
   top: 311.52,
   left: 85.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller_16", 
   top: 311.52,
   left: 130.92,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "OneHalf by Buyer and OneHalf by Seller_8", 
   top: 311.52,
   left: 174.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "NA_9", 
   top: 311.52,
   left: 376.44,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "IS a foreign", 
   top: 357.60,
   left: 514.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Taxes for the Calendar Year Immediately Preceding Closing", 
   top: 530.64,
   left: 189.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Most Recent Mill Levy", 
   top: 530.64,
   left: 466.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Other_3", 
   top: 553.56,
   left: 141.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Rents Rents based on", 
   top: 552.72,
   left: 182.4,
   width: 264.12,
   height: 10.32
}
,
{
   page: 13,
   type: "checkbox",
   name: "Rents Actually Received", 
   top: 565.08,
   left: 238.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Accrued At Closing Seller will transfer or credit", 
   top: 565.08,
   left: 362.16,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Final Settlement Unless otherwise specified in Additional Provisions these prorations are final", 
   top: 598.44,
   left: 488.76,
   width: 84.84,
   height: 10.32
}
,
{
   page: 13,
   type: "checkbox",
   name: "Buyer_12", 
   top: 668.64,
   left: 403.44,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   type: "checkbox",
   name: "Seller Except however any", 
   top: 668.64,
   left: 448.92,
   width: 9.96,
   height: 9.96
}
,
{
   page: 13,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Association Assessments are subject to change as provided in the Governing Documents", 
   top: 713.40,
   left: 50.4,
   width: 149.88,
   height: 10.32
}
,
{
   page: 14,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "notwithstanding  33 Day from Possession Date and Possession Time until possession is delivered", 
   top: 91.80,
   left: 377.52,
   width: 69.12,
   height: 10.68
}
,
{
   page: 14,
   type: "checkbox",
   name: "Does Not represent that Buyer will occupy the Property as Buyers principal residence", 
   top: 127.56,
   left: 80.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 14,
   type: "checkbox",
   name: "If the box is checked Buyer and Seller agree to execute a PostClosing Occupancy Agreement", 
   top: 145.08,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 15,
   type: "checkbox",
   name: "Check Box26", 
   top: 93.207,
   left: 51.3162,
   width: 9.4254,
   height: 8.902
}
,
{
   page: 16,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Electronic Delivery Electronic Delivery of documents and notice may be delivered by 1 email at the email address", 
   top: 230.4,
   left: 408.72,
   width: 164.88,
   height: 10.2
}
,
{
   page: 16,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text16", 
   top: 503.213,
   left: 53.9344,
   width: 509.4966,
   height: 126.196
}
,
{
   page: 16,
   name: "DocumentsPartOfContract", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 675.386,
   left: 53.3199,
   width: 509.4971,
   height: 73.4287
}
,
{
   page: 17,
   name: "DocumentsNOTPartOfContract", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 61.578,
   left: 47.6293,
   width: 530.7067,
   height: 39.802
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Buyers Name", 
   top: 139.2,
   left: 121.8,
   width: 182.52,
   height: 11.4
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Buyers Name_2", 
   top: 139.2,
   left: 393.24,
   width: 184.20,
   height: 11.4
}
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Buyers Signature", 
//    top: 177.0,
//    left: 50.4,
//    width: 185.324,
//    height: 11.4
// }
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 12,
//    type: "text",
//    name: "Text17", 
//    top: 176.465,
//    left: 236.683,
//    width: 67.549,
//    height: 12.043
// }
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Buyers Signature_2", 
//    top: 177.000,
//    left: 324.84,
//    width: 185.051,
//    height: 11.924
// }
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 12,
//    type: "text",
//    name: "Text18", 
//    top: 176.988,
//    left: 510.544,
//    width: 67.025,
//    height: 12.044
// }
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 1", 
   top: 212.04,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 1_2", 
   top: 212.04,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 2", 
   top: 224.04,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 2_2", 
   top: 224.04,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 3", 
   top: 236.04,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 3_2", 
   top: 236.04,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 4", 
   top: 248.04,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 4_2", 
   top: 248.04,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Email Address", 
   top: 260.04,
   left: 135.24,
   width: 169.08,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Email Address_2", 
   top: 260.04,
   left: 409.8,
   width: 167.64,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Sellers Name", 
   top: 306.96,
   left: 121.8,
   width: 182.52,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Sellers Name_2", 
   top: 306.96,
   left: 393.24,
   width: 184.20,
   height: 11.40
}
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Sellers Signature", 
//    top: 344.280,
//    left: 50.4,
//    width: 197.891,
//    height: 10.353
// }
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 12,
//    type: "text",
//    name: "Text20", 
//    top: 344.028,
//    left: 249.774,
//    width: 56.029,
//    height: 12.043
// }
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 10,
//    type: "signature",
//    name: "Sellers Signature_2", 
//    top: 345.851,
//    left: 324.84,
//    width: 191.858,
//    height: 9.829
// }
// ,
// {
//    page: 17,
//    fontName: "Helvetica",
//    fontSize: 12,
//    type: "text",
//    name: "Text19", 
//    top: 345.599,
//    left: 517.875,
//    width: 59.694,
//    height: 10.472
// }
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 1_3", 
   top: 379.44,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 1_4", 
   top: 379.44,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 2_3", 
   top: 391.44,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 2_4", 
   top: 391.44,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 3_3", 
   top: 403.44,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 3_4", 
   top: 403.44,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 4_3", 
   top: 415.44,
   left: 135.96,
   width: 168.36,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 4_4", 
   top: 415.44,
   left: 410.52,
   width: 166.92,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Email Address_3", 
   top: 427.44,
   left: 135.24,
   width: 169.08,
   height: 11.40
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Email Address_4", 
   top: 427.44,
   left: 409.8,
   width: 167.64,
   height: 11.40
}
,
{
   page: 17,
   type: "checkbox",
   name: "Does_6", 
   top: 559.08,
   left: 89.76,
   width: 9.96,
   height: 9.96
}
,
{
   page: 17,
   type: "checkbox",
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the Earnest", 
   top: 559.08,
   left: 130.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 17,
   type: "checkbox",
   name: "Buyers Agent", 
   top: 628.56,
   left: 199.32,
   width: 9.96,
   height: 9.96
}
,
{
   page: 17,
   type: "checkbox",
   name: "TransactionBroker in this transaction", 
   top: 628.56,
   left: 282.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 17,
   type: "checkbox",
   name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
   top: 652.08,
   left: 56.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 17,
   type: "checkbox",
   name: "Listing Brokerage Firm", 
   top: 675.60,
   left: 309.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 17,
   type: "checkbox",
   name: "Buyer_13", 
   top: 675.60,
   left: 427.8,
   width: 9.96,
   height: 9.96
}
,
{
   page: 17,
   type: "checkbox",
   name: "Other_4", 
   top: 675.60,
   left: 472.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 17,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined_5", 
   top: 673.68,
   left: 512.88,
   width: 65.04,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms Name 1", 
   top: 69.24,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms License 1", 
   top: 81.24,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms Name 2", 
   top: 93.24,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms License 2", 
   top: 105.24,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "signature",
   name: "Broker Signature", 
   top: 129.240,
   left: 180.96,
   width: 317.335,
   height: 11.924
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 12,
   type: "date",
   name: "Text21",   // Broker sig date 
   top: 129.338,
   left: 499.024,
   width: 83.258,
   height: 12.043
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 1_5", 
   top: 164.16,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 2_5", 
   top: 176.16,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 3_5", 
   top: 188.16,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 4_5", 
   top: 200.16,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 5", 
   top: 212.16,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 6", 
   top: 224.16,
   left: 180.96,
   width: 401.64,
   height: 11.40
}
,
{
   page: 18,
   type: "checkbox",
   name: "Does_7", 
   top: 292.56,
   left: 89.76,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the Earnest_2", 
   top: 292.56,
   left: 130.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Sellers Agent", 
   top: 362.16,
   left: 198.24,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "TransactionBroker in this transaction_2", 
   top: 362.16,
   left: 276.96,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Customer  Broker has no brokerage relationship with Seller See  A for Brokers brokerage relationship with Buyer", 
   top: 385.56,
   left: 56.52,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Seller_17", 
   top: 409.08,
   left: 315.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Buyer_14", 
   top: 409.08,
   left: 359.28,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   type: "checkbox",
   name: "Other_5", 
   top: 409.08,
   left: 404.4,
   width: 9.96,
   height: 9.96
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined_6", 
   top: 407.16,
   left: 445.8,
   width: 132.12,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms Name 1_2", 
   top: 477.84,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms License 1_2", 
   top: 489.84,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms Name 2_2", 
   top: 501.84,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firms License 2_2", 
   top: 513.84,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "signature",
   name: "Brokerage Firms License 3_2", 
   top: 537.84,
   left: 180.96,
   width: 315.917,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 12,
   type: "date",
   name: "Text22", 
   top: 537.773,
   left: 497.453,
   width: 80.639,
   height: 12.043
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 1_6", 
   top: 572.76,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 2_6", 
   top: 584.76,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 3_6", 
   top: 596.76,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 4_6", 
   top: 608.76,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 5_2", 
   top: 620.76,
   left: 180.96,
   width: 397.08,
   height: 11.40
}
,
{
   page: 18,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Date 6_2", 
   top: 632.76,
   left: 180.24,
   width: 397.80,
   height: 11.40
}
] }
