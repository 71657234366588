export function addendumLeadBasedPaint() {
return [   //2024 Release 2024-02-18 23:16:50
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 115.154,
   left: 203.64,
   width: 371.04,
   height: 11.215
}
,
{
   page: 0,
   name: "Check Box7", 
   isText: false,
   type: "checkbox",
   top: 281.787,
   left: 66.533,
   width: 11.151,
   height: 11.151
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 281.335,
   left: 532.2,
   width: 46.8,
   height: 11.705
}
,
{
   page: 0,
   name: "b Seller has no actual knowledge of leadbased paint andor leadbased paint hazards in the Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 294,
   left: 92.88,
   width: 481.56,
   height: 10.32
}
,
{
   page: 0,
   name: "Check Box8", 
   isText: false,
   type: "checkbox",
   top: 305.269,
   left: 67.0222,
   width: 10.6618,
   height: 9.684
}
,
{
   page: 0,
   name: "Check Box9", 
   isText: false,
   type: "checkbox",
   top: 325.816,
   left: 66.533,
   width: 10.6618,
   height: 10.662
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 338.04,
   left: 392.16,
   width: 186.84,
   height: 10.32
}
,
{
   page: 0,
   name: "b Seller has no reports or records pertaining to leadbased paint andor leadbased paint hazards in the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 349.32,
   left: 92.88,
   width: 476.40,
   height: 10.449
}
,
{
   page: 0,
   name: "Check Box10", 
   isText: false,
   type: "checkbox",
   top: 360.061,
   left: 66.0438,
   width: 10.1726,
   height: 9.683
}
,
{
   page: 0,
   name: "Check Box11", 
   isText: false,
   type: "checkbox",
   top: 392.349,
   left: 52.835,
   width: 10.1726,
   height: 10.662
}
,
{
   page: 0,
   name: "Check Box12", 
   isText: false,
   type: "checkbox",
   top: 413.875,
   left: 53.3242,
   width: 10.1726,
   height: 11.151
}
,
{
   page: 0,
   name: "Check Box13", 
   isText: false,
   type: "checkbox",
   top: 468.666,
   left: 53.3242,
   width: 10.6619,
   height: 11.151
}
,
{
   page: 0,
   name: "Check Box14", 
   isText: false,
   type: "checkbox",
   top: 479.918,
   left: 52.835,
   width: 11.151,
   height: 9.684
}
] }
