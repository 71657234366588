export function buyersCounteroffertoSellersCounteroffer() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 42", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 88.872,
   left: 435.893,
   width: 100.440,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 43", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 88.872,
   left: 553.613,
   width: 25.199,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 63", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 109.754,
   left: 264.24,
   width: 316.26,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 178.008,
   left: 166.608,
   width: 410.328,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 196.008,
   left: 31.5,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 35", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 214.296,
   left: 31.5,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 36", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 232.224,
   left: 31.536,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 41", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 250.152,
   left: 31.536,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 37", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 270.528,
   left: 131.616,
   width: 445.320,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 38", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 288.528,
   left: 31.5,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 39", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 306.816,
   left: 31.5,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 40", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 324.744,
   left: 31.536,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 46", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 433.344,
   left: 264.384,
   width: 30.240,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 47", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 433.344,
   left: 299.376,
   width: 20.160,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 44", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 433.344,
   left: 345.816,
   width: 95.184,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 62", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 433.344,
   left: 447.12,
   width: 129.852,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 45", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 454.848,
   left: 42.624,
   width: 50.040,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 490", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 474.088,
   left: 31.5,
   width: 260.46,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 51", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 504.328,
   left: 31.5,
   width: 260.46,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 57", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 565.728,
   left: 109.908,
   width: 275.112,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 58", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 565.728,
   left: 402.3,
   width: 55.08,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 59", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 565.728,
   left: 474.66,
   width: 69.912,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 60", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 565.728,
   left: 549.036,
   width: 19.800,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 53", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 585.312,
   left: 32.04,
   width: 260.46,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 55", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 609.432,
   left: 32.04,
   width: 260.46,
   height: 13.500
}
] }
