export function contracttoBuyandSellResidential2024() {
return [   //2024 Release 2024-08-02 16:45:21
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 199.2,
   left: 407.16,
   width: 155.04,
   height: 12.36
}
,
{
   page: 0,
   name: "Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 300.48,
   left: 126,
   width: 399.96,
   height: 12.36
}
,
{
   page: 0,
   name: "Joint Tenants", 
   isText: false,
   type: "checkbox",
   top: 333.72,
   left: 96.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Tenants In Common", 
   isText: false,
   type: "checkbox",
   top: 351,
   left: 95.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 368.28,
   left: 95.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 365.76,
   left: 139.92,
   width: 425.04,
   height: 12.36
}
,
{
   page: 0,
   name: "Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 400.08,
   left: 121.8,
   width: 429.96,
   height: 12.36
}
,
{
   page: 0,
   name: "Property The Property is the following legally described real estate in the County of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 432.72,
   left: 437.28,
   width: 125.04,
   height: 12.36
}
,
{
   page: 0,
   name: "Text1", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 9,
   top: 458.838,
   left: 66.1094,
   width: 508.0376,
   height: 81.564
}
,
{
   page: 0,
   name: "known as", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 541.92,
   left: 110.64,
   width: 455.04,
   height: 12.36
}
,
{
   page: 0,
   name: "accessories and garage door openersincluding", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 675.6,
   left: 280.52,
   width: 54.96,
   height: 12.36
}
,
{
   page: 0,
   name: "Solar Panels", 
   isText: false,
   type: "checkbox",
   top: 704.52,
   left: 91.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Water Softeners", 
   isText: false,
   type: "checkbox",
   top: 718.44,
   left: 91.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Security Systems", 
   isText: false,
   type: "checkbox",
   top: 732.12,
   left: 91.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Satellite Systems including satellite dishes", 
   isText: false,
   type: "checkbox",
   top: 746.04,
   left: 91.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Text2", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 159.564,
   left: 75.9276,
   width: 493.6374,
   height: 139.164
}
,
{
   page: 1,
   name: "property outside of this Contract", 
   isText: false,
   type: "checkbox",
   top: 301.32,
   left: 77.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Text3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 403.202,
   left: 76.5822,
   width: 489.0558,
   height: 59.309
}
,
{
   page: 1,
   name: "review under 106 Encumbered Inclusion Documents and Buyers receipt of written approval by such lender before", 
   isText: false,
   type: "checkbox",
   top: 464.28,
   left: 109.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Will", 
   isText: false,
   type: "checkbox",
   top: 464.28,
   left: 154.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "257 Parking and Storage Facilities The use or ownership of the following parking facilities", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 539.76,
   left: 469.08,
   width: 84.96,
   height: 12.36
}
,
{
   page: 1,
   name: "91", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 552.96,
   left: 77.04,
   width: 249.96,
   height: 12.36
}
,
{
   page: 1,
   name: "Text4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.184,
   left: 76.5822,
   width: 470.0738,
   height: 12.182
}
,
{
   page: 1,
   name: "Text5", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 632.294,
   left: 75.9276,
   width: 487.7464,
   height: 77.6363
}
,
{
   page: 1,
   name: "Buyers review under 106 Leased Items Documents and Buyers receipt of written approval by such lender before", 
   isText: false,
   type: "checkbox",
   top: 711.72,
   left: 108.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Will_2", 
   isText: false,
   type: "checkbox",
   top: 711.72,
   left: 153.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "259", 
   isText: false,
   type: "checkbox",
   top: 47.88,
   left: 107.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "electricity Solar Power Plan that will remain in effect after Closing Buyer", 
   isText: false,
   type: "checkbox",
   top: 74.28,
   left: 387.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will_3", 
   isText: false,
   type: "checkbox",
   top: 74.28,
   left: 432.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Text6", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 130.91,
   left: 66.1094,
   width: 504.7656,
   height: 81.564
}
,
{
   page: 2,
   name: "271", 
   isText: false,
   type: "checkbox",
   top: 231.96,
   left: 103.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Text7", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 242.837,
   left: 74.6185,
   width: 498.8745,
   height: 49.491
}
,
{
   page: 2,
   name: "deed at Closing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 294.72,
   left: 346.44,
   width: 155.04,
   height: 12.36
}
,
{
   page: 2,
   name: "272", 
   isText: false,
   type: "checkbox",
   top: 316.44,
   left: 103.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Text8", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 340.365,
   left: 75.9276,
   width: 494.2924,
   height: 53.418
}
,
{
   page: 2,
   name: "273", 
   isText: false,
   type: "checkbox",
   top: 395.16,
   left: 105.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 470.64,
   left: 294.6,
   width: 270.0,
   height: 12.36
}
,
{
   page: 2,
   name: "274", 
   isText: false,
   type: "checkbox",
   top: 506.76,
   left: 107.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Text9", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 519.057,
   left: 75.2731,
   width: 492.3289,
   height: 44.909
}
,
{
   page: 2,
   name: "Time of Day Deadline", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 707.4,
   left: 402.12,
   width: 178.80,
   height: 20.76
}
,
{
   page: 2,
   name: "Date or Deadline DetailsAlternative Earnest Money Deadline", 
   fontSize: 9,
   type: "date",
   top: 729.48,
   left: 330.0,
   width: 69.32,
   height: 21.00
}
,
{
   page: 2,
   name: "Alternative Earnest Money Deadline", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 729.48,
   left: 402.12,
   width: 178.80,
   height: 21.00
}
,
{
   page: 3,
   name: "DateRecord Title Deadline and Tax Certificate", 
   fontSize: 9,
   type: "date",
   top: 59.16,
   left: 329.76,
   width: 69.80,
   height: 14.04
}
,
{
   page: 3,
   name: "DateRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 74.28,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateOffRecord Title Deadline", 
   fontSize: 9,
   type: "date",
   top: 89.16,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateOffRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 104.04,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateTitle Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 118.92,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateThird Party Right to PurchaseApprove Deadline", 
   fontSize: 9,
   type: "date",
   top: 133.8,
   left: 329.76,
   width: 69.80,
   height: 11.64
}
,
{
   page: 3,
   name: "DateAssociation Documents Deadline", 
   fontSize: 9,
   type: "date",
   top: 157.56,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateAssociation Documents Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 172.44,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateSellers Property Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 198.36,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateLeadBased Paint Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 213.24,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateNew Loan Application Deadline", 
   fontSize: 9,
   type: "date",
   top: 239.4,
   left: 329.76,
   width: 69.80,
   height: 13.8
}
,
{
   page: 3,
   name: "DateNew Loan Terms Deadline", 
   fontSize: 9,
   type: "date",
   top: 254.28,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateNew Loan Availability Deadline", 
   fontSize: 9,
   type: "date",
   top: 269.16,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateBuyers Credit Information Deadline", 
   fontSize: 9,
   type: "date",
   top: 284.04,
   left: 329.76,
   width: 69.80,
   height: 11.64
}
,
{
   page: 3,
   name: "DateDisapproval of Buyers Credit Information Deadline", 
   fontSize: 9,
   type: "date",
   top: 296.76,
   left: 329.76,
   width: 69.80,
   height: 11.88
}
,
{
   page: 3,
   name: "DateExisting Loan Deadline", 
   fontSize: 9,
   type: "date",
   top: 309.72,
   left: 329.76,
   width: 69.80,
   height: 11.64
}
,
{
   page: 3,
   name: "DateExisting Loan Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 322.44,
   left: 329.76,
   width: 69.80,
   height: 11.64
}
,
{
   page: 3,
   name: "DateLoan Transfer Approval Deadline", 
   fontSize: 9,
   type: "date",
   top: 335.16,
   left: 329.76,
   width: 69.80,
   height: 11.64
}
,
{
   page: 3,
   name: "DateSeller or Private Financing Deadline", 
   fontSize: 9,
   type: "date",
   top: 347.88,
   left: 329.76,
   width: 69.80,
   height: 11.64
}
,
{
   page: 3,
   name: "DateAppraisal Deadline", 
   fontSize: 9,
   type: "date",
   top: 373.32,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateAppraisal Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 388.2,
   left: 329.76,
   width: 69.80,
   height: 13.8
}
,
{
   page: 3,
   name: "DateAppraisal Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 403.08,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Deadline", 
   fontSize: 9,
   type: "date",
   top: 429.0,
   left: 329.76,
   width: 69.80,
   height: 13.8
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 443.88,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 458.76,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateWater Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 484.68,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateMineral Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 499.56,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateInspection Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 514.44,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateInspection Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 529.32,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateInspection Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 544.2,
   left: 329.76,
   width: 69.80,
   height: 13.8
}
,
{
   page: 3,
   name: "DateProperty Insurance Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 559.08,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Delivery Deadline", 
   fontSize: 9,
   type: "date",
   top: 574.615,
   left: 329.76,
   width: 69.80,
   height: 14.040
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 589.08,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 603.96,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateConditional Sale Deadline", 
   fontSize: 9,
   type: "date",
   top: 618.84,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DateLeadBased Paint Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 633.72,
   left: 329.76,
   width: 69.80,
   height: 12.491
}
,
{
   page: 3,
   name: "DateClosing Date", 
   fontSize: 9,
   type: "date",
   top: 660.36,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "DatePossession Date", 
   fontSize: 9,
   type: "date",
   top: 675.24,
   left: 329.76,
   width: 69.80,
   height: 13.80
}
,
{
   page: 3,
   name: "Possession Time", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 690.12,
   left: 401.411,
   width: 178.164,
   height: 12.80
}
,
{
   page: 3,
   name: "DateAcceptance Deadline Date", 
   fontSize: 9,
   type: "date",
   top: 704.5485,
   left: 329.76,
   width: 69.80,
   height: 14.4515
}
,
{
   page: 3,
   name: "Acceptance Deadline Time", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 719.88,
   left: 401.411,
   width: 178.164,
   height: 12.80
}
,
{
   page: 3,
   name: "Record Title Objection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 74.84,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "OffRecord Title Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 89.72,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "OffRecordTitleObjectionDeadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 104.909,
   left: 401.411,
   width: 178.164,
   height: 11.640
}
,
{
   page: 3,
   name: "TitleResolutionDeadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 119.825,
   left: 401.411,
   width: 178.164,
   height: 11.640
}
,
{
   page: 3,
   name: "Third Party Right to PurchaseApproveDeadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 133.36,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "AssociationDocuments Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 158.083,
   left: 401.411,
   width: 178.164,
   height: 11.640
}
,
{
   page: 3,
   name: "AssociationDocumentsTermination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 172.0,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "SellersPropertyDisclosure Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 199.229,
   left: 401.411,
   width: 178.164,
   height: 11.640
}
,
{
   page: 3,
   name: "LeadBasedPaint Disclosure Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 213.8,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "NewLoan Application Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 239.614,
   left: 401.411,
   width: 178.164,
   height: 11.640
}
,
{
   page: 3,
   name: "NewLoan Terms Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 253.84,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "NewLoan Availability Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 268.72,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "BuyersCredit Information Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 283.6,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "DisapprovalofBuyers Credit Information Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 296.32,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "ExistingLoan Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 309.28,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "ExistingLoan Termination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 322.0,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "LoanTransferApproval Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 334.72,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "SellerorPrivate Financing Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 347.99,
   left: 401.411,
   width: 178.164,
   height: 11.09
}
,
{
   page: 3,
   name: "AppraisalDeadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 373.534,
   left: 401.411,
   width: 178.164,
   height: 11.640
}
,
{
   page: 3,
   name: "AppraisalObjection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 388.76,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "AppraisalResolution Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 403.64,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 3,
   name: "NewILCorNew Survey Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 429.214,
   left: 401.411,
   width: 178.164,
   height: 13.146
}
,
{
   page: 3,
   name: "NewILCorNew Survey Objection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 443.44,
   left: 401.411,
   width: 178.164,
   height: 13.25
}
,
{
   page: 3,
   name: "NewILCorNew Survey Resolution Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 459.32,
   left: 401.411,
   width: 178.164,
   height: 11.80
}
,
{
   page: 3,
   name: "WaterRightsExamination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 485.24,
   left: 401.411,
   width: 178.164,
   height: 12.149
}
,
{
   page: 3,
   name: "MineralRightsExamination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 500.12,
   left: 401.411,
   width: 178.164,
   height: 11.599
}
,
{
   page: 3,
   name: "InspectionTermination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 515.0,
   left: 401.411,
   width: 178.164,
   height: 11.699
}
,
{
   page: 3,
   name: "InspectionObjection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 529.88,
   left: 401.411,
   width: 178.164,
   height: 11.699
}
,
{
   page: 3,
   name: "InspectionResolution Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 544.76,
   left: 401.411,
   width: 178.164,
   height: 11.699
}
,
{
   page: 3,
   name: "PropertyInsurance Termination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 559.64,
   left: 401.411,
   width: 178.164,
   height: 11.699
}
,
{
   page: 3,
   name: "DueDiligenceDocuments Delivery Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 574.52,
   left: 401.411,
   width: 178.164,
   height: 11.699
}
,
{
   page: 3,
   name: "DueDiligenceDocuments Objection Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 589.64,
   left: 401.411,
   width: 178.164,
   height: 11.699
}
,
{
   page: 3,
   name: "DueDiligenceDocuments Resolution Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 604.52,
   left: 401.411,
   width: 178.164,
   height: 11.699
}
,
{
   page: 3,
   name: "ConditionalSale Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 618.4,
   left: 401.411,
   width: 178.164,
   height: 12.8
}
,
{
   page: 3,
   name: "LeadBasedPaint Termination Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 633.28,
   left: 401.411,
   width: 178.164,
   height: 12.80
}
,
{
   page: 3,
   name: "Closing Date Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 660.574,
   left: 401.411,
   width: 178.164,
   height: 12.146
}
,
{
   page: 3,
   name: "AcceptanceDeadline Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 705.074,
   left: 401.411,
   width: 178.164,
   height: 12.800
}
,
{
   page: 3,
   name: "Possession Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 675.314,
   left: 401.411,
   width: 178.164,
   height: 12.800
}
,
{
   page: 3,
   name: "Extra2744", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 734.64,
   left: 77.64,
   width: 30.36,
   height: 10.08
}
,
{
   page: 3,
   name: "Extra2745", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 745.68,
   left: 77.64,
   width: 30.36,
   height: 10.08
}
,
{
   page: 3,
   name: "2744Title", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 734.64,
   left: 109.08,
   width: 219.48,
   height: 10.08
}
,
{
   page: 3,
   name: "2745Title", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 745.68,
   left: 109.08,
   width: 219.48,
   height: 10.08
}
,
{
   page: 3,
   name: "Date extra2744", 
   fontSize: 9,
   type: "date",
   top: 734.6251,
   left: 330.094,
   width: 67.488,
   height: 9.4255
}
,
{
   page: 3,
   name: "Date extra2745", 
   fontSize: 9,
   type: "date",
   top: 746.0105,
   left: 330.094,
   width: 67.488,
   height: 8.7710
}
,
{
   page: 3,
   name: "Details 2744", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 734.6251,
   left: 401.411,
   width: 178.164,
   height: 10.0800
}
,
{
   page: 3,
   name: "Details 2745", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 745.6651,
   left: 401.411,
   width: 178.164,
   height: 8.7709
}
,
{
   page: 3,
   name: "Record Title Deadline and Tax Certificate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 59.4,
   left: 401.411,
   width: 178.164,
   height: 11.64
}
,
{
   page: 4,
   name: "333 Deadlines If any deadline falls on a Saturday Sunday or federal or Colorado state holiday Holiday such Deadline", 
   isText: false,
   type: "checkbox",
   top: 313.08,
   left: 73.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "checked the deadline will not be extended", 
   isText: false,
   type: "checkbox",
   top: 313.08,
   left: 117.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Amount", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 398.215,
   left: 326.32,
   width: 93.56,
   height: 13.800
}
,
{
   page: 4,
   name: "Earnest Money", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 412.44,
   left: 434.324,
   width: 82.676,
   height: 13.80
}
,
{
   page: 4,
   name: "New Loan", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.32,
   left: 434.324,
   width: 82.676,
   height: 13.80
}
,
{
   page: 4,
   name: "Assumption Balance", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 442.2,
   left: 434.324,
   width: 82.676,
   height: 13.8
}
,
{
   page: 4,
   name: "Private Financing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 457.08,
   left: 434.324,
   width: 82.676,
   height: 13.80
}
,
{
   page: 4,
   name: "Seller Financing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 471.96,
   left: 434.324,
   width: 82.676,
   height: 13.80
}
,
{
   page: 4,
   name: " 477", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 486.84,
   left: 122.64,
   width: 52.68,
   height: 13.80
}
,
{
   page: 4,
   name: "Seller Financing7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 486.84,
   left: 176.64,
   width: 133.80,
   height: 13.80
}
,
{
   page: 4,
   name: "fill_28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 486.84,
   left: 319.4,
   width: 101.6,
   height: 13.80
}
,
{
   page: 4,
   name: "fill_29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 486.84,
   left: 434.324,
   width: 82.676,
   height: 13.80
}
,
{
   page: 4,
   name: " 478", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 501.72,
   left: 122.64,
   width: 52.68,
   height: 13.80
}
,
{
   page: 4,
   name: "Seller Financing8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 501.72,
   left: 176.64,
   width: 133.80,
   height: 13.80
}
,
{
   page: 4,
   name: " 4410", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 501.611,
   left: 320.35,
   width: 99.735,
   height: 15.109
}
,
{
   page: 4,
   name: "fill_30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 501.72,
   left: 434.324,
   width: 82.676,
   height: 13.80
}
,
{
   page: 4,
   name: "cashAtClosing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 516.6,
   left: 434.324,
   width: 82.676,
   height: 14.04
}
,
{
   page: 4,
   name: "fill_33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 531.72,
   left: 322.88,
   width: 98.12,
   height: 13.80
}
,
{
   page: 4,
   name: "fill_34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 531.72,
   left: 434.324,
   width: 82.676,
   height: 13.80
}
,
{
   page: 4,
   name: "Seller Concession The Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 555.36,
   left: 331.08,
   width: 110.04,
   height: 13.08
}
,
{
   page: 4,
   name: "EMform", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 640.56,
   left: 402.96,
   width: 165.00,
   height: 13.08
}
,
{
   page: 4,
   name: "Earnest Money Holder", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 654.48,
   left: 186.84,
   width: 280.08,
   height: 13.08
}
,
{
   page: 5,
   name: "funds that are immediately verifiable and available in an amount not less than the amount stated as Cash at Closing in  41", 
   isText: false,
   type: "checkbox",
   top: 425.4,
   left: 424.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Does", 
   isText: false,
   type: "checkbox",
   top: 425.4,
   left: 471.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "If either or both of the FHA or VA boxes are checked and Buyer closes the transaction using one of those loan types Seller", 
   isText: false,
   type: "checkbox",
   top: 574.44,
   left: 73.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Conventional", 
   isText: false,
   type: "checkbox",
   top: 574.44,
   left: 156.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "FHA", 
   isText: false,
   type: "checkbox",
   top: 574.44,
   left: 204.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "VA", 
   isText: false,
   type: "checkbox",
   top: 574.44,
   left: 245.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Bond", 
   isText: false,
   type: "checkbox",
   top: 574.44,
   left: 294.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 571.92,
   left: 341.04,
   width: 220.08,
   height: 12.36
}
,
{
   page: 5,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 604.32,
   left: 463.8,
   width: 94.92,
   height: 12.36
}
,
{
   page: 5,
   name: "per", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 708.96,
   left: 301.8,
   width: 70.08,
   height: 12.36
}
,
{
   page: 5,
   name: "including principal", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 708.96,
   left: 389.64,
   width: 99.96,
   height: 12.36
}
,
{
   page: 5,
   name: "per annum and also including escrow for the following as indicated", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 722.16,
   left: 204.6,
   width: 60.0,
   height: 12.36
}
,
{
   page: 6,
   name: "Real Estate Taxes", 
   isText: false,
   type: "checkbox",
   top: 45.72,
   left: 63.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Property Insurance Premium", 
   isText: false,
   type: "checkbox",
   top: 45.72,
   left: 165.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Mortgage Insurance Premium and", 
   isText: false,
   type: "checkbox",
   top: 45.72,
   left: 318.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Check Box20", 
   isText: false,
   type: "checkbox",
   top: 65.102,
   left: 63.1821,
   width: 10.0800,
   height: 10.080
}
,
{
   page: 6,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 62.4,
   left: 81.72,
   width: 465.00,
   height: 12.36
}
,
{
   page: 6,
   name: "At the time of assumption the new", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 81.6,
   left: 310.44,
   width: 99.96,
   height: 12.36
}
,
{
   page: 6,
   name: "per annum and the new payment will not exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 94.8,
   left: 176.76,
   width: 75.00,
   height: 12.36
}
,
{
   page: 6,
   name: "per_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 114.24,
   left: 78.48,
   width: 80.04,
   height: 12.36
}
,
{
   page: 6,
   name: "principal and interest plus escrow if any If the actual principal", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 114.24,
   left: 176.16,
   width: 110.04,
   height: 12.36
}
,
{
   page: 6,
   name: "or if any other terms or provisions of the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 140.64,
   left: 267.96,
   width: 120.00,
   height: 12.36
}
,
{
   page: 6,
   name: "Will_4", 
   isText: false,
   type: "checkbox",
   top: 175.56,
   left: 130.2,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Will Not be released from liability on said loan If applicable compliance with the", 
   isText: false,
   type: "checkbox",
   top: 175.56,
   left: 174.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "on or before Loan Transfer Approval Deadline", 
   isText: false,
   type: "checkbox",
   top: 203.16,
   left: 73.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "at Closing of an appropriate letter of commitment from lender Any cost payable for release of liability will be paid by", 
   isText: false,
   type: "checkbox",
   top: 216.84,
   left: 73.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "in an amount not to exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 234.636,
   left: 309.138,
   width: 212.387,
   height: 11.809
}
,
{
   page: 6,
   name: "undefined_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 247.294,
   left: 167.378,
   width: 84.960,
   height: 12.360
}
,
{
   page: 6,
   name: "Buyer_2", 
   isText: false,
   type: "checkbox",
   top: 387.28,
   left: 392.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Seller will deliver the", 
   isText: false,
   type: "checkbox",
   top: 387.28,
   left: 444.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "days before Seller or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 397.96,
   left: 352.08,
   width: 100.08,
   height: 12.36
}
,
{
   page: 7,
   name: "55 Buyer Representation of Principal Residence Buyer represents that Buyer will occupy the Property as Buyers 272", 
   isText: false,
   type: "checkbox",
   top: 466.44,
   left: 339.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "The purchaser", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 141.84,
   left: 421.8,
   width: 85.08,
   height: 12.36
}
,
{
   page: 8,
   name: "64 Cost of Appraisal Cost of the Appraisal to be obtained after the date of this Contract must be timely paid by", 
   isText: false,
   type: "checkbox",
   top: 414.12,
   left: 63.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "company lenders agent or all three", 
   isText: false,
   type: "checkbox",
   top: 414.12,
   left: 116.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "to furnish the owners title insurance policy at Sellers expense On or before Record Title Deadline Seller must furnish to", 
   isText: false,
   type: "checkbox",
   top: 634.68,
   left: 103.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "policy to be issued and delivered to Buyer as soon as practicable at or after Closing", 
   isText: false,
   type: "checkbox",
   top: 674.28,
   left: 201.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "to furnish the owners title insurance policy at Buyers expense On or before Record Title Deadline Buyer must furnish to", 
   isText: false,
   type: "checkbox",
   top: 706.92,
   left: 103.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "Extended Coverage OEC If the Title Commitment is to contain OEC it will commit to delete or insure over the standard", 
   isText: false,
   type: "checkbox",
   top: 84.12,
   left: 366.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "Will_5", 
   isText: false,
   type: "checkbox",
   top: 84.12,
   left: 411.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "Regardless of whether the Contract requires OEC the Title Insurance Commitment may not provide OEC or delete or insure", 
   isText: false,
   type: "checkbox",
   top: 169.56,
   left: 72.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "Buyer_3", 
   isText: false,
   type: "checkbox",
   top: 169.56,
   left: 124.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "Seller_2", 
   isText: false,
   type: "checkbox",
   top: 169.56,
   left: 176.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "OneHalf by Buyer and OneHalf by Seller", 
   isText: false,
   type: "checkbox",
   top: 169.56,
   left: 385.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "undefined_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 166.964,
   left: 431.64,
   width: 135.00,
   height: 12.436
}
,
{
   page: 11,
   name: "undefined_9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 241.44,
   left: 62.76,
   width: 500.04,
   height: 13.32
}
,
{
   page: 11,
   name: "metropolitan districts that affect the Property Tax Certificate must be delivered to Buyer on or before Record Title", 
   isText: false,
   type: "checkbox",
   top: 264.84,
   left: 276.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 11,
   name: "Seller_3", 
   isText: false,
   type: "checkbox",
   top: 264.84,
   left: 327.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "New ILC or New Survey If the box is checked 1", 
   isText: false,
   type: "checkbox",
   top: 477.24,
   left: 306.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "2_2", 
   isText: false,
   type: "checkbox",
   top: 491.16,
   left: 81,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "New Survey in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 488.815,
   left: 213.08,
   width: 195.00,
   height: 12.185
}
,
{
   page: 12,
   name: "Ordering of New ILC or New Survey", 
   isText: false,
   type: "checkbox",
   top: 524.52,
   left: 273.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Seller_4", 
   isText: false,
   type: "checkbox",
   top: 524.52,
   left: 324.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Closing by", 
   isText: false,
   type: "checkbox",
   top: 584.04,
   left: 131.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Seller_5", 
   isText: false,
   type: "checkbox",
   top: 584.04,
   left: 183,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Buyer or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 582.349,
   left: 243.48,
   width: 320.04,
   height: 11.531
}
,
{
   page: 12,
   name: "opinion of title if an Abstract of Title and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 614.64,
   left: 243.84,
   width: 315.00,
   height: 12.84
}
,
{
   page: 14,
   name: "Text10", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 318.765,
   left: 90.9822,
   width: 475.3108,
   height: 65.200
}
,
{
   page: 14,
   name: "Text11", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 552.439,
   left: 91.6368,
   width: 473.3472,
   height: 78.291
}
,
{
   page: 15,
   name: "undefined_10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 243.513,
   left: 62.76,
   width: 504.96,
   height: 15.327
}
,
{
   page: 15,
   name: "Does_2", 
   isText: false,
   type: "checkbox",
   top: 341.16,
   left: 95.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Does Not acknowledge receipt of a copy of Sellers Property Disclosure or Source of Water", 
   isText: false,
   type: "checkbox",
   top: 341.16,
   left: 142.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "There is No Well Buyer", 
   isText: false,
   type: "checkbox",
   top: 375,
   left: 63.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Does_3", 
   isText: false,
   type: "checkbox",
   top: 375,
   left: 191.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Does Not acknowledge receipt of a copy of the current well permit", 
   isText: false,
   type: "checkbox",
   top: 375,
   left: 238.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Closing Instructions Colorado Real Estate Commissions Closing Instructions", 
   isText: false,
   type: "checkbox",
   top: 543.96,
   left: 420.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Are", 
   isText: false,
   type: "checkbox",
   top: 543.96,
   left: 463.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "undefined_11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 613.44,
   left: 62.76,
   width: 420.00,
   height: 12.36
}
,
{
   page: 17,
   name: "special warranty deed", 
   isText: false,
   type: "checkbox",
   top: 45.72,
   left: 64.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "general warranty deed", 
   isText: false,
   type: "checkbox",
   top: 45.72,
   left: 178.2,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "bargain and sale deed", 
   isText: false,
   type: "checkbox",
   top: 45.72,
   left: 293.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "quit claim deed", 
   isText: false,
   type: "checkbox",
   top: 45.72,
   left: 406.2,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "personal representatives deed", 
   isText: false,
   type: "checkbox",
   top: 59.88,
   left: 64.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "undefined_12", 
   isText: false,
   type: "checkbox",
   top: 59.88,
   left: 214.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "deed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 58.364,
   left: 232.92,
   width: 219.96,
   height: 11.356
}
,
{
   page: 17,
   name: "Buyer_4", 
   isText: false,
   type: "checkbox",
   top: 292.68,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_6", 
   isText: false,
   type: "checkbox",
   top: 292.68,
   left: 242.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_2", 
   isText: false,
   type: "checkbox",
   top: 292.68,
   left: 293.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Other_2", 
   isText: false,
   type: "checkbox",
   top: 311.88,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "undefined_13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 310.364,
   left: 231.48,
   width: 320.04,
   height: 11.356
}
,
{
   page: 17,
   name: "Buyer_5", 
   isText: false,
   type: "checkbox",
   top: 415.32,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_7", 
   isText: false,
   type: "checkbox",
   top: 415.32,
   left: 242.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_3", 
   isText: false,
   type: "checkbox",
   top: 415.32,
   left: 293.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA", 
   isText: false,
   type: "checkbox",
   top: 415.32,
   left: 502.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_6", 
   isText: false,
   type: "checkbox",
   top: 466.92,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_8", 
   isText: false,
   type: "checkbox",
   top: 466.92,
   left: 242.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_4", 
   isText: false,
   type: "checkbox",
   top: 466.92,
   left: 293.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_2", 
   isText: false,
   type: "checkbox",
   top: 466.92,
   left: 502.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_7", 
   isText: false,
   type: "checkbox",
   top: 505.32,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_9", 
   isText: false,
   type: "checkbox",
   top: 505.32,
   left: 242.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_5", 
   isText: false,
   type: "checkbox",
   top: 505.32,
   left: 293.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_3", 
   isText: false,
   type: "checkbox",
   top: 505.32,
   left: 502.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_8", 
   isText: false,
   type: "checkbox",
   top: 542.04,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_10", 
   isText: false,
   type: "checkbox",
   top: 542.04,
   left: 242.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_6", 
   isText: false,
   type: "checkbox",
   top: 542.04,
   left: 293.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_4", 
   isText: false,
   type: "checkbox",
   top: 542.04,
   left: 502.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_9", 
   isText: false,
   type: "checkbox",
   top: 578.76,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_11", 
   isText: false,
   type: "checkbox",
   top: 578.76,
   left: 242.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_7", 
   isText: false,
   type: "checkbox",
   top: 578.76,
   left: 293.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_5", 
   isText: false,
   type: "checkbox",
   top: 578.76,
   left: 502.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_10", 
   isText: false,
   type: "checkbox",
   top: 630.36,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_12", 
   isText: false,
   type: "checkbox",
   top: 630.36,
   left: 242.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_8", 
   isText: false,
   type: "checkbox",
   top: 630.36,
   left: 293.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_6", 
   isText: false,
   type: "checkbox",
   top: 630.36,
   left: 502.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "for", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 663.6,
   left: 70.32,
   width: 84.96,
   height: 13.32
}
,
{
   page: 17,
   name: "Water DistrictMunicipality", 
   isText: false,
   type: "checkbox",
   top: 684.6,
   left: 99.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Augmentation Membership", 
   isText: false,
   type: "checkbox",
   top: 702.12,
   left: 99.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Water Stock", 
   isText: false,
   type: "checkbox",
   top: 684.6,
   left: 253.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "undefined_14", 
   isText: false,
   type: "checkbox",
   top: 702.12,
   left: 253.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Check Box21", 
   isText: false,
   type: "checkbox",
   top: 701.9413,
   left: 419.947,
   width: 10.080,
   height: 10.0800
}
,
{
   page: 17,
   name: "undefined_15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 698.64,
   left: 438,
   width: 114.96,
   height: 13.32
}
,
{
   page: 17,
   name: "Buyer_11", 
   isText: false,
   type: "checkbox",
   top: 737.16,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_13", 
   isText: false,
   type: "checkbox",
   top: 737.16,
   left: 242.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_9", 
   isText: false,
   type: "checkbox",
   top: 737.16,
   left: 293.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_7", 
   isText: false,
   type: "checkbox",
   top: 737.16,
   left: 502.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_12", 
   isText: false,
   type: "checkbox",
   top: 74.76,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_14", 
   isText: false,
   type: "checkbox",
   top: 74.76,
   left: 242.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_10", 
   isText: false,
   type: "checkbox",
   top: 74.76,
   left: 293.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_8", 
   isText: false,
   type: "checkbox",
   top: 74.76,
   left: 502.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "IS a foreign person for purposes of US income taxation If the box in this Section is not checked Seller", 
   isText: false,
   type: "checkbox",
   top: 151.08,
   left: 103.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Taxes for the Calendar Year Immediately Preceding Closing", 
   isText: false,
   type: "checkbox",
   top: 367.32,
   left: 86.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Most Recent Mill Levy and Most Recent Assessed Valuation adjusted by any applicable qualifying seniors", 
   isText: false,
   type: "checkbox",
   top: 387.24,
   left: 86.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Other_3", 
   isText: false,
   type: "checkbox",
   top: 420.84,
   left: 86.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 418.32,
   left: 133.2,
   width: 405.0,
   height: 12.36
}
,
{
   page: 18,
   name: "Rents Actually Received", 
   isText: false,
   type: "checkbox",
   top: 440.52,
   left: 205.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Accrued", 
   isText: false,
   type: "checkbox",
   top: 440.52,
   left: 335.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 497.52,
   left: 72.48,
   width: 480.00,
   height: 12.36
}
,
{
   page: 18,
   name: "Buyer_13", 
   isText: false,
   type: "checkbox",
   top: 605.16,
   left: 79.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller Except however any special assessment by the Association for improvements that have been", 
   isText: false,
   type: "checkbox",
   top: 605.16,
   left: 132.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 642.48,
   left: 328.32,
   width: 215.04,
   height: 12.36
}
,
{
   page: 18,
   name: "per day or any", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 733.68,
   left: 428.28,
   width: 56.64,
   height: 12.36
}
,
{
   page: 20,
   name: "Buyer will be paid to Seller and retained by Seller It is agreed that the Earnest Money is not a penalty and the Parties", 
   isText: false,
   type: "checkbox",
   top: 143.16,
   left: 108.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 21,
   name: "undefined_19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 493.375,
   left: 62.76,
   width: 390.00,
   height: 11.705
}
,
{
   page: 22,
   name: "party beneficiary under this provision only The amount paid by Seller under this provision is in addition to any other amounts", 
   isText: false,
   type: "checkbox",
   top: 78.12,
   left: 92.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "of the Purchase Price or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 75.6,
   left: 110.28,
   width: 40.08,
   height: 12.469
}
,
{
   page: 22,
   name: "by Seller Buyers brokerage firm is an intended third", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 75.6,
   left: 266.76,
   width: 79.92,
   height: 12.469
}
,
{
   page: 22,
   name: "Buyer and Buyers brokerage firm This amount may be modified between Buyer and Buyers brokerage firm outside of this", 
   isText: false,
   type: "checkbox",
   top: 123.96,
   left: 92.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "of the Purchase Price or_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 121.44,
   left: 110.28,
   width: 40.08,
   height: 12.469
}
,
{
   page: 22,
   name: "by Buyer pursuant to a separate agreement between", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 121.44,
   left: 266.76,
   width: 75.00,
   height: 12.469
}
,
{
   page: 22,
   name: "firm and Sellers brokerage firm", 
   isText: false,
   type: "checkbox",
   top: 164.56,
   left: 92.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "of the Purchase Price or_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.04,
   left: 110.28,
   width: 40.08,
   height: 12.469
}
,
{
   page: 22,
   name: "by a separate agreement between Buyers brokerage", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.04,
   left: 266.76,
   width: 75.00,
   height: 12.469
}
,
{
   page: 22,
   name: "additionalProvisions", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 9,
   top: 246.11,
   left: 53.673,
   width: 523.093,
   height: 504.4023
}
,
{
   page: 23,
   name: "DocsPartOfContract", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 75.928,
   left: 62.8366,
   width: 507.3834,
   height: 40.982
}
,
{
   page: 23,
   name: "of this Contract", 
   isText: false,
   type: "checkbox",
   top: 120.12,
   left: 108.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "DocsNotPartOfContract", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 164.292,
   left: 62.1821,
   width: 508.0379,
   height: 41.636
}
,
{
   page: 23,
   name: "Buyers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 244.92,
   left: 120.24,
   width: 180.12,
   height: 16.80
}
,
{
   page: 23,
   name: "Address 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 308.52,
   left: 126.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Address 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 326.04,
   left: 126.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Phone No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 343.56,
   left: 126.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Fax No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 361.08,
   left: 126.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 378.6,
   left: 126.48,
   width: 175.08,
   height: 16.8
}
,
{
   page: 23,
   name: "Buyers Name 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 402.12,
   left: 132.84,
   width: 170.04,
   height: 16.80
}
,
{
   page: 23,
   name: "Buyers Name 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 244.92,
   left: 387.84,
   width: 180.00,
   height: 16.80
}
,
{
   page: 23,
   name: "Address 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 308.52,
   left: 396.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Address 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 326.04,
   left: 396.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Phone No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 343.56,
   left: 396.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Fax No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 361.08,
   left: 396.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 378.6,
   left: 396.48,
   width: 175.08,
   height: 16.8
}
,
{
   page: 23,
   name: "Buyers Name 4 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 402.12,
   left: 400.32,
   width: 170.04,
   height: 16.80
}
,
{
   page: 23,
   name: "Sellers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 500.76,
   left: 119.16,
   width: 180.12,
   height: 16.80
}
,
{
   page: 23,
   name: "Sellers Name 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 500.76,
   left: 389.76,
   width: 177.00,
   height: 16.80
}
,
{
   page: 23,
   name: "Address 1_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 564.36,
   left: 126.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Address 2_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 581.88,
   left: 126.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Address 1_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 564.36,
   left: 396.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Address 2_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 581.88,
   left: 396.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Phone No_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 599.4,
   left: 126.48,
   width: 175.08,
   height: 16.8
}
,
{
   page: 23,
   name: "Phone No_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 599.4,
   left: 396.48,
   width: 175.08,
   height: 16.8
}
,
{
   page: 23,
   name: "Fax No_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 616.92,
   left: 126.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Fax No_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 616.92,
   left: 396.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Email Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 634.44,
   left: 126.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Email Address_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 634.44,
   left: 396.48,
   width: 175.08,
   height: 16.80
}
,
{
   page: 23,
   name: "Sellers Name 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 657.96,
   left: 131.64,
   width: 170.04,
   height: 16.80
}
,
{
   page: 23,
   name: "Sellers Name 4 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 657.96,
   left: 399.24,
   width: 170.04,
   height: 16.80
}
,
{
   page: 24,
   name: "Does_4", 
   isText: false,
   type: "checkbox",
   top: 124.68,
   left: 108.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is", 
   isText: false,
   type: "checkbox",
   top: 124.68,
   left: 155.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Buyers Agent", 
   isText: false,
   type: "checkbox",
   top: 193.8,
   left: 218.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "TransactionBroker in this transaction", 
   isText: false,
   type: "checkbox",
   top: 193.8,
   left: 311.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
   isText: false,
   type: "checkbox",
   top: 211.08,
   left: 73.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Brokerage Firms Name 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 320.52,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 24,
   name: "Brokerage Firms Name 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 346.92,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 24,
   name: "Brokerage Firms License 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 333.72,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 24,
   name: "Brokerage Firms License 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 360.12,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 24,
   name: "Date brokerSigns", 
   fontSize: 9,
   type: "date",
   top: 384.84,
   left: 393.48,
   width: 90.00,
   height: 12.48
}
,
{
   page: 24,
   name: "1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 409.68,
   left: 198.48,
   width: 355.08,
   height: 10.68
}
,
{
   page: 24,
   name: "2_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 421.08,
   left: 198.48,
   width: 355.08,
   height: 10.80
}
,
{
   page: 24,
   name: "1_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 432.6,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 24,
   name: "2_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 445.8,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 24,
   name: "3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 459,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 24,
   name: "1_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 483.72,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 24,
   name: "Date cobrokerSigns", 
   fontSize: 9,
   type: "date",
   top: 507.24,
   left: 393.48,
   width: 90.00,
   height: 12.48
}
,
{
   page: 24,
   name: "Does_5", 
   isText: false,
   type: "checkbox",
   top: 576.56,
   left: 108.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is_2", 
   isText: false,
   type: "checkbox",
   top: 576.56,
   left: 155.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Sellers Agent", 
   isText: false,
   type: "checkbox",
   top: 645.68,
   left: 217.32,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "TransactionBroker in this transaction_2", 
   isText: false,
   type: "checkbox",
   top: 645.68,
   left: 303.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Customer Broker has no brokerage relationship with Seller See  A for Brokers brokerage relationship with Buyer", 
   isText: false,
   type: "checkbox",
   top: 662.72,
   left: 73.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Seller_15", 
   isText: false,
   type: "checkbox",
   top: 679.72,
   left: 334.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Buyer_14", 
   isText: false,
   type: "checkbox",
   top: 679.72,
   left: 385.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Other_4", 
   isText: false,
   type: "checkbox",
   top: 679.72,
   left: 438.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "undefined_21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 677.32,
   left: 484.92,
   width: 77.04,
   height: 12.48
}
,
{
   page: 24,
   name: "signature cobrokerSig", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 506.62,
   left: 198.983,
   width: 186.000,
   height: 13.491
}
,
{
   page: 24,
   name: "signature BrokerSig", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 384.874,
   left: 197.674,
   width: 186.000,
   height: 12.837
}
,
{
   page: 25,
   name: "1_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 63.08,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 25,
   name: "2_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 76.28,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 25,
   name: "Date brokerSellerSigns", 
   fontSize: 9,
   type: "date",
   top: 101.0,
   left: 393.48,
   width: 90.00,
   height: 12.48
}
,
{
   page: 25,
   name: "1_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 136.04,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 25,
   name: "2_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 149.24,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 25,
   name: "3_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 162.44,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 25,
   name: "4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 175.64,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 25,
   name: "5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 188.84,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 25,
   name: "1_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 213.56,
   left: 198.48,
   width: 355.08,
   height: 12.48
}
,
{
   page: 25,
   name: "Date_12", 
   fontSize: 9,
   type: "date",
   top: 237.08,
   left: 393.48,
   width: 90.00,
   height: 12.48
}
,
{
   page: 25,
   name: "signature brokerSignsSeller", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 9,
   top: 101.473,
   left: 200.292,
   width: 183.382,
   height: 12.182
}
,
{
   page: 25,
   name: "signature cobrokerSellerSigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 9,
   top: 235.655,
   left: 197.674,
   width: 185.345,
   height: 14.146
}
,
{
   page: 25,
   name: "Brokerage Firms License", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 47.248,
   left: 197.516,
   width: 355.080,
   height: 12.480
}
,
{
   page: 25,
   name: "Brokerage Firms compensation or commission is to be paid by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 34.048,
   left: 197.516,
   width: 355.080,
   height: 12.480
}
] }
