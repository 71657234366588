export function residentialCondominiumContract() {
return [   //2024 Release 2024-02-18 21:49:48
{
   page: 0,
   name: "RESIDENTIAL CONDOMINIUM CONTRACT RESALE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 74.846,
   left: 286.25,
   width: 226.417,
   height: 11.634
}
,
{
   page: 0,
   name: "1 PARTIES The parties to this contract are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 84.451,
   left: 89.4694,
   width: 347.9016,
   height: 11.721
}
,
{
   page: 0,
   name: "A The Condominium Unit improvements and accessories described below are collectively", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 133.928,
   left: 257.76,
   width: 72.12,
   height: 10.390
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 134.197,
   left: 408.56,
   width: 144.00,
   height: 11.188
}
,
{
   page: 0,
   name: "1 CONDOMINIUM", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 143.726,
   left: 115.92,
   width: 260.88,
   height: 11.188
}
,
{
   page: 0,
   name: "of 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 153.393,
   left: 102.72,
   width: 445.56,
   height: 10.121
}
,
{
   page: 0,
   name: "of 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 161.926,
   left: 102.72,
   width: 445.56,
   height: 11.722
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 171.725,
   left: 240.0,
   width: 158.28,
   height: 11.188
}
,
{
   page: 0,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 172.127,
   left: 453.96,
   width: 94.32,
   height: 10.121
}
,
{
   page: 0,
   name: "addresszip code City of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 180.532,
   left: 102.72,
   width: 445.56,
   height: 11.188
}
,
{
   page: 0,
   name: "Elements which have been specifically assigned to the Unit in any other manner Parking", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 237.467,
   left: 260.41,
   width: 292.401,
   height: 10.655
}
,
{
   page: 0,
   name: "areas assigned to the Unit are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 247.067,
   left: 104.05,
   width: 449.04,
   height: 11.188
}
,
{
   page: 0,
   name: "4 EXCLUSIONS The following improvements and accessories will be retained by Seller and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 409.606,
   left: 350.64,
   width: 206.194,
   height: 10.208
}
,
{
   page: 0,
   name: "must be removed prior to delivery of possession", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 418.293,
   left: 102.72,
   width: 449.52,
   height: 11.188
}
,
{
   page: 0,
   name: "1 Buyer has received a copy of the Documents Buyer is advised to read the Documents", 
   isText: false,
   type: "checkbox",
   top: 447.066,
   left: 66.88,
   width: 10.9864,
   height: 9.934
}
,
{
   page: 0,
   name: "2 Buyer has not received a copy of the Documents Seller at Sellers expense shall deliver", 
   isText: false,
   type: "checkbox",
   top: 466.626,
   left: 66.6529,
   width: 12.0536,
   height: 9.934
}
,
{
   page: 0,
   name: "may cancel the contract before the sixth day after Buyer receives the Documents by hand", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 477.533,
   left: 262.12,
   width: 43.14,
   height: 9.587
}
,
{
   page: 0,
   name: "1 Buyer has received the Certificate", 
   isText: false,
   type: "checkbox",
   top: 578.226,
   left: 67.36,
   width: 11.64,
   height: 9.934
}
,
{
   page: 0,
   name: "2 Buyer has not received the Certificate Seller shall deliver the Certificate to Buyer within", 
   isText: false,
   type: "checkbox",
   top: 588.893,
   left: 66.8264,
   width: 12.0536,
   height: 8.867
}
,
{
   page: 0,
   name: "before the sixth day after the date Buyer receives the Certificate by handdelivering or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 599.613,
   left: 102.72,
   width: 43.406,
   height: 9.467
}
,
{
   page: 0,
   name: "3 Buyer", 
   isText: false,
   type: "checkbox",
   top: 641.959,
   left: 67.2671,
   width: 10.4529,
   height: 9.401
}
,
{
   page: 0,
   name: "buy", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 733.2264,
   left: 83.72,
   width: 44.52,
   height: 10.1207
}
,
{
   page: 1,
   name: "Contract Concerning", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 32.719,
   left: 129.36,
   width: 292.581,
   height: 11.841
}
,
{
   page: 1,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 69.066,
   left: 438.48,
   width: 119.64,
   height: 10.774
}
,
{
   page: 1,
   name: "Third Party Financing Addendum", 
   isText: false,
   type: "checkbox",
   top: 99.959,
   left: 320.16,
   width: 10.68,
   height: 8.801
}
,
{
   page: 1,
   name: "Check Box21", 
   isText: false,
   type: "checkbox",
   top: 111.841,
   left: 81.6359,
   width: 10.5300,
   height: 8.929
}
,
{
   page: 1,
   name: "Loan Assumption Addendum", 
   isText: false,
   type: "checkbox",
   top: 111.959,
   left: 254.4,
   width: 10.68,
   height: 8.801
}
,
{
   page: 1,
   name: "Seller Financing Addendum", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 111.826,
   left: 438.48,
   width: 119.64,
   height: 10.774
}
,
{
   page: 1,
   name: "undefined_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 123.039,
   left: 438.48,
   width: 119.64,
   height: 11.841
}
,
{
   page: 1,
   name: "4_B_0", 
   isText: true,
   type: "checkbox",
   top: 173.428,
   left: 55.1452,
   width: 10.6229,
   height: 11.065
}
,
{
   page: 1,
   name: "4_B_1", 
   isText: true,
   type: "checkbox",
   top: 193.691,
   left: 54.4667,
   width: 10.6229,
   height: 11.065
}
,
{
   page: 1,
   name: "undefined_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 244.714,
   left: 192.585,
   width: 206.060,
   height: 13.041
}
,
{
   page: 1,
   name: "undefined_9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 246.376,
   left: 500.258,
   width: 58.230,
   height: 11.133
}
,
{
   page: 1,
   name: "address pg 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 255.622,
   left: 81.2395,
   width: 257.2825,
   height: 12.375
}
,
{
   page: 1,
   name: "as earnest money to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 257.685,
   left: 402.944,
   width: 97.663,
   height: 10.241
}
,
{
   page: 1,
   name: "as escrow agent  at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 268.724,
   left: 147.311,
   width: 89.217,
   height: 10.372
}
,
{
   page: 1,
   name: "Buyer shall deliver additional earnest money of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 289.075,
   left: 369.202,
   width: 97.596,
   height: 10.505
}
,
{
   page: 1,
   name: "within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 297.593,
   left: 131.88,
   width: 38.28,
   height: 10.774
}
,
{
   page: 1,
   name: "within 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 431.344,
   left: 80.9764,
   width: 38.2796,
   height: 10.774
}
,
{
   page: 1,
   name: "Sellers", 
   isText: false,
   type: "checkbox",
   top: 572.842,
   left: 313.855,
   width: 11.520,
   height: 9.934
}
,
{
   page: 1,
   name: "Buyers expense an owner policy of", 
   isText: false,
   type: "checkbox",
   top: 572.375,
   left: 369.749,
   width: 10.986,
   height: 9.401
}
,
{
   page: 1,
   name: "title insurance Title Policy issued by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 583.749,
   left: 272.335,
   width: 201.600,
   height: 9.707
}
,
{
   page: 2,
   name: "Contract Concerning_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 32.128,
   left: 129.36,
   width: 292.581,
   height: 12.432
}
,
{
   page: 2,
   name: "following use or activity", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 170.403,
   left: 208.925,
   width: 344.880,
   height: 10.242
}
,
{
   page: 2,
   name: "undefined_10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 179.71,
   left: 81.4851,
   width: 472.3199,
   height: 11.842
}
,
{
   page: 2,
   name: "Buyer must object the earlier of i the Closing Date or ii", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 190.137,
   left: 384.169,
   width: 38.040,
   height: 10.655
}
,
{
   page: 3,
   name: "Contract Concerning_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.934,
   left: 129.36,
   width: 292.581,
   height: 12.626
}
,
{
   page: 3,
   name: "undefined_15-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 123.019,
   left: 300.756,
   width: 250.920,
   height: 10.895
}
,
{
   page: 3,
   name: "following specific repairs and treatments-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 131.018,
   left: 91.1628,
   width: 458.6712,
   height: 9.819
}
,
{
   page: 3,
   name: "following specific repairs and treatments-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 139.557,
   left: 91.1044,
   width: 458.6706,
   height: 9.820
}
,
{
   page: 3,
   name: "1 Buyer has received the Notice", 
   isText: false,
   type: "checkbox",
   top: 238.968,
   left: 60.1795,
   width: 11.5200,
   height: 9.934
}
,
{
   page: 3,
   name: "2 Buyer has not received the Notice Within", 
   isText: false,
   type: "checkbox",
   top: 249.275,
   left: 60.1795,
   width: 11.5200,
   height: 8.867
}
,
{
   page: 3,
   name: "B SELLERS DISCLOSURE NOTICE PURSUANT TO 5008 TEXAS PROPERTY CODE Notice", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 247.035,
   left: 315.1,
   width: 50.791,
   height: 12.827
}
,
{
   page: 3,
   name: "3The Texas Property Code does not require this Seller to furnish the Notice", 
   isText: false,
   type: "checkbox",
   top: 308.941,
   left: 58.5395,
   width: 11.5200,
   height: 9.401
}
,
{
   page: 3,
   name: "1 Buyer accepts the Property As Is", 
   isText: false,
   type: "checkbox",
   top: 403.287,
   left: 59.52,
   width: 10.68,
   height: 8.801
}
,
{
   page: 3,
   name: "2 Buyer accepts the Property As Is provided Seller at Sellers expense shall complete the", 
   isText: false,
   type: "checkbox",
   top: 413.007,
   left: 59.52,
   width: 10.68,
   height: 8.801
}
,
{
   page: 3,
   name: "undefined_15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 421.593,
   left: 300.36,
   width: 250.92,
   height: 10.895
}
,
{
   page: 3,
   name: "following specific repairs and treatments", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 431.433,
   left: 91.8,
   width: 458.671,
   height: 12.496
}
,
{
   page: 3,
   name: "Execeeding", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 705.7016,
   left: 424.151,
   width: 96.760,
   height: 11.5682
}
,
{
   page: 4,
   name: "Contract Concerning_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.012,
   left: 129.36,
   width: 293.911,
   height: 13.082
}
,
{
   page: 4,
   name: "contract Seller shall reimburse Buyer at closing for the cost of the residential service contract", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 117.683,
   left: 345.366,
   width: 205.540,
   height: 11.299
}
,
{
   page: 4,
   name: "contract Seller shall reimburse Buyer at closing for the cost of the residential service contract2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 129.645,
   left: 73.4024,
   width: 475.8466,
   height: 11.625
}
,
{
   page: 4,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 173.457,
   left: 295.746,
   width: 135.240,
   height: 11.961
}
,
{
   page: 4,
   name: "or within 7 days", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 174.523,
   left: 451.026,
   width: 22.560,
   height: 10.895
}
,
{
   page: 4,
   name: "upon closing and funding", 
   isText: false,
   type: "checkbox",
   top: 357.511,
   left: 335.466,
   width: 11.520,
   height: 8.867
}
,
{
   page: 4,
   name: "according to a", 
   isText: false,
   type: "checkbox",
   top: 357.511,
   left: 477.306,
   width: 11.520,
   height: 8.867
}
,
{
   page: 4,
   name: "Text2-4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 542.466,
   left: 347.699,
   width: 209.999,
   height: 10.935
}
,
{
   page: 4,
   name: "Text2-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 551.892,
   left: 61.3835,
   width: 496.8185,
   height: 11.826
}
,
{
   page: 4,
   name: "Text2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 560.613,
   left: 62.0255,
   width: 496.8185,
   height: 12.272
}
,
{
   page: 4,
   name: "other charges resulting from the transfer of the Property not to exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 637.215,
   left: 352.337,
   width: 82.321,
   height: 12.724
}
,
{
   page: 5,
   name: "Contract Concerning_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.36,
   left: 129.36,
   width: 293.246,
   height: 13.20
}
,
{
   page: 5,
   name: "within 3 days after the Effective Date of this contract Seller grants Buyer the unrestricted right to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 89.766,
   left: 387.627,
   width: 83.536,
   height: 10.805
}
,
{
   page: 6,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.929,
   left: 128.36,
   width: 293.911,
   height: 12.631
}
,
{
   page: 6,
   name: "at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 309.526,
   left: 133.927,
   width: 162.189,
   height: 12.623
}
,
{
   page: 6,
   name: "undefined_19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 338.433,
   left: 62.6329,
   width: 234.6021,
   height: 14.801
}
,
{
   page: 6,
   name: "Text14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 378.194,
   left: 140.587,
   width: 23.011,
   height: 14.530
}
,
{
   page: 6,
   name: "Text13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 377.407,
   left: 167.936,
   width: 128.324,
   height: 15.195
}
,
{
   page: 6,
   name: "Text16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 415.134,
   left: 133.646,
   width: 162.178,
   height: 14.398
}
,
{
   page: 6,
   name: "Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 451.965,
   left: 133.919,
   width: 162.002,
   height: 12.937
}
,
{
   page: 6,
   name: "2-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 491.209,
   left: 63.5074,
   width: 234.7446,
   height: 14.094
}
,
{
   page: 6,
   name: "at_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 306.902,
   left: 387.877,
   width: 165.462,
   height: 15.466
}
,
{
   page: 6,
   name: "undefined_20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 338.685,
   left: 316.424,
   width: 236.257,
   height: 14.223
}
,
{
   page: 6,
   name: "Text17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 377.67,
   left: 395.409,
   width: 22.478,
   height: 15.064
}
,
{
   page: 6,
   name: "Text18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 377.269,
   left: 421.554,
   width: 129.003,
   height: 16.357
}
,
{
   page: 6,
   name: "Text20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 414.066,
   left: 388.214,
   width: 164.618,
   height: 15.598
}
,
{
   page: 6,
   name: "Email_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 451.635,
   left: 387.674,
   width: 168.334,
   height: 12.929
}
,
{
   page: 6,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 490.778,
   left: 316.976,
   width: 232.514,
   height: 14.094
}
,
{
   page: 6,
   name: "Check Box22", 
   isText: false,
   type: "checkbox",
   top: 532.993,
   left: 52.9376,
   width: 9.9964,
   height: 9.997
}
,
{
   page: 6,
   name: "Check Box23", 
   isText: false,
   type: "checkbox",
   top: 547.399,
   left: 52.9376,
   width: 9.9964,
   height: 10.530
}
,
{
   page: 6,
   name: "Check Box24", 
   isText: false,
   type: "checkbox",
   top: 561.272,
   left: 52.9376,
   width: 9.9964,
   height: 9.997
}
,
{
   page: 6,
   name: "Check Box25", 
   isText: false,
   type: "checkbox",
   top: 575.678,
   left: 52.9376,
   width: 9.9964,
   height: 9.997
}
,
{
   page: 6,
   name: "Check Box26", 
   isText: false,
   type: "checkbox",
   top: 590.085,
   left: 52.404,
   width: 10.530,
   height: 9.996
}
,
{
   page: 6,
   name: "Check Box28", 
   isText: false,
   type: "checkbox",
   top: 612.495,
   left: 52.9376,
   width: 9.9964,
   height: 9.996
}
,
{
   page: 6,
   name: "Check Box30", 
   isText: false,
   type: "checkbox",
   top: 627.367,
   left: 52.9376,
   width: 9.9964,
   height: 9.997
}
,
{
   page: 6,
   name: "Check Box31", 
   isText: false,
   type: "checkbox",
   top: 641.307,
   left: 52.9376,
   width: 9.9964,
   height: 8.930
}
,
{
   page: 6,
   name: "Check Box32", 
   isText: false,
   type: "checkbox",
   top: 655.646,
   left: 52.9376,
   width: 9.9964,
   height: 9.463
}
,
{
   page: 6,
   name: "Check Box33", 
   isText: false,
   type: "checkbox",
   top: 668.519,
   left: 52.9376,
   width: 9.9964,
   height: 9.997
}
,
{
   page: 6,
   name: "Check Box34", 
   isText: false,
   type: "checkbox",
   top: 710.671,
   left: 52.9376,
   width: 9.4629,
   height: 9.4629
}
,
{
   page: 6,
   name: "Check Box35", 
   isText: false,
   type: "checkbox",
   top: 733.1058,
   left: 52.9376,
   width: 9.9964,
   height: 9.4381
}
,
{
   page: 6,
   name: "Environmental Assessment Threatened or", 
   isText: false,
   type: "checkbox",
   top: 532.072,
   left: 312.594,
   width: 12.054,
   height: 9.401
}
,
{
   page: 6,
   name: "Addendum for Property Located Seaward", 
   isText: false,
   type: "checkbox",
   top: 565.566,
   left: 312.594,
   width: 11.520,
   height: 8.867
}
,
{
   page: 6,
   name: "Addendum for Release of Liability on", 
   isText: false,
   type: "checkbox",
   top: 590.032,
   left: 313.128,
   width: 11.520,
   height: 9.401
}
,
{
   page: 6,
   name: "Addendum for Property in a Propane Gas_0", 
   isText: false,
   type: "checkbox",
   top: 626.685,
   left: 313.128,
   width: 11.520,
   height: 10.468
}
,
{
   page: 6,
   name: "Other list", 
   isText: false,
   type: "checkbox",
   top: 653.072,
   left: 313.128,
   width: 11.520,
   height: 9.401
}
,
{
   page: 6,
   name: "Addendum for Property in a Propane Gas_1", 
   isText: false,
   type: "checkbox",
   top: 674.738,
   left: 312.911,
   width: 11.520,
   height: 10.468
}
,
{
   page: 6,
   name: "PID", 
   isText: false,
   type: "checkbox",
   top: 693.125,
   left: 312.911,
   width: 11.520,
   height: 9.401
}
,
{
   page: 6,
   name: "Other (List)", 
   isText: false,
   type: "checkbox",
   top: 719.4607,
   left: 312.963,
   width: 11.520,
   height: 9.4010
}
,
{
   page: 6,
   name: "System Service Area", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 717.768,
   left: 396.627,
   width: 148.834,
   height: 14.0894
}
,
{
   page: 6,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 731.887,
   left: 336.387,
   width: 232.883,
   height: 10.7561
}
,
{
   page: 7,
   name: "Address of Property_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 32.201,
   left: 129.36,
   width: 292.581,
   height: 12.359
}
,
{
   page: 7,
   name: "Attorney is 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 116.467,
   left: 122.075,
   width: 169.080,
   height: 13.560
}
,
{
   page: 7,
   name: "Attorney is 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 147.642,
   left: 60.0073,
   width: 231.6627,
   height: 13.560
}
,
{
   page: 7,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 178.504,
   left: 134.224,
   width: 22.477,
   height: 12.929
}
,
{
   page: 7,
   name: "Attorney is 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 177.183,
   left: 159.024,
   width: 136.665,
   height: 14.093
}
,
{
   page: 7,
   name: "Text8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 210.511,
   left: 134.224,
   width: 22.477,
   height: 13.462
}
,
{
   page: 7,
   name: "Text9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 210.511,
   left: 160.903,
   width: 136.127,
   height: 13.996
}
,
{
   page: 7,
   name: "undefined_23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 244.442,
   left: 127.075,
   width: 169.080,
   height: 13.560
}
,
{
   page: 7,
   name: "Attorney is 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 115.802,
   left: 384.515,
   width: 169.080,
   height: 13.560
}
,
{
   page: 7,
   name: "Attorney is 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 148.176,
   left: 322.447,
   width: 231.130,
   height: 13.026
}
,
{
   page: 7,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 177.97,
   left: 394.74,
   width: 22.477,
   height: 13.463
}
,
{
   page: 7,
   name: "Attorney is 4_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 177.716,
   left: 421.464,
   width: 136.665,
   height: 14.094
}
,
{
   page: 7,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 211.044,
   left: 394.74,
   width: 21.943,
   height: 12.396
}
,
{
   page: 7,
   name: "Text12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 211.044,
   left: 423.418,
   width: 136.127,
   height: 12.929
}
,
{
   page: 7,
   name: "undefined_24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 244.682,
   left: 389.515,
   width: 169.080,
   height: 13.560
}
,
{
   page: 7,
   name: "BROKER FILL IN THE DATE OF FINAL ACCEPTANCE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 321.962,
   left: 132.515,
   width: 41.280,
   height: 14.627
}
,
{
   page: 7,
   name: "20_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 323.029,
   left: 212.555,
   width: 195.120,
   height: 13.560
}
,
{
   page: 7,
   name: "Effective Date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 322.496,
   left: 429.275,
   width: 33.720,
   height: 14.627
}
,
{
   page: 8,
   name: "Address of Property_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.7,
   left: 127.789,
   width: 293.291,
   height: 12.738
}
,
{
   page: 8,
   name: "Other Broker Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 132.31,
   left: 52.9251,
   width: 169.4999,
   height: 15.439
}
,
{
   page: 8,
   name: "Liceense Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 132.373,
   left: 223.121,
   width: 63.884,
   height: 15.314
}
,
{
   page: 8,
   name: "Buyer only as Buyers agent", 
   isText: false,
   type: "checkbox",
   top: 169.443,
   left: 113.737,
   width: 11.520,
   height: 9.934
}
,
{
   page: 8,
   name: "Seller as Listing Brokers subagent", 
   isText: false,
   type: "checkbox",
   top: 184.376,
   left: 113.257,
   width: 11.520,
   height: 9.401
}
,
{
   page: 8,
   name: " Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 198.005,
   left: 54.2171,
   width: 169.4999,
   height: 15.439
}
,
{
   page: 8,
   name: "License No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 198.068,
   left: 224.413,
   width: 63.884,
   height: 15.313
}
,
{
   page: 8,
   name: "Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 224.111,
   left: 53.9495,
   width: 233.3615,
   height: 16.770
}
,
{
   page: 8,
   name: "Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 251.992,
   left: 54.2171,
   width: 163.0349,
   height: 14.625
}
,
{
   page: 8,
   name: "Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 251.992,
   left: 218.424,
   width: 70.873,
   height: 14.625
}
,
{
   page: 8,
   name: "Licensed Supervisor of Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 279.232,
   left: 54.2171,
   width: 170.0869,
   height: 14.625
}
,
{
   page: 8,
   name: "License No_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 279.232,
   left: 224.943,
   width: 64.354,
   height: 14.625
}
,
{
   page: 8,
   name: "Other Brokers Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 306.886,
   left: 54.2171,
   width: 162.0349,
   height: 14.625
}
,
{
   page: 8,
   name: "Phone_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 306.886,
   left: 217.424,
   width: 71.873,
   height: 14.625
}
,
{
   page: 8,
   name: "City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 332.525,
   left: 54.2171,
   width: 129.7029,
   height: 16.226
}
,
{
   page: 8,
   name: "State", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 332.525,
   left: 185.626,
   width: 39.945,
   height: 16.226
}
,
{
   page: 8,
   name: "Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 332.525,
   left: 227.277,
   width: 62.020,
   height: 16.226
}
,
{
   page: 8,
   name: "Listing Broker Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 133.641,
   left: 305.436,
   width: 189.394,
   height: 15.439
}
,
{
   page: 8,
   name: "License No_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 133.62,
   left: 495.112,
   width: 67.395,
   height: 14.625
}
,
{
   page: 8,
   name: "Seller and Buyer as an intermediary", 
   isText: false,
   type: "checkbox",
   top: 169.976,
   left: 365.697,
   width: 11.520,
   height: 9.401
}
,
{
   page: 8,
   name: "Seller only as Sellers agent", 
   isText: false,
   type: "checkbox",
   top: 184.376,
   left: 365.217,
   width: 11.520,
   height: 9.401
}
,
{
   page: 8,
   name: "Listing Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 197.066,
   left: 307.177,
   width: 189.394,
   height: 15.439
}
,
{
   page: 8,
   name: "License No_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 196.448,
   left: 497.344,
   width: 63.833,
   height: 15.439
}
,
{
   page: 8,
   name: "Phone_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 224.232,
   left: 306.062,
   width: 256.445,
   height: 16.891
}
,
{
   page: 8,
   name: "Listing Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 252.123,
   left: 307.177,
   width: 189.164,
   height: 15.561
}
,
{
   page: 8,
   name: "License No_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 252.54,
   left: 497.277,
   width: 63.900,
   height: 14.091
}
,
{
   page: 8,
   name: "Licensed Supervisor of Listing Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 279.766,
   left: 307.177,
   width: 189.861,
   height: 14.091
}
,
{
   page: 8,
   name: "Phone_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 279.126,
   left: 499.706,
   width: 61.471,
   height: 15.159
}
,
{
   page: 8,
   name: "Listing Brokers Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 306.352,
   left: 307.177,
   width: 188.431,
   height: 15.159
}
,
{
   page: 8,
   name: "Zip_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 306.569,
   left: 497.262,
   width: 63.915,
   height: 15.158
}
,
{
   page: 8,
   name: "City_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 334.126,
   left: 307.177,
   width: 155.666,
   height: 15.158
}
,
{
   page: 8,
   name: "State_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 334.126,
   left: 464.015,
   width: 33.008,
   height: 15.158
}
,
{
   page: 8,
   name: "License No_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 333.275,
   left: 495.809,
   width: 65.368,
   height: 15.692
}
,
{
   page: 8,
   name: "Selling Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 360.832,
   left: 307.177,
   width: 187.860,
   height: 15.159
}
,
{
   page: 8,
   name: "Phone_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 360.395,
   left: 495.98,
   width: 65.197,
   height: 15.159
}
,
{
   page: 8,
   name: "Team Name 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 388.895,
   left: 306.203,
   width: 255.713,
   height: 14.494
}
,
{
   page: 8,
   name: "Selling Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 415.226,
   left: 305.847,
   width: 181.247,
   height: 15.159
}
,
{
   page: 8,
   name: "Phone 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 414.572,
   left: 488.352,
   width: 74.352,
   height: 15.693
}
,
{
   page: 8,
   name: "Licensed Supervisor of Selling Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 442.466,
   left: 306.512,
   width: 186.727,
   height: 15.159
}
,
{
   page: 8,
   name: "License No_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 440.985,
   left: 494.142,
   width: 67.035,
   height: 15.693
}
,
{
   page: 8,
   name: "Selling Associates Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 469.172,
   left: 306.512,
   width: 254.400,
   height: 15.693
}
,
{
   page: 8,
   name: "City_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 497.325,
   left: 307.177,
   width: 146.079,
   height: 15.039
}
,
{
   page: 8,
   name: "State_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 497.326,
   left: 454.428,
   width: 36.726,
   height: 15.038
}
,
{
   page: 8,
   name: "Zip_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 497.648,
   left: 492.855,
   width: 68.322,
   height: 15.038
}
,
{
   page: 8,
   name: "fee broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 572.3,
   left: 437.735,
   width: 126.359,
   height: 12.227
}
,
{
   page: 8,
   name: "price when the Listing Brokers fee is received Escrow agent is authorized and directed to pay Other Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 580.639,
   left: 52.8189,
   width: 201.5291,
   height: 12.893
}
,
{
   page: 9,
   name: "Address of Property 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 31.071,
   left: 129.31,
   width: 293.291,
   height: 12.738
}
,
{
   page: 9,
   name: "is acknowledged", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 89.13,
   left: 110.379,
   width: 114.480,
   height: 13.785
}
,
{
   page: 9,
   name: "Option Fee in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 90.197,
   left: 356.724,
   width: 199.935,
   height: 13.252
}
,
{
   page: 9,
   name: "Seller or Listing Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 124.729,
   left: 52.6593,
   width: 248.1597,
   height: 14.853
}
,
{
   page: 9,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 124.196,
   left: 313.659,
   width: 243.120,
   height: 15.919
}
,
{
   page: 9,
   name: "is acknowledged_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 178.637,
   left: 112.779,
   width: 109.200,
   height: 12.718
}
,
{
   page: 9,
   name: "Earnest Money in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 177.57,
   left: 360.579,
   width: 195.480,
   height: 14.319
}
,
{
   page: 9,
   name: "Escrow Agent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 204.997,
   left: 52.4193,
   width: 135.4647,
   height: 13.785
}
,
{
   page: 9,
   name: "Received by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 204.997,
   left: 189.35,
   width: 111.229,
   height: 13.785
}
,
{
   page: 9,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 204.997,
   left: 313.539,
   width: 152.739,
   height: 13.785
}
,
{
   page: 9,
   name: "DateTime", 
   fontSize: 9,
   type: "date",
   top: 204.463,
   left: 467.744,
   width: 88.795,
   height: 14.319
}
,
{
   page: 9,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 231.21,
   left: 52.4193,
   width: 306.7197,
   height: 13.785
}
,
{
   page: 9,
   name: "Phone_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 230.143,
   left: 368.019,
   width: 189.120,
   height: 14.852
}
,
{
   page: 9,
   name: "City_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 255.823,
   left: 52.1792,
   width: 161.9168,
   height: 14.319
}
,
{
   page: 9,
   name: "State_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 255.289,
   left: 216.095,
   width: 38.285,
   height: 14.852
}
,
{
   page: 9,
   name: "Zip_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 254.756,
   left: 255.845,
   width: 103.294,
   height: 15.386
}
,
{
   page: 9,
   name: "Fax", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 255.289,
   left: 368.019,
   width: 189.120,
   height: 14.853
}
,
{
   page: 9,
   name: "Escrow Agent_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 331.17,
   left: 52.4193,
   width: 145.6027,
   height: 13.252
}
,
{
   page: 9,
   name: "Received by_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 331.17,
   left: 199.488,
   width: 101.091,
   height: 13.252
}
,
{
   page: 9,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 331.17,
   left: 313.539,
   width: 169.631,
   height: 13.252
}
,
{
   page: 9,
   name: "Date_2", 
   fontSize: 9,
   type: "date",
   top: 331.17,
   left: 484.102,
   width: 72.437,
   height: 13.252
}
,
{
   page: 9,
   name: "Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 355.783,
   left: 52.4193,
   width: 306.7197,
   height: 14.852
}
,
{
   page: 9,
   name: "Phone_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 355.783,
   left: 368.019,
   width: 189.120,
   height: 14.319
}
,
{
   page: 9,
   name: "City_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 381.463,
   left: 52.1792,
   width: 161.9168,
   height: 14.852
}
,
{
   page: 9,
   name: "State_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 381.463,
   left: 216.095,
   width: 42.553,
   height: 14.319
}
,
{
   page: 9,
   name: "Zip_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 381.463,
   left: 260.114,
   width: 99.025,
   height: 14.319
}
,
{
   page: 9,
   name: "Fax_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 381.463,
   left: 368.019,
   width: 189.120,
   height: 14.319
}
,
{
   page: 9,
   name: "is acknowledged_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 438.69,
   left: 112.779,
   width: 109.200,
   height: 14.319
}
,
{
   page: 9,
   name: "additional Earnest Money in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 438.157,
   left: 408.099,
   width: 149.400,
   height: 14.852
}
,
{
   page: 9,
   name: "Escrow Agent_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 463.982,
   left: 52.4193,
   width: 167.4797,
   height: 15.800
}
,
{
   page: 9,
   name: "Received by_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 463.982,
   left: 221.364,
   width: 79.215,
   height: 16.333
}
,
{
   page: 9,
   name: "Email Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 463.862,
   left: 313.539,
   width: 153.272,
   height: 15.920
}
,
{
   page: 9,
   name: "DateTime_2", 
   fontSize: 9,
   type: "date",
   top: 463.329,
   left: 467.744,
   width: 88.795,
   height: 16.453
}
,
{
   page: 9,
   name: "Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 490.609,
   left: 52.4193,
   width: 306.7197,
   height: 15.386
}
,
{
   page: 9,
   name: "Phone_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 490.609,
   left: 368.019,
   width: 189.120,
   height: 15.386
}
,
{
   page: 9,
   name: "City_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 515.756,
   left: 52.1792,
   width: 162.4498,
   height: 15.919
}
,
{
   page: 9,
   name: "State_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 515.756,
   left: 216.628,
   width: 39.352,
   height: 15.919
}
,
{
   page: 9,
   name: "Zip_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 515.756,
   left: 256.912,
   width: 102.227,
   height: 15.919
}
,
{
   page: 9,
   name: "Fax_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 515.756,
   left: 368.019,
   width: 189.120,
   height: 15.386
}
] }
