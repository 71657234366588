import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { addDays, startOfDay, subDays } from "date-fns";

const initialState = {
  deadlinesUpcoming: { tasks: [], column: "", direction: "" },
  deadlinesTransActive: { tasks: [], column: "", direction: "" },
  deadlinesTransAll: { tasks: [], column: "", direction: "" },
  tasksUpcoming: { tasks: [], column: "", direction: "" },
  tasksTransActive: { tasks: [], column: "", direction: "" },
  tasksTransAll: { tasks: [], column: "", direction: "" },
  tasksTransWithDate: [],
  tasksTransWithoutDate: [],
  tasksAllWithDateUpcoming: [],
  tasksTransWithFourDay: [],
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    fetchTasksUpcoming(state, action) {
      state.tasksAllWithDateUpcoming = action.payload.filter(
        (task) => task.archived !== true
      );
    },
    fetchTasksTrans(state, action) {
      state.tasksTransWithDateAll = _.orderBy(
        action.payload.filter((task) => task.end),
        "end",
        "desc"
      );
      state.tasksTransWithFourDay = _.orderBy(
        action.payload.filter(
          (task) =>
            task.end &&
            task.end >= startOfDay(subDays(new Date(), 1)) &&
            task.end <= startOfDay(addDays(new Date(), 3))
        ),
        "end",
        "asc"
      );
      state.tasksTransWithDateUpcoming = _.orderBy(
        action.payload.filter(
          (task) => task.end && task.end >= startOfDay(new Date())
        ),
        "end",
        "asc"
      );
      state.tasksTransWithDatePast = _.orderBy(
        action.payload.filter(
          (task) => task.end && task.end < startOfDay(new Date())
        ),
        "end",
        "desc"
      );
      state.tasksTransWithoutDateActive = _.orderBy(
        action.payload.filter(
          (task) => !task.end && task.status !== "Complete"
        ),
        "title",
        "asc"
      );
      state.tasksTransWithoutDateComplete = _.orderBy(
        action.payload.filter(
          (task) => !task.end && task.status === "Complete"
        ),
        "title",
        "asc"
      );
    },
    sortTasksTransActive(state, action) {
      if (state.tasksTransActive.column === action.payload) {
        state.tasksTransActive = {
          tasks: state.tasksTransActive.tasks.slice().reverse(),
          column: action.payload,
          direction:
            state.tasksTransActive.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.tasksTransActive = {
          tasks: _.sortBy(state.tasksTransActive.tasks, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    sortTasksTransAll(state, action) {
      if (state.tasksTransAll.column === action.payload) {
        state.tasksTransAll = {
          tasks: state.tasksTransAll.tasks.slice().reverse(),
          column: action.payload,
          direction:
            state.tasksTransAll.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.tasksTransAll = {
          tasks: _.sortBy(state.tasksTransAll.tasks, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    sortTasksUpcoming(state, action) {
      if (state.tasksUpcoming.column === action.payload) {
        state.tasksUpcoming = {
          tasks: state.tasksUpcoming.tasks.slice().reverse(),
          column: action.payload,
          direction:
            state.tasksUpcoming.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.tasksUpcoming = {
          tasks: _.sortBy(state.tasksUpcoming.tasks, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    sortDeadlinesTransActive(state, action) {
      if (state.deadlinesTransActive.column === action.payload) {
        state.deadlinesTransActive = {
          tasks: state.deadlinesTransActive.tasks.slice().reverse(),
          column: action.payload,
          direction:
            state.deadlinesTransActive.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.deadlinesTransActive = {
          tasks: _.sortBy(state.deadlinesTransActive.tasks, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    sortDeadlinesTransAll(state, action) {
      if (state.deadlinesTransAll.column === action.payload) {
        state.deadlinesTransAll = {
          tasks: state.deadlinesTransAll.tasks.slice().reverse(),
          column: action.payload,
          direction:
            state.deadlinesTransAll.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.deadlinesTransAll = {
          tasks: _.sortBy(state.deadlinesTransAll.tasks, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    sortDeadlinesUpcoming(state, action) {
      if (state.deadlinesUpcoming.column === action.payload) {
        state.deadlinesUpcoming = {
          tasks: state.deadlinesUpcoming.tasks.slice().reverse(),
          column: action.payload,
          direction:
            state.deadlinesUpcoming.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.deadlinesUpcoming = {
          tasks: _.sortBy(state.deadlinesUpcoming.tasks, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
  },
});

export const {
  fetchTasksUpcoming,
  fetchTasksTrans,
  sortTasksTransActive,
  sortTasksTransAll,
  sortTasksUpcoming,
  sortDeadlinesTransActive,
  sortDeadlinesTransAll,
  sortDeadlinesUpcoming,
} = taskSlice.actions;

export default taskSlice.reducer;
