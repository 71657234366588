import React from "react";
import AnnotFieldCheckbox from "./AnnotFieldCheckbox";
import AnnotFieldDateSigned from "./AnnotFieldDateSigned";
import AnnotFieldSignature from "./AnnotFieldSignature";
import AnnotFieldText from "./AnnotFieldText";

export function AnnotTypeDisplay({ annot, doc, pageScale, ipAddress }) {
  if (annot.type === "signature" || annot.type === "initials") {
    return (
      <AnnotFieldSignature
        key={annot.uniqueId}
        annot={annot}
        doc={doc}
        pageScale={pageScale}
        ipAddress={ipAddress}
      />
    );
  }
  if (annot.type === "date") {
    return (
      <AnnotFieldDateSigned
        key={annot.uniqueId}
        annot={annot}
        doc={doc}
        pageScale={pageScale}
      />
    );
  }
  if (annot.type === "text") {
    return (
      <AnnotFieldText
        key={annot.uniqueId}
        annot={annot}
        doc={doc}
        pageScale={pageScale}
      />
    );
  }
  if (annot.type === "checkbox") {
    return (
      <AnnotFieldCheckbox
        key={annot.uniqueId}
        annot={annot}
        doc={doc}
        pageScale={pageScale}
      />
    );
  }
}
