export function exclusiveBrokerageAddendumtoListingContract() {
return [   //2024 Release 2024-03-13 13:58:47
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 166.603,
   left: 398.52,
   width: 174.00,
   height: 13.037
}
,
{
   page: 0,
   name: "Exclusive RighttoSell Listing Contract Seller Listing Contract", 
   isText: false,
   type: "checkbox",
   top: 216.12,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   name: "Exclusive RighttoLease Listing Contract Landlord Listing Contract", 
   isText: false,
   type: "checkbox",
   top: 233.64,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   name: "dated", 
   fontSize: 9,
   type: "date",
   top: 252.731,
   left: 74.52,
   width: 97.44,
   height: 13.429
}
,
{
   page: 0,
   name: "PropertyAddress", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 277.44,
   left: 108.12,
   width: 464.28,
   height: 12.12
}
,
{
   page: 0,
   name: "Sale Commission 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 532.909,
   left: 226.08,
   width: 31.32,
   height: 12.131
}
,
{
   page: 0,
   name: "StaticCommissionAmount", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 532.909,
   left: 405.36,
   width: 167.04,
   height: 12.131
}
,
{
   page: 0,
   name: "paid a fee equal to 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 568.396,
   left: 138.36,
   width: 33.60,
   height: 11.204
}
,
{
   page: 0,
   name: "of the gross rent under the lease or 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 568.396,
   left: 339.96,
   width: 169.44,
   height: 11.204
}
,
{
   page: 0,
   name: "payable as follows", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 580.44,
   left: 129.604,
   width: 101.880,
   height: 10.68
}
,
{
   page: 0,
   name: "Other Compensation", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 590.629,
   left: 192.6,
   width: 379.92,
   height: 12.251
}
,
{
   page: 0,
   name: "CalendarDays", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 660.96,
   left: 477,
   width: 20.16,
   height: 10.68
}
,
{
   page: 0,
   name: "Will", 
   isText: false,
   type: "checkbox",
   top: 685.08,
   left: 484.92,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   name: "Will Not", 
   isText: false,
   type: "checkbox",
   top: 685.08,
   left: 524.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   name: "AddtlAmendments", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 183.796,
   left: 50.7925,
   width: 527.0165,
   height: 103.687
}
,
{
   page: 1,
   name: "Seller", 
   isText: false,
   type: "checkbox",
   top: 304.64,
   left: 51.12,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   name: "Landlord", 
   isText: false,
   type: "checkbox",
   top: 304.64,
   left: 93.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 1,
   name: "Owners Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 328.716,
   left: 121.44,
   width: 194.498,
   height: 15.709
}
,
{
   page: 1,
   name: "Brokers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 330.026,
   left: 391.32,
   width: 184.80,
   height: 15.054
}
,
{
   page: 1,
   name: "signature brokersigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 363.756,
   left: 318.339,
   width: 180.218,
   height: 16.364
}
,
{
   page: 1,
   name: "Date_3", 
   fontSize: 9,
   type: "date",
   top: 362.447,
   left: 503.902,
   width: 72.218,
   height: 17.673
}
,
{
   page: 1,
   name: "Brokerage Firms Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 401.338,
   left: 390.6,
   width: 185.52,
   height: 13.702
}
,
{
   page: 1,
   name: "Text3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 424.147,
   left: 123.71,
   width: 150.00,
   height: 13.491
}
] }
