export function condominiumAssociation() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 134.88,
   left: 31.5,
   width: 548.1,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 159.08,
   left: 31.5,
   width: 548.1,
   height: 13.50
}
] }
