import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Grid, Header, Button, Segment, Divider } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { functionDeleteTransaction } from "../../../app/firestore/functionsService";
import { deleteTransactionTransAll } from "../transactionSlice";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";

export default function TransactionDeleteConfirmModal({
  transactionId,
  lastPath,
}) {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  async function handleDelete() {
    setIsDeleting(true);
    try {
      await functionDeleteTransaction(transactionId);
      if (lastPath === "transactionsAll") {
        dispatch(deleteTransactionTransAll(transactionId));
      }
      setIsDeleting(false);
      dispatch(
        closeModal({
          modalType: "TransactionDeleteConfirmModal",
        })
      );
      toast.success("Transaction successfully deleted");
    } catch (error) {
      setIsDeleting(false);
      toast.error(error.message);
    }
  }

  return (
    <ModalWrapper size="small">
      <Segment>
        <Grid>
          <Grid.Column width={16} className="zero bottom padding">
            <Header as="h2" color="blue" className="zero bottom margin">
              Delete Transaction{" "}
            </Header>
            <Divider className="zero bottom margin" />
          </Grid.Column>
          <Grid.Column width={16}>
            <p className="text-medium text red">
              <b>Warning:</b> Deleting the transaction can not be undone.
            </p>
            <p className="text-medium text red">
              When deleting a transaction all documents that are part of the
              transaction will be deleted as well.
            </p>
            <Divider className="medium top margin" />
            <Button
              loading={isDeleting}
              disabled={isDeleting}
              type="button"
              floated={isMobile ? null : "right"}
              negative
              onClick={() => handleDelete()}
              content="Delete"
              className={isMobile ? "fluid medium bottom margin" : null}
            />
            <Button
              disabled={isDeleting}
              onClick={() =>
                dispatch(
                  closeModal({
                    modalType: "TransactionDeleteConfirmModal",
                  })
                )
              }
              to="#"
              type="button"
              floated={isMobile ? null : "right"}
              content="Cancel"
              className={isMobile ? "fluid medium" : null}
            />
          </Grid.Column>
        </Grid>
      </Segment>
    </ModalWrapper>
  );
}
