export function sellerWarningCOForeclosureProtectionAct() {
return [   //2024 Release 2024-03-13 13:17:22
{
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 200,
      height: 30,
    }
   ,
   {
   page: 0,
   name: "BUYER WILL NOT ASSUME OR PAY ANY PRESENT MORTGAGE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 226.56,
   left: 241.68,
   width: 310.811,
   height: 14.76
}
,
{
   page: 0,
   name: "RESPONSIBLE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 275.04,
   left: 281.858,
   width: 264.459,
   height: 15.415
}
,
{
   page: 0,
   name: "WILL NOT ASSUME ANY PRESENT MORTGAGES DEEDS OF TRUST", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 387.6,
   left: 160.756,
   width: 318.382,
   height: 14.76
}
,
{
   page: 0,
   name: "STREET ADDRESS", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 475.16,
   left: 68.64,
   width: 199.68,
   height: 14.76
}
,
{
   page: 0,
   name: "CITY", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 475.16,
   left: 273.84,
   width: 120.48,
   height: 14.76
}
,
{
   page: 0,
   name: "ZIP", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 476.16,
   left: 455.313,
   width: 83.967,
   height: 13.451
}
] }
