export const taskTypeOptions = [
  { key: "Deadline", text: "Deadline", value: "Deadline" },
  // {key: 'Reminder', text: 'Reminder', value: 'Reminder'},
  // {key: 'Calendar Entry', text: 'Calendar Entry', value: 'Calendar Entry'},
];

export function taskAssignedToOptions(transaction, parties, transClients) {
  let conversionData = {
    Buyer: "Buyer",
    "Buyer 2": "Buyer 2",
    Seller: "Seller",
    "Seller 2": "Seller 2",
    "Buyer Agent": "Buyer Agent",
    "Listing Agent": "Listing Agent",
    "Buyer Lender": "Buyer Lender",
    "Escrow Officer": "Escrow Officer",
    "Title Company": "Title Company",
    "Buyer Attorney": "Buyer Attorney",
    "Seller Attorney": "Seller Attorney",
  };

  if (transaction.agentRepresents === "Buyer") {
    conversionData["Buyer Agent"] = "Buyer Agent - Me";
  } else if (transaction.agentRepresents === "Seller") {
    conversionData["Listing Agent"] = "Listing Agent - Me";
  }

  transClients.forEach((client) => {
    conversionData[
      `${client.role}`
    ] = `${client.role} - ${client.firstName} ${client.lastName}`;
  });

  parties.forEach((party) => {
    conversionData[
      `${party.role}`
    ] = `${party.role} - ${party.firstName} ${party.lastName}`;
  });

  let convertedParties = [
    { key: "Buyer", text: conversionData["Buyer"], value: "Buyer" },
    { key: "Buyer 2", text: conversionData["Buyer 2"], value: "Buyer 2" },
    { key: "Seller", text: conversionData["Seller"], value: "Seller" },
    { key: "Seller 2", text: conversionData["Seller 2"], value: "Seller 2" },
    {
      key: "Buyer Agent",
      text: conversionData["Buyer Agent"],
      value: "Buyer Agent",
    },
    {
      key: "Listing Agent",
      text: conversionData["Listing Agent"],
      value: "Listing Agent",
    },
    {
      key: "Buyer Lender",
      text: conversionData["Buyer Lender"],
      value: "Buyer Lender",
    },
    {
      key: "Escrow Officer",
      text: conversionData["Escrow Officer"],
      value: "Escrow Officer",
    },
    {
      key: "Title Company",
      text: conversionData["Title Company"],
      value: "Title Company",
    },
    {
      key: "Seller Attorney",
      text: conversionData["Seller Attorney"],
      value: "Seller Attorney",
    },
    {
      key: "Buyer Attorney",
      text: conversionData["Buyer Attorney"],
      value: "Buyer Attorney",
    },
  ];

  return convertedParties;
}

export const clientRoleOptions = [
  { key: "buyer", text: "Buyer", value: "Buyer" },
  { key: "buyer2", text: "Buyer 2", value: "Buyer 2" },
  { key: "seller", text: "Seller", value: "Seller" },
  { key: "seller2", text: "Seller 2", value: "Seller 2" },
];

export const partyRoleBuyerOptions = [
  { key: "Seller", text: "Seller", value: "Seller" },
  { key: "Seller 2", text: "Seller 2", value: "Seller 2" },
  {
    key: "Listing Agent",
    text: "Listing Agent",
    value: "Listing Agent",
  },
  { key: "TC (Mine)", text: "TC (Mine)", value: "TC (Mine)" },
  { key: "CoAgent (Mine)", text: "CoAgent (Mine)", value: "CoAgent (Mine)" },
  { key: "Buyer Lender", text: "Buyer Lender", value: "Buyer Lender" },
  { key: "Escrow Officer", text: "Escrow Officer", value: "Escrow Officer" },
  { key: "Title Company", text: "Title Company", value: "Title Company" },
  {
    key: "Seller Attorney",
    text: "Seller Attorney",
    value: "Seller Attorney",
  },
  { key: "Buyer Attorney", text: "Buyer Attorney", value: "Buyer Attorney" },
  { key: "Other", text: "Other", value: "Other" },
];

export const partyRoleSellerOptions = [
  { key: "Buyer", text: "Buyer", value: "Buyer" },
  { key: "Buyer 2", text: "Buyer 2", value: "Buyer 2" },
  {
    key: "Buyer Agent",
    text: "Buyer Agent",
    value: "Buyer Agent",
  },
  { key: "TC (Mine)", text: "TC (Mine)", value: "TC (Mine)" },
  { key: "CoAgent (Mine)", text: "CoAgent (Mine)", value: "CoAgent (Mine)" },
  { key: "Buyer Lender", text: "Buyer Lender", value: "Buyer Lender" },
  { key: "Escrow Officer", text: "Escrow Officer", value: "Escrow Officer" },
  { key: "Title Company", text: "Title Company", value: "Title Company" },
  {
    key: "Seller Attorney",
    text: "Seller Attorney",
    value: "Seller Attorney",
  },
  { key: "Buyer Attorney", text: "Buyer Attorney", value: "Buyer Attorney" },
  { key: "Other", text: "Other", value: "Other" },
];

export const peopleOptions = [
  { key: "Client", text: "Client", value: "Client" },
  { key: "Agent", text: "Agent", value: "Agent" },
  { key: "Lender", text: "Lender", value: "Lender" },
  { key: "Escrow", text: "Escrow", value: "Escrow" },
  { key: "Title", text: "Title", value: "Title" },
  {
    key: "Attorney",
    text: "Attorney",
    value: "Attorney",
  },
  { key: "TC", text: "TC", value: "TC" },
  { key: "Other", text: "Other", value: "Other" },
];

export const taskNameOptions = [
  {
    key: "Earnest Money Due",
    text: "Earnest Money Due",
    value: "Earnest Money Due",
  },
  { key: "Inspection", text: "Inspection", value: "Inspection" },
  {
    key: "Inspection Deadline",
    text: "Inspection Deadline",
    value: "Inspection Deadline",
  },
  { key: "Appraiser Visit", text: "Appraiser Visit", value: "Appraiser Visit" },
  {
    key: "Appraisal Objection Deadline",
    text: "Appraisal Objection Deadline",
    value: "Appraisal Objection Deadline",
  },
  {
    key: "Final Walkthrough",
    text: "Final Walkthrough",
    value: "Final Walkthrough",
  },
];

export const taskStatusOptions = [
  { key: "Active", text: "Active", value: "Active" },
  { key: "Complete", text: "Complete", value: "Complete" },
  // {key: 'Archived', text: 'Archived', value: 'Archived'},
];

export const agentRepresentsOptions = [
  { key: "buyer", text: "Buyer", value: "Buyer" },
  { key: "seller", text: "Seller", value: "Seller" },
  // {key: 'landlord', text: 'Landlord', value: 'Landlord'},
  // {key: 'tenant', text: 'Tenant', value: 'Tenant'},
];

export const transactionStatusOptions = [
  { key: "Active Listing", text: "Active Listing", value: "Active Listing" },
  { key: "Under Contract", text: "Under Contract", value: "Under Contract" },
  { key: "Active Buyer", text: "Active Buyer", value: "Active Buyer" },
  { key: "Complete", text: "Complete", value: "Complete" },
  { key: "Archived", text: "Archived", value: "Archived" },
];

export const transactionStatusOptionsBuyer = [
  { key: "Active Buyer", text: "Active Buyer", value: "Active Buyer" },
  { key: "Under Contract", text: "Under Contract", value: "Under Contract" },
  { key: "Complete", text: "Complete", value: "Complete" },
  { key: "Archived", text: "Archived", value: "Archived" },
];

export const transactionStatusOptionsSeller = [
  { key: "Active Listing", text: "Active Listing", value: "Active Listing" },
  { key: "Under Contract", text: "Under Contract", value: "Under Contract" },
  { key: "Complete", text: "Complete", value: "Complete" },
  { key: "Archived", text: "Archived", value: "Archived" },
];

export const startingDateOptions = [
  { key: "contract", text: "Contract", value: "Contract" },
  { key: "closing", text: "Closing", value: "Closing" },
  { key: "mec", text: "MEC", value: "MEC" },
];

export const daysOfWeekArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const tableForDeadlineTemplates = [
  {
    isTopic: false,
    itemNumber: 2,
    reference: "§ 4",
    event: "Alternative Earnest Money Deadline",
    name: "alternativeEarnestMoneyDeadline",
  },
  {
    isTopic: true,
    itemNumber: null,
    reference: null,
    event: "Title",
    name: null,
  },
  {
    isTopic: false,
    itemNumber: 3,
    reference: "§ 8",
    event: "Record Title Deadline (and Tax Certificate)",
    name: "recordTitleDeadline",
  },
  {
    isTopic: false,
    itemNumber: 4,
    reference: "§ 8",
    event: "Record Title Objection Deadline",
    name: "recordTitleObjectionDeadline",
  },
  {
    isTopic: false,
    itemNumber: 5,
    reference: "§ 8",
    event: "Off-Record Title Deadline",
    name: "offRecordTitleDeadline",
  },
  {
    isTopic: false,
    itemNumber: 6,
    reference: "§ 8",
    event: "Off-Record Title Objection Deadline",
    name: "offRecordTitleObjectionDeadline",
  },
  {
    isTopic: false,
    itemNumber: 7,
    reference: "§ 8",
    event: "Title Resolution Deadline",
    name: "titleResolutionDeadline",
  },
  {
    isTopic: false,
    itemNumber: 8,
    reference: "§ 8",
    event: "Third Party Right to Purchase/Approve Deadline",
    name: "thirdPartyRightToPurchaseApproveDeadline",
  },
  {
    isTopic: true,
    itemNumber: null,
    reference: null,
    event: "Owner's Association",
    name: null,
  },
  {
    isTopic: false,
    itemNumber: 9,
    reference: "§ 7",
    event: "Association Documents Deadline",
    name: "associationDocumentsDeadline",
  },
  {
    isTopic: false,
    itemNumber: 10,
    reference: "§ 7",
    event: "Association Documents Termination Deadline",
    name: "associationDocumentsTerminationDeadline",
  },
  {
    isTopic: true,
    itemNumber: null,
    reference: null,
    event: "Seller's Disclosures",
    name: null,
  },
  {
    isTopic: false,
    itemNumber: 11,
    reference: "§ 10",
    event: "Seller's Property Disclosure Deadline",
    name: "sellersPropertyDisclosureDeadline",
  },
  {
    isTopic: false,
    itemNumber: 12,
    reference: "§ 10",
    event: "Lead-Based Paint Disclosure Deadline",
    name: "leadBasedPaintDisclosureDeadline",
  },
  {
    isTopic: true,
    itemNumber: null,
    reference: null,
    event: "Loan and Credit",
    name: null,
  },
  {
    isTopic: false,
    itemNumber: 13,
    reference: "§ 5",
    event: "New Loan Application Deadline",
    name: "newLoanApplicationDeadline",
  },
  {
    isTopic: false,
    itemNumber: 14,
    reference: "§ 5",
    event: "New Loan Terms Deadline",
    name: "newLoanTermsDeadline",
  },
  {
    isTopic: false,
    itemNumber: 15,
    reference: "§ 5",
    event: "New Loan Availability Deadline",
    name: "newLoanAvailabilityDeadline",
  },
  {
    isTopic: false,
    itemNumber: 16,
    reference: "§ 5",
    event: "Buyer's Credit Information Deadline",
    name: "buyersCreditInformationDeadline",
  },
  {
    isTopic: false,
    itemNumber: 17,
    reference: "§ 5",
    event: "Disapproval of Buyer's Credit Information Deadline",
    name: "disapprovalOfBuyersCreditInformationDeadline",
  },
  {
    isTopic: false,
    itemNumber: 18,
    reference: "§ 5",
    event: "Existing Loan Deadline",
    name: "existingLoanDeadline",
  },
  {
    isTopic: false,
    itemNumber: 19,
    reference: "§ 5",
    event: "Existing Loan Termination Deadline",
    name: "existingLoanTerminationDeadline",
  },
  {
    isTopic: false,
    itemNumber: 20,
    reference: "§ 5",
    event: "Loan Transfer Approval Deadline",
    name: "loanTransferApprovalDeadline",
  },
  {
    isTopic: false,
    itemNumber: 21,
    reference: "§ 4",
    event: "Seller or Private Financing Deadline",
    name: "sellerOrPrivateFinancingDeadline",
  },
  {
    isTopic: true,
    itemNumber: null,
    reference: null,
    event: "Appraisal",
    name: null,
  },
  {
    isTopic: false,
    itemNumber: 22,
    reference: "§ 6",
    event: "Appraisal Deadline",
    name: "appraisalDeadline",
  },
  {
    isTopic: false,
    itemNumber: 23,
    reference: "§ 6",
    event: "Appraisal Objection Deadline",
    name: "appraisalObjectionDeadline",
  },
  {
    isTopic: false,
    itemNumber: 24,
    reference: "§ 6",
    event: "Appraisal Resolution Deadline",
    name: "appraisalResolutionDeadline",
  },
  {
    isTopic: true,
    itemNumber: null,
    reference: null,
    event: "Survey",
    name: null,
  },
  {
    isTopic: false,
    itemNumber: 25,
    reference: "§ 9",
    event: "New ILC or New Survey Deadline",
    name: "newIlcOrNewSurveyDeadline",
  },
  {
    isTopic: false,
    itemNumber: 26,
    reference: "§ 9",
    event: "New ILC or New Survey Objection Deadline",
    name: "newIlcOrNewSurveyObjectionDeadline",
  },
  {
    isTopic: false,
    itemNumber: 27,
    reference: "§ 9",
    event: "New ILC or New Survey Resolution Deadline",
    name: "newIlcOrNewSurveyResolutionDeadline",
  },
  {
    isTopic: true,
    itemNumber: null,
    reference: null,
    event: "Inspection and Due Diligence",
    name: null,
  },
  {
    isTopic: false,
    itemNumber: 28,
    reference: "§ 2",
    event: "Water Rights Examination Deadline",
    name: "waterRightsExaminationDeadline",
  },
  {
    isTopic: false,
    itemNumber: 29,
    reference: "§ 8",
    event: "Mineral Rights Examination Deadline",
    name: "mineralRightsExaminationDeadline",
  },
  {
    isTopic: false,
    itemNumber: 30,
    reference: "§ 10",
    event: "Inspection Termination Deadline",
    name: "inspectionTerminationDeadline",
  },
  {
    isTopic: false,
    itemNumber: 31,
    reference: "§ 10",
    event: "Inspection Objection Deadline",
    name: "inspectionObjectionDeadline",
  },
  {
    isTopic: false,
    itemNumber: 32,
    reference: "§ 10",
    event: "Inspection Resolution Deadline",
    name: "inspectionResolutionDeadline",
  },
  {
    isTopic: false,
    itemNumber: 33,
    reference: "§ 10",
    event: "Property Insurance Termination Deadline",
    name: "propertyInsuranceTerminationDeadline",
  },
  {
    isTopic: false,
    itemNumber: 34,
    reference: "§ 10",
    event: "Due Diligence Documents Delivery Deadline",
    name: "dueDiligenceDocumentsDeliveryDeadline",
  },
  {
    isTopic: false,
    itemNumber: 35,
    reference: "§ 10",
    event: "Due Diligence Documents Objection Deadline",
    name: "dueDiligenceDocumentsObjectionDeadline",
  },
  {
    isTopic: false,
    itemNumber: 36,
    reference: "§ 10",
    event: "Due Diligence Documents Resolution Deadline",
    name: "dueDiligenceDocumentsResolutionDeadline",
  },
  {
    isTopic: false,
    itemNumber: 37,
    reference: "§ 10",
    event: "Conditional Sale Deadline",
    name: "conditionalSaleDeadline",
  },
  {
    isTopic: false,
    itemNumber: 38,
    reference: "§ 10",
    event: "Lead Based Paint Termination Deadline",
    name: "leadBasedPaintTerminationDeadline",
  },
  {
    isTopic: true,
    itemNumber: null,
    reference: null,
    event: "Closing and Possession",
    name: null,
  },
  {
    isTopic: false,
    itemNumber: 40,
    reference: "§ 17",
    event: "Possession Date",
    name: "possessionDate",
  },
  {
    isTopic: false,
    itemNumber: 42,
    reference: "§ 27",
    event: "Acceptance Deadline Date",
    name: "acceptanceDeadlineDate",
  },
  {
    isTopic: false,
    itemNumber: 39,
    reference: "§ 12",
    event: "Closing Date",
    name: "closingDate",
  },
];
