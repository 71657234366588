import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Divider, Grid, Header, Icon, Segment } from "semantic-ui-react";
import {
  closeModal,
  openModal,
} from "../../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../../app/common/modals/modalWrapper";
import DocFileUpload from "./DocFileUpload";
import { useMediaQuery } from "react-responsive";

export default function DocAddDocumentsModal() {
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const dispatch = useDispatch();

  function handleSelectForms() {
    dispatch(
      closeModal({
        modalType: "DocAddDocumentsModal",
      })
    );
    dispatch(
      openModal({
        modalType: "DocSelectFormsModal",
      })
    );
  }

  return (
    <>
      <ModalWrapper>
        <Segment placeholder>
          <Grid stackable columns={2} textAlign="center">
            {!isMobile && <Divider vertical>Or</Divider>}
            <Grid.Row verticalAlign="middle">
              <Grid.Column>
                <DocFileUpload status={"In Progress"} />
              </Grid.Column>
              <Grid.Column as={Link} to="#" onClick={() => handleSelectForms()}>
                <Header icon color="grey">
                  <Icon name="file alternate outline" />
                  <br />
                  Select Forms
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </ModalWrapper>
    </>
  );
}
