import { formatDistance } from "date-fns";
import React from "react";
import { Icon, Popup, Table, Label } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  convertFileTypeToIcon,
  convertFullName,
  convertPartiesAbleToShare,
  convertRoleToSharingDisplay,
  convertSharingWithToColor,
  dateFormatDeadlineWithTime,
  partyIsAgent,
  truncateText,
} from "../../../../app/common/util/util";
import DocActionButtons from "../../docComponents/DocActionButtons";
import {
  addHistoryToDb,
  sendDocSharingEmail,
  sendOfferSubmittedEmail,
  updateDocAddSharingInDb,
  updateDocInDb,
  updateDocRemoveSharingInDb,
  updateTransAddSharingInDb,
} from "../../../../app/firestore/firestoreService";
import _ from "lodash";
import { openModal } from "../../../../app/common/modals/modalSlice";
import { functionShareWithAgent } from "../../../../app/firestore/functionsService";
import { serverTimestamp } from "firebase/firestore";

export default function DocTransActiveListItem({ doc }) {
  const dispatch = useDispatch();
  const { transaction, allParties } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);

  function sendOfferAlertEmailToAdmin(party, doc) {
    if (
      partyIsAgent(party.role) &&
      doc.title === "Contract to Buy and Sell, Residential"
    ) {
      sendOfferSubmittedEmail(currentUserProfile, party, transaction, doc);
    }
  }

  function handleSharingClick(party) {
    const partyRoleCamel = _.camelCase(party.role);
    const agentProfile = transaction.agentProfile?.lastName
      ? transaction.agentProfile
      : currentUserProfile;
    const sharedByName = agentProfile.firstName + " " + agentProfile.lastName;
    if (doc.sharingWithRole?.[partyRoleCamel]) {
      try {
        updateDocRemoveSharingInDb(doc.id, party, transaction);
        addHistoryToDb(
          transaction.id,
          currentUserProfile,
          "unshared",
          doc.name,
          party
        );
      } catch (error) {
        throw error;
      }
    } else if (
      partyIsAgent(party.role) &&
      party.isUser &&
      !party.isLinked &&
      !party.declinedLinking
    ) {
      dispatch(
        openModal({
          modalType: "DocShareAgentLinking",
          modalProps: { doc: doc, party: party, transaction: transaction },
        })
      );
    } else {
      try {
        updateDocAddSharingInDb(doc.id, party, transaction);
        updateTransAddSharingInDb(transaction.id, party, transaction);
      } catch (error) {
        throw error;
      }
      if (partyIsAgent(party.role) && party.isUser && party.isLinked) {
        updateDocInDb(
          doc.id,
          {
            sentToAgent: true,
            sentToAgentId: party.id,
            sentDocIsDirty: false,
            sentToAgentAt: serverTimestamp(),
          },
          true
        );
        functionShareWithAgent({
          doc: doc,
          party: party,
          sharedBy: sharedByName,
        });
      }
      sendDocSharingEmail([party], "sharing", agentProfile, transaction);
      sendOfferAlertEmailToAdmin(party, doc);
      addHistoryToDb(
        transaction.id,
        currentUserProfile,
        "shared",
        doc.name,
        party
      );
    }
  }

  return (
    <Table.Row key={doc.id}>
      <Table.Cell>
        <Icon
          name={`file ${convertFileTypeToIcon(doc)} outline`}
          size="large"
        />
        &nbsp;
        {truncateText(doc.name, 60)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {doc.signingComplete && (
          <Icon color="green" name="check" size="large" />
        )}
      </Table.Cell>

      {doc.shared && <Table.Cell>{doc.sharedBy}</Table.Cell>}
      <Table.Cell>
        {convertPartiesAbleToShare(allParties, transaction.agentRepresents).map(
          (party) => (
            <React.Fragment key={party.role}>
              {party.email && (
                <Popup
                  trigger={
                    <Label
                      key={party.role}
                      style={{ cursor: "pointer", marginBottom: "2px" }}
                      color={convertSharingWithToColor(doc, party)}
                      onClick={() => handleSharingClick(party)}
                    >
                      {convertRoleToSharingDisplay(party.role)}
                    </Label>
                  }
                >
                  <p className="tiny bottom margin">
                    <span className="bold text blue">{party.role}:</span>{" "}
                    {convertFullName(party)}
                  </p>
                  {partyIsAgent(party.role) &&
                    party.hasTransactionCoordinator &&
                    party.transactionCoordinator?.firstName &&
                    party.transactionCoordinator?.lastName &&
                    party.transactionCoordinator?.email && (
                      <p className="tiny bottom margin">
                        <span className="bold text blue">TC:</span>{" "}
                        {convertFullName(party.transactionCoordinator)}
                      </p>
                    )}
                  {partyIsAgent(party.role) &&
                    party.isUser &&
                    party.isLinked &&
                    doc.sentToAgentAt && (
                      <p>
                        <span className="bold text blue">Sent out</span>{" "}
                        {dateFormatDeadlineWithTime(doc.sentToAgentAt)}
                      </p>
                    )}
                </Popup>
              )}
            </React.Fragment>
          )
        )}
      </Table.Cell>
      <Table.Cell>
        <p>
          {doc.updatedAt && doc.updatedAt instanceof Date
            ? formatDistance(doc.updatedAt, new Date(), { addSuffix: true })
            : ""}
        </p>
      </Table.Cell>
      <Table.Cell>
        <DocActionButtons doc={doc} />
      </Table.Cell>
    </Table.Row>
  );
}
