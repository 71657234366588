export function sellersNetSheet() {
return [   //2024 Release 2024-10-24 18:22:05
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "Names", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 110.92,
   left: 135.12,
   width: 161.40,
   height: 14.48
}
,
{
   page: 0,
   name: "Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 130.24,
   left: 135.12,
   width: 161.40,
   height: 12.68
}
,
{
   page: 0,
   name: "Closing Date", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 147.76,
   left: 135.12,
   width: 161.40,
   height: 12.68
}
,
{
   page: 0,
   name: "OTHER", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 690.04,
   left: 85.68,
   width: 164.28,
   height: 14.48
}
,
{
   page: 0,
   name: "CREDITS", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 204.16,
   left: 279.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "CREDITS 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 689.92,
   left: 279.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "CREDITS 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 709.36,
   left: 279.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "CREDITS 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 728.8,
   left: 279.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 110.92,
   left: 387.12,
   width: 161.40,
   height: 14.48
}
,
{
   page: 0,
   name: "2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 130.24,
   left: 387.12,
   width: 161.40,
   height: 12.68
}
,
{
   page: 0,
   name: "3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 147.76,
   left: 387.12,
   width: 161.40,
   height: 12.68
}
,
{
   page: 0,
   name: "1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 223.6,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 243.04,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "3_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 262.48,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 281.92,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 320.8,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 340.24,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 359.68,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 379.12,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 398.56,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 6", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 417.88,
   left: 387.12,
   width: 71.16,
   height: 14.36
}
,
{
   page: 0,
   name: "DEBITS 7", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 437.2,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 476.08,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 495.52,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 1_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 534.4,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 2_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 553.84,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 3_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 573.28,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 1_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 612.16,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 2_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 631.6,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 3_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 651.04,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 1_5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 689.92,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 2_5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 709.36,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
,
{
   page: 0,
   name: "DEBITS 3_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 728.8,
   left: 387.12,
   width: 71.16,
   height: 14.48
}
] }
