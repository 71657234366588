export function farmRanchRecConventionalLoan() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 47", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 116.136,
   left: 35.856,
   width: 535.104,
   height: 14.400
}
,
{
   page: 0,
   name: "Text Field 65", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 180.864,
   left: 54.576,
   width: 125.280,
   height: 14.400
}
,
{
   page: 0,
   name: "Text Field 61", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 180.72,
   left: 405.936,
   width: 55.296,
   height: 14.40
}
,
{
   page: 0,
   name: "Text Field 66", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 192.24,
   left: 82.08,
   width: 50.40,
   height: 14.40
}
,
{
   page: 0,
   name: "Text Field 64", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.925,
   left: 243.504,
   width: 50.400,
   height: 14.400
}
,
{
   page: 0,
   name: "Text Field 63", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 202.896,
   left: 230.688,
   width: 80.352,
   height: 14.400
}
,
{
   page: 0,
   name: "Text Field 62", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 202.896,
   left: 327.204,
   width: 55.296,
   height: 14.400
}
,
{
   page: 0,
   name: "Text Field 67", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 305.28,
   left: 63.36,
   width: 25.227,
   height: 14.40
}
,
{
   page: 0,
   name: "Text Field 68", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 610.56,
   left: 49.68,
   width: 523.089,
   height: 14.40
}
,
{
   page: 0,
   name: "Text Field 69", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 627.12,
   left: 49.68,
   width: 523.089,
   height: 14.40
}
,
{
   page: 0,
   name: "Text Field 70", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 644.544,
   left: 49.7161,
   width: 523.0889,
   height: 14.400
}
,
{
   page: 0,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 679.736,
   left: 49.4064,
   width: 190.6406,
   height: 14.4301
}
,
{
   page: 0,
   name: "Text Field 72", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 681.696,
   left: 238.896,
   width: 46.080,
   height: 14.400
}
,
{
   page: 0,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 680.49,
   left: 324.833,
   width: 179.485,
   height: 14.4301
}
,
{
   page: 0,
   name: "Text Field 74", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 681.696,
   left: 504.432,
   width: 69.984,
   height: 14.400
}
,
{
   page: 0,
   name: "ERROR", 
   type: "UNKNOWN",
   fontName: "Helvetica",
   fontSize: 10,
   top: 710.7717,
   left: 49.5121,
   width: 236.0629,
   height: 14.4297
}
,
{
   page: 0,
   name: "ERROR", 
   type: "UNKNOWN",
   fontName: "Helvetica",
   fontSize: 10,
   top: 711.5686,
   left: 325.232,
   width: 250.008,
   height: 14.4297
}
] }
