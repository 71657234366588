export function contracttoBuyandSellForeclosure2024() {
return [   //2024 Release 2024-08-04 19:07:29
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 207.76,
   left: 456.12,
   width: 109.92,
   height: 12.44
}
,
{
   page: 0,
   name: "Buyer", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 316.0,
   left: 116.88,
   width: 420.00,
   height: 12.44
}
,
{
   page: 0,
   name: "Joint Tenants", 
   isText: false,
   type: "checkbox",
   top: 346.2,
   left: 75.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Tenants In Common", 
   isText: false,
   type: "checkbox",
   top: 359.88,
   left: 75.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 373.8,
   left: 75.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 374.56,
   left: 119.16,
   width: 420.00,
   height: 9.08
}
,
{
   page: 0,
   name: "Seller", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 406.24,
   left: 118.44,
   width: 420.12,
   height: 12.44
}
,
{
   page: 0,
   name: "Property The Property is the following legally described real estate in the County of", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 436.72,
   left: 433.68,
   width: 135.00,
   height: 12.44
}
,
{
   page: 0,
   name: "known as", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 613.08,
   left: 117.96,
   width: 445.08,
   height: 13.44
}
,
{
   page: 0,
   name: "Text3", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 465.384,
   left: 58.9093,
   width: 515.1877,
   height: 148.942
}
,
{
   page: 1,
   name: "remote controls If checked the following are owned by", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 33.12,
   left: 283.92,
   width: 55.08,
   height: 12.36
}
,
{
   page: 1,
   name: "Leased items should be listed under  258 Leased Items If any additional items are attached to the Property after the", 
   isText: false,
   type: "checkbox",
   top: 66.12,
   left: 89.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Water Softeners", 
   isText: false,
   type: "checkbox",
   top: 66.12,
   left: 166.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Security Systems", 
   isText: false,
   type: "checkbox",
   top: 66.12,
   left: 260.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Satellite Systems including satellite dishes", 
   isText: false,
   type: "checkbox",
   top: 66.12,
   left: 357,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "property outside of this Contract", 
   isText: false,
   type: "checkbox",
   top: 336.84,
   left: 76.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "review under 106 Encumbered Inclusion Documents and Buyers receipt of written approval by such lender before", 
   isText: false,
   type: "checkbox",
   top: 499.56,
   left: 122.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Will", 
   isText: false,
   type: "checkbox",
   top: 499.56,
   left: 167.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "and the use or ownership of the following", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 584.4,
   left: 89.04,
   width: 300.00,
   height: 12.36
}
,
{
   page: 1,
   name: "undefined_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 597.6,
   left: 160.08,
   width: 395.04,
   height: 12.36
}
,
{
   page: 1,
   name: "Text4", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 190.474,
   left: 68.7276,
   width: 505.0694,
   height: 145.262
}
,
{
   page: 1,
   name: "Text5", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 427.42,
   left: 71.3458,
   width: 504.1492,
   height: 70.753
}
,
{
   page: 1,
   name: "Text6", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 653.239,
   left: 68.7276,
   width: 505.0694,
   height: 102.0288
}
,
{
   page: 2,
   name: "Will_2", 
   isText: false,
   type: "checkbox",
   top: 35.64,
   left: 121.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will Not assume Sellers debt and obligations under such leases for the Leased Items subject to", 
   isText: false,
   type: "checkbox",
   top: 35.64,
   left: 166.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Solar Power Plan If the box is checked Seller has entered into a solar power purchase agreement regardless", 
   isText: false,
   type: "checkbox",
   top: 75.48,
   left: 104.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will_3", 
   isText: false,
   type: "checkbox",
   top: 101.88,
   left: 398.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will Not assume Sellers", 
   isText: false,
   type: "checkbox",
   top: 101.88,
   left: 443.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Deeded Water Rights The following legally described water rights", 
   isText: false,
   type: "checkbox",
   top: 284.04,
   left: 114.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "deed at Closing", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 347.4,
   left: 359.52,
   width: 144.96,
   height: 12.48
}
,
{
   page: 2,
   name: "Other Rights Relating to Water The following rights relating to water not included in  271 273 and", 
   isText: false,
   type: "checkbox",
   top: 367.32,
   left: 114.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Well Rights Seller agrees to supply required information to Buyer about the well Buyer understands that if", 
   isText: false,
   type: "checkbox",
   top: 457.56,
   left: 114.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "undefined_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 534.24,
   left: 274.56,
   width: 216.84,
   height: 12.48
}
,
{
   page: 2,
   name: "Water Stock The water stock to be transferred at Closing are as follows", 
   isText: false,
   type: "checkbox",
   top: 554.04,
   left: 114.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Text7", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 155.782,
   left: 57.6003,
   width: 515.1877,
   height: 112.148
}
,
{
   page: 2,
   name: "Text8", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 293.892,
   left: 69.3821,
   width: 500.4699,
   height: 54.196
}
,
{
   page: 2,
   name: "Text9", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 390.765,
   left: 69.3821,
   width: 501.3899,
   height: 64.314
}
,
{
   page: 2,
   name: "Text10", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 564.875,
   left: 69.3821,
   width: 501.4929,
   height: 51.455
}
,
{
   page: 3,
   name: "Time of Day Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 48.12,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateAlternative Earnest Money Deadline", 
   fontSize: 9,
   type: "date",
   top: 61.8,
   left: 307.68,
   width: 69.72,
   height: 12.84
}
,
{
   page: 3,
   name: "Alternative Earnest Money Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 61.8,
   left: 378.72,
   width: 193.56,
   height: 12.84
}
,
{
   page: 3,
   name: "DateRecord Title Deadline and Tax Certificate", 
   fontSize: 9,
   type: "date",
   top: 87.72,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Record Title Deadline and Tax Certificate_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 87.72,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 101.4,
   left: 307.68,
   width: 69.72,
   height: 12.6
}
,
{
   page: 3,
   name: "Record Title Objection Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 101.4,
   left: 378.72,
   width: 193.56,
   height: 12.6
}
,
{
   page: 3,
   name: "DateOffRecord Title Deadline", 
   fontSize: 9,
   type: "date",
   top: 115.08,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "OffRecord Title Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 115.08,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateOffRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 128.76,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "OffRecord Title Objection Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 128.76,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateTitle Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 142.44,
   left: 307.68,
   width: 69.72,
   height: 12.84
}
,
{
   page: 3,
   name: "Title Resolution Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 142.44,
   left: 378.72,
   width: 193.56,
   height: 12.84
}
,
{
   page: 3,
   name: "DateThird Party Right to PurchaseApprove Deadline", 
   fontSize: 9,
   type: "date",
   top: 156.36,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Third Party Right to PurchaseApprove Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 156.36,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateAssociation Documents Deadline", 
   fontSize: 9,
   type: "date",
   top: 182.04,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Association Documents Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 182.04,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateAssociation Documents Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 195.72,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Association Documents Termination Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 195.72,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateSellers Property Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 221.4,
   left: 307.68,
   width: 69.72,
   height: 12.6
}
,
{
   page: 3,
   name: "Sellers Property Disclosure Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 221.4,
   left: 378.72,
   width: 193.56,
   height: 12.6
}
,
{
   page: 3,
   name: "DateLeadBased Paint Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 235.08,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "LeadBased Paint Disclosure Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 235.08,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateNew Loan Application Deadline", 
   fontSize: 9,
   type: "date",
   top: 260.76,
   left: 307.68,
   width: 69.72,
   height: 12.84
}
,
{
   page: 3,
   name: "New Loan Application Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 260.76,
   left: 378.72,
   width: 193.56,
   height: 12.84
}
,
{
   page: 3,
   name: "DateNew Loan Terms Deadline", 
   fontSize: 9,
   type: "date",
   top: 274.68,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "New Loan Terms Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 274.68,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateNew Loan Availability Deadline", 
   fontSize: 9,
   type: "date",
   top: 288.36,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "New Loan Availability Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 288.36,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateBuyers Credit Information Deadline", 
   fontSize: 9,
   type: "date",
   top: 302.04,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Buyers Credit Information Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 302.04,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateDisapproval of Buyers Credit Information Deadline", 
   fontSize: 9,
   type: "date",
   top: 315.72,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Disapproval of Buyers Credit Information Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 315.72,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateExisting Loan Deadline", 
   fontSize: 9,
   type: "date",
   top: 329.4,
   left: 307.68,
   width: 69.72,
   height: 12.84
}
,
{
   page: 3,
   name: "Existing Loan Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 329.4,
   left: 378.72,
   width: 193.56,
   height: 12.84
}
,
{
   page: 3,
   name: "DateExisting Loan Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 343.32,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Existing Loan Termination Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 343.32,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateLoan Transfer Approval Deadline", 
   fontSize: 9,
   type: "date",
   top: 357,
   left: 307.68,
   width: 69.72,
   height: 12.6
}
,
{
   page: 3,
   name: "Loan Transfer Approval Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 357,
   left: 378.72,
   width: 193.56,
   height: 12.6
}
,
{
   page: 3,
   name: "DateSeller or Private Financing Deadline", 
   fontSize: 9,
   type: "date",
   top: 370.68,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Seller or Private Financing Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 370.68,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateAppraisal Deadline", 
   fontSize: 9,
   type: "date",
   top: 396.36,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Appraisal Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 396.36,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateAppraisal Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 410.04,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Appraisal Objection Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 410.04,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateAppraisal Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 423.72,
   left: 307.68,
   width: 69.72,
   height: 12.84
}
,
{
   page: 3,
   name: "Appraisal Resolution Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 423.72,
   left: 378.72,
   width: 193.56,
   height: 12.84
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Deadline", 
   fontSize: 9,
   type: "date",
   top: 449.64,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "New ILC or New Survey Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 449.64,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 463.32,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "New ILC or New Survey Objection Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 463.32,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 477,
   left: 307.68,
   width: 69.72,
   height: 12.6
}
,
{
   page: 3,
   name: "New ILC or New Survey Resolution Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 477,
   left: 378.72,
   width: 193.56,
   height: 12.6
}
,
{
   page: 3,
   name: "DateWater Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 502.68,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Water Rights Examination Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 502.68,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateMineral Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 516.36,
   left: 307.68,
   width: 69.72,
   height: 12.84
}
,
{
   page: 3,
   name: "Mineral Rights Examination Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 516.36,
   left: 378.72,
   width: 193.56,
   height: 12.84
}
,
{
   page: 3,
   name: "DateInspection Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 530.28,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Inspection Termination Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 530.28,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateInspection Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 543.96,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Inspection Objection Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 543.96,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateInspection Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 557.64,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Inspection Resolution Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 557.64,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateProperty Insurance Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 571.32,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Property Insurance Termination Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 571.32,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Delivery Deadline", 
   fontSize: 9,
   type: "date",
   top: 585,
   left: 307.68,
   width: 69.72,
   height: 12.6
}
,
{
   page: 3,
   name: "Due Diligence Documents Delivery Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 585,
   left: 378.72,
   width: 193.56,
   height: 12.6
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 598.68,
   left: 307.68,
   width: 69.72,
   height: 12.84
}
,
{
   page: 3,
   name: "Due Diligence Documents Objection Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 598.68,
   left: 378.72,
   width: 193.56,
   height: 12.84
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 612.6,
   left: 307.68,
   width: 69.72,
   height: 12.6
}
,
{
   page: 3,
   name: "Due Diligence Documents Resolution Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 612.6,
   left: 378.72,
   width: 193.56,
   height: 12.6
}
,
{
   page: 3,
   name: "DateConditional Sale Deadline", 
   fontSize: 9,
   type: "date",
   top: 626.28,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Conditional Sale Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 626.28,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateLeadBased Paint Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 639.96,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "LeadBased Paint Termination Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 639.96,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateClosing Date", 
   fontSize: 9,
   type: "date",
   top: 665.64,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Closing Date_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 665.64,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DatePossession Date", 
   fontSize: 9,
   type: "date",
   top: 679.32,
   left: 307.68,
   width: 69.72,
   height: 12.84
}
,
{
   page: 3,
   name: "Possession Date_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 679.32,
   left: 378.72,
   width: 193.56,
   height: 12.84
}
,
{
   page: 3,
   name: "Possession Time_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 693.24,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "DateAcceptance Deadline Date", 
   fontSize: 9,
   type: "date",
   top: 706.92,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Acceptance Deadline Date_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 706.92,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 3,
   name: "Acceptance Deadline Time_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 720.6,
   left: 378.72,
   width: 193.56,
   height: 12.6
}
,
{
   page: 3,
   name: " 2744", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 734.28,
   left: 63.84,
   width: 39.24,
   height: 11.60
}
,
{
   page: 3,
   name: "Acceptance Deadline Time44", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 734.28,
   left: 104.4,
   width: 201.96,
   height: 11.60
}
,
{
   page: 3,
   name: "Date or Deadline44", 
   fontSize: 9,
   type: "date",
   top: 734.28,
   left: 307.68,
   width: 69.72,
   height: 12.60
}
,
{
   page: 3,
   name: "Deadline44_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 734.28,
   left: 378.72,
   width: 193.56,
   height: 12.60
}
,
{
   page: 4,
   name: "Deadlines If any deadline falls on a Saturday Sunday or federal or Colorado state holiday Holiday such", 
   isText: false,
   type: "checkbox",
   top: 301.32,
   left: 175.56,
   width: 10.56,
   height: 10.56
}
,
{
   page: 4,
   name: "neither box be checked the deadline will not be extended", 
   isText: false,
   type: "checkbox",
   top: 301.32,
   left: 130.92,
   width: 10.56,
   height: 10.56
}
,
{
   page: 4,
   name: "Amount", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 497.182,
   left: 414.597,
   width: 83.240,
   height: 12.600
}
,
{
   page: 4,
   name: "Purchase Price", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 398.4,
   left: 300.797,
   width: 94.960,
   height: 13.36
}
,
{
   page: 4,
   name: "Amount_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 512.946,
   left: 414.597,
   width: 83.240,
   height: 12.600
}
,
{
   page: 4,
   name: "Earnest Money", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 414.96,
   left: 414.597,
   width: 83.240,
   height: 12.60
}
,
{
   page: 4,
   name: "New Loan", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 431.28,
   left: 414.597,
   width: 83.240,
   height: 12.60
}
,
{
   page: 4,
   name: "Assumption Balance", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 447.6,
   left: 414.597,
   width: 83.240,
   height: 12.6
}
,
{
   page: 4,
   name: "Private Financing", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 463.92,
   left: 414.597,
   width: 83.240,
   height: 12.60
}
,
{
   page: 4,
   name: "Seller Financing", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 480.24,
   left: 414.597,
   width: 83.240,
   height: 12.60
}
,
{
   page: 4,
   name: " 477", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 497.68,
   left: 99.84,
   width: 52.68,
   height: 12.52
}
,
{
   page: 4,
   name: "Seller Financing7", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 497.68,
   left: 153.84,
   width: 133.80,
   height: 12.52
}
,
{
   page: 4,
   name: "fill_27", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 496.68,
   left: 300.797,
   width: 94.960,
   height: 13.36
}
,
{
   page: 4,
   name: " 478", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 513.28,
   left: 99.84,
   width: 52.68,
   height: 12.76
}
,
{
   page: 4,
   name: "Seller Financing8", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 513.28,
   left: 153.84,
   width: 133.80,
   height: 12.76
}
,
{
   page: 4,
   name: "Cash at Closing", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 528.895,
   left: 414.597,
   width: 83.240,
   height: 12.600
}
,
{
   page: 4,
   name: "TOTAL", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 545.131,
   left: 414.597,
   width: 83.240,
   height: 12.600
}
,
{
   page: 4,
   name: "fill_29", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 544.44,
   left: 300.797,
   width: 94.960,
   height: 13.36
}
,
{
   page: 4,
   name: "Seller Concession The Seller", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 571.92,
   left: 331.2,
   width: 95.04,
   height: 12.36
}
,
{
   page: 4,
   name: "undefined_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 657.12,
   left: 408.6,
   width: 159.96,
   height: 12.36
}
,
{
   page: 4,
   name: "Earnest Money Holder in its trust account on", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 670.32,
   left: 197.64,
   width: 160.08,
   height: 12.36
}
,
{
   page: 5,
   name: "or as otherwise agreed in writing between the parties to allow disbursement by Closing Company at Closing OR SUCH", 
   isText: false,
   type: "checkbox",
   top: 391.32,
   left: 434.28,
   width: 11.28,
   height: 11.28
}
,
{
   page: 5,
   name: "funds that are immediately verifiable and available in an amount not less than the amount stated as Cash at Closing in", 
   isText: false,
   type: "checkbox",
   top: 391.32,
   left: 483.24,
   width: 11.28,
   height: 11.28
}
,
{
   page: 5,
   name: "453", 
   isText: false,
   type: "checkbox",
   top: 541.56,
   left: 89.64,
   width: 10.56,
   height: 10.56
}
,
{
   page: 5,
   name: "Loan Limitations Buyer may purchase the Property using any of the following types of loans", 
   isText: false,
   type: "checkbox",
   top: 540.84,
   left: 177.96,
   width: 11.28,
   height: 11.28
}
,
{
   page: 5,
   name: "If either or both of the FHA or VA boxes are checked and Buyer closes the transaction using one of those loan types", 
   isText: false,
   type: "checkbox",
   top: 540.84,
   left: 227.64,
   width: 11.28,
   height: 11.28
}
,
{
   page: 5,
   name: "Bond", 
   isText: false,
   type: "checkbox",
   top: 540.84,
   left: 269.88,
   width: 11.28,
   height: 11.28
}
,
{
   page: 5,
   name: "Other_2", 
   isText: false,
   type: "checkbox",
   top: 540.84,
   left: 321.24,
   width: 11.28,
   height: 11.28
}
,
{
   page: 5,
   name: "undefined_5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 538.8,
   left: 369.72,
   width: 195.00,
   height: 12.84
}
,
{
   page: 5,
   name: "However this amount does not include any compensation to be paid to Buyers brokerage firm", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 580.56,
   left: 96.48,
   width: 75.00,
   height: 12.84
}
,
{
   page: 5,
   name: "per", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 668.16,
   left: 299.04,
   width: 69.96,
   height: 12.84
}
,
{
   page: 5,
   name: "including", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 668.16,
   left: 389.28,
   width: 135.00,
   height: 12.84
}
,
{
   page: 5,
   name: "per annum and also including escrow for the following as", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 682.08,
   left: 253.44,
   width: 50.04,
   height: 12.84
}
,
{
   page: 5,
   name: "principal and interest presently at the rate of", 
   isText: false,
   type: "checkbox",
   top: 712.68,
   left: 175.32,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "indicated", 
   isText: false,
   type: "checkbox",
   top: 712.68,
   left: 75.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Mortgage Insurance Premium", 
   isText: false,
   type: "checkbox",
   top: 712.68,
   left: 325.32,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Real Estate Taxes", 
   isText: false,
   type: "checkbox",
   top: 726.36,
   left: 94.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "undefined_6", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 723.36,
   left: 108.12,
   width: 394.92,
   height: 12.84
}
,
{
   page: 6,
   name: "At the time of assumption the new interest", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 32.64,
   left: 305.4,
   width: 69.96,
   height: 12.84
}
,
{
   page: 6,
   name: "per annum and the new payment will not exceed", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 46.56,
   left: 155.76,
   width: 45.00,
   height: 12.84
}
,
{
   page: 6,
   name: "per_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 46.56,
   left: 417.84,
   width: 70.08,
   height: 12.84
}
,
{
   page: 6,
   name: "principal and interest plus escrow if any If the actual principal balance of the existing", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 60.24,
   left: 74.64,
   width: 140.04,
   height: 12.84
}
,
{
   page: 6,
   name: "Terminate under  241 on or before Closing Date", 
   isText: false,
   type: "checkbox",
   top: 118.44,
   left: 116.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Will_4", 
   isText: false,
   type: "checkbox",
   top: 118.44,
   left: 161.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "or if any other terms or provisions of the loan change Buyer has the Right to", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 87.84,
   left: 177.6,
   width: 70.08,
   height: 12.84
}
,
{
   page: 6,
   name: "Will Not be released from liability on said loan If applicable compliance with the requirements", 
   isText: false,
   type: "checkbox",
   top: 132.36,
   left: 298.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "for release from liability will be evidenced by delivery", 
   isText: false,
   type: "checkbox",
   top: 146.04,
   left: 75.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "in an amount not to exceed", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 156.96,
   left: 74.64,
   width: 174.96,
   height: 12.84
}
,
{
   page: 6,
   name: "undefined_7", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 156.96,
   left: 372.72,
   width: 69.96,
   height: 12.84
}
,
{
   page: 6,
   name: "471", 
   isText: false,
   type: "checkbox",
   top: 293.64,
   left: 89.64,
   width: 10.56,
   height: 10.56
}
,
{
   page: 6,
   name: "Seller Financing If Buyer is to pay all or any portion of the Purchase Price with Seller financing", 
   isText: false,
   type: "checkbox",
   top: 293.64,
   left: 142.2,
   width: 10.56,
   height: 10.56
}
,
{
   page: 6,
   name: "days before Seller or Private Financing Deadline", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 305.28,
   left: 89.04,
   width: 55.08,
   height: 12.84
}
,
{
   page: 7,
   name: "undefined_8", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 563.28,
   left: 472.8,
   width: 90.0,
   height: 10.68
}
,
{
   page: 8,
   name: "Cost of Appraisal Cost of the Appraisal to be obtained after the date of this Contract must be timely paid by", 
   isText: false,
   type: "checkbox",
   top: 87.96,
   left: 125.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "company lenders agent or all three", 
   isText: false,
   type: "checkbox",
   top: 87.96,
   left: 75.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Evidence of Record Title", 
   isText: false,
   type: "checkbox",
   top: 188.04,
   left: 114.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "must furnish to Buyer a current commitment for an owners title insurance policy Title Commitment in an amount", 
   isText: false,
   type: "checkbox",
   top: 227.64,
   left: 308.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "cause the title insurance policy to be issued and delivered to Buyer as soon as practicable at or after Closing", 
   isText: false,
   type: "checkbox",
   top: 258.12,
   left: 114.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "to the Purchase Price If neither box in  811 or  812 is checked  811 applies", 
   isText: false,
   type: "checkbox",
   top: 315,
   left: 374.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Extended Coverage OEC If the Title Commitment is to contain OEC it will commit to delete or insure over the", 
   isText: false,
   type: "checkbox",
   top: 315,
   left: 416.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "time the deed is recorded and 6 unpaid taxes assessments and unredeemed tax sales prior to the year of Closing Any", 
   isText: false,
   type: "checkbox",
   top: 381.24,
   left: 383.88,
   width: 11.28,
   height: 11.28
}
,
{
   page: 9,
   name: "Other_3", 
   isText: false,
   type: "checkbox",
   top: 382.2,
   left: 330.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "additional premium expense to obtain OEC will be paid by", 
   isText: false,
   type: "checkbox",
   top: 395.64,
   left: 89.64,
   width: 11.28,
   height: 11.28
}
,
{
   page: 9,
   name: "Regardless of whether the Contract requires OEC the Title Insurance Commitment may not provide OEC or delete or", 
   isText: false,
   type: "checkbox",
   top: 395.64,
   left: 317.16,
   width: 11.28,
   height: 11.28
}
,
{
   page: 9,
   name: "undefined_9", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 393.12,
   left: 365.52,
   width: 198.00,
   height: 13.44
}
,
{
   page: 10,
   name: "metropolitan districts that affect the Property Tax Certificate must be delivered to Buyer on or before Record Title", 
   isText: false,
   type: "checkbox",
   top: 398.76,
   left: 278.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "Buyer for the Property listing any special taxing or", 
   isText: false,
   type: "checkbox",
   top: 398.76,
   left: 327.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "Text19", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 378.329,
   left: 72.6549,
   width: 481.2011,
   height: 13.491
}
,
{
   page: 11,
   name: "New Improvement Location Certificate New ILC or", 
   isText: false,
   type: "checkbox",
   top: 530.52,
   left: 91.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 11,
   name: "New Survey in the form of", 
   isText: false,
   type: "checkbox",
   top: 543.72,
   left: 91.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 11,
   name: "is", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 541.2,
   left: 222,
   width: 319.92,
   height: 12.36
}
,
{
   page: 11,
   name: "Seller_2", 
   isText: false,
   type: "checkbox",
   top: 574.2,
   left: 283.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 11,
   name: "Buyer will order the New ILC or New Survey The", 
   isText: false,
   type: "checkbox",
   top: 574.2,
   left: 331.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 11,
   name: "Seller_3", 
   isText: false,
   type: "checkbox",
   top: 628.68,
   left: 146.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 11,
   name: "Buyer or", 
   isText: false,
   type: "checkbox",
   top: 628.68,
   left: 194.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 11,
   name: "opinion of title if an Abstract of Title and", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 671.52,
   left: 260.4,
   width: 300.0,
   height: 12.36
}
,
{
   page: 13,
   name: "Text11", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 375.056,
   left: 85.0913,
   width: 481.2017,
   height: 63.237
}
,
{
   page: 14,
   name: "Does", 
   isText: false,
   type: "checkbox",
   top: 35.88,
   left: 470.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Does Not", 
   isText: false,
   type: "checkbox",
   top: 35.88,
   left: 515.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "There is No Well Buyer", 
   isText: false,
   type: "checkbox",
   top: 62.04,
   left: 205.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Does_2", 
   isText: false,
   type: "checkbox",
   top: 62.04,
   left: 330.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Does Not acknowledge receipt of a copy of", 
   isText: false,
   type: "checkbox",
   top: 62.04,
   left: 375,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "If English is not the Sellers principal", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 113.52,
   left: 207.84,
   width: 185.04,
   height: 11.88
}
,
{
   page: 15,
   name: "Are", 
   isText: false,
   type: "checkbox",
   top: 348.12,
   left: 473.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Are Not", 
   isText: false,
   type: "checkbox",
   top: 348.12,
   left: 513.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "undefined_10", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 414.48,
   left: 74.64,
   width: 290.04,
   height: 11.88
}
,
{
   page: 15,
   name: "special warranty deed", 
   isText: false,
   type: "checkbox",
   top: 534.84,
   left: 68.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "general warranty deed", 
   isText: false,
   type: "checkbox",
   top: 534.84,
   left: 230.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "bargain and sale deed", 
   isText: false,
   type: "checkbox",
   top: 534.84,
   left: 342.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "quit claim deed", 
   isText: false,
   type: "checkbox",
   top: 534.84,
   left: 453.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "personal representatives deed", 
   isText: false,
   type: "checkbox",
   top: 548.76,
   left: 68.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "deed", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 546.72,
   left: 245.88,
   width: 200.04,
   height: 11.88
}
,
{
   page: 15,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 548.76,
   left: 230.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Buyer_2", 
   isText: false,
   type: "checkbox",
   top: 73.32,
   left: 158.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Seller_4", 
   isText: false,
   type: "checkbox",
   top: 73.32,
   left: 208.2,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "OneHalf by Buyer and OneHalf by Seller", 
   isText: false,
   type: "checkbox",
   top: 73.32,
   left: 256.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Other_4", 
   isText: false,
   type: "checkbox",
   top: 88.2,
   left: 194.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "undefined_11", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 86.16,
   left: 237.96,
   width: 294.96,
   height: 11.88
}
,
{
   page: 16,
   name: "Buyer_3", 
   isText: false,
   type: "checkbox",
   top: 187.32,
   left: 137.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Seller_5", 
   isText: false,
   type: "checkbox",
   top: 187.32,
   left: 187.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "OneHalf by Buyer and OneHalf by Seller_2", 
   isText: false,
   type: "checkbox",
   top: 187.32,
   left: 236.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "NA", 
   isText: false,
   type: "checkbox",
   top: 187.32,
   left: 442.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Buyer_4", 
   isText: false,
   type: "checkbox",
   top: 233.4,
   left: 137.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Seller_6", 
   isText: false,
   type: "checkbox",
   top: 233.4,
   left: 187.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "OneHalf by Buyer and OneHalf by Seller_3", 
   isText: false,
   type: "checkbox",
   top: 233.4,
   left: 236.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "NA_2", 
   isText: false,
   type: "checkbox",
   top: 233.4,
   left: 442.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Buyer_5", 
   isText: false,
   type: "checkbox",
   top: 265.56,
   left: 137.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Seller_7", 
   isText: false,
   type: "checkbox",
   top: 265.56,
   left: 187.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "OneHalf by Buyer and OneHalf by Seller_4", 
   isText: false,
   type: "checkbox",
   top: 265.56,
   left: 236.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "NA_3", 
   isText: false,
   type: "checkbox",
   top: 265.56,
   left: 442.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Buyer_6", 
   isText: false,
   type: "checkbox",
   top: 303.72,
   left: 137.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Seller_8", 
   isText: false,
   type: "checkbox",
   top: 303.72,
   left: 187.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "OneHalf by Buyer and OneHalf by Seller_5", 
   isText: false,
   type: "checkbox",
   top: 303.72,
   left: 236.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "NA_4", 
   isText: false,
   type: "checkbox",
   top: 303.72,
   left: 442.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Buyer_7", 
   isText: false,
   type: "checkbox",
   top: 337.8,
   left: 137.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Seller_9", 
   isText: false,
   type: "checkbox",
   top: 337.8,
   left: 187.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "OneHalf by Buyer and OneHalf by Seller_6", 
   isText: false,
   type: "checkbox",
   top: 337.8,
   left: 236.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "NA_5", 
   isText: false,
   type: "checkbox",
   top: 337.8,
   left: 442.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Buyer_8", 
   isText: false,
   type: "checkbox",
   top: 384.84,
   left: 137.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Seller_10", 
   isText: false,
   type: "checkbox",
   top: 384.84,
   left: 187.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "OneHalf by Buyer and OneHalf by Seller_7", 
   isText: false,
   type: "checkbox",
   top: 384.84,
   left: 236.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "NA_6", 
   isText: false,
   type: "checkbox",
   top: 384.84,
   left: 442.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "undefined_12", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 413.52,
   left: 82.08,
   width: 95.04,
   height: 11.88
}
,
{
   page: 16,
   name: "Water DistrictMunicipality", 
   isText: false,
   type: "checkbox",
   top: 432.12,
   left: 99.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Augmentation Membership", 
   isText: false,
   type: "checkbox",
   top: 448.92,
   left: 99.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Water Stock", 
   isText: false,
   type: "checkbox",
   top: 432.12,
   left: 248.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "undefined_13", 
   isText: false,
   type: "checkbox",
   top: 448.92,
   left: 248.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "undefined_14", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 446.88,
   left: 423.48,
   width: 144.96,
   height: 11.88
}
,
{
   page: 16,
   name: "Buyer_9", 
   isText: false,
   type: "checkbox",
   top: 479.16,
   left: 198.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Seller_11", 
   isText: false,
   type: "checkbox",
   top: 479.16,
   left: 248.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "OneHalf by Buyer and OneHalf by Seller_8", 
   isText: false,
   type: "checkbox",
   top: 479.16,
   left: 297,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "NA_7", 
   isText: false,
   type: "checkbox",
   top: 479.16,
   left: 503.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Buyer_10", 
   isText: false,
   type: "checkbox",
   top: 524.52,
   left: 112.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Seller_12", 
   isText: false,
   type: "checkbox",
   top: 524.52,
   left: 163.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "OneHalf by Buyer and OneHalf by Seller_9", 
   isText: false,
   type: "checkbox",
   top: 524.52,
   left: 211.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "NA_8", 
   isText: false,
   type: "checkbox",
   top: 524.52,
   left: 418.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "IS a foreign person for purposes of US income taxation If the box in this Section is not", 
   isText: false,
   type: "checkbox",
   top: 593.64,
   left: 163.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Check Box2", 
   isText: false,
   type: "checkbox",
   top: 448.92,
   left: 409.82,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Taxes for the Calendar Year Immediately Preceding Closing", 
   isText: false,
   type: "checkbox",
   top: 101.16,
   left: 89.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Most Recent Mill Levy and Most Recent Assessed Valuation adjusted by any applicable qualifying seniors", 
   isText: false,
   type: "checkbox",
   top: 114.36,
   left: 89.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Other_5", 
   isText: false,
   type: "checkbox",
   top: 140.76,
   left: 89.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "undefined_15", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 139.92,
   left: 133.56,
   width: 415.08,
   height: 10.68
}
,
{
   page: 17,
   name: "Rents Actually Received", 
   isText: false,
   type: "checkbox",
   top: 158.04,
   left: 212.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Accrued At Closing Seller will transfer or credit to", 
   isText: false,
   type: "checkbox",
   top: 158.04,
   left: 340.2,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "undefined_16", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 207.12,
   left: 89.04,
   width: 440.04,
   height: 10.68
}
,
{
   page: 17,
   name: "Buyer_11", 
   isText: false,
   type: "checkbox",
   top: 305.16,
   left: 228.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller Except however any special assessment by the Association", 
   isText: false,
   type: "checkbox",
   top: 305.16,
   left: 278.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "per day or", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 414.12,
   left: 444.48,
   width: 75.24,
   height: 10.68
}
,
{
   page: 18,
   name: "by Buyer will be paid to Seller and retained by Seller It is agreed that the Earnest Money is not a penalty and the", 
   isText: false,
   type: "checkbox",
   top: 404.76,
   left: 114.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "undefined_17", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 560.64,
   left: 74.64,
   width: 320.04,
   height: 12.36
}
,
{
   page: 20,
   name: "of the Purchase Price or", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 87.12,
   left: 101.76,
   width: 35.04,
   height: 9.96
}
,
{
   page: 20,
   name: "by Seller Buyers brokerage firm is an intended thirdparty", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 87.12,
   left: 255.6,
   width: 75.0,
   height: 9.96
}
,
{
   page: 20,
   name: "beneficiary under this provision only The amount paid by Seller under this provision is in addition to any other amounts", 
   isText: false,
   type: "checkbox",
   top: 87.24,
   left: 83.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 20,
   name: "of the Purchase Price or_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 129.36,
   left: 101.76,
   width: 35.04,
   height: 9.96
}
,
{
   page: 20,
   name: "by Buyer pursuant to a separate agreement between Buyer", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 129.36,
   left: 255.6,
   width: 75.0,
   height: 9.96
}
,
{
   page: 20,
   name: "and Buyers brokerage firm This amount may be modified between Buyer and Buyers brokerage firm outside of this", 
   isText: false,
   type: "checkbox",
   top: 129.48,
   left: 83.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 20,
   name: "of the Purchase Price or_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 171.36,
   left: 101.76,
   width: 35.04,
   height: 9.72
}
,
{
   page: 20,
   name: "by a separate agreement between Buyers brokerage firm", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 171.12,
   left: 255.6,
   width: 75.0,
   height: 9.96
}
,
{
   page: 20,
   name: "and Sellers brokerage firm", 
   isText: false,
   type: "checkbox",
   top: 171.24,
   left: 83.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 20,
   name: "Text12", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 267.71,
   left: 45.8184,
   width: 539.4566,
   height: 488.6933
}
,
{
   page: 21,
   name: "Homeowner Warning Notice  Right to Cancel in Sellers principal language of", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 251.76,
   left: 433.2,
   width: 130.08,
   height: 12.12
}
,
{
   page: 21,
   name: "BUYERS NAME OR", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 349.44,
   left: 71.88,
   width: 354.00,
   height: 12.12
}
,
{
   page: 21,
   name: "ANYONE WORKING FOR", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 365.28,
   left: 220.32,
   width: 342.00,
   height: 12.12
}
,
{
   page: 21,
   name: "Date cancelContract", 
   fontSize: 9,
   type: "date",
   top: 489.36,
   left: 56.64,
   width: 156.00,
   height: 12.12
}
,
{
   page: 21,
   name: "Buyers Name", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 606,
   left: 115.2,
   width: 179.88,
   height: 12.12
}
,
{
   page: 21,
   name: "Buyers Name 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 606,
   left: 382.8,
   width: 179.88,
   height: 12.12
}
,
{
   page: 21,
   name: "1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 664.2,
   left: 121.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 21,
   name: "2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 676.92,
   left: 121.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 21,
   name: "Address 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 664.2,
   left: 391.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 21,
   name: "Address 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 676.92,
   left: 391.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 21,
   name: "Phone No", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 689.64,
   left: 391.44,
   width: 171.00,
   height: 11.88
}
,
{
   page: 21,
   name: "Phone No 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 689.64,
   left: 121.44,
   width: 171.00,
   height: 11.88
}
,
{
   page: 21,
   name: "Phone No 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 702.12,
   left: 121.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 21,
   name: "Fax No", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 702.12,
   left: 391.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 21,
   name: "Email Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 714.84,
   left: 121.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 21,
   name: "Email Address_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 714.84,
   left: 391.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 21,
   name: "Text13", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 56.946,
   left: 58.9093,
   width: 526.3657,
   height: 72.400
}
,
{
   page: 21,
   name: "Text14", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 137.455,
   left: 58.9093,
   width: 526.3657,
   height: 70.437
}
,
{
   page: 22,
   name: "Buyers Name 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 31.92,
   left: 122.16,
   width: 171.00,
   height: 12.48
}
,
{
   page: 22,
   name: "Buyers Name 4 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 31.92,
   left: 392.16,
   width: 166.56,
   height: 12.48
}
,
{
   page: 22,
   name: "Sellers Name", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 122.04,
   left: 114.12,
   width: 180.12,
   height: 12.48
}
,
{
   page: 22,
   name: "Sellers Name 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 122.04,
   left: 384.72,
   width: 177.00,
   height: 12.48
}
,
{
   page: 22,
   name: "1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 180.48,
   left: 121.44,
   width: 171.00,
   height: 12.48
}
,
{
   page: 22,
   name: "2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 193.56,
   left: 121.44,
   width: 171.00,
   height: 11.88
}
,
{
   page: 22,
   name: "Address 1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 180.48,
   left: 391.44,
   width: 171.00,
   height: 12.48
}
,
{
   page: 22,
   name: "Address 2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 193.56,
   left: 391.44,
   width: 171.00,
   height: 11.88
}
,
{
   page: 22,
   name: "Phone No_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 206.04,
   left: 391.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 22,
   name: "Phone No 1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 206.04,
   left: 121.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 22,
   name: "Phone No 2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 218.76,
   left: 121.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 22,
   name: "Fax No_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 218.76,
   left: 391.44,
   width: 171.00,
   height: 12.12
}
,
{
   page: 22,
   name: "Email Address_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 231.48,
   left: 121.44,
   width: 171.00,
   height: 11.88
}
,
{
   page: 22,
   name: "Email Address_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 231.48,
   left: 391.44,
   width: 171.00,
   height: 11.88
}
,
{
   page: 22,
   name: "Sellers Name 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 255.6,
   left: 123.48,
   width: 166.44,
   height: 12.48
}
,
{
   page: 22,
   name: "Sellers Name 4 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 255.6,
   left: 391.2,
   width: 166.44,
   height: 12.48
}
,
{
   page: 22,
   name: "Does_3", 
   isText: false,
   type: "checkbox",
   top: 439.32,
   left: 86.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the", 
   isText: false,
   type: "checkbox",
   top: 439.32,
   left: 130.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "Buyers Agent", 
   isText: false,
   type: "checkbox",
   top: 501.24,
   left: 195.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "TransactionBroker in this transaction", 
   isText: false,
   type: "checkbox",
   top: 501.24,
   left: 280.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
   isText: false,
   type: "checkbox",
   top: 517.32,
   left: 50.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "Brokerage Firms Name 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 590.76,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 22,
   name: "Brokerage Firms Name 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 617.16,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 22,
   name: "Brokerage Firms License 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 603.96,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 22,
   name: "Brokerage Firms License 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 630.36,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 22,
   name: "Date_10", 
   fontSize: 9,
   type: "date",
   top: 656.76,
   left: 189.48,
   width: 105.00,
   height: 12.48
}
,
{
   page: 22,
   name: "Date_11", 
   fontSize: 9,
   type: "date",
   top: 656.76,
   left: 454.44,
   width: 95.04,
   height: 12.48
}
,
{
   page: 22,
   name: "1_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 692.52,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 22,
   name: "2_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 705.72,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 22,
   name: "3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 718.92,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 22,
   name: "4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 732.12,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 22,
   name: "signature buyerBrokerSign", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 656.003,
   left: 48.4366,
   width: 136.0004,
   height: 13.000
}
,
{
   page: 22,
   name: "signature buyerCoBrokerSign", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 656.003,
   left: 310.91,
   width: 136.00,
   height: 13.000
}
,
{
   page: 23,
   name: "Does_4", 
   isText: false,
   type: "checkbox",
   top: 65.16,
   left: 85.32,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the_2", 
   isText: false,
   type: "checkbox",
   top: 65.16,
   left: 129.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "Sellers Agent", 
   isText: false,
   type: "checkbox",
   top: 127.08,
   left: 194.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "TransactionBroker in this transaction_2", 
   isText: false,
   type: "checkbox",
   top: 127.08,
   left: 277.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "Customer Broker has no brokerage relationship with Seller See  A for Brokers brokerage relationship with Buyer", 
   isText: false,
   type: "checkbox",
   top: 143.16,
   left: 49.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "Seller_13", 
   isText: false,
   type: "checkbox",
   top: 182.28,
   left: 122.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "Buyer_12", 
   isText: false,
   type: "checkbox",
   top: 182.28,
   left: 170.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "Other_6", 
   isText: false,
   type: "checkbox",
   top: 182.28,
   left: 220.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "otherSellerBroker", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 179.64,
   left: 267.84,
   width: 285.00,
   height: 12.48
}
,
{
   page: 23,
   name: "Brokerage Firms Name", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 250.92,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 23,
   name: "Brokerage Firms License 1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 264.12,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 23,
   name: "Brokerage Firms License 2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 277.32,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 23,
   name: "Brokerage Firms License 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 290.52,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 23,
   name: "Date_12", 
   fontSize: 9,
   type: "date",
   top: 316.92,
   left: 189.48,
   width: 105.00,
   height: 12.48
}
,
{
   page: 23,
   name: "Date_13", 
   fontSize: 9,
   type: "date",
   top: 316.92,
   left: 454.44,
   width: 95.04,
   height: 12.48
}
,
{
   page: 23,
   name: "1_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 352.68,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 23,
   name: "2_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 365.88,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 23,
   name: "3_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 379.08,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 23,
   name: "4_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 392.28,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 23,
   name: "5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 405.48,
   left: 184.44,
   width: 375.00,
   height: 12.48
}
,
{
   page: 23,
   name: "signature SellerBrokerSign", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 317.292,
   left: 49.7457,
   width: 132.0003,
   height: 12.000
}
,
{
   page: 23,
   name: "signature SellerCoBrokerSign", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 317.292,
   left: 314.838,
   width: 132.000,
   height: 12.000
}
] }
