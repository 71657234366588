export function releaseofContractandDisbursementofEarnestMoney() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 120.68,
   left: 204.48,
   width: 100.52,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 120.68,
   left: 205.48,
   width: 100.52,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 146.835,
   left: 30.75,
   width: 552.00,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 239.76,
   left: 192.24,
   width: 390.51,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 259.92,
   left: 345.5,
   width: 98.46,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 259.92,
   left: 457.92,
   width: 124.83,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 280.08,
   left: 30.75,
   width: 552.00,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 300.24,
   left: 30.75,
   width: 450.75,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 357.4,
   left: 163.0,
   width: 120.5,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 357.4,
   left: 424.08,
   width: 156.96,
   height: 13.5
}

// ,
// {
//    page: 0,
//    name: "Text Field 12", 
//    type: "text",
//    fontName: "Helvetica",
//    fontSize: 10,
//    top: 516.24,
//    left: 30.75,
//    width: 252.75,
//    height: 13.50
// }
// ,
// {
//    page: 0,
//    name: "Text Field 13", 
//    type: "text",
//    fontName: "Helvetica",
//    fontSize: 10,
//    top: 516.24,
//    left: 294.48,
//    width: 288.27,
//    height: 13.50
// }
,
{
   page: 0,
   name: "Text Field 14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 559.88,
   left: 52.5833,
   width: 230.9167,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 559.88,
   left: 314.458,
   width: 268.292,
   height: 13.50
}
] }
