import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, Image } from "semantic-ui-react";
import { format } from "date-fns";
import { convertAddressFull } from "../../../app/common/util/util";

export default function ManagerAnalyticsListItem({ transaction }) {
  const navigate = useNavigate();

  function handleRowClick() {
    navigate(`/transactions/${transaction.id}/overview`);
  }

  return (
    <Table.Row
      key={transaction.id}
      onClick={() => handleRowClick()}
      style={{ cursor: "pointer" }}
    >
      <Table.Cell style={{ padding: "0px" }}>
        {transaction.pic ? (
          <Image style={{ width: "90px" }} src={transaction.pic} />
        ) : (
          <Image
            src="/assets/placeholder-house.png"
            style={{ width: "90px" }}
            rounded
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {transaction.closingDateTime &&
          format(transaction.closingDateTime, "MM/dd/yyyy")}
      </Table.Cell>
      <Table.Cell>
        {transaction.agentProfile?.firstName}{" "}
        {transaction.agentProfile?.lastName}
      </Table.Cell>
      <Table.Cell>
        {transaction.client?.firstName} {transaction.client?.lastName}
      </Table.Cell>
      <Table.Cell>
        {transaction.clientSecondary?.firstName}{" "}
        {transaction.clientSecondary?.lastName}
      </Table.Cell>
      <Table.Cell>{transaction.agentRepresents}</Table.Cell>
      <Table.Cell>{convertAddressFull(transaction.address)}</Table.Cell>
    </Table.Row>
  );
}
