export function contracttoBuyandSellForeclosure2023() {
    return [   //2023
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 185.52,
       left: 456.24,
       width: 105.12,
       height: 12.72
    }
    ,
    {
       page: 0,
       name: "Buyer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 263.28,
       left: 121.68,
       width: 365.16,
       height: 12.72
    }
    ,
    {
       page: 0,
       name: "Joint Tenants", 
       isText: false,
       type: "checkbox",
       top: 279.24,
       left: 219.48,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Tenants In Common", 
       isText: false,
       type: "checkbox",
       top: 279.24,
       left: 301.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Other", 
       isText: false,
       type: "checkbox",
       top: 292.68,
       left: 77.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "undefined", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 289.92,
       left: 124.32,
       width: 240.12,
       height: 12.72
    }
    ,
    {
       page: 0,
       name: "Seller", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 324,
       left: 121.2,
       width: 417.72,
       height: 12.72
    }
    ,
    {
       page: 0,
       name: "tf_county", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 351.12,
       left: 436.32,
       width: 137.64,
       height: 12.72
    }
    ,
    {
       page: 0,
       name: "tf_knownAsAddress", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 424.8,
       left: 118.32,
       width: 452.52,
       height: 12.72
    }
    ,
    {
       page: 0,
       name: "and garage door openers including", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 515.28,
       left: 234.96,
       width: 30.72,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Solar Panels", 
       isText: false,
       type: "checkbox",
       top: 527.4,
       left: 140.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Water Softeners", 
       isText: false,
       type: "checkbox",
       top: 527.4,
       left: 217.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Security Systems", 
       isText: false,
       type: "checkbox",
       top: 527.4,
       left: 309.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Satellite Systems including satellite", 
       isText: false,
       type: "checkbox",
       top: 527.4,
       left: 405.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "If the box is checked Buyer and Seller have concurrently entered into a separate agreement for additional personal property", 
       isText: false,
       type: "checkbox",
       top: 732.12,
       left: 77.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "ta_propertyLegalDesc", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 375.748,
       left: 71.92,
       width: 503.085,
       height: 47.126
    }
    ,
    {
       page: 0,
       name: "ta_otherInclusions", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 637.438,
       left: 73.2232,
       width: 505.6918,
       height: 92.4776
    }
    ,
    {
       page: 1,
       name: "and the use or ownership of the following storage facilities", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 159.6,
       left: 89.28,
       width: 241.20,
       height: 10.8
    }
    ,
    {
       page: 1,
       name: "Note to Buyer If exact rights to the parking and storage facilities", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 173.04,
       left: 89.28,
       width: 220.20,
       height: 10.80
    }
    ,
    {
       page: 1,
       name: "Deeded Water Rights The following legally described water rights", 
       isText: false,
       type: "checkbox",
       top: 404.28,
       left: 115.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "deed at Closing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 461.04,
       left: 357.84,
       width: 151.08,
       height: 10.80
    }
    ,
    {
       page: 1,
       name: "Other Rights Relating to Water The following rights relating to water not included in  271 273 and", 
       isText: false,
       type: "checkbox",
       top: 477.24,
       left: 115.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Well Rights Seller agrees to supply required information to Buyer about the well Buyer understands that if the", 
       isText: false,
       type: "checkbox",
       top: 553.56,
       left: 115.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "undefined_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 621.84,
       left: 304.8,
       width: 222.84,
       height: 10.80
    }
    ,
    {
       page: 1,
       name: "Water Stock Certificates The water stock certificates to be transferred at Closing are as follows", 
       isText: false,
       type: "checkbox",
       top: 638.28,
       left: 115.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Does", 
       isText: false,
       type: "checkbox",
       top: 726.6,
       left: 243,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Does Not have a Right to Terminate if examination of the Water", 
       isText: false,
       type: "checkbox",
       top: 726.6,
       left: 286.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "ta_encumberedInclusions", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 71.461,
       left: 82.4464,
       width: 503.0856,
       height: 48.689
    }
    ,
    {
       page: 1,
       name: "ta_leasedItems", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 227.381,
       left: 81.6798,
       width: 503.0852,
       height: 60.157
    }
    ,
    {
       page: 1,
       name: "ta_exclusions", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 300.202,
       left: 81.6049,
       width: 503.0851,
       height: 86.222
    }
    ,
    {
       page: 1,
       name: "ta_deededWaterRights", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 415.845,
       left: 82.2556,
       width: 503.0854,
       height: 43.477
    }
    ,
    {
       page: 1,
       name: "ta_otherRightsWater", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 498.774,
       left: 84.1704,
       width: 503.0856,
       height: 52.338
    }
    ,
    {
       page: 1,
       name: "ta_waterStockCerts", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 650.353,
       left: 85.3613,
       width: 503.0857,
       height: 38.263
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsTime of Day Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 84.96,
       left: 392.52,
       width: 192.60,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "DateAlternative Earnest Money Deadline", 
       fontSize: 9,
       type: "date",
       top: 101.52,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsAlternative Earnest Money Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 101.52,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateRecord Title Deadline and Tax Certificate", 
       fontSize: 9,
       type: "date",
       top: 134.16,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsRecord Title Deadline and Tax Certificate", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 134.16,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateRecord Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 150.48,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsRecord Title Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 150.48,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateOffRecord Title Deadline", 
       fontSize: 9,
       type: "date",
       top: 166.8,
       left: 307.08,
       width: 83.88,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsOffRecord Title Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 166.8,
       left: 392.52,
       width: 192.60,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "DateOffRecord Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 183.36,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsOffRecord Title Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 183.36,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateTitle Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 199.68,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsTitle Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 199.68,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateThird Party Right to PurchaseApprove Deadline", 
       fontSize: 9,
       type: "date",
       top: 216,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsThird Party Right to PurchaseApprove Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 216,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateAssociation Documents Deadline", 
       fontSize: 9,
       type: "date",
       top: 248.64,
       left: 307.08,
       width: 83.88,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsAssociation Documents Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 248.64,
       left: 392.52,
       width: 192.60,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "DateAssociation Documents Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 265.2,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsAssociation Documents Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 265.2,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateSellers Property Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 297.84,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsSellers Property Disclosure Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 297.84,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateLeadBased Paint Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 314.16,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsLeadBased Paint Disclosure Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 314.16,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateNew Loan Application Deadline", 
       fontSize: 9,
       type: "date",
       top: 347.04,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsNew Loan Application Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 347.04,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateNew Loan Terms Deadline", 
       fontSize: 9,
       type: "date",
       top: 363.36,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsNew Loan Terms Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 363.36,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateNew Loan Availability Deadline", 
       fontSize: 9,
       type: "date",
       top: 379.68,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsNew Loan Availability Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 379.68,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateBuyers Credit Information Deadline", 
       fontSize: 9,
       type: "date",
       top: 396,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsBuyers Credit Information Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 396,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateDisapproval of Buyers Credit Information Deadline", 
       fontSize: 9,
       type: "date",
       top: 412.56,
       left: 307.32,
       width: 83.40,
       height: 25.68
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsDisapproval of Buyers Credit Information Deadline", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 412.56,
       left: 392.76,
       width: 192.12,
       height: 25.68
    }
    ,
    {
       page: 2,
       name: "DateExisting Loan Deadline", 
       fontSize: 9,
       type: "date",
       top: 439.44,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsExisting Loan Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 439.44,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateExisting Loan Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 455.76,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsExisting Loan Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 455.76,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateLoan Transfer Approval Deadline", 
       fontSize: 9,
       type: "date",
       top: 472.08,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsLoan Transfer Approval Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 472.08,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateSeller or Private Financing Deadline", 
       fontSize: 9,
       type: "date",
       top: 488.4,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsSeller or Private Financing Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 488.4,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateAppraisal Deadline", 
       fontSize: 9,
       type: "date",
       top: 521.28,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsAppraisal Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 521.28,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateAppraisal Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 537.6,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsAppraisal Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 537.6,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateAppraisal Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 553.92,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsAppraisal Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 553.92,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateNew ILC or New Survey Deadline", 
       fontSize: 9,
       type: "date",
       top: 586.56,
       left: 307.08,
       width: 83.88,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsNew ILC or New Survey Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 586.56,
       left: 392.52,
       width: 192.60,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "DateNew ILC or New Survey Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 603.12,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsNew ILC or New Survey Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 603.12,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateNew ILC or New Survey Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 619.44,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsNew ILC or New Survey Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 619.44,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateWater Rights Examination Deadline", 
       fontSize: 9,
       type: "date",
       top: 652.08,
       left: 307.08,
       width: 83.88,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsWater Rights Examination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 652.08,
       left: 392.52,
       width: 192.60,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "DateMineral Rights Examination Deadline", 
       fontSize: 9,
       type: "date",
       top: 668.64,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsMineral Rights Examination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 668.64,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateInspection Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 684.96,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsInspection Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 684.96,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateInspection Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 701.28,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsInspection Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 701.28,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateInspection Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 717.6,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsInspection Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 717.6,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "DateProperty Insurance Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 733.92,
       left: 307.08,
       width: 83.88,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "Deadline DetailsProperty Insurance Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 733.92,
       left: 392.52,
       width: 192.60,
       height: 15.36
    }
    ,
    {
       page: 3,
       name: "DateDue Diligence Documents Delivery Deadline", 
       fontSize: 9,
       type: "date",
       top: 50.16,
       left: 307.08,
       width: 83.88,
       height: 15.36
    }
    ,
    {
       page: 3,
       name: "Deadline DetailsDue Diligence Documents Delivery Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 50.16,
       left: 392.52,
       width: 192.60,
       height: 15.36
    }
    ,
    {
       page: 3,
       name: "DateDue Diligence Documents Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 66.72,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Deadline DetailsDue Diligence Documents Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 66.72,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "DateDue Diligence Documents Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 83.04,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Deadline DetailsDue Diligence Documents Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 83.04,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "DateConditional Sale Deadline", 
       fontSize: 9,
       type: "date",
       top: 99.36,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Deadline DetailsConditional Sale Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 99.36,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "DateLeadBased Paint Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 115.68,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Deadline DetailsLeadBased Paint Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 115.68,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "DateClosing Date", 
       fontSize: 9,
       type: "date",
       top: 148.56,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Deadline DetailsClosing Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 148.56,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "DatePossession Date", 
       fontSize: 9,
       type: "date",
       top: 164.88,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Deadline DetailsPossession Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 164.88,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Deadline DetailsPossession Time", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 181.2,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "DateAcceptance Deadline Date", 
       fontSize: 9,
       type: "date",
       top: 197.52,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Deadline DetailsAcceptance Deadline Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 197.52,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Deadline DetailsAcceptance Deadline Time", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 213.84,
       left: 392.52,
       width: 192.60,
       height: 15.36
    }
    ,
    {
       page: 3,
       name: " 2744", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 230.4,
       left: 63.96,
       width: 39.00,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Acceptance Deadline Time44", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 230.4,
       left: 104.52,
       width: 201.00,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Date44", 
       fontSize: 9,
       type: "date",
       top: 230.4,
       left: 307.08,
       width: 83.88,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Deadline Details44", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 230.4,
       left: 392.52,
       width: 192.60,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: " 2745", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 246.6,
       left: 63.84,
       width: 39.24,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Acceptance Deadline Time45", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 246.6,
       left: 104.4,
       width: 201.24,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Date45", 
       fontSize: 9,
       type: "date",
       top: 246.6,
       left: 306.96,
       width: 84.12,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Deadline Details45", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 246.6,
       left: 392.4,
       width: 192.84,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Deadlines If any deadline falls on a Saturday Sunday or federal or Colorado state holiday Holiday such deadline", 
       isText: false,
       type: "checkbox",
       top: 498.36,
       left: 133.56,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 3,
       name: "checked the deadline will not be extended", 
       isText: false,
       type: "checkbox",
       top: 498.36,
       left: 89.88,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 3,
       name: "139", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 572.64,
       left: 302.313,
       width: 97.320,
       height: 14.88
    }
    ,
    {
       page: 3,
       name: "Earnest Money", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 588.72,
       left: 412.299,
       width: 87.021,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "New Loan", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 605.04,
       left: 412.299,
       width: 87.021,
       height: 15.36
    }
    ,
    {
       page: 3,
       name: "Assumption Balance", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 621.6,
       left: 412.299,
       width: 87.021,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Private Financing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 637.92,
       left: 412.299,
       width: 87.021,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Seller Financing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 654.24,
       left: 412.299,
       width: 87.021,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: " 477", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 670.44,
       left: 100.08,
       width: 52.68,
       height: 14.76
    }
    ,
    {
       page: 3,
       name: "Seller Financing7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 670.44,
       left: 154.08,
       width: 133.80,
       height: 14.76
    }
    ,
    {
       page: 3,
       name: "tf_extra1_amount1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 670.44,
       left: 296.88,
       width: 103.56,
       height: 14.76
    }
    ,
    {
       page: 3,
       name: " 478", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 686.28,
       left: 100.08,
       width: 52.68,
       height: 14.76
    }
    ,
    {
       page: 3,
       name: "Seller Financing8", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 686.28,
       left: 154.08,
       width: 133.80,
       height: 14.76
    }
    ,
    {
       page: 3,
       name: "tf_totalAmountRightSide", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 718.3974,
       left: 411.974,
       width: 87.551,
       height: 14.7600
    }
    ,
    {
       page: 3,
       name: "tf_cashClosingAmount", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 702.24,
       left: 412.3,
       width: 87.02,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "tf_extra2amountright", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 686.077,
       left: 412.856,
       width: 87.086,
       height: 14.7602
    }
    ,
    {
       page: 3,
       name: "fill_72", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 718.44,
       left: 299.28,
       width: 101.16,
       height: 14.76
    }
    ,
    {
       page: 3,
       name: "tf_extraAmount", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 670.52,
       left: 412.589,
       width: 87.086,
       height: 14.76
    }
    ,
    {
       page: 3,
       name: "tf_extra2_amount1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 685.723,
       left: 296.965,
       width: 103.560,
       height: 14.7596
    }
    ,
    {
       page: 4,
       name: "Seller Concession At Closing Seller will credit to Buyer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 33.36,
       left: 350.76,
       width: 85.44,
       height: 12.48
    }
    ,
    {
       page: 4,
       name: "Earnest Money The Earnest Money set forth in this Section in the form of a", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 106.56,
       left: 415.2,
       width: 162.36,
       height: 12.48
    }
    ,
    {
       page: 4,
       name: "will be payable to and held by", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 119.76,
       left: 200.4,
       width: 145.08,
       height: 12.24
    }
    ,
    {
       page: 4,
       name: "funds that are immediately verifiable and available in an amount not less than the amount stated as Cash at Closing in  41", 
       isText: false,
       type: "checkbox",
       top: 511.08,
       left: 434.52,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "Does Not have", 
       isText: false,
       type: "checkbox",
       top: 511.08,
       left: 482.76,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "453", 
       isText: false,
       type: "checkbox",
       top: 632.28,
       left: 89.88,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 4,
       name: "Loan Limitations Buyer may purchase the Property using any of the following types of loans", 
       isText: false,
       type: "checkbox",
       top: 631.56,
       left: 225.72,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "If either or both of the FHA or VA boxes are checked and Buyer closes the transaction using one of those loan types", 
       isText: false,
       type: "checkbox",
       top: 631.56,
       left: 176.76,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "Bond", 
       isText: false,
       type: "checkbox",
       top: 631.56,
       left: 267.48,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "undefined_3", 
       isText: false,
       type: "checkbox",
       top: 631.56,
       left: 318.12,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "Other_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 630,
       left: 366.48,
       width: 210.60,
       height: 12.48
    }
    ,
    {
       page: 4,
       name: "Seller agrees to pay those closing costs and fees that Buyer is not allowed by law to pay not to exceed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 656.88,
       left: 499.92,
       width: 75.24,
       height: 12.48
    }
    ,
    {
       page: 4,
       name: "forth in  41 Price and Terms presently payable at", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 733.2,
       left: 292.32,
       width: 72.12,
       height: 12.48
    }
    ,
    {
       page: 4,
       name: "per", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 733.2,
       left: 382.08,
       width: 105.00,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "Property Insurance Premium", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 33.36,
       left: 198.24,
       width: 61.44,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "cb_assumptionRealEstateTaxes", 
       isText: false,
       type: "checkbox",
       top: 49.08,
       left: 77.88,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 5,
       name: "Real Estate Taxes", 
       isText: false,
       type: "checkbox",
       top: 49.08,
       left: 186.6,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 5,
       name: "per annum and also including escrow for the following as indicated", 
       isText: false,
       type: "checkbox",
       top: 49.08,
       left: 349.8,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 5,
       name: "undefined_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 62.16,
       left: 98.64,
       width: 228.00,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "At the time of assumption the new interest rate", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 75.84,
       left: 309.12,
       width: 69.48,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "per annum and the new payment will not exceed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 89.04,
       left: 139.2,
       width: 38.52,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "per_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 89.04,
       left: 394.44,
       width: 62.16,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "principal and interest plus escrow if any If the actual principal balance of the existing loan at Closing is less than the", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 89.04,
       left: 474.24,
       width: 99.96,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "undefined_6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 128.64,
       left: 79.92,
       width: 57.00,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "before Closing Date", 
       isText: false,
       type: "checkbox",
       top: 157.8,
       left: 118.44,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 5,
       name: "or if any other terms or provisions of the loan change Buyer has the Right to Terminate under  241 on or", 
       isText: false,
       type: "checkbox",
       top: 157.8,
       left: 160.68,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 5,
       name: "Will Not be released from liability on said loan If applicable compliance with the requirements for", 
       isText: false,
       type: "checkbox",
       top: 171.48,
       left: 273.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "at Closing of an", 
       isText: false,
       type: "checkbox",
       top: 171.48,
       left: 482.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "in", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 182.16,
       left: 461.04,
       width: 108.12,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "undefined_7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 195.36,
       left: 178.56,
       width: 85.56,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "471", 
       isText: false,
       type: "checkbox",
       top: 290.04,
       left: 89.88,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 5,
       name: "Seller Financing If Buyer is to pay all or any portion of the Purchase Price with Seller financing", 
       isText: false,
       type: "checkbox",
       top: 290.04,
       left: 141.72,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 5,
       name: "days before Seller or Private Financing Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 302.16,
       left: 89.28,
       width: 60.00,
       height: 12.48
    }
    ,
    {
       page: 5,
       name: "cb_assumptionOther", 
       isText: false,
       type: "checkbox",
       top: 63.663,
       left: 78.3594,
       width: 11.2800,
       height: 11.280
    }
    ,
    {
       page: 6,
       name: "The", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 513.6,
       left: 466.08,
       width: 75.00,
       height: 10.8
    }
    ,
    {
       page: 7,
       name: "Cost of Appraisal Cost of the Appraisal to be obtained after the date of this Contract must be timely paid by", 
       isText: false,
       type: "checkbox",
       top: 48.6,
       left: 126.12,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 7,
       name: "company lenders agent or all three", 
       isText: false,
       type: "checkbox",
       top: 48.6,
       left: 75.48,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 8,
       name: "company to furnish the owners title insurance policy at Sellers expense On or before Record Title Deadline Seller must", 
       isText: false,
       type: "checkbox",
       top: 128.52,
       left: 115.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "insurance policy to be issued and delivered to Buyer as soon as practicable at or after Closing", 
       isText: false,
       type: "checkbox",
       top: 162.84,
       left: 257.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "company to furnish the owners title insurance policy at Buyers expense On or before Record Title Deadline Buyer must", 
       isText: false,
       type: "checkbox",
       top: 189.96,
       left: 115.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "Extended Coverage OEC If the Title Commitment is to contain OEC it will commit to delete or insure over the standard", 
       isText: false,
       type: "checkbox",
       top: 240.12,
       left: 382.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "Will Not contain Owners", 
       isText: false,
       type: "checkbox",
       top: 240.12,
       left: 424.2,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "OneHalf by Buyer and OneHalf by Seller", 
       isText: false,
       type: "checkbox",
       top: 307.08,
       left: 285.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "Other_3", 
       isText: false,
       type: "checkbox",
       top: 306.12,
       left: 338.28,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 8,
       name: "Buyer_2", 
       isText: false,
       type: "checkbox",
       top: 320.52,
       left: 315.48,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 8,
       name: "Regardless of whether the Contract requires OEC the Title Insurance Commitment may not provide OEC or delete or", 
       isText: false,
       type: "checkbox",
       top: 320.52,
       left: 89.88,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 8,
       name: "undefined_8", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 318,
       left: 363.12,
       width: 209.04,
       height: 13.44
    }
    ,
    {
       page: 9,
       name: "metropolitan districts that affect the Property Tax Certificate must be delivered to Buyer on or before Record Title", 
       isText: false,
       type: "checkbox",
       top: 292.92,
       left: 285.48,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Buyer for the Property listing any special taxing or", 
       isText: false,
       type: "checkbox",
       top: 292.92,
       left: 333.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "tf_metroTaxWebsite", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 273.115,
       left: 83.407,
       width: 471.638,
       height: 14.181
    }
    ,
    {
       page: 10,
       name: "Does_2", 
       isText: false,
       type: "checkbox",
       top: 324.6,
       left: 229.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "Does Not have a Right to Terminate if examination of the Mineral", 
       isText: false,
       type: "checkbox",
       top: 324.6,
       left: 272.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "New Improvement Location Certificate New ILC or", 
       isText: false,
       type: "checkbox",
       top: 370.44,
       left: 301.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "New Survey in the form of", 
       isText: false,
       type: "checkbox",
       top: 383.64,
       left: 93,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "is required and the following will apply", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 381.36,
       left: 222,
       width: 181.08,
       height: 12.24
    }
    ,
    {
       page: 10,
       name: "Seller_2", 
       isText: false,
       type: "checkbox",
       top: 400.92,
       left: 281.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "Buyer will order the New ILC or New Survey The New", 
       isText: false,
       type: "checkbox",
       top: 400.92,
       left: 329.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "Seller_3", 
       isText: false,
       type: "checkbox",
       top: 457.8,
       left: 145.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "Buyer or", 
       isText: false,
       type: "checkbox",
       top: 457.8,
       left: 192.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "will receive", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 500.16,
       left: 260.64,
       width: 267.72,
       height: 12.24
    }
    ,
    {
       page: 10,
       name: "tf_paymentNewSurvey", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 468.74,
       left: 85.8076,
       width: 503.0854,
       height: 20.018
    }
    ,
    {
       page: 11,
       name: "Buyer at Closing Seller agrees to deliver copies of the leases and information pertaining to the personal", 
       isText: false,
       type: "checkbox",
       top: 722.52,
       left: 506.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 11,
       name: "assume the Sellers obligations under such leases for the Leased Items  257 Leased Items", 
       isText: false,
       type: "checkbox",
       top: 722.52,
       left: 465.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Will", 
       isText: false,
       type: "checkbox",
       top: 80.52,
       left: 135.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Will Not assume the debt on the Encumbered Inclusions  254 Encumbered", 
       isText: false,
       type: "checkbox",
       top: 80.52,
       left: 176.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "undefined_9", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 297.12,
       left: 245.04,
       width: 324.96,
       height: 12.24
    }
    ,
    {
       page: 12,
       name: "Does_3", 
       isText: false,
       type: "checkbox",
       top: 369.72,
       left: 466.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Does Not", 
       isText: false,
       type: "checkbox",
       top: 369.72,
       left: 510.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "There is No Well Buyer", 
       isText: false,
       type: "checkbox",
       top: 396.12,
       left: 170.04,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Does_4", 
       isText: false,
       type: "checkbox",
       top: 396.12,
       left: 294.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Does Not acknowledge receipt of a copy of the current", 
       isText: false,
       type: "checkbox",
       top: 396.12,
       left: 338.04,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "If English is not the Sellers principal language and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 428.88,
       left: 210.72,
       width: 152.40,
       height: 12.48
    }
    ,
    {
       page: 13,
       name: "Are", 
       isText: false,
       type: "checkbox",
       top: 612.84,
       left: 471.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Are Not", 
       isText: false,
       type: "checkbox",
       top: 612.84,
       left: 510.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "undefined_10", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 663.84,
       left: 327.12,
       width: 233.04,
       height: 12.48
    }
    ,
    {
       page: 14,
       name: "special warranty deed", 
       isText: false,
       type: "checkbox",
       top: 62.28,
       left: 68.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "general warranty deed", 
       isText: false,
       type: "checkbox",
       top: 62.28,
       left: 185.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "bargain and sale deed", 
       isText: false,
       type: "checkbox",
       top: 62.28,
       left: 305.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "quit claim deed", 
       isText: false,
       type: "checkbox",
       top: 62.28,
       left: 421.56,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "personal", 
       isText: false,
       type: "checkbox",
       top: 62.28,
       left: 510.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "deed Seller provided another deed is not", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 73.2,
       left: 180.48,
       width: 216.12,
       height: 12.24
    }
    ,
    {
       page: 14,
       name: "selected must execute and deliver a good and sufficient special warranty deed to Buyer at Closing Unless otherwise specified", 
       isText: false,
       type: "checkbox",
       top: 75.72,
       left: 165.0,
       width: 10.08,
       height: 9.559
    }
    ,
    {
       page: 14,
       name: "undefined_11", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 259.92,
       left: 435.6,
       width: 135.12,
       height: 12.24
    }
    ,
    {
       page: 14,
       name: "Buyer_3", 
       isText: false,
       type: "checkbox",
       top: 262.2,
       left: 89.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_4", 
       isText: false,
       type: "checkbox",
       top: 262.2,
       left: 139.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_2", 
       isText: false,
       type: "checkbox",
       top: 262.2,
       left: 186.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Other_4", 
       isText: false,
       type: "checkbox",
       top: 262.2,
       left: 391.56,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Buyer_4", 
       isText: false,
       type: "checkbox",
       top: 317.88,
       left: 140.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_5", 
       isText: false,
       type: "checkbox",
       top: 317.88,
       left: 189.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_3", 
       isText: false,
       type: "checkbox",
       top: 317.88,
       left: 237.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "NA", 
       isText: false,
       type: "checkbox",
       top: 317.88,
       left: 441.96,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Buyer_5", 
       isText: false,
       type: "checkbox",
       top: 346.68,
       left: 137.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_6", 
       isText: false,
       type: "checkbox",
       top: 346.68,
       left: 187.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_4", 
       isText: false,
       type: "checkbox",
       top: 346.68,
       left: 234.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "NA_2", 
       isText: false,
       type: "checkbox",
       top: 346.68,
       left: 439.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Buyer_6", 
       isText: false,
       type: "checkbox",
       top: 377.16,
       left: 137.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_7", 
       isText: false,
       type: "checkbox",
       top: 377.16,
       left: 187.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_5", 
       isText: false,
       type: "checkbox",
       top: 377.16,
       left: 234.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "NA_3", 
       isText: false,
       type: "checkbox",
       top: 377.16,
       left: 439.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Buyer_7", 
       isText: false,
       type: "checkbox",
       top: 428.76,
       left: 137.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_8", 
       isText: false,
       type: "checkbox",
       top: 428.76,
       left: 187.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_6", 
       isText: false,
       type: "checkbox",
       top: 428.76,
       left: 234.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "NA_4", 
       isText: false,
       type: "checkbox",
       top: 428.76,
       left: 439.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Buyer_8", 
       isText: false,
       type: "checkbox",
       top: 457.56,
       left: 137.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_9", 
       isText: false,
       type: "checkbox",
       top: 457.56,
       left: 187.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_7", 
       isText: false,
       type: "checkbox",
       top: 457.56,
       left: 234.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "NA_5", 
       isText: false,
       type: "checkbox",
       top: 457.56,
       left: 439.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Buyer_9", 
       isText: false,
       type: "checkbox",
       top: 488.04,
       left: 137.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_10", 
       isText: false,
       type: "checkbox",
       top: 488.04,
       left: 187.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_8", 
       isText: false,
       type: "checkbox",
       top: 488.04,
       left: 234.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "NA_6", 
       isText: false,
       type: "checkbox",
       top: 488.04,
       left: 439.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Buyer_10", 
       isText: false,
       type: "checkbox",
       top: 518.52,
       left: 137.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_11", 
       isText: false,
       type: "checkbox",
       top: 518.52,
       left: 187.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_9", 
       isText: false,
       type: "checkbox",
       top: 518.52,
       left: 234.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "NA_7", 
       isText: false,
       type: "checkbox",
       top: 518.52,
       left: 439.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Buyer_11", 
       isText: false,
       type: "checkbox",
       top: 558.6,
       left: 140.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_12", 
       isText: false,
       type: "checkbox",
       top: 558.6,
       left: 189.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_10", 
       isText: false,
       type: "checkbox",
       top: 558.6,
       left: 237.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "NA_8", 
       isText: false,
       type: "checkbox",
       top: 558.6,
       left: 441.96,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "for", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 585.12,
       left: 79.92,
       width: 79.56,
       height: 12.24
    }
    ,
    {
       page: 14,
       name: "Water StockCertificates", 
       isText: false,
       type: "checkbox",
       top: 604.68,
       left: 120.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Augmentation Membership", 
       isText: false,
       type: "checkbox",
       top: 621.96,
       left: 120.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Water District", 
       isText: false,
       type: "checkbox",
       top: 604.68,
       left: 277.56,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "undefined_12", 
       isText: false,
       type: "checkbox",
       top: 621.96,
       left: 277.56,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "undefined_13", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 619.68,
       left: 462,
       width: 110.52,
       height: 12.24
    }
    ,
    {
       page: 14,
       name: "Buyer_12", 
       isText: false,
       type: "checkbox",
       top: 652.44,
       left: 140.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_13", 
       isText: false,
       type: "checkbox",
       top: 652.44,
       left: 189.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_11", 
       isText: false,
       type: "checkbox",
       top: 652.44,
       left: 237.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "NA_9", 
       isText: false,
       type: "checkbox",
       top: 652.44,
       left: 441.96,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Buyer_13", 
       isText: false,
       type: "checkbox",
       top: 692.52,
       left: 140.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_14", 
       isText: false,
       type: "checkbox",
       top: 692.52,
       left: 189.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "OneHalf by Buyer and OneHalf by Seller_12", 
       isText: false,
       type: "checkbox",
       top: 692.52,
       left: 237.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "NA_10", 
       isText: false,
       type: "checkbox",
       top: 692.52,
       left: 441.96,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "cb_waterTransferFeeOther", 
       isText: false,
       type: "checkbox",
       top: 621.96,
       left: 446.275,
       width: 10.080,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "IS a foreign person for purposes of US income taxation If the box in this Section is not checked Seller", 
       isText: false,
       type: "checkbox",
       top: 47.4,
       left: 120.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Taxes for the Calendar Year Immediately Preceding Closing", 
       isText: false,
       type: "checkbox",
       top: 215.16,
       left: 199.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Most Recent Mill", 
       isText: false,
       type: "checkbox",
       top: 215.16,
       left: 477.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Other_5", 
       isText: false,
       type: "checkbox",
       top: 241.56,
       left: 260.04,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "undefined_14", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 239.28,
       left: 303.84,
       width: 267.48,
       height: 12.24
    }
    ,
    {
       page: 15,
       name: "Rents Actually Received", 
       isText: false,
       type: "checkbox",
       top: 258.84,
       left: 214.2,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Accrued At Closing Seller will transfer or credit to", 
       isText: false,
       type: "checkbox",
       top: 258.84,
       left: 340.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "undefined_15", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 294.96,
       left: 460.32,
       width: 117.36,
       height: 12.24
    }
    ,
    {
       page: 15,
       name: "Buyer_14", 
       isText: false,
       type: "checkbox",
       top: 394.44,
       left: 75.48,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Seller Except however any special assessment by the Association for improvements that have been installed", 
       isText: false,
       type: "checkbox",
       top: 394.44,
       left: 125.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "undefined_16", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 431.52,
       left: 362.64,
       width: 213.72,
       height: 12.24
    }
    ,
    {
       page: 15,
       name: "per day or any part of a day", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 501.84,
       left: 328.56,
       width: 75.00,
       height: 12.24
    }
    ,
    {
       page: 15,
       name: "Does Not represent that Buyer will occupy the Property as Buyers principal residence", 
       isText: false,
       type: "checkbox",
       top: 543.96,
       left: 117.96,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Buyer will be paid to Seller and retained by Seller It is agreed that the Earnest Money is not a penalty and the parties", 
       isText: false,
       type: "checkbox",
       top: 481.8,
       left: 115.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 17,
       name: "undefined_17", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 605.76,
       left: 72.96,
       width: 187.56,
       height: 11.04
    }
    ,
    {
       page: 18,
       name: "ta_additionalProvisions", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 140.209,
       left: 45.8553,
       width: 537.4907,
       height: 441.744
    }
    ,
    {
       page: 18,
       name: "ta_docsPartContract", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 605.33,
       left: 66.4464,
       width: 516.1176,
       height: 68.499
    }
    ,
    {
       page: 18,
       name: "ta_docs_notContract", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 686.179,
       left: 68.0103,
       width: 515.5967,
       height: 70.062
    }
    ,
    {
       page: 19,
       name: "Homeowner Warning Notice  Right to Cancel in Sellers principal language of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 78.84,
       left: 431.04,
       width: 142.32,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "BUYERS NAME OR ANYONE", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 174.24,
       left: 72.24,
       width: 300.12,
       height: 12.72
    }
    ,
    {
       page: 19,
       name: "WORKING FOR", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 188.16,
       left: 164.64,
       width: 306.84,
       height: 12.48
    }
    ,
    {
       page: 19,
       name: "1029", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 293.52,
       left: 56.88,
       width: 182.28,
       height: 11.28
    }
    ,
    {
       page: 19,
       name: "Buyers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 385.32,
       left: 121.68,
       width: 171.24,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Buyers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 385.32,
       left: 392.4,
       width: 188.52,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 431.88,
       left: 121.68,
       width: 171.24,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Address_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 431.88,
       left: 367.2,
       width: 213.72,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Phone", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 445.32,
       left: 121.68,
       width: 171.24,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Phone_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 445.32,
       left: 366.96,
       width: 213.96,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Email", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 458.76,
       left: 121.68,
       width: 171.24,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Email_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 458.76,
       left: 360.72,
       width: 220.20,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Sellers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 531.48,
       left: 121.68,
       width: 171.24,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Sellers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 531.48,
       left: 391.44,
       width: 189.48,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Address_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 578.04,
       left: 121.68,
       width: 171.24,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Address_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 578.04,
       left: 367.2,
       width: 213.72,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Phone_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 591.48,
       left: 121.68,
       width: 171.24,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Phone_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 591.48,
       left: 366.96,
       width: 213.96,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Email_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 604.92,
       left: 121.68,
       width: 171.24,
       height: 12.84
    }
    ,
    {
       page: 19,
       name: "Email_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 604.92,
       left: 360.72,
       width: 220.20,
       height: 12.84
    }
    ,
    {
       page: 20,
       name: "Does_5", 
       isText: false,
       type: "checkbox",
       top: 77.4,
       left: 92.04,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the", 
       isText: false,
       type: "checkbox",
       top: 77.4,
       left: 144.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Buyers Agent", 
       isText: false,
       type: "checkbox",
       top: 139.32,
       left: 192.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "TransactionBroker in this transaction", 
       isText: false,
       type: "checkbox",
       top: 139.32,
       left: 276.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
       isText: false,
       type: "checkbox",
       top: 155.4,
       left: 50.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Other_6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 183.48,
       left: 337.2,
       width: 223.2,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Brokerage Firms compensation or commission is to be paid by", 
       isText: false,
       type: "checkbox",
       top: 187.08,
       left: 291.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Listing Brokerage Firm", 
       isText: false,
       type: "checkbox",
       top: 187.08,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Buyer_15", 
       isText: false,
       type: "checkbox",
       top: 187.08,
       left: 243.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Brokerage Firms Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 242.04,
       left: 183.84,
       width: 351.96,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Brokerage Firms License", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 256.2,
       left: 183.6,
       width: 352.2,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Brokers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 270.6,
       left: 130.32,
       width: 405.48,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Brokers License 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 284.76,
       left: 136.32,
       width: 399.48,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Date Brokers License 3", 
       fontSize: 9,
       type: "date",
       top: 311.16,
       left: 234.12,
       width: 58.56,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Address_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 349.32,
       left: 102.24,
       width: 334.68,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Phone No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 363.48,
       left: 102.96,
       width: 333.96,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Fax No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 363.36,
       left: 479.16,
       width: 79.92,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Email_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 377.76,
       left: 85.92,
       width: 351.00,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Does_6", 
       isText: false,
       type: "checkbox",
       top: 417,
       left: 83.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the_2", 
       isText: false,
       type: "checkbox",
       top: 417,
       left: 127.56,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Sellers Agent", 
       isText: false,
       type: "checkbox",
       top: 478.92,
       left: 190.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "TransactionBroker in this transaction_2", 
       isText: false,
       type: "checkbox",
       top: 478.92,
       left: 272.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Customer Broker has no brokerage relationship with Seller See  A for Brokers brokerage relationship with Buyer", 
       isText: false,
       type: "checkbox",
       top: 495,
       left: 49.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Brokerage Firms compensation or commission is to be paid by_2", 
       isText: false,
       type: "checkbox",
       top: 534.12,
       left: 217.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Seller_15", 
       isText: false,
       type: "checkbox",
       top: 534.12,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Buyer_16", 
       isText: false,
       type: "checkbox",
       top: 534.12,
       left: 169.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 20,
       name: "Other_SellerBroker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 530.52,
       left: 262.8,
       width: 223.2,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Brokerage Firms Name_SellerBroker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 585.24,
       left: 183.84,
       width: 351.96,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Brokerage Firms License_SellerBroker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 599.4,
       left: 183.6,
       width: 352.2,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Brokers Name_SellerBroker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 613.8,
       left: 130.32,
       width: 405.48,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Brokers LicenseSellerBroker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 627.96,
       left: 136.32,
       width: 399.48,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Date Brokers License SellerBroker", 
       fontSize: 9,
       type: "date",
       top: 654.36,
       left: 234.12,
       width: 58.56,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Address_6SellerBroker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 692.52,
       left: 102.24,
       width: 334.68,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Phone No_2SellerBroker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 706.68,
       left: 102.96,
       width: 333.96,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Fax No_SellerBroker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 706.56,
       left: 481.68,
       width: 79.92,
       height: 13.56
    }
    ,
    {
       page: 20,
       name: "Email_6SellerBroker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 720.96,
       left: 85.92,
       width: 351.00,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "signature seller broker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 12,
       top: 653.181,
       left: 58.9062,
       width: 168.2448,
       height: 14.702
    }
    ,
    {
       page: 20,
       name: "signature buyerbroker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 12,
       top: 308.127,
       left: 57.8636,
       width: 170.3304,
       height: 15.745
    }
    ] }
    