import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "semantic-ui-react";
import { sortHistory } from "../historySlice";
import HistoryListItem from "./HistoryListItem";

export default function HistoryList({ history, column, direction }) {
  const dispatch = useDispatch();

  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell
              sorted={column === "createdAt" ? direction : null}
              onClick={() => dispatch(sortHistory("createdAt"))}
            >
              Date
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "role" ? direction : null}
              onClick={() => dispatch(sortHistory("role"))}
            >
              Role
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "name" ? direction : null}
              onClick={() => dispatch(sortHistory("name"))}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "action" ? direction : null}
              onClick={() => dispatch(sortHistory("action"))}
            >
              Action
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "document" ? direction : null}
              onClick={() => dispatch(sortHistory("document"))}
            >
              Document
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "toRole" ? direction : null}
              onClick={() => dispatch(sortHistory("toRole"))}
            >
              To Role
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "toName" ? direction : null}
              onClick={() => dispatch(sortHistory("toName"))}
            >
              To Name
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {history.length !== 0 &&
            history.map((entry) => (
              <HistoryListItem entry={entry} key={entry.id} />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
