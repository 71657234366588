export function contracttoBuyandSellResidential2023() {
  return [
    //2023
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    },
    {
      page: 0,
      name: "Date",
      fontSize: 9,
      type: "date",
      top: 172.56,
      left: 439.2,
      width: 140.28,
      height: 12.48,
    },
    {
      page: 0,
      name: "Buyer",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 253.2,
      left: 124.32,
      width: 369.6,
      height: 12.48,
    },
    {
      page: 0,
      name: "Other",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 270.48,
      left: 425.28,
      width: 151.8,
      height: 12.48,
    },
    {
      page: 0,
      name: "Seller",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 300.72,
      left: 122.64,
      width: 371.28,
      height: 12.48,
    },
    {
      page: 0,
      name: "Property The Property is the following legally described real estate in the County of",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 323.52,
      left: 427.92,
      width: 114.0,
      height: 12.48,
    },
    {
      page: 0,
      name: "known as",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 404.16,
      left: 100.8,
      width: 476.28,
      height: 12.48,
    },
    {
      page: 0,
      name: "including",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 515.52,
      left: 98.64,
      width: 36.84,
      height: 10.8,
    },
    {
      page: 0,
      name: "ta_legalDescription",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 348.62,
      left: 55.5768,
      width: 520.8522,
      height: 52.481,
    },
    {
      page: 0,
      name: "cb_jointTenants",
      isText: false,
      type: "checkbox",
      top: 270.574,
      left: 195.859,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 0,
      name: "cb_tenantsInCommon",
      isText: false,
      type: "checkbox",
      top: 271.574,
      left: 273.288,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 0,
      name: "cb_buyerOther",
      isText: false,
      type: "checkbox",
      top: 271.382,
      left: 381.409,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 0,
      name: "cb_securitySystems",
      isText: false,
      type: "checkbox",
      top: 528.189,
      left: 99.3322,
      width: 14.5718,
      height: 14.571,
    },
    {
      page: 0,
      name: "cb_satellite",
      isText: false,
      type: "checkbox",
      top: 528.131,
      left: 196.146,
      width: 14.572,
      height: 14.047,
    },
    {
      page: 0,
      name: "cb_solarPanels",
      isText: false,
      type: "checkbox",
      top: 515.854,
      left: 463.491,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 0,
      name: "cb_waterSofteners",
      isText: false,
      type: "checkbox",
      top: 515.301,
      left: 533.891,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 0,
      name: "ta_otherInclusions",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 630.136,
      left: 54.0166,
      width: 520.8514,
      height: 96.4653,
    },
    {
      page: 0,
      name: "cb_ppa",
      isText: false,
      type: "checkbox",
      top: 728.4208,
      left: 69.9504,
      width: 14.5714,
      height: 14.5714,
    },
    {
      page: 1,
      name: "and the use or ownership of the following storage facilities",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 168.96,
      left: 54.96,
      width: 144.12,
      height: 10.8,
    },
    {
      page: 1,
      name: "tf_storageFacilities",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 169.2,
      left: 443.28,
      width: 133.56,
      height: 10.8,
    },
    {
      page: 1,
      name: "deed at Closing",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 379.68,
      left: 350.4,
      width: 159.0,
      height: 10.8,
    },
    {
      page: 1,
      name: "tf_wellPermitNo",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 529.2,
      left: 54.96,
      width: 130.08,
      height: 10.8,
    },
    {
      page: 1,
      name: "Deadline DetailsTime of Day Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 692.64,
      left: 424.44,
      width: 165.96,
      height: 11.04,
    },
    {
      page: 1,
      name: "DateAlternative Earnest Money Deadline",
      fontSize: 9,
      type: "date",
      top: 704.64,
      left: 329.88,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 1,
      name: "Deadline DetailsAlternative Earnest Money Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 704.64,
      left: 424.44,
      width: 165.96,
      height: 11.04,
    },
    {
      page: 1,
      name: "DateRecord Title Deadline and Tax Certificate",
      fontSize: 9,
      type: "date",
      top: 728.64,
      left: 329.88,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 1,
      name: "Deadline DetailsRecord Title Deadline and Tax Certificate",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 728.64,
      left: 424.44,
      width: 165.96,
      height: 11.04,
    },
    {
      page: 1,
      name: "DateRecord Title Objection Deadline",
      fontSize: 9,
      type: "date",
      top: 740.64,
      left: 329.88,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 1,
      name: "Deadline DetailsRecord Title Objection Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 740.64,
      left: 424.44,
      width: 165.96,
      height: 11.04,
    },
    {
      page: 1,
      name: "ta_encumberedInclusions",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 86.127,
      left: 52.9693,
      width: 520.8517,
      height: 48.291,
    },
    {
      page: 1,
      name: "ta_leasedItems",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 214.37,
      left: 52.9693,
      width: 520.8517,
      height: 48.291,
    },
    {
      page: 1,
      name: "ta_exclusions",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 272.97,
      left: 52.9693,
      width: 520.8517,
      height: 50.386,
    },
    {
      page: 1,
      name: "ta_deededWaterRights",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 345.046,
      left: 52.9693,
      width: 520.8517,
      height: 32.582,
    },
    {
      page: 1,
      name: "ta_otherRightsWater",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 414.922,
      left: 52.9694,
      width: 520.8516,
      height: 44.102,
    },
    {
      page: 1,
      name: "ta_waterStockCerts",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 554.5,
      left: 52.9694,
      width: 520.8516,
      height: 35.2,
    },
    {
      page: 1,
      name: "cb_waterRightsReview_does",
      isText: false,
      type: "checkbox",
      top: 621.076,
      left: 235.419,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 1,
      name: "cb_waterRightsReview_doesNot",
      isText: false,
      type: "checkbox",
      top: 620.861,
      left: 281.761,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 1,
      name: "cb_waterStockCerts",
      isText: false,
      type: "checkbox",
      top: 540.465,
      left: 50.6051,
      width: 14.5714,
      height: 14.571,
    },
    {
      page: 1,
      name: "cb_wellRights",
      isText: false,
      type: "checkbox",
      top: 459.534,
      left: 50.8377,
      width: 14.5714,
      height: 14.572,
    },
    {
      page: 1,
      name: "cb_deededWaterRights",
      isText: false,
      type: "checkbox",
      top: 331.188,
      left: 50.2056,
      width: 14.5715,
      height: 14.572,
    },
    {
      page: 1,
      name: "cb_otherRightsRelatingToWater",
      isText: false,
      type: "checkbox",
      top: 388.68,
      left: 49.6877,
      width: 14.5714,
      height: 14.571,
    },
    {
      page: 2,
      name: "DateOffRecord Title Deadline",
      fontSize: 9,
      type: "date",
      top: 91.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsOffRecord Title Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 91.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateOffRecord Title Objection Deadline",
      fontSize: 9,
      type: "date",
      top: 103.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsOffRecord Title Objection Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 103.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateTitle Resolution Deadline",
      fontSize: 9,
      type: "date",
      top: 115.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsTitle Resolution Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 115.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateThird Party Right to PurchaseApprove Deadline",
      fontSize: 9,
      type: "date",
      top: 127.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsThird Party Right to PurchaseApprove Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 127.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateAssociation Documents Deadline",
      fontSize: 9,
      type: "date",
      top: 151.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsAssociation Documents Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 151.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateAssociation Documents Termination Deadline",
      fontSize: 9,
      type: "date",
      top: 163.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsAssociation Documents Termination Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 163.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateSellers Property Disclosure Deadline",
      fontSize: 9,
      type: "date",
      top: 187.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsSellers Property Disclosure Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 187.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateLeadBased Paint Disclosure Deadline",
      fontSize: 9,
      type: "date",
      top: 199.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsLeadBased Paint Disclosure Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 199.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateNew Loan Application Deadline",
      fontSize: 9,
      type: "date",
      top: 223.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsNew Loan Application Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 223.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateNew Loan Terms Deadline",
      fontSize: 9,
      type: "date",
      top: 235.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsNew Loan Terms Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 235.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateNew Loan Availability Deadline",
      fontSize: 9,
      type: "date",
      top: 247.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsNew Loan Availability Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 247.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateBuyers Credit Information Deadline",
      fontSize: 9,
      type: "date",
      top: 259.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsBuyers Credit Information Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 259.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateDisapproval of Buyers Credit Information Deadline",
      fontSize: 9,
      type: "date",
      top: 271.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsDisapproval of Buyers Credit Information Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 271.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateExisting Loan Deadline",
      fontSize: 9,
      type: "date",
      top: 283.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsExisting Loan Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 283.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateExisting Loan Termination Deadline",
      fontSize: 9,
      type: "date",
      top: 295.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsExisting Loan Termination Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 295.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateLoan Transfer Approval Deadline",
      fontSize: 9,
      type: "date",
      top: 307.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsLoan Transfer Approval Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 307.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateSeller or Private Financing Deadline",
      fontSize: 9,
      type: "date",
      top: 319.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsSeller or Private Financing Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 319.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateAppraisal Deadline",
      fontSize: 9,
      type: "date",
      top: 343.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsAppraisal Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 343.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateAppraisal Objection Deadline",
      fontSize: 9,
      type: "date",
      top: 355.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsAppraisal Objection Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 355.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateAppraisal Resolution Deadline",
      fontSize: 9,
      type: "date",
      top: 367.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsAppraisal Resolution Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 367.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateNew ILC or New Survey Deadline",
      fontSize: 9,
      type: "date",
      top: 391.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsNew ILC or New Survey Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 391.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateNew ILC or New Survey Objection Deadline",
      fontSize: 9,
      type: "date",
      top: 403.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsNew ILC or New Survey Objection Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 403.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateNew ILC or New Survey Resolution Deadline",
      fontSize: 9,
      type: "date",
      top: 415.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsNew ILC or New Survey Resolution Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 415.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateWater Rights Examination Deadline",
      fontSize: 9,
      type: "date",
      top: 439.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsWater Rights Examination Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 439.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateMineral Rights Examination Deadline",
      fontSize: 9,
      type: "date",
      top: 451.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsMineral Rights Examination Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 451.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateInspection Termination Deadline",
      fontSize: 9,
      type: "date",
      top: 463.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsInspection Termination Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 463.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateInspection Objection Deadline",
      fontSize: 9,
      type: "date",
      top: 475.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsInspection Objection Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 475.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateInspection Resolution Deadline",
      fontSize: 9,
      type: "date",
      top: 487.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsInspection Resolution Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 487.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateProperty Insurance Termination Deadline",
      fontSize: 9,
      type: "date",
      top: 499.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsProperty Insurance Termination Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 499.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateDue Diligence Documents Delivery Deadline",
      fontSize: 9,
      type: "date",
      top: 511.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsDue Diligence Documents Delivery Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 511.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateDue Diligence Documents Objection Deadline",
      fontSize: 9,
      type: "date",
      top: 523.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsDue Diligence Documents Objection Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 523.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateDue Diligence Documents Resolution Deadline",
      fontSize: 9,
      type: "date",
      top: 535.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsDue Diligence Documents Resolution Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 535.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateConditional Sale Deadline",
      fontSize: 9,
      type: "date",
      top: 547.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsConditional Sale Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 547.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateLeadBased Paint Termination Deadline",
      fontSize: 9,
      type: "date",
      top: 559.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsLeadBased Paint Termination Deadline",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 559.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateClosing Date",
      fontSize: 9,
      type: "date",
      top: 583.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsClosing Date",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 583.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DatePossession Date",
      fontSize: 9,
      type: "date",
      top: 595.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsPossession Date",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 595.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsPossession Time",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 607.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "DateAcceptance Deadline Date",
      fontSize: 9,
      type: "date",
      top: 619.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsAcceptance Deadline Date",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 619.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline DetailsAcceptance Deadline Time",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 631.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: " 2744",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 643.2,
      left: 82.2,
      width: 34.92,
      height: 11.04,
    },
    {
      page: 2,
      name: "Acceptance Deadline Time44",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 643.2,
      left: 118.2,
      width: 214.92,
      height: 11.04,
    },
    {
      page: 2,
      name: "Date44",
      fontSize: 9,
      type: "date",
      top: 643.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline Details44",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 643.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 2,
      name: " 2745",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 655.2,
      left: 82.2,
      width: 34.92,
      height: 11.04,
    },
    {
      page: 2,
      name: "Acceptance Deadline Time45",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 655.2,
      left: 118.2,
      width: 214.92,
      height: 11.04,
    },
    {
      page: 2,
      name: "Date45",
      fontSize: 9,
      type: "date",
      top: 655.2,
      left: 334.2,
      width: 93.48,
      height: 11.04,
    },
    {
      page: 2,
      name: "Deadline Details45",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 655.2,
      left: 428.76,
      width: 156.36,
      height: 11.04,
    },
    {
      page: 3,
      name: "Purchase Price",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 292.996,
      left: 320.585,
      width: 93.524,
      height: 11.04,
    },
    {
      page: 3,
      name: "Amount",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 293.52,
      left: 419.88,
      width: 97.8,
      height: 11.04,
    },
    {
      page: 3,
      name: "Earnest Money",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 305.52,
      left: 307.32,
      width: 111.48,
      height: 11.04,
    },
    {
      page: 3,
      name: "fill_24",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 305.52,
      left: 429,
      width: 88.68,
      height: 11.04,
    },
    {
      page: 3,
      name: "New Loan",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 317.52,
      left: 307.32,
      width: 111.48,
      height: 11.04,
    },
    {
      page: 3,
      name: "fill_26",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 317.52,
      left: 429,
      width: 88.68,
      height: 11.04,
    },
    {
      page: 3,
      name: "Assumption Balance",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 329.52,
      left: 307.32,
      width: 111.48,
      height: 11.04,
    },
    {
      page: 3,
      name: "fill_28",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 329.52,
      left: 429,
      width: 88.68,
      height: 11.04,
    },
    {
      page: 3,
      name: "Private Financing",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 341.52,
      left: 307.32,
      width: 111.48,
      height: 11.04,
    },
    {
      page: 3,
      name: "fill_30",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 341.52,
      left: 429,
      width: 88.68,
      height: 11.04,
    },
    {
      page: 3,
      name: "Seller Financing",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 353.52,
      left: 307.32,
      width: 111.48,
      height: 11.04,
    },
    {
      page: 3,
      name: " 477",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 365.52,
      left: 118.2,
      width: 48.6,
      height: 11.04,
    },
    {
      page: 3,
      name: "Seller Financing7",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 365.52,
      left: 167.88,
      width: 138.36,
      height: 11.04,
    },
    {
      page: 3,
      name: "7",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 365.52,
      left: 307.32,
      width: 111.48,
      height: 11.04,
    },
    {
      page: 3,
      name: "7_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 365.52,
      left: 419.88,
      width: 97.8,
      height: 11.04,
    },
    {
      page: 3,
      name: " 478",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 377.52,
      left: 118.2,
      width: 48.6,
      height: 11.04,
    },
    {
      page: 3,
      name: "Seller Financing8",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 377.52,
      left: 167.88,
      width: 138.36,
      height: 11.04,
    },
    {
      page: 3,
      name: "8",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 377.52,
      left: 307.32,
      width: 111.48,
      height: 11.04,
    },
    {
      page: 3,
      name: "8_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 377.52,
      left: 419.88,
      width: 97.8,
      height: 11.04,
    },
    {
      page: 3,
      name: "Cash at Closing",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 389.52,
      left: 307.32,
      width: 111.48,
      height: 11.04,
    },
    {
      page: 3,
      name: "fill_31",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 389.52,
      left: 429,
      width: 88.68,
      height: 11.04,
    },
    {
      page: 3,
      name: "fill_33",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 401.52,
      left: 316.44,
      width: 102.36,
      height: 11.04,
    },
    {
      page: 3,
      name: "fill_34",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 401.52,
      left: 429,
      width: 88.68,
      height: 11.04,
    },
    {
      page: 3,
      name: "Seller Concession The Seller",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 416.88,
      left: 347.76,
      width: 92.52,
      height: 12.72,
    },
    {
      page: 3,
      name: "will be",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 489.36,
      left: 412.08,
      width: 130.08,
      height: 12.72,
    },
    {
      page: 3,
      name: "Earnest Money Holder in its trust account on behalf of",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 502.8,
      left: 143.52,
      width: 202.44,
      height: 12.72,
    },
    {
      page: 3,
      name: "tf_sellerFinancingAmt",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 353.431,
      left: 429.549,
      width: 88.68,
      height: 11.04,
    },
    {
      page: 3,
      name: "cb_deadlinesExtended_will",
      isText: false,
      type: "checkbox",
      top: 216.757,
      left: 86.823,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 3,
      name: "cb_deadlinesExtended_willNot",
      isText: false,
      type: "checkbox",
      top: 217.615,
      left: 123.623,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 4,
      name: "tf_loanLimitations_otherField",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 304.32,
      left: 284.4,
      width: 288.12,
      height: 10.8,
    },
    {
      page: 4,
      name: "tf_loanLimitations_notExceed",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 327.36,
      left: 408.12,
      width: 164.16,
      height: 10.56,
    },
    {
      page: 4,
      name: "per",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 396.24,
      left: 280.8,
      width: 74.28,
      height: 10.8,
    },
    {
      page: 4,
      name: "including principal and interest",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 396.24,
      left: 370.32,
      width: 81.84,
      height: 10.8,
    },
    {
      page: 4,
      name: "per annum and also including escrow for the following as indicated",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 407.76,
      left: 141.84,
      width: 40.32,
      height: 10.8,
    },
    {
      page: 4,
      name: "undefined_5",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 419.28,
      left: 360.96,
      width: 211.56,
      height: 10.8,
    },
    {
      page: 4,
      name: "At the time of assumption the new interest rate will",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 430.8,
      left: 292.92,
      width: 66.72,
      height: 10.8,
    },
    {
      page: 4,
      name: "per annum and the new payment will not exceed",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 442.32,
      left: 97.92,
      width: 39.36,
      height: 10.8,
    },
    {
      page: 4,
      name: "per_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 442.32,
      left: 354,
      width: 68.76,
      height: 10.8,
    },
    {
      page: 4,
      name: "principal and",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 442.32,
      left: 438,
      width: 83.88,
      height: 10.8,
    },
    {
      page: 4,
      name: "or if any other terms or",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 465.36,
      left: 409.08,
      width: 68.04,
      height: 10.8,
    },
    {
      page: 4,
      name: "in an amount",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 511.44,
      left: 410.4,
      width: 110.4,
      height: 10.8,
    },
    {
      page: 4,
      name: "undefined_6",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 522.96,
      left: 110.88,
      width: 65.4,
      height: 10.8,
    },
    {
      page: 4,
      name: "days before Seller or",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 607.2,
      left: 437.76,
      width: 49.8,
      height: 10.8,
    },
    {
      page: 4,
      name: "cb_availableFunds_does",
      isText: false,
      type: "checkbox",
      top: 198.43,
      left: 459.651,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_availableFunds_doesNot",
      isText: false,
      type: "checkbox",
      top: 197.979,
      left: 499.592,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_loanLimitations_conventional",
      isText: false,
      type: "checkbox",
      top: 303.259,
      left: 49.5578,
      width: 14.5714,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_loanLimitations_fha",
      isText: false,
      type: "checkbox",
      top: 303.444,
      left: 125.194,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_loanLimitations_va",
      isText: false,
      type: "checkbox",
      top: 303.491,
      left: 165.514,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 4,
      name: "cb_loanLimitations_bond",
      isText: false,
      type: "checkbox",
      top: 303.259,
      left: 199.317,
      width: 14.048,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_loanLimitations_other",
      isText: false,
      type: "checkbox",
      top: 303.55,
      left: 240.452,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_realEstateTaxes",
      isText: false,
      type: "checkbox",
      top: 406.124,
      left: 469.222,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_propertyInsurancePremium",
      isText: false,
      type: "checkbox",
      top: 406.124,
      left: 561.287,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_mortgageInsurancePremium",
      isText: false,
      type: "checkbox",
      top: 418.982,
      left: 180.466,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_assumption_other",
      isText: false,
      type: "checkbox",
      top: 416.749,
      left: 343.517,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 4,
      name: "cb_sellerReleasedFromLoanLiability_will",
      isText: false,
      type: "checkbox",
      top: 488.16,
      left: 97.7905,
      width: 14.5715,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_sellerReleasedFromLoanLiability_willNot",
      isText: false,
      type: "checkbox",
      top: 487.811,
      left: 134.619,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_loanTransferApprovalDeadline_atClosing",
      isText: false,
      type: "checkbox",
      top: 498.051,
      left: 447.52,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 4,
      name: "cb_loanRelease_beforeLoanTransferApproval",
      isText: false,
      type: "checkbox",
      top: 498.865,
      left: 229.455,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 4,
      name: "cb_sellerFinancing_buyer",
      isText: false,
      type: "checkbox",
      top: 592.363,
      left: 534.502,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 4,
      name: "cb_sellerFinancing_seller",
      isText: false,
      type: "checkbox",
      top: 606.152,
      left: 49.2668,
      width: 14.5714,
      height: 14.572,
    },
    {
      page: 6,
      name: "setting forth the appraised value of the Property of not less than",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 138.96,
      left: 311.64,
      width: 72.24,
      height: 10.8,
    },
    {
      page: 6,
      name: "cb_costAppraisal_buyer",
      isText: false,
      type: "checkbox",
      top: 322.168,
      left: 534.502,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 6,
      name: "cb_costAppraisal_seller",
      isText: false,
      type: "checkbox",
      top: 335.429,
      left: 49.645,
      width: 14.5714,
      height: 14.572,
    },
    {
      page: 7,
      name: "Other_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 564.48,
      left: 377.52,
      width: 178.08,
      height: 12.48,
    },
    {
      page: 7,
      name: "cb_sellerSelectsTitle",
      isText: false,
      type: "checkbox",
      top: 399.666,
      left: 50.1396,
      width: 14.5715,
      height: 14.571,
    },
    {
      page: 7,
      name: "cb_buyerSelectsTitle",
      isText: false,
      type: "checkbox",
      top: 460.058,
      left: 49.7904,
      width: 14.5714,
      height: 14.571,
    },
    {
      page: 7,
      name: "cb_abstractOfTitle",
      isText: false,
      type: "checkbox",
      top: 435.215,
      left: 152.714,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 7,
      name: "cb_oec_will",
      isText: false,
      type: "checkbox",
      top: 504.625,
      left: 408.538,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 7,
      name: "cb_oec_willNot",
      isText: false,
      type: "checkbox",
      top: 505.614,
      left: 449.847,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 7,
      name: "cb_oec_other",
      isText: false,
      type: "checkbox",
      top: 565.076,
      left: 336.509,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 7,
      name: "cb_oec_half",
      isText: false,
      type: "checkbox",
      top: 565.309,
      left: 134.619,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 7,
      name: "cb_oec_seller",
      isText: false,
      type: "checkbox",
      top: 565.076,
      left: 92.4958,
      width: 14.5712,
      height: 14.571,
    },
    {
      page: 7,
      name: "cb_oec_buyer",
      isText: false,
      type: "checkbox",
      top: 565.367,
      left: 49.3251,
      width: 14.5714,
      height: 14.571,
    },
    {
      page: 8,
      name: "tf_metroUrl",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 518.64,
      left: 50.4,
      width: 523.2,
      height: 16.56,
    },
    {
      page: 8,
      name: "cb_taxCert_seller",
      isText: false,
      type: "checkbox",
      top: 540.989,
      left: 300.379,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 8,
      name: "cb_taxCert_buyer",
      isText: false,
      type: "checkbox",
      top: 540.698,
      left: 345.644,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 9,
      name: "is required and the following will apply",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 612.96,
      left: 175.92,
      width: 232.8,
      height: 10.8,
    },
    {
      page: 9,
      name: "will receive a New ILC or New Survey on or before",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 720,
      left: 236.4,
      width: 131.04,
      height: 10.8,
    },
    {
      page: 9,
      name: "cb_mineralRights_does",
      isText: false,
      type: "checkbox",
      top: 556.174,
      left: 244.35,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 9,
      name: "cb_mineralRights_doesNot",
      isText: false,
      type: "checkbox",
      top: 555.883,
      left: 285.426,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 9,
      name: "cb_newIlc",
      isText: false,
      type: "checkbox",
      top: 597.541,
      left: 315.04,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 9,
      name: "cb_newSurvey",
      isText: false,
      type: "checkbox",
      top: 611.97,
      left: 49.8488,
      width: 14.5714,
      height: 14.572,
    },
    {
      page: 9,
      name: "cb_whoOrdersSurvey_seller",
      isText: false,
      type: "checkbox",
      top: 624.829,
      left: 307.768,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 9,
      name: "cb_whoOrdersSurvey_buyer",
      isText: false,
      type: "checkbox",
      top: 624.956,
      left: 351.928,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 9,
      name: "cb_payIlc_seller",
      isText: false,
      type: "checkbox",
      top: 671.945,
      left: 68.9322,
      width: 14.5714,
      height: 14.571,
    },
    {
      page: 9,
      name: "cb_payIlc_buyer",
      isText: false,
      type: "checkbox",
      top: 672.654,
      left: 116.292,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 9,
      name: "ta_paymentIlcSurvey",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 686.003,
      left: 51.6375,
      width: 520.8515,
      height: 22.8835,
    },
    {
      page: 11,
      name: "undefined_8",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 597.36,
      left: 221.04,
      width: 319.92,
      height: 12.48,
    },
    {
      page: 11,
      name: "ta_otherDocuments",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 391.126,
      left: 50.733,
      width: 520.852,
      height: 57.193,
    },
    {
      page: 11,
      name: "ta_occupancyAgreements",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 218.821,
      left: 49.5657,
      width: 520.8523,
      height: 45.673,
    },
    {
      page: 11,
      name: "cb_leasedItemsBuyerAssume_will",
      isText: false,
      type: "checkbox",
      top: 286.211,
      left: 357.164,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 11,
      name: "cb_leasedItemsBuyerAssume_willNot",
      isText: false,
      type: "checkbox",
      top: 286.211,
      left: 393.818,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 11,
      name: "cb_encumberedInclusionsBuyerAssume_will",
      isText: false,
      type: "checkbox",
      top: 343.288,
      left: 466.08,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 11,
      name: "cb_encumberedInclusionsBuyerAssume_willNot",
      isText: false,
      type: "checkbox",
      top: 343.811,
      left: 515.825,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 11,
      name: "cb_receivedSow_does",
      isText: false,
      type: "checkbox",
      top: 654.617,
      left: 481.033,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 11,
      name: "cb_receivedSow_doesNot",
      isText: false,
      type: "checkbox",
      top: 654.85,
      left: 521.585,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 11,
      name: "cb_noWell",
      isText: false,
      type: "checkbox",
      top: 680.799,
      left: 104.539,
      width: 14.572,
      height: 14.5716,
    },
    {
      page: 11,
      name: "cb_receiptWellPermit_does",
      isText: false,
      type: "checkbox",
      top: 680.508,
      left: 222.59,
      width: 14.571,
      height: 14.5717,
    },
    {
      page: 11,
      name: "cb_receiptWellPermit_doesNot",
      isText: false,
      type: "checkbox",
      top: 680.508,
      left: 260.815,
      width: 14.572,
      height: 14.5717,
    },
    {
      page: 12,
      name: "cb_closingInstructions_are",
      isText: false,
      type: "checkbox",
      top: 691.505,
      left: 429.425,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 12,
      name: "cb_closingInstructions_areNot",
      isText: false,
      type: "checkbox",
      top: 691.457,
      left: 465.556,
      width: 14.572,
      height: 14.5716,
    },
    {
      page: 13,
      name: "Disclosure of Settlement Costs Buyer and Seller acknowledge that costs quality and extent of service vary between",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 76.08,
      left: 302.88,
      width: 246.84,
      height: 12.48,
    },
    {
      page: 13,
      name: "sufficient special warranty deed to Buyer at Closing",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 187.68,
      left: 65.52,
      width: 142.2,
      height: 12.48,
    },
    {
      page: 13,
      name: "tf_closingServicesFee_other",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 379.44,
      left: 260.64,
      width: 292.56,
      height: 12.48,
    },
    {
      page: 13,
      name: "Water StockCertificates",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 666.72,
      left: 55.56,
      width: 79.92,
      height: 12.48,
    },
    {
      page: 13,
      name: "tf_water_other",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 693.0,
      left: 425.416,
      width: 121.418,
      height: 13.5159,
    },
    {
      page: 13,
      name: "cb_transferTitle_bargain",
      isText: false,
      type: "checkbox",
      top: 174.968,
      left: 249.586,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_transferTitle_general",
      isText: false,
      type: "checkbox",
      top: 174.552,
      left: 139.572,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_transferTitle_quit",
      isText: false,
      type: "checkbox",
      top: 175.288,
      left: 356.826,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_transferTitle_personal",
      isText: false,
      type: "checkbox",
      top: 175.806,
      left: 438.661,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_transferTitle_other",
      isText: false,
      type: "checkbox",
      top: 187.237,
      left: 49.1698,
      width: 14.5714,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_transferTitle_special",
      isText: false,
      type: "checkbox",
      top: 162.34,
      left: 562.966,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_closingServicesFee_buyer",
      isText: false,
      type: "checkbox",
      top: 366.87,
      left: 197.245,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_closingServicesFee_seller",
      isText: false,
      type: "checkbox",
      top: 367.444,
      left: 249.613,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_closingServicesFee_half",
      isText: false,
      type: "checkbox",
      top: 366.388,
      left: 299.761,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_statusLetter_na",
      isText: false,
      type: "checkbox",
      top: 446.689,
      left: 510.024,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_statusLetter_half",
      isText: false,
      type: "checkbox",
      top: 446.689,
      left: 298.817,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_statusLetter_seller",
      isText: false,
      type: "checkbox",
      top: 446.689,
      left: 249.095,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_statusLetter_buyer",
      isText: false,
      type: "checkbox",
      top: 446.689,
      left: 194.193,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_recordChangeFee_buyer",
      isText: false,
      type: "checkbox",
      top: 473.104,
      left: 194.767,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_recordChangeFee_seller",
      isText: false,
      type: "checkbox",
      top: 473.104,
      left: 249.669,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_recordChangeFee_half",
      isText: false,
      type: "checkbox",
      top: 473.104,
      left: 299.391,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_recordChangeFee_na",
      isText: false,
      type: "checkbox",
      top: 473.104,
      left: 510.598,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_assessments_buyer",
      isText: false,
      type: "checkbox",
      top: 513.447,
      left: 194.711,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_assessments_seller",
      isText: false,
      type: "checkbox",
      top: 513.447,
      left: 249.613,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_assessments_half",
      isText: false,
      type: "checkbox",
      top: 513.447,
      left: 299.335,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_assessments_na",
      isText: false,
      type: "checkbox",
      top: 513.447,
      left: 510.542,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_otherFees_buyer",
      isText: false,
      type: "checkbox",
      top: 539.288,
      left: 194.137,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_otherFees_seller",
      isText: false,
      type: "checkbox",
      top: 539.288,
      left: 249.038,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_otherFees_half",
      isText: false,
      type: "checkbox",
      top: 539.288,
      left: 298.761,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_otherFees_na",
      isText: false,
      type: "checkbox",
      top: 539.288,
      left: 509.968,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_localTransferTax_buyer",
      isText: false,
      type: "checkbox",
      top: 575.102,
      left: 194.767,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_localTransferTax_seller",
      isText: false,
      type: "checkbox",
      top: 575.102,
      left: 249.669,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_localTransferTax_half",
      isText: false,
      type: "checkbox",
      top: 575.102,
      left: 299.391,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_localTransferTax_na",
      isText: false,
      type: "checkbox",
      top: 575.102,
      left: 510.598,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_salesUseTax_buyer",
      isText: false,
      type: "checkbox",
      top: 600.979,
      left: 194.193,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_salesUseTax_seller",
      isText: false,
      type: "checkbox",
      top: 600.979,
      left: 249.095,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_salesUseTax_half",
      isText: false,
      type: "checkbox",
      top: 600.979,
      left: 298.817,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_salesUseTax_na",
      isText: false,
      type: "checkbox",
      top: 600.979,
      left: 510.024,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_privateTransferFee_buyer",
      isText: false,
      type: "checkbox",
      top: 640.286,
      left: 194.137,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_privateTransferFee_seller",
      isText: false,
      type: "checkbox",
      top: 640.286,
      left: 249.038,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_privateTransferFee_half",
      isText: false,
      type: "checkbox",
      top: 640.286,
      left: 298.761,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_privateTransferFee_na",
      isText: false,
      type: "checkbox",
      top: 640.286,
      left: 509.968,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 13,
      name: "cb_utilityTransferFee_buyer",
      isText: false,
      type: "checkbox",
      top: 737.0848,
      left: 195.117,
      width: 14.571,
      height: 14.5714,
    },
    {
      page: 13,
      name: "cb_utilityTransferFee_seller",
      isText: false,
      type: "checkbox",
      top: 737.0848,
      left: 250.018,
      width: 14.572,
      height: 14.5714,
    },
    {
      page: 13,
      name: "cb_utilityTransferFee_half",
      isText: false,
      type: "checkbox",
      top: 737.0848,
      left: 299.74,
      width: 14.572,
      height: 14.5714,
    },
    {
      page: 13,
      name: "cb_utilityTransferFee_na",
      isText: false,
      type: "checkbox",
      top: 737.0848,
      left: 510.948,
      width: 14.571,
      height: 14.5714,
    },
    {
      page: 13,
      name: "cb_waterTransferFee_buyer",
      isText: false,
      type: "checkbox",
      top: 706.9882,
      left: 195.578,
      width: 14.572,
      height: 14.5714,
    },
    {
      page: 13,
      name: "cb_waterTransferFee_seller",
      isText: false,
      type: "checkbox",
      top: 706.9882,
      left: 250.48,
      width: 14.571,
      height: 14.5714,
    },
    {
      page: 13,
      name: "cb_waterTransferFee_half",
      isText: false,
      type: "checkbox",
      top: 706.9882,
      left: 300.202,
      width: 14.572,
      height: 14.5714,
    },
    {
      page: 13,
      name: "cb_waterTransferFee_na",
      isText: false,
      type: "checkbox",
      top: 706.9882,
      left: 511.41,
      width: 14.571,
      height: 14.5714,
    },
    {
      page: 13,
      name: "cb_waterDistrict",
      isText: false,
      type: "checkbox",
      top: 680.021,
      left: 228.895,
      width: 14.571,
      height: 14.5713,
    },
    {
      page: 13,
      name: "cb_smallDomesticWaterCompany",
      isText: false,
      type: "checkbox",
      top: 694.0052,
      left: 228.895,
      width: 14.571,
      height: 14.5714,
    },
    {
      page: 13,
      name: "cb_waterStockCert",
      isText: false,
      type: "checkbox",
      top: 680.057,
      left: 71.9591,
      width: 14.5714,
      height: 14.5712,
    },
    {
      page: 13,
      name: "cb_augmentationMembership",
      isText: false,
      type: "checkbox",
      top: 693.4873,
      left: 71.9591,
      width: 14.5714,
      height: 14.5714,
    },
    {
      page: 13,
      name: "cb_closingServicesFee_other",
      isText: false,
      type: "checkbox",
      top: 379.875,
      left: 217.5,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 13,
      name: "cb_waterTransfer_other",
      isText: false,
      type: "checkbox",
      top: 693.0297,
      left: 406.354,
      width: 14.571,
      height: 14.5714,
    },
    {
      page: 14,
      name: "undefined_9",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 304.8,
      left: 182.64,
      width: 263.88,
      height: 12.24,
    },
    {
      page: 14,
      name: "tf_otherProrations",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 368.16,
      left: 462.72,
      width: 110.88,
      height: 12.24,
    },
    {
      page: 14,
      name: "Association Assessments are subject to change as provided in the Governing Documents",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 486.48,
      left: 50.4,
      width: 159.12,
      height: 12.24,
    },
    {
      page: 14,
      name: "per day or any part of a day",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 539.04,
      left: 375.36,
      width: 73.32,
      height: 12.24,
    },
    {
      page: 14,
      name: "cb_buyerNotOccupyAsPrimary",
      isText: false,
      type: "checkbox",
      top: 574.97,
      left: 78.0621,
      width: 14.5714,
      height: 14.571,
    },
    {
      page: 14,
      name: "cb_postClosingOccAgreement",
      isText: false,
      type: "checkbox",
      top: 593.097,
      left: 49.5754,
      width: 14.5714,
      height: 14.572,
    },
    {
      page: 14,
      name: "cb_associationAssessments_buyer",
      isText: false,
      type: "checkbox",
      top: 438.92,
      left: 401.887,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 14,
      name: "cb_associationAssessments_seller",
      isText: false,
      type: "checkbox",
      top: 438.92,
      left: 446.948,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 14,
      name: "cb_rents_accrued",
      isText: false,
      type: "checkbox",
      top: 323.419,
      left: 315.391,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 14,
      name: "cb_rents_actuallyReceived",
      isText: false,
      type: "checkbox",
      top: 321.865,
      left: 190.049,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 14,
      name: "cb_taxes_other",
      isText: false,
      type: "checkbox",
      top: 305.291,
      left: 139.291,
      width: 14.572,
      height: 14.572,
    },
    {
      page: 14,
      name: "cb_taxesPrecedingYear",
      isText: false,
      type: "checkbox",
      top: 279.338,
      left: 187.404,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 14,
      name: "cb_mostRecentMillLevy",
      isText: false,
      type: "checkbox",
      top: 279.282,
      left: 463.41,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 14,
      name: "cb_isForeign",
      isText: false,
      type: "checkbox",
      top: 111.894,
      left: 512.65,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 15,
      name: "cb_specificPerformance",
      isText: false,
      type: "checkbox",
      top: 536.124,
      left: 49.0574,
      width: 14.5715,
      height: 14.571,
    },
    {
      page: 16,
      name: "tf_electronicNotice_or",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 654.24,
      left: 405.36,
      width: 168.0,
      height: 12.48,
    },
    {
      page: 17,
      name: "ta_additionalProvisions",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 241.959,
      left: 49.6971,
      width: 520.8519,
      height: 367.957,
    },
    {
      page: 17,
      name: "ta_postClosingOccAgreement",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 658.618,
      left: 46.6564,
      width: 520.8516,
      height: 26.117,
    },
    {
      page: 17,
      name: "ta_docsNotPartOfContract",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 694.412,
      left: 47.2306,
      width: 520.8514,
      height: 56.6752,
    },
    {
      page: 18,
      name: "Buyers Name 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 102.84,
      left: 121.92,
      width: 184.92,
      height: 11.4,
    },
    {
      page: 18,
      name: "Buyers Name 1_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 102.84,
      left: 393.12,
      width: 186.6,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_buyer1_address1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 159.96,
      left: 136.32,
      width: 170.52,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_buyer1_address2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 173.4,
      left: 135.36,
      width: 171.48,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_buyer2_address1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 159.96,
      left: 410.64,
      width: 169.08,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_buyer2_address2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 173.4,
      left: 409.92,
      width: 169.8,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_buyer1_phone",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 185.4,
      left: 136.32,
      width: 170.52,
      height: 9.96,
    },
    {
      page: 18,
      name: "tf_buyer2_phone",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 185.4,
      left: 410.64,
      width: 169.08,
      height: 9.96,
    },
    {
      page: 18,
      name: "tf_buyer1_Email Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 207.48,
      left: 135.6,
      width: 171.0,
      height: 10.44,
    },
    {
      page: 18,
      name: "tf_buyer2_Email Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 207.48,
      left: 410.04,
      width: 169.44,
      height: 10.44,
    },
    {
      page: 18,
      name: "Sellers Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 251.16,
      left: 121.92,
      width: 184.92,
      height: 11.4,
    },
    {
      page: 18,
      name: "Sellers Name_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 251.16,
      left: 393.36,
      width: 186.36,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_seller1_address1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 300.84,
      left: 136.08,
      width: 170.76,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_seller1_Phone_No",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 324.84,
      left: 136.08,
      width: 170.76,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_seller2_address1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 300.84,
      left: 410.4,
      width: 169.32,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_seller2_phone",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 324.84,
      left: 410.4,
      width: 169.32,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_seller1_EmailAddress",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 348.84,
      left: 135.36,
      width: 171.48,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_seller2_Email Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 348.84,
      left: 404.4,
      width: 175.32,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_buyerAgentPaidBy_other",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 551.64,
      left: 505.68,
      width: 77.52,
      height: 11.4,
    },
    {
      page: 18,
      name: "Buyer Brokerage Firms Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 615.96,
      left: 180.96,
      width: 404.04,
      height: 11.4,
    },
    {
      page: 18,
      name: "Buyer Broker Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 639.96,
      left: 181.2,
      width: 403.8,
      height: 11.4,
    },
    {
      page: 18,
      name: "Buyer Brokerage Firms License",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 627.96,
      left: 180.96,
      width: 404.04,
      height: 11.4,
    },
    {
      page: 18,
      name: "Buyer Broker License",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 651.96,
      left: 181.2,
      width: 403.8,
      height: 11.4,
    },
    {
      page: 18,
      name: "cb_buyerBrokerAckReceiptEM_does",
      isText: false,
      type: "checkbox",
      top: 449.664,
      left: 95.6721,
      width: 14.5709,
      height: 14.571,
    },
    {
      page: 18,
      name: "cb_buyerBrokerAckReceiptEM_doesNot",
      isText: false,
      type: "checkbox",
      top: 450.314,
      left: 135.666,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 18,
      name: "cb_workingAs_buyerAgent",
      isText: false,
      type: "checkbox",
      top: 508.268,
      left: 196.209,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 18,
      name: "cb_workingAs_transactionBroker",
      isText: false,
      type: "checkbox",
      top: 508.842,
      left: 287.422,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 18,
      name: "cb_paidBy_ListingBrokerageFirm",
      isText: false,
      type: "checkbox",
      top: 550.795,
      left: 298.817,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 18,
      name: "cb_paidBy_buyer",
      isText: false,
      type: "checkbox",
      top: 551.831,
      left: 416.907,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 18,
      name: "cb_paidBy_other",
      isText: false,
      type: "checkbox",
      top: 550.831,
      left: 461.45,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 18,
      name: "cb_buyer_customer",
      isText: false,
      type: "checkbox",
      top: 523.862,
      left: 48.6519,
      width: 14.5714,
      height: 14.571,
    },
    {
      page: 18,
      name: "tf_brokerBuyerAddress2a",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 714.4521,
      left: 182.292,
      width: 404.04,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_brokerBuyerPhone",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 726.4521,
      left: 182.532,
      width: 403.8,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_brokerBuyerEmail",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 738.4521,
      left: 182.532,
      width: 403.8,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_buyerBrokerAddressLine1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 700.2524,
      left: 181.376,
      width: 403.8,
      height: 11.4,
    },
    {
      page: 18,
      name: "tf_seller2_address2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 313.723,
      left: 410.58,
      width: 169.32,
      height: 11.4,
    },
    {
      page: 18,
      name: "signature_buyerBrokerSignature",
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 670.189,
      left: 181.14,
      width: 174.03,
      height: 11.937,
    },
    {
      page: 18,
      name: "date_buyerBrokerSignatureDate",
      fontSize: 9,
      type: "date",
      top: 672.873,
      left: 455.468,
      width: 127.324,
      height: 9.79,
    },
    {
      page: 18,
      name: "tf_seller1_address2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 313.186,
      left: 136.069,
      width: 170.76,
      height: 11.4,
    },
    {
      page: 19,
      name: "tf_sellerAgentPaidBy_other",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 216.6,
      left: 431.16,
      width: 149.76,
      height: 11.16,
    },
    {
      page: 19,
      name: "Seller Brokerage Firms Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 239.88,
      left: 180.96,
      width: 404.04,
      height: 11.16,
    },
    {
      page: 19,
      name: "Seller Broker Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 263.88,
      left: 181.2,
      width: 403.8,
      height: 11.16,
    },
    {
      page: 19,
      name: "Seller Brokerage Firms License",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 251.88,
      left: 180.96,
      width: 404.04,
      height: 11.16,
    },
    {
      page: 19,
      name: "Seller Broker License",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 275.64,
      left: 181.2,
      width: 403.8,
      height: 11.16,
    },
    {
      page: 19,
      name: "cb_brokerAckReceiptEM_does",
      isText: false,
      type: "checkbox",
      top: 104.68,
      left: 84.7953,
      width: 14.5714,
      height: 14.571,
    },
    {
      page: 19,
      name: "cb_brokerAckReceiptEM_doesNot",
      isText: false,
      type: "checkbox",
      top: 105.33,
      left: 124.789,
      width: 14.571,
      height: 14.572,
    },
    {
      page: 19,
      name: "cb_sellerAgentWorkingAs_Agent",
      isText: false,
      type: "checkbox",
      top: 175.284,
      left: 196.332,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 19,
      name: "cb_sellerAgentWorkingAs_transactionBroker",
      isText: false,
      type: "checkbox",
      top: 174.858,
      left: 271.545,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 19,
      name: "cb_SellerAgentPaidBy_seller",
      isText: false,
      type: "checkbox",
      top: 215.616,
      left: 306.622,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 19,
      name: "cb_SellerAgentPaidBy_buyer",
      isText: false,
      type: "checkbox",
      top: 215.652,
      left: 346.985,
      width: 14.572,
      height: 14.571,
    },
    {
      page: 19,
      name: "cb_SellerAgentPaidBy_other",
      isText: false,
      type: "checkbox",
      top: 215.652,
      left: 393.6,
      width: 14.571,
      height: 14.571,
    },
    {
      page: 19,
      name: "tf_brokerSellerAddress1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 334.715,
      left: 180.738,
      width: 404.04,
      height: 11.4,
    },
    {
      page: 19,
      name: "tf_brokerSellerAddress2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 346.715,
      left: 180.738,
      width: 404.04,
      height: 11.4,
    },
    {
      page: 19,
      name: "tf_brokerSellerPhone",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 358.715,
      left: 180.978,
      width: 403.8,
      height: 11.4,
    },
    {
      page: 19,
      name: "tf_brokerSellerEmail",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 370.715,
      left: 180.978,
      width: 403.8,
      height: 11.4,
    },
    {
      page: 19,
      name: "cb_sellerAgentWorkingAs_Customer",
      isText: false,
      type: "checkbox",
      top: 191.862,
      left: 54.8671,
      width: 14.5715,
      height: 14.572,
    },
  ];
}
