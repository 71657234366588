import React from "react";
import EmailsListItem from "./EmailListItem";

export default function EmailsList({ emails, column, direction }) {
  return (
    <>
      {emails &&
        emails.length !== 0 &&
        emails.map((email) => (
          <EmailsListItem email={email} key={`List${email.id}`} />
        ))}
    </>
  );
}
