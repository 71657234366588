export function addendumCoastalAreaProperty() {
return [   //2024 Release 2024-02-18 22:32:11
{
   page: 0,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 155.16,
   left: 54,
   width: 504.12,
   height: 11.16
}
,
{
   page: 0,
   name: "described in and subject to this contract except 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 381.12,
   left: 315.72,
   width: 235.32,
   height: 11.16
}
,
{
   page: 0,
   name: "described in and subject to this contract except 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 393.36,
   left: 72,
   width: 479.04,
   height: 11.04
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 405.36,
   left: 72,
   width: 479.04,
   height: 11.16
}
] }
