export function farmRanchRecLandRemovalofLivestock() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 106.63,
   left: 31.5,
   width: 496.26,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 129.04,
   left: 31.5,
   width: 515.7,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 152.45,
   left: 280.8,
   width: 199.008,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 175.525,
   left: 211.4,
   width: 369.1,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 198.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 221.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 244.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 267.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 290.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 313.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 336.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 25", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 359.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 382.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 405.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 428.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 451.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 474.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 497.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 32", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 520.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 544.1,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.6,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 35", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 589.56,
   left: 31.5,
   width: 549.0,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 36", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 612.52,
   left: 31.5,
   width: 549.0,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 37", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 635.48,
   left: 31.5,
   width: 549.0,
   height: 13.50
}
,
{
   page: 0,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 676.947,
   left: 30.6798,
   width: 181.0782,
   height: 14.430
}
,
{
   page: 0,
   name: "Text Field 38", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 679.12,
   left: 210.6,
   width: 54.72,
   height: 13.50
}
,
{
   page: 0,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 675.709,
   left: 340.771,
   width: 181.078,
   height: 14.430
}
,
{
   page: 0,
   name: "Text Field 40", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 679.12,
   left: 521.82,
   width: 54.72,
   height: 13.50
}
,
{
   page: 0,
   name: "Text12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 704.3967,
   left: 30.7855,
   width: 181.0785,
   height: 14.4297
}
,
{
   page: 0,
   name: "Text Field 39", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 707.128,
   left: 210.6,
   width: 54.72,
   height: 13.500
}
,
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 704.3967,
   left: 341.169,
   width: 181.078,
   height: 14.4297
}
,
{
   page: 0,
   name: "Text Field 41", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 707.128,
   left: 521.82,
   width: 54.72,
   height: 13.500
}
] }
