import { createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit'

const asyncAdapter = createEntityAdapter()

const initialState = asyncAdapter.getInitialState({
  loading: false,
  error: null,
  initialized: false
})

const asyncSlice = createSlice({
  name: 'async',
  initialState,
  reducers: {
    appLoaded(state, action) {
      state.initialized = true
    },
    asyncActionStart(state, action) {
      state.loading = true
      state.error = null
    },
    asyncActionFinish(state, action) {
      state.loading = false
    },
    asyncActionError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  }
});

export const { appLoaded, asyncActionStart, asyncActionFinish, asyncActionError } = asyncSlice.actions

export default asyncSlice.reducer
