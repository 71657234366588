export function sellersCounteroffer() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 77.4,
   left: 437.04,
   width: 100.44,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 77.4,
   left: 554.76,
   width: 25.20,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 118.44,
   left: 31.68,
   width: 550.25,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 134.28,
   left: 31.536,
   width: 550.214,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 150.12,
   left: 31.536,
   width: 465.300,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 165.464,
   left: 136.08,
   width: 445.67,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 186.344,
   left: 72.36,
   width: 222.48,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 243.504,
   left: 166.608,
   width: 410.328,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 261.504,
   left: 31.5,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 279.792,
   left: 31.5,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 297.72,
   left: 31.536,
   width: 545.436,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 315.288,
   left: 131.616,
   width: 445.320,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 333.288,
   left: 31.5,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 351.576,
   left: 31.5,
   width: 545.436,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 439.056,
   left: 277.48,
   width: 130.32,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 439.056,
   left: 424.72,
   width: 35.64,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 439.056,
   left: 474.28,
   width: 35.64,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 439.056,
   left: 511.84,
   width: 20.16,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 464.176,
   left: 31.5,
   width: 260.46,
   height: 13.500
}
,
{
   page: 0,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 463.94,
   left: 309.992,
   width: 246.872,
   height: 12.051
}
,
{
   page: 0,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 488.296,
   left: 31.5,
   width: 260.46,
   height: 13.500
}
,
{
   page: 0,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 487.762,
   left: 309.61,
   width: 246.872,
   height: 12.051
}
,
{
   page: 0,
   name: "Text Field 26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 554.832,
   left: 110.016,
   width: 295.344,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 554.832,
   left: 422.64,
   width: 55.08,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 554.832,
   left: 495.36,
   width: 45.00,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 554.832,
   left: 545.4,
   width: 19.8,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 570.6,
   left: 31.5,
   width: 260.46,
   height: 13.5
}
,
{
   page: 0,
   name: "Text12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 571.02,
   left: 310.657,
   width: 246.872,
   height: 12.051
}
,
{
   page: 0,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 594.72,
   left: 31.5,
   width: 260.46,
   height: 13.50
}
,
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 595.107,
   left: 310.657,
   width: 246.872,
   height: 12.051
}
,
{
   page: 0,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 647.208,
   left: 31.5,
   width: 27.108,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 32", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 647.208,
   left: 74.448,
   width: 27.108,
   height: 13.500
}
,
{
   page: 0,
   name: "Check Box 1", 
   isText: false,
   type: "checkbox",
   top: 666.432,
   left: 31.968,
   width: 9.288,
   height: 9.288
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 685.44,
   left: 31.968,
   width: 9.288,
   height: 9.288
}
] }
