export function condominiumResaleCertificate() {
return [   //2024 Release 2024-02-18 22:29:03
{
   page: 0,
   name: "Section 82157 Texas Property Code", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 96.12,
   left: 321.72,
   width: 27.72,
   height: 11.04
}
,
{
   page: 0,
   name: "CONDOMINIUM RESALE CERTIFICATE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 96.12,
   left: 409.32,
   width: 39.00,
   height: 11.04
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 96.12,
   left: 467.4,
   width: 84.6,
   height: 11.04
}
,
{
   page: 0,
   name: "Condominium Certificate concerning Condominium Unit", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 108.12,
   left: 52.32,
   width: 202.56,
   height: 11.04
}
,
{
   page: 0,
   name: "of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 108.12,
   left: 434.52,
   width: 117.48,
   height: 11.04
}
,
{
   page: 0,
   name: "a condominium project located at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 120.644,
   left: 52.32,
   width: 228.72,
   height: 11.040
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 120.644,
   left: 392.04,
   width: 156.60,
   height: 11.040
}
,
{
   page: 0,
   name: "Texas on behalf of the condominium owners association", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 132.12,
   left: 101.52,
   width: 153.36,
   height: 11.04
}
,
{
   page: 0,
   name: "does", 
   isText: false,
   type: "checkbox",
   top: 165.72,
   left: 148.68,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "does not contain a right of first refusal or other restraint that restricts", 
   isText: false,
   type: "checkbox",
   top: 165.72,
   left: 187.92,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "the right to transfer the Unit If a right of first refusal or other restraint exists see Section", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 188.28,
   left: 66.36,
   width: 40.08,
   height: 10.92
}
,
{
   page: 0,
   name: "B The periodic common expense assessment for the Unit is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 209.127,
   left: 364.08,
   width: 66.36,
   height: 11.040
}
,
{
   page: 0,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 208.604,
   left: 453.84,
   width: 93.60,
   height: 11.040
}
,
{
   page: 0,
   name: "is", 
   isText: false,
   type: "checkbox",
   top: 229.68,
   left: 99.72,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "is not a common expense or special assessment due and unpaid by the Seller to the", 
   isText: false,
   type: "checkbox",
   top: 229.68,
   left: 126.36,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "Association The total unpaid amount is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 240.12,
   left: 270.48,
   width: 74.52,
   height: 11.04
}
,
{
   page: 0,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 240.12,
   left: 399.24,
   width: 148.20,
   height: 11.04
}
,
{
   page: 0,
   name: "are", 
   isText: false,
   type: "checkbox",
   top: 261.72,
   left: 147.84,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "are not payable by Seller to the Association The total unpaid amount is", 
   isText: false,
   type: "checkbox",
   top: 261.72,
   left: 179.4,
   width: 10.8,
   height: 8.40
}
,
{
   page: 0,
   name: "D Other amounts", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 272.16,
   left: 72.24,
   width: 91.069,
   height: 11.04
}
,
{
   page: 0,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 272.16,
   left: 215.76,
   width: 331.68,
   height: 11.04
}
,
{
   page: 0,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 292.2,
   left: 465.12,
   width: 82.32,
   height: 11.04
}
,
{
   page: 0,
   name: "E Capital expenditures approved by the Association for the next 12 months are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 312.12,
   left: 263.52,
   width: 80.52,
   height: 11.04
}
,
{
   page: 0,
   name: "of this amount", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 312.12,
   left: 431.88,
   width: 115.56,
   height: 11.04
}
,
{
   page: 0,
   name: "F Reserves for capital expenditures are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 324.12,
   left: 187.32,
   width: 360.12,
   height: 11.04
}
,
{
   page: 0,
   name: "G The current operating budget and balance sheet of the Association is attached", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 364.604,
   left: 399.6,
   width: 147.84,
   height: 11.040
}
,
{
   page: 0,
   name: "are_2", 
   isText: false,
   type: "checkbox",
   top: 385.68,
   left: 100.2,
   width: 10.8,
   height: 8.40
}
,
{
   page: 0,
   name: "are not any suits pending against the Association The nature of the suits is", 
   isText: false,
   type: "checkbox",
   top: 385.68,
   left: 132.6,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "I There", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 396.12,
   left: 65.88,
   width: 481.92,
   height: 11.04
}
,
{
   page: 0,
   name: "does_2", 
   isText: false,
   type: "checkbox",
   top: 417.72,
   left: 146.28,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "does not provide insurance coverage for the benefit of unit owners as per", 
   isText: false,
   type: "checkbox",
   top: 417.72,
   left: 183,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "has", 
   isText: false,
   type: "checkbox",
   top: 449.64,
   left: 120.84,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "has no knowledge of alterations or improvements to the Unit or to the limited", 
   isText: false,
   type: "checkbox",
   top: 449.64,
   left: 152.64,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "common elements assigned to the Unit or any portion of the project that violate any provision of the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 472.2,
   left: 409.56,
   width: 142.44,
   height: 10.92
}
,
{
   page: 0,
   name: "Declaration bylaws or rules of the Association Known violations are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 484.2,
   left: 65.88,
   width: 481.44,
   height: 10.92
}
,
{
   page: 0,
   name: "has_2", 
   isText: false,
   type: "checkbox",
   top: 505.68,
   left: 121.44,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "has not received notice from a governmental authority concerning violations", 
   isText: false,
   type: "checkbox",
   top: 505.68,
   left: 153.36,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "of health or building codes with respect to the Unit the limited common elements assigned to the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 528.12,
   left: 446.64,
   width: 105.36,
   height: 11.04
}
,
{
   page: 0,
   name: "Unit or any other portion of the condominium project Notices received are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 540.12,
   left: 65.88,
   width: 481.44,
   height: 11.04
}
,
{
   page: 0,
   name: "undefined_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 560.16,
   left: 452.04,
   width: 99.96,
   height: 11.04
}
,
{
   page: 0,
   name: "MThe remaining term of any leasehold estate that affects the condominium is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 572.16,
   left: 436.32,
   width: 115.68,
   height: 11.04
}
,
{
   page: 0,
   name: "and the provisions governing an extension or a renewal of the lease are 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 584.16,
   left: 65.88,
   width: 486.12,
   height: 11.04
}
,
{
   page: 0,
   name: "and the provisions governing an extension or a renewal of the lease are 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 596.16,
   left: 65.88,
   width: 481.56,
   height: 11.04
}
,
{
   page: 0,
   name: "undefined_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 616.56,
   left: 256.44,
   width: 297.12,
   height: 11.04
}
,
{
   page: 0,
   name: "Name of Agent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 644.16,
   left: 67.44,
   width: 486.12,
   height: 11.04
}
,
{
   page: 0,
   name: "undefined_9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 675.72,
   left: 67.44,
   width: 189.12,
   height: 11.04
}
,
{
   page: 0,
   name: "Mailing Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 675.72,
   left: 324,
   width: 229.56,
   height: 11.04
}
,
{
   page: 0,
   name: "Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 701.64,
   left: 67.44,
   width: 486.12,
   height: 11.04
}
,
{
   page: 1,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 54.12,
   left: 43.32,
   width: 522.12,
   height: 12.84
}
,
{
   page: 1,
   name: "Description 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 143.684,
   left: 69.72,
   width: 137.88,
   height: 14.476
}
,
{
   page: 1,
   name: "Paid To 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 144.207,
   left: 221.16,
   width: 151.68,
   height: 13.953
}
,
{
   page: 1,
   name: "Amount 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 144.207,
   left: 387.0,
   width: 151.68,
   height: 13.953
}
,
{
   page: 1,
   name: "Description 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 166.091,
   left: 69.72,
   width: 137.88,
   height: 13.429
}
,
{
   page: 1,
   name: "Paid To 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 164.52,
   left: 221.16,
   width: 151.68,
   height: 15.00
}
,
{
   page: 1,
   name: "Amount 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 165.567,
   left: 387.0,
   width: 151.68,
   height: 13.953
}
,
{
   page: 1,
   name: "undefined_10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 186.0,
   left: 69.72,
   width: 137.88,
   height: 13.724
}
,
{
   page: 1,
   name: "undefined_11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 186.524,
   left: 221.16,
   width: 151.68,
   height: 14.247
}
,
{
   page: 1,
   name: "undefined_12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 187.047,
   left: 387.524,
   width: 151.680,
   height: 13.200
}
,
{
   page: 1,
   name: "P Required contribution if any to the capital reserves account", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 209.204,
   left: 384.96,
   width: 151.44,
   height: 14.476
}
,
{
   page: 1,
   name: "Name of Association", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 373.571,
   left: 48.0,
   width: 513.12,
   height: 13.953
}
,
{
   page: 1,
   name: "Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 461.04,
   left: 89.52,
   width: 340.92,
   height: 15.00
}
,
{
   page: 1,
   name: "Title", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 486.327,
   left: 82.4836,
   width: 348.4804,
   height: 14.477
}
,
{
   page: 1,
   name: "Date", 
   fontSize: 9,
   type: "text",
   top: 510.687,
   left: 80.52,
   width: 349.92,
   height: 13.953
}
,
{
   page: 1,
   name: "Mailing Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 534.927,
   left: 139.08,
   width: 291.36,
   height: 13.953
}
,
{
   page: 1,
   name: "Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 559.287,
   left: 92.52,
   width: 337.92,
   height: 13.953
}
] }
