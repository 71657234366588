import { Form, Formik } from "formik";
import React from "react";
import { Button, Grid, Header, Label, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { updateUserPassword } from "../../../app/firestore/firebaseService";
import MyTextInput from "../../../app/common/form/MyTextInput";

export default function PartyChangePassword() {
  const { currentUser } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <div className="main-page-wrapper">
      <Grid centered stackable className="small bottom margin">
        <Grid.Column width={8}>
          <Header color="blue" as="h2">
            My Account
          </Header>
          <Segment clearing>
            {currentUser.providerId === "password" && (
              <>
                <Header color="blue" content="Change Password" />
                <Formik
                  initialValues={{ newPassword1: "", newPassword2: "" }}
                  validationSchema={Yup.object({
                    newPassword1: Yup.string().required("Password is required"),
                    newPassword2: Yup.string().required("Password is required").oneOf(
                      [Yup.ref("newPassword1"), null],
                      "Passwords do not match"
                    ),
                  })}
                  onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                      await updateUserPassword(values);
                      toast.success("Password successfully changed");
                      setSubmitting(false);
                    } catch (error) {
                      setErrors({ auth: error.message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ errors, isSubmitting, isValid, dirty }) => (
                    <Form className="ui form">
                      <MyTextInput
                        name="newPassword1"
                        type="password"
                        placeholder="New Password"
                        autoComplete="new-password"
                      />
                      <MyTextInput
                        name="newPassword2"
                        type="password"
                        placeholder="Confirm Password"
                        autoComplete="new-password"
                      />{" "}
                      {errors.auth && (
                        <Label
                          basic
                          color="red"
                          style={{ marginBottom: 10 }}
                          content={errors.auth}
                        />
                      )}
                      <Button
                        style={{ display: "block" }}
                        type="submit"
                        disabled={!isValid || isSubmitting || !dirty}
                        loading={isSubmitting}
                        className={isMobile ? "fluid large" : "large"}
                        primary
                        content="Update Password"
                      />
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}