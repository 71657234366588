import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import { changePageScaleFill } from "../../../app/annots/annotSlice";

export default function FormTemplateFillActionButtons() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageScaleFill } = useSelector((state) => state.annot);
  function closeDocument() {
    navigate(`/formTemplates/`);
  }

  async function saveAnnots() {
    toast.success("Document successfully saved");
  }

  function handleZoomIn() {
    if (pageScaleFill < 4) {
      dispatch(changePageScaleFill(pageScaleFill * 1.25));
    }
  }

  function handleZoomOut() {
    if (pageScaleFill > 0.5) {
      dispatch(changePageScaleFill(pageScaleFill / 1.25));
    }
  }

  return (
    <>
      <div
        className="large horizontal padding background-white"
        style={{
          top: "auto",
          bottom: "0",
          position: "fixed",
          width: "100%",
          height: "80px",
          borderTop: "1px solid #e8e8e8",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            float: "left",
          }}
        >
          <Icon
            size="large"
            color="grey"
            name="minus circle"
            onClick={() => handleZoomOut()}
            style={{ cursor: "pointer" }}
          />
          <p
            className="zero bottom margin"
            style={{
              marginLeft: "3px",
              marginRight: "6px",
              fontWeight: "bold",
            }}
          >
            {Math.round(pageScaleFill * 100)}%
          </p>
          <Icon
            size="large"
            color="grey"
            name="plus circle"
            onClick={() => handleZoomIn()}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div
          className="large right margin"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            float: "right",
          }}
        >
          <Button
            onClick={() => closeDocument()}
            content="Close"
            style={{ marginRight: "10px" }}
          />
          <Button
            primary
            onClick={() => saveAnnots()}
            content="Save"
            style={{ marginRight: "10px" }}
          />
        </div>
      </div>
    </>
  );
}
