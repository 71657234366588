export function fHALoan() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 112.184,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 196.28,
   left: 469.08,
   width: 111.42,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 341.0,
   left: 163.44,
   width: 53.64,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 627.992,
   left: 278.64,
   width: 111.168,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 194.72,
   left: 422.266,
   width: 158.234,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 284.488,
   left: 181.584,
   width: 394.848,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 303.976,
   left: 53.64,
   width: 522.792,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 44", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 321.476,
   left: 53.64,
   width: 522.792,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 45", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 339.976,
   left: 53.64,
   width: 522.792,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 46", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 357.476,
   left: 53.64,
   width: 522.792,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 47", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 375.976,
   left: 53.64,
   width: 522.792,
   height: 13.500
}
] }
