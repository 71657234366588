import React from "react";
import { Grid, Divider } from "semantic-ui-react";
import ClausesActionButtons from "../ClausesActionButtons";

export default function ClausesList({ clauses }) {
  return (
    <>
      {clauses?.length !== 0 &&
        clauses.map((clause) => (
          <Grid.Row key={clause.id} className="small top padding">
            <Divider />
            <Grid.Column width={2}>
              <b>{clause.title}</b>
            </Grid.Column>
            <Grid.Column width={13} style={{ whiteSpace: "pre-line" }}>
              {clause.text}
            </Grid.Column>
            <Grid.Column width={1}>
              <ClausesActionButtons clause={clause} />
            </Grid.Column>
          </Grid.Row>
        ))}
    </>
  );
}
