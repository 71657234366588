export function cBMoldDisclosure() {
    return [  //2023
    {
      page: 0,
      name: "logo",
      isText: false,
      type: "logo",
      top: 28.673,
      left: 53,
      width: 253,
      height: 43.2
   }
   ,
    {
       page: 0,
       name: "Broker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 12,
       top: 662.4,
       left: 80.04,
       width: 270.12,
       height: 24.12
    }
    ,
    {
       page: 0,
       name: "Date_5", 
       fontSize: 9,
       type: "date",
       top: 662.4,
       left: 378,
       width: 64.08,
       height: 24.12
    }
    ,
    {
       page: 0,
       name: "Seller Declined", 
       isText: false,
       type: "checkbox",
       top: 635.799,
       left: 65.4738,
       width: 13,
       height: 13
    }
    ,
    {
       page: 0,
       name: "Buyer Declined", 
       isText: false,
       type: "checkbox",
       top: 636.807,
       left: 334.514,
       width: 13,
       height: 13
    }
    ] }
    