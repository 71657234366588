import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button, Label } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signInWithEmail } from "../../../app/firestore/firebaseService";
import {
  getUserProfileFromDb,
  updateLastSignIn,
} from "../../../app/firestore/firestoreService";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { openModal } from "../../../app/common/modals/modalSlice";
import MyEmailInput from "../../../app/common/form/MyEmailInput";

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            var result = await signInWithEmail(values);
            const profile = await getUserProfileFromDb(result?.user?.uid);
            setSubmitting(false);
            updateLastSignIn(result.user.uid);
            if (profile.role === "tc") {
              navigate("overviewTc");
            } else if (profile.role === "manager") {
              navigate("overviewManager");
            } else if (profile.type === "party") {
              navigate("/overviewParty");
            } else {
              navigate("overview");
            }
          } catch (error) {
            setErrors({ auth: "Problem with username or password" });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MyEmailInput
              name="email"
              placeholder="Email address"
              data-test="email"
            />
            <MyTextInput
              name="password"
              placeholder="Password"
              type="password"
              data-test="password"
            />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              primary
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              content="Login"
              className="large top margin medium bottom margin"
              data-test="login"
            />
          </Form>
        )}
      </Formik>
      <div className="small top margin text-center medium bottom margin">
        <p
          onClick={() =>
            dispatch(openModal({ modalType: "ResetPasswordForm" }))
          }
          style={{ color: "#4183c4", cursor: "pointer" }}
        >
          Forgot Password
        </p>
      </div>
    </>
  );
}
