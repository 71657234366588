export function gTARSalesContract() {
   return [   //2024 Release 2024-04-18 19:56:46
      {
         page: 0,
         name: "BuyerTenant Name Printed",
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 486,
         left: 162,
         width: 143.76,
         height: 14.88
      }
      ,
      {
         page: 0,
         name: "BuyerTenant Name Printed_2",
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 486.24,
         left: 413.52,
         width: 133.68,
         height: 14.88
      }
      ,
      {
         page: 0,
         name: "SellerLandlord Name Printed",
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 639.335,
         left: 167.28,
         width: 136.429,
         height: 14.225
      }
      ,
      {
         page: 0,
         name: "SellerLandlord Name Printed_2",
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 638.68,
         left: 415.68,
         width: 140.858,
         height: 14.88
      }
      ,
      {
         page: 0,
         name: "Check Box1",
         isText: false,
         type: "checkbox",
         top: 185.286,
         left: 64.6141,
         width: 12.6423,
         height: 13.312
      }
      ,
      {
         page: 0,
         name: "Check Box2",
         isText: false,
         type: "checkbox",
         top: 250.918,
         left: 64.6141,
         width: 12.6423,
         height: 13.312
      }
      ,
      {
         page: 0,
         name: "Check Box3",
         isText: false,
         type: "checkbox",
         top: 283.734,
         left: 64.6141,
         width: 12.6423,
         height: 13.312
      }
      ,
      {
         page: 0,
         name: "Check Box4",
         isText: false,
         type: "checkbox",
         top: 315.872,
         left: 64.6141,
         width: 12.6423,
         height: 13.312
      }
      ,
      {
         page: 0,
         name: "Check Box5",
         isText: false,
         type: "checkbox",
         top: 334.294,
         left: 64.6141,
         width: 12.6423,
         height: 13.312
      }
      ,
      {
         page: 0,
         name: "Check Box6",
         isText: false,
         type: "checkbox",
         top: 378.495,
         left: 66.6141,
         width: 12.6423,
         height: 13.312
      }
      ,
      {
         page: 0,
         name: "Check Box7",
         isText: false,
         type: "checkbox",
         top: 418.678,
         left: 66.6141,
         width: 12.6423,
         height: 13.312
      }
      ,
      {
         page: 0,
         name: "Check Box8",
         isText: false,
         type: "checkbox",
         top: 439.439,
         left: 66.6141,
         width: 12.6423,
         height: 13.312
      }
      ,
      {
         page: 0,
         name: "Check Box9",
         isText: false,
         type: "checkbox",
         top: 582.429,
         left: 64.2747,
         width: 13.9817,
         height: 13.982
      }
      ,
      {
         page: 1,
         name: "Seller Financing",
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 244.32,
         left: 112.32,
         width: 114.44,
         height: 9.294
      }
]}