import { Formik, Form, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { addTransactionToDb } from "../../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";
import MyRadioButtons from "../../../app/common/form/MyRadioButtons";
import MyCheckbox from "../../../app/common/form/MyCheckbox";
import { agentRepresentsOptions } from "../../../app/common/categoryData/categoryOptions";
import MySelectInput from "../../../app/common/form/MySelectInput";
import {
  createPeopleOptions,
  filterPeopleByName,
} from "../../../app/common/util/util";
import FormAddressAutofillGoogle from "../../../app/common/form/FormAddressAutofillGoogle";
import FormParty from "../../../app/common/form/FormParty";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MyTextArea from "../../../app/common/form/MyTextArea";
import TransactionImportMlsForm from "./TransactionImportMlsForm";
import TransactionImportLegalAddressForm from "./TransactionImportLegalAddressForm";

export default function TransactionForm({ transactionId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { transActive } = useSelector((state) => state.transaction);
  const { forms } = useSelector((state) => state.doc);
  const { people } = useSelector((state) => state.people);
  const { currentUserProfile, agentsForAssistant } = useSelector(
    (state) => state.profile
  );
  const [mlsData, setMlsData] = useState(null);
  const [legalAddressData, setLegalAddressData] = useState(null);

  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  const [agentOptions, setAgentOptions] = useState([]);
  const peopleOptions = createPeopleOptions(people, "Client");
  const isAssistant = currentUserProfile?.role === "assistant" ? true : false;
  const isTc = currentUserProfile?.role === "tc" ? true : false;

  let initialValues = {
    active: true,
    agentId: "",
    managerId: currentUserProfile.managerId || "",
    agentRepresents: "Buyer",
    client: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      isTrust: false,
      entityName: "",
    },
    clientSecondary: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      isTrust: false,
      entityName: "",
      exists: false,
    },
    address: {
      street: "",
      unit: "",
      city: "",
      state: "",
      zipcode: "",
    },
    propertyDetails: {
      county: "",
      legalDescription: "",
      inclusions: "",
      exclusions: "",
      yearBuilt: "",
    },
    title: "",
    addressGoogle: {
      address: "",
    },
    clientSelected: "",
    clientSecondarySelected: false,
    state: "No Offer",
    closingDateTime: "",
    contractDateTime: "",
    salesPrice: "",
    mlsNum: "",
    mlsNumbers: [],
    role: "Transaction",
    pic: "",
    owner: {
      firstName: "",
      middleName: "",
      lastName: "",
      isTrust: false,
      entityName: "",
    },
    owner2: {
      firstName: "",
      middleName: "",
      lastName: "",
      isTrust: false,
      entityName: "",
      exists: false,
    },
    hasTc: false,
  };

  useEffect(() => {
    if (!isAssistant) {
      return;
    } else {
      let agentList = [];
      agentsForAssistant.forEach((agent) => {
        const agentName = agent.firstName + " " + agent.lastName;
        agentList = [
          ...agentList,
          {
            key: agent.userId,
            value: agent.userId,
            text: agentName,
          },
        ];
      });
      setAgentOptions(agentList);
    }
  }, [isAssistant, agentsForAssistant]);

  if (transactionId) {
    initialValues = transActive.filter(
      (trans) => trans.id === transactionId
    )[0];
  }

  const [clientSecondaryExistsField, setClientSecondaryExistsField] = useState(
    initialValues.clientSecondary.exists ? true : false
  );
  const [clientIsTrustField, setClientIsTrustField] = useState(
    initialValues.client?.isTrust ? true : false
  );
  const [clientSecondaryIsTrustField, setClientSecondaryIsTrustField] =
    useState(initialValues.clientSecondary?.isTrust ? true : false);

  const validationSchema = Yup.object().shape({
    agentId: Yup.string().concat(
      isAssistant || isTc
        ? Yup.string().required("Must select an agent for the transaction")
        : Yup.string()
    ),
    agentRepresents: Yup.string().required("You must choose one"),
    title: Yup.string().required("You must name the transaction"),
    client: Yup.object().shape({
      email: Yup.string().email("Must be a valid email"),
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
    }),
    clientSecondary: Yup.object().shape({
      exists: Yup.boolean(),
      email: Yup.string().when("exists", {
        is: true,
        then: Yup.string().email("Must be a valid email"),
      }),
      firstName: Yup.string().when("exists", {
        is: true,
        then: Yup.string().required("First name is required"),
      }),
      lastName: Yup.string().when("exists", {
        is: true,
        then: Yup.string().required("Last name is required"),
      }),
      }),
    })
    .test({
      name: "notOneOf",
      test: function (values) {
        const isValid =
          (!values?.client?.email ||
            !values?.clientSecondary?.email ||
            values?.client?.email !== values?.clientSecondary?.email) &&
          values?.client?.email !== currentUserProfile.email &&
          values?.clientSecondary?.email !== currentUserProfile.email;
        if (isValid) return true;
        return this.createError({
          path: "client.email",
          message: "Emails can't be the same as another party",
        });
      },
    });

  function Watcher() {
    const { values, setFieldValue, touched } = useFormikContext();

    useEffect(() => {
      if (
        !touched.title &&
        (values.client?.lastName || values.address?.street)
      ) {
        const newTitle =
          values.client?.lastName && values.address?.street
            ? values.client?.lastName + " - " + values.address?.street
            : values.client?.street
            ? values.client?.street
            : values.client?.lastName;
        setFieldValue("title", newTitle);
      }
    }, [values, touched, setFieldValue]);

    useEffect(() => {
      if (values.clientSelected) {
        const selectedClient = filterPeopleByName(
          people,
          values.clientSelected?.split("|")[0],
          values.clientSelected?.split("|")[1]
        );
        if (selectedClient) {
          const clientFirstName = selectedClient.firstName
            ? selectedClient.firstName
            : "";
          setFieldValue("client.firstName", clientFirstName);
          const clientMiddleName = selectedClient.middleName
            ? selectedClient.middleName
            : "";
          setFieldValue("client.middleName", clientMiddleName);
          const clientLastName = selectedClient.lastName
            ? selectedClient.lastName
            : "";
          setFieldValue("client.lastName", clientLastName);
          const clientEmail = selectedClient.email ? selectedClient.email : "";
          setFieldValue("client.email", clientEmail);
          const clientPhone = selectedClient.phone ? selectedClient.phone : "";
          setFieldValue("client.phone", clientPhone);
          setFieldValue("clientSelected", "");
          if (selectedClient.isTrust && selectedClient.entityName) {
            setClientIsTrustField(true);
            setFieldValue("client.isTrust", true);
            setFieldValue("client.entityName", selectedClient.entityName);
          } else {
            setClientIsTrustField(false);
            setFieldValue("client.isTrust", false);
            setFieldValue("client.entityName", "");
          }
        }
      }
      if (values.clientSecondarySelected) {
        const selectedSecondaryClient = filterPeopleByName(
          people,
          values.clientSecondarySelected?.split("|")[0],
          values.clientSecondarySelected?.split("|")[1]
        );
        if (selectedSecondaryClient) {
          const clientSecondaryFirstName = selectedSecondaryClient.firstName
            ? selectedSecondaryClient.firstName
            : "";
          setFieldValue("clientSecondary.firstName", clientSecondaryFirstName);
          const clientSecondaryMiddleName = selectedSecondaryClient.middleName
            ? selectedSecondaryClient.middleName
            : "";
          setFieldValue(
            "clientSecondary.middleName",
            clientSecondaryMiddleName
          );
          const clientSecondaryLastName = selectedSecondaryClient.lastName
            ? selectedSecondaryClient.lastName
            : "";
          setFieldValue("clientSecondary.lastName", clientSecondaryLastName);
          const clientSecondaryEmail = selectedSecondaryClient.email
            ? selectedSecondaryClient.email
            : "";
          setFieldValue("clientSecondary.email", clientSecondaryEmail);
          const clientSecondaryPhone = selectedSecondaryClient.phone
            ? selectedSecondaryClient.phone
            : "";
          setFieldValue("clientSecondary.phone", clientSecondaryPhone);
          setFieldValue("clientSecondarySelected", "");
          if (
            selectedSecondaryClient.isTrust &&
            selectedSecondaryClient.entityName
          ) {
            setClientSecondaryIsTrustField(true);
            setFieldValue("clientSecondary.isTrust", true);
            setFieldValue(
              "clientSecondary.entityName",
              selectedSecondaryClient.entityName
            );
          } else {
            setClientSecondaryIsTrustField(false);
            setFieldValue("clientSecondary.isTrust", false);
            setFieldValue("clientSecondary.entityName", "");
          }
        }
      }
    }, [values.clientSelected, values.clientSecondarySelected, setFieldValue]);

    useEffect(() => {
      if (legalAddressData) {
        setFieldValue("propertyDetails.legalDescription", legalAddressData);
      }
    }, [setFieldValue]);

    useEffect(() => {
      if (mlsData) {
        if (mlsData.address?.street) {
          setFieldValue("address.street", mlsData.address.street);
        }
        if (mlsData.address?.unit) {
          setFieldValue("address.unit", mlsData.address.unit);
        }
        if (mlsData.address?.city) {
          setFieldValue("address.city", mlsData.address.city);
        }
        if (mlsData.address?.state) {
          setFieldValue("address.state", mlsData.address.state);
        }
        if (mlsData.address?.zipcode) {
          setFieldValue("address.zipcode", mlsData.address.zipcode);
        }
        if (mlsData.propertyDetails?.legalDescription) {
          setFieldValue(
            "propertyDetails.legalDescription",
            mlsData.propertyDetails.legalDescription
          );
        }
        if (mlsData.propertyDetails?.county) {
          setFieldValue(
            "propertyDetails.county",
            mlsData.propertyDetails.county
          );
        }
        if (mlsData.propertyDetails?.inclusions) {
          setFieldValue(
            "propertyDetails.inclusions",
            mlsData.propertyDetails.inclusions
          );
        }
        if (mlsData.propertyDetails?.exclusions) {
          setFieldValue(
            "propertyDetails.exclusions",
            mlsData.propertyDetails.exclusions
          );
        }
        if (mlsData.propertyDetails?.yearBuilt) {
          setFieldValue(
            "propertyDetails.yearBuilt",
            mlsData.propertyDetails.yearBuilt
          );
        }
        if (mlsData.pic) {
          setFieldValue("pic", mlsData.pic);
        }
      }
    }, [setFieldValue]);
    return null;
  }

  function formSelectAgent() {
    return (
      <>
        <Segment className="background-lightgrey">
          <div className="zero bottom margin">
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} computer={4}>
                  <h2 className="text blue">Transaction Agent</h2>
                  <MySelectInput
                    name="agentId"
                    placeholder="Select Agent"
                    options={agentOptions}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Segment>
      </>
    );
  }

  function formWhoRepresenting() {
    return (
      <Segment className="background-lightgrey">
        <div className="zero bottom margin">
          <Grid>
            <Grid.Column width={16}>
              <h2 className="text blue">I'm representing the</h2>
              <MyRadioButtons
                name="agentRepresents"
                placeholder=""
                options={agentRepresentsOptions}
              />
            </Grid.Column>
          </Grid>
        </div>
      </Segment>
    );
  }

  function formClients() {
    return (
      <Segment className="background-lightgrey">
        <div className="small bottom margin">
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <h2 className="text blue">Primary Client</h2>
                <p>Choose an existing client or create a new one.</p>
              </Grid.Column>
              <Grid.Column mobile={16} computer={4}>
                <MySelectInput
                  name="clientSelected"
                  placeholder="Select existing client"
                  options={peopleOptions}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <FormParty fieldKey="client." />
          <Grid>
            <Grid.Row className="tiny vertical padding">
              <Grid.Column width={16}>
                <MyCheckbox
                  name="client.isTrust"
                  label="The client is a company, trust, or other entity"
                  onClick={() => setClientIsTrustField(!clientIsTrustField)}
                />
              </Grid.Column>
            </Grid.Row>
            {clientIsTrustField && (
              <Grid.Row className="zero top padding small bottom padding">
                <Grid.Column mobile={16} computer={10}>
                  <MyTextInput
                    name="client.entityName"
                    label="Company, trust, or entity name"
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row className="tiny top padding tiny bottom padding">
              <Grid.Column width={16}>
                <MyCheckbox
                  name="clientSecondary.exists"
                  label="There is another client for this transaction"
                  onClick={() =>
                    setClientSecondaryExistsField(!clientSecondaryExistsField)
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {clientSecondaryExistsField && (
            <>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <h2 className="text blue">Secondary Client</h2>
                    <p>Choose an existing client or create a new one</p>
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={5}>
                    <MySelectInput
                      name="clientSecondarySelected"
                      placeholder="Select existing client"
                      options={peopleOptions}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <FormParty fieldKey="clientSecondary." />
              <Grid>
                <Grid.Row className="tiny vertical padding">
                  <Grid.Column width={16}>
                    <MyCheckbox
                      name="clientSecondary.isTrust"
                      label="The secondary client is a company, trust, or other
                        entity"
                      onClick={() =>
                        setClientSecondaryIsTrustField(
                          !clientSecondaryIsTrustField
                        )
                      }
                    />
                  </Grid.Column>
                </Grid.Row>

                {clientSecondaryIsTrustField && (
                  <Grid.Row className="zero top padding small bottom padding">
                    <Grid.Column mobile={16} computer={8}>
                      <MyTextInput
                        name="clientSecondary.entityName"
                        label="Company, trust, or entity name"
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </>
          )}
        </div>
      </Segment>
    );
  }

  function formImportMls() {
    return (
      <Segment className="background-lightgrey">
        <div className="tiny bottom margin">
          <h2 className="text blue">MLS Infomation</h2>
          <TransactionImportMlsForm mlsData={mlsData} setMlsData={setMlsData} />
        </div>
      </Segment>
    );
  }

  function formAddressDetails() {
    return (
      <Segment className="background-lightgrey">
        <div className="tiny bottom margin">
          <h2 className="text blue">Property Address</h2>
          <FormAddressAutofillGoogle />
        </div>
      </Segment>
    );
  }

  function formLegalDescription(values) {
    return (
      <Segment className="background-lightgrey">
        <div className="tiny bottom margin">
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <h2 className="text blue">Legal Description</h2>
                <MyTextArea
                  rows="2"
                  name="propertyDetails.legalDescription"
                  label="Legal Description"
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <TransactionImportLegalAddressForm
                  setLegalAddressData={setLegalAddressData}
                  address={values.address}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
    );
  }

  function formTransactionName() {
    return (
      <Segment className="background-lightgrey">
        <div className="tiny bottom margin">
          <Grid>
            <Grid.Column mobile={16} computer={8}>
              <h2 className="text blue">Transaction Name</h2>
              <MyTextInput name="title" data-test="transactionTitle" />
            </Grid.Column>
          </Grid>
        </div>
      </Segment>
    );
  }

  function formButtons(isSubmitting, dirty) {
    return (
      <>
        <Grid>
          <Grid.Column className="zero top padding">
            <Divider className="large bottom margin" />
            <Button
              loading={isSubmitting}
              disabled={!dirty || isSubmitting}
              type="submit"
              floated={isMobile ? "left" : "right"}
              primary
              content="Submit"
              className={isMobile ? "fluid small bottom margin" : null}
              data-test="submit"
            />
            <Button
              disabled={isSubmitting}
              onClick={() =>
                dispatch(
                  closeModal({
                    modalType: "TransactionForm",
                  })
                )
              }
              to="#"
              type="button"
              floated={isMobile ? "left" : "right"}
              content="Cancel"
              className={isMobile ? "fluid" : null}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <ModalWrapper size="large">
      <Segment clearing>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const newTransaction = await addTransactionToDb(
                values,
                people,
                forms,
                currentUserProfile,
                mlsData,
                agentsForAssistant
              );
              setSubmitting(false);
              toast.success("Transaction successfully created");
              dispatch(
                closeModal({
                  modalType: "TransactionForm",
                })
              );
              navigate(`/transactions/${newTransaction}/documents`);
            } catch (error) {
              toast.error(error.message);
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, values, errors }) => (
            <Form className="ui form medium margin">
              <Watcher />
              <Header size="huge" color="blue">
                {transactionId ? "Edit Transaction" : "New Transaction"}
              </Header>
              <Divider />
              {(isAssistant || isTc) && formSelectAgent()}
              {formWhoRepresenting()}
              {formClients()}
              {values.agentRepresents === "Buyer" && <>{formImportMls()}</>}
              {formAddressDetails()}
              {formLegalDescription(values)}
              {formTransactionName()}
              {formButtons(isSubmitting, dirty)}

              {errors.client && (
                <Message
                  negative
                  className="float-right"
                  style={{ color: "red" }}
                >
                  {errors.client.email && <p>{errors.client.email}</p>}
                  {errors.client.lastName && <p>{errors.client.lastName}</p>}
                </Message>
              )}
            </Form>
          )}
        </Formik>
      </Segment>
    </ModalWrapper>
  );
}
