export function closingInstructions() {
    return [   //2023
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 157.92,
       left: 400.56,
       width: 172.08,
       height: 11.40
    }
    ,
    {
       page: 0,
       name: "theSellersFullName", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 182.313,
       left: 188.28,
       width: 339.24,
       height: 10.527
    }
    ,
    {
       page: 0,
       name: "theBuyersFullName", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 193.68,
       left: 67.32,
       width: 476.40,
       height: 11.16
    }
    ,
    {
       page: 0,
       name: "theTitleCompanyName", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 205.44,
       left: 117.36,
       width: 410.16,
       height: 11.40
    }
    ,
    {
       page: 0,
       name: "known as No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 246.96,
       left: 108.12,
       width: 464.40,
       height: 11.40
    }
    ,
    {
       page: 0,
       name: "dateOfContract", 
       fontSize: 9,
       type: "date",
       top: 276,
       left: 353.52,
       width: 160.56,
       height: 11.4
    }
    ,
    {
       page: 0,
       name: "Agrees", 
       isText: false,
       type: "checkbox",
       top: 341.4,
       left: 407.52,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 0,
       name: "Does Not agree that upon", 
       isText: false,
       type: "checkbox",
       top: 341.4,
       left: 456.48,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 0,
       name: "Agrees_2", 
       isText: false,
       type: "checkbox",
       top: 364.44,
       left: 406.68,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 0,
       name: "Does Not agree to furnish", 
       isText: false,
       type: "checkbox",
       top: 364.44,
       left: 456.6,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 0,
       name: "CLOSING FEE Closing Company will receive a fee of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 697.56,
       left: 315,
       width: 131.64,
       height: 11.40
    }
    ,
    {
       page: 1,
       name: "Cashiers Check at Sellers", 
       isText: false,
       type: "checkbox",
       top: 127.8,
       left: 52.0327,
       width: 9.0873,
       height: 9.96
    }
    ,
    {
       page: 1,
       name: "Funds Electronically Transferred wire transfer to an account specified by Seller at Sellers expense", 
       isText: false,
       type: "checkbox",
       top: 139.32,
       left: 52.0327,
       width: 9.0873,
       height: 9.96
    }
    ,
    {
       page: 1,
       name: "Closing", 
       isText: false,
       type: "checkbox",
       top: 152.247,
       left: 52.0327,
       width: 9.0873,
       height: 9.960
    }
    ,
    {
       page: 1,
       name: "Text60", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 642.079,
       left: 49.0914,
       width: 531.1686,
       height: 114.9981
    }
    ,
    {
       page: 2,
       name: "electronicNotice", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 205.12,
       left: 325.2,
       width: 189.72,
       height: 10.32
    }
    ,
    {
       page: 2,
       name: "Buyers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 312.68,
       left: 121.8,
       width: 182.64,
       height: 11.88
    }
    ,
    {
       page: 2,
       name: "Buyer1AddressFirstLine", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 369.745,
       left: 135.96,
       width: 168.48,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "Buyer1AddressCityStateZip", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 382.225,
       left: 135.96,
       width: 168.48,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "Buyer1Phone", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 393.833,
       left: 135.96,
       width: 168.48,
       height: 11.007
    }
    ,
    {
       page: 2,
       name: "Buyer1Fax", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 407.305,
       left: 135.96,
       width: 168.48,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "Buyer1Email Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 419.785,
       left: 135.96,
       width: 168.48,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "Sellers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 454.825,
       left: 121.8,
       width: 182.64,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "AddressSeller1FirstLine", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 527.665,
       left: 135.96,
       width: 168.48,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "AddressCityStateZipSeller1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 538.836,
       left: 135.96,
       width: 168.48,
       height: 11.444
    }
    ,
    {
       page: 2,
       name: "phoneSeller1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 552.625,
       left: 135.96,
       width: 168.48,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "faxSeller1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 564.669,
       left: 135.96,
       width: 168.48,
       height: 10.571
    }
    ,
    {
       page: 2,
       name: "emailSeller1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 577.269,
       left: 135.24,
       width: 169.20,
       height: 10.571
    }
    ,
    {
       page: 2,
       name: "Buyers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 312.68,
       left: 393.24,
       width: 184.20,
       height: 11.88
    }
    ,
    {
       page: 2,
       name: "Buyer2AddressFirstLine", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 370.182,
       left: 410.52,
       width: 166.92,
       height: 9.698
    }
    ,
    {
       page: 2,
       name: "buyer2CityStateZip", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 382.225,
       left: 410.52,
       width: 166.92,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "buyer2Phone", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 394.705,
       left: 410.52,
       width: 166.92,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "buyer2Fax", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 407.305,
       left: 410.52,
       width: 166.92,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "buyer2Email", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 419.349,
       left: 410.52,
       width: 166.92,
       height: 10.571
    }
    ,
    {
       page: 2,
       name: "Sellers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 454.389,
       left: 393.24,
       width: 184.20,
       height: 10.571
    }
    ,
    {
       page: 2,
       name: "AddressSeller2FirstLine", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 527.229,
       left: 410.52,
       width: 166.92,
       height: 10.571
    }
    ,
    {
       page: 2,
       name: "AddressSeller2CityStateZip", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 539.273,
       left: 410.52,
       width: 166.92,
       height: 11.007
    }
    ,
    {
       page: 2,
       name: "phoneSeller2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 552.625,
       left: 410.52,
       width: 166.92,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "faxSeller2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 565.105,
       left: 410.52,
       width: 166.92,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "emailSeller2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 577.705,
       left: 409.8,
       width: 167.64,
       height: 10.135
    }
    ,
    {
       page: 2,
       name: "Closing Companys Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 610.88,
       left: 181.8,
       width: 395.64,
       height: 11.88
    }
    ,
    {
       page: 2,
       name: "Title 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 682.313,
       left: 181.8,
       width: 395.64,
       height: 11.007
    }
    ,
    {
       page: 2,
       name: "Title 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 695.6654,
       left: 181.8,
       width: 395.64,
       height: 9.6546
    }
    ,
    {
       page: 2,
       name: "Title 3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 706.7927,
       left: 181.8,
       width: 395.64,
       height: 10.5273
    }
    ,
    {
       page: 2,
       name: "Title 4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 719.2291,
       left: 181.8,
       width: 395.64,
       height: 10.0909
    }
    ,
    {
       page: 2,
       name: "Title 5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 732.1018,
       left: 181.08,
       width: 396.36,
       height: 9.6545
    }
    ] }
    