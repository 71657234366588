export function disclosureofBrokerageServiceSeller() {
return [   //2024 Release 2024-02-20 14:22:42
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 12.28,
   left: 131.04,
   width: 462.96,
   height: 13.418
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 136.446,
   left: 36.28,
   width: 10.08,
   height: 15.642
}
,
{
   page: 0,
   name: "Check Box 5", 
   isText: false,
   type: "checkbox",
   top: 136.446,
   left: 217.167,
   width: 10.080,
   height: 15.642
}
,
{
   page: 0,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 156.765,
   left: 36.28,
   width: 10.08,
   height: 15.642
}
,
{
   page: 0,
   name: "Check Box 6", 
   isText: false,
   type: "checkbox",
   top: 156.765,
   left: 217.167,
   width: 10.080,
   height: 15.642
}
,
{
   page: 0,
   name: "Check Box 7", 
   isText: false,
   type: "checkbox",
   top: 156.765,
   left: 399.833,
   width: 10.080,
   height: 15.642
}
,
{
   page: 0,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 156.74,
   left: 437.76,
   width: 137.74,
   height: 14.073
}
,
{
   page: 0,
   name: "Text Field 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 650.72,
   left: 325.843,
   width: 49.357,
   height: 13.418
}
,
{
   page: 0,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 650.72,
   left: 409.913,
   width: 115.687,
   height: 13.418
}
,
{
   page: 0,
   name: "Text Field 26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 650.72,
   left: 544.68,
   width: 27.00,
   height: 13.418
}
,
{
   page: 0,
   name: "Text Field 27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 683.5381,
   left: 136.8,
   width: 166.2,
   height: 13.4181
}
,
{
   page: 0,
   name: "Text Field 28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 713.3382,
   left: 136.8,
   width: 166.2,
   height: 13.4182
}
] }
