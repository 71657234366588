import React from "react";
import { Grid, Popup } from "semantic-ui-react";
import MyTextInput from "../form/MyTextInput";
import MyEmailInput from "./MyEmailInput";

export default function FormParty({ fieldKey = "", edit }) {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} computer={5}>
          <MyTextInput
            name={fieldKey + "firstName"}
            label={"First name"}
            data-test="firstName"
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={5}>
          <MyTextInput
            name={fieldKey + "middleName"}
            label={"Middle name"}
            data-test="middleName"
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={5}>
          <MyTextInput
            name={fieldKey + "lastName"}
            label={"Last name"}
            data-test="lastName"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="zero top padding">
        <Grid.Column mobile={16} computer={5}>
          {edit ? (
            // <Popup
            //   content="By changing the email address, any documents already shared will have to be reshared."
            //   style={{ backgroundColor: "lightblue" }}
            //   trigger={
            //     <MyEmailInput
            //       name={fieldKey + "email"}
            //       label="Email"
            //       // readOnly={true}
            //       data-test="email"
            //     />
            //   }
            // />
            <Popup
              content="Email can't be changed. You can delete this party and create a new one."
              className="background-orange"
              trigger={
                <MyEmailInput
                  name={fieldKey + "email"}
                  label="Email"
                  readOnly={true}
                  data-test="email"
                />
              }
            />
          ) : (
            <MyEmailInput
              name={fieldKey + "email"}
              label="Email"
              data-test="email"
            />
          )}
        </Grid.Column>
        <Grid.Column mobile={16} computer={5}>
          <MyTextInput
            name={fieldKey + "phone"}
            label="Phone"
            data-test="phone"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
