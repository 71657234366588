import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";
import {
  autoGoToNextAnnot,
  selectDocAnnot,
} from "../../../../app/annots/annotSlice";
import { updateAnnotSignedInDb } from "../../../../app/firestore/firestoreService";
import { updateCheckboxAnnotSigned } from "../partyDocSignUtils";

export default function AnnotFieldCheckbox({ annot, doc, pageScale }) {
  const dispatch = useDispatch();
  const { annotsSigningStatus } = useSelector((state) => state.annot);
  const ref = useRef(null);

  const color =
    annot.readOnly || annot.signed ? "232, 232, 232" : "103, 211, 189";

  function handleOnClick() {
    dispatch(selectDocAnnot(annot));
    let annotFields = {};
    if (annot.text === "checked") {
      annotFields["text"] = "";
    } else {
      annotFields["text"] = "checked";
    }
    try {
      dispatch(autoGoToNextAnnot(false));
      if (doc.pdfBurnVersion) {
        updateCheckboxAnnotSigned(doc, annot, annotFields);
      } else {
        updateAnnotSignedInDb(annot.id, doc.id, annotFields);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div
      ref={ref}
      id={"page" + annot.page + "-y" + annot.y.toString().split(".")[0]}
      onClick={() => handleOnClick()}
      style={{
        width: annot.width * pageScale,
        height: annot.height * pageScale,
        minWidth: 12 * pageScale,
        minHeight: 12 * pageScale,
        maxWidth: 960 * pageScale,
        maxHeight: 960 * pageScale,
        position: "absolute",
        display: "inline-block",
        cursor: "pointer",
        zIndex: 2,
        top: annot.y * pageScale,
        left: annot.x * pageScale,
        lineHeight: 0.6,
      }}
    >
      {annotsSigningStatus?.nextAnnotToSign?.uniqueId === annot.uniqueId &&
        !annot.signed && (
          <>
            <div
              className="arrow-pointer blink_me"
              style={{
                color: "white",
                backgroundColor: `rgb(${color})`,
              }}
            >
              <span>Click Here</span>
            </div>
          </>
        )}
      <div
        className="annot-outer-wrapper"
        style={{ backgroundColor: `rgba(${color}, 0.6)` }}
      >
        <div
          className="annot-inner-wrapper"
          style={{
            boxShadow: `rgb(${color}) 0px 0px 0px 1px`,
            zIndex: 5,
          }}
        >
          {annot.type === "checkbox" && (
            <span
              style={{
                fontSize: `${annot.fontSize * pageScale}px`,
                color: "#0037b8",
                fontFamily: `${annot.fontFamily}`,
              }}
            >
              <Icon
                // className="small right margin"
                style={{ display: "inline" }}
                name={
                  annot.text === "checked"
                    ? "square check outline"
                    : "square outline"
                }
                // size="large"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
