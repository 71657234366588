export function gTARUSDARuralLoan() {
return [   //2024 Release 2024-04-18 20:17:21
{
   page: 0,
   name: "This financing supplement is attached to and part of the Contract of Sale of Real Estate and relates to the following described real estate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 104.04,
   left: 25.2,
   width: 559.56,
   height: 10.80
}
,
{
   page: 0,
   name: "1 LOAN The Contract is contingent upon Buyer qualifying for a USDA Rural Housing Loan in the amount of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.12,
   left: 468.84,
   width: 35.28,
   height: 10.80
}
,
{
   page: 0,
   name: "days prior to Closing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 174,
   left: 475.08,
   width: 30.00,
   height: 10.8
}
,
{
   page: 0,
   name: "regulation Buyer shall instruct Lender to immediately begin the loan approval process Buyer shall also instruct Lender to issue a", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 311.16,
   left: 406.8,
   width: 24.72,
   height: 10.80
}
,
{
   page: 1,
   name: "6 ADDITIONAL PROVISIONS", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 28.08,
   left: 157.92,
   width: 428.64,
   height: 17.04
}
,
{
   page: 1,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 46.2,
   left: 33.84,
   width: 552.72,
   height: 16.2
}
,
{
   page: 1,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 62.88,
   left: 33.84,
   width: 552.72,
   height: 17.04
}
,
{
   page: 1,
   name: "3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 80.4,
   left: 33.84,
   width: 552.72,
   height: 17.04
}
] }
