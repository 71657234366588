import { Field, useField } from "formik";
import React from "react";
import { FormField, Label } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";

export default function MyCheckbox({ label, ...props }) {
  const [meta] = useField(props);
  return (
    <FormField error={meta.touched && !!meta.error}>
      <div className="ui checkbox">
        <Field type="checkbox" {...props} />
        <label>{label}</label>
      </div>
      {meta.touched && meta.error ? (
        <Label basic size="large" color="red">
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
}
