export function inspectionResolution2023() {
    return [   //2024 Release 2023-12-19 14:49:48
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 240,
      height: 36,
    }
    ,
   {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 151.418,
       left: 398.64,
       width: 174.00,
       height: 12.862
    }
    ,
    {
       page: 0,
       name: "Buyer relating to the sale and purchase of the Property known as", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 165.938,
       left: 294.96,
       width: 126.196,
       height: 12.862
    }
    ,
    {
       page: 0,
       name: "sellerNamesFull", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 181.604,
       left: 59.9125,
       width: 458.4015,
       height: 13.123
    }
    ,
    {
       page: 0,
       name: "buyernamesfull", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 196.32,
       left: 60.0638,
       width: 456.9392,
       height: 12.207
    }
    ,
    {
       page: 0,
       name: "known as", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 223.331,
       left: 50.4,
       width: 477.24,
       height: 12.469
    }
    ,
    {
       page: 0,
       name: "whenFulfillReso", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 265.985,
       left: 304.636,
       width: 124.026,
       height: 11.815
    }
    ,
    {
       page: 0,
       name: "Text1", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 290.533,
       left: 45.6871,
       width: 536.0709,
       height: 303.448
    }
    ,
    {
       page: 0,
       name: "buyer1name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 658.476,
       left: 107.0,
       width: 204.328,
       height: 14.800
    }
    ,
    {
       page: 0,
       name: "buyer2name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 657.821,
       left: 380.293,
       width: 194.509,
       height: 15.455
    }
    ,
    {
       page: 0,
       name: "seller1name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 706.2576,
       left: 106.037,
       width: 203.018,
       height: 15.4546
    }
    ,
    {
       page: 0,
       name: "seller2name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 708.2213,
       left: 380.293,
       width: 195.818,
       height: 14.1455
    }
    ] }
    