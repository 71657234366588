export function sourceOfWaterAddendum() {
    return [ 
      {
         page: 0,
         name: "Logo",
         isText: false,
         fontSize: 10,
         type: "logo",
         top: 28.673,
         left: 52.9457,
         width: 288.0003,
         height: 43.2,
       }
       ,
      {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date", 
            top: 170.88,
            left: 398.88,
            width: 173.88,
            height: 17.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 9,
            type: "date",
            name: "Contract", 
            top: 212,
            left: 416.596,
            width: 111.284,
            height: 12.2
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Street Address", 
            top: 242.88,
            left: 109.08,
            width: 207.24,
            height: 17.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "City", 
            top: 242.88,
            left: 320.64,
            width: 112.68,
            height: 17.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "State", 
            top: 242.88,
            left: 437.64,
            width: 85.68,
            height: 17.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Zip", 
            top: 242.88,
            left: 527.64,
            width: 45.00,
            height: 17.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "2_1", 
            top: 340.632,
            left: 51.3998,
            width: 13.6441,
            height: 12.773
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "If a well is the source of water for the Property a copy of the current Well Permit", 
            top: 332.88,
            left: 335.52,
            width: 183.24,
            height: 17.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "2_1 Is", 
            top: 364.59,
            left: 105.413,
            width: 11.466,
            height: 11.466
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "2_1 Is Not", 
            top: 364.590,
            left: 131.984,
            width: 11.466,
            height: 11.466
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "2_2", 
            top: 388.112,
            left: 51.3998,
            width: 13.6441,
            height: 12.773
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Name_2_2", 
            top: 398.76,
            left: 133.68,
            width: 385.08,
            height: 16.56
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Address2_2", 
            top: 417.92,
            left: 142.8,
            width: 375.96,
            height: 14.92
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Website2_2", 
            top: 433.44,
            left: 146.88,
            width: 371.88,
            height: 16.92
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Phone2_2", 
            top: 450.96,
            left: 152.16,
            width: 366.60,
            height: 17.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "2_3", 
            top: 481.764,
            left: 51.3998,
            width: 13.6441,
            height: 12.773
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "textarea",
            name: "2_3 Details", 
            top: 505.245,
            left: 55.2218,
            width: 400.7892,
            height: 40.423
         }
]
}