export function exclusiveRighttoBuy() {
   return [
   {
      page: 0,
      type: "checkbox",
      name: "BA Check", 
      top: 192.240,
      left: 165.878,
      width: 16.448,
      height: 15.931
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "TB Check", 
      top: 191.429,
      left: 283.493,
      width: 16.448,
      height: 15.931
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "date",
      name: "Date", 
      top: 217.92,
      left: 398.52,
      width: 174.00,
      height: 10.68
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "MPF Check", 
      top: 299.092,
      left: 50.0305,
      width: 12.8267,
      height: 11.275
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "OPF Check", 
      top: 356.787,
      left: 50.0305,
      width: 12.8267,
      height: 11.275
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Buyer", 
      top: 414.84,
      left: 129.84,
      width: 442.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Brokerage Firm", 
      top: 443.88,
      left: 172.92,
      width: 399.60,
      height: 10.68
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Broker", 
      top: 461.40,
      left: 134.28,
      width: 438.24,
      height: 10.68
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "3_4 Property", 
      top: 490.376,
      left: 50.0384,
      width: 529.6366,
      height: 36.566
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "3_5_2 Check", 
      top: 571.601,
      left: 66.2967,
      width: 14.8960,
      height: 13.344
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "date",
      name: "Listing Period Begin", 
      top: 593.88,
      left: 407.64,
      width: 109.80,
      height: 10.32
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Listing Period End", 
      top: 605.40,
      left: 420.6,
      width: 151.92,
      height: 10.68
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "Will Check", 
      top: 56.639,
      left: 334.601,
      width: 13.344,
      height: 12.309
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "Will Not Check", 
      top: 57.156,
      left: 371.508,
      width: 13.344,
      height: 11.792
   }
   // ,
   // {
   //    page: 1,
   //    type: "checkbox",
   //    name: "Will Not Check", 
   //    top: 57.156,
   //    left: 371.508,
   //    width: 13.344,
   //    height: 11.792
   // }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "BAO Check", 
      top: 276.207,
      left: 96.6856,
      width: 11.2744,
      height: 13.344
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "SF Check", 
      top: 208.029,
      left: 68.4007,
      width: 14.8960,
      height: 12.351
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "SF %", 
      top: 218.88,
      left: 273.36,
      width: 33.48,
      height: 10.68
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "minimum dollar amount", 
      top: 218.88,
      left: 479.04,
      width: 61.80,
      height: 10.68
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "AA Check", 
      top: 242.494,
      left: 254.074,
      width: 10.757,
      height: 11.792
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "Other Check", 
      top: 242.277,
      left: 415.378,
      width: 11.275,
      height: 11.792
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Other", 
      top: 241.80,
      left: 456.48,
      width: 116.04,
      height: 10.68
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "HF Check", 
      top: 311.044,
      left: 68.4007,
      width: 14.8960,
      height: 12.351
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Hourly Fee Brokerage", 
      top: 310.80,
      left: 307.8,
      width: 62.28,
      height: 10.68
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Buyer Listing Contract maximum fee", 
      top: 322.32,
      left: 268.08,
      width: 48.24,
      height: 10.68
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "RF Check", 
      top: 345.112,
      left: 68.4007,
      width: 14.8960,
      height: 12.351
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Retainer Fee", 
      top: 345.36,
      left: 454.8,
      width: 53.28,
      height: 10.68
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "Will1 Check", 
      top: 357.928,
      left: 282.177,
      width: 13.344,
      height: 10.757
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "Will Not 1 Check", 
      top: 357.928,
      left: 318.568,
      width: 13.344,
      height: 10.757
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "OC Check", 
      top: 379.991,
      left: 68.4007,
      width: 14.8960,
      height: 12.351
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Other Compensation", 
      top: 379.80,
      left: 228.6,
      width: 343.8,
      height: 10.68
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Amount", 
      top: 414.36,
      left: 181.44,
      width: 67.08,
      height: 10.68
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "per square", 
      top: 414.36,
      left: 294.72,
      width: 79.68,
      height: 10.68
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Alternate Amount", 
      top: 414.36,
      left: 390.24,
      width: 182.04,
      height: 10.68
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "Adj Amount Check", 
      top: 437.028,
      left: 213.405,
      width: 11.275,
      height: 12.309
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "Other Adjusted Amount Check", 
      top: 436.734,
      left: 374.458,
      width: 12.827,
      height: 11.792
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Adj Amount Other", 
      top: 437.28,
      left: 415.92,
      width: 156.48,
      height: 10.68
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Other Fee Arrangement", 
      top: 448.80,
      left: 162,
      width: 410.52,
      height: 10.68
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Earned When Payable", 
      top: 483.36,
      left: 85.68,
      width: 180.72,
      height: 10.68
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "Will Earned Check", 
      top: 506.451,
      left: 116.992,
      width: 13.344,
      height: 11.792
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "Will Not Earned Check", 
      top: 506.451,
      left: 155.15,
      width: 13.861,
      height: 12.309
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "731 Check", 
      top: 551.323,
      left: 47.8572,
      width: 14.3787,
      height: 12.826
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "732 Check", 
      top: 587.935,
      left: 47.8572,
      width: 14.3787,
      height: 12.827
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "733 Check", 
      top: 611.095,
      left: 47.8572,
      width: 14.3787,
      height: 12.827
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 8,
      type: "text",
      name: "Holdover Days", 
      top: 678.84,
      left: 49.4,
      width: 19.12,
      height: 10.68
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "Holdover Will Check", 
      top: 700.8580,
      left: 78.169,
      width: 12.3094,
      height: 12.8268
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "Holdover Will Not Check", 
      top: 700.8580,
      left: 115.662,
      width: 12.310,
      height: 12.8268
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "9 Is Check", 
      top: 174.764,
      left: 313.658,
      width: 11.792,
      height: 11.792
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "9 Is Not Check", 
      top: 174.247,
      left: 339.503,
      width: 12.309,
      height: 12.309
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "9 Has Check", 
      top: 184.974,
      left: 543.574,
      width: 13.861,
      height: 13.344
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "9 Has Not Check", 
      top: 196.441,
      left: 50.3311,
      width: 12.3094,
      height: 12.827
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "12_1 Brokerage Services", 
      top: 451.115,
      left: 47.344,
      width: 530.791,
      height: 56.967
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "12 2 Showing Properties", 
      top: 531.36,
      left: 289.8,
      width: 277.92,
      height: 10.68
   }
   ,
{
   page: 3,
   name: "MLSserviceslist",
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 555.184,
   left: 50.6403,
   width: 514.3387,
   height: 10.162
}

   ,
   {
      page: 3,
      type: "checkbox",
      name: "13 Does Check", 
      top: 578.341,
      left: 294.19,
      width: 11.792,
      height: 10.757
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "13 Does Not Check", 
      top: 578.341,
      left: 336.292,
      width: 11.792,
      height: 10.757
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "Add Prov Text", 
      top: 278.221,
      left: 48.2628,
      width: 548.6752,
      height: 58.095
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "Attachment Text", 
      top: 349.195,
      left: 49.0739,
      width: 549.4861,
      height: 45.524
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Delivery Method", 
      top: 497.88,
      left: 235.44,
      width: 190.08,
      height: 10.20
   }
   // ,
   // {
   //    page: 5,
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    type: "signature",
   //    name: "Buyers Signature", 
   //    top: 156.36,
   //    left: 50.4,
   //    width: 175.56,
   //    height: 10.32
   // }
   // ,
   // {
   //    page: 5,
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    type: "text",
   //    name: "Date_2", 
   //    top: 156.36,
   //    left: 230.4,
   //    width: 54.96,
   //    height: 10.32
   // }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "signature",
      name: "Brokers Signature", 
      top: 156.36,
      left: 302.4,
      width: 166.44,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "date",
      name: "Date_3", 
      top: 156.36,
      left: 473.28,
      width: 66.88,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Street Address", 
      top: 179.4,
      left: 50.4,
      width: 234.96,
      height: 10.2
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Brokerage Firm Street Address", 
      top: 179.4,
      left: 302.16,
      width: 240.00,
      height: 10.2
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "City State Zip", 
      top: 202.32,
      left: 50.4,
      width: 234.96,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Brokerage Firm City State Zip", 
      top: 202.32,
      left: 302.16,
      width: 240.00,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Phone No", 
      top: 225.36,
      left: 50.4,
      width: 234.96,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Broker Phone No", 
      top: 225.36,
      left: 302.16,
      width: 240.00,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Fax No", 
      top: 248.4,
      left: 50.4,
      width: 234.96,
      height: 10.2
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Broker Fax No", 
      top: 248.4,
      left: 302.16,
      width: 240.00,
      height: 10.2
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Email Address", 
      top: 271.32,
      left: 50.4,
      width: 234.96,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Broker Email Address", 
      top: 271.32,
      left: 302.16,
      width: 240.00,
      height: 10.32
   }
   // ,
   // {
   //    page: 5,
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    type: "signature",
   //    name: "Buyers Signature_2", 
   //    top: 317.28,
   //    left: 50.4,
   //    width: 183.12,
   //    height: 10.32
   // }
   // ,
   // {
   //    page: 5,
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    type: "text",
   //    name: "Date_4", 
   //    top: 317.28,
   //    left: 237.96,
   //    width: 47.40,
   //    height: 10.32
   // }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Street Address_2", 
      top: 340.32,
      left: 50.4,
      width: 234.96,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "City State Zip_2", 
      top: 363.36,
      left: 50.4,
      width: 234.96,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Phone No_2", 
      top: 386.28,
      left: 50.4,
      width: 234.96,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Fax No_2", 
      top: 409.32,
      left: 50.4,
      width: 234.96,
      height: 10.32
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Email Address_2", 
      top: 432.36,
      left: 50.4,
      width: 234.96,
      height: 10.32
   }
   
   ] }
   