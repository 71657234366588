export function addendumNonRealtyItems() {
return [   //2024 Release 2024-02-18 23:13:11
{
   page: 0,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 145.052,
   left: 34.56,
   width: 540.12,
   height: 12.988
}
,
{
   page: 0,
   name: "convey to Buyer at closing the following personal property specify each item carefully include", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 172.634,
   left: 197.409,
   width: 96.120,
   height: 11.944
}
,
{
   page: 0,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 235.163,
   left: 64.44,
   width: 502.68,
   height: 13.477
}
,
{
   page: 0,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 254.492,
   left: 64.44,
   width: 502.68,
   height: 12.988
}
,
{
   page: 0,
   name: "3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 272.354,
   left: 64.44,
   width: 502.68,
   height: 13.966
}
,
{
   page: 0,
   name: "4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 291.683,
   left: 64.44,
   width: 502.68,
   height: 13.477
}
,
{
   page: 0,
   name: "5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 311.132,
   left: 64.44,
   width: 502.68,
   height: 12.988
}
,
{
   page: 0,
   name: "6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 330.461,
   left: 64.44,
   width: 502.68,
   height: 12.499
}
,
{
   page: 0,
   name: "7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 349.301,
   left: 64.44,
   width: 502.68,
   height: 12.499
}
,
{
   page: 0,
   name: "8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 367.163,
   left: 64.44,
   width: 502.68,
   height: 13.477
}
,
{
   page: 0,
   name: "9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 386.981,
   left: 64.44,
   width: 502.68,
   height: 12.499
}
,
{
   page: 0,
   name: "10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 404.843,
   left: 64.44,
   width: 502.68,
   height: 13.477
}
,
{
   page: 0,
   name: "11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 424.052,
   left: 64.44,
   width: 502.68,
   height: 12.628
}
] }
