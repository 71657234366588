import React from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import ManagerAnalyticsListItem from "./ManagerAnalyticsListItem";
import { startOfDay, subDays } from "date-fns";

export default function ManagerAnalyticsList({ transactions }) {
  const closingTransactions = transactions.filter(
    (transaction) =>
      transaction.closingDateTime &&
      transaction.closingDateTime > startOfDay(subDays(new Date(), 2))
  );
  const closingTransactionsSorted = _.orderBy(
    closingTransactions,
    "closingDateTime",
    "asc"
  );

  if (!closingTransactions || closingTransactions.length === 0) return null;

  return (
    <Table compact>
      <Table.Header className="mobile hidden">
        <Table.Row className="small-header">
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Closing Date</Table.HeaderCell>
          <Table.HeaderCell>Agent</Table.HeaderCell>
          <Table.HeaderCell>Client&nbsp;&nbsp;(Primary)</Table.HeaderCell>
          <Table.HeaderCell>Client&nbsp;&nbsp;(Secondary)</Table.HeaderCell>
          <Table.HeaderCell>Representing</Table.HeaderCell>
          <Table.HeaderCell>Address</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {closingTransactionsSorted.map((transaction) => (
          <ManagerAnalyticsListItem
            transaction={transaction}
            key={transaction.id}
          />
        ))}
      </Table.Body>
    </Table>
  );
}
