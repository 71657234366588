import React from "react";
import { Icon, Label, Popup, Table } from "semantic-ui-react";
import DocTransAwaitingSignatureListItem from "./DocTransAwaitingSignatureListItem";

export default function DocTransAwaitingSignatureList({ docs }) {
  return (
    <>
      <Table compact>
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Sent Out</Table.HeaderCell>
            <Table.HeaderCell>
              Sharing With{" "}
              <Popup
                flowing
                size="large"
                trigger={
                  <Icon
                    name="info"
                    color="blue"
                    circular
                    inverted
                    size="small"
                    style={{
                      marginLeft: "3px",
                      marginBottom: "3px",
                      cursor: "pointer",
                    }}
                  />
                }
              >
                <p className="bold text blue mini bottom margin">
                  Share this document with other people.
                </p>
                <p className="text-dark text-normal mini bottom margin">
                  Note: Only people in your 'Parties' page that have an email
                  address are shown here.
                </p>
                <p className="text-dark text-normal mini bottom margin">
                  Color Key:
                </p>
                <Label size="tiny">Not Shared</Label>
                <Label size="tiny" color="blue">
                  Shared
                </Label>
                <Label size="tiny" color="orange">
                  Awaiting Signature
                </Label>
                <Label size="tiny" color="green">
                  Signed
                </Label>
              </Popup>
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {docs?.length !== 0 &&
            docs.map((doc) => (
              <DocTransAwaitingSignatureListItem doc={doc} key={doc.id} />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
