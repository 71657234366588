import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import {
  getTasksForTransaction,
  updateTaskInDb,
  updateTransStatusInDb,
} from "../../../app/firestore/firestoreService";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { updateTransactionTransAll } from "../transactionSlice";
import { openModal } from "../../../app/common/modals/modalSlice";

export default function TransactionActionButtons({ transaction }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const location = useLocation();
  const lastPath = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  function handleView() {
    navigate(`/transactions/${transaction.id}/overview`);
  }

  async function handleArchive() {
    try {
      await updateTransStatusInDb(transaction.id, {
        status: "Archived",
        statusPrevious: transaction.status,
      });
      let tasks = await getTasksForTransaction(transaction.id);
      if (tasks) {
        tasks.forEach((task) => {
          updateTaskInDb(task.id, { archived: true });
        });
      }
      if (lastPath === "transactionsAll") {
        dispatch(
          updateTransactionTransAll({
            id: transaction.id,
            fields: {
              status: "Archived",
              statusPrevious: transaction.status,
            },
          })
        );
      }
      toast.success("Transaction successfully archived");
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  }

  async function handleMakeActive() {
    try {
      await updateTransStatusInDb(transaction.id, {
        status:
          transaction.statusPrevious ||
          (transaction.agentRepresents === "Buyer" && "Active Buyer") ||
          (transaction.agentRepresents === "Seller" && "Active Listing"),
      });
      let tasks = await getTasksForTransaction(transaction.id);
      if (tasks) {
        tasks.forEach((task) => {
          updateTaskInDb(task.id, { archived: false });
        });
      }
      if (lastPath === "transactionsAll") {
        dispatch(
          updateTransactionTransAll({
            id: transaction.id,
            fields: {
              status: transaction.statusPrevious,
            },
          })
        );
      }
      toast.success("Transaction successfully made active");
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  }

  return (
    <div className={isMobile ? null : "text align right"}>
      <Dropdown
        button
        icon="chevron down"
        text={isMobile ? "Actions " : null}
        className={isMobile ? "button icon" : "button mini icon"}
        direction={isMobile ? "right" : "left"}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleView()}>View</Dropdown.Item>
          {transaction.status !== "Archived" ? (
            <Dropdown.Item onClick={() => handleArchive()}>
              Archive
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => handleMakeActive()}>
              Make Active
            </Dropdown.Item>
          )}
          {(currentUserProfile.role === "agent" ||
            currentUserProfile.role === "assistant") && (
            <Dropdown.Item
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: "TransactionDeleteConfirmModal",
                    modalProps: {
                      transactionId: transaction.id,
                      lastPath: lastPath,
                    },
                  })
                )
              }
            >
              Delete
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
