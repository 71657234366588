export function informationAboutBrokerageServices() {
return [   //2024 Release 2024-03-12 21:23:02
{
   page: 0,
   name: "Licensed Broker Broker Firm Name or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 562.8,
   left: 22.92,
   width: 173.40,
   height: 12.807
}
,
{
   page: 0,
   name: "License No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 562.8,
   left: 205.2,
   width: 64.92,
   height: 12.807
}
,
{
   page: 0,
   name: "Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 562.8,
   left: 279.566,
   width: 192.480,
   height: 12.807
}
,
{
   page: 0,
   name: "Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 562.8,
   left: 482.4,
   width: 93.72,
   height: 12.807
}
,
{
   page: 0,
   name: "Designated Broker of Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 605.64,
   left: 24.12,
   width: 173.04,
   height: 12.807
}
,
{
   page: 0,
   name: "License No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 605.64,
   left: 206.04,
   width: 64.08,
   height: 12.807
}
,
{
   page: 0,
   name: "Email_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 605.64,
   left: 279.24,
   width: 193.32,
   height: 12.807
}
,
{
   page: 0,
   name: "Phone_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 605.64,
   left: 481.44,
   width: 94.68,
   height: 12.807
}
,
{
   page: 0,
   name: "Licensed Supervisor of Sales Agent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 639.12,
   left: 24.12,
   width: 173.04,
   height: 12.807
}
,
{
   page: 0,
   name: "License No_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 639.12,
   left: 206.04,
   width: 64.08,
   height: 12.807
}
,
{
   page: 0,
   name: "Email_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 639.12,
   left: 279.24,
   width: 193.32,
   height: 12.807
}
,
{
   page: 0,
   name: "Phone_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 639.12,
   left: 481.44,
   width: 94.68,
   height: 12.807
}
,
{
   page: 0,
   name: "Sales AgentAssociates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 677.88,
   left: 22.92,
   width: 174.60,
   height: 12.807
}
,
{
   page: 0,
   name: "License No_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 677.88,
   left: 206.4,
   width: 63.12,
   height: 12.807
}
,
{
   page: 0,
   name: "Email_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 677.88,
   left: 278.64,
   width: 194.52,
   height: 12.807
}
,
{
   page: 0,
   name: "Phone_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 677.88,
   left: 483.72,
   width: 91.80,
   height: 12.807
}
] }
