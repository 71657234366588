export function addendumAuthorizingHydrostaticTesting() {
return [   //2024 Release 2024-02-18 22:54:42
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 119.347,
   left: 241.32,
   width: 316.80,
   height: 10.649
}
,
{
   page: 0,
   name: "1 Seller shall be liable for damages caused by the hydrostatic plumbing test", 
   isText: false,
   type: "checkbox",
   top: 335.849,
   left: 47.76,
   width: 9.84,
   height: 8.671
}
,
{
   page: 0,
   name: "2 Buyer shall be liable for damages caused by the hydrostatic plumbing test", 
   isText: false,
   type: "checkbox",
   top: 352.049,
   left: 47.2422,
   width: 10.3578,
   height: 8.671
}
,
{
   page: 0,
   name: "3 Buyer shall be liable for damages caused by the hydrostatic plumbing test in an amount not to", 
   isText: false,
   type: "checkbox",
   top: 368.369,
   left: 47.2422,
   width: 10.3578,
   height: 8.671
}
,
{
   page: 0,
   name: "exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 379.478,
   left: 110.64,
   width: 139.92,
   height: 11.411
}
] }
