import React from "react";
import ModalWrapper from "../../app/common/modals/modalWrapper";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalSlice";
import { toast } from "react-toastify";
import { updateUserProfileInDb } from "../../app/firestore/firestoreService";
import { signOutFirebase } from "../../app/firestore/firebaseService";
import { useNavigate } from "react-router-dom";

export default function DeleteAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function handleDeleteClick() {
    try {
      await updateUserProfileInDb({
        profileComplete: false,
        emailNotifications: false,
      });
      dispatch(closeModal({ modalType: "DeleteAccountForm" }));
      navigate("/");
      await signOutFirebase();
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  }

  return (
    <ModalWrapper size="mini" header="We're sorry to see you go :(">
      <Button
        onClick={() => handleDeleteClick()}
        type="submit"
        fluid
        size="large"
        color="red"
        content="Delete Account"
      />
      <Button
        onClick={() => dispatch(closeModal({ modalType: "DeleteAccountForm" }))}
        fluid
        size="large"
        content="Close"
      />
    </ModalWrapper>
  );
}
