import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Header, Segment, Table } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { closeModal } from "../../../app/common/modals/modalSlice";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { addFormTemplateToDb } from "../../../app/firestore/firestoreService";
import { updateFormListForBrokerage } from "../../../app/common/util/util";

export default function FormTemplatesSelectFormsModal() {
  const [formList, setFormList] = useState();
  const [formsToAdd, setFormsToAdd] = useState([]);
  const dispatch = useDispatch();
  const { forms } = useSelector((state) => state.doc);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { loading } = useSelector((state) => state.async);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  useEffect(() => {
    const formsCanBeTemplate = forms.filter((form) => form.canBeTemplate === true );
    const formsToShow = updateFormListForBrokerage(formsCanBeTemplate, currentUserProfile);
    setFormList(formsToShow);
    return () => {};
  }, [forms, currentUserProfile]);

  function handleAddForm(formTitle) {
    setFormsToAdd(
      formsToAdd.includes(formTitle)
        ? formsToAdd.filter((e) => e !== formTitle)
        : [...formsToAdd, formTitle]
    );
  }

  async function handleDone() {
    if (formsToAdd.length > 0) {
      try {
        let formsAdded = [];
        formsToAdd.forEach((formTitle) => {
          const formFullDoc = formList.filter(
            (form) => form.title === formTitle
          )[0];
          if (formFullDoc) {
            formsAdded = [...formsAdded, formFullDoc];
          }
        });
        await addFormTemplateToDb(formsAdded, currentUserProfile);
        dispatch(
          closeModal({
            modalType: "FormTemplatesSelectFormsModal",
          })
        );
      } catch (error) {
        throw error;
      }
    } else {
      dispatch(
        closeModal({
          modalType: "FormTemplatesSelectFormsModal",
        })
      );
    }
  }

  if (loading) return <LoadingComponent content="Loading documents..." />;

  return (
    <>
      <ModalWrapper style={{ backgroundColor: "#f9fafb" }}>
        <Segment clearing style={{ backgroundColor: "#f9fafb" }}>
          <Header color="blue" size="large">
            Select Forms to Add as Templates
            {!isMobile && (
              <Button
                primary
                floated="right"
                content="Done"
                loading={loading}
                onClick={() => handleDone()}
              ></Button>
            )}
          </Header>
          <Divider />{" "}
          <Table compact>
            <Table.Header className="mobile hidden">
              <Table.Row
                className="small-header-grey"
                style={{ backgroundColor: "#f9fafb !important" }}
              >
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {formList &&
                formList?.length !== 0 &&
                formList.map((form) => (
                  <Table.Row key={form.id}>
                    <Table.Cell>{form.title}</Table.Cell>
                    <Table.Cell>
                      {formsToAdd.includes(form.title) ? (
                        <Button
                          floated={isMobile ? null : "right"}
                          size={isMobile ? "medium" : "mini"}
                          primary
                          onClick={() => handleAddForm(form.title)}
                          content="Remove"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Button
                          floated={isMobile ? null : "right"}
                          size={isMobile ? "medium" : "mini"}
                          onClick={() => handleAddForm(form.title)}
                          content="Add"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <Button
            primary
            floated={isMobile ? null : "right"}
            content="Done"
            loading={loading}
            onClick={() => handleDone()}
            className={isMobile ? "fluid" : null}
          ></Button>
        </Segment>
      </ModalWrapper>
    </>
  );
}
