export function propertyDisclosureDocument() {
return [   //2024 Release 2024-06-07 09:45:18
{
   page: 0,
   name: "Address 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 61.65,
   left: 35.9778,
   width: 442.9522,
   height: 14.225
}
,
{
   page: 0,
   name: "Date 1", 
   fontSize: 9,
   type: "date",
   top: 61.65,
   left: 482.411,
   width: 102.905,
   height: 14.225
}
,
{
   page: 1,
   name: "address2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 32.995,
   left: 35.9778,
   width: 442.9522,
   height: 14.880
}
,
{
   page: 1,
   name: "Date 2", 
   fontSize: 9,
   type: "date",
   top: 32.341,
   left: 482.411,
   width: 102.905,
   height: 15.534
}
,
{
   page: 1,
   name: "Check Box270a", 
   isText: false,
   type: "checkbox",
   top: 272.763,
   left: 40.843,
   width: 10.7999,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box271b", 
   isText: false,
   type: "checkbox",
   top: 330.4,
   left: 41.4,
   width: 10.8,
   height: 10.8
}
,
{
   page: 1,
   name: "Check Box272", 
   isText: false,
   type: "checkbox",
   top: 348.527,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box273", 
   isText: false,
   type: "checkbox",
   top: 392.654,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box274", 
   isText: false,
   type: "checkbox",
   top: 423.782,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box275", 
   isText: false,
   type: "checkbox",
   top: 440.909,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box276", 
   isText: false,
   type: "checkbox",
   top: 458.036,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box277", 
   isText: false,
   type: "checkbox",
   top: 475.163,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box278", 
   isText: false,
   type: "checkbox",
   top: 505.291,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box279", 
   isText: false,
   type: "checkbox",
   top: 524.418,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box2710", 
   isText: false,
   type: "checkbox",
   top: 554.545,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box2711", 
   isText: false,
   type: "checkbox",
   top: 571.672,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box2712", 
   isText: false,
   type: "checkbox",
   top: 629.8,
   left: 41.4,
   width: 10.8,
   height: 10.8
}
,
{
   page: 1,
   name: "Check Box2713", 
   isText: false,
   type: "checkbox",
   top: 646.927,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 1,
   name: "Check Box2714", 
   isText: false,
   type: "checkbox",
   top: 679.054,
   left: 41.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 2,
   name: "address3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 31.686,
   left: 35.9778,
   width: 442.9522,
   height: 16.189
}
,
{
   page: 2,
   name: "date 3", 
   fontSize: 9,
   type: "date",
   top: 31.032,
   left: 482.411,
   width: 102.905,
   height: 16.843
}
,
{
   page: 2,
   name: "0", 
   isText: false,
   type: "checkbox",
   top: 209.673,
   left: 41.6728,
   width: 10.6909,
   height: 10.691
}
,
{
   page: 2,
   name: "Check Box281", 
   isText: false,
   type: "checkbox",
   top: 276.8,
   left: 41.6728,
   width: 10.6909,
   height: 10.691
}
,
{
   page: 2,
   name: "Check Box282", 
   isText: false,
   type: "checkbox",
   top: 356.927,
   left: 41.6728,
   width: 10.6909,
   height: 10.691
}
,
{
   page: 2,
   name: "Time", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 417.12,
   left: 330.4,
   width: 43.8,
   height: 16.876
}
,
{
   page: 2,
   name: "print", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 418.12,
   left: 412.593,
   width: 167.727,
   height: 15.567
}
,
{
   page: 2,
   name: "Time_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 444.0,
   left: 330.4,
   width: 43.8,
   height: 16.876
}
,
{
   page: 2,
   name: "print_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 445.0,
   left: 412.593,
   width: 167.727,
   height: 15.567
}
,
{
   page: 2,
   name: "Time_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 470.88,
   left: 330.4,
   width: 43.8,
   height: 16.876
}
,
{
   page: 2,
   name: "print_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 471.88,
   left: 412.593,
   width: 167.727,
   height: 15.567
}
,
{
   page: 2,
   name: "Time_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 497.76,
   left: 330.4,
   width: 43.8,
   height: 16.876
}
,
{
   page: 2,
   name: "print_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 498.76,
   left: 412.593,
   width: 167.727,
   height: 15.567
}
,
{
   page: 2,
   name: "Time_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 560.52,
   left: 332.4,
   width: 43.8,
   height: 16.876
}
,
{
   page: 2,
   name: "print_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 558.52,
   left: 412.593,
   width: 167.727,
   height: 15.567
}
,
{
   page: 2,
   name: "Time_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 587.4,
   left: 332.4,
   width: 43.8,
   height: 16.876
}
,
{
   page: 2,
   name: "print_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 585.4,
   left: 412.593,
   width: 167.727,
   height: 15.567
}
,
{
   page: 2,
   name: "Time_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 614.16,
   left: 332.4,
   width: 43.8,
   height: 16.876
}
,
{
   page: 2,
   name: "print_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 612.16,
   left: 412.593,
   width: 167.727,
   height: 15.567
}
,
{
   page: 2,
   name: "Time_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 641.04,
   left: 332.4,
   width: 43.8,
   height: 16.876
}
,
{
   page: 2,
   name: "print_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 639.04,
   left: 412.593,
   width: 167.727,
   height: 15.567
}
,
{
   page: 3,
   name: "address4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 31.686,
   left: 35.9778,
   width: 442.9522,
   height: 16.189
}
,
{
   page: 3,
   name: "date 4", 
   fontSize: 9,
   type: "date",
   top: 31.686,
   left: 482.411,
   width: 102.905,
   height: 16.189
}
,
{
   page: 3,
   name: "What is the length of ownership of the property by the SELLER", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.4,
   left: 342,
   width: 234.12,
   height: 12.6
}
,
{
   page: 3,
   name: "Lot size or acres", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 246.6,
   left: 126,
   width: 450,
   height: 12.6
}
,
{
   page: 3,
   name: "Other", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 421.56,
   left: 357.84,
   width: 208.20,
   height: 12.60
}
,
{
   page: 3,
   name: "Queson Number 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 619.44,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 3,
   name: "Queson Number 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 632.76,
   left: 41.4,
   width: 87.84,
   height: 12.60
}
,
{
   page: 3,
   name: "Queson Number 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 646.2,
   left: 41.4,
   width: 87.84,
   height: 12.6
}
,
{
   page: 3,
   name: "Queson Number 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 659.64,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 3,
   name: "Explanaon of Yes answers 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 619.44,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 3,
   name: "Explanaon of Yes answers 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 632.76,
   left: 143.28,
   width: 427.32,
   height: 12.60
}
,
{
   page: 3,
   name: "Explanaon of Yes answers 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 646.2,
   left: 143.28,
   width: 427.32,
   height: 12.6
}
,
{
   page: 3,
   name: "Explanaon of Yes answers 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 659.64,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 3,
   name: "Check Box2900", 
   isText: false,
   type: "checkbox",
   top: 283.254,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 3,
   name: "Check Box2901", 
   isText: false,
   type: "checkbox",
   top: 283.254,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 3,
   name: "Check Box2910", 
   isText: false,
   type: "checkbox",
   top: 486.382,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 3,
   name: "Check Box2911", 
   isText: false,
   type: "checkbox",
   top: 486.382,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 3,
   name: "Check Box2912", 
   isText: false,
   type: "checkbox",
   top: 486.382,
   left: 541.254,
   width: 10.800,
   height: 10.800
}
,
{
   page: 3,
   name: "Check Box3000", 
   isText: false,
   type: "checkbox",
   top: 322.255,
   left: 203.128,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3010", 
   isText: false,
   type: "checkbox",
   top: 322.255,
   left: 277.528,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3020", 
   isText: false,
   type: "checkbox",
   top: 322.255,
   left: 457.746,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3030", 
   isText: false,
   type: "checkbox",
   top: 322.255,
   left: 531.055,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3001", 
   isText: false,
   type: "checkbox",
   top: 343.6,
   left: 203.128,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3002", 
   isText: false,
   type: "checkbox",
   top: 363.946,
   left: 203.128,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3003", 
   isText: false,
   type: "checkbox",
   top: 384.291,
   left: 203.128,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3004", 
   isText: false,
   type: "checkbox",
   top: 404.637,
   left: 203.128,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3005", 
   isText: false,
   type: "checkbox",
   top: 424.982,
   left: 203.128,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3006", 
   isText: false,
   type: "checkbox",
   top: 444.328,
   left: 203.128,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3011", 
   isText: false,
   type: "checkbox",
   top: 343.6,
   left: 277.528,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3012", 
   isText: false,
   type: "checkbox",
   top: 363.946,
   left: 277.528,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3013", 
   isText: false,
   type: "checkbox",
   top: 384.291,
   left: 277.528,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3014", 
   isText: false,
   type: "checkbox",
   top: 404.637,
   left: 277.528,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3015", 
   isText: false,
   type: "checkbox",
   top: 424.982,
   left: 277.528,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3016", 
   isText: false,
   type: "checkbox",
   top: 444.328,
   left: 277.528,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3021", 
   isText: false,
   type: "checkbox",
   top: 343.6,
   left: 457.746,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3022", 
   isText: false,
   type: "checkbox",
   top: 363.946,
   left: 457.746,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3023", 
   isText: false,
   type: "checkbox",
   top: 384.291,
   left: 457.746,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3024", 
   isText: false,
   type: "checkbox",
   top: 404.637,
   left: 457.746,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3031", 
   isText: false,
   type: "checkbox",
   top: 343.6,
   left: 531.055,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3032", 
   isText: false,
   type: "checkbox",
   top: 363.946,
   left: 531.055,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3033", 
   isText: false,
   type: "checkbox",
   top: 384.291,
   left: 531.055,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box3034", 
   isText: false,
   type: "checkbox",
   top: 404.637,
   left: 531.055,
   width: 10.909,
   height: 10.909
}
,
{
   page: 3,
   name: "Check Box53", 
   isText: false,
   type: "checkbox",
   top: 604.071,
   left: 299.345,
   width: 10.800,
   height: 10.800
}
,
{
   page: 4,
   name: "Name of company", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 243,
   left: 153.6,
   width: 422.52,
   height: 12.6
}
,
{
   page: 4,
   name: "Date contract expires", 
   fontSize: 9,
   type: "date",
   top: 263.76,
   left: 171,
   width: 404.88,
   height: 12.60
}
,
{
   page: 4,
   name: "List any structures not covered by contract", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 283.92,
   left: 266.76,
   width: 309.00,
   height: 12.60
}
,
{
   page: 4,
   name: "Queson Number 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 321.84,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 4,
   name: "Queson Number 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 335.16,
   left: 41.4,
   width: 87.84,
   height: 12.60
}
,
{
   page: 4,
   name: "Queson Number 3_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 348.6,
   left: 41.4,
   width: 87.84,
   height: 12.6
}
,
{
   page: 4,
   name: "Queson Number 4_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 362.04,
   left: 41.4,
   width: 87.84,
   height: 12.60
}
,
{
   page: 4,
   name: "Explanaon of Yes answers 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 321.84,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 4,
   name: "Explanaon of Yes answers 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 335.16,
   left: 143.28,
   width: 427.32,
   height: 12.60
}
,
{
   page: 4,
   name: "Explanaon of Yes answers 3_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 348.6,
   left: 143.28,
   width: 427.32,
   height: 12.6
}
,
{
   page: 4,
   name: "Explanaon of Yes answers 4_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 362.04,
   left: 143.28,
   width: 427.32,
   height: 12.60
}
,
{
   page: 4,
   name: "Main structure", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 441.72,
   left: 126,
   width: 449.88,
   height: 12.60
}
,
{
   page: 4,
   name: "Other structures", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 460.68,
   left: 130.44,
   width: 445.80,
   height: 12.60
}
,
{
   page: 4,
   name: "Main structure_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 581.16,
   left: 125.88,
   width: 449.88,
   height: 12.60
}
,
{
   page: 4,
   name: "Other structures_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 600.84,
   left: 130.68,
   width: 445.44,
   height: 12.60
}
,
{
   page: 4,
   name: "address5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 31.032,
   left: 35.9778,
   width: 442.9522,
   height: 16.843
}
,
{
   page: 4,
   name: "date 5", 
   fontSize: 9,
   type: "date",
   top: 31.032,
   left: 482.411,
   width: 102.905,
   height: 16.843
}
,
{
   page: 4,
   name: "Check Box3100", 
   isText: false,
   type: "checkbox",
   top: 137.455,
   left: 468.437,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3101", 
   isText: false,
   type: "checkbox",
   top: 137.455,
   left: 503.564,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3110", 
   isText: false,
   type: "checkbox",
   top: 157.582,
   left: 468.437,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3111", 
   isText: false,
   type: "checkbox",
   top: 157.582,
   left: 503.564,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3112", 
   isText: false,
   type: "checkbox",
   top: 157.582,
   left: 539.691,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3120", 
   isText: false,
   type: "checkbox",
   top: 178.709,
   left: 468.437,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3121", 
   isText: false,
   type: "checkbox",
   top: 178.709,
   left: 503.564,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3122", 
   isText: false,
   type: "checkbox",
   top: 178.709,
   left: 539.691,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3130", 
   isText: false,
   type: "checkbox",
   top: 197.836,
   left: 468.437,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3131", 
   isText: false,
   type: "checkbox",
   top: 197.836,
   left: 503.564,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3132", 
   isText: false,
   type: "checkbox",
   top: 197.836,
   left: 539.691,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3140", 
   isText: false,
   type: "checkbox",
   top: 504.964,
   left: 468.437,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3141", 
   isText: false,
   type: "checkbox",
   top: 504.964,
   left: 503.564,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3150", 
   isText: false,
   type: "checkbox",
   top: 539.091,
   left: 468.437,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3151", 
   isText: false,
   type: "checkbox",
   top: 539.091,
   left: 503.564,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box3152", 
   isText: false,
   type: "checkbox",
   top: 539.091,
   left: 539.691,
   width: 10.691,
   height: 10.691
}
,
{
   page: 4,
   name: "Check Box52", 
   isText: false,
   type: "checkbox",
   top: 309.818,
   left: 293.455,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Other_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 283.08,
   left: 83.28,
   width: 240.96,
   height: 12.60
}
,
{
   page: 5,
   name: "If yes provide the name of the warranty company", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 492.72,
   left: 297,
   width: 279.12,
   height: 12.60
}
,
{
   page: 5,
   name: "Queson Number 1_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.28,
   left: 41.4,
   width: 87.84,
   height: 12.60
}
,
{
   page: 5,
   name: "Queson Number 2_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 579.72,
   left: 41.4,
   width: 87.84,
   height: 12.60
}
,
{
   page: 5,
   name: "Queson Number 3_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 593.16,
   left: 41.4,
   width: 87.84,
   height: 12.60
}
,
{
   page: 5,
   name: "Queson Number 4_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 606.6,
   left: 41.4,
   width: 87.84,
   height: 12.6
}
,
{
   page: 5,
   name: "Explanaon of Yes answers 1_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.4,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 5,
   name: "Explanaon of Yes answers 2_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 579.72,
   left: 143.28,
   width: 427.32,
   height: 12.60
}
,
{
   page: 5,
   name: "Explanaon of Yes answers 3_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 593.16,
   left: 143.28,
   width: 427.32,
   height: 12.60
}
,
{
   page: 5,
   name: "Explanaon of Yes answers 4_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 606.6,
   left: 143.28,
   width: 427.32,
   height: 12.6
}
,
{
   page: 5,
   name: "address6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 31.686,
   left: 35.9778,
   width: 442.9522,
   height: 16.189
}
,
{
   page: 5,
   name: "date 6", 
   fontSize: 9,
   type: "date",
   top: 30.377,
   left: 482.411,
   width: 102.905,
   height: 17.498
}
,
{
   page: 5,
   name: "Check Box3200", 
   isText: false,
   type: "checkbox",
   top: 103.636,
   left: 204.0,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3201", 
   isText: false,
   type: "checkbox",
   top: 103.636,
   left: 277.128,
   width: 10.690,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3202", 
   isText: false,
   type: "checkbox",
   top: 103.636,
   left: 458.255,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3203", 
   isText: false,
   type: "checkbox",
   top: 103.636,
   left: 532.382,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3210", 
   isText: false,
   type: "checkbox",
   top: 123.764,
   left: 204.0,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3211", 
   isText: false,
   type: "checkbox",
   top: 123.764,
   left: 277.128,
   width: 10.690,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3212", 
   isText: false,
   type: "checkbox",
   top: 123.764,
   left: 458.255,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3213", 
   isText: false,
   type: "checkbox",
   top: 123.764,
   left: 532.382,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3220", 
   isText: false,
   type: "checkbox",
   top: 265.891,
   left: 204.0,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3221", 
   isText: false,
   type: "checkbox",
   top: 265.891,
   left: 277.128,
   width: 10.690,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3222", 
   isText: false,
   type: "checkbox",
   top: 265.891,
   left: 458.255,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3223", 
   isText: false,
   type: "checkbox",
   top: 265.891,
   left: 532.382,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3230", 
   isText: false,
   type: "checkbox",
   top: 145.018,
   left: 204.0,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3231", 
   isText: false,
   type: "checkbox",
   top: 145.018,
   left: 277.128,
   width: 10.690,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3232", 
   isText: false,
   type: "checkbox",
   top: 145.018,
   left: 458.255,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3233", 
   isText: false,
   type: "checkbox",
   top: 145.018,
   left: 532.382,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3240", 
   isText: false,
   type: "checkbox",
   top: 165.146,
   left: 204.0,
   width: 10.691,
   height: 10.690
}
,
{
   page: 5,
   name: "Check Box3241", 
   isText: false,
   type: "checkbox",
   top: 165.146,
   left: 277.128,
   width: 10.690,
   height: 10.690
}
,
{
   page: 5,
   name: "Check Box3242", 
   isText: false,
   type: "checkbox",
   top: 165.146,
   left: 458.255,
   width: 10.691,
   height: 10.690
}
,
{
   page: 5,
   name: "Check Box3243", 
   isText: false,
   type: "checkbox",
   top: 165.146,
   left: 532.382,
   width: 10.691,
   height: 10.690
}
,
{
   page: 5,
   name: "Check Box3250", 
   isText: false,
   type: "checkbox",
   top: 185.273,
   left: 204.0,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3251", 
   isText: false,
   type: "checkbox",
   top: 185.273,
   left: 277.128,
   width: 10.690,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3252", 
   isText: false,
   type: "checkbox",
   top: 185.273,
   left: 458.255,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3253", 
   isText: false,
   type: "checkbox",
   top: 185.273,
   left: 532.382,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3260", 
   isText: false,
   type: "checkbox",
   top: 204.4,
   left: 204.0,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3261", 
   isText: false,
   type: "checkbox",
   top: 204.4,
   left: 277.128,
   width: 10.690,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3262", 
   isText: false,
   type: "checkbox",
   top: 204.4,
   left: 458.255,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3263", 
   isText: false,
   type: "checkbox",
   top: 204.4,
   left: 532.382,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3270", 
   isText: false,
   type: "checkbox",
   top: 225.527,
   left: 204.0,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3271", 
   isText: false,
   type: "checkbox",
   top: 225.527,
   left: 277.128,
   width: 10.690,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3272", 
   isText: false,
   type: "checkbox",
   top: 225.527,
   left: 458.255,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3273", 
   isText: false,
   type: "checkbox",
   top: 225.527,
   left: 532.382,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3280", 
   isText: false,
   type: "checkbox",
   top: 245.655,
   left: 204.0,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3281", 
   isText: false,
   type: "checkbox",
   top: 245.655,
   left: 277.128,
   width: 10.690,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3282", 
   isText: false,
   type: "checkbox",
   top: 245.655,
   left: 458.255,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3283", 
   isText: false,
   type: "checkbox",
   top: 245.655,
   left: 532.382,
   width: 10.691,
   height: 10.691
}
,
{
   page: 5,
   name: "Check Box3300", 
   isText: false,
   type: "checkbox",
   top: 346.745,
   left: 467.836,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box3301", 
   isText: false,
   type: "checkbox",
   top: 346.745,
   left: 503.963,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box3310", 
   isText: false,
   type: "checkbox",
   top: 367.872,
   left: 467.836,
   width: 10.800,
   height: 10.801
}
,
{
   page: 5,
   name: "Check Box3311", 
   isText: false,
   type: "checkbox",
   top: 367.872,
   left: 503.963,
   width: 10.800,
   height: 10.801
}
,
{
   page: 5,
   name: "Check Box3312", 
   isText: false,
   type: "checkbox",
   top: 367.872,
   left: 540.091,
   width: 10.800,
   height: 10.801
}
,
{
   page: 5,
   name: "Check Box3320", 
   isText: false,
   type: "checkbox",
   top: 433.0,
   left: 467.836,
   width: 10.800,
   height: 10.8
}
,
{
   page: 5,
   name: "Check Box3321", 
   isText: false,
   type: "checkbox",
   top: 433.0,
   left: 503.963,
   width: 10.800,
   height: 10.8
}
,
{
   page: 5,
   name: "Check Box3330", 
   isText: false,
   type: "checkbox",
   top: 454.127,
   left: 467.836,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box3331", 
   isText: false,
   type: "checkbox",
   top: 454.127,
   left: 503.963,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box3332", 
   isText: false,
   type: "checkbox",
   top: 454.127,
   left: 540.091,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box3340", 
   isText: false,
   type: "checkbox",
   top: 474.254,
   left: 467.836,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box3341", 
   isText: false,
   type: "checkbox",
   top: 474.254,
   left: 503.963,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box3342", 
   isText: false,
   type: "checkbox",
   top: 474.254,
   left: 540.091,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box3350", 
   isText: false,
   type: "checkbox",
   top: 534.382,
   left: 467.836,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box3351", 
   isText: false,
   type: "checkbox",
   top: 534.382,
   left: 503.963,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box3352", 
   isText: false,
   type: "checkbox",
   top: 534.382,
   left: 540.091,
   width: 10.800,
   height: 10.800
}
,
{
   page: 5,
   name: "Check Box51", 
   isText: false,
   type: "checkbox",
   top: 555.055,
   left: 300.655,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 237,
   left: 468,
   width: 108.24,
   height: 12.48
}
,
{
   page: 6,
   name: "How many private wells service the primary residence only", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 252.48,
   left: 344.4,
   width: 231.72,
   height: 12.48
}
,
{
   page: 6,
   name: "If there are private wells when was the water last tested Date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 268.92,
   left: 357.24,
   width: 69.96,
   height: 12.48
}
,
{
   page: 6,
   name: "Results", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 268.92,
   left: 468,
   width: 108.24,
   height: 12.48
}
,
{
   page: 6,
   name: "If leased please list service provider", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 385.68,
   left: 237.48,
   width: 338.88,
   height: 12.48
}
,
{
   page: 6,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 477,
   left: 414.12,
   width: 159.00,
   height: 12.48
}
,
{
   page: 6,
   name: "How many private sewer systems service the primary residence only", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 494.04,
   left: 383.64,
   width: 192.24,
   height: 12.48
}
,
{
   page: 6,
   name: "Queson Number 1_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 553.08,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 6,
   name: "Queson Number 2_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.52,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 6,
   name: "Queson Number 3_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 579.96,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 6,
   name: "Queson Number 4_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 593.4,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 6,
   name: "Explanaon of Yes answers 1_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 553.08,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 6,
   name: "Explanaon of Yes answers 2_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.52,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 6,
   name: "Explanaon of Yes answers 3_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 579.96,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 6,
   name: "Explanaon of Yes answers 4_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 593.4,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 6,
   name: "address7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 31.032,
   left: 35.9778,
   width: 442.9522,
   height: 16.843
}
,
{
   page: 6,
   name: "date 7", 
   fontSize: 9,
   type: "date",
   top: 31.032,
   left: 482.411,
   width: 102.905,
   height: 16.843
}
,
{
   page: 6,
   name: "Check Box3400", 
   isText: false,
   type: "checkbox",
   top: 133.364,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3401", 
   isText: false,
   type: "checkbox",
   top: 133.364,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3410", 
   isText: false,
   type: "checkbox",
   top: 149.491,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3411", 
   isText: false,
   type: "checkbox",
   top: 149.491,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3420", 
   isText: false,
   type: "checkbox",
   top: 192.618,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3421", 
   isText: false,
   type: "checkbox",
   top: 192.618,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3430", 
   isText: false,
   type: "checkbox",
   top: 208.745,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3431", 
   isText: false,
   type: "checkbox",
   top: 208.745,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3440", 
   isText: false,
   type: "checkbox",
   top: 287.873,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3441", 
   isText: false,
   type: "checkbox",
   top: 287.873,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3450", 
   isText: false,
   type: "checkbox",
   top: 316.0,
   left: 468.0,
   width: 10.8,
   height: 10.8
}
,
{
   page: 6,
   name: "Check Box3451", 
   isText: false,
   type: "checkbox",
   top: 316.0,
   left: 504.127,
   width: 10.800,
   height: 10.8
}
,
{
   page: 6,
   name: "Check Box3452", 
   isText: false,
   type: "checkbox",
   top: 316.0,
   left: 540.254,
   width: 10.800,
   height: 10.8
}
,
{
   page: 6,
   name: "Check Box3460", 
   isText: false,
   type: "checkbox",
   top: 352.127,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3461", 
   isText: false,
   type: "checkbox",
   top: 352.127,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3470", 
   isText: false,
   type: "checkbox",
   top: 433.254,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3471", 
   isText: false,
   type: "checkbox",
   top: 433.254,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3480", 
   isText: false,
   type: "checkbox",
   top: 451.382,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3481", 
   isText: false,
   type: "checkbox",
   top: 451.382,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3490", 
   isText: false,
   type: "checkbox",
   top: 514.509,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3491", 
   isText: false,
   type: "checkbox",
   top: 514.509,
   left: 504.127,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box3492", 
   isText: false,
   type: "checkbox",
   top: 514.509,
   left: 540.254,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box350", 
   isText: false,
   type: "checkbox",
   top: 240.763,
   left: 72.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box351", 
   isText: false,
   type: "checkbox",
   top: 240.763,
   left: 149.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box352", 
   isText: false,
   type: "checkbox",
   top: 240.763,
   left: 229.036,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box353", 
   isText: false,
   type: "checkbox",
   top: 240.763,
   left: 315.055,
   width: 10.799,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box354", 
   isText: false,
   type: "checkbox",
   top: 240.763,
   left: 422.073,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box360", 
   isText: false,
   type: "checkbox",
   top: 333.491,
   left: 153.164,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box361", 
   isText: false,
   type: "checkbox",
   top: 333.491,
   left: 206.182,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box362", 
   isText: false,
   type: "checkbox",
   top: 333.491,
   left: 260.2,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box370", 
   isText: false,
   type: "checkbox",
   top: 370.582,
   left: 252.218,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box371", 
   isText: false,
   type: "checkbox",
   top: 370.582,
   left: 324.236,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box380", 
   isText: false,
   type: "checkbox",
   top: 479.127,
   left: 222.818,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box381", 
   isText: false,
   type: "checkbox",
   top: 479.127,
   left: 298.727,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box382", 
   isText: false,
   type: "checkbox",
   top: 479.127,
   left: 372.636,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box50", 
   isText: false,
   type: "checkbox",
   top: 541.091,
   left: 297.328,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "If leased please list service provider_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 525.84,
   left: 238.68,
   width: 332.52,
   height: 12.60
}
,
{
   page: 7,
   name: "Queson Number 1_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 565.8,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 7,
   name: "Queson Number 2_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 579.12,
   left: 41.4,
   width: 87.84,
   height: 12.60
}
,
{
   page: 7,
   name: "Queson Number 3_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 592.56,
   left: 41.4,
   width: 87.84,
   height: 12.60
}
,
{
   page: 7,
   name: "Queson Number 4_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 606,
   left: 41.4,
   width: 87.84,
   height: 12.6
}
,
{
   page: 7,
   name: "Explanaon of Yes answers 1_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 565.8,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 7,
   name: "Explanaon of Yes answers 2_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 579.12,
   left: 143.28,
   width: 427.32,
   height: 12.60
}
,
{
   page: 7,
   name: "Explanaon of Yes answers 3_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 592.56,
   left: 143.28,
   width: 427.32,
   height: 12.60
}
,
{
   page: 7,
   name: "Explanaon of Yes answers 4_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 606,
   left: 143.28,
   width: 427.32,
   height: 12.6
}
,
{
   page: 7,
   name: "address8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 32.341,
   left: 35.9778,
   width: 442.9522,
   height: 15.534
}
,
{
   page: 7,
   name: "date 8", 
   fontSize: 9,
   type: "date",
   top: 32.341,
   left: 482.411,
   width: 102.905,
   height: 15.534
}
,
{
   page: 7,
   name: "Check Box3900", 
   isText: false,
   type: "checkbox",
   top: 137.454,
   left: 468.327,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3901", 
   isText: false,
   type: "checkbox",
   top: 137.454,
   left: 504.454,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3910", 
   isText: false,
   type: "checkbox",
   top: 157.582,
   left: 468.327,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3911", 
   isText: false,
   type: "checkbox",
   top: 157.582,
   left: 504.454,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3920", 
   isText: false,
   type: "checkbox",
   top: 177.709,
   left: 468.327,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3921", 
   isText: false,
   type: "checkbox",
   top: 177.709,
   left: 504.454,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3930", 
   isText: false,
   type: "checkbox",
   top: 224.836,
   left: 468.327,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3931", 
   isText: false,
   type: "checkbox",
   top: 224.836,
   left: 504.454,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3940", 
   isText: false,
   type: "checkbox",
   top: 244.964,
   left: 468.327,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3941", 
   isText: false,
   type: "checkbox",
   top: 244.964,
   left: 504.454,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3950", 
   isText: false,
   type: "checkbox",
   top: 273.091,
   left: 468.327,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3951", 
   isText: false,
   type: "checkbox",
   top: 273.091,
   left: 504.454,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3952", 
   isText: false,
   type: "checkbox",
   top: 273.091,
   left: 539.582,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3960", 
   isText: false,
   type: "checkbox",
   top: 320.218,
   left: 468.327,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3961", 
   isText: false,
   type: "checkbox",
   top: 320.218,
   left: 504.454,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3970", 
   isText: false,
   type: "checkbox",
   top: 340.345,
   left: 468.327,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3971", 
   isText: false,
   type: "checkbox",
   top: 340.345,
   left: 504.454,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3980", 
   isText: false,
   type: "checkbox",
   top: 507.473,
   left: 468.327,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3981", 
   isText: false,
   type: "checkbox",
   top: 507.473,
   left: 504.454,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box3982", 
   isText: false,
   type: "checkbox",
   top: 507.473,
   left: 539.582,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4000", 
   isText: false,
   type: "checkbox",
   top: 401.236,
   left: 189.982,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4001", 
   isText: false,
   type: "checkbox",
   top: 401.236,
   left: 240.109,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4002", 
   isText: false,
   type: "checkbox",
   top: 401.236,
   left: 291.236,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4003", 
   isText: false,
   type: "checkbox",
   top: 401.236,
   left: 441.364,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4004", 
   isText: false,
   type: "checkbox",
   top: 401.236,
   left: 492.491,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4005", 
   isText: false,
   type: "checkbox",
   top: 401.236,
   left: 539.618,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4010", 
   isText: false,
   type: "checkbox",
   top: 421.363,
   left: 189.982,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4011", 
   isText: false,
   type: "checkbox",
   top: 421.363,
   left: 240.109,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4012", 
   isText: false,
   type: "checkbox",
   top: 421.363,
   left: 291.236,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4013", 
   isText: false,
   type: "checkbox",
   top: 421.363,
   left: 441.364,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4014", 
   isText: false,
   type: "checkbox",
   top: 421.363,
   left: 492.491,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4015", 
   isText: false,
   type: "checkbox",
   top: 421.363,
   left: 539.618,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4020", 
   isText: false,
   type: "checkbox",
   top: 454.491,
   left: 189.982,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4021", 
   isText: false,
   type: "checkbox",
   top: 454.491,
   left: 240.109,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4022", 
   isText: false,
   type: "checkbox",
   top: 454.491,
   left: 291.236,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4023", 
   isText: false,
   type: "checkbox",
   top: 454.491,
   left: 441.364,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4024", 
   isText: false,
   type: "checkbox",
   top: 454.491,
   left: 492.491,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4025", 
   isText: false,
   type: "checkbox",
   top: 454.491,
   left: 539.618,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4030", 
   isText: false,
   type: "checkbox",
   top: 488.618,
   left: 189.982,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4031", 
   isText: false,
   type: "checkbox",
   top: 488.618,
   left: 240.109,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box4032", 
   isText: false,
   type: "checkbox",
   top: 488.618,
   left: 291.236,
   width: 10.800,
   height: 10.800
}
,
{
   page: 7,
   name: "Check Box49", 
   isText: false,
   type: "checkbox",
   top: 551.182,
   left: 304.019,
   width: 10.800,
   height: 10.800
}
,
{
   page: 8,
   name: "fill_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 268.92,
   left: 337.8,
   width: 65.76,
   height: 12.60
}
,
{
   page: 8,
   name: "Date SurveyDate", 
   fontSize: 9,
   type: "date",
   top: 301.8,
   left: 131.28,
   width: 71.16,
   height: 12.6
}
,
{
   page: 8,
   name: "Date fill_4", 
   fontSize: 9,
   type: "date",
   top: 301.8,
   left: 356.28,
   width: 63.062,
   height: 12.6
}
,
{
   page: 8,
   name: "Date OtherDate", 
   fontSize: 9,
   type: "date",
   top: 301.8,
   left: 506.52,
   width: 64.889,
   height: 12.6
}
,
{
   page: 8,
   name: "Other_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 359.88,
   left: 104.52,
   width: 221.987,
   height: 12.60
}
,
{
   page: 8,
   name: "If YES what was the amount received", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 595.68,
   left: 247.68,
   width: 328.44,
   height: 12.60
}
,
{
   page: 8,
   name: "If YES what was the amount received_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 663.12,
   left: 245.04,
   width: 331.08,
   height: 12.60
}
,
{
   page: 8,
   name: "address9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 31.032,
   left: 35.9778,
   width: 442.9522,
   height: 16.843
}
,
{
   page: 8,
   name: "date 9", 
   fontSize: 9,
   type: "date",
   top: 31.032,
   left: 482.411,
   width: 102.905,
   height: 16.843
}
,
{
   page: 8,
   name: "Check Box4100", 
   isText: false,
   type: "checkbox",
   top: 148.582,
   left: 468.001,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4101", 
   isText: false,
   type: "checkbox",
   top: 148.582,
   left: 503.346,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4110", 
   isText: false,
   type: "checkbox",
   top: 167.709,
   left: 468.001,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4111", 
   isText: false,
   type: "checkbox",
   top: 167.709,
   left: 503.346,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4112", 
   isText: false,
   type: "checkbox",
   top: 167.709,
   left: 540.691,
   width: 10.910,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4120", 
   isText: false,
   type: "checkbox",
   top: 225.836,
   left: 468.001,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4121", 
   isText: false,
   type: "checkbox",
   top: 225.836,
   left: 503.346,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4130", 
   isText: false,
   type: "checkbox",
   top: 243.964,
   left: 468.001,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4131", 
   isText: false,
   type: "checkbox",
   top: 243.964,
   left: 503.346,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4132", 
   isText: false,
   type: "checkbox",
   top: 243.964,
   left: 540.691,
   width: 10.910,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4140", 
   isText: false,
   type: "checkbox",
   top: 430.091,
   left: 468.001,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4141", 
   isText: false,
   type: "checkbox",
   top: 430.091,
   left: 503.346,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4150", 
   isText: false,
   type: "checkbox",
   top: 457.218,
   left: 468.001,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4151", 
   isText: false,
   type: "checkbox",
   top: 457.218,
   left: 503.346,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4160", 
   isText: false,
   type: "checkbox",
   top: 530.346,
   left: 468.001,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4161", 
   isText: false,
   type: "checkbox",
   top: 530.346,
   left: 503.346,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4170", 
   isText: false,
   type: "checkbox",
   top: 558.473,
   left: 468.001,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4171", 
   isText: false,
   type: "checkbox",
   top: 558.473,
   left: 503.346,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4180", 
   isText: false,
   type: "checkbox",
   top: 578.6,
   left: 468.001,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4181", 
   isText: false,
   type: "checkbox",
   top: 578.6,
   left: 503.346,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box4190", 
   isText: false,
   type: "checkbox",
   top: 625.728,
   left: 468.001,
   width: 10.909,
   height: 10.690
}
,
{
   page: 8,
   name: "Check Box4191", 
   isText: false,
   type: "checkbox",
   top: 625.728,
   left: 503.346,
   width: 10.909,
   height: 10.690
}
,
{
   page: 8,
   name: "Check Box4192", 
   isText: false,
   type: "checkbox",
   top: 625.728,
   left: 540.691,
   width: 10.910,
   height: 10.690
}
,
{
   page: 8,
   name: "Check Box41100", 
   isText: false,
   type: "checkbox",
   top: 645.855,
   left: 468.001,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box41101", 
   isText: false,
   type: "checkbox",
   top: 645.855,
   left: 503.346,
   width: 10.909,
   height: 10.691
}
,
{
   page: 8,
   name: "Check Box41102", 
   isText: false,
   type: "checkbox",
   top: 645.855,
   left: 540.691,
   width: 10.910,
   height: 10.691
}
,
{
   page: 9,
   name: "If YES what was the amount received_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 141.36,
   left: 247.68,
   width: 328.44,
   height: 12.48
}
,
{
   page: 9,
   name: "If YES what was the amount received_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 208.2,
   left: 247.68,
   width: 328.44,
   height: 12.48
}
,
{
   page: 9,
   name: "If YES from which federal agency eg FEMA SBA", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 342.24,
   left: 306,
   width: 270.12,
   height: 12.48
}
,
{
   page: 9,
   name: "If YES what was the amount received_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 361.32,
   left: 247.68,
   width: 328.44,
   height: 12.48
}
,
{
   page: 9,
   name: "If YES what was the purpose of the assistance eg elevaon migaon restoraon", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 381.72,
   left: 459,
   width: 117.36,
   height: 12.48
}
,
{
   page: 9,
   name: "Queson Number 1_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 616.08,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 9,
   name: "Queson Number 2_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 629.52,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 9,
   name: "Queson Number 3_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 642.96,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 9,
   name: "Queson Number 4_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 656.4,
   left: 41.4,
   width: 87.84,
   height: 12.48
}
,
{
   page: 9,
   name: "Explanaon of Yes answers 1_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 616.08,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 9,
   name: "Explanaon of Yes answers 2_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 629.52,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 9,
   name: "Explanaon of Yes answers 3_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 642.96,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 9,
   name: "Explanaon of Yes answers 4_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 656.4,
   left: 143.28,
   width: 427.32,
   height: 12.48
}
,
{
   page: 9,
   name: "address10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 32.759,
   left: 35.9778,
   width: 442.9522,
   height: 15.534
}
,
{
   page: 9,
   name: "date 10", 
   fontSize: 9,
   type: "date",
   top: 32.759,
   left: 482.411,
   width: 102.905,
   height: 15.534
}
,
{
   page: 9,
   name: "Check Box4200", 
   isText: false,
   type: "checkbox",
   top: 103.527,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4201", 
   isText: false,
   type: "checkbox",
   top: 103.527,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4210", 
   isText: false,
   type: "checkbox",
   top: 123.545,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4211", 
   isText: false,
   type: "checkbox",
   top: 123.545,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4220", 
   isText: false,
   type: "checkbox",
   top: 171.564,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4221", 
   isText: false,
   type: "checkbox",
   top: 171.564,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4222", 
   isText: false,
   type: "checkbox",
   top: 171.564,
   left: 540.036,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4230", 
   isText: false,
   type: "checkbox",
   top: 191.582,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4231", 
   isText: false,
   type: "checkbox",
   top: 191.582,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4232", 
   isText: false,
   type: "checkbox",
   top: 191.582,
   left: 540.036,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4240", 
   isText: false,
   type: "checkbox",
   top: 324.6,
   left: 468.0,
   width: 10.8,
   height: 10.8
}
,
{
   page: 9,
   name: "Check Box4241", 
   isText: false,
   type: "checkbox",
   top: 324.6,
   left: 504.018,
   width: 10.800,
   height: 10.8
}
,
{
   page: 9,
   name: "Check Box4242", 
   isText: false,
   type: "checkbox",
   top: 324.6,
   left: 540.036,
   width: 10.800,
   height: 10.8
}
,
{
   page: 9,
   name: "Check Box4250", 
   isText: false,
   type: "checkbox",
   top: 430.618,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4251", 
   isText: false,
   type: "checkbox",
   top: 430.618,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4260", 
   isText: false,
   type: "checkbox",
   top: 457.636,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4261", 
   isText: false,
   type: "checkbox",
   top: 457.636,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4262", 
   isText: false,
   type: "checkbox",
   top: 457.636,
   left: 540.036,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4270", 
   isText: false,
   type: "checkbox",
   top: 510.654,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4271", 
   isText: false,
   type: "checkbox",
   top: 510.654,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4272", 
   isText: false,
   type: "checkbox",
   top: 510.654,
   left: 540.036,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4280", 
   isText: false,
   type: "checkbox",
   top: 577.673,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4281", 
   isText: false,
   type: "checkbox",
   top: 577.673,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box4282", 
   isText: false,
   type: "checkbox",
   top: 577.673,
   left: 540.036,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box48", 
   isText: false,
   type: "checkbox",
   top: 604.146,
   left: 305.673,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "date 11", 
   fontSize: 9,
   type: "date",
   top: 31.032,
   left: 482.411,
   width: 102.905,
   height: 16.843
}
,
{
   page: 10,
   name: "address11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 31.686,
   left: 35.9778,
   width: 442.9522,
   height: 16.189
}
,
{
   page: 10,
   name: "Check Box4300", 
   isText: false,
   type: "checkbox",
   top: 144.873,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4301", 
   isText: false,
   type: "checkbox",
   top: 144.873,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "39 What is the zoning of the property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 169.56,
   left: 216,
   width: 360.36,
   height: 12.60
}
,
{
   page: 10,
   name: "Check Box4310", 
   isText: false,
   type: "checkbox",
   top: 191.891,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4311", 
   isText: false,
   type: "checkbox",
   top: 191.891,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4312", 
   isText: false,
   type: "checkbox",
   top: 191.891,
   left: 540.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4320", 
   isText: false,
   type: "checkbox",
   top: 220.909,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4321", 
   isText: false,
   type: "checkbox",
   top: 220.909,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4322", 
   isText: false,
   type: "checkbox",
   top: 220.909,
   left: 540.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "If yes which historic district", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 238.32,
   left: 185.52,
   width: 273.84,
   height: 12.60
}
,
{
   page: 10,
   name: "Check Box4330", 
   isText: false,
   type: "checkbox",
   top: 280.927,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4331", 
   isText: false,
   type: "checkbox",
   top: 280.927,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4340", 
   isText: false,
   type: "checkbox",
   top: 308.945,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4341", 
   isText: false,
   type: "checkbox",
   top: 308.945,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4350", 
   isText: false,
   type: "checkbox",
   top: 349.964,
   left: 468.0,
   width: 10.8,
   height: 10.799
}
,
{
   page: 10,
   name: "Check Box4351", 
   isText: false,
   type: "checkbox",
   top: 349.964,
   left: 504.018,
   width: 10.800,
   height: 10.799
}
,
{
   page: 10,
   name: "Check Box4360", 
   isText: false,
   type: "checkbox",
   top: 368.982,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4361", 
   isText: false,
   type: "checkbox",
   top: 368.982,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4370", 
   isText: false,
   type: "checkbox",
   top: 387.0,
   left: 468.0,
   width: 10.8,
   height: 10.8
}
,
{
   page: 10,
   name: "Check Box4371", 
   isText: false,
   type: "checkbox",
   top: 387.0,
   left: 504.018,
   width: 10.800,
   height: 10.8
}
,
{
   page: 10,
   name: "Check Box4372", 
   isText: false,
   type: "checkbox",
   top: 387.0,
   left: 540.145,
   width: 10.800,
   height: 10.8
}
,
{
   page: 10,
   name: "c", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 424.2,
   left: 54,
   width: 522.12,
   height: 12.6
}
,
{
   page: 10,
   name: "Check Box440", 
   isText: false,
   type: "checkbox",
   top: 573.3,
   left: 402.218,
   width: 10.800,
   height: 10.8
}
,
{
   page: 10,
   name: "Check Box441", 
   isText: false,
   type: "checkbox",
   top: 573.3,
   left: 468.182,
   width: 10.800,
   height: 10.8
}
,
{
   page: 10,
   name: "Check Box442", 
   isText: false,
   type: "checkbox",
   top: 573.3,
   left: 542.145,
   width: 10.800,
   height: 10.8
}
,
{
   page: 10,
   name: "Check Box4380", 
   isText: false,
   type: "checkbox",
   top: 618.018,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4381", 
   isText: false,
   type: "checkbox",
   top: 618.018,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4382", 
   isText: false,
   type: "checkbox",
   top: 618.018,
   left: 540.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4390", 
   isText: false,
   type: "checkbox",
   top: 636.036,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4391", 
   isText: false,
   type: "checkbox",
   top: 636.036,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box4392a", 
   isText: false,
   type: "checkbox",
   top: 636.036,
   left: 540.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box43100yes", 
   isText: false,
   type: "checkbox",
   top: 655.054,
   left: 468.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box43101no", 
   isText: false,
   type: "checkbox",
   top: 655.054,
   left: 504.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box43101NK", 
   isText: false,
   type: "checkbox",
   top: 655.054,
   left: 540.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "address12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 31.759,
   left: 35.9778,
   width: 442.9522,
   height: 16.189
}
,
{
   page: 11,
   name: "date 12", 
   fontSize: 9,
   type: "date",
   top: 31.759,
   left: 482.411,
   width: 102.905,
   height: 16.189
}
,
{
   page: 11,
   name: "Check Box4600", 
   isText: false,
   type: "checkbox",
   top: 83.945,
   left: 468.082,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4601", 
   isText: false,
   type: "checkbox",
   top: 83.945,
   left: 504.045,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4602", 
   isText: false,
   type: "checkbox",
   top: 83.945,
   left: 541.009,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4610", 
   isText: false,
   type: "checkbox",
   top: 124.909,
   left: 468.082,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4611", 
   isText: false,
   type: "checkbox",
   top: 124.909,
   left: 504.045,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4612", 
   isText: false,
   type: "checkbox",
   top: 124.909,
   left: 541.009,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4620", 
   isText: false,
   type: "checkbox",
   top: 164.873,
   left: 468.082,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4621", 
   isText: false,
   type: "checkbox",
   top: 164.873,
   left: 504.045,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4630", 
   isText: false,
   type: "checkbox",
   top: 184.836,
   left: 468.082,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4631", 
   isText: false,
   type: "checkbox",
   top: 184.836,
   left: 504.045,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4632", 
   isText: false,
   type: "checkbox",
   top: 184.836,
   left: 541.009,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4500", 
   isText: false,
   type: "checkbox",
   top: 245.782,
   left: 190.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4501", 
   isText: false,
   type: "checkbox",
   top: 245.782,
   left: 241.273,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4502", 
   isText: false,
   type: "checkbox",
   top: 245.782,
   left: 288.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4503", 
   isText: false,
   type: "checkbox",
   top: 245.782,
   left: 443.527,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4504", 
   isText: false,
   type: "checkbox",
   top: 245.782,
   left: 488.654,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4505", 
   isText: false,
   type: "checkbox",
   top: 245.782,
   left: 534.782,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4510", 
   isText: false,
   type: "checkbox",
   top: 265.909,
   left: 190.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4511", 
   isText: false,
   type: "checkbox",
   top: 265.909,
   left: 241.273,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4512", 
   isText: false,
   type: "checkbox",
   top: 265.909,
   left: 288.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4513", 
   isText: false,
   type: "checkbox",
   top: 265.909,
   left: 443.527,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4514", 
   isText: false,
   type: "checkbox",
   top: 265.909,
   left: 488.654,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4515", 
   isText: false,
   type: "checkbox",
   top: 265.909,
   left: 534.782,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4520", 
   isText: false,
   type: "checkbox",
   top: 299.036,
   left: 190.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4521", 
   isText: false,
   type: "checkbox",
   top: 299.036,
   left: 241.273,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4522", 
   isText: false,
   type: "checkbox",
   top: 299.036,
   left: 288.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4523", 
   isText: false,
   type: "checkbox",
   top: 299.036,
   left: 443.527,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4524", 
   isText: false,
   type: "checkbox",
   top: 299.036,
   left: 488.654,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4525", 
   isText: false,
   type: "checkbox",
   top: 299.036,
   left: 534.782,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4530", 
   isText: false,
   type: "checkbox",
   top: 319.163,
   left: 190.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4531", 
   isText: false,
   type: "checkbox",
   top: 319.163,
   left: 241.273,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4532", 
   isText: false,
   type: "checkbox",
   top: 319.163,
   left: 288.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4533", 
   isText: false,
   type: "checkbox",
   top: 319.163,
   left: 443.527,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4534", 
   isText: false,
   type: "checkbox",
   top: 319.163,
   left: 488.654,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4535", 
   isText: false,
   type: "checkbox",
   top: 319.163,
   left: 534.782,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4540", 
   isText: false,
   type: "checkbox",
   top: 339.291,
   left: 190.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4541", 
   isText: false,
   type: "checkbox",
   top: 339.291,
   left: 241.273,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4542", 
   isText: false,
   type: "checkbox",
   top: 339.291,
   left: 288.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4543", 
   isText: false,
   type: "checkbox",
   top: 339.291,
   left: 443.527,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4544", 
   isText: false,
   type: "checkbox",
   top: 339.291,
   left: 488.654,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4545", 
   isText: false,
   type: "checkbox",
   top: 339.291,
   left: 534.782,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4550", 
   isText: false,
   type: "checkbox",
   top: 372.418,
   left: 190.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4551", 
   isText: false,
   type: "checkbox",
   top: 372.418,
   left: 241.273,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4552", 
   isText: false,
   type: "checkbox",
   top: 372.418,
   left: 288.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4553", 
   isText: false,
   type: "checkbox",
   top: 372.418,
   left: 443.527,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4554", 
   isText: false,
   type: "checkbox",
   top: 372.418,
   left: 488.654,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4555", 
   isText: false,
   type: "checkbox",
   top: 372.418,
   left: 534.782,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4560", 
   isText: false,
   type: "checkbox",
   top: 405.545,
   left: 190.145,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4561", 
   isText: false,
   type: "checkbox",
   top: 405.545,
   left: 241.273,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4562", 
   isText: false,
   type: "checkbox",
   top: 405.545,
   left: 288.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4640", 
   isText: false,
   type: "checkbox",
   top: 458.8,
   left: 468.082,
   width: 10.800,
   height: 10.8
}
,
{
   page: 11,
   name: "Check Box4641", 
   isText: false,
   type: "checkbox",
   top: 458.8,
   left: 504.045,
   width: 10.800,
   height: 10.8
}
,
{
   page: 11,
   name: "Check Box4642", 
   isText: false,
   type: "checkbox",
   top: 458.8,
   left: 541.009,
   width: 10.800,
   height: 10.8
}
,
{
   page: 11,
   name: "Check Box4650", 
   isText: false,
   type: "checkbox",
   top: 499.764,
   left: 468.082,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4651", 
   isText: false,
   type: "checkbox",
   top: 499.764,
   left: 504.045,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4652", 
   isText: false,
   type: "checkbox",
   top: 499.764,
   left: 541.009,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4660", 
   isText: false,
   type: "checkbox",
   top: 520.727,
   left: 468.082,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4661", 
   isText: false,
   type: "checkbox",
   top: 520.727,
   left: 504.045,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box4662", 
   isText: false,
   type: "checkbox",
   top: 520.727,
   left: 541.009,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Check Box47", 
   isText: false,
   type: "checkbox",
   top: 545.873,
   left: 303.273,
   width: 10.800,
   height: 10.800
}
,
{
   page: 11,
   name: "Queson Number 1_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 558.909,
   left: 42.0546,
   width: 54.0000,
   height: 12.480
}
,
{
   page: 11,
   name: "Explanaon of Yes answers 1_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 558.909,
   left: 105.971,
   width: 475.538,
   height: 12.480
}
,
{
   page: 11,
   name: "Queson Number 2_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 573.068,
   left: 41.4,
   width: 54.0,
   height: 12.480
}
,
{
   page: 11,
   name: "Explanaon of Yes answers 2_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 573.068,
   left: 105.971,
   width: 475.538,
   height: 12.480
}
,
{
   page: 11,
   name: "Queson Number 3_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 587.227,
   left: 41.4,
   width: 54.0,
   height: 12.480
}
,
{
   page: 11,
   name: "Explanaon of Yes answers 3_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 587.227,
   left: 105.971,
   width: 475.538,
   height: 12.480
}
,
{
   page: 11,
   name: "Queson Number 4_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 601.386,
   left: 41.4,
   width: 54.0,
   height: 12.480
}
,
{
   page: 11,
   name: "Explanaon of Yes answers 4_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 601.386,
   left: 105.971,
   width: 475.538,
   height: 12.480
}
,
{
   page: 11,
   name: "Text22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 615.545,
   left: 41.4,
   width: 54.0,
   height: 12.480
}
,
{
   page: 11,
   name: "Text23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 615.545,
   left: 105.971,
   width: 475.538,
   height: 12.480
}
,
{
   page: 11,
   name: "Text24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 629.704,
   left: 41.4,
   width: 54.0,
   height: 12.480
}
,
{
   page: 11,
   name: "Text25", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 629.704,
   left: 105.971,
   width: 475.538,
   height: 12.480
}
,
{
   page: 11,
   name: "Text26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 643.863,
   left: 41.4,
   width: 54.0,
   height: 12.480
}
,
{
   page: 11,
   name: "Text27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 643.863,
   left: 105.971,
   width: 475.538,
   height: 12.480
}
,
{
   page: 11,
   name: "Text28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 658.022,
   left: 41.4,
   width: 54.0,
   height: 12.480
}
,
{
   page: 11,
   name: "Text29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 658.022,
   left: 105.971,
   width: 475.538,
   height: 12.480
}
,
{
   page: 11,
   name: "Text30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 672.181,
   left: 41.4,
   width: 54.0,
   height: 12.480
}
,
{
   page: 11,
   name: "Text31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 684.661,
   left: 105.971,
   width: 475.538,
   height: 12.480
}
,
{
   page: 12,
   name: "address13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 33.759,
   left: 35.9778,
   width: 442.9522,
   height: 14.880
}
,
{
   page: 12,
   name: "date 13", 
   fontSize: 9,
   type: "date",
   top: 33.759,
   left: 482.411,
   width: 102.905,
   height: 15.534
}
,
{
   page: 12,
   name: "print_9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 231.36,
   left: 421.193,
   width: 155.291,
   height: 11.52
}
,
{
   page: 12,
   name: "print_10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 270.131,
   left: 421.193,
   width: 155.291,
   height: 11.520
}
,
{
   page: 12,
   name: "print_11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 285.491,
   left: 421.193,
   width: 155.291,
   height: 11.520
}
,
{
   page: 12,
   name: "print_12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 323.545,
   left: 421.193,
   width: 155.291,
   height: 11.520
}
,
{
   page: 12,
   name: "print_13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 391.4,
   left: 421.193,
   width: 155.291,
   height: 11.52
}
,
{
   page: 12,
   name: "print_14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 418.16,
   left: 421.193,
   width: 155.291,
   height: 11.52
}
,
{
   page: 12,
   name: "print_15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 445.04,
   left: 421.193,
   width: 155.291,
   height: 11.52
}
,
{
   page: 12,
   name: "print_16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 471.92,
   left: 421.193,
   width: 155.291,
   height: 11.52
}
,
{
   page: 12,
   name: "Text14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 231.71,
   left: 343.947,
   width: 45.927,
   height: 11.527
}
,
{
   page: 12,
   name: "Text15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 257.892,
   left: 342.983,
   width: 45.927,
   height: 11.527
}
,
{
   page: 12,
   name: "Text16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 285.383,
   left: 342.983,
   width: 45.927,
   height: 11.527
}
,
{
   page: 12,
   name: "Text17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 310.91,
   left: 342.983,
   width: 45.927,
   height: 11.528
}
,
{
   page: 12,
   name: "Text18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 378.329,
   left: 342.983,
   width: 45.927,
   height: 11.527
}
,
{
   page: 12,
   name: "Text19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 405.511,
   left: 342.983,
   width: 45.927,
   height: 11.527
}
,
{
   page: 12,
   name: "Text20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 433.311,
   left: 342.983,
   width: 45.927,
   height: 11.527
}
,
{
   page: 12,
   name: "Text21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 459.493,
   left: 342.983,
   width: 45.927,
   height: 11.527
}
] }
