import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { createPeopleOptions, filterPeopleByName } from "../util/util";
import FormParty from "./FormParty";
import MyCheckbox from "./MyCheckbox";
import MySelectInput from "./MySelectInput";
import MyTextInput from "./MyTextInput";
import MyEmailInput from "./MyEmailInput";

export default function FormPeopleAutofill({
  people,
  type,
  hasAssistant,
  fieldKey = "",
  edit = false,
  setIsTrustField,
  disableAutofill = false,
}) {
  const peopleOptions = createPeopleOptions(people, type);
  const [partyHasAssistant, setPartyHasAssistant] = useState(hasAssistant);
  function handleAssistant() {
    setPartyHasAssistant(!partyHasAssistant);
  }

  function Watcher() {
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      if (values.personSelected) {
        const selectedPerson = filterPeopleByName(
          people,
          values.personSelected?.split("|")[0],
          values.personSelected?.split("|")[1]
        );
        setFieldValue(fieldKey + "firstName", selectedPerson.firstName);
        setFieldValue(fieldKey + "middleName", selectedPerson.middleName);
        setFieldValue(fieldKey + "lastName", selectedPerson.lastName);
        setFieldValue(fieldKey + "email", selectedPerson.email);
        setFieldValue(fieldKey + "phone", selectedPerson.phone);
        const street = selectedPerson.address?.street
          ? selectedPerson.address.street
          : "";
        setFieldValue("address.street", street);
        const unit = selectedPerson.address?.unit
          ? selectedPerson.address.unit
          : "";
        setFieldValue("address.unit", unit);
        const city = selectedPerson.address?.city
          ? selectedPerson.address.city
          : "";
        setFieldValue("address.city", city);
        const state = selectedPerson.address?.state
          ? selectedPerson.address.state
          : "";
        setFieldValue("address.state", state);
        const zipcode = selectedPerson.address?.zipcode
          ? selectedPerson.address.zipcode
          : "";
        setFieldValue("address.zipcode", zipcode);
        setFieldValue("selectedRole", selectedPerson.role);
        const companyName = selectedPerson.companyName
          ? selectedPerson.companyName
          : "";
        setFieldValue("companyName", companyName);
        setIsTrustField(selectedPerson.isTrust || false);
        setFieldValue(
          "isTrust",
          selectedPerson.isTrust ? selectedPerson.isTrust : false
        );
        const entityName = selectedPerson.entityName
          ? selectedPerson.entityName
          : "";
        setFieldValue("entityName", entityName);

        setFieldValue(
          "hasAssistant",
          selectedPerson.hasAssistant ? selectedPerson.hasAssistant : false
        );
        if (selectedPerson.hasAssistant) {
          setFieldValue(
            "assistant.firstName",
            selectedPerson.assistant?.firstName
          );
          setFieldValue(
            "assistant.lastName",
            selectedPerson.assistant?.lastName
          );
          setFieldValue("assistant.email", selectedPerson.assistant?.email);
          setFieldValue("assistant.phone", selectedPerson.assistant?.phone);
        } else {
          setFieldValue("assistant.firstName", "");
          setFieldValue("assistant.lastName", "");
          setFieldValue("assistant.email", "");
          setFieldValue("assistant.phone", "");
        }
        setPartyHasAssistant(
          selectedPerson.hasAssistant ? selectedPerson.hasAssistant : false
        );
        if (selectedPerson?.brokerLicenseNumber) {
          setFieldValue(
            "brokerLicenseNumber",
            selectedPerson.brokerLicenseNumber
          );
        }
        if (selectedPerson?.brokerageId) {
          setFieldValue("brokerageId", selectedPerson.brokerageId);
        }
        if (selectedPerson?.brokerageLicenseNumber) {
          setFieldValue(
            "brokerageLicenseNumber",
            selectedPerson.brokerageLicenseNumber
          );
        }
        if (selectedPerson?.brokerageName) {
          setFieldValue("brokerageName", selectedPerson.brokerageName);
        }
        setFieldValue("personSelected", "");
      }
    }, [values.personSelected, setFieldValue]);
    return null;
  }

  return (
    <>
      <Watcher />
      {!disableAutofill && (
        <Grid>
          <Grid.Row className="mini vertical padding">
            <Grid.Column mobile={16} computer={5}>
              <MySelectInput
                name="personSelected"
                placeholder="Select existing person"
                options={peopleOptions}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <FormParty edit={edit} />
      {type !== "Client" && (
        <Grid>
          <Grid.Row className="tiny top padding tiny bottom padding">
            <Grid.Column width={16}>
              <MyCheckbox
                name="hasAssistant"
                label="This party has an assistant"
                onClick={() => handleAssistant()}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {type !== "Client" && partyHasAssistant && (
        <Grid>
          <Grid.Row className="tiny vertical padding">
            <Grid.Column mobile={16} computer={4}>
              <MyTextInput
                name="assistant.firstName"
                label="Assistant first name"
              />
            </Grid.Column>
            <Grid.Column mobile={16} computer={4}>
              <MyTextInput
                name="assistant.lastName"
                label="Assistant last name"
              />
            </Grid.Column>
            <Grid.Column mobile={16} computer={4}>
              <MyEmailInput name="assistant.email" label="Assistant email" />
            </Grid.Column>
            <Grid.Column mobile={16} computer={4}>
              <MyTextInput name="assistant.phone" label="Assistant phone" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
}
