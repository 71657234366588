import { isPast, isSameDay, parseISO } from "date-fns";
import React from "react";
import { Icon, Table } from "semantic-ui-react";
import { dateFormatDeadline, truncateText } from "../../../app/common/util/util";
import DocActionButtons from "../docComponents/DocActionButtons";

export default function DocAllAwaitingSignatureListItem({ doc }) {
  return (
    <Table.Row
      key={doc.id}
      className={
        (isSameDay(parseISO(doc.signingDueAt), new Date()) &&
          "background-blue") ||
        (isPast(parseISO(doc.signingDueAt)) && "background-yellow") ||
        ""
      }
    >
      <Table.Cell>
        <Icon name="file pdf outline" size="large" />
        &nbsp;
        {truncateText(doc.name, 40)}
      </Table.Cell>
      <Table.Cell>
        <span>{doc.transactionTitle}</span>
      </Table.Cell>
      <Table.Cell>
        <span>
          {doc.signingSentOutAt && dateFormatDeadline(doc.signingSentOutAt)}
        </span>
      </Table.Cell>
      <Table.Cell>
        <DocActionButtons doc={doc} />
      </Table.Cell>
    </Table.Row>
  );
}
