export function manufacturedHomeCBSLotLease2024() {
    return [   //2024 Release 2024-01-13 09:34:55
    {
        page: 0,
        name: "Logo",
        isText: false,
        fontSize: 10,
        type: "logo",
        top: 28.673,
        left: 52.9457,
        width: 240,
        height: 36,
      }
      ,
      {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 210.556,
       left: 506.28,
       width: 69.96,
       height: 10.364
    }
    ,
    {
       page: 0,
       name: "will take title to the Home described below as", 
       isText: false,
       type: "checkbox",
       top: 330.84,
       left: 176.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "No Assignability This Contract IS NOT assignable by Buyer unless otherwise specified in Additional Provisions", 
       isText: false,
       type: "checkbox",
       top: 330.84,
       left: 94.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Other", 
       isText: false,
       type: "checkbox",
       top: 330.84,
       left: 287.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Manufacturer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 469.68,
       left: 179.4,
       width: 382.68,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Model", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 487.68,
       left: 179.4,
       width: 382.68,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Serial No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 505.68,
       left: 179.4,
       width: 382.68,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Size", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 523.68,
       left: 179.4,
       width: 382.68,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Year", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 541.68,
       left: 179.4,
       width: 382.68,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Certificate No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 559.68,
       left: 179.4,
       width: 382.68,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Manufacturer Tag No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 577.68,
       left: 179.4,
       width: 382.68,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "VIN No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 595.68,
       left: 179.4,
       width: 382.68,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Lot The Home is located on the below lot Lot located in the County of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 636.316,
       left: 413.04,
       width: 135.00,
       height: 10.364
    }
    ,
    {
       page: 0,
       name: "Space No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 672.24,
       left: 176.52,
       width: 385.56,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Mobile Home Park", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 690.24,
       left: 176.52,
       width: 385.56,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "MHC1623 MANUFACTURED HOME CONTRACT TO BUY AND SELL", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 717.8981,
       left: 150.109,
       width: 408.317,
       height: 12.3273
    }
    ,
    {
       page: 0,
       name: "Text109", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 302.401,
       left: 123.055,
       width: 415.747,
       height: 13.491
    }
    ,
    {
       page: 0,
       name: "Text110", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 328.583,
       left: 329.892,
       width: 234.437,
       height: 13.491
    }
    ,
    {
       page: 0,
       name: "Text111", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 358.693,
       left: 126.328,
       width: 417.056,
       height: 14.145
    }
    ,
    {
       page: 0,
       name: "Text112", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 405.165,
       left: 68.7276,
       width: 506.0744,
       height: 39.673
    }
    ,
    {
       page: 1,
       name: "dishes If any additional items are attached to the Home after the date of this Contract such additional items are also", 
       isText: false,
       type: "checkbox",
       top: 138.6,
       left: 98.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Solar Panels", 
       isText: false,
       type: "checkbox",
       top: 138.6,
       left: 143.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Water Softeners", 
       isText: false,
       type: "checkbox",
       top: 138.6,
       left: 219.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Security Systems", 
       isText: false,
       type: "checkbox",
       top: 138.6,
       left: 312.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Satellite Systems including satellite", 
       isText: false,
       type: "checkbox",
       top: 138.6,
       left: 407.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "274 Conditional on Buyers Review Buyer has the right to review the Lot Lease and the Lot Owner Approval Buyer", 
       isText: false,
       type: "checkbox",
       top: 615.96,
       left: 110.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Seller", 
       isText: false,
       type: "checkbox",
       top: 615.96,
       left: 159,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Half by Buyer and Half by Seller", 
       isText: false,
       type: "checkbox",
       top: 615.96,
       left: 206.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "None", 
       isText: false,
       type: "checkbox",
       top: 615.96,
       left: 363,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Text113", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 269.674,
       left: 80.5094,
       width: 492.9836,
       height: 33.127
    }
    ,
    {
       page: 1,
       name: "Text114", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 314.183,
       left: 79.5458,
       width: 493.6382,
       height: 46.873
    }
    ,
    {
       page: 1,
       name: "Text115", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 371.783,
       left: 80.5095,
       width: 492.9835,
       height: 44.910
    }
    ,
    {
       page: 1,
       name: "Text116", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 441.82,
       left: 80.5094,
       width: 494.2926,
       height: 32.473
    }
    ,
    {
       page: 1,
       name: "Text117", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 486.329,
       left: 68.7276,
       width: 505.4194,
       height: 35.091
    }
    ,
    {
       page: 2,
       name: " 3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 116.749,
       left: 404.696,
       width: 170.161,
       height: 9.077
    }
    ,
    {
       page: 2,
       name: "Date Alternative Earnest Money Deadline", 
       fontSize: 9,
       type: "date",
       top: 127.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "2Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 151.531,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: " 4Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 127.004,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Date UCC and Certificate of Title Deadline", 
       fontSize: 9,
       type: "date",
       top: 151.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date UCC and Certificate of Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 163.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date UCC and Certificate of Title Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 175.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "5Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 199.531,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: " 7Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 175.004,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Date Lease Delivery Deadline", 
       fontSize: 9,
       type: "date",
       top: 199.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Lot Owner Approval Deadline", 
       fontSize: 9,
       type: "date",
       top: 211.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Lease Review Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 223.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "8Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 247.531,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: " 2Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 223.004,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Date Sellers Property Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 247.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date LeadBased Paint Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 259.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "10Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 283.531,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: " 8Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 259.004,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Date New Loan Application Deadline", 
       fontSize: 9,
       type: "date",
       top: 283.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date New Loan Terms Deadline", 
       fontSize: 9,
       type: "date",
       top: 295.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date New Loan Availability Deadline", 
       fontSize: 9,
       type: "date",
       top: 307.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Seller or Private Financing Deadline", 
       fontSize: 9,
       type: "date",
       top: 319.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "14Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 343.531,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: " 4Row1_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 319.004,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Date Appraisal Deadline", 
       fontSize: 9,
       type: "date",
       top: 343.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Appraisal Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 355.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Appraisal Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 367.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "17Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 391.531,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: " 6Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 367.004,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Date Inspection Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 391.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Inspection Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 403.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Inspection Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 415.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Home Insurance Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 427.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Due Diligence Documents Delivery Deadline", 
       fontSize: 9,
       type: "date",
       top: 439.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Due Diligence Documents Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 451.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Due Diligence Documents Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 463.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Conditional Sale Deadline", 
       fontSize: 9,
       type: "date",
       top: 475.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date LeadBased Paint Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 487.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Closing Date", 
       fontSize: 9,
       type: "date",
       top: 511.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Possession Date", 
       fontSize: 9,
       type: "date",
       top: 523.44,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date Acceptance Deadline Date", 
       fontSize: 9,
       type: "date",
       top: 547.2,
       left: 324.84,
       width: 75.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "31Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 571.2,
       left: 45.72,
       width: 26.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: " 24Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 571.2,
       left: 72.84,
       width: 30.36,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Acceptance Deadline TimeRow1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 571.2,
       left: 104.28,
       width: 219.48,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "31Row2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 583.32,
       left: 45.84,
       width: 25.80,
       height: 11.16
    }
    ,
    {
       page: 2,
       name: " 24Row2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 583.32,
       left: 72.96,
       width: 30.12,
       height: 11.16
    }
    ,
    {
       page: 2,
       name: "Acceptance Deadline TimeRow2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 583.32,
       left: 104.4,
       width: 219.24,
       height: 11.16
    }
    ,
    {
       page: 2,
       name: "Text118", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 162.983,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text119", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 211.419,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text120", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 295.856,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text121", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 356.074,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text122", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 403.856,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text123", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 416.293,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text124", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 428.729,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text125", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 439.856,
       left: 404.696,
       width: 170.161,
       height: 11.041
    }
    ,
    {
       page: 2,
       name: "Text126", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 450.984,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text127", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 463.42,
       left: 404.696,
       width: 170.161,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Text128", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 475.857,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text129", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 488.293,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text130", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 510.548,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text131", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 523.639,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text132", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 536.73,
       left: 404.696,
       width: 170.161,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Text133", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 547.202,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text134", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 559.639,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text135", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 572.075,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text136", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 583.203,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Date Text137", 
       fontSize: 9,
       type: "date",
       top: 572.075,
       left: 324.84,
       width: 75.48,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Date Text138", 
       fontSize: 9,
       type: "date",
       top: 584.512,
       left: 324.84,
       width: 75.48,
       height: 11.040
    }
    ,
    {
       page: 2,
       name: "Text139", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 307.638,
       left: 404.696,
       width: 170.161,
       height: 11.040
    }
    ,
    {
       page: 3,
       name: "333 Deadlines If any deadline falls on a Saturday Sunday or federal or Colorado state holiday Holiday such", 
       isText: false,
       type: "checkbox",
       top: 123.96,
       left: 180.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "box be checked the deadline will not be extended", 
       isText: false,
       type: "checkbox",
       top: 123.96,
       left: 138.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Item", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 380.292,
       left: 419.03,
       width: 144.906,
       height: 10.385
    }
    ,
    {
       page: 3,
       name: "Purchase Price", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 205.865,
       left: 348.426,
       width: 84.480,
       height: 12.360
    }
    ,
    {
       page: 3,
       name: "Amount", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 206.52,
       left: 435.84,
       width: 98.28,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "Earnest Money", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 220.269,
       left: 447.971,
       width: 85.386,
       height: 11.051
    }
    ,
    {
       page: 3,
       name: "New Loan", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 233.4,
       left: 447.971,
       width: 85.386,
       height: 11.051
    }
    ,
    {
       page: 3,
       name: "Private Financing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 246.84,
       left: 447.971,
       width: 85.386,
       height: 11.051
    }
    ,
    {
       page: 3,
       name: "Seller Financing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 260.28,
       left: 447.971,
       width: 85.386,
       height: 11.051
    }
    ,
    {
       page: 3,
       name: " 466", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 273.72,
       left: 131.52,
       width: 52.68,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "Seller Financing6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 273.72,
       left: 185.52,
       width: 147.24,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 273.72,
       left: 334.08,
       width: 100.44,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "6_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 273.72,
       left: 435.84,
       width: 98.28,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: " 467", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 287.16,
       left: 131.52,
       width: 52.68,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "Seller Financing7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 287.16,
       left: 185.52,
       width: 147.24,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 287.16,
       left: 334.08,
       width: 100.44,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "7_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 287.16,
       left: 435.84,
       width: 98.28,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "Cash at Closing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 300.6,
       left: 334.08,
       width: 100.44,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "fill_27", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 300.6,
       left: 445.2,
       width: 88.92,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "TOTAL", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 392.622,
       left: 216.425,
       width: 190.954,
       height: 12.360
    }
    ,
    {
       page: 3,
       name: "fill_29", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 314.04,
       left: 343.44,
       width: 91.08,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "fill_30", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 314.04,
       left: 445.2,
       width: 88.92,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "Concession may be used for any Buyer fee cost charge or expenditure to the extent the amount is allowed by the", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 329.738,
       left: 354.0,
       width: 84.96,
       height: 13.582
    }
    ,
    {
       page: 4,
       name: "Closing OR SUCH NONPAYING PARTY WILL BE IN DEFAULT Buyer represents that Buyer as of the date of this", 
       isText: false,
       type: "checkbox",
       top: 150.36,
       left: 141.48,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "the amount stated as Cash at Closing in  41", 
       isText: false,
       type: "checkbox",
       top: 150.36,
       left: 184.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "461 Seller Financing If Buyer is to pay all or any portion of the Purchase Price with Seller financing", 
       isText: false,
       type: "checkbox",
       top: 313.8,
       left: 112.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "days before Seller or Private Financing Deadline", 
       isText: false,
       type: "checkbox",
       top: 313.8,
       left: 161.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Text140", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 246.11,
       left: 257.237,
       width: 267.819,
       height: 11.527
    }
    ,
    {
       page: 4,
       name: "Text152", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 325.965,
       left: 96.2186,
       width: 41.3454,
       height: 13.491
    }
    ,
    {
       page: 5,
       name: "Cost of Appraisal Cost of the Appraisal to be obtained after the date of this Contract must be timely paid by", 
       isText: false,
       type: "checkbox",
       top: 339,
       left: 144.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "management company lenders agent or all three", 
       isText: false,
       type: "checkbox",
       top: 339,
       left: 94.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "Occupancy Agreements", 
       isText: false,
       type: "checkbox",
       top: 575.16,
       left: 155.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "Leased Items Documents If any lease of personal property  255 Leased Items will be", 
       isText: false,
       type: "checkbox",
       top: 682.92,
       left: 155.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "Will", 
       isText: false,
       type: "checkbox",
       top: 722.76,
       left: 151.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "Will Not assume the Sellers obligations under such leases for the Leased Items  256", 
       isText: false,
       type: "checkbox",
       top: 722.76,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "Text141", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 613.966,
       left: 102.764,
       width: 461.565,
       height: 65.855
    }
    ,
    {
       page: 7,
       name: "Encumbered Inclusions Documents If any Inclusions owned by Seller are encumbered pursuant to", 
       isText: false,
       type: "checkbox",
       top: 60.6,
       left: 119.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "Will_2", 
       isText: false,
       type: "checkbox",
       top: 100.44,
       left: 151.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "Will Not assume the debt on the Encumbered Inclusions  253 Encumbered Inclusions", 
       isText: false,
       type: "checkbox",
       top: 100.44,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "Other Documents Other documents and information", 
       isText: false,
       type: "checkbox",
       top: 117.24,
       left: 155.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "Does", 
       isText: false,
       type: "checkbox",
       top: 437.88,
       left: 126.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "Does Not acknowledge receipt of a copy of Sellers Property Disclosure or Source of Water", 
       isText: false,
       type: "checkbox",
       top: 437.88,
       left: 171,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "Text142", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 128.946,
       left: 102.764,
       width: 464.183,
       height: 57.346
    }
    ,
    {
       page: 7,
       name: "Text143", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 363.274,
       left: 93.6004,
       width: 449.1286,
       height: 12.182
    }
    ,
    {
       page: 8,
       name: "Text144", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 438.547,
       left: 275.565,
       width: 283.528,
       height: 13.491
    }
    ,
    {
       page: 8,
       name: "Text145", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 452.293,
       left: 231.056,
       width: 270.436,
       height: 12.836
    }
    ,
    {
       page: 9,
       name: "Other_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 134.16,
       left: 427.44,
       width: 137.88,
       height: 14.04
    }
    ,
    {
       page: 9,
       name: "Buyer", 
       isText: false,
       type: "checkbox",
       top: 138.36,
       left: 96.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Seller_2", 
       isText: false,
       type: "checkbox",
       top: 138.36,
       left: 144.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "OneHalf by Buyer and OneHalf by Seller", 
       isText: false,
       type: "checkbox",
       top: 138.36,
       left: 189.96,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "123 Lot Lease Status Letter At least fourteen days prior to Closing Date Seller agrees to promptly request the Lot", 
       isText: false,
       type: "checkbox",
       top: 138.36,
       left: 385.56,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "None_2", 
       isText: false,
       type: "checkbox",
       top: 238.92,
       left: 194.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Buyer_2", 
       isText: false,
       type: "checkbox",
       top: 238.92,
       left: 239.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Seller_3", 
       isText: false,
       type: "checkbox",
       top: 238.92,
       left: 289.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "OneHalf by Buyer and OneHalf by Seller_2", 
       isText: false,
       type: "checkbox",
       top: 238.92,
       left: 336.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "None_3", 
       isText: false,
       type: "checkbox",
       top: 266.52,
       left: 194.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Buyer_3", 
       isText: false,
       type: "checkbox",
       top: 266.52,
       left: 239.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Seller_4", 
       isText: false,
       type: "checkbox",
       top: 266.52,
       left: 288.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "OneHalf by Buyer and OneHalf by Seller_3", 
       isText: false,
       type: "checkbox",
       top: 266.52,
       left: 336.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "None_4", 
       isText: false,
       type: "checkbox",
       top: 309.96,
       left: 194.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Buyer_4", 
       isText: false,
       type: "checkbox",
       top: 309.96,
       left: 239.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Seller_5", 
       isText: false,
       type: "checkbox",
       top: 309.96,
       left: 288.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "OneHalf by Buyer and OneHalf by Seller_4", 
       isText: false,
       type: "checkbox",
       top: 309.96,
       left: 336.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "None_5", 
       isText: false,
       type: "checkbox",
       top: 353.16,
       left: 194.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Buyer_5", 
       isText: false,
       type: "checkbox",
       top: 353.16,
       left: 239.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Seller_6", 
       isText: false,
       type: "checkbox",
       top: 353.16,
       left: 288.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "OneHalf by Buyer and OneHalf by Seller_5", 
       isText: false,
       type: "checkbox",
       top: 353.16,
       left: 336.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Taxes for the Calendar Immediately Preceding Closing", 
       isText: false,
       type: "checkbox",
       top: 434.04,
       left: 94.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Most Recent Mill Levy and Most Recent Assessed Valuation or", 
       isText: false,
       type: "checkbox",
       top: 448.92,
       left: 94.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "132 Rents Rents based on", 
       isText: false,
       type: "checkbox",
       top: 463.8,
       left: 94.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Other_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 459.6,
       left: 137.64,
       width: 377.28,
       height: 14.04
    }
    ,
    {
       page: 9,
       name: "Rents Actually Received", 
       isText: false,
       type: "checkbox",
       top: 483,
       left: 193.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Accrued", 
       isText: false,
       type: "checkbox",
       top: 483,
       left: 320.04,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Does Not represent that Buyer will occupy the Home as Buyers principal residence", 
       isText: false,
       type: "checkbox",
       top: 646.2,
       left: 101.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Text146", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 534.111,
       left: 329.892,
       width: 200.401,
       height: 14.800
    }
    ,
    {
       page: 9,
       name: "Text147", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 603.494,
       left: 208.146,
       width: 73.418,
       height: 13.490
    }
    ,
    {
       page: 10,
       name: "by Buyer will be paid to Seller and retained by Seller It is agreed that the Earnest Money is not a penalty and the", 
       isText: false,
       type: "checkbox",
       top: 606.36,
       left: 117.48,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "233 Electronic Delivery Electronic Delivery of documents and notice may be delivered by 1 email at the email address of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 71.28,
       left: 93.84,
       width: 153.00,
       height: 12.24
    }
    ,
    {
       page: 12,
       name: "Text148", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 298.129,
       left: 49.7457,
       width: 514.5833,
       height: 304.110
    }
    ,
    {
       page: 12,
       name: "Text149", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 623.785,
       left: 66.7639,
       width: 498.8741,
       height: 50.145
    }
    ,
    {
       page: 12,
       name: "Text150", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 686.621,
       left: 67.4185,
       width: 497.5655,
       height: 63.2367
    }
    ,
    {
       page: 13,
       name: "Buyers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 93.36,
       left: 120.84,
       width: 189.96,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "Buyers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 93.36,
       left: 381.6,
       width: 189.96,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 156.6,
       left: 145.2,
       width: 163.08,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 172.68,
       left: 145.2,
       width: 163.08,
       height: 15.24
    }
    ,
    {
       page: 13,
       name: "3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 188.52,
       left: 145.2,
       width: 163.08,
       height: 15.00
    }
    ,
    {
       page: 13,
       name: "1_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 156.6,
       left: 419.76,
       width: 161.40,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "2_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 172.68,
       left: 419.76,
       width: 161.40,
       height: 13.80
    }
    ,
    {
       page: 13,
       name: "undefined", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 212.76,
       left: 145.2,
       width: 163.08,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "1_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 187.08,
       left: 419.76,
       width: 161.40,
       height: 15.24
    }
    ,
    {
       page: 13,
       name: "2_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 211.56,
       left: 419.76,
       width: 161.40,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "Email Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 234.36,
       left: 145.2,
       width: 163.08,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "Email Address_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 233.16,
       left: 419.76,
       width: 161.40,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "Sellers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 296.64,
       left: 119.76,
       width: 189.96,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "Sellers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 296.64,
       left: 379.44,
       width: 189.96,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "1_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 359.64,
       left: 145.68,
       width: 168.36,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "2_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 375.72,
       left: 145.68,
       width: 168.36,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "3_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 391.8,
       left: 145.68,
       width: 168.36,
       height: 15.0
    }
    ,
    {
       page: 13,
       name: "1_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 359.64,
       left: 415.68,
       width: 165.48,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "2_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 375.72,
       left: 415.68,
       width: 165.48,
       height: 14.04
    }
    ,
    {
       page: 13,
       name: "undefined_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 416.04,
       left: 145.68,
       width: 168.36,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "Phone No 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 390.36,
       left: 415.68,
       width: 165.48,
       height: 15.24
    }
    ,
    {
       page: 13,
       name: "Phone No 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 414.84,
       left: 415.68,
       width: 165.48,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "Email Address_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 437.64,
       left: 145.68,
       width: 168.36,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "Email Address_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 436.44,
       left: 415.68,
       width: 165.48,
       height: 15.48
    }
    ,
    {
       page: 13,
       name: "Does_2", 
       isText: false,
       type: "checkbox",
       top: 574.68,
       left: 90.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the", 
       isText: false,
       type: "checkbox",
       top: 574.68,
       left: 134.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Buyers Agent", 
       isText: false,
       type: "checkbox",
       top: 638.28,
       left: 200.04,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "TransactionBroker in this transaction", 
       isText: false,
       type: "checkbox",
       top: 638.28,
       left: 285.48,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
       isText: false,
       type: "checkbox",
       top: 655.8,
       left: 54.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Listing Brokerage Firm", 
       isText: false,
       type: "checkbox",
       top: 692.04,
       left: 90.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Buyer_6", 
       isText: false,
       type: "checkbox",
       top: 692.04,
       left: 216.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Other_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 689.52,
       left: 299.04,
       width: 264.96,
       height: 12.48
    }
    ,
    {
       page: 14,
       name: "Brokerage Firms Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 117.24,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "Brokerage Firms License 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 132.24,
       left: 180.24,
       width: 378.12,
       height: 14.16
    }
    ,
    {
       page: 14,
       name: "Brokerage Firms License 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 147.24,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "Brokerage Firms License 3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 162.12,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "addressBA", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 221.16,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "phoneBA", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 236.04,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "faxBA", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 251.16,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "emailBA", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 266.28,
       left: 179.52,
       width: 378.84,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "signature broker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 189.24,
       left: 177.95,
       width: 281.311,
       height: 15.589
    }
    ,
    {
       page: 14,
       name: "Does_3", 
       isText: false,
       type: "checkbox",
       top: 337.8,
       left: 93.72,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 14,
       name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage", 
       isText: false,
       type: "checkbox",
       top: 337.8,
       left: 142.68,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 14,
       name: "Sellers Agent", 
       isText: false,
       type: "checkbox",
       top: 419.64,
       left: 199.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "TransactionBroker in this transaction_2", 
       isText: false,
       type: "checkbox",
       top: 419.64,
       left: 282.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Customer Broker has no brokerage relationship with Seller See A for Brokers brokerage relationship with Buyer", 
       isText: false,
       type: "checkbox",
       top: 442.68,
       left: 54.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Seller_7", 
       isText: false,
       type: "checkbox",
       top: 467.4,
       left: 90.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Buyer_7", 
       isText: false,
       type: "checkbox",
       top: 467.4,
       left: 139.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Other_5", 
       isText: false,
       type: "checkbox",
       top: 467.4,
       left: 189.48,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "undefined_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 464.88,
       left: 233.28,
       width: 335.04,
       height: 12.48
    }
    ,
    {
       page: 14,
       name: "Brokerage Firms Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 540.12,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "Brokerage Firms License 1_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 555.12,
       left: 180.24,
       width: 378.12,
       height: 14.16
    }
    ,
    {
       page: 14,
       name: "Brokerage Firms License 2_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 570.12,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "Brokerage Firms License 3_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 585,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "addressLA", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 644.04,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "phoneLA", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 659.16,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "FaxLA", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 674.04,
       left: 180.24,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "emailLA", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 689.16,
       left: 179.52,
       width: 378.84,
       height: 14.28
    }
    ,
    {
       page: 14,
       name: "signature broker2", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 615.763,
       left: 180.001,
       width: 272.999,
       height: 13.626
    }
    ,
    {
       page: 14,
       name: "Date brokerListingsign", 
       fontSize: 9,
       type: "date",
       top: 614.454,
       left: 478.309,
       width: 78.873,
       height: 13.625
    }
    ,
    {
       page: 14,
       name: "Date brokerBuyerAgentSigns", 
       fontSize: 9,
       type: "date",
       top: 189.164,
       left: 468.657,
       width: 89.781,
       height: 16.110
    }
    ] }
    