export function exchangeAddendumtoContracttoBuySellRealEstate() {
return [ // no logo, rarely used
   {
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "known as No", 
   top: 212.76,
   left: 147.48,
   width: 356.52,
   height: 15.36
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "which is dated", 
   top: 228.84,
   left: 144,
   width: 144.12,
   height: 12.84
}
,
{
   page: 0,
   type: "checkbox",
   name: "a_Buyer", 
   top: 284.360,
   left: 93.8988,
   width: 7.9912,
   height: 7.991
}
,
{
   page: 0,
   type: "checkbox",
   name: "Seller is also know as the Exchanging Party", 
   top: 284.408,
   left: 181.286,
   width: 7.991,
   height: 7.991
}
,
{
   page: 0,
   type: "checkbox",
   name: "b_Buyer",   
   top: 297.880,
   left: 94.1858,
   width: 7.9912,
   height: 7.991
}
,
{
   page: 0,
   type: "checkbox",
   name: "Seller is also know as the Cooperating Party", 
   top: 298.403,
   left: 181.633,
   width: 7.991,
   height: 7.992
}
]}