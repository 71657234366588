export function agencyDisclosureForm() {
return [   //2024 Release 2024-01-21 14:52:15
{
   page: 1,
   name: "BuyerLessee", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 351.68,
   left: 153.96,
   width: 156.00,
   height: 11.367
}
,
{
   page: 1,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 351.68,
   left: 417.24,
   width: 152.88,
   height: 11.367
}
,
{
   page: 1,
   name: "Title", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 378.32,
   left: 153.96,
   width: 156.00,
   height: 11.367
}
,
{
   page: 1,
   name: "Title_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 378.56,
   left: 417.24,
   width: 152.88,
   height: 11.367
}
,
{
   page: 1,
   name: "Licensee", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 424.24,
   left: 153.96,
   width: 156.00,
   height: 11.367
}
,
{
   page: 1,
   name: "Licensee_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 424.48,
   left: 417.12,
   width: 153.00,
   height: 11.367
}
,
{
   page: 1,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 445.211,
   left: 153.96,
   width: 156.00,
   height: 11.367
}
,
{
   page: 1,
   name: "Date_5", 
   fontSize: 9,
   type: "date",
   top: 444.571,
   left: 417.24,
   width: 152.88,
   height: 11.367
}
,
{
   page: 1,
   name: "BuyerLessee_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 505.52,
   left: 153.96,
   width: 156.00,
   height: 11.367
}
,
{
   page: 1,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 505.52,
   left: 417.24,
   width: 152.88,
   height: 11.367
}
,
{
   page: 1,
   name: "Title_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 532.16,
   left: 153.96,
   width: 156.00,
   height: 11.367
}
,
{
   page: 1,
   name: "Title_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 532.4,
   left: 417.24,
   width: 152.88,
   height: 11.367
}
,
{
   page: 1,
   name: "Licensee_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 578.08,
   left: 153.96,
   width: 156.00,
   height: 11.367
}
,
{
   page: 1,
   name: "Licensee_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 579.32,
   left: 417.12,
   width: 153.00,
   height: 11.367
}
,
{
   page: 1,
   name: "Date_3", 
   fontSize: 9,
   type: "date",
   top: 598.36,
   left: 153.96,
   width: 156.00,
   height: 11.367
}
,
{
   page: 1,
   name: "Date_7", 
   fontSize: 9,
   type: "date",
   top: 598.946,
   left: 417.24,
   width: 152.88,
   height: 11.367
}
] }
