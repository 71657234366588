import { createSlice
} from '@reduxjs/toolkit'

const initialState = {
	modalType: "",
	modalProps: ""
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, action) {
			state.modalType = action.payload.modalType
			state.modalProps = action.payload.modalProps
    },
    closeModal(state, action) {
			state.modalType = ""
			state.modalProps = ""
    }
  }
});

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer