export function brokerageDutiesDisclosuretoSellernonCRECListing() {
return [
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 240,
      height: 36,
    }
    ,
   {
   page: 0,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "SA Check", 
   top: 172.15,
   left: 141.733,
   width: 16,
   height: 16
}
,
{
   page: 0,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "TB Check", 
   top: 171.745,
   left: 275.976,
   width: 16,
   height: 16
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date", 
   top: 198.00,
   left: 387.72,
   width: 160.56,
   height: 11.76
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 9,
   type: "date",
   name: "LA Date", 
   top: 218,
   left: 500.879,
   width: 70.579,
   height: 13.888
}
,
{
   page: 0,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "MPF Check", 
   top: 271.515,
   left: 54.9408,
   width: 16,
   height: 16
}
,
{
   page: 0,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "OPF Check", 
   top: 347.356,
   left: 54.5352,
   width: 16,
   height: 16
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller", 
   top: 411.48,
   left: 144.36,
   width: 403.92,
   height: 11.76
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Brokerage Firm", 
   top: 429.00,
   left: 189.12,
   width: 359.16,
   height: 11.76
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Broker", 
   top: 446.40,
   left: 150.48,
   width: 397.80,
   height: 11.76
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Property", 
   top: 470.52,
   left: 226.56,
   width: 321.72,
   height: 10.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Address", 
   top: 482.04,
   left: 120.84,
   width: 427.44,
   height: 11.76
}
,
{
   page: 1,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "SAO Check",  //seller agency only
   top: 46,
   left: 110,
   width: 15,
   height: 15
}
,
{
   page: 1,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "Will Check", 
   top: 493.8,
   left: 193.8,
   width: 15,
   height: 15
}
,
{
   page: 1,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "Will Not Check", 
   top: 493.6,
   left: 230.4,
   width: 15,
   height: 15
}
,
{
   page: 2,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "Agrees Check", 
   top: 45.8,
   left: 328,
   width: 15,
   height: 15
}
,
{
   page: 2,
   fontName: "Zapfdingbats",
   fontSize: 10,
   type: "checkbox",
   name: "DNAgree Check", 
   top: 45.5,
   left: 376.5,
   width: 15,
   height: 15
}
,
{  
   page: 2,
   name: "Additional Disclosures",
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 230.741,
   left: 47.0461,
   width: 541.5749,
   height: 81.667
}
,
{  
   page: 2,
   name: "Seller1 Name",
   type: "text",
   fontName: "Helvetica",
   fontSize: 8,
   top: 421.357,
   left: 89.6729,
   width: 215.4651,
   height: 11.269
}
,
{  
   page: 2,
   name: "Seller2 Name",
   type: "text",
   fontName: "Helvetica",
   fontSize: 8,
   top: 421.498,
   left: 335.782,
   width: 234.120,
   height: 11.640
}

,
{
   page: 2,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Broker Ack Date", 
   top: 467.796,
   left: 71.7858,
   width: 144.7272,
   height: 12.836
}
,
{
   page: 2,
   name: "Name of Seller",
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 465.72,
   left: 292.92,
   width: 247.691,
   height: 14.149
}
,
{
   page: 2,
   name: "Method of Delivery",
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 498.644,
   left: 131.16,
   width: 232.56,
   height: 10.745
}
,
{
   page: 2,
   name: "Brokerage Firm Name",
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 525.462,
   left: 160.08,
   width: 383.64,
   height: 13.898
}
,
{
   page: 2,
   name: "Broker Name",
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 555.587,
   left: 57.6,
   width: 216.12,
   height: 13.887
}
] }
