import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateDocInDb } from "../../../../../app/firestore/firestoreService";

export function FormFieldText({
  formField,
  index,
  populatingFieldsStatus,
  newPopulatedFields,
}) {
  const { pageScaleFill } = useSelector((state) => state.annot);
  const { doc } = useSelector((state) => state.doc);
  const [value, setValue] = useState(
    doc.formFieldValues?.[formField.name] || ""
  );
  const pageScale = pageScaleFill;

  // This is a catch for prepopulating data to make sure it rerenders
  useEffect(() => {
    if (
      populatingFieldsStatus === "Complete" &&
      newPopulatedFields?.[formField.name]
    ) {
      setValue(newPopulatedFields[formField.name]);
    }
  }, [populatingFieldsStatus, formField.name, newPopulatedFields]);

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleBlur(e) {
    let newValue = e.target.value;
    newValue = newValue.replace(/[\u{0080}-\u{FFFF}]/gu, "");
    updateDocInDb(doc.id, {
      [`formFieldValues.${formField.name}`]: newValue,
    });
  }

  return (
    <div
      className="pdfFormField"
      style={{
        top: `${formField.top * pageScale}px`,
        left: `${formField.left * pageScale}px`,
        height: `${formField.height * pageScale}px`,
        width: `${formField.width * pageScale}px`,
        fontSize: `${formField.fontSize * pageScale}px`,
      }}
    >
      <input
        className="fill-input-field"
        type={formField.type}
        name={formField.name}
        aria-label={formField.name}
        tabIndex={index}
        data-tabindex={index}
        autoComplete="off"
        value={value}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => handleBlur(e)}
        style={{
          borderWidth: "0px",
          fontFamily: "Helvetica",
          fontSize: `${formField.fontSize * pageScale}px`,
          margin: "0px",
          textAlign: "left",
        }}
      ></input>
    </div>
  );
}
