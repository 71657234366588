import React from "react";
import { format } from "date-fns";
import { Table } from "semantic-ui-react";
import _ from "lodash";

export default function HistoryListItem({ entry }) {
  return (
    <Table.Row key={entry.id}>
      <Table.Cell>
        {format(new Date(entry.createdAt), "MM/dd/yyyy h:mm a")}
      </Table.Cell>
      <Table.Cell>{_.capitalize(entry.role)}</Table.Cell>
      <Table.Cell>
        {entry.firstName} {entry.lastName}
      </Table.Cell>
      <Table.Cell>{entry.action}</Table.Cell>
      <Table.Cell>{entry.document}</Table.Cell>
      <Table.Cell>{_.capitalize(entry.toRole)}</Table.Cell>
      <Table.Cell>
        {entry.toFirstName} {entry.toLastName}
      </Table.Cell>
    </Table.Row>
  );
}
