import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Header, Segment } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import TaskTemplatesList from "./TaskTemplatesList";
import ModalWrapper from "../../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../../app/common/modals/modalSlice";
import { fetchTaskTemplatesFromDb } from "../../../../app/firestore/firestoreService";
import { fetchTaskTemplates } from "../taskTemplatesSlice";
import useFirestoreCollectionNoAsync from "../../../../app/hooks/useFirestoreCollectionNoAsync";

export default function TaskTemplatesSelect() {
  const dispatch = useDispatch();
  const { taskTemplates } = useSelector((state) => state.taskTemplates);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  useFirestoreCollectionNoAsync({
    query: () => fetchTaskTemplatesFromDb(),
    data: (taskTemplates) => dispatch(fetchTaskTemplates(taskTemplates)),
    deps: [dispatch],
  });

  function handleClose() {
    dispatch(
      closeModal({
        modalType: "TaskTemplatesSelect",
      })
    );
  }

  return (
    <>
      <ModalWrapper style={{ backgroundColor: "#f9fafb" }}>
        <Segment clearing style={{ backgroundColor: "#f9fafb" }}>
          <Header color="blue" size="large">
            Select Task Template to Add
          </Header>
          <Divider />{" "}
          {taskTemplates?.length > 0 ? (
            <TaskTemplatesList
              taskTemplates={taskTemplates}
              column={taskTemplates.column}
              direction={taskTemplates.direction}
              actionButtonMode="apply"
            />
          ) : (
            <p>There are no task templates.</p>
          )}
          <Button
            floated={isMobile ? null : "right"}
            content="Close"
            onClick={() => handleClose()}
            className={isMobile ? "fluid" : null}
          ></Button>
        </Segment>
      </ModalWrapper>
    </>
  );
}
