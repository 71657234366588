import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Segment, Divider, Icon } from "semantic-ui-react";
import { closeModal, openModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { dateFormatDeadline } from "../../../app/common/util/util";

export default function TaskViewModal({ task, hideNavigate, allowEdit }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleGoToTransaction() {
    dispatch(
      closeModal({
        modalType: "TaskViewModal",
      })
    );
    navigate(`/transactions/${task.transactionId}/overview`);
  }

  function handleEdit() {
    dispatch(
      openModal({
        modalType: "TaskForm",
        modalProps: { task: task },
      })
    );
  }

  return (
    <ModalWrapper size="mini">
      <Segment>
        <div className="flex">
          <h3 className="zero bottom margin text blue">{task.title}</h3>
          {hideNavigate && allowEdit && (
            <Icon
              name="edit"
              color="blue"
              link
              onClick={() => handleEdit()}
              style={{ paddingTop: "3px", marginLeft: "10px" }}
            />
          )}
        </div>
        <Divider />
        {task.end && (
          <p>
            <b>Deadline:</b> {dateFormatDeadline(task.end)}
          </p>
        )}
        {task.transactionTitle && (
          <p>
            <b>For Transaction:</b> {task.transactionTitle}
          </p>
        )}
        {/* {task.assignedTo && (
          <p>
            <b>Assigned To:</b> {task.assignedTo}
          </p>
        )} */}
        {task.description && (
          <p>
            <b>Description:</b> {task.description}
          </p>
        )}
        {!hideNavigate && (
          <Button
            fluid
            basic
            primary
            onClick={() => handleGoToTransaction()}
            content="View Transaction"
          />
        )}
      </Segment>
    </ModalWrapper>
  );
}
