import React from "react";
import { Table } from "semantic-ui-react";
import FormTemplatesListItem from "./FormTemplatesListItem";

export default function FormTemplatesList({
  formTemplates,
  column,
  direction,
}) {
  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell sorted={column === "title" ? direction : null}>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell style={{ cursor: "default" }}></Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "updatedAt" ? direction : null}
            >
              Last Updated
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {formTemplates &&
            formTemplates.length !== 0 &&
            formTemplates.map((formTemplate) => (
              <FormTemplatesListItem
                formTemplate={formTemplate}
                key={formTemplate.id}
              />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
