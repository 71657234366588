export function affiliateBusinessArrangementHSPR() {
    return [   //2023
    {
       page: 0,
       name: "Buyer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 8,
       top: 627.011,
       left: 77.9565,
       width: 159.6765,
       height: 13.091
    }
    ,
    {
       page: 0,
       name: "Seller", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 8,
       top: 636.829,
       left: 347.913,
       width: 159.676,
       height: 12.436
    }
    ,
    {
       page: 0,
       name: "Buyer_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 8,
       top: 706.4291,
       left: 75.8729,
       width: 159.6761,
       height: 12.4363
    }
    ,
    {
       page: 0,
       name: "Seller_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 8,
       top: 706.3091,
       left: 346.953,
       width: 159.676,
       height: 12.4363
    }
    ] }
    