export function gTARMLSComingSoonSellerAuthorization() {
return [   //2024 Release 2024-04-18 19:56:46
{
   page: 0,
   name: "Property Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 112.08,
   left: 133.68,
   width: 424.32,
   height: 14.40
}
,
{
   page: 0,
   name: "of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 238.68,
   left: 59.88,
   width: 83.64,
   height: 14.40
}
,
{
   page: 0,
   name: "required to include the following statement Coming Soon No Showings or Offers until", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 563.64,
   left: 492.12,
   width: 71.52,
   height: 14.40
}
,
{
   page: 1,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 146.727,
   left: 43.8545,
   width: 191.2805,
   height: 15.349
}
,
{
   page: 1,
   name: "signature REALTOR", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.237,
   left: 44.5078,
   width: 233.0402,
   height: 15.349
}
] }
