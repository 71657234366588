export function addendumNoticeofObligationtoPayImprovementDistrictAssessment() {
return [   //2024 Release 2024-02-19 17:44:18
{
   page: 0,
   name: "Text15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 91.0,
   left: 228.084,
   width: 218.001,
   height: 12.191
}
,
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 122.875,
   left: 68.0003,
   width: 477.2517,
   height: 14.917
}
,
{
   page: 0,
   name: "Text2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 161.084,
   left: 43.2085,
   width: 201.7085,
   height: 10.882
}
,
{
   page: 0,
   name: "Text4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 191.542,
   left: 43.9169,
   width: 201.0001,
   height: 10.882
}
,
{
   page: 0,
   name: "Text3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 190.417,
   left: 401.918,
   width: 168.417,
   height: 10.882
}
,
{
   page: 0,
   name: "Text8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 206.964,
   left: 43.2085,
   width: 273.2505,
   height: 10.537
}
,
{
   page: 0,
   name: "Text5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 293.835,
   left: 369.335,
   width: 198.167,
   height: 11.590
}
,
{
   page: 0,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 310.251,
   left: 427.127,
   width: 142.208,
   height: 11.429
}
,
{
   page: 0,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 324.71,
   left: 43.2085,
   width: 139.3745,
   height: 10.119
}
,
{
   page: 0,
   name: "Text9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 338.168,
   left: 451.609,
   width: 115.769,
   height: 11.536
}
,
{
   page: 0,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 353.043,
   left: 41.7919,
   width: 140.0831,
   height: 11.591
}
] }
