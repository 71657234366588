export function saleofBuyersPropertyConditionNotUnderContract() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 214.98,
   left: 46.08,
   width: 529.92,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 237.84,
   left: 46.08,
   width: 529.92,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 256.7,
   left: 46.08,
   width: 430.56,
   height: 13.5
}
,
{
   page: 0,
   name: "Check Box 1", 
   isText: false,
   type: "checkbox",
   top: 335.36,
   left: 49.68,
   width: 12.384,
   height: 18.188
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 390.512,
   left: 49.68,
   width: 12.384,
   height: 18.188
}
] }
