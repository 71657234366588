export function residentialSale() {
return [   //2024 Release 2024-02-21 09:45:15
{
   page: 0,
   name: "Text Field 280", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 15.688,
   left: 111.888,
   width: 466.992,
   height: 10.227
}
,
{
   page: 0,
   name: "Text Field 164", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 93.5,
   left: 393.84,
   width: 177.84,
   height: 10.227
}
,
{
   page: 0,
   name: "Text Field 165", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 104.04,
   left: 31.5,
   width: 540.18,
   height: 10.227
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 179.928,
   left: 77.976,
   width: 6.912,
   height: 6.768
}
,
{
   page: 0,
   name: "Check Box 22", 
   isText: false,
   type: "checkbox",
   top: 245.88,
   left: 77.04,
   width: 6.912,
   height: 6.768
}
,
{
   page: 0,
   name: "Check Box 23", 
   isText: false,
   type: "checkbox",
   top: 268.875,
   left: 77.04,
   width: 6.912,
   height: 6.768
}
,
{
   page: 0,
   name: "Check Box 24", 
   isText: false,
   type: "checkbox",
   top: 291.87,
   left: 77.04,
   width: 6.912,
   height: 6.768
}
,
{
   page: 0,
   name: "Check Box 25", 
   isText: false,
   type: "checkbox",
   top: 304.92,
   left: 77.04,
   width: 6.912,
   height: 6.768
}
,
{
   page: 0,
   name: "Check Box 26", 
   isText: false,
   type: "checkbox",
   top: 348.984,
   left: 77.04,
   width: 6.912,
   height: 6.768
}
,
{
   page: 0,
   name: "Check Box 27", 
   isText: false,
   type: "checkbox",
   top: 383.976,
   left: 77.04,
   width: 6.912,
   height: 6.768
}
,
{
   page: 0,
   name: "Check Box 28", 
   isText: false,
   type: "checkbox",
   top: 396.936,
   left: 77.04,
   width: 6.912,
   height: 6.768
}
,
{
   page: 0,
   name: "Text Field 84", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 467.957,
   left: 155.25,
   width: 158.094,
   height: 10.882
}
,
{
   page: 0,
   name: "Text Field 85", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 468.612,
   left: 414.288,
   width: 154.368,
   height: 10.882
}
,
{
   page: 0,
   name: "Check Box 29", 
   isText: false,
   type: "checkbox",
   top: 557.784,
   left: 59.04,
   width: 6.912,
   height: 6.768
}
,
{
   page: 0,
   name: "Text Field 90", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 614.672,
   left: 152.271,
   width: 161.937,
   height: 10.882
}
,
{
   page: 0,
   name: "Text Field 91", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 614.672,
   left: 413.712,
   width: 156.240,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.688,
   left: 111.888,
   width: 466.992,
   height: 10.882
}
,
{
   page: 1,
   name: "Check Box 31", 
   subType: "addDoc",
   docToAdd: "Conventional Loan",
   isText: false,
   type: "checkbox",
   top: 130.32,
   left: 49.176,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 33", 
   subType: "addDoc",
   docToAdd: "Single Family Homeowners Association",
   isText: false,
   type: "checkbox",
   top: 130.32,
   left: 319.402,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 30", 
   subType: "addDoc",
   docToAdd: "FHA Loan",
   isText: false,
   type: "checkbox",
   top: 141.056,
   left: 49.176,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 34", 
   subType: "addDoc",
   docToAdd: "Condominium Association",
   isText: false,
   type: "checkbox",
   top: 141.344,
   left: 319.402,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 32", 
   subType: "addDoc",
   docToAdd: "VA Loan",
   isText: false,
   type: "checkbox",
   top: 151.792,
   left: 49.176,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 35", 
   subType: "addDoc",
   docToAdd: "Supplement",
   isText: false,
   type: "checkbox",
   top: 152.368,
   left: 319.402,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 40", 
   subType: "addDoc",
   docToAdd: "USDA Loan",
   isText: false,
   type: "checkbox",
   top: 163.176,
   left: 49.176,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 36", 
   // subType: "addDoc",
   // docToAdd: "Sale of Buyers Property - Under Contract",
   isText: false,
   type: "checkbox",
   top: 163.392,
   left: 319.402,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 41", 
   subType: "addDoc",
   docToAdd: "Native American Home Loan",
   isText: false,
   type: "checkbox",
   top: 174.236,
   left: 49.176,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 37", 
   subType: "addDoc",
   docToAdd: "Sale of Buyers Property Condition - Not Under Contract",
   isText: false,
   type: "checkbox",
   top: 174.416,
   left: 319.402,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 39", 
   subType: "addDoc",
   docToAdd: "Assumption of Loan",
   isText: false,
   type: "checkbox",
   top: 185.296,
   left: 49.176,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Check Box 38", 
   isText: false,
   type: "checkbox",
   top: 185.242,
   left: 319.402,
   width: 16.632,
   height: 9.612
}
,
{
   page: 1,
   name: "Text Field 96", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 182.25,
   left: 337.034,
   width: 100.080,
   height: 11.536
}
,
{
   page: 1,
   name: "Check Box 65", 
   isText: false,
   type: "checkbox",
   top: 196.75,
   left: 49.176,
   width: 16.704,
   height: 9.612
}
,
{
   page: 1,
   name: "Text Field 97", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 231.92,
   left: 31.5,
   width: 522.756,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 98", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 244.88,
   left: 53.784,
   width: 489.384,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 99", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 395.0,
   left: 339.048,
   width: 158.472,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 100", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 413.504,
   left: 159.408,
   width: 420.948,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 101", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.472,
   left: 43.92,
   width: 536.58,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 102", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 441.584,
   left: 43.875,
   width: 536.580,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 104", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 455.75,
   left: 43.875,
   width: 309.645,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 105", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 455.75,
   left: 353.52,
   width: 143.73,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 106", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 455.75,
   left: 497.25,
   width: 83.106,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 107", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.756,
   left: 242.44,
   width: 135.56,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 108", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 546.736,
   left: 461.448,
   width: 71.604,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 110", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 557.236,
   left: 85.68,
   width: 154.76,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 109", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 637.722,
   left: 306.625,
   width: 230.135,
   height: 10.882
}
,
{
   page: 1,
   name: "Text Field 112", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 668.936,
   left: 42.282,
   width: 535.968,
   height: 13.500
}
,
{
   page: 2,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.544,
   left: 111.744,
   width: 466.992,
   height: 10.882
}
,
{
   page: 2,
   name: "Text Field 113", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 278.96,
   left: 73.44,
   width: 507.06,
   height: 10.882
}
,
{
   page: 2,
   name: "Text Field 114", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 289.04,
   left: 73.44,
   width: 507.06,
   height: 10.882
}
,
{
   page: 2,
   name: "Text Field 118", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 300.92,
   left: 375.12,
   width: 205.38,
   height: 10.882
}
,
{
   page: 2,
   name: "Text Field 115", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 313.25,
   left: 73.44,
   width: 507.06,
   height: 10.882
}
,
{
   page: 2,
   name: "Text Field 116", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 341.96,
   left: 191.88,
   width: 225.36,
   height: 10.882
}
,
{
   page: 2,
   name: "Text Field 117", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 475.44,
   left: 140.256,
   width: 66.816,
   height: 10.882
}
,
{
   page: 3,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 16.552,
   left: 112.032,
   width: 466.992,
   height: 9.573
}
,
{
   page: 3,
   name: "Text Field 119", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 137.84,
   left: 87.48,
   width: 493.02,
   height: 9.573
}
,
{
   page: 3,
   name: "Text Field 120", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 383.8,
   left: 246.2,
   width: 54.72,
   height: 9.573
}
,
{
   page: 4,
   name: "Text Field 33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.832,
   left: 111.888,
   width: 466.992,
   height: 12.191
}
,
{
   page: 4,
   name: "Check Box 61", 
   isText: false,
   type: "checkbox",
   top: 287.04,
   left: 68.12,
   width: 9.216,
   height: 9.396
}
,
{
   page: 4,
   name: "Check Box 62", 
   isText: false,
   type: "checkbox",
   top: 325.48,
   left: 68.12,
   width: 9.216,
   height: 9.396
}
,
{
   page: 4,
   name: "Check Box 63", 
   isText: false,
   type: "checkbox",
   top: 381.891,
   left: 68.12,
   width: 9.216,
   height: 9.396
}
,
{
   page: 4,
   name: "Check Box 68", 
   isText: false,
   type: "checkbox",
   top: 381.891,
   left: 345.384,
   width: 9.216,
   height: 9.396
}
,
{
   page: 4,
   name: "Check Box 64", 
   isText: false,
   type: "checkbox",
   top: 394.32,
   left: 68.12,
   width: 9.216,
   height: 9.396
}
,
{
   page: 4,
   name: "Text Field 221", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 604.76,
   left: 174.96,
   width: 59.76,
   height: 11.536
}
,
{
   page: 5,
   name: "Text Field 162", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.76,
   left: 111.96,
   width: 466.992,
   height: 10.882
}
,
{
   page: 5,
   name: "Check Box 44", 
   isText: false,
   type: "checkbox",
   top: 255.04,
   left: 68.004,
   width: 9.216,
   height: 9.396
}
,
{
   page: 5,
   name: "Check Box 45", 
   isText: false,
   type: "checkbox",
   top: 269.772,
   left: 68.004,
   width: 9.216,
   height: 9.396
}
,
{
   page: 5,
   name: "Check Box 46", 
   isText: false,
   type: "checkbox",
   top: 295.7,
   left: 68.004,
   width: 9.216,
   height: 9.396
}
,
{
   page: 5,
   name: "Text Field 127", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 304.14,
   left: 98.388,
   width: 77.904,
   height: 12.191
}
,
{
   page: 5,
   name: "Text Field 128", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 304.331,
   left: 280.548,
   width: 77.904,
   height: 12.191
}
,
{
   page: 5,
   name: "Text Field 130", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 377.824,
   left: 53.56,
   width: 529.056,
   height: 12.191
}
,
{
   page: 5,
   name: "Text Field 131", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 393.664,
   left: 53.56,
   width: 529.056,
   height: 12.191
}
,
{
   page: 5,
   name: "Text Field 132", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 409.648,
   left: 53.56,
   width: 529.056,
   height: 12.191
}
,
{
   page: 5,
   name: "Text Field 133", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 425.416,
   left: 53.56,
   width: 529.056,
   height: 12.191
}
,
{
   page: 6,
   name: "Text Field 163", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.76,
   left: 111.96,
   width: 466.992,
   height: 11.536
}
,
{
   page: 6,
   name: "date Text Field 144", 
   fontSize: 9,
   type: "date",
   top: 369.0,
   left: 440.08,
   width: 127.80,
   height: 13.5
}
,
{
   page: 6,
   name: "Text Field 218", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 379.492,
   left: 31.68,
   width: 78.55,
   height: 13.500
}
,
{
   page: 6,
   name: "Check Box 69", 
   isText: false,
   type: "checkbox",
   top: 382.5,
   left: 112.034,
   width: 9.216,
   height: 9.396
}
,
{
   page: 6,
   name: "Check Box 70", 
   isText: false,
   type: "checkbox",
   top: 382.5,
   left: 155.152,
   width: 9.216,
   height: 9.396
}
,
{
   page: 6,
   name: "Text Field 147", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 402.294,
   left: 354.6,
   width: 72.625,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 146", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 402.294,
   left: 432.0,
   width: 66.23,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 145", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 402.294,
   left: 514.8,
   width: 66.95,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 135", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 504.996,
   left: 31.536,
   width: 202.464,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 140", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 504.996,
   left: 320.04,
   width: 199.08,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 137", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.916,
   left: 31.536,
   width: 202.464,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 142", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.916,
   left: 320.04,
   width: 199.08,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 222", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 631.736,
   left: 31.536,
   width: 202.464,
   height: 13.500
}
,
{
   page: 6,
   name: "Text Field 223", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 631.736,
   left: 320.04,
   width: 199.08,
   height: 13.500
}
,
{
   page: 7,
   name: "Text Field 281", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.688,
   left: 111.888,
   width: 466.992,
   height: 12.191
}
,
{
   page: 7,
   name: "Text Field 248", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 73.16,
   left: 30.875,
   width: 265.860,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 249", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 73.16,
   left: 318.875,
   width: 262.250,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 209", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 104.48,
   left: 31.536,
   width: 265.860,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 2010", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 104.48,
   left: 319.536,
   width: 262.250,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 250", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 134.8,
   left: 31.536,
   width: 265.860,
   height: 13.5
}
,
{
   page: 7,
   name: "Text Field 251", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 134.8,
   left: 319.536,
   width: 262.250,
   height: 13.5
}
,
{
   page: 7,
   name: "Text Field 262", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 166.12,
   left: 31.5,
   width: 265.86,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 263", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 166.12,
   left: 319.5,
   width: 262.25,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 252", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 197.44,
   left: 31.536,
   width: 265.860,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 254", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 197.44,
   left: 319.536,
   width: 262.250,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 256", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.76,
   left: 31.536,
   width: 265.860,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 257", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.76,
   left: 319.536,
   width: 262.250,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 253", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 259.08,
   left: 31.536,
   width: 265.860,
   height: 13.50
}
,
{
   page: 7,
   name: "Text Field 255", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 259.08,
   left: 319.536,
   width: 262.250,
   height: 13.50
}
, 
{
   page: 7,
   name: "Logo",
   isText: false,
   fontSize: 10,
   type: "logo",
   top: 290,
   left: 31.4,
   width: 200,
   height: 30,
}
] }
