export function subdivisionandPropertyOwnersAssociation() {
return [   //2024 Release 2024-02-18 22:06:33
{
   page: 0,
   name: "at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 177.24,
   left: 52.32,
   width: 410.76,
   height: 11.16
}
,
{
   page: 0,
   name: "of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 189.36,
   left: 51.96,
   width: 251.04,
   height: 11.16
}
,
{
   page: 0,
   name: "County of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 189.48,
   left: 362.28,
   width: 123.72,
   height: 11.04
}
,
{
   page: 0,
   name: "is", 
   isText: false,
   type: "checkbox",
   top: 228.72,
   left: 125.76,
   width: 12.48,
   height: 9.72
}
,
{
   page: 0,
   name: "is not subject to a right of first refusal other than a right of first refusal", 
   isText: false,
   type: "checkbox",
   top: 228.72,
   left: 151.56,
   width: 12.48,
   height: 9.72
}
,
{
   page: 0,
   name: "B The current regular assessment for the Property is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 277.56,
   left: 317.65,
   width: 173.28,
   height: 11.16
}
,
{
   page: 0,
   name: "per", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 277.56,
   left: 513.72,
   width: 56.40,
   height: 11.16
}
,
{
   page: 0,
   name: "C A special assessment for the Property due after this resale certificate is delivered is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 301.68,
   left: 525.6,
   width: 48.12,
   height: 11.16
}
,
{
   page: 0,
   name: "payable as follows", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 313.92,
   left: 143.88,
   width: 429.84,
   height: 11.04
}
,
{
   page: 0,
   name: "for the following purpose", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 326.04,
   left: 182.16,
   width: 391.44,
   height: 11.16
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 362.4,
   left: 62.28,
   width: 116.16,
   height: 11.04
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 399.13,
   left: 62.7696,
   width: 119.6404,
   height: 11.16
}
,
{
   page: 0,
   name: "F The amount of reserves for capital expenditures is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 423.25,
   left: 316.2,
   width: 115.8,
   height: 11.16
}
,
{
   page: 0,
   name: "G Unsatisfied judgments against the Association total", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 446.88,
   left: 320.4,
   width: 108.84,
   height: 11.16
}
,
{
   page: 0,
   name: "are", 
   isText: false,
   type: "checkbox",
   top: 489.96,
   left: 83.04,
   width: 12.48,
   height: 9.72
}
,
{
   page: 0,
   name: "are not any suits pending in which the Association is a party The style and cause", 
   isText: false,
   type: "checkbox",
   top: 489.96,
   left: 128.52,
   width: 12.48,
   height: 9.72
}
,
{
   page: 0,
   name: "number of each pending suit is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 507.12,
   left: 215.76,
   width: 147.12,
   height: 11.16
}
,
{
   page: 0,
   name: "has actual knowledge", 
   isText: false,
   type: "checkbox",
   top: 537.96,
   left: 186.12,
   width: 12.48,
   height: 9.72
}
,
{
   page: 0,
   name: "has no actual knowledge of conditions on the", 
   isText: false,
   type: "checkbox",
   top: 537.96,
   left: 317.76,
   width: 12.48,
   height: 9.72
}
,
{
   page: 0,
   name: "Association Known violations are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 570.84,
   left: 233.28,
   width: 165.72,
   height: 11.16
}
,
{
   page: 0,
   name: "has", 
   isText: false,
   type: "checkbox",
   top: 601.68,
   left: 135.6,
   width: 12.48,
   height: 9.72
}
,
{
   page: 0,
   name: "has not received notice from any governmental authority regarding health or", 
   isText: false,
   type: "checkbox",
   top: 601.68,
   left: 169.2,
   width: 12.48,
   height: 9.72
}
,
{
   page: 0,
   name: "property in the subdivision is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 668.76,
   left: 197.16,
   width: 76.20,
   height: 11.16
}
,
{
   page: 0,
   name: "include a description of each fee to whom each fee is payable and the amount of each fee 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 687,
   left: 508.08,
   width: 65.88,
   height: 11.16
}
,
{
   page: 0,
   name: "include a description of each fee to whom each fee is payable and the amount of each fee 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 705.24,
   left: 38.88,
   width: 535.08,
   height: 11.16
}
,
{
   page: 0,
   name: "include a description of each fee to whom each fee is payable and the amount of each fee 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 723.48,
   left: 38.88,
   width: 535.08,
   height: 11.16
}
,
{
   page: 1,
   name: "Subdivision Information Concerning", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 35.554,
   left: 202.68,
   width: 258.12,
   height: 13.166
}
,
{
   page: 1,
   name: "The Associations managing agent is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 88.973,
   left: 240.6,
   width: 327.48,
   height: 13.637
}
,
{
   page: 1,
   name: "Mailing Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 118.205,
   left: 54.0,
   width: 514.08,
   height: 12.005
}
,
{
   page: 1,
   name: "Telephone Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 147.154,
   left: 54.0,
   width: 186.72,
   height: 14.616
}
,
{
   page: 1,
   name: "Fax Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 146.664,
   left: 360.24,
   width: 207.84,
   height: 15.106
}
,
{
   page: 1,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 178.464,
   left: 54.0,
   width: 514.08,
   height: 15.106
}
,
{
   page: 1,
   name: "M The restrictions", 
   isText: false,
   type: "checkbox",
   top: 238.32,
   left: 139.68,
   width: 9.84,
   height: 7.68
}
,
{
   page: 1,
   name: "do", 
   isText: false,
   type: "checkbox",
   top: 238.32,
   left: 168.24,
   width: 9.84,
   height: 7.68
}
,
{
   page: 1,
   name: "Name of Association", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 439.454,
   left: 44.4,
   width: 522.12,
   height: 16.085
}
,
{
   page: 1,
   name: "Print Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 514.862,
   left: 105.96,
   width: 314.04,
   height: 13.148
}
,
{
   page: 1,
   name: "Title", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 538.123,
   left: 71.52,
   width: 348.48,
   height: 13.637
}
,
{
   page: 1,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 562.483,
   left: 70.2,
   width: 241.8,
   height: 13.637
}
,
{
   page: 1,
   name: "Mailing Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 587.213,
   left: 128.64,
   width: 291.36,
   height: 13.147
}
,
{
   page: 1,
   name: "Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 611.573,
   left: 82.08,
   width: 337.92,
   height: 13.147
}
] }
