import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  transActive: [],
  transActiveForManager: [],
  transUnderContractForManager: [],
  transActiveListingForManager: [],
  transActiveBuyerForManager: [],
  transClosedForManager: [],
  transUnderContract: { transactions: [], column: "", direction: "" },
  transActiveListing: { transactions: [], column: "", direction: "" },
  transActiveBuyer: { transactions: [], column: "", direction: "" },
  transAll: { transactions: [], moreTransactions: true, lastVisible: null },
  keepStateTransAll: false,
  transaction: {},
  transClients: [],
  parties: [],
  allParties: [],
  mlsData: {},
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    fetchTransActive(state, action) {
      state.transActive = action.payload;
      state.transUnderContract = {
        transactions: action.payload.filter(
          (trans) => trans.status === "Under Contract"
        ),
      };
      state.transActiveListing = {
        transactions: action.payload.filter(
          (trans) => trans.status === "Active Listing"
        ),
      };
      state.transActiveBuyer = {
        transactions: action.payload.filter(
          (trans) => trans.status === "Active Buyer"
        ),
      };
    },
    fetchTransActiveForManager(state, action) {
      state.transActiveForManager = action.payload;
      state.transUnderContractForManager = action.payload.filter(
        (trans) => trans.status === "Under Contract"
      );
      state.transActiveListingForManager = action.payload.filter(
        (trans) => trans.status === "Active Listing"
      );
      state.transActiveBuyerForManager = action.payload.filter(
        (trans) => trans.status === "Active Buyer"
      );
    },
    fetchTransClosedForManager(state, action) {
      state.transClosedForManager = action.payload;
    },
    fetchTransAll(state, action) {
      state.transAll.transactions = action.payload.transactions;
      state.transAll.moreTransactions = action.payload.moreTransactions;

      state.transAll.firstVisible = action.payload.firstVisible;
      state.transAll.lastVisible = action.payload.lastVisible;
      if (action.payload.firstTransaction) {
        state.transAll.firstTransaction = action.payload.firstTransaction;
      }
    },
    retainStateTransAll(state) {
      state.keepStateTransAll = true;
    },
    fetchTransaction(state, action) {
      const transClients = action.payload.clientSecondary?.lastName
        ? [action.payload.client, action.payload.clientSecondary]
        : [action.payload.client];
      state.transaction = action.payload;
      state.transClients = transClients;
    },
    fetchTransactionParties(state, action) {
      state.parties = action.payload.filter((party) => party.type !== "Client");
      state.allParties = action.payload;
    },
    updateTransactionTransAll(state, action) {
      const index = state.transAll.transactions.findIndex(
        (transaction) => transaction.id === action.payload.id
      );
      state.transAll.transactions[index] = {
        ...state.transAll.transactions[index],
        ...action.payload.fields,
      };
    },
    deleteTransactionTransAll(state, action) {
      if (state.transAll?.transactions) {
        const newTransactions = state.transAll.transactions.filter(
          (transaction) => transaction.id !== action.payload
        );
        state.transAll.transactions = newTransactions;
      }
    },
    sortTransUnderContract(state, action) {
      if (state.transUnderContract.column === action.payload) {
        state.transUnderContract = {
          transactions: state.transUnderContract.transactions.slice().reverse(),
          column: action.payload,
          direction:
            state.transUnderContract.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.transUnderContract = {
          transactions: _.sortBy(state.transUnderContract.transactions, [
            action.payload,
          ]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    sortTransActiveListing(state, action) {
      if (state.transActiveListing.column === action.payload) {
        state.transActiveListing = {
          transactions: state.transActiveListing.transactions.slice().reverse(),
          column: action.payload,
          direction:
            state.transActiveListing.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.transActiveListing = {
          transactions: _.sortBy(state.transActiveListing.transactions, [
            action.payload,
          ]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    sortTransActiveBuyer(state, action) {
      if (state.transActiveBuyer.column === action.payload) {
        state.transActiveBuyer = {
          transactions: state.transActiveBuyer.transactions.slice().reverse(),
          column: action.payload,
          direction:
            state.transActiveBuyer.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.transActiveBuyer = {
          transactions: _.sortBy(state.transActiveBuyer.transactions, [
            action.payload,
          ]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    getMlsData(state, action) {
      state.mlsData = action.payload;
    },
    resetMlsData(state, action) {
      state.mlsData = {};
    },
  },
});

export const {
  fetchTransActive,
  fetchTransActiveForManager,
  fetchTransClosedForManager,
  fetchTransAll,
  retainStateTransAll,
  fetchTransaction,
  fetchTransactionParties,
  updateTransactionTransAll,
  deleteTransactionTransAll,
  sortTransUnderContract,
  sortTransActiveListing,
  sortTransActiveBuyer,
  getMlsData,
  resetMlsData,
} = transactionSlice.actions;

export default transactionSlice.reducer;
