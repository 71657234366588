export function addendumPropaneGasArea() {
return [   //2024 Release 2024-02-18 22:58:11
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 119.723,
   left: 202.2,
   width: 354.72,
   height: 13.015
}
] }
