export function listingContractAmendExtendCleanWritable2020() {
return [
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date", 
   top: 156.00,
   left: 398.64,
   width: 174.00,
   height: 16.56
}
,
{
   page: 0,
   type: "checkbox",
   name: "Contract",
   isText: true, 
   top: 216.12,
   left: 74.64,
   width: 8.04,
   height: 8.28
}
,
{
   page: 0,
   type: "checkbox",
   name: "undefined",
   isText: true, 
   top: 233.52,
   left: 74.64,
   width: 8.04,
   height: 8.28
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "dated", 
   top: 243.00,
   left: 74.52,
   width: 128.88,
   height: 16.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "15", 
   top: 260.40,
   left: 50.4,
   width: 153.0,
   height: 10.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text1", 
   top: 271.638,
   left: 50.4002,
   width: 534.7658,
   height: 37.963
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "known as", 
   top: 312.00,
   left: 90.96,
   width: 436.68,
   height: 16.56
}
,
{
   page: 0,
   type: "checkbox",
   name: "undefined_2", 
   isText: true,
   top: 349.08,
   left: 74.64,
   width: 8.04,
   height: 8.28
}
,
{
   page: 0,
   type: "checkbox",
   name: "undefined_3",
   isText: true,
   top: 366.60,
   left: 74.64,
   width: 8.04,
   height: 8.28
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "dated_2", 
   top: 375.96,
   left: 74.52,
   width: 119.88,
   height: 16.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "The date ending the Listing Period is changed to", 
   top: 451.08,
   left: 269.4,
   width: 303.12,
   height: 16.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "The price or rental rate is changed to", 
   top: 468.60,
   left: 227.28,
   width: 147.24,
   height: 16.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 9,
   type: "textarea",
   name: "Text2", 
   top: 503.348,
   left: 60.873,
   width: 504.657,
   height: 47.782
}
,
{
   page: 0,
   type: "checkbox",
   name: "Seller", 
   top: 585.12,
   left: 53.64,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Landlord", 
   top: 585.12,
   left: 97.08,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Buyer", 
   top: 585.12,
   left: 156.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   type: "checkbox",
   name: "Tenant", 
   top: 585.12,
   left: 201.84,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 14,
   type: "signature",
   name: "Brokers Signature", 
   top: 612.24,
   left: 338.52,
   width: 175.56,
   height: 16.56
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 9,
   type: "date",
   name: "Date_2", 
   top: 612.24,
   left: 518.52,
   width: 68.60,
   height: 16.56
}
] }
