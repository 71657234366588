import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  autoGoToNextAnnot,
  selectDocAnnot,
} from "../../../../app/annots/annotSlice";
import { updateAnnotSignedInDb } from "../../../../app/firestore/firestoreService";
import { updateTextAnnotSigned } from "../partyDocSignUtils";

export default function AnnotFieldText({ annot, doc, pageScale }) {
  const dispatch = useDispatch();
  const { annotsSigningStatus } = useSelector((state) => state.annot);
  const ref = useRef(null);
  const inputRef = useRef(null);
  const [showTextInputField, setShowTextInputField] = useState(false);
  const [textInput, setTextInput] = useState("");

  function handleTextInputChange(event) {
    setTextInput(event.target.value);
  }

  function handleTextSave() {
    if (doc.pdfBurnVersion) {
      updateTextAnnotSigned(doc, annot, { text: textInput });
    } else {
      updateAnnotSignedInDb(annot.id, doc.id, { text: textInput });
    }
  }

  const color =
    annot.readOnly || annot.signed || annot.text
      ? "232, 232, 232"
      : "103, 211, 189";

  function handleOnClick() {
    dispatch(selectDocAnnot(annot));
    setTextInput(annot.text);
    setShowTextInputField(true);
    inputRef.current.focus();
    let annotFields = {
      text: annot.text,
    };
    try {
      dispatch(autoGoToNextAnnot(false));
      if (!doc.pdfBurnVersion) {
        updateAnnotSignedInDb(annot.id, doc.id, annotFields);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div
      ref={ref}
      id={"page" + annot.page + "-y" + annot.y.toString().split(".")[0]}
      onClick={() => handleOnClick()}
      style={{
        width: annot.width * pageScale,
        height: annot.height * pageScale,
        minWidth: 6 * pageScale,
        minHeight: 2 * pageScale,
        maxWidth: 960 * pageScale,
        maxHeight: 960 * pageScale,
        position: "absolute",
        display: "inline-block",
        cursor: "pointer",
        zIndex: 2,
        top: annot.y * pageScale,
        left: annot.x * pageScale,
        lineHeight: 0.6,
        whiteSpace: "nowrap",
      }}
    >
      {annotsSigningStatus?.nextAnnotToSign?.uniqueId === annot.uniqueId &&
        !showTextInputField && (
          <>
            <div
              className="arrow-pointer blink_me"
              style={{
                color: "white",
                backgroundColor: `rgb(${color})`,
              }}
            >
              <span>Add Text Here</span>
            </div>
          </>
        )}
      <div
        className="annot-outer-wrapper"
        style={{ backgroundColor: `rgba(${color}, 0.6)` }}
      >
        <div
          className="annot-inner-wrapper"
          style={{
            boxShadow: `rgb(${color}) 0px 0px 0px 1px`,
            zIndex: 5,
          }}
        >
          <>
            <span
              onClick={() => handleOnClick()}
              style={{
                fontSize: `${annot.fontSize * pageScale}px`,
                color: `${annot.fontColor}`,
                fontFamily: `${annot.fontFamily}`,
                width: "100%",
                height: "100%",
              }}
            >
              <input
                ref={inputRef}
                value={textInput}
                onChange={handleTextInputChange}
                onBlur={() => handleTextSave()}
                style={{
                  fontSize: `${annot.fontSize}`,
                  color: "#0037b8",
                  fontFamily: `${annot.fontFamily}`,
                  width: "100%",
                  height: "100%",
                }}
              ></input>
            </span>
          </>
        </div>
      </div>
    </div>
  );
}
