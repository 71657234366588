import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "semantic-ui-react";
import { sortTasksTransActive } from "../../taskSlice";
import TaskTransActiveWithoutDateListItem from "./TaskTransListWithoutDateListItem";

export default function TaskTransActiveWithoutDateList({
  tasks,
  column,
  direction,
}) {
  const dispatch = useDispatch();

  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell
              sorted={column === "title" ? direction : null}
              onClick={() => dispatch(sortTasksTransActive("title"))}
            >
              Name
            </Table.HeaderCell>
            {/* <Table.HeaderCell
              sorted={column === "description" ? direction : null}
              onClick={() => dispatch(sortTasksTransActive("description"))}
            >
              Description
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "status" ? direction : null}
              onClick={() => dispatch(sortTasksTransActive("status"))}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "end" ? direction : null}
              onClick={() => dispatch(sortTasksTransActive("end"))}
            >
              Deadline
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "assignedTo" ? direction : null}
              onClick={() => dispatch(sortTasksTransActive("assignedTo"))}
            >
              Assigned To
            </Table.HeaderCell> */}
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tasks.length !== 0 &&
            tasks.map((task) => (
              <TaskTransActiveWithoutDateListItem task={task} key={task.id} />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
