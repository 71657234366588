export function gTARVALoan() {
return [   //2024 Release 2024-04-18 20:17:17
{
   page: 0,
   name: "real estate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 137.4,
   left: 27.6,
   width: 552.12,
   height: 10.32
}
,
{
   page: 0,
   name: "supplement is made an integral part of the Contract All provisions of the Contract shall remain in full force and effect", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 199.56,
   left: 491.4,
   width: 77.28,
   height: 10.32
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 211.08,
   left: 546.84,
   width: 33.36,
   height: 10.32
}
,
{
   page: 0,
   name: "Buyer shall also instruct Lender to issue a written statement of conditional loan approval not final loan commitment to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 368.52,
   left: 270.12,
   width: 27.24,
   height: 10.32
}
,
{
   page: 1,
   name: "which includes prepaids", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 69.6,
   left: 369.84,
   width: 93.72,
   height: 10.32
}
,
{
   page: 1,
   name: "shall pay at time of closing an amount up to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 159.36,
   left: 237.24,
   width: 88.20,
   height: 10.32
}
,
{
   page: 1,
   name: "of the stated amount If a written agreement is not reached within the time specified in this provision this Contract is null", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 238.56,
   left: 97.44,
   width: 33.12,
   height: 10.32
}
,
{
   page: 1,
   name: "6 ADDITIONAL PROVISIONS", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 335.4,
   left: 169.2,
   width: 406.08,
   height: 16.92
}
,
{
   page: 1,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 370.44,
   left: 33.84,
   width: 541.44,
   height: 16.92
}
,
{
   page: 1,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 387.96,
   left: 33.84,
   width: 541.44,
   height: 16.92
}
,
{
   page: 1,
   name: "3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 405.36,
   left: 33.84,
   width: 541.44,
   height: 16.92
}
,
{
   page: 1,
   name: "4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 423,
   left: 33.84,
   width: 541.44,
   height: 16.92
}
,
{
   page: 1,
   name: "5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 440.52,
   left: 33.84,
   width: 541.44,
   height: 16.92
}
,
{
   page: 1,
   name: "6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 458.04,
   left: 33.84,
   width: 541.44,
   height: 16.92
}
,
{
   page: 1,
   name: "7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 475.56,
   left: 33.84,
   width: 541.44,
   height: 16.92
}
,
{
   page: 1,
   name: "8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 492.96,
   left: 33.84,
   width: 541.44,
   height: 16.92
}
,
{
   page: 1,
   name: "9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 510.48,
   left: 33.84,
   width: 541.44,
   height: 16.92
}
,
{
   page: 1,
   name: "10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 528,
   left: 33.84,
   width: 541.44,
   height: 16.92
}
] }
