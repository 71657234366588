import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchDocsTransFromDb,
  fetchHistoryFromDb,
  fetchPartiesFromDb,
  fetchTasksTransFromDb,
  fetchTransactionFromDb,
} from "./firestoreService";
import {
  fetchTransaction,
  fetchTransactionParties,
} from "../../features/transactions/transactionSlice";
import useFirestoreCollection from "../hooks/useFirestoreCollection";
import { fetchTasksTrans } from "../../features/tasks/taskSlice";
import { fetchDocsTrans } from "../../features/docs/docSlice";
import useFirestoreDoc from "../hooks/useFirestoreDoc";
import { fetchHistory } from "../../features/history/historySlice";

export default function TransactionData() {
  const dispatch = useDispatch();
  let { id } = useParams();

  useFirestoreDoc({
    query: () => fetchTransactionFromDb(id),
    data: (transaction) => dispatch(fetchTransaction(transaction)),
    deps: [dispatch, id],
  });

  useFirestoreCollection({
    query: () => fetchPartiesFromDb(id),
    data: (parties) => dispatch(fetchTransactionParties(parties)),
    deps: [dispatch, id],
  });

  useFirestoreCollection({
    query: () => fetchTasksTransFromDb(id),
    data: (tasks) => dispatch(fetchTasksTrans(tasks)),
    deps: [dispatch, id],
  });

  useFirestoreCollection({
    query: () => fetchDocsTransFromDb(id),
    data: (docs) => dispatch(fetchDocsTrans(docs)),
    deps: [dispatch, id],
  });

  useFirestoreCollection({
    query: () => fetchHistoryFromDb(id),
    data: (history) => dispatch(fetchHistory(history)),
    deps: [dispatch, id],
  });

  return <></>;
}
