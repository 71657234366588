export function definitionsofWorkingRelationshipsForSellers() {
return [
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Broker provided", 
   top: 570.059,
   left: 72.36,
   width: 129.84,
   height: 15.421
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "with this document via", 
   top: 572.057,
   left: 275.64,
   width: 179.88,
   height: 13.423
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "and retained a copy for Broker’s records", 
   top: 593.099,
   left: 57.6,
   width: 139.92,
   height: 15.421
}
,
{
   page: 0,
   name: "Brokerage Firms Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 616.019,
   left: 160.08,
   width: 392.76,
   height: 15.421
}
,
{
page: 0,
name: "Broker Signature", 
type: "signature",
fontName: "Helvetica",
fontSize: 10,
top: 649.467,
left: 59.3385,
width: 232.4995,
height: 15.421
}
]}