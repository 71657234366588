export function buyerBrokerServiceAgreement() {
return [   //2024 Release 2024-02-20 14:48:53
{
   page: 0,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 308.88,
   left: 330.24,
   width: 44.16,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 308.88,
   left: 406.8,
   width: 94.2,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 308.88,
   left: 523.68,
   width: 32.88,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 321.5,
   left: 185.52,
   width: 38.64,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 321.5,
   left: 253.88,
   width: 99.88,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 321.5,
   left: 370.32,
   width: 22.08,
   height: 13.5
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 403.38,
   left: 61.32,
   width: 10.20,
   height: 9.90
}
,
{
   page: 0,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 403.0,
   left: 273.24,
   width: 44.04,
   height: 13.5
}
,
{
   page: 0,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 452.34,
   left: 61.25,
   width: 10.20,
   height: 9.90
}
,
{
   page: 0,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 452.0,
   left: 354.0,
   width: 49.68,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 452.0,
   left: 419.04,
   width: 39.36,
   height: 13.5
}
,
{
   page: 0,
   name: "Check Box 4", 
   isText: false,
   type: "checkbox",
   top: 489.48,
   left: 61.25,
   width: 10.20,
   height: 9.90
}
,
{
   page: 0,
   name: "Check Box 5", 
   isText: false,
   type: "checkbox",
   top: 502.5,
   left: 61.25,
   width: 10.20,
   height: 9.9
}
,
{
   page: 0,
   name: "Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 502.5,
   left: 115.68,
   width: 244.32,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 550.56,
   left: 365.0,
   width: 44.2,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 69.0,
   left: 161.76,
   width: 417.84,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 88.0,
   left: 51.84,
   width: 527.697,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 107.0,
   left: 51.84,
   width: 527.697,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 126.0,
   left: 52.1431,
   width: 527.6969,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 144.72,
   left: 52.1431,
   width: 527.6969,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 277.2,
   left: 137.52,
   width: 89.04,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 277.2,
   left: 259.0,
   width: 238.52,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 277.2,
   left: 516.48,
   width: 60.96,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 312.5,
   left: 107.04,
   width: 472.80,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 348.72,
   left: 151.44,
   width: 117.12,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 348.72,
   left: 305.625,
   width: 128.055,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 348.72,
   left: 463.2,
   width: 116.55,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 25", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 384.72,
   left: 31.5,
   width: 266.82,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 432.6,
   left: 31.5,
   width: 266.82,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 480.72,
   left: 140.4,
   width: 83.6,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 480.72,
   left: 253.2,
   width: 233.16,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 480.72,
   left: 505.32,
   width: 72.36,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 516.6,
   left: 31.5,
   width: 266.86,
   height: 13.5
}
,
{
   page: 1,
   name: "signature Text Field 37", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 12,
   top: 516.6,
   left: 301.5,
   width: 190.86,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 32", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 516.6,
   left: 494.36,
   width: 87.39,
   height: 13.5
}
, 
{
   page: 7,
   name: "Logo",
   isText: false,
   fontSize: 10,
   type: "logo",
   top: 550,
   left: 31.4,
   width: 200,
   height: 30,
}
] }
