export function inspectionResponseAddenda() {
return [   //2024 Release 2024-06-07 09:44:12
{
   page: 0,
   name: "DESCRIPTION the Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 73.2,
   left: 106.08,
   width: 189.12,
   height: 18.0
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 112.92,
   left: 106.08,
   width: 36.12,
   height: 18.00
}
,
{
   page: 0,
   name: "City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 112.92,
   left: 186.24,
   width: 108.96,
   height: 18.00
}
,
{
   page: 0,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 158.746,
   left: 47.782,
   width: 180.109,
   height: 15.455
}
,
{
   page: 0,
   name: "Name of Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 182.16,
   left: 92.16,
   width: 203.16,
   height: 18.00
}
,
{
   page: 0,
   name: "Name of Buyer_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 209.16,
   left: 92.16,
   width: 203.16,
   height: 18.00
}
,
{
   page: 0,
   name: "Name of Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 236.16,
   left: 92.16,
   width: 203.16,
   height: 18.00
}
,
{
   page: 0,
   name: "Name of Seller_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 263.16,
   left: 92.16,
   width: 203.16,
   height: 18.00
}
,
{
   page: 0,
   name: "Subdivision", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 73.2,
   left: 373.08,
   width: 219.12,
   height: 18.0
}
,
{
   page: 0,
   name: "Parish", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 112.92,
   left: 373.08,
   width: 219.12,
   height: 18.00
}
,
{
   page: 0,
   name: "Name of Buyer_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 182.16,
   left: 389.16,
   width: 203.16,
   height: 18.00
}
,
{
   page: 0,
   name: "Name of Buyer_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 209.16,
   left: 389.16,
   width: 203.16,
   height: 18.00
}
,
{
   page: 0,
   name: "Name of Seller_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 236.16,
   left: 389.16,
   width: 203.16,
   height: 18.00
}
,
{
   page: 0,
   name: "Name of Seller_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 263.16,
   left: 389.16,
   width: 203.16,
   height: 18.00
}
,
{
   page: 0,
   name: "Check Box21", 
   isText: false,
   type: "checkbox",
   top: 316.377,
   left: 17.3765,
   width: 14.4000,
   height: 14.400
}
,
{
   page: 0,
   name: "Check Box22", 
   isText: false,
   type: "checkbox",
   top: 342.899,
   left: 17.462,
   width: 14.400,
   height: 14.400
}
,
{
   page: 0,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 398.52,
   left: 53.16,
   width: 260.40,
   height: 18.00
}
,
{
   page: 0,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 398.52,
   left: 332.28,
   width: 260.40,
   height: 18.00
}
,
{
   page: 0,
   name: "3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 425.76,
   left: 53.16,
   width: 260.40,
   height: 18.00
}
,
{
   page: 0,
   name: "4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 425.76,
   left: 332.28,
   width: 260.40,
   height: 18.00
}
,
{
   page: 0,
   name: "5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 453.0,
   left: 53.16,
   width: 260.40,
   height: 18.0
}
,
{
   page: 0,
   name: "6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 453.0,
   left: 332.28,
   width: 260.40,
   height: 18.0
}
,
{
   page: 0,
   name: "7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 480.24,
   left: 53.16,
   width: 260.40,
   height: 18.00
}
,
{
   page: 0,
   name: "8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 480.24,
   left: 332.28,
   width: 260.40,
   height: 18.00
}
,
{
   page: 0,
   name: "9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 507.48,
   left: 52.44,
   width: 261.12,
   height: 18.00
}
,
{
   page: 0,
   name: "10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 507.48,
   left: 331.44,
   width: 261.24,
   height: 18.00
}
,
{
   page: 1,
   name: "signature p2sellerdesignatedagent", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 148.473,
   left: 179.3,
   width: 230.663,
   height: 18.000
}
,
{
   page: 1,
   name: "DateTime_4", 
   fontSize: 9,
   type: "date",
   top: 149.04,
   left: 491.52,
   width: 96.48,
   height: 18.00
}
,
{
   page: 2,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 67.448,
   left: 18.3765,
   width: 14.4000,
   height: 14.400
}
,
{
   page: 2,
   name: "Check Box2", 
   isText: false,
   type: "checkbox",
   top: 108.688,
   left: 16.5474,
   width: 14.4000,
   height: 14.400
}
,
{
   page: 2,
   name: "Check Box3", 
   isText: false,
   type: "checkbox",
   top: 128.723,
   left: 17.462,
   width: 14.400,
   height: 14.400
}
,
{
   page: 2,
   name: "1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 121.08,
   left: 65.76,
   width: 58.32,
   height: 17.28
}
,
{
   page: 2,
   name: "Check Box4", 
   isText: false,
   type: "checkbox",
   top: 128.894,
   left: 134.354,
   width: 14.400,
   height: 14.400
}
,
{
   page: 2,
   name: "1_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 121.08,
   left: 184.2,
   width: 58.2,
   height: 17.28
}
,
{
   page: 2,
   name: "Check Box5", 
   isText: false,
   type: "checkbox",
   top: 127.894,
   left: 251.502,
   width: 14.400,
   height: 14.400
}
,
{
   page: 2,
   name: "1_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 121.08,
   left: 298.2,
   width: 58.44,
   height: 17.28
}
,
{
   page: 2,
   name: "Check Box6", 
   isText: false,
   type: "checkbox",
   top: 127.637,
   left: 368.48,
   width: 14.40,
   height: 14.400
}
,
{
   page: 2,
   name: "1_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 121.08,
   left: 416.76,
   width: 58.32,
   height: 17.28
}
,
{
   page: 2,
   name: "Check Box7", 
   isText: false,
   type: "checkbox",
   top: 127.637,
   left: 486.543,
   width: 14.400,
   height: 14.400
}
,
{
   page: 2,
   name: "1_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 121.08,
   left: 533.16,
   width: 58.20,
   height: 17.28
}
,
{
   page: 2,
   name: "Check Box8", 
   isText: false,
   type: "checkbox",
   top: 147.014,
   left: 17.3765,
   width: 14.4000,
   height: 14.400
}
,
{
   page: 2,
   name: "2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 138.96,
   left: 65.64,
   width: 58.44,
   height: 17.28
}
,
{
   page: 2,
   name: "Check Box9", 
   isText: false,
   type: "checkbox",
   top: 146.27,
   left: 134.439,
   width: 14.400,
   height: 14.40
}
,
{
   page: 2,
   name: "2_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 138.96,
   left: 181.92,
   width: 58.32,
   height: 17.28
}
,
{
   page: 2,
   name: "Check Box10", 
   isText: false,
   type: "checkbox",
   top: 145.27,
   left: 251.502,
   width: 14.400,
   height: 14.40
}
,
{
   page: 2,
   name: "2_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 138.96,
   left: 298.08,
   width: 58.56,
   height: 17.28
}
,
{
   page: 2,
   name: "Check Box11", 
   isText: false,
   type: "checkbox",
   top: 145.929,
   left: 368.565,
   width: 14.400,
   height: 14.400
}
,
{
   page: 2,
   name: "2_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 138.96,
   left: 415.92,
   width: 58.44,
   height: 17.28
}
,
{
   page: 2,
   name: "Check Box12", 
   isText: false,
   type: "checkbox",
   top: 145.929,
   left: 485.628,
   width: 14.400,
   height: 14.400
}
,
{
   page: 2,
   name: "2_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 138.96,
   left: 532.08,
   width: 59.28,
   height: 17.28
}
,
{
   page: 2,
   name: "Check Box13", 
   isText: false,
   type: "checkbox",
   top: 206.631,
   left: 17.462,
   width: 14.400,
   height: 14.400
}
,
{
   page: 2,
   name: "In", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 198.48,
   left: 39.84,
   width: 327.96,
   height: 10.92
}
,
{
   page: 2,
   name: "DOLLARS", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 198.48,
   left: 415.44,
   width: 172.92,
   height: 10.92
}
,
{
   page: 2,
   name: "Check Box14", 
   isText: false,
   type: "checkbox",
   top: 247.786,
   left: 17.2056,
   width: 14.4000,
   height: 14.400
}
,
{
   page: 3,
   name: "Check Box15", 
   isText: false,
   type: "checkbox",
   top: 80.252,
   left: 25.6075,
   width: 14.4000,
   height: 14.400
}
,
{
   page: 3,
   name: "Check Box16", 
   isText: false,
   type: "checkbox",
   top: 116.919,
   left: 25.6929,
   width: 14.4000,
   height: 14.400
}
,
{
   page: 3,
   name: "Check Box17", 
   isText: false,
   type: "checkbox",
   top: 153.501,
   left: 25.6075,
   width: 14.4000,
   height: 14.400
}
,
{
   page: 3,
   name: "Check Box18", 
   isText: false,
   type: "checkbox",
   top: 179.767,
   left: 25.6075,
   width: 14.4000,
   height: 14.400
}
,
{
   page: 3,
   name: "Check Box19", 
   isText: false,
   type: "checkbox",
   top: 204.46,
   left: 25.6929,
   width: 14.4000,
   height: 14.40
}
,
{
   page: 3,
   name: "Check Box20", 
   isText: false,
   type: "checkbox",
   top: 241.042,
   left: 25.7784,
   width: 14.4000,
   height: 14.400
}
] }
