export function titleRelatedObjectionNotice() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 240,
      height: 36,
    }
    ,
    {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 142.698,
      left: 398.64,
      width: 174.00,
      height: 11.622
   }
   ,
   {
      page: 0,
      name: "Buyer relating", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 173.549,
      left: 398.16,
      width: 128.101,
      height: 10.971
   }
   ,
   {
      page: 0,
      name: "to the sale and purchase of the Property known as", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 186.116,
      left: 50.4,
      width: 452.147,
      height: 12.924
   }
   ,
   {
      page: 0,
      name: "Contract Terms", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 199.835,
      left: 50.4,
      width: 452.147,
      height: 12.924
   }
   ,
   {
      page: 0,
      name: "used herein shall have the same meaning as in the Contract", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 226.632,
      left: 50.4,
      width: 452.147,
      height: 12.924
   }
   ,
   {
      page: 0,
      name: "Check Box1", 
      isText: false,
      type: "checkbox",
      top: 350.454,
      left: 69.3271,
      width: 11.7749,
      height: 10.247
   }
   ,
   {
      page: 0,
      name: "Check Box3", 
      isText: false,
      type: "checkbox",
      top: 350.799,
      left: 276.163,
      width: 11.775,
      height: 10.902
   }
   ,
   {
      page: 0,
      name: "Check Box2", 
      isText: false,
      type: "checkbox",
      top: 364.454,
      left: 69.7635,
      width: 11.7749,
      height: 10.902
   }
   ,
   {
      page: 0,
      name: "Check Box4", 
      isText: false,
      type: "checkbox",
      top: 364.581,
      left: 276.163,
      width: 11.775,
      height: 10.902
   }
   ,
   {
      page: 0,
      name: "Text5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 433.086,
      left: 49.1012,
      width: 525.8168,
      height: 96.609
   }
   ,
   {
      page: 0,
      name: "Check Box7", 
      isText: false,
      type: "checkbox",
      top: 663.599,
      left: 65.3271,
      width: 11.7749,
      height: 10.902
   }
   ,
   {
      page: 0,
      name: "Check Box145", 
      isText: false,
      type: "checkbox",
      top: 663.599,
      left: 264.981,
      width: 11.775,
      height: 10.902
   }
   ,
   {
      page: 0,
      name: "Check Box143", 
      isText: false,
      type: "checkbox",
      top: 680.678,
      left: 65.3271,
      width: 11.7749,
      height: 10.902
   }
   ,
   {
      page: 0,
      name: "Check Box144", 
      isText: false,
      type: "checkbox",
      top: 680.678,
      left: 264.981,
      width: 11.775,
      height: 10.902
   }
   ] }
   