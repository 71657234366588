import React from "react";
import { useSelector } from "react-redux";
import LoginForm from "../../../features/auth/LoginForm";
import ResetPasswordForm from "../../../features/auth/ResetPasswordForm";
import PrivacyPolicy from "../../../features/home/legal/PrivacyPolicy";
import TermsConditions from "../../../features/home/legal/TermsConditions";
import DeleteAccountForm from "../../../features/auth/DeleteAccountForm";
import TransactionForm from "../../../features/transactions/transactionComponents/TransactionForm";
import TransactionPartyForm from "../../../features/transactions/transactionComponents/TransactionPartyForm";
import TransactionPropertyForm from "../../../features/transactions/transactionComponents/TransactionPropertyForm";
import TaskForm from "../../../features/tasks/taskComponents/TaskForm";
import DocAddDocumentsModal from "../../../features/docs/docComponents/docAdd/DocAddDocumentsModal";
import DocSelectFormsModal from "../../../features/docs/docComponents/docAdd/DocSelectFormsModal";
import TransactionPropertyDetails from "../../../features/transactions/transactionComponents/TransactionPropertyDetails";
import TaskViewModal from "../../../features/tasks/taskComponents/TaskViewModal";
import TransactionClientAddForm from "../../../features/transactions/transactionComponents/TransactionCientAddForm";
import TransactionClientEditForm from "../../../features/transactions/transactionComponents/TransactionClientEditForm";
import TransactionPhotoUpload from "../../../features/transactions/transactionComponents/TransactionPhotoUpload";
import TransactionEditForm from "../../../features/transactions/transactionComponents/TransactionEditForm";
import DocPrepareAddSigner from "../../../features/docs/docComponents/docPrepare/signerList/DocPrepareAddSigner";
import DocUploadForEsign from "../../../features/docs/docComponents/DocUploadForEsign";
import DocUploadModal from "../../../features/docs/docComponents/docAdd/DocUploadModal";
import PersonForm from "../../../features/people/peopleComponents/PersonForm";
import PersonView from "../../../features/people/peopleComponents/PersonView";
import SendForSigning from "../../../features/docs/docComponents/docPrepare/sendForSigning/SendForSigning";
import AnnotSignatureModal from "../../../features/parties/partyDocSign/annots/AnnotFieldSignatureModal";
import DocExtractDeadlines from "../../../features/docs/docComponents/DocExtractDeadlines";
import DocRenameModal from "../../../features/docs/docComponents/DocRenameModal";
import PartyDocSignComplete from "../../../features/parties/partyDocSign/PartyDocSignComplete";
import DocShareAgentLinking from "../../../features/docs/docComponents/DocShareAgentLinking";
import DocShareAgentLinkSuccessfull from "../../../features/docs/docComponents/DocShareAgentLinkSuccessfull";
import FormTemplateRenameModal from "../../../features/formTemplates/formTemplatesComponents/FormTemplateRenameModal";
import FormTemplatesSelectFormsModal from "../../../features/formTemplates/formTemplatesComponents/FormTemplatesSelectFormsModal";
import PartyLoginCurrentUserForm from "../../../features/parties/partyLogin/PartyLoginCurrentUserForm";
import PartyLoginNewUserForm from "../../../features/parties/partyLogin/PartyLoginNewUserForm";
import TaskShareAgentWarning from "../../../features/tasks/taskComponents/TaskShareAgentWarning";
import DeadlineTemplatesEdit from "../../../features/deadlineTemplates/deadlineTemplatesComponents/DeadlineTemplatesEdit";
import DeadlineTemplatesRename from "../../../features/deadlineTemplates/deadlineTemplatesComponents/DeadlineTemplatesRename";
import DeadlineTemplatesSelect from "../../../features/deadlineTemplates/deadlineTemplatesComponents/DeadlineTemplatesSelect";
import DeadlineTemplatesApply from "../../../features/deadlineTemplates/deadlineTemplatesComponents/DeadlineTemplatesApply";
import ClausesView from "../../../features/clauses/clausesComponents/ClausesView";
import ClausesEdit from "../../../features/clauses/clausesComponents/ClausesEdit";
import TaskTemplatesRename from "../../../features/tasks/taskTemplates/taskTemplatesComponents/TaskTemplatesRename";
import TaskTemplatesEdit from "../../../features/tasks/taskTemplates/taskTemplatesComponents/TaskTemplatesEdit";
import TaskTemplatesSelect from "../../../features/tasks/taskTemplates/taskTemplatesComponents/TaskTemplatesSelect";
import TaskDeadlineForm from "../../../features/tasks/taskComponents/TaskDeadlineForm";
import AdminLogoPhotoUpload from "../../../features/auth/AdminLogoPhotoUpload";
import TransactionDeleteConfirmModal from "../../../features/transactions/transactionComponents/TransactionDeleteConfirmModal";
import DocViewImageFileModal from "../../../features/docs/docComponents/docView/DocViewImageFileModal";
import EmailTemplatesEdit from "../../../features/emailTemplates/emailTemplatesComponents/EmailTemplatesEdit";
import EmailTemplatesRename from "../../../features/emailTemplates/emailTemplatesComponents/EmailTemplatesRename";
import EmailTemplatesSelect from "../../../features/emailTemplates/emailTemplatesComponents/EmailTemplatesSelect";

export default function ModalManager() {
  const modalLookup = {
    LoginForm,
    ResetPasswordForm,
    PrivacyPolicy,
    TermsConditions,
    DeleteAccountForm,
    TransactionForm,
    TaskForm,
    DocAddDocumentsModal,
    DocSelectFormsModal,
    TransactionPartyForm,
    TransactionPropertyForm,
    TransactionPropertyDetails,
    TaskViewModal,
    TransactionClientAddForm,
    TransactionClientEditForm,
    TransactionPhotoUpload,
    TransactionEditForm,
    DocPrepareAddSigner,
    DocUploadForEsign,
    DocUploadModal,
    PersonForm,
    PersonView,
    SendForSigning,
    AnnotSignatureModal,
    DocExtractDeadlines,
    DocRenameModal,
    PartyDocSignComplete,
    DocShareAgentLinking,
    DocShareAgentLinkSuccessfull,
    FormTemplatesSelectFormsModal,
    FormTemplateRenameModal,
    PartyLoginCurrentUserForm,
    PartyLoginNewUserForm,
    TaskShareAgentWarning,
    DeadlineTemplatesEdit,
    DeadlineTemplatesRename,
    DeadlineTemplatesSelect,
    DeadlineTemplatesApply,
    ClausesView,
    ClausesEdit,
    TaskTemplatesRename,
    TaskTemplatesEdit,
    TaskTemplatesSelect,
    TaskDeadlineForm,
    AdminLogoPhotoUpload,
    TransactionDeleteConfirmModal,
    DocViewImageFileModal,
    EmailTemplatesEdit,
    EmailTemplatesRename,
    EmailTemplatesSelect,
  };
  const currentModal = useSelector((state) => state.modals);
  let renderedModal;
  if (currentModal?.modalType !== "") {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
