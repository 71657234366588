import React from "react";
import { Table } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { sortDocsForSigning } from "../partySlice";
import PartyDocSignListItem from "./PartyDocSignListItem";

export default function PartyDocSignList({ type, docs, column, direction }) {
  const dispatch = useDispatch();

  return (
    <>
      <Table
        id="signing-table"
        compact
        sortable
        color="orange"
        inverted
        className="mini top margin"
      >
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header-orange">
            <Table.HeaderCell
              sorted={column === "title" ? direction : null}
              onClick={() => dispatch(sortDocsForSigning("title"))}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {docs &&
            docs.length !== 0 &&
            docs.map((doc) => <PartyDocSignListItem doc={doc} key={doc.id} />)}
        </Table.Body>
      </Table>
    </>
  );
}
