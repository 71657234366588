import { Formik, Form } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { updateTransactionInDb } from "../../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";
import FormAddress from "../../../app/common/form/FormAddress";

export default function TransactionPropertyForm() {
  const dispatch = useDispatch();
  const { transaction } = useSelector((state) => state.transaction);

  let initialValues = {
    address: transaction.address,
    propertyDetails: transaction.propertyDetails,
  };

  const validationSchema = Yup.object({
    address: Yup.string().required("You must provide an address"),
  });

  return (
    <ModalWrapper size="medium">
      <Segment clearing>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await updateTransactionInDb(transaction.id, values);
              setSubmitting(false);
              toast.success("Property successfully updated");
              dispatch(
                closeModal({
                  modalType: "TransactionPropertyForm",
                })
              );
            } catch (error) {
              toast.error(error.message);
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid, setFieldValue }) => (
            <Form className="ui form medium margin bottom">
              <Header size="huge" color="blue">
                Edit Property
              </Header>
              <Divider />
              <Grid>
                {/* <Grid.Column width={4}>
                  <Icon
                    name="edit"
                    color="blue"
                    className="float-right"
                    // size="small"
                    link
                    onClick={() => handleEditPic()}
                    // style={{ paddingBottom: "3px", marginLeft: "10px" }}
                  />
                  {transaction.pic ? (
                    <Image src={transaction.pic} size="medium" />
                  ) : (
                    <Image
                      src="/assets/placeholder-house.png"
                      size="medium"
                      // wrapped
                      // ui={false}
                    />
                  )}
                </Grid.Column> */}
                <Grid.Column width={16}>
                  <FormAddress />
                  <Grid className="zero top padding zero top margin">
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <MyTextInput
                          name="propertyDetails.county"
                          placeholder="County"
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <MyTextInput
                          name="propertyDetails.mlsNumber"
                          placeholder="MLS Number"
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <MyTextInput
                          name="propertyDetails.parcelNumber"
                          placeholder="Parcel Number"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="zero top padding">
                      <Grid.Column width={6}>
                        <MyTextInput
                          name="propertyDetails.subdivision"
                          placeholder="Subdivision"
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <MyTextInput
                          name="propertyDetails.yearBuilt"
                          placeholder="Year Built"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
              <br />
              <br />
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                floated="right"
                primary
                content="Submit"
              />
              <Button
                disabled={isSubmitting}
                onClick={() =>
                  dispatch(
                    closeModal({
                      modalType: "TransactionPropertyForm",
                    })
                  )
                }
                to="#"
                type="button"
                floated="right"
                content="Cancel"
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </ModalWrapper>
  );
}
