export function cBFSBOAgreementforPaymentofCommission() {
return [   //2024 Release 2024-03-11 12:37:55
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 135.96,
   left: 459.84,
   width: 114.12,
   height: 10.68
}
,
{
   page: 0,
   name: "sellers", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 200.4,
   left: 309.84,
   width: 261.96,
   height: 10.68
}
,
{
   page: 0,
   name: "CO", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 224.095,
   left: 223.08,
   width: 278.64,
   height: 10.025
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 223.44,
   left: 519.96,
   width: 52.178,
   height: 10.68
}
,
{
   page: 0,
   name: "Check Box30", 
   isText: false,
   type: "checkbox",
   top: 269.401,
   left: 35.7274,
   width: 13.4182,
   height: 12.764
}
,
{
   page: 0,
   name: "Check Box31", 
   isText: false,
   type: "checkbox",
   top: 269.401,
   left: 114.928,
   width: 13.418,
   height: 12.764
}
,
{
   page: 0,
   name: "closing of the purchase of the property referenced above from Seller to a Buyer The commission shall be paid in full at the closing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 361.44,
   left: 79.2,
   width: 25.2,
   height: 10.56
}
,
{
   page: 0,
   name: "of the gross sales price in US dollars or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 361.44,
   left: 284.88,
   width: 54.96,
   height: 10.56
}
,
{
   page: 0,
   name: "signature Broker Associate", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 629.24,
   left: 36.0,
   width: 195.479,
   height: 10.68
}
,
{
   page: 0,
   name: "signature cobrokerBuyer", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 629.445,
   left: 263.105,
   width: 163.778,
   height: 10.680
}
,
{
   page: 0,
   name: "Date_4", 
   fontSize: 9,
   type: "date",
   top: 629.24,
   left: 458.16,
   width: 117.96,
   height: 10.68
}
] }
