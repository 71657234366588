export function assumptionofLoan() {
return [   //2024 Release 2024-03-06 22:07:26
{
   page: 0,
   name: "Text Field 18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 104.4,
   left: 31.5,
   width: 549.0,
   height: 13.5
}
,
{
   page: 0,
   name: "Check Box 1", 
   isText: false,
   type: "checkbox",
   top: 195.44,
   left: 183.024,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 195.44,
   left: 210.096,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 195.44,
   left: 244.08,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 195.584,
   left: 280.08,
   width: 295.20,
   height: 13.500
}
,
{
   page: 0,
   name: "Check Box 4", 
   isText: false,
   type: "checkbox",
   top: 218.48,
   left: 183.024,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 5", 
   isText: false,
   type: "checkbox",
   top: 218.48,
   left: 210.096,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 6", 
   isText: false,
   type: "checkbox",
   top: 218.48,
   left: 244.08,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 218.192,
   left: 280.125,
   width: 295.200,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 254.448,
   left: 320.76,
   width: 108.72,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 254.448,
   left: 471.78,
   width: 108.72,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 268.56,
   left: 49.68,
   width: 258.84,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 268.56,
   left: 499.68,
   width: 62.28,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 399.375,
   left: 95.472,
   width: 46.800,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 450.207,
   left: 102.24,
   width: 51.84,
   height: 13.500
}
,
{
   page: 0,
   name: "Check Box 7", 
   isText: false,
   type: "checkbox",
   top: 575.775,
   left: 49.968,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Text Field 14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 575.262,
   left: 406.512,
   width: 98.613,
   height: 13.500
}
,
{
   page: 0,
   name: "Check Box 8", 
   isText: false,
   type: "checkbox",
   top: 635.868,
   left: 49.968,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Text Field 15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 649.359,
   left: 108.432,
   width: 98.496,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 36", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 14.4,
   left: 111.96,
   width: 467.28,
   height: 13.5
}
,
{
   page: 1,
   name: "Check Box 9", 
   isText: false,
   type: "checkbox",
   top: 149.08,
   left: 233.568,
   width: 9.216,
   height: 9.144
}
,
{
   page: 1,
   name: "Check Box 10", 
   isText: false,
   type: "checkbox",
   top: 149.08,
   left: 256.896,
   width: 9.216,
   height: 9.144
}
,
{
   page: 1,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 200.056,
   left: 484.56,
   width: 93.312,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 250.456,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 264.712,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 278.968,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 291.928,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 25", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 306.184,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 320.44,
   left: 31.5,
   width: 549.0,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 333.94,
   left: 31.5,
   width: 549.0,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 348.196,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 362.452,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 375.708,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 389.964,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
] }
