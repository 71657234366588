export function counterproposal() {
   return [   //2023
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 154.32,
      left: 398.64,
      width: 174.00,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "contract dated", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 188.88,
      left: 109.32,
      width: 121.20,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Contract between", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 188.88,
      left: 312.36,
      width: 260.28,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Seller and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 200.4,
      left: 99.84,
      width: 306.36,
      height: 10.56
   }
   ,
   {
      page: 0,
      name: "of the following legally described real estate in the County of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 211.8,
      left: 298.44,
      width: 107.64,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Text1", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 223.856,
      left: 49.7457,
      width: 522.9843,
      height: 32.727
   }
   ,
   {
      page: 0,
      name: "known as", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 259.32,
      left: 96.24,
      width: 431.40,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineTime of Day Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 383.102,
      left: 357.549,
      width: 158.459,
      height: 10.527
   }
   ,
   {
      page: 0,
      name: "No ChangeTime of Day Deadline", 
      isText: true,
      type: "checkbox",
      top: 383.808,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedTime of Day Deadline", 
      isText: true,
      type: "checkbox",
      top: 383.407,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAlternative Earnest Money Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 395.451,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineAlternative Earnest Money Deadline", 
      fontSize: 9,
      type: "date",
      top: 395.673,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeAlternative Earnest Money Deadline", 
      isText: true,
      type: "checkbox",
      top: 395.76,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedAlternative Earnest Money Deadline", 
      isText: true,
      type: "checkbox",
      top: 395.76,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineRecord Title Deadline and Tax Certificate", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 420.411,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineRecord Title Deadline and Tax Certificate", 
      fontSize: 9,
      type: "date",
      top: 420.633,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeRecord Title Deadline and Tax Certificate", 
      isText: true,
      type: "checkbox",
      top: 420.72,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedRecord Title Deadline and Tax Certificate", 
      isText: true,
      type: "checkbox",
      top: 420.72,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineRecord Title Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 433.011,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineRecord Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 433.233,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeRecord Title Objection Deadline", 
      isText: true,
      type: "checkbox",
      top: 433.32,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedRecord Title Objection Deadline", 
      isText: true,
      type: "checkbox",
      top: 433.32,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineOffRecord Title Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 445.491,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineOffRecord Title Deadline", 
      fontSize: 9,
      type: "date",
      top: 445.713,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeOffRecord Title Deadline", 
      isText: true,
      type: "checkbox",
      top: 445.8,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedOffRecord Title Deadline", 
      isText: true,
      type: "checkbox",
      top: 445.8,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineOffRecord Title Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 457.971,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineOffRecord Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 458.193,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeOffRecord Title Objection Deadline", 
      isText: true,
      type: "checkbox",
      top: 458.28,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedOffRecord Title Objection Deadline", 
      isText: true,
      type: "checkbox",
      top: 458.28,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineTitle Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 470.451,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineTitle Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 470.673,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeTitle Resolution Deadline", 
      isText: true,
      type: "checkbox",
      top: 470.76,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedTitle Resolution Deadline", 
      isText: true,
      type: "checkbox",
      top: 470.76,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineThird Party Right to PurchaseApprove Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 482.931,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineThird Party Right to PurchaseApprove Deadline", 
      fontSize: 9,
      type: "date",
      top: 483.153,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeThird Party Right to PurchaseApprove Deadline", 
      isText: true,
      type: "checkbox",
      top: 483.24,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedThird Party Right to PurchaseApprove Deadline", 
      isText: true,
      type: "checkbox",
      top: 483.24,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAssociation Documents Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 508.011,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineAssociation Documents Deadline", 
      fontSize: 9,
      type: "date",
      top: 508.75,
      left: 438.788,
      width: 78.495,
      height: 11.40
   }
   ,
   {
      page: 0,
      name: "No ChangeAssociation Documents Deadline", 
      isText: true,
      type: "checkbox",
      top: 508.32,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedAssociation Documents Deadline", 
      isText: true,
      type: "checkbox",
      top: 508.32,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAssociation Documents Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 520.491,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineAssociation Documents Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 520.713,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeAssociation Documents Termination Deadline", 
      isText: true,
      type: "checkbox",
      top: 520.8,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedAssociation Documents Termination Deadline", 
      isText: true,
      type: "checkbox",
      top: 520.8,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineSellers Property Disclosure Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 545.451,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineSellers Property Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 545.673,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeSellers Property Disclosure Deadline", 
      isText: true,
      type: "checkbox",
      top: 545.76,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedSellers Property Disclosure Deadline", 
      isText: true,
      type: "checkbox",
      top: 545.76,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineLeadBased Paint Disclosure Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 557.931,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineLeadBased Paint Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 558.153,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeLeadBased Paint Disclosure Deadline", 
      isText: true,
      type: "checkbox",
      top: 558.24,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedLeadBased Paint Disclosure Deadline", 
      isText: true,
      type: "checkbox",
      top: 558.24,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineNew Loan Application Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 583.011,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineNew Loan Application Deadline", 
      fontSize: 9,
      type: "date",
      top: 583.233,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeNew Loan Application Deadline", 
      isText: true,
      type: "checkbox",
      top: 583.32,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedNew Loan Application Deadline", 
      isText: true,
      type: "checkbox",
      top: 583.32,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineNew Loan Terms Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 595.491,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineNew Loan Terms Deadline", 
      fontSize: 9,
      type: "date",
      top: 595.713,
      left: 438.788,
      width: 78.495,
      height: 10.799
   }
   ,
   {
      page: 0,
      name: "No ChangeNew Loan Terms Deadline", 
      isText: true,
      type: "checkbox",
      top: 595.8,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedNew Loan Terms Deadline", 
      isText: true,
      type: "checkbox",
      top: 595.8,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineNew Loan Availability Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 607.971,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineNew Loan Availability Deadline", 
      fontSize: 9,
      type: "date",
      top: 608.193,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeNew Loan Availability Deadline", 
      isText: true,
      type: "checkbox",
      top: 608.28,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedNew Loan Availability Deadline", 
      isText: true,
      type: "checkbox",
      top: 608.28,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineBuyers Credit Information Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 620.451,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineBuyers Credit Information Deadline", 
      fontSize: 9,
      type: "date",
      top: 620.673,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeBuyers Credit Information Deadline", 
      isText: true,
      type: "checkbox",
      top: 620.76,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedBuyers Credit Information Deadline", 
      isText: true,
      type: "checkbox",
      top: 620.76,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineDisapproval of Buyers Credit Information Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 632.931,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineDisapproval of Buyers Credit Information Deadline", 
      fontSize: 9,
      type: "date",
      top: 633.153,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeDisapproval of Buyers Credit Information Deadline", 
      isText: true,
      type: "checkbox",
      top: 633.24,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedDisapproval of Buyers Credit Information Deadline", 
      isText: true,
      type: "checkbox",
      top: 633.24,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineExisting Loan Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 645.411,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineExisting Loan Deadline", 
      fontSize: 9,
      type: "date",
      top: 645.633,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeExisting Loan Deadline", 
      isText: true,
      type: "checkbox",
      top: 645.72,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedExisting Loan Deadline", 
      isText: true,
      type: "checkbox",
      top: 645.72,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineExisting Loan Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 658.011,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineExisting Loan Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 658.233,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeExisting Loan Termination Deadline", 
      isText: true,
      type: "checkbox",
      top: 658.32,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedExisting Loan Termination Deadline", 
      isText: true,
      type: "checkbox",
      top: 658.32,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineLoan Transfer Approval Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 670.491,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineLoan Transfer Approval Deadline", 
      fontSize: 9,
      type: "date",
      top: 670.713,
      left: 438.788,
      width: 78.495,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "No ChangeLoan Transfer Approval Deadline", 
      isText: true,
      type: "checkbox",
      top: 670.8,
      left: 527.702,
      width: 11.002,
      height: 9.872
   }
   ,
   {
      page: 0,
      name: "DeletedLoan Transfer Approval Deadline", 
      isText: true,
      type: "checkbox",
      top: 670.8,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineSeller or Private Financing Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 682.971,
      left: 357.203,
      width: 80.786,
      height: 11.400
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineSeller or Private Financing Deadline", 
      fontSize: 9,
      type: "date",
      top: 683.193,
      left: 438.788,
      width: 78.495,
      height: 11.3999
   }
   ,
   {
      page: 0,
      name: "No ChangeSeller or Private Financing Deadline", 
      isText: true,
      type: "checkbox",
      top: 683.28,
      left: 527.702,
      width: 11.002,
      height: 9.8723
   }
   ,
   {
      page: 0,
      name: "DeletedSeller or Private Financing Deadline", 
      isText: true,
      type: "checkbox",
      top: 683.28,
      left: 558.713,
      width: 10.675,
      height: 10.3091
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAppraisal Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 707.9309,
      left: 357.203,
      width: 80.786,
      height: 11.4000
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineAppraisal Deadline", 
      fontSize: 9,
      type: "date",
      top: 708.1528,
      left: 438.788,
      width: 78.495,
      height: 11.4000
   }
   ,
   {
      page: 0,
      name: "No ChangeAppraisal Deadline", 
      isText: true,
      type: "checkbox",
      top: 708.24,
      left: 527.702,
      width: 11.002,
      height: 9.8723
   }
   ,
   {
      page: 0,
      name: "DeletedAppraisal Deadline", 
      isText: true,
      type: "checkbox",
      top: 708.24,
      left: 558.713,
      width: 10.675,
      height: 10.309
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAppraisal Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 720.4109,
      left: 357.203,
      width: 80.786,
      height: 11.4000
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineAppraisal Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 720.6328,
      left: 438.788,
      width: 78.495,
      height: 11.4000
   }
   ,
   {
      page: 0,
      name: "No ChangeAppraisal Objection Deadline", 
      isText: true,
      type: "checkbox",
      top: 720.72,
      left: 527.702,
      width: 11.002,
      height: 9.8723
   }
   ,
   {
      page: 0,
      name: "DeletedAppraisal Objection Deadline", 
      isText: true,
      type: "checkbox",
      top: 720.72,
      left: 558.713,
      width: 10.675,
      height: 10.3091
   }
   ,
   {
      page: 1,
      name: "Appraisal Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 58.68,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Appraisal Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 58.149,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text2", 
      isText: true,
      type: "checkbox",
      top: 59.738,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text3", 
      isText: true,
      type: "checkbox",
      top: 58.927,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "New ILC or New Survey Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 83.109,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField New ILC or New Survey Deadline", 
      fontSize: 9,
      type: "date",
      top: 83.109,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text4", 
      isText: true,
      type: "checkbox",
      top: 84.8,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text5", 
      isText: true,
      type: "checkbox",
      top: 83.636,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "New ILC or New Survey Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 95.709,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField New ILC or New Survey Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 95.709,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text6", 
      isText: true,
      type: "checkbox",
      top: 97.564,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text7", 
      isText: true,
      type: "checkbox",
      top: 96.564,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "New ILC or New Survey Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 108.189,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField New ILC or New Survey Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 108.189,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text8", 
      isText: true,
      type: "checkbox",
      top: 110.0,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text9", 
      isText: true,
      type: "checkbox",
      top: 108.836,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Water Rights Examination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 133.149,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Water Rights Examination Deadline", 
      fontSize: 9,
      type: "date",
      top: 133.149,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text10", 
      isText: true,
      type: "checkbox",
      top: 134.709,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text11", 
      isText: true,
      type: "checkbox",
      top: 134.036,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Mineral Rights Examination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 145.629,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Mineral Rights Examination Deadline", 
      fontSize: 9,
      type: "date",
      top: 145.629,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text12", 
      isText: true,
      type: "checkbox",
      top: 147.309,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text13", 
      isText: true,
      type: "checkbox",
      top: 146.145,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Inspection Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 158.109,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Inspection Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 158.109,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text14", 
      isText: true,
      type: "checkbox",
      top: 159.909,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text15", 
      isText: true,
      type: "checkbox",
      top: 159.073,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Inspection Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 170.709,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Inspection Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 170.709,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text16", 
      isText: true,
      type: "checkbox",
      top: 172.591,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text17", 
      isText: true,
      type: "checkbox",
      top: 171.427,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Inspection Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 183.189,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Inspection Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 183.189,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text18", 
      isText: true,
      type: "checkbox",
      top: 185.109,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text19", 
      isText: true,
      type: "checkbox",
      top: 184.109,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Property Insurance Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 195.669,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Property Insurance Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 195.669,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text20", 
      isText: true,
      type: "checkbox",
      top: 197.464,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text21", 
      isText: true,
      type: "checkbox",
      top: 196.464,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Due Diligence Documents Delivery Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 208.149,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Due Diligence Documents Delivery Deadline", 
      fontSize: 9,
      type: "date",
      top: 208.149,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text22", 
      isText: true,
      type: "checkbox",
      top: 209.982,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text23", 
      isText: true,
      type: "checkbox",
      top: 208.818,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Due Diligence Documents Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 220.629,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Due Diligence Documents Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 220.629,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text24", 
      isText: true,
      type: "checkbox",
      top: 222.5,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text25", 
      isText: true,
      type: "checkbox",
      top: 221.5,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Due Diligence Documents Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 233.109,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Due Diligence Documents Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 233.109,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text26", 
      isText: true,
      type: "checkbox",
      top: 234.936,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text27", 
      isText: true,
      type: "checkbox",
      top: 233.773,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Environmental Inspection Objection Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 245.709,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Environmental Inspection Objection Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 245.709,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text28", 
      isText: true,
      type: "checkbox",
      top: 247.373,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text29", 
      isText: true,
      type: "checkbox",
      top: 246.291,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "ADA Evaluation Termination Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 258.189,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField ADA Evaluation Termination Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 258.189,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text30", 
      isText: true,
      type: "checkbox",
      top: 260.136,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text31", 
      isText: true,
      type: "checkbox",
      top: 258.973,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Conditional Sale Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 270.669,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Conditional Sale Deadline", 
      fontSize: 9,
      type: "date",
      top: 270.669,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text32", 
      isText: true,
      type: "checkbox",
      top: 272.491,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text33", 
      isText: true,
      type: "checkbox",
      top: 271.654,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "LeadBased Paint Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 283.149,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField LeadBased Paint Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 283.149,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text34", 
      isText: true,
      type: "checkbox",
      top: 285.009,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text35", 
      isText: true,
      type: "checkbox",
      top: 284.091,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Estoppel Statements Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 295.629,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Estoppel Statements Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 295.629,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text36", 
      isText: true,
      type: "checkbox",
      top: 297.527,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text37", 
      isText: true,
      type: "checkbox",
      top: 296.363,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Estoppel Statements Termination Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 308.109,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Estoppel Statements Termination Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 308.109,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text38", 
      isText: true,
      type: "checkbox",
      top: 310.045,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text39", 
      isText: true,
      type: "checkbox",
      top: 308.963,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Closing Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 333.189,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Closing Date", 
      fontSize: 9,
      type: "date",
      top: 333.189,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text40", 
      isText: true,
      type: "checkbox",
      top: 335.163,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text41", 
      isText: true,
      type: "checkbox",
      top: 333.918,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Possession Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 345.669,
      left: 375.24,
      width: 73.385,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "DateField Possession Date", 
      fontSize: 9,
      type: "date",
      top: 345.669,
      left: 452.543,
      width: 65.203,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text42", 
      isText: true,
      type: "checkbox",
      top: 347.682,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text43", 
      isText: true,
      type: "checkbox",
      top: 346.763,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Possession Time", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 358.327,
      left: 375.24,
      width: 140,
      height: 10.527
   }
   ,
   {
      page: 1,
      name: "Text44", 
      isText: true,
      type: "checkbox",
      top: 360.118,
      left: 530.589,
      width: 9.278,
      height: 9.084
   }
   ,
   {
      page: 1,
      name: "Text45", 
      isText: true,
      type: "checkbox",
      top: 359.2,
      left: 559.445,
      width: 9.758,
      height: 9.845
   }
   ,
   {
      page: 1,
      name: "Text58", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 456.193,
      left: 375.827,
      width: 89.397,
      height: 11.400
   }
   ,
   {
      page: 1,
      name: "fill_53", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 468.84,
      left: 478.158,
      width: 92.442,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "fill_55", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 481.773,
      left: 478.358,
      width: 92.242,
      height: 10.947
   }
   ,
   {
      page: 1,
      name: "fill_57", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 493.8,
      left: 478.214,
      width: 92.386,
      height: 11.4
   }
   ,
   {
      page: 1,
      name: "fill_59", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 506.28,
      left: 478.214,
      width: 92.386,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Text53", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 519.132,
      left: 478.386,
      width: 92.385,
      height: 11.400
   }
   ,
   {
      page: 1,
      name: "fill_61", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 556.32,
      left: 478.378,
      width: 92.222,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "fill_63", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 568.8,
      left: 375.955,
      width: 88.925,
      height: 11.4
   }
   ,
   {
      page: 1,
      name: "fill_64", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 568.8,
      left: 478.051,
      width: 92.549,
      height: 11.4
   }
   ,
   {
      page: 1,
      name: "Text59", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 603.215,
      left: 52.8808,
      width: 521.0172,
      height: 36.493
   }
   ,
   {
      page: 1,
      name: "Text60", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 650.617,
      left: 52.3635,
      width: 521.4535,
      height: 37.203
   }
   ,
   {
      page: 1,
      name: "Text61", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 699.4893,
      left: 52.7999,
      width: 520.5811,
      height: 44.1715
   }
   ,
   {
      page: 2,
      name: "Date Acceptance Deadline", 
      type: "date",
      fontName: "Helvetica",
      fontSize: 10,
      top: 79.521,
      left: 52.6902,
      width: 78.2708,
      height: 11.159
   }
   ,
   {
      page: 2,
      name: "43", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 79.284,
      left: 136.276,
      width: 57.578,
      height: 11.676
   }
   ,
   {
      page: 2,
      name: "Buyers Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 149.16,
      left: 121.8,
      width: 182.52,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Buyers Name_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 149.16,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Buyers Signature", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 186.96,
      left: 50.4,
      width: 193.2,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Date_2", 
      fontSize: 9,
      type: "date",
      top: 186.96,
      left: 248.4,
      width: 55.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Buyers Signature_2", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 186.96,
      left: 324.84,
      width: 187.08,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Date_4", 
      fontSize: 9,
      type: "date",
      top: 186.96,
      left: 516.72,
      width: 60.72,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Address 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 222.48,
      left: 122.4,
      width: 181.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Address 1_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 222.48,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Address 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 234.96,
      left: 122.4,
      width: 181.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Address 2_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 234.96,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Phone No", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 247.56,
      left: 122.4,
      width: 181.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Phone No_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 247.56,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Fax No", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 260.04,
      left: 122.4,
      width: 181.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Fax No_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 260.04,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Email Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 272.52,
      left: 122.4,
      width: 181.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Email Address_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 272.52,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Sellers Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 307.56,
      left: 121.8,
      width: 182.52,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Sellers Name_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 307.56,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Sellers Signature", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 344.88,
      left: 50.4,
      width: 193.2,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Date_3", 
      fontSize: 9,
      type: "date",
      top: 344.88,
      left: 248.4,
      width: 55.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Sellers Signature_2", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 344.88,
      left: 324.84,
      width: 187.08,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Date_5", 
      fontSize: 9,
      type: "date",
      top: 344.88,
      left: 516.72,
      width: 60.72,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Address 1_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 380.4,
      left: 122.4,
      width: 181.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Address 1_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 380.4,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Address 2_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 392.88,
      left: 122.4,
      width: 181.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Address 2_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 392.88,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Phone No_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 405.48,
      left: 122.4,
      width: 181.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Phone No_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 405.48,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Fax No_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 417.96,
      left: 122.4,
      width: 181.92,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Fax No_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 417.96,
      left: 397.08,
      width: 180.36,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Email Address_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 430.44,
      left: 121.68,
      width: 182.64,
      height: 11.88
   }
   ,
   {
      page: 2,
      name: "Email Address_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 430.44,
      left: 396.36,
      width: 181.08,
      height: 11.88
   }
   ] }
   