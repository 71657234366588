export function manufacturedHomeCounterproposal2024() {
    return [   //2024 Release 2024-01-13 11:33:42
    {
        page: 0,
        name: "Logo",
        isText: false,
        fontSize: 10,
        type: "logo",
        top: 28.673,
        left: 52.9457,
        width: 240,
        height: 36,
      }
      ,
      {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 147.96,
       left: 398.88,
       width: 173.88,
       height: 14.28
    }
    ,
    {
       page: 0,
       name: "known as No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 244.855,
       left: 108.48,
       width: 419.40,
       height: 13.625
    }
    ,
    {
       page: 0,
       name: "Date contractDate", 
       fontSize: 9,
       type: "date",
       top: 184.417,
       left: 107.683,
       width: 58.734,
       height: 12.840
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsTime of Day Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 368.52,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateAlternative Earnest Money Deadline", 
       fontSize: 9,
       type: "date",
       top: 382.92,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsAlternative Earnest Money Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 382.92,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "SellersBoth", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 197.028,
       left: 51.6,
       width: 471.787,
       height: 12.426
    }
    ,
    {
       page: 0,
       name: "BuyersBoth", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 210.428,
       left: 51.4689,
       width: 471.7871,
       height: 12.426
    }
    ,
    {
       page: 0,
       name: "DateUCC and Certificate of Title Deadline", 
       fontSize: 9,
       type: "date",
       top: 411.48,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsUCC and Certificate of Title Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 411.48,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateUCC and Certificate of Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 425.88,
       left: 338.16,
       width: 88.68,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsUCC and Certificate of Title Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 425.88,
       left: 428.16,
       width: 151.56,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "DateUCC and Certificate of Title Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 440.04,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsUCC and Certificate of Title Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 440.04,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "contractDateAsText", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 185.421,
       left: 171.906,
       width: 164.302,
       height: 11.771
    }
    ,
    {
       page: 0,
       name: "DateLease Delivery Deadline", 
       fontSize: 9,
       type: "date",
       top: 468.6,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsLease Delivery Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 468.6,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateLot Owner Approval Deadline", 
       fontSize: 9,
       type: "date",
       top: 483,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsLot Owner Approval Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 483,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateLease Review Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 497.4,
       left: 338.16,
       width: 88.68,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsLease Review Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 497.4,
       left: 428.16,
       width: 151.56,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "DateSellers Property Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 525.96,
       left: 338.16,
       width: 88.68,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsSellers Property Disclosure Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 525.96,
       left: 428.16,
       width: 151.56,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "DateLeadBased Paint Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 540.12,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsLeadBased Paint Disclosure Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 540.12,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateNew Loan Application Deadline", 
       fontSize: 9,
       type: "date",
       top: 568.68,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsNew Loan Application Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 568.68,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateNew Loan Terms Deadline", 
       fontSize: 9,
       type: "date",
       top: 583.08,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsNew Loan Terms Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 583.08,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateNew Loan Availability Deadline", 
       fontSize: 9,
       type: "date",
       top: 597.48,
       left: 338.16,
       width: 88.68,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsNew Loan Availability Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 597.48,
       left: 428.16,
       width: 151.56,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "DateSeller or Private Financing Deadline", 
       fontSize: 9,
       type: "date",
       top: 611.64,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsSeller or Private Financing Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 611.64,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateAppraisal Deadline", 
       fontSize: 9,
       type: "date",
       top: 640.2,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsAppraisal Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 640.2,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateAppraisal Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 654.6,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsAppraisal Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 654.6,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateAppraisal Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 669,
       left: 338.16,
       width: 88.68,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsAppraisal Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 669,
       left: 428.16,
       width: 151.56,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "DateInspection Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 697.56,
       left: 338.16,
       width: 88.68,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsInspection Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 697.56,
       left: 428.16,
       width: 151.56,
       height: 13.08
    }
    ,
    {
       page: 0,
       name: "DateInspection Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 711.72,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsInspection Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 711.72,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "DateInspection Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 726.12,
       left: 338.16,
       width: 88.68,
       height: 13.32
    }
    ,
    {
       page: 0,
       name: "Deadline DetailsInspection Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 726.12,
       left: 428.16,
       width: 151.56,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "DateHome Insurance Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 79.8,
       left: 338.4,
       width: 87.72,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsHome Insurance Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 79.8,
       left: 428.4,
       width: 150.84,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "DateDue Diligence Documents Delivery Deadline", 
       fontSize: 9,
       type: "date",
       top: 94.68,
       left: 338.4,
       width: 87.72,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsDue Diligence Documents Delivery Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 94.68,
       left: 428.4,
       width: 150.84,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "DateDue Diligence Documents Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 108.84,
       left: 338.4,
       width: 87.72,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsDue Diligence Documents Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 108.84,
       left: 428.4,
       width: 150.84,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "DateDue Diligence Documents Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 123.24,
       left: 338.4,
       width: 87.72,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsDue Diligence Documents Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 123.24,
       left: 428.4,
       width: 150.84,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "DateConditional Sale Deadline", 
       fontSize: 9,
       type: "date",
       top: 137.4,
       left: 338.4,
       width: 87.72,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsConditional Sale Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 137.4,
       left: 428.4,
       width: 150.84,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "DateLeadBased Paint Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 151.8,
       left: 338.4,
       width: 87.72,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsLeadBased Paint Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 151.8,
       left: 428.4,
       width: 150.84,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "DateClosing Date", 
       fontSize: 9,
       type: "date",
       top: 180.84,
       left: 338.4,
       width: 87.72,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsClosing Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 180.84,
       left: 428.4,
       width: 150.84,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "DatePossession Date", 
       fontSize: 9,
       type: "date",
       top: 195.24,
       left: 338.4,
       width: 87.72,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsPossession Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 195.24,
       left: 428.4,
       width: 150.84,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsPossession Time", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 209.4,
       left: 428.4,
       width: 150.84,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "DateAcceptance Deadline Date", 
       fontSize: 9,
       type: "date",
       top: 223.8,
       left: 338.4,
       width: 87.72,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsAcceptance Deadline Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 223.8,
       left: 428.4,
       width: 150.84,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsAcceptance Deadline Time", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 238.2,
       left: 428.4,
       width: 150.84,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "31Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 252.24,
       left: 51.72,
       width: 32.52,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: " 24Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 252.24,
       left: 86.28,
       width: 29.64,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Acceptance Deadline TimeRow1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 252.24,
       left: 117.72,
       width: 218.52,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "DateRow13", 
       fontSize: 9,
       type: "date",
       top: 252.24,
       left: 338.28,
       width: 87.96,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsRow13", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 252.24,
       left: 428.28,
       width: 151.08,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "31Row2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 264.24,
       left: 51.72,
       width: 32.52,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: " 24Row2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 264.24,
       left: 86.28,
       width: 29.64,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Acceptance Deadline TimeRow2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 264.24,
       left: 117.72,
       width: 218.52,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "DateRow14", 
       fontSize: 9,
       type: "date",
       top: 264.24,
       left: 338.28,
       width: 87.96,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Deadline DetailsRow14", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 264.24,
       left: 428.28,
       width: 151.08,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Attachments", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 485.913,
       left: 51.5447,
       width: 516.1003,
       height: 70.604
    }
    ,
    {
       page: 1,
       name: "AdditionalDocsNotPart", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 568.725,
       left: 53.6832,
       width: 514.0388,
       height: 59.793
    }
    ,
    {
       page: 1,
       name: "Purchase Price", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 336.36,
       left: 350.106,
       width: 85.200,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "OtherChanges", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 641.379,
       left: 54.9922,
       width: 511.4208,
       height: 114.1209
    }
    ,
    {
       page: 1,
       name: "Earnest Money", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 350.76,
       left: 451.48,
       width: 81.698,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "New Loan", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 365.16,
       left: 451.48,
       width: 81.698,
       height: 12.425
    }
    ,
    {
       page: 1,
       name: "Private Financing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 379.32,
       left: 451.48,
       width: 81.698,
       height: 12.665
    }
    ,
    {
       page: 1,
       name: "Seller Financing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 393.72,
       left: 451.48,
       width: 81.698,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: " 466", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 407.88,
       left: 132.48,
       width: 53.64,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "Seller Financing6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 407.88,
       left: 187.44,
       width: 147.72,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 407.88,
       left: 336.48,
       width: 100.68,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "6_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 408.451,
       left: 442.145,
       width: 94.615,
       height: 11.749
    }
    ,
    {
       page: 1,
       name: " 467", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 422.28,
       left: 132.48,
       width: 53.64,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "Seller Financing7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 422.28,
       left: 187.44,
       width: 147.72,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 422.28,
       left: 336.48,
       width: 100.68,
       height: 13.08
    }
    ,
    {
       page: 1,
       name: "7_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 421.804,
       left: 441.098,
       width: 95.662,
       height: 12.556
    }
    ,
    {
       page: 1,
       name: "fill_62", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 436.44,
       left: 448.56,
       width: 88.20,
       height: 13.32
    }
    ,
    {
       page: 1,
       name: "fill_64", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 450.72,
       left: 346.68,
       width: 90.60,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "fill_65", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 450.72,
       left: 448.44,
       width: 88.44,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Date acceptCounterDate", 
       fontSize: 9,
       type: "date",
       top: 277.354,
       left: 49.0909,
       width: 90.0001,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "undefined", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 277.354,
       left: 146.651,
       width: 105.000,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Buyers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 348.034,
       left: 121.331,
       width: 182.280,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Buyers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 348.034,
       left: 396.131,
       width: 180.120,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 405.634,
       left: 121.331,
       width: 182.280,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Address_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 405.634,
       left: 396.131,
       width: 180.120,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Phone No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 445.714,
       left: 121.331,
       width: 182.280,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Phone No_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 445.714,
       left: 396.131,
       width: 180.120,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Fax No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 460.354,
       left: 121.331,
       width: 182.280,
       height: 12.600
    }
    ,
    {
       page: 2,
       name: "Fax No_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 460.354,
       left: 396.131,
       width: 180.120,
       height: 12.600
    }
    ,
    {
       page: 2,
       name: "Email Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 473.554,
       left: 121.331,
       width: 182.280,
       height: 12.600
    }
    ,
    {
       page: 2,
       name: "Email Address_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 473.554,
       left: 390.851,
       width: 185.400,
       height: 12.600
    }
    ,
    {
       page: 2,
       name: "Sellers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 516.754,
       left: 121.331,
       width: 182.280,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Sellers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 516.754,
       left: 396.131,
       width: 180.120,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Address_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 589.474,
       left: 121.331,
       width: 182.280,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "addressSeller2", 
       fontSize: 9,
       type: "text",
       top: 589.474,
       left: 396.131,
       width: 180.120,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Phone No_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 630.994,
       left: 121.331,
       width: 182.280,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Fax No_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 646.114,
       left: 121.331,
       width: 182.280,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Phone No 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 630.994,
       left: 396.131,
       width: 180.120,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Phone No 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 646.114,
       left: 396.131,
       width: 180.120,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Email Address_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 660.994,
       left: 120.611,
       width: 183.000,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Email Address_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 660.994,
       left: 396.131,
       width: 180.120,
       height: 14.040
    }
    ,
    {
       page: 2,
       name: "Text153", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 33.382,
       left: 47.1275,
       width: 526.3655,
       height: 220.328
    }
    ,
    {
       page: 2,
       name: "Text154", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 421.529,
       left: 120.437,
       width: 185.346,
       height: 14.800
    }
    ,
    {
       page: 2,
       name: "Text155", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 420.875,
       left: 394.693,
       width: 179.454,
       height: 14.800
    }
    ,
    {
       page: 2,
       name: "Text156", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 606.112,
       left: 120.437,
       width: 182.727,
       height: 10.872
    }
    ,
    {
       page: 2,
       name: "Text157", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 605.457,
       left: 394.693,
       width: 180.109,
       height: 11.527
    }
    ] }
