export function addendumLoanAssumption() {
return [   //2024 Release 2024-02-18 23:19:57
{
   page: 0,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 120.703,
   left: 36.0,
   width: 540.12,
   height: 11.617
}
,
{
   page: 0,
   name: "within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 206.731,
   left: 96.1696,
   width: 42.5574,
   height: 10.839
}
,
{
   page: 0,
   name: "credit report", 
   isText: false,
   type: "checkbox",
   top: 207.84,
   left: 385.68,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "verification of", 
   isText: false,
   type: "checkbox",
   top: 207.84,
   left: 474.6,
   width: 10.8,
   height: 8.40
}
,
{
   page: 0,
   name: "verification of funds on deposit in financial institutions", 
   isText: false,
   type: "checkbox",
   top: 218.76,
   left: 214.2,
   width: 10.8,
   height: 8.40
}
,
{
   page: 0,
   name: "current", 
   isText: false,
   type: "checkbox",
   top: 218.76,
   left: 510.36,
   width: 10.80,
   height: 8.40
}
,
{
   page: 0,
   name: "employment including salary", 
   isText: false,
   type: "checkbox",
   top: 229.8,
   left: 183.12,
   width: 10.68,
   height: 8.4
}
,
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 229.921,
   left: 197.057,
   width: 361.319,
   height: 9.973
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 240.46,
   left: 61.32,
   width: 497.04,
   height: 10.22
}
,
{
   page: 0,
   name: "1 The unpaid principal balance of a first lien promissory note payable to", 
   isText: false,
   type: "checkbox",
   top: 415.8,
   left: 61.32,
   width: 10.68,
   height: 8.4
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 415.08,
   left: 445.08,
   width: 115.92,
   height: 10.08
}
,
{
   page: 0,
   name: "The total current monthly payment including principal interest and any reserve deposits is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 426.24,
   left: 92.88,
   width: 171.00,
   height: 10.08
}
,
{
   page: 0,
   name: "which unpaid balance at closing will be", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 426.24,
   left: 472.56,
   width: 87.96,
   height: 10.08
}
,
{
   page: 0,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 448.32,
   left: 102.72,
   width: 88.08,
   height: 9.96
}
,
{
   page: 0,
   name: "2 The unpaid principal balance of a  second  lien  promissory  note  payable  to", 
   isText: false,
   type: "checkbox",
   top: 464.84,
   left: 61.32,
   width: 10.68,
   height: 8.40
}
,
{
   page: 0,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 465.0,
   left: 485.04,
   width: 75.96,
   height: 10.08
}
,
{
   page: 0,
   name: "The total current monthly payment including principal interest and any reserve deposits is_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 476.16,
   left: 92.88,
   width: 168.12,
   height: 10.08
}
,
{
   page: 0,
   name: "which unpaid balance at closing will be_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 476.16,
   left: 470.4,
   width: 90.24,
   height: 10.08
}
,
{
   page: 0,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 498.36,
   left: 102.72,
   width: 91.56,
   height: 9.96
}
,
{
   page: 0,
   name: "cash payable at closing", 
   isText: false,
   type: "checkbox",
   top: 526.8,
   left: 195.24,
   width: 10.80,
   height: 8.4
}
,
{
   page: 0,
   name: "Sales Price will be adjusted by the amount", 
   isText: false,
   type: "checkbox",
   top: 526.8,
   left: 332.88,
   width: 10.68,
   height: 8.4
}
,
{
   page: 0,
   name: "undefinedNo 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 547.174,
   left: 67.8568,
   width: 62.3492,
   height: 9.960
}
,
{
   page: 0,
   name: "1 payment of an assumption fee in excess of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 624.16,
   left: 313.872,
   width: 56.179,
   height: 10.08
}
,
{
   page: 0,
   name: "in C1 or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 624.16,
   left: 435.36,
   width: 71.04,
   height: 10.08
}
,
{
   page: 0,
   name: "2 an increase in the interest rate to more than", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 646.24,
   left: 311.16,
   width: 35.28,
   height: 10.08
}
,
{
   page: 0,
   name: "in C1 or_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 646.24,
   left: 408.881,
   width: 31.800,
   height: 10.08
}
,
{
   page: 1,
   name: "Address of Property_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 50.746,
   left: 38.88,
   width: 531.12,
   height: 13.214
}
] }
