export function shortSaleAddendumSellerListingContract() {
    return [   //2023
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 172.92,
       left: 398.64,
       width: 173.88,
       height: 10.68
    }
    ,
    {
       page: 0,
       name: "Seller Listing Contract dated", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 10,
       top: 207.12,
       left: 174.84,
       width: 114.84,
       height: 10.68
    }
    ,
    {
       page: 0,
       name: "known as", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 230.28,
       left: 90.96,
       width: 436.68,
       height: 10.68
    }
    ,
    {
       page: 1,
       name: "Brokers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 409.023,
       left: 131.52,
       width: 174.84,
       height: 14.817
    }
    ,
    {
       page: 1,
       name: "undefined", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 409.023,
       left: 401.52,
       width: 174.60,
       height: 14.817
    }
    // ,
    // {
    //    page: 1,
    //    name: "Sellers Signature", 
    //    type: "signature",
    //    fontName: "Helvetica",
    //    fontSize: 10,
    //    top: 445.271,
    //    left: 50.4,
    //    width: 193.32,
    //    height: 16.969
    // }
    // ,
    // {
    //    page: 1,
    //    name: "Date_2", 
    //    fontSize: 9,
    //    type: "date",
    //    top: 445.271,
    //    left: 248.4,
    //    width: 57.96,
    //    height: 16.969
    // }
    ,
    {
       page: 1,
       name: "Brokers Signature", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 445.271,
       left: 319.92,
       width: 189.24,
       height: 16.969
    }
    ,
    {
       page: 1,
       name: "Date_3", 
       fontSize: 9,
       type: "date",
       top: 445.271,
       left: 513.84,
       width: 62.28,
       height: 16.969
    }
    ,
    {
       page: 1,
       name: "Firms Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 495.067,
       left: 329.454,
       width: 246.66,
       height: 15.893
    }
    ,
{
   page: 1,
   name: "Sellers2Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 484.668,
   left: 131.729,
   width: 174.840,
   height: 14.817
}
    ] }
    