export function cOVID19Addendum2020() {
return [
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "1", 
   top: 175.8,
   left: 230.4,
   width: 216.465,
   height: 21.0
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "2", 
   top: 197.76,
   left: 230.4,
   width: 217.239,
   height: 21.00
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "3", 
   top: 219.72,
   left: 230.4,
   width: 217.239,
   height: 21.00
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "4", 
   top: 241.68,
   left: 230.4,
   width: 216.079,
   height: 21.00
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "5", 
   top: 263.64,
   left: 230.4,
   width: 214.918,
   height: 21.00
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "days", 
   top: 285.6,
   left: 230.4,
   width: 30.0,
   height: 21.0
}
,
{
   page: 0,
   type: "checkbox",
   name: "Clerk and Recorder etc or any thirdparty providing services or required information in", 
   top: 424.32,
   left: 87.12,
   width: 10.68,
   height: 10.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "days from the original Closing Date due to governmental entities andor", 
   top: 625.92,
   left: 158.28,
   width: 27.48,
   height: 13.56
}
,
{
   page: 1,
   type: "checkbox",
   name: "quarantine mandatory quarantine or shelter in place or equivalent order Quarantine or", 
   top: 60.24,
   left: 87.12,
   width: 10.68,
   height: 10.68
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Date", 
   top: 352.802,
   left: 230.4,
   width: 58.558,
   height: 31.438
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Date_2", 
   top: 412.802,
   left: 230.013,
   width: 58.558,
   height: 31.438
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Date_3", 
   top: 353.189,
   left: 482.4,
   width: 72.12,
   height: 31.051
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Date_4", 
   top: 413.962,
   left: 482.4,
   width: 72.12,
   height: 30.278
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Signature14_es_:signer:signature", 
   top: 352.814,
   left: 49.9046,
   width: 180.2754,
   height: 31.722
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Signature15_es_:signer:signature", 
   top: 352.814,
   left: 301.362,
   width: 180.276,
   height: 32.109
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Signature16_es_:signer:signature", 
   top: 412.777,
   left: 51.0652,
   width: 178.7278,
   height: 30.562
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "textarea",
   name: "Signature17_es_:signer:signature", 
   top: 413.164,
   left: 301.362,
   width: 180.663,
   height: 30.175
}
] }
