import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import MyEmailInput from "../../../app/common/form/MyEmailInput";
import { functionPartyLogin } from "../../../app/firestore/functionsService";
import { openModal } from "../../../app/common/modals/modalSlice";

export default function PartyLoginEmailForm() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email") || "";

  return (
    <>
      <Formik
        initialValues={{
          email: email,
        }}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const result = await functionPartyLogin(values.email);
            if (result.isUser) {
              dispatch(
                openModal({
                  modalType: "PartyLoginCurrentUserForm",
                  modalProps: { email: values.email },
                })
              );
            } else {
              dispatch(
                openModal({
                  modalType: "PartyLoginNewUserForm",
                  modalProps: { email: values.email },
                })
              );
            }
          } catch (error) {
            toast.error(error.message);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MyEmailInput
              name="email"
              placeholder="Email address"
              className="tiny bottom margin"
            />
            <Button
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="blue"
              content="Get Started"
              className="small bottom margin"
            />
          </Form>
        )}
      </Formik>
    </>
  );
}
