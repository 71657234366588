import React from "react";
import { Table, Button, Image } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { addHistoryToDb } from "../../../app/firestore/firestoreService";

export default function PartyAllListItem({ party }) {
  const navigate = useNavigate();

  function handleViewDocuments() {
    addHistoryToDb(party.transactionId, party, "logged in to transaction");
    navigate(`/party/${party.id}/documents`);
  }

  return (
    <Table.Row key={party.id}>
      <Table.Cell style={{ padding: "0px" }}>
        {party.transactionPic ? (
          <Image style={{ width: "90px" }} src={party.transactionPic} />
        ) : (
          <Image
            src="/assets/placeholder-house.png"
            style={{ width: "90px" }}
            rounded
          />
        )}
      </Table.Cell>
      <Table.Cell>{party.address?.street}</Table.Cell>
      <Table.Cell>
        {party.agentFirstName || party.agentProfile?.firstName}
      </Table.Cell>
      <Table.Cell>
        {party.agentLastName || party.agentProfile?.lastName}
      </Table.Cell>
      <Table.Cell>
        <Button
          primary
          floated="right"
          onClick={() => handleViewDocuments()}
          content="View Documents"
        />
      </Table.Cell>
    </Table.Row>
  );
}
