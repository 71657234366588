import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { abbreviateDirection } from "../../app/common/util/util";

const initialState = {
  historyAll: { history: [], column: "", direction: "" },
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    fetchHistory(state, action) {
      if (state.historyAll.column && state.historyAll.direction) {
        state.historyAll.history = _.orderBy(
          action.payload,
          state.historyAll.column,
          abbreviateDirection(state.historyAll.direction)
        );
      } else {
        state.historyAll.history = action.payload;
      }
    },
    sortHistory(state, action) {
      if (state.historyAll.column === action.payload) {
        state.historyAll = {
          history: state.historyAll.history.slice().reverse(),
          column: action.payload,
          direction:
            state.historyAll.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.historyAll = {
          history: _.sortBy(state.historyAll.history, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
  },
});

export const { fetchHistory, sortHistory } = historySlice.actions;

export default historySlice.reducer;
