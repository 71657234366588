import React from "react";
import { Table } from "semantic-ui-react";
import EmailTemplatesListItem from "./EmailTemplatesListItem";

export default function EmailTemplatesList({
  emailTemplates,
  column,
  direction,
  actionButtonMode,
}) {
  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell sorted={column === "title" ? direction : null}>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "updatedAt" ? direction : null}
            >
              Last Updated
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {emailTemplates &&
            emailTemplates.length !== 0 &&
            emailTemplates.map((emailTemplate) => (
              <EmailTemplatesListItem
                emailTemplate={emailTemplate}
                actionButtonMode={actionButtonMode}
                key={emailTemplate.id}
              />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
