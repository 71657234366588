import React from "react";
import { Grid } from "semantic-ui-react";
import MyTextInput from "../form/MyTextInput";

export default function FormAddress() {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} computer={13}>
          <MyTextInput
            name="address.street"
            label="Street address"
            data-test="address-street"
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3}>
          <MyTextInput
            name="address.unit"
            label="Unit #"
            data-test="address-unit"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="zero top padding">
        <Grid.Column mobile={16} computer={10}>
          <MyTextInput
            name="address.city"
            label="City"
            data-test="address-city"
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3}>
          <MyTextInput
            name="address.state"
            label="State"
            data-test="address-state"
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3}>
          <MyTextInput
            name="address.zipcode"
            label="Zipcode"
            data-test="address-zipcode"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
