import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { changePageScalePrepare } from "../../../../app/annots/annotSlice";
import { downloadPdf } from "../../../../app/common/util/util";

export default function DocViewActionButtons() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { doc } = useSelector((state) => state.doc);
  const { transaction } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { pageScalePrepare } = useSelector((state) => state.annot);

  function closeDocument() {
    navigate(`/transactions/${transaction.id}/documents`);
  }

  async function handleDownload() {
    downloadPdf(doc, transaction, currentUserProfile);
  }

  function handleZoomIn() {
    if (pageScalePrepare < 4) {
      dispatch(changePageScalePrepare(pageScalePrepare * 1.25));
    }
  }

  function handleZoomOut() {
    if (pageScalePrepare > 0.5) {
      dispatch(changePageScalePrepare(pageScalePrepare / 1.25));
    }
  }

  return (
    <>
      <div
        className="large horizontal padding background-white"
        style={{
          top: "auto",
          bottom: "0",
          position: "fixed",
          width: "100%",
          height: "80px",
          borderTop: "1px solid #e8e8e8",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            float: "left",
          }}
        >
          <Button
            onClick={() => handleDownload()}
            style={{ marginRight: "24px" }}
            content="Download"
          />
          <Icon
            size="large"
            color="grey"
            name="minus circle"
            onClick={() => handleZoomOut()}
            style={{ cursor: "pointer" }}
          />
          <p
            className="zero bottom margin"
            style={{
              marginLeft: "3px",
              marginRight: "6px",
              fontWeight: "bold",
            }}
          >
            {Math.round(pageScalePrepare * 100)}%
          </p>
          <Icon
            size="large"
            color="grey"
            name="plus circle"
            onClick={() => handleZoomIn()}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div
          className="large right margin"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            float: "right",
          }}
        >
          <Button
            primary
            onClick={() => closeDocument()}
            content="Close"
            style={{ marginRight: "10px" }}
          />
        </div>
      </div>
    </>
  );
}
