import React from "react";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Icon, Input, Select } from "semantic-ui-react";
import {
  addAnnot,
  clearSelectedAnnot,
  deleteAnnot,
  selectDocAnnot,
  updateAnnot,
} from "../../../../../app/annots/annotSlice";
import { annotTextSizeOptions } from "./annotUtils";
import _ from "lodash";

export default function AnnotEditButtons() {
  const dispatch = useDispatch();
  const { selectedAnnot } = useSelector((state) => state.annot);

  function handleTextChange(event) {
    dispatch(
      updateAnnot({
        annot: selectedAnnot,
        newFields: {
          text: event.target.value,
        },
      })
    );
  }

  function handleFontSizeChange(event) {
    dispatch(
      updateAnnot({
        annot: selectedAnnot,
        newFields: {
          fontSize: event.target.textContent,
        },
      })
    );
  }

  function handleCopy() {
    let annot = _.cloneDeep(selectedAnnot);
    annot.uniqueId = "u-" + uuid();
    annot.x = annot.x + 30;
    annot.y = annot.y + 30;
    dispatch(addAnnot(annot));
    dispatch(selectDocAnnot(annot));
  }

  function handleDelete() {
    dispatch(deleteAnnot(selectedAnnot));
    dispatch(clearSelectedAnnot());
  }

  if (!selectedAnnot.uniqueId) {
    return <></>;
  }

  return (
    <div id="prepare-page">
      <p
        style={{
          color: "#9a9a9a",
          marginBottom: "3px",
          marginTop: "30px",
        }}
      >
        Edit Selected Field
      </p>
      <Divider className="zero top margin" />
      <div className="small top margin">
        {selectedAnnot.agentsField === true &&
          selectedAnnot.type !== "date" &&
          selectedAnnot.type !== "strikethrough" &&
          selectedAnnot.type !== "checkbox" && (
            <>
              <Input
                className="tiny bottom margin"
                fluid
                placeholder="Add text..."
                onChange={(event) => handleTextChange(event)}
                value={selectedAnnot.text}
              />
            </>
          )}
        {selectedAnnot.type !== "strikethrough" && (
          <div
            className="small top margin"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <span className="tiny right margin">Font Size</span>
            <Select
              compact
              size="small"
              value={selectedAnnot.fontSize}
              onChange={(event) => handleFontSizeChange(event)}
              options={annotTextSizeOptions}
              style={{ padding: ".3em 2.1em .3em 1em", minHeight: ".6em" }}
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          marginTop: "18px",
        }}
      >
        <div onClick={() => handleCopy()} className="cursor-pointer">
          <Icon name="copy" style={{ marginRight: "8px" }} />
          <span>Copy</span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          marginTop: "18px",
        }}
      >
        <div onClick={() => handleDelete()} className="cursor-pointer text red">
          <Icon name="trash" style={{ marginRight: "8px" }} />
          <span>Delete</span>
        </div>
      </div>
    </div>
  );
}
