import React from "react";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/common/modals/modalSlice";
import { useMediaQuery } from "react-responsive";
import FormTemplatesList from "./formTemplatesComponents/FormTemplatesList";

export default function FormTemplatesFillDashboard() {
  const dispatch = useDispatch();
  const { formTemplates } = useSelector((state) => state.formTemplates);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <div className="main-page-wrapper">
      <Grid centered stackable className="small bottom margin">
        <Grid.Column width={10}>
          <Header color="blue" as="h2">
            Form Templates
          </Header>
          <p color="grey">
            Create partially filled in forms and save as a template.
            <br />
            These templates appear as options when you add a form to a
            transaction.
          </p>
          <Segment clearing>
            <Grid.Column width={10}>
              <div className={isMobile ? null : "float-right"}>
                <Button
                  to="#"
                  icon="plus"
                  size="small"
                  className={isMobile ? "fluid" : null}
                  onClick={() =>
                    dispatch(
                      openModal({
                        modalType: "FormTemplatesSelectFormsModal",
                      })
                    )
                  }
                  content="Add Template"
                  color="blue"
                />
              </div>
            </Grid.Column>
            <Divider hidden />
            {formTemplates?.length > 0 ? (
              <FormTemplatesList
                formTemplates={formTemplates}
                column={formTemplates.column}
                direction={formTemplates.direction}
              />
            ) : (
              <p>There are no form templates.</p>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}
