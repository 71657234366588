import React from "react";

export function FormFieldTextareaDisplay({ formField, pageScale }) {
  return (
    <div
      className="pdfFormFieldDisplay"
      style={{
        top: `${formField.top * pageScale}px`,
        left: `${formField.left * pageScale}px`,
        height: `${formField.height * pageScale}px`,
        width: `${formField.width * pageScale}px`,
        fontSize: `${formField.fontSize * pageScale}px`,
      }}
    >
      <textarea
        readOnly
        className="textFieldDisplay"
        value={formField.value}
        style={{
          fontFamily: "helvetica",
          fontSize: `${formField.fontSize * pageScale}px`,
          margin: "0px",
          textAlign: "left",
          pointerEvents: "none",
          border: "none",
          color: "#0037b8",
          paddingTop: "5px",
          lineHeight: "1.1em",
        }}
      ></textarea>
    </div>
  );
}
