export function changeofStatus() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 240,
      height: 36,
    }
    ,
   {
      page: 0,
      name: "Regarding", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 261.84,
      left: 104.88,
      width: 448.20,
      height: 11.40
   }
   ,
   {
      page: 0,
      name: "Seller", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 297.84,
      left: 102.96,
      width: 450.12,
      height: 11.40
   }
   ,
   {
      page: 0,
      name: "Buyer", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 321.84,
      left: 102.96,
      width: 450.12,
      height: 11.40
   }
   ,
   {
      page: 0,
      name: "Right to sell", 
      isText: false,
      type: "checkbox",
      top: 370.933,
      left: 57.2716,
      width: 9.9892,
      height: 9.990
   }
   ,
   {
      page: 0,
      name: "Exclusive Right-to-Sell Listing Contract, dated", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 369.84,
      left: 260.4,
      width: 157.56,
      height: 11.40
   }
   ,
   {
      page: 0,
      name: "Right to lease", 
      isText: false,
      type: "checkbox",
      top: 383.707,
      left: 57.2716,
      width: 9.9892,
      height: 9.989
   }
   ,
   {
      page: 0,
      name: "Exclusive Right-to-Lease Listing Contract, dated", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 381.84,
      left: 268.08,
      width: 149.88,
      height: 11.40
   }
   ,
   {
      page: 0,
      name: "Right to buy", 
      isText: false,
      type: "checkbox",
      top: 395.225,
      left: 57.2716,
      width: 9.9892,
      height: 9.989
   }
   ,
   {
      page: 0,
      name: "Exclusive Right-to Buy-Contract, dated", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 394.2,
      left: 230.64,
      width: 187.32,
      height: 11.04
   }
   ,
   {
      page: 0,
      name: "Exclusive", 
      isText: false,
      type: "checkbox",
      top: 406.643,
      left: 57.2716,
      width: 9.9892,
      height: 9.990
   }
   ,
   {
      page: 0,
      name: "Exclusive Tenant Contract, dated", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 406.2,
      left: 204.96,
      width: 213.00,
      height: 11.04
   }
   ,
   {
      page: 0,
      name: "Other 1", 
      isText: false,
      type: "checkbox",
      top: 418.716,
      left: 57.2716,
      width: 9.9892,
      height: 9.989
   }
   ,
   {
      page: 0,
      name: "Other contract titled", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 418.855,
      left: 155.04,
      width: 284.684,
      height: 10.385
   }
   ,
   {
      page: 0,
      name: "otherContractdated", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 417.84,
      left: 469.0,
      width: 87.96,
      height: 11.40
   }
   ,
   {
      page: 0,
      name: "Receipt of this Disclosure form is hereby acknowledged on", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 525.84,
      left: 297.36,
      width: 120.60,
      height: 11.40
   }
   ,
   {
      page: 0,
      name: "Seller1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 558.131,
      left: 87.2947,
      width: 204.6653,
      height: 12.054
   }
   ,
   {
      page: 0,
      name: "BuyerName1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 558.33,
      left: 347.565,
      width: 150.000,
      height: 10.872
   }
   ,
   {
      page: 0,
      name: "SellerName2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 587.13,
      left: 85.7458,
      width: 205.6362,
      height: 11.527
   }
   ,
   {
      page: 0,
      name: "Buyer_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 586.276,
      left: 347.106,
      width: 205.974,
      height: 12.055
   }
   ,
   {
      page: 0,
      name: "date dateProvided", 
      fontSize: 9,
      type: "date",
      top: 609.84,
      left: 72.48,
      width: 120.60,
      height: 11.40
   }
   ,
   {
      page: 0,
      name: "Seller 3", 
      isText: false,
      type: "checkbox",
      top: 610.79,
      left: 297.588,
      width: 9.989,
      height: 9.989
   }
   ,
   {
      page: 0,
      name: "Buyer 3", 
      isText: false,
      type: "checkbox",
      top: 610.79,
      left: 339.116,
      width: 9.989,
      height: 9.989
   }
   ,
   {
      page: 0,
      name: "Brokerage Firms Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 645.84,
      left: 160.32,
      width: 392.76,
      height: 11.40
   }
   ,
   {
      page: 0,
      name: "signature Broker Signature", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 666.985,
      left: 59.3542,
      width: 155.2458,
      height: 14.800
   }
   ,
   {
      page: 0,
      name: "date brokerDateSigned", 
      fontSize: 9,
      type: "date",
      top: 666.985,
      left: 219.274,
      width: 76.690,
      height: 14.800
   }
   ,
   {
      page: 0,
      name: "brokerName", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 682.039,
      left: 90.3277,
      width: 150.0003,
      height: 12.8367
   }
   ,
   {
      page: 0,
      name: "signature cobrokerSignature", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 704.2939,
      left: 59.3542,
      width: 155.2458,
      height: 14.1455
   }
   ,
   {
      page: 0,
      name: "date cobrokerDateSigned", 
      fontSize: 9,
      type: "date",
      top: 704.2939,
      left: 219.274,
      width: 76.690,
      height: 14.1455
   }
   ,
   {
      page: 0,
      name: "cobrokerFullName", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 722,
      left: 104.437,
      width: 150.000,
      height: 12.8363
   }
   ] }
   