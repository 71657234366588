export function earnestMoneyForm() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 204", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 91.12,
   left: 97.92,
   width: 478.80,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 205", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 107.5,
   left: 97.92,
   width: 478.80,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 206", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 123.88,
   left: 147.6,
   width: 429.12,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 207", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 140.26,
   left: 31.5,
   width: 545.22,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 208", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 177.02,
   left: 186.48,
   width: 113.76,
   height: 13.50
}
,
{
   page: 0,
   name: "Check Box 62", 
   isText: false,
   type: "checkbox",
   top: 181.09,
   left: 303.21,
   width: 7.20,
   height: 7.20
}
,
{
   page: 0,
   name: "Check Box 61", 
   isText: false,
   type: "checkbox",
   top: 181.09,
   left: 342.795,
   width: 7.200,
   height: 7.20
}
,
{
   page: 0,
   name: "Text Field 2025", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 190.52,
   left: 48.24,
   width: 528.48,
   height: 11.27
}
,
{
   page: 0,
   name: "Check Box 64", 
   isText: false,
   type: "checkbox",
   top: 238.65,
   left: 46.08,
   width: 7.20,
   height: 7.20
}
,
{
   page: 0,
   name: "Check Box 65", 
   isText: false,
   type: "checkbox",
   top: 255.77,
   left: 46.08,
   width: 7.20,
   height: 7.20
}
,
{
   page: 0,
   name: "Check Box 66", 
   isText: false,
   type: "checkbox",
   top: 271.89,
   left: 46.08,
   width: 7.20,
   height: 7.20
}
,
{
   page: 0,
   name: "Check Box 73", 
   isText: false,
   type: "checkbox",
   top: 288.01,
   left: 46.08,
   width: 7.20,
   height: 7.20
}
,
{
   page: 0,
   name: "Text Field 209", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 283.59,
   left: 90.72,
   width: 488.16,
   height: 13.50
}
,
{
   page: 0,
   name: "Text1", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 12,
   top: 311.563,
   left: 31.4181,
   width: 289.8099,
   height: 14.145
}
,
{
   page: 0,
   name: "Text Field 2010", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 312.515,
   left: 319.68,
   width: 67.68,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 2012", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 352.908,
   left: 31.5,
   width: 288.18,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 2011", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 352.908,
   left: 319.68,
   width: 67.68,
   height: 13.500
}
,
{
   page: 0,
   name: "Check Box 70", 
   isText: false,
   type: "checkbox",
   top: 420.05,
   left: 46.08,
   width: 7.20,
   height: 7.20
}
,
{
   page: 0,
   name: "Check Box 71", 
   isText: false,
   type: "checkbox",
   top: 436.365,
   left: 46.08,
   width: 7.20,
   height: 7.200
}
,
{
   page: 0,
   name: "Check Box 72", 
   isText: false,
   type: "checkbox",
   top: 451.96,
   left: 46.08,
   width: 7.20,
   height: 7.20
}
,
{
   page: 0,
   name: "Text Field 2013", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 447.97,
   left: 90.72,
   width: 488.16,
   height: 13.50
}
,
{
   page: 0,
   name: "Text12", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 12,
   top: 479.907,
   left: 31.036,
   width: 289.810,
   height: 14.146
}
,
{
   page: 0,
   name: "Text Field 2014", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 481.505,
   left: 319.68,
   width: 67.68,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 2016", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 520.92,
   left: 31.5,
   width: 288.18,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 2015", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 520.92,
   left: 319.68,
   width: 67.68,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 2018", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 574.5,
   left: 325.6,
   width: 82.64,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 2026", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 574.5,
   left: 427.24,
   width: 149.48,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 2017", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 586.54,
   left: 43.2,
   width: 43.92,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 2019", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 619.08,
   left: 159.12,
   width: 293.76,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 2020", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 643.84,
   left: 159.12,
   width: 293.76,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 2022", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 684.1,
   left: 198.72,
   width: 233.28,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 2023", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 699.98,
   left: 215.28,
   width: 216.72,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 2024", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 716.86,
   left: 254.88,
   width: 177.12,
   height: 13.50
}
] }
