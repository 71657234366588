import { formatDistance } from "date-fns";
import React from "react";
import { Segment } from "semantic-ui-react";

export default function EmailsListItem({ email }) {
  return (
    <Segment>
      <p className="zero bottom margin text-medium">
        <span className="text blue bold tiny right margin">Subject:</span>{" "}
        {email.subject}
      </p>
      <p className="text muted">
        {email.updatedAt && email.updatedAt instanceof Date
          ? formatDistance(email.updatedAt, new Date(), {
              addSuffix: true,
            })
          : ""}
      </p>
      <p className="zero bottom margin">
        <span className="text blue bold tiny right margin">To:</span>{" "}
        <span>
          {email.toEmailsAndRoles.map((toEmailAndRole, index) => (
            <span key={`to${index}`}>
              {index !== 0 && <span>,&nbsp;</span>}
              {toEmailAndRole.role}: {toEmailAndRole.firstName}{" "}
              {toEmailAndRole.lastName} ({toEmailAndRole.email})
            </span>
          ))}
        </span>
        {email.otherEmailsTo && <span>,&nbsp;{email.otherEmailsTo}</span>}
      </p>
      <p className="zero bottom margin">
        <span className="text blue bold tiny right margin">cc:</span>{" "}
        <span>
          {email.ccEmailsAndRoles.map((ccEmailAndRole, index) => (
            <span key={`cc${index}`}>
              {index !== 0 && <span>,&nbsp;</span>}
              {ccEmailAndRole.role}: {ccEmailAndRole.firstName}{" "}
              {ccEmailAndRole.lastName} ({ccEmailAndRole.email})
            </span>
          ))}
        </span>
        {email.otherEmailsCc && <span>,&nbsp;{email.otherEmailsCc}</span>}
      </p>
      <p>
        <span className="text blue bold tiny right margin">bcc:</span>{" "}
        <span>
          {email.bccEmailsAndRoles.map((bccEmailAndRole, index) => (
            <span key={`bcc${index}`}>
              {index !== 0 && <span>,&nbsp;</span>}
              {bccEmailAndRole.role}: {bccEmailAndRole.firstName}{" "}
              {bccEmailAndRole.lastName} ({bccEmailAndRole.email})
            </span>
          ))}
        </span>
        {email.otherEmailsBcc && <span>,&nbsp;{email.otherEmailsBcc}</span>}
      </p>
      <p style={{ whiteSpace: "pre-line" }}>
        <span className="text blue bold tiny right margin">
          Message:
          <br />
        </span>
        {email.message}
      </p>
    </Segment>
  );
}
