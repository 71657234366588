import React from "react";
import { Button, Grid, Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { searchFilter } from "../../app/common/util/util";
import { openModal } from "../../app/common/modals/modalSlice";
import PeopleList from "./peopleComponents/PeopleList";

export default function PeopleDashboard() {
  const dispatch = useDispatch();
  const { people, column, direction } = useSelector((state) => state.people);
  const [searchTerms, setSearchTerms] = useState("");
  const peopleDisplay = searchFilter(people, searchTerms);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <div className="main-page-wrapper">
      <Grid stackable>
        <Grid.Column computer={8}>
          <Input
            type="text"
            fluid
            placeholder="Search by name or role"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          ></Input>
        </Grid.Column>
        <Grid.Column computer={8}>
          <Button
            color="blue"
            to="#"
            icon="plus"
            floated={isMobile ? "left" : "right"}
            size="small"
            className={isMobile ? "fluid" : null}
            onClick={() =>
              dispatch(
                openModal({
                  modalType: "PersonForm",
                })
              )
            }
            content="Add Person"
          />
        </Grid.Column>
        <Grid.Column computer={16}>
          <h3>People</h3>
          {peopleDisplay?.length > 0 ? (
            <PeopleList
              people={peopleDisplay}
              column={column}
              direction={direction}
            />
          ) : (
            <p>There are no saved people in your account</p>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
}
