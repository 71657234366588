import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useFirestoreCollection from "../hooks/useFirestoreCollection";
import { fetchAgentsForManager } from "../../features/profiles/profileSlice";
import {
  fetchAgentsForManagerFromDb,
  fetchTransActiveForManagerFromDb,
  fetchTransClosedForManagerFromDb,
} from "./firestoreService";
import {
  fetchTransActiveForManager,
  fetchTransClosedForManager,
} from "../../features/transactions/transactionSlice";

export default function ManagerData() {
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);

  useFirestoreCollection({
    query: () => fetchAgentsForManagerFromDb(),
    data: (agents) =>
      dispatch(
        fetchAgentsForManager({
          agents: agents,
          managerId: currentUserProfile.userId,
        })
      ),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchTransActiveForManagerFromDb(),
    data: (transactions) => dispatch(fetchTransActiveForManager(transactions)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchTransClosedForManagerFromDb(),
    data: (transactions) => dispatch(fetchTransClosedForManager(transactions)),
    deps: [dispatch],
  });

  return <></>;
}
