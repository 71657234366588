import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Table, Message } from "semantic-ui-react";
import PartyAllListItem from "./PartyAllListItem";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import _ from "lodash";

export default function PartyAllOverview() {
  const { partiesForUser, transactionsForUser } = useSelector(
    (state) => state.party
  );
  const [loaded, setLoaded] = useState(false);
  const [partiesForDisplay, setPartiesForDisplay] = useState([]);
  const parties = partiesForUser?.parties;
  // const parties = searchFilter(partiesForUser?.parties, searchTerms);

  useEffect(() => {
    if (transactionsForUser?.length > 0 && parties?.length > 0) {
      let partiesWithTransaction = [];
      parties.forEach((party, index) => {
        const partyCopy = _.cloneDeep(party);
        const transaction = transactionsForUser.filter(
          (trans) => trans.id === party.transactionId
        )[0];
        if (transaction) {
          // Wait until after transaction to add party or extra parties appear
          partiesWithTransaction[index] = partyCopy;
          const transactionCopy = _.cloneDeep(transaction);
          partiesWithTransaction[index]["address"] =
            transactionCopy.address || {};
          partiesWithTransaction[index]["agentFirstName"] =
            transactionCopy.agentFirstName ||
            transactionCopy.agentProfile?.firstName ||
            "";
          partiesWithTransaction[index]["agentLastName"] =
            transactionCopy.agentLastName ||
            transactionCopy.agentProfile?.lastName ||
            "";
          partiesWithTransaction[index]["agentRepresents"] =
            transactionCopy.agentRepresents || "";
          partiesWithTransaction[index]["client"] =
            transactionCopy.client || {};
          partiesWithTransaction[index]["clientSecondary"] =
            transactionCopy.clientSecondary || {};
          partiesWithTransaction[index]["mlsNumbers"] =
            transactionCopy.mlsNumbers || [];
          partiesWithTransaction[index]["title"] = transactionCopy.title || "";
          partiesWithTransaction[index]["transactionPic"] =
            transactionCopy.pic || "";
        }
      });
      setPartiesForDisplay(partiesWithTransaction);
    }
    setLoaded(true);
  }, [parties, transactionsForUser]);

  if (!loaded) return <LoadingComponent content="Loading details..." />;

  // function handleSort(column) {
  //   dispatch(sortPartiesForUser(column));
  // }
  // const column = partiesForUser?.column;
  // const direction = partiesForUser?.direction;

  return (
    <div className="main-page-wrapper">
      <>
        <Grid stackable className="small bottom margin">
          <Grid.Column computer={16} className="small bottom margin">
            <h3
              className="zero bottom margin"
              style={{ position: "absolute", bottom: "0" }}
            >
              Transaction List
            </h3>
          </Grid.Column>
          {/* <Grid.Column computer={8}>
            <Input
              type="text"
              fluid
              placeholder="Search by name"
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
            ></Input>
          </Grid.Column>
          <Grid.Column computer={3} tablet={4}>
          </Grid.Column> */}
          <Grid.Column computer={16} className="small bottom margin">
            {partiesForDisplay && partiesForDisplay.length !== 0 ? (
              <Table compact sortable className="mini top margin">
                <Table.Header className="mobile hidden">
                  <Table.Row className="small-header">
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell
                    // sorted={column === "address" ? direction : null}
                    // onClick={() => handleSort("Address")}
                    >
                      Address
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    // sorted={column === "agentFirstName" ? direction : null}
                    // onClick={() => handleSort("agentFirstName")}
                    >
                      Agent First Name
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    // sorted={column === "agentLastName" ? direction : null}
                    // onClick={() => handleSort("agentLastName")}
                    >
                      Agent Last Name
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {partiesForDisplay &&
                    partiesForDisplay.length !== 0 &&
                    partiesForDisplay.map((party) => (
                      <PartyAllListItem party={party} key={party.id} />
                    ))}
                </Table.Body>
              </Table>
            ) : (
              <Message size="large">
                If you do not see a transaction below, make sure you logged in
                using the same email where you received the notification that
                documents were shared with you.
                <br />
                Also, be sure you are not using a VPN to access this site.
                <br />
                You can access your account from your phone as an alternative
                way to log in and sign/view documents.
                <br />
                If you are still having problems, then reach out to the agent
                who sent you a notification.
              </Message>
            )}
          </Grid.Column>
        </Grid>
      </>
    </div>
  );
}
