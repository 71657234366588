export function onetoFourFamilyResidentialContract() {
  return [
    //2024 Release 2024-02-19 17:32:14
    {
      page: 0,
      name: "Seller Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 94.251,
      left: 279.021,
      width: 277.241,
      height: 10.025,
    },
    {
      page: 0,
      name: "Buyer Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 103.753,
      left: 122.544,
      width: 391.05,
      height: 10.025,
    },
    {
      page: 0,
      name: "Lot",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 166.435,
      left: 126.933,
      width: 65.965,
      height: 10.166,
    },
    {
      page: 0,
      name: "Block",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 167.498,
      left: 221.761,
      width: 83.439,
      height: 10.103,
    },
    {
      page: 0,
      name: "Addition",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 166.843,
      left: 309.326,
      width: 247.138,
      height: 10.103,
    },
    {
      page: 0,
      name: "City Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 177.395,
      left: 157.436,
      width: 170.764,
      height: 10.103,
    },
    {
      page: 0,
      name: "County Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 178.801,
      left: 387.221,
      width: 169.397,
      height: 9.48,
    },
    {
      page: 0,
      name: "Address ZIP code",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 189.417,
      left: 161.059,
      width: 394.75,
      height: 9.48,
    },
    {
      page: 0,
      name: "2D Improvements and accessories to be retained by Seller and removed prior to delivery of posssion line 1",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 12,
      top: 398.186,
      left: 321.822,
      width: 232.91,
      height: 22.0,
    },
    {
      page: 0,
      name: "Improvements and accessories to be retained by Seller and removed prior to delivery of posession",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 411.792,
      left: 72.8426,
      width: 481.2284,
      height: 12.753,
    },
    {
      page: 0,
      name: "Amount 3(A)",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 465.68,
      left: 457.08,
      width: 100.757,
      height: 9.48,
    },
    {
      page: 0,
      name: "Third Party Financing Addendum_2",
      subType: "addDoc",
      docToAdd: "Addendum Third Party Financing",
      isText: false,
      type: "checkbox",
      top: 499.56,
      left: 312.84,
      width: 11.64,
      height: 9.12,
    },
    {
      page: 0,
      name: "Loan Assumption Addendum,",
      subType: "addDoc",
      docToAdd: "Loan Assumption Addendum",
      isText: false,
      type: "checkbox",
      top: 513.022,
      left: 75.66,
      width: 8.9957,
      height: 9.842,
    },
    {
      page: 0,
      name: "Seller Financing Addendum",
      subType: "addDoc",
      docToAdd: "Seller Financing Addendum",
      isText: false,
      type: "checkbox",
      top: 513.449,
      left: 249.316,
      width: 10.68,
      height: 9.789,
    },
    {
      page: 0,
      name: "Amount 3B",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 515.436,
      left: 457.08,
      width: 100.757,
      height: 9.48,
    },
    {
      page: 0,
      name: "Amount 3C",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 527.12,
      left: 457.08,
      width: 101.381,
      height: 9.48,
    },
    {
      page: 0,
      name: "4A Addendum Regarding Residential Leases is attaached to this contract",
      subType: "addDoc",
      docToAdd: "Addendum Regarding Residential Leases",
      isText: false,
      type: "checkbox",
      top: 587.768,
      left: 48.0423,
      width: 11.52,
      height: 8.318,
    },
    {
      page: 0,
      name: "4B Addendum Regarding Fixture Leases",
      subType: "addDoc",
      docToAdd: "Addendum Regarding Fixture Leases",
      isText: false,
      type: "checkbox",
      top: 612.693,
      left: 47.5601,
      width: 11.0022,
      height: 9.353,
    },
    {
      page: 0,
      name: "4C Natural Resource Leases",
      isText: false,
      type: "checkbox",
      top: 647.822,
      left: 47.899,
      width: 11.198,
      height: 9.789,
    },
    {
      page: 0,
      name: "4C(1) Seller has delivered to Buyer a copy of the Natural Resource Leases",
      isText: false,
      type: "checkbox",
      top: 684.34,
      left: 60.657,
      width: 10.68,
      height: 9.271,
    },
    {
      page: 0,
      name: "4C(2) Seller has not delivered to Buyer a copy of all the Natural Resource Leases",
      isText: false,
      type: "checkbox",
      top: 698.556,
      left: 59.6707,
      width: 12.038,
      height: 9.353,
    },
    {
      page: 0,
      name: "Number of days",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 722.5352,
      left: 336.92,
      width: 35.539,
      height: 9.48,
    },
    {
      page: 1,
      name: "Address of Property Page 2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 30.142,
      left: 128.434,
      width: 325.847,
      height: 11.978,
    },
    {
      page: 1,
      name: "5A Escrow Agent",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 82.834,
      left: 152.654,
      width: 152.624,
      height: 10.007,
    },
    {
      page: 1,
      name: "5A Escrow Agent Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 81.918,
      left: 403.513,
      width: 157.656,
      height: 11.254,
    },
    {
      page: 1,
      name: "Escrow Agent Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 93.742,
      left: 75.4987,
      width: 150.9273,
      height: 8.953,
    },
    {
      page: 1,
      name: "5A Amount (Earnest Money)",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 92.988,
      left: 292.518,
      width: 72.123,
      height: 10.103,
    },
    {
      page: 1,
      name: "5A Amount (Option Fee)",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 93.26,
      left: 488.441,
      width: 74.759,
      height: 10.103,
    },
    {
      page: 1,
      name: "5A(1) Amount",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 124.645,
      left: 341.615,
      width: 105.851,
      height: 10.726,
    },
    {
      page: 1,
      name: "5A(1) Number of Days",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 136.645,
      left: 94.215,
      width: 35.539,
      height: 9.48,
    },
    {
      page: 1,
      name: "5B Number of Days",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 281.88,
      left: 76.0993,
      width: 38.3807,
      height: 9.6,
    },
    {
      page: 1,
      name: "Seller’s expense",
      isText: false,
      type: "checkbox",
      top: 431.007,
      left: 314.042,
      width: 11.198,
      height: 8.753,
    },
    {
      page: 1,
      name: "Buyer’s expense",
      isText: false,
      type: "checkbox",
      top: 431.007,
      left: 368.44,
      width: 10.68,
      height: 8.753,
    },
    {
      page: 1,
      name: "Name of Title Company",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 442.645,
      left: 284.655,
      width: 199.92,
      height: 9.48,
    },
    {
      page: 1,
      name: "Checkbox 6A(8)(i)",
      isText: false,
      type: "checkbox",
      top: 603.931,
      left: 76.0,
      width: 10.68,
      height: 9.789,
    },
    {
      page: 1,
      name: "Checkbox 6A(8)(ii)",
      isText: false,
      type: "checkbox",
      top: 614.931,
      left: 75.3454,
      width: 11.3346,
      height: 9.789,
    },
    {
      page: 1,
      name: "Checkbox Buyer 6A(8)ii",
      isText: false,
      type: "checkbox",
      top: 615.725,
      left: 434.6,
      width: 10.68,
      height: 8.235,
    },
    {
      page: 1,
      name: "Checkbox Seller 6A(8)ii",
      isText: false,
      type: "checkbox",
      top: 615.725,
      left: 499.398,
      width: 10.68,
      height: 8.235,
    },
    {
      page: 2,
      name: "Address of Property Page 3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 28.92,
      left: 130.936,
      width: 323.692,
      height: 14.254,
    },
    {
      page: 2,
      name: "6C(1) Checkbox",
      isText: false,
      type: "checkbox",
      top: 75.756,
      left: 58.1001,
      width: 11.9327,
      height: 9.871,
    },
    {
      page: 2,
      name: "Number of Days (6(C)1)",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 76.893,
      left: 125.275,
      width: 37.199,
      height: 9.954,
    },
    {
      page: 2,
      name: "Seller’s Checkbox",
      isText: false,
      type: "checkbox",
      top: 142.46,
      left: 274.626,
      width: 11.198,
      height: 8.753,
    },
    {
      page: 2,
      name: "Buyer’s Checkbox",
      isText: false,
      type: "checkbox",
      top: 143.148,
      left: 327.556,
      width: 11.197,
      height: 8.753,
    },
    {
      page: 2,
      name: "6C(2) Checkbox",
      isText: false,
      type: "checkbox",
      top: 165.145,
      left: 59.1612,
      width: 11.1978,
      height: 8.753,
    },
    {
      page: 2,
      name: "Number of Days (6(C)2)",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 164.851,
      left: 124.882,
      width: 35.86,
      height: 10.847,
    },
    {
      page: 2,
      name: "6C(3) Checkbox",
      isText: false,
      type: "checkbox",
      top: 197.832,
      left: 60.0908,
      width: 11.1978,
      height: 8.753,
    },
    {
      page: 2,
      name: "Number of Days,",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 197.228,
      left: 124.29,
      width: 40.309,
      height: 11.47,
    },
    {
      page: 2,
      name: "6D Prohibitions on use or activity",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 254.298,
      left: 117.919,
      width: 434.609,
      height: 9.6,
    },
    {
      page: 2,
      name: "Number of Days 6D(ii)",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 264.136,
      left: 370.809,
      width: 36.79,
      height: 8.842,
    },
    {
      page: 2,
      name: "Is checkbox",
      isText: false,
      type: "checkbox",
      top: 461.232,
      left: 453.626,
      width: 11.198,
      height: 8.753,
    },
    {
      page: 2,
      name: "Is not checkbox",
      isText: false,
      type: "checkbox",
      top: 461.92,
      left: 477.556,
      width: 11.197,
      height: 8.753,
    },
    {
      page: 3,
      name: "Address of Property Page 4",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 29.053,
      left: 128.413,
      width: 325.8,
      height: 13.594,
    },
    {
      page: 3,
      name: "6E(11) Required Notices_0",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 470.208,
      left: 295.198,
      width: 257.536,
      height: 9.876,
    },
    {
      page: 3,
      name: "6E(11) Required Notices 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 479.766,
      left: 88.413,
      width: 464.175,
      height: 9.349,
    },
    {
      page: 3,
      name: "6E(11) Required Notices_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 488.913,
      left: 88.0717,
      width: 464.7013,
      height: 9.349,
    },
    {
      page: 3,
      name: "7B(1) Buyer has received the Notice checkbox",
      isText: false,
      type: "checkbox",
      top: 590.451,
      left: 62.44,
      width: 10.68,
      height: 9.789,
    },
    {
      page: 3,
      name: "7B(2) Buyer has not received the Notice checkbox",
      isText: false,
      type: "checkbox",
      top: 601.371,
      left: 62.44,
      width: 10.68,
      height: 9.789,
    },
    {
      page: 3,
      name: "Number of Days",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 602.185,
      left: 319.332,
      width: 48.809,
      height: 10.583,
    },
    {
      page: 3,
      name: "7B(3) The Seller is not required to furnish the notice under the Texas Property Code",
      isText: false,
      type: "checkbox",
      top: 661.103,
      left: 62.327,
      width: 10.5747,
      height: 9.271,
    },
    {
      page: 4,
      name: "Address of Property Page 5",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 30.446,
      left: 128.413,
      width: 325.8,
      height: 12.674,
    },
    {
      page: 4,
      name: "7D(1) Buyer accepts Property As Is Checkbox",
      isText: false,
      type: "checkbox",
      top: 61.291,
      left: 58.9222,
      width: 11.1978,
      height: 9.789,
    },
    {
      page: 4,
      name: "7D(2) Buyer accepts Property As Is provided Seller … checkbox",
      isText: false,
      type: "checkbox",
      top: 73.506,
      left: 59.0908,
      width: 11.1978,
      height: 9.789,
    },
    {
      page: 4,
      name: "7D(2) Specific reparis and treatments",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 85.68,
      left: 302.17,
      width: 251.403,
      height: 9.96,
    },
    {
      page: 4,
      name: "7D(2) Specific reparis and treatments 2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 96.217,
      left: 90.0097,
      width: 463.5133,
      height: 10.463,
    },
    {
      page: 4,
      name: "7H Amount",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 384.594,
      left: 421.661,
      width: 92.212,
      height: 11.014,
    },
    {
      page: 4,
      name: "8A Brokers and Sales Agent Disclosure",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 493.136,
      left: 388.34,
      width: 163.752,
      height: 10.127,
    },
    {
      page: 4,
      name: "8A Brokers and Sales Agent Disclosure 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 505.157,
      left: 81.574,
      width: 469.034,
      height: 9.503,
    },
    {
      page: 4,
      name: "Date Number 1",
      fontSize: 9,
      type: "date",
      top: 549.38,
      left: 290.83,
      width: 130.738,
      height: 9.96,
    },
    {
      page: 4,
      name: "Year Number 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 549.38,
      left: 441.608,
      width: 27.0,
      height: 9.96,
    },
    {
      page: 5,
      name: "10A upon closing and funding",
      isText: false,
      type: "checkbox",
      top: 77.3,
      left: 351.471,
      width: 12.158,
      height: 9.353,
    },
    {
      page: 5,
      name: "10A according to a temporary residential lease",
      isText: false,
      type: "checkbox",
      top: 77.3,
      left: 499.138,
      width: 9.771,
      height: 9.353,
    },
    {
      page: 5,
      name: "11 Special Provisions",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 302.051,
      left: 350.172,
      width: 204.601,
      height: 10.92,
    },
    {
      page: 5,
      name: "11 Special Provisions Blank 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 312.668,
      left: 59.5311,
      width: 494.5269,
      height: 10.92,
    },
    {
      page: 5,
      name: "11 Special Provisions Blank 2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 323.211,
      left: 59.5311,
      width: 495.0549,
      height: 11.447,
    },
    {
      page: 5,
      name: "12A(1)(b) Amount",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 414.947,
      left: 351.11,
      width: 84.824,
      height: 9.658,
    },
    {
      page: 5,
      name: "Address of Property Page 6",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 30.906,
      left: 128.701,
      width: 326.278,
      height: 12.364,
    },
    {
      page: 6,
      name: "Address of Property Page 7",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 31.284,
      left: 129.404,
      width: 325.415,
      height: 11.782,
    },
    {
      page: 7,
      name: "Seller Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 79.091,
      left: 385.987,
      width: 162.42,
      height: 14.49,
    },
    {
      page: 7,
      name: "Buyer Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 79.874,
      left: 133.462,
      width: 161.486,
      height: 13.373,
    },
    {
      page: 7,
      name: "Seller Address 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 105.524,
      left: 316.536,
      width: 231.766,
      height: 13.469,
    },
    {
      page: 7,
      name: "Buyer Address 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 105.524,
      left: 61.8097,
      width: 232.8613,
      height: 13.996,
    },
    {
      page: 7,
      name: "Buyer Email Fax Number 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 155.97,
      left: 134.608,
      width: 160.412,
      height: 14.041,
    },
    {
      page: 7,
      name: "Buyer Phone Number",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 130.768,
      left: 166.108,
      width: 128.675,
      height: 13.61,
    },
    {
      page: 7,
      name: "Seller Phone Number",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 129.006,
      left: 420.964,
      width: 127.549,
      height: 15.814,
    },
    {
      page: 7,
      name: "Buyer Area Code",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 130.168,
      left: 139.256,
      width: 21.893,
      height: 14.728,
    },
    {
      page: 7,
      name: "Seller Area Code",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 129.034,
      left: 394.351,
      width: 21.375,
      height: 15.764,
    },
    {
      page: 7,
      name: "Seller Email Fax",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 156.894,
      left: 387.526,
      width: 161.893,
      height: 12.845,
    },
    {
      page: 7,
      name: "Buyer Email Fax Number",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 180.526,
      left: 133.379,
      width: 161.582,
      height: 13.867,
    },
    {
      page: 7,
      name: "Seller Email Fax 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 179.926,
      left: 387.053,
      width: 161.893,
      height: 14.954,
    },
    {
      page: 7,
      name: "Seller’s Agent Information",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 211.764,
      left: 316.275,
      width: 233.264,
      height: 14.954,
    },
    {
      page: 7,
      name: "Buyer’s Agent Information",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 212.308,
      left: 59.9428,
      width: 234.7372,
      height: 13.867,
    },
    {
      page: 7,
      name: "Third Party Financing Addendum Checkbox",
      subType: "addDoc",
      docToAdd: "Addendum Third Party Financing",
      isText: false,
      type: "checkbox",
      top: 270.748,
      left: 65.2286,
      width: 11.52,
      height: 9.871,
    },
    {
      page: 7,
      name: "Seller's Temporary Residential Lease Checkbox",
      subType: "addDoc",
      docToAdd: "Sellers Temporary Residential Lease",
      isText: false,
      type: "checkbox",
      top: 273.066,
      left: 316.109,
      width: 11.52,
      height: 9.353,
    },
    {
      page: 7,
      name: "Seller Financing Addendum Checkbox",
      subType: "addDoc",
      docToAdd: "Addendum Seller Financing",
      isText: false,
      type: "checkbox",
      top: 285.628,
      left: 65.2286,
      width: 11.52,
      height: 9.871,
    },
    {
      page: 7,
      name: "Short Sale Addendum Checkbox",
      subType: "addDoc",
      docToAdd: "Addendum Short Sale",
      isText: false,
      type: "checkbox",
      top: 291.066,
      left: 316.109,
      width: 11.52,
      height: 9.353,
    },
    {
      page: 7,
      name: "Addendum for Property Subject to Mandatory Membership in a Property Owners Association",
      subType: "addDoc",
      docToAdd: "Addendum Mandatory Membership in Owners Association",
      isText: false,
      type: "checkbox",
      top: 300.628,
      left: 65.2286,
      width: 11.52,
      height: 9.871,
    },
    {
      page: 7,
      name: "Addendum for Property Located Seaward of the Gulf Intracoastal Waterway",
      subType: "addDoc",
      docToAdd: "Addendum Property Located Seaward of Gulf Waterway",
      isText: false,
      type: "checkbox",
      top: 309.066,
      left: 316.109,
      width: 11.52,
      height: 9.353,
    },
    {
      page: 7,
      name: "Buyers Temporary Residential Lease",
      subType: "addDoc",
      docToAdd: "Buyers Temporary Residential Lease",
      isText: false,
      type: "checkbox",
      top: 333.628,
      left: 65.2286,
      width: 11.52,
      height: 9.871,
    },
    {
      page: 7,
      name: "Addendum for Seller's Disclosure of Information on Lead-based Paint and Lead-Based Paint Hazards as Required by Federal Law",
      subType: "addDoc",
      docToAdd: "Addendum Lead Based Paint",
      isText: false,
      type: "checkbox",
      top: 335.548,
      left: 316.109,
      width: 11.52,
      height: 9.871,
    },
    {
      page: 7,
      name: "Loan Assumption Addendum",
      subType: "addDoc",
      docToAdd: "Addendum Loan Assumption",
      isText: false,
      type: "checkbox",
      top: 348.628,
      left: 64.7108,
      width: 12.0378,
      height: 9.871,
    },
    {
      page: 7,
      name: "Addendum for Sale of Other Property by Buyer",
      subType: "addDoc",
      docToAdd: "Addendum Sale of Other Property by Buyer",
      isText: false,
      type: "checkbox",
      top: 363.11,
      left: 65.2286,
      width: 11.52,
      height: 10.389,
    },
    {
      page: 7,
      name: "Addendum Property in a Propane Gas System Service Area",
      subType: "addDoc",
      docToAdd: "Addendum Propane Gas Area",
      isText: false,
      type: "checkbox",
      top: 379.03,
      left: 316.109,
      width: 11.52,
      height: 10.389,
    },
    {
      page: 7,
      name: "Addendum Reservation of Oil Gas and Other Minerals",
      subType: "addDoc",
      docToAdd: "Addendum Reservation of Oil Gas Minerals",
      isText: false,
      type: "checkbox",
      top: 388.348,
      left: 65.2286,
      width: 11.52,
      height: 9.871,
    },
    {
      page: 7,
      name: "Addendum Regarding Residential Leases",
      subType: "addDoc",
      docToAdd: "Addendum Regarding Residential Leases",
      isText: false,
      type: "checkbox",
      top: 402.433,
      left: 316.93,
      width: 11.52,
      height: 10.389,
    },
    {
      page: 7,
      name: "Addendum for Back-Up Contract",
      subType: "addDoc",
      docToAdd: "Addendum Back-Up Contract",
      isText: false,
      type: "checkbox",
      top: 411.23,
      left: 66.2641,
      width: 10.4845,
      height: 10.389,
    },
    {
      page: 7,
      name: "Addendum for Coastal Area Property",
      subType: "addDoc",
      docToAdd: "Addendum Coastal Area Property",
      isText: false,
      type: "checkbox",
      top: 427.191,
      left: 66.2843,
      width: 11.3703,
      height: 9.42,
    },
    {
      page: 7,
      name: "Addendum Regarding Fixture Leases",
      subType: "addDoc",
      docToAdd: "Addendum Regarding Fixture Leases",
      isText: false,
      type: "checkbox",
      top: 419.548,
      left: 316.109,
      width: 11.52,
      height: 9.871,
    },
    {
      page: 7,
      name: "Addendum for Authorizing Hydrostatic Testing",
      subType: "addDoc",
      docToAdd: "Addendum Authorizing Hydrostatic Testing",
      isText: false,
      type: "checkbox",
      top: 444.54,
      left: 65.7666,
      width: 11.888,
      height: 10.561,
    },
    {
      page: 7,
      name: "Addendum Containing Notice of Obligation to Pay Inmprovment District Assessment",
      subType: "addDoc",
      docToAdd: "Addendum Notice of Obligation to Pay Improvement District Assessment",
      isText: false,
      type: "checkbox",
      top: 439.628,
      left: 316.627,
      width: 11.002,
      height: 9.871,
    },
    {
      page: 7,
      name: "Other 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 466.299,
      left: 399.152,
      width: 155.517,
      height: 10.08,
    },
    {
      page: 7,
      name: "Addendum Concerning Right to Terminate Due to Lender’s Appraisal",
      subType: "addDoc",
      docToAdd: "Addendum Right to Terminate Due to Appraisal",
      isText: false,
      type: "checkbox",
      top: 470.908,
      left: 65.2286,
      width: 11.52,
      height: 9.871,
    },
    {
      page: 7,
      name: "Other 2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 477.311,
      left: 335.432,
      width: 219.237,
      height: 9.748,
    },
    {
      page: 7,
      name: "Other 3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 489.779,
      left: 335.432,
      width: 219.237,
      height: 9.96,
    },
    {
      page: 7,
      name: "Other 4",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 500.459,
      left: 335.432,
      width: 219.237,
      height: 10.08,
    },
    {
      page: 7,
      name: "Other",
      isText: false,
      type: "checkbox",
      top: 467.843,
      left: 316.113,
      width: 11.002,
      height: 9.871,
    },
    {
      page: 7,
      name: "Environmental Assessment, Threatened or Endangered Species and Wetlands Addendum",
      subType: "addDoc",
      docToAdd: "Addendum Environmental Assessment",
      isText: false,
      type: "checkbox",
      top: 492.692,
      left: 65.9297,
      width: 11.52,
      height: 9.871,
    },
    {
      page: 7,
      name: "Seller’s Attorney Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 611.155,
      left: 322.52,
      width: 238.08,
      height: 14.125,
    },
    {
      page: 7,
      name: "Buyer’s Attorney Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 612.463,
      left: 66.68,
      width: 233.04,
      height: 12.817,
    },
    {
      page: 7,
      name: "Buyer’s Attorney Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 576.813,
      left: 130.28,
      width: 169.44,
      height: 14.507,
    },
    {
      page: 7,
      name: "Seller’s Attorney Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 577.433,
      left: 386.6,
      width: 174.0,
      height: 14.411,
    },
    {
      page: 7,
      name: "Buyer’s Attorney Phone Number",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 651.281,
      left: 162.814,
      width: 137.056,
      height: 14.449,
    },
    {
      page: 7,
      name: "Seller’s Attorney Phone Number",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 651.018,
      left: 419.188,
      width: 141.198,
      height: 15.103,
    },
    {
      page: 7,
      name: "Buyer’s Attorney Area Code",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 651.281,
      left: 135.89,
      width: 22.628,
      height: 14.967,
    },
    {
      page: 7,
      name: "Seller’s Attorney Area Code",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 651.277,
      left: 393.041,
      width: 22.628,
      height: 14.067,
    },
    {
      page: 7,
      name: "Buyer’s Attorney Fax Number",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 692.83,
      left: 162.814,
      width: 137.056,
      height: 14.448,
    },
    {
      page: 7,
      name: "Seller’s Attorney Fax Number",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 691.861,
      left: 419.137,
      width: 141.198,
      height: 15.757,
    },
    {
      page: 7,
      name: "Buyer’s Attorney Fax Area Code",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 692.176,
      left: 136.164,
      width: 22.872,
      height: 15.62,
    },
    {
      page: 7,
      name: "Seller’s Attorney Fax Area Code",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 692.12,
      left: 392.99,
      width: 22.628,
      height: 15.24,
    },
    {
      page: 7,
      name: "Buyer’s Attorney Email",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 738.6869,
      left: 130.28,
      width: 169.44,
      height: 10.4731,
    },
    {
      page: 7,
      name: "Seller’s Attorney Email",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 738.2723,
      left: 386.6,
      width: 174.0,
      height: 11.1277,
    },
    {
      page: 7,
      name: "Address of Property Page 8",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 29.858,
      left: 128.914,
      width: 325.826,
      height: 13.262,
    },
    {
      page: 8,
      name: "Address of Property Page 9",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 28.6,
      left: 128.697,
      width: 325.84,
      height: 14.52,
    },
    {
      page: 8,
      name: "text Date Number 2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 222.648,
      left: 148.843,
      width: 37.56,
      height: 13.667,
    },
    {
      page: 8,
      name: "Month",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 224.72,
      left: 224.443,
      width: 136.8,
      height: 11.595,
    },
    {
      page: 8,
      name: "Year Number 2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 224.16,
      left: 382.483,
      width: 27.36,
      height: 12.155,
    },
    {
      page: 9,
      name: "Other Broker Firm",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 149.718,
      left: 49.7897,
      width: 176.7283,
      height: 13.539,
    },
    {
      page: 9,
      name: "License No",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 149.718,
      left: 227.602,
      width: 58.91,
      height: 13.539,
    },
    {
      page: 9,
      name: "Buyer only as Buyer’s agent checkbox",
      isText: false,
      type: "checkbox",
      top: 184.308,
      left: 109.31,
      width: 11.52,
      height: 9.353,
    },
    {
      page: 9,
      name: "Seller as List Broker's subagent checkbox",
      isText: false,
      type: "checkbox",
      top: 198.708,
      left: 108.83,
      width: 11.52,
      height: 9.353,
    },
    {
      page: 9,
      name: "Associates Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 216.485,
      left: 49.7897,
      width: 177.1683,
      height: 15.216,
    },
    {
      page: 9,
      name: "License No_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 216.485,
      left: 228.56,
      width: 58.47,
      height: 15.216,
    },
    {
      page: 9,
      name: "Team Name 0",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 246.978,
      left: 49.4594,
      width: 237.6206,
      height: 15.216,
    },
    {
      page: 9,
      name: "Associate's Email Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 276.919,
      left: 49.7897,
      width: 163.5653,
      height: 14.782,
    },
    {
      page: 9,
      name: "Phone",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 276.401,
      left: 215.516,
      width: 71.514,
      height: 15.3,
    },
    {
      page: 9,
      name: "Licensed Supervisor of Associate",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 304.408,
      left: 49.7897,
      width: 177.0103,
      height: 16.811,
    },
    {
      page: 9,
      name: "License No_3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 304.408,
      left: 228.921,
      width: 58.627,
      height: 16.811,
    },
    {
      page: 9,
      name: "Other Broker's Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 336.485,
      left: 49.7897,
      width: 164.6453,
      height: 15.216,
    },
    {
      page: 9,
      name: "Phone_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 336.443,
      left: 216.037,
      width: 70.993,
      height: 15.258,
    },
    {
      page: 9,
      name: "City",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 365.288,
      left: 49.7897,
      width: 127.0523,
      height: 16.293,
    },
    {
      page: 9,
      name: "State",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 365.288,
      left: 177.885,
      width: 43.554,
      height: 16.293,
    },
    {
      page: 9,
      name: "Zip",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 365.246,
      left: 222.482,
      width: 64.548,
      height: 16.335,
    },
    {
      page: 9,
      name: "Listing Broker Firm",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 149.159,
      left: 305.428,
      width: 197.448,
      height: 14.098,
    },
    {
      page: 9,
      name: "License No_4",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 149.159,
      left: 503.96,
      width: 57.63,
      height: 14.098,
    },
    {
      page: 9,
      name: "Seller and Buyer as an intermediary",
      isText: false,
      type: "checkbox",
      top: 184.188,
      left: 364.671,
      width: 12.038,
      height: 9.871,
    },
    {
      page: 9,
      name: "Seller only as Sellers agent",
      isText: false,
      type: "checkbox",
      top: 198.447,
      left: 364.93,
      width: 11.52,
      height: 9.353,
    },
    {
      page: 9,
      name: "List Assoc Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 215.883,
      left: 304.91,
      width: 190.535,
      height: 15.818,
    },
    {
      page: 9,
      name: "License No_5",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 215.366,
      left: 497.607,
      width: 63.983,
      height: 16.335,
    },
    {
      page: 9,
      name: "Listing Associate's Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 247.3,
      left: 304.528,
      width: 257.843,
      height: 15.194,
    },
    {
      page: 9,
      name: "Listing Associate's Email Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 274.807,
      left: 304.91,
      width: 183.086,
      height: 16.894,
    },
    {
      page: 9,
      name: "Phone_3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 274.848,
      left: 489.598,
      width: 71.992,
      height: 16.853,
    },
    {
      page: 9,
      name: "Licensed Supervisor of Listing Associate",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 305.926,
      left: 304.91,
      width: 191.493,
      height: 16.293,
    },
    {
      page: 9,
      name: "License No_6",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 305.926,
      left: 498.006,
      width: 63.584,
      height: 16.811,
    },
    {
      page: 9,
      name: "Listing Brokers Office Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 335.926,
      left: 304.91,
      width: 182.405,
      height: 15.775,
    },
    {
      page: 9,
      name: "Phone_4",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 335.926,
      left: 488.917,
      width: 72.673,
      height: 15.775,
    },
    {
      page: 9,
      name: "City_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 365.946,
      left: 304.91,
      width: 151.692,
      height: 15.258,
    },
    {
      page: 9,
      name: "State_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 365.946,
      left: 457.645,
      width: 41.59,
      height: 15.258,
    },
    {
      page: 9,
      name: "Zip_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 365.946,
      left: 500.278,
      width: 61.312,
      height: 15.258,
    },
    {
      page: 9,
      name: "Selling Associate's Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 395.722,
      left: 304.91,
      width: 192.813,
      height: 15.859,
    },
    {
      page: 9,
      name: "License No_7",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 395.722,
      left: 498.766,
      width: 62.824,
      height: 15.859,
    },
    {
      page: 9,
      name: "Team Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 424.194,
      left: 304.66,
      width: 257.005,
      height: 15.859,
    },
    {
      page: 9,
      name: "Selling Associate's Email Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 454.687,
      left: 304.91,
      width: 181.448,
      height: 15.694,
    },
    {
      page: 9,
      name: "Phone_5",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 454.687,
      left: 487.961,
      width: 73.629,
      height: 15.694,
    },
    {
      page: 9,
      name: "Licensed Supervisor of Selling Associate",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 484.647,
      left: 304.91,
      width: 190.974,
      height: 15.374,
    },
    {
      page: 9,
      name: "License No_8",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 484.647,
      left: 497.487,
      width: 64.103,
      height: 15.374,
    },
    {
      page: 9,
      name: "Selling Associate's Office Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 514.448,
      left: 304.91,
      width: 256.68,
      height: 15.213,
    },
    {
      page: 9,
      name: "City_3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 546.089,
      left: 304.91,
      width: 140.163,
      height: 15.572,
    },
    {
      page: 9,
      name: "State_3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 546.047,
      left: 445.723,
      width: 46.836,
      height: 15.614,
    },
    {
      page: 9,
      name: "Zip_3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 545.529,
      left: 494.162,
      width: 67.428,
      height: 16.691,
    },
    {
      page: 9,
      name: "Fee Amount",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 631.438,
      left: 432.71,
      width: 124.911,
      height: 10.434,
    },
    {
      page: 9,
      name: "Fee Amount 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 641.174,
      left: 51.6413,
      width: 203.9647,
      height: 10.835,
    },
    {
      page: 9,
      name: "Address of Property Page 10",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 27.52,
      left: 125.575,
      width: 325.839,
      height: 15.6,
    },
    {
      page: 10,
      name: "Amount (Option Fee)",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 95.438,
      left: 106.92,
      width: 108.72,
      height: 15.6,
    },
    {
      page: 10,
      name: "Form of Payment 0",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 95.956,
      left: 344.04,
      width: 211.68,
      height: 15.6,
    },
    {
      page: 10,
      name: "Escrow Agent Name 0",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 132.12,
      left: 52.32,
      width: 248.16,
      height: 15.6,
    },
    {
      page: 10,
      name: "Date",
      fontSize: 9,
      type: "date",
      top: 132.12,
      left: 313.32,
      width: 243.12,
      height: 15.6,
    },
    {
      page: 10,
      name: "Amount (Earnest Money)",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 181.351,
      left: 106.2,
      width: 114.84,
      height: 14.047,
    },
    {
      page: 10,
      name: "Form of Payment 1",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 182.073,
      left: 358.92,
      width: 197.88,
      height: 14.047,
    },
    {
      page: 10,
      name: "Escrow Agent Name",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 207.24,
      left: 52.08,
      width: 130.096,
      height: 15.6,
    },
    {
      page: 10,
      name: "Received by",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 207.24,
      left: 183.187,
      width: 117.053,
      height: 15.6,
    },
    {
      page: 10,
      name: "Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 232.92,
      left: 52.08,
      width: 306.72,
      height: 15.6,
    },
    {
      page: 10,
      name: "City_4",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 256.56,
      left: 51.84,
      width: 163.189,
      height: 15.6,
    },
    {
      page: 10,
      name: "State_4",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 256.56,
      left: 216.558,
      width: 39.619,
      height: 15.6,
    },
    {
      page: 10,
      name: "Zip_4",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 256.56,
      left: 257.705,
      width: 101.095,
      height: 15.6,
    },
    {
      page: 10,
      name: "Email Address",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 207.24,
      left: 313.2,
      width: 140.181,
      height: 15.6,
    },
    {
      page: 10,
      name: "text DateTime",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 207.24,
      left: 455.945,
      width: 100.255,
      height: 15.6,
    },
    {
      page: 10,
      name: "Phone_6",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 232.92,
      left: 367.68,
      width: 189.12,
      height: 15.6,
    },
    {
      page: 10,
      name: "Fax",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 256.56,
      left: 367.68,
      width: 189.12,
      height: 15.6,
    },
    {
      page: 10,
      name: "Escrow Agent Name (Contract Receipt)",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 330.4,
      left: 52.08,
      width: 134.132,
      height: 15.6,
    },
    {
      page: 10,
      name: "Received by_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 330.4,
      left: 188.611,
      width: 111.629,
      height: 15.6,
    },
    {
      page: 10,
      name: "Email Address_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 330.4,
      left: 313.2,
      width: 147.832,
      height: 15.6,
    },
    {
      page: 10,
      name: "Date_2",
      fontSize: 9,
      type: "date",
      top: 330.4,
      left: 463.079,
      width: 93.121,
      height: 15.6,
    },
    {
      page: 10,
      name: "Address_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 355.08,
      left: 52.08,
      width: 306.72,
      height: 15.6,
    },
    {
      page: 10,
      name: "City_5",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 378.72,
      left: 51.84,
      width: 161.636,
      height: 15.6,
    },
    {
      page: 10,
      name: "State_5",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 378.72,
      left: 214.796,
      width: 40.275,
      height: 15.6,
    },
    {
      page: 10,
      name: "Zip_5",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 378.72,
      left: 257.939,
      width: 101.385,
      height: 15.6,
    },
    {
      page: 10,
      name: "Phone_7",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 355.08,
      left: 367.68,
      width: 189.12,
      height: 15.6,
    },
    {
      page: 10,
      name: "Fax_2",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 378.72,
      left: 367.68,
      width: 189.12,
      height: 15.6,
    },
    {
      page: 10,
      name: "Amount (Additional Earnest Money Receipt)",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 434.629,
      left: 109.32,
      width: 103.56,
      height: 13.011,
    },
    {
      page: 10,
      name: "Form of Payment",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 435.147,
      left: 398.88,
      width: 160.92,
      height: 12.493,
    },
    {
      page: 10,
      name: "Escrow Agent_3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 458.88,
      left: 52.08,
      width: 126.472,
      height: 15.6,
    },
    {
      page: 10,
      name: "Received by_3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 458.88,
      left: 180.081,
      width: 120.159,
      height: 15.6,
    },
    {
      page: 10,
      name: "Address_3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 485.56,
      left: 52.08,
      width: 306.72,
      height: 15.6,
    },
    {
      page: 10,
      name: "City_6",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 509.32,
      left: 51.84,
      width: 159.565,
      height: 15.48,
    },
    {
      page: 10,
      name: "State_6",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 509.32,
      left: 213.969,
      width: 41.69,
      height: 15.48,
    },
    {
      page: 10,
      name: "Zip_6",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 509.32,
      left: 258.223,
      width: 100.577,
      height: 15.48,
    },
    {
      page: 10,
      name: "Email Address_3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 458.88,
      left: 313.2,
      width: 149.501,
      height: 15.6,
    },
    {
      page: 10,
      name: "DateTime_2",
      fontSize: 9,
      type: "date",
      top: 458.88,
      left: 464.747,
      width: 91.453,
      height: 15.6,
    },
    {
      page: 10,
      name: "Phone_8",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 485.56,
      left: 367.68,
      width: 189.12,
      height: 15.6,
    },
    {
      page: 10,
      name: "Fax_3",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 509.32,
      left: 367.68,
      width: 189.12,
      height: 16.527,
    },
    {
      page: 10,
      name: "Address of Property Page 11",
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 27.52,
      left: 126.913,
      width: 325.84,
      height: 15.6,
    },
  ];
}
