export function condominiumAddendum() {
return [   //2024 Release 2024-06-07 08:58:10
{
   page: 0,
   name: "date contractDate", 
   fontSize: 9,
   type: "date",
   top: 150.327,
   left: 71.3455,
   width: 88.3635,
   height: 13.091
}
,
{
   page: 0,
   name: "Text11", 
   isText: false,
   type: "checkbox",
   top: 150.018,
   left: 181.746,
   width: 14.182,
   height: 13.091
}
,
{
   page: 0,
   name: "address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.709,
   left: 71.3455,
   width: 194.1825,
   height: 13.091
}
,
{
   page: 0,
   name: "unit", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.709,
   left: 267.618,
   width: 55.637,
   height: 13.091
}
,
{
   page: 0,
   name: "city", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.709,
   left: 325.091,
   width: 140.291,
   height: 13.091
}
,
{
   page: 0,
   name: "zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.709,
   left: 471.055,
   width: 77.237,
   height: 13.091
}
,
{
   page: 0,
   name: "condoproject", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 281.4,
   left: 81.9456,
   width: 212.9454,
   height: 13.746
}
,
{
   page: 0,
   name: "amount", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.764,
   left: 77.4546,
   width: 102.5454,
   height: 14.400
}
,
{
   page: 0,
   name: "assessmentsother", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 446.09,
   left: 87.2725,
   width: 447.2715,
   height: 14.40
}
,
{
   page: 0,
   name: "dateExamine", 
   fontSize: 9,
   type: "date",
   top: 611.471,
   left: 142.909,
   width: 84.163,
   height: 14.400
}
,
{
   page: 0,
   name: "Check Box20", 
   isText: false,
   type: "checkbox",
   top: 433.636,
   left: 251.673,
   width: 7.854,
   height: 8.182
}
,
{
   page: 0,
   name: "Check Box21", 
   isText: false,
   type: "checkbox",
   top: 433.636,
   left: 297.182,
   width: 7.854,
   height: 8.182
}
,
{
   page: 0,
   name: "Check Box22", 
   isText: false,
   type: "checkbox",
   top: 433.636,
   left: 348.691,
   width: 7.854,
   height: 8.182
}
,
{
   page: 1,
   name: "seller1name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 211.8,
   left: 71.7819,
   width: 187.6371,
   height: 13.091
}
,
{
   page: 1,
   name: "buyer1name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 127.273,
   left: 70.6909,
   width: 187.6371,
   height: 13.091
}
,
{
   page: 1,
   name: "buyer2name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 114.182,
   left: 359.764,
   width: 187.637,
   height: 13.091
}
,
{
   page: 1,
   name: "seller2name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 211.8,
   left: 360.2,
   width: 187.637,
   height: 13.091
}
] }
