import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Image } from "semantic-ui-react";

export function FormFieldLogo({
  formField,
  index,
  populatingFieldsStatus,
  newPopulatedFields,
}) {
  const { pageScaleFill } = useSelector((state) => state.annot);
  const { doc } = useSelector((state) => state.doc);
  const [value, setValue] = useState(
    (doc.formFieldValues && doc.formFieldValues[formField.name]) || ""
  );
  const pageScale = pageScaleFill;

  // This is a catch for prepopulating data to make sure it rerenders
  useEffect(() => {
    if (
      populatingFieldsStatus === "Complete" &&
      newPopulatedFields?.[formField.name]
    ) {
      setValue(newPopulatedFields[formField.name]);
    }
  }, [populatingFieldsStatus, formField.name, newPopulatedFields]);

  return (
    <>
      {value && formField?.height > 10 && (
        <Image
          id={index}
          src={value}
          value="logo"
          style={{
            top: `${formField.top * pageScale}px`,
            left: `${formField.left * pageScale}px`,

            height: `${formField.height * pageScale}px`,
            width: `${formField.width * pageScale}px`,
          }}
        ></Image>
      )}
    </>
  );
}
