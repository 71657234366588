import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateFormTemplateInDb } from "../../../../app/firestore/firestoreService";

export function FormTemplateFieldDate({
  formField,
  index,
  populatingFieldsStatus,
  newPopulatedFields,
}) {
  const { pageScaleFill } = useSelector((state) => state.annot);
  const { doc } = useSelector((state) => state.doc);
  const [value, setValue] = useState(
    doc.formFieldValues?.[formField.name]?.toDate() || ""
  );

  const pageScale = pageScaleFill;
  const color = value ? "#0037b8" : "rgba(255, 255, 255, 0)";

  // This is a catch for prepopulating data to make sure it rerenders
  useEffect(() => {
    if (
      populatingFieldsStatus === "Complete" &&
      newPopulatedFields?.[formField.name]
    ) {
      setValue(newPopulatedFields[formField.name]);
    }
  }, [populatingFieldsStatus, formField.name, newPopulatedFields]);

  function handleChange(date) {
    setValue(date);
    updateFormTemplateInDb(doc.id, {
      [`formFieldValues.${formField.name}`]: date,
    });
  }

  return (
    <div
      className="pdfFormField customDatePickerWidth"
      style={{
        top: `${formField.top * pageScale}px`,
        left: `${formField.left * pageScale}px`,
        height: `${formField.height * pageScale}px`,
        width: `${formField.width * pageScale}px`,
        fontSize: `${formField.fontSize * pageScale}px`,
        // lineHeight: "unset",
      }}
    >
      <DatePicker
        className="fill-input-field"
        type={formField.type}
        name={formField.name}
        aria-label={formField.name}
        tabIndex={index}
        data-tabindex={index}
        autoComplete="off"
        value={value}
        selected={(value && new Date(value)) || null}
        onChange={(date) => handleChange(date)}
        preventOpenOnFocus={true}
        style={{
          borderWidth: "0px",
          fontFamily: "Helvetica",
          fontSize: `${formField.fontSize * pageScale}px`,
          margin: "0px",
          textAlign: "left",
          color: color,
        }}
      ></DatePicker>
    </div>
  );
}
