export function backUpSupplement() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Check Box 1", 
   isText: false,
   type: "checkbox",
   top: 295.2,
   left: 63.54,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 341.208,
   left: 63.54,
   width: 9.216,
   height: 9.144
}
] }
