import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import "react-toastify/dist/ReactToastify.min.css";
import './app/layout/styles.css';
import 'react-widgets/dist/css/react-widgets.css';
import App from './app/layout/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './app/store/configureStore';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './app/layout/ScrollToTop';
import './app/fonts/Parisienne-Regular.ttf';
import "./app/fonts/Arizonia-Regular.ttf";
import './app/fonts/LaBelleAurore-Regular.ttf';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://ced525806fe44b8198c96c604a61570e@o4504624399187968.ingest.sentry.io/4504624401940480",
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const rootEl = document.getElementById('root');

function render() {
  ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
        <ScrollToTop />
        <App />
        </BrowserRouter>
    </Provider>,
    rootEl
  );
}

if (module.hot) {
  module.hot.accept('./app/layout/App', function() {
    setTimeout(render);
  });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
