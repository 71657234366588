import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Menu } from "semantic-ui-react";
import { changeActiveAnnotType } from "../../../../../app/annots/annotSlice";

export default function AnnotTypeMenu() {
  const dispatch = useDispatch();
  const { activeAnnotType } = useSelector((state) => state.annot);

  return (
    <>
      <Menu
        widths={6}
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "0px",
          maxWidth: "720px",
        }}
      >
        <Menu.Item
          draggable
          name="signature"
          active={activeAnnotType === "signature"}
          onDragStart={() => dispatch(changeActiveAnnotType("signature"))}
          style={{
            cursor: "pointer",
          }}
        >
          <Icon name="pencil" />
          Signature
        </Menu.Item>
        <Menu.Item
          draggable
          onDragStart={() => dispatch(changeActiveAnnotType("initials"))}
          name="initials"
          active={activeAnnotType === "initials"}
          style={{
            cursor: "pointer",
          }}
        >
          <Icon name="pencil square" />
          Initials
        </Menu.Item>
        <Menu.Item
          draggable
          name="date"
          active={activeAnnotType === "date"}
          onDragStart={() => dispatch(changeActiveAnnotType("date"))}
          style={{
            cursor: "pointer",
          }}
        >
          <Icon name="calendar outline" />
          Date Signed
        </Menu.Item>
        <Menu.Item
          draggable
          name="text"
          active={activeAnnotType === "text"}
          onDragStart={() => dispatch(changeActiveAnnotType("text"))}
          style={{
            cursor: "pointer",
          }}
        >
          <Icon name="font" />
          Text
        </Menu.Item>
        <Menu.Item
          draggable
          name="strikethrough"
          active={activeAnnotType === "strikethrough"}
          onDragStart={() => dispatch(changeActiveAnnotType("strikethrough"))}
          style={{
            cursor: "pointer",
          }}
        >
          <Icon name="strikethrough" />
          Strikethrough
        </Menu.Item>
        <Menu.Item
          draggable
          name="checkbox"
          active={activeAnnotType === "checkbox"}
          onDragStart={() => dispatch(changeActiveAnnotType("checkbox"))}
          style={{
            cursor: "pointer",
          }}
        >
          <Icon name="check square outline" />
          Checkbox
        </Menu.Item>
      </Menu>
    </>
  );
}
