export function gTARSellerCounteroffer() {
return [   //2024 Release 2024-04-18 20:40:12
{
   page: 0,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 101.88,
   left: 435.96,
   width: 102.12,
   height: 13.44
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 101.88,
   left: 551.52,
   width: 22.20,
   height: 13.44
}
,
{
   page: 0,
   name: "address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 120.24,
   left: 265.08,
   width: 288.84,
   height: 13.44
}
,
{
   page: 0,
   name: "undersigned Sellers hereby amends and modifies the Contract of Sale of Real Estate and if included the Financing Supplement collectively referred", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 142.713,
   left: 145.92,
   width: 195.36,
   height: 9.327
}
,
{
   page: 0,
   name: "Reference should be made by number to the specific paragraph of the Contract which is amended or modified  please number each item", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 175.44,
   left: 157.32,
   width: 418.20,
   height: 13.44
}
,
{
   page: 0,
   name: "Contract of Sale of Real Estate 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 189.84,
   left: 36,
   width: 538.32,
   height: 12.84
}
,
{
   page: 0,
   name: "Contract of Sale of Real Estate 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 203.04,
   left: 36,
   width: 538.32,
   height: 13.44
}
,
{
   page: 0,
   name: "Contract of Sale of Real Estate 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 216.84,
   left: 36,
   width: 538.2,
   height: 13.44
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 235.2,
   left: 126.84,
   width: 444.72,
   height: 13.44
}
,
{
   page: 0,
   name: "Financing Supplement 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 249.6,
   left: 36,
   width: 538.32,
   height: 12.84
}
,
{
   page: 0,
   name: "Financing Supplement 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 262.8,
   left: 36,
   width: 538.32,
   height: 13.44
}
,
{
   page: 0,
   name: "textdateAccept", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 332.4,
   left: 148.68,
   width: 84.24,
   height: 8.28
}
,
{
   page: 0,
   name: "20_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 332.4,
   left: 246.36,
   width: 31.08,
   height: 8.28
}
,
{
   page: 0,
   name: "at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 332.4,
   left: 291,
   width: 26.76,
   height: 8.28
}
,
{
   page: 0,
   name: "ampm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 329.892,
   left: 320.729,
   width: 15.817,
   height: 11.528
}
,
{
   page: 0,
   name: "notice is given of the withdrawal of the Counteroffer prior to acceptance or termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 354.84,
   left: 36,
   width: 249.24,
   height: 13.44
}
,
{
   page: 0,
   name: "Print or Type Sellers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 383.095,
   left: 36.0,
   width: 249.24,
   height: 13.440
}
,
{
   page: 0,
   name: "Property on terms and conditions set forth in Sellers Counteroffer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 437.76,
   left: 104.04,
   width: 253.44,
   height: 13.44
}
,
{
   page: 0,
   name: "at_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 437.76,
   left: 371.04,
   width: 44.52,
   height: 13.44
}
,
{
   page: 0,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 437.76,
   left: 428.88,
   width: 57.84,
   height: 13.44
}
,
{
   page: 0,
   name: "m", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 437.76,
   left: 489,
   width: 35.64,
   height: 13.44
}
,
{
   page: 0,
   name: "Accepted this date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 456.12,
   left: 36,
   width: 249.24,
   height: 13.44
}
,
{
   page: 0,
   name: "Print or Type Buyers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 483.72,
   left: 36,
   width: 249.24,
   height: 13.44
}
,
{
   page: 0,
   name: "REJECTS Sellers Counteroffer", 
   isText: false,
   type: "checkbox",
   top: 558.48,
   left: 36.48,
   width: 13.44,
   height: 13.80
}
,
{
   page: 0,
   name: "MODIFIES Sellers Counteroffer is modified as set forth in the Buyers Counteroffer to Sellers Counteroffer form", 
   isText: false,
   type: "checkbox",
   top: 577.68,
   left: 36.48,
   width: 13.44,
   height: 13.80
}
] }
