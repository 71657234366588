export function contracttoBuyandSellIncomeResidential2024() {
return [   //2024 Release 2024-08-03 06:28:01
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "14 Units", 
   isText: false,
   type: "checkbox",
   top: 196.68,
   left: 203.4,
   width: 11.28,
   height: 11.28
}
,
{
   page: 0,
   name: "Larger than 14 Units", 
   isText: false,
   type: "checkbox",
   top: 196.68,
   left: 283.56,
   width: 11.28,
   height: 11.28
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 230.24,
   left: 460.92,
   width: 110.04,
   height: 14.20
}
,
{
   page: 0,
   name: "Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 341.6,
   left: 114.0,
   width: 420.0,
   height: 14.2
}
,
{
   page: 0,
   name: "Joint Tenants", 
   isText: false,
   type: "checkbox",
   top: 373.8,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Tenants In Common", 
   isText: false,
   type: "checkbox",
   top: 387.96,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 401.88,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 398.64,
   left: 110.52,
   width: 450.00,
   height: 13.08
}
,
{
   page: 0,
   name: "Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 432.8,
   left: 112.32,
   width: 420.00,
   height: 14.2
}
,
{
   page: 0,
   name: "Property The Property is the following legally described real estate in the County of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 461.28,
   left: 425.04,
   width: 140.04,
   height: 16.20
}
,
{
   page: 0,
   name: "known as", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 616.844,
   left: 121.08,
   width: 440.04,
   height: 14.236
}
,
{
   page: 0,
   name: "Text81", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 492.22,
   left: 51.7093,
   width: 514.5837,
   height: 124.11
}
,
{
   page: 1,
   name: "remote controls", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 67.44,
   left: 193.2,
   width: 65.04,
   height: 12.36
}
,
{
   page: 1,
   name: "Leased items should", 
   isText: false,
   type: "checkbox",
   top: 96.36,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Water Softeners", 
   isText: false,
   type: "checkbox",
   top: 96.36,
   left: 143.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Security Systems", 
   isText: false,
   type: "checkbox",
   top: 96.36,
   left: 242.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Satellite Systems including satellite dishes", 
   isText: false,
   type: "checkbox",
   top: 96.36,
   left: 338.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "property outside of this Contract", 
   isText: false,
   type: "checkbox",
   top: 338.28,
   left: 73.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "under 106", 
   isText: false,
   type: "checkbox",
   top: 511.8,
   left: 98.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Will", 
   isText: false,
   type: "checkbox",
   top: 511.8,
   left: 143.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Text82", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 220.583,
   left: 61.5275,
   width: 509.3475,
   height: 116.255
}
,
{
   page: 1,
   name: "Text83", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 430.693,
   left: 62.1821,
   width: 511.3109,
   height: 80.909
}
,
{
   page: 1,
   name: "Text84", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 591.057,
   left: 63.4912,
   width: 496.9108,
   height: 13.491
}
,
{
   page: 1,
   name: "Text85", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 616.585,
   left: 63.4912,
   width: 496.9108,
   height: 12.836
}
,
{
   page: 1,
   name: "leasedItems", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 675.494,
   left: 62.8366,
   width: 516.5474,
   height: 74.3637
}
,
{
   page: 2,
   name: "Will_2", 
   isText: false,
   type: "checkbox",
   top: 56.76,
   left: 98.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will Not assume", 
   isText: false,
   type: "checkbox",
   top: 56.76,
   left: 143.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Solar Power Plan", 
   isText: false,
   type: "checkbox",
   top: 100.44,
   left: 99,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will_3", 
   isText: false,
   type: "checkbox",
   top: 126.84,
   left: 375.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will Not assume Sellers", 
   isText: false,
   type: "checkbox",
   top: 126.84,
   left: 420.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Deeded Water Rights", 
   isText: false,
   type: "checkbox",
   top: 291.72,
   left: 103.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "deed at Closing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 359.76,
   left: 337.32,
   width: 165.00,
   height: 12.36
}
,
{
   page: 2,
   name: "Other Rights Relating to Water", 
   isText: false,
   type: "checkbox",
   top: 377.88,
   left: 103.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Well Rights Seller agrees", 
   isText: false,
   type: "checkbox",
   top: 465.96,
   left: 103.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 542.64,
   left: 151.8,
   width: 315.0,
   height: 12.36
}
,
{
   page: 2,
   name: "Water Stock check", 
   isText: false,
   type: "checkbox",
   top: 562.44,
   left: 103.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "exclusions", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 180.655,
   left: 50.4002,
   width: 521.7838,
   height: 95.964
}
,
{
   page: 2,
   name: "deededWaterRights", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 302.401,
   left: 62.1821,
   width: 507.3829,
   height: 57.346
}
,
{
   page: 2,
   name: "otherRightsWater", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 400.584,
   left: 64.8003,
   width: 504.7647,
   height: 64.545
}
,
{
   page: 2,
   name: "waterStock", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 574.693,
   left: 61.5275,
   width: 509.3475,
   height: 50.146
}
,
{
   page: 3,
   name: "Time of Day Deadline_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 77.64,
   left: 387.12,
   width: 183.48,
   height: 11.64
}
,
{
   page: 3,
   name: "DateAlternative Earnest Money Deadline", 
   fontSize: 9,
   type: "date",
   top: 90.36,
   left: 328.56,
   width: 57.24,
   height: 11.40
}
,
{
   page: 3,
   name: "Alternative Earnest Money Deadline_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 90.36,
   left: 387.12,
   width: 183.48,
   height: 11.40
}
,
{
   page: 3,
   name: "DateRecord Title Deadline and Tax Certificate", 
   fontSize: 9,
   type: "date",
   top: 113.64,
   left: 328.56,
   width: 57.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Record Title Deadline and Tax Certificate_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 113.64,
   left: 387.12,
   width: 183.48,
   height: 12.12
}
,
{
   page: 3,
   name: "DateRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 126.84,
   left: 328.56,
   width: 57.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Record Title Objection Deadline_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 126.84,
   left: 387.12,
   width: 183.48,
   height: 12.12
}
,
{
   page: 3,
   name: "DateOffRecord Title Deadline", 
   fontSize: 9,
   type: "date",
   top: 140.04,
   left: 328.56,
   width: 57.24,
   height: 12.12
}
,
{
   page: 3,
   name: "OffRecord Title Deadline_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 140.04,
   left: 387.12,
   width: 183.48,
   height: 12.12
}
,
{
   page: 3,
   name: "DateOffRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 153.24,
   left: 328.56,
   width: 57.24,
   height: 12.12
}
,
{
   page: 3,
   name: "OffRecord Title Objection Deadline_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 153.24,
   left: 387.12,
   width: 183.48,
   height: 12.12
}
,
{
   page: 3,
   name: "DateTitle Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 166.44,
   left: 328.56,
   width: 57.24,
   height: 11.88
}
,
{
   page: 3,
   name: "Title Resolution Deadline_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 166.44,
   left: 387.12,
   width: 183.48,
   height: 11.88
}
,
{
   page: 3,
   name: "DateThird Party Right to PurchaseApprove Deadline", 
   fontSize: 9,
   type: "date",
   top: 179.4,
   left: 328.56,
   width: 57.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Third Party Right to PurchaseApprove Deadline_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 179.4,
   left: 387.12,
   width: 183.48,
   height: 12.12
}
,
{
   page: 3,
   name: "Association Documents", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 202.2,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Association Documents Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 215.4,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Sellers Property Disclose", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 239.64,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "LeadBased Paint Disclosure", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 252.84,
   left: 388.56,
   width: 180.24,
   height: 11.88
}
,
{
   page: 3,
   name: "New Loan Application", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 276.84,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "New Loan Terms", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 290.04,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "New Loan Availability", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 303.24,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Buyers Credit Information", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 316.44,
   left: 388.56,
   width: 180.24,
   height: 11.88
}
,
{
   page: 3,
   name: "Disapproval of Buyers Credit Information", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 329.4,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Existing Loan", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 342.6,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Existing Loan Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 355.8,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Loan Transfer Approval", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 369.0,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Seller or Private Financing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 382.2,
   left: 388.56,
   width: 180.24,
   height: 11.88
}
,
{
   page: 3,
   name: "Appraisal DL", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 406.2,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Appraisal Objection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 419.4,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Appraisal Resolution", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 432.6,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "New ILC or New Survey", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 458.76,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "New ILC or New Survey Objection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 471.96,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "New ILC or New Survey Resolution", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 485.16,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Water Rights Examination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 519.0,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Mineral Rights Examination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 532.2,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Inspection Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 545.4,
   left: 388.56,
   width: 180.24,
   height: 11.88
}
,
{
   page: 3,
   name: "Inspection Objection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 558.36,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Inspection Resolution", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 571.56,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Property Insurance Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 584.76,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Due Diligence Documents Delivery", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 597.96,
   left: 388.56,
   width: 180.24,
   height: 11.88
}
,
{
   page: 3,
   name: "Due Diligence Documents Objection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 610.92,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Due Diligence Documents Resolution", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 624.12,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Environmental Inspection Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 637.32,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "ADA Evaluation Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 650.52,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Conditional Sale", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 663.72,
   left: 388.56,
   width: 180.24,
   height: 11.88
}
,
{
   page: 3,
   name: "LeadBased Paint Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 676.68,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Estoppel Statements", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 689.88,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "Estoppel Statements Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 703.08,
   left: 388.56,
   width: 180.24,
   height: 12.12
}
,
{
   page: 3,
   name: "DateAssociation Documents Deadline", 
   fontSize: 9,
   type: "date",
   top: 202.011,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateAssociation Documents Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 215.211,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateSellers Property Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 239.451,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateLeadBased Paint Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 252.651,
   left: 328.436,
   width: 57.240,
   height: 11.880
}
,
{
   page: 3,
   name: "DateNew Loan Application Deadline", 
   fontSize: 9,
   type: "date",
   top: 276.651,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateNew Loan Terms Deadline", 
   fontSize: 9,
   type: "date",
   top: 289.851,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateNew Loan Availability Deadline", 
   fontSize: 9,
   type: "date",
   top: 303.051,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateBuyers Credit Information Deadline", 
   fontSize: 9,
   type: "date",
   top: 316.251,
   left: 328.436,
   width: 57.240,
   height: 11.880
}
,
{
   page: 3,
   name: "DateDisapproval of Buyers Credit Information Deadline", 
   fontSize: 9,
   type: "date",
   top: 329.211,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateExisting Loan Deadline", 
   fontSize: 9,
   type: "date",
   top: 342.411,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateExisting Loan Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 355.611,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateLoan Transfer Approval Deadline", 
   fontSize: 9,
   type: "date",
   top: 368.811,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateSeller or Private Financing Deadline", 
   fontSize: 9,
   type: "date",
   top: 382.011,
   left: 328.436,
   width: 57.240,
   height: 11.880
}
,
{
   page: 3,
   name: "DateAppraisal Deadline", 
   fontSize: 9,
   type: "date",
   top: 406.011,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateAppraisal Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 419.211,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateAppraisal Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 432.411,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Deadline", 
   fontSize: 9,
   type: "date",
   top: 458.571,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 471.771,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 484.971,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateWater Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 518.811,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateMineral Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 532.011,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateInspection Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 545.211,
   left: 328.436,
   width: 57.240,
   height: 11.880
}
,
{
   page: 3,
   name: "DateInspection Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 558.171,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateInspection Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 571.371,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateProperty Insurance Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 584.571,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Delivery Deadline", 
   fontSize: 9,
   type: "date",
   top: 597.771,
   left: 328.436,
   width: 57.240,
   height: 11.880
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 610.731,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 623.931,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateEnvironmental Inspection Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 637.131,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateADA Evaluation Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 650.331,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateConditional Sale Deadline", 
   fontSize: 9,
   type: "date",
   top: 663.531,
   left: 328.436,
   width: 57.240,
   height: 11.880
}
,
{
   page: 3,
   name: "DateLeadBased Paint Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 676.491,
   left: 328.436,
   width: 57.240,
   height: 12.120
}
,
{
   page: 3,
   name: "DateEstoppel Statements Deadline", 
   fontSize: 9,
   type: "date",
   top: 689.691,
   left: 328.436,
   width: 57.240,
   height: 12.1201
}
,
{
   page: 3,
   name: "DateEstoppel Statements Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 702.8911,
   left: 328.436,
   width: 57.240,
   height: 12.1200
}
,
{
   page: 4,
   name: "DateClosing Date", 
   fontSize: 9,
   type: "date",
   top: 68.442,
   left: 329.418,
   width: 57.240,
   height: 12.120
}
,
{
   page: 4,
   name: "DatePossession Date", 
   fontSize: 9,
   type: "date",
   top: 81.642,
   left: 329.418,
   width: 57.240,
   height: 12.120
}
,
{
   page: 4,
   name: "DateAcceptance Deadline Date", 
   fontSize: 9,
   type: "date",
   top: 107.802,
   left: 329.418,
   width: 57.240,
   height: 12.120
}
,
{
   page: 4,
   name: "Closing Date Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 69.24,
   left: 388.56,
   width: 181.24,
   height: 12.12
}
,
{
   page: 4,
   name: "Possession Date Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 82.44,
   left: 388.56,
   width: 181.24,
   height: 12.12
}
,
{
   page: 4,
   name: "Possession Time Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 95.64,
   left: 388.56,
   width: 181.24,
   height: 11.88
}
,
{
   page: 4,
   name: "Acceptance Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 108.6,
   left: 388.56,
   width: 181.24,
   height: 12.12
}
,
{
   page: 4,
   name: "Acceptance Deadline Time Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 121.8,
   left: 388.56,
   width: 181.24,
   height: 12.12
}
,
{
   page: 4,
   name: "47Row1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 134.88,
   left: 51.96,
   width: 18.84,
   height: 11.04
}
,
{
   page: 4,
   name: " 27Row1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 134.88,
   left: 71.88,
   width: 34.92,
   height: 11.04
}
,
{
   page: 4,
   name: "Acceptance Deadline TimeRow1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 134.88,
   left: 107.88,
   width: 219.48,
   height: 11.04
}
,
{
   page: 4,
   name: "47Row2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 146.88,
   left: 51.96,
   width: 18.84,
   height: 11.04
}
,
{
   page: 4,
   name: " 27Row2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 146.88,
   left: 71.88,
   width: 34.92,
   height: 11.04
}
,
{
   page: 4,
   name: "extra1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 135.03,
   left: 388.299,
   width: 181.240,
   height: 10.121
}
,
{
   page: 4,
   name: "extra2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 147.23,
   left: 388.299,
   width: 181.240,
   height: 10.12
}
,
{
   page: 4,
   name: "Date extra1", 
   fontSize: 9,
   type: "date",
   top: 135.233,
   left: 329.156,
   width: 56.240,
   height: 10.120
}
,
{
   page: 4,
   name: "Date extra2", 
   fontSize: 9,
   type: "date",
   top: 147.433,
   left: 329.156,
   width: 56.240,
   height: 10.120
}
,
{
   page: 4,
   name: "Acceptance Deadline TimeRow2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 146.88,
   left: 107.88,
   width: 219.48,
   height: 11.04
}
,
{
   page: 4,
   name: "DeadlinesWILLNOT", 
   isText: false,
   type: "checkbox",
   top: 429.96,
   left: 150.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "deadlinesWILL", 
   isText: false,
   type: "checkbox",
   top: 429.96,
   left: 107.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Amount", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 506.8,
   left: 314.24,
   width: 95.847,
   height: 11.8
}
,
{
   page: 4,
   name: "Earnest Money", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 521.68,
   left: 425.68,
   width: 89.24,
   height: 11.80
}
,
{
   page: 4,
   name: "New Loan", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 536.56,
   left: 425.68,
   width: 89.24,
   height: 11.80
}
,
{
   page: 4,
   name: "Assumption Balance", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 551.44,
   left: 425.68,
   width: 89.24,
   height: 11.80
}
,
{
   page: 4,
   name: "Private Financing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.32,
   left: 425.68,
   width: 89.24,
   height: 11.80
}
,
{
   page: 4,
   name: "Seller Financing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 581.2,
   left: 425.68,
   width: 89.24,
   height: 11.8
}
,
{
   page: 4,
   name: " 477", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 596.44,
   left: 130.44,
   width: 53.16,
   height: 13.32
}
,
{
   page: 4,
   name: "Seller Financing7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 596.44,
   left: 185.16,
   width: 115.08,
   height: 13.32
}
,
{
   page: 4,
   name: "fill_38", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 596.44,
   left: 314.24,
   width: 95.847,
   height: 13.32
}
,
{
   page: 4,
   name: "fill_39", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 596.44,
   left: 425.68,
   width: 89.24,
   height: 11.80
}
,
{
   page: 4,
   name: " 478", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 613.96,
   left: 130.44,
   width: 53.16,
   height: 13.56
}
,
{
   page: 4,
   name: "Seller Financing8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 613.96,
   left: 185.16,
   width: 115.08,
   height: 13.56
}
,
{
   page: 4,
   name: "fill_40", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 613.96,
   left: 425.68,
   width: 89.24,
   height: 12.80
}
,
{
   page: 4,
   name: "Cash at Closing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 631.6,
   left: 425.68,
   width: 89.24,
   height: 11.8
}
,
{
   page: 4,
   name: "fill_41", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 613.582,
   left: 314.24,
   width: 95.847,
   height: 14.800
}
,
{
   page: 4,
   name: "fill_43", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 646.48,
   left: 310.56,
   width: 102.36,
   height: 11.04
}
,
{
   page: 4,
   name: "fill_44", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 646.48,
   left: 425.68,
   width: 89.24,
   height: 11.80
}
,
{
   page: 4,
   name: "Seller Concession", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 662.24,
   left: 322.56,
   width: 140.04,
   height: 12.28
}
,
{
   page: 5,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 54.24,
   left: 394.56,
   width: 169.92,
   height: 12.36
}
,
{
   page: 5,
   name: "Earnest Money Holder", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 67.44,
   left: 189,
   width: 270.12,
   height: 12.36
}
,
{
   page: 5,
   name: "that are immediately at Closing", 
   isText: false,
   type: "checkbox",
   top: 498.84,
   left: 413.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Does", 
   isText: false,
   type: "checkbox",
   top: 498.84,
   left: 458.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Loan Limitations Buyer", 
   isText: false,
   type: "checkbox",
   top: 638.76,
   left: 150.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "If either or both of the FHA", 
   isText: false,
   type: "checkbox",
   top: 638.76,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "FHA", 
   isText: false,
   type: "checkbox",
   top: 638.76,
   left: 197.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "VA", 
   isText: false,
   type: "checkbox",
   top: 638.76,
   left: 238.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Bond", 
   isText: false,
   type: "checkbox",
   top: 638.76,
   left: 288.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 636.24,
   left: 334.56,
   width: 225.12,
   height: 12.36
}
,
{
   page: 5,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 663.84,
   left: 457.32,
   width: 75.00,
   height: 12.36
}
,
{
   page: 6,
   name: "per", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 67.44,
   left: 290.4,
   width: 69.96,
   height: 12.84
}
,
{
   page: 6,
   name: "including", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 67.44,
   left: 380.64,
   width: 135.00,
   height: 12.84
}
,
{
   page: 6,
   name: "per annum", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 81.36,
   left: 244.8,
   width: 50.04,
   height: 12.84
}
,
{
   page: 6,
   name: "principal and interest", 
   isText: false,
   type: "checkbox",
   top: 111.96,
   left: 166.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "indicated", 
   isText: false,
   type: "checkbox",
   top: 111.96,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Mortgage Insurance Premium", 
   isText: false,
   type: "checkbox",
   top: 111.96,
   left: 316.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Real Estate Taxes", 
   isText: false,
   type: "checkbox",
   top: 125.64,
   left: 86.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 122.64,
   left: 99.48,
   width: 394.92,
   height: 12.84
}
,
{
   page: 6,
   name: "At the time of assumption the new interest rate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 136.56,
   left: 300.36,
   width: 69.96,
   height: 12.84
}
,
{
   page: 6,
   name: "per annum and the new payment will not exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 150.24,
   left: 129.6,
   width: 45.0,
   height: 12.84
}
,
{
   page: 6,
   name: "per_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 150.24,
   left: 391.8,
   width: 69.96,
   height: 12.84
}
,
{
   page: 6,
   name: "principal and interest plus", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 164.16,
   left: 66,
   width: 140.04,
   height: 12.84
}
,
{
   page: 6,
   name: "Terminate under  241 on or before Closing Date", 
   isText: false,
   type: "checkbox",
   top: 222.36,
   left: 111.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "for release", 
   isText: false,
   type: "checkbox",
   top: 222.36,
   left: 156.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "or if any other terms", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.76,
   left: 168.96,
   width: 70.08,
   height: 12.84
}
,
{
   page: 6,
   name: "Will Not be released", 
   isText: false,
   type: "checkbox",
   top: 236.04,
   left: 289.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "at Closing", 
   isText: false,
   type: "checkbox",
   top: 236.04,
   left: 511.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "in an amount not to exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 260.64,
   left: 66,
   width: 175.08,
   height: 12.84
}
,
{
   page: 6,
   name: "undefined_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 260.64,
   left: 364.08,
   width: 69.96,
   height: 12.84
}
,
{
   page: 6,
   name: "471", 
   isText: false,
   type: "checkbox",
   top: 401.64,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Seller Financing If Buyer", 
   isText: false,
   type: "checkbox",
   top: 401.64,
   left: 116.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "days", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 398.64,
   left: 496.08,
   width: 50.04,
   height: 12.84
}
,
{
   page: 8,
   name: "Endorsement lender", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 54.164,
   left: 415.32,
   width: 75.12,
   height: 12.436
}
,
{
   page: 8,
   name: "company lenders agent or all three", 
   isText: false,
   type: "checkbox",
   top: 297,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "Seller The cost of the", 
   isText: false,
   type: "checkbox",
   top: 297,
   left: 114.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "furnish the owners title", 
   isText: false,
   type: "checkbox",
   top: 435,
   left: 99,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "policy to be issued and", 
   isText: false,
   type: "checkbox",
   top: 472.92,
   left: 197.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "to furnish the owners title", 
   isText: false,
   type: "checkbox",
   top: 502.2,
   left: 99,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Coverage OEC If the Title", 
   isText: false,
   type: "checkbox",
   top: 556.92,
   left: 358.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Will Not contain Owners Extended", 
   isText: false,
   type: "checkbox",
   top: 556.92,
   left: 400.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Regardless of whether the", 
   isText: false,
   type: "checkbox",
   top: 636.84,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Seller_2", 
   isText: false,
   type: "checkbox",
   top: 636.84,
   left: 116.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "OneHalf by Buyer and OneHalf by Seller", 
   isText: false,
   type: "checkbox",
   top: 636.84,
   left: 165.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Other_2", 
   isText: false,
   type: "checkbox",
   top: 636.84,
   left: 371.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "undefined_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 634.364,
   left: 415.92,
   width: 144.96,
   height: 12.316
}
,
{
   page: 10,
   name: "metropolitan districts that affect the", 
   isText: false,
   type: "checkbox",
   top: 693.96,
   left: 268.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "Buyer for the Property listing any special taxing or", 
   isText: false,
   type: "checkbox",
   top: 693.96,
   left: 317.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "Text91", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 668.294,
   left: 65.4548,
   width: 495.6012,
   height: 12.836
}
,
{
   page: 12,
   name: "New Improvement Location Certificate New ILC or", 
   isText: false,
   type: "checkbox",
   top: 176.28,
   left: 85.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "New Survey in the form of", 
   isText: false,
   type: "checkbox",
   top: 191.16,
   left: 85.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "undefined_9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 188.88,
   left: 213.36,
   width: 349.92,
   height: 12.12
}
,
{
   page: 12,
   name: "Seller_3", 
   isText: false,
   type: "checkbox",
   top: 225.24,
   left: 271.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Buyer will order the New ILC or New Survey The New", 
   isText: false,
   type: "checkbox",
   top: 225.24,
   left: 320.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "undefined_10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 279.6,
   left: 195.96,
   width: 364.92,
   height: 12.12
}
,
{
   page: 12,
   name: "Seller_4", 
   isText: false,
   type: "checkbox",
   top: 281.88,
   left: 86.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Buyer or", 
   isText: false,
   type: "checkbox",
   top: 281.88,
   left: 135.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "opinion of title if an Abstract of Title and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 310.08,
   left: 237.36,
   width: 325.08,
   height: 12.12
}
,
{
   page: 13,
   name: "Text92", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 622.475,
   left: 72.0003,
   width: 499.5287,
   height: 128.0373
}
,
{
   page: 14,
   name: "All contracts relating to the operation", 
   isText: false,
   type: "checkbox",
   top: 239.88,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Property tax bills for the last", 
   isText: false,
   type: "checkbox",
   top: 257.16,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Asbuilt construction plans to the", 
   isText: false,
   type: "checkbox",
   top: 274.44,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "years", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 254.64,
   left: 272.04,
   width: 105.00,
   height: 12.36
}
,
{
   page: 14,
   name: "A list of all Inclusions to be conveyed to Buyer", 
   isText: false,
   type: "checkbox",
   top: 318.12,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Operating statements for the past", 
   isText: false,
   type: "checkbox",
   top: 335.16,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "A rent roll accurate and correct to the date of this Contract", 
   isText: false,
   type: "checkbox",
   top: 352.44,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "A schedule of any tenant", 
   isText: false,
   type: "checkbox",
   top: 369.72,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "years_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 332.64,
   left: 290.04,
   width: 100.08,
   height: 12.36
}
,
{
   page: 14,
   name: "All insurance policies", 
   isText: false,
   type: "checkbox",
   top: 400.2,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "the past", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 410.88,
   left: 140.76,
   width: 110.04,
   height: 12.36
}
,
{
   page: 14,
   name: "Soils reports surveys and engineering", 
   isText: false,
   type: "checkbox",
   top: 430.68,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Any and all existing documentation and", 
   isText: false,
   type: "checkbox",
   top: 461.16,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Any Americans with Disabilities Act", 
   isText: false,
   type: "checkbox",
   top: 531.24,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "All permits licenses and other", 
   isText: false,
   type: "checkbox",
   top: 561.72,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Other_3", 
   isText: false,
   type: "checkbox",
   top: 605.16,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Text93", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 615.93,
   left: 84.4367,
   width: 479.2383,
   height: 86.8003
}
,
{
   page: 15,
   name: "Due Diligence  Environmental Buyer has the right to obtain environmental inspections of the Property including", 
   isText: false,
   type: "checkbox",
   top: 334.2,
   left: 242.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Site Assessment compliant with the most current version of the applicable ASTM E1527 standard practices for", 
   isText: false,
   type: "checkbox",
   top: 334.2,
   left: 290.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "undefined_11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 358.32,
   left: 231,
   width: 334.92,
   height: 12.36
}
,
{
   page: 15,
   name: "Environmental Site Assessments andor", 
   isText: false,
   type: "checkbox",
   top: 374.04,
   left: 141.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Buyer Environmental Inspection", 
   isText: false,
   type: "checkbox",
   top: 374.04,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "days Extended", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 402,
   left: 357.6,
   width: 95.04,
   height: 12.36
}
,
{
   page: 15,
   name: "extends beyond the Closing Date the Closing Date will be extended a like period of time In such event", 
   isText: false,
   type: "checkbox",
   top: 444.12,
   left: 69,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Notwithstanding Buyers right to obtain additional environmental inspections of the Property in this  1064 Buyer", 
   isText: false,
   type: "checkbox",
   top: 444.12,
   left: 117.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Source of Potable Water Residential Land and Residential Improvements Only", 
   isText: false,
   type: "checkbox",
   top: 700.92,
   left: 98.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Addendum disclosing the source of potable water for the Property", 
   isText: false,
   type: "checkbox",
   top: 700.92,
   left: 143.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Does Not acknowledge receipt of a copy of Sellers Property Disclosure or Source of Water", 
   isText: false,
   type: "checkbox",
   top: 714.12,
   left: 340.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Does_2", 
   isText: false,
   type: "checkbox",
   top: 714.12,
   left: 463.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Does Not", 
   isText: false,
   type: "checkbox",
   top: 714.12,
   left: 508.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Text94", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 614.275,
   left: 64.8003,
   width: 485.1287,
   height: 12.837
}
,
{
   page: 17,
   name: "Are", 
   isText: false,
   type: "checkbox",
   top: 480.84,
   left: 408.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Are Not executed with", 
   isText: false,
   type: "checkbox",
   top: 480.84,
   left: 448.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "undefined_12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 546,
   left: 63.12,
   width: 309.96,
   height: 11.64
}
,
{
   page: 17,
   name: "special warranty deed", 
   isText: false,
   type: "checkbox",
   top: 663.48,
   left: 59.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "general warranty deed", 
   isText: false,
   type: "checkbox",
   top: 663.48,
   left: 225.0,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "bargain and sale deed", 
   isText: false,
   type: "checkbox",
   top: 663.48,
   left: 345.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "quit claim deed", 
   isText: false,
   type: "checkbox",
   top: 663.48,
   left: 462.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "personal representatives deed", 
   isText: false,
   type: "checkbox",
   top: 677.16,
   left: 59.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "deed Seller provided another", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 675.48,
   left: 240.84,
   width: 200.04,
   height: 11.64
}
,
{
   page: 17,
   name: "Check Box75", 
   isText: false,
   type: "checkbox",
   top: 677.16,
   left: 225.0,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_2", 
   isText: false,
   type: "checkbox",
   top: 205.56,
   left: 189,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_5", 
   isText: false,
   type: "checkbox",
   top: 205.56,
   left: 239.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_2", 
   isText: false,
   type: "checkbox",
   top: 205.56,
   left: 287.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Other_4", 
   isText: false,
   type: "checkbox",
   top: 220.44,
   left: 225,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 218.64,
   left: 268.92,
   width: 295.08,
   height: 11.64
}
,
{
   page: 18,
   name: "Buyer_3", 
   isText: false,
   type: "checkbox",
   top: 317.64,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_6", 
   isText: false,
   type: "checkbox",
   top: 317.64,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_3", 
   isText: false,
   type: "checkbox",
   top: 317.64,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA", 
   isText: false,
   type: "checkbox",
   top: 317.64,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_4", 
   isText: false,
   type: "checkbox",
   top: 363.72,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_7", 
   isText: false,
   type: "checkbox",
   top: 363.72,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_4", 
   isText: false,
   type: "checkbox",
   top: 363.72,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_2", 
   isText: false,
   type: "checkbox",
   top: 363.72,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_5", 
   isText: false,
   type: "checkbox",
   top: 395.88,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_8", 
   isText: false,
   type: "checkbox",
   top: 395.88,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_5", 
   isText: false,
   type: "checkbox",
   top: 395.88,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_3", 
   isText: false,
   type: "checkbox",
   top: 395.88,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_6", 
   isText: false,
   type: "checkbox",
   top: 427.8,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_9", 
   isText: false,
   type: "checkbox",
   top: 427.8,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_6", 
   isText: false,
   type: "checkbox",
   top: 427.8,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_4", 
   isText: false,
   type: "checkbox",
   top: 427.8,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_7", 
   isText: false,
   type: "checkbox",
   top: 459.96,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_10", 
   isText: false,
   type: "checkbox",
   top: 459.96,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_7", 
   isText: false,
   type: "checkbox",
   top: 459.96,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_5", 
   isText: false,
   type: "checkbox",
   top: 459.96,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_8", 
   isText: false,
   type: "checkbox",
   top: 507,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_11", 
   isText: false,
   type: "checkbox",
   top: 507,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_8", 
   isText: false,
   type: "checkbox",
   top: 507,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_6", 
   isText: false,
   type: "checkbox",
   top: 507,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 535.92,
   left: 73.44,
   width: 95.04,
   height: 11.64
}
,
{
   page: 18,
   name: "Water DistrictMunicipality", 
   isText: false,
   type: "checkbox",
   top: 554.52,
   left: 94.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Augmentation Membership", 
   isText: false,
   type: "checkbox",
   top: 571.08,
   left: 94.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Water Stock info", 
   isText: false,
   type: "checkbox",
   top: 554.52,
   left: 243,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_15", 
   isText: false,
   type: "checkbox",
   top: 571.08,
   left: 243.0,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 569.28,
   left: 418.44,
   width: 144.96,
   height: 11.64
}
,
{
   page: 18,
   name: "Buyer_9", 
   isText: false,
   type: "checkbox",
   top: 601.56,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_12", 
   isText: false,
   type: "checkbox",
   top: 601.56,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_9", 
   isText: false,
   type: "checkbox",
   top: 601.56,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_7", 
   isText: false,
   type: "checkbox",
   top: 601.56,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_10", 
   isText: false,
   type: "checkbox",
   top: 633,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_13", 
   isText: false,
   type: "checkbox",
   top: 633,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_10", 
   isText: false,
   type: "checkbox",
   top: 633,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_8", 
   isText: false,
   type: "checkbox",
   top: 633,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "IS a", 
   isText: false,
   type: "checkbox",
   top: 692.76,
   left: 529.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Check Box76", 
   isText: false,
   type: "checkbox",
   top: 571.08,
   left: 404.584,
   width: 10.080,
   height: 10.735
}
,
{
   page: 19,
   name: "Taxes for the Calendar Year Immediately Preceding Closing", 
   isText: false,
   type: "checkbox",
   top: 222.6,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "Most Recent Mill Levy and Most Recent Assessed Valuation adjusted by any applicable qualifying seniors property", 
   isText: false,
   type: "checkbox",
   top: 236.76,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "Other_5", 
   isText: false,
   type: "checkbox",
   top: 264.84,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "undefined_17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 263.04,
   left: 110.52,
   width: 445.08,
   height: 11.64
}
,
{
   page: 19,
   name: "Rents Actually Received", 
   isText: false,
   type: "checkbox",
   top: 288.84,
   left: 201,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "Accrued At Closing Seller will transfer or credit to", 
   isText: false,
   type: "checkbox",
   top: 288.84,
   left: 326.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "undefined_18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 347.52,
   left: 66,
   width: 485.04,
   height: 11.64
}
,
{
   page: 19,
   name: "Buyer_11", 
   isText: false,
   type: "checkbox",
   top: 447.96,
   left: 125.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "Seller Except however any special assessment by the Association for improvements that", 
   isText: false,
   type: "checkbox",
   top: 447.96,
   left: 175.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "undefined_19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 496.8,
   left: 66,
   width: 500.04,
   height: 11.64
}
,
{
   page: 19,
   name: "per day", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 588.72,
   left: 435.96,
   width: 85.08,
   height: 11.64
}
,
{
   page: 20,
   name: "Buyer will be paid", 
   isText: false,
   type: "checkbox",
   top: 662.04,
   left: 103.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "electronicNotice", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 277.2,
   left: 66,
   width: 360,
   height: 12.0
}
,
{
   page: 22,
   name: "of the Purchase Price or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 545.4,
   left: 96.72,
   width: 35.04,
   height: 11.96
}
,
{
   page: 22,
   name: "by Seller Buyers", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 545.4,
   left: 250.56,
   width: 75.00,
   height: 11.96
}
,
{
   page: 22,
   name: "beneficiary under this provision only The amount paid by Seller under this provision is in addition to any other amounts", 
   isText: false,
   type: "checkbox",
   top: 548.52,
   left: 78.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "of the Purchase Price or_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 588.4,
   left: 96.72,
   width: 35.04,
   height: 11.96
}
,
{
   page: 22,
   name: "by Buyer pursuant", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 588.4,
   left: 250.56,
   width: 75.00,
   height: 11.96
}
,
{
   page: 22,
   name: "and Buyers brokerage firm This amount may be modified between Buyer and Buyers brokerage firm outside of this", 
   isText: false,
   type: "checkbox",
   top: 590.52,
   left: 78.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "of the Purchase Price or_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 630.4,
   left: 96.72,
   width: 35.04,
   height: 11.96
}
,
{
   page: 22,
   name: "by a separate agreement", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 630.4,
   left: 250.56,
   width: 75.00,
   height: 11.96
}
,
{
   page: 22,
   name: "and Sellers brokerage firm", 
   isText: false,
   type: "checkbox",
   top: 632.52,
   left: 78.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "of this Contract", 
   isText: false,
   type: "checkbox",
   top: 648.12,
   left: 107.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "additionalProvisions", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 79.2,
   left: 37.3092,
   width: 544.6928,
   height: 485.421
}
,
{
   page: 23,
   name: "docsArePart", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 588.439,
   left: 51.7093,
   width: 525.7107,
   height: 59.309
}
,
{
   page: 23,
   name: "DocsNotPart", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 691.858,
   left: 50.4002,
   width: 527.0198,
   height: 57.3452
}
,
{
   page: 24,
   name: "Buyers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 88.32,
   left: 110.16,
   width: 180.12,
   height: 12.12
}
,
{
   page: 24,
   name: "Buyers Name 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 88.32,
   left: 377.76,
   width: 180.00,
   height: 12.12
}
,
{
   page: 24,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 148.2,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 160.92,
   left: 116.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 24,
   name: "Address 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 148.2,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "Address 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 160.92,
   left: 386.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 24,
   name: "Phone No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 173.4,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "Phone No 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 173.4,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "Phone No 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 186.12,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "Fax No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 186.12,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 198.84,
   left: 116.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 24,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 198.84,
   left: 386.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 24,
   name: "Buyers Name 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 223.32,
   left: 117.12,
   width: 171.12,
   height: 12.12
}
,
{
   page: 24,
   name: "Buyers Name 4 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 223.32,
   left: 387.12,
   width: 166.56,
   height: 12.12
}
,
{
   page: 24,
   name: "Sellers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 313.44,
   left: 109.08,
   width: 180.12,
   height: 12.12
}
,
{
   page: 24,
   name: "Sellers Name 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 313.44,
   left: 379.68,
   width: 177.00,
   height: 12.12
}
,
{
   page: 24,
   name: "1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 373.32,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 386.04,
   left: 116.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 24,
   name: "Address 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 373.32,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "Address 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 386.04,
   left: 386.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 24,
   name: "Phone No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 398.52,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "Phone No 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 398.52,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "Phone No 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 411.24,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "Fax No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 411.24,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 24,
   name: "Email Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 423.96,
   left: 116.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 24,
   name: "Email Address_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 423.96,
   left: 386.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 24,
   name: "Sellers Name 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 448.44,
   left: 118.44,
   width: 166.56,
   height: 12.12
}
,
{
   page: 24,
   name: "Sellers Name 4 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 448.44,
   left: 386.16,
   width: 166.56,
   height: 12.12
}
,
{
   page: 24,
   name: "Does_3", 
   isText: false,
   type: "checkbox",
   top: 633.96,
   left: 90.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the", 
   isText: false,
   type: "checkbox",
   top: 633.96,
   left: 132.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Buyers Agent", 
   isText: false,
   type: "checkbox",
   top: 704.76,
   left: 201.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "TransactionBroker in this transaction", 
   isText: false,
   type: "checkbox",
   top: 704.76,
   left: 287.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
   isText: false,
   type: "checkbox",
   top: 722.28,
   left: 56.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Brokerage Firms Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 126.12,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "Brokerage Firms License 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 139.56,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "Brokerage Firms License 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 152.76,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "Brokerage Firms License 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 165.96,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "Date brokerSigned", 
   fontSize: 9,
   type: "date",
   top: 192.36,
   left: 356.28,
   width: 124.92,
   height: 12.48
}
,
{
   page: 25,
   name: "Date cobrokerSigned", 
   fontSize: 9,
   type: "date",
   top: 226.8,
   left: 356.76,
   width: 126.00,
   height: 12.48
}
,
{
   page: 25,
   name: "1_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 252.12,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "2_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 265.32,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 278.52,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 291.72,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "Does_4", 
   isText: false,
   type: "checkbox",
   top: 340.92,
   left: 92.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the_2", 
   isText: false,
   type: "checkbox",
   top: 340.92,
   left: 139.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Sellers Agent", 
   isText: false,
   type: "checkbox",
   top: 417.48,
   left: 203.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "TransactionBroker in this transaction_2", 
   isText: false,
   type: "checkbox",
   top: 417.48,
   left: 289.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Customer Broker has no brokerage relationship with Seller See  A for Brokers brokerage relationship with Buyer", 
   isText: false,
   type: "checkbox",
   top: 436.68,
   left: 56.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Seller_14", 
   isText: false,
   type: "checkbox",
   top: 477,
   left: 89.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Buyer_12", 
   isText: false,
   type: "checkbox",
   top: 477,
   left: 138.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Other_6", 
   isText: false,
   type: "checkbox",
   top: 477,
   left: 188.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "undefined_21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 474.36,
   left: 235.68,
   width: 320.04,
   height: 12.48
}
,
{
   page: 25,
   name: "Brokerage Firms Name_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 550.92,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "Brokerage Firms License 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 564.12,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "Brokerage Firms License 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 577.32,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "Brokerage Firms License 3_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 590.52,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "Date_12", 
   fontSize: 9,
   type: "date",
   top: 616.92,
   left: 356.28,
   width: 124.92,
   height: 12.48
}
,
{
   page: 25,
   name: "Date_13", 
   fontSize: 9,
   type: "date",
   top: 651.6,
   left: 356.76,
   width: 126.00,
   height: 12.48
}
,
{
   page: 25,
   name: "1_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 676.68,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "2_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 689.88,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "3_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 703.08,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "4_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 716.28,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "signature brokerSigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 189.583,
   left: 180.655,
   width: 168.000,
   height: 15.000
}
,
{
   page: 25,
   name: "signature cobrokerSigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 224.274,
   left: 181.31,
   width: 168.00,
   height: 15.000
}
,
{
   page: 25,
   name: "signature sellerAgentSigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 614.421,
   left: 181.964,
   width: 169.000,
   height: 15.000
}
,
{
   page: 25,
   name: "signature cobrokerSellerSideSigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 649.421,
   left: 181.31,
   width: 169.00,
   height: 15.000
}
] }
