export function residentialAgreementtoBuyorSell() {
return [   //2024 Release 2024-06-06 17:26:43
{
   page: 0,
   name: "address1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 90.61,
   left: 34.7341,
   width: 408.4929,
   height: 11.542
}
,
{
   page: 0,
   name: "date p1", 
   fontSize: 9,
   type: "date",
   top: 90.61,
   left: 447.141,
   width: 125.239,
   height: 13.506
}
,
{
   page: 0,
   name: "Lisng Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 140.8,
   left: 41.4,
   width: 258.84,
   height: 13.506
}
,
{
   page: 0,
   name: "Selling Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 140.8,
   left: 311.16,
   width: 258.84,
   height: 13.506
}
,
{
   page: 0,
   name: "Sellers Designated Agent Name  License Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 177.52,
   left: 40.4,
   width: 197.312,
   height: 10.888
}
,
{
   page: 0,
   name: "ListingAgentLicense", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 176.074,
   left: 240.91,
   width: 64.909,
   height: 14.799
}
,
{
   page: 0,
   name: "Buyers Designated Agent Name License Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 176.865,
   left: 311.16,
   width: 195.348,
   height: 12.197
}
,
{
   page: 0,
   name: "BuyerAgentLicense", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 176.074,
   left: 509.437,
   width: 64.908,
   height: 13.490
}
,
{
   page: 0,
   name: "Check Box2", 
   isText: false,
   type: "checkbox",
   top: 204.756,
   left: 242.715,
   width: 11.455,
   height: 13.506
}
,
{
   page: 0,
   name: "Brokerage Name Listing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 229.655,
   left: 41.4,
   width: 197.312,
   height: 12.851
}
,
{
   page: 0,
   name: "ListingBrokerageLicense", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.706,
   left: 240.91,
   width: 64.909,
   height: 14.800
}
,
{
   page: 0,
   name: "Brokerage Name Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 229.0,
   left: 311.16,
   width: 197.312,
   height: 12.197
}
,
{
   page: 0,
   name: "BuyerBrokerageLicense", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.706,
   left: 509.437,
   width: 64.908,
   height: 14.800
}
,
{
   page: 0,
   name: "Agent Phone Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 265.72,
   left: 41.4,
   width: 124.44,
   height: 10.888
}
,
{
   page: 0,
   name: "Brokerage Phone Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 265.72,
   left: 170.4,
   width: 129.36,
   height: 11.542
}
,
{
   page: 0,
   name: "Agent Phone Number_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 265.72,
   left: 311.16,
   width: 124.44,
   height: 13.506
}
,
{
   page: 0,
   name: "Brokerage Phone Number_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 265.72,
   left: 440.16,
   width: 129.36,
   height: 13.506
}
,
{
   page: 0,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 301.32,
   left: 41.4,
   width: 258.84,
   height: 13.506
}
,
{
   page: 0,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 301.32,
   left: 311.16,
   width: 258.84,
   height: 13.506
}
,
{
   page: 0,
   name: "Name of Designated Agent Receiving Agreement", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 338.575,
   left: 40.7455,
   width: 527.7595,
   height: 13.505
}
,
{
   page: 0,
   name: "Day", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 356.32,
   left: 328.32,
   width: 39.60,
   height: 13.506
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 356.32,
   left: 391.56,
   width: 39.84,
   height: 13.506
}
,
{
   page: 0,
   name: "Time", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 356.32,
   left: 458.4,
   width: 34.8,
   height: 13.506
}
,
{
   page: 0,
   name: "Check Box300am", 
   isText: false,
   type: "checkbox",
   top: 359.345,
   left: 505.963,
   width: 10.800,
   height: 10.800
}
,
{
   page: 0,
   name: "Check Box310pm", 
   isText: false,
   type: "checkbox",
   top: 359.345,
   left: 543.272,
   width: 10.800,
   height: 10.800
}
,
{
   page: 0,
   name: "Check Box401", 
   isText: false,
   type: "checkbox",
   top: 384.545,
   left: 148.709,
   width: 10.800,
   height: 10.800
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 381.273,
   left: 202.32,
   width: 104.64,
   height: 12.851
}
,
{
   page: 0,
   name: "Check Box400hand delivery", 
   isText: false,
   type: "checkbox",
   top: 384.545,
   left: 310.254,
   width: 10.800,
   height: 10.800
}
,
{
   page: 0,
   name: "Check Box41other", 
   isText: false,
   type: "checkbox",
   top: 384.545,
   left: 384.709,
   width: 10.800,
   height: 10.800
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 381.0,
   left: 420.84,
   width: 149.28,
   height: 13.506
}
,
{
   page: 0,
   name: "Day_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 422.88,
   left: 328.56,
   width: 39.60,
   height: 13.506
}
,
{
   page: 0,
   name: "Date_2", 
   fontSize: 9,
   type: "date",
   top: 422.88,
   left: 391.8,
   width: 39.84,
   height: 13.506
}
,
{
   page: 0,
   name: "Time_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 422.88,
   left: 458.52,
   width: 35.04,
   height: 13.506
}
,
{
   page: 0,
   name: "Check Box301am", 
   isText: false,
   type: "checkbox",
   top: 425.8,
   left: 505.963,
   width: 10.800,
   height: 10.8
}
,
{
   page: 0,
   name: "Check Box311pm", 
   isText: false,
   type: "checkbox",
   top: 425.8,
   left: 543.272,
   width: 10.800,
   height: 10.8
}
,
{
   page: 0,
   name: "Comments", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 448.36,
   left: 87.6,
   width: 478.08,
   height: 13.506
}
,
{
   page: 0,
   name: "Check Box50", 
   isText: false,
   type: "checkbox",
   top: 498.872,
   left: 41.0182,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 0,
   name: "Check Box51", 
   isText: false,
   type: "checkbox",
   top: 546.891,
   left: 41.0182,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 1,
   name: "Municipal Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 89.28,
   left: 169.56,
   width: 368.52,
   height: 11.52
}
,
{
   page: 1,
   name: "City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 101.64,
   left: 104.16,
   width: 139.44,
   height: 10.211
}
,
{
   page: 1,
   name: "Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 101.52,
   left: 263.04,
   width: 74.64,
   height: 10.865
}
,
{
   page: 1,
   name: "Parish", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 101.52,
   left: 369.6,
   width: 127.833,
   height: 11.52
}
,
{
   page: 1,
   name: "Legal Descripon0", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 113.987,
   left: 163.886,
   width: 373.759,
   height: 10.865
}
,
{
   page: 1,
   name: "grounds measuring approximately", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 163.051,
   left: 288.949,
   width: 119.520,
   height: 11.520
}
,
{
   page: 1,
   name: "33exclusions", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 468.76,
   left: 85.7455,
   width: 459.6655,
   height: 75.011
}
,
{
   page: 1,
   name: "40", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 565.32,
   left: 86.4,
   width: 238.92,
   height: 11.52
}
,
{
   page: 1,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 565.32,
   left: 333.72,
   width: 29.76,
   height: 11.52
}
,
{
   page: 1,
   name: "fill_23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 626.4,
   left: 309.0,
   width: 233.88,
   height: 11.4
}
,
{
   page: 1,
   name: "46", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 644.4,
   left: 86.4,
   width: 288.72,
   height: 11.52
}
,
{
   page: 1,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 644.4,
   left: 384.56,
   width: 74.76,
   height: 11.52
}
,
{
   page: 1,
   name: "address2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 36.927,
   left: 71.4251,
   width: 364.4639,
   height: 11.520
}
,
{
   page: 1,
   name: "date p2", 
   fontSize: 9,
   type: "date",
   top: 36.927,
   left: 439.803,
   width: 103.224,
   height: 11.520
}
,
{
   page: 1,
   name: "Legal Descripon1", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 9,
   top: 126.217,
   left: 86.541,
   width: 458.959,
   height: 37.047
}
,
{
   page: 1,
   name: "221 inclusions", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 321.874,
   left: 85.5908,
   width: 461.5202,
   height: 99.616
}
,
{
   page: 2,
   name: "date BUYER on0", 
   fontSize: 9,
   type: "date",
   top: 83.655,
   left: 131.109,
   width: 158.995,
   height: 11.252
}
,
{
   page: 2,
   name: "for the loan the sum of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 303.36,
   left: 200.4,
   width: 233.88,
   height: 11.40
}
,
{
   page: 2,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 303.36,
   left: 453.24,
   width: 69.60,
   height: 11.40
}
,
{
   page: 2,
   name: "65", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 315.6,
   left: 86.4,
   width: 69.6,
   height: 11.4
}
,
{
   page: 2,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 315.6,
   left: 161.52,
   width: 24.84,
   height: 11.4
}
,
{
   page: 2,
   name: "66", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 327.84,
   left: 86.4,
   width: 89.52,
   height: 11.28
}
,
{
   page: 2,
   name: "undefined_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 327.84,
   left: 182.64,
   width: 24.84,
   height: 11.28
}
,
{
   page: 2,
   name: "67", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 339.96,
   left: 86.4,
   width: 59.64,
   height: 11.40
}
,
{
   page: 2,
   name: "undefined_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 339.96,
   left: 156.48,
   width: 29.88,
   height: 11.40
}
,
{
   page: 2,
   name: "undefined_9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 451.2,
   left: 144.72,
   width: 234.00,
   height: 11.4
}
,
{
   page: 2,
   name: "The BUYER agrees to pay discount points not to exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 475.68,
   left: 345.72,
   width: 194.16,
   height: 11.40
}
,
{
   page: 2,
   name: "undefined_10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 487.92,
   left: 89.4,
   width: 34.8,
   height: 11.40
}
,
{
   page: 2,
   name: "fill_130", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 487.746,
   left: 335.6,
   width: 204.492,
   height: 11.741
}
,
{
   page: 2,
   name: "79", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 512.28,
   left: 86.4,
   width: 453.12,
   height: 11.40
}
,
{
   page: 2,
   name: "80", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 524.52,
   left: 86.4,
   width: 453.12,
   height: 11.40
}
,
{
   page: 2,
   name: "the loan approval process within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 634.44,
   left: 219.72,
   width: 94.44,
   height: 11.28
}
,
{
   page: 2,
   name: "undefined_11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 634.44,
   left: 324.36,
   width: 39.72,
   height: 11.28
}
,
{
   page: 2,
   name: "date p3", 
   fontSize: 9,
   type: "date",
   top: 36.236,
   left: 439.803,
   width: 103.224,
   height: 12.197
}
,
{
   page: 2,
   name: "address3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 36.236,
   left: 71.4251,
   width: 364.4639,
   height: 12.197
}
,
{
   page: 2,
   name: "Act of Sale Year", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 83.655,
   left: 305.373,
   width: 15.103,
   height: 11.252
}
,
{
   page: 2,
   name: "fill_131", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 500.465,
   left: 86.5459,
   width: 453.5461,
   height: 11.741
}
,
{
   page: 2,
   name: "Check Box600", 
   isText: false,
   type: "checkbox",
   top: 189.164,
   left: 84.8454,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 2,
   name: "Check Box601", 
   isText: false,
   type: "checkbox",
   top: 276.127,
   left: 84.8454,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 2,
   name: "Check Box602", 
   isText: false,
   type: "checkbox",
   top: 220.091,
   left: 84.8454,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 2,
   name: "Check Box603", 
   isText: false,
   type: "checkbox",
   top: 293.054,
   left: 84.8454,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 2,
   name: "Check Box700", 
   isText: false,
   type: "checkbox",
   top: 382.691,
   left: 106.909,
   width: 10.691,
   height: 10.691
}
,
{
   page: 2,
   name: "Check Box710", 
   isText: false,
   type: "checkbox",
   top: 400.8,
   left: 106.909,
   width: 10.691,
   height: 10.691
}
,
{
   page: 2,
   name: "Check Box720", 
   isText: false,
   type: "checkbox",
   top: 418.691,
   left: 106.909,
   width: 10.691,
   height: 10.909
}
,
{
   page: 2,
   name: "Check Box730", 
   isText: false,
   type: "checkbox",
   top: 435.71,
   left: 106.909,
   width: 10.691,
   height: 10.909
}
,
{
   page: 2,
   name: "Check Box740", 
   isText: false,
   type: "checkbox",
   top: 452.728,
   left: 106.909,
   width: 10.691,
   height: 10.909
}
,
{
   page: 2,
   name: "Check Box711", 
   isText: false,
   type: "checkbox",
   top: 400.8,
   left: 287.036,
   width: 10.691,
   height: 10.691
}
,
{
   page: 2,
   name: "Check Box721", 
   isText: false,
   type: "checkbox",
   top: 418.691,
   left: 287.036,
   width: 10.691,
   height: 10.909
}
,
{
   page: 2,
   name: "Check Box731", 
   isText: false,
   type: "checkbox",
   top: 435.71,
   left: 287.036,
   width: 10.691,
   height: 10.909
}
,
{
   page: 2,
   name: "Check Box701", 
   isText: false,
   type: "checkbox",
   top: 382.691,
   left: 287.036,
   width: 10.691,
   height: 10.691
}
,
{
   page: 3,
   name: "SELLER with a copy of the appraisal within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 365.52,
   left: 258.12,
   width: 114.60,
   height: 11.40
}
,
{
   page: 3,
   name: "undefined_12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 365.52,
   left: 382.92,
   width: 44.76,
   height: 11.40
}
,
{
   page: 3,
   name: "119", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 389.88,
   left: 86.4,
   width: 109.44,
   height: 11.40
}
,
{
   page: 3,
   name: "undefined_13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 389.88,
   left: 205.8,
   width: 44.76,
   height: 11.40
}
,
{
   page: 3,
   name: "127", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 487.56,
   left: 86.4,
   width: 99.48,
   height: 11.40
}
,
{
   page: 3,
   name: "undefined_14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 487.56,
   left: 196.08,
   width: 49.68,
   height: 11.40
}
,
{
   page: 3,
   name: "or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 487.56,
   left: 261.6,
   width: 109.56,
   height: 11.40
}
,
{
   page: 3,
   name: "undefined_15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 487.56,
   left: 376.2,
   width: 34.8,
   height: 11.40
}
,
{
   page: 3,
   name: "Cash", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 517.2,
   left: 119.04,
   width: 109.56,
   height: 11.4
}
,
{
   page: 3,
   name: "undefined_16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 517.2,
   left: 238.44,
   width: 59.76,
   height: 11.4
}
,
{
   page: 3,
   name: "Text2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 517.439,
   left: 383.565,
   width: 87.818,
   height: 10.872
}
,
{
   page: 3,
   name: "undefined_17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 517.2,
   left: 482.16,
   width: 54.72,
   height: 11.4
}
,
{
   page: 3,
   name: "Check", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.96,
   left: 123.12,
   width: 104.52,
   height: 11.40
}
,
{
   page: 3,
   name: "undefined_20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.96,
   left: 237.72,
   width: 59.76,
   height: 11.40
}
,
{
   page: 3,
   name: "Electronic Transfer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.96,
   left: 397.32,
   width: 74.76,
   height: 11.40
}
,
{
   page: 3,
   name: "undefined_21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.96,
   left: 482.16,
   width: 54.72,
   height: 11.40
}
,
{
   page: 3,
   name: "Other_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 578.04,
   left: 370.56,
   width: 169.32,
   height: 11.40
}
,
{
   page: 3,
   name: "date p4", 
   fontSize: 9,
   type: "date",
   top: 34.854,
   left: 439.803,
   width: 103.224,
   height: 12.852
}
,
{
   page: 3,
   name: "address4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 34.854,
   left: 71.4251,
   width: 364.4639,
   height: 12.852
}
,
{
   page: 3,
   name: "Check Box80", 
   isText: false,
   type: "checkbox",
   top: 318.6,
   left: 137.618,
   width: 10.800,
   height: 10.8
}
,
{
   page: 3,
   name: "Check Box81", 
   isText: false,
   type: "checkbox",
   top: 318.6,
   left: 328.745,
   width: 10.800,
   height: 10.8
}
,
{
   page: 3,
   name: "Check Box900", 
   isText: false,
   type: "checkbox",
   top: 518.4,
   left: 84.9272,
   width: 10.8000,
   height: 10.8
}
,
{
   page: 3,
   name: "Check Box910", 
   isText: false,
   type: "checkbox",
   top: 537.382,
   left: 84.9272,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 3,
   name: "Check Box920", 
   isText: false,
   type: "checkbox",
   top: 554.727,
   left: 84.9272,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 3,
   name: "Check Box911", 
   isText: false,
   type: "checkbox",
   top: 537.382,
   left: 309.055,
   width: 10.799,
   height: 10.800
}
,
{
   page: 3,
   name: "Check Box901", 
   isText: false,
   type: "checkbox",
   top: 518.4,
   left: 309.055,
   width: 10.799,
   height: 10.8
}
,
{
   page: 3,
   name: "Check Box100", 
   isText: false,
   type: "checkbox",
   top: 580.091,
   left: 199.309,
   width: 10.800,
   height: 10.800
}
,
{
   page: 3,
   name: "Check Box101", 
   isText: false,
   type: "checkbox",
   top: 580.091,
   left: 266.436,
   width: 10.800,
   height: 10.800
}
,
{
   page: 3,
   name: "Check Box102", 
   isText: false,
   type: "checkbox",
   top: 580.091,
   left: 333.564,
   width: 10.799,
   height: 10.800
}
,
{
   page: 4,
   name: "186", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 652.56,
   left: 86.4,
   width: 194.16,
   height: 11.52
}
,
{
   page: 4,
   name: "undefined_23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 652.56,
   left: 291.24,
   width: 54.72,
   height: 11.52
}
,
{
   page: 4,
   name: "date p5", 
   fontSize: 9,
   type: "date",
   top: 34.236,
   left: 439.803,
   width: 103.224,
   height: 14.161
}
,
{
   page: 4,
   name: "address5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 34.236,
   left: 71.4251,
   width: 364.4639,
   height: 14.161
}
,
{
   page: 5,
   name: "date p6", 
   fontSize: 9,
   type: "date",
   top: 35.545,
   left: 439.148,
   width: 103.224,
   height: 12.852
}
,
{
   page: 5,
   name: "address6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 35.545,
   left: 70.7706,
   width: 364.4634,
   height: 12.852
}
,
{
   page: 6,
   name: "There isare", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 332.52,
   left: 150,
   width: 69.72,
   height: 11.52
}
,
{
   page: 6,
   name: "undefined_24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 332.52,
   left: 230.64,
   width: 34.80,
   height: 11.52
}
,
{
   page: 6,
   name: "There isare_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 382.56,
   left: 153.36,
   width: 74.64,
   height: 11.52
}
,
{
   page: 6,
   name: "undefined_25", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 382.56,
   left: 240.12,
   width: 34.80,
   height: 11.52
}
,
{
   page: 6,
   name: "272", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 522,
   left: 86.4,
   width: 273.84,
   height: 11.52
}
,
{
   page: 6,
   name: "undefined_26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 522,
   left: 373.44,
   width: 64.68,
   height: 11.52
}
,
{
   page: 6,
   name: "Home Service Warranty will be ordered by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 559.92,
   left: 258.48,
   width: 278.88,
   height: 11.52
}
,
{
   page: 6,
   name: "date p7", 
   fontSize: 9,
   type: "date",
   top: 35.2,
   left: 439.803,
   width: 103.224,
   height: 12.851
}
,
{
   page: 6,
   name: "address7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 35.2,
   left: 71.4251,
   width: 364.4639,
   height: 12.851
}
,
{
   page: 6,
   name: "Check Box110", 
   isText: false,
   type: "checkbox",
   top: 334.473,
   left: 84.9272,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box111", 
   isText: false,
   type: "checkbox",
   top: 384.6,
   left: 84.9272,
   width: 10.8000,
   height: 10.8
}
,
{
   page: 6,
   name: "Check Box112", 
   isText: false,
   type: "checkbox",
   top: 435.727,
   left: 84.9272,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box113", 
   isText: false,
   type: "checkbox",
   top: 460.854,
   left: 84.9272,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box120", 
   isText: false,
   type: "checkbox",
   top: 510.436,
   left: 213.6,
   width: 10.8,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box121", 
   isText: false,
   type: "checkbox",
   top: 510.436,
   left: 249.618,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box13", 
   isText: false,
   type: "checkbox",
   top: 523.745,
   left: 511.527,
   width: 10.800,
   height: 10.800
}
,
{
   page: 6,
   name: "Check Box14", 
   isText: false,
   type: "checkbox",
   top: 537.6,
   left: 120.545,
   width: 10.800,
   height: 10.8
}
,
{
   page: 7,
   name: "do extend the date for passing the Act of Sale to a date not more than", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 330.72,
   left: 395.64,
   width: 94.68,
   height: 11.40
}
,
{
   page: 7,
   name: "undefined_27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 330.72,
   left: 502.08,
   width: 34.80,
   height: 11.40
}
,
{
   page: 7,
   name: "date p8", 
   fontSize: 9,
   type: "date",
   top: 36.2,
   left: 439.803,
   width: 103.224,
   height: 12.197
}
,
{
   page: 7,
   name: "address8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 36.2,
   left: 71.4251,
   width: 364.4639,
   height: 12.197
}
,
{
   page: 7,
   name: "Check Box150", 
   isText: false,
   type: "checkbox",
   top: 89.455,
   left: 86.1819,
   width: 10.6909,
   height: 10.691
}
,
{
   page: 7,
   name: "Check Box152", 
   isText: false,
   type: "checkbox",
   top: 131.709,
   left: 86.1819,
   width: 10.6910,
   height: 10.691
}
,
{
   page: 7,
   name: "Check Box151", 
   isText: false,
   type: "checkbox",
   top: 222.582,
   left: 86.1819,
   width: 10.6910,
   height: 10.691
}
,
{
   page: 8,
   name: "3600 addtional", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 441.719,
   left: 86.5908,
   width: 453.0112,
   height: 134.796
}
,
{
   page: 8,
   name: "date p9", 
   fontSize: 9,
   type: "date",
   top: 35.2,
   left: 438.494,
   width: 103.224,
   height: 12.851
}
,
{
   page: 8,
   name: "address9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 11,
   top: 35.2,
   left: 70.116,
   width: 364.464,
   height: 12.851
}
,
{
   page: 9,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 244.32,
   left: 406.92,
   width: 129.36,
   height: 11.52
}
,
{
   page: 9,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 263.76,
   left: 406.92,
   width: 129.36,
   height: 11.52
}
,
{
   page: 9,
   name: "3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 283.32,
   left: 406.92,
   width: 129.36,
   height: 11.52
}
,
{
   page: 9,
   name: "date p10", 
   fontSize: 9,
   type: "date",
   top: 36.509,
   left: 439.803,
   width: 103.224,
   height: 11.520
}
,
{
   page: 9,
   name: "Check Box1600", 
   isText: false,
   type: "checkbox",
   top: 226.473,
   left: 84.4363,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box1610", 
   isText: false,
   type: "checkbox",
   top: 246.436,
   left: 84.4363,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box1620", 
   isText: false,
   type: "checkbox",
   top: 266.236,
   left: 84.4363,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box1630", 
   isText: false,
   type: "checkbox",
   top: 285.218,
   left: 84.4363,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box1601", 
   isText: false,
   type: "checkbox",
   top: 226.473,
   left: 393.564,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box1611", 
   isText: false,
   type: "checkbox",
   top: 246.436,
   left: 393.564,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box1621", 
   isText: false,
   type: "checkbox",
   top: 266.236,
   left: 393.564,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "Check Box1631", 
   isText: false,
   type: "checkbox",
   top: 285.218,
   left: 393.564,
   width: 10.800,
   height: 10.800
}
,
{
   page: 9,
   name: "address10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 36.0,
   left: 71.3458,
   width: 360.1102,
   height: 11.527
}
,
{
   page: 10,
   name: "date expiration", 
   fontSize: 9,
   type: "date",
   top: 90.509,
   left: 253.963,
   width: 85.091,
   height: 13.047
}
,
{
   page: 10,
   name: "at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 90.575,
   left: 380.28,
   width: 64.68,
   height: 13.047
}
,
{
   page: 10,
   name: "430", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 177.215,
   left: 86.4,
   width: 219.0,
   height: 13.047
}
,
{
   page: 10,
   name: "Print BuyersSellers Full Name First Middle Last", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 177.215,
   left: 314.52,
   width: 224.16,
   height: 13.047
}
,
{
   page: 10,
   name: "436", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 251.735,
   left: 86.4,
   width: 219.0,
   height: 13.047
}
,
{
   page: 10,
   name: "Print BuyersSellers Full Name First Middle Last_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 251.735,
   left: 314.52,
   width: 224.16,
   height: 13.047
}
,
{
   page: 10,
   name: "undefined_30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 289.535,
   left: 300.96,
   width: 238.92,
   height: 13.047
}
,
{
   page: 10,
   name: "441", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 314.015,
   left: 86.4,
   width: 453.12,
   height: 13.047
}
,
{
   page: 10,
   name: "450", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.655,
   left: 86.4,
   width: 219.0,
   height: 13.047
}
,
{
   page: 10,
   name: "Print BuyersSellers Full Name First Middle Last_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.655,
   left: 314.52,
   width: 224.16,
   height: 13.047
}
,
{
   page: 10,
   name: "456", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 502.175,
   left: 86.4,
   width: 219.0,
   height: 13.047
}
,
{
   page: 10,
   name: "Print BuyersSellers Full Name First Middle Last_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 502.175,
   left: 314.52,
   width: 224.16,
   height: 13.047
}
,
{
   page: 10,
   name: "undefined_33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 540.095,
   left: 300.96,
   width: 238.92,
   height: 13.047
}
,
{
   page: 10,
   name: "date p11", 
   fontSize: 9,
   type: "date",
   top: 35.073,
   left: 439.803,
   width: 103.224,
   height: 13.047
}
,
{
   page: 10,
   name: "address11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 35.073,
   left: 71.4251,
   width: 364.4639,
   height: 13.047
}
,
{
   page: 10,
   name: "fill_111year", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 91.636,
   left: 354.763,
   width: 13.963,
   height: 11.782
}
,
{
   page: 10,
   name: "Check Box170", 
   isText: false,
   type: "checkbox",
   top: 92.509,
   left: 445.746,
   width: 10.909,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box171", 
   isText: false,
   type: "checkbox",
   top: 92.509,
   left: 473.091,
   width: 10.910,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box172", 
   isText: false,
   type: "checkbox",
   top: 92.509,
   left: 499.437,
   width: 10.909,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1800", 
   isText: false,
   type: "checkbox",
   top: 155.127,
   left: 85.5274,
   width: 10.6909,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1810", 
   isText: false,
   type: "checkbox",
   top: 155.127,
   left: 132.655,
   width: 10.691,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1820", 
   isText: false,
   type: "checkbox",
   top: 155.127,
   left: 249.818,
   width: 10.691,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1830", 
   isText: false,
   type: "checkbox",
   top: 155.127,
   left: 314.837,
   width: 10.691,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1840", 
   isText: false,
   type: "checkbox",
   top: 155.127,
   left: 361.091,
   width: 10.691,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1850", 
   isText: false,
   type: "checkbox",
   top: 155.127,
   left: 480.219,
   width: 10.691,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1801", 
   isText: false,
   type: "checkbox",
   top: 229.255,
   left: 85.5274,
   width: 10.6909,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1811", 
   isText: false,
   type: "checkbox",
   top: 229.255,
   left: 132.655,
   width: 10.691,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1821", 
   isText: false,
   type: "checkbox",
   top: 229.255,
   left: 249.818,
   width: 10.691,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1831", 
   isText: false,
   type: "checkbox",
   top: 229.255,
   left: 314.837,
   width: 10.691,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1841", 
   isText: false,
   type: "checkbox",
   top: 229.255,
   left: 361.091,
   width: 10.691,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box1851", 
   isText: false,
   type: "checkbox",
   top: 229.255,
   left: 480.219,
   width: 10.691,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box190", 
   isText: false,
   type: "checkbox",
   top: 292.036,
   left: 215.018,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box191", 
   isText: false,
   type: "checkbox",
   top: 292.036,
   left: 251.036,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box200", 
   isText: false,
   type: "checkbox",
   top: 330.328,
   left: 153.818,
   width: 10.909,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box201", 
   isText: false,
   type: "checkbox",
   top: 330.328,
   left: 209.164,
   width: 10.909,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box202", 
   isText: false,
   type: "checkbox",
   top: 330.328,
   left: 181.509,
   width: 10.909,
   height: 10.691
}
,
{
   page: 10,
   name: "Check Box210", 
   isText: false,
   type: "checkbox",
   top: 368.509,
   left: 137.237,
   width: 10.690,
   height: 10.910
}
,
{
   page: 10,
   name: "Check Box211", 
   isText: false,
   type: "checkbox",
   top: 368.509,
   left: 318.364,
   width: 10.691,
   height: 10.910
}
,
{
   page: 10,
   name: "Check Box212", 
   isText: false,
   type: "checkbox",
   top: 368.509,
   left: 192.491,
   width: 10.691,
   height: 10.910
}
,
{
   page: 10,
   name: "Check Box22000", 
   isText: false,
   type: "checkbox",
   top: 405.818,
   left: 85.4181,
   width: 10.8000,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box221000", 
   isText: false,
   type: "checkbox",
   top: 405.818,
   left: 132.545,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box221100", 
   isText: false,
   type: "checkbox",
   top: 405.818,
   left: 248.4,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box221110", 
   isText: false,
   type: "checkbox",
   top: 405.818,
   left: 478.8,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box221010", 
   isText: false,
   type: "checkbox",
   top: 405.818,
   left: 360.0,
   width: 10.8,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box22010", 
   isText: false,
   type: "checkbox",
   top: 405.818,
   left: 313.854,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box221001", 
   isText: false,
   type: "checkbox",
   top: 480.272,
   left: 132.545,
   width: 10.800,
   height: 10.801
}
,
{
   page: 10,
   name: "Check Box221101", 
   isText: false,
   type: "checkbox",
   top: 480.272,
   left: 248.4,
   width: 10.8,
   height: 10.801
}
,
{
   page: 10,
   name: "Check Box221111", 
   isText: false,
   type: "checkbox",
   top: 480.272,
   left: 478.8,
   width: 10.8,
   height: 10.801
}
,
{
   page: 10,
   name: "Check Box221011", 
   isText: false,
   type: "checkbox",
   top: 480.272,
   left: 360.0,
   width: 10.8,
   height: 10.801
}
,
{
   page: 10,
   name: "Check Box22011", 
   isText: false,
   type: "checkbox",
   top: 480.272,
   left: 313.854,
   width: 10.800,
   height: 10.801
}
,
{
   page: 10,
   name: "Check Box22001", 
   isText: false,
   type: "checkbox",
   top: 480.272,
   left: 85.4181,
   width: 10.8000,
   height: 10.801
}
,
{
   page: 10,
   name: "Check Box230", 
   isText: false,
   type: "checkbox",
   top: 542.372,
   left: 214.691,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box231", 
   isText: false,
   type: "checkbox",
   top: 542.372,
   left: 250.654,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box240", 
   isText: false,
   type: "checkbox",
   top: 581.018,
   left: 154.364,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box241", 
   isText: false,
   type: "checkbox",
   top: 581.018,
   left: 181.382,
   width: 10.800,
   height: 10.800
}
,
{
   page: 10,
   name: "Check Box242", 
   isText: false,
   type: "checkbox",
   top: 581.018,
   left: 208.4,
   width: 10.8,
   height: 10.800
}
] }
