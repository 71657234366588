export function sellersConditionRemovalDemandNotification() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 131.04,
   width: 462.96,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 42", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 119.749,
   left: 405.0,
   width: 170.5,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 39", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 131.249,
   left: 120.24,
   width: 242.28,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 41", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 144.749,
   left: 36.5,
   width: 229.9,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 36", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 185.32,
   left: 104.84,
   width: 61.48,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 37", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 185.32,
   left: 210.96,
   width: 120.96,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 38", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 185.32,
   left: 352.8,
   width: 27.145,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 32", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 270.229,
   left: 36.5,
   width: 178.06,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 270.229,
   left: 214.97,
   width: 88.03,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 270.229,
   left: 309.0,
   width: 178.06,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 35", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 270.229,
   left: 487.47,
   width: 88.03,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 53", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 308.311,
   left: 36.5,
   width: 266.5,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 47", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 308.311,
   left: 309.0,
   width: 266.5,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 345.393,
   left: 36.5,
   width: 178.06,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 345.393,
   left: 214.97,
   width: 88.03,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 52", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 383.476,
   left: 36.5,
   width: 266.5,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 420.558,
   left: 36.5,
   width: 178.06,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 420.558,
   left: 214.97,
   width: 88.03,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 51", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 458.64,
   left: 36.5,
   width: 266.5,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 43", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 500.467,
   left: 152.48,
   width: 59.48,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 44", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 500.467,
   left: 276.44,
   width: 27.145,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 45", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 500.467,
   left: 336.24,
   width: 61.79,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 46", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 500.467,
   left: 418.6,
   width: 27.145,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 525.759,
   left: 36.5,
   width: 178.06,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 525.759,
   left: 214.97,
   width: 88.03,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 525.759,
   left: 309.0,
   width: 178.06,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 525.759,
   left: 487.47,
   width: 88.03,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 49", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 563.038,
   left: 36.5,
   width: 266.5,
   height: 18.000
}
,
{
   page: 0,
   name: "Text Field 50", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 563.038,
   left: 309.0,
   width: 266.5,
   height: 18.000
}
] }
