import React from "react";
import { Card, Image } from "semantic-ui-react";
import { convertAddressFull } from "../../../app/common/util/util";
import { useNavigate } from "react-router-dom";

export default function TransactionCardList({ transactions }) {
  const navigate = useNavigate();

  function handleClick(transaction) {
    navigate(`/transactions/${transaction.id}/overview`);
  }

  return (
    <Card.Group stackable doubling itemsPerRow={4}>
      {transactions.map((transaction) => (
        <Card
          key={transaction.id}
          className="tiny top margin"
          onClick={() => handleClick(transaction)}
        >
          <Card.Content>
            {transaction.pic ? (
              <Image
                src={transaction.pic}
                rounded
                floated="right"
                size="small"
                wrapped
                style={{ maxWidth: "120px" }}
              />
            ) : (
              <Image
                bordered
                size="small"
                wrapped
                className="background-white"
                src="/assets/placeholder-house.png"
                floated="right"
                style={{ maxWidth: "120px" }}
              />
            )}

            <Card.Header
              style={{ fontSize: "1.1em" }}
              data-test="transaction-card"
            >
              {transaction.client?.firstName} {transaction.client?.lastName}{" "}
              <br />
              {transaction.clientSecondary?.firstName}{" "}
              {transaction.clientSecondary?.lastName}
              <p style={{ fontSize: "0.8em", fontWeight: "normal" }}>
                {transaction.agentRepresents}
                <br />
                {transaction?.address?.street && (
                  <>{convertAddressFull(transaction.address)}</>
                )}
              </p>
            </Card.Header>
            <Card.Description></Card.Description>
            <Card.Meta></Card.Meta>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  );
}
