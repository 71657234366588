import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Header,
  Image,
  Segment,
  Divider,
} from "semantic-ui-react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { extractPartyInfoFromUrl } from "../partySlice";
import PartyLoginEmailForm from "./PartyLoginEmailForm";

export default function PartyLoginPage() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const agentName = searchParams.get("agentName") || "";
  const partyFirstName = searchParams.get("partyFirstName") || "";
  const partyLastName = searchParams.get("partyLastName") || "";
  const partyIsUser = searchParams.get("partyIsUser") || false;
  const isAssistant = searchParams.get("assistant") || false;
  const partyFullName = partyFirstName + " " + partyLastName;

  useEffect(() => {
    dispatch(
      extractPartyInfoFromUrl({
        agentName: agentName,
        partyFirstName: partyFirstName,
        partyLastName: partyLastName,
        partyIsUser: partyIsUser,
        isAssistant: isAssistant,
      })
    );
  });

  return (
    <>
      <div
        className="hero-background-img"
        style={{ backgroundImage: "url('/assets/img-background-blue.jpeg')" }}
      >
        <Container style={{ height: "100vh" }}>
          <Grid>
            <Grid.Row>
              <div className="medium top margin" style={{ width: "100%" }}>
                <Image
                  src="/assets/logo-transactioner-white-small.png"
                  style={{ float: "left" }}
                />
              </div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                floated="left"
                mobile={16}
                tablet={7}
                computer={7}
                className="mobile hidden tablet hidden"
              >
                <Image
                  src="/assets/img-laptop-login.png"
                  fluid
                  alt="laptop contract"
                  style={{ maxWidth: "540px" }}
                />
              </Grid.Column>
              <Grid.Column
                className="large top margin"
                floated="left"
                mobile={16}
                tablet={16}
                computer={8}
                textAlign="center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Segment style={{ padding: "2.2em" }}>
                  <Image
                    centered
                    src="/assets/logo-transactioner-color-small.png"
                    width="70"
                    alt="laptop contract"
                    style={{ marginTop: "2px" }}
                  />
                  <p className="text darkgrey tiny top margin medium bottom margin">
                    Modern Real Estate Transaction Management
                  </p>
                  <Divider />
                  <Header
                    color="blue"
                    as="h1"
                    textAlign="center"
                    className="text-big medium top margin"
                  >
                    Welcome {partyFullName}
                  </Header>
                  <div style={{ color: "dimgray" }}>
                    <p className="text-medium small bottom margin">
                      {agentName ? agentName : "Your real estate agent"} is
                      sharing documents with you.{" "}
                    </p>
                    {isAssistant ? (
                      <>
                        <p className="text-medium medium bottom margin">
                          Please log in using the email address where you
                          received the link to this page.
                        </p>
                        <p className="text-medium medium bottom margin">
                          Note: If you're an assistant, please use the email of
                          the person you're assisting.
                        </p>
                      </>
                    ) : (
                      <p className="text-medium medium bottom margin">
                        Please log in using the email address where you received
                        the link to this page.
                      </p>
                    )}
                  </div>
                  <PartyLoginEmailForm />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </>
  );
}
