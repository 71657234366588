export function landlordFloodplainandFloodNotice() {
return [   //2024 Release 2024-02-19 07:21:23
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 97.244,
   left: 238.68,
   width: 320.52,
   height: 12.436
}
,
{
   page: 0,
   name: "A Landlord", 
   isText: false,
   type: "checkbox",
   top: 129.24,
   left: 112.92,
   width: 11.52,
   height: 9.12
}
,
{
   page: 0,
   name: "is", 
   isText: false,
   type: "checkbox",
   top: 129.24,
   left: 139.2,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "B Landlord", 
   isText: false,
   type: "checkbox",
   top: 222.72,
   left: 112.68,
   width: 11.52,
   height: 9.12
}
,
{
   page: 0,
   name: "is_2", 
   isText: false,
   type: "checkbox",
   top: 222.72,
   left: 138.6,
   width: 11.52,
   height: 9.12
}
] }
