export function addendumEnvironmentalAssessment() {
return [   //2024 Release 2024-02-19 07:28:15
{
   page: 0,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 178.08,
   left: 39.72,
   width: 536.40,
   height: 12.24
}
,
{
   page: 0,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 241.859,
   left: 57.7733,
   width: 11.8001,
   height: 11.641
}
,
{
   page: 0,
   name: "Check Box2", 
   isText: false,
   type: "checkbox",
   top: 280.213,
   left: 58.098,
   width: 10.8209,
   height: 10.167
}
,
{
   page: 0,
   name: "Check Box3", 
   isText: false,
   type: "checkbox",
   top: 344.352,
   left: 58.4226,
   width: 10.1664,
   height: 10.166
}
,
{
   page: 0,
   name: "furnishing Seller a copy of any report noted above that adversely affects the use of the Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 405.696,
   left: 88.7712,
   width: 30.4798,
   height: 13.133
}
] }
