import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon, Segment, Header, Image } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  convertPhoneToLink,
  partyIsAgent,
} from "../../../app/common/util/util";
import { openModal } from "../../../app/common/modals/modalSlice";
import { format } from "date-fns";
import DocTransAwaitingSignatureList from "../../docs/docTrans/docTransAwaitingSignature/DocTransAwaitingSignatureList";
import { updateTransactionInDb } from "../../../app/firestore/firestoreService";
import { TaskTransCalendarWeekly } from "../../tasks/taskTrans/taskTransCalendar/TaskTransCalenderWeekly";

export default function TransactionOverview() {
  const dispatch = useDispatch();
  const { transaction, parties, transClients } = useSelector(
    (state) => state.transaction
  );
  const { docsTransAwaitingSignature } = useSelector((state) => state.doc);

  if (!transaction || !transClients)
    return <LoadingComponent content="Loading transaction..." />;

  function handleEditTransaction() {
    dispatch(
      openModal({
        modalType: "TransactionEditForm",
        modalProps: { transaction: transaction },
      })
    );
  }

  function handleEditPhoto() {
    dispatch(
      openModal({
        modalType: "TransactionPhotoUpload",
      })
    );
  }

  function deletePic() {
    updateTransactionInDb(transaction.id, {
      pic: null,
    });
  }

  return (
    <div className="secondary-page-wrapper">
      {transaction && (
        <>
          <Grid stackable className="zero bottom margin">
            {transClients.map((client) => (
              <Grid.Column width={8} key={client.role}>
                <span className="inline text-large bold large right margin">
                  {client.firstName} {client.lastName}
                </span>{" "}
                {client.email && (
                  <div className="inline large right margin">
                    <span className="mini left margin">
                      <a href={`mailto:${client.email}`}>
                        <Icon name="mail" /> {client.email}
                      </a>
                    </span>
                  </div>
                )}
                {client.phone && (
                  <div className="inline">
                    <span className="mini left margin">
                      <a href={`tel:${convertPhoneToLink(client.phone)}`}>
                        <Icon name="phone" /> {client.phone}
                      </a>
                    </span>
                  </div>
                )}
              </Grid.Column>
            ))}
            <Grid.Column
              width={16}
              className="tiny top padding large bottom padding"
            >
              <Segment>
                <Grid stackable>
                  <Grid.Row className="zero bottom padding">
                    <Grid.Column width={16}>
                      <div className="flex">
                        <h3 className="small right margin tiny bottom margin">
                          Transaction Details
                        </h3>
                        <Icon
                          name="edit"
                          color="blue"
                          link
                          onClick={() => handleEditTransaction()}
                          style={{ paddingTop: "3px" }}
                          data-test="transaction-edit"
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="zero top padding mini bottom margin">
                    <Grid.Column width={6}>
                      {transaction.pic ? (
                        <Image src={transaction.pic} size="medium" rounded />
                      ) : (
                        <Image
                          src="/assets/placeholder-house.png"
                          size="medium"
                          rounded
                        />
                      )}
                      <div>
                        <span className="text blue bold">Edit Image:</span>
                        <Icon
                          name="edit"
                          color="blue"
                          // className="float-right"
                          link
                          onClick={() => handleEditPhoto()}
                          style={{ paddingBottom: "10px", marginLeft: "10px" }}
                        />
                        <Icon
                          name="trash"
                          // color="red"
                          // className="float-right"
                          link
                          onClick={() => deletePic()}
                          style={{
                            marginTop: "15px",
                            paddingBottom: "3px",
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column width={5} style={{ wordBreak: "break-word" }}>
                      <Header className="text-medium text lightblack">
                        {transaction.address && (
                          <span>
                            {transaction.address?.street &&
                              transaction.address?.street + ","}{" "}
                            {transaction.address?.unit &&
                              transaction.address?.unit + ","}{" "}
                            <br />
                            {transaction.address?.city &&
                              transaction.address?.city + ","}{" "}
                            {transaction.address?.state &&
                              transaction.address?.state}{" "}
                            {transaction.address?.zipcode &&
                              transaction.address?.zipcode}
                          </span>
                        )}
                      </Header>
                      {transaction?.mlsNumbers?.[0] && (
                        <p className="text muted tiny bottom margin">
                          MLS #: {transaction.mlsNumbers[0]}
                        </p>
                      )}
                      {transaction?.mlsNumbers?.[1] && (
                        <p className="text muted tiny bottom margin">
                          MLS # (secondary): {transaction.mlsNumbers[1]}
                        </p>
                      )}
                      {transaction?.propertyDetails?.county && (
                        <p className="text muted tiny bottom margin">
                          County: {transaction.propertyDetails.county}
                        </p>
                      )}
                      {transaction?.propertyDetails?.legalDescription && (
                        <p className="text muted tiny bottom margin">
                          Legal Description:{" "}
                          {transaction.propertyDetails.legalDescription}
                        </p>
                      )}
                      {transaction.salesPrice && (
                        <p className="text muted tiny bottom margin">
                          Sales Price: ${transaction.salesPrice}
                        </p>
                      )}
                      {transaction.contractDateTime && (
                        <p className="text muted tiny bottom margin">
                          Contract Date:{" "}
                          {format(transaction.contractDateTime, "MM/dd/yyyy")}
                        </p>
                      )}
                      {transaction.closingDateTime && (
                        <p className="text muted tiny bottom margin">
                          Closing Date:{" "}
                          {format(transaction.closingDateTime, "MM/dd/yyyy")}
                        </p>
                      )}
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Header className="text-medium text lightblack">
                        Status: {transaction.status}
                      </Header>
                      <Header
                        className="text-medium small vertical margin  text lightblack"
                        style={{ wordBreak: "break-word" }}
                        data-test="transaction-name"
                      >
                        Name: {transaction.title}
                      </Header>
                      {parties
                        .filter((party) => partyIsAgent(party.role))
                        .map((party, index) => (
                          <div key={party.role}>
                            <Header className="inline tiny right margin bold zero bottom margin zero top margin text-medium  text lightblack">
                              {transaction.agentRepresents === "Buyer"
                                ? "Listing"
                                : "Buyer"}{" "}
                              Agent: {party.firstName} {party.middleName}{" "}
                              {party.lastName}
                            </Header>
                            <div className="small top margin">
                              {party.email && (
                                <p>
                                  <a href={`mailto:${party.email}`}>
                                    <Icon name="mail" /> {party.email}
                                  </a>
                                </p>
                              )}
                              {party.phone && (
                                <p>
                                  <a
                                    href={`tel:${convertPhoneToLink(
                                      party.phone
                                    )}`}
                                  >
                                    <Icon name="phone" /> {party.phone}
                                  </a>
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>
          <div className="large bottom margin">
            <h3 className="large bottom margin">Calendar</h3>
            <div className="small horizontal padding">
              <TaskTransCalendarWeekly />
            </div>
          </div>
          <Grid>
            <Grid.Column width={16}>
              <h3 className="mini bottom margin">
                Documents Awaiting Signature
              </h3>
              {docsTransAwaitingSignature.length > 0 ? (
                <DocTransAwaitingSignatureList
                  docs={docsTransAwaitingSignature}
                />
              ) : (
                <p className="tiny top margin">
                  There are no documents awaiting signature
                </p>
              )}
            </Grid.Column>
          </Grid>
        </>
      )}
    </div>
  );
}
