import React from "react";
import { Table, Popup, Icon, Label } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import DocTransActiveListItem from "./DocTransActiveListItem";
import {
  sortDocsTransActive,
  sortDocsTransArchived,
  sortDocsTransShared,
} from "../../docSlice";

export default function DocTransActiveList({
  docs,
  column,
  direction,
  shared = false,
  archived = false,
}) {
  const dispatch = useDispatch();

  function handleSort(column) {
    if (shared) {
      dispatch(sortDocsTransShared(column));
    } else if (archived) {
      dispatch(sortDocsTransArchived(column));
    } else {
      dispatch(sortDocsTransActive(column));
    }
  }

  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell
              sorted={column === "title" ? direction : null}
              onClick={() => handleSort("title")}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              sorted={column === "signingStatus" ? direction : null}
              onClick={() => handleSort("signingStatus")}
            >
              Signing Complete{" "}
              <Popup
                size="large"
                trigger={
                  <Icon
                    name="info"
                    color="blue"
                    circular
                    inverted
                    size="small"
                    style={{ marginLeft: "3px", marginBottom: "3px" }}
                  />
                }
              >
                <p className="bold text blue mini bottom margin">
                  Signing Complete
                </p>
                <p className="bold text blue mini bottom margin"></p>
                <p className="text-dark text-normal mini bottom margin">
                  You can mark a document as 'Signing Complete' by using the
                  dropdown on the right side of the corresponding row below.
                </p>
              </Popup>
            </Table.HeaderCell>
            {shared && <Table.HeaderCell>Shared By</Table.HeaderCell>}
            <Table.HeaderCell style={{ cursor: "default" }}>
              <>
                Sharing With{" "}
                <Popup
                  flowing
                  size="large"
                  position="bottom center"
                  trigger={
                    <Icon
                      name="info"
                      color="blue"
                      circular
                      inverted
                      size="small"
                      style={{ marginLeft: "3px", marginBottom: "3px" }}
                    />
                  }
                >
                  <p className="bold text blue small bottom margin">
                    Sharing Document with Parties
                  </p>
                  <p className="text-dark text-normal small bottom margin">
                    Note: Only people in your 'Parties' page that have an email
                    address are shown here.
                    <br />
                    Sharing with a party will also share with any assistant and
                    TC listed for that party in the Parties tab.
                  </p>
                  <p className="bold text-normal text blue mini bottom margin">
                    Color Key:
                  </p>
                  <span className="text-dark text-normal mini bottom margin">
                    <Label className="tiny right margin" size="tiny">
                      Not Shared
                    </Label>{" "}
                    Party can not access document
                  </span>
                  <br />
                  <span className="text-dark text-normal mini bottom margin">
                    <Label
                      className="tiny right margin"
                      size="tiny"
                      color="blue"
                    >
                      Shared
                    </Label>{" "}
                    Party can view document and sees your changes after sharing
                  </span>
                  <br />
                  <span className="text-dark text-normal mini bottom margin">
                    <Label
                      className="tiny right margin"
                      size="tiny"
                      color="purple"
                    >
                      Sent
                    </Label>{" "}
                    Party was sent a <u>copy</u> of this document and they will
                    not see any changes you make to this document.
                    <br />
                    You can send a new copy to them with any changes by
                    double-clicking the purple button.
                  </span>
                  <br />
                  <span className="text-dark text-normal mini bottom margin">
                    <Label
                      className="tiny right margin"
                      size="tiny"
                      color="orange"
                    >
                      Awaiting Signature
                    </Label>{" "}
                    Party can view and sign document.
                  </span>
                  <br />
                  <span className="text-dark text-normal mini bottom margin">
                    <Label
                      className="tiny right margin"
                      size="tiny"
                      color="green"
                    >
                      Signed
                    </Label>{" "}
                    Party signed document and can view and download it.
                  </span>
                  <br />
                </Popup>
              </>
              {/* )} */}
            </Table.HeaderCell>
            {/* <Table.HeaderCell style={{ cursor: "default" }}>
              Other Side's Signers{" "}
              <Popup
                flowing
                size="large"
                trigger={
                  <Icon
                    name="info"
                    color="blue"
                    circular
                    inverted
                    size="small"
                    style={{ marginLeft: "3px", marginBottom: "3px" }}
                  />
                }
              >
                <p className="bold text blue mini bottom margin">
                  Other Side's Signers
                </p>
                <p className="bold text blue mini bottom margin"></p>
                <p className="text-dark text-normal mini bottom margin">
                  These are the parties on the other side that need to sign the
                  form.
                </p>
              </Popup>
            </Table.HeaderCell> */}
            <Table.HeaderCell
              sorted={column === "updatedAt" ? direction : null}
              onClick={() => handleSort("updatedAt")}
            >
              Last Updated
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {docs &&
            docs.length !== 0 &&
            docs.map((doc) => (
              <DocTransActiveListItem doc={doc} key={doc.id} />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
