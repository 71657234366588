import React, { useEffect } from "react";
import { Button, Grid, Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import TransactionCompleteList from "./TransactionCompleteList";
import { convertSearchTerms } from "../../../app/common/util/util";
import { fetchTransAll } from "../transactionSlice";
import { getDocs } from "firebase/firestore";
import {
  dataFromSnapshot,
  fetchTransactionsStatusFromDb,
} from "../../../app/firestore/firestoreService";

export default function TransactionCompleteDashboard() {
  const limit = 50;
  const dispatch = useDispatch();
  const { transAll } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);
  let { agentId } = useParams();
  const [searchTermsRaw, setSearchTermsRaw] = useState("");
  const [searchTerms, setSearchTerms] = useState("");

  useEffect(() => {
    async function fetchData(limit) {
      return await getDocs(
        fetchTransactionsStatusFromDb(
          limit,
          "initial",
          transAll.firstVisible,
          searchTerms,
          agentId,
          "Complete"
        )
      );
    }
    fetchData(limit).then((snapshot) => {
      let firstTransaction = snapshot.docs[0];
      const firstVisible = snapshot.docs[0];
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      const moreTransactions = snapshot.docs.length >= limit;
      const transactions = snapshot.docs.map((doc) => dataFromSnapshot(doc));
      dispatch(
        fetchTransAll({
          transactions,
          moreTransactions,
          firstTransaction,
          firstVisible,
          lastVisible,
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms, dispatch]);

  async function handlePageClick(direction) {
    let snapshot = null;
    if (direction === "next") {
      snapshot = await getDocs(
        fetchTransactionsStatusFromDb(
          limit,
          "next",
          transAll.lastVisible,
          searchTerms,
          agentId
        )
      );
    } else {
      snapshot = await getDocs(
        fetchTransactionsStatusFromDb(
          limit,
          "previous",
          transAll.firstVisible,
          searchTerms,
          agentId,
          "Complete"
        )
      );
    }
    const firstVisible = snapshot.docs[0];
    const lastVisible = snapshot.docs[snapshot.docs.length - 1];
    const moreTransactions = snapshot.docs.length >= limit;
    const transactions = snapshot.docs.map((doc) => dataFromSnapshot(doc));
    dispatch(
      fetchTransAll({
        transactions,
        moreTransactions,
        firstVisible,
        lastVisible,
      })
    );
  }

  async function handleSearch(eventKey) {
    if (eventKey === "Enter") {
      const searchTermsConverted = convertSearchTerms(searchTermsRaw);
      setSearchTerms(searchTermsConverted);
    }
  }

  return (
    <div className="main-page-wrapper">
      <Grid stackable>
        <Grid.Column computer={8}>
          <Input
            action={{
              icon: "search",
              onClick: () => handleSearch("Enter"),
            }}
            onKeyDown={(event) => {
              handleSearch(event.key);
            }}
            type="text"
            fluid
            size="small"
            placeholder="Search by client name or address"
            value={searchTermsRaw}
            onChange={(e) => setSearchTermsRaw(e.target.value)}
          ></Input>
        </Grid.Column>
        <Grid.Column computer={3} tablet={4}>
          <Button.Group fluid size="small">
            <Button
              as={Link}
              to={
                currentUserProfile?.role === "manager"
                  ? `/agent/${agentId}/transactions`
                  : "/transactions"
              }
            >
              Active
            </Button>
            <Button
              as={Link}
              to={
                currentUserProfile?.role === "manager"
                  ? `/agent/${agentId}/transactionsAll`
                  : "/transactionsAll"
              }
            >
              All
            </Button>
            <Button active as={Link} to="">
              Complete
            </Button>
          </Button.Group>
        </Grid.Column>
        <Grid.Column computer={16}>
          <h3>All Transactions</h3>
          {transAll?.transactions?.length !== 0 ? (
            <TransactionCompleteList transactions={transAll.transactions} />
          ) : (
            <p>There are no transactions</p>
          )}

          {transAll.transactions[0] &&
            (!transAll?.firstTransaction ||
              transAll.transactions[0].id !== transAll.firstTransaction.id) && (
              <Button primary onClick={() => handlePageClick("previous")}>
                Previous
              </Button>
            )}
          <div style={{ float: "right" }}>
            {transAll?.moreTransactions && (
              <Button primary onClick={() => handlePageClick("next")}>
                Next
              </Button>
            )}
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}
