export function cRENDelayedEntryForm() {
return [   //2024 Release 2024-03-19 21:51:02
{
   page: 0,
   name: "Listing Agent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 221.298,
   left: 229.92,
   width: 306.72,
   height: 12.698
}
,
{
   page: 0,
   name: "of Listing Office", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 246.738,
   left: 150.24,
   width: 388.80,
   height: 12.698
}
,
{
   page: 0,
   name: "Street Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 298.458,
   left: 147.84,
   width: 339.36,
   height: 12.698
}
,
{
   page: 0,
   name: "condition", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 341.498,
   left: 72.0,
   width: 252.0,
   height: 12.698
}
,
{
   page: 0,
   name: "date Delayed Entry Start Date", 
   fontSize: 9,
   type: "text",
   top: 428.538,
   left: 189.6,
   width: 82.68,
   height: 12.698
}
,
{
   page: 0,
   name: "date Delayed Entry Termination Date", 
   fontSize: 9,
   type: "text",
   top: 428.538,
   left: 427.68,
   width: 101.607,
   height: 12.698
}
,
{
   page: 0,
   name: "date Listing Contract Date", 
   fontSize: 9,
   type: "text",
   top: 507.738,
   left: 173.92,
   width: 111.393,
   height: 12.698
}
,
{
   page: 0,
   name: "date Listing Expiration Date", 
   fontSize: 9,
   type: "text",
   top: 507.738,
   left: 419.32,
   width: 116.858,
   height: 12.698
}
,
{
   page: 0,
   name: "signature lister", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 601.015,
   left: 190.952,
   width: 192.065,
   height: 12.043
}
,
{
   page: 0,
   name: "signature colistersigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 630.611,
   left: 206.552,
   width: 176.356,
   height: 12.044
}
,
{
   page: 0,
   name: "Date_3", 
   fontSize: 9,
   type: "date",
   top: 602.058,
   left: 419.52,
   width: 113.76,
   height: 12.698
}
,
{
   page: 0,
   name: "Date_4", 
   fontSize: 9,
   type: "date",
   top: 630.618,
   left: 419.92,
   width: 112.822,
   height: 12.698
}
] }
