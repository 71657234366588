import React from "react";
import { Button, Grid, Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";
import { openModal } from "../../../../app/common/modals/modalSlice";
import { searchFilter } from "../../../../app/common/util/util";
import TaskTransActiveWithDateList from "./TaskTransListWithDateList";
import TaskTransActiveWithoutDateList from "./TaskTransListWithoutDateList";

export default function TaskTransActiveDashboard() {
  const dispatch = useDispatch();
  const {
    tasksTransWithDateUpcoming,
    tasksTransWithDatePast,
    tasksTransWithoutDateActive,
    tasksTransWithoutDateComplete,
  } = useSelector((state) => state.task);
  let { id } = useParams();
  const [searchTerms, setSearchTerms] = useState("");
  const tasksWithDateUpcoming = searchFilter(
    tasksTransWithDateUpcoming,
    searchTerms
  );
  const tasksWithDatePast = searchFilter(tasksTransWithDatePast, searchTerms);
  const tasksWithoutDateActive = searchFilter(
    tasksTransWithoutDateActive,
    searchTerms
  );
  const tasksWithoutDateComplete = searchFilter(
    tasksTransWithoutDateComplete,
    searchTerms
  );
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <div className="secondary-page-wrapper">
      <Grid stackable>
        <Grid.Column computer={5}>
          <Input
            type="text"
            fluid
            placeholder="Search by name or status"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          ></Input>
        </Grid.Column>
        <Grid.Column computer={5}>
          <Button.Group fluid size="small">
            <Button active as={Link} to="">
              List
            </Button>
            <Button as={Link} to={`/transactions/${id}/tasksCalendar`}>
              Calendar
            </Button>
            <Button as={Link} to={`/transactions/${id}/tasksSharing`}>
              Sharing
            </Button>
          </Button.Group>
        </Grid.Column>
        <Grid.Column computer={6}>
          <Button
            color="blue"
            to="#"
            icon="plus"
            floated={isMobile ? "left" : "right"}
            size="small"
            className={isMobile ? "fluid mini bottom margin" : null}
            onClick={() =>
              dispatch(
                openModal({
                  modalType: "TaskForm",
                  modalProps: {
                    category: "Task",
                  },
                })
              )
            }
            content={`Add Task`}
            data-test="add-task-btn"
          />
          <Button
            color="blue"
            to="#"
            icon="plus"
            floated={isMobile ? "left" : "right"}
            size="small"
            className={isMobile ? "fluid" : null}
            onClick={() =>
              dispatch(
                openModal({
                  modalType: "TaskTemplatesSelect",
                })
              )
            }
            content={`Add Template`}
            data-test="add-task-btn"
          />
        </Grid.Column>
        <Grid.Column computer={8}>
          <h3>Upcoming Deadlines</h3>
          {tasksWithDateUpcoming?.length > 0 ? (
            <TaskTransActiveWithDateList tasks={tasksWithDateUpcoming} />
          ) : (
            <p>There are no upcoming deadlines for this transaction</p>
          )}
          {tasksWithDatePast?.length > 0 && (
            <>
              <h3>Past Deadlines</h3>
              <TaskTransActiveWithDateList tasks={tasksWithDatePast} />
            </>
          )}
        </Grid.Column>
        <Grid.Column computer={8}>
          <h3>To Do</h3>
          {tasksWithoutDateActive?.length > 0 ? (
            <TaskTransActiveWithoutDateList tasks={tasksWithoutDateActive} />
          ) : (
            <p>There are no active tasks for this transaction</p>
          )}
          {tasksWithoutDateComplete?.length > 0 && (
            <>
              <h3>Complete</h3>
              <TaskTransActiveWithoutDateList
                tasks={tasksWithoutDateComplete}
              />
            </>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
}
