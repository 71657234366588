export function contracttoBuyandSellLand2024Aug() {
return [   //2024 Release 2024-07-31 16:45:12
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "Property with No Residences", 
   isText: false,
   type: "checkbox",
   top: 184.68,
   left: 230.52,
   width: 11.28,
   height: 11.28
}
,
{
   page: 0,
   name: "Property with Residences Residential Addendum Attached", 
   isText: false,
   type: "checkbox",
   top: 206.28,
   left: 150.6,
   width: 11.28,
   height: 11.28
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 223.44,
   left: 447.12,
   width: 125.16,
   height: 18.36
}
,
{
   page: 0,
   name: "Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 335.04,
   left: 115.32,
   width: 419.88,
   height: 11.16
}
,
{
   page: 0,
   name: "Joint Tenants", 
   isText: false,
   type: "checkbox",
   top: 349.56,
   left: 279.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Tenants In Common", 
   isText: false,
   type: "checkbox",
   top: 349.56,
   left: 369.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 362.76,
   left: 75.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 360.895,
   left: 120.0,
   width: 444.96,
   height: 11.705
}
,
{
   page: 0,
   name: "Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 398.531,
   left: 113.64,
   width: 424.92,
   height: 12.469
}
,
{
   page: 0,
   name: "Property The Property is the following legally described real estate in the County of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 430.931,
   left: 428.76,
   width: 140.04,
   height: 12.469
}
,
{
   page: 0,
   name: "known as", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 542.771,
   left: 126.6,
   width: 424.92,
   height: 12.469
}
,
{
   page: 0,
   name: "legalDesc", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 459.493,
   left: 58.2548,
   width: 513.9292,
   height: 80.909
}
,
{
   page: 0,
   name: "Inclusions", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 652.585,
   left: 69.3821,
   width: 503.4559,
   height: 98.5819
}
,
{
   page: 1,
   name: "Will", 
   isText: false,
   type: "checkbox",
   top: 189.24,
   left: 109.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Will Not assume the debt and obligations on the Encumbered Inclusions subject to Buyers review", 
   isText: false,
   type: "checkbox",
   top: 189.24,
   left: 156.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Will_2", 
   isText: false,
   type: "checkbox",
   top: 352.68,
   left: 107.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Will Not assume Sellers debt and obligations under such leases for the Leased Items subject to", 
   isText: false,
   type: "checkbox",
   top: 352.68,
   left: 154.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Will_3", 
   isText: false,
   type: "checkbox",
   top: 424.92,
   left: 388.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Will Not assume Sellers", 
   isText: false,
   type: "checkbox",
   top: 424.92,
   left: 435.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Deeded Water Rights The following legally described water rights", 
   isText: false,
   type: "checkbox",
   top: 627.96,
   left: 107.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "deed at Closing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 730.84,
   left: 347.04,
   width: 159.96,
   height: 12.4364
}
,
{
   page: 1,
   name: "encumberedIncl", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 119.782,
   left: 73.3094,
   width: 499.5286,
   height: 67.819
}
,
{
   page: 1,
   name: "leasedItems", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 290.965,
   left: 69.3821,
   width: 508.6929,
   height: 61.927
}
,
{
   page: 1,
   name: "exclusi", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 480.438,
   left: 58.2548,
   width: 513.9292,
   height: 127.383
}
,
{
   page: 1,
   name: "deededWater", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 639.494,
   left: 70.6912,
   width: 502.1468,
   height: 94.0001
}
,
{
   page: 2,
   name: "Other Rights Relating to Water The following rights relating to water not included in  271 273 274", 
   isText: false,
   type: "checkbox",
   top: 51,
   left: 107.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Well Rights Seller agrees to supply required information to Buyer about the well Buyer understands that if the", 
   isText: false,
   type: "checkbox",
   top: 189.24,
   left: 107.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 266.575,
   left: 219.36,
   width: 274.92,
   height: 11.945
}
,
{
   page: 2,
   name: "Water Stock The water stock to be transferred at Closing are as follows", 
   isText: false,
   type: "checkbox",
   top: 287.88,
   left: 107.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "otherWaterR", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 74.619,
   left: 74.6185,
   width: 504.1105,
   height: 112.982
}
,
{
   page: 2,
   name: "waterStock", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 298.474,
   left: 71.3458,
   width: 506.7292,
   height: 74.364
}
,
{
   page: 2,
   name: "waterSewerTap", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 398.62,
   left: 71.3458,
   width: 506.7292,
   height: 79.60
}
,
{
   page: 2,
   name: "growingCrops", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 606.112,
   left: 59.5639,
   width: 517.2021,
   height: 146.364
}
,
{
   page: 3,
   name: "Time of Day Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 118.68,
   left: 385.92,
   width: 183.00,
   height: 13.32
}
,
{
   page: 3,
   name: "DateAlternative Earnest Money Deadline", 
   fontSize: 9,
   type: "date",
   top: 133.32,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "Alternative Earnest Money Deadline", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 133.32,
   left: 385.92,
   width: 183.00,
   height: 13.08
}
,
{
   page: 3,
   name: "DateRecord Title Deadline and Tax Certificate", 
   fontSize: 9,
   type: "date",
   top: 160.2,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 174.6,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateOffRecord Title Deadline", 
   fontSize: 9,
   type: "date",
   top: 189.24,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateOffRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 203.64,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateTitle Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 218.28,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateThird Party Right to PurchaseApprove Deadline", 
   fontSize: 9,
   type: "date",
   top: 232.92,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateAssociation Documents Deadline", 
   fontSize: 9,
   type: "date",
   top: 259.56,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateAssociation Documents Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 274.2,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateSellers Property Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 301.08,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateLeadBased Paint Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 315.72,
   left: 314.16,
   width: 69.96,
   height: 17.182
}
,
{
   page: 3,
   name: "DateNew Loan Application Deadline", 
   fontSize: 9,
   type: "date",
   top: 349.08,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateNew Loan Terms Deadline", 
   fontSize: 9,
   type: "date",
   top: 363.48,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateNew Loan Availability Deadline", 
   fontSize: 9,
   type: "date",
   top: 378.12,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateBuyers Credit Information Deadline", 
   fontSize: 9,
   type: "date",
   top: 392.76,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateDisapproval of Buyers Credit Information Deadline", 
   fontSize: 9,
   type: "date",
   top: 407.16,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateExisting Loan Deadline", 
   fontSize: 9,
   type: "date",
   top: 421.8,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateExisting Loan Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 436.2,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateLoan Transfer Approval Deadline", 
   fontSize: 9,
   type: "date",
   top: 450.84,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateSeller or Private Financing Deadline", 
   fontSize: 9,
   type: "date",
   top: 465.48,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateAppraisal Deadline", 
   fontSize: 9,
   type: "date",
   top: 492.12,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateAppraisal Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 506.76,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateAppraisal Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 521.4,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Deadline", 
   fontSize: 9,
   type: "date",
   top: 548.04,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 562.68,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateNew ILC or New Survey Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 577.32,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateWater Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 603.96,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateMineral Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 618.6,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateInspection Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 633,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateInspection Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 647.64,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateInspection Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 662.28,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateProperty Insurance Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 676.68,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Delivery Deadline", 
   fontSize: 9,
   type: "date",
   top: 691.32,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 705.96,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 3,
   name: "DateDue Diligence Documents Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 720.36,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 3,
   name: "Record Title Deadline and Tax", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 160.292,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "Record Title Objection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 174.692,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "OffRecord Title", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 189.332,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "OffRecord Title Objection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 203.732,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Title Resolution", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 218.372,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Third Party Right to PurchaseApprove", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 233.012,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "Association Documents", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 259.652,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Association Documents Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 274.292,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Sellers Property Disclosure", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 301.172,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "LeadBased Paint Disclosure", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 315.812,
   left: 387.858,
   width: 179.960,
   height: 18.800
}
,
{
   page: 3,
   name: "New Loan Application", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 349.172,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "New Loan Terms", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 363.572,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "New Loan Availability", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 378.212,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Buyers Credit Information", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 392.852,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "Disapproval of Buyers Credit Information", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 407.252,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Existing Loan", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 421.892,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "Existing Loan Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 436.292,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Loan Transfer Approval", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 450.932,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Seller or Private Financing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 465.572,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "Appraisals", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 492.212,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Appraisal Objection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 506.852,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Appraisal Resolution", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 521.492,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "New ILC or New Survey", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 548.132,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "New ILC or New Survey Objection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 562.772,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "New ILC or New Survey Resolution", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 577.412,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "Water Rights Examination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 604.052,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Mineral Rights Examination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 618.692,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "Inspection Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 633.092,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Inspection Objection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 647.732,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Inspection Resolution", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 662.372,
   left: 387.618,
   width: 180.440,
   height: 12.080
}
,
{
   page: 3,
   name: "Property Insurance Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 676.772,
   left: 387.618,
   width: 180.440,
   height: 12.320
}
,
{
   page: 3,
   name: "Due Diligence Documents Delivery", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 691.412,
   left: 387.618,
   width: 180.440,
   height: 12.3197
}
,
{
   page: 3,
   name: "Due Diligence Documents Objection", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 706.0516,
   left: 387.618,
   width: 180.440,
   height: 12.0801
}
,
{
   page: 3,
   name: "Due Diligence Documents Resolution", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 720.4517,
   left: 387.618,
   width: 180.440,
   height: 12.3200
}
,
{
   page: 4,
   name: "Date Environmental Inspection Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 51.72,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 4,
   name: "Date ADA Evaluation Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 66.36,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 4,
   name: "DateConditional Sale Deadline", 
   fontSize: 9,
   type: "date",
   top: 80.76,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 4,
   name: "DateLeadBased Paint Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 95.64,
   left: 314.16,
   width: 69.96,
   height: 19.56
}
,
{
   page: 4,
   name: "Date Estoppel Statements Deadline", 
   fontSize: 9,
   type: "date",
   top: 116.52,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 4,
   name: "Date Estoppel Statements Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 131.16,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 4,
   name: "DateClosing Date", 
   fontSize: 9,
   type: "date",
   top: 158.04,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 4,
   name: "DatePossession Date", 
   fontSize: 9,
   type: "date",
   top: 172.44,
   left: 313.92,
   width: 70.44,
   height: 13.32
}
,
{
   page: 4,
   name: "DateAcceptance Deadline Date", 
   fontSize: 9,
   type: "date",
   top: 201.72,
   left: 313.92,
   width: 70.44,
   height: 13.08
}
,
{
   page: 4,
   name: "47Row1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 230.64,
   left: 48.12,
   width: 26.04,
   height: 11.04
}
,
{
   page: 4,
   name: " 27Row1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 230.64,
   left: 75.48,
   width: 30.12,
   height: 11.04
}
,
{
   page: 4,
   name: "Acceptance Deadline TimeRow1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 230.64,
   left: 106.92,
   width: 205.56,
   height: 11.04
}
,
{
   page: 4,
   name: "Date Closing and PossessionRow6", 
   fontSize: 9,
   type: "date",
   top: 230.64,
   left: 313.8,
   width: 70.68,
   height: 11.04
}
,
{
   page: 4,
   name: "Closing and PossessionRow6_2 Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 230.64,
   left: 385.8,
   width: 183.24,
   height: 11.04
}
,
{
   page: 4,
   name: "47Row2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 242.88,
   left: 48.12,
   width: 26.04,
   height: 11.04
}
,
{
   page: 4,
   name: " 27Row2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 242.88,
   left: 75.48,
   width: 30.12,
   height: 11.04
}
,
{
   page: 4,
   name: "Acceptance Deadline TimeRow2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 242.88,
   left: 106.92,
   width: 205.56,
   height: 11.04
}
,
{
   page: 4,
   name: "Date Closing and PossessionRow7", 
   fontSize: 9,
   type: "date",
   top: 242.88,
   left: 313.8,
   width: 70.68,
   height: 11.04
}
,
{
   page: 4,
   name: "Closing and PossessionRow7_2 Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 242.88,
   left: 385.8,
   width: 183.24,
   height: 11.04
}
,
{
   page: 4,
   name: "Deadlines If any deadline falls on a Saturday Sunday or federal or Colorado state holiday Holiday such deadline", 
   isText: false,
   type: "checkbox",
   top: 498.12,
   left: 129.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "checked the deadline will not be extended", 
   isText: false,
   type: "checkbox",
   top: 498.12,
   left: 82.2,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Amount", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 589.051,
   left: 311.378,
   width: 86.389,
   height: 12.800
}
,
{
   page: 4,
   name: "Earnest Money", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 603.48,
   left: 415.48,
   width: 84.44,
   height: 13.32
}
,
{
   page: 4,
   name: "New Loan", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 618.12,
   left: 415.48,
   width: 84.44,
   height: 13.08
}
,
{
   page: 4,
   name: "Assumption Balance", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 632.52,
   left: 415.48,
   width: 84.44,
   height: 13.32
}
,
{
   page: 4,
   name: "Private Financing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 647.16,
   left: 415.48,
   width: 84.44,
   height: 13.32
}
,
{
   page: 4,
   name: "Seller Financing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 661.8,
   left: 415.48,
   width: 84.44,
   height: 13.08
}
,
{
   page: 4,
   name: " 477", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 676.2,
   left: 127.2,
   width: 52.92,
   height: 12.12
}
,
{
   page: 4,
   name: "Seller Financing7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 676.2,
   left: 181.68,
   width: 117.24,
   height: 12.12
}
,
{
   page: 4,
   name: "7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 676.2,
   left: 300.48,
   width: 100.44,
   height: 12.12
}
,
{
   page: 4,
   name: "7_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 676.2,
   left: 402.48,
   width: 98.28,
   height: 12.12
}
,
{
   page: 4,
   name: " 478", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 689.64,
   left: 127.2,
   width: 52.92,
   height: 12.12
}
,
{
   page: 4,
   name: "Seller Financing8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 689.64,
   left: 181.68,
   width: 117.24,
   height: 12.12
}
,
{
   page: 4,
   name: "8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 689.64,
   left: 300.48,
   width: 100.44,
   height: 12.12
}
,
{
   page: 4,
   name: "8_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 689.64,
   left: 402.48,
   width: 98.28,
   height: 12.12
}
,
{
   page: 4,
   name: "cashAtClose", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 703.08,
   left: 415.16,
   width: 85.60,
   height: 13.56
}
,
{
   page: 4,
   name: " 4410", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 717.96,
   left: 127.2,
   width: 52.92,
   height: 13.08
}
,
{
   page: 4,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 717.96,
   left: 309.24,
   width: 91.68,
   height: 13.08
}
,
{
   page: 4,
   name: "undefined_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 717.96,
   left: 415.16,
   width: 84.60,
   height: 13.08
}
,
{
   page: 4,
   name: "Environmental Inspection Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 51.679,
   left: 386.636,
   width: 180.440,
   height: 12.320
}
,
{
   page: 4,
   name: "ADA Evaluation Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 66.319,
   left: 386.636,
   width: 180.440,
   height: 12.080
}
,
{
   page: 4,
   name: "Conditional Sale", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 80.719,
   left: 386.636,
   width: 180.440,
   height: 12.320
}
,
{
   page: 4,
   name: "LeadBased Paint Terminationss", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 95.599,
   left: 386.876,
   width: 179.960,
   height: 18.560
}
,
{
   page: 4,
   name: "Estoppel Statements", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 116.479,
   left: 386.636,
   width: 180.440,
   height: 12.320
}
,
{
   page: 4,
   name: "Estoppel Statements Termination", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 131.119,
   left: 386.636,
   width: 180.440,
   height: 12.320
}
,
{
   page: 4,
   name: "Closing Date Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 157.999,
   left: 386.636,
   width: 180.440,
   height: 12.080
}
,
{
   page: 4,
   name: "Possession Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 172.399,
   left: 386.636,
   width: 180.440,
   height: 12.320
}
,
{
   page: 4,
   name: "Acceptance Deadline Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 201.679,
   left: 386.636,
   width: 180.440,
   height: 12.080
}
,
{
   page: 4,
   name: "PossessionTimeBox Details", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 187.397,
   left: 387.273,
   width: 180.440,
   height: 12.080
}
,
{
   page: 4,
   name: "AcceptDeadlineTime", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 216.506,
   left: 387.273,
   width: 180.440,
   height: 12.080
}
,
{
   page: 5,
   name: "Seller Concession At Closing Seller will credit to Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 50.367,
   left: 329.52,
   width: 130.08,
   height: 10.473
}
,
{
   page: 5,
   name: "Earnest Money The Earnest Money set forth in this Section in the form of a", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 136.153,
   left: 401.52,
   width: 153.72,
   height: 10.472
}
,
{
   page: 5,
   name: "funds that are immediately verifiable and available in an amount not less than the amount stated as Cash at Closing in  41", 
   isText: false,
   type: "checkbox",
   top: 608.76,
   left: 427.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Does Not have", 
   isText: false,
   type: "checkbox",
   top: 608.76,
   left: 471.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "earnestMoneyHolder", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 147.273,
   left: 196.401,
   width: 309.328,
   height: 11.528
}
,
{
   page: 6,
   name: "453", 
   isText: false,
   type: "checkbox",
   top: 65.16,
   left: 86.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Loan Limitations Buyer may purchase the Property using any of the following types of loans", 
   isText: false,
   type: "checkbox",
   top: 64.2,
   left: 169.8,
   width: 11.28,
   height: 11.28
}
,
{
   page: 6,
   name: "Other_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 62.64,
   left: 216.48,
   width: 320.04,
   height: 12.36
}
,
{
   page: 6,
   name: "forth in  41 Price and Terms presently payable at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 108.72,
   left: 251.4,
   width: 60.0,
   height: 12.36
}
,
{
   page: 6,
   name: "per", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 98.138,
   left: 392.88,
   width: 129.96,
   height: 9.742
}
,
{
   page: 6,
   name: "principal and interest presently at the rate of", 
   isText: false,
   type: "checkbox",
   top: 138.6,
   left: 172.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "indicated", 
   isText: false,
   type: "checkbox",
   top: 138.6,
   left: 73.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "per annum and also including escrow for the following as", 
   isText: false,
   type: "checkbox",
   top: 137.64,
   left: 349.8,
   width: 11.28,
   height: 11.28
}
,
{
   page: 6,
   name: "undefined_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 138.044,
   left: 366.96,
   width: 195.00,
   height: 10.396
}
,
{
   page: 6,
   name: "Buyer agrees to pay a loan transfer fee not to exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 152.378,
   left: 310.56,
   width: 80.04,
   height: 9.742
}
,
{
   page: 6,
   name: "rate will not exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 164.924,
   left: 152.88,
   width: 50.04,
   height: 10.396
}
,
{
   page: 6,
   name: "per annum and the new payment will not exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 164.269,
   left: 422.76,
   width: 65.04,
   height: 11.051
}
,
{
   page: 6,
   name: "Closing is less than the Assumption Balance which causes the amount of cash required from Buyer at Closing to be", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 178.778,
   left: 72.96,
   width: 105.00,
   height: 9.742
}
,
{
   page: 6,
   name: "increased by more than", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 204.524,
   left: 176.16,
   width: 75.00,
   height: 10.396
}
,
{
   page: 6,
   name: "Terminate under  241 on or before Closing Date", 
   isText: false,
   type: "checkbox",
   top: 231.48,
   left: 115.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "release from liability will be evidenced by delivery", 
   isText: false,
   type: "checkbox",
   top: 231.48,
   left: 157.32,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Will Not be released from liability on said loan If applicable compliance with the requirements for", 
   isText: false,
   type: "checkbox",
   top: 244.68,
   left: 285,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "at Closing", 
   isText: false,
   type: "checkbox",
   top: 244.68,
   left: 506.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "in an amount not to exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 270.764,
   left: 428.28,
   width: 80.04,
   height: 10.396
}
,
{
   page: 6,
   name: "471", 
   isText: false,
   type: "checkbox",
   top: 409.56,
   left: 79.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Seller Financing If Buyer is to pay all or any portion of the Purchase Price with Seller financing", 
   isText: false,
   type: "checkbox",
   top: 409.56,
   left: 129,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Seller will deliver the proposed Seller financing documents to the other party on or before", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 408.349,
   left: 516.12,
   width: 54.96,
   height: 11.051
}
,
{
   page: 6,
   name: "Text23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 270.019,
   left: 72.6549,
   width: 233.1281,
   height: 11.528
}
,
{
   page: 6,
   name: "assumepayableamount", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 96.219,
   left: 292.929,
   width: 84.545,
   height: 10.836
}
,
{
   page: 8,
   name: "Cost of Appraisal Cost of the Appraisal to be obtained after the date of this Contract must be timely paid by", 
   isText: false,
   type: "checkbox",
   top: 221.64,
   left: 123.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "company lenders agent or all three", 
   isText: false,
   type: "checkbox",
   top: 221.64,
   left: 73.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Seller Selects Title Insurance Company If this box is checked Seller will select the title insurance company", 
   isText: false,
   type: "checkbox",
   top: 458.52,
   left: 107.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "an Abstract of Title certified to a current date Seller will cause the title insurance", 
   isText: false,
   type: "checkbox",
   top: 498.36,
   left: 208.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Buyer Selects Title Insurance Company If this box is checked Buyer will select the title insurance", 
   isText: false,
   type: "checkbox",
   top: 530.76,
   left: 109.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Will_4", 
   isText: false,
   type: "checkbox",
   top: 602.76,
   left: 370.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Will Not contain Owners", 
   isText: false,
   type: "checkbox",
   top: 602.76,
   left: 415.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Buyer_2", 
   isText: false,
   type: "checkbox",
   top: 669,
   left: 239.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Seller_2", 
   isText: false,
   type: "checkbox",
   top: 669,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "OneHalf by Buyer and OneHalf by Seller", 
   isText: false,
   type: "checkbox",
   top: 669,
   left: 342.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Other_3", 
   isText: false,
   type: "checkbox",
   top: 683.16,
   left: 77.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "undefined_9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 682.953,
   left: 121.2,
   width: 240.0,
   height: 10.047
}
,
{
   page: 11,
   name: "metropolitan districts that affect the Property Tax Certificate must be delivered to Buyer on or before Record Title", 
   isText: false,
   type: "checkbox",
   top: 86.04,
   left: 275.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 11,
   name: "Buyer for the Property listing any special taxing or", 
   isText: false,
   type: "checkbox",
   top: 86.04,
   left: 324.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 11,
   name: "metroWebsite", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 64.8,
   left: 69.3821,
   width: 500.1829,
   height: 10.873
}
,
{
   page: 12,
   name: "New Improvement Location Certificate New ILC or", 
   isText: false,
   type: "checkbox",
   top: 312.36,
   left: 90.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "New Survey in the form of", 
   isText: false,
   type: "checkbox",
   top: 326.28,
   left: 90.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "undefined_10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 325.724,
   left: 217.8,
   width: 349.92,
   height: 10.396
}
,
{
   page: 12,
   name: "Seller_3", 
   isText: false,
   type: "checkbox",
   top: 359.88,
   left: 277.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Buyer will order the New ILC or New Survey The New", 
   isText: false,
   type: "checkbox",
   top: 359.88,
   left: 326.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Seller_4", 
   isText: false,
   type: "checkbox",
   top: 418.68,
   left: 133.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Buyer or", 
   isText: false,
   type: "checkbox",
   top: 418.68,
   left: 182.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "opinion of title if an Abstract of Title and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 477.578,
   left: 247.92,
   width: 324.96,
   height: 9.742
}
,
{
   page: 12,
   name: "Text25", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 428.729,
   left: 73.3094,
   width: 490.3656,
   height: 23.964
}
,
{
   page: 14,
   name: "All contracts relating to the operation maintenance and management of the Property", 
   isText: false,
   type: "checkbox",
   top: 544.68,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Property tax bills for the last", 
   isText: false,
   type: "checkbox",
   top: 563.88,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Asbuilt construction plans to the Property and the tenant improvements including architectural", 
   isText: false,
   type: "checkbox",
   top: 583.08,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "years", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 562.669,
   left: 281.76,
   width: 64.92,
   height: 11.051
}
,
{
   page: 14,
   name: "A list of all Inclusions to be conveyed to Buyer", 
   isText: false,
   type: "checkbox",
   top: 628.92,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Operating statements for the past", 
   isText: false,
   type: "checkbox",
   top: 648.12,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "A rent roll accurate and correct to the date of this Contract", 
   isText: false,
   type: "checkbox",
   top: 667.32,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "A schedule of any tenant improvement work Seller is obligated to complete but has not yet completed", 
   isText: false,
   type: "checkbox",
   top: 686.52,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "years_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 647.564,
   left: 299.76,
   width: 65.04,
   height: 10.396
}
,
{
   page: 14,
   name: "All insurance policies pertaining to the Property and copies of any claims which have been made for", 
   isText: false,
   type: "checkbox",
   top: 718.92,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "the past", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 731.8728,
   left: 138.72,
   width: 64.92,
   height: 10.0872
}
,
{
   page: 14,
   name: "occupancyAgree", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 231.056,
   left: 89.0186,
   width: 491.0194,
   height: 99.891
}
,
{
   page: 15,
   name: "106169", 
   isText: false,
   type: "checkbox",
   top: 51,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "1061610", 
   isText: false,
   type: "checkbox",
   top: 83.4,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "1061611", 
   isText: false,
   type: "checkbox",
   top: 155.64,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "1061612", 
   isText: false,
   type: "checkbox",
   top: 188.04,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "1061613", 
   isText: false,
   type: "checkbox",
   top: 233.64,
   left: 149.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "a Phase I Environmental Site Assessment", 
   isText: false,
   type: "checkbox",
   top: 657.96,
   left: 253.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Seller_5", 
   isText: false,
   type: "checkbox",
   top: 657.96,
   left: 301.32,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "undefined_11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 683.804,
   left: 241.56,
   width: 330.00,
   height: 10.396
}
,
{
   page: 15,
   name: "at the expense of", 
   isText: false,
   type: "checkbox",
   top: 697.56,
   left: 152.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Seller_6", 
   isText: false,
   type: "checkbox",
   top: 697.56,
   left: 203.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "days Extended", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 722.0945,
   left: 368.16,
   width: 130.08,
   height: 11.7055
}
,
{
   page: 15,
   name: "Text27", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 247.419,
   left: 97.5277,
   width: 468.1103,
   height: 69.128
}
,
{
   page: 16,
   name: "Environmental Inspection Objection Deadline and if such Extended Environmental Inspection Objection Deadline extends", 
   isText: false,
   type: "checkbox",
   top: 64.2,
   left: 473.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Buyer_3", 
   isText: false,
   type: "checkbox",
   top: 64.2,
   left: 521.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "undefined_12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 246.175,
   left: 244.32,
   width: 324.96,
   height: 11.705
}
,
{
   page: 16,
   name: "Source of Potable Water Residential Land and Residential Improvements Only", 
   isText: false,
   type: "checkbox",
   top: 333.48,
   left: 105.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Does Not acknowledge receipt of a copy of Sellers Property Disclosure or Source of Water", 
   isText: false,
   type: "checkbox",
   top: 333.48,
   left: 152.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Addendum disclosing the source of potable water for the Property", 
   isText: false,
   type: "checkbox",
   top: 359.88,
   left: 73.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Note to Buyer SOME WATER PROVIDERS RELY TO VARYING DEGREES ON NONRENEWABLE", 
   isText: false,
   type: "checkbox",
   top: 359.88,
   left: 199.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Does Not acknowledge receipt of a copy of the current well permit", 
   isText: false,
   type: "checkbox",
   top: 359.88,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Are", 
   isText: false,
   type: "checkbox",
   top: 334.92,
   left: 415.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Are Not executed with", 
   isText: false,
   type: "checkbox",
   top: 334.92,
   left: 455.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "undefined_13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 391.855,
   left: 334.8,
   width: 235.08,
   height: 11.705
}
,
{
   page: 17,
   name: "special warranty deed", 
   isText: false,
   type: "checkbox",
   top: 519.24,
   left: 62.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "general warranty deed", 
   isText: false,
   type: "checkbox",
   top: 519.24,
   left: 207.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "bargain and sale deed", 
   isText: false,
   type: "checkbox",
   top: 519.24,
   left: 320.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "quit claim deed", 
   isText: false,
   type: "checkbox",
   top: 519.24,
   left: 430.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "deed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 532.189,
   left: 223.68,
   width: 315.00,
   height: 11.051
}
,
{
   page: 17,
   name: "personal representatives deed", 
   isText: false,
   type: "checkbox",
   top: 533.4,
   left: 62.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller provided another deed is not selected must execute and deliver a good and sufficient special warranty deed to Buyer at", 
   isText: false,
   type: "checkbox",
   top: 533.4,
   left: 207.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_4", 
   isText: false,
   type: "checkbox",
   top: 64.2,
   left: 93.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_7", 
   isText: false,
   type: "checkbox",
   top: 64.2,
   left: 151.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_2", 
   isText: false,
   type: "checkbox",
   top: 64.2,
   left: 207,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Other_4", 
   isText: false,
   type: "checkbox",
   top: 64.2,
   left: 413.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 60.96,
   left: 457.68,
   width: 109.92,
   height: 13.08
}
,
{
   page: 18,
   name: "Buyer_5", 
   isText: false,
   type: "checkbox",
   top: 161.64,
   left: 192.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_8", 
   isText: false,
   type: "checkbox",
   top: 161.64,
   left: 255.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_3", 
   isText: false,
   type: "checkbox",
   top: 161.64,
   left: 311.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA", 
   isText: false,
   type: "checkbox",
   top: 161.64,
   left: 518.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_6", 
   isText: false,
   type: "checkbox",
   top: 207.24,
   left: 192.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_9", 
   isText: false,
   type: "checkbox",
   top: 207.24,
   left: 255.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_4", 
   isText: false,
   type: "checkbox",
   top: 207.24,
   left: 311.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_2", 
   isText: false,
   type: "checkbox",
   top: 207.24,
   left: 518.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_7", 
   isText: false,
   type: "checkbox",
   top: 239.64,
   left: 192.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_10", 
   isText: false,
   type: "checkbox",
   top: 239.64,
   left: 255.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_5", 
   isText: false,
   type: "checkbox",
   top: 239.64,
   left: 311.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_3", 
   isText: false,
   type: "checkbox",
   top: 239.64,
   left: 518.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_8", 
   isText: false,
   type: "checkbox",
   top: 272.04,
   left: 192.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_11", 
   isText: false,
   type: "checkbox",
   top: 272.04,
   left: 255.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_6", 
   isText: false,
   type: "checkbox",
   top: 272.04,
   left: 311.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_4", 
   isText: false,
   type: "checkbox",
   top: 272.04,
   left: 518.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_9", 
   isText: false,
   type: "checkbox",
   top: 304.68,
   left: 192.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_12", 
   isText: false,
   type: "checkbox",
   top: 304.68,
   left: 255.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_7", 
   isText: false,
   type: "checkbox",
   top: 304.68,
   left: 311.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_5", 
   isText: false,
   type: "checkbox",
   top: 304.68,
   left: 518.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_10", 
   isText: false,
   type: "checkbox",
   top: 368.76,
   left: 192.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_13", 
   isText: false,
   type: "checkbox",
   top: 368.76,
   left: 255.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_8", 
   isText: false,
   type: "checkbox",
   top: 368.76,
   left: 311.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_6", 
   isText: false,
   type: "checkbox",
   top: 368.76,
   left: 518.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 413.258,
   left: 78.0,
   width: 84.96,
   height: 10.462
}
,
{
   page: 18,
   name: "Water DistrictMunicipality", 
   isText: false,
   type: "checkbox",
   top: 428.04,
   left: 73.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Augmentation Membership", 
   isText: false,
   type: "checkbox",
   top: 441.96,
   left: 73.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Water Stock", 
   isText: false,
   type: "checkbox",
   top: 428.04,
   left: 237.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Small Domestic Water Company", 
   isText: false,
   type: "checkbox",
   top: 441.96,
   left: 237.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_11", 
   isText: false,
   type: "checkbox",
   top: 470.04,
   left: 192.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_14", 
   isText: false,
   type: "checkbox",
   top: 470.04,
   left: 255.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_9", 
   isText: false,
   type: "checkbox",
   top: 470.04,
   left: 311.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_7", 
   isText: false,
   type: "checkbox",
   top: 470.04,
   left: 518.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Buyer_12", 
   isText: false,
   type: "checkbox",
   top: 503.4,
   left: 192.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller_15", 
   isText: false,
   type: "checkbox",
   top: 503.4,
   left: 255.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "OneHalf by Buyer and OneHalf by Seller_10", 
   isText: false,
   type: "checkbox",
   top: 503.4,
   left: 311.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "NA_8", 
   isText: false,
   type: "checkbox",
   top: 503.4,
   left: 518.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "IS a foreign person for purposes of US income taxation If the box in this Section is not checked Seller", 
   isText: false,
   type: "checkbox",
   top: 581.4,
   left: 107.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "Taxes for the Calendar Year Immediately Preceding Closing", 
   isText: false,
   type: "checkbox",
   top: 117.48,
   left: 77.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "Most Recent Mill Levy and Most Recent Assessed Valuation", 
   isText: false,
   type: "checkbox",
   top: 131.64,
   left: 77.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "Other_5", 
   isText: false,
   type: "checkbox",
   top: 145.56,
   left: 77.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "undefined_16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 143.16,
   left: 121.2,
   width: 429.96,
   height: 12.36
}
,
{
   page: 19,
   name: "Rents Actually Received", 
   isText: false,
   type: "checkbox",
   top: 165.72,
   left: 207,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "Accrued At Closing Seller will transfer or credit to", 
   isText: false,
   type: "checkbox",
   top: 165.72,
   left: 334.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "Buyer_13", 
   isText: false,
   type: "checkbox",
   top: 354.12,
   left: 132.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "Seller Except however any special assessment by the Association for improvements that", 
   isText: false,
   type: "checkbox",
   top: 354.12,
   left: 182.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 19,
   name: "undefined_17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 404.64,
   left: 72.96,
   width: 495.00,
   height: 12.36
}
,
{
   page: 19,
   name: "per day or any", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 477.295,
   left: 439.32,
   width: 65.04,
   height: 11.705
}
,
{
   page: 19,
   name: "Text28", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 221.892,
   left: 68.7276,
   width: 512.6194,
   height: 45.564
}
,
{
   page: 20,
   name: "buyerDefaultSpecificPerf", 
   isText: false,
   type: "checkbox",
   top: 641.16,
   left: 109.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "undefined_18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 265.72,
   left: 72.96,
   width: 450.00,
   height: 11.36
}
,
{
   page: 22,
   name: "of the Purchase Price or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 552.8,
   left: 87.36,
   width: 35.04,
   height: 10.36
}
,
{
   page: 22,
   name: "by Seller Buyers brokerage firm is an intended thirdparty", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 552.8,
   left: 241.2,
   width: 75.0,
   height: 10.36
}
,
{
   page: 22,
   name: "of the Purchase Price or_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 598.4,
   left: 89.88,
   width: 34.92,
   height: 10.36
}
,
{
   page: 22,
   name: "by Buyer pursuant to a separate agreement between Buyer and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 598.4,
   left: 243.72,
   width: 75.00,
   height: 10.36
}
,
{
   page: 22,
   name: "of the Purchase Price or_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 630.8,
   left: 87.36,
   width: 35.04,
   height: 10.36
}
,
{
   page: 22,
   name: "by a separate agreement between Buyers brokerage firm and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 630.8,
   left: 241.2,
   width: 75.0,
   height: 10.36
}
,
{
   page: 23,
   name: "additionalProvisions", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 75.928,
   left: 50.4002,
   width: 522.4378,
   height: 593.420
}
,
{
   page: 23,
   name: "docsPartOfContract", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 692.5121,
   left: 57.6003,
   width: 515.8927,
   height: 58.6548
}
,
{
   page: 24,
   name: "Buyers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 182.24,
   left: 119.76,
   width: 159.96,
   height: 13.00
}
,
{
   page: 24,
   name: "BuyerName2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 182.24,
   left: 355.56,
   width: 159.96,
   height: 13.00
}
,
{
   page: 24,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 242.96,
   left: 119.76,
   width: 159.96,
   height: 13.00
}
,
{
   page: 24,
   name: "undefined_20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 261.68,
   left: 119.76,
   width: 159.96,
   height: 13.00
}
,
{
   page: 24,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 242.96,
   left: 355.56,
   width: 159.96,
   height: 13.00
}
,
{
   page: 24,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 261.68,
   left: 355.56,
   width: 159.96,
   height: 13.00
}
,
{
   page: 24,
   name: "Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 278.4,
   left: 119.76,
   width: 159.96,
   height: 10.2
}
,
{
   page: 24,
   name: "Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 292.32,
   left: 119.76,
   width: 159.96,
   height: 10.44
}
,
{
   page: 24,
   name: "1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 278.4,
   left: 355.56,
   width: 159.96,
   height: 10.2
}
,
{
   page: 24,
   name: "2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 292.32,
   left: 355.56,
   width: 159.96,
   height: 10.44
}
,
{
   page: 24,
   name: "Sellers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 411.92,
   left: 119.76,
   width: 159.96,
   height: 13.00
}
,
{
   page: 24,
   name: "SellerName2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 411.92,
   left: 355.56,
   width: 159.96,
   height: 13.00
}
,
{
   page: 24,
   name: "Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 472.64,
   left: 119.76,
   width: 159.96,
   height: 13.00
}
,
{
   page: 24,
   name: "undefined_22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 489.36,
   left: 119.76,
   width: 159.96,
   height: 15.00
}
,
{
   page: 24,
   name: "1_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 472.64,
   left: 355.56,
   width: 159.96,
   height: 13.00
}
,
{
   page: 24,
   name: "2_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 489.36,
   left: 355.56,
   width: 159.96,
   height: 15.00
}
,
{
   page: 24,
   name: "Phone_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 508.08,
   left: 119.76,
   width: 159.96,
   height: 10.20
}
,
{
   page: 24,
   name: "Email_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 522.0,
   left: 119.76,
   width: 159.96,
   height: 10.44
}
,
{
   page: 24,
   name: "1_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 508.08,
   left: 355.56,
   width: 159.96,
   height: 10.20
}
,
{
   page: 24,
   name: "2_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 522.0,
   left: 355.56,
   width: 159.96,
   height: 10.44
}
,
{
   page: 24,
   name: "docsNotContr", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 64.8,
   left: 58.2548,
   width: 510.0012,
   height: 68.473
}
,
{
   page: 25,
   name: "Does", 
   isText: false,
   type: "checkbox",
   top: 70.2,
   left: 83.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the", 
   isText: false,
   type: "checkbox",
   top: 70.2,
   left: 128.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Buyers Agent", 
   isText: false,
   type: "checkbox",
   top: 142.44,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "TransactionBroker in this transaction", 
   isText: false,
   type: "checkbox",
   top: 142.44,
   left: 281.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
   isText: false,
   type: "checkbox",
   top: 161.64,
   left: 48.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Brokerage Firms Name 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 287.56,
   left: 173.76,
   width: 378.12,
   height: 12.16
}
,
{
   page: 25,
   name: "Brokerage Firms Name 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 305.44,
   left: 173.76,
   width: 378.12,
   height: 12.04
}
,
{
   page: 25,
   name: "Brokerage Firms Name 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 323.08,
   left: 173.76,
   width: 378.12,
   height: 12.16
}
,
{
   page: 25,
   name: "Brokerage Firms Name 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 340.84,
   left: 173.76,
   width: 378.12,
   height: 12.16
}
,
{
   page: 25,
   name: "date BrokerSignDate", 
   fontSize: 9,
   type: "date",
   top: 359.92,
   left: 359.04,
   width: 96.00,
   height: 12.16
}
,
{
   page: 25,
   name: "Date_10", 
   fontSize: 9,
   type: "date",
   top: 400.96,
   left: 359.04,
   width: 96.00,
   height: 12.16
}
,
{
   page: 25,
   name: "CoBrokers Signature 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 458.68,
   left: 173.76,
   width: 378.12,
   height: 11.92
}
,
{
   page: 25,
   name: "CoBrokers Signature 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 476.2,
   left: 173.76,
   width: 378.12,
   height: 12.16
}
,
{
   page: 25,
   name: "CoBrokers Signature 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 493.96,
   left: 173.04,
   width: 378.84,
   height: 12.16
}
,
{
   page: 25,
   name: "addressBrokerBuy", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 441.567,
   left: 174.742,
   width: 378.840,
   height: 12.160
}
,
{
   page: 25,
   name: "Does_2", 
   isText: false,
   type: "checkbox",
   top: 563.4,
   left: 83.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the_2", 
   isText: false,
   type: "checkbox",
   top: 563.4,
   left: 128.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Sellers Agent", 
   isText: false,
   type: "checkbox",
   top: 635.4,
   left: 195,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "TransactionBroker in this transaction_2", 
   isText: false,
   type: "checkbox",
   top: 635.4,
   left: 278.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Customer Broker has no brokerage relationship with Seller See A for Brokers brokerage relationship with Buyer", 
   isText: false,
   type: "checkbox",
   top: 667.8,
   left: 48.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Seller_16", 
   isText: false,
   type: "checkbox",
   top: 713.64,
   left: 50.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Buyer_14", 
   isText: false,
   type: "checkbox",
   top: 713.64,
   left: 99.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Other_6", 
   isText: false,
   type: "checkbox",
   top: 713.64,
   left: 149.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 25,
   name: "Brokerage Firms compensation or commission is to be paid by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 711.12,
   left: 193.32,
   width: 334.80,
   height: 12.48
}
,
{
   page: 25,
   name: "signature BrokerSigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 360.002,
   left: 176.728,
   width: 172.255,
   height: 13.490
}
,
{
   page: 25,
   name: "signature CoBrokerSigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 401.238,
   left: 178.037,
   width: 167.673,
   height: 12.836
}
,
{
   page: 26,
   name: "Brokerage Firms Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 89.88,
   left: 174.48,
   width: 364.92,
   height: 16.56
}
,
{
   page: 26,
   name: "Brokerage Firms License 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 107.16,
   left: 174.48,
   width: 364.92,
   height: 16.56
}
,
{
   page: 26,
   name: "Brokerage Firms License 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 124.44,
   left: 174.48,
   width: 364.92,
   height: 16.56
}
,
{
   page: 26,
   name: "Brokerage Firms License 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 141.72,
   left: 174.48,
   width: 364.92,
   height: 16.56
}
,
{
   page: 26,
   name: "signature BrokerSignsSeller", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 167.16,
   left: 174.48,
   width: 168.00,
   height: 16.56
}
,
{
   page: 26,
   name: "Date brokerSellerSigns", 
   fontSize: 9,
   type: "date",
   top: 167.16,
   left: 354.48,
   width: 96.00,
   height: 16.56
}
,
{
   page: 26,
   name: "Date CoBrokerSellerSigns", 
   fontSize: 9,
   type: "date",
   top: 208.2,
   left: 354.48,
   width: 96.00,
   height: 16.56
}
,
{
   page: 26,
   name: "CoBrokers Signature 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 265.68,
   left: 169.2,
   width: 382.68,
   height: 16.56
}
,
{
   page: 26,
   name: "CoBrokers Signature 3_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 283.44,
   left: 169.2,
   width: 382.68,
   height: 16.56
}
,
{
   page: 26,
   name: "CoBrokers Signature 4_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 301.2,
   left: 168.48,
   width: 383.40,
   height: 16.56
}
,
{
   page: 26,
   name: "signature CoBrokerSellerSigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 205.528,
   left: 177.037,
   width: 150.000,
   height: 18.727
}
,
{
   page: 26,
   name: "brokerAddressSellerSide", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 248.074,
   left: 170.183,
   width: 381.055,
   height: 15.454
}
] }
