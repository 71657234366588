export function amendExtendContract() {
   return [   //2023
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 154.32,
      left: 398.64,
      width: 174.00,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "This agreement amends the contract dated", 
      type: "date",
      fontName: "Helvetica",
      fontSize: 10,
      top: 177.36,
      left: 244.08,
      width: 121.56,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Contract between", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 177.36,
      left: 447.48,
      width: 125.16,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 188.88,
      left: 50.4,
      width: 198.12,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Seller and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 188.88,
      left: 300.36,
      width: 272.28,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Buyer relating to the sale and purchase of the following legally described real estate in the County of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 200.28,
      left: 463.2,
      width: 109.32,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Text1", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 11,
      top: 223.633,
      left: 50.8082,
      width: 519.2438,
      height: 88.914
   }
   ,
   {
      page: 0,
      name: "known as", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 316.515,
      left: 96.24,
      width: 431.40,
      height: 10.680
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineTime of Day Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 438.637,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeTime of Day Deadline", 
      isText: true,
      type: "checkbox",
      top: 440.074,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedTime of Day Deadline", 
      isText: true,
      type: "checkbox",
      top: 439.855,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAlternative Earnest Money Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 450.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineAlternative Earnest Money Deadline", 
      fontSize: 9,
      type: "date",
      top: 449.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeAlternative Earnest Money Deadline", 
      isText: true,
      type: "checkbox",
      top: 451.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedAlternative Earnest Money Deadline", 
      isText: true,
      type: "checkbox",
      top: 451.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineRecord Title Deadline and Tax Certificate", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 474.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineRecord Title Deadline and Tax Certificate", 
      fontSize: 9,
      type: "date",
      top: 473.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeRecord Title Deadline and Tax Certificate", 
      isText: true,
      type: "checkbox",
      top: 475.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedRecord Title Deadline and Tax Certificate", 
      isText: true,
      type: "checkbox",
      top: 475.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineRecord Title Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 486.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineRecord Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 485.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeRecord Title Objection Deadline", 
      isText: true,
      type: "checkbox",
      top: 487.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedRecord Title Objection Deadline", 
      isText: true,
      type: "checkbox",
      top: 487.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineOffRecord Title Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 498.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineOffRecord Title Deadline", 
      fontSize: 9,
      type: "date",
      top: 497.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeOffRecord Title Deadline", 
      isText: true,
      type: "checkbox",
      top: 499.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedOffRecord Title Deadline", 
      isText: true,
      type: "checkbox",
      top: 499.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineOffRecord Title Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 510.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineOffRecord Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 510.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeOffRecord Title Objection Deadline", 
      isText: true,
      type: "checkbox",
      top: 511.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedOffRecord Title Objection Deadline", 
      isText: true,
      type: "checkbox",
      top: 511.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineTitle Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 522.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineTitle Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 522.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeTitle Resolution Deadline", 
      isText: true,
      type: "checkbox",
      top: 523.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedTitle Resolution Deadline", 
      isText: true,
      type: "checkbox",
      top: 523.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineThird Party Right to PurchaseApprove Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 534.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineThird Party Right to PurchaseApprove Deadline", 
      fontSize: 9,
      type: "date",
      top: 534.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeThird Party Right to PurchaseApprove Deadline", 
      isText: true,
      type: "checkbox",
      top: 535.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedThird Party Right to PurchaseApprove Deadline", 
      isText: true,
      type: "checkbox",
      top: 535.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAssociation Documents Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 558.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineAssociation Documents Deadline", 
      fontSize: 9,
      type: "date",
      top: 558.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeAssociation Documents Deadline", 
      isText: true,
      type: "checkbox",
      top: 559.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedAssociation Documents Deadline", 
      isText: true,
      type: "checkbox",
      top: 559.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAssociation Documents Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 570.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineAssociation Documents Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 570.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeAssociation Documents Termination Deadline", 
      isText: true,
      type: "checkbox",
      top: 571.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedAssociation Documents Termination Deadline", 
      isText: true,
      type: "checkbox",
      top: 571.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineSellers Property Disclosure Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 594.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineSellers Property Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 594.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeSellers Property Disclosure Deadline", 
      isText: true,
      type: "checkbox",
      top: 595.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedSellers Property Disclosure Deadline", 
      isText: true,
      type: "checkbox",
      top: 595.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineLeadBased Paint Disclosure Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 606.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineLeadBased Paint Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 606.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeLeadBased Paint Disclosure Deadline", 
      isText: true,
      type: "checkbox",
      top: 607.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedLeadBased Paint Disclosure Deadline", 
      isText: true,
      type: "checkbox",
      top: 607.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineNew Loan Application Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 630.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineNew Loan Application Deadline", 
      fontSize: 9,
      type: "date",
      top: 630.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeNew Loan Application Deadline", 
      isText: true,
      type: "checkbox",
      top: 631.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedNew Loan Application Deadline", 
      isText: true,
      type: "checkbox",
      top: 631.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineNew Loan Terms Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 642.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineNew Loan Terms Deadline", 
      fontSize: 9,
      type: "date",
      top: 642.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeNew Loan Terms Deadline", 
      isText: true,
      type: "checkbox",
      top: 643.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedNew Loan Terms Deadline", 
      isText: true,
      type: "checkbox",
      top: 643.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineNew Loan Availability Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 654.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineNew Loan Availability Deadline", 
      fontSize: 9,
      type: "date",
      top: 654.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeNew Loan Availability Deadline", 
      isText: true,
      type: "checkbox",
      top: 655.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedNew Loan Availability Deadline", 
      isText: true,
      type: "checkbox",
      top: 655.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineBuyers Credit Information Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 666.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineBuyers Credit Information Deadline", 
      fontSize: 9,
      type: "date",
      top: 666.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeBuyers Credit Information Deadline", 
      isText: true,
      type: "checkbox",
      top: 667.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedBuyers Credit Information Deadline", 
      isText: true,
      type: "checkbox",
      top: 667.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineDisapproval of Buyers Credit Information Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 678.12,
      left: 335.014,
      width: 108.026,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineDisapproval of Buyers Credit Information Deadline", 
      fontSize: 9,
      type: "date",
      top: 678.646,
      left: 444.355,
      width: 55.335,
      height: 10.523
   }
   ,
   {
      page: 0,
      name: "No ChangeDisapproval of Buyers Credit Information Deadline", 
      isText: true,
      type: "checkbox",
      top: 679.12,
      left: 514.903,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "DeletedDisapproval of Buyers Credit Information Deadline", 
      isText: true,
      type: "checkbox",
      top: 679.12,
      left: 554.603,
      width: 8.299,
      height: 7.823
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineExisting Loan Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 690.12,
      left: 335.014,
      width: 108.026,
      height: 10.5227
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineExisting Loan Deadline", 
      fontSize: 9,
      type: "date",
      top: 690.646,
      left: 444.355,
      width: 55.335,
      height: 10.5228
   }
   ,
   {
      page: 0,
      name: "No ChangeExisting Loan Deadline", 
      isText: true,
      type: "checkbox",
      top: 691.12,
      left: 514.903,
      width: 8.299,
      height: 7.8229
   }
   ,
   {
      page: 0,
      name: "DeletedExisting Loan Deadline", 
      isText: true,
      type: "checkbox",
      top: 691.12,
      left: 554.603,
      width: 8.299,
      height: 7.8229
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineExisting Loan Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 702.12,
      left: 335.014,
      width: 108.026,
      height: 10.5227
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineExisting Loan Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 702.6461,
      left: 444.355,
      width: 55.335,
      height: 10.5227
   }
   ,
   {
      page: 0,
      name: "No ChangeExisting Loan Termination Deadline", 
      isText: true,
      type: "checkbox",
      top: 703.12,
      left: 514.903,
      width: 8.299,
      height: 7.8229
   }
   ,
   {
      page: 0,
      name: "DeletedExisting Loan Termination Deadline", 
      isText: true,
      type: "checkbox",
      top: 703.12,
      left: 554.603,
      width: 8.299,
      height: 7.8229
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineLoan Transfer Approval Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 714.12,
      left: 335.014,
      width: 108.026,
      height: 10.5227
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineLoan Transfer Approval Deadline", 
      fontSize: 9,
      type: "date",
      top: 714.6461,
      left: 444.355,
      width: 55.335,
      height: 10.5227
   }
   ,
   {
      page: 0,
      name: "No ChangeLoan Transfer Approval Deadline", 
      isText: true,
      type: "checkbox",
      top: 715.12,
      left: 514.903,
      width: 8.299,
      height: 7.8229
   }
   ,
   {
      page: 0,
      name: "DeletedLoan Transfer Approval Deadline", 
      isText: true,
      type: "checkbox",
      top: 715.12,
      left: 554.603,
      width: 8.299,
      height: 7.8229
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineSeller or Private Financing Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 726.12,
      left: 335.014,
      width: 108.026,
      height: 10.5227
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineSeller or Private Financing Deadline", 
      fontSize: 9,
      type: "date",
      top: 726.6461,
      left: 444.355,
      width: 55.335,
      height: 10.5227
   }
   ,
   {
      page: 0,
      name: "No ChangeSeller or Private Financing Deadline", 
      isText: true,
      type: "checkbox",
      top: 727.12,
      left: 514.903,
      width: 8.299,
      height: 7.8229
   }
   ,
   {
      page: 0,
      name: "DeletedSeller or Private Financing Deadline", 
      isText: true,
      type: "checkbox",
      top: 727.12,
      left: 554.603,
      width: 8.299,
      height: 7.8229
   }
   ,
   {
      page: 1,
      name: "Appraisal Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 71.595,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Appraisal Deadline", 
      fontSize: 9,
      type: "date",
      top: 71.595,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text2", 
      isText: true,
      type: "checkbox",
      top: 71.909,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text3", 
      isText: true,
      type: "checkbox",
      top: 72.145,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Appraisal Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 82.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Appraisal Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 82.56,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text4", 
      isText: true,
      type: "checkbox",
      top: 84.145,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text5", 
      isText: true,
      type: "checkbox",
      top: 83.164,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Appraisal Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 94.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Appraisal Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 94.56,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text6", 
      isText: true,
      type: "checkbox",
      top: 95.927,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text7", 
      isText: true,
      type: "checkbox",
      top: 96.255,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "New ILC or New Survey Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 118.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField New ILC or New Survey Deadline", 
      fontSize: 9,
      type: "date",
      top: 118.56,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text8", 
      isText: true,
      type: "checkbox",
      top: 119.982,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text9", 
      isText: true,
      type: "checkbox",
      top: 119.982,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "New ILC or New Survey Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 130.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField New ILC or New Survey Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 130.56,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text10", 
      isText: true,
      type: "checkbox",
      top: 132.091,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text11", 
      isText: true,
      type: "checkbox",
      top: 131.6,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "New ILC or New Survey Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 142.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField New ILC or New Survey Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 142.56,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text12", 
      isText: true,
      type: "checkbox",
      top: 143.873,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text13", 
      isText: true,
      type: "checkbox",
      top: 143.709,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Water Rights Examination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 166.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Water Rights Examination Deadline", 
      fontSize: 9,
      type: "date",
      top: 166.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text14", 
      isText: true,
      type: "checkbox",
      top: 167.927,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text15", 
      isText: true,
      type: "checkbox",
      top: 167.764,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Mineral Rights Examination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 178.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Mineral Rights Examination Deadline", 
      fontSize: 9,
      type: "date",
      top: 178.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text16", 
      isText: true,
      type: "checkbox",
      top: 179.873,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text17", 
      isText: true,
      type: "checkbox",
      top: 179.709,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Inspection Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 190.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Inspection Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 190.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text18", 
      isText: true,
      type: "checkbox",
      top: 192.145,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text19", 
      isText: true,
      type: "checkbox",
      top: 192.145,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Inspection Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 202.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Inspection Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 202.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text20", 
      isText: true,
      type: "checkbox",
      top: 204.254,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text21", 
      isText: true,
      type: "checkbox",
      top: 204.173,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Inspection Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 214.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Inspection Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 214.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text22", 
      isText: true,
      type: "checkbox",
      top: 216.445,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text23", 
      isText: true,
      type: "checkbox",
      top: 216.282,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Property Insurance Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 226.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Property Insurance Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 226.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text24", 
      isText: true,
      type: "checkbox",
      top: 228.145,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text25", 
      isText: true,
      type: "checkbox",
      top: 228.063,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Due Diligence Documents Delivery Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 238.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Due Diligence Documents Delivery Deadline", 
      fontSize: 9,
      type: "date",
      top: 238.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text26", 
      isText: true,
      type: "checkbox",
      top: 240.091,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text27", 
      isText: true,
      type: "checkbox",
      top: 239.845,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Due Diligence Documents Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 250.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Due Diligence Documents Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 250.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text28", 
      isText: true,
      type: "checkbox",
      top: 252.2,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text29", 
      isText: true,
      type: "checkbox",
      top: 251.954,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Due Diligence Documents Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 262.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Due Diligence Documents Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 262.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text30", 
      isText: true,
      type: "checkbox",
      top: 264.309,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text31", 
      isText: true,
      type: "checkbox",
      top: 264.063,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Environmental Inspection Objection Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 274.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Environmental Inspection Objection Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 274.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text32", 
      isText: true,
      type: "checkbox",
      top: 276.173,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text33", 
      isText: true,
      type: "checkbox",
      top: 275.845,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "ADA Evaluation Termination Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 286.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField ADA Evaluation Termination Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 286.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text34", 
      isText: true,
      type: "checkbox",
      top: 288.363,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text35", 
      isText: true,
      type: "checkbox",
      top: 288.036,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Conditional Sale Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 298.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Conditional Sale Deadline", 
      fontSize: 9,
      type: "date",
      top: 298.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text36", 
      isText: true,
      type: "checkbox",
      top: 300.145,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text37", 
      isText: true,
      type: "checkbox",
      top: 300.227,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "LeadBased Paint Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 310.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField LeadBased Paint Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 310.56,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text38", 
      isText: true,
      type: "checkbox",
      top: 312.009,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text39", 
      isText: true,
      type: "checkbox",
      top: 312.091,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Estoppel Statements Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 322.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Estoppel Statements Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 322.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text40", 
      isText: true,
      type: "checkbox",
      top: 324.2,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text41", 
      isText: true,
      type: "checkbox",
      top: 324.2,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Estoppel Statements Termination Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 334.56,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Estoppel Statements Termination Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 334.5,
      left: 440.986,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text42", 
      isText: true,
      type: "checkbox",
      top: 336.063,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text43", 
      isText: true,
      type: "checkbox",
      top: 335.982,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Closing Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 359.111,
      left: 348.49,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "DateField Closing Date", 
      fontSize: 9,
      type: "date",
      top: 359.11,
      left: 440.908,
      width: 57.436,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text44#1", 
      isText: true,
      type: "checkbox",
      top: 360.058,
      left: 513.666,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text46#1", 
      isText: true,
      type: "checkbox",
      top: 360.058,
      left: 551.666,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Possession Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 370.68,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   ,
   {
      page: 1,
      name: "Text44#0", 
      isText: true,
      type: "checkbox",
      top: 372.71,
      left: 514.071,
      width: 8.270,
      height: 7.675
   }
   ,
   {
      page: 1,
      name: "Text46#0", 
      isText: true,
      type: "checkbox",
      top: 372.145,
      left: 551.752,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Possession Time", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 382.68,
      left: 348.568,
      width: 90.163,
      height: 9.132
   }
   // ,
   // {
   //    page: 1,
   //    name: "DateField Possession Time", 
   //    fontSize: 9,
   //    type: "date",
   //    top: 382.68,
   //    left: 440.986,
   //    width: 57.436,
   //    height: 9.132
   // }
   ,
   {
      page: 1,
      name: "Text45", 
      isText: true,
      type: "checkbox",
      top: 384.254,
      left: 514.071,
      width: 8.270,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text47", 
      isText: true,
      type: "checkbox",
      top: 384.091,
      left: 552.153,
      width: 7.869,
      height: 8.076
   }
   ,
   {
      page: 1,
      name: "Text48", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 12,
      top: 442.155,
      left: 51.1931,
      width: 520.7839,
      height: 56.292
   }
   ,
   {
      page: 1,
      name: "Text49", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 12,
      top: 511.306,
      left: 50.4233,
      width: 521.5537,
      height: 167.676
   }
   ,
{
   page: 1,
   name: "DateField_AmendAcceptDate", 
   fontSize: 9,
   type: "date",
   top: 716.6268,
   left: 311.16,
   width: 87.065,
   height: 10.6801
}
,
{
   page: 1,
   name: "DateField_AmendAcceptTime", 
   fontSize: 9,
   type: "text",
   top: 716.6268,
   left: 399.269,
   width: 115.211,
   height: 10.6801
}
   ,
   {
      page: 2,
      name: "Buyers Name", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 88.5,
      left: 117.96,
      width: 180.12,
      height: 17.936
   }
   ,
   {
      page: 2,
      name: "Buyers Name_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 88.5,
      left: 388.597,
      width: 188.160,
      height: 17.936
   }
   // ,
   // {
   //    page: 2,
   //    name: "Buyers Signature", 
   //    type: "signature",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 112.56,
   //    left: 50.4,
   //    width: 178.2,
   //    height: 28.80
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Date_2", 
   //    fontSize: 9,
   //    type: "date",
   //    top: 112.56,
   //    left: 232.92,
   //    width: 65.16,
   //    height: 28.80
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Buyers Signature_2", 
   //    type: "signature",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 112.56,
   //    left: 316.92,
   //    width: 186.84,
   //    height: 28.80
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Date_3", 
   //    fontSize: 9,
   //    type: "date",
   //    top: 112.56,
   //    left: 508.08,
   //    width: 68.16,
   //    height: 28.80
   // }
   ,
   {
      page: 2,
      name: "Sellers Name", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 177,
      left: 117.96,
      width: 180.12,
      height: 17.936
   }
   ,
   {
      page: 2,
      name: "Sellers Name_2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 177,
      left: 388.08,
      width: 188.16,
      height: 17.936
   }
   // ,
   // {
   //    page: 2,
   //    name: "Sellers Signature", 
   //    type: "signature",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 199.68,
   //    left: 50.4,
   //    width: 178.2,
   //    height: 28.80
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Date_4", 
   //    fontSize: 9,
   //    type: "date",
   //    top: 199.68,
   //    left: 232.92,
   //    width: 65.16,
   //    height: 28.80
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Sellers Signature_2", 
   //    type: "signature",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 199.68,
   //    left: 316.92,
   //    width: 186.84,
   //    height: 28.80
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Date_5", 
   //    fontSize: 9,
   //    type: "date",
   //    top: 199.68,
   //    left: 508.08,
   //    width: 68.16,
   //    height: 28.80
   // }
   ] }
   