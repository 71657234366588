export function farmandRanchContract() {
return [   //2024 Release 2024-02-18 21:23:59
{
   page: 0,
   name: "FARM AND RANCH CONTRACT", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 79.335,
   left: 275.797,
   width: 279.563,
   height: 11.276
}
,
{
   page: 0,
   name: "1 PARTIES The parties to this contract are", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 91.295,
   left: 121.753,
   width: 297.000,
   height: 9.403
}
,
{
   page: 0,
   name: "reservations are collectively referred to as the Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 132.76,
   left: 352.387,
   width: 203.070,
   height: 11.904
}
,
{
   page: 0,
   name: "A LAND The land situated in the County of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 142.859,
   left: 70.1345,
   width: 485.3455,
   height: 12.913
}
,
{
   page: 0,
   name: "described as follows", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 154.859,
   left: 210.557,
   width: 344.923,
   height: 13.359
}
,
{
   page: 0,
   name: "addresszip code together with all rights privileges and appurtenances pertaining thereto", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 167.732,
   left: 68.8636,
   width: 486.6164,
   height: 12.022
}
,
{
   page: 0,
   name: "addresszip code together with all rights privileges and appurtenances pertaining thereto-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 179.315,
   left: 69.161,
   width: 486.616,
   height: 12.705
}
,
{
   page: 0,
   name: "addresszip code together with all rights privileges and appurtenances pertaining thereto-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 189.15,
   left: 327.433,
   width: 227.898,
   height: 12.914
}
,
{
   page: 0,
   name: "portable", 
   isText: false,
   type: "checkbox",
   top: 338.769,
   left: 217.198,
   width: 11.122,
   height: 9.871
}
,
{
   page: 0,
   name: "hunting", 
   isText: false,
   type: "checkbox",
   top: 339.287,
   left: 325.6,
   width: 11.52,
   height: 9.353
}
,
{
   page: 0,
   name: "game", 
   isText: false,
   type: "checkbox",
   top: 339.241,
   left: 414.8,
   width: 11.52,
   height: 9.399
}
,
{
   page: 0,
   name: "livestock feeders and troughs", 
   isText: false,
   type: "checkbox",
   top: 339.247,
   left: 502.88,
   width: 11.52,
   height: 9.353
}
,
{
   page: 0,
   name: "irrigation equipment", 
   isText: false,
   type: "checkbox",
   top: 348.729,
   left: 193.321,
   width: 10.559,
   height: 9.871
}
,
{
   page: 0,
   name: "undefined", 
   isText: false,
   type: "checkbox",
   top: 349.764,
   left: 317.204,
   width: 10.956,
   height: 8.836
}
,
{
   page: 0,
   name: "submersible", 
   isText: false,
   type: "checkbox",
   top: 349.247,
   left: 387.64,
   width: 11.52,
   height: 9.353
}
,
{
   page: 0,
   name: "pressure tanks", 
   isText: false,
   type: "checkbox",
   top: 349.207,
   left: 502.044,
   width: 12.556,
   height: 9.353
}
,
{
   page: 0,
   name: "corrals", 
   isText: false,
   type: "checkbox",
   top: 359.207,
   left: 116.562,
   width: 12.158,
   height: 9.353
}
,
{
   page: 0,
   name: "gates", 
   isText: false,
   type: "checkbox",
   top: 358.171,
   left: 170.436,
   width: 10.604,
   height: 10.389
}
,
{
   page: 0,
   name: "chutes", 
   isText: false,
   type: "checkbox",
   top: 358.689,
   left: 214.962,
   width: 12.038,
   height: 9.871
}
,
{
   page: 0,
   name: "other", 
   isText: false,
   type: "checkbox",
   top: 359.207,
   left: 267.08,
   width: 11.52,
   height: 9.353
}
,
{
   page: 0,
   name: "fuel tanks", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 358.805,
   left: 314.16,
   width: 240.781,
   height: 10.595
}
,
{
   page: 0,
   name: "pumps-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 369.449,
   left: 86.88,
   width: 468.48,
   height: 11.246
}
,
{
   page: 0,
   name: "pumps", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 380.195,
   left: 87.5966,
   width: 468.4804,
   height: 9.253
}
,
{
   page: 0,
   name: "The following improvements accessories and crops will be retained by Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 494.07,
   left: 317.448,
   width: 237.281,
   height: 11.013
}
,
{
   page: 0,
   name: "and must be removed prior to delivery of possession", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 503.051,
   left: 70.4036,
   width: 483.3594,
   height: 11.307
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 545.916,
   left: 426.893,
   width: 125.640,
   height: 10.331
}
,
{
   page: 0,
   name: "A Cash portion of Sales Price payable by Buyer at closing", 
   isText: false,
   type: "checkbox",
   top: 576.501,
   left: 308.925,
   width: 10.162,
   height: 8.753
}
,
{
   page: 0,
   name: "Check Box27", 
   isText: false,
   type: "checkbox",
   top: 586.164,
   left: 68.1193,
   width: 10.6739,
   height: 10.111
}
,
{
   page: 0,
   name: "Seller Financing Addendum", 
   isText: false,
   type: "checkbox",
   top: 587.421,
   left: 243.165,
   width: 10.162,
   height: 8.753
}
,
{
   page: 0,
   name: "Third Party Financing Addendum", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 587.829,
   left: 426.447,
   width: 125.640,
   height: 10.331
}
,
{
   page: 0,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 598.989,
   left: 426.447,
   width: 127.200,
   height: 10.331
}
,
{
   page: 0,
   name: "will", 
   isText: false,
   type: "checkbox",
   top: 609.383,
   left: 148.527,
   width: 11.520,
   height: 9.871
}
,
{
   page: 0,
   name: "C Sales Price Sum of A and B", 
   isText: false,
   type: "checkbox",
   top: 609.383,
   left: 183.567,
   width: 11.640,
   height: 9.871
}
,
{
   page: 0,
   name: "will not be adjusted based on the survey required by Paragraph 6C", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 628.529,
   left: 68.7671,
   width: 47.6889,
   height: 10.659
}
,
{
   page: 0,
   name: "per acre If the Sales Price is adjusted by more than 10 either party may terminate this", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 638.81,
   left: 464.233,
   width: 49.911,
   height: 9.971
}
,
{
   page: 0,
   name: "which the license holder or the license holders spouse parent or child is a beneficiary to notify the-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 666.515,
   left: 258.777,
   width: 25.454,
   height: 11.552
}
,
{
   page: 0,
   name: "3A", 
   isText: false,
   type: "checkbox",
   top: 685.514,
   left: 243.763,
   width: 12.084,
   height: 9.399
}
,
{
   page: 0,
   name: "3B", 
   isText: false,
   type: "checkbox",
   top: 685.042,
   left: 275.849,
   width: 12.038,
   height: 9.871
}
,
{
   page: 0,
   name: "proportionately to 3A and 3B", 
   isText: false,
   type: "checkbox",
   top: 685.297,
   left: 312.485,
   width: 11.002,
   height: 10.317
}
,
{
   page: 0,
   name: "Residential", 
   isText: false,
   type: "checkbox",
   top: 735.39,
   left: 43.1859,
   width: 11.0021,
   height: 8.605
}
,
{
   page: 1,
   name: "Page 2 of 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 27.521,
   left: 134.04,
   width: 322.374,
   height: 11.933
}
,
{
   page: 1,
   name: "Fixture 1", 
   isText: false,
   type: "checkbox",
   top: 53.316,
   left: 43.2771,
   width: 11.0021,
   height: 9.871
}
,
{
   page: 1,
   name: "Natural Resources", 
   isText: false,
   type: "checkbox",
   top: 85.316,
   left: 42.7232,
   width: 10.5560,
   height: 9.871
}
,
{
   page: 1,
   name: "Seller has", 
   isText: false,
   type: "checkbox",
   top: 107.759,
   left: 55.858,
   width: 11.002,
   height: 9.871
}
,
{
   page: 1,
   name: "Seller has not", 
   isText: false,
   type: "checkbox",
   top: 119.759,
   left: 55.858,
   width: 11.002,
   height: 9.871
}
,
{
   page: 1,
   name: "which the license holder or the license holders spouse parent or child is a beneficiary to notify the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 137.823,
   left: 277.11,
   width: 54.003,
   height: 12.444
}
,
{
   page: 1,
   name: "Fixture 1-3", 
   isText: false,
   type: "checkbox",
   top: 161.218,
   left: 42.4867,
   width: 11.0021,
   height: 9.871
}
,
{
   page: 1,
   name: "Natural Resources-4", 
   isText: false,
   type: "checkbox",
   top: 197.218,
   left: 55.4867,
   width: 11.0021,
   height: 9.871
}
,
{
   page: 1,
   name: "Seller has-6", 
   isText: false,
   type: "checkbox",
   top: 211.661,
   left: 55.0676,
   width: 11.0020,
   height: 9.871
}
,
{
   page: 1,
   name: "The following improvements accessories and crops will be retained by Seller-5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 225.17,
   left: 351.698,
   width: 196.689,
   height: 11.459
}
,
{
   page: 1,
   name: "and must be removed prior to delivery of possession-6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 233.597,
   left: 68.9683,
   width: 478.4527,
   height: 12.616
}
,
{
   page: 1,
   name: "Seller has not-7", 
   isText: false,
   type: "checkbox",
   top: 247.661,
   left: 55.0676,
   width: 11.0020,
   height: 9.871
}
,
{
   page: 1,
   name: "3 days 2-5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 280.687,
   left: 402.754,
   width: 38.894,
   height: 11.375
}
,
{
   page: 1,
   name: "other party in writing before entering into a contract of sale Disclose if applicable", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 327.704,
   left: 148.284,
   width: 249.252,
   height: 12.091
}
,
{
   page: 1,
   name: "5 EARNEST", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 329.939,
   left: 494.634,
   width: 63.733,
   height: 9.763
}
,
{
   page: 1,
   name: "as earnest money to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 339.284,
   left: 67.2698,
   width: 263.3422,
   height: 11.701
}
,
{
   page: 1,
   name: "at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 340.24,
   left: 394.27,
   width: 106.045,
   height: 9.581
}
,
{
   page: 1,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 348.928,
   left: 135.897,
   width: 108.283,
   height: 12.275
}
,
{
   page: 1,
   name: "to escrow", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 371.253,
   left: 334.008,
   width: 105.119,
   height: 12.275
}
,
{
   page: 1,
   name: "3 days 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 382.462,
   left: 85.8003,
   width: 38.8937,
   height: 11.375
}
,
{
   page: 1,
   name: "3 days 2-7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 529.592,
   left: 69.5433,
   width: 45.5847,
   height: 11.821
}
,
{
   page: 1,
   name: "Sellers", 
   isText: false,
   type: "checkbox",
   top: 672.385,
   left: 303.982,
   width: 11.520,
   height: 9.353
}
,
{
   page: 1,
   name: "Buyers expense an owner policy of", 
   isText: false,
   type: "checkbox",
   top: 671.867,
   left: 362.062,
   width: 11.520,
   height: 9.871
}
,
{
   page: 1,
   name: "A TITLE POLICY Seller shall furnish to Buyer at", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 683.521,
   left: 265.913,
   width: 218.919,
   height: 10.053
}
,
{
   page: 2,
   name: "Page 3 of 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 28.397,
   left: 133.918,
   width: 322.301,
   height: 11.057
}
,
{
   page: 2,
   name: "i will not be amended or deleted from the title policy or", 
   isText: false,
   type: "checkbox",
   top: 99.212,
   left: 68.2384,
   width: 11.5200,
   height: 9.353
}
,
{
   page: 2,
   name: "ii will be amended to read shortages in area at the expense of", 
   isText: false,
   type: "checkbox",
   top: 110.292,
   left: 68.2384,
   width: 11.5200,
   height: 9.353
}
,
{
   page: 2,
   name: "Buyer", 
   isText: false,
   type: "checkbox",
   top: 109.292,
   left: 427.398,
   width: 11.520,
   height: 9.353
}
,
{
   page: 2,
   name: "Seller", 
   isText: false,
   type: "checkbox",
   top: 110.292,
   left: 482.316,
   width: 11.002,
   height: 9.353
}
,
{
   page: 2,
   name: "1 Within", 
   isText: false,
   type: "checkbox",
   top: 254.42,
   left: 54.5309,
   width: 11.5201,
   height: 9.353
}
,
{
   page: 2,
   name: "the Title Company and Buyers lenders Check one box only", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 255.656,
   left: 121.851,
   width: 43.020,
   height: 9.957
}
,
{
   page: 2,
   name: "will_2", 
   isText: false,
   type: "checkbox",
   top: 304.546,
   left: 126.895,
   width: 10.086,
   height: 9.353
}
,
{
   page: 2,
   name: "will not be recertified to a date subsequent to the Effective", 
   isText: false,
   type: "checkbox",
   top: 304.546,
   left: 164.057,
   width: 11.640,
   height: 9.353
}
,
{
   page: 2,
   name: "Buyer_2", 
   isText: false,
   type: "checkbox",
   top: 314.506,
   left: 222.857,
   width: 11.520,
   height: 9.353
}
,
{
   page: 2,
   name: "Seller If the existing survey is not", 
   isText: false,
   type: "checkbox",
   top: 314.506,
   left: 271.817,
   width: 12.556,
   height: 9.353
}
,
{
   page: 2,
   name: "Buyer_3", 
   isText: false,
   type: "checkbox",
   top: 324.546,
   left: 524.375,
   width: 11.002,
   height: 9.353
}
,
{
   page: 2,
   name: "Seller no later than 3 days prior to Closing Date", 
   isText: false,
   type: "checkbox",
   top: 334.546,
   left: 85.219,
   width: 12.158,
   height: 9.353
}
,
{
   page: 2,
   name: "2 Within", 
   isText: false,
   type: "checkbox",
   top: 344.988,
   left: 54.9769,
   width: 11.5202,
   height: 9.871
}
,
{
   page: 2,
   name: "expense of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 345.128,
   left: 122.297,
   width: 37.721,
   height: 10.451
}
,
{
   page: 2,
   name: "3 Within", 
   isText: false,
   type: "checkbox",
   top: 374.896,
   left: 55.5405,
   width: 10.9564,
   height: 9.963
}
,
{
   page: 2,
   name: "the date specified in this paragraph whichever is earlier", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 375.128,
   left: 122.537,
   width: 39.666,
   height: 10.451
}
,
{
   page: 2,
   name: "4 No survey is required", 
   isText: false,
   type: "checkbox",
   top: 394.546,
   left: 54.9769,
   width: 11.5202,
   height: 9.353
}
,
{
   page: 2,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 441.632,
   left: 429.887,
   width: 137.224,
   height: 12.646
}
,
{
   page: 2,
   name: "Management", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 452.717,
   left: 67.806,
   width: 498.627,
   height: 11.992
}
,
{
   page: 2,
   name: "Management 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 463.003,
   left: 67.681,
   width: 497.548,
   height: 11.992
}
,
{
   page: 2,
   name: "Commitment Exception Documents and the survey Buyers failure to object within the time", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 474.655,
   left: 366.639,
   width: 34.920,
   height: 9.887
}
,
{
   page: 2,
   name: "Document 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 650.938,
   left: 69.7607,
   width: 228.1633,
   height: 13.961
}
,
{
   page: 2,
   name: "Date 1", 
   fontSize: 9,
   type: "date",
   top: 652.442,
   left: 318.954,
   width: 97.033,
   height: 12.477
}
,
{
   page: 2,
   name: "Recording Reference 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 651.544,
   left: 443.079,
   width: 125.011,
   height: 12.463
}
,
{
   page: 2,
   name: "Document 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 667.683,
   left: 69.3147,
   width: 228.6093,
   height: 13.976
}
,
{
   page: 2,
   name: "Date 2", 
   fontSize: 9,
   type: "date",
   top: 668.307,
   left: 319.062,
   width: 97.033,
   height: 13.352
}
,
{
   page: 2,
   name: "Recording Reference 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 668.22,
   left: 442.633,
   width: 125.457,
   height: 13.439
}
,
{
   page: 2,
   name: "SURFACE LEASES Prior to the execution of the contract Seller has provided Buyer with copies", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 683.613,
   left: 68.8686,
   width: 229.5014,
   height: 14.006
}
,
{
   page: 2,
   name: "Date 3", 
   fontSize: 9,
   type: "date",
   top: 685.351,
   left: 318.616,
   width: 98.371,
   height: 12.268
}
,
{
   page: 2,
   name: "Recording Reference 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 684.192,
   left: 442.187,
   width: 126.349,
   height: 12.893
}
,
{
   page: 2,
   name: "The following Leases will be permitted exceptions in the Title Policy and will not be a basis for", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 710.5699,
   left: 293.202,
   width: 272.215,
   height: 11.9330
}
,
{
   page: 2,
   name: "objection to title", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 722.103,
   left: 68.041,
   width: 497.376,
   height: 11.4847
}
,
{
   page: 3,
   name: "Page 4 of 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 27.637,
   left: 137.04,
   width: 317.395,
   height: 10.925
}
,
{
   page: 3,
   name: "information", 
   isText: false,
   type: "checkbox",
   top: 377.224,
   left: 424.409,
   width: 12.038,
   height: 9.353
}
,
{
   page: 3,
   name: "is", 
   isText: false,
   type: "checkbox",
   top: 377.813,
   left: 456.79,
   width: 9.175,
   height: 8.764
}
,
{
   page: 3,
   name: "The following Leases will be permitted exceptions in the Title Policy and will not be a basis for-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 555.318,
   left: 296.525,
   width: 272.215,
   height: 11.041
}
,
{
   page: 3,
   name: "objection to title-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 565.19,
   left: 89.2069,
   width: 479.5331,
   height: 12.377
}
,
{
   page: 3,
   name: "objection to title-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 575.705,
   left: 89.2069,
   width: 477.7491,
   height: 10.592
}
,
{
   page: 3,
   name: "1 Buyer has received the Notice", 
   isText: false,
   type: "checkbox",
   top: 688.314,
   left: 58.1904,
   width: 11.5200,
   height: 8.7198
}
,
{
   page: 3,
   name: "2 Buyer has not received the Notice", 
   isText: false,
   type: "checkbox",
   top: 697.7608,
   left: 58.4682,
   width: 11.1222,
   height: 9.3530
}
,
{
   page: 3,
   name: "contract Seller shall deliver the Notice to Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 696.8198,
   left: 304.11,
   width: 37.702,
   height: 11.7239
}
,
{
   page: 3,
   name: "3 The Texas Property Code does not require this Seller to furnish the Notice", 
   isText: false,
   type: "checkbox",
   top: 751.0118,
   left: 58.6704,
   width: 11.5200,
   height: 10.1020
}
,
{
   page: 4,
   name: "Contract Concerning", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 29.363,
   left: 137.04,
   width: 317.395,
   height: 9.645
}
,
{
   page: 4,
   name: "1 Buyer accepts the Property As Is", 
   isText: false,
   type: "checkbox",
   top: 130.199,
   left: 65.4172,
   width: 10.6800,
   height: 9.271
}
,
{
   page: 4,
   name: "2 Buyer accepts the Property As Is provided Seller at Sellers expense shall complete the", 
   isText: false,
   type: "checkbox",
   top: 140.001,
   left: 65.1772,
   width: 10.6800,
   height: 9.789
}
,
{
   page: 4,
   name: "undefined_11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 149.854,
   left: 309.813,
   width: 265.679,
   height: 9.983
}
,
{
   page: 4,
   name: "following specific repairs and treatments", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 158.586,
   left: 96.1408,
   width: 479.1592,
   height: 11.187
}
,
{
   page: 4,
   name: "information-1", 
   isText: false,
   type: "checkbox",
   top: 381.719,
   left: 127.0,
   width: 12.038,
   height: 9.353
}
,
{
   page: 4,
   name: "is1", 
   isText: false,
   type: "checkbox",
   top: 382.308,
   left: 165.381,
   width: 9.175,
   height: 8.764
}
,
{
   page: 4,
   name: "information-2", 
   isText: false,
   type: "checkbox",
   top: 400.719,
   left: 127.0,
   width: 12.038,
   height: 9.353
}
,
{
   page: 4,
   name: "is-2", 
   isText: false,
   type: "checkbox",
   top: 401.308,
   left: 165.381,
   width: 9.175,
   height: 8.764
}
,
{
   page: 4,
   name: "information-3", 
   isText: false,
   type: "checkbox",
   top: 418.719,
   left: 127.0,
   width: 12.038,
   height: 9.353
}
,
{
   page: 4,
   name: "is-3", 
   isText: false,
   type: "checkbox",
   top: 419.308,
   left: 165.381,
   width: 9.175,
   height: 8.764
}
,
{
   page: 4,
   name: "information-4", 
   isText: false,
   type: "checkbox",
   top: 437.719,
   left: 127.0,
   width: 12.038,
   height: 9.353
}
,
{
   page: 4,
   name: "is-4", 
   isText: false,
   type: "checkbox",
   top: 438.308,
   left: 165.827,
   width: 8.729,
   height: 8.764
}
,
{
   page: 4,
   name: "information-5", 
   isText: false,
   type: "checkbox",
   top: 455.952,
   left: 127.0,
   width: 12.038,
   height: 9.353
}
,
{
   page: 4,
   name: "is-5", 
   isText: false,
   type: "checkbox",
   top: 456.541,
   left: 165.381,
   width: 9.175,
   height: 8.764
}
,
{
   page: 4,
   name: "information-6", 
   isText: false,
   type: "checkbox",
   top: 474.952,
   left: 127.0,
   width: 12.038,
   height: 9.353
}
,
{
   page: 4,
   name: "is-6", 
   isText: false,
   type: "checkbox",
   top: 475.541,
   left: 165.381,
   width: 9.175,
   height: 8.764
}
,
{
   page: 4,
   name: "information-7", 
   isText: false,
   type: "checkbox",
   top: 492.952,
   left: 127.0,
   width: 12.038,
   height: 9.353
}
,
{
   page: 4,
   name: "is-7", 
   isText: false,
   type: "checkbox",
   top: 493.541,
   left: 165.381,
   width: 9.175,
   height: 8.764
}
,
{
   page: 4,
   name: "information-71", 
   isText: false,
   type: "checkbox",
   top: 492.952,
   left: 388.0,
   width: 12.038,
   height: 9.353
}
,
{
   page: 4,
   name: "is-72", 
   isText: false,
   type: "checkbox",
   top: 493.541,
   left: 443.381,
   width: 9.175,
   height: 8.764
}
,
{
   page: 4,
   name: "information-8", 
   isText: false,
   type: "checkbox",
   top: 502.952,
   left: 127.0,
   width: 12.038,
   height: 9.353
}
,
{
   page: 4,
   name: "is-8", 
   isText: false,
   type: "checkbox",
   top: 503.541,
   left: 165.381,
   width: 9.175,
   height: 8.764
}
,
{
   page: 4,
   name: "following specific repairs and treatments2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 519.872,
   left: 78.2424,
   width: 497.8936,
   height: 12.525
}
,
{
   page: 4,
   name: "amount not exceeding", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 559.009,
   left: 370.159,
   width: 95.181,
   height: 10.325
}
,
{
   page: 4,
   name: "or on the attached exhibit", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 608.263,
   left: 198.967,
   width: 374.285,
   height: 11.121
}
,
{
   page: 4,
   name: "2 on the attached", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 618.027,
   left: 78.3314,
   width: 495.8106,
   height: 11.121
}
,
{
   page: 4,
   name: "Disclose if appl", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 711.1139,
   left: 231.018,
   width: 343.559,
   height: 10.6751
}
,
{
   page: 4,
   name: "Disclose if appl 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 721.403,
   left: 78.7643,
   width: 496.7027,
   height: 11.1212
}
,
{
   page: 4,
   name: "Disclose if appl 2-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 730.225,
   left: 78.3919,
   width: 494.0261,
   height: 11.1213
}
,
{
   page: 5,
   name: "Contract Concerning_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 28.573,
   left: 137.04,
   width: 320.071,
   height: 10.881
}
,
{
   page: 5,
   name: "A The closing of the sale will be on or before", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 63.677,
   left: 298.619,
   width: 132.480,
   height: 14.423
}
,
{
   page: 5,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 65.368,
   left: 452.477,
   width: 28.182,
   height: 13.250
}
,
{
   page: 5,
   name: "A Buyers Possession Seller shall deliver to Buyer possession of the Property in its present or required", 
   isText: false,
   type: "checkbox",
   top: 270.48,
   left: 339.317,
   width: 11.002,
   height: 8.836
}
,
{
   page: 5,
   name: "temporary residential lease form promulgated by TREC or other written lease required by the", 
   isText: false,
   type: "checkbox",
   top: 270.963,
   left: 484.201,
   width: 12.038,
   height: 9.353
}
,
{
   page: 5,
   name: "Text10-3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 453.446,
   left: 351.932,
   width: 216.370,
   height: 11.557
}
,
{
   page: 5,
   name: "Text10-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 463.706,
   left: 62.8813,
   width: 505.4207,
   height: 11.111
}
,
{
   page: 5,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 472.921,
   left: 62.9009,
   width: 500.9601,
   height: 11.111
}
,
{
   page: 5,
   name: "b Seller shall also pay an amount not to exceed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 537.548,
   left: 356.803,
   width: 83.302,
   height: 12.087
}
,
{
   page: 6,
   name: "Contract Concerning_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 28.628,
   left: 136.476,
   width: 320.071,
   height: 10.857
}
,
{
   page: 7,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 28.606,
   left: 132.359,
   width: 323.640,
   height: 10.086
}
,
{
   page: 7,
   name: "at_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 203.025,
   left: 134.024,
   width: 165.403,
   height: 15.003
}
,
{
   page: 7,
   name: "undefined_17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 246.589,
   left: 62.423,
   width: 235.517,
   height: 15.166
}
,
{
   page: 7,
   name: "Text2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 288.287,
   left: 141.072,
   width: 24.182,
   height: 14.644
}
,
{
   page: 7,
   name: "Text3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 287.853,
   left: 168.514,
   width: 127.678,
   height: 15.078
}
,
{
   page: 7,
   name: "Text5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 333.814,
   left: 135.3,
   width: 160.588,
   height: 14.887
}
,
{
   page: 7,
   name: "Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 377.862,
   left: 134.551,
   width: 162.238,
   height: 14.256
}
,
{
   page: 7,
   name: "Email-1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 419.757,
   left: 62.7373,
   width: 233.6087,
   height: 14.256
}
,
{
   page: 7,
   name: "at_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 203.138,
   left: 390.73,
   width: 163.518,
   height: 14.811
}
,
{
   page: 7,
   name: "undefined_18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 245.675,
   left: 318.639,
   width: 236.084,
   height: 16.100
}
,
{
   page: 7,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 289.066,
   left: 396.069,
   width: 23.663,
   height: 13.865
}
,
{
   page: 7,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 288.211,
   left: 424.148,
   width: 128.482,
   height: 14.353
}
,
{
   page: 7,
   name: "Text9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 332.592,
   left: 389.913,
   width: 163.636,
   height: 16.556
}
,
{
   page: 7,
   name: "Email_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 378.56,
   left: 389.005,
   width: 162.782,
   height: 13.264
}
,
{
   page: 7,
   name: "Email_2-2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 420.699,
   left: 319.399,
   width: 233.261,
   height: 13.264
}
,
{
   page: 7,
   name: "Third Party Financing Addendum_2", 
   isText: false,
   type: "checkbox",
   top: 480.407,
   left: 60.9374,
   width: 12.0378,
   height: 9.871
}
,
{
   page: 7,
   name: "Seller Financing Addendum_2", 
   isText: false,
   type: "checkbox",
   top: 497.807,
   left: 61.4552,
   width: 11.5200,
   height: 9.871
}
,
{
   page: 7,
   name: "Addendum for Property Subject to Mandatory", 
   isText: false,
   type: "checkbox",
   top: 512.889,
   left: 61.4552,
   width: 12.0378,
   height: 10.389
}
,
{
   page: 7,
   name: "Buyers Temporary Residential Lease", 
   isText: false,
   type: "checkbox",
   top: 546.407,
   left: 61.973,
   width: 11.0022,
   height: 9.871
}
,
{
   page: 7,
   name: "Loan Assumption Addendum", 
   isText: false,
   type: "checkbox",
   top: 561.407,
   left: 61.4552,
   width: 11.5200,
   height: 9.871
}
,
{
   page: 7,
   name: "Addendum for Sale of Other Property by", 
   isText: false,
   type: "checkbox",
   top: 575.889,
   left: 61.4552,
   width: 11.5200,
   height: 10.389
}
,
{
   page: 7,
   name: "Addendum for BackUp Contract", 
   isText: false,
   type: "checkbox",
   top: 600.407,
   left: 61.4552,
   width: 11.5200,
   height: 9.871
}
,
{
   page: 7,
   name: "Addendum for Coastal Area Property", 
   isText: false,
   type: "checkbox",
   top: 615.407,
   left: 61.973,
   width: 11.0022,
   height: 9.871
}
,
{
   page: 7,
   name: "Addendum for Authorizing Hydrostatic", 
   isText: false,
   type: "checkbox",
   top: 630.407,
   left: 61.4552,
   width: 11.5200,
   height: 9.871
}
,
{
   page: 7,
   name: "Addendum Concerning Right to Terminate", 
   isText: false,
   type: "checkbox",
   top: 655.885,
   left: 61.4552,
   width: 11.5200,
   height: 9.353
}
,
{
   page: 7,
   name: "Addendum for Reservation of Oil Gas and", 
   isText: false,
   type: "checkbox",
   top: 680.845,
   left: 61.4552,
   width: 11.5200,
   height: 9.353
}
,
{
   page: 7,
   name: "PID", 
   isText: false,
   type: "checkbox",
   top: 702.59,
   left: 61.7371,
   width: 11.5200,
   height: 9.353
}
,
{
   page: 7,
   name: "Environmental Assessment Threatened or", 
   isText: false,
   type: "checkbox",
   top: 480.499,
   left: 332.417,
   width: 12.556,
   height: 9.871
}
,
{
   page: 7,
   name: "Sellers Temporary Residential Lease", 
   isText: false,
   type: "checkbox",
   top: 512.057,
   left: 332.417,
   width: 11.520,
   height: 9.353
}
,
{
   page: 7,
   name: "Short Sale Addendum", 
   isText: false,
   type: "checkbox",
   top: 528.017,
   left: 332.417,
   width: 12.038,
   height: 9.353
}
,
{
   page: 7,
   name: "Addendum for Property Located Seaward", 
   isText: false,
   type: "checkbox",
   top: 544.579,
   left: 331.899,
   width: 11.002,
   height: 9.871
}
,
{
   page: 7,
   name: "Addendum for Sellers Disclosure of", 
   isText: false,
   type: "checkbox",
   top: 568.614,
   left: 332.417,
   width: 11.520,
   height: 9.872
}
,
{
   page: 7,
   name: "Addendum for Property in a Propane Gas", 
   isText: false,
   type: "checkbox",
   top: 608.539,
   left: 332.935,
   width: 11.002,
   height: 9.871
}
,
{
   page: 7,
   name: "Other list", 
   isText: false,
   type: "checkbox",
   top: 631.061,
   left: 332.935,
   width: 10.369,
   height: 10.389
}
,
{
   page: 7,
   name: "Fixture", 
   isText: false,
   type: "checkbox",
   top: 647.21,
   left: 332.149,
   width: 10.370,
   height: 10.389
}
,
{
   page: 7,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 662.843,
   left: 332.782,
   width: 9.737,
   height: 9.756
}
,
{
   page: 7,
   name: "System Service Area", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 663.461,
   left: 417.337,
   width: 146.160,
   height: 13.305
}
,
{
   page: 7,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 676.781,
   left: 353.617,
   width: 209.880,
   height: 10.945
}
,
{
   page: 8,
   name: "undefined_23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 24.782,
   left: 137.604,
   width: 319.625,
   height: 14.229
}
,
{
   page: 8,
   name: "Attorney is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 98.531,
   left: 126.287,
   width: 168.240,
   height: 13.988
}
,
{
   page: 8,
   name: "Text14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 117.478,
   left: 132.11,
   width: 22.111,
   height: 13.378
}
,
{
   page: 8,
   name: "Text15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 118.507,
   left: 158.517,
   width: 136.020,
   height: 12.815
}
,
{
   page: 8,
   name: "Text16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 133.573,
   left: 130.514,
   width: 22.628,
   height: 13.942
}
,
{
   page: 8,
   name: "Text17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 133.679,
   left: 157.438,
   width: 136.538,
   height: 13.285
}
,
{
   page: 8,
   name: "undefined_21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 149.772,
   left: 125.467,
   width: 168.240,
   height: 13.454
}
,
{
   page: 8,
   name: "Attorney is_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 97.996,
   left: 389.687,
   width: 172.680,
   height: 13.989
}
,
{
   page: 8,
   name: "Text18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 118.087,
   left: 395.093,
   width: 22.156,
   height: 13.287
}
,
{
   page: 8,
   name: "Text19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 118.133,
   left: 421.545,
   width: 139.645,
   height: 12.707
}
,
{
   page: 8,
   name: "Text25", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 133.604,
   left: 394.666,
   width: 21.513,
   height: 13.727
}
,
{
   page: 8,
   name: "Text26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 134.701,
   left: 420.589,
   width: 140.420,
   height: 13.163
}
,
{
   page: 8,
   name: "undefined_22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 149.471,
   left: 388.867,
   width: 172.680,
   height: 13.928
}
,
{
   page: 8,
   name: "BROKER FILL IN THE DATE OF FINAL ACCEPTANCE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 219.683,
   left: 146.713,
   width: 45.960,
   height: 13.928
}
,
{
   page: 8,
   name: "20_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 220.78,
   left: 232.957,
   width: 167.400,
   height: 12.831
}
,
{
   page: 8,
   name: "Effective Date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 220.277,
   left: 422.317,
   width: 33.600,
   height: 13.365
}
,
{
   page: 9,
   name: "Price when Listing Brokers fee is received Escrow Agent is authorized and directed to pay Other Broker from", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 67.809,
   left: 266.284,
   width: 205.920,
   height: 13.967
}
,
{
   page: 9,
   name: "By", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 111.11,
   left: 72.3636,
   width: 215.4004,
   height: 12.666
}
,
{
   page: 9,
   name: "By_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 110.546,
   left: 342.364,
   width: 210.960,
   height: 13.230
}
,
{
   page: 9,
   name: "Other Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 140.071,
   left: 46.5636,
   width: 182.1584,
   height: 14.505
}
,
{
   page: 9,
   name: "License No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 140.071,
   left: 230.093,
   width: 68.591,
   height: 14.505
}
,
{
   page: 9,
   name: "Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 171.496,
   left: 46.5636,
   width: 182.4804,
   height: 15.039
}
,
{
   page: 9,
   name: "License No_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 171.496,
   left: 230.415,
   width: 68.269,
   height: 15.039
}
,
{
   page: 9,
   name: "Team Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 196.715,
   left: 46.0974,
   width: 252.0986,
   height: 14.406
}
,
{
   page: 9,
   name: "Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 224.096,
   left: 46.5636,
   width: 171.9724,
   height: 15.039
}
,
{
   page: 9,
   name: "Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 224.63,
   left: 219.907,
   width: 78.777,
   height: 14.505
}
,
{
   page: 9,
   name: "Licensed Supervisor of Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 254.15,
   left: 46.5636,
   width: 182.3604,
   height: 14.505
}
,
{
   page: 9,
   name: "License No_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 254.15,
   left: 230.813,
   width: 67.871,
   height: 14.505
}
,
{
   page: 9,
   name: "Other Brokers Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 285.176,
   left: 46.5636,
   width: 169.4134,
   height: 15.039
}
,
{
   page: 9,
   name: "Phone_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 285.176,
   left: 217.912,
   width: 80.772,
   height: 15.039
}
,
{
   page: 9,
   name: "City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 316.616,
   left: 46.5636,
   width: 135.0134,
   height: 15.039
}
,
{
   page: 9,
   name: "State", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 316.616,
   left: 182.431,
   width: 38.065,
   height: 15.039
}
,
{
   page: 9,
   name: "Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 317.15,
   left: 222.902,
   width: 75.782,
   height: 14.505
}
,
{
   page: 9,
   name: "Buyer only as Buyers agent", 
   isText: false,
   type: "checkbox",
   top: 362.222,
   left: 105.124,
   width: 11.520,
   height: 10.871
}
,
{
   page: 9,
   name: "Seller as Listing Brokers subagent", 
   isText: false,
   type: "checkbox",
   top: 372.782,
   left: 105.124,
   width: 11.520,
   height: 9.353
}
,
{
   page: 9,
   name: "Listing or Principal Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 140.537,
   left: 316.684,
   width: 173.878,
   height: 13.438
}
,
{
   page: 9,
   name: "License No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 140.568,
   left: 492.268,
   width: 62.376,
   height: 13.971
}
,
{
   page: 9,
   name: "Listing Associates Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 170.496,
   left: 316.778,
   width: 172.621,
   height: 15.039
}
,
{
   page: 9,
   name: "License No_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 170.496,
   left: 491.668,
   width: 63.576,
   height: 15.039
}
,
{
   page: 9,
   name: "Team Name 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 197.031,
   left: 315.077,
   width: 242.605,
   height: 14.406
}
,
{
   page: 9,
   name: "Listing Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 224.63,
   left: 316.793,
   width: 166.222,
   height: 14.505
}
,
{
   page: 9,
   name: "Phone_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 225.697,
   left: 484.721,
   width: 70.523,
   height: 13.438
}
,
{
   page: 9,
   name: "Licensed Supervisor of Listing Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 254.15,
   left: 316.684,
   width: 172.104,
   height: 14.505
}
,
{
   page: 9,
   name: "License No_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 254.684,
   left: 489.931,
   width: 65.313,
   height: 13.971
}
,
{
   page: 9,
   name: "Listing Brokers Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 285.71,
   left: 316.684,
   width: 164.844,
   height: 14.505
}
,
{
   page: 9,
   name: "Phone_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 286.244,
   left: 483.234,
   width: 71.410,
   height: 13.971
}
,
{
   page: 9,
   name: "City_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 317.15,
   left: 316.684,
   width: 132.866,
   height: 14.505
}
,
{
   page: 9,
   name: "State_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 317.15,
   left: 450.692,
   width: 34.680,
   height: 14.505
}
,
{
   page: 9,
   name: "Zip_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 317.15,
   left: 487.078,
   width: 68.166,
   height: 14.505
}
,
{
   page: 9,
   name: "Selling Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 348.71,
   left: 316.684,
   width: 173.388,
   height: 14.505
}
,
{
   page: 9,
   name: "License No_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 348.71,
   left: 491.778,
   width: 62.866,
   height: 14.505
}
,
{
   page: 9,
   name: "Team Name 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 380.115,
   left: 315.887,
   width: 243.238,
   height: 14.406
}
,
{
   page: 9,
   name: "Selling Associates Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 411.616,
   left: 316.684,
   width: 165.131,
   height: 15.039
}
,
{
   page: 9,
   name: "Phone_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 411.083,
   left: 482.957,
   width: 71.687,
   height: 16.136
}
,
{
   page: 9,
   name: "Licensed Supervisor of Selling Associate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 443.176,
   left: 316.684,
   width: 172.261,
   height: 15.039
}
,
{
   page: 9,
   name: "License No_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 443.176,
   left: 491.778,
   width: 62.266,
   height: 15.039
}
,
{
   page: 9,
   name: "Selling Associates Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 475.684,
   left: 316.564,
   width: 238.080,
   height: 13.971
}
,
{
   page: 9,
   name: "City_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 505.15,
   left: 316.684,
   width: 134.019,
   height: 14.505
}
,
{
   page: 9,
   name: "State_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 505.15,
   left: 452.409,
   width: 33.553,
   height: 14.505
}
,
{
   page: 9,
   name: "Zip_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 505.15,
   left: 487.667,
   width: 66.977,
   height: 14.505
}
,
{
   page: 9,
   name: "Seller only", 
   isText: false,
   type: "checkbox",
   top: 548.722,
   left: 375.945,
   width: 12.084,
   height: 10.618
}
,
{
   page: 9,
   name: "Buyer only", 
   isText: false,
   type: "checkbox",
   top: 559.929,
   left: 375.945,
   width: 12.648,
   height: 8.927
}
,
{
   page: 9,
   name: "Seller and Buyer as an intermediary", 
   isText: false,
   type: "checkbox",
   top: 570.086,
   left: 374.945,
   width: 13.211,
   height: 8.294
}
,
{
   page: 9,
   name: "Seller_4", 
   isText: false,
   type: "checkbox",
   top: 592.929,
   left: 172.109,
   width: 11.640,
   height: 9.491
}
,
{
   page: 9,
   name: "Buyer will pay ListingPrincipal Broker", 
   isText: false,
   type: "checkbox",
   top: 592.929,
   left: 212.549,
   width: 11.640,
   height: 9.491
}
,
{
   page: 9,
   name: "a cash fee of", 
   isText: false,
   type: "checkbox",
   top: 592.929,
   left: 401.465,
   width: 12.084,
   height: 9.491
}
,
{
   page: 9,
   name: "a cash fee of_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 592.202,
   left: 480.957,
   width: 69.992,
   height: 12.472
}
,
{
   page: 9,
   name: "agreement is attached a", 
   isText: false,
   type: "checkbox",
   top: 602.889,
   left: 62.6729,
   width: 10.9565,
   height: 9.491
}
,
{
   page: 9,
   name: "of the total Sales Price and b", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 601.839,
   left: 76.1094,
   width: 37.3196,
   height: 12.141
}
,
{
   page: 9,
   name: "Seller_5", 
   isText: false,
   type: "checkbox",
   top: 602.453,
   left: 287.716,
   width: 10.393,
   height: 8.927
}
,
{
   page: 9,
   name: "Buyer will pay Other Broker", 
   isText: false,
   type: "checkbox",
   top: 601.889,
   left: 327.269,
   width: 11.520,
   height: 9.491
}
,
{
   page: 9,
   name: "SellerBuyer authorizes and directs Escrow Agent to", 
   isText: false,
   type: "checkbox",
   top: 604.053,
   left: 479.789,
   width: 10.680,
   height: 8.327
}
,
{
   page: 9,
   name: "pay the brokers from the proceeds at closing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 611.362,
   left: 53.0259,
   width: 79.1901,
   height: 14.022
}
,
{
   page: 9,
   name: "or", 
   isText: false,
   type: "checkbox",
   top: 612.969,
   left: 148.465,
   width: 12.204,
   height: 9.491
}
,
{
   page: 9,
   name: "of the total Sales Price", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 612.799,
   left: 163.429,
   width: 36.024,
   height: 11.628
}
,
{
   page: 10,
   name: "is acknowledged", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 87.979,
   left: 107.047,
   width: 107.760,
   height: 14.259
}
,
{
   page: 10,
   name: "Option Fee in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 88.505,
   left: 346.567,
   width: 210.000,
   height: 13.733
}
,
{
   page: 10,
   name: "Seller or Listing Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 116.143,
   left: 52.447,
   width: 248.160,
   height: 13.815
}
,
{
   page: 10,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 114.609,
   left: 313.447,
   width: 243.120,
   height: 14.883
}
,
{
   page: 10,
   name: "is acknowledged_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 165.241,
   left: 106.567,
   width: 112.680,
   height: 12.717
}
,
{
   page: 10,
   name: "Earnest Money in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 164.173,
   left: 357.607,
   width: 199.560,
   height: 13.785
}
,
{
   page: 10,
   name: "Escrow Agent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 191.893,
   left: 52.447,
   width: 145.424,
   height: 13.785
}
,
{
   page: 10,
   name: "Received by", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 191.893,
   left: 198.653,
   width: 101.954,
   height: 13.785
}
,
{
   page: 10,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 191.36,
   left: 313.567,
   width: 135.655,
   height: 14.318
}
,
{
   page: 10,
   name: "DateTime", 
   fontSize: 9,
   type: "date",
   top: 191.36,
   left: 451.131,
   width: 105.436,
   height: 14.318
}
,
{
   page: 10,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 217.04,
   left: 52.447,
   width: 306.720,
   height: 14.852
}
,
{
   page: 10,
   name: "Phone_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 217.573,
   left: 368.047,
   width: 189.120,
   height: 13.785
}
,
{
   page: 10,
   name: "City_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 242.186,
   left: 52.207,
   width: 163.158,
   height: 11.579
}
,
{
   page: 10,
   name: "State_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 253.765,
   left: 217.837,
   width: 36.043,
   height: 11.579
}
,
{
   page: 10,
   name: "Zip_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 253.765,
   left: 255.79,
   width: 103.377,
   height: 11.579
}
,
{
   page: 10,
   name: "Fax", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 253.765,
   left: 368.047,
   width: 189.120,
   height: 11.579
}
,
{
   page: 10,
   name: "Escrow Agent_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 304.905,
   left: 52.447,
   width: 146.551,
   height: 15.386
}
,
{
   page: 10,
   name: "Received by_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 304.905,
   left: 200.343,
   width: 100.264,
   height: 15.386
}
,
{
   page: 10,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 304.905,
   left: 313.567,
   width: 143.115,
   height: 15.386
}
,
{
   page: 10,
   name: "Date_2", 
   fontSize: 9,
   type: "date",
   top: 304.905,
   left: 458.591,
   width: 97.976,
   height: 15.386
}
,
{
   page: 10,
   name: "Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 330.585,
   left: 52.447,
   width: 306.720,
   height: 15.386
}
,
{
   page: 10,
   name: "Phone_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 330.585,
   left: 368.047,
   width: 189.120,
   height: 15.386
}
,
{
   page: 10,
   name: "City_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 356.799,
   left: 52.207,
   width: 163.157,
   height: 14.852
}
,
{
   page: 10,
   name: "State_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 356.799,
   left: 217.274,
   width: 38.297,
   height: 14.852
}
,
{
   page: 10,
   name: "Zip_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 356.799,
   left: 257.48,
   width: 101.687,
   height: 14.852
}
,
{
   page: 10,
   name: "Fax_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 356.296,
   left: 368.047,
   width: 189.120,
   height: 15.919
}
,
{
   page: 10,
   name: "is acknowledged_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 411.187,
   left: 109.927,
   width: 109.680,
   height: 13.251
}
,
{
   page: 10,
   name: "additional Earnest Money in the form of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 411.187,
   left: 407.167,
   width: 149.880,
   height: 13.251
}
,
{
   page: 10,
   name: "Escrow Agent_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 437.96,
   left: 52.447,
   width: 155.567,
   height: 13.665
}
,
{
   page: 10,
   name: "Received by_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 437.96,
   left: 208.797,
   width: 91.810,
   height: 13.665
}
,
{
   page: 10,
   name: "Email Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 438.373,
   left: 313.567,
   width: 148.616,
   height: 13.785
}
,
{
   page: 10,
   name: "DateTime_2", 
   fontSize: 9,
   type: "date",
   top: 438.373,
   left: 463.529,
   width: 93.038,
   height: 13.816
}
,
{
   page: 10,
   name: "Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 464.053,
   left: 52.447,
   width: 306.720,
   height: 12.718
}
,
{
   page: 10,
   name: "Phone_8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 464.053,
   left: 368.047,
   width: 189.120,
   height: 13.785
}
,
{
   page: 10,
   name: "City_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 488.666,
   left: 52.207,
   width: 162.594,
   height: 14.852
}
,
{
   page: 10,
   name: "State_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 488.666,
   left: 216.147,
   width: 36.606,
   height: 14.883
}
,
{
   page: 10,
   name: "Zip_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 488.666,
   left: 254.663,
   width: 104.504,
   height: 14.883
}
,
{
   page: 10,
   name: "Fax_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 488.666,
   left: 368.047,
   width: 189.120,
   height: 14.319
}
] }
