import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	authenticated: false,
	currentUser: {
		email: "",
		photoURL: "",
		uid: "",
		displayName: "",
		providerId: ""
	},
	prevLocation: null,
	currentLocation: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInUser(state, action) {
			state.authenticated = true
			state.currentUser = {
				email: action.payload.email,
				photoURL: action.payload.photoURL,
				uid: action.payload.uid,
				displayName: action.payload.displayName,
				providerId: action.payload.providerData[0]?.providerId
			}
    },
    signOutUser(state, action) {
			state.authenticated = false
			state.currentUser = null
    },
    locationChange(state, action) {
			state.prevLocation = state.currentLocation
			state.currentLocation = action.payload.location
    },
  }
});

export const { signInUser, signOutUser, locationChange } = authSlice.actions

export default authSlice.reducer