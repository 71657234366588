import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import DocViewActionButtons from "./DocViewActionButtons";
import FormFieldSelector from "../docPrepare/formFields/FormFieldSelector";
import DocViewAgentAnnotField from "./DocViewAgentAnnotField";
import {
  getAndSavePdfDimensions,
  getFormFieldValues,
} from "../../../../app/common/util/util";
import DocViewSignedAnnotField from "./DocViewSignedAnnotField";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

export function DocViewDashboard() {
  const [numPages, setNumPages] = useState(null);
  const { pageScalePrepare } = useSelector((state) => state.annot);
  const { transaction } = useSelector((state) => state.transaction);
  const { doc, docUrl } = useSelector((state) => state.doc);
  const [formFieldValues, setFormFieldValues] = useState();
  const [pageDimensions, setPageDimensions] = useState(
    doc.dimensions || {
      width: 612,
      height: 792,
    }
  );
  const pageScale = pageScalePrepare;
  const agentAnnots = doc.annotsInProgress
    ? doc.annotsInProgress.filter((annot) => annot.agentsField === true)
    : [];

  useEffect(() => {
    getFormFieldValues(doc, transaction, setFormFieldValues);
  }, [doc, transaction]);

  async function onDocumentLoadSuccess(pdfObject) {
    getAndSavePdfDimensions(
      doc,
      pdfObject,
      setNumPages,
      setPageDimensions,
      "agent"
    );
  }

  function Canvas(props) {
    const canvasRef = useRef(null);
    useEffect(() => {
      const canvas = canvasRef.current;
      canvas.getContext("2d");
    }, []);
    return <canvas ref={canvasRef} {...props} />;
  }

  return (
    <>
      <div
        id="pdf-page"
        style={{
          position: "fixed",
          bottom: "80px",
          top: "75px",
          left: 0,
          right: 0,
          overflow: "auto",
          backgroundColor: "#f3f6f8",
        }}
      >
        <Grid className="zero horizontal margin">
          <Grid.Column width={16} className="zero top padding">
            <div>
              <Document
                file={docUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                  <div style={{ height: window.innerHeight }}>
                    <LoadingComponent />
                  </div>
                }
                options={options}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    className="pdf-page-wrapper"
                    id={`page-${index}`}
                    key={`page-${index}`}
                  >
                    <div className="pdf-page-number">
                      Page {index + 1}/{numPages}
                    </div>
                    <div
                      className="pdf-page-container"
                      style={{
                        height: `${pageDimensions.height * pageScale}px`,
                        width: `${pageDimensions.width * pageScale}px`,
                      }}
                    >
                      <Page
                        scale={pageScale}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                      />
                      <Canvas
                        className="pdf-canvas"
                        id={`canvas-${index}`}
                        style={{
                          width: `${pageDimensions.width * pageScale}px`,
                          height: `${pageDimensions.height * pageScale}px`,
                        }}
                      />
                      {numPages &&
                        formFieldValues?.map((formField) => (
                          <React.Fragment key={formField.name}>
                            {formField.page === index && (
                              <FormFieldSelector
                                formField={formField}
                                pageScale={pageScale}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      {numPages &&
                        ((!doc.pdfBurnVersion &&
                          doc.status === "In Progress") ||
                          doc.pdfBurnVersion) &&
                        agentAnnots?.map((annot) => (
                          <React.Fragment key={annot.uniqueId}>
                            {annot.page === index && (
                              <DocViewAgentAnnotField
                                annot={annot}
                                pageScale={pageScale}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      {numPages &&
                        doc.pdfBurnVersion &&
                        doc.annotsByAgent &&
                        Object.entries(doc.annotsByAgent).map(
                          ([key, value]) => (
                            <React.Fragment key={key}>
                              {value.page === index && (
                                <DocViewAgentAnnotField
                                  annot={value}
                                  pageScale={pageScale}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {numPages &&
                        doc.pdfBurnVersion &&
                        doc.annotsToSign &&
                        Object.entries(doc.annotsToSign).map(([key, value]) => (
                          <React.Fragment key={key}>
                            {value.page === index && (
                              <>
                                {value.signed === true && (
                                  <DocViewSignedAnnotField
                                    annot={value}
                                    pageScale={pageScale}
                                  />
                                )}
                              </>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                ))}
              </Document>
            </div>
          </Grid.Column>
        </Grid>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#f3f6f8",
        }}
      >
        <DocViewActionButtons />
      </div>
    </>
  );
}
