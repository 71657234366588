export function cBWireFraudDisclosure() {
    return [   //2023
    {
      page: 0,
      name: "logo",
      isText: false,
      type: "logo",
      top: 28.673,
      left: 53,
      width: 118,
      height: 20
   }
   ,
    {
       page: 0,
       name: "Broker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 16,
       top: 597.495,
       left: 111.96,
       width: 270.12,
       height: 16.985
    }
    ,
    {
       page: 0,
       name: "Date_5", 
       fontSize: 9,
       type: "date",
       top: 597.495,
       left: 410.04,
       width: 64.08,
       height: 16.985
    }
    ,
    {
       page: 0,
       name: "SellerDeclined", 
       isText: false,
       type: "checkbox",
       top: 565.544,
       left: 64.7312,
       width: 12.7636,
       height: 15.382
    }
    ,
    {
       page: 0,
       name: "BuyerDeclined", 
       isText: false,
       type: "checkbox",
       top: 563.279,
       left: 361.736,
       width: 12.763,
       height: 15.382
    }
    ] }
    