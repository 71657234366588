import { formatDistance } from "date-fns";
import React from "react";
import { Table } from "semantic-ui-react";
import DeadlineTemplatesActionButtons from "../DeadlineTemplatesActionButtons";

export default function DeadlineTemplatesListItem({
  deadlineTemplate,
  actionButtonMode,
}) {
  return (
    <Table.Row key={deadlineTemplate.id}>
      <Table.Cell>{deadlineTemplate.name}</Table.Cell>
      <Table.Cell collapsing>
        <p>
          {deadlineTemplate.updatedAt &&
          deadlineTemplate.updatedAt instanceof Date
            ? formatDistance(deadlineTemplate.updatedAt, new Date(), {
                addSuffix: true,
              })
            : ""}
        </p>
      </Table.Cell>
      <Table.Cell collapsing>
        <DeadlineTemplatesActionButtons
          deadlineTemplate={deadlineTemplate}
          actionButtonMode={actionButtonMode}
        />
      </Table.Cell>
    </Table.Row>
  );
}
