import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "semantic-ui-react";
import { sortPeople } from "../peopleSlice";
import PeopleListItem from "./PeopleListItem";

export default function PeopleList({ people, column, direction }) {
  const dispatch = useDispatch();

  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell
              sorted={column === "firstName" ? direction : null}
              onClick={() => dispatch(sortPeople("firstName"))}
            >
              First Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "lastName" ? direction : null}
              onClick={() => dispatch(sortPeople("lastName"))}
            >
              Last Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "email" ? direction : null}
              onClick={() => dispatch(sortPeople("email"))}
            >
              Email
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "phone" ? direction : null}
              onClick={() => dispatch(sortPeople("phone"))}
            >
              Phone
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "type" ? direction : null}
              onClick={() => dispatch(sortPeople("type"))}
            >
              Role
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {people.length !== 0 &&
            people.map((person) => (
              <PeopleListItem person={person} key={person.id} />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
