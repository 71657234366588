import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import DocFillActionButtons from "./DocFillActionButtons";
import { getFormFieldData } from "./formFieldData/formFieldData";
import { FormFieldText } from "./formFieldTypes/FormFieldText";
import { FormFieldDate } from "./formFieldTypes/FormFieldDate";
import { FormFieldCheckbox } from "./formFieldTypes/FormFieldCheckbox";
import { FormFieldSignature } from "./formFieldTypes/FormFieldSignature";
import { FormFieldTextarea } from "./formFieldTypes/FormFieldTextarea";
import { docPrepopulateFields } from "./DocFillUtils";
import { updateDocInDb } from "../../../../app/firestore/firestoreService";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import _ from "lodash";
import { FormFieldLogo } from "./formFieldTypes/FormFieldLogo";
import { getAndSavePdfDimensions } from "../../../../app/common/util/util";
import DocViewSignedAnnotField from "../docView/DocViewSignedAnnotField";
import DocViewAgentAnnotField from "../docView/DocViewAgentAnnotField";
import { FormFieldCheckboxAddDoc } from "./formFieldTypes/FormFieldCheckboxAddDoc";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

export function DocFillDashboard() {
  const [numPages, setNumPages] = useState(null);
  const { pageScaleFill } = useSelector((state) => state.annot);
  const { parties, transClients, transaction } = useSelector(
    (state) => state.transaction
  );
  const { doc, docUrl } = useSelector((state) => state.doc);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [formFieldData, setFormFieldData] = useState();
  const [newPopulatedFields, setNewPopulatedFields] = useState();
  const [pageDimensions, setPageDimensions] = useState(
    doc.dimensions || {
      width: 612,
      height: 792,
    }
  );
  const [populatingFieldsStatus, setPopulatingFieldsStatus] =
    useState("Initial");
  const pageScale = pageScaleFill;
  const agentAnnots = doc.annotsInProgress
    ? doc.annotsInProgress.filter((annot) => annot.agentsField === true)
    : [];

  useEffect(() => {
    const newFormFieldData = getFormFieldData(doc.title, transaction);
    if (newFormFieldData?.length > 0) {
      setFormFieldData(newFormFieldData);
    }
  }, [doc.title, transaction]);

  useEffect(() => {
    if (!doc.fieldsPopulated && populatingFieldsStatus === "Initial") {
      setPopulatingFieldsStatus("In progress");
      let agentProfile = {};
      if (transaction.createdBy && transaction.agentProfile) {
        Object.assign(agentProfile, transaction.agentProfile);
      } else {
        const agentCopy = _.cloneDeep(currentUserProfile);
        Object.assign(agentProfile, agentCopy);
      }
      if (transaction.agentRepresents === "Buyer") {
        agentProfile["role"] = "Buyer Agent";
      } else {
        agentProfile["role"] = "Listing Agent";
      }
      const allParties = [
        ...transClients,
        ...parties,
        transaction,
        agentProfile,
      ];
      docPrepopulateFields(doc, allParties, transaction).then(
        (formFieldValues) => {
          setNewPopulatedFields(formFieldValues);
          let clonedFormFields = _.cloneDeep(doc.formFieldValues);
          let newFormFieldValues = {
            ...clonedFormFields,
            ...formFieldValues,
          };
          updateDocInDb(doc.id, {
            formFieldValues: newFormFieldValues,
            fieldsPopulated: true,
          }).then(() => {
            setPopulatingFieldsStatus("Complete");
          });
        }
      );
    }
  }, [
    doc,
    parties,
    transaction,
    transClients,
    populatingFieldsStatus,
    currentUserProfile,
  ]);

  async function onDocumentLoadSuccess(pdfObject) {
    getAndSavePdfDimensions(
      doc,
      pdfObject,
      setNumPages,
      setPageDimensions,
      "agent"
    );
  }

  function Canvas(props) {
    const canvasRef = useRef(null);
    useEffect(() => {
      const canvas = canvasRef.current;
      canvas.getContext("2d");
    }, []);
    return <canvas ref={canvasRef} {...props} />;
  }

  return (
    <>
      <div
        id="pdf-page"
        style={{
          position: "fixed",
          bottom: "80px",
          top: "75px",
          left: 0,
          right: 0,
          overflow: "auto",
          backgroundColor: "#f3f6f8",
        }}
      >
        <Grid className="zero horizontal margin">
          <Grid.Column width={16} className="zero top padding">
            <div>
              <Document
                file={docUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                  <div style={{ height: window.innerHeight }}>
                    <LoadingComponent />
                  </div>
                }
                options={options}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    className="pdf-page-wrapper"
                    id={`page-${index}`}
                    key={`page-${index}`}
                  >
                    <div className="pdf-page-number">
                      Page {index + 1}/{numPages}
                    </div>
                    <div
                      className="pdf-page-container"
                      style={{
                        height: `${pageDimensions.height * pageScale}px`,
                        width: `${pageDimensions.width * pageScale}px`,
                      }}
                    >
                      <Page
                        scale={pageScale}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                      />
                      <Canvas
                        className="pdf-canvas"
                        id={`canvas-${index}`}
                        style={{
                          width: `${pageDimensions.width * pageScale}px`,
                          height: `${pageDimensions.height * pageScale}px`,
                        }}
                      />
                      {formFieldData?.map((formField) => (
                        <React.Fragment key={formField.name}>
                          {formField.page === index && (
                            <>
                              {formField.type === "text" && (
                                <FormFieldText
                                  formField={formField}
                                  index={index}
                                  populatingFieldsStatus={
                                    populatingFieldsStatus
                                  }
                                  newPopulatedFields={newPopulatedFields}
                                />
                              )}
                              {formField.type === "date" && (
                                <FormFieldDate
                                  formField={formField}
                                  index={index}
                                  populatingFieldsStatus={
                                    populatingFieldsStatus
                                  }
                                  newPopulatedFields={newPopulatedFields}
                                />
                              )}
                              {formField.type === "checkbox" &&
                                !formField.subType && (
                                  <FormFieldCheckbox
                                    formField={formField}
                                    index={index}
                                    populatingFieldsStatus={
                                      populatingFieldsStatus
                                    }
                                    newPopulatedFields={newPopulatedFields}
                                  />
                                )}
                              {formField.type === "checkbox" &&
                                formField.subType && (
                                  <FormFieldCheckboxAddDoc
                                    formField={formField}
                                    index={index}
                                    populatingFieldsStatus={
                                      populatingFieldsStatus
                                    }
                                    newPopulatedFields={newPopulatedFields}
                                  />
                                )}
                              {formField.type === "signature" && (
                                <FormFieldSignature
                                  formField={formField}
                                  index={index}
                                  populatingFieldsStatus={
                                    populatingFieldsStatus
                                  }
                                  newPopulatedFields={newPopulatedFields}
                                />
                              )}
                              {formField.type === "textarea" && (
                                <FormFieldTextarea
                                  formField={formField}
                                  index={index}
                                  populatingFieldsStatus={
                                    populatingFieldsStatus
                                  }
                                  newPopulatedFields={newPopulatedFields}
                                />
                              )}
                              {formField.type === "logo" && (
                                <FormFieldLogo
                                  formField={formField}
                                  index={index}
                                  populatingFieldsStatus={
                                    populatingFieldsStatus
                                  }
                                  newPopulatedFields={newPopulatedFields}
                                />
                              )}
                            </>
                          )}
                        </React.Fragment>
                      ))}
                      {numPages &&
                        ((!doc.pdfBurnVersion &&
                          doc.status === "In Progress") ||
                          doc.pdfBurnVersion) &&
                        agentAnnots?.map((annot) => (
                          <React.Fragment key={annot.uniqueId}>
                            {annot.page === index && (
                              <DocViewAgentAnnotField
                                annot={annot}
                                pageScale={pageScale}
                              />
                            )}
                          </React.Fragment>
                        ))}

                      {numPages &&
                        doc.pdfBurnVersion &&
                        Object.entries(doc.annotsByAgent).map(
                          ([key, value]) => (
                            <React.Fragment key={key}>
                              {value.page === index && (
                                <DocViewAgentAnnotField
                                  annot={value}
                                  pageScale={pageScale}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {numPages &&
                        doc.pdfBurnVersion &&
                        Object.entries(doc.annotsToSign).map(([key, value]) => (
                          <React.Fragment key={key}>
                            {value.page === index && (
                              <>
                                {value.signed === true && (
                                  <DocViewSignedAnnotField
                                    annot={value}
                                    pageScale={pageScale}
                                  />
                                )}
                              </>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                ))}
              </Document>
            </div>
          </Grid.Column>
        </Grid>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#f3f6f8",
        }}
      >
        <DocFillActionButtons />
      </div>
    </>
  );
}
