export function estoppelStatement() {
return [
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Re Lease Dated 1", 
   top: 177.24,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Premises", 
   top: 189.72,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Re Lease Dated 2", 
   top: 202.20,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Original Landlord", 
   top: 214.68,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Current Landlord 1", 
   top: 227.16,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Current Landlords Contact Address", 
   top: 239.76,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Current Landlord 2", 
   top: 252.24,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Telephone", 
   top: 264.72,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Current Landlord 3", 
   top: 277.20,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Tenant 1", 
   top: 289.68,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Tenants Contact Address", 
   top: 302.16,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Tenant 2", 
   top: 314.76,
   left: 243.96,
   width: 261.24,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Telephone_2", 
   top: 327.24,
   left: 243.24,
   width: 261.96,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "The signer states and certifies to", 
   top: 350.40,
   left: 182.04,
   width: 174.48,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "to", 
   top: 363.12,
   left: 60.72,
   width: 142.68,
   height: 11.16
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "The commencement date of the Lease was", 
   top: 380.40,
   left: 240.84,
   width: 106.68,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "is", 
   top: 393.12,
   left: 59.52,
   width: 105.12,
   height: 11.16
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "31", 
   top: 453.24,
   left: 96.0,
   width: 61.897,
   height: 11.16
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "32", 
   top: 465.0,
   left: 96.0,
   width: 61.153,
   height: 11.4
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "33", 
   top: 477.0,
   left: 96.0,
   width: 59.292,
   height: 11.4
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "34", 
   top: 489.000,
   left: 96.0,
   width: 60.169,
   height: 16.249
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "The amount of rent currently paid to Landlord per month is", 
   top: 506.160,
   left: 312.96,
   width: 103.61,
   height: 15.972
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box4", 
   top: 544.292,
   left: 130.213,
   width: 11.906,
   height: 11.161
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box5", 
   top: 543.176,
   left: 157.0,
   width: 11.906,
   height: 11.161
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box6", 
   top: 556.453,
   left: 120.424,
   width: 11.906,
   height: 11.161
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box7", 
   top: 557.081,
   left: 148.327,
   width: 11.906,
   height: 11.161
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text1", 
   top: 615.723,
   left: 71.0593,
   width: 515.2727,
   height: 34.600
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box2", 
   top: 652.555,
   left: 87.0569,
   width: 10.4171,
   height: 11.161
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box3", 
   top: 652.927,
   left: 134,
   width: 10.4171,
   height: 11.161
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined", 
   top: 675.84,
   left: 81.96,
   width: 211.68,
   height: 11.88
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined_2", 
   top: 675.84,
   left: 356.52,
   width: 210.72,
   height: 11.88
}
] }