export function rBBrokerRebatetoBuyer2022() {
    return [   //2023
    {
       page: 0,
       name: "Brokerage Firm and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 160.156,
       left: 252.175,
       width: 253.560,
       height: 15.764
    }
    ,
    {
       page: 0,
       name: "BuyerFullName", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 199.88,
       left: 33.0545,
       width: 437.2915,
       height: 13.80
    }
    ,
    {
       page: 0,
       name: "1", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 10,
       top: 218.793,
       left: 177.83,
       width: 119.465,
       height: 14.192
    }
    ,
    {
       page: 0,
       name: "2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 238.942,
       left: 36.8945,
       width: 514.6595,
       height: 16.418
    }
    ,
    {
       page: 0,
       name: "If and only if the following conditions are met Broker will rebate", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 278.302,
       left: 416.88,
       width: 98.04,
       height: 16.418
    }
    ,
    {
       page: 0,
       name: "Broker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 444.425,
       left: 55.2219,
       width: 190.4181,
       height: 14.455
    }
    ,
    {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 444.426,
       left: 251.04,
       width: 65.64,
       height: 14.454
    }
    ,
    {
       page: 0,
       name: "20", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 566.331,
       left: 126.36,
       width: 223.80,
       height: 13.549
    }
    ,
    {
       page: 0,
       name: "undefined_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 566.331,
       left: 372.0,
       width: 35.04,
       height: 13.549
    }
    ,
    {
       page: 0,
       name: "Type or print Lenders company name above", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 606.72,
       left: 35.52,
       width: 279.96,
       height: 13.44
    }
    ,
    {
       page: 0,
       name: "Title", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 658.876,
       left: 362.4,
       width: 219.48,
       height: 15.764
    }
    ] }
    