import React from "react";
import TransactionActiveListingListItem from "./TransactionActiveListingListItem";
import { Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { sortTransActiveListing } from "../../transactionSlice";

export default function TransactionActiveListingList({
  transactions,
  column,
  direction,
}) {
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);

  return (
    <>
      <Table compact sortable stackable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell></Table.HeaderCell>
            {currentUserProfile?.role !== "agent" && (
              <>
                <Table.HeaderCell
                sorted={column === "agnetFirstName" ? direction : null}
                onClick={() => dispatch(sortTransActiveListing("agentFirstName"))}
                >
                  Agent First Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "agentLastName" ? direction : null}
                  onClick={() => dispatch(sortTransActiveListing("agentLastName"))}
                >
                  Agent Last Name
                </Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell
              sorted={column === "title" ? direction : null}
              onClick={() => dispatch(sortTransActiveListing("title"))}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "client.firstName" ? direction : null}
              onClick={() =>
                dispatch(sortTransActiveListing("client.firstName"))
              }
            >
              Client&nbsp;&nbsp;(Primary)
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "clientSecondary.firstName" ? direction : null}
              onClick={() =>
                dispatch(sortTransActiveListing("clientSecondary.firstName"))
              }
            >
              Client&nbsp;&nbsp;(Secondary)
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "address" ? direction : null}
              onClick={() => dispatch(sortTransActiveListing("address"))}
            >
              Address
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "dateOnMarket" ? direction : null}
              onClick={() => dispatch(sortTransActiveListing("dateOnMarket"))}
            >
              Days On Market
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {transactions &&
            transactions.length !== 0 &&
            transactions.map((transaction) => (
              <TransactionActiveListingListItem
                transaction={transaction}
                key={transaction.id}
              />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
