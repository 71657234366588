import { formatDistance } from "date-fns";
import React from "react";
import { Table } from "semantic-ui-react";
import EmailTemplatesActionButtons from "../EmailTemplatesActionButtons";

export default function EmailTemplatesListItem({
  emailTemplate,
  actionButtonMode,
}) {
  return (
    <Table.Row key={emailTemplate.id}>
      <Table.Cell>{emailTemplate.name}</Table.Cell>
      <Table.Cell collapsing>
        <p>
          {emailTemplate.updatedAt && emailTemplate.updatedAt instanceof Date
            ? formatDistance(emailTemplate.updatedAt, new Date(), {
                addSuffix: true,
              })
            : ""}
        </p>
      </Table.Cell>
      <Table.Cell collapsing>
        <EmailTemplatesActionButtons
          emailTemplate={emailTemplate}
          actionButtonMode={actionButtonMode}
        />
      </Table.Cell>
    </Table.Row>
  );
}
