export function rBSellerComingSoonAddendum2022() {
    return [   //2023
    {
       page: 0,
       name: "In the event of a conflict between this Addendum and the indicated CREC approved form this", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 232.8,
       left: 35.52,
       width: 527.40,
       height: 13.68
    }
    ,
    {
       page: 0,
       name: "solely to buyers sometimes referred to subsequently in this Addendum as inside buyers", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 300.24,
       left: 54.24,
       width: 69.96,
       height: 12.48
    }
    ,
    {
       page: 0,
       name: "working with the Broker identified in  33 of the Seller Listing Contract", 
       isText: false,
       type: "checkbox",
       top: 327.72,
       left: 39.36,
       width: 12.00,
       height: 12.00
    }
    ,
    {
       page: 0,
       name: "working with the Broker identified in  33 of the Seller Listing Contract or", 
       isText: false,
       type: "checkbox",
       top: 348.72,
       left: 39.36,
       width: 12.00,
       height: 12.00
    }
    ,
    {
       page: 1,
       name: "Signature_Broker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 440.149,
       left: 55.24,
       width: 153.24,
       height: 15.371
    }
    ,
    {
       page: 1,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 440.804,
       left: 213.64,
       width: 102.84,
       height: 14.716
    }
    ] }
    