export function exclusiveBuyerRepresentationsAgreement() {
return [   //2024 Release 2024-06-06 17:10:03
{
        page: 0,
        name: "Logo",
        isText: false,
        fontSize: 10,
        type: "logo",
        top: 28.673,
        left: 52.9457,
        width: 288.0003,
      height: 43.2,
      }
    ,
    {
   page: 0,
   name: "This Exclusive Buyer Representation Agreement Agreement is by and between the following parties Parties", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 132.36,
   left: 92.4,
   width: 219.6,
   height: 12.12
}
,
{
   page: 0,
   name: "BROKER", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 132.36,
   left: 363.12,
   width: 204.00,
   height: 12.12
}
,
{
   page: 0,
   name: "BUYER", 
   isText: false,
   type: "checkbox",
   top: 134.04,
   left: 41.4,
   width: 11.28,
   height: 11.28
}
,
{
   page: 0,
   name: "MULTIPLE BUYERS", 
   isText: false,
   type: "checkbox",
   top: 157.8,
   left: 41.4,
   width: 11.28,
   height: 11.28
}
,
{
   page: 0,
   name: "BROKERAGE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 156.12,
   left: 381.24,
   width: 183.12,
   height: 12.12
}
,
{
   page: 0,
   name: "AGENT", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 192.84,
   left: 364.92,
   width: 203.88,
   height: 12.12
}
,
{
   page: 0,
   name: "1 PURPOSE Broker hereby appoints and Buyer accepts", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 229.32,
   left: 286.44,
   width: 235.32,
   height: 12.12
}
,
{
   page: 0,
   name: "immovable property in the parish or parishes of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 255.24,
   left: 252.84,
   width: 318.96,
   height: 11.88
}
,
{
   page: 0,
   name: "A Designated Agent Broker designates and Buyer accepts", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 472.92,
   left: 305.16,
   width: 266.64,
   height: 12.12
}
,
{
   page: 1,
   name: "i Percentage of Sales Price of Purchased Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 478.68,
   left: 279.48,
   width: 52.32,
   height: 11.88
}
,
{
   page: 1,
   name: "ii Flat Fee", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 491.64,
   left: 111,
   width: 209.16,
   height: 11.88
}
,
{
   page: 1,
   name: "iii Other", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 506.52,
   left: 104.88,
   width: 465.36,
   height: 11.88
}
,
{
   page: 1,
   name: "ii Buyer Purchases Property within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 661.8,
   left: 218.52,
   width: 57.60,
   height: 11.88
}
,
{
   page: 2,
   name: "and terminate at midnight on", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 106.92,
   left: 219.84,
   width: 109.80,
   height: 11.88
}
,
{
   page: 2,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 106.92,
   left: 460.08,
   width: 84.72,
   height: 11.88
}
,
{
   page: 2,
   name: "calendar days of the expiration of this", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 145.32,
   left: 357.84,
   width: 36.72,
   height: 11.88
}
,
{
   page: 3,
   name: "BUYER_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 279.564,
   left: 328.665,
   width: 235.320,
   height: 11.880
}
,
{
   page: 3,
   name: "date brokerSigns", 
   fontSize: 9,
   type: "date",
   top: 310.92,
   left: 90.36,
   width: 198.60,
   height: 11.88
}
,
{
   page: 3,
   name: "emailBroker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 341.12,
   left: 90.36,
   width: 198.60,
   height: 11.88
}
,
{
   page: 3,
   name: "emailBuyer1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 342.12,
   left: 328.8,
   width: 235.32,
   height: 11.88
}
,
{
   page: 3,
   name: "phoneBroker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 372.56,
   left: 90.36,
   width: 198.60,
   height: 11.88
}
,
{
   page: 3,
   name: "phoneBuyer1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 373.56,
   left: 328.8,
   width: 235.32,
   height: 11.88
}
,
{
   page: 3,
   name: "PHYSICAL BUSINESS ADDRESS 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 416.04,
   left: 90.36,
   width: 198.60,
   height: 9.916
}
,
{
   page: 3,
   name: "PHYSICAL BUSINESS ADDRESS 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.32,
   left: 90.36,
   width: 198.60,
   height: 9.916
}
,
{
   page: 3,
   name: "PHYSICAL BUSINESS ADDRESS 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 438.84,
   left: 90.36,
   width: 198.60,
   height: 9.916
}
,
{
   page: 3,
   name: "PHYSICAL ADDRESS 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 416.04,
   left: 328.8,
   width: 235.32,
   height: 10.44
}
,
{
   page: 3,
   name: "PHYSICAL ADDRESS 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.32,
   left: 328.8,
   width: 235.32,
   height: 10.68
}
,
{
   page: 3,
   name: "PHYSICAL ADDRESS 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 438.84,
   left: 328.8,
   width: 235.32,
   height: 10.68
}
,
{
   page: 3,
   name: "signature designated", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 474.8,
   left: 90.36,
   width: 198.60,
   height: 11.88
}
,
{
   page: 3,
   name: "BUYER_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 507.873,
   left: 329.32,
   width: 235.32,
   height: 11.880
}
,
{
   page: 3,
   name: "date designatedSigns", 
   fontSize: 9,
   type: "date",
   top: 537.92,
   left: 90.36,
   width: 198.60,
   height: 11.88
}
,
{
   page: 3,
   name: "emailDesignatedAgent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 570.12,
   left: 90.36,
   width: 198.60,
   height: 11.88
}
,
{
   page: 3,
   name: "phoneDesignatedAgent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 601.32,
   left: 90.36,
   width: 198.60,
   height: 11.88
}
,
{
   page: 3,
   name: "PHYSICAL BUSINESS ADDRESS 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 664.44,
   left: 90.36,
   width: 198.60,
   height: 11.88
}
,
{
   page: 3,
   name: "PHYSICAL BUSINESS ADDRESS 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 677.16,
   left: 90.36,
   width: 198.72,
   height: 11.88
}
,
{
   page: 3,
   name: "PHYSICAL BUSINESS ADDRESS 3_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 690.12,
   left: 90.36,
   width: 198.60,
   height: 11.88
}
,
{
   page: 3,
   name: "emailBuyer2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 570.12,
   left: 328.8,
   width: 235.32,
   height: 11.88
}
,
{
   page: 3,
   name: "phoneBuyer2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 601.32,
   left: 328.8,
   width: 235.32,
   height: 11.88
}
,
{
   page: 3,
   name: "PHYSICAL ADDRESS 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 664.44,
   left: 328.8,
   width: 235.32,
   height: 11.88
}
,
{
   page: 3,
   name: "PHYSICAL ADDRESS 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 677.16,
   left: 328.8,
   width: 235.32,
   height: 11.88
}
,
{
   page: 3,
   name: "PHYSICAL ADDRESS 3_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 690.12,
   left: 328.8,
   width: 235.32,
   height: 11.88
}
,
{
   page: 3,
   name: "signature broker", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 12,
   top: 246.765,
   left: 89.6731,
   width: 197.7819,
   height: 12.534
}
,
{
   page: 3,
   name: "nameBroker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 279.492,
   left: 89.6731,
   width: 197.7819,
   height: 11.880
}
,
{
   page: 3,
   name: "nameDesignatedAgent", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 506.93,
   left: 90.3277,
   width: 198.6003,
   height: 11.88
}
,
{
   page: 3,
   name: "Text1", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 58.255,
   left: 42.5456,
   width: 524.4014,
   height: 150.946
}
] }
