import { appLoaded } from "../../app/async/asyncSlice";
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import { dataFromSnapshot, getUserProfile } from "../../app/firestore/firestoreService";
import { listenToCurrentUserProfile } from "../profiles/profileSlice";
import { signInUser, signOutUser } from "./authSlice";
import { app } from "../../app/config/firebase";
import { onSnapshot } from '@firebase/firestore';

const auth = getAuth(app);

export function verifyAuth() {
  return function (dispatch) {
    return onAuthStateChanged(auth, user => {
      if (user) {
        dispatch(signInUser(user))
        const profileRef = getUserProfile(user.uid);
        onSnapshot(profileRef, snapshot => {
          dispatch(listenToCurrentUserProfile(dataFromSnapshot(snapshot)));
          dispatch(appLoaded())
        })
      } else {
        dispatch(signOutUser()) 
        dispatch(appLoaded())
      }
    })
  }
}