export function contracttoBuyandSellCommercial() {
   return [   //2024 Release 2024-01-30 18:48:59
      {
         page: 0,
         name: "Logo",
         isText: false,
         fontSize: 10,
         type: "logo",
         top: 28.673,
         left: 52.9457,
         width: 200,
         height: 30,
       }
      ,
         {
            page: 0,
            name: "Property with No Residences", 
            isText: false,
            type: "checkbox",
            top: 159.84,
            left: 232.44,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 0,
            name: "Property with ResidencesResidential Addendum Attached", 
            isText: false,
            type: "checkbox",
            top: 173.64,
            left: 155.64,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 0,
            name: "Date document", 
            fontSize: 9,
            type: "date",
            top: 193.346,
            left: 438.775,
            width: 125.775,
            height: 14.219
         }
         ,
         {
            page: 0,
            name: "Buyers", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 289.92,
            left: 138.0,
            width: 348.96,
            height: 10.68
         }
         ,
         {
            page: 0,
            name: "JT check", 
            isText: false,
            type: "checkbox",
            top: 302.52,
            left: 194.16,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 0,
            name: "TIC check", 
            isText: false,
            type: "checkbox",
            top: 302.52,
            left: 271.8,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 0,
            name: "Other check", 
            isText: false,
            type: "checkbox",
            top: 302.52,
            left: 379.2,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 0,
            name: "Other Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 301.44,
            left: 420.48,
            width: 151.92,
            height: 10.68
         }
         ,
         {
            page: 0,
            name: "Sellers", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 327.753,
            left: 136.32,
            width: 400.386,
            height: 13.407
         }
         ,
         {
            page: 0,
            name: "County", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 349.4,
            left: 443.88,
            width: 133.386,
            height: 14.716
         }
         ,
         {
            page: 0,
            name: "Legal Property Description", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 9,
            top: 376.295,
            left: 50.8082,
            width: 519.8988,
            height: 53.025
         }
         ,
         {
            page: 0,
            name: "Street Address", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 432.229,
            left: 97.5623,
            width: 470.7917,
            height: 12.098
         }
         ,
         {
            page: 0,
            name: "No of Remotes", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 543.731,
            left: 94.688,
            width: 35.297,
            height: 10.838
         }
         ,
         {
            page: 0,
            name: "Solar Panels check", 
            isText: false,
            type: "checkbox",
            top: 562.196,
            left: 111.333,
            width: 9.960,
            height: 10.080
         }
         ,
         {
            page: 0,
            name: "Water check", 
            isText: false,
            type: "checkbox",
            top: 561.851,
            left: 183.482,
            width: 9.960,
            height: 9.960
         }
         ,
         {
            page: 0,
            name: "Security Systems check", 
            isText: false,
            type: "checkbox",
            top: 561.96,
            left: 278.16,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 0,
            name: "Satellite Systems check", 
            isText: false,
            type: "checkbox",
            top: 561.96,
            left: 374.92,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 0,
            name: "2_5_3 Other Inclusions", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 9,
            top: 669.159,
            left: 50.0384,
            width: 526.8276,
            height: 82.5871
         }
         ,
         {
            page: 1,
            name: "2_5_4 Encumbered Inclusions", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 90.717,
            left: 47.5551,
            width: 530.4679,
            height: 47.832
         }
         ,
         {
            page: 1,
            name: "256 Parking", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 172.32,
            left: 50.4,
            width: 144.0,
            height: 10.68
         }
         ,
         {
            page: 1,
            name: "256 Storage", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 172.32,
            left: 440.16,
            width: 132.36,
            height: 10.68
         }
         ,
         {
            page: 1,
            name: "257 Leased Items", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 221.673,
            left: 49.6238,
            width: 526.6192,
            height: 29.312
         }
         ,
         {
            page: 1,
            name: "258 Trade Fixtures", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 265.414,
            left: 50.0808,
            width: 527.2442,
            height: 31.734
         }
         ,
         {
            page: 1,
            name: "Conveyance Exception", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 310.32,
            left: 337.8,
            width: 180.6,
            height: 10.68
         }
         ,
         {
            page: 1,
            name: "2_6 Exclustions", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 344.09,
            left: 47.6993,
            width: 530.1787,
            height: 34.871
         }
         ,
         {
            page: 1,
            name: "2_7 box", 
            isText: false,
            type: "checkbox",
            top: 392.162,
            left: 51.1429,
            width: 10.0800,
            height: 10.080
         }
         ,
         {
            page: 1,
            name: "2_7_1 Deeded Water Rights", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 402.988,
            left: 50.7065,
            width: 525.8725,
            height: 31.734
         }
         ,
         {
            page: 1,
            name: "2_7_1 Conveyance Method", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 436.8,
            left: 374.88,
            width: 107.64,
            height: 10.68
         }
         ,
         {
            page: 1,
            name: "2_7_2 box", 
            isText: false,
            type: "checkbox",
            top: 449.616,
            left: 51.2156,
            width: 10.0800,
            height: 10.080
         }
         ,
         {
            page: 1,
            name: "2_7_2 Other Water Rights", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 475.339,
            left: 49.6959,
            width: 528.9761,
            height: 57.193
         }
         ,
         {
            page: 1,
            name: "2_7_3 box", 
            isText: false,
            type: "checkbox",
            top: 541.98,
            left: 51.0701,
            width: 10.0800,
            height: 9.425
         }
         ,
         {
            page: 1,
            name: "Well Permit No", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 609.24,
            left: 50.4,
            width: 90.0,
            height: 10.68
         }
         ,
         {
            page: 1,
            name: "2_7_4 box", 
            isText: false,
            type: "checkbox",
            top: 621.469,
            left: 51.2509,
            width: 10.0800,
            height: 10.080
         }
         ,
         {
            page: 1,
            name: "2_7_4 Water Stock Certs", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 637.131,
            left: 49.6959,
            width: 528.4981,
            height: 50.351
         }
         ,
         {
            page: 2,
            name: "2_7_6 Does check", 
            isText: false,
            type: "checkbox",
            top: 58.44,
            left: 267.84,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 2,
            name: "2_7_6 Does Not check", 
            isText: false,
            type: "checkbox",
            top: 58.44,
            left: 306.72,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 2,
            name: "Time of Day Deadline", 
            fontSize: 9,
            type: "text",
            top: 134.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateAlternative Earnest Money Deadline", 
            fontSize: 9,
            type: "date",
            top: 146.52,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateRecord Title Deadline and Tax Certificate", 
            fontSize: 9,
            type: "date",
            top: 170.52,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateRecord Title Objection Deadline", 
            fontSize: 9,
            type: "date",
            top: 182.52,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateOffRecord Title Deadline", 
            fontSize: 9,
            type: "date",
            top: 194.52,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateOffRecord Title Objection Deadline", 
            fontSize: 9,
            type: "date",
            top: 206.52,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateTitle Resolution Deadline", 
            fontSize: 9,
            type: "date",
            top: 218.52,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateThird Party Right to PurchaseApprove Deadline", 
            fontSize: 9,
            type: "date",
            top: 230.52,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateAssociation Documents Deadline", 
            fontSize: 9,
            type: "date",
            top: 254.52,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateAssociation Documents Termination Deadline", 
            fontSize: 9,
            type: "date",
            top: 266.52,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateSellers Property Disclosure Deadline", 
            fontSize: 9,
            type: "date",
            top: 290.52,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateLeadBased Paint Disclosure Deadline", 
            fontSize: 9,
            type: "date",
            top: 304.844,
            left: 357.08,
            width: 50.40,
            height: 15.654
         }
         ,
         {
            page: 2,
            name: "DateNew Loan Application Deadline", 
            fontSize: 9,
            type: "date",
            top: 338.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateNew Loan Terms Deadline", 
            fontSize: 9,
            type: "date",
            top: 350.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateNew Loan Availability Deadline", 
            fontSize: 9,
            type: "date",
            top: 362.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateBuyers Credit Information Deadline", 
            fontSize: 9,
            type: "date",
            top: 374.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateDisapproval of Buyers Credit Information Deadline", 
            fontSize: 9,
            type: "date",
            top: 386.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateExisting Loan Deadline", 
            fontSize: 9,
            type: "date",
            top: 398.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateExisting Loan Termination Deadline", 
            fontSize: 9,
            type: "date",
            top: 410.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateLoan Transfer Approval Deadline", 
            fontSize: 9,
            type: "date",
            top: 422.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateSeller or Private Financing Deadline", 
            fontSize: 9,
            type: "date",
            top: 434.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateAppraisal Deadline", 
            fontSize: 9,
            type: "date",
            top: 458.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateAppraisal Objection Deadline", 
            fontSize: 9,
            type: "date",
            top: 470.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateAppraisal Resolution Deadline", 
            fontSize: 9,
            type: "date",
            top: 482.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateNew ILC or New Survey Deadline", 
            fontSize: 9,
            type: "date",
            top: 506.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateNew ILC or New Survey Objection Deadline", 
            fontSize: 9,
            type: "date",
            top: 518.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateNew ILC or New Survey Resolution Deadline", 
            fontSize: 9,
            type: "date",
            top: 530.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateWater Rights Examination Deadline", 
            fontSize: 9,
            type: "date",
            top: 554.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateMineral Rights Examination Deadline", 
            fontSize: 9,
            type: "date",
            top: 566.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateInspection Termination Deadline", 
            fontSize: 9,
            type: "date",
            top: 578.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateInspection Objection Deadline", 
            fontSize: 9,
            type: "date",
            top: 590.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateInspection Resolution Deadline", 
            fontSize: 9,
            type: "date",
            top: 602.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateProperty Insurance Termination Deadline", 
            fontSize: 9,
            type: "date",
            top: 614.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateDue Diligence Documents Delivery Deadline", 
            fontSize: 9,
            type: "date",
            top: 626.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateDue Diligence Documents Objection Deadline", 
            fontSize: 9,
            type: "date",
            top: 638.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateDue Diligence Documents Resolution Deadline", 
            fontSize: 9,
            type: "date",
            top: 650.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Date or DeadlineEnvironmental Inspection Termination Deadline", 
            fontSize: 9,
            type: "date",
            top: 662.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Date or DeadlineADA Evaluation Termination Deadline", 
            fontSize: 9,
            type: "date",
            top: 674.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "DateConditional Sale Deadline", 
            fontSize: 9,
            type: "date",
            top: 686.04,
            left: 356.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text8", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 146.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text9", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 170.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text10", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 182.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text11", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 194.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text12", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 206.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text13", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 218.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text14", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 230.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text15", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 254.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text16", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 266.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text17", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 290.52,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text18", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 305.02,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text19", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 338.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text20", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 350.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text21", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 362.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text22", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 374.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text23", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 386.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text24", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 398.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text25", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 410.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text26", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 422.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text27", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 434.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text28", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 458.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text29", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 470.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text30", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 482.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text31", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 506.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text32", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 518.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text33", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 530.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text34", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 554.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text35", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 566.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text36", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 578.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text37", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 590.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text38", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 602.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text39", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 614.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text40", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 626.08,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text41", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 638.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text42", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 650.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text43", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 662.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text44", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 674.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 2,
            name: "Text45", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 686.04,
            left: 409.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "DateLeadBased Paint Termination Deadline", 
            fontSize: 9,
            type: "date",
            top: 59.92,
            left: 356.08,
            width: 50.40,
            height: 18.273
         }
         ,
         {
            page: 3,
            name: "Date Estoppel Statements Deadline", 
            fontSize: 9,
            type: "date",
            top: 82.08,
            left: 355.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "Date Estoppel Statements Termination Deadline", 
            fontSize: 9,
            type: "date",
            top: 94.08,
            left: 355.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "DateClosing Date", 
            fontSize: 9,
            type: "date",
            top: 118.08,
            left: 355.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "DatePossession Date", 
            fontSize: 9,
            type: "date",
            top: 130.08,
            left: 355.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "Possession Time", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 142.08,
            left: 408.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "DateAcceptance Deadline Date", 
            fontSize: 9,
            type: "date",
            top: 154.08,
            left: 355.72,
            width: 50.40,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "Acceptance Deadline Time", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 166.08,
            left: 408.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "3_3_3 Will check", 
            isText: false,
            type: "checkbox",
            top: 398.88,
            left: 89.04,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 3,
            name: "3_3_3 Will Not check", 
            isText: false,
            type: "checkbox",
            top: 398.88,
            left: 125.04,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 3,
            name: "Purchase Price", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 474.383,
            left: 381.661,
            width: 88.491,
            height: 11.617
         }
         ,
         {
            page: 3,
            name: "Earnest Money", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 486.84,
            left: 485.336,
            width: 85.864,
            height: 11.16
         }
         ,
         {
            page: 3,
            name: "New Loan", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 498.84,
            left: 485.794,
            width: 85.406,
            height: 10.246
         }
         ,
         {
            page: 3,
            name: "Assumption Balance", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 509.469,
            left: 485.793,
            width: 85.407,
            height: 12.531
         }
         ,
         {
            page: 3,
            name: "Private Financing", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 521.926,
            left: 485.794,
            width: 85.406,
            height: 12.074
         }
         ,
         {
            page: 3,
            name: "Seller Financing", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 534.89,
            left: 485.883,
            width: 85.406,
            height: 12.074
         }
         ,
         {
            page: 3,
            name: "Cash at Closing", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 570.84,
            left: 485.336,
            width: 85.864,
            height: 10.246
         }
         ,
         {
            page: 3,
            name: "Total1", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 582.84,
            left: 382.542,
            width: 89.058,
            height: 11.16
         }
         ,
         {
            page: 3,
            name: "Total2", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 581.469,
            left: 485.793,
            width: 85.407,
            height: 12.531
         }
         ,
         {
            page: 3,
            name: "4_2 Seller Concession", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 600.24,
            left: 367.8,
            width: 70.2,
            height: 10.68
         }
         ,
         {
            page: 3,
            name: "4_3 Earnest Money Form", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 669.24,
            left: 431.88,
            width: 110.04,
            height: 10.68
         }
         ,
         {
            page: 3,
            name: "4_3 Earnest Money Holder", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 680.76,
            left: 143.52,
            width: 200.16,
            height: 10.68
         }
         ,
         {
            page: 3,
            name: "Text46", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 59.92,
            left: 408.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "Text47", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 82.08,
            left: 408.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "Text48", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 94.08,
            left: 408.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "Text49", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 118.08,
            left: 408.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 3,
            name: "Text50", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 130.08,
            left: 408.72,
            width: 162.00,
            height: 11.04
         }
         ,
         {
            page: 4,
            name: "4_4_3 Does check", 
            isText: false,
            type: "checkbox",
            top: 345.84,
            left: 461.88,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 4,
            name: "4_4_3 Does Not check", 
            isText: false,
            type: "checkbox",
            top: 345.84,
            left: 502.08,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 4,
            name: "4_5_3 Conventional check", 
            isText: false,
            type: "checkbox",
            top: 449.4,
            left: 51.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 4,
            name: "4_5_3 Other check", 
            isText: false,
            type: "checkbox",
            top: 449.4,
            left: 127.32,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 4,
            name: "4_5_3 Other field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 448.32,
            left: 168.6,
            width: 403.8,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Assumption Balance", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 471.24,
            left: 282.84,
            width: 69.96,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Assumption Frequency", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 471.24,
            left: 370.08,
            width: 79.92,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Assumption Percent", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 482.76,
            left: 142.2,
            width: 39.96,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Tax check", 
            isText: false,
            type: "checkbox",
            top: 483.84,
            left: 470.28,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 4,
            name: "4_6 Ins Premium check", 
            isText: false,
            type: "checkbox",
            top: 483.84,
            left: 565.2,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 4,
            name: "4_6 Other check", 
            isText: false,
            type: "checkbox",
            top: 495.36,
            left: 201.6,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 4,
            name: "4_6 Other Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 494.28,
            left: 214.92,
            width: 60.48,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Trans Fee Not Exceed", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 505.8,
            left: 294.48,
            width: 65.04,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Int Rate Not Exceed", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 517.32,
            left: 97.2,
            width: 40.08,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Payment Not Exceed", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 517.32,
            left: 355.68,
            width: 65.16,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Payment Freq", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 517.32,
            left: 439.8,
            width: 80.04,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Cash Req'd", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 540.24,
            left: 411.96,
            width: 65.04,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Seller Will check", 
            isText: false,
            type: "checkbox",
            top: 564.36,
            left: 99.24,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 4,
            name: "4_6 Seller Will Not check", 
            isText: false,
            type: "checkbox",
            top: 564.36,
            left: 135.96,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 4,
            name: "4_6 On or Before check", 
            isText: false,
            type: "checkbox",
            top: 575.88,
            left: 231.36,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 4,
            name: "4_6 At Closing check", 
            isText: false,
            type: "checkbox",
            top: 575.88,
            left: 449.16,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 4,
            name: "4_6 Release of Liability", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 586.32,
            left: 412.08,
            width: 106.44,
            height: 10.68
         }
         ,
         {
            page: 4,
            name: "4_6 Release Cost", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 597.84,
            left: 111.24,
            width: 64.92,
            height: 10.56
         }
         ,
         {
            page: 4,
            name: "4_7_1 Buyer check", 
            isText: false,
            type: "checkbox",
            top: 667.8,
            left: 536.64,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 4,
            name: "4_7_1 Seller check", 
            isText: false,
            type: "checkbox",
            top: 679.32,
            left: 51.12,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 4,
            name: "4_7_1 Date", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 678.24,
            left: 440.16,
            width: 45.00,
            height: 10.68
         }
         ,
         {
            page: 6,
            name: "6_4 Buyer check", 
            isText: false,
            type: "checkbox",
            top: 230.36,
            left: 51.52,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 6,
            name: "6_4 Seller check", 
            isText: false,
            type: "checkbox",
            top: 230.88,
            left: 107.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 7,
            name: "8_1_1 check", 
            isText: false,
            type: "checkbox",
            top: 289.674,
            left: 51.9437,
            width: 9.2182,
            height: 8.433
         }
         ,
         {
            page: 7,
            name: "8_1_1 Abstract check", 
            isText: false,
            type: "checkbox",
            top: 323.4,
            left: 154.68,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 7,
            name: "8_1_2 box", 
            isText: false,
            type: "checkbox",
            top: 347.236,
            left: 51.4908,
            width: 9.0000,
            height: 8.672
         }
         ,
         {
            page: 7,
            name: "8_1_3 Will check", 
            isText: false,
            type: "checkbox",
            top: 392.4,
            left: 411,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 7,
            name: "8_1_3 Will Not check", 
            isText: false,
            type: "checkbox",
            top: 392.4,
            left: 452.28,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 7,
            name: "8_1_3 Buyer check", 
            isText: false,
            type: "checkbox",
            top: 449.88,
            left: 51.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 7,
            name: "8_1_3 Seller check", 
            isText: false,
            type: "checkbox",
            top: 449.88,
            left: 93.72,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 7,
            name: "8_1_3 50_50 check", 
            isText: false,
            type: "checkbox",
            top: 449.88,
            left: 137.16,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 7,
            name: "8_1_3 Other check", 
            isText: false,
            type: "checkbox",
            top: 449.88,
            left: 338.88,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 7,
            name: "8_1_3 Other Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 448.8,
            left: 377.88,
            width: 129.96,
            height: 10.68
         }
         ,
         {
            page: 8,
            name: "8_5 Seller check", 
            isText: false,
            type: "checkbox",
            top: 276.84,
            left: 288,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 8,
            name: "8_5 Buyer check", 
            isText: false,
            type: "checkbox",
            top: 276.84,
            left: 329.88,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 9,
            name: "8_9 Does check", 
            isText: false,
            type: "checkbox",
            top: 265.32,
            left: 245.28,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 9,
            name: "8_9 Does Not check", 
            isText: false,
            type: "checkbox",
            top: 265.32,
            left: 287.28,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 9,
            name: "9_1 New ILC check", 
            isText: false,
            type: "checkbox",
            top: 311.88,
            left: 316.68,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 9,
            name: "9_1 New Survey check", 
            isText: false,
            type: "checkbox",
            top: 323.4,
            left: 51.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 9,
            name: "9_1 Survey form", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 323.32,
            left: 176.04,
            width: 215.16,
            height: 10.68
         }
         ,
         {
            page: 9,
            name: "9_1_1 Seller check", 
            isText: false,
            type: "checkbox",
            top: 334.92,
            left: 309.84,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 9,
            name: "9_1_1 Buyer check", 
            isText: false,
            type: "checkbox",
            top: 334.92,
            left: 353.52,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 9,
            name: "9_1_2 Seller check", 
            isText: false,
            type: "checkbox",
            top: 380.88,
            left: 102.48,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 9,
            name: "9_1_2 Buyer check", 
            isText: false,
            type: "checkbox",
            top: 380.88,
            left: 145.92,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 9,
            name: "9_1_2 Text Box", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 391.51,
            left: 50.0782,
            width: 527.8878,
            height: 25.067
         }
         ,
         {
            page: 9,
            name: "9_1_3 Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 426.76,
            left: 237.48,
            width: 106.691,
            height: 10.68
         }
         ,
         {
            page: 10,
            name: "10_6_1 Delivery Deadline", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 520.306,
            left: 93.3751,
            width: 487.1259,
            height: 20.973
         }
         ,
         {
            page: 10,
            name: "10_6_1_1 Leases", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 573.901,
            left: 50.0384,
            width: 527.9816,
            height: 26.234
         }
         ,
         {
            page: 10,
            name: "10_6_1_2 Will check", 
            isText: false,
            type: "checkbox",
            top: 621.84,
            left: 359.4,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 10,
            name: "10_6_1_2 Will Not check", 
            isText: false,
            type: "checkbox",
            top: 621.84,
            left: 395.64,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 10,
            name: "10_6_1_3 Will check", 
            isText: false,
            type: "checkbox",
            top: 679.32,
            left: 510.24,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 10,
            name: "10_6_1_3 Will Not check", 
            isText: false,
            type: "checkbox",
            top: 679.32,
            left: 544.68,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_1", 
            isText: false,
            type: "checkbox",
            top: 59.007,
            left: 135.709,
            width: 9.327,
            height: 8.673
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_2", 
            isText: false,
            type: "checkbox",
            top: 81.788,
            left: 135.513,
            width: 9.164,
            height: 8.836
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_2 Years", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 80.28,
            left: 333.04,
            width: 45.48,
            height: 10.68
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_3", 
            isText: false,
            type: "checkbox",
            top: 93.273,
            left: 135.873,
            width: 8.672,
            height: 8.836
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_4", 
            isText: false,
            type: "checkbox",
            top: 128.291,
            left: 135.709,
            width: 9.000,
            height: 8.345
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_5", 
            isText: false,
            type: "checkbox",
            top: 139.745,
            left: 135.382,
            width: 9.654,
            height: 8.509
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_5 Years", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 137.88,
            left: 351.04,
            width: 63.48,
            height: 10.56
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_6", 
            isText: false,
            type: "checkbox",
            top: 150.873,
            left: 135.382,
            width: 9.491,
            height: 8.836
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_7", 
            isText: false,
            type: "checkbox",
            top: 162.818,
            left: 135.545,
            width: 9.000,
            height: 8.509
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_8", 
            isText: false,
            type: "checkbox",
            top: 185.564,
            left: 135.709,
            width: 8.836,
            height: 8.836
         }
         ,
         {
            page: 11,
            name: "1_6_1_4_8 Years", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 194.585,
            left: 269.396,
            width: 37.004,
            height: 11.335
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_9", 
            isText: false,
            type: "checkbox",
            top: 208.636,
            left: 135.218,
            width: 9.982,
            height: 8.673
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_10", 
            isText: false,
            type: "checkbox",
            top: 231.545,
            left: 135.709,
            width: 8.836,
            height: 8.673
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_11", 
            isText: false,
            type: "checkbox",
            top: 288.654,
            left: 135.054,
            width: 10.146,
            height: 9.655
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_12", 
            isText: false,
            type: "checkbox",
            top: 312.054,
            left: 135.545,
            width: 9.328,
            height: 8.837
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_13", 
            isText: false,
            type: "checkbox",
            top: 346.418,
            left: 135.709,
            width: 9.000,
            height: 8.673
         }
         ,
         {
            page: 11,
            name: "10_6_1_4_13 Other", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 357.88,
            left: 43.1533,
            width: 528.2727,
            height: 53.72
         }
         ,
         {
            page: 11,
            name: "10_6_4 Seller check", 
            isText: false,
            type: "checkbox",
            top: 598.8,
            left: 407.52,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 11,
            name: "10_6_4 Buyer check", 
            isText: false,
            type: "checkbox",
            top: 598.8,
            left: 450.96,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 11,
            name: "10_6_4 Ph I check", 
            isText: false,
            type: "checkbox",
            top: 610.32,
            left: 51.12,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 11,
            name: "10_6_4 Ph II check", 
            isText: false,
            type: "checkbox",
            top: 610.32,
            left: 238.92,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 11,
            name: "10_6_4 Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 620.76,
            left: 431.88,
            width: 140.52,
            height: 10.68
         }
         ,
         {
            page: 11,
            name: "10_6_4 Seller 02 check", 
            isText: false,
            type: "checkbox",
            top: 633.36,
            left: 126.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 11,
            name: "10_6_4 Buyer 02 check", 
            isText: false,
            type: "checkbox",
            top: 633.36,
            left: 171.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 11,
            name: "10_6_4 No of Days", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 689.76,
            left: 275.16,
            width: 121.80,
            height: 10.68
         }
         ,
         {
            page: 11,
            name: "10_6_4 Seller 03 check", 
            isText: false,
            type: "checkbox",
            top: 713.88,
            left: 338.4,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 11,
            name: "10_6_4 Buyer 03 check", 
            isText: false,
            type: "checkbox",
            top: 713.88,
            left: 384.96,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 12,
            name: "10_7 Conditional Property", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 137.88,
            left: 221.64,
            width: 296.76,
            height: 10.56
         }
         ,
         {
            page: 12,
            name: "12_2 Are check", 
            isText: false,
            type: "checkbox",
            top: 675.36,
            left: 430.56,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 12,
            name: "12_2 Are Not check", 
            isText: false,
            type: "checkbox",
            top: 675.36,
            left: 467.52,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 12,
            name: "12_3 Designated By", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 720.36,
            left: 304.8,
            width: 199.92,
            height: 10.56
         }
         ,
         {
            page: 13,
            name: "13 SWD check", 
            isText: false,
            type: "checkbox",
            top: 150.36,
            left: 53.64,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "13 GWD check", 
            isText: false,
            type: "checkbox",
            top: 150.36,
            left: 161.88,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "13 BSD check", 
            isText: false,
            type: "checkbox",
            top: 150.36,
            left: 271.68,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "13 QCD check", 
            isText: false,
            type: "checkbox",
            top: 150.36,
            left: 379.56,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "13 PRD check", 
            isText: false,
            type: "checkbox",
            top: 150.36,
            left: 462.24,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "13 Other check", 
            isText: false,
            type: "checkbox",
            top: 161.88,
            left: 76.2,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "13 Other Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 160.8,
            left: 90.12,
            width: 140.04,
            height: 10.68
         }
         ,
         {
            page: 13,
            name: "15_2 Buyer", 
            isText: false,
            type: "checkbox",
            top: 323.88,
            left: 488.04,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_2 Seller", 
            isText: false,
            type: "checkbox",
            top: 323.88,
            left: 537,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_2 50_50", 
            isText: false,
            type: "checkbox",
            top: 335.4,
            left: 51.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_2 Other", 
            isText: false,
            type: "checkbox",
            top: 335.4,
            left: 252.84,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_2 Other Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 334.32,
            left: 294.24,
            width: 278.16,
            height: 10.68
         }
         ,
         {
            page: 13,
            name: "15_3_1 Buyer", 
            isText: false,
            type: "checkbox",
            top: 381.36,
            left: 536.64,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_3_1 Seller", 
            isText: false,
            type: "checkbox",
            top: 392.88,
            left: 51.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_1 50_50", 
            isText: false,
            type: "checkbox",
            top: 392.88,
            left: 94.56,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_1 NA", 
            isText: false,
            type: "checkbox",
            top: 392.88,
            left: 296.4,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_2 Buyer", 
            isText: false,
            type: "checkbox",
            top: 404.4,
            left: 395.52,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_2 Seller", 
            isText: false,
            type: "checkbox",
            top: 404.4,
            left: 439.56,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_2 50_50", 
            isText: false,
            type: "checkbox",
            top: 404.4,
            left: 482.04,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_2 NA", 
            isText: false,
            type: "checkbox",
            top: 415.92,
            left: 154.68,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_3 Buyer", 
            isText: false,
            type: "checkbox",
            top: 450.36,
            left: 63.6,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_3 Seller", 
            isText: false,
            type: "checkbox",
            top: 450.36,
            left: 108.72,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_3 50_50", 
            isText: false,
            type: "checkbox",
            top: 450.36,
            left: 152.16,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_3 NA", 
            isText: false,
            type: "checkbox",
            top: 450.36,
            left: 354,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_4 Buyer", 
            isText: false,
            type: "checkbox",
            top: 461.88,
            left: 565.2,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_4 Seller", 
            isText: false,
            type: "checkbox",
            top: 473.4,
            left: 82.2,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_4 50_50", 
            isText: false,
            type: "checkbox",
            top: 473.4,
            left: 125.64,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_3_4 NA", 
            isText: false,
            type: "checkbox",
            top: 473.4,
            left: 327.36,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_4 Buyer", 
            isText: false,
            type: "checkbox",
            top: 484.8,
            left: 414.6,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_4 Seller", 
            isText: false,
            type: "checkbox",
            top: 484.8,
            left: 462.12,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_4 50_50", 
            isText: false,
            type: "checkbox",
            top: 484.8,
            left: 508.08,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_4 NA", 
            isText: false,
            type: "checkbox",
            top: 496.32,
            left: 183.24,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_5 Buyer", 
            isText: false,
            type: "checkbox",
            top: 519.36,
            left: 51.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_5 Seller", 
            isText: false,
            type: "checkbox",
            top: 519.36,
            left: 96.24,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_5 50_50", 
            isText: false,
            type: "checkbox",
            top: 519.36,
            left: 139.68,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_5 NA", 
            isText: false,
            type: "checkbox",
            top: 519.36,
            left: 341.4,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_6 Buyer", 
            isText: false,
            type: "checkbox",
            top: 542.4,
            left: 485.88,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_6 Seller", 
            isText: false,
            type: "checkbox",
            top: 542.4,
            left: 536.52,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_6 50_50", 
            isText: false,
            type: "checkbox",
            top: 553.8,
            left: 51.12,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_6 NA", 
            isText: false,
            type: "checkbox",
            top: 553.8,
            left: 250.44,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_7 Water Trans Fees", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 575.76,
            left: 55.44,
            width: 59.88,
            height: 10.68
         }
         ,
         {
            page: 13,
            name: "15_7 Water StockCerts", 
            isText: false,
            type: "checkbox",
            top: 588.36,
            left: 73.56,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_7 Water District", 
            isText: false,
            type: "checkbox",
            top: 588.36,
            left: 231.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_7 Augmentation", 
            isText: false,
            type: "checkbox",
            top: 599.88,
            left: 73.56,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_7 Small Domestic", 
            isText: false,
            type: "checkbox",
            top: 599.88,
            left: 231.12,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_7 Other check", 
            isText: false,
            type: "checkbox",
            top: 600.609,
            left: 411.25,
            width: 9.654,
            height: 8.836
         }
         ,
         {
            page: 13,
            name: "15_7  Other field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 598.8,
            left: 424.44,
            width: 148.08,
            height: 10.68
         }
         ,
         {
            page: 13,
            name: "15_7 Buyer", 
            isText: false,
            type: "checkbox",
            top: 611.4,
            left: 177.48,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_7 Seller", 
            isText: false,
            type: "checkbox",
            top: 611.4,
            left: 222.6,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_7 50_50", 
            isText: false,
            type: "checkbox",
            top: 611.4,
            left: 266.04,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_7 NA", 
            isText: false,
            type: "checkbox",
            top: 611.4,
            left: 465.36,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 13,
            name: "15_8 Buyer", 
            isText: false,
            type: "checkbox",
            top: 634.32,
            left: 85.8,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_8 Seller", 
            isText: false,
            type: "checkbox",
            top: 634.32,
            left: 130.92,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_8 50_50", 
            isText: false,
            type: "checkbox",
            top: 634.32,
            left: 174.36,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_8 NA", 
            isText: false,
            type: "checkbox",
            top: 634.32,
            left: 376.08,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 13,
            name: "15_9_1 Is check", 
            isText: false,
            type: "checkbox",
            top: 680.28,
            left: 514.08,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 14,
            name: "16_1_1 Taxes", 
            isText: false,
            type: "checkbox",
            top: 185.4,
            left: 189.96,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 14,
            name: "16_1_1 Mill Levy", 
            isText: false,
            type: "checkbox",
            top: 185.4,
            left: 465.96,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 14,
            name: "16_1_1 Other", 
            isText: false,
            type: "checkbox",
            top: 208.32,
            left: 141.12,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 14,
            name: "16_1_1 Other Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 207.36,
            left: 182.4,
            width: 264.0,
            height: 10.56
         }
         ,
         {
            page: 14,
            name: "16_1_2 Rents", 
            isText: false,
            type: "checkbox",
            top: 219.84,
            left: 238.8,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 14,
            name: "16_1_2 Accrued", 
            isText: false,
            type: "checkbox",
            top: 219.84,
            left: 362.04,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 14,
            name: "16_1_3 Prorations Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 254.44,
            left: 488.52,
            width: 84.84,
            height: 10.56
         }
         ,
         {
            page: 14,
            name: "16_2 Buyer check", 
            isText: false,
            type: "checkbox",
            top: 323.4,
            left: 403.32,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 14,
            name: "16_2 Seller check", 
            isText: false,
            type: "checkbox",
            top: 323.4,
            left: 448.68,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 14,
            name: "16_2 Assessments", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 368.4,
            left: 50.4,
            width: 150.12,
            height: 10.56
         }
         ,
         {
            page: 14,
            name: "17 Per Diem", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 426.36,
            left: 377.28,
            width: 69.24,
            height: 10.56
         }
         ,
         {
            page: 15,
            name: "Check Box1", 
            isText: false,
            type: "checkbox",
            top: 368.511,
            left: 51.7093,
            width: 13.4182,
            height: 12.763
         }
         ,
         {
            page: 16,
            name: "26_2 Notice", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 507.84,
            left: 408.36,
            width: 165.12,
            height: 10.56
         }
         ,
         {
            page: 17,
            name: "29 Add'l Provisions", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 109.279,
            left: 48.7702,
            width: 525.5828,
            height: 127.645
         }
         ,
         {
            page: 17,
            name: "30_1 Part of Contracts", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 264.306,
            left: 50.4546,
            width: 525.7274,
            height: 30.231
         }
         ,
         {
            page: 17,
            name: "30_2 Not Part of Contracts", 
            type: "textarea",
            fontName: "Helvetica",
            fontSize: 10,
            top: 315.521,
            left: 52.2837,
            width: 524.8133,
            height: 35.718
         }
         ,
         {
            page: 17,
            name: "Buyers Name 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 387.6,
            left: 121.68,
            width: 182.52,
            height: 11.4
         }
         ,
         {
            page: 17,
            name: "Buyer Address 01 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 460.44,
            left: 135.84,
            width: 168.36,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Buyer Address 02 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 472.44,
            left: 135.84,
            width: 168.36,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Buyer Telephone 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 484.44,
            left: 135.84,
            width: 168.36,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Buyer Fax 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 496.44,
            left: 135.84,
            width: 168.36,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Buyer Email 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 508.44,
            left: 135.12,
            width: 169.08,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Buyers Name 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 387.6,
            left: 393.12,
            width: 184.08,
            height: 11.4
         }
         ,
         {
            page: 17,
            name: "Buyer Address 01 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 460.44,
            left: 410.4,
            width: 166.8,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Buyer Address 02 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 472.44,
            left: 410.4,
            width: 166.8,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Buyer Telephone 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 484.44,
            left: 410.4,
            width: 166.8,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Buyer Fax 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 496.44,
            left: 410.4,
            width: 166.8,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Buyer Email 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 508.44,
            left: 409.68,
            width: 167.52,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Sellers Name 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 555.48,
            left: 121.68,
            width: 182.52,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Seller Address 01 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 627.72,
            left: 135.84,
            width: 168.36,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Seller Address 02 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 639.72,
            left: 135.84,
            width: 168.36,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Seller Telephone 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 651.72,
            left: 135.84,
            width: 168.36,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Seller Fax 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 663.72,
            left: 135.84,
            width: 168.36,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Seller Email 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 675.72,
            left: 135.12,
            width: 169.08,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Sellers Name 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 555.48,
            left: 393.12,
            width: 184.08,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Seller Address 01 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 627.72,
            left: 410.4,
            width: 166.8,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Seller Address 02 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 639.72,
            left: 410.4,
            width: 166.8,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Seller Telephone 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 651.72,
            left: 410.4,
            width: 166.8,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Seller Fax 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 663.72,
            left: 410.4,
            width: 166.8,
            height: 11.40
         }
         ,
         {
            page: 17,
            name: "Seller Email 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 675.72,
            left: 409.68,
            width: 167.52,
            height: 11.40
         }
         ,
         {
            page: 18,
            name: "A Broker Does check", 
            isText: false,
            type: "checkbox",
            top: 124.68,
            left: 89.76,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 18,
            name: "A Broker Does Not check", 
            isText: false,
            type: "checkbox",
            top: 124.68,
            left: 130.08,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 18,
            name: "A Buyer's Agent check", 
            isText: false,
            type: "checkbox",
            top: 194.28,
            left: 199.2,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 18,
            name: "A Transaction-Broker check", 
            isText: false,
            type: "checkbox",
            top: 194.28,
            left: 282.12,
            width: 10.08,
            height: 9.96
         }
         ,
         {
            page: 18,
            name: "A Customer check", 
            isText: false,
            type: "checkbox",
            top: 217.68,
            left: 56.52,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 18,
            name: "A Listing Brokerage Firm", 
            isText: false,
            type: "checkbox",
            top: 241.2,
            left: 309.48,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 18,
            name: "A Buyer check", 
            isText: false,
            type: "checkbox",
            top: 241.2,
            left: 427.56,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 18,
            name: "A Othe check", 
            isText: false,
            type: "checkbox",
            top: 241.2,
            left: 471.96,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 18,
            name: "A Other Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 239.4,
            left: 512.64,
            width: 65.16,
            height: 11.4
         }
         ,
         {
            page: 18,
            name: "Brokerage Firms Name", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 309.96,
            left: 180.84,
            width: 401.52,
            height: 11.40
         }
         ,
         {
            page: 18,
            name: "Brokerage Firms License No", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 321.96,
            left: 180.84,
            width: 401.52,
            height: 11.40
         }
         ,
         {
            page: 18,
            name: "Broker Name 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 333.96,
            left: 180.84,
            width: 401.52,
            height: 11.40
         }
         ,
         {
            page: 18,
            name: "Broker License 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 345.96,
            left: 180.84,
            width: 401.52,
            height: 11.40
         }
         ,
         {
            page: 18,
            name: "date brokerBuyerSign", 
            fontSize: 9,
            type: "date",
            top: 364.506,
            left: 476.169,
            width: 96.066,
            height: 14.738
         }
         ,
         {
            page: 18,
            name: "Broker Address 01 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 420,
            left: 180.84,
            width: 401.52,
            height: 11.4
         }
         ,
         {
            page: 18,
            name: "Broker Address 02 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 432,
            left: 180.84,
            width: 401.52,
            height: 11.4
         }
         ,
         {
            page: 18,
            name: "Broker Address  03 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 444,
            left: 180.84,
            width: 401.52,
            height: 11.4
         }
         ,
         {
            page: 18,
            name: "Broker Telephone 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 456,
            left: 180.84,
            width: 401.52,
            height: 11.4
         }
         ,
         {
            page: 18,
            name: "Broker Fax 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 468,
            left: 180.84,
            width: 401.52,
            height: 11.4
         }
         ,
         {
            page: 18,
            name: "Broker Email 01", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 480,
            left: 180.84,
            width: 401.52,
            height: 11.28
         }
         ,
         {
            page: 18,
            name: "B Broker Does Check", 
            isText: false,
            type: "checkbox",
            top: 548.16,
            left: 89.76,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 18,
            name: "B Broker Does Not Check", 
            isText: false,
            type: "checkbox",
            top: 548.16,
            left: 130.08,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 18,
            name: "B Seller's Agent check", 
            isText: false,
            type: "checkbox",
            top: 641.16,
            left: 198.12,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 18,
            name: "B Transaction Broker check", 
            isText: false,
            type: "checkbox",
            top: 641.16,
            left: 276.84,
            width: 10.08,
            height: 10.08
         }
         ,
         {
            page: 18,
            name: "B Customer check", 
            isText: false,
            type: "checkbox",
            top: 664.68,
            left: 56.52,
            width: 9.96,
            height: 10.08
         }
         ,
         {
            page: 18,
            name: "B Seller Check", 
            isText: false,
            type: "checkbox",
            top: 688.2,
            left: 315.6,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 18,
            name: "B Buyer Check", 
            isText: false,
            type: "checkbox",
            top: 688.2,
            left: 359.04,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 18,
            name: "B Other Check", 
            isText: false,
            type: "checkbox",
            top: 688.2,
            left: 404.16,
            width: 9.96,
            height: 9.96
         }
         ,
         {
            page: 18,
            name: "B Other Field", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 686.4,
            left: 445.56,
            width: 132.24,
            height: 11.4
         }
         ,
         {
            page: 18,
            name: "signature brokerBuyer", 
            type: "signature",
            fontName: "Helvetica",
            fontSize: 10,
            top: 364.602,
            left: 193.092,
            width: 252.109,
            height: 14.145
         }
         ,
         {
            page: 18,
            name: "signature cobrokerBuyer", 
            type: "signature",
            fontName: "Helvetica",
            fontSize: 10,
            top: 391.358,
            left: 193.092,
            width: 252.109,
            height: 14.145
         }
         ,
         {
            page: 18,
            name: "Date cobrokerBuyerSign", 
            fontSize: 9,
            type: "date",
            top: 390.765,
            left: 476.169,
            width: 96.066,
            height: 14.738
         }
         ,
         {
            page: 19,
            name: "Brokerage Firms Name 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 109.68,
            left: 180.84,
            width: 397.08,
            height: 11.40
         }
         ,
         {
            page: 19,
            name: "Brokerage Firms License Number 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 121.68,
            left: 180.84,
            width: 397.08,
            height: 11.40
         }
         ,
         {
            page: 19,
            name: "Broker Name 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 133.68,
            left: 180.84,
            width: 397.08,
            height: 11.40
         }
         ,
         {
            page: 19,
            name: "Broker License Number 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 145.68,
            left: 180.84,
            width: 397.08,
            height: 11.40
         }
         ,
         {
            page: 19,
            name: "Date BrokerSellerSign", 
            fontSize: 9,
            type: "date",
            top: 163.7,
            left: 461.627,
            width: 105.666,
            height: 12.836
         }
         ,
         {
            page: 19,
            name: "Broker Address 01 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 218.6,
            left: 180.84,
            width: 397.08,
            height: 11.4
         }
         ,
         {
            page: 19,
            name: "Broker Address 02 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 230.6,
            left: 180.84,
            width: 397.08,
            height: 11.4
         }
         ,
         {
            page: 19,
            name: "Broker Address 03 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 242.6,
            left: 180.84,
            width: 397.08,
            height: 11.4
         }
         ,
         {
            page: 19,
            name: "Broker Telephone 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 254.6,
            left: 180.84,
            width: 397.08,
            height: 11.4
         }
         ,
         {
            page: 19,
            name: "Broker Fax 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 266.6,
            left: 180.84,
            width: 397.08,
            height: 11.4
         }
         ,
         {
            page: 19,
            name: "Broker Email 02", 
            type: "text",
            fontName: "Helvetica",
            fontSize: 10,
            top: 278.72,
            left: 180.12,
            width: 397.80,
            height: 11.40
         }
         ,
         {
            page: 19,
            name: "signature brokerSeller", 
            type: "signature",
            fontName: "Helvetica",
            fontSize: 10,
            top: 164.354,
            left: 189.819,
            width: 254.073,
            height: 12.182
         }
         ,
         {
            page: 19,
            name: "signature cobrokerSeller", 
            type: "signature",
            fontName: "Helvetica",
            fontSize: 10,
            top: 191.128,
            left: 189.819,
            width: 254.073,
            height: 12.182
         }
         ,
         {
            page: 19,
            name: "Date cobrokerSellerSign", 
            fontSize: 9,
            type: "date",
            top: 190.474,
            left: 461.627,
            width: 105.666,
            height: 12.836
         }
         ] }
         
         