import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  formTemplates: [],
}

const formTemplatesSlice = createSlice({
  name: 'formTemplates',
  initialState,
  reducers: {
    fetchFormTemplates(state, action) {
      state.formTemplates = action.payload
    },
  }
});

export const { fetchFormTemplates } = formTemplatesSlice.actions

export default formTemplatesSlice.reducer