import React from "react";
import { Table } from "semantic-ui-react";
import TransactionAllListItem from "./TransactionAllListItem";
import { useSelector } from "react-redux";

export default function TransactionAllList({ transactions }) {
  const { currentUserProfile } = useSelector((state) => state.profile);

  return (
    <>
      <Table compact>
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell></Table.HeaderCell>
            {currentUserProfile?.role !== "agent" && (
              <>
                <Table.HeaderCell>Agent First Name</Table.HeaderCell>
                <Table.HeaderCell>Agent Last Name</Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Client&nbsp;&nbsp;(Primary)</Table.HeaderCell>
            <Table.HeaderCell>Client&nbsp;&nbsp;(Secondary)</Table.HeaderCell>
            <Table.HeaderCell>Representing</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Close Date</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {transactions.map((transaction) => (
            <TransactionAllListItem
              transaction={transaction}
              key={transaction.id}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
