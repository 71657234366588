import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Confirm, Dropdown } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import _ from "lodash";
import { closeModal, openModal } from "../../app/common/modals/modalSlice";
import {
  copyEmailTemplateToDb,
  deleteEmailTemplateInDb,
} from "../../app/firestore/firestoreService";
import { fetchEmailFromTemplate } from "../emails/emailSlice";

export default function EmailTemplatesActionButtons({
  emailTemplate,
  actionButtonMode,
}) {
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { allParties } = useSelector((state) => state.transaction);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleEdit() {
    dispatch(
      openModal({
        modalType: "EmailTemplatesEdit",
        modalProps: { emailTemplate: emailTemplate },
      })
    );
  }

  async function handleCopy() {
    copyEmailTemplateToDb(emailTemplate);
  }

  function handleChoose() {
    let emailTemplateCopy = _.cloneDeep(emailTemplate);
    let allPartiesCopy = _.cloneDeep(allParties);
    let currentUserProfileCopy = _.cloneDeep(currentUserProfile);
    currentUserProfileCopy.role = "Me (Agent)";
    let allPartiesAndAgent = allPartiesCopy;
    allPartiesAndAgent.push(currentUserProfileCopy);
    emailTemplate.to.forEach((to) => {
      const toParty = allPartiesAndAgent.filter(
        (party) => party.role === to
      )[0];
      if (toParty && toParty.email) {
        return;
      } else {
        emailTemplateCopy.to = emailTemplateCopy.to.filter(
          (role) => role !== to
        );
      }
    });
    emailTemplate.cc.forEach((cc) => {
      const ccParty = allPartiesAndAgent.filter(
        (party) => party.role === cc
      )[0];
      if (ccParty && ccParty.email) {
        return;
      } else {
        emailTemplateCopy.cc = emailTemplateCopy.cc.filter(
          (role) => role !== cc
        );
      }
    });
    emailTemplate.bcc.forEach((bcc) => {
      const bccParty = allPartiesAndAgent.filter(
        (party) => party.role === bcc
      )[0];
      if (bccParty && bccParty.email) {
        return;
      } else {
        emailTemplateCopy.bcc = emailTemplateCopy.bcc.filter(
          (role) => role !== bcc
        );
      }
    });
    dispatch(fetchEmailFromTemplate(emailTemplateCopy));
    dispatch(
      closeModal({
        modalType: "EmailTemplatesSelect",
      })
    );
  }

  function handleRename() {
    dispatch(
      openModal({
        modalType: "EmailTemplatesRename",
        modalProps: { emailTemplate: emailTemplate },
      })
    );
  }

  async function handleDelete() {
    try {
      await deleteEmailTemplateInDb(emailTemplate.id);
      toast.success("Template successfully deleted");
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  }

  if (actionButtonMode === "apply") {
    return (
      <div className={isMobile ? null : "text align right"}>
        <Button primary onClick={() => handleChoose()}>
          Choose
        </Button>
      </div>
    );
  } else {
    return (
      <div className={isMobile ? null : "text align right"}>
        <Dropdown
          button
          icon="chevron down"
          text={isMobile ? "Actions " : null}
          className={isMobile ? "button icon" : "button mini icon"}
          direction={isMobile ? "right" : "left"}
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleEdit()}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => handleCopy()}>Copy</Dropdown.Item>
            <Dropdown.Item onClick={() => handleRename()}>Rename</Dropdown.Item>
            <Dropdown.Item onClick={() => setConfirmOpen(true)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Confirm
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={() => handleDelete()}
        ></Confirm>
      </div>
    );
  }
}
