export function historicDistrictDisclosure() {
return [   //2024 Release 2024-06-07 08:57:05
{
   page: 0,
   name: "Municipal Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 74.4,
   left: 72.0,
   width: 174.96,
   height: 12.608
}
,
{
   page: 0,
   name: "Subdivision", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 74.4,
   left: 252.0,
   width: 102.96,
   height: 12.608
}
,
{
   page: 0,
   name: "Lot Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 75.055,
   left: 360.0,
   width: 115.396,
   height: 11.953
}
,
{
   page: 0,
   name: "Parish", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 75.055,
   left: 477.818,
   width: 81.448,
   height: 11.953
}
,
{
   page: 0,
   name: "Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 135.08,
   left: 72.0,
   width: 341.64,
   height: 12.608
}
,
{
   page: 0,
   name: "Location Municipal Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 135.08,
   left: 416.68,
   width: 119.64,
   height: 12.608
}
,
{
   page: 0,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 186.752,
   left: 106.49,
   width: 9.36,
   height: 9.360
}
,
{
   page: 0,
   name: "Check Box2", 
   isText: false,
   type: "checkbox",
   top: 187.056,
   left: 143.729,
   width: 9.360,
   height: 9.360
}
,
{
   page: 0,
   name: "Check Box3", 
   isText: false,
   type: "checkbox",
   top: 242.674,
   left: 106.147,
   width: 9.360,
   height: 9.360
}
,
{
   page: 0,
   name: "Check Box4", 
   isText: false,
   type: "checkbox",
   top: 243.059,
   left: 143.892,
   width: 9.360,
   height: 9.360
}
,
{
   page: 0,
   name: "Check Box5", 
   isText: false,
   type: "checkbox",
   top: 299.676,
   left: 106.147,
   width: 9.360,
   height: 9.360
}
,
{
   page: 0,
   name: "Check Box6", 
   isText: false,
   type: "checkbox",
   top: 299.676,
   left: 145.817,
   width: 9.360,
   height: 9.360
}
,
{
   page: 0,
   name: "Check Box7", 
   isText: false,
   type: "checkbox",
   top: 356.442,
   left: 106.991,
   width: 9.360,
   height: 9.360
}
,
{
   page: 0,
   name: "Check Box8", 
   isText: false,
   type: "checkbox",
   top: 356.598,
   left: 146.047,
   width: 9.360,
   height: 8.975
}
,
{
   page: 0,
   name: "If yes please provide details below or attach documentation 1", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 390.508,
   left: 72.0,
   width: 477.382,
   height: 81.990
}
,
{
   page: 0,
   name: "Check Box9", 
   isText: false,
   type: "checkbox",
   top: 506.185,
   left: 106.829,
   width: 9.360,
   height: 9.360
}
,
{
   page: 0,
   name: "Check Box10", 
   isText: false,
   type: "checkbox",
   top: 506.341,
   left: 145.04,
   width: 9.36,
   height: 9.360
}
,
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 585.254,
   left: 78.5662,
   width: 189.0928,
   height: 12.764
}
,
{
   page: 0,
   name: "Text4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 695.001,
   left: 79.4807,
   width: 189.0933,
   height: 12.7635
}
,
{
   page: 0,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 585.083,
   left: 344.701,
   width: 189.093,
   height: 12.764
}
,
{
   page: 0,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 694.915,
   left: 344.701,
   width: 189.093,
   height: 12.7635
}
] }
