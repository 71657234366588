import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Image, Menu, Dropdown } from "semantic-ui-react";
import { signOutFirebase } from "../../app/firestore/firebaseService";

export default function NavBarParty() {
  const location = useLocation();
  const navigate = useNavigate();
  const { partyId } = useParams();
  const { authenticated } = useSelector((state) => state.auth);
  const { party, tasksTrans } = useSelector((state) => state.party);
  const { currentUserProfile } = useSelector((state) => state.profile);

  function handleSignOut() {
    signOutFirebase();
    navigate(`/partyLogin`);
  }

  return (
    <>
      <Menu secondary stackable className="main-menu">
        <Menu.Item header style={{ marginLeft: "0px", paddingLeft: "0px" }}>
          <Image src="/assets/logo-color-with-text-small.png" />
        </Menu.Item>
        {authenticated && party?.email && (
          <>
            <Menu.Item
              as={NavLink}
              active={location.pathname.includes("overview")}
              to={`/overviewParty`}
              name="All Transactions"
            />
            <Menu.Item
              as={NavLink}
              active={location.pathname.includes("documents")}
              to={`/party/${partyId}/documents`}
              name="Documents"
            />
            {tasksTrans.length !== 0 && (
              <Menu.Item
                as={NavLink}
                to={`/party/${partyId}/calendar`}
                name="Calendar"
              />
            )}
            <Menu.Item
              as={NavLink}
              to={`/party/${partyId}/contacts`}
              name="Contacts"
            />
            <Menu.Item position="right">
              <Dropdown
                pointing="top left"
                direction="left"
                text={currentUserProfile?.lastName ? (
                  currentUserProfile?.firstName +
                  " " +
                  currentUserProfile?.lastName
                ) : "Account"
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={handleSignOut}
                    text="Sign Out"
                    icon="power"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </>
        )}
      </Menu>
    </>
  );
}
