export function squareFootageDisclosure() {
    return [ 
      {
         page: 0,
         name: "Logo",
         isText: false,
         fontSize: 10,
         type: "logo",
         top: 28.673,
         left: 52.9457,
         width: 288.0003,
         height: 43.2,
       }
       ,
      {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Property Address", 
            top: 190.8,
            left: 147,
            width: 388.8,
            height: 11.4
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box1", 
            top: 231.491,
            left: 160.8,
            width: 14.618,
            height: 13.527
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box2", 
            top: 231.491,
            left: 193.964,
            width: 14.618,
            height: 13.527
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box3", 
            top: 273.600,
            left: 72.8728,
            width: 10.4728,
            height: 9.818
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date Measured 1", 
            top: 273.24,
            left: 307.32,
            width: 120.72,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Square Footage 1", 
            top: 273.24,
            left: 445.92,
            width: 99.12,
            height: 11.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box4", 
            top: 285.382,
            left: 72.8728,
            width: 10.4728,
            height: 9.818
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date Measured 2", 
            top: 285.24,
            left: 307.32,
            width: 120.72,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Square Footage 2", 
            top: 285.24,
            left: 445.92,
            width: 99.12,
            height: 11.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box5", 
            top: 296.946,
            left: 72.8728,
            width: 10.4728,
            height: 9.818
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date Measured 3", 
            top: 297.24,
            left: 307.32,
            width: 120.72,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Square Footage 3", 
            top: 297.24,
            left: 445.92,
            width: 99.12,
            height: 11.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box6", 
            top: 308.946,
            left: 72.8728,
            width: 10.4728,
            height: 9.818
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "undefined", 
            top: 307.8,
            left: 151.92,
            width: 145.56,
            height: 11.4
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date Measured 4", 
            top: 309.24,
            left: 307.32,
            width: 120.72,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Square Footage 4", 
            top: 309.24,
            left: 445.92,
            width: 99.12,
            height: 11.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box7", 
            top: 321.818,
            left: 72.8728,
            width: 10.4728,
            height: 9.818
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "undefined_2", 
            top: 320.04,
            left: 115.56,
            width: 181.92,
            height: 11.16
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date Measured 5", 
            top: 321.24,
            left: 306.6,
            width: 121.44,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Square Footage 5", 
            top: 321.24,
            left: 445.2,
            width: 99.84,
            height: 11.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box8", //is
            top: 362.619,
            left: 166.909,
            width: 11.564,
            height: 11.127
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box9", //is not
            top: 362.619,
            left: 197.018,
            width: 11.564,
            height: 11.127
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Text10", 
            top: 403.200,
            left: 72.8728,
            width: 9.8182,
            height: 9.819
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date 1", 
            top: 402.72,
            left: 307.32,
            width: 120.48,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Square Footage 1_2", 
            top: 402.72,
            left: 445.68,
            width: 99.12,
            height: 11.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box11", 
            top: 415.637,
            left: 72.8728,
            width: 10.4728,
            height: 9.818
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date 2", 
            top: 414.84,
            left: 307.32,
            width: 120.48,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Square Footage 2_2", 
            top: 414.84,
            left: 445.68,
            width: 99.12,
            height: 11.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box12", 
            top: 427.855,
            left: 72.8728,
            width: 10.4728,
            height: 9.818
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date 3", 
            top: 426.84,
            left: 307.32,
            width: 120.48,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Square Footage 3_2", 
            top: 426.84,
            left: 445.68,
            width: 99.12,
            height: 11.40
         }
         ,
         {
            page: 0,
            type: "checkbox",
            name: "Check Box13", 
            top: 439.200,
            left: 72.8728,
            width: 10.4728,
            height: 9.818
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",           //other 
            name: "Date obtained", 
            top: 437.64,
            left: 115.32,
            width: 181.08,
            height: 11.16
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date 4", 
            top: 438.84,
            left: 306.6,
            width: 121.2,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "text",
            name: "Square Footage 4_2", 
            top: 438.84,
            left: 444.96,
            width: 99.84,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 10,
            type: "date",
            name: "Date", 
            top: 541.44,
            left: 96.12,
            width: 192.12,
            height: 11.40
         }
         ,
         {
            page: 0,
            fontName: "Helvetica",
            fontSize: 14,
            type: "signature",
            name: "Broker Signature", 
            top: 537.601,
            left: 324.219,
            width: 212.291,
            height: 15.272
         } 
]
}