export function greenDisclosure() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      type: "logo",
      top: 28.673,
      left: 53,
      width: 240,
      height: 36,
   }
   ,
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 263.945,
      left: 91.8,
      width: 462.48,
      height: 14.335
   }
   ,
   {
      page: 0,
      name: "Property Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 278.121,
      left: 142.68,
      width: 411.60,
      height: 11.679
   }
   ,
   {
      page: 0,
      name: "Seller", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 289.502,
      left: 96.24,
      width: 458.04,
      height: 11.818
   }
   ,
   {
      page: 0,
      name: "Property Address_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 311.306,
      left: 118.44,
      width: 424.32,
      height: 15.094
   }
   ,
   {
      page: 0,
      name: "Home Performance with ENERGY STAR", 
      isText: false,
      type: "checkbox",
      top: 351.96,
      left: 211.56,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "SIPS", 
      isText: false,
      type: "checkbox",
      top: 379.2,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "ICF", 
      isText: false,
      type: "checkbox",
      top: 379.2,
      left: 253.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Material Efficient Framing", 
      isText: false,
      type: "checkbox",
      top: 379.2,
      left: 325.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Improved Insulation", 
      isText: false,
      type: "checkbox",
      top: 392.16,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Straw Bale", 
      isText: false,
      type: "checkbox",
      top: 392.16,
      left: 253.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Earthen BuildRammed Earth", 
      isText: false,
      type: "checkbox",
      top: 392.16,
      left: 325.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Other", 
      isText: false,
      type: "checkbox",
      top: 405.12,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "2_Other", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 402.734,
      left: 200.261,
      width: 352.277,
      height: 14.506
   }
   ,
   {
      page: 0,
      name: "Ground Source Heat Pump", 
      isText: false,
      type: "checkbox",
      top: 432.36,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "High Efficiency FurnaceBoiler eg = 90 AFUE", 
      isText: false,
      type: "checkbox",
      top: 432.36,
      left: 325.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "TanklessOn Demand Water Heater", 
      isText: false,
      type: "checkbox",
      top: 445.32,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "High Efficiency Water Heater eg =90, or EF=82 for gas", 
      isText: false,
      type: "checkbox",
      top: 445.32,
      left: 325.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Evaporative Cooling", 
      isText: false,
      type: "checkbox",
      top: 458.28,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Whole House Fan", 
      isText: false,
      type: "checkbox",
      top: 458.28,
      left: 325.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Ceiling Fans", 
      isText: false,
      type: "checkbox",
      top: 458.28,
      left: 438,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "High SEER AC", 
      isText: false,
      type: "checkbox",
      top: 471.12,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Insulation Air Sealing Upgrades Completed", 
      isText: false,
      type: "checkbox",
      top: 471.12,
      left: 325.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "SEER Rating", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 485.4,
      left: 233.04,
      width: 41.76,
      height: 10.92
   }
   ,
   {
      page: 0,
      name: "Home Orientation South Facing Overhangs", 
      isText: false,
      type: "checkbox",
      top: 484.08,
      left: 325.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Low Flow Toilets", 
      isText: false,
      type: "checkbox",
      top: 511.44,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Low Flow FixturesShower Heads", 
      isText: false,
      type: "checkbox",
      top: 511.44,
      left: 325.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Low Water sod", 
      isText: false,
      type: "checkbox",
      top: 524.28,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Hot Water Recirculation PumpStructured Plumbing", 
      isText: false,
      type: "checkbox",
      top: 524.28,
      left: 325.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Xeriscaping", 
      isText: false,
      type: "checkbox",
      top: 537.24,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Greywater System", 
      isText: false,
      type: "checkbox",
      top: 537.24,
      left: 325.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "WaterSense by ENERGY STAR", 
      isText: false,
      type: "checkbox",
      top: 550.92,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Indoor Air Quality Plus by ENERGY STAR", 
      isText: false,
      type: "checkbox",
      top: 578.28,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "No or Low VOC Paint", 
      isText: false,
      type: "checkbox",
      top: 578.28,
      left: 415.56,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Heat Recovery VentilatorFresh Air System", 
      isText: false,
      type: "checkbox",
      top: 591.12,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Radon Mitigation System", 
      isText: false,
      type: "checkbox",
      top: 591.12,
      left: 415.56,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Green Guard Certified Carpet  Flooring documentation required", 
      isText: false,
      type: "checkbox",
      top: 604.08,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "No Formaldehyde Certified Cabinetry documentation required", 
      isText: false,
      type: "checkbox",
      top: 617.04,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "FSC Certified Lumber", 
      isText: false,
      type: "checkbox",
      top: 644.28,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "FSC Certified Cabinets", 
      isText: false,
      type: "checkbox",
      top: 644.28,
      left: 289.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Recycled Content", 
      isText: false,
      type: "checkbox",
      top: 644.28,
      left: 415.56,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Reclaimed Flooring", 
      isText: false,
      type: "checkbox",
      top: 657.24,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Sustainable Flooring", 
      isText: false,
      type: "checkbox",
      top: 657.24,
      left: 289.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Regionally Harvested", 
      isText: false,
      type: "checkbox",
      top: 657.24,
      left: 415.56,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "ENERGY STARLow E Windows documentation required", 
      isText: false,
      type: "checkbox",
      top: 684.6,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Automated Lighting Controls", 
      isText: false,
      type: "checkbox",
      top: 684.6,
      left: 415.56,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "OrientationPassive Solar Design", 
      isText: false,
      type: "checkbox",
      top: 697.44,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "High Efficiency Lighting", 
      isText: false,
      type: "checkbox",
      top: 697.44,
      left: 415.56,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "ENERGY STAR Rated Roof documentation required", 
      isText: false,
      type: "checkbox",
      top: 710.4,
      left: 145.44,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Programmable Thermostat", 
      isText: false,
      type: "checkbox",
      top: 710.4,
      left: 415.56,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 1,
      name: "Refrigerator", 
      isText: false,
      type: "checkbox",
      top: 32.4,
      left: 145.8,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 1,
      name: "RangeOven", 
      isText: false,
      type: "checkbox",
      top: 32.4,
      left: 240.24,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 1,
      name: "Dishwasher", 
      isText: false,
      type: "checkbox",
      top: 32.4,
      left: 352.68,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 1,
      name: "Clothes Washer", 
      isText: false,
      type: "checkbox",
      top: 32.4,
      left: 447.12,
      width: 10.68,
      height: 10.68
   }
   ,
   {
      page: 1,
      name: "AdditionalComments", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 83.283,
      left: 31.3769,
      width: 521.6921,
      height: 197.201
   }
   ,
   {
      page: 1,
      name: "Seller1NameFull", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 391.648,
      left: 57.0429,
      width: 172.2661,
      height: 12.063
   }
   ,
   {
      page: 1,
      name: "Seller2NameFull", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 390.993,
      left: 340.952,
      width: 158.520,
      height: 13.373
   }
   ,
   {
      page: 1,
      name: "Buyer1Fullname", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 671.033,
      left: 55.7775,
      width: 174.5245,
      height: 14.531
   }
   ,
   {
      page: 1,
      name: "Buyer2Fullname", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 670.378,
      left: 337.723,
      width: 146.695,
      height: 15.841
   }
   ] }
   