import React from "react";
import LoginPage from "./login/LoginPage";

export default function HomePage({ history }) {
  return (
    <>
      <LoginPage />
    </>
  );
}
