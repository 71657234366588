export function addendumReservationofOilGasMinerals() {
return [   //2024 Release 2024-02-18 23:06:18
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 95.12,
   left: 37.08,
   width: 540.24,
   height: 13.48
}
,
{
   page: 0,
   name: "Check Box2", 
   isText: false,
   type: "checkbox",
   top: 266.466,
   left: 71.0717,
   width: 14.6156,
   height: 15.180
}
,
{
   page: 0,
   name: "Check Box3", 
   isText: false,
   type: "checkbox",
   top: 287.337,
   left: 71.0717,
   width: 14.6156,
   height: 15.743
}
,
{
   page: 0,
   name: "Seller does not own all of the Mineral Estate Seller reserves only this percentage or fraction of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 288.701,
   left: 241.2,
   width: 48.24,
   height: 13.219
}
,
{
   page: 0,
   name: "Check Box4", 
   isText: false,
   type: "checkbox",
   top: 329.462,
   left: 103.787,
   width: 14.616,
   height: 12.923
}
,
{
   page: 0,
   name: "Check Box5", 
   isText: false,
   type: "checkbox",
   top: 329.462,
   left: 143.836,
   width: 15.743,
   height: 12.923
}
] }
