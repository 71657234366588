import React from "react";
import { Table, Popup, Label, Icon } from "semantic-ui-react";
import TaskTransSharingListItem from "./TaskTransSharingListItem";

export default function TaskTransSharingList({ deadlines }) {
  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            {/* <Table.HeaderCell style={{ cursor: "default" }}>
              Status
            </Table.HeaderCell> */}
            <Table.HeaderCell>Deadline</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell style={{ cursor: "default" }}>
              Sharing With{" "}
              <Popup
                flowing
                size="large"
                trigger={
                  <Icon
                    name="info"
                    color="blue"
                    circular
                    inverted
                    size="small"
                    style={{ marginLeft: "3px", marginBottom: "3px" }}
                  />
                }
              >
                <p className="bold text blue mini bottom margin">
                  Share deadlines with people so it shows up on their calendar.
                </p>
                <p className="text-dark text-normal mini bottom margin">
                  Note: Only people in your 'Parties' page that have an email
                  address are shown here.
                </p>
                <p className="text-dark text-normal mini bottom margin">
                  Color Key:
                </p>
                <p className="text-dark text-normal mini bottom margin">
                  <Label size="small">Not Shared</Label> Party cannot see this
                  deadline.
                </p>
                <p className="text-dark text-normal mini bottom margin">
                  <Label size="small" color="blue">
                    Shared
                  </Label>{" "}
                  Party can see this deadline.
                  <br />
                  You can unshare it, and they will no longer see it.
                </p>
                <p className="text-dark text-normal mini bottom margin">
                  <Label size="small" color="purple">
                    Copy Sent
                  </Label>{" "}
                  Party received a <span className="bold">copy</span> of this
                  deadline (they have a TransActioner account).
                  <br />
                  If you edit a purple deadline, <br />
                  you need to re-share with the party by double-clicking their
                  button.
                  <br />
                  Purple deadlines cannot be un-shared because they have a copy.
                </p>
              </Popup>
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {deadlines.length !== 0 &&
            deadlines.map((deadline) => (
              <TaskTransSharingListItem deadline={deadline} key={deadline.id} />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
