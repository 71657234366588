export function addendumBlank() {
    return [   //2024 Release 2024-01-22 15:04:40
    {
        page: 0,
        name: "Logo",
        isText: false,
        fontSize: 10,
        type: "logo",
        top: 28.673,
        left: 52.9457,
        width: 288.0003,
      height: 43.2,
      }
      ,
       {
       page: 0,
       name: "Addendum", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 74.771,
       left: 100.92,
       width: 463.20,
       height: 14.629
    }
    ,
    {
       page: 0,
       name: "Buyer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 8,
       top: 676.629,
       left: 78.0874,
       width: 149.3996,
       height: 12.666
    }
    ,
    {
       page: 0,
       name: "signature broker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 729.2474,
       left: 46.8102,
       width: 149.8588,
       height: 12.6654
    }
    ,
    {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 99.011,
       left: 462.72,
       width: 104.76,
       height: 14.629
    }
    ,
    {
       page: 0,
       name: "Buyer_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 8,
       top: 675.666,
       left: 346.397,
       width: 149.400,
       height: 12.665
    }
    ,
    {
       page: 0,
       name: "signature cobroker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 729.2474,
       left: 304.647,
       width: 140.694,
       height: 12.6654
    }
    ,
    {
       page: 0,
       name: "Seller", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 8,
       top: 710.2292,
       left: 78.0874,
       width: 149.3996,
       height: 12.6654
    }
    ,
    {
       page: 0,
       name: "Seller_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 8,
       top: 709.2655,
       left: 346.397,
       width: 149.400,
       height: 12.6654
    }
    ,
    {
       page: 0,
       name: "Date brokerSignDate", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 10,
       top: 729.7746,
       left: 199.975,
       width: 89.640,
       height: 12.6653
    }
    ,
    {
       page: 0,
       name: "Date cobrokerSignDate", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 10,
       top: 729.4655,
       left: 451.265,
       width: 89.640,
       height: 12.6654
    }
    ,
    {
       page: 0,
       name: "Broker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 8,
       top: 744.8292,
       left: 78.0874,
       width: 149.3996,
       height: 12.6654
    }
    ,
    {
       page: 0,
       name: "CoBroker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 8,
       top: 742.8656,
       left: 346.397,
       width: 149.400,
       height: 12.6653
    }
    ,
    {
       page: 0,
       name: "addendumText", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 128.291,
       left: 51.0548,
       width: 513.2742,
       height: 523.385
    }
    ] }
    