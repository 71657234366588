export function rBHomeInspectionWaiverAgreement23() {
    return [   //2023
    {
       page: 0,
       name: "Property Address if applicable", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 410.52,
       left: 182.04,
       width: 378.12,
       height: 15.12
    }
    ,
    {
       page: 0,
       name: "Print Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 482.04,
       left: 36,
       width: 192,
       height: 15.12
    }
    ,
    {
       page: 0,
       name: "Print Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 514.92,
       left: 36,
       width: 192,
       height: 15.12
    }
    ,
    {
       page: 0,
       name: "Date_3", 
       fontSize: 9,
       type: "date",
       top: 561.48,
       left: 288.12,
       width: 76.80,
       height: 15.12
    }
    ,
    {
       page: 0,
       name: "RedBowSig", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 12,
       top: 562.912,
       left: 39.2729,
       width: 223.9641,
       height: 15.454
    }
    ] }
    