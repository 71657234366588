export function exclusiveRighttoSell2024CB() {
    return [   //2024 Release 2023-12-18 08:52:25
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
       page: 0,
       name: "SELLER AGENCY", 
       isText: false,
       type: "checkbox",
       top: 220.44,
       left: 173.88,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "TRANSACTIONBROKERAGE", 
       isText: false,
       type: "checkbox",
       top: 220.44,
       left: 286.44,
       width: 10.56,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "Date_contract", 
       fontSize: 9,
       type: "date",
       top: 245.4,
       left: 405.48,
       width: 154.92,
       height: 14.64
    }
    ,
   //  {
   //     page: 0,
   //     name: "MultiplePerson Firm If this box is checked Broker as defined below is the individual designated by Brokerage Firm", 
   //     isText: false,
   //     type: "checkbox",
   //     top: 326.28,
   //     left: 79.08,
   //     width: 10.08,
   //     height: 10.08
   //  }
   //  ,
   //  {
   //     page: 0,
   //     name: "OnePerson Firm If this box is checked Broker as defined below is a brokerage firm with only one licensed person", 
   //     isText: false,
   //     type: "checkbox",
   //     top: 399.24,
   //     left: 79.08,
   //     width: 10.08,
   //     height: 10.08
   //  }
   //  ,
    {
       page: 0,
       name: "undefined_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 450.989,
       left: 109.56,
       width: 465.00,
       height: 13.331
    }
    ,
    {
       page: 0,
       name: "undefined_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 466.022,
       left: 147.36,
       width: 425.04,
       height: 13.985
    }
    ,
    {
       page: 0,
       name: "undefined_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 481.295,
       left: 114.0,
       width: 459.96,
       height: 13.985
    }
    ,
    {
       page: 0,
       name: "undefined_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 495,
       left: 419.28,
       width: 154.92,
       height: 14.64
    }
    ,
    {
       page: 0,
       name: "Text5", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 521.675,
       left: 58.2549,
       width: 515.2381,
       height: 54.727
    }
    ,
    {
       page: 0,
       name: "undefined_6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 576.76,
       left: 143.4,
       width: 430.08,
       height: 14.64
    }
    ,
    {
       page: 0,
       name: "Affordable Housing If this box is checked Seller represents to the best of Sellers actual knowledge the Property IS", 
       isText: false,
       type: "checkbox",
       top: 634.16,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "If this box is checked Seller authorizes Broker to negotiate a lease of the Property Lease of the Property or", 
       isText: false,
       type: "checkbox",
       top: 726.8,
       left: 97.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "undefined_7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 43.68,
       left: 384.24,
       width: 190.08,
       height: 12.36
    }
    ,
    {
       page: 1,
       name: "undefined_8", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 70.08,
       left: 92.64,
       width: 480.00,
       height: 12.36
    }
    ,
    {
       page: 1,
       name: "not a Saturday Sunday or Holiday Should neither box be checked the deadline will not be extended", 
       isText: false,
       type: "checkbox",
       top: 223.8,
       left: 338.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Will", 
       isText: false,
       type: "checkbox",
       top: 223.8,
       left: 376.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "buyer as a customer", 
       isText: false,
       type: "checkbox",
       top: 464.04,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "offers on the Property and whether the offers were obtained by Broker a broker within Brokerage Firm or by another", 
       isText: false,
       type: "checkbox",
       top: 231.72,
       left: 171,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Will Not disclose to prospective buyers and cooperating brokers the existence of", 
       isText: false,
       type: "checkbox",
       top: 231.72,
       left: 218.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "of the gross purchase price or 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 407.585,
       left: 184.88,
       width: 42.982,
       height: 13.735
    }
    ,
    {
       page: 2,
       name: "in US dollars", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 408.24,
       left: 423.753,
       width: 80.585,
       height: 12.425
    }
    ,
    {
       page: 2,
       name: "undefined_9", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 422.88,
       left: 508.8,
       width: 54.96,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "in US dollars_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 438.0,
       left: 218.04,
       width: 84.96,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "undefined_10", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 453.84,
       left: 495.48,
       width: 70.08,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "in US dollars payable as follows", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 467.76,
       left: 254.64,
       width: 120.00,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "Brokerage Firm agrees to contribute from the Lease", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 481.68,
       left: 92.52,
       width: 274.92,
       height: 12.84
    }
    ,
    {
       page: 2,
       name: "of the gross rent or", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 495.36,
       left: 304.92,
       width: 40.08,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "in US dollars_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 495.36,
       left: 443.88,
       width: 64.92,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "Text6", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 522.984,
       left: 66.7639,
       width: 511.3111,
       height: 32.473
    }
    ,
    {
       page: 2,
       name: "calendar days after the Listing Period expires", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 607.44,
       left: 354.96,
       width: 39.96,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "Brokerage Firm under this  723 if a commission is earned by another licensed brokerage firm acting pursuant to an", 
       isText: false,
       type: "checkbox",
       top: 637.08,
       left: 383.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Holdover Period 1 to anyone with whom Broker negotiated and 2 whose name was submitted in writing to Seller by", 
       isText: false,
       type: "checkbox",
       top: 637.08,
       left: 423.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will_2", 
       isText: false,
       type: "checkbox",
       top: 176.04,
       left: 162.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will Not be submitted to one or more MLS and", 
       isText: false,
       type: "checkbox",
       top: 176.04,
       left: 202.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will_3", 
       isText: false,
       type: "checkbox",
       top: 192.12,
       left: 162.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will Not be submitted to one or more property information exchanges", 
       isText: false,
       type: "checkbox",
       top: 192.12,
       left: 202.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Text7", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 269.019,
       left: 70.6912,
       width: 507.3838,
       height: 30.510
    }
    ,
    {
       page: 3,
       name: "Will_4", 
       isText: false,
       type: "checkbox",
       top: 314.28,
       left: 189.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will Not be displayed on the Internet", 
       isText: false,
       type: "checkbox",
       top: 314.28,
       left: 232.2,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will_5", 
       isText: false,
       type: "checkbox",
       top: 329.16,
       left: 189.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Will Not be displayed on the Internet_2", 
       isText: false,
       type: "checkbox",
       top: 329.16,
       left: 232.2,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Electronic Lock Box", 
       isText: false,
       type: "checkbox",
       top: 372.12,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Manual Lock Box", 
       isText: false,
       type: "checkbox",
       top: 387.72,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Check Box26", 
       isText: false,
       type: "checkbox",
       top: 403.893,
       left: 122.28,
       width: 10.08,
       height: 10.080
    }
    ,
    {
       page: 3,
       name: "undefined_11", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 398.64,
       left: 138.24,
       width: 429.96,
       height: 14.52
    }
    ,
    {
       page: 3,
       name: "undefined_12", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 414,
       left: 162.84,
       width: 405.00,
       height: 14.52
    }
    ,
    {
       page: 3,
       name: "Actively Licensed Real Estate Brokers", 
       isText: false,
       type: "checkbox",
       top: 461.16,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Licensed Appraisers", 
       isText: false,
       type: "checkbox",
       top: 461.16,
       left: 302.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Unlicensed Broker Assistants", 
       isText: false,
       type: "checkbox",
       top: 476.76,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Unlicensed Inspectors", 
       isText: false,
       type: "checkbox",
       top: 476.76,
       left: 302.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "Other", 
       isText: false,
       type: "checkbox",
       top: 492.12,
       left: 122.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 3,
       name: "undefined_13", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 487.44,
       left: 166.2,
       width: 405.0,
       height: 14.52
    }
    ,
    {
       page: 3,
       name: "Text8", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 531.493,
       left: 38.6183,
       width: 540.1107,
       height: 224.9103
    }
    ,
    {
       page: 4,
       name: "Text9", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 57.6,
       left: 69.3821,
       width: 510.0019,
       height: 45.564
    }
    ,
    {
       page: 4,
       name: "Is", 
       isText: false,
       type: "checkbox",
       top: 258.84,
       left: 338.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Is Not currently a party to any listing agreement", 
       isText: false,
       type: "checkbox",
       top: 258.84,
       left: 367.56,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Has", 
       isText: false,
       type: "checkbox",
       top: 271.8,
       left: 393.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Has Not received a list of", 
       isText: false,
       type: "checkbox",
       top: 271.8,
       left: 431.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "111 Price US", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 484.8,
       left: 135.96,
       width: 129.96,
       height: 14.04
    }
    ,
    {
       page: 4,
       name: "Cash", 
       isText: false,
       type: "checkbox",
       top: 504.12,
       left: 119.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Conventional", 
       isText: false,
       type: "checkbox",
       top: 504.12,
       left: 170.04,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "FHA", 
       isText: false,
       type: "checkbox",
       top: 504.12,
       left: 256.2,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "VA", 
       isText: false,
       type: "checkbox",
       top: 504.12,
       left: 306.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "undefined_14", 
       isText: false,
       type: "checkbox",
       top: 504.12,
       left: 349.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Other_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 499.92,
       left: 396.48,
       width: 180.00,
       height: 14.04
    }
    ,
    {
       page: 4,
       name: "113 Loan Discount Points", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 518.88,
       left: 177.72,
       width: 399.96,
       height: 14.04
    }
    ,
    {
       page: 4,
       name: "114 Buyers Closing Costs FHAVA Seller must pay closing costs and fees not to exceed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 538.08,
       left: 447.36,
       width: 109.92,
       height: 14.04
    }
    ,
    {
       page: 4,
       name: "Buyer is not allowed by law to pay for tax service and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 552.96,
       left: 299.76,
       width: 275.04,
       height: 14.04
    }
    ,
    {
       page: 4,
       name: "115 Earnest Money Minimum amount of earnest money deposit US", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 571.92,
       left: 356.76,
       width: 174.96,
       height: 14.04
    }
    ,
    {
       page: 4,
       name: "in the form of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 586.8,
       left: 136.2,
       width: 440.04,
       height: 14.04
    }
    ,
    {
       page: 4,
       name: "Cashiers Check at Sellers expense", 
       isText: false,
       type: "checkbox",
       top: 627.24,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Funds Electronically Transferred Wire Transfer to an account specified by Seller at Sellers expense or", 
       isText: false,
       type: "checkbox",
       top: 644.52,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Closing Companys Trust Account Check Wire and other frauds occur in real estate transactions Any time Seller is", 
       isText: false,
       type: "checkbox",
       top: 663.72,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "IS a foreign person for purposes of US income taxation and authorizes", 
       isText: false,
       type: "checkbox",
       top: 730.44,
       left: 273.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "including accessories and garage door openers including", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 221.76,
       left: 328.56,
       width: 94.92,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "following are owned by the Seller and included leased items should be listed under 1316 Leased Items", 
       isText: false,
       type: "checkbox",
       top: 252.36,
       left: 93,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "dishes If any additional items are attached to the Property after the date of this Seller Listing Contract such additional", 
       isText: false,
       type: "checkbox",
       top: 252.36,
       left: 139.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Water Softeners", 
       isText: false,
       type: "checkbox",
       top: 252.36,
       left: 218.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Security Systems", 
       isText: false,
       type: "checkbox",
       top: 252.36,
       left: 312.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Satellite Systems including satellite", 
       isText: false,
       type: "checkbox",
       top: 252.36,
       left: 406.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Text10", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 359.347,
       left: 66.7639,
       width: 509.3471,
       height: 213.783
    }
    ,
    {
       page: 5,
       name: "Text11", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 608.73,
       left: 69.3821,
       width: 504.7649,
       height: 110.364
    }
    ,
    {
       page: 6,
       name: "Text12", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 73.964,
       left: 79.2003,
       width: 498.2197,
       height: 75.018
    }
    ,
    {
       page: 6,
       name: "Text13", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 199.637,
       left: 55.6366,
       width: 522.4384,
       height: 65.200
    }
    ,
    {
       page: 6,
       name: "Text14", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 276.874,
       left: 56.9457,
       width: 519.1653,
       height: 28.545
    }
    ,
    {
       page: 6,
       name: "undefined_15", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 329.28,
       left: 78.48,
       width: 495.00,
       height: 12.36
    }
    ,
    {
       page: 6,
       name: "undefined_16", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 372.96,
       left: 78.48,
       width: 489.96,
       height: 12.36
    }
    ,
    {
       page: 6,
       name: "undefined_17", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 403.44,
       left: 78.48,
       width: 495.00,
       height: 12.36
    }
    ,
    {
       page: 6,
       name: "1351", 
       isText: false,
       type: "checkbox",
       top: 436.68,
       left: 101.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "Text15", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 447.711,
       left: 68.7276,
       width: 509.3474,
       height: 58.000
    }
    ,
    {
       page: 6,
       name: "Seller agrees to convey any deeded water rights by a good and sufficient", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 507.12,
       left: 362.04,
       width: 215.04,
       height: 12.36
    }
    ,
    {
       page: 6,
       name: "1352", 
       isText: false,
       type: "checkbox",
       top: 536.76,
       left: 101.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "Text16", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 557.675,
       left: 70.6912,
       width: 507.3838,
       height: 47.528
    }
    ,
    {
       page: 6,
       name: "1353", 
       isText: false,
       type: "checkbox",
       top: 609.96,
       left: 101.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "undefined_18", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 607.44,
       left: 258.72,
       width: 310.08,
       height: 12.36
    }
    ,
    {
       page: 6,
       name: "1354", 
       isText: false,
       type: "checkbox",
       top: 625.32,
       left: 101.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 6,
       name: "Text17", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 638.839,
       left: 68.073,
       width: 510.656,
       height: 42.946
    }
    ,
    {
       page: 6,
       name: "Text18", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 695.7849,
       left: 57.6003,
       width: 520.4747,
       height: 59.3092
    }
    ,
    {
       page: 7,
       name: "special warranty deed", 
       isText: false,
       type: "checkbox",
       top: 127.56,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "quit claim deed", 
       isText: false,
       type: "checkbox",
       top: 127.56,
       left: 194.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "general warranty deed", 
       isText: false,
       type: "checkbox",
       top: 142.44,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "personal representatives deed", 
       isText: false,
       type: "checkbox",
       top: 142.44,
       left: 194.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "bargain and sale deed", 
       isText: false,
       type: "checkbox",
       top: 157.56,
       left: 79.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "Check Box25", 
       isText: false,
       type: "checkbox",
       top: 157.782,
       left: 194.52,
       width: 10.08,
       height: 10.080
    }
    ,
    {
       page: 7,
       name: "deed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 154.56,
       left: 210.36,
       width: 334.92,
       height: 12.84
    }
    ,
    {
       page: 7,
       name: "Text19", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 246.419,
       left: 76.5822,
       width: 504.7648,
       height: 25.273
    }
    ,
    {
       page: 7,
       name: "Text20", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 310.256,
       left: 57.6003,
       width: 524.4017,
       height: 33.782
    }
    ,
    {
       page: 7,
       name: "An Abstract of Title certified to a current date", 
       isText: false,
       type: "checkbox",
       top: 371.64,
       left: 344.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "per", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 401.76,
       left: 175.92,
       width: 90.00,
       height: 12.84
    }
    ,
    {
       page: 7,
       name: "and that there", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 401.76,
       left: 283.68,
       width: 240.00,
       height: 12.84
    }
    ,
    {
       page: 7,
       name: "Seller agrees to promptly request", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 429.36,
       left: 64.8,
       width: 375.0,
       height: 12.84
    }
    ,
    {
       page: 7,
       name: "undefined_19", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 474.72,
       left: 64.8,
       width: 504.96,
       height: 12.84
    }
    ,
    {
       page: 7,
       name: "Agrees", 
       isText: false,
       type: "checkbox",
       top: 628.92,
       left: 278.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "Does Not Agree to provide on or before the sale contract", 
       isText: false,
       type: "checkbox",
       top: 628.92,
       left: 329.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "Check Box28", 
       isText: false,
       type: "checkbox",
       top: 362.729,
       left: 82.2,
       width: 10.08,
       height: 10.080
    }
    ,
    {
       page: 8,
       name: "Check Box27", 
       isText: false,
       type: "checkbox",
       top: 378.02,
       left: 82.2,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "Check Box29", 
       isText: false,
       type: "checkbox",
       top: 407.782,
       left: 82.2,
       width: 10.08,
       height: 10.080
    }
    ,
    {
       page: 8,
       name: "undefined_20", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 403.68,
       left: 126.0,
       width: 450.0,
       height: 14.04
    }
    ,
    {
       page: 9,
       name: "Text21", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 334.474,
       left: 38.6184,
       width: 536.1836,
       height: 420.6202
    }
    ,
    {
       page: 10,
       name: "Text22", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 58.255,
       left: 55.6366,
       width: 519.8194,
       height: 109.055
    }
    ,
    {
       page: 10,
       name: "313 Electronic Delivery Electronic Delivery of documents and notice may be delivered by 1 email at the email address of the", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 279.6,
       left: 305.64,
       width: 270.00,
       height: 12.36
    }
    ,
    {
       page: 10,
       name: "Brokerage Firm", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 518.191,
       left: 124.025,
       width: 424.920,
       height: 14.489
    }
    ,
    {
       page: 10,
       name: "Signature_broker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 537.275,
       left: 47.1275,
       width: 223.9635,
       height: 14.489
    }
    ,
    {
       page: 10,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 537.275,
       left: 282.12,
       width: 120.00,
       height: 14.489
    }
    ,
    {
       page: 10,
       name: "Brokerage Firm Street Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 566.807,
       left: 49.68,
       width: 495.00,
       height: 14.489
    }
    ,
    {
       page: 10,
       name: "Brokerage Firm City State Zip", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 594.367,
       left: 49.68,
       width: 495.00,
       height: 14.489
    }
    ,
    {
       page: 10,
       name: "Broker Phone No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 623.273,
       left: 49.68,
       width: 495.00,
       height: 14.489
    }
    ,
    {
       page: 10,
       name: "Broker Fax No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 651.556,
       left: 49.68,
       width: 495.00,
       height: 14.489
    }
    ,
    {
       page: 10,
       name: "Broker Email Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 678.807,
       left: 49.68,
       width: 495.00,
       height: 14.4891
    }
    ,
    {
       page: 10,
       name: "Text26", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 709.4931,
       left: 172.781,
       width: 372.109,
       height: 14.4888
    }
    ,
    {
       page: 10,
       name: "Text27", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 726.7726,
       left: 48.1969,
       width: 225.9271,
       height: 14.4888
    }
    ,
    {
       page: 10,
       name: "Text28", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 10,
       top: 726.2615,
       left: 286.926,
       width: 258.655,
       height: 14.4887
    }
    ,
    {
       page: 11,
       name: "Seller", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 54.195,
       left: 77.64,
       width: 390.00,
       height: 15.960
    }
    ,
    {
       page: 11,
       name: "Street Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 127.875,
       left: 49.68,
       width: 420.00,
       height: 15.960
    }
    ,
    {
       page: 11,
       name: "City State Zip", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 159.315,
       left: 49.68,
       width: 420.00,
       height: 15.720
    }
    ,
    {
       page: 11,
       name: "Phone No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 190.515,
       left: 49.68,
       width: 420.00,
       height: 15.720
    }
    ,
    {
       page: 11,
       name: "Email Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 221.715,
       left: 49.68,
       width: 420.00,
       height: 15.960
    }
    ,
    {
       page: 11,
       name: "Seller_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 279.795,
       left: 77.64,
       width: 390.00,
       height: 15.960
    }
    ,
    {
       page: 11,
       name: "Street Address_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 349.395,
       left: 49.68,
       width: 420.00,
       height: 15.960
    }
    ,
    {
       page: 11,
       name: "City State Zip_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 380.835,
       left: 49.68,
       width: 420.00,
       height: 15.960
    }
    ,
    {
       page: 11,
       name: "Phone No_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 412.275,
       left: 49.68,
       width: 420.00,
       height: 15.720
    }
    ,
    {
       page: 11,
       name: "Email Address_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 443.475,
       left: 49.68,
       width: 420.00,
       height: 15.960
    }
    ] }
    