import { useEffect } from "react";
import { dataFromSnapshot } from "../firestore/firestoreService";
import { onSnapshot } from "@firebase/firestore";

export default function useFirestoreCollectionNoAsync({
  query,
  data,
  deps,
  retainState,
}) {
  useEffect(() => {
    console.log("Running");
    if (retainState) {
      return;
    }
    console.log("No data fetch");
    const unsubscribe = onSnapshot(
      query(),
      (snapshot) => {
        const docs = snapshot.docs.map((doc) => dataFromSnapshot(doc));
        data(docs);
      },
      (error) => console.log(error)
    );
    return () => {
      unsubscribe();
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
