export function gTARListingAgreement12024rev4() {
return [   //2024 Release 2024-04-18 20:49:32
{
   page: 0,
   name: "This Listing AgreementExclusive Right to Sell the Agreement is entered into effective as of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 141.12,
   left: 427.56,
   width: 99.96,
   height: 15.48
}
,
{
   page: 0,
   name: "Text40", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 143.835,
   left: 542.547,
   width: 16.029,
   height: 13.780
}
,
{
   page: 0,
   name: "Effective Date by and between", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 157.44,
   left: 171.24,
   width: 225.12,
   height: 12.36
}
,
{
   page: 0,
   name: "Sellers Broker and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 170.64,
   left: 125.16,
   width: 358.80,
   height: 12.36
}
,
{
   page: 0,
   name: "Property Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 213.12,
   left: 105.72,
   width: 463.44,
   height: 15.48
}
,
{
   page: 0,
   name: "Legal Description", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 231.48,
   left: 100.68,
   width: 468.12,
   height: 15.48
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 249.36,
   left: 36,
   width: 531,
   height: 15.48
}
,
{
   page: 0,
   name: "this Agreement at a price of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 348.48,
   left: 153.6,
   width: 135.0,
   height: 15.48
}
,
{
   page: 0,
   name: "insert date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 503.88,
   left: 54,
   width: 54.96,
   height: 12.36
}
,
{
   page: 0,
   name: "Seller agrees to pay Sellers Broker a commission the Commission equal to the greater of i", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 552.36,
   left: 441.6,
   width: 81.12,
   height: 15.48
}
,
{
   page: 0,
   name: "blank of the total Purchase Price for the Property plus", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 569.76,
   left: 274.32,
   width: 80.04,
   height: 15.48
}
,
{
   page: 0,
   name: "0 if left blank or ii", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 569.76,
   left: 454.56,
   width: 98.64,
   height: 15.48
}
,
{
   page: 0,
   name: "The Commission shall be split", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 604.32,
   left: 199.92,
   width: 20.04,
   height: 15.48
}
,
{
   page: 0,
   name: "of the total Purchase Price or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 604.32,
   left: 369.6,
   width: 50.04,
   height: 15.48
}
,
{
   page: 0,
   name: "and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 621.6,
   left: 141.96,
   width: 20.04,
   height: 15.48
}
,
{
   page: 0,
   name: "of the total Purchase Price or_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 621.6,
   left: 311.76,
   width: 50.04,
   height: 15.48
}
,
{
   page: 0,
   name: "The Sale of the Property within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 672.24,
   left: 200.16,
   width: 54.96,
   height: 15.48
}
,
{
   page: 1,
   name: "The Sale of the Property during or within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 60.48,
   left: 237.12,
   width: 20.04,
   height: 15.36
}
,
{
   page: 3,
   name: "is", 
   isText: false,
   type: "checkbox",
   top: 610.44,
   left: 189.6,
   width: 10.08,
   height: 9.96
}
,
{
   page: 3,
   name: "is not authorized to place a Lock Box on the Property", 
   isText: false,
   type: "checkbox",
   top: 610.44,
   left: 210.36,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   name: "C  Seller hereby discloses to REALTOR the following video or audio recording devices are in or around the Property 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 68.4,
   left: 54,
   width: 494.88,
   height: 12.48
}
,
{
   page: 4,
   name: "C  Seller hereby discloses to REALTOR the following video or audio recording devices are in or around the Property 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 85.56,
   left: 54,
   width: 494.88,
   height: 12.48
}
,
{
   page: 4,
   name: "C  Seller hereby discloses to REALTOR the following video or audio recording devices are in or around the Property 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 102.84,
   left: 54,
   width: 494.88,
   height: 12.48
}
,
{
   page: 4,
   name: "C  Seller hereby discloses to REALTOR the following video or audio recording devices are in or around the Property 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 120,
   left: 54,
   width: 494.88,
   height: 12.48
}
,
{
   page: 4,
   name: "does", 
   isText: false,
   type: "checkbox",
   top: 227.4,
   left: 80.52,
   width: 10.08,
   height: 9.96
}
,
{
   page: 4,
   name: "does not authorize Sellers Broker to disclose the existence of offers on the Property", 
   isText: false,
   type: "checkbox",
   top: 227.4,
   left: 123.48,
   width: 9.96,
   height: 9.96
}
,
{
   page: 4,
   name: "0 if left blank for the marketing of the property or the performance of administrative and transactional services Seller understands", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 257.28,
   left: 462.6,
   width: 113.4,
   height: 12.48
}
,
{
   page: 4,
   name: "Sellers Property that would be transferable to the Buyer  The cost for a RSA is approximately", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 357.96,
   left: 427.8,
   width: 69.24,
   height: 12.48
}
,
{
   page: 4,
   name: "TO PURCHASE", 
   isText: false,
   type: "checkbox",
   top: 391.2,
   left: 141.24,
   width: 9.96,
   height: 10.08
}
,
{
   page: 4,
   name: "NOT TO PURCHASE a RSA at this time", 
   isText: false,
   type: "checkbox",
   top: 391.2,
   left: 235.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Check Box38", 
   isText: false,
   type: "checkbox",
   top: 424.147,
   left: 54.6366,
   width: 14.0727,
   height: 12.764
}
,
{
   page: 4,
   name: "Check Box39", 
   isText: false,
   type: "checkbox",
   top: 424.147,
   left: 88.0186,
   width: 14.0724,
   height: 12.764
}
,
{
   page: 4,
   name: "OTHER CONDITIONS 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 453.36,
   left: 50.4,
   width: 519.96,
   height: 12.36
}
,
{
   page: 4,
   name: "OTHER CONDITIONS 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 466.44,
   left: 50.4,
   width: 519.96,
   height: 12.48
}
,
{
   page: 4,
   name: "OTHER CONDITIONS 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 479.64,
   left: 50.4,
   width: 519.96,
   height: 12.48
}
,
{
   page: 5,
   name: "SELLERS BROKER Company Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 77.174,
   left: 36.0,
   width: 229.92,
   height: 13.494
}
,
{
   page: 5,
   name: "Date Signed", 
   fontSize: 9,
   type: "date",
   top: 64.68,
   left: 520.92,
   width: 54.96,
   height: 14.803
}
,
{
   page: 5,
   name: "SELLERS BROKERAssociate Printed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 99.24,
   left: 295.2,
   width: 280.8,
   height: 13.494
}
,
{
   page: 5,
   name: "Cell Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 133.68,
   left: 295.2,
   width: 234.96,
   height: 13.494
}
,
{
   page: 5,
   name: "Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 168.24,
   left: 295.2,
   width: 234.96,
   height: 13.494
}
,
{
   page: 5,
   name: "signature sellers broker", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 66.162,
   left: 295.997,
   width: 212.647,
   height: 13.494
}
,
{
   page: 5,
   name: "SELLER Printed", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 271.68,
   left: 36.0,
   width: 249.96,
   height: 13.494
}
,
{
   page: 5,
   name: "SELLER Printed_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 271.68,
   left: 309.6,
   width: 266.76,
   height: 13.494
}
,
{
   page: 5,
   name: "Cell Phone_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 306.24,
   left: 36.0,
   width: 254.88,
   height: 13.494
}
,
{
   page: 5,
   name: "Cell Phone_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 306.24,
   left: 309.6,
   width: 266.76,
   height: 13.494
}
,
{
   page: 5,
   name: "Email_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 340.68,
   left: 36.0,
   width: 254.88,
   height: 13.494
}
,
{
   page: 5,
   name: "Email_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 340.68,
   left: 309.6,
   width: 266.76,
   height: 13.494
}
,
{
   page: 5,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 378.6,
   left: 36.0,
   width: 254.76,
   height: 13.494
}
,
{
   page: 5,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 378.6,
   left: 309.36,
   width: 266.64,
   height: 13.494
}
,
{
   page: 5,
   name: "Mailing Address of Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 408.48,
   left: 36.0,
   width: 254.76,
   height: 13.494
}
,
{
   page: 5,
   name: "Mailing Address of Seller_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 408.48,
   left: 309.36,
   width: 266.64,
   height: 13.494
}
,
{
   page: 5,
   name: "SELLER Printed_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 510.84,
   left: 36.0,
   width: 249.96,
   height: 13.494
}
,
{
   page: 5,
   name: "SELLER Printed_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 510.84,
   left: 309.6,
   width: 266.76,
   height: 13.494
}
,
{
   page: 5,
   name: "Cell Phone_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 545.4,
   left: 36.0,
   width: 254.88,
   height: 13.494
}
,
{
   page: 5,
   name: "Cell Phone_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 545.4,
   left: 309.6,
   width: 266.76,
   height: 13.494
}
,
{
   page: 5,
   name: "Email_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 579.84,
   left: 36.0,
   width: 254.88,
   height: 13.494
}
,
{
   page: 5,
   name: "Email_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 579.84,
   left: 309.6,
   width: 266.76,
   height: 13.494
}
,
{
   page: 5,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 617.76,
   left: 36.0,
   width: 254.76,
   height: 13.494
}
,
{
   page: 5,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 617.76,
   left: 309.36,
   width: 266.64,
   height: 13.494
}
,
{
   page: 5,
   name: "Mailing Address of Seller_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 647.76,
   left: 36.0,
   width: 254.76,
   height: 13.494
}
,
{
   page: 5,
   name: "Mailing Address of Seller_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 647.76,
   left: 309.36,
   width: 266.64,
   height: 13.494
}
] }
