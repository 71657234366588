import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  autoGoToNextAnnot,
  selectDocAnnot,
} from "../../../../app/annots/annotSlice";
import { openModal } from "../../../../app/common/modals/modalSlice";
import { updateAnnotSignedInDb } from "../../../../app/firestore/firestoreService";
import { updateSignatureAnnotSigned } from "../partyDocSignUtils";

export default function AnnotFieldSignature({
  annot,
  doc,
  pageScale,
  ipAddress,
}) {
  const dispatch = useDispatch();
  const { selectedSigner, annotsSigningStatus } = useSelector(
    (state) => state.annot
  );
  const ref = useRef(null);

  const color = annot.signed ? "232, 232, 232" : "103, 211, 189";

  function handleOnClick() {
    if (!annot.signed) {
      dispatch(selectDocAnnot(annot));
      if (selectedSigner.signingDetailsConfirmed === true) {
        const annotFields = {
          signedFullName: selectedSigner.signedFullName,
          signedInitials: selectedSigner.signedInitials,
          signedFontFamily: selectedSigner.signedFontFamily,
        };
        try {
          dispatch(autoGoToNextAnnot(true));
          if (doc.pdfBurnVersion) {
            updateSignatureAnnotSigned(doc, annot, selectedSigner, ipAddress);
          } else {
            updateAnnotSignedInDb(annot.id, doc.id, annotFields);
          }
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        dispatch(
          openModal({
            modalType: "AnnotSignatureModal",
            modalProps: { ipAddress: ipAddress },
          })
        );
      }
    }
  }

  return (
    <div
      ref={ref}
      id={"page" + annot.page + "-y" + annot.y.toString().split(".")[0]}
      onClick={() => handleOnClick()}
      style={{
        width: annot.width * pageScale,
        height: annot.height * pageScale,
        minWidth: 12 * pageScale,
        minHeight: 12 * pageScale,
        maxWidth: 960 * pageScale,
        maxHeight: 960 * pageScale,
        position: "absolute",
        display: "inline-block",
        cursor: "pointer",
        zIndex: 2,
        top: annot.y * pageScale,
        left: annot.x * pageScale,
        lineHeight: 0.6,
        whiteSpace: "nowrap",
      }}
    >
      {annotsSigningStatus?.nextAnnotToSign?.uniqueId === annot.uniqueId &&
        !annot.signed && (
          <>
            <div
              className="arrow-pointer blink_me"
              style={{
                color: "white",
                backgroundColor: `rgb(${color})`,
              }}
            >
              <span>
                {annot.type === "signature" || annot.type === "initials"
                  ? "Sign Here"
                  : "Click Here"}
              </span>
            </div>
          </>
        )}
      <div
        className="annot-outer-wrapper"
        style={{ backgroundColor: `rgba(${color}, 0.6)` }}
      >
        <div
          className="annot-inner-wrapper"
          style={{
            boxShadow: `rgb(${color}) 0px 0px 0px 1px`,
            zIndex: 5,
          }}
        >
          {annot.signed && annot.type === "signature" && (
            <>
              <span
                style={{
                  fontSize: `${annot.fontSize * pageScale}px`,
                  color: "#0037b8",
                  fontFamily: `${annot.signedFontFamily}`,
                }}
              >
                {annot.signedFullName}
              </span>
            </>
          )}
          {annot.signed && annot.type === "initials" && (
            <>
              <span
                style={{
                  fontSize: `${annot.fontSize * pageScale}px`,
                  color: "#0037b8",
                  fontFamily: `${annot.signedFontFamily}`,
                }}
              >
                {annot.signedInitials}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
