export function noticetoPurchaserofSpecialTaxingDistrict() {
return [   //2024 Release 2024-02-18 21:37:53
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.128,
   left: 50.4002,
   width: 508.6928,
   height: 13.491
}
,
{
   page: 0,
   name: "Text2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 238.601,
   left: 51.7093,
   width: 174.8727,
   height: 12.182
}
,
{
   page: 0,
   name: "The current rate of the district property tax is", 
   isText: false,
   type: "checkbox",
   top: 297.84,
   left: 48.12,
   width: 11.52,
   height: 9.12
}
,
{
   page: 0,
   name: "Text3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 293.893,
   left: 303.71,
   width: 163.091,
   height: 12.836
}
,
{
   page: 0,
   name: "The district has not yet imposed taxes The projected rate of the district property tax is", 
   isText: false,
   type: "checkbox",
   top: 329.28,
   left: 48.12,
   width: 11.52,
   height: 9.12
}
,
{
   page: 0,
   name: "Text4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 340.365,
   left: 69.3821,
   width: 170.2909,
   height: 11.527
}
,
{
   page: 0,
   name: "The rate of the district assessment is", 
   isText: false,
   type: "checkbox",
   top: 400.8,
   left: 48.12,
   width: 11.52,
   height: 9.12
}
,
{
   page: 0,
   name: "Text5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 397.966,
   left: 261.165,
   width: 204.982,
   height: 11.527
}
,
{
   page: 0,
   name: "The amount of the district assessment on the real property that you are about to purchase is", 
   isText: false,
   type: "checkbox",
   top: 432.24,
   left: 48.12,
   width: 11.52,
   height: 9.12
}
,
{
   page: 0,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 455.966,
   left: 73.3094,
   width: 205.6366,
   height: 13.491
}
,
{
   page: 0,
   name: "The district has not yet imposed an assessment but the projected", 
   isText: false,
   type: "checkbox",
   top: 463.56,
   left: 48.12,
   width: 11.52,
   height: 9.12
}
,
{
   page: 0,
   name: "rate", 
   isText: false,
   type: "checkbox",
   top: 463.56,
   left: 436.8,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "amount of the", 
   isText: false,
   type: "checkbox",
   top: 463.56,
   left: 480.72,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 476.548,
   left: 145.31,
   width: 206.945,
   height: 13.491
}
,
{
   page: 0,
   name: "property taxes", 
   isText: false,
   type: "checkbox",
   top: 509.76,
   left: 359.52,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "assessments", 
   isText: false,
   type: "checkbox",
   top: 509.76,
   left: 458.52,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "excluding refunding bonds that are separately approved by the voters", 
   isText: false,
   type: "checkbox",
   top: 527.76,
   left: 70.44,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "excluding any bonds", 
   isText: false,
   type: "checkbox",
   top: 527.012,
   left: 454.56,
   width: 11.64,
   height: 9.868
}
,
{
   page: 0,
   name: "Text8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.493,
   left: 94.255,
   width: 100.909,
   height: 14.801
}
,
{
   page: 0,
   name: "Text9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 585.203,
   left: 94.255,
   width: 100.909,
   height: 14.801
}
,
{
   page: 0,
   name: "Text10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 606.839,
   left: 94.255,
   width: 100.909,
   height: 14.801
}
,
{
   page: 0,
   name: "Text11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 627.475,
   left: 94.255,
   width: 100.909,
   height: 14.801
}
,
{
   page: 0,
   name: "Text12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 627.439,
   left: 215.346,
   width: 339.819,
   height: 14.801
}
,
{
   page: 0,
   name: "Text13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 663.403,
   left: 94.255,
   width: 100.909,
   height: 13.492
}
,
{
   page: 0,
   name: "Text14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 683.385,
   left: 94.255,
   width: 100.909,
   height: 14.8008
}
,
{
   page: 0,
   name: "Text15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 705.9486,
   left: 94.255,
   width: 101.563,
   height: 14.8008
}
,
{
   page: 0,
   name: "Text16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 726.2396,
   left: 94.255,
   width: 102.218,
   height: 14.8008
}
,
{
   page: 0,
   name: "Text17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 725.6214,
   left: 214.692,
   width: 339.819,
   height: 14.8008
}
,
{
   page: 0,
   name: "Initialed for identification by Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 757.44,
   left: 193.44,
   width: 22.92,
   height: 12.36
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 757.44,
   left: 225.24,
   width: 27.12,
   height: 12.36
}
,
{
   page: 0,
   name: "and Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 757.44,
   left: 313.68,
   width: 28.68,
   height: 12.36
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 757.44,
   left: 351.24,
   width: 27.12,
   height: 12.36
}
,
{
   page: 1,
   name: "Text18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 42.273,
   left: 33.382,
   width: 547.965,
   height: 13.491
}
,
{
   page: 1,
   name: "Text19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 85.128,
   left: 452.293,
   width: 119.891,
   height: 13.491
}
,
{
   page: 1,
   name: "The", 
   isText: false,
   type: "checkbox",
   top: 171.4,
   left: 48.6,
   width: 11.52,
   height: 9.12
}
,
{
   page: 1,
   name: "The_2", 
   isText: false,
   type: "checkbox",
   top: 234.28,
   left: 47.4,
   width: 11.64,
   height: 9.12
}
,
{
   page: 1,
   name: "Text20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 183.201,
   left: 73.964,
   width: 150.000,
   height: 13.491
}
,
{
   page: 1,
   name: "Text21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 246.038,
   left: 72.6549,
   width: 151.9641,
   height: 13.491
}
,
{
   page: 1,
   name: "The_3", 
   isText: false,
   type: "checkbox",
   top: 318.52,
   left: 49.92,
   width: 11.52,
   height: 9.12
}
,
{
   page: 1,
   name: "Text22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 329.166,
   left: 72.6549,
   width: 150.0001,
   height: 13.491
}
,
{
   page: 1,
   name: "water", 
   isText: false,
   type: "checkbox",
   top: 403.24,
   left: 140.88,
   width: 11.64,
   height: 9.12
}
,
{
   page: 1,
   name: "sewer", 
   isText: false,
   type: "checkbox",
   top: 403.24,
   left: 187.92,
   width: 11.64,
   height: 9.12
}
,
{
   page: 1,
   name: "drainage", 
   isText: false,
   type: "checkbox",
   top: 403.24,
   left: 236.04,
   width: 11.64,
   height: 9.12
}
,
{
   page: 1,
   name: "flood control", 
   isText: false,
   type: "checkbox",
   top: 403.24,
   left: 298.44,
   width: 11.64,
   height: 9.12
}
,
{
   page: 1,
   name: "firefighting", 
   isText: false,
   type: "checkbox",
   top: 403.24,
   left: 383.28,
   width: 11.64,
   height: 9.12
}
,
{
   page: 1,
   name: "road", 
   isText: false,
   type: "checkbox",
   top: 403.24,
   left: 459.96,
   width: 11.64,
   height: 9.12
}
,
{
   page: 1,
   name: "parks and recreational", 
   isText: false,
   type: "checkbox",
   top: 422.68,
   left: 49.8,
   width: 11.64,
   height: 9.12
}
,
{
   page: 1,
   name: "is not included in the purchase price of your property", 
   isText: false,
   type: "checkbox",
   top: 422.68,
   left: 181.08,
   width: 11.64,
   height: 9.12
}
,
{
   page: 1,
   name: "Text23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 417.111,
   left: 196.271,
   width: 229.200,
   height: 14.146
}
] }
