export function escalationAddendum() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 183", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 103.32,
   left: 175.5,
   width: 80.1,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 194", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 103.32,
   left: 285.75,
   width: 199.17,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 195", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 103.32,
   left: 499.68,
   width: 37.17,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 196", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 114.75,
   left: 32.4,
   width: 143.1,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 197", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 115.75,
   left: 237.6,
   width: 154.98,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 198", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 127.08,
   left: 73.44,
   width: 311.895,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 199", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 234.28,
   left: 462.6,
   width: 73.215,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 200", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 245.52,
   left: 387.36,
   width: 76.815,
   height: 13.50
}
,
{
   page: 0,
   name: "Check Box 19", 
   isText: false,
   type: "checkbox",
   top: 324.93,
   left: 49.68,
   width: 8.28,
   height: 8.25
}
,
{
   page: 0,
   name: "Check Box 20", 
   isText: false,
   type: "checkbox",
   top: 352.08,
   left: 49.68,
   width: 8.28,
   height: 8.25
}
,
{
   page: 0,
   name: "Check Box 21", 
   isText: false,
   type: "checkbox",
   top: 379.23,
   left: 49.68,
   width: 8.28,
   height: 8.25
}
,
{
   page: 0,
   name: "Text Field 201", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 376.21,
   left: 381.96,
   width: 45.54,
   height: 13.50
}
,
{
   page: 0,
   name: "Check Box 22", 
   isText: false,
   type: "checkbox",
   top: 406.38,
   left: 49.68,
   width: 8.28,
   height: 8.25
}
,
{
   page: 0,
   name: "Text Field 202", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 403.755,
   left: 201.96,
   width: 81.36,
   height: 13.500
}
,
{
   page: 0,
   name: "Check Box 23", 
   isText: false,
   type: "checkbox",
   top: 434.88,
   left: 49.68,
   width: 8.28,
   height: 8.25
}
,
{
   page: 0,
   name: "Text Field 203", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 430.905,
   left: 88.56,
   width: 491.94,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 204", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 448.92,
   left: 59.76,
   width: 520.74,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 205", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 466.935,
   left: 59.76,
   width: 520.74,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 206", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 531.115,
   left: 201.96,
   width: 45.36,
   height: 13.500
}
,
{
   page: 0,
   name: "Check Box 24", 
   isText: false,
   type: "checkbox",
   top: 621.75,
   left: 49.68,
   width: 8.28,
   height: 8.25
}
,
{
   page: 0,
   name: "Check Box 25", 
   isText: false,
   type: "checkbox",
   top: 649.08,
   left: 49.68,
   width: 8.28,
   height: 8.25
}
,
{
   page: 0,
   name: "Check Box 26", 
   isText: false,
   type: "checkbox",
   top: 676.41,
   left: 49.68,
   width: 8.28,
   height: 8.25
}
,
{
   page: 0,
   name: "Text Field 207", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 672.75,
   left: 88.56,
   width: 491.94,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 208", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 690.765,
   left: 59.76,
   width: 520.74,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 209", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 708.78,
   left: 59.76,
   width: 520.74,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 219", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 180.0,
   left: 110.16,
   width: 172.80,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 220", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 180.0,
   left: 396.0,
   width: 184.5,
   height: 13.5
}
,
{
   page: 1,
   name: "Text Field 218", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 200.16,
   left: 110.16,
   width: 172.80,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 221", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 200.16,
   left: 396.0,
   width: 184.5,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 176", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 220.32,
   left: 55.44,
   width: 227.52,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 193", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 220.32,
   left: 344.88,
   width: 235.62,
   height: 13.50
}
] }
