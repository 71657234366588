export function sellersPropertyDisclosureResidential() {
   return [   //2023


      {
         page: 0,
         name: "Date", 
         fontSize: 9,
         type: "date",
         top: 315.6,
         left: 96.12,
         width: 453.00,
         height: 14.52
      }
      ,
      {
         page: 0,
         name: "Property", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 333.6,
         left: 111.72,
         width: 437.40,
         height: 14.52
      }
      ,
      {
         page: 0,
         name: "Seller", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 351.6,
         left: 100.56,
         width: 448.56,
         height: 14.52
      }
      ,
      {
         page: 0,
         name: "Year Built", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 369.6,
         left: 119.16,
         width: 429.96,
         height: 14.52
      }
      ,
      {
         page: 0,
         name: "Year Seller Acquired Property", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 387.6,
         left: 199.2,
         width: 349.92,
         height: 14.52
      }
      ,
      {
         page: 0,
         name: "Check A1", 
         isText: false,
         type: "checkbox",
         top: 532.478,
         left: 299.092,
         width: 14.379,
         height: 15.413
      }
      ,
      {
         page: 0,
         name: "1 Structural", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 531.992,
         left: 320.221,
         width: 254.153,
         height: 15.514
      }
      ,
      {
         page: 0,
         name: "Check A2", 
         isText: false,
         type: "checkbox",
         top: 552.861,
         left: 299.092,
         width: 14.379,
         height: 15.413
      }
      ,
      {
         page: 0,
         name: "2 Moisture andor water", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 551.786,
         left: 320.221,
         width: 254.153,
         height: 15.514
      }
      ,
      {
         page: 0,
         name: "Check A3", 
         isText: false,
         type: "checkbox",
         top: 574.161,
         left: 299.092,
         width: 14.379,
         height: 15.413
      }
      ,
      {
         page: 0,
         name: "3 Damage due to termites other insects birds animals or rodents", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 573.04,
         left: 320.221,
         width: 254.153,
         height: 15.514
      }
      ,
      {
         page: 0,
         name: "Check A4", 
         isText: false,
         type: "checkbox",
         top: 596.65,
         left: 299.092,
         width: 14.379,
         height: 15.413
      }
      ,
      {
         page: 0,
         name: "4 Damage due to hail wind fire flood or other casualty", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 596.741,
         left: 320.221,
         width: 254.153,
         height: 15.514
      }
      ,
      {
         page: 0,
         name: "Check A5", 
         isText: false,
         type: "checkbox",
         top: 617.112,
         left: 299.092,
         width: 14.379,
         height: 15.413
      }
      ,
      {
         page: 0,
         name: "5 Cracks heaving or settling", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 615.444,
         left: 320.221,
         width: 254.153,
         height: 15.514
      }
      ,
      {
         page: 0,
         name: "Check A6", 
         isText: false,
         type: "checkbox",
         top: 637.979,
         left: 299.092,
         width: 14.379,
         height: 15.413
      }
      ,
      {
         page: 0,
         name: "6 Exterior wall or window", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 635.89,
         left: 320.221,
         width: 254.153,
         height: 15.514
      }
      ,
      {
         page: 0,
         name: "Check A7", 
         isText: false,
         type: "checkbox",
         top: 657.412,
         left: 299.092,
         width: 14.379,
         height: 15.413
      }
      ,
      {
         page: 0,
         name: "7 Exterior Artificial Stucco EIFS", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 655.404,
         left: 320.221,
         width: 254.153,
         height: 15.514
      }
      ,
      {
         page: 0,
         name: "Check A8", 
         isText: false,
         type: "checkbox",
         top: 675.251,
         left: 299.092,
         width: 14.379,
         height: 15.413
      }
      ,
      {
         page: 0,
         name: "8 Subfloors", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 674.89,
         left: 320.221,
         width: 254.153,
         height: 15.514
      }
      ,
      {
         page: 0,
         name: "A9 Issue", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 693.1185,
         left: 73.2261,
         width: 217.7239,
         height: 17.3716
      }
      ,
      {
         page: 0,
         name: "Check A9", 
         isText: false,
         type: "checkbox",
         top: 694.901,
         left: 299.092,
         width: 14.379,
         height: 15.4133
      }
      ,
      {
         page: 0,
         name: "9", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 693.7752,
         left: 320.221,
         width: 254.153,
         height: 15.5136
      }
      ,
      {
         page: 0,
         name: "10", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 711.4,
         left: 72.96,
         width: 217.724,
         height: 17.3715
      }
      ,
      {
         page: 0,
         name: "Check A10", 
         isText: false,
         type: "checkbox",
         top: 712.5233,
         left: 299.092,
         width: 14.379,
         height: 15.4133
      }
      ,
      {
         page: 0,
         name: "A10 Comment", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 712.7747,
         left: 320.221,
         width: 254.153,
         height: 15.5136
      }
      ,
      {
         page: 1,
         name: "Check Box34", 
         isText: false,
         type: "checkbox",
         top: 69.68,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "1 Roof leak", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 70.92,
         left: 324.829,
         width: 249.571,
         height: 12.128
      }
      ,
      {
         page: 1,
         name: "Check Box35", 
         isText: false,
         type: "checkbox",
         top: 87.386,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "2 Damage to roof", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 87.24,
         left: 324.829,
         width: 249.571,
         height: 12.128
      }
      ,
      {
         page: 1,
         name: "Check Box36", 
         isText: false,
         type: "checkbox",
         top: 104.015,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "3 Skylight", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 105.84,
         left: 324.829,
         width: 249.571,
         height: 12.128
      }
      ,
      {
         page: 1,
         name: "Check Box37", 
         isText: false,
         type: "checkbox",
         top: 122.237,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "4 Gutter or downspout", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 122.52,
         left: 324.829,
         width: 249.571,
         height: 12.128
      }
      ,
      {
         page: 1,
         name: "Check Box38", 
         isText: false,
         type: "checkbox",
         top: 140.083,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "5 Other roof problems issues or concerns", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 139.68,
         left: 324.829,
         width: 249.571,
         height: 12.128
      }
      ,
      {
         page: 1,
         name: "B6 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 156.55,
         left: 73.597,
         width: 221.221,
         height: 13.245
      }
      ,
      {
         page: 1,
         name: "Check Box39", 
         isText: false,
         type: "checkbox",
         top: 157.116,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "6", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 156.48,
         left: 324.829,
         width: 249.571,
         height: 12.128
      }
      ,
      {
         page: 1,
         name: "B7 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 172.031,
         left: 73.5187,
         width: 221.2203,
         height: 13.245
      }
      ,
      {
         page: 1,
         name: "Check Box40", 
         isText: false,
         type: "checkbox",
         top: 173.339,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "7", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 173.16,
         left: 324.829,
         width: 249.571,
         height: 12.128
      }
      ,
      {
         page: 1,
         name: "undefined", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 216.96,
         left: 174.24,
         width: 84.48,
         height: 9.60
      }
      ,
      {
         page: 1,
         name: "undefined_2", 
         isText: false,
         type: "checkbox",
         top: 229.92,
         left: 130.68,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "undefined_3", 
         isText: false,
         type: "checkbox",
         top: 229.92,
         left: 164.76,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "Check Box42", 
         isText: false,
         type: "checkbox",
         top: 222.413,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "8 Roof under warranty until Transferable YES NO", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 217.68,
         left: 325.662,
         width: 248.309,
         height: 21.968
      }
      ,
      {
         page: 1,
         name: "Check Box43", 
         isText: false,
         type: "checkbox",
         top: 243.503,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "9 Roof work done while under current roof warranty", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 242.72,
         left: 325.662,
         width: 248.309,
         height: 12.96
      }
      ,
      {
         page: 1,
         name: "undefined_4", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 258.008,
         left: 132.72,
         width: 102.394,
         height: 11.152
      }
      ,
      {
         page: 1,
         name: "undefined_5", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 258.768,
         left: 253.48,
         width: 41.882,
         height: 11.152
      }
      ,
      {
         page: 1,
         name: "Check Box44", 
         isText: false,
         type: "checkbox",
         top: 257.698,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "10 Roof material Age", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 258.2,
         left: 325.662,
         width: 248.309,
         height: 12.915
      }
      ,
      {
         page: 1,
         name: "11", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 275.04,
         left: 73.32,
         width: 223.08,
         height: 13.32
      }
      ,
      {
         page: 1,
         name: "Check Box45", 
         isText: false,
         type: "checkbox",
         top: 273.109,
         left: 305.029,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 1,
         name: "B11 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 274.165,
         left: 325.662,
         width: 248.309,
         height: 13.246
      }
      ,
      {
         page: 1,
         name: "Check Box46", 
         isText: false,
         type: "checkbox",
         top: 338.39,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text47", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 338.545,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "1 Builtin vacuum system  accessories", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 338.669,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box48", 
         isText: false,
         type: "checkbox",
         top: 353.818,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text49", // Dryer
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 354.2,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "2 Clothes dryer", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 354.2,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box50", 
         isText: false,
         type: "checkbox",
         top: 368.824,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text52", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 369.56,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "3 Clothes washer", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 369.56,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box53", 
         isText: false,
         type: "checkbox",
         top: 385.047,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text54", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 384.703,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "4 Dishwasher", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 384.703,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box55", 
         isText: false,
         type: "checkbox",
         top: 400.269,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text56", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 400.183,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "5 Disposal", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 400.183,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box57", 
         isText: false,
         type: "checkbox",
         top: 416.087,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text58", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 415.76,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "6 Freezer", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 415.76,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box59", 
         isText: false,
         type: "checkbox",
         top: 432.309,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text60", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 431.12,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "7 Gas grill", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 431.12,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box61", 
         isText: false,
         type: "checkbox",
         top: 446.504,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text62", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 446.6,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "8 Hood", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 446.6,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box63", 
         isText: false,
         type: "checkbox",
         top: 461.916,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text64", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 461.96,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "9 Microwave oven", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 461.96,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box65", 
         isText: false,
         type: "checkbox",
         top: 476.111,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text66", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 477.32,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "10 Oven", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 477.32,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box67", 
         isText: false,
         type: "checkbox",
         top: 490.711,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text68", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 492.8,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "11 Range", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 492.8,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box69", 
         isText: false,
         type: "checkbox",
         top: 506.529,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text70", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 508.16,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "12 Refrigerator", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 508.16,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "undefined_6", 
         isText: false,
         type: "checkbox",
         top: 526.2,
         left: 132.24,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "undefined_7", 
         isText: false,
         type: "checkbox",
         top: 526.2,
         left: 175.32,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "Check Box71", 
         isText: false,
         type: "checkbox",
         top: 521.129,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text72", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 523.52,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "13 TV antenna Owned Leased", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 523.52,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "undefined_8", 
         isText: false,
         type: "checkbox",
         top: 541.56,
         left: 188.04,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "undefined_9", 
         isText: false,
         type: "checkbox",
         top: 541.56,
         left: 231.12,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "Check Box73", 
         isText: false,
         type: "checkbox",
         top: 538.163,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text74", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 539.0,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "14 Satellite system or DSS dish Owned Leased", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 539.0,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "Check Box75", 
         isText: false,
         type: "checkbox",
         top: 553.575,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text76", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 554.36,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "15 Trash compactor", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 554.36,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "C16 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 570.636,
         left: 72.597,
         width: 204.004,
         height: 11.694
      }
      ,
      {
         page: 1,
         name: "Check Box77", 
         isText: false,
         type: "checkbox",
         top: 570.609,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text78", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 569.72,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "16", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 569.72,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "17", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 584.643,
         left: 72.84,
         width: 204.004,
         height: 11.694
      }
      ,
      {
         page: 1,
         name: "Check Box79", 
         isText: false,
         type: "checkbox",
         top: 585.181,
         left: 285.89,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 1,
         name: "Text80", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 584.643,
         left: 306.575,
         width: 37.386,
         height: 12.505
      }
      ,
      {
         page: 1,
         name: "C17 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 584.643,
         left: 347.582,
         width: 226.336,
         height: 12.683
      }
      ,
      {
         page: 1,
         name: "undefined_10", 
         isText: false,
         type: "checkbox",
         top: 652.08,
         left: 142.68,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "undefined_11", 
         isText: false,
         type: "checkbox",
         top: 652.08,
         left: 185.76,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "Check Box81", 
         isText: false,
         type: "checkbox",
         top: 649.952,
         left: 297.54,
         width: 12.309,
         height: 12.309
      }
      ,
      {
         page: 1,
         name: "Text82", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 650.128,
         left: 320.589,
         width: 37.315,
         height: 10.882
      }
      ,
      {
         page: 1,
         name: "1 Security system Owned Leased", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 650.28,
         left: 361.76,
         width: 213.571,
         height: 12.045
      }
      ,
      {
         page: 1,
         name: "undefined_12", 
         isText: false,
         type: "checkbox",
         top: 667.44,
         left: 160.2,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "undefined_13", 
         isText: false,
         type: "checkbox",
         top: 667.44,
         left: 203.76,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "Check Box83", 
         isText: false,
         type: "checkbox",
         top: 665.078,
         left: 297.54,
         width: 12.309,
         height: 12.309
      }
      ,
      {
         page: 1,
         name: "Text84", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 664.729,
         left: 320.589,
         width: 37.315,
         height: 10.882
      }
      ,
      {
         page: 1,
         name: "2 Smokefire detectors Battery Hardwire", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 665.88,
         left: 361.76,
         width: 213.571,
         height: 12.045
      }
      ,
      {
         page: 1,
         name: "undefined_14", 
         isText: false,
         type: "checkbox",
         top: 682.8,
         left: 174.12,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "undefined_15", 
         isText: false,
         type: "checkbox",
         top: 682.8,
         left: 216.72,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 1,
         name: "Check Box85", 
         isText: false,
         type: "checkbox",
         top: 679.784,
         left: 297.54,
         width: 12.309,
         height: 12.8264
      }
      ,
      {
         page: 1,
         name: "Text86", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 680.14,
         left: 320.589,
         width: 37.315,
         height: 10.882
      }
      ,
      {
         page: 1,
         name: "3 Carbon Monoxide Alarm Battery Hardwire", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 681.24,
         left: 361.76,
         width: 213.571,
         height: 12.0454
      }
      ,
      {
         page: 1,
         name: "Check Box87", 
         isText: false,
         type: "checkbox",
         top: 696.1185,
         left: 297.54,
         width: 12.309,
         height: 12.3094
      }
      ,
      {
         page: 1,
         name: "Text88", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 695.9575,
         left: 320.589,
         width: 37.315,
         height: 10.8820
      }
      ,
      {
         page: 1,
         name: "4 Light fixtures", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 696.72,
         left: 361.76,
         width: 213.571,
         height: 12.0454
      }
      ,
      {
         page: 1,
         name: "Check Box89", 
         isText: false,
         type: "checkbox",
         top: 710.7469,
         left: 297.54,
         width: 12.309,
         height: 12.3095
      }
      ,
      {
         page: 1,
         name: "Text90", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 711.3691,
         left: 320.589,
         width: 37.315,
         height: 10.8820
      }
      ,
      {
         page: 1,
         name: "5 Switches  outlets", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 712.08,
         left: 361.76,
         width: 213.571,
         height: 12.0454
      }
      ,
      {
         page: 1,
         name: "Check Box91", 
         isText: false,
         type: "checkbox",
         top: 726.1307,
         left: 297.54,
         width: 12.309,
         height: 12.3094
      }
      ,
      {
         page: 1,
         name: "Text92", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 726.3752,
         left: 320.589,
         width: 37.315,
         height: 10.8820
      }
      ,
      {
         page: 1,
         name: "Telecommunications T1 fiber cable satellite", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 727.44,
         left: 361.76,
         width: 213.571,
         height: 12.0454
      }
      ,
      {
         page: 2,
         name: "Text94", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 44.396,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "Check Box93", 
         isText: false,
         type: "checkbox",
         top: 44.136,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "7 Inside telephone wiring  blocksjacks", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 43.72,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box95", 
         isText: false,
         type: "checkbox",
         top: 58.996,
         left: 298.135,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 2,
         name: "Text96", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 60.024,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "8 Ceiling fans", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 59.84,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "undefined_16", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 88.2,
         left: 154.44,
         width: 40.44,
         height: 10.08
      }
      ,
      {
         page: 2,
         name: "Check Box97", 
         isText: false,
         type: "checkbox",
         top: 80.492,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text98", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 80.303,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "9 Garage door opener and remote control  of remoteopeners", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 75.92,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box99", 
         isText: false,
         type: "checkbox",
         top: 102.959,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text100", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 103.015,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "10 Intercomdoorbell", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 102.92,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box101", 
         isText: false,
         type: "checkbox",
         top: 117.993,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text102", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 118.426,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "11 Inwall speakers", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 118.28,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "D12 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 133.838,
         left: 73.3803,
         width: 212.4797,
         height: 12.210
      }
      ,
      {
         page: 2,
         name: "Check Box103", 
         isText: false,
         type: "checkbox",
         top: 133.216,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text104", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 133.838,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "12", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 133.76,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "D13 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 148.913,
         left: 73.3803,
         width: 212.4797,
         height: 12.210
      }
      ,
      {
         page: 2,
         name: "Check Box105", 
         isText: false,
         type: "checkbox",
         top: 148.627,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text106", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 148.033,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "13", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 149.12,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box107", 
         isText: false,
         type: "checkbox",
         top: 202.54,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text108", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 202.7,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "14 Electrical Service", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 203.96,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box109", 
         isText: false,
         type: "checkbox",
         top: 218.385,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text110", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 219.413,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "15 Aluminum wiring at the outlets 110", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 219.32,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "undefined_18", 
         isText: false,
         type: "checkbox",
         top: 237.36,
         left: 129.72,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 2,
         name: "undefined_19", 
         isText: false,
         type: "checkbox",
         top: 237.36,
         left: 172.8,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 2,
         name: "Check Box111", 
         isText: false,
         type: "checkbox",
         top: 232.986,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text112", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 234.419,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "16 Solar panels Owned Leased", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 234.68,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "undefined_21", 
         isText: false,
         type: "checkbox",
         top: 252.72,
         left: 145.2,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 2,
         name: "undefined_22", 
         isText: false,
         type: "checkbox",
         top: 252.72,
         left: 188.28,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 2,
         name: "Check Box113", 
         isText: false,
         type: "checkbox",
         top: 249.209,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text114", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 248.9,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "17 Wind generators Owned Leased", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 250.16,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box115", 
         isText: false,
         type: "checkbox",
         top: 264.62,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text116", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 265.8,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "17 Electric Wiring or Panel", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 265.52,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "D18 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 281.249,
         left: 74.1914,
         width: 213.5156,
         height: 12.210
      }
      ,
      {
         page: 2,
         name: "Check Box117", 
         isText: false,
         type: "checkbox",
         top: 280.032,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text118", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 281.465,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "18", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 280.88,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "D19 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 296.729,
         left: 74.1914,
         width: 213.5156,
         height: 12.210
      }
      ,
      {
         page: 2,
         name: "Check Box119", 
         isText: false,
         type: "checkbox",
         top: 295.849,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text120", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 297.283,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "19", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 296.36,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box121", 
         isText: false,
         type: "checkbox",
         top: 350.762,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text122", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 351.629,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "20 220 volt service", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 351.08,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "undefined_23", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 366.683,
         left: 169.92,
         width: 116.405,
         height: 10.597
      }
      ,
      {
         page: 2,
         name: "Check Box123", 
         isText: false,
         type: "checkbox",
         top: 365.985,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text124", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 366.635,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "21 Electrical Service Amps", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 366.56,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box125", 
         isText: false,
         type: "checkbox",
         top: 383.019,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text126", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 382.452,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "22 Landscape lighting", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 381.92,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "undefined_25", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 397.523,
         left: 147.585,
         width: 138.231,
         height: 10.597
      }
      ,
      {
         page: 2,
         name: "Check Box127", 
         isText: false,
         type: "checkbox",
         top: 399.025,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text128", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 397.864,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "23 Electric Provider", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 397.28,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "undefined_26", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 411.848,
         left: 151.035,
         width: 134.781,
         height: 11.632
      }
      ,
      {
         page: 2,
         name: "Check Box129", 
         isText: false,
         type: "checkbox",
         top: 413.625,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text130", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 413.8,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "24 CableTV provider", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 412.76,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "undefined_27", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 428.76,
         left: 173.16,
         width: 112.656,
         height: 10.08
      }
      ,
      {
         page: 2,
         name: "Check Box131", 
         isText: false,
         type: "checkbox",
         top: 429.037,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text132", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 428.282,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "25 Sellers Internet Provider", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 428.12,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "26", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 444.48,
         left: 72.84,
         width: 214.92,
         height: 12.96
      }
      ,
      {
         page: 2,
         name: "Check Box133", 
         isText: false,
         type: "checkbox",
         top: 444.26,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text134", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 443.288,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "D26 Comment", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 442.476,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box135", 
         isText: false,
         type: "checkbox",
         top: 507.717,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text136", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 507.773,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "1 Overhead doors including garage doors", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 507.56,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box137", 
         isText: false,
         type: "checkbox",
         top: 522.129,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text138", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 522.779,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "2 Entry gate system", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 523.16,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box139", 
         isText: false,
         type: "checkbox",
         top: 538.163,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text140", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 538.191,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "3 Elevator", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 538.64,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box141", 
         isText: false,
         type: "checkbox",
         top: 553.791,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text142", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 553.603,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "4 Radon mitigation system", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 554.0,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "undefined_28", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 570.12,
         left: 151.56,
         width: 43.56,
         height: 10.08
      }
      ,
      {
         page: 2,
         name: "Check Box143", 
         isText: false,
         type: "checkbox",
         top: 568.986,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text144", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 569.014,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "5 Sump pumps  of", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 569.36,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box145", 
         isText: false,
         type: "checkbox",
         top: 583.587,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text146", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 584.426,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "6 Recycle pump", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 584.84,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "E7 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 601.054,
         left: 72.597,
         width: 217.136,
         height: 13.246
      }
      ,
      {
         page: 2,
         name: "Check Box147", 
         isText: false,
         type: "checkbox",
         top: 600.215,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text148", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 600.243,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "7_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 600.2,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "8", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 616.56,
         left: 72.84,
         width: 216.12,
         height: 12.96
      }
      ,
      {
         page: 2,
         name: "Check Box149", 
         isText: false,
         type: "checkbox",
         top: 616.466,
         left: 298.135,
         width: 12.309,
         height: 12.827
      }
      ,
      {
         page: 2,
         name: "Text150", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 616.06,
         left: 320.994,
         width: 37.428,
         height: 13.134
      }
      ,
      {
         page: 2,
         name: "E8 Comment", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 614.871,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box151", 
         isText: false,
         type: "checkbox",
         top: 679.896,
         left: 298.135,
         width: 12.309,
         height: 12.8267
      }
      ,
      {
         page: 2,
         name: "Text152", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 679.329,
         left: 316.994,
         width: 37.428,
         height: 12.099
      }
      ,
      {
         page: 2,
         name: "1 Heating system", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 679.64,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box153", 
         isText: false,
         type: "checkbox",
         top: 695.3074,
         left: 298.135,
         width: 12.309,
         height: 12.8267
      }
      ,
      {
         page: 2,
         name: "Text154", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 695.36,
         left: 316.994,
         width: 37.428,
         height: 12.0990
      }
      ,
      {
         page: 2,
         name: "2 Evaporative cooler", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 695.36,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box155", 
         isText: false,
         type: "checkbox",
         top: 710.3135,
         left: 298.135,
         width: 12.309,
         height: 12.8267
      }
      ,
      {
         page: 2,
         name: "Text156", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 710.72,
         left: 316.994,
         width: 37.428,
         height: 12.0990
      }
      ,
      {
         page: 2,
         name: "3 Window air conditioning units", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 710.72,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 2,
         name: "Check Box157", 
         isText: false,
         type: "checkbox",
         top: 726.1585,
         left: 298.135,
         width: 12.309,
         height: 12.8267
      }
      ,
      {
         page: 2,
         name: "Text158", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 726.08,
         left: 316.994,
         width: 37.428,
         height: 12.0991
      }
      ,
      {
         page: 2,
         name: "Central air conditioning", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 726.08,
         left: 360.911,
         width: 212.879,
         height: 12.434
      }
      ,
      {
         page: 3,
         name: "Text160", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 44.613,
         left: 316.128,
         width: 37.944,
         height: 12.323
      }
      ,
      {
         page: 3,
         name: "Check Box159", 
         isText: false,
         type: "checkbox",
         top: 44.151,
         left: 297.416,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 3,
         name: "5 Atticwhole house fan", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 45.72,
         left: 357.89,
         width: 216.075,
         height: 12.029
      }
      ,
      {
         page: 3,
         name: "Check Box161", 
         isText: false,
         type: "checkbox",
         top: 60.65,
         left: 297.416,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 3,
         name: "Text162", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 60.647,
         left: 316.128,
         width: 37.944,
         height: 12.323
      }
      ,
      {
         page: 3,
         name: "6 Vent fans", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 60.84,
         left: 357.89,
         width: 216.075,
         height: 12.029
      }
      ,
      {
         page: 3,
         name: "Check Box163", 
         isText: false,
         type: "checkbox",
         top: 75.219,
         left: 297.416,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 3,
         name: "Text164", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 75.653,
         left: 316.128,
         width: 37.944,
         height: 12.323
      }
      ,
      {
         page: 3,
         name: "7 Humidifier", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 76.56,
         left: 357.89,
         width: 216.075,
         height: 12.029
      }
      ,
      {
         page: 3,
         name: "Check Box165", 
         isText: false,
         type: "checkbox",
         top: 91.26,
         left: 297.416,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 3,
         name: "Text166", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 91.253,
         left: 316.128,
         width: 37.944,
         height: 12.323
      }
      ,
      {
         page: 3,
         name: "8 Air purifier", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 91.92,
         left: 357.89,
         width: 216.075,
         height: 12.029
      }
      ,
      {
         page: 3,
         name: "Check Box167", 
         isText: false,
         type: "checkbox",
         top: 105.842,
         left: 297.416,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 3,
         name: "Text168", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 105.854,
         left: 316.128,
         width: 37.944,
         height: 12.323
      }
      ,
      {
         page: 3,
         name: "9 Fireplace", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 106.063,
         left: 357.89,
         width: 216.075,
         height: 12.029
      }
      ,
      {
         page: 3,
         name: "Check Box169", 
         isText: false,
         type: "checkbox",
         top: 121.687,
         left: 297.416,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 3,
         name: "Text170", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 122.076,
         left: 316.128,
         width: 37.944,
         height: 12.323
      }
      ,
      {
         page: 3,
         name: "10 Fireplace insert", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 121.543,
         left: 357.89,
         width: 216.075,
         height: 12.029
      }
      ,
      {
         page: 3,
         name: "Check Box171", 
         isText: false,
         type: "checkbox",
         top: 136.866,
         left: 297.416,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 3,
         name: "Text172", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 137.488,
         left: 316.128,
         width: 37.944,
         height: 12.323
      }
      ,
      {
         page: 3,
         name: "11 Heating Stove", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 138.12,
         left: 357.89,
         width: 216.075,
         height: 12.029
      }
      ,
      {
         page: 3,
         name: "Check Box173", 
         isText: false,
         type: "checkbox",
         top: 152.788,
         left: 297.416,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 3,
         name: "Text174", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 152.089,
         left: 316.128,
         width: 37.944,
         height: 12.323
      }
      ,
      {
         page: 3,
         name: "12 Fuel tanks", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 153.48,
         left: 357.89,
         width: 216.075,
         height: 12.029
      }
      ,
      {
         page: 3,
         name: "F13 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 167.5,
         left: 73.0025,
         width: 214.5185,
         height: 13.246
      }
      ,
      {
         page: 3,
         name: "Check Box175", 
         isText: false,
         type: "checkbox",
         top: 167.256,
         left: 297.416,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 3,
         name: "Text176", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 167.5,
         left: 316.128,
         width: 37.944,
         height: 12.323
      }
      ,
      {
         page: 3,
         name: "13_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 168.96,
         left: 357.89,
         width: 216.075,
         height: 12.029
      }
      ,
      {
         page: 3,
         name: "F14 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 182.981,
         left: 73.1271,
         width: 214.9539,
         height: 13.246
      }
      ,
      {
         page: 3,
         name: "Check Box177", 
         isText: false,
         type: "checkbox",
         top: 183.117,
         left: 297.416,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 3,
         name: "Text178", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 182.912,
         left: 316.128,
         width: 37.944,
         height: 12.323
      }
      ,
      {
         page: 3,
         name: "14", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 184.32,
         left: 357.89,
         width: 216.075,
         height: 12.029
      }
      ,
      {
         page: 3,
         name: "1", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 246.24,
         left: 98.52,
         width: 67.44,
         height: 9.84
      }
      ,
      {
         page: 3,
         name: "1_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 245.367,
         left: 186.48,
         width: 99.731,
         height: 10.713
      }
      ,
      {
         page: 3,
         name: "2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 257.28,
         left: 98.52,
         width: 67.44,
         height: 9.84
      }
      ,
      {
         page: 3,
         name: "2_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 267.993,
         left: 186.48,
         width: 99.731,
         height: 10.713
      }
      ,
      {
         page: 3,
         name: "Check Box179", 
         isText: false,
         type: "checkbox",
         top: 246.964,
         left: 297.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text180", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 248.614,
         left: 316.45,
         width: 36.504,
         height: 13.540
      }
      ,
      {
         page: 3,
         name: "15 Heating system including furnace Type Fuel Type Fuel", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 239.2,
         left: 359.106,
         width: 215.262,
         height: 27.765
      }
      ,
      {
         page: 3,
         name: "undefined_29", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 272.16,
         left: 136.68,
         width: 149.913,
         height: 9.36
      }
      ,
      {
         page: 3,
         name: "undefined_30", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 8,
         top: 284.029,
         left: 96.12,
         width: 189.502,
         height: 8.531
      }
      ,
      {
         page: 3,
         name: "Check Box181", 
         isText: false,
         type: "checkbox",
         top: 276.976,
         left: 297.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text182", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 276.193,
         left: 316.45,
         width: 36.504,
         height: 13.540
      }
      ,
      {
         page: 3,
         name: "16 Fireplace Type Fuel", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 271.88,
         left: 359.106,
         width: 215.262,
         height: 22.56
      }
      ,
      {
         page: 3,
         name: "undefined_31", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 297.6,
         left: 154.44,
         width: 131.269,
         height: 9.36
      }
      ,
      {
         page: 3,
         name: "undefined_32", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 308.04,
         left: 96.12,
         width: 189.502,
         height: 11.149
      }
      ,
      {
         page: 3,
         name: "Check Box183", 
         isText: false,
         type: "checkbox",
         top: 302.122,
         left: 297.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text184", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 303.772,
         left: 316.45,
         width: 36.504,
         height: 13.540
      }
      ,
      {
         page: 3,
         name: "17 Heating Stove Type Fuel", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 297.32,
         left: 359.106,
         width: 215.262,
         height: 22.44
      }
      ,
      {
         page: 3,
         name: "undefined_33", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 333.48,
         left: 134.04,
         width: 67.32,
         height: 9.84
      }
      ,
      {
         page: 3,
         name: "undefined_34", 
         isText: false,
         type: "checkbox",
         top: 335.16,
         left: 206.76,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 3,
         name: "Check Box185", 
         isText: false,
         type: "checkbox",
         top: 326.456,
         left: 297.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text186", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 328.511,
         left: 316.45,
         width: 36.504,
         height: 13.540
      }
      ,
      {
         page: 3,
         name: "18 When was fireplacewood stove chimneyflue last cleaned Date Do not know", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 322.64,
         left: 359.106,
         width: 215.262,
         height: 22.56
      }
      ,
      {
         page: 3,
         name: "undefined_35", 
         isText: false,
         type: "checkbox",
         top: 350.28,
         left: 122.76,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 3,
         name: "undefined_36", 
         isText: false,
         type: "checkbox",
         top: 350.28,
         left: 168,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 3,
         name: "Check Box187", 
         isText: false,
         type: "checkbox",
         top: 346.329,
         left: 297.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text188", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 347.573,
         left: 316.45,
         width: 36.504,
         height: 13.540
      }
      ,
      {
         page: 3,
         name: "19 Fuel tanks Owned Leased", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 347.72,
         left: 359.106,
         width: 215.262,
         height: 13.56
      }
      ,
      {
         page: 3,
         name: "undefined_37", 
         isText: false,
         type: "checkbox",
         top: 365.64,
         left: 169.44,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 3,
         name: "undefined_38", 
         isText: false,
         type: "checkbox",
         top: 365.64,
         left: 215.88,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 3,
         name: "undefined_39", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 8,
         top: 375.753,
         left: 98.52,
         width: 187.003,
         height: 8.967
      }
      ,
      {
         page: 3,
         name: "Check Box189", 
         isText: false,
         type: "checkbox",
         top: 368.229,
         left: 297.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text190", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 369.068,
         left: 316.45,
         width: 36.504,
         height: 13.540
      }
      ,
      {
         page: 3,
         name: "20 Radiant heating system Interior Exterior Type", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 364.16,
         left: 359.106,
         width: 215.262,
         height: 22.44
      }
      ,
      {
         page: 3,
         name: "undefined_40", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 390.12,
         left: 134.04,
         width: 152.902,
         height: 9.84
      }
      ,
      {
         page: 3,
         name: "Check Box191", 
         isText: false,
         type: "checkbox",
         top: 388.508,
         left: 297.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text192", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 389.752,
         left: 316.45,
         width: 36.504,
         height: 13.540
      }
      ,
      {
         page: 3,
         name: "21 Fuel Provider", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 389.12,
         left: 359.106,
         width: 215.262,
         height: 12.343
      }
      ,
      {
         page: 3,
         name: "F22 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 405.164,
         left: 73.0025,
         width: 214.9545,
         height: 13.246
      }
      ,
      {
         page: 3,
         name: "Check Box193", 
         isText: false,
         type: "checkbox",
         top: 406.353,
         left: 297.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text194", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 405.57,
         left: 316.45,
         width: 36.504,
         height: 13.54
      }
      ,
      {
         page: 3,
         name: "22", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 406.2,
         left: 359.106,
         width: 215.262,
         height: 11.127
      }
      ,
      {
         page: 3,
         name: "Check Box195", 
         isText: false,
         type: "checkbox",
         top: 468.811,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text196", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 471.055,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "1 Water heaters", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 470.88,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box197", 
         isText: false,
         type: "checkbox",
         top: 485.033,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text198", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 487.278,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "2 Water filter system", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 486.6,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box199", 
         isText: false,
         type: "checkbox",
         top: 500.851,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text200", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 503.095,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "3 Water softener", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 501.96,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box201", 
         isText: false,
         type: "checkbox",
         top: 516.668,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text202", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 518.101,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "4 Water system pump", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 517.32,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box203", 
         isText: false,
         type: "checkbox",
         top: 532.485,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text204", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 533.107,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "5 Sauna", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 532.8,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box205", 
         isText: false,
         type: "checkbox",
         top: 547.491,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text206", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 548.113,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "6 Hot tub or spa", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 548.16,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box207", 
         isText: false,
         type: "checkbox",
         top: 561.686,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text208", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 563.931,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "7 Steam roomshower", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 563.52,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box209", 
         isText: false,
         type: "checkbox",
         top: 577.098,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text210", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 578.937,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "8 Underground sprinkler system", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 579.0,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box211", 
         isText: false,
         type: "checkbox",
         top: 593.321,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text212", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 594.754,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "9 Fire sprinkler system", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 594.36,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box213", 
         isText: false,
         type: "checkbox",
         top: 608.327,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text214", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 610.166,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "10 Backflow prevention device", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 609.72,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box215", 
         isText: false,
         type: "checkbox",
         top: 624.144,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text216", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 625.577,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "11 Irrigation pump", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 625.2,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "G12 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 638.772,
         left: 78.0582,
         width: 212.9968,
         height: 12.211
      }
      ,
      {
         page: 3,
         name: "Check Box217", 
         isText: false,
         type: "checkbox",
         top: 638.744,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text218", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 641.394,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "12_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 640.56,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "G13 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 655.064,
         left: 78.0582,
         width: 212.9968,
         height: 12.211
      }
      ,
      {
         page: 3,
         name: "Check Box219", 
         isText: false,
         type: "checkbox",
         top: 654.562,
         left: 301.416,
         width: 11.792,
         height: 13.861
      }
      ,
      {
         page: 3,
         name: "Text220", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 656.617,
         left: 325.456,
         width: 37.539,
         height: 11.177
      }
      ,
      {
         page: 3,
         name: "13_3", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 655.92,
         left: 366.32,
         width: 208.493,
         height: 11.131
      }
      ,
      {
         page: 3,
         name: "Check Box221", 
         isText: false,
         type: "checkbox",
         top: 708.5023,
         left: 301.416,
         width: 11.792,
         height: 13.8614
      }
      ,
      {
         page: 3,
         name: "Text222", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 711.1525,
         left: 325.456,
         width: 37.539,
         height: 11.1767
      }
      ,
      {
         page: 3,
         name: "14 Water system including lines and water pressure", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 710.76,
         left: 366.32,
         width: 208.493,
         height: 11.1307
      }
      ,
      {
         page: 3,
         name: "Check Box223", 
         isText: false,
         type: "checkbox",
         top: 724.7529,
         left: 301.416,
         width: 11.792,
         height: 13.8614
      }
      ,
      {
         page: 3,
         name: "Text224", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 725.7529,
         left: 325.456,
         width: 37.539,
         height: 11.1767
      }
      ,
      {
         page: 3,
         name: "Well", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 726.12,
         left: 366.32,
         width: 208.493,
         height: 11.1307
      }
      ,
      {
         page: 4,
         name: "Text226", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 45.613,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "Check Box225", 
         isText: false,
         type: "checkbox",
         top: 44.541,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "16 Pool", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 45.72,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "Check Box227", 
         isText: false,
         type: "checkbox",
         top: 59.808,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text228", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 60.619,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "17 Irrigation system", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 60.84,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "G18 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 75.653,
         left: 77.6527,
         width: 212.4803,
         height: 12.210
      }
      ,
      {
         page: 4,
         name: "Check Box229", 
         isText: false,
         type: "checkbox",
         top: 75.814,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text230", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 78.869,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "18_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 76.56,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "G19 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 90.917,
         left: 77.6527,
         width: 212.4803,
         height: 12.210
      }
      ,
      {
         page: 4,
         name: "Check Box231", 
         isText: false,
         type: "checkbox",
         top: 91.225,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text232", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 92.659,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "19_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 92.66,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "undefined_41", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 133.08,
         left: 174.96,
         width: 67.56,
         height: 10.68
      }
      ,
      {
         page: 4,
         name: "undefined_42", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 144.84,
         left: 118.56,
         width: 67.44,
         height: 10.68
      }
      ,
      {
         page: 4,
         name: "undefined_43", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 145.875,
         left: 222.48,
         width: 68.064,
         height: 9.645
      }
      ,
      {
         page: 4,
         name: "Check Box233", 
         isText: false,
         type: "checkbox",
         top: 137.677,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text234", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 140.11,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "20 Water heater Number of Fuel type Capacity", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 135.12,
         left: 367.808,
         width: 205.976,
         height: 22.34
      }
      ,
      {
         page: 4,
         name: "undefined_44", 
         isText: false,
         type: "checkbox",
         top: 162.72,
         left: 156.96,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "undefined_45", 
         isText: false,
         type: "checkbox",
         top: 162.72,
         left: 202.32,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Check Box235", 
         isText: false,
         type: "checkbox",
         top: 159.361,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text236", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 162.011,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "21 Water filter system Owned Leased", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 162.154,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "undefined_46", 
         isText: false,
         type: "checkbox",
         top: 178.8,
         left: 140.88,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "undefined_47", 
         isText: false,
         type: "checkbox",
         top: 178.8,
         left: 186.24,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Check Box237", 
         isText: false,
         type: "checkbox",
         top: 175.773,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text238", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 176.612,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "22 Water softener Owned Leased", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 176.114,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "undefined_48", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 192.84,
         left: 199.56,
         width: 91.347,
         height: 10.08
      }
      ,
      {
         page: 4,
         name: "Check Box239", 
         isText: false,
         type: "checkbox",
         top: 191.807,
         left: 301.819,
         width: 11.275,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text240", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 193.24,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "23 Master Water Shutoff Location", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 193.194,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "Check Box241", 
         isText: false,
         type: "checkbox",
         top: 207.624,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text242", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 209.463,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "24 Well metered", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 209.554,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "Date of last inspection", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 235.08,
         left: 165.12,
         width: 96.53,
         height: 10.68
      }
      ,
      {
         page: 4,
         name: "Date of last service", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 247.597,
         left: 153.6,
         width: 108.309,
         height: 9.646
      }
      ,
      {
         page: 4,
         name: "Check Box243", 
         isText: false,
         type: "checkbox",
         top: 233.797,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text244", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 235.014,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "25 Well Pump Date of last inspection Date of last service", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 224.76,
         left: 367.808,
         width: 205.976,
         height: 31.94
      }
      ,
      {
         page: 4,
         name: "Check Box245", 
         isText: false,
         type: "checkbox",
         top: 261.753,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text246", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 263.809,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "26 Galvanized pipe", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 263.394,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "Check Box247", 
         isText: false,
         type: "checkbox",
         top: 278.382,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text248", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 279.626,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "27 Polybutylene pipe", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 279.754,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "undefined_49", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 293.88,
         left: 130.44,
         width: 27.00,
         height: 10.08
      }
      ,
      {
         page: 4,
         name: "undefined_50", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 292.846,
         left: 181.44,
         width: 85.869,
         height: 11.114
      }
      ,
      {
         page: 4,
         name: "undefined_51", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 306.315,
         left: 106.08,
         width: 130.674,
         height: 9.645
      }
      ,
      {
         page: 4,
         name: "Check Box249", 
         isText: false,
         type: "checkbox",
         top: 297.471,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text250", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 299.499,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "28 Well Pump  GPM Date", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 294.6,
         left: 367.808,
         width: 205.976,
         height: 21.904
      }
      ,
      {
         page: 4,
         name: "undefined_52", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 321.24,
         left: 160.92,
         width: 31.44,
         height: 10.08
      }
      ,
      {
         page: 4,
         name: "Check Box251", 
         isText: false,
         type: "checkbox",
         top: 319.967,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text252", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 321.4,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "29 Cistern water storage gallons", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 321.594,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "Check Box253", 
         isText: false,
         type: "checkbox",
         top: 334.973,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text254", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 336.406,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "30 Supplemental water purchased in past 2 years", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 337.954,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "31", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 352.44,
         left: 77.28,
         width: 214.68,
         height: 12.84
      }
      ,
      {
         page: 4,
         name: "Check Box255", 
         isText: false,
         type: "checkbox",
         top: 351.007,
         left: 302.337,
         width: 10.757,
         height: 12.827
      }
      ,
      {
         page: 4,
         name: "Text256", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 352.223,
         left: 326.456,
         width: 36.098,
         height: 10.071
      }
      ,
      {
         page: 4,
         name: "G31 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 352.217,
         left: 367.808,
         width: 205.976,
         height: 10.994
      }
      ,
      {
         page: 4,
         name: "Public", 
         isText: false,
         type: "checkbox",
         top: 407.76,
         left: 162.24,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Community", 
         isText: false,
         type: "checkbox",
         top: 407.76,
         left: 202.32,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Well_2", 
         isText: false,
         type: "checkbox",
         top: 407.76,
         left: 262.44,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Shared Well", 
         isText: false,
         type: "checkbox",
         top: 407.76,
         left: 297,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Other", 
         isText: false,
         type: "checkbox",
         top: 407.76,
         left: 356.52,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "None", 
         isText: false,
         type: "checkbox",
         top: 407.76,
         left: 391.8,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Is", 
         isText: false,
         type: "checkbox",
         top: 419.76,
         left: 303.96,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Is Not attached Well Permit", 
         isText: false,
         type: "checkbox",
         top: 419.76,
         left: 327.96,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "undefined_53", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 417.48,
         left: 460.32,
         width: 102.12,
         height: 10.68
      }
      ,
      {
         page: 4,
         name: "Are", 
         isText: false,
         type: "checkbox",
         top: 431.76,
         left: 141,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Are Not attached Shared Well Agreement", 
         isText: false,
         type: "checkbox",
         top: 431.76,
         left: 174.84,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Yes", 
         isText: false,
         type: "checkbox",
         top: 431.76,
         left: 348.48,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "No", 
         isText: false,
         type: "checkbox",
         top: 431.76,
         left: 379.56,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "Name", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 465.48,
         left: 104.04,
         width: 240.00,
         height: 10.68
      }
      ,
      {
         page: 4,
         name: "Address", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 465.48,
         left: 382.68,
         width: 179.76,
         height: 10.68
      }
      ,
      {
         page: 4,
         name: "Web Site", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 477.48,
         left: 115.8,
         width: 228.24,
         height: 10.68
      }
      ,
      {
         page: 4,
         name: "Phone No", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 477.48,
         left: 391.2,
         width: 171.24,
         height: 10.68
      }
      ,
      {
         page: 4,
         name: "There is neither a Well nor a Water Provider for the Property The source of potable water for the Property is describe source", 
         isText: false,
         type: "checkbox",
         top: 491.76,
         left: 78.48,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 4,
         name: "H Source", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 502.501,
         left: 75.0304,
         width: 496.9796,
         height: 42.446
      }
      ,
      {
         page: 4,
         name: "Check Box257", 
         isText: false,
         type: "checkbox",
         top: 629.2,
         left: 295.849,
         width: 13.344,
         height: 14.379
      }
      ,
      {
         page: 4,
         name: "1 Sewage system including sewer lines", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 631.32,
         left: 320.628,
         width: 252.703,
         height: 11.011
      }
      ,
      {
         page: 4,
         name: "Check Box258", 
         isText: false,
         type: "checkbox",
         top: 645.233,
         left: 295.849,
         width: 13.344,
         height: 14.379
      }
      ,
      {
         page: 4,
         name: "2 Lift station sewage ejector pump", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 646.92,
         left: 320.628,
         width: 252.703,
         height: 11.011
      }
      ,
      {
         page: 4,
         name: "I3 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 661.89,
         left: 73.0025,
         width: 214.0325,
         height: 11.693
      }
      ,
      {
         page: 4,
         name: "Check Box259", 
         isText: false,
         type: "checkbox",
         top: 660.456,
         left: 295.849,
         width: 13.344,
         height: 14.379
      }
      ,
      {
         page: 4,
         name: "3", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 662.28,
         left: 320.628,
         width: 252.703,
         height: 11.011
      }
      ,
      {
         page: 4,
         name: "I4 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 676.545,
         left: 73.0025,
         width: 214.0325,
         height: 11.693
      }
      ,
      {
         page: 4,
         name: "Check Box260", 
         isText: false,
         type: "checkbox",
         top: 676.818,
         left: 295.849,
         width: 12.827,
         height: 13.862
      }
      ,
      {
         page: 4,
         name: "I4 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 676.896,
         left: 320.628,
         width: 252.703,
         height: 11.011
      }
      ,
      {
         page: 5,
         name: "Type of sanitary sewer service", 
         isText: false,
         type: "checkbox",
         top: 47.28,
         left: 197.64,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 5,
         name: "Public_2", 
         isText: false,
         type: "checkbox",
         top: 47.28,
         left: 240,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 5,
         name: "Community_2", 
         isText: false,
         type: "checkbox",
         top: 59.28,
         left: 128.28,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 5,
         name: "Septic System", 
         isText: false,
         type: "checkbox",
         top: 59.28,
         left: 198.84,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 5,
         name: "None_2", 
         isText: false,
         type: "checkbox",
         top: 59.28,
         left: 237.72,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 5,
         name: "If the Property is served by an onsite septic system", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 69.24,
         left: 77.76,
         width: 206.64,
         height: 10.44
      }
      ,
      {
         page: 5,
         name: "Type of septic system", 
         isText: false,
         type: "checkbox",
         top: 107.28,
         left: 164.16,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 5,
         name: "Tank", 
         isText: false,
         type: "checkbox",
         top: 107.28,
         left: 199.8,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 5,
         name: "Leach", 
         isText: false,
         type: "checkbox",
         top: 107.28,
         left: 238.92,
         width: 8.76,
         height: 8.76
      }
      ,
      {
         page: 5,
         name: "Check Box261", 
         isText: false,
         type: "checkbox",
         top: 73.408,
         left: 295.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "5 Type of sanitary sewer service Public Community Septic System None Other If the Property is served by an onsite septic system provide buyer with a copy of the permit Type of septic system Tank Leach Lagoon", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 46.2,
         left: 321.252,
         width: 252.422,
         height: 69.256
      }
      ,
      {
         page: 5,
         name: "undefined_54", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 132.48,
         left: 77.76,
         width: 148.44,
         height: 10.44
      }
      ,
      {
         page: 5,
         name: "Check Box262", 
         isText: false,
         type: "checkbox",
         top: 124.51,
         left: 295.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "6 Sewer service provider", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 121.2,
         left: 321.252,
         width: 252.422,
         height: 22.771
      }
      ,
      {
         page: 5,
         name: "undefined_55", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 147.683,
         left: 173.4,
         width: 74.285,
         height: 10.597
      }
      ,
      {
         page: 5,
         name: "Check Box263", 
         isText: false,
         type: "checkbox",
         top: 146.788,
         left: 295.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "7 Sewer line scoped Date", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 148.56,
         left: 321.252,
         width: 252.422,
         height: 11.875
      }
      ,
      {
         page: 5,
         name: "undefined_56", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 175.2,
         left: 105,
         width: 85.44,
         height: 10.44
      }
      ,
      {
         page: 5,
         name: "Check Box264", 
         isText: false,
         type: "checkbox",
         top: 167.5,
         left: 295.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "8 If a septic system date latest Individual Use Permit issued", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 164.28,
         left: 321.252,
         width: 252.422,
         height: 22.411
      }
      ,
      {
         page: 5,
         name: "undefined_57", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 202.68,
         left: 77.76,
         width: 81.00,
         height: 10.44
      }
      ,
      {
         page: 5,
         name: "Check Box265", 
         isText: false,
         type: "checkbox",
         top: 194.673,
         left: 295.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "9 If a septic system date of latest inspection", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 191.64,
         left: 321.252,
         width: 252.422,
         height: 23.048
      }
      ,
      {
         page: 5,
         name: "undefined_58", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 230.04,
         left: 77.76,
         width: 85.56,
         height: 10.44
      }
      ,
      {
         page: 5,
         name: "Check Box266", 
         isText: false,
         type: "checkbox",
         top: 221.847,
         left: 295.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "10 If a septic system date of latest pumping", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 219.12,
         left: 321.252,
         width: 252.422,
         height: 22.411
      }
      ,
      {
         page: 5,
         name: "Check Box267", 
         isText: false,
         type: "checkbox",
         top: 244.342,
         left: 295.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "11 Gray water storageuse", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 245.12,
         left: 321.252,
         width: 252.422,
         height: 13.246
      }
      ,
      {
         page: 5,
         name: "12_3", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 261.48,
         left: 72.84,
         width: 213.496,
         height: 12.96
      }
      ,
      {
         page: 5,
         name: "Check Box268", 
         isText: false,
         type: "checkbox",
         top: 260.376,
         left: 295.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "I 12 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 260.565,
         left: 321.252,
         width: 252.422,
         height: 12.910
      }
      ,
      {
         page: 5,
         name: "Check Box269", 
         isText: false,
         type: "checkbox",
         top: 323.428,
         left: 294.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "1 Flooding or drainage", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 325.8,
         left: 321.26,
         width: 251.387,
         height: 11.694
      }
      ,
      {
         page: 5,
         name: "J2 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 340.462,
         left: 74.0025,
         width: 211.1225,
         height: 12.910
      }
      ,
      {
         page: 5,
         name: "Check Box270", 
         isText: false,
         type: "checkbox",
         top: 338.651,
         left: 294.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "2_3", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 340.92,
         left: 321.26,
         width: 251.387,
         height: 11.694
      }
      ,
      {
         page: 5,
         name: "J3 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 355.851,
         left: 74.0025,
         width: 211.1225,
         height: 12.910
      }
      ,
      {
         page: 5,
         name: "Check Box271", 
         isText: false,
         type: "checkbox",
         top: 355.279,
         left: 294.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "3_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 356.64,
         left: 321.26,
         width: 251.387,
         height: 11.694
      }
      ,
      {
         page: 5,
         name: "Check Box272", 
         isText: false,
         type: "checkbox",
         top: 409.192,
         left: 294.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "4 Drainage retention ponds", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 411.36,
         left: 321.26,
         width: 251.387,
         height: 12.554
      }
      ,
      {
         page: 5,
         name: "5", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 426.84,
         left: 74.0025,
         width: 211.1225,
         height: 10.841
      }
      ,
      {
         page: 5,
         name: "Check Box273", 
         isText: false,
         type: "checkbox",
         top: 425.037,
         left: 294.66,
         width: 14.896,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "J5 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 425.473,
         left: 321.26,
         width: 251.387,
         height: 13.215
      }
      ,
      {
         page: 5,
         name: "Check Box274", 
         isText: false,
         type: "checkbox",
         top: 491.677,
         left: 298.405,
         width: 11.599,
         height: 11.162
      }
      ,
      {
         page: 5,
         name: "1 Included fixtures and equipment", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 491.88,
         left: 321.26,
         width: 251.387,
         height: 11.528
      }
      ,
      {
         page: 5,
         name: "Check Box275", 
         isText: false,
         type: "checkbox",
         top: 505.717,
         left: 298.405,
         width: 11.599,
         height: 11.162
      }
      ,
      {
         page: 5,
         name: "2 Stains on carpet", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 507.48,
         left: 321.26,
         width: 251.387,
         height: 11.743
      }
      ,
      {
         page: 5,
         name: "Check Box276", 
         isText: false,
         type: "checkbox",
         top: 522.751,
         left: 298.405,
         width: 11.599,
         height: 11.162
      }
      ,
      {
         page: 5,
         name: "3 Floors", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 521.96,
         left: 321.26,
         width: 251.387,
         height: 13.246
      }
      ,
      {
         page: 5,
         name: "K4 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 537.163,
         left: 74.0025,
         width: 211.1225,
         height: 12.910
      }
      ,
      {
         page: 5,
         name: "Check Box277", 
         isText: false,
         type: "checkbox",
         top: 538.163,
         left: 298.405,
         width: 11.599,
         height: 11.162
      }
      ,
      {
         page: 5,
         name: "4", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 537.32,
         left: 321.26,
         width: 251.387,
         height: 13.651
      }
      ,
      {
         page: 5,
         name: "5_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 553.197,
         left: 74.0025,
         width: 211.1225,
         height: 12.910
      }
      ,
      {
         page: 5,
         name: "Check Box278", 
         isText: false,
         type: "checkbox",
         top: 554.386,
         left: 298.405,
         width: 11.599,
         height: 11.162
      }
      ,
      {
         page: 5,
         name: "K5 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 552.386,
         left: 321.26,
         width: 251.387,
         height: 13.204
      }
      ,
      {
         page: 5,
         name: "Check Box279", 
         isText: false,
         type: "checkbox",
         top: 671.407,
         left: 311.477,
         width: 13.861,
         height: 13.861
      }
      ,
      {
         page: 5,
         name: "1 Zoning violation variance conditional use violation of an enforceable PUD or nonconforming use", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 668.72,
         left: 335.12,
         width: 238.575,
         height: 22.68
      }
      ,
      {
         page: 5,
         name: "Check Box280", 
         isText: false,
         type: "checkbox",
         top: 693.496,
         left: 311.289,
         width: 13.861,
         height: 13.8614
      }
      ,
      {
         page: 5,
         name: "2 Notice or threat of condemnation proceedings", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 693.92,
         left: 335.12,
         width: 238.575,
         height: 13.3655
      }
      ,
      {
         page: 5,
         name: "Check Box281", 
         isText: false,
         type: "checkbox",
         top: 713.7747,
         left: 311.883,
         width: 13.861,
         height: 13.8614
      }
      ,
      {
         page: 5,
         name: "Notice of any adverse conditions from any governmental or quasigovernmental agency that have not been resolved", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 709.76,
         left: 335.12,
         width: 238.575,
         height: 22.56
      }
      ,
      {
         page: 6,
         name: "Check Box282", 
         isText: false,
         type: "checkbox",
         top: 44.136,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "4 Notice of zoning action related to the Property", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 44.692,
         left: 334.893,
         width: 238.996,
         height: 12.799
      }
      ,
      {
         page: 6,
         name: "Check Box283", 
         isText: false,
         type: "checkbox",
         top: 59.996,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "5 Building code city or county violations", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 59.84,
         left: 334.893,
         width: 238.996,
         height: 13.20
      }
      ,
      {
         page: 6,
         name: "Check Box284", 
         isText: false,
         type: "checkbox",
         top: 79.681,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "6 Violation of restrictive covenants or owners association rules or regulations", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 75.92,
         left: 334.893,
         width: 238.996,
         height: 22.44
      }
      ,
      {
         page: 6,
         name: "Check Box285", 
         isText: false,
         type: "checkbox",
         top: 108.476,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "7 Any building or improvements constructed within the past one year before this Date without approval by the owners association or its designated approving body", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 101.984,
         left: 334.893,
         width: 238.996,
         height: 32.856
      }
      ,
      {
         page: 6,
         name: "Check Box286", 
         isText: false,
         type: "checkbox",
         top: 136.838,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "8 Any additions or alterations made with a Building Permit", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 137.36,
         left: 334.893,
         width: 238.996,
         height: 13.246
      }
      ,
      {
         page: 6,
         name: "Check Box287", 
         isText: false,
         type: "checkbox",
         top: 156.333,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "9 Any additions or nonaesthetic alterations made without a Building Permit", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 153.08,
         left: 334.893,
         width: 238.996,
         height: 24.48
      }
      ,
      {
         page: 6,
         name: "Check Box288", 
         isText: false,
         type: "checkbox",
         top: 177.017,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "10 Other legal action", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 181.08,
         left: 334.893,
         width: 238.996,
         height: 10.932
      }
      ,
      {
         page: 6,
         name: "Check Box289", 
         isText: false,
         type: "checkbox",
         top: 193.646,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "11 Any part of the Property leased to others written or oral", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 196.56,
         left: 334.893,
         width: 238.996,
         height: 11.218
      }
      ,
      {
         page: 6,
         name: "Check Box290", 
         isText: false,
         type: "checkbox",
         top: 210.68,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "12 Used for shortterm rentals in the past year", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 211.92,
         left: 334.893,
         width: 238.996,
         height: 11.218
      }
      ,
      {
         page: 6,
         name: "Check Box291", 
         isText: false,
         type: "checkbox",
         top: 226.902,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "13 Grandfathered conditions or uses", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 227.28,
         left: 334.893,
         width: 238.996,
         height: 10.779
      }
      ,
      {
         page: 6,
         name: "L14 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 241.719,
         left: 72.597,
         width: 232.548,
         height: 12.435
      }
      ,
      {
         page: 6,
         name: "Check Box292", 
         isText: false,
         type: "checkbox",
         top: 241.908,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "14_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 243.76,
         left: 334.893,
         width: 238.996,
         height: 10.407
      }
      ,
      {
         page: 6,
         name: "15", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 258.12,
         left: 72.84,
         width: 232.56,
         height: 12.84
      }
      ,
      {
         page: 6,
         name: "Check Box293", 
         isText: false,
         type: "checkbox",
         top: 256.914,
         left: 311.994,
         width: 11.792,
         height: 12.827
      }
      ,
      {
         page: 6,
         name: "L15 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 258.501,
         left: 334.893,
         width: 238.996,
         height: 12.281
      }
      ,
      {
         page: 6,
         name: "Check Box294", 
         isText: false,
         type: "checkbox",
         top: 321.407,
         left: 313.477,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 6,
         name: "1 Any access problems issues or concerns", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 322.2,
         left: 336.893,
         width: 237.444,
         height: 11.771
      }
      ,
      {
         page: 6,
         name: "Check Box295", 
         isText: false,
         type: "checkbox",
         top: 341.678,
         left: 313.477,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 6,
         name: "2 Roads trails paths or driveways through the Property used by others", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 338.16,
         left: 336.893,
         width: 237.444,
         height: 21.875
      }
      ,
      {
         page: 6,
         name: "Check Box296", 
         isText: false,
         type: "checkbox",
         top: 363.363,
         left: 313.477,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 6,
         name: "3 Public highway or county road bordering the Property", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 364.16,
         left: 336.893,
         width: 237.444,
         height: 13.08
      }
      ,
      {
         page: 6,
         name: "Check Box297", 
         isText: false,
         type: "checkbox",
         top: 384.263,
         left: 313.477,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 6,
         name: "4 Any proposed or existing transportation project that affects or is expected to affect the Property", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 381.0,
         left: 336.893,
         width: 237.444,
         height: 20.84
      }
      ,
      {
         page: 6,
         name: "Check Box298", 
         isText: false,
         type: "checkbox",
         top: 409.409,
         left: 313.477,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 6,
         name: "5 Encroachments boundary disputes or unrecorded easements", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 406.32,
         left: 336.893,
         width: 237.444,
         height: 22.585
      }
      ,
      {
         page: 6,
         name: "Check Box299", 
         isText: false,
         type: "checkbox",
         top: 432.093,
         left: 313.477,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 6,
         name: "6 Shared or common areas with adjoining properties", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 432.44,
         left: 336.893,
         width: 237.444,
         height: 13.08
      }
      ,
      {
         page: 6,
         name: "Check Box300", 
         isText: false,
         type: "checkbox",
         top: 447.127,
         left: 313.477,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 6,
         name: "7 Requirements for curb gravelpaving landscaping", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 447.8,
         left: 336.893,
         width: 237.444,
         height: 13.08
      }
      ,
      {
         page: 6,
         name: "Check Box301", 
         isText: false,
         type: "checkbox",
         top: 469.433,
         left: 313.477,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 6,
         name: "8 Any limitations on parking or access due to size number of vehicles or type of vehicles in the past year", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 464.52,
         left: 336.893,
         width: 237.444,
         height: 22.909
      }
      ,
      {
         page: 6,
         name: "M9 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 490.523,
         left: 79.6805,
         width: 225.0935,
         height: 12.393
      }
      ,
      {
         page: 6,
         name: "Check Box302", 
         isText: false,
         type: "checkbox",
         top: 490.711,
         left: 313.477,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 6,
         name: "9_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 491.64,
         left: 336.893,
         width: 237.444,
         height: 11.011
      }
      ,
      {
         page: 6,
         name: "10_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 505.393,
         left: 79.6805,
         width: 225.0935,
         height: 12.393
      }
      ,
      {
         page: 6,
         name: "Check Box303", 
         isText: false,
         type: "checkbox",
         top: 505.934,
         left: 313.477,
         width: 12.309,
         height: 13.344
      }
      ,
      {
         page: 6,
         name: "M10 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 505.969,
         left: 336.893,
         width: 237.444,
         height: 12.045
      }
      ,
      {
         page: 6,
         name: "Check Box304", 
         isText: false,
         type: "checkbox",
         top: 585.329,
         left: 309.477,
         width: 12.309,
         height: 13.070
      }
      ,
      {
         page: 6,
         name: "1 Hazardous materials on the Property such as radioactive toxic or biohazardous materials asbestos pesticides herbicides wastewater sludge radon methane mill tailings solvents or petroleum products", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 570.8,
         left: 330.966,
         width: 242.923,
         height: 44.4
      }
      ,
      {
         page: 6,
         name: "Check Box305", 
         isText: false,
         type: "checkbox",
         top: 617.28,
         left: 309.477,
         width: 12.309,
         height: 12.634
      }
      ,
      {
         page: 6,
         name: "2 Underground storage tanks", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 618.72,
         left: 330.966,
         width: 242.923,
         height: 12.029
      }
      ,
      {
         page: 6,
         name: "Check Box306", 
         isText: false,
         type: "checkbox",
         top: 633.751,
         left: 309.477,
         width: 12.309,
         height: 12.197
      }
      ,
      {
         page: 6,
         name: "3 Aboveground storage tanks", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 633.08,
         left: 330.966,
         width: 242.923,
         height: 12.96
      }
      ,
      {
         page: 6,
         name: "Check Box307", 
         isText: false,
         type: "checkbox",
         top: 648.289,
         left: 309.477,
         width: 12.309,
         height: 13.070
      }
      ,
      {
         page: 6,
         name: "4 Underground transmission lines", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 648.56,
         left: 330.966,
         width: 242.923,
         height: 12.84
      }
      ,
      {
         page: 6,
         name: "Check Box308", 
         isText: false,
         type: "checkbox",
         top: 669.421,
         left: 309.477,
         width: 12.309,
         height: 13.070
      }
      ,
      {
         page: 6,
         name: "5 Property used as situated on or adjoining a dump landfill or municipal solid waste landfill", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 664.28,
         left: 330.966,
         width: 242.923,
         height: 22.56
      }
      ,
      {
         page: 6,
         name: "Check Box309", 
         isText: false,
         type: "checkbox",
         top: 689.8876,
         left: 309.477,
         width: 12.309,
         height: 13.0696
      }
      ,
      {
         page: 6,
         name: "6 Monitoring wells or test equipment", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 689.36,
         left: 330.966,
         width: 242.923,
         height: 12.0289
      }
      ,
      {
         page: 6,
         name: "Check Box310", 
         isText: false,
         type: "checkbox",
         top: 709.9775,
         left: 309.477,
         width: 12.309,
         height: 13.0696
      }
      ,
      {
         page: 6,
         name: "Sliding settling upheaval movement or instability of earth or expansive soils on the Property", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 705.08,
         left: 330.966,
         width: 242.923,
         height: 22.56
      }
      ,
      {
         page: 7,
         name: "Check Box311", 
         isText: false,
         type: "checkbox",
         top: 42.963,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "8 Mine shafts tunnels or abandoned wells on the Property", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 44.909,
         left: 330.07,
         width: 243.218,
         height: 13.134
      }
      ,
      {
         page: 7,
         name: "Check Box312", 
         isText: false,
         type: "checkbox",
         top: 63.863,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "9 Within a governmentally designated geological hazard or sensitive area", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 60.2,
         left: 330.07,
         width: 243.218,
         height: 22.8
      }
      ,
      {
         page: 7,
         name: "Check Box313", 
         isText: false,
         type: "checkbox",
         top: 89.009,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "10 Within a governmentally designated floodplain or wetland area", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 86.207,
         left: 330.07,
         width: 243.218,
         height: 22.560
      }
      ,
      {
         page: 7,
         name: "Check Box314", 
         isText: false,
         type: "checkbox",
         top: 109.287,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "11 Dead diseased or infested trees or shrubs", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 110.96,
         left: 330.07,
         width: 243.218,
         height: 12.015
      }
      ,
      {
         page: 7,
         name: "Check Box315", 
         isText: false,
         type: "checkbox",
         top: 129.566,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "12 Environmental assessments studies or reports done involving the physical condition of the Property", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 126.68,
         left: 330.07,
         width: 243.218,
         height: 22.56
      }
      ,
      {
         page: 7,
         name: "Check Box316", 
         isText: false,
         type: "checkbox",
         top: 154.305,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "13 Used for any mining graveling or other natural resource extraction operations such as oil and gas wells", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 152.12,
         left: 330.07,
         width: 243.218,
         height: 22.44
      }
      ,
      {
         page: 7,
         name: "Check Box317", 
         isText: false,
         type: "checkbox",
         top: 181.073,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "14 Smoking inside improvements including garages unfinished space or detached buildings on Property", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 177.44,
         left: 330.07,
         width: 243.218,
         height: 24.60
      }
      ,
      {
         page: 7,
         name: "Check Box318", 
         isText: false,
         type: "checkbox",
         top: 203.568,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "15 Animals kept in the residence", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 205.56,
         left: 330.07,
         width: 243.218,
         height: 11.218
      }
      ,
      {
         page: 7,
         name: "Check Box319", 
         isText: false,
         type: "checkbox",
         top: 219.196,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "16 Other environmental problems issues or concerns", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 219.92,
         left: 330.07,
         width: 243.218,
         height: 13.246
      }
      ,
      {
         page: 7,
         name: "Check Box320", 
         isText: false,
         type: "checkbox",
         top: 235.014,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "17 Odors", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 236.28,
         left: 330.07,
         width: 243.218,
         height: 11.743
      }
      ,
      {
         page: 7,
         name: "N18 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 251.236,
         left: 74.4081,
         width: 222.4379,
         height: 11.876
      }
      ,
      {
         page: 7,
         name: "Check Box321", 
         isText: false,
         type: "checkbox",
         top: 249.614,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "18_3", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 251.087,
         left: 330.07,
         width: 243.218,
         height: 12.997
      }
      ,
      {
         page: 7,
         name: "19_3", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 267.12,
         left: 74.4081,
         width: 222.4379,
         height: 11.876
      }
      ,
      {
         page: 7,
         name: "Check Box322", 
         isText: false,
         type: "checkbox",
         top: 265.62,
         left: 306.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "N19 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 266.648,
         left: 330.07,
         width: 243.218,
         height: 12.421
      }
      ,
      {
         page: 7,
         name: "Check Box323", 
         isText: false,
         type: "checkbox",
         top: 340.245,
         left: 310.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "1 Property is part of an owners association", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 341.12,
         left: 335.761,
         width: 237.527,
         height: 13.20
      }
      ,
      {
         page: 7,
         name: "Check Box324", 
         isText: false,
         type: "checkbox",
         top: 361.74,
         left: 310.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "2 Special assessments or increases in regular assessments approved by owners association but not yet implemented", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 357.2,
         left: 335.761,
         width: 237.527,
         height: 22.44
      }
      ,
      {
         page: 7,
         name: "Check Box325", 
         isText: false,
         type: "checkbox",
         top: 385.669,
         left: 310.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "3 Problems or defects in the Common Elements or Limited Common Elements of the Association Property", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 382.52,
         left: 335.761,
         width: 237.527,
         height: 22.56
      }
      ,
      {
         page: 7,
         name: "Check Box327", 
         isText: false,
         type: "checkbox",
         top: 484.222,
         left: 310.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "4 Has the Association made demand or commenced a lawsuit against a builder or contractor alleging defective construction of improvements of the Association Property common area or property owned or controlled by the Association but outside the Sellers Property or unit", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 459.68,
         left: 335.433,
         width: 237.527,
         height: 60.24
      }
      ,
      {
         page: 7,
         name: "O5 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 522.779,
         left: 73.597,
         width: 229.444,
         height: 11.876
      }
      ,
      {
         page: 7,
         name: "Check Box328", 
         isText: false,
         type: "checkbox",
         top: 520.318,
         left: 310.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "5_3", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 522.767,
         left: 335.433,
         width: 237.527,
         height: 12.840
      }
      ,
      {
         page: 7,
         name: "O6 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 537.841,
         left: 73.597,
         width: 229.444,
         height: 11.876
      }
      ,
      {
         page: 7,
         name: "Check Box329", 
         isText: false,
         type: "checkbox",
         top: 538.38,
         left: 310.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "6_2", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 538.611,
         left: 335.761,
         width: 237.527,
         height: 12.029
      }
      ,
      {
         page: 7,
         name: "undefined_59", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 603.203,
         left: 77.76,
         width: 225.423,
         height: 11.197
      }
      ,
      {
         page: 7,
         name: "Check Box330", 
         isText: false,
         type: "checkbox",
         top: 596.376,
         left: 310.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "7 Owners Association 1", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 593.0,
         left: 335.761,
         width: 237.527,
         height: 23.52
      }
      ,
      {
         page: 7,
         name: "undefined_60", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 629.317,
         left: 77.76,
         width: 225.423,
         height: 11.197
      }
      ,
      {
         page: 7,
         name: "Check Box331", 
         isText: false,
         type: "checkbox",
         top: 623.549,
         left: 310.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "8 Owners Association 2", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 619.4,
         left: 335.761,
         width: 237.527,
         height: 23.52
      }
      ,
      {
         page: 7,
         name: "undefined_61", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 655.717,
         left: 77.76,
         width: 225.423,
         height: 11.197
      }
      ,
      {
         page: 7,
         name: "Check Box332", 
         isText: false,
         type: "checkbox",
         top: 650.317,
         left: 310.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "9 Owners Association 3", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 645.8,
         left: 335.761,
         width: 237.527,
         height: 23.52
      }
      ,
      {
         page: 7,
         name: "Owners Association 4", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 682.1173,
         left: 77.76,
         width: 225.423,
         height: 11.1973
      }
      ,
      {
         page: 7,
         name: "Check Box333", 
         isText: false,
         type: "checkbox",
         top: 676.679,
         left: 310.611,
         width: 12.309,
         height: 13.861
      }
      ,
      {
         page: 7,
         name: "Owners Association 4_2", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 672.2,
         left: 335.761,
         width: 237.527,
         height: 23.52
      }
      ,
      {
         page: 8,
         name: "Check Box334", 
         isText: false,
         type: "checkbox",
         top: 90.036,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "1 Written reports of any building site roofing soils water sewer or engineering investigations or studies of the Property", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 84.24,
         left: 330.08,
         width: 243.736,
         height: 28.80
      }
      ,
      {
         page: 8,
         name: "Check Box335", 
         isText: false,
         type: "checkbox",
         top: 120.454,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "2 Any property insurance claim submitted whether paid or not", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 117.92,
         left: 330.08,
         width: 243.736,
         height: 24.48
      }
      ,
      {
         page: 8,
         name: "Check Box336", 
         isText: false,
         type: "checkbox",
         top: 148.033,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "3 Structural architectural and engineering plans andor specifications for any existing improvements", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 145.28,
         left: 330.08,
         width: 243.736,
         height: 22.56
      }
      ,
      {
         page: 8,
         name: "Check Box337", 
         isText: false,
         type: "checkbox",
         top: 172.773,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "4 Property was previously used as a methamphetamine laboratory and not remediated to state standards", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 170.72,
         left: 330.08,
         width: 243.736,
         height: 22.44
      }
      ,
      {
         page: 8,
         name: "Check Box338", 
         isText: false,
         type: "checkbox",
         top: 199.54,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "5 Government special improvements approved but not yet installed that may become a lien against the Property", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 196.04,
         left: 330.08,
         width: 243.736,
         height: 22.56
      }
      ,
      {
         page: 8,
         name: "Check Box339", 
         isText: false,
         type: "checkbox",
         top: 225.091,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "6 Pending 1 litigation or 2 other dispute resolution proceeding regarding the Property", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 221.48,
         left: 330.08,
         width: 243.736,
         height: 22.56
      }
      ,
      {
         page: 8,
         name: "Check Box340", 
         isText: false,
         type: "checkbox",
         top: 250.642,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "7 Property is subject to Deed Restrictions other recorded document restrictions or Affordable Housing Restrictions", 
         type: "textarea",
         fontName: "Helvetica",
         fontSize: 10,
         top: 246.92,
         left: 330.08,
         width: 243.736,
         height: 24.48
      }
      ,
      {
         page: 8,
         name: "Check Box341", 
         isText: false,
         type: "checkbox",
         top: 273.732,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "8 Property is located in a historic district", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 273.514,
         left: 330.08,
         width: 243.736,
         height: 13.246
      }
      ,
      {
         page: 8,
         name: "P9 Issue", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 290,
         left: 75.1271,
         width: 221.7919,
         height: 12.210
      }
      ,
      {
         page: 8,
         name: "Check Box342", 
         isText: false,
         type: "checkbox",
         top: 289.143,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "9_3", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 290.497,
         left: 330.08,
         width: 243.736,
         height: 11.743
      }
      ,
      {
         page: 8,
         name: "P10 Issues", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 304.876,
         left: 75.1271,
         width: 221.7919,
         height: 12.210
      }
      ,
      {
         page: 8,
         name: "Check Box343", 
         isText: false,
         type: "checkbox",
         top: 306.177,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "10_4", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 305.977,
         left: 330.08,
         width: 243.736,
         height: 11.623
      }
      ,
      {
         page: 8,
         name: "undefined_62", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 9,
         top: 348.637,
         left: 187.44,
         width: 109.634,
         height: 9.683
      }
      ,
      {
         page: 8,
         name: "Check Box345", 
         isText: false,
         type: "checkbox",
         top: 345.923,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "11 Location of Mailbox and No", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 346.997,
         left: 328.497,
         width: 246.386,
         height: 12.960
      }
      ,
      {
         page: 8,
         name: "12_4", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 362.96,
         left: 72.84,
         width: 224.565,
         height: 12.84
      }
      ,
      {
         page: 8,
         name: "Check Box346", 
         isText: false,
         type: "checkbox",
         top: 362.957,
         left: 305.772,
         width: 12.827,
         height: 12.827
      }
      ,
      {
         page: 8,
         name: "P12 Comments", 
         type: "text",
         fontName: "Helvetica",
         fontSize: 10,
         top: 362.502,
         left: 329.728,
         width: 244.715,
         height: 12.393
      }
      ,
      {
         page: 8,
         name: "Seller1Signature", 
         type: "signature",
         fontName: "Helvetica",
         fontSize: 12,
         top: 576.302,
         left: 39.8342,
         width: 149.9998,
         height: 16.827
      }
      ,
      {
         page: 8,
         name: "Seller1SigDate", 
         type: "date",
         fontName: "Helvetica",
         fontSize: 12,
         top: 576.819,
         left: 194.515,
         width: 108.614,
         height: 16.310
      }
      ,
      {
         page: 8,
         name: "Seller2Signature", 
         type: "signature",
         fontName: "Helvetica",
         fontSize: 12,
         top: 577.1,
         left: 326.433,
         width: 150.000,
         height: 16.31
      }
      ,
      {
         page: 8,
         name: "Seller2SigDate", 
         type: "date",
         fontName: "Helvetica",
         fontSize: 12,
         top: 577.337,
         left: 480.597,
         width: 96.715,
         height: 15.792
      }
      ,
      {
         page: 9,
         name: "Buyer1Signature", 
         type: "signature",
         fontName: "Helvetica",
         fontSize: 12,
         top: 267.7,
         left: 36.2129,
         width: 150.0001,
         height: 15.792
      }
      ,
      {
         page: 9,
         name: "Buyer2Signature", 
         type: "signature",
         fontName: "Helvetica",
         fontSize: 12,
         top: 265.389,
         left: 327.985,
         width: 150.000,
         height: 17.861
      }
      ,
      {
         page: 9,
         name: "Buyer1SigDate", 
         type: "date",
         fontName: "Helvetica",
         fontSize: 12,
         top: 267.458,
         left: 193.998,
         width: 111.717,
         height: 15.275
      }
      ,
      {
         page: 9,
         name: "Buyer2SigDate", 
         type: "date",
         fontName: "Helvetica",
         fontSize: 12,
         top: 265.389,
         left: 480.079,
         width: 90.508,
         height: 17.344
      }
    
      

   // {
   //    page: 0,
   //    name: "Date", 
   //    fontSize: 9,
   //    type: "date",
   //    top: 315.6,
   //    left: 96.12,
   //    width: 100,
   //    height: 14.52
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Property", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 333.6,
   //    left: 111.72,
   //    width: 437.40,
   //    height: 14.52
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Seller", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 351.6,
   //    left: 100.56,
   //    width: 448.56,
   //    height: 14.52
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Year Built", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 369.6,
   //    left: 119.16,
   //    width: 429.96,
   //    height: 14.52
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Year Seller Acquired Property", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 387.6,
   //    left: 199.2,
   //    width: 349.92,
   //    height: 14.52
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Check A1", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 532.478,
   //    left: 299.092,
   //    width: 14.379,
   //    height: 15.413
   // }
   // ,
   // {
   //    page: 0,
   //    name: "1 Structural", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 531.992,
   //    left: 320.221,
   //    width: 254.153,
   //    height: 15.514
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Check A2", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 552.861,
   //    left: 299.092,
   //    width: 14.379,
   //    height: 15.413
   // }
   // ,
   // {
   //    page: 0,
   //    name: "2 Moisture andor water", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 551.786,
   //    left: 320.221,
   //    width: 254.153,
   //    height: 15.514
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Check A3", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 574.161,
   //    left: 299.092,
   //    width: 14.379,
   //    height: 15.413
   // }
   // ,
   // {
   //    page: 0,
   //    name: "3 Damage due to termites other insects birds animals or rodents", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 573.04,
   //    left: 320.221,
   //    width: 254.153,
   //    height: 15.514
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Check A4", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 596.65,
   //    left: 299.092,
   //    width: 14.379,
   //    height: 15.413
   // }
   // ,
   // {
   //    page: 0,
   //    name: "4 Damage due to hail wind fire flood or other casualty", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 596.741,
   //    left: 320.221,
   //    width: 254.153,
   //    height: 15.514
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Check A5", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 617.112,
   //    left: 299.092,
   //    width: 14.379,
   //    height: 15.413
   // }
   // ,
   // {
   //    page: 0,
   //    name: "5 Cracks heaving or settling", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 615.444,
   //    left: 320.221,
   //    width: 254.153,
   //    height: 15.514
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Check A6", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 637.979,
   //    left: 299.092,
   //    width: 14.379,
   //    height: 15.413
   // }
   // ,
   // {
   //    page: 0,
   //    name: "6 Exterior wall or window", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 635.89,
   //    left: 320.221,
   //    width: 254.153,
   //    height: 15.514
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Check A7", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 657.412,
   //    left: 299.092,
   //    width: 14.379,
   //    height: 15.413
   // }
   // ,
   // {
   //    page: 0,
   //    name: "7 Exterior Artificial Stucco EIFS", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 655.404,
   //    left: 320.221,
   //    width: 254.153,
   //    height: 15.514
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Check A8", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 675.251,
   //    left: 299.092,
   //    width: 14.379,
   //    height: 15.413
   // }
   // ,
   // {
   //    page: 0,
   //    name: "8 Subfloors", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 674.89,
   //    left: 320.221,
   //    width: 254.153,
   //    height: 15.514
   // }
   // ,
   // {
   //    page: 0,
   //    name: "A9 Issue", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 693.1185,
   //    left: 73.2261,
   //    width: 217.7239,
   //    height: 17.3716
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Check A9", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 694.901,
   //    left: 299.092,
   //    width: 14.379,
   //    height: 15.4133
   // }
   // ,
   // {
   //    page: 0,
   //    name: "9", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 693.7752,
   //    left: 320.221,
   //    width: 254.153,
   //    height: 15.5136
   // }
   // ,
   // {
   //    page: 0,
   //    name: "10", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 711.4,
   //    left: 72.96,
   //    width: 217.724,
   //    height: 17.3715
   // }
   // ,
   // {
   //    page: 0,
   //    name: "Check A10", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 712.5233,
   //    left: 299.092,
   //    width: 14.379,
   //    height: 15.4133
   // }
   // ,
   // {
   //    page: 0,
   //    name: "A10 Comment", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 712.7747,
   //    left: 320.221,
   //    width: 254.153,
   //    height: 15.5136
   // }
   // // ,
   // // {
   // //    page: 0,
   // //    name: "SPD19622 SELLERS PROPERTY DISCLOSURE RESIDENTIAL", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 729.96,
   // //    left: 36,
   // //    width: 481.32,
   // //    height: 14.52
   // // }
   // // ,
   // // {
   // //    page: 0,
   // //    name: "Page 1 of 10", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 729.96,
   // //    left: 521.76,
   // //    width: 55.80,
   // //    height: 14.52
   // // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box34", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 69.68,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "1 Roof leak", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 70.92,
   //    left: 324.829,
   //    width: 249.571,
   //    height: 12.128
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box35", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 87.386,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "2 Damage to roof", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 87.24,
   //    left: 324.829,
   //    width: 249.571,
   //    height: 12.128
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box36", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 104.015,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "3 Skylight", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 105.84,
   //    left: 324.829,
   //    width: 249.571,
   //    height: 12.128
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box37", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 122.237,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "4 Gutter or downspout", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 122.52,
   //    left: 324.829,
   //    width: 249.571,
   //    height: 12.128
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box38", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 140.083,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "5 Other roof problems issues or concerns", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 139.68,
   //    left: 324.829,
   //    width: 249.571,
   //    height: 12.128
   // }
   // ,
   // {
   //    page: 1,
   //    name: "B6 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 156.55,
   //    left: 73.597,
   //    width: 221.221,
   //    height: 13.245
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box39", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 157.116,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "6", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 156.48,
   //    left: 324.829,
   //    width: 249.571,
   //    height: 12.128
   // }
   // ,
   // {
   //    page: 1,
   //    name: "B7 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 172.031,
   //    left: 73.5187,
   //    width: 221.2203,
   //    height: 13.245
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box40", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 173.339,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "7", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 173.16,
   //    left: 324.829,
   //    width: 249.571,
   //    height: 12.128
   // }
   // ,
   // {
   //    page: 1,
   //    name: "ROOF  Other Information Do you know of the following on the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 190.32,
   //    left: 247.56,
   //    width: 327.48,
   //    height: 24.48
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 216.96,
   //    left: 174.24,
   //    width: 84.48,
   //    height: 9.60
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_2", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 229.92,
   //    left: 130.68,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_3", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 229.92,
   //    left: 164.76,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box42", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 222.413,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "8 Roof under warranty until Transferable YES NO", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 217.68,
   //    left: 325.662,
   //    width: 248.309,
   //    height: 21.968
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box43", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 243.503,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "9 Roof work done while under current roof warranty", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 242.72,
   //    left: 325.662,
   //    width: 248.309,
   //    height: 12.96
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_4", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 259.56,
   //    left: 132.72,
   //    width: 47.04,
   //    height: 9.60
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_5", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 259.32,
   //    left: 201.48,
   //    width: 26.88,
   //    height: 9.60
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box44", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 257.698,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "10 Roof material Age", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 258.2,
   //    left: 325.662,
   //    width: 248.309,
   //    height: 12.915
   // }
   // ,
   // {
   //    page: 1,
   //    name: "11", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 275.04,
   //    left: 73.32,
   //    width: 223.08,
   //    height: 13.32
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box45", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 273.109,
   //    left: 305.029,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 1,
   //    name: "B11 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 274.165,
   //    left: 325.662,
   //    width: 248.309,
   //    height: 13.246
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box46", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 338.39,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text47", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 338.545,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "1 Builtin vacuum system  accessories", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 338.669,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box48", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 353.818,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text49", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 354.2,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "2 Clothes dryer", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 354.2,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box50", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 368.824,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text52", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 369.5,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "3 Clothes washer", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 369.56,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box53", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 385.047,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text54", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 383.7,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "4 Dishwasher", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 383.703,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box55", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 400.269,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text56", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 399.2,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "5 Disposal", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 399.183,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box57", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 416.087,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text58", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 416.808,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "6 Freezer", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 415.76,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box59", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 432.309,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text60", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 432.626,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "7 Gas grill", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 431.12,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box61", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 446.504,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text62", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 447.632,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "8 Hood", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 446.6,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box63", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 461.916,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text64", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 463.043,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "9 Microwave oven", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 461.96,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box65", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 476.111,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text66", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 477.238,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "10 Oven", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 477.32,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box67", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 490.711,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text68",  //range
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 492.74,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "11 Range", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 492.8,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box69", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 506.529,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text70", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 508.873,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "12 Refrigerator", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 508.16,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_6", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 526.2,
   //    left: 132.24,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_7", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 526.2,
   //    left: 175.32,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box71", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 521.129,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text72", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 523,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "13 TV antenna Owned Leased", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 523.52,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_8", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 541.56,
   //    left: 188.04,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_9", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 541.56,
   //    left: 231.12,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box73", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 538.163,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text74", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 539,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "14 Satellite system or DSS dish Owned Leased", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 539.0,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box75", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 553.575,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text76", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 554,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "15 Trash compactor", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 554.36,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "C16 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 570.636,
   //    left: 72.597,
   //    width: 204.004,
   //    height: 11.694
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box77", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 570.609,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text78", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 570.6,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "16", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 569.72,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "17", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 584.643,
   //    left: 72.84,
   //    width: 204.004,
   //    height: 11.694
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box79", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 585.181,
   //    left: 285.89,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text80", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 584.643,
   //    left: 306.575,
   //    width: 37.386,
   //    height: 12.505
   // }
   // ,
   // {
   //    page: 1,
   //    name: "C17 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 584.643,
   //    left: 347.582,
   //    width: 226.336,
   //    height: 12.683
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_10", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 652.08,
   //    left: 142.68,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_11", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 652.08,
   //    left: 185.76,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box81", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 649.952,
   //    left: 297.54,
   //    width: 12.309,
   //    height: 12.309
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text82", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 650.128,
   //    left: 320.589,
   //    width: 37.315,
   //    height: 10.882
   // }
   // ,
   // {
   //    page: 1,
   //    name: "1 Security system Owned Leased", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 650.28,
   //    left: 361.76,
   //    width: 213.571,
   //    height: 12.045
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_12", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 667.44,
   //    left: 160.2,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_13", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 667.44,
   //    left: 203.76,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box83", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 665.078,
   //    left: 297.54,
   //    width: 12.309,
   //    height: 12.309
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text84", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 664.729,
   //    left: 320.589,
   //    width: 37.315,
   //    height: 10.882
   // }
   // ,
   // {
   //    page: 1,
   //    name: "2 Smokefire detectors Battery Hardwire", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 665.88,
   //    left: 361.76,
   //    width: 213.571,
   //    height: 12.045
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_14", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 682.8,
   //    left: 174.12,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "undefined_15", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 682.8,
   //    left: 216.72,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box85", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 679.784,
   //    left: 297.54,
   //    width: 12.309,
   //    height: 12.8264
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text86", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 680.14,
   //    left: 320.589,
   //    width: 37.315,
   //    height: 10.882
   // }
   // ,
   // {
   //    page: 1,
   //    name: "3 Carbon Monoxide Alarm Battery Hardwire", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 681.24,
   //    left: 361.76,
   //    width: 213.571,
   //    height: 12.0454
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box87", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 696.1185,
   //    left: 297.54,
   //    width: 12.309,
   //    height: 12.3094
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text88", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 695.9575,
   //    left: 320.589,
   //    width: 37.315,
   //    height: 10.8820
   // }
   // ,
   // {
   //    page: 1,
   //    name: "4 Light fixtures", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 696.72,
   //    left: 361.76,
   //    width: 213.571,
   //    height: 12.0454
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box89", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 710.7469,
   //    left: 297.54,
   //    width: 12.309,
   //    height: 12.3095
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text90", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 711.3691,
   //    left: 320.589,
   //    width: 37.315,
   //    height: 10.8820
   // }
   // ,
   // {
   //    page: 1,
   //    name: "5 Switches  outlets", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 712.08,
   //    left: 361.76,
   //    width: 213.571,
   //    height: 12.0454
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Check Box91", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 726.1307,
   //    left: 297.54,
   //    width: 12.309,
   //    height: 12.3094
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Text92", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 726.3752,
   //    left: 320.589,
   //    width: 37.315,
   //    height: 10.8820
   // }
   // ,
   // {
   //    page: 1,
   //    name: "Telecommunications T1 fiber cable satellite", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 727.44,
   //    left: 361.76,
   //    width: 213.571,
   //    height: 12.0454
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text94", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 44.396,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box93", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 44.136,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "7 Inside telephone wiring  blocksjacks", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 43.72,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box95", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 58.996,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text96", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 60.024,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "8 Ceiling fans", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 59.84,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "undefined_16", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 88.2,
   //    left: 154.44,
   //    width: 40.44,
   //    height: 10.08
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box97", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 80.492,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text98", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 80.303,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "9 Garage door opener and remote control  of remoteopeners", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 75.92,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box99", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 102.959,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text100", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 103.015,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "10 Intercomdoorbell", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 102.92,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box101", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 117.993,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text102", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 118.426,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "11 Inwall speakers", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 118.28,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "D12 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 133.838,
   //    left: 73.3803,
   //    width: 212.4797,
   //    height: 12.210
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box103", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 133.216,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text104", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 133.838,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "12", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 133.76,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "D13 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 148.913,
   //    left: 73.3803,
   //    width: 212.4797,
   //    height: 12.210
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box105", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 148.627,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text106", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 148.033,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "13", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 149.12,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box107", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 201,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "ELECTRICAL  TELECOMMUNICATIONS If you know of any problems EVER EXISTING with the following check the Yes column", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 166.2,
   //    left: 274.32,
   //    width: 300.36,
   //    height: 36.24
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text108", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 202.379,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "14 Electrical Service", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 203.96,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box109", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 218.385,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text110", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 219.413,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "15 Aluminum wiring at the outlets 110", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 219.32,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "undefined_18", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 237.36,
   //    left: 129.72,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 2,
   //    name: "undefined_19", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 237.36,
   //    left: 172.8,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box111", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 232.986,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text112", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 234.419,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "16 Solar panels Owned Leased", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 234.68,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "undefined_21", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 252.72,
   //    left: 145.2,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 2,
   //    name: "undefined_22", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 252.72,
   //    left: 188.28,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box113", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 249.209,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text114", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 248.614,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "17 Wind generators Owned Leased", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 250.16,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box115", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 264.62,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text116", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 265.648,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "17 Electric Wiring or Panel", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 265.52,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "D18 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 281.249,
   //    left: 74.1914,
   //    width: 213.5156,
   //    height: 12.210
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box117", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 280.032,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text118", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 281.465,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "18", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 280.88,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "D19 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 296.729,
   //    left: 74.1914,
   //    width: 213.5156,
   //    height: 12.210
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box119", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 295.849,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text120", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 297.283,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "19", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 296.36,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "ELECTRICAL  TELECOMMUNICATIONS  Other Information Do you know of the following on the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 313.44,
   //    left: 267.84,
   //    width: 306.84,
   //    height: 36.12
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box121", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 350.762,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text122", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 351.629,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "20 220 volt service", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 351.08,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "undefined_23", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 366.683,
   //    left: 169.92,
   //    width: 116.405,
   //    height: 10.597
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box123", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 365.985,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text124", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 366.635,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "21 Electrical Service Amps", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 366.56,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box125", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 383.019,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text126", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 382.452,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "22 Landscape lighting", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 381.92,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "undefined_25", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 397.523,
   //    left: 147.585,
   //    width: 138.231,
   //    height: 10.597
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box127", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 399.025,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text128", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 397.864,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "23 Electric Provider", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 397.28,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "undefined_26", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 411.848,
   //    left: 151.035,
   //    width: 134.781,
   //    height: 11.632
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box129", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 413.625,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text130", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 414.087,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "24 CableTV provider", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 412.76,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "undefined_27", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 428.76,
   //    left: 173.16,
   //    width: 112.656,
   //    height: 10.08
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box131", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 429.037,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text132", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 428.282,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "25 Sellers Internet Provider", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 428.12,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "26", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 444.48,
   //    left: 72.84,
   //    width: 214.92,
   //    height: 12.96
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box133", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 444.26,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text134", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 443.288,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "D26 Comment", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 442.476,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box135", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 507.717,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text136", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 507.773,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "1 Overhead doors including garage doors", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 507.56,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box137", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 522.129,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text138", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 522.779,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "2 Entry gate system", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 523.16,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box139", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 538.163,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text140", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 538.191,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "3 Elevator", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 538.64,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box141", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 553.791,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text142", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 553.603,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "4 Radon mitigation system", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 554.0,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "undefined_28", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 570.12,
   //    left: 151.56,
   //    width: 43.56,
   //    height: 10.08
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box143", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 568.986,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text144", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 569.014,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "5 Sump pumps  of", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 569.36,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box145", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 583.587,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text146", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 584.426,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "6 Recycle pump", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 584.84,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "E7 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 601.054,
   //    left: 72.597,
   //    width: 217.136,
   //    height: 13.246
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box147", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 600.215,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text148", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 600.243,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "7_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 600.2,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "8", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 616.56,
   //    left: 72.84,
   //    width: 216.12,
   //    height: 12.96
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box149", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 616.466,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text150", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 616.06,
   //    left: 320.994,
   //    width: 37.428,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 2,
   //    name: "E8 Comment", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 614.871,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box151", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 679.896,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.8267
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text152", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 679.329,
   //    left: 316.994,
   //    width: 37.428,
   //    height: 12.099
   // }
   // ,
   // {
   //    page: 2,
   //    name: "1 Heating system", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 679.64,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box153", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 695.3074,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.8267
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text154", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 695.36,
   //    left: 316.994,
   //    width: 37.428,
   //    height: 12.0990
   // }
   // ,
   // {
   //    page: 2,
   //    name: "2 Evaporative cooler", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 695.36,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box155", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 710.3135,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.8267
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text156", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 710.72,
   //    left: 316.994,
   //    width: 37.428,
   //    height: 12.0990
   // }
   // ,
   // {
   //    page: 2,
   //    name: "3 Window air conditioning units", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 710.72,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Check Box157", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 726.1585,
   //    left: 298.135,
   //    width: 12.309,
   //    height: 12.8267
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Text158", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 726.08,
   //    left: 316.994,
   //    width: 37.428,
   //    height: 12.0991
   // }
   // ,
   // {
   //    page: 2,
   //    name: "Central air conditioning", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 726.08,
   //    left: 360.911,
   //    width: 212.879,
   //    height: 12.434
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text160", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 44.613,
   //    left: 316.128,
   //    width: 37.944,
   //    height: 12.323
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box159", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 44.151,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 3,
   //    name: "5 Atticwhole house fan", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 45.72,
   //    left: 357.89,
   //    width: 216.075,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box161", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 60.9,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text162", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 60.647,
   //    left: 316.128,
   //    width: 37.944,
   //    height: 12.323
   // }
   // ,
   // {
   //    page: 3,
   //    name: "6 Vent fans", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 60.84,
   //    left: 357.89,
   //    width: 216.075,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box163", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 76.2,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text164", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 75.653,
   //    left: 316.128,
   //    width: 37.944,
   //    height: 12.323
   // }
   // ,
   // {
   //    page: 3,
   //    name: "7 Humidifier", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 76.56,
   //    left: 357.89,
   //    width: 216.075,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box165", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 91,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text166", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 91.253,
   //    left: 316.128,
   //    width: 37.944,
   //    height: 12.323
   // }
   // ,
   // {
   //    page: 3,
   //    name: "8 Air purifier", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 91.92,
   //    left: 357.89,
   //    width: 216.075,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box167", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 106,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text168", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 105.854,
   //    left: 316.128,
   //    width: 37.944,
   //    height: 12.323
   // }
   // ,
   // {
   //    page: 3,
   //    name: "9 Fireplace", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 106.063,
   //    left: 357.89,
   //    width: 216.075,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box169", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 122,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text170", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 122.076,
   //    left: 316.128,
   //    width: 37.944,
   //    height: 12.323
   // }
   // ,
   // {
   //    page: 3,
   //    name: "10 Fireplace insert", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 121.543,
   //    left: 357.89,
   //    width: 216.075,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box171", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 138,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text172", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 137.488,
   //    left: 316.128,
   //    width: 37.944,
   //    height: 12.323
   // }
   // ,
   // {
   //    page: 3,
   //    name: "11 Heating Stove", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 138.12,
   //    left: 357.89,
   //    width: 216.075,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box173", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 153.5,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text174", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 152.089,
   //    left: 316.128,
   //    width: 37.944,
   //    height: 12.323
   // }
   // ,
   // {
   //    page: 3,
   //    name: "12 Fuel tanks", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 153.48,
   //    left: 357.89,
   //    width: 216.075,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 3,
   //    name: "F13 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 167.5,
   //    left: 73.0025,
   //    width: 217.1365,
   //    height: 13.246
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box175", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 167.083,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text176", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 167.5,
   //    left: 316.128,
   //    width: 37.944,
   //    height: 12.323
   // }
   // ,
   // {
   //    page: 3,
   //    name: "13_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 168.96,
   //    left: 357.89,
   //    width: 216.075,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 3,
   //    name: "F14 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 182.981,
   //    left: 73.1271,
   //    width: 217.1359,
   //    height: 13.246
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box177", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 183.3,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text178", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 182.912,
   //    left: 316.128,
   //    width: 37.944,
   //    height: 12.323
   // }
   // ,
   // {
   //    page: 3,
   //    name: "14", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 184.32,
   //    left: 357.89,
   //    width: 216.075,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 3,
   //    name: "VENTILATION AIR  HEAT  Other Information Do you know of the following on the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 204.84,
   //    left: 247.8,
   //    width: 327.12,
   //    height: 28.80
   // }
   // ,
   // {
   //    page: 3,
   //    name: "1", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 246.24,
   //    left: 98.52,
   //    width: 67.44,
   //    height: 9.84
   // }
   // ,
   // {
   //    page: 3,
   //    name: "1_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 246.24,
   //    left: 186.48,
   //    width: 67.44,
   //    height: 9.84
   // }
   // ,
   // {
   //    page: 3,
   //    name: "2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 257.28,
   //    left: 98.52,
   //    width: 67.44,
   //    height: 9.84
   // }
   // ,
   // {
   //    page: 3,
   //    name: "2_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 257.28,
   //    left: 186.48,
   //    width: 67.44,
   //    height: 9.84
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box179", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 246.964,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text180", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 248.614,
   //    left: 316.45,
   //    width: 36.504,
   //    height: 13.540
   // }
   // ,
   // {
   //    page: 3,
   //    name: "15 Heating system including furnace Type Fuel Type Fuel", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 239.2,
   //    left: 359.106,
   //    width: 215.262,
   //    height: 27.765
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_29", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 272.16,
   //    left: 136.68,
   //    width: 67.44,
   //    height: 9.36
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_30", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 282.72,
   //    left: 96.12,
   //    width: 67.32,
   //    height: 9.84
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box181", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 276.976,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text182", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 276.193,
   //    left: 316.45,
   //    width: 36.504,
   //    height: 13.540
   // }
   // ,
   // {
   //    page: 3,
   //    name: "16 Fireplace Type Fuel", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 271.88,
   //    left: 359.106,
   //    width: 215.262,
   //    height: 22.56
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_31", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 297.6,
   //    left: 154.44,
   //    width: 67.56,
   //    height: 9.36
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_32", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 308.04,
   //    left: 96.12,
   //    width: 67.32,
   //    height: 9.84
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box183", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 302.122,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text184", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 303.772,
   //    left: 316.45,
   //    width: 36.504,
   //    height: 13.540
   // }
   // ,
   // {
   //    page: 3,
   //    name: "17 Heating Stove Type Fuel", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 297.32,
   //    left: 359.106,
   //    width: 215.262,
   //    height: 22.44
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_33", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 333.48,
   //    left: 134.04,
   //    width: 67.32,
   //    height: 9.84
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_34", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 335.16,
   //    left: 206.76,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box185", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 326.456,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text186", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 328.511,
   //    left: 316.45,
   //    width: 36.504,
   //    height: 13.540
   // }
   // ,
   // {
   //    page: 3,
   //    name: "18 When was fireplacewood stove chimneyflue last cleaned Date Do not know", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 322.64,
   //    left: 359.106,
   //    width: 215.262,
   //    height: 22.56
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_35", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 350.28,
   //    left: 122.76,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_36", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 350.28,
   //    left: 168,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box187", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 346.329,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text188", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 347.573,
   //    left: 316.45,
   //    width: 36.504,
   //    height: 13.540
   // }
   // ,
   // {
   //    page: 3,
   //    name: "19 Fuel tanks Owned Leased", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 347.72,
   //    left: 359.106,
   //    width: 215.262,
   //    height: 13.56
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_37", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 365.64,
   //    left: 169.44,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_38", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 365.64,
   //    left: 215.88,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_39", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 374.88,
   //    left: 98.52,
   //    width: 67.44,
   //    height: 9.84
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box189", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 368.229,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text190", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 369.068,
   //    left: 316.45,
   //    width: 36.504,
   //    height: 13.540
   // }
   // ,
   // {
   //    page: 3,
   //    name: "20 Radiant heating system Interior Exterior Type", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 364.16,
   //    left: 359.106,
   //    width: 215.262,
   //    height: 22.44
   // }
   // ,
   // {
   //    page: 3,
   //    name: "undefined_40", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 390.12,
   //    left: 134.04,
   //    width: 112.32,
   //    height: 9.84
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box191", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 388.508,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text192", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 389.752,
   //    left: 316.45,
   //    width: 36.504,
   //    height: 13.540
   // }
   // ,
   // {
   //    page: 3,
   //    name: "21 Fuel Provider", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 389.12,
   //    left: 359.106,
   //    width: 215.262,
   //    height: 12.343
   // }
   // ,
   // {
   //    page: 3,
   //    name: "F22 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 405.164,
   //    left: 73.0025,
   //    width: 217.1365,
   //    height: 13.246
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box193", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 406.353,
   //    left: 297.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text194", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 405.57,
   //    left: 316.45,
   //    width: 36.504,
   //    height: 13.54
   // }
   // ,
   // {
   //    page: 3,
   //    name: "22", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 406.2,
   //    left: 359.106,
   //    width: 215.262,
   //    height: 11.127
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box195", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 468.811,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text196", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 471.055,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "1 Water heaters", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 470.88,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box197", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 485.033,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text198", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 487.278,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "2 Water filter system", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 486.6,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box199", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 500.851,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text200", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 503.095,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "3 Water softener", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 501.96,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box201", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 516.668,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text202", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 518.101,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "4 Water system pump", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 517.32,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box203", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 532.485,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text204", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 533.107,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "5 Sauna", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 532.8,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box205", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 547.491,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text206", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 548.113,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "6 Hot tub or spa", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 548.16,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box207", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 561.686,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text208", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 563.931,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "7 Steam roomshower", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 563.52,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box209", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 577.098,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text210", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 578.937,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "8 Underground sprinkler system", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 579.0,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box211", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 593.321,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text212", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 594.754,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "9 Fire sprinkler system", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 594.36,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box213", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 608.327,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text214", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 610.166,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "10 Backflow prevention device", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 609.72,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box215", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 624.144,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text216", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 625.577,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "11 Irrigation pump", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 625.2,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "G12 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 638.772,
   //    left: 78.0582,
   //    width: 212.9968,
   //    height: 12.211
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box217", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 638.744,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text218", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 641.394,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "12_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 640.56,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "G13 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 655.064,
   //    left: 78.0582,
   //    width: 212.9968,
   //    height: 12.211
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box219", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 654.562,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text220", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 656.617,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.177
   // }
   // ,
   // {
   //    page: 3,
   //    name: "13_3", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 655.92,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.131
   // }
   // ,
   // {
   //    page: 3,
   //    name: "WATER If you know of any problems EVER EXISTING with the following check the Yes column", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 672.12,
   //    left: 278.76,
   //    width: 295.92,
   //    height: 36.12
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box221", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 708.5023,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.8614
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text222", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 711.1525,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.1767
   // }
   // ,
   // {
   //    page: 3,
   //    name: "14 Water system including lines and water pressure", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 710.76,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.1307
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Check Box223", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 724.7529,
   //    left: 301.416,
   //    width: 11.792,
   //    height: 13.8614
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Text224", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 725.7529,
   //    left: 325.456,
   //    width: 37.539,
   //    height: 11.1767
   // }
   // ,
   // {
   //    page: 3,
   //    name: "Well", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 726.12,
   //    left: 366.32,
   //    width: 208.493,
   //    height: 11.1307
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text226", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 45.613,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box225", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 44.541,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "16 Pool", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 45.72,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box227", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 59.808,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text228", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 60.619,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "17 Irrigation system", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 60.84,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "G18 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 75.653,
   //    left: 77.6527,
   //    width: 212.4803,
   //    height: 12.210
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box229", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 75.814,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text230", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 78.869,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "18_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 76.56,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "G19 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 90.917,
   //    left: 77.6527,
   //    width: 212.4803,
   //    height: 12.210
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box231", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 91.225,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text232", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 92.659,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "19_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 92,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "WATER  Other Information Do you know of the following on the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 107.64,
   //    left: 252.12,
   //    width: 322.92,
   //    height: 24.60
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_41", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 133.08,
   //    left: 174.96,
   //    width: 67.56,
   //    height: 10.68
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_42", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 144.84,
   //    left: 118.56,
   //    width: 67.44,
   //    height: 10.68
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_43", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 145.875,
   //    left: 222.48,
   //    width: 68.064,
   //    height: 9.645
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box233", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 137.677,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text234", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 140.11,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "20 Water heater Number of Fuel type Capacity", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 140.114,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 16.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_44", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 162.72,
   //    left: 156.96,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_45", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 162.72,
   //    left: 202.32,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box235", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 159.361,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text236", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 162.011,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "21 Water filter system Owned Leased", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 162.154,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_46", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 178.8,
   //    left: 140.88,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_47", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 178.8,
   //    left: 186.24,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box237", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 175.773,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text238", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 176.612,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "22 Water softener Owned Leased", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 176.114,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_48", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 192.84,
   //    left: 199.56,
   //    width: 91.347,
   //    height: 10.08
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box239", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 191.807,
   //    left: 301.819,
   //    width: 11.275,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text240", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 193.24,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "23 Master Water Shutoff Location", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 193.2,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box241", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 207.624,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text242", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 209.463,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "24 Well metered", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 209.554,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Date of last inspection", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 235.08,
   //    left: 165.12,
   //    width: 80.56,
   //    height: 10.68
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Date of last service", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 247.597,
   //    left: 153.6,
   //    width: 80.787,
   //    height: 10.163
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box243", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 233.797,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text244", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 235.014,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "25 Well Pump Date of last inspection Date of last service", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 235.754,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 20.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box245", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 261.753,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text246", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 263.809,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "26 Galvanized pipe", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 263.394,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box247", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 278.382,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text248", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 279.626,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "27 Polybutylene pipe", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 279.754,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_49", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 293.88,
   //    left: 130.44,
   //    width: 27.00,
   //    height: 10.08
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_50", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 293.363,
   //    left: 181.44,
   //    width: 54.829,
   //    height: 10.597
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_51", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 306.315,
   //    left: 106.08,
   //    width: 130.674,
   //    height: 9.645
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box249", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 297.471,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text250", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 299.499,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "28 Well Pump  GPM Date", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 295.594,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 20.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_52", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 321.24,
   //    left: 160.92,
   //    width: 31.44,
   //    height: 10.08
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box251", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 319.967,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text252", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 321.4,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "29 Cistern water storage gallons", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 321.594,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box253", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 334.973,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text254", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 336.406,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "30 Supplemental water purchased in past 2 years", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 336.954,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // ,
   // {
   //    page: 4,
   //    name: "31", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 352.44,
   //    left: 77.28,
   //    width: 214.68,
   //    height: 12.84
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box255", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 351.007,
   //    left: 302.337,
   //    width: 10.757,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Text256", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 352.223,
   //    left: 326.456,
   //    width: 36.098,
   //    height: 10.071
   // }
   // ,
   // {
   //    page: 4,
   //    name: "G31 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 352.217,
   //    left: 367.808,
   //    width: 205.976,
   //    height: 10.994
   // }
   // // ,
   // // {
   // //    page: 4,
   // //    name: "H", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 366,
   // //    left: 42.72,
   // //    width: 28.92,
   // //    height: 11.76
   // // }
   // // ,
   // // {
   // //    page: 4,
   // //    name: "1_3", 
   // //    type: "textarea",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 415.56,
   // //    left: 43.56,
   // //    width: 27.36,
   // //    height: 164.52
   // // }
   // ,
   // {
   //    page: 4,
   //    name: "Public", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 407.76,
   //    left: 162.24,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Community", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 407.76,
   //    left: 202.32,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Well_2", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 407.76,
   //    left: 262.44,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Shared Well", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 407.76,
   //    left: 297,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Other", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 407.76,
   //    left: 356.52,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "None", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 407.76,
   //    left: 391.8,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Is", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 419.76,
   //    left: 303.96,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Is Not attached Well Permit", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 419.76,
   //    left: 327.96,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "undefined_53", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 417.48,
   //    left: 460.32,
   //    width: 102.12,
   //    height: 10.68
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Are", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 431.76,
   //    left: 141,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Are Not attached Shared Well Agreement", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 431.76,
   //    left: 174.84,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Yes", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 431.76,
   //    left: 348.48,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "No", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 431.76,
   //    left: 379.56,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Name", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 465.48,
   //    left: 104.04,
   //    width: 240.00,
   //    height: 10.68
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Address", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 465.48,
   //    left: 382.68,
   //    width: 179.76,
   //    height: 10.68
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Web Site", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 477.48,
   //    left: 115.8,
   //    width: 228.24,
   //    height: 10.68
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Phone No", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 477.48,
   //    left: 391.2,
   //    width: 171.24,
   //    height: 10.68
   // }
   // ,
   // {
   //    page: 4,
   //    name: "There is neither a Well nor a Water Provider for the Property The source of potable water for the Property is describe source", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 491.76,
   //    left: 78.48,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 4,
   //    name: "H Source", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 502.501,
   //    left: 75.0304,
   //    width: 496.9796,
   //    height: 42.446
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box257", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 629.2,
   //    left: 295.849,
   //    width: 13.344,
   //    height: 14.379
   // }
   // ,
   // {
   //    page: 4,
   //    name: "1 Sewage system including sewer lines", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 631.32,
   //    left: 320.628,
   //    width: 252.703,
   //    height: 11.011
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box258", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 645.233,
   //    left: 295.849,
   //    width: 13.344,
   //    height: 14.379
   // }
   // ,
   // {
   //    page: 4,
   //    name: "2 Lift station sewage ejector pump", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 646.92,
   //    left: 320.628,
   //    width: 252.703,
   //    height: 11.011
   // }
   // ,
   // {
   //    page: 4,
   //    name: "I3 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 661.89,
   //    left: 73.0025,
   //    width: 214.0325,
   //    height: 11.693
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box259", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 660.456,
   //    left: 295.849,
   //    width: 13.344,
   //    height: 14.379
   // }
   // ,
   // {
   //    page: 4,
   //    name: "3", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 662.28,
   //    left: 320.628,
   //    width: 252.703,
   //    height: 11.011
   // }
   // ,
   // {
   //    page: 4,
   //    name: "I4 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 676.545,
   //    left: 73.0025,
   //    width: 214.0325,
   //    height: 11.693
   // }
   // ,
   // {
   //    page: 4,
   //    name: "Check Box260", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 676.818,
   //    left: 295.849,
   //    width: 12.827,
   //    height: 13.862
   // }
   // ,
   // {
   //    page: 4,
   //    name: "I4 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 676.896,
   //    left: 320.628,
   //    width: 252.703,
   //    height: 11.011
   // }
   // // ,
   // // {
   // //    page: 4,
   // //    name: "4Row1", 
   // //    type: "textarea",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 693.48,
   // //    left: 43.2,
   // //    width: 27.84,
   // //    height: 24.48
   // // }
   // // ,
   // // {
   // //    page: 4,
   // //    name: "SEWER  Other Information Do you know of the following on the Property", 
   // //    type: "textarea",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 693.48,
   // //    left: 291.24,
   // //    width: 26.64,
   // //    height: 24.48
   // // }
   // // ,
   // // {
   // //    page: 4,
   // //    name: "SPD19622 SELLERS PROPERTY DISCLOSURE RESIDENTIAL_2", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 732.72,
   // //    left: 36,
   // //    width: 481.44,
   // //    height: 11.76
   // // }
   // // ,
   // // {
   // //    page: 4,
   // //    name: "Page 5 of 10", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 732.72,
   // //    left: 521.76,
   // //    width: 55.80,
   // //    height: 11.76
   // // }
   // ,
   // {
   //    page: 5,
   //    name: "Type of sanitary sewer service", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 47.28,
   //    left: 197.64,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Public_2", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 47.28,
   //    left: 240,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Community_2", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 59.28,
   //    left: 128.28,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Septic System", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 59.28,
   //    left: 198.84,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 5,
   //    name: "None_2", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 59.28,
   //    left: 237.72,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 5,
   //    name: "If the Property is served by an onsite septic system", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 69.24,
   //    left: 77.76,
   //    width: 206.64,
   //    height: 10.44
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Type of septic system", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 107.28,
   //    left: 164.16,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Tank", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 107.28,
   //    left: 199.8,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Leach", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 107.28,
   //    left: 238.92,
   //    width: 8.76,
   //    height: 8.76
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box261", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 73.408,
   //    left: 295.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "5 Type of sanitary sewer service Public Community Septic System None Other If the Property is served by an onsite septic system provide buyer with a copy of the permit Type of septic system Tank Leach Lagoon", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 46.2,
   //    left: 321.252,
   //    width: 252.422,
   //    height: 69.256
   // }
   // ,
   // {
   //    page: 5,
   //    name: "undefined_54", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 132.48,
   //    left: 77.76,
   //    width: 148.44,
   //    height: 10.44
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box262", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 124.51,
   //    left: 295.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "6 Sewer service provider", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 121.2,
   //    left: 321.252,
   //    width: 252.422,
   //    height: 22.771
   // }
   // ,
   // {
   //    page: 5,
   //    name: "undefined_55", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 147.683,
   //    left: 173.4,
   //    width: 74.285,
   //    height: 10.597
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box263", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 146.788,
   //    left: 295.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "7 Sewer line scoped Date", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 148.56,
   //    left: 321.252,
   //    width: 252.422,
   //    height: 11.875
   // }
   // ,
   // {
   //    page: 5,
   //    name: "undefined_56", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 175.2,
   //    left: 105,
   //    width: 85.44,
   //    height: 10.44
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box264", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 167.5,
   //    left: 295.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "8 If a septic system date latest Individual Use Permit issued", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 164.28,
   //    left: 321.252,
   //    width: 252.422,
   //    height: 22.411
   // }
   // ,
   // {
   //    page: 5,
   //    name: "undefined_57", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 202.68,
   //    left: 77.76,
   //    width: 81.00,
   //    height: 10.44
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box265", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 194.673,
   //    left: 295.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "9 If a septic system date of latest inspection", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 191.64,
   //    left: 321.252,
   //    width: 252.422,
   //    height: 23.048
   // }
   // ,
   // {
   //    page: 5,
   //    name: "undefined_58", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 230.04,
   //    left: 77.76,
   //    width: 85.56,
   //    height: 10.44
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box266", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 221.847,
   //    left: 295.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "10 If a septic system date of latest pumping", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 219.12,
   //    left: 321.252,
   //    width: 252.422,
   //    height: 22.411
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box267", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 244.342,
   //    left: 295.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "11 Gray water storageuse", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 245.12,
   //    left: 321.252,
   //    width: 252.422,
   //    height: 13.246
   // }
   // ,
   // {
   //    page: 5,
   //    name: "12_3", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 261.48,
   //    left: 72.84,
   //    width: 213.496,
   //    height: 12.96
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box268", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 260.376,
   //    left: 295.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "I 12 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 260.565,
   //    left: 321.252,
   //    width: 252.422,
   //    height: 12.910
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box269", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 323.428,
   //    left: 294.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "1 Flooding or drainage", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 325.8,
   //    left: 321.26,
   //    width: 251.387,
   //    height: 11.694
   // }
   // ,
   // {
   //    page: 5,
   //    name: "J2 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 340.462,
   //    left: 74.0025,
   //    width: 211.1225,
   //    height: 12.910
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box270", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 338.651,
   //    left: 294.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "2_3", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 340.92,
   //    left: 321.26,
   //    width: 251.387,
   //    height: 11.694
   // }
   // ,
   // {
   //    page: 5,
   //    name: "J3 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 355.851,
   //    left: 74.0025,
   //    width: 211.1225,
   //    height: 12.910
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box271", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 355.279,
   //    left: 294.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "3_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 356.64,
   //    left: 321.26,
   //    width: 251.387,
   //    height: 11.694
   // }
   // ,
   // {
   //    page: 5,
   //    name: "DRAINAGE AND RETENTION PONDS  Other Information Do you know of the following on the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 372.72,
   //    left: 269.16,
   //    width: 305.52,
   //    height: 36.12
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box272", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 408.192,
   //    left: 294.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "4 Drainage retention ponds", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 411.36,
   //    left: 321.26,
   //    width: 251.387,
   //    height: 12.554
   // }
   // ,
   // {
   //    page: 5,
   //    name: "5", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 426.84,
   //    left: 74.0025,
   //    width: 211.1225,
   //    height: 10.841
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box273", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 425.037,
   //    left: 294.66,
   //    width: 14.896,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "J5 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 425.037,
   //    left: 321.26,
   //    width: 251.387,
   //    height: 13.651
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box274", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 488.495,
   //    left: 297.66,
   //    width: 13.344,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 5,
   //    name: "1 Included fixtures and equipment", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 491.88,
   //    left: 321.26,
   //    width: 251.387,
   //    height: 11.528
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box275", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 504.717,
   //    left: 297.66,
   //    width: 13.344,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 5,
   //    name: "2 Stains on carpet", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 507.48,
   //    left: 321.26,
   //    width: 251.387,
   //    height: 11.743
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box276", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 521.751,
   //    left: 297.66,
   //    width: 13.344,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 5,
   //    name: "3 Floors", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 521.96,
   //    left: 321.26,
   //    width: 251.387,
   //    height: 13.246
   // }
   // ,
   // {
   //    page: 5,
   //    name: "K4 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 537.163,
   //    left: 74.0025,
   //    width: 211.1225,
   //    height: 12.910
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box277", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 537.163,
   //    left: 297.66,
   //    width: 13.344,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 5,
   //    name: "4", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 537.32,
   //    left: 321.26,
   //    width: 251.387,
   //    height: 13.651
   // }
   // ,
   // {
   //    page: 5,
   //    name: "5_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 553.197,
   //    left: 74.0025,
   //    width: 211.1225,
   //    height: 12.910
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box278", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 553.386,
   //    left: 297.66,
   //    width: 13.344,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 5,
   //    name: "K5 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 552.386,
   //    left: 321.26,
   //    width: 251.387,
   //    height: 13.204
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box279", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 671.407,
   //    left: 311.477,
   //    width: 13.861,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 5,
   //    name: "1 Zoning violation variance conditional use violation of an enforceable PUD or nonconforming use", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 668.72,
   //    left: 335.12,
   //    width: 238.575,
   //    height: 22.68
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box280", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 693.496,
   //    left: 311.289,
   //    width: 13.861,
   //    height: 13.8614
   // }
   // ,
   // {
   //    page: 5,
   //    name: "2 Notice or threat of condemnation proceedings", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 693.92,
   //    left: 335.12,
   //    width: 238.575,
   //    height: 13.3655
   // }
   // // ,
   // // {
   // //    page: 5,
   // //    name: "3_3", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 719.28,
   // //    left: 42.84,
   // //    width: 28.56,
   // //    height: 14.04
   // // }
   // ,
   // {
   //    page: 5,
   //    name: "Check Box281", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 713.7747,
   //    left: 311.883,
   //    width: 13.861,
   //    height: 13.8614
   // }
   // ,
   // {
   //    page: 5,
   //    name: "Notice of any adverse conditions from any governmental or quasigovernmental agency that have not been resolved", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 709.76,
   //    left: 335.12,
   //    width: 238.575,
   //    height: 22.56
   // }
   // // ,
   // // {
   // //    page: 5,
   // //    name: "SPD19622 SELLERS PROPERTY DISCLOSURE RESIDENTIAL_3", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 734.04,
   // //    left: 36,
   // //    width: 481.44,
   // //    height: 10.44
   // // }
   // // ,
   // // {
   // //    page: 5,
   // //    name: "Page 6 of 10", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 734.04,
   // //    left: 521.76,
   // //    width: 55.80,
   // //    height: 10.44
   // // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box282", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 44.136,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "4 Notice of zoning action related to the Property", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 44.692,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 12.799
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box283", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 59.996,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "5 Building code city or county violations", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 59.84,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 13.20
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box284", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 79.681,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "6 Violation of restrictive covenants or owners association rules or regulations", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 75.92,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 22.44
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box285", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 108.476,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "7 Any building or improvements constructed within the past one year before this Date without approval by the owners association or its designated approving body", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 101.984,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 32.856
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box286", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 136.838,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "8 Any additions or alterations made with a Building Permit", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 137.36,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 13.246
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box287", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 156.333,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "9 Any additions or nonaesthetic alterations made without a Building Permit", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 153.08,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 24.48
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box288", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 177.017,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "10 Other legal action", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 181.08,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 10.932
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box289", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 193.646,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "11 Any part of the Property leased to others written or oral", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 196.56,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 11.218
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box290", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 210.68,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "12 Used for shortterm rentals in the past year", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 211.92,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 11.218
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box291", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 226.902,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "13 Grandfathered conditions or uses", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 227.28,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 10.779
   // }
   // ,
   // {
   //    page: 6,
   //    name: "L14 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 241.719,
   //    left: 72.597,
   //    width: 232.548,
   //    height: 12.435
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box292", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 241.908,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "14_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 243.76,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 10.407
   // }
   // ,
   // {
   //    page: 6,
   //    name: "15", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 258.12,
   //    left: 72.84,
   //    width: 232.56,
   //    height: 12.84
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box293", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 256.914,
   //    left: 311.994,
   //    width: 11.792,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 6,
   //    name: "L15 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 258.501,
   //    left: 334.893,
   //    width: 238.996,
   //    height: 12.281
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box294", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 321.407,
   //    left: 313.477,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 6,
   //    name: "1 Any access problems issues or concerns", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 322.2,
   //    left: 336.893,
   //    width: 237.444,
   //    height: 11.771
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box295", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 341.678,
   //    left: 313.477,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 6,
   //    name: "2 Roads trails paths or driveways through the Property used by others", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 338.16,
   //    left: 336.893,
   //    width: 237.444,
   //    height: 21.875
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box296", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 363.363,
   //    left: 313.477,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 6,
   //    name: "3 Public highway or county road bordering the Property", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 364.16,
   //    left: 336.893,
   //    width: 237.444,
   //    height: 13.08
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box297", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 384.263,
   //    left: 313.477,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 6,
   //    name: "4 Any proposed or existing transportation project that affects or is expected to affect the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 381.0,
   //    left: 336.893,
   //    width: 237.444,
   //    height: 20.84
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box298", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 409.409,
   //    left: 313.477,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 6,
   //    name: "5 Encroachments boundary disputes or unrecorded easements", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 406.32,
   //    left: 336.893,
   //    width: 237.444,
   //    height: 22.585
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box299", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 432.093,
   //    left: 313.477,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 6,
   //    name: "6 Shared or common areas with adjoining properties", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 432.44,
   //    left: 336.893,
   //    width: 237.444,
   //    height: 13.08
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box300", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 447.127,
   //    left: 313.477,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 6,
   //    name: "7 Requirements for curb gravelpaving landscaping", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 447.8,
   //    left: 336.893,
   //    width: 237.444,
   //    height: 13.08
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box301", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 469.433,
   //    left: 313.477,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 6,
   //    name: "8 Any limitations on parking or access due to size number of vehicles or type of vehicles in the past year", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 464.52,
   //    left: 336.893,
   //    width: 237.444,
   //    height: 22.909
   // }
   // ,
   // {
   //    page: 6,
   //    name: "M9 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 490.523,
   //    left: 79.6805,
   //    width: 225.0935,
   //    height: 12.393
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box302", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 490.711,
   //    left: 313.477,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 6,
   //    name: "9_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 491.64,
   //    left: 336.893,
   //    width: 237.444,
   //    height: 11.011
   // }
   // ,
   // {
   //    page: 6,
   //    name: "10_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 507,
   //    left: 79.6805,
   //    width: 225.0935,
   //    height: 12.393
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box303", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 505.934,
   //    left: 313.477,
   //    width: 12.309,
   //    height: 13.344
   // }
   // ,
   // {
   //    page: 6,
   //    name: "M10 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 505.969,
   //    left: 336.893,
   //    width: 237.444,
   //    height: 12.045
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box304", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 585.329,
   //    left: 309.477,
   //    width: 12.309,
   //    height: 13.070
   // }
   // ,
   // {
   //    page: 6,
   //    name: "1 Hazardous materials on the Property such as radioactive toxic or biohazardous materials asbestos pesticides herbicides wastewater sludge radon methane mill tailings solvents or petroleum products", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 570.8,
   //    left: 330.966,
   //    width: 242.923,
   //    height: 44.4
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box305", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 618.5,
   //    left: 309.477,
   //    width: 12.309,
   //    height: 12.6
   // }
   // ,
   // {
   //    page: 6,
   //    name: "2 Underground storage tanks", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 618.72,
   //    left: 330.966,
   //    width: 242.923,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box306", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 632.878,
   //    left: 309.477,
   //    width: 12.309,
   //    height: 13.070
   // }
   // ,
   // {
   //    page: 6,
   //    name: "3 Aboveground storage tanks", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 633.08,
   //    left: 330.966,
   //    width: 242.923,
   //    height: 12.96
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box307", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 648.289,
   //    left: 309.477,
   //    width: 12.309,
   //    height: 13.070
   // }
   // ,
   // {
   //    page: 6,
   //    name: "4 Underground transmission lines", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 648.56,
   //    left: 330.966,
   //    width: 242.923,
   //    height: 12.84
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box308", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 666.421,
   //    left: 309.477,
   //    width: 12.309,
   //    height: 13.070
   // }
   // ,
   // {
   //    page: 6,
   //    name: "5 Property used as situated on or adjoining a dump landfill or municipal solid waste landfill", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 664.28,
   //    left: 330.966,
   //    width: 242.923,
   //    height: 22.56
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box309", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 689.3,
   //    left: 309.477,
   //    width: 12.309,
   //    height: 13.0696
   // }
   // ,
   // {
   //    page: 6,
   //    name: "6 Monitoring wells or test equipment", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 689.36,
   //    left: 330.966,
   //    width: 242.923,
   //    height: 12.0289
   // }
   // // ,
   // // {
   // //    page: 6,
   // //    name: "7_3", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 714.6,
   // //    left: 42.84,
   // //    width: 28.56,
   // //    height: 14.04
   // // }
   // ,
   // {
   //    page: 6,
   //    name: "Check Box310", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 706.9775,
   //    left: 309.477,
   //    width: 12.309,
   //    height: 13.0696
   // }
   // ,
   // {
   //    page: 6,
   //    name: "Sliding settling upheaval movement or instability of earth or expansive soils on the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 705.08,
   //    left: 330.966,
   //    width: 242.923,
   //    height: 22.56
   // }
   // // ,
   // // {
   // //    page: 6,
   // //    name: "SPD19622 SELLERS PROPERTY DISCLOSURE RESIDENTIAL_4", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 729.36,
   // //    left: 36,
   // //    width: 481.44,
   // //    height: 15.12
   // // }
   // // ,
   // // {
   // //    page: 6,
   // //    name: "Page 7 of 10", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 729.36,
   // //    left: 521.76,
   // //    width: 55.80,
   // //    height: 15.12
   // // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box311", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 42.963,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "8 Mine shafts tunnels or abandoned wells on the Property", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 44.909,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 13.134
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box312", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 63.863,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "9 Within a governmentally designated geological hazard or sensitive area", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 60.2,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 22.8
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box313", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 89.009,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "10 Within a governmentally designated floodplain or wetland area", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 86.207,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 22.560
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box314", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 109.287,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "11 Dead diseased or infested trees or shrubs", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 110.96,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 12.015
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box315", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 129.566,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "12 Environmental assessments studies or reports done involving the physical condition of the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 126.68,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 22.56
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box316", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 154.305,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "13 Used for any mining graveling or other natural resource extraction operations such as oil and gas wells", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 152.12,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 22.44
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box317", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 181.073,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "14 Smoking inside improvements including garages unfinished space or detached buildings on Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 177.44,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 24.60
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box318", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 203.568,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "15 Animals kept in the residence", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 205.56,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 11.218
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box319", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 219.196,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "16 Other environmental problems issues or concerns", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 219.92,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 13.246
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box320", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 235.014,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "17 Odors", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 236.28,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 11.743
   // }
   // ,
   // {
   //    page: 7,
   //    name: "N18 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 251.236,
   //    left: 74.4081,
   //    width: 222.4379,
   //    height: 11.876
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box321", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 249.614,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "18_3", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 251.087,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 12.997
   // }
   // ,
   // {
   //    page: 7,
   //    name: "19_3", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 267.12,
   //    left: 74.4081,
   //    width: 222.4379,
   //    height: 11.876
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box322", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 265.62,
   //    left: 306.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "N19 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 266.648,
   //    left: 330.07,
   //    width: 243.218,
   //    height: 12.421
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box323", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 340.245,
   //    left: 310.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "1 Property is part of an owners association", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 341.12,
   //    left: 335.761,
   //    width: 237.527,
   //    height: 13.20
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box324", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 361.74,
   //    left: 310.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "2 Special assessments or increases in regular assessments approved by owners association but not yet implemented", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 357.2,
   //    left: 335.761,
   //    width: 237.527,
   //    height: 22.44
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box325", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 385.669,
   //    left: 310.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "3 Problems or defects in the Common Elements or Limited Common Elements of the Association Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 382.52,
   //    left: 335.761,
   //    width: 237.527,
   //    height: 22.56
   // }
   // ,
   // {
   //    page: 7,
   //    name: "COMMON INTEREST COMMUNITY  ASSOCIATION PROPERTY If you know of any of the following EVER EXISTED check the Yes column", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 409.32,
   //    left: 278.4,
   //    width: 296.28,
   //    height: 48.12
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box327", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 484.222,
   //    left: 310.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "4 Has the Association made demand or commenced a lawsuit against a builder or contractor alleging defective construction of improvements of the Association Property common area or property owned or controlled by the Association but outside the Sellers Property or unit", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 459.68,
   //    left: 335.433,
   //    width: 237.527,
   //    height: 60.24
   // }
   // ,
   // {
   //    page: 7,
   //    name: "O5 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 522.779,
   //    left: 73.597,
   //    width: 229.444,
   //    height: 11.876
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box328", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 520.318,
   //    left: 310.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "5_3", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 522.767,
   //    left: 335.433,
   //    width: 237.527,
   //    height: 12.840
   // }
   // ,
   // {
   //    page: 7,
   //    name: "O6 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 537.841,
   //    left: 73.597,
   //    width: 229.444,
   //    height: 11.876
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box329", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 538.38,
   //    left: 310.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "6_2", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 538.611,
   //    left: 335.761,
   //    width: 237.527,
   //    height: 12.029
   // }
   // ,
   // {
   //    page: 7,
   //    name: "undefined_59", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 603.203,
   //    left: 77.76,
   //    width: 225.423,
   //    height: 11.197
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box330", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 596.376,
   //    left: 310.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "7 Owners Association 1", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 593.0,
   //    left: 335.761,
   //    width: 237.527,
   //    height: 23.52
   // }
   // ,
   // {
   //    page: 7,
   //    name: "undefined_60", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 630.317,
   //    left: 77.76,
   //    width: 225.423,
   //    height: 11.197
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box331", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 623.549,
   //    left: 310.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "8 Owners Association 2", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 619.4,
   //    left: 335.761,
   //    width: 237.527,
   //    height: 23.52
   // }
   // ,
   // {
   //    page: 7,
   //    name: "undefined_61", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 656.717,
   //    left: 77.76,
   //    width: 225.423,
   //    height: 11.197
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box332", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 650.317,
   //    left: 310.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "9 Owners Association 3", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 645.8,
   //    left: 335.761,
   //    width: 237.527,
   //    height: 23.52
   // }
   // // ,
   // // {
   // //    page: 7,
   // //    name: "10_3", 
   // //    type: "text",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 681.84,
   // //    left: 42.96,
   // //    width: 28.32,
   // //    height: 14.88
   // // }
   // ,
   // {
   //    page: 7,
   //    name: "Owners Association 4", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 683.1173,
   //    left: 77.76,
   //    width: 225.423,
   //    height: 11.1973
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Check Box333", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 676.679,
   //    left: 310.611,
   //    width: 12.309,
   //    height: 13.861
   // }
   // ,
   // {
   //    page: 7,
   //    name: "Owners Association 4_2", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 672.2,
   //    left: 335.761,
   //    width: 237.527,
   //    height: 23.52
   // }
   // // ,
   // // {
   // //    page: 7,
   // //    name: "SPD19622 SELLERS PROPERTY DISCLOSURE RESIDENTIAL_5", 
   // //    type: "textarea",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 708.48,
   // //    left: 36,
   // //    width: 481.44,
   // //    height: 36.00
   // // }
   // // ,
   // // {
   // //    page: 7,
   // //    name: "Page 8 of 10", 
   // //    type: "textarea",
   // //    fontName: "Helvetica",
   // //    fontSize: 10,
   // //    top: 708.48,
   // //    left: 521.76,
   // //    width: 55.80,
   // //    height: 36.00
   // // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box334", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 90.036,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "1 Written reports of any building site roofing soils water sewer or engineering investigations or studies of the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 84.24,
   //    left: 330.08,
   //    width: 243.736,
   //    height: 28.80
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box335", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 120.454,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "2 Any property insurance claim submitted whether paid or not", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 117.92,
   //    left: 330.08,
   //    width: 243.736,
   //    height: 24.48
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box336", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 148.033,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "3 Structural architectural and engineering plans andor specifications for any existing improvements", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 145.28,
   //    left: 330.08,
   //    width: 243.736,
   //    height: 22.56
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box337", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 172.773,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "4 Property was previously used as a methamphetamine laboratory and not remediated to state standards", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 170.72,
   //    left: 330.08,
   //    width: 243.736,
   //    height: 22.44
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box338", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 199.54,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "5 Government special improvements approved but not yet installed that may become a lien against the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 196.04,
   //    left: 330.08,
   //    width: 243.736,
   //    height: 22.56
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box339", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 225.091,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "6 Pending 1 litigation or 2 other dispute resolution proceeding regarding the Property", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 221.48,
   //    left: 330.08,
   //    width: 243.736,
   //    height: 22.56
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box340", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 250.642,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "7 Property is subject to Deed Restrictions other recorded document restrictions or Affordable Housing Restrictions", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 246.92,
   //    left: 330.08,
   //    width: 243.736,
   //    height: 24.48
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box341", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 273.732,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "8 Property is located in a historic district", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 273.514,
   //    left: 330.08,
   //    width: 243.736,
   //    height: 13.246
   // }
   // ,
   // {
   //    page: 8,
   //    name: "P9 Issue", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 289.381,
   //    left: 75.1271,
   //    width: 221.7919,
   //    height: 12.210
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box342", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 289.143,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "9_3", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 290.497,
   //    left: 330.08,
   //    width: 243.736,
   //    height: 11.743
   // }
   // ,
   // {
   //    page: 8,
   //    name: "P10 Issues", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 304.876,
   //    left: 75.1271,
   //    width: 221.7919,
   //    height: 12.210
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box343", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 306.177,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "10_4", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 305.977,
   //    left: 330.08,
   //    width: 243.736,
   //    height: 11.623
   // }
   // ,
   // {
   //    page: 8,
   //    name: "GENERAL  Other Information", 
   //    type: "textarea",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 321.48,
   //    left: 206.16,
   //    width: 368.88,
   //    height: 24.48
   // }
   // ,
   // {
   //    page: 8,
   //    name: "undefined_62", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 348.637,
   //    left: 187.44,
   //    width: 109.634,
   //    height: 9.683
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box345", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 345.923,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "11 Location of Mailbox and No", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 346.997,
   //    left: 328.497,
   //    width: 246.386,
   //    height: 12.960
   // }
   // ,
   // {
   //    page: 8,
   //    name: "12_4", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 362.96,
   //    left: 72.84,
   //    width: 224.565,
   //    height: 12.84
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Check Box346", 
   //    isText: false,
   //    type: "checkbox",
   //    top: 362.957,
   //    left: 305.772,
   //    width: 12.827,
   //    height: 12.827
   // }
   // ,
   // {
   //    page: 8,
   //    name: "P12 Comments", 
   //    type: "text",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 362.502,
   //    left: 329.728,
   //    width: 244.715,
   //    height: 12.393
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Seller1Signature", 
   //    type: "signature",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 583.08,
   //    left: 36,
   //    width: 189,
   //    height: 10.20
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Seller1SigDate", 
   //    fontSize: 9,
   //    type: "date",
   //    top: 583.08,
   //    left: 229.56,
   //    width: 74.16,
   //    height: 10.20
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Seller2Signature", 
   //    type: "signature",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 583.08,
   //    left: 327.72,
   //    width: 167.28,
   //    height: 10.20
   // }
   // ,
   // {
   //    page: 8,
   //    name: "Seller2SigDate", 
   //    fontSize: 9,
   //    type: "date",
   //    top: 583.08,
   //    left: 499.56,
   //    width: 76.56,
   //    height: 10.20
   // }
   // ,
   // {
   //    page: 9,
   //    name: "Buyer1Siganture", 
   //    type: "signature",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 247.2,
   //    left: 36,
   //    width: 188.88,
   //    height: 36.0
   // }
   // ,
   // {
   //    page: 9,
   //    name: "Buyer1SigDate", 
   //    fontSize: 9,
   //    type: "date",
   //    top: 247.2,
   //    left: 229.56,
   //    width: 74.16,
   //    height: 36.0
   // }
   // ,
   // {
   //    page: 9,
   //    name: "Buyer2Signature", 
   //    type: "signature",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 247.2,
   //    left: 327.84,
   //    width: 166.92,
   //    height: 36.0
   // }
   // ,
   // {
   //    page: 9,
   //    name: "Buyer2SigDate", 
   //    fontSize: 9,
   //    type: "date",
   //    top: 247.2,
   //    left: 499.44,
   //    width: 54.24,
   //    height: 36.0
   // }
   
   ] }
   