export function extensionorTermination() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 178.255,
      left: 399.41,
      width: 174.00,
      height: 14.345
   }
   ,
   {
      page: 0,
      name: "PARTIES AND PROPERTY This document affects the contract dated", 
      type: "date",
      fontName: "Helvetica",
      fontSize: 10,
      top: 204.96,
      left: 370.68,
      width: 156.96,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "between", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 216.16,
      left: 86.16,
      width: 162.36,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Seller and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 216.16,
      left: 300.36,
      width: 227.28,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "relating to the sale and purchase of the following legally described real estate in the County of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 228.56,
      left: 429.6,
      width: 97.92,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Text1", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 239.565,
      left: 49.7457,
      width: 520.3663,
      height: 90.458
   }
   ,
   {
      page: 0,
      name: "known as", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 330.687,
      left: 96.24,
      width: 431.40,
      height: 15.393
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineRecord Title Deadline and Tax Certificate", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 474.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField  Date or DeadlineRecord Title Deadline and Tax Certificate", 
      fontSize: 9,
      type: "date",
      top: 475.353,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineRecord Title Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 486.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineRecord Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 486.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineOffRecord Title Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 498.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField  or DeadlineOffRecord Title Deadline", 
      fontSize: 9,
      type: "date",
      top:498.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineOffRecord Title Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 510.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField  or DeadlineOffRecord Title Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 510.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineTitle Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 522.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField  or DeadlineTitle Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 522.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineThird Party Right to PurchaseApprove Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 534.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField  or DeadlineThird Party Right to PurchaseApprove Deadline", 
      fontSize: 9,
      type: "date",
      top: 534.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAssociation Documents Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 558.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineAssociation Documents Deadline", 
      type: "date",
      fontName: "Helvetica",
      fontSize: 9,
      top: 558.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineAssociation Documents Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 570.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineAssociation Documents Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 570.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineSellers Property Disclosure Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 594.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineSellers Property Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 594.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineLeadBased Paint Disclosure Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 606.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineLeadBased Paint Disclosure Deadline", 
      fontSize: 9,
      type: "date",
      top: 606.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineNew Loan Application Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 630.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineNew Loan Application Deadline", 
      fontSize: 9,
      type: "date",
      top: 630.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineNew Loan Terms Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 642.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineNew Loan Terms Deadline", 
      fontSize: 9,
      type: "date",
      top: 642.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineNew Loan Availability Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 654.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineNew Loan Availability Deadline", 
      fontSize: 9,
      type: "date",
      top: 654.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineBuyers Credit Information Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 666.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineBuyers Credit Information Deadline", 
      fontSize: 9,
      type: "date",
      top: 666.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineDisapproval of Buyers Credit Information Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 678.84,
      left: 376.68,
      width: 114.691,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineDisapproval of Buyers Credit Information Deadline", 
      fontSize: 9,
      type: "date",
      top: 678.648,
      left: 491.71,
      width: 72.363,
      height: 9.949
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineExisting Loan Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 690.84,
      left: 376.68,
      width: 114.691,
      height: 9.9491
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineExisting Loan Deadline", 
      fontSize: 9,
      type: "date",
      top: 690.6479,
      left: 491.71,
      width: 72.363,
      height: 9.9489
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineExisting Loan Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 702.84,
      left: 376.68,
      width: 114.691,
      height: 9.9491
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineExisting Loan Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 702.6479,
      left: 491.71,
      width: 72.363,
      height: 9.9491
   }
   ,
   {
      page: 0,
      name: "Date or DeadlineLoan Transfer Approval Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 714.84,
      left: 376.68,
      width: 114.691,
      height: 9.9491
   }
   ,
   {
      page: 0,
      name: "DateField Date or DeadlineLoan Transfer Approval Deadline", 
      fontSize: 9,
      type: "date",
      top: 715.0843,
      left: 491.71,
      width: 72.363,
      height: 9.9491
   }
   ,
   {
      page: 1,
      name: "Seller or Private Financing Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 59.215,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Seller or Private Financing Deadline", 
      fontSize: 9,
      type: "date",
      top: 59.14,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Appraisal Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 82.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Appraisal Deadline", 
      fontSize: 9,
      type: "date",
      top: 82.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Appraisal Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 94.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Appraisal Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 94.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Appraisal Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 106.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Appraisal Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 106.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "New ILC or New Survey Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 130.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField New ILC or New Survey Deadline", 
      fontSize: 9,
      type: "date",
      top: 130.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "New ILC or New Survey Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 142.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField New ILC or New Survey Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 142.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "New ILC or New Survey Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 154.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField New ILC or New Survey Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 154.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Inspection Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 202.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Inspection Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 202.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Inspection Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 214.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Inspection Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 214.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Inspection Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 226.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Inspection Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 226.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Property Insurance Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 238.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Property Insurance Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 238.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Due Diligence Documents Delivery Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 250.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Due Diligence Documents Delivery Deadline", 
      fontSize: 9,
      type: "date",
      top: 250.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Due Diligence Documents Objection Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 262.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Due Diligence Documents Objection Deadline", 
      fontSize: 9,
      type: "date",
      top: 262.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Due Diligence Documents Resolution Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 274.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Due Diligence Documents Resolution Deadline", 
      fontSize: 9,
      type: "date",
      top: 274.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Text5", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 286.874,
      left: 407.68,
      width: 104.04,
      height: 10.386
   }
   ,
   {
      page: 1,
      name: "DateField Text5", 
      fontSize: 9,
      type: "date",
      top: 287.008,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Environmental Inspection Termination Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 298.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Environmental Inspection Termination Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 298.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "ADA Evaluation Termination Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 310.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField ADA Evaluation Termination Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 310.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "LeadBased Paint Termination Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 322.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField LeadBased Paint Termination Deadline", 
      fontSize: 9,
      type: "date",
      top: 322.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Estoppel Statement Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 334.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Estoppel Statement Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 334.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Estoppel Statement Termination Deadline CBS2 3 4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 346.945,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Estoppel Statement Termination Deadline CBS2 3 4", 
      fontSize: 9,
      type: "date",
      top: 346.694,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Short Sale Acceptance Deadline", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 371.065,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Short Sale Acceptance Deadline", 
      fontSize: 9,
      type: "date",
      top: 370.814,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Closing Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 395.065,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Closing Date", 
      fontSize: 9,
      type: "date",
      top: 394.814,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Possession Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 407.065,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Possession Date", 
      fontSize: 9,
      type: "date",
      top: 406.814,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Possession Time", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 419.065,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "Acceptance Deadline Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 431.065,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "DateField Acceptance Deadline Date", 
      fontSize: 9,
      type: "date",
      top: 430.814,
      left: 512.529,
      width: 61.344,
      height: 10.111
   }
   ,
   {
      page: 1,
      name: "Acceptance Deadline Time", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 443.065,
      left: 407.68,
      width: 104.04,
      height: 10.385
   }
   ,
   {
      page: 1,
      name: "Buyers Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 534,
      left: 117.96,
      width: 180.12,
      height: 19.047
   }
   ,
   {
      page: 1,
      name: "Buyers Name_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 532.735,
      left: 388.44,
      width: 187.80,
      height: 20.945
   }
   // ,
   // {
   //    page: 1,
   //    name: "Buyers Signature", 
   //    type: "signature",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 563.16,
   //    left: 50.4,
   //    width: 177.72,
   //    height: 28.80
   // }
   // ,
   
   // ,
   // {
   //    page: 1,
   //    name: "Buyers Signature_2", 
   //    type: "signature",
   //    fontName: "Helvetica",
   //    fontSize: 10,
   //    top: 563.16,
   //    left: 316.92,
   //    width: 186.36,
   //    height: 28.80
   // }
   // ,
   
   ,
   {
      page: 1,
      name: "Sellers Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 630.512,
      left: 117.96,
      width: 180.12,
      height: 28.80
   }
   ,
   {
      page: 1,
      name: "Sellers Name_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 629.477,
      left: 388.08,
      width: 188.16,
      height: 28.80
   }
   ,
   {
      page: 2,
      name: "NOTICE TO TERMINATE In the event this document for the above Extension is not signed by both Buyer and Seller or", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 109.2,
      left: 392.76,
      width: 161.76,
      height: 10.68
   }
   ,
   {
      page: 2,
      name: "Buyers", 
      
      type: "checkbox",
      top: 122.4,
      left: 259.44,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Sellers Notice to Terminate pursuant to", 
      
      type: "checkbox",
      top: 122.4,
      left: 307.56,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Text2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 11,
      top: 134.182,
      left: 49.7457,
      width: 522.9843,
      height: 13.091
   }
   ,
   {
      page: 2,
      name: "undefined", 
      
      type: "checkbox",
      top: 160.08,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "undefined_2", 
      
      type: "checkbox",
      top: 160.08,
      left: 314.88,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "undefined_3", 
      
      type: "checkbox",
      top: 174.96,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Due Diligence Documents Leases  10", 
      
      type: "checkbox",
      top: 174.96,
      left: 314.88,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "undefined_4", 
      
      type: "checkbox",
      top: 189.84,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Check Box6", 
      
      type: "checkbox",
      top: 190.527,
      left: 315.655,
      width: 8.509,
      height: 8.564
   }
   ,
   {
      page: 2,
      name: "undefined_6", 
      
      type: "checkbox",
      top: 204.84,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Check Box7", 
      
      type: "checkbox",
      top: 205.2,
      left: 315.164,
      width: 9.272,
      height: 9.218
   }
   ,
   {
      page: 2,
      name: "undefined_7", 
      
      type: "checkbox",
      top: 219.72,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Check Box8", 
      
      type: "checkbox",
      top: 219.982,
      left: 315.164,
      width: 9.327,
      height: 9.491
   }
   ,
   {
      page: 2,
      name: "undefined_9", 
      
      type: "checkbox",
      top: 234.6,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Check Box9", 
      
      type: "checkbox",
      top: 235.036,
      left: 315.055,
      width: 9.600,
      height: 9.273
   }
   ,
   {
      page: 2,
      name: "undefined_11", 
      
      type: "checkbox",
      top: 249.48,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Check Box10", 
      
      type: "checkbox",
      top: 249.982,
      left: 315.218,
      width: 9.382,
      height: 9.327
   }
   ,
   {
      page: 2,
      name: "Record Title OffRecord Title or Tax Certificate  8", 
      
      type: "checkbox",
      top: 264.36,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Check Box11", 
      
      type: "checkbox",
      top: 264.709,
      left: 315.218,
      width: 9.273,
      height: 9.273
   }
   ,
   {
      page: 2,
      name: "undefined_12", 
      
      type: "checkbox",
      top: 279.24,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Check Box12", 
      
      type: "checkbox",
      top: 279.546,
      left: 315.055,
      width: 9.545,
      height: 9.381
   }
   ,
   {
      page: 2,
      name: "undefined_14", 
      
      type: "checkbox",
      top: 294.12,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Check Box13", 
      
      type: "checkbox",
      top: 294.491,
      left: 315.164,
      width: 9.600,
      height: 9.491
   }
   ,
   {
      page: 2,
      name: "undefined_15", 
      
      type: "checkbox",
      top: 309.12,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Check Box14", 
      
      type: "checkbox",
      top: 309.273,
      left: 315.109,
      width: 9.491,
      height: 9.545
   }
   ,
   {
      page: 2,
      name: "Other", 
      
      type: "checkbox",
      top: 324,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 320.662,
      left: 104.28,
      width: 203.095,
      height: 13.896
   }
   ,
   {
      page: 2,
      name: "undefined_17", 
      
      type: "checkbox",
      top: 324,
      left: 314.88,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Other_2", 
      
      type: "checkbox",
      top: 338.88,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 336.578,
      left: 104.28,
      width: 474.36,
      height: 12.133
   }
   ,
   {
      page: 2,
      name: "undefined_18", 
      
      type: "checkbox",
      top: 390.36,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "undefined_19", 
      
      type: "checkbox",
      top: 405.24,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "undefined_20", 
      
      type: "checkbox",
      top: 420.24,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "undefined_21", 
      
      type: "checkbox",
      top: 435.12,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Other_3", 
      
      type: "checkbox",
      top: 450,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "undefined_22", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 448.538,
      left: 103.527,
      width: 469.986,
      height: 23.580
   }
   ,
   {
      page: 2,
      name: "Buyer", 
      
      type: "checkbox",
      top: 499.2,
      left: 51.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "Seller is terminating the Contract", 
      
      type: "checkbox",
      top: 499.2,
      left: 97.08,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 2,
      name: "undefined_23", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 523.32,
      left: 117.96,
      width: 180.12,
      height: 10.68
   }
   ,
   {
      page: 2,
      name: "undefined_24", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 523.32,
      left: 388.08,
      width: 188.16,
      height: 10.68
   }
   ] }
   