export function energyBenchmarkingDisclosure() {
    return [   //2024 Release 2023-12-18 20:09:22
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
       page: 0,
       name: "owners", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 165,
       left: 159.36,
       width: 393.186,
       height: 13.516
    }
    ,
    {
       page: 0,
       name: "Date Listing Contract Date 1", 
       fontSize: 9,
       type: "date",
       top: 186,
       left: 159.36,
       width: 393.186,
       height: 15.48
    }
    ,
    {
       page: 0,
       name: "Property", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 211,
       left: 159.36,
       width: 393.186,
       height: 15.48
    }
    ,
    {
       page: 0,
       name: "Seller", 
       isText: false,
       type: "checkbox",
       top: 514.8,
       left: 54.72,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 0,
       name: "Landlord", 
       isText: false,
       type: "checkbox",
       top: 514.8,
       left: 98.16,
       width: 9.96,
       height: 9.96
    }
    ,
    {
       page: 0,
       name: "Date On", 
       fontSize: 9,
       type: "date",
       top: 601.44,
       left: 68.64,
       width: 94.32,
       height: 15.48
    }
    ,
    {
       page: 0,
       name: "ownersFullName", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 601.44,
       left: 241.833,
       width: 276.327,
       height: 15.48
    }
    ,
    {
       page: 0,
       name: "with this document via", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 624.84,
       left: 152.88,
       width: 234.72,
       height: 15.48
    }
    ,
    {
       page: 0,
       name: "Brokerage Firms Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 648.36,
       left: 156.48,
       width: 374.64,
       height: 15.48
    }
    ,
    {
       page: 0,
       name: "Brokers Name 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 669.96,
       left: 116.16,
       width: 182.28,
       height: 15.48
    }
    ,
    {
       page: 0,
       name: "CoBrokers Name 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 668.64,
       left: 388.92,
       width: 166.20,
       height: 15.48
    }
    ,
    {
       page: 0,
       name: "signature broker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 686.618,
       left: 54.6653,
       width: 178.7227,
       height: 13.5164
    }
    ,
    {
       page: 0,
       name: "date BrokerSign", 
       fontSize: 9,
       type: "date",
       top: 686.16,
       left: 241.102,
       width: 56.618,
       height: 14.28
    }
    ,
    {
       page: 0,
       name: "signature cobrokerSign", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 683.345,
       left: 310.309,
       width: 178.178,
       height: 15.4803
    }
    ,
    {
       page: 0,
       name: "date CobrokerSign", 
       fontSize: 9,
       type: "date",
       top: 684.84,
       left: 496.342,
       width: 56.738,
       height: 14.52
    }
    ] }
    