export function leadBasedPaintSellerDisclosure() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 86", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 212.76,
   left: 103.01,
   width: 449.59,
   height: 12.24
}
,
{
   page: 0,
   name: "Text Field 61", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 269.92,
   left: 88.128,
   width: 30.960,
   height: 12.24
}
,
{
   page: 0,
   name: "Text Field 62", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 299.656,
   left: 77.472,
   width: 446.112,
   height: 12.240
}
,
{
   page: 0,
   name: "Text Field 85", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 316.936,
   left: 90.144,
   width: 25.731,
   height: 12.240
}
,
{
   page: 0,
   name: "Text Field 64", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 351.208,
   left: 88.128,
   width: 30.960,
   height: 12.240
}
,
{
   page: 0,
   name: "Text Field 63", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 381.016,
   left: 77.328,
   width: 446.256,
   height: 12.240
}
,
{
   page: 0,
   name: "Text Field 65", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 398.296,
   left: 90.576,
   width: 26.064,
   height: 12.240
}
] }
