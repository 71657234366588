import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { updateProfileFieldsInDb } from "../../app/firestore/firestoreService";
import { useSelector } from "react-redux";

export default function ManagerAgentActionButtons({ agent }) {
  const navigate = useNavigate();
  const { currentUserProfile } = useSelector((state) => state.profile);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleGoToAgentTransactions() {
    navigate(`/agent/${agent.id}/transactions`);
  }

  function handleGoToAgentDashboard() {
    navigate(`/agent/${agent.id}/overview`);
  }

  async function handleMakeActive() {
    try {
      await updateProfileFieldsInDb(agent.id, {
        managerActive: true,
      });
      toast.success("Agent successfully made active");
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleMakeInactive() {
    try {
      await updateProfileFieldsInDb(agent.id, {
        managerActive: false,
      });
      toast.success("Agent successfully made inactive");
    } catch (error) {
      toast.error(error.message);
    }
  }

  // async function handleRemove(agent) {
  //   try {
  //     await updateProfileFieldsInDb(agent.id, {
  //       managerId: null
  //     });
  //     setConfirmOpen(false);
  //     toast.success("Agent successfully removed");
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // }

  return (
    <div className={isMobile ? null : "text align right"}>
      <Dropdown
        button
        icon="chevron down"
        text={isMobile ? "Actions " : null}
        className={isMobile ? "button icon" : "button mini icon"}
        direction={isMobile ? "right" : "left"}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => handleGoToAgentTransactions()}
          >
            View Transactions
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleGoToAgentDashboard()}
          >
            View Dashboard
          </Dropdown.Item>
          {agent.managerId === currentUserProfile.userId && (
            <>
              {agent.managerActive === true ? (
                <Dropdown.Item
                  onClick={() => handleMakeInactive()}
                >
                  Make Inactive
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={() => handleMakeActive()}
                >
                  Make Active
                </Dropdown.Item>
              )}
            </>
          )}
          {/* <Dropdown.Item
            onClick={() => setConfirmOpen(true)}
          >
            Remove
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
      {/* <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleRemove(agent)}
      ></Confirm> */}
    </div>
  );
}