export function inspectionResolution() {
return [
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date", 
   top: 165.60,
   left: 398.64,
   width: 174.00,
   height: 22.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Buyer relating to the sale and purchase of the Property known as", 
   top: 189.12,
   left: 294.96,
   width: 106.56,
   height: 22.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "between", 
   top: 189.12,
   left: 442.32,
   width: 130.32,
   height: 22.68
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller and 1", 
   top: 212.64,
   left: 99.84,
   width: 184.80,
   height: 11.16
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller and 2", 
   top: 224.64,
   left: 50.4,
   width: 477.24,
   height: 11.16
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "will do the following to resolve", 
   top: 272.64,
   left: 299.4,
   width: 124.68,
   height: 11.16
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text1", 
   top: 297.424,
   left: 50.2689,
   width: 531.4891,
   height: 186.938
}
// ,
// {
//    page: 0,
//    fontName: "Helvetica",
//    fontSize: 12,
//    type: "signature",
//    name: "Signature2", 
//    top: 581.758,
//    left: 49.2216,
//    width: 197.4104,
//    height: 21.993
// }
// ,
// {
//    page: 0,
//    fontName: "Helvetica",
//    fontSize: 9,
//    type: "date",
//    name: "Date_2", 
//    top: 581.04,
//    left: 248.04,
//    width: 70,
//    height: 22.68
// }
// ,
// {
//    page: 0,
//    fontName: "Helvetica",
//    fontSize: 12,
//    type: "signature",
//    name: "Signature3", 
//    top: 581.234,
//    left: 324.653,
//    width: 185.891,
//    height: 21.993
// }
// ,
// {
//    page: 0,
//    fontName: "Helvetica",
//    fontSize: 9,
//    type: "date",
//    name: "Date_3", 
//    top: 581.04,
//    left: 513.36,
//    width: 70,
//    height: 22.68
// }
// ,
// {
//    page: 0,
//    fontName: "Helvetica",
//    fontSize: 12,
//    type: "signature",
//    name: "Signature5", 
//    top: 631.503,
//    left: 48.698,
//    width: 196.887,
//    height: 21.469
// }
// ,
// {
//    page: 0,
//    fontName: "Helvetica",
//    fontSize: 9,
//    type: "date",
//    name: "Date_4", 
//    top: 630.36,
//    left: 248.04,
//    width: 70,
//    height: 22.68
// }
// ,
// {
//    page: 0,
//    fontName: "Helvetica",
//    fontSize: 12,
//    type: "signature",
//    name: "Signature4", 
//    top: 630.456,
//    left: 325.177,
//    width: 186.938,
//    height: 23.040
// }
// ,
// {
//    page: 0,
//    fontName: "Helvetica",
//    fontSize: 9,
//    type: "date",
//    name: "Date_5", 
//    top: 630.36,
//    left: 513.36,
//    width: 70,
//    height: 22.68
// }
] }
