import React from "react";
import { Card, Image, Item } from "semantic-ui-react";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { convertAddressCityStateZipcode } from "../../../app/common/util/util";

export default function TransactionPropertyDetails({ transaction }) {
  return (
    <ModalWrapper size="mini">
      {transaction && (
        <Card fluid>
          <Image src={transaction.pic} wrapped ui={false} />
          <Card.Content>
            <Card.Header>
              {transaction.address && (
                <>
                  <div className="tiny bottom margin">
                    {transaction.address.street}
                  </div>
                  <span>
                    {convertAddressCityStateZipcode(transaction.address)}
                  </span>
                </>
              )}
            </Card.Header>
            <Card.Meta>
              {transaction?.propertyDetails?.mlsNumber && (
                <p>MLS #: {transaction.propertyDetails.mlsNumber}</p>
              )}
            </Card.Meta>
            <Card.Description>
              <p className="small top margin">Status: {transaction.status}</p>
              {transaction.propertyDetails && (
                <>
                  {transaction.propertyDetails.subdivision && (
                    <Item.Extra>
                      Subdivision: {transaction.propertyDetails.subdivision}
                    </Item.Extra>
                  )}
                  {transaction.propertyDetails.parcelNumber && (
                    <Item.Extra>
                      Parcel #: {transaction.propertyDetails.parcelNumber}
                    </Item.Extra>
                  )}
                  {transaction.propertyDetails.county && (
                    <Item.Extra>
                      County: {transaction.propertyDetails.county}
                    </Item.Extra>
                  )}
                  {transaction.propertyDetails.yearBuilt && (
                    <Item.Extra>
                      Year Built: {transaction.propertyDetails.yearBuilt}
                    </Item.Extra>
                  )}
                </>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      )}
    </ModalWrapper>
  );
}
