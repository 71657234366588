export function brokerageDisclosuretoSellerFSBO() {
return [
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "relationship specified below is for a specific property or properties described as", 
   top: 510.00,
   left: 57.6,
   width: 495.24,
   height: 22.08
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box4", 
   top: 608.73,
   left: 58.9093,
   width: 9,
   height: 9
}
,
{
   page: 0,
   type: "checkbox",
   name: "Check Box5", 
   top: 663.057,
   left: 58.2548,
   width: 9,
   height: 9
}
,
{
   page: 1,
   type: "checkbox",
   name: "Check Box6", 
   top: 79.200,
   left: 59.5639,
   width: 9,
   height: 9
}
,
{
   page: 1,
   type: "checkbox",
   name: "buyers agent", 
   top: 81.24,
   left: 187.8,
   width: 9,
   height: 9
}
,
{
   page: 1,
   type: "checkbox",
   name: "buyers transactionbroker and Seller is a customer Broker intends to", 
   top: 81.24,
   left: 260.16,
   width: 9,
   height: 9
}
,
{
   page: 1,
   type: "checkbox",
   name: "Show a property", 
   top: 92,
   left: 200.88,
   width: 9,
   height: 9
}
,
{
   page: 1,
   type: "checkbox",
   name: "Prepare and Convey written offers counteroffers and agreements", 
   top: 92,
   left: 282.84,
   width: 9,
   height: 9
}
,
{
   page: 1,
   type: "checkbox",
   name: "Check Box7", 
   top: 123.055,
   left: 57.6003,
   width: 9,
   height: 9
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "undefined", 
   top: 288.24,
   left: 254.4,
   width: 181.32,
   height: 22.56
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Text11", 
   top: 401.965,
   left: 70.0367,
   width: 167.0183,
   height: 15
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Text12", 
   top: 392.693,
   left: 309.947,
   width: 202.910,
   height: 21
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Text13", 
   top: 424.184,
   left: 151.201,
   width: 87.709,
   height: 17.018
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Text14", 
   top: 445.438,
   left: 159.71,
   width: 377.02,
   height: 18
}
,
{
   page: 1,
   fontName: "Helvetica",
   fontSize: 12,
   type: "signature",
   name: "Signature10", 
   top: 475.584,
   left: 58.2548,
   width: 244.1462,
   height: 30.073
}
] }