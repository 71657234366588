import { formatDistance, formatRelative } from "date-fns";
import enUsLocale from "date-fns/locale/en-US";
import download from "downloadjs";
import _ from "lodash";
import {
  addFormsToTransaction,
  getDocDownloadUrl,
} from "../../firestore/firebaseService";
import {
  createSigningCertificatePdf,
  fillAndFlattenPdf,
} from "../../pdfLib/pdfLib";
import { updateDocInDb } from "../../firestore/firestoreService";
import { getFormFieldData } from "../../../features/docs/docComponents/docFill/formFieldData/formFieldData";

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function abbreviateDirection(direction) {
  return direction === "ascending" ? "asc" : "desc";
}

export function generateEmailFields(
  values,
  allParties,
  agentProfile,
  toRoles,
  ccRoles,
  bccRoles
) {
  let allPartiesCopy = _.cloneDeep(allParties);
  allPartiesCopy = [...allPartiesCopy, agentProfile];

  let toEmails = [];
  let toEmailsAndRoles = [];
  let ccEmails = [];
  let ccEmailsAndRoles = [];
  let bccEmails = [];
  let bccEmailsAndRoles = [];

  ////// ADD EXTRA EMAILS FROM INPUT FIELD /////////
  if (values.otherEmailsTo) {
    values.otherEmailsTo.split(",").forEach((toEmail) => {
      toEmails = [...toEmails, toEmail.trim()];
    });
  }
  if (values.otherEmailsCc) {
    values.otherEmailsCc.split(",").forEach((ccEmail) => {
      ccEmails = [...ccEmails, ccEmail.trim()];
    });
  }
  if (values.otherEmailBxc) {
    values.otherEmailsbcc.split(",").forEach((bccEmail) => {
      bccEmails = [...bccEmails, bccEmail.trim()];
    });
  }

  ////// GET EMAILS FROM ROLES INC ASSISTANTS /////////

  toRoles.forEach((toRole) => {
    let party = allPartiesCopy.filter(
      (party) => party.email && party.role === toRole
    )[0];
    if (party) {
      toEmails = [...toEmails, party.email];
      toEmailsAndRoles = [
        ...toEmailsAndRoles,
        {
          role: toRole,
          email: party.email,
          firstName: party.firstName,
          lastName: party.lastName,
        },
      ];
      if (party.hasAssistant && party.assistant?.email) {
        toEmails = [...toEmails, party.assistant.email];
        toEmailsAndRoles = [
          ...toEmailsAndRoles,
          {
            role: `${toRole} Assistant`,
            email: party.assistant.email,
            firstName: party.assistant.firstName,
            lastName: party.assistant.lastName,
          },
        ];
      }
      if (
        party.hasTransactionCoordinator &&
        party.transactionCoordinator?.email
      ) {
        toEmails = [...toEmails, party.transactionCoordinator.email];
        toEmailsAndRoles = [
          ...toEmailsAndRoles,
          {
            role: "TC (Other)",
            email: party.transactionCoordinator.email,
            firstName: party.transactionCoordinator.firstName,
            lastName: party.transactionCoordinator.lastName,
          },
        ];
      }
      if (party.hasCoAgent && party.coAgent?.email) {
        toEmails = [...toEmails, party.coAgent.email];
        toEmailsAndRoles = [
          ...toEmailsAndRoles,
          {
            role: "CoAgent (Other)",
            email: party.coAgent.email,
            firstName: party.coAgent.firstName,
            lastName: party.coAgent.lastName,
          },
        ];
      }
    }
  });

  ccRoles.forEach((ccRole) => {
    let party = allPartiesCopy.filter(
      (party) => party.email && party.role === ccRole
    )[0];
    if (party) {
      ccEmails = [...ccEmails, party.email];
      ccEmailsAndRoles = [
        ...ccEmailsAndRoles,
        {
          role: ccRole,
          email: party.email,
          firstName: party.firstName,
          lastName: party.lastName,
        },
      ];
      if (party.hasAssistant && party.assistant?.email) {
        ccEmails = [...ccEmails, party.assistant.email];
        ccEmailsAndRoles = [
          ...ccEmailsAndRoles,
          {
            role: `${ccRole} Assistant`,
            email: party.assistant.email,
            firstName: party.assistant.firstName,
            lastName: party.assistant.lastName,
          },
        ];
      }
      if (
        party.hasTransactionCoordinator &&
        party.transactionCoordinator?.email
      ) {
        ccEmails = [...ccEmails, party.transactionCoordinator.email];
        ccEmailsAndRoles = [
          ...ccEmailsAndRoles,
          {
            role: "TC (Other)",
            email: party.transactionCoordinator.email,
            firstName: party.transactionCoordinator.firstName,
            lastName: party.transactionCoordinator.lastName,
          },
        ];
      }
      if (party.hasCoAgent && party.coAgent?.email) {
        ccEmails = [...ccEmails, party.coAgent.email];
        ccEmailsAndRoles = [
          ...ccEmailsAndRoles,
          {
            role: "CoAgent (Other)",
            email: party.coAgent.email,
            firstName: party.coAgent.firstName,
            lastName: party.coAgent.lastName,
          },
        ];
      }
    }
  });

  bccRoles.forEach((bccRole) => {
    let party = allPartiesCopy.filter(
      (party) => party.email && party.role === bccRole
    )[0];
    if (party) {
      bccEmails = [...bccEmails, party.email];
      bccEmailsAndRoles = [
        ...bccEmailsAndRoles,
        {
          role: bccRole,
          email: party.email,
          firstName: party.firstName,
          lastName: party.lastName,
        },
      ];
      if (party.hasAssistant && party.assistant?.email) {
        bccEmails = [...bccEmails, party.assistant.email];
        bccEmailsAndRoles = [
          ...bccEmailsAndRoles,
          {
            role: `${bccRole} Assistant`,
            email: party.assistant.email,
            firstName: party.assistant.firstName,
            lastName: party.assistant.lastName,
          },
        ];
      }
      if (
        party.hasTransactionCoordinator &&
        party.transactionCoordinator?.email
      ) {
        bccEmails = [...bccEmails, party.transactionCoordinator.email];
        bccEmailsAndRoles = [
          ...bccEmailsAndRoles,
          {
            role: "TC (Other)",
            email: party.transactionCoordinator.email,
            firstName: party.transactionCoordinator.firstName,
            lastName: party.transactionCoordinator.lastName,
          },
        ];
      }
      if (party.hasCoAgent && party.coAgent?.email) {
        bccEmails = [...bccEmails, party.coAgent.email];
        bccEmailsAndRoles = [
          ...bccEmailsAndRoles,
          {
            role: "CoAgent (Other)",
            email: party.coAgent.email,
            firstName: party.coAgent.firstName,
            lastName: party.coAgent.lastName,
          },
        ];
      }
    }
  });
  return {
    toEmails: toEmails,
    toEmailsAndRoles: toEmailsAndRoles,
    ccEmails: ccEmails,
    ccEmailsAndRoles: ccEmailsAndRoles,
    bccEmails: bccEmails,
    bccEmailsAndRoles: bccEmailsAndRoles,
  };
}

export async function getFormFieldValues(doc, transaction, setFormFieldValues) {
  if (
    !_.isEmpty(doc.formFieldValues) &&
    (doc.status === "In Progress" || doc.pdfBurnVersion)
  ) {
    const formFieldData = getFormFieldData(doc.title, transaction);
    if (formFieldData) {
      let formValuesToDisplay = [];
      Object.keys(doc.formFieldValues).forEach((key) => {
        let newItem = formFieldData.filter((item) => item.name === key)[0];
        if (newItem) {
          newItem["value"] = doc.formFieldValues[key];
          formValuesToDisplay = [...formValuesToDisplay, newItem];
        }
      });
      setFormFieldValues(formValuesToDisplay);
    }
  }
}

export async function getAndSavePdfDimensions(
  doc,
  pdfObject,
  setNumPages,
  setPageDimensions,
  userRole
) {
  const page = await pdfObject.getPage(1);
  let docWidth = page.view?.[2] || 612;
  let docHeight = page.view?.[3] || 792;
  let rotation = 0;
  if (page.rotate && (page.rotate === 90 || page.rotate === 270)) {
    docWidth = page.view?.[3] || 792;
    docHeight = page.view?.[2] || 612;
    rotation = page.rotate;
  }
  if (pdfHasNonStandardDimensions(page)) {
    setPageDimensions({ width: docWidth, height: docHeight });
  }
  setNumPages(pdfObject.numPages);
  if (
    userRole === "agent" &&
    (!doc.numPages ||
      !doc.dimensions?.width ||
      !doc.dimensions?.height ||
      !doc.dimensions?.rotation)
  ) {
    updateDocInDb(doc.id, {
      numPages: pdfObject.numPages,
      dimensions: {
        width: docWidth,
        height: docHeight,
        rotation: rotation,
      },
    });
  }
}

export function pdfHasNonStandardDimensions(page) {
  if (
    page.view?.[2] &&
    page.view?.[3] &&
    (page.view?.[2] < 600 ||
      page.view?.[2] > 620 ||
      page.view?.[3] < 780 ||
      page.view?.[3] > 800)
  ) {
    return true;
  } else {
    return false;
  }
}

export function convertMlsData(jsonData) {
  const values = jsonData.value[0];

  const formattedData = {
    address: {
      street:
        values["StreetNumber"] +
        " " +
        values["StreetName"] +
        " " +
        values["StreetSuffix"],
      unit: values["UnitNumber"],
      city: values["City"],
      state: values["StateOrProvince"],
      zipcode: values["PostalCode"],
    },
    propertyDetails: {
      county: values["CountyOrParish"],
      legalDescription: values["TaxLegalDescription"],
      parcelNumber: values["ParcelNumber"],
      subdivisionName: values["SubdivisionName"],
      yearBuilt: values["YearBuilt"],
      privateRemarks: values["PrivateRemarks"],
      inclusions: values["Inclusions"],
      exclusions: values["Exclusions"],
    },
    pic: values["Media"]?.[0]?.["MediaURL"],
    listingAgent: {
      firstName: values["ListAgent"]?.["MemberFirstName"],
      lastName: values["ListAgent"]?.["MemberLastName"],
      email: values["ListAgentEmail"],
      phone: values["ListAgentPreferredPhone"],
      address: {
        street: values["ListAgent"]?.["MemberAddress1"],
        city: values["ListAgent"]?.["MemberCity"],
        state: values["ListAgent"]?.["MemberStateOrProvince"],
        zipcode: values["ListAgent"]?.["MemberPostalCode"],
      },
      brokerLicenseNumber: values["ListAgent"]?.["MemberStateLicense"],
      colistingAgent: values["CoListAgentFullName"],
      brokerageName: values["ListOffice"]?.["OfficeName"],
      brokerageId: values["ListAgent"]?.["OfficeMlsId"],
    },
  };
  return formattedData;
}

export async function downloadPdf(doc, transaction, currentUserProfile) {
  const newDocName = doc.name.replace(/\./g, "-");
  if (doc.status === "In Progress" || doc.pdfBurnVersion) {
    const pdfBytes = await fillAndFlattenPdf(
      doc,
      transaction,
      currentUserProfile
    );
    download(pdfBytes, newDocName, "application/pdf");
  } else {
    const docUrl = await getDocDownloadUrl(doc.docRef);
    const pdfBytes = await fetch(docUrl).then((res) => res.arrayBuffer());
    download(pdfBytes, newDocName, "application/pdf");
  }
}

export async function downloadSigningCertificatePdf(doc, currentUserProfile) {
  const newDocName = doc.name.replace(/\./g, "-") + " (Signing Certificate)";
  const pdfBytes = await createSigningCertificatePdf(doc, currentUserProfile);
  download(pdfBytes, newDocName, "application/pdf");
}

export async function downloadNonPdf(doc) {
  const newDocName = doc.name.replace(/\./g, "-");
  const docUrl = await getDocDownloadUrl(doc.docRef);
  const pdfBytes = await fetch(docUrl).then((res) => res.arrayBuffer());
  let docName = newDocName;
  if (doc.filetype) {
    docName = docName + "." + doc.filetype;
  }
  download(pdfBytes, docName, doc.filetypeMIME);
}

export function docIsTypePdf(doc) {
  return doc.filetype === "pdf" || !doc.filetype;
}

export function docIsTypeImage(doc) {
  return doc.filetypeMIME && doc.filetypeMIME.includes("image") && doc.fileUrl;
}

export function convertFileTypeToIcon(doc) {
  if (doc.filetype && doc.filetypeMIME && doc.filetype !== "pdf") {
    if (doc.filetypeMIME.includes("image")) {
      return "image";
    } else if (doc.filetypeMIME.includes("video")) {
      return "video";
    } else {
      return "pdf";
    }
  } else {
    return "pdf";
  }
}

export function createTransactionFields(transaction, clientForm) {
  let transactionFields = {};
  if (clientForm.role === "Buyer" || clientForm.role === "Seller") {
    transactionFields = { client: clientForm };
    transactionFields["searchArray"] = createSearchArrayClient(
      transaction,
      clientForm
    );
  } else if (clientForm.role === "Buyer 2" || clientForm.role === "Seller 2") {
    transactionFields = {
      clientSecondary: { ...clientForm },
      clientSecondaryExists: true,
    };
    transactionFields["searchArray"] = createSearchArrayClientSecondary(
      transaction,
      clientForm
    );
  }
  return transactionFields;
}

export function convertSharingWithToColor(doc, party) {
  const partyRoleCamel = _.camelCase(party.role);
  if (doc.sharingWithRole && doc.sharingWithRole[partyRoleCamel]) {
    if (
      doc.signingDetails &&
      doc.signingDetails[partyRoleCamel] &&
      doc.signingDetails[partyRoleCamel]?.signed
    ) {
      return "green";
    } else if (
      doc.signingDetails &&
      doc.signingDetails[partyRoleCamel] &&
      doc.signingDetails[partyRoleCamel]?.sentOut
    ) {
      return "orange";
    } else if (partyIsAgent(party.role) && party.isUser && party.isLinked) {
      return "purple";
    } else {
      return "blue";
    }
  } else {
    return null;
  }
}

export function convertPartiesAbleToShare(parties, agentRepresents) {
  let allParties = _.cloneDeep(parties);
  parties.forEach((party, index) => {
    if (
      agentRepresents === "Buyer" &&
      (party.role === "Seller" || party.role === "Seller 2")
    ) {
      allParties = allParties.filter((allParty) => allParty.id !== party.id);
    } else if (
      agentRepresents === "Seller" &&
      (party.role === "Buyer" || party.role === "Buyer 2")
    ) {
      allParties = allParties.filter((allParty) => allParty.id !== party.id);
    }
  });
  allParties = allParties.filter(
    (allParty) => allParty.role !== "Transaction Coordinator"
  );
  allParties = allParties.filter((allParty) => allParty.role !== "TC (Mine)");
  allParties = allParties.filter(
    (allParty) => allParty.role !== "CoAgent (Mine)"
  );
  allParties.forEach((party, index) => {
    allParties[index].index = convertRoleToIndex(party.role, agentRepresents);
  });
  return _.sortBy(allParties, "index");
}

export function convertOtherPartiesAbleToSign(doc, parties, agentRepresents) {
  let allParties = _.cloneDeep(parties);
  let otherPartiesAbleToSign = [];
  if (agentRepresents === "Buyer" && doc.signingDetails?.seller) {
    otherPartiesAbleToSign = allParties.filter(
      (party) => party.role === "Seller" || party.role === "Seller 2"
    );
  } else if (agentRepresents === "Seller" && doc.signingDetails?.buyer) {
    otherPartiesAbleToSign = allParties.filter(
      (party) => party.role === "Buyer" || party.role === "Buyer 2"
    );
  }
  otherPartiesAbleToSign.forEach((party, index) => {
    otherPartiesAbleToSign[index].index = convertRoleToIndex(
      party.role,
      agentRepresents
    );
  });
  return _.sortBy(otherPartiesAbleToSign, "index");
}

export function convertPartiesToSendEmail(parties, agent, agentRepresents) {
  let allParties = _.cloneDeep(parties);
  if (agent) {
    let agentParty = _.cloneDeep(agent);
    allParties = [...allParties, agentParty];
  }
  parties.forEach((party, index) => {
    if (
      agentRepresents === "Buyer" &&
      (party.role === "Seller" || party.role === "Seller 2")
    ) {
      allParties = allParties.filter((allParty) => allParty.id !== party.id);
    } else if (
      agentRepresents === "Seller" &&
      (party.role === "Buyer" || party.role === "Buyer 2")
    ) {
      allParties = allParties.filter((allParty) => allParty.id !== party.id);
    }
  });
  allParties = allParties.filter(
    (allParty) => allParty.role !== "Transaction Coordinator"
  );
  // allParties = allParties.filter((allParty) => allParty.role !== "TC (Mine)");
  // allParties = allParties.filter(
  //   (allParty) => allParty.role !== "CoAgent (Mine)"
  // );
  allParties.forEach((party, index) => {
    allParties[index].index = convertRoleToIndex(party.role, agentRepresents);
  });
  return _.sortBy(allParties, "index");
}

export function convertContactsToDisplay(
  parties,
  agentRepresents,
  currentUser
) {
  let allParties = _.cloneDeep(parties);
  parties.forEach((party, index) => {
    if (party.email === currentUser.email) {
      allParties = allParties.filter((allParty) => allParty.id !== party.id);
    }
    if (
      agentRepresents === "Buyer" &&
      (party.role === "Seller" || party.role === "Seller 2")
    ) {
      allParties = allParties.filter((allParty) => allParty.id !== party.id);
    } else if (
      agentRepresents === "Seller" &&
      (party.role === "Buyer" || party.role === "Buyer 2")
    ) {
      allParties = allParties.filter((allParty) => allParty.id !== party.id);
    }
  });
  allParties.forEach((party, index) => {
    allParties[index].index = convertRoleToIndex(party.role, agentRepresents);
  });
  return _.sortBy(allParties, "index");
}

export function hidePartyContactDetails(userParty, party) {
  let hideInfo = false;
  if (userParty.role === "Listing Agent") {
    if (party.role === "Buyer" || party.role === "Buyer 2") {
      hideInfo = true;
    }
  } else if (userParty.role === "Buyer Agent") {
    if (party.role === "Seller" || party.role === "Seller 2") {
      hideInfo = true;
    }
  }
  return hideInfo;
}

export function convertRoleToSharingDisplay(role) {
  switch (role) {
    case "Transaction Coordinator":
      return "TC";
    case "Escrow Officer":
      return "Escrow";
    case "Title Company":
      return "Title";
    case "Buyer Lender":
      return "Lender";
    default:
      return role;
  }
}

export function convertRoleToIndex(role, agentRepresents) {
  if (agentRepresents === "Buyer") {
    switch (role) {
      case "Buyer":
        return 0;
      case "Buyer 2":
        return 1;
      case "Listing Agent":
        return 2;
      case "TC (Mine)":
        return 3;
      case "CoAgent (Mine)":
        return 4;
      case "Escrow Officer":
        return 5;
      case "Title Company":
        return 6;
      case "Buyer Lender":
        return 7;
      case "Seller":
        return 8;
      case "Seller 2":
        return 9;
      case "Other":
        return 10;
      case "Me (Agent)":
        return 11;
      default:
        return 0;
    }
  } else {
    switch (role) {
      case "Seller":
        return 0;
      case "Seller 2":
        return 1;
      case "Buyer Agent":
        return 2;
      case "TC (Mine)":
        return 3;
      case "CoAgent (Mine)":
        return 4;
      case "Escrow Officer":
        return 5;
      case "Title Company":
        return 6;
      case "Buyer":
        return 7;
      case "Buyer 2":
        return 8;
      case "Buyer Lender":
        return 9;
      case "Me (Agent)":
        return 10;
      case "Other":
        return 11;
      default:
        return "";
    }
  }
}

export function convertRoleToPeopleType(role) {
  switch (role) {
    case "Listing Agent":
      return "Agent";
    case "Buyer Agent":
      return "Agent";
    case "Escrow Officer":
      return "Escrow";
    case "Title Company":
      return "Title";
    case "Buyer Lender":
      return "Lender";
    case "Buyer Attorney":
      return "Attorney";
    case "Seller Attorney":
      return "Attorney";
    case "Transaction Coordinator":
      return "TC";
    case "TC (Mine)":
      return "TC";
    case "CoAgent (Mine)":
      return "Agent";
    case "Buyer":
    case "Buyer 2":
    case "Seller":
    case "Seller 2":
      return "Client";
    default:
      return "";
  }
}

export function createSearchArray(transaction) {
  let searchArray = [];
  if (transaction.searchArray) {
    searchArray = JSON.parse(JSON.stringify(transaction.searchArray));
  } else {
    searchArray = ["", "", "", "", "", "", "", "", "", ""];
  }
  if (transaction.client?.firstName) {
    searchArray[0] = transaction.client?.firstName;
  }
  if (transaction.client?.lastName) {
    searchArray[1] = transaction.client?.lastName;
  }
  if (transaction.clientSecondary?.firstName) {
    searchArray[2] = transaction.clientSecondary?.firstName;
  }
  if (transaction.clientSecondary?.lastName) {
    searchArray[3] = transaction.clientSecondary?.lastName;
  }
  if (transaction.address?.street) {
    searchArray[4] = transaction.address?.street.split(" ")[0]
      ? transaction.address?.street.split(" ")[0]
      : "";
    searchArray[5] = transaction.address?.street.split(" ")[1]
      ? transaction.address?.street.split(" ")[1]
      : "";
    searchArray[6] = transaction.address?.street.split(" ")[2]
      ? transaction.address?.street.split(" ")[2]
      : "";
  }
  if (transaction.address?.city) {
    searchArray[7] = transaction.address?.city;
  }
  if (transaction.address?.zipcode) {
    searchArray[8] = transaction.address?.zipcode;
  }
  return searchArray;
}

export function createSearchArrayClient(transaction, client) {
  let searchArray = [];
  if (transaction.searchArray) {
    searchArray = JSON.parse(JSON.stringify(transaction.searchArray));
  } else {
    searchArray = ["", "", "", "", "", "", "", "", "", ""];
  }
  if (client.firstName) {
    searchArray[0] = client?.firstName;
  }
  if (client.lastName) {
    searchArray[1] = client?.lastName;
  }
  return searchArray;
}

export function createSearchArrayClientSecondary(transaction, clientSecondary) {
  let searchArray = [];
  if (transaction.searchArray) {
    searchArray = JSON.parse(JSON.stringify(transaction.searchArray));
  } else {
    searchArray = ["", "", "", "", "", "", "", "", "", ""];
  }
  if (clientSecondary.firstName) {
    searchArray[2] = clientSecondary.firstName;
  }
  if (clientSecondary.lastName) {
    searchArray[3] = clientSecondary.lastName;
  }
  return searchArray;
}

export function createInitialSigningDetails(transaction, form) {
  let signingDetails = {};
  if (form.annotsInProgressSuggested) {
    form.annotsInProgressSuggested.forEach((annot) => {
      signingDetails[_.camelCase(annot.signerRole)] = {
        sentOut: false,
        signed: false,
        sentOutAt: null,
        signedAt: null,
        role: annot.signerRole,
      };
    });
  }
  return signingDetails;
}

export function createSuggestedAnnots(transaction, form) {
  let suggestedAnnots = [];
  if (transaction.agentRepresents === "Buyer") {
    if (
      transaction.clientSecondaryExists &&
      transaction.clientSecondary?.lastName
    ) {
      suggestedAnnots = form.annotsInProgressSuggested?.filter(
        (annot) =>
          annot.signerRole === "Buyer" ||
          annot.signerRole === "Buyer 2" ||
          annot.signerRole === "Title Company"
      );
    } else {
      suggestedAnnots = form.annotsInProgressSuggested?.filter(
        (annot) =>
          annot.signerRole === "Buyer" || annot.signerRole === "Title Company"
      );
    }
  } else if (transaction.agentRepresents === "Seller") {
    if (
      transaction.clientSecondaryExists &&
      transaction.clientSecondary?.lastName
    ) {
      suggestedAnnots = form.annotsInProgressSuggested?.filter(
        (annot) =>
          annot.signerRole === "Seller" ||
          annot.signerRole === "Seller 2" ||
          annot.signerRole === "Title Company"
      );
    } else {
      suggestedAnnots = form.annotsInProgressSuggested?.filter(
        (annot) =>
          annot.signerRole === "Seller" || annot.signerRole === "Title Company"
      );
    }
  }
  return suggestedAnnots;
}

export function createSignerStatusMessage(signer) {
  let message = "";
  if (!signer.sentOut) {
    message = "Not sent out";
    if (signer.firstName && signer.lastName) {
      message = message + " to " + signer.firstName + " " + signer.lastName;
    }
    message = message + " for signing.";
  } else if (signer.signed) {
    message = "Signed";
    if (signer.firstName && signer.lastName) {
      message = message + " by " + signer.firstName + " " + signer.lastName;
    }
    if (signer.signedAt?.toDate()) {
      message =
        message +
        " " +
        formatDistance(signer.signedAt?.toDate(), new Date(), {
          addSuffix: true,
        });
    }
  } else {
    message = "Sent out";
    if (signer.firstName && signer.lastName) {
      message = message + " to " + signer.firstName + " " + signer.lastName;
    }
    if (signer.sentOutAt?.toDate()) {
      message =
        message +
        " for signing " +
        formatDistance(signer.sentOutAt?.toDate(), new Date(), {
          addSuffix: true,
        });
    }
  }
  return message;
}

export function createFormList(transaction) {
  console.log("THIS DOESNT RUN ANYMORE LIZ");
  let formList = [];
  if (transaction.agentRepresents === "Buyer") {
    formList = [
      { title: "Client Contracts", type: "header" },
      {
        title: "Definitions of Working Relationships (for Buyers)",
        type: "form",
        required: true,
      },
      { title: "Exclusive Right to Buy", type: "form", required: true },
    ];
  } else if (transaction.agentRepresents === "Seller") {
    formList = [
      { title: "Client Contracts", type: "header" },
      {
        title: "Definitions of Working Relationships (for Sellers)",
        type: "form",
        required: true,
      },
      { title: "Exclusive Right To Sell", type: "form", required: true },
    ];
  }
  const formsCommon = [
    { title: "Sales Contracts", type: "header" },
    {
      title: "Contract to Buy and Sell, Residential",
      type: "form",
      required: true,
    },
    {
      title: "Contract to Buy and Sell, Income-Residential",
      type: "form",
      required: false,
    },
    { title: "Addenda to Contracts", type: "header" },
    {
      title: "Post-Closing Occupancy Agreement",
      type: "form",
      required: false,
    },
    {
      title: "Source of Water Addendum to Contract to Buy & Sell Real Estate",
      type: "form",
      required: false,
    },
    { title: "Disclosures", type: "header" },
    {
      title: "CB Affiliated Business Arrangement Disclosure",
      type: "form",
      required: true,
    },
    { title: "CB Mold Disclosure", type: "form", required: true },
    { title: "CB Radon Disclosure", type: "form", required: true },
    { title: "CB Wire Fraud Disclosure", type: "form", required: true },
    {
      title: "Lead Based Paint Disclosure (Sales)",
      type: "form",
      required: true,
    },
    {
      title: "Sellers Property disclosure (Residential)",
      type: "form",
      required: true,
    },
    { title: "Square Footage Disclosure", type: "form", required: true },
    { title: "Notice of Documents", type: "header" },
    { title: "Inspection Objection Notice", type: "form", required: false },
    { title: "Notice to Terminate", type: "form", required: false },
    { title: "Title Related Objection Notice", type: "form", required: false },
    { title: "Counterproposal", type: "header" },
    { title: "Counterproposal", type: "form", required: false },
    { title: "Amend/Extend", type: "header" },
    { title: "Amend-Extend Contract", type: "form", required: false },
    { title: "Optional Forms", type: "header" },
    { title: "Personal Property Agreement", type: "form", required: false },
  ];
  formList = [...formList, ...formsCommon];
  return formList;
}

export function createFormsToAddDisplayLists(
  transaction,
  forms,
  formsAlreadyAdded,
  currentUserProfile
) {
  const formsSorted = _.sortBy(forms, "title");
  let formsSuggestedTitles = [];
  let formsSuggested = [];
  let allOtherForms = [];
  if (transaction.agentRepresents === "Buyer") {
    if (transaction.state === "No Offer") {
      formsSuggestedTitles = [
        "Personal Property Agreement",
        "Post-Closing Occupancy Agreement",
      ];
    } else if (transaction.state === "Signed Offer") {
      formsSuggestedTitles = [
        "Amend-Extend Contract 2023",
        "Counterproposal 2023",
        "Inspection Objection Notice 2023",
        "Title Related Objection Notice",
      ];
    }
  } else if (transaction.agentRepresents === "Seller") {
    if (transaction.state === "No Offer") {
      formsSuggestedTitles = ["Amend-Extend Contract 2023"];
    } else if (transaction.state === "Signed Offer") {
      formsSuggestedTitles = [
        "Amend-Extend Contract  2023",
        "Counterproposal 2023",
      ];
    }
  }
  let formTitlesToRemove = [];
  formsSorted.forEach((form) => {
    if (
      formsSuggestedTitles.includes(form.title) &&
      !formsAlreadyAdded.includes(form.title)
    ) {
      formsSuggested = [...formsSuggested, form];
    }
    if (!form.dontShowFormInAddDocumentModal) {
      if (!form.showBuyerAgent && !form.showSellerAgent) {
        allOtherForms = [...allOtherForms, form];
      } else if (
        transaction.agentRepresents === "Buyer" &&
        form.showBuyerAgent
      ) {
        allOtherForms = [...allOtherForms, form];
      } else if (
        transaction.agentRepresents === "Seller" &&
        form.showSellerAgent
      ) {
        allOtherForms = [...allOtherForms, form];
      }
      if (form.isBrokerageForm && currentUserProfile.role !== "tc") {
        if (!currentUserProfile.brokerageForms) {
          allOtherForms = allOtherForms.filter(
            (item) => item.title !== form.title
          );
        } else if (form.brokerage !== currentUserProfile.brokerageForms) {
          allOtherForms = allOtherForms.filter(
            (item) => item.title !== form.title
          );
        } else if (form.brokerageReplacesParentFormTitle) {
          formTitlesToRemove.push(form.brokerageReplacesParentFormTitle);
        }

        if (form.isSubBrokerageForm) {
          if (!currentUserProfile.subBrokerage) {
            allOtherForms = allOtherForms.filter(
              (item) => item.title !== form.title
            );
          } else if (form.subBrokerage !== currentUserProfile.subBrokerage) {
            allOtherForms = allOtherForms.filter(
              (item) => item.title !== form.title
            );
          } else if (form.subBrokerageReplacesParentFormTitle) {
            formTitlesToRemove.push(form.subBrokerageReplacesParentFormTitle);
          }
        }
      }
    }
  });
  formTitlesToRemove.forEach((formTitle) => {
    allOtherForms = allOtherForms.filter((item) => item.title !== formTitle);
  });

  return { formsSuggested: formsSuggested, allOtherForms: allOtherForms };
}

export function updateFormListForBrokerage(forms, currentUserProfile) {
  const formsSorted = _.sortBy(forms, "title");
  let allOtherForms = formsSorted;
  let formTitlesToRemove = [];
  formsSorted.forEach((form) => {
    if (form.isBrokerageForm && currentUserProfile.role !== "tc") {
      if (
        !currentUserProfile.brokerageForms ||
        form.brokerage !== currentUserProfile.brokerageForms
      ) {
        allOtherForms = allOtherForms.filter(
          (item) => item.title !== form.title
        );
      } else if (form.brokerageReplacesParentFormTitle) {
        formTitlesToRemove.push(form.brokerageReplacesParentFormTitle);
      }
      if (form.isSubBrokerageForm) {
        if (
          !currentUserProfile.subBrokerage ||
          form.subBrokerage !== currentUserProfile.subBrokerage
        ) {
          allOtherForms = allOtherForms.filter(
            (item) => item.title !== form.title
          );
        } else if (form.subBrokerageReplacesParentFormTitle) {
          formTitlesToRemove.push(form.subBrokerageReplacesParentFormTitle);
        }
      }
    }
  });
  formTitlesToRemove.forEach((formTitle) => {
    allOtherForms = allOtherForms.filter((item) => item.title !== formTitle);
  });
  return allOtherForms;
}

export async function autoAddFormsToTransaction(
  transaction,
  forms,
  currentUserProfile
) {
  let formsToAdd = [];
  if (transaction.agentRepresents === "Buyer") {
    formsToAdd = forms.filter(
      (form) => form.autoAddToNewTransaction && form.showBuyerAgent
    );
  } else if (transaction.agentRepresents === "Seller") {
    formsToAdd = forms.filter(
      (form) => form.autoAddToNewTransaction && form.showSellerAgent
    );
  }
  addFormsToTransaction(transaction, formsToAdd);
}

export function createSignerListArray(signingDetails) {
  let signerList = [];
  if (signingDetails) {
    Object.entries(signingDetails).forEach(([key, value]) => {
      signerList = [...signerList, value];
    });
  }
  return _.sortBy(signerList, "index");
}

export function createSignerList2(signers, agentRepresents) {
  let mySide = [];
  let otherSide = [];
  if (signers) {
    Object.entries(signers).forEach(([key, value]) => {
      if (agentRepresents === "Buyer") {
        if (key === "Buyer" || key === "Buyer 2") {
          mySide = [...mySide, value];
        }
        if (key === "Seller" || key === "Seller 2" || key === "Listing Agent") {
          otherSide = [...otherSide, value];
        }
      } else {
        if (key === "Buyer" || key === "Buyer 2" || key === "Buyer Agent") {
          otherSide = [...otherSide, value];
        }
        if (key === "Seller" || key === "Seller 2") {
          mySide = [...mySide, value];
        }
      }
    });
    mySide = _.sortBy(mySide, "index");
    otherSide = _.sortBy(otherSide, "index");
  }
  return { mySide, otherSide };
}

export function docSigningComplete(signingDetails) {
  let signingComplete = true;
  Object.values(signingDetails).forEach((value, index) => {
    if (value.sentOut === true && value.signed === false) {
      signingComplete = false;
    }
  });
  return signingComplete;
}

export function docSigningVerificationEnabled(document, currentUserProfile) {
  if (
    document.hasSigningVerification &&
    currentUserProfile.hasSigningVerification
  ) {
    return true;
  } else {
    return false;
  }
}

export function taskIsActive(status) {
  return status === "In Progress";
}

export function personExists(people, email) {
  if (email) {
    let personFound = people.filter((person) => person.email === email)[0];
    return personFound?.email ? true : false;
  }
  return false;
}

export function createClientRoleOptions(client, transaction) {
  let clientRoleOptions = [];
  if (transaction.agentRepresents === "Buyer") {
    if (client?.role === "Buyer") {
      clientRoleOptions = [{ key: "buyer", text: "Buyer", value: "Buyer" }];
    } else {
      clientRoleOptions = [
        { key: "buyer2", text: "Buyer 2", value: "Buyer 2" },
      ];
    }
  } else {
    if (client?.role === "Seller") {
      clientRoleOptions = [{ key: "seller", text: "Seller", value: "Seller" }];
    } else {
      clientRoleOptions = [
        { key: "seller2", text: "Seller 2", value: "Seller 2" },
      ];
    }
  }
  return clientRoleOptions;
}

export function createRestrictedEmails(
  currentUserProfile,
  transaction,
  allParties,
  existingParty = null
) {
  let restrictedEmails = [currentUserProfile.email];
  let agentEmail = transaction.agentEmail
    ? transaction.agentEmail
    : transaction.agentProfile?.email
    ? transaction.agentProfile?.email
    : "";
  if (agentEmail) {
    restrictedEmails = [...restrictedEmails, agentEmail];
  }
  if (allParties && allParties.length !== 0) {
    allParties.forEach((party) => {
      if (
        party.email &&
        (!existingParty ||
          !existingParty?.email ||
          party.email !== existingParty?.email)
      ) {
        restrictedEmails = [...restrictedEmails, party.email];
      }
    });
  }
  return restrictedEmails;
}

export function createPeopleOptions(people, type) {
  let peopleOptions = [{ key: "", value: "", text: "" }];
  if (people) {
    const peopleOfType = _.orderBy(
      people.filter((person) => person.type === type),
      [(person) => person.firstName.toLowerCase()],
      ["asc"]
    );
    if (peopleOfType?.length > 0) {
      peopleOfType.forEach((person, index) => {
        let fullName = "";
        let valueName = "";
        if (person.firstName) {
          fullName = person.firstName + " " + person.lastName;
          valueName = person.firstName + "|" + person.lastName;
        } else {
          fullName = person.lastName;
          valueName = fullName;
        }
        peopleOptions.push({
          key: fullName + "-" + index,
          value: valueName,
          text: fullName,
        });
      });
    }
  }
  return peopleOptions;
}

export function filterClientsById(clients, id) {
  return clients.filter((client) => client.id === id)[0];
}

export function filterPeopleByName(people, firstName, lastName) {
  return people.filter(
    (person) => person.firstName === firstName && person.lastName === lastName
  )[0];
}

export function filterRecentTrans(transactions) {
  return _.orderBy(transactions, "updatedAt", "desc").slice(0, 4);
}

export function filterUpcomingTasks(tasks) {
  return _.orderBy(_.orderBy(tasks, "end", "asc").slice(0, 7), "end");
}

export function filterUpcomingDocs(docs) {
  return _.orderBy(
    _.orderBy(docs, "signingDueAt", "desc").slice(0, 7),
    "signingDueAt"
  );
}

export function truncateText(text, count) {
  return text.slice(0, count) + (text.length > count ? "..." : "");
}

export function dateFormatDeadline(date) {
  // const locales = {
  //     "en-US": require("date-fns/locale/en-US"),
  //   };

  // const formatRelativeLocale = {
  //     lastWeek: "EEEE', 'MMM do 'at' h:mm a",
  //     yesterday: "'Yesterday at' h:mm a",
  //     today: "'Today at' h:mm a",
  //     tomorrow: "'Tomorrow at' h:mm a",
  //     nextWeek: "EEEE', 'MMM do 'at' h:mm a",
  //     other: "EEEE', 'MMM do 'at' h:mm a", // Difference: Add time to the date
  //   };
  const formatRelativeLocale = {
    lastWeek: "EEEE', 'MMM do",
    yesterday: "'Yesterday'",
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: "EEEE', 'MMM do",
    other: "EEEE', 'MMM do", // Difference: Add time to the date
  };
  const locale = {
    ...enUsLocale,
    formatRelative: (token) => formatRelativeLocale[token],
  };
  return formatRelative(new Date(date), new Date(), { locale });
}

export function dateFormatDeadlineWithTime(date) {
  const formatRelativeLocale = {
    lastWeek: "EEEE', 'MMM do 'at' h:mm a",
    yesterday: "'Yesterday at' h:mm a",
    today: "'Today at' h:mm a",
    tomorrow: "'Tomorrow at' h:mm a",
    nextWeek: "EEEE', 'MMM do 'at' h:mm a",
    other: "EEEE', 'MMM do 'at' h:mm a", // Difference: Add time to the date
  };
  const locale = {
    ...enUsLocale,
    formatRelative: (token) => formatRelativeLocale[token],
  };
  return formatRelative(new Date(date), new Date(), { locale });
}

export function searchFilter(items, searchTerms) {
  return searchTerms
    ? items.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchTerms.toLowerCase());
      })
    : items;
}

export function createDocSignersList(signingDetails, parties) {
  Object.keys(signingDetails).forEach((key, index) => {
    const party = parties.filter((party) => _.camelCase(party.role) === key)[0];
    if (!party) {
      delete signingDetails[key];
    }
  });
  return signingDetails;
}

export function createOtherSideRole(agentRepresents) {
  return agentRepresents === "Buyer" ? "Seller" : "Buyer";
}

export function convertPhoneToLink(phone) {
  return phone.replace(/[^+\d]+/g, "");
}

export function convertAddressToLink(address) {
  return address.replace(/ /g, "+");
}

export function convertInitials(person) {
  let initials = "";
  if (person.firstName) {
    initials = initials + person.firstName.charAt(0) + " ";
  }
  if (person.lastName) {
    initials = initials + person.lastName.charAt(0);
  }
  return initials;
}

export function convertFullName(person) {
  let fullName = "";
  if (person.firstName) {
    fullName = fullName + person.firstName + " ";
  }
  if (person.middleName) {
    fullName = fullName + person.middleName + " ";
  }
  if (person.lastName) {
    fullName = fullName + person.lastName;
  }
  return fullName;
}

export function convertFirstLastName(person) {
  let name = "";
  if (person.firstName) {
    name = name + person.firstName + " ";
  }
  if (person.lastName) {
    name = name + person.lastName;
  }
  return name;
}

export function convertFirstLastNameUrl(person) {
  let name = "";
  if (person.firstName) {
    name = name + person.firstName + "%20";
  }
  if (person.lastName) {
    name = name + person.lastName;
  }
  return name;
}

export function convertPartyLegalName(party) {
  let legalName = "";
  if (party.isTrust && party.entityName) {
    legalName = party.entityName;
  } else {
    legalName = convertFullName(party);
  }
  return legalName;
}

export function convertTransactionLegalNames(parties) {
  let legalNames = {
    buyer: null,
    seller: null,
  };
  const buyer = parties.filter((party) => party.role === "Buyer")[0];
  const buyer2 = parties.filter((party) => party.role === "Buyer 2")[0];
  const seller = parties.filter((party) => party.role === "Seller")[0];
  const seller2 = parties.filter((party) => party.role === "Seller 2")[0];
  if (buyer) {
    if (buyer.isTrust && buyer.entityName) {
      legalNames["buyer"] = buyer.entityName;
    } else {
      legalNames["buyer"] = convertFullName(buyer);
    }
  }
  if (buyer2) {
    if (buyer2.isTrust && buyer2.entityName) {
      if (buyer2.entityName !== buyer?.entityName) {
        legalNames["buyer"] = legalNames["buyer"] + ", " + buyer2.entityName;
      }
    } else {
      legalNames["buyer"] =
        legalNames["buyer"] + ", " + convertFullName(buyer2);
    }
  }
  if (seller) {
    if (seller.isTrust && seller.entityName) {
      legalNames["seller"] = seller.entityName;
    } else {
      legalNames["seller"] = convertFullName(seller);
    }
  }
  if (seller2) {
    if (seller2.isTrust && seller2.entityName) {
      if (seller2.entityName !== seller?.entityName) {
        legalNames["seller"] = legalNames["seller"] + ", " + seller2.entityName;
      }
    } else {
      legalNames["seller"] =
        legalNames["seller"] + ", " + convertFullName(seller2);
    }
  }
  return legalNames;
}

export function convertAddressFull(address) {
  let addressFull = "";
  if (address?.street) {
    addressFull = addressFull + address.street;
  }
  if (address?.unit) {
    addressFull = addressFull + " " + address.unit;
  }
  if (address?.city) {
    addressFull = addressFull + ", " + address.city;
  }
  if (address?.state) {
    addressFull = addressFull + ", " + address.state;
  }
  if (address?.zipcode) {
    addressFull = addressFull + " " + address.zipcode;
  }
  return addressFull;
}

export function convertAddressStreet(address) {
  return address.street;
}
export function convertAddressStreetAndUnit(address) {
  let addressAndUnit = "";
  if (address.unit) {
    addressAndUnit = address.street + " " + address.unit;
  } else {
    addressAndUnit = address.street;
  }
  return addressAndUnit;
}

export function convertAddressCity(address) {
  return address.city;
}

export function convertAddressState(address) {
  return address.state;
}

export function convertAddressZipcode(address) {
  return address.zipcode;
}

export function convertAddressCityStateZipcode(address) {
  if (address?.city || address?.state || address?.zipcode) {
    return address.city + ", " + address.state + " " + address.zipcode;
  } else {
    return "";
  }
}

export function createClientLegalName(transaction) {
  let peopleNames = convertFullName(transaction.client);
  if (transaction.clientSecondary?.lastName) {
    peopleNames =
      peopleNames + " & " + convertFullName(transaction.clientSecondary);
  }
  const legalName = transaction.client?.isTrust
    ? transaction.client?.entityName
    : peopleNames;
  return legalName;
}

export function partyIsClient(transaction, party) {
  if (transaction.agentRepresents === "Buyer") {
    return party ? party.role === "Buyer" || party.role === "Buyer 2" : false;
  } else if (transaction.agentRepresents === "Seller") {
    return party ? party.role === "Seller" || party.role === "Seller 2" : false;
  }
}

export function partyIsFirstClient(role) {
  return role ? role === "Buyer" || role === "Seller" : false;
}

export function partyIsBuyerOrSeller(role) {
  return role
    ? role === "Buyer" ||
        role === "Buyer 2" ||
        role === "Seller" ||
        role === "Seller 2" ||
        role === "Client"
    : false;
}

export function partyIsAgent(role) {
  return role
    ? role === "Listing Agent" ||
        role === "Listing Agent 2" ||
        role === "Buyer Agent" ||
        role === "Buyer Agent 2" ||
        role === "Agent"
    : false;
}

export function partyIsMyTc(role) {
  return role
    ? role === "Transaction Coordinator" || role === "TC (Mine)"
    : false;
}

export function partyIsMyCoAgent(role) {
  return role ? role === "CoAgent" || role === "CoAgent (Mine)" : false;
}

export function partyHasCompany(role) {
  return role
    ? role === "Buyer Lender" ||
        role === "Escrow Officer" ||
        role === "Title Company" ||
        role === "Seller Attorney" ||
        role === "Buyer Attorney" ||
        role === "Lender" ||
        role === "Escrow" ||
        role === "Title" ||
        role === "Attorney"
    : false;
}

export function partyIsOtherAgent(transaction, party) {
  if (transaction?.agentRepresents === "Buyer") {
    return party?.role === "Listing Agent";
  } else if (transaction?.agentRepresents === "Seller") {
    return party?.role === "Buyer Agent";
  }
}

export function createPartyPrimaryFromTrans(transaction, transactionId) {
  return {
    firstName: transaction.clientFirstName || "",
    lastName: transaction.clientLastName || "",
    email: transaction.clientEmail || "",
    phone: transaction.clientPhone || "",
    isTrust: transaction.clientIsTrust || "",
    entityName: transaction.entityName || "",
    role: transaction.agentRepresents || "",
    transactionId: transactionId || "",
  };
}

export function createPartySecondaryFromTrans(transaction, transactionId) {
  return {
    firstName: transaction.clientSecondaryFirstName || "",
    lastName: transaction.clientSecondaryLastName || "",
    email: transaction.clientSecondaryEmail || "",
    phone: transaction.clientSecondaryPhone || "",
    isTrust: transaction.clientSecondaryIsTrust || "",
    entityName: transaction.entitySecondaryName || "",
    role: transaction.agentRepresents || "",
    transactionId: transactionId || "",
  };
}

export function createPartyBrokerFromTrans(
  transaction,
  transactionId,
  currentUserProfile
) {
  return {
    firstName: currentUserProfile.firstName || "",
    lastName: currentUserProfile.lastName || "",
    email: currentUserProfile.email || "",
    phone: currentUserProfile.phone || "",
    fax: currentUserProfile.fax || "",
    brokerLicenseNumber: currentUserProfile.brokerLicenseNumber || "",
    brokerageLicenseNumber: currentUserProfile.brokerageLicenseNumber || "",
    brokerageName: currentUserProfile.brokerageName || "",
    role:
      transaction.agentRepresents === "Buyer" ? "Buyer Agent" : "Listing Agent",
    transactionId: transactionId || "",
  };
}

export function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}

export function convertHoursToGreeting() {
  const hours = new Date().getHours();
  if (hours < 12) {
    return "Morning";
  } else if (hours <= 18) {
    return "Afternoon";
  } else {
    return "Evening";
  }
}

export function convertSearchTerms(searchTerms) {
  searchTerms = searchTerms.replace(/,/g, "");
  return searchTerms === ""
    ? null
    : searchTerms
        .split(" ")
        .slice(0, 9)
        .map(
          (searchTerm) =>
            searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1)
        );
}

export function getFileExtension(filename) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function extractPdfDeadlinesFromTable(pdfTextArray) {
  const DEADLINES = [
    "Alternative Earnest Money Deadline",
    "Record Title Deadline (and Tax Certificate)",
    "Record Title Objection Deadline",
    "Off-Record Title Deadline",
    "Off-Record Title Objection Deadline",
    "Title Resolution Deadline",
    "Third Party Right to Purchase/Approve Deadline",
    "Association Documents Termination Deadline",
    "Seller’s Property Disclosure Deadline",
    "Lead Based Paint Disclosure Deadline",
    "New Loan Application Deadline",
    "New Loan Terms Deadline",
    "New Loan Availability Deadline",
    "Buyer’s Credit Information Deadline",
    "Disapproval of Buyer’s Credit Information Deadline",
    "Existing Loan Deadline",
    "Existing Loan Termination Deadline",
    "Loan Transfer Approval Deadline",
    "Seller or Private Financing Deadline",
    "Appraisal Deadline",
    "Appraisal Objection Deadline",
    "Appraisal Resolution Deadline",
    "New ILC or New Survey Deadline",
    "New ILC or New Survey Objection Deadline",
    "New ILC or New Survey Resolution Deadline",
    "Water Rights Examination Deadline",
    "Mineral Rights Examination Deadline",
    "Inspection Termination Deadline",
    "Inspection Objection Deadline",
    "Inspection Resolution Deadline",
    "Property Insurance Termination Deadline",
    "Due Diligence Documents Delivery",
    "Due Diligence Documents Objection Deadline",
    "Due Diligence Documents Resolution Deadline",
    "Conditional Sale Deadline",
    "Lead-Based Paint Termination Deadline",
    "Closing Date",
    "Possession Date",
    "Acceptance Deadline Date",
  ];

  const deadlineKeyValuePairs = {};
  console.log(pdfTextArray);

  DEADLINES.forEach((deadlineTitle) => {
    console.log("looking for", deadlineTitle);
    let done = false;
    let pdfIndex = -1;
    while (
      !done &&
      ++pdfIndex < pdfTextArray?.length - 1 &&
      pdfTextArray[pdfIndex]
    ) {
      let pdfToken = pdfTextArray[pdfIndex].join();
      if (
        pdfToken?.includes(deadlineTitle) ||
        pdfTextArray[pdfIndex]
          .join("")
          .includes(deadlineTitle.replace(/\s/g, ""))
      ) {
        console.log("found deadline  ", deadlineTitle);
        let possibleDateDeadline = "";
        // now EITHER the date is in the next [pdfIndex+1][0] OR it is [pdfIndex][x] or DocuSign [pdfIndex][next 3 for mmm, dd,yyyy] so go find x
        //

        //
        // now find the index in the array where the date is
        if (pdfTextArray[pdfIndex].length === 1) {
          // date must be the next array line
          // example: 178: ['Title Resolution Deadline']
          //          179: ['3/11/2024']
          // from a CTM ->Docusign -> CTM pdf
          possibleDateDeadline = pdfTextArray[pdfIndex + 1][0].trim();
        } else {
          // Deadline will be in the same array
          //let indexOfDeadlineTitle = pdfTextArray[pdfIndex].indexOf(deadlineTitle); cant use indexOf as there may be whitespace on token
          let indexOfDeadlineTitle = -1;
          while (
            ++indexOfDeadlineTitle < pdfTextArray[pdfIndex].length &&
            !pdfTextArray[pdfIndex][indexOfDeadlineTitle].includes(
              deadlineTitle
            )
          ); // do nothing, go find index MUST keep the semi-colon here
          if (
            indexOfDeadlineTitle >= 0 &&
            indexOfDeadlineTitle + 1 < pdfTextArray[pdfIndex].length
          ) {
            // example: ['42', '§ 27', 'Acceptance Deadline Date', '10/14/2023', 'Saturday']
            possibleDateDeadline =
              pdfTextArray[pdfIndex][indexOfDeadlineTitle + 1].trim();
          } else if (
            pdfTextArray[pdfIndex]
              .join("")
              .includes(deadlineTitle.split(" ").join(""))
          ) {
            let lastWord = deadlineTitle.split(" ").at(-1);
            let indexOfLastWord = pdfTextArray[pdfIndex].indexOf(lastWord); // will not work if word is broken up
            let indexOfPossibleDate = indexOfLastWord + 1;
            if (indexOfPossibleDate < pdfTextArray[pdfIndex].length) {
              if (
                !isNaN(
                  pdfTextArray[pdfIndex][indexOfPossibleDate].substring(0, 1)
                )
              ) {
                // example: 126: (8) ['13', '§ ', '5', 'New', 'Loan', 'Application', 'Deadline', '9/19/2023']
                // 127: (10) ['14', '§ ', '5', 'Ne', 'w', 'Loan', 'Terms', 'Deadline', '9/19/2023', '9/22/2023']
                // 128: (9) ['15', '§ ', '5', 'New', 'Loan', 'Availability', 'Deadline', '10/10/2023', '9/22/2023']
                // 129: (9) ['16', '§ ', '5', 'Buyer’s', 'Credit', 'Information', 'Deadline', '10/10/2023', 'n/a']
                possibleDateDeadline =
                  pdfTextArray[pdfIndex][indexOfPossibleDate];
              } else if (
                indexOfPossibleDate + 2 <
                pdfTextArray[pdfIndex].length
              ) {
                // DocuSign:::128: (9) ['26', '§', '10.3', 'Inspection', 'Objection', 'Deadline', 'May', '23,', '2019']
                // DocuSign:::129: (9) ['27', '§', '10.3', 'Inspection', 'Termination', 'Deadline', 'May', '27,', '2019']
                // DocuSign:::130: (9) ['28', '§', '10.3', 'Inspection', 'Resolution', 'Deadline', 'May', '27,', '2019']
                possibleDateDeadline =
                  pdfTextArray[pdfIndex][indexOfPossibleDate] +
                  " " +
                  pdfTextArray[pdfIndex][indexOfPossibleDate + 1] +
                  " " +
                  pdfTextArray[pdfIndex][indexOfPossibleDate + 2];
              }
            }
            // hmmm...
            // 120: (8) ['9', '§ ', '7', 'Association', 'Documents', 'De', 'adline', '10/2/2023']
            else
              console.log(
                "deadline found, but hard to parse. May have 2 dates listed or broken wording: ",
                pdfTextArray[pdfIndex].join()
              );
          }
        }

        let timestamp = Date.parse(possibleDateDeadline);
        console.log(" tried date ", possibleDateDeadline);
        if (!isNaN(timestamp)) {
          deadlineKeyValuePairs[deadlineTitle] = possibleDateDeadline;
          console.log("found deadline: ", possibleDateDeadline);
        }
        done = true;
      }
    }
  });

  // pdfTextArray returns an array of arrays.
  // It reads the PDF and when a PDF has the characters in separate text fields
  //   (like "The" broken into a textfield with "T" and a textfield with "he")
  // Transactioner uploaded PDFs has each array has an array of individual words or "T","he", etc
  //     146 (8) ['30', '§ ', '10', 'Inspection', 'Termin', 'ation', 'Deadline', '10/4/2023']
  // Flat PDFs may have the deadline phrase as the only thing in the array at [0] and the date in the next array
  // So, try this: for each array in the array, squish that array together then check against a squished DEADLINE
  //   Since we need to squish, let's work line by line on the pdf as outer loop
  /*pdfTextArray.forEach((arrOfTokens) => {
    let textline = arrOfTokens.join('');
    let key = '';
    let deadline = '';
    
    
    
    arr.forEach((token) => {
      let tokentrim = token.trim();
      if (key) { debugger
      let timestamp = Date.parse(tokentrim);
        if (!isNaN(timestamp)) {
          deadline = tokentrim;
          return;
        }
      }
      else if (DEADLINES.includes(tokentrim)) { debugger
        key = tokentrim;
      }
    }) // end foreach
    if (key && deadline) {
      deadlineKeyValuePairs[key] = deadline;
    }
  }
  );
  */

  /*
  deadlineTableArray.forEach((arr) => {
    let key = '';
    let deadline = '';
    arr.forEach((token) => {
      let tokentrim = token.trim();
      if (key) { debugger
      let timestamp = Date.parse(tokentrim);
        if (!isNaN(timestamp)) {
          deadline = tokentrim;
          return;
        }
      }
      else if (DEADLINES.includes(tokentrim)) { debugger
        key = tokentrim;
      }
    }) // end foreach
    if (key && deadline) {
      deadlineKeyValuePairs[key] = deadline;
    }
  }
  ); */

  return deadlineKeyValuePairs;
}

export default function stateNameToAbbreviation(stateInput) {
  const states = [
    ["Alabama", "AL"],
    ["Alaska", "AK"],
    ["American Samoa", "AS"],
    ["Arizona", "AZ"],
    ["Arkansas", "AR"],
    ["Armed Forces Americas", "AA"],
    ["Armed Forces Europe", "AE"],
    ["Armed Forces Pacific", "AP"],
    ["California", "CA"],
    ["Colorado", "CO"],
    ["Connecticut", "CT"],
    ["Delaware", "DE"],
    ["District Of Columbia", "DC"],
    ["Florida", "FL"],
    ["Georgia", "GA"],
    ["Guam", "GU"],
    ["Hawaii", "HI"],
    ["Idaho", "ID"],
    ["Illinois", "IL"],
    ["Indiana", "IN"],
    ["Iowa", "IA"],
    ["Kansas", "KS"],
    ["Kentucky", "KY"],
    ["Louisiana", "LA"],
    ["Maine", "ME"],
    ["Marshall Islands", "MH"],
    ["Maryland", "MD"],
    ["Massachusetts", "MA"],
    ["Michigan", "MI"],
    ["Minnesota", "MN"],
    ["Mississippi", "MS"],
    ["Missouri", "MO"],
    ["Montana", "MT"],
    ["Nebraska", "NE"],
    ["Nevada", "NV"],
    ["New Hampshire", "NH"],
    ["New Jersey", "NJ"],
    ["New Mexico", "NM"],
    ["New York", "NY"],
    ["North Carolina", "NC"],
    ["North Dakota", "ND"],
    ["Northern Mariana Islands", "NP"],
    ["Ohio", "OH"],
    ["Oklahoma", "OK"],
    ["Oregon", "OR"],
    ["Pennsylvania", "PA"],
    ["Puerto Rico", "PR"],
    ["Rhode Island", "RI"],
    ["South Carolina", "SC"],
    ["South Dakota", "SD"],
    ["Tennessee", "TN"],
    ["Texas", "TX"],
    ["US Virgin Islands", "VI"],
    ["Utah", "UT"],
    ["Vermont", "VT"],
    ["Virginia", "VA"],
    ["Washington", "WA"],
    ["West Virginia", "WV"],
    ["Wisconsin", "WI"],
    ["Wyoming", "WY"],
  ];
  for (let i = 0; i < states.length; i++) {
    if (states[i][0] === stateInput) {
      return states[i][1];
    }
  }
}
