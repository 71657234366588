export function addendumBackUpContract() {
return [   //2024 Release 2024-02-18 22:45:25
{
   page: 0,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 139.32,
   left: 55.44,
   width: 504.12,
   height: 10.08
}
,
{
   page: 0,
   name: "Except as provided by this Addendum neither party is required to perform under the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 225.25,
   left: 244.32,
   width: 172.32,
   height: 10.08
}
,
{
   page: 0,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 224.76,
   left: 440.64,
   width: 42.00,
   height: 10.08
}
,
{
   page: 0,
   name: "the BackUp Contract terminates and the earnest money will be refunded to Buyer  Seller must", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 265.68,
   left: 336.48,
   width: 168.84,
   height: 10.08
}
,
{
   page: 0,
   name: "20_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 265.68,
   left: 525.36,
   width: 31.08,
   height: 10.08
}
] }
