import React from "react";
import { useMediaQuery } from "react-responsive";
import NavBarLarge from "./NavBarLarge";
import NavBarMobile from "./NavBarMobile";

export default function NavBar() {
  const isMobile = useMediaQuery({ query: "(min-width:768px)" });

  return <>{isMobile ? <NavBarLarge /> : <NavBarMobile />}</>;
}
