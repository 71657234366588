import React from "react";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/common/modals/modalSlice";
import { useMediaQuery } from "react-responsive";
import DeadlineTemplatesList from "./deadlineTemplatesComponents/DeadlineTemplatesList";

export default function DeadlineTemplatesDashboard() {
  const dispatch = useDispatch();
  const { deadlineTemplates } = useSelector((state) => state.deadlineTemplates);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <div className="main-page-wrapper">
      <Grid centered stackable className="small bottom margin">
        <Grid.Column width={10}>
          <Header color="blue" as="h2">
            Deadline Templates
          </Header>
          <p color="grey">
            Create templates for dates and deadlines of the Contract to Buy &
            Sell form.
          </p>
          <Segment clearing>
            <Grid.Column width={10} className="large bottom margin">
              <div className={isMobile ? null : "float-right"}>
                <Button
                  to="#"
                  icon="plus"
                  size="small"
                  className={isMobile ? "fluid" : null}
                  onClick={() =>
                    dispatch(
                      openModal({
                        modalType: "DeadlineTemplatesEdit",
                        modalProps: { deadlineTemplate: null },
                      })
                    )
                  }
                  content="Add Template"
                  color="blue"
                />
              </div>
            </Grid.Column>
            <br />
            <Divider hidden className="large vertical margin" />
            <Grid.Column width={10}>
              {deadlineTemplates?.length > 0 ? (
                <DeadlineTemplatesList
                  deadlineTemplates={deadlineTemplates}
                  column={deadlineTemplates.column}
                  direction={deadlineTemplates.direction}
                  actionButtonMode="regular"
                />
              ) : (
                <p>There are no deadline templates.</p>
              )}
            </Grid.Column>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}
