export function addendumMandatoryMembershipinOwnersAssociation() {
return [   //2024 Release 2024-02-18 23:03:40
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 121.56,
   left: 36.0,
   width: 540.24,
   height: 12.84
}
,
{
   page: 0,
   name: "Name of Property Owners Association Association and Phone Number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 146.92,
   left: 36.0,
   width: 540.24,
   height: 12.84
}
,
{
   page: 0,
   name: "1 Within", 
   isText: false,
   type: "checkbox",
   top: 228.8,
   left: 43.44,
   width: 11.52,
   height: 7.8
}
,
{
   page: 0,
   name: "the Subdivision Information to the Buyer If Seller delivers the Subdivision Information Buyer may terminate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 226.909,
   left: 102.36,
   width: 71.64,
   height: 11.531
}
,
{
   page: 0,
   name: "undefined", 
   isText: false,
   type: "checkbox",
   top: 284.84,
   left: 43.44,
   width: 11.52,
   height: 7.80
}
,
{
   page: 0,
   name: "copy of the Subdivision Information to the Seller", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 284.093,
   left: 101.27,
   width: 72.24,
   height: 10.877
}
,
{
   page: 0,
   name: "3Buyer has received and approved the Subdivision Information before signing the contract Buyer", 
   isText: false,
   type: "checkbox",
   top: 349.76,
   left: 43.44,
   width: 11.52,
   height: 7.80
}
,
{
   page: 0,
   name: "does", 
   isText: false,
   type: "checkbox",
   top: 349.596,
   left: 533.08,
   width: 8.88,
   height: 7.964
}
,
{
   page: 0,
   name: "does not require an updated resale certificate If Buyer requires an updated resale certificate Seller at", 
   isText: false,
   type: "checkbox",
   top: 358.251,
   left: 69.88,
   width: 11.4982,
   height: 7.309
}
,
{
   page: 0,
   name: "4Buyer does not require delivery of the Subdivision Information", 
   isText: false,
   type: "checkbox",
   top: 399.92,
   left: 43.44,
   width: 11.52,
   height: 7.80
}
,
{
   page: 0,
   name: "D DEPOSITS FOR RESERVES Buyer shall pay any deposits for reserves required at closing by the Association", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 490.682,
   left: 365.018,
   width: 89.520,
   height: 10.658
}
,
{
   page: 0,
   name: "Buyer", 
   isText: false,
   type: "checkbox",
   top: 557.685,
   left: 226.771,
   width: 10.869,
   height: 10.115
}
,
{
   page: 0,
   name: "Seller shall pay the Title Company the cost of obtaining the", 
   isText: false,
   type: "checkbox",
   top: 558.628,
   left: 273.228,
   width: 12.524,
   height: 9.172
}
] }
