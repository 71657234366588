export function cBDPAffiliatedBusinesses2022() {
    return [   //2023
    {
      page: 0,
      name: "logo",
      isText: false,
      type: "logo",
      top: 28.673,
      left: 53,
      width: 253,
      height: 43.2
   }
   ,
   {
       page: 0,
       name: "Common property address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 78.24,
       left: 181.08,
       width: 340.32,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Print Seller Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 153.48,
       left: 72,
       width: 190.2,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Print Buyer Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 153.48,
       left: 316.8,
       width: 200.16,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Print Seller Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 174.48,
       left: 72,
       width: 190.2,
       height: 9.84
    }
    ,
    {
       page: 0,
       name: "Print Buyer Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 174.48,
       left: 316.8,
       width: 200.16,
       height: 9.84
    }
    ] }
    