import { Formik, Form } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import {
  addTaskToDb,
  updateTaskInDb,
} from "../../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";
import MyDateInput from "../../../app/common/form/MyDateInput";
import { useMediaQuery } from "react-responsive";
import MyTextArea from "../../../app/common/form/MyTextArea";

export default function TaskForm({ task }) {
  const dispatch = useDispatch();
  const { transaction } = useSelector((state) => state.transaction);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  let initialValues = task
    ? task
    : {
        category: "Task",
        title: "",
        description: "",
        start: null,
        end: null,
        assignedTo: "Me",
      };

  const validationSchema = Yup.object({
    title: Yup.string().required("You must provide a name"),
  });

  return (
    <ModalWrapper size="tiny">
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                values.start = values.end;
                task
                  ? await updateTaskInDb(task.id, values)
                  : await addTaskToDb(values, transaction);
                setSubmitting(false);
                task
                  ? toast.success("Deadline successfully updated")
                  : toast.success("Deadline successfully created");
                dispatch(
                  closeModal({
                    modalType: "TaskForm",
                  })
                );
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty }) => (
              <Form className="ui form" autoComplete="off">
                <Header size="large" color="blue">
                  {task ? `Edit Task` : `Add Task`}
                </Header>
                <Divider />
                <h4 className="zero top margin tiny bottom margin">Name</h4>
                <MyTextInput name="title" data-test="task-name-input" />
                <h4 className="zero top margin tiny bottom margin">
                  Description (optional)
                </h4>
                <MyTextArea rows={4} name="description" />
                <h4 className="zero top margin tiny bottom margin">
                  Deadline (optional)
                </h4>
                <MyDateInput
                  name="end"
                  placeholder="End date"
                  dateFormat="MMMM d, yyyy"
                  data-test="task-date-input"
                />
                <Divider className="medium top margin medium bottom margin" />
                <div>
                  <Button
                    primary
                    loading={isSubmitting}
                    disabled={!dirty || isSubmitting}
                    type="submit"
                    floated={isMobile ? null : "right"}
                    content="Submit"
                    className={isMobile ? "fluid medium bottom margin" : null}
                    data-test="task-submit-btn"
                  />
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    onClick={() =>
                      dispatch(
                        closeModal({
                          modalType: "TaskForm",
                        })
                      )
                    }
                    floated={isMobile ? null : "right"}
                    content="Cancel"
                    className={isMobile ? "fluid medium bottom margin" : null}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </ModalWrapper>
  );
}
