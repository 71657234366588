import React from "react";
import TransactionUnderContractListItem from "./TransactionUnderContractListItem";
import { Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { sortTransUnderContract } from "../../transactionSlice";

export default function TransactionUnderContractList({
  transactions,
  column,
  direction,
}) {
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);

  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell></Table.HeaderCell>
            {currentUserProfile?.role !== "agent" && (
              <>
                <Table.HeaderCell
                sorted={column === "agnetFirstName" ? direction : null}
                onClick={() => dispatch(sortTransUnderContract("agentFirstName"))}
                >
                  Agent First Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "agentLastName" ? direction : null}
                  onClick={() => dispatch(sortTransUnderContract("agentLastName"))}
                >
                  Agent Last Name
                </Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell
              sorted={column === "title" ? direction : null}
              onClick={() => dispatch(sortTransUnderContract("title"))}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "client.firstName" ? direction : null}
              onClick={() =>
                dispatch(sortTransUnderContract("client.firstName"))
              }
            >
              Client&nbsp;&nbsp;(Primary)
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "client.secondaryFirstName" ? direction : null}
              onClick={() =>
                dispatch(sortTransUnderContract("client.secondaryFirstName"))
              }
            >
              Client&nbsp;&nbsp;(Secondary)
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "agentRepresents" ? direction : null}
              onClick={() =>
                dispatch(sortTransUnderContract("agentRepresents"))
              }
            >
              Representing
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "address" ? direction : null}
              onClick={() => dispatch(sortTransUnderContract("address"))}
            >
              Address
            </Table.HeaderCell>
            <Table.HeaderCell>Close Date</Table.HeaderCell>
            {/* <Table.HeaderCell>Other Agent/Broker</Table.HeaderCell> */}
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {transactions &&
            transactions.length !== 0 &&
            transactions.map((transaction) => (
              <TransactionUnderContractListItem
                transaction={transaction}
                key={transaction.id}
              />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
