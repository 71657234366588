import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  taskTemplates: [],
  taskTemplate: {},
};

const taskTemplatesSlice = createSlice({
  name: "taskTemplates",
  initialState,
  reducers: {
    fetchTaskTemplates(state, action) {
      state.taskTemplates = _.sortBy(action.payload, "title");
    },
    fetchTaskTemplate(state, action) {
      state.taskTemplate = action.payload;
    },
    updateTaskTemplateTitle(state, action) {
      state.taskTemplate.title = action.payload;
    },
    addItemToTaskTemplate(state, action) {
      state.taskTemplate.items = [...state.taskTemplate.items, action.payload];
    },
    updateItemInTaskTemplate(state, action) {
      state.taskTemplate.items.splice(
        action.payload.index,
        1,
        action.payload.item
      );
    },
    deleteItemInTaskTemplate(state, action) {
      state.taskTemplate.items.splice(action.payload, 1);
    },
  },
});

export const {
  fetchTaskTemplates,
  fetchTaskTemplate,
  updateTaskTemplateTitle,
  addItemToTaskTemplate,
  updateItemInTaskTemplate,
  deleteItemInTaskTemplate,
} = taskTemplatesSlice.actions;

export default taskTemplatesSlice.reducer;
