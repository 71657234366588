import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import {
  getStateFormByTitleFromDb,
  updateStateFormInDb,
  updateDocInDb,
} from "../../../../app/firestore/firestoreService";
import { openModal } from "../../../../app/common/modals/modalSlice";
import { changePageScalePrepare } from "../../../../app/annots/annotSlice";
import { uploadBlobToStorage } from "../../../../app/firestore/firebaseService";
import { addAnnotsAndFlattenPdf } from "../../../../app/pdfLib/pdfLib";
import _ from "lodash";

export default function DocPrepareActionButtons() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { annots, signerListDisplay, selectedSigner, pageScalePrepare } =
    useSelector((state) => state.annot);
  const { doc } = useSelector((state) => state.doc);
  const { transaction } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [processing, setProcessing] = useState(false);
  const pageScale = pageScalePrepare;

  function closeDocument() {
    navigate(`/transactions/${doc.transactionId}/documents`);
  }

  async function saveAnnots() {
    try {
      await updateDocInDb(doc.id, {
        annotsInProgress: annots,
        signerListInProgress: signerListDisplay,
        selectedSignerInProgress: selectedSigner,
      });
      toast.success("Document successfully saved");
    } catch (error) {
      toast.error(error.message);
    }
  }
  // DO NOT DELETE
  async function saveDataToForm() {
    const title = doc.title.split(".")[0];
    const form = await getStateFormByTitleFromDb(title, currentUserProfile);
    if (form) {
      await updateStateFormInDb(
        form.id,
        {
          annotsInProgressSuggested: annots,
        },
        currentUserProfile
      );
      toast.success("Document successfully saved");
    }
  }

  async function sendForSigning() {
    await updateDocInDb(doc.id, {
      annotsInProgress: annots,
      signerListInProgress: signerListDisplay,
      selectedSignerInProgress: selectedSigner,
    });
    dispatch(
      openModal({
        modalType: "SendForSigning",
      })
    );
  }

  async function saveAndFlatten() {
    if (annots?.length !== 0) {
      setProcessing(true);
      try {
        let newDoc = _.cloneDeep(doc);
        newDoc.annotsInProgress = annots;
        newDoc.signerListInProgress = signerListDisplay;
        newDoc.selectedSignerInProgress = selectedSigner;
        const annotsToAddToPdf =
          newDoc.annotsInProgress &&
          newDoc.annotsInProgress.filter((annot) => annot.agentsField === true);
        const pdfBytes = await addAnnotsAndFlattenPdf(
          annotsToAddToPdf,
          newDoc,
          currentUserProfile
        );
        await uploadBlobToStorage(doc.docRef, pdfBytes);
        // Remove agent annots since they are burned
        let nonAgentAnnots = annots.filter(
          (annot) => annot.agentsField !== true
        );
        updateDocInDb(doc.id, {
          annotsInProgress: nonAgentAnnots,
          signerListInProgress: signerListDisplay,
          selectedSignerInProgress: selectedSigner,
        });
        setProcessing(false);
        navigate(`/transactions/${transaction.id}/documents`);
        toast.success("Document successfully saved");
      } catch (error) {
        toast.error(error.message);
      }
    }
  }

  function handleZoomIn() {
    if (pageScalePrepare < 4) {
      dispatch(changePageScalePrepare(pageScale * 1.25));
    }
  }

  function handleZoomOut() {
    if (pageScalePrepare > 0.5) {
      dispatch(changePageScalePrepare(pageScale / 1.25));
    }
  }

  return (
    <div
      className="large horizontal padding background-white"
      style={{
        top: "auto",
        bottom: "0",
        position: "fixed",
        width: "100%",
        height: "80px",
        borderTop: "1px solid #e8e8e8",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          float: "left",
        }}
      >
        <Icon
          size="large"
          color="grey"
          name="minus circle"
          onClick={() => handleZoomOut()}
          style={{ cursor: "pointer" }}
        />
        <p
          className="zero bottom margin"
          style={{
            marginLeft: "3px",
            marginRight: "6px",
            fontWeight: "bold",
          }}
        >
          {Math.round(pageScalePrepare * 100)}%
        </p>
        <Icon
          size="large"
          color="grey"
          name="plus circle"
          onClick={() => handleZoomIn()}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div
        className="large right margin"
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          float: "right",
        }}
      >
        <Button
          onClick={() => closeDocument()}
          accessibilitylabel="Close"
          content="Close"
          style={{ marginRight: "10px" }}
        />

        {!doc.pdfBurnVersion &&
        doc.statusPrevious &&
        doc.statusPrevious === "Complete" ? (
          <Button
            primary
            onClick={() => saveAndFlatten()}
            loading={processing}
            accessibilitylabel="Send for Signing"
            content="Save & Close"
            style={{ marginRight: "10px" }}
          />
        ) : (
          <Button
            primary
            onClick={() => saveAnnots()}
            accessibilitylabel="Save Draft"
            content="Save"
            style={{ marginRight: "10px" }}
          />
        )}
        <Button
          className="dark-blue"
          onClick={() => sendForSigning()}
          accessibilitylabel="Send for Signing"
          content="Send for Signing"
        />
        {/* This button is for processing CO forms */}
        {currentUserProfile.isAdmin && (
          <Button
            color="red"
            onClick={() => saveDataToForm()}
            accessibilitylabel="Send for Signing"
            content="Save Data to Form"
          />
        )}
      </div>
    </div>
  );
}
