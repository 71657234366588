import React from "react";
import { Table, Popup, Icon } from "semantic-ui-react";
import TaskActionButtons from "../../taskComponents/TaskActionButtons";

export default function TaskTransActiveWithoutDateListItem({ task }) {
  return (
    <Table.Row
      key={task.id}
      className={(task.status === "Complete" && "background-green") || ""}
    >
      <Table.Cell>
        {task.title}{" "}
        {task.description && (
          <Popup
            trigger={
              <Icon
                name="info"
                color="blue"
                circular
                size="small"
                style={{ marginLeft: "3px", marginBottom: "3px" }}
              />
            }
          >
            {task.description}
          </Popup>
        )}
      </Table.Cell>
      <Table.Cell>
        <TaskActionButtons task={task} />
      </Table.Cell>
    </Table.Row>
  );
}
