export function contracttoBuyandSellCommercial2024() {
return [   //2024 Release 2024-08-03 10:29:09
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "Property with No Residences", 
   isText: false,
   type: "checkbox",
   top: 196.68,
   left: 224.76,
   width: 11.28,
   height: 11.28
}
,
{
   page: 0,
   name: "Property with ResidencesResidential Addendum Attached", 
   isText: false,
   type: "checkbox",
   top: 212.76,
   left: 149.4,
   width: 11.28,
   height: 11.28
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 243.84,
   left: 460.92,
   width: 109.92,
   height: 16.44
}
,
{
   page: 0,
   name: "Buyer", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 355.2,
   left: 114,
   width: 420,
   height: 16.44
}
,
{
   page: 0,
   name: "Joint Tenants", 
   isText: false,
   type: "checkbox",
   top: 389.64,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Tenants In Common", 
   isText: false,
   type: "checkbox",
   top: 403.8,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 417.72,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 414.48,
   left: 110.52,
   width: 450.00,
   height: 13.08
}
,
{
   page: 0,
   name: "Seller", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 446.64,
   left: 112.32,
   width: 420.00,
   height: 16.44
}
,
{
   page: 0,
   name: "county", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 476.88,
   left: 425.04,
   width: 140.04,
   height: 16.44
}
,
{
   page: 0,
   name: "known as", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 683.28,
   left: 121.08,
   width: 439.92,
   height: 16.44
}
,
{
   page: 0,
   name: "legalDesc", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 508.584,
   left: 51.0548,
   width: 521.1292,
   height: 175.819
}
,
{
   page: 1,
   name: "remote controls If checked the following are owned by the Seller and", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 126.48,
   left: 193.2,
   width: 64.92,
   height: 12.12
}
,
{
   page: 1,
   name: "Leased items should be listed", 
   isText: false,
   type: "checkbox",
   top: 155.4,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Water Softeners", 
   isText: false,
   type: "checkbox",
   top: 155.4,
   left: 143.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Security Systems", 
   isText: false,
   type: "checkbox",
   top: 155.4,
   left: 242.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Satellite Systems including satellite dishes", 
   isText: false,
   type: "checkbox",
   top: 155.4,
   left: 338.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "under 106 Encumbered Inclusion Documents and Buyers receipt of written approval by such lender before Closing If", 
   isText: false,
   type: "checkbox",
   top: 607.56,
   left: 98.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Will", 
   isText: false,
   type: "checkbox",
   top: 607.56,
   left: 143.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "undefined_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 687.36,
   left: 66,
   width: 495,
   height: 12.12
}
,
{
   page: 1,
   name: "undefined_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 714,
   left: 66,
   width: 495,
   height: 12.12
}
,
{
   page: 1,
   name: "Text100", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 277.529,
   left: 60.2184,
   width: 521.1286,
   height: 158.801
}
,
{
   page: 1,
   name: "Text101", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 468.657,
   left: 58.2548,
   width: 523.7472,
   height: 137.855
}
,
{
   page: 2,
   name: "Will_2", 
   isText: false,
   type: "checkbox",
   top: 402.6,
   left: 98.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will Not assume Sellers debt and obligations under such leases for the Leased Items subject to", 
   isText: false,
   type: "checkbox",
   top: 402.6,
   left: 143.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Solar Power Plan If the box is checked Seller has entered into a solar power purchase agreement regardless of", 
   isText: false,
   type: "checkbox",
   top: 446.52,
   left: 99,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will_3", 
   isText: false,
   type: "checkbox",
   top: 472.68,
   left: 375.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will Not assume Sellers", 
   isText: false,
   type: "checkbox",
   top: 472.68,
   left: 420.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Deeded Water Rights The following legally described water rights", 
   isText: false,
   type: "checkbox",
   top: 660.6,
   left: 103.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "deed at Closing", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 728.3237,
   left: 337.32,
   width: 165.00,
   height: 12.4363
}
,
{
   page: 2,
   name: "Text102", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 68.073,
   left: 60.2184,
   width: 512.6196,
   height: 134.582
}
,
{
   page: 2,
   name: "Text103", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 263.783,
   left: 59.5639,
   width: 510.0021,
   height: 137.200
}
,
{
   page: 2,
   name: "Text104", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 526.911,
   left: 51.7093,
   width: 524.4017,
   height: 118.873
}
,
{
   page: 2,
   name: "Text105", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 671.567,
   left: 62.1821,
   width: 513.9289,
   height: 57.3452
}
,
{
   page: 3,
   name: "Other Rights Relating to Water The following rights relating to water not included in  271 273 and", 
   isText: false,
   type: "checkbox",
   top: 56.76,
   left: 103.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Well Rights Seller agrees to supply required information to Buyer about the well Buyer understands that if the", 
   isText: false,
   type: "checkbox",
   top: 156.6,
   left: 103.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "undefined_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 233.28,
   left: 151.8,
   width: 315.0,
   height: 12.36
}
,
{
   page: 3,
   name: "Water Stock The water stock to be transferred at Closing are as follows", 
   isText: false,
   type: "checkbox",
   top: 253.08,
   left: 103.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Text106", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 80.509,
   left: 61.5275,
   width: 510.0025,
   height: 75.018
}
,
{
   page: 3,
   name: "Text107", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 263.783,
   left: 61.5275,
   width: 511.3115,
   height: 64.546
}
,
{
   page: 3,
   name: "Time of Day Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 463.389,
   left: 388.56,
   width: 181.48,
   height: 11.640
}
,
{
   page: 3,
   name: "DateAlternative Earnest Money Deadline", 
   fontSize: 9,
   type: "date",
   top: 476.109,
   left: 329.0,
   width: 56.24,
   height: 11.400
}
,
{
   page: 3,
   name: "Alternative Earnest Money Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 476.109,
   left: 388.56,
   width: 181.48,
   height: 11.400
}
,
{
   page: 3,
   name: "DateRecord Title Deadline and Tax Certificate", 
   fontSize: 9,
   type: "date",
   top: 499.389,
   left: 329.0,
   width: 56.24,
   height: 12.120
}
,
{
   page: 3,
   name: "Record Title Deadline and Tax Certificate_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 499.389,
   left: 388.56,
   width: 181.48,
   height: 12.120
}
,
{
   page: 3,
   name: "DateRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 512.589,
   left: 329.0,
   width: 56.24,
   height: 12.120
}
,
{
   page: 3,
   name: "Record Title Objection Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 512.589,
   left: 388.56,
   width: 181.48,
   height: 12.120
}
,
{
   page: 3,
   name: "DateOffRecord Title Deadline", 
   fontSize: 9,
   type: "date",
   top: 525.789,
   left: 329.0,
   width: 56.24,
   height: 12.120
}
,
{
   page: 3,
   name: "OffRecord Title Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 525.789,
   left: 388.56,
   width: 181.48,
   height: 12.120
}
,
{
   page: 3,
   name: "DateOffRecord Title Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 538.989,
   left: 329.0,
   width: 56.24,
   height: 12.120
}
,
{
   page: 3,
   name: "OffRecord Title Objection Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 538.989,
   left: 388.56,
   width: 181.48,
   height: 12.120
}
,
{
   page: 3,
   name: "DateTitle Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 552.314,
   left: 329.0,
   width: 56.24,
   height: 11.880
}
,
{
   page: 3,
   name: "Title Resolution Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 552.314,
   left: 388.56,
   width: 181.48,
   height: 11.880
}
,
{
   page: 3,
   name: "DateThird Party Right to PurchaseApprove Deadline", 
   fontSize: 9,
   type: "date",
   top: 565.274,
   left: 329.0,
   width: 56.24,
   height: 12.120
}
,
{
   page: 3,
   name: "Third Party Right to PurchaseApprove Deadline_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 565.274,
   left: 388.56,
   width: 181.48,
   height: 12.120
}
,
{
   page: 3,
   name: "DateAssociation Documents Deadline", 
   fontSize: 9,
   type: "date",
   top: 588.849,
   left: 329.0,
   width: 56.24,
   height: 12.120
}
,
{
   page: 3,
   name: "Disapproval of Buyers Credit Information", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 726.3654,
   left: 388.56,
   width: 181.48,
   height: 12.1200
}
,
{
   page: 3,
   name: "Association Documents", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 589.038,
   left: 388.56,
   width: 181.48,
   height: 12.120
}
,
{
   page: 3,
   name: "Association Documents Termination", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 602.238,
   left: 388.56,
   width: 181.48,
   height: 12.120
}
,
{
   page: 3,
   name: "Sellers Property Disclose", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 627.35,
   left: 388.56,
   width: 181.48,
   height: 12.12
}
,
{
   page: 3,
   name: "LeadBased Paint Disclosure", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 640.55,
   left: 388.56,
   width: 181.48,
   height: 11.88
}
,
{
   page: 3,
   name: "New Loan Application", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 674.55,
   left: 388.56,
   width: 181.48,
   height: 12.12
}
,
{
   page: 3,
   name: "New Loan Terms", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 687.75,
   left: 388.56,
   width: 181.48,
   height: 12.1202
}
,
{
   page: 3,
   name: "New Loan Availability", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 700.9502,
   left: 388.56,
   width: 181.48,
   height: 12.1200
}
,
{
   page: 3,
   name: "Buyers Credit Information", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 714.1502,
   left: 388.56,
   width: 181.48,
   height: 11.8800
}
,
{
   page: 3,
   name: "DateAssociation Documents Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 602.049,
   left: 329.0,
   width: 56.24,
   height: 12.120
}
,
{
   page: 3,
   name: "DateSellers Property Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 627.161,
   left: 329.0,
   width: 56.24,
   height: 12.120
}
,
{
   page: 3,
   name: "DateLeadBased Paint Disclosure Deadline", 
   fontSize: 9,
   type: "date",
   top: 641.361,
   left: 329.0,
   width: 56.24,
   height: 11.880
}
,
{
   page: 3,
   name: "DateNew Loan Application Deadline", 
   fontSize: 9,
   type: "date",
   top: 674.361,
   left: 329.0,
   width: 56.24,
   height: 12.120
}
,
{
   page: 3,
   name: "DateNew Loan Terms Deadline", 
   fontSize: 9,
   type: "date",
   top: 687.561,
   left: 329.0,
   width: 56.24,
   height: 12.1202
}
,
{
   page: 3,
   name: "DateNew Loan Availability Deadline", 
   fontSize: 9,
   type: "date",
   top: 700.7612,
   left: 329.0,
   width: 56.24,
   height: 12.1200
}
,
{
   page: 3,
   name: "DateBuyers Credit Information Deadline", 
   fontSize: 9,
   type: "date",
   top: 713.9612,
   left: 329.0,
   width: 56.24,
   height: 11.8800
}
,
{
   page: 3,
   name: "DateDisapproval of Buyers Credit Information Deadline", 
   fontSize: 9,
   type: "date",
   top: 726.1764,
   left: 329.0,
   width: 56.24,
   height: 12.1200
}
,
{
   page: 4,
   name: "47Row1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 509.52,
   left: 51.96,
   width: 18.84,
   height: 11.04
}
,
{
   page: 4,
   name: " 27Row1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 509.52,
   left: 71.88,
   width: 34.92,
   height: 11.04
}
,
{
   page: 4,
   name: "Acceptance Deadline TimeRow1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 509.52,
   left: 107.88,
   width: 219.48,
   height: 11.04
}
,
{
   page: 4,
   name: "Closing and PossessionRow6", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 509.52,
   left: 328.44,
   width: 57.48,
   height: 11.04
}
,
{
   page: 4,
   name: "Closing and PossessionRow6_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 509.52,
   left: 387,
   width: 183.72,
   height: 11.04
}
,
{
   page: 4,
   name: "47Row2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 521.52,
   left: 51.96,
   width: 18.84,
   height: 11.04
}
,
{
   page: 4,
   name: " 27Row2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 521.52,
   left: 71.88,
   width: 34.92,
   height: 11.04
}
,
{
   page: 4,
   name: "Acceptance Deadline TimeRow2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 521.52,
   left: 107.88,
   width: 219.48,
   height: 11.04
}
,
{
   page: 4,
   name: "Closing and PossessionRow7", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 521.52,
   left: 328.44,
   width: 57.48,
   height: 11.04
}
,
{
   page: 4,
   name: "Closing and PossessionRow7_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 521.52,
   left: 387,
   width: 183.72,
   height: 11.04
}
,
{
   page: 4,
   name: "Existing Loan", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 57.817,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Existing Loan Termination", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 71.017,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Loan Transfer Approval", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 84.217,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Seller or Private Financing", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 97.417,
   left: 389.105,
   width: 180.240,
   height: 10.880
}
,
{
   page: 4,
   name: "Appraisal DL", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 121.417,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Appraisal Objection", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 134.617,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Appraisal Resolution", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 147.817,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "New ILC or New Survey", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 172.977,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "New ILC or New Survey Objection", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 186.177,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "New ILC or New Survey Resolution", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 199.377,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateExisting Loan Deadline", 
   fontSize: 9,
   type: "date",
   top: 57.817,
   left: 328.982,
   width: 56.239,
   height: 11.120
}
,
{
   page: 4,
   name: "DateExisting Loan Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 71.017,
   left: 328.982,
   width: 56.239,
   height: 11.120
}
,
{
   page: 4,
   name: "DateLoan Transfer Approval Deadline", 
   fontSize: 9,
   type: "date",
   top: 84.217,
   left: 328.982,
   width: 56.239,
   height: 11.120
}
,
{
   page: 4,
   name: "DateSeller or Private Financing Deadline", 
   fontSize: 9,
   type: "date",
   top: 97.417,
   left: 328.982,
   width: 56.239,
   height: 10.880
}
,
{
   page: 4,
   name: "DateAppraisal Deadline", 
   fontSize: 9,
   type: "date",
   top: 121.417,
   left: 328.982,
   width: 56.239,
   height: 11.120
}
,
{
   page: 4,
   name: "DateAppraisal Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 134.617,
   left: 328.982,
   width: 56.239,
   height: 11.120
}
,
{
   page: 4,
   name: "DateAppraisal Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 147.817,
   left: 328.982,
   width: 56.239,
   height: 11.120
}
,
{
   page: 4,
   name: "DateNew ILC or New Survey Deadline", 
   fontSize: 9,
   type: "date",
   top: 172.977,
   left: 328.982,
   width: 56.239,
   height: 11.120
}
,
{
   page: 4,
   name: "DateNew ILC or New Survey Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 186.177,
   left: 328.982,
   width: 56.239,
   height: 11.120
}
,
{
   page: 4,
   name: "DateNew ILC or New Survey Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 199.377,
   left: 328.982,
   width: 56.239,
   height: 11.120
}
,
{
   page: 4,
   name: "Water Rights Examination", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 225.978,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Mineral Rights Examination", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 240.178,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Inspection Termination", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 252.817,
   left: 389.105,
   width: 180.240,
   height: 10.880
}
,
{
   page: 4,
   name: "Inspection Objection", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 265.777,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Inspection Resolution", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 278.977,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Property Insurance Termination", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 293.177,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Due Diligence Documents Delivery", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 305.377,
   left: 389.105,
   width: 180.240,
   height: 10.880
}
,
{
   page: 4,
   name: "Due Diligence Documents Objection", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 318.337,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Due Diligence Documents Resolution", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 331.537,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Environmental Inspection Termination", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 344.737,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "ADA Evaluation Termination", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 357.937,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Conditional Sale", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 371.137,
   left: 389.105,
   width: 180.240,
   height: 10.880
}
,
{
   page: 4,
   name: "LeadBased Paint Termination", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 384.097,
   left: 389.105,
   width: 180.240,
   height: 11.120
}
,
{
   page: 4,
   name: "Estoppel Statements", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 405.297,
   left: 389.105,
   width: 180.240,
   height: 12.120
}
,
{
   page: 4,
   name: "Estoppel Statements Termination", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 418.497,
   left: 389.105,
   width: 180.240,
   height: 12.120
}
,
{
   page: 4,
   name: "DateWater Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 225.789,
   left: 328.547,
   width: 56.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateMineral Rights Examination Deadline", 
   fontSize: 9,
   type: "date",
   top: 238.989,
   left: 328.547,
   width: 56.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateInspection Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 252.628,
   left: 329.091,
   width: 56.240,
   height: 10.880
}
,
{
   page: 4,
   name: "DateInspection Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 265.588,
   left: 329.091,
   width: 56.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateInspection Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 278.788,
   left: 329.091,
   width: 56.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateProperty Insurance Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 291.988,
   left: 329.091,
   width: 56.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateDue Diligence Documents Delivery Deadline", 
   fontSize: 9,
   type: "date",
   top: 305.188,
   left: 329.091,
   width: 56.240,
   height: 10.880
}
,
{
   page: 4,
   name: "DateDue Diligence Documents Objection Deadline", 
   fontSize: 9,
   type: "date",
   top: 318.148,
   left: 329.091,
   width: 56.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateDue Diligence Documents Resolution Deadline", 
   fontSize: 9,
   type: "date",
   top: 331.348,
   left: 329.091,
   width: 56.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateEnvironmental Inspection Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 344.548,
   left: 329.091,
   width: 56.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateADA Evaluation Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 357.748,
   left: 329.091,
   width: 56.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateConditional Sale Deadline", 
   fontSize: 9,
   type: "date",
   top: 370.948,
   left: 329.091,
   width: 56.240,
   height: 10.880
}
,
{
   page: 4,
   name: "DateLeadBased Paint Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 383.908,
   left: 329.091,
   width: 56.240,
   height: 11.120
}
,
{
   page: 4,
   name: "DateEstoppel Statements Deadline", 
   fontSize: 9,
   type: "date",
   top: 405.108,
   left: 329.091,
   width: 56.240,
   height: 12.120
}
,
{
   page: 4,
   name: "DateEstoppel Statements Termination Deadline", 
   fontSize: 9,
   type: "date",
   top: 418.308,
   left: 329.091,
   width: 56.240,
   height: 12.120
}
,
{
   page: 4,
   name: "DateClosing Date", 
   fontSize: 9,
   type: "date",
   top: 443.445,
   left: 328.504,
   width: 56.240,
   height: 12.120
}
,
{
   page: 4,
   name: "DatePossession Date", 
   fontSize: 9,
   type: "date",
   top: 456.645,
   left: 328.504,
   width: 56.240,
   height: 12.120
}
,
{
   page: 4,
   name: "DateAcceptance Deadline Date", 
   fontSize: 9,
   type: "date",
   top: 482.805,
   left: 328.504,
   width: 56.240,
   height: 12.120
}
,
{
   page: 4,
   name: "Closing Date Details", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 444.243,
   left: 388.105,
   width: 181.240,
   height: 12.120
}
,
{
   page: 4,
   name: "Possession Date Details", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 457.443,
   left: 388.105,
   width: 181.240,
   height: 12.120
}
,
{
   page: 4,
   name: "Possession Time Details", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 470.643,
   left: 388.105,
   width: 181.240,
   height: 11.880
}
,
{
   page: 4,
   name: "Acceptance Deadline", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 483.656,
   left: 388.105,
   width: 181.240,
   height: 12.120
}
,
{
   page: 4,
   name: "Acceptance Deadline Time Details", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 496.803,
   left: 388.105,
   width: 181.240,
   height: 12.120
}
,
{
   page: 5,
   name: "Deadlines If any deadline falls on a Saturday Sunday or federal or Colorado state holiday Holiday such", 
   isText: false,
   type: "checkbox",
   top: 69.96,
   left: 150.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "box be checked the deadline will not be extended", 
   isText: false,
   type: "checkbox",
   top: 69.96,
   left: 107.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Amount", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 145.8,
   left: 312.24,
   width: 100.12,
   height: 12.8
}
,
{
   page: 5,
   name: "Earnest Money", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 160.68,
   left: 424.68,
   width: 91.24,
   height: 13.04
}
,
{
   page: 5,
   name: "New Loan", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 175.8,
   left: 424.68,
   width: 91.24,
   height: 13.04
}
,
{
   page: 5,
   name: "Assumption Balance", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 190.68,
   left: 424.68,
   width: 91.24,
   height: 13.04
}
,
{
   page: 5,
   name: "Private Financing", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 205.56,
   left: 424.68,
   width: 91.24,
   height: 13.04
}
,
{
   page: 5,
   name: "Seller Financing", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 220.44,
   left: 424.68,
   width: 91.24,
   height: 13.04
}
,
{
   page: 5,
   name: " 477", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 235.44,
   left: 130.44,
   width: 53.16,
   height: 16.56
}
,
{
   page: 5,
   name: "Seller Financing7", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 235.44,
   left: 185.16,
   width: 115.08,
   height: 16.56
}
,
{
   page: 5,
   name: "fill_28", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 235.44,
   left: 312.24,
   width: 100.12,
   height: 12.80
}
,
{
   page: 5,
   name: "fill_29", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 235.44,
   left: 424.68,
   width: 91.24,
   height: 13.04
}
,
{
   page: 5,
   name: " 478", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 253.2,
   left: 130.44,
   width: 53.16,
   height: 16.56
}
,
{
   page: 5,
   name: "Seller Financing8", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 253.2,
   left: 185.16,
   width: 115.08,
   height: 16.56
}
,
{
   page: 5,
   name: "fill_30", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 253.2,
   left: 424.68,
   width: 91.24,
   height: 13.04
}
,
{
   page: 5,
   name: "Cash at Closing", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 271.495,
   left: 424.68,
   width: 91.24,
   height: 13.039
}
,
{
   page: 5,
   name: "fill_31", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 254.476,
   left: 312.24,
   width: 100.12,
   height: 12.800
}
,
{
   page: 5,
   name: " 4410", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 285.72,
   left: 130.32,
   width: 53.40,
   height: 13.80
}
,
{
   page: 5,
   name: "fill_33", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 285.72,
   left: 312.24,
   width: 100.12,
   height: 12.80
}
,
{
   page: 5,
   name: "fill_34", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 285.72,
   left: 424.68,
   width: 91.24,
   height: 13.04
}
,
{
   page: 5,
   name: "Seller Concession At Closing Seller will credit to Buyer", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 319.44,
   left: 322.56,
   width: 140.04,
   height: 12.36
}
,
{
   page: 5,
   name: "Earnest Money The Earnest Money set forth in this Section in the form of a", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 402.72,
   left: 394.56,
   width: 169.92,
   height: 12.36
}
,
{
   page: 5,
   name: "will be payable to and held by", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 415.92,
   left: 189,
   width: 270,
   height: 12.36
}
,
{
   page: 6,
   name: "Does", 
   isText: false,
   type: "checkbox",
   top: 157.32,
   left: 413.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Does Not have funds", 
   isText: false,
   type: "checkbox",
   top: 157.32,
   left: 458.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Conventional", 
   isText: false,
   type: "checkbox",
   top: 297.48,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Other_2", 
   isText: false,
   type: "checkbox",
   top: 297.48,
   left: 150.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "undefined_5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 294.48,
   left: 196.8,
   width: 345.0,
   height: 12.84
}
,
{
   page: 6,
   name: "per", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 331.92,
   left: 290.4,
   width: 69.96,
   height: 12.84
}
,
{
   page: 6,
   name: "including", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 331.92,
   left: 380.64,
   width: 135.00,
   height: 12.84
}
,
{
   page: 6,
   name: "per annum and also including escrow for the following as", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 345.84,
   left: 244.8,
   width: 50.04,
   height: 12.84
}
,
{
   page: 6,
   name: "undefined_6", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 373.44,
   left: 349.56,
   width: 215.04,
   height: 12.84
}
,
{
   page: 6,
   name: "Real Estate Taxes", 
   isText: false,
   type: "checkbox",
   top: 376.44,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Property Insurance Premium and", 
   isText: false,
   type: "checkbox",
   top: 376.44,
   left: 166.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "undefined_7", 
   isText: false,
   type: "checkbox",
   top: 376.44,
   left: 336.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "At the time of assumption the new interest rate", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 387.12,
   left: 300.36,
   width: 69.96,
   height: 12.84
}
,
{
   page: 6,
   name: "per annum and the new payment will not exceed", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 401.04,
   left: 129.6,
   width: 45.0,
   height: 12.84
}
,
{
   page: 6,
   name: "per_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 401.04,
   left: 391.8,
   width: 69.96,
   height: 12.84
}
,
{
   page: 6,
   name: "principal and interest plus escrow if any If the actual principal balance of the existing loan at Closing is less than the", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 401.04,
   left: 482.04,
   width: 84.96,
   height: 12.84
}
,
{
   page: 6,
   name: "or if any other terms or provisions of the loan change Buyer has the Right to Terminate under  241 on", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 442.32,
   left: 73.44,
   width: 70.08,
   height: 12.84
}
,
{
   page: 6,
   name: "Will_4", 
   isText: false,
   type: "checkbox",
   top: 472.92,
   left: 111.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Will Not be released from liability on said loan If applicable compliance with the requirements", 
   isText: false,
   type: "checkbox",
   top: 472.92,
   left: 156.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "on or before Loan Transfer Approval Deadline", 
   isText: false,
   type: "checkbox",
   top: 486.84,
   left: 289.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "at Closing", 
   isText: false,
   type: "checkbox",
   top: 486.84,
   left: 511.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "in an amount not to exceed", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 511.44,
   left: 66,
   width: 174.96,
   height: 12.84
}
,
{
   page: 6,
   name: "undefined_8", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 511.44,
   left: 364.08,
   width: 69.96,
   height: 12.84
}
,
{
   page: 6,
   name: "days", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 649.2,
   left: 496.08,
   width: 50.04,
   height: 12.84
}
,
{
   page: 6,
   name: "Buyer_2", 
   isText: false,
   type: "checkbox",
   top: 652.2,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Seller will deliver the proposed Seller financing documents to the other party on or before", 
   isText: false,
   type: "checkbox",
   top: 652.2,
   left: 116.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "company lenders agent or all three", 
   isText: false,
   type: "checkbox",
   top: 368.76,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "Seller The cost of the Appraisal may include any and all fees paid to the appraiser appraisal management", 
   isText: false,
   type: "checkbox",
   top: 368.76,
   left: 114.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "furnish the owners title insurance policy at Sellers expense On or before Record Title Deadline Seller must furnish to", 
   isText: false,
   type: "checkbox",
   top: 519,
   left: 99,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "policy to be issued and delivered to Buyer as soon as practicable at or after Closing", 
   isText: false,
   type: "checkbox",
   top: 556.68,
   left: 197.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "to furnish the owners title insurance policy at Buyers expense On or before Record Title Deadline Buyer must furnish to", 
   isText: false,
   type: "checkbox",
   top: 586.2,
   left: 99,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Coverage OEC If the Title Commitment is to contain OEC it will commit to delete or insure over the standard exceptions", 
   isText: false,
   type: "checkbox",
   top: 640.68,
   left: 358.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Will Not contain Owners Extended", 
   isText: false,
   type: "checkbox",
   top: 640.68,
   left: 400.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Regardless of whether the Contract requires OEC the Title Insurance Commitment may not provide OEC or delete or insure", 
   isText: false,
   type: "checkbox",
   top: 720.6,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Seller_2", 
   isText: false,
   type: "checkbox",
   top: 720.6,
   left: 116.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "OneHalf by Buyer and OneHalf by Seller", 
   isText: false,
   type: "checkbox",
   top: 720.6,
   left: 165.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Other_3", 
   isText: false,
   type: "checkbox",
   top: 720.6,
   left: 371.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "undefined_9", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 718.3638,
   left: 415.92,
   width: 144.96,
   height: 12.0762
}
,
{
   page: 10,
   name: "metropolitan districts that affect the Property Tax Certificate must be delivered to Buyer on or before Record Title", 
   isText: false,
   type: "checkbox",
   top: 634.68,
   left: 268.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 10,
   name: "Buyer for the Property listing any special taxing or", 
   isText: false,
   type: "checkbox",
   top: 634.68,
   left: 317.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "New Improvement Location Certificate New ILC or", 
   isText: false,
   type: "checkbox",
   top: 117.72,
   left: 85.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "New Survey in the form of", 
   isText: false,
   type: "checkbox",
   top: 132.6,
   left: 85.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "undefined_10", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 130.32,
   left: 213.36,
   width: 349.92,
   height: 12.12
}
,
{
   page: 12,
   name: "Seller_3", 
   isText: false,
   type: "checkbox",
   top: 166.68,
   left: 271.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Buyer will order the New ILC or New Survey The New", 
   isText: false,
   type: "checkbox",
   top: 166.68,
   left: 320.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "undefined_11", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 221.04,
   left: 195.96,
   width: 364.92,
   height: 12.12
}
,
{
   page: 12,
   name: "Seller_4", 
   isText: false,
   type: "checkbox",
   top: 223.32,
   left: 86.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "Buyer or", 
   isText: false,
   type: "checkbox",
   top: 223.32,
   left: 135.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 12,
   name: "opinion of title if an Abstract of Title and", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 251.52,
   left: 237.36,
   width: 324.96,
   height: 12.12
}
,
{
   page: 13,
   name: "Text108", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 566.839,
   left: 74.6185,
   width: 510.6565,
   height: 182.3644
}
,
{
   page: 14,
   name: "All contracts relating to the operation maintenance and management of the Property", 
   isText: false,
   type: "checkbox",
   top: 239.88,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Property tax bills for the last", 
   isText: false,
   type: "checkbox",
   top: 257.16,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Asbuilt construction plans to the Property and the tenant improvements including architectural", 
   isText: false,
   type: "checkbox",
   top: 274.44,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "years", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 254.64,
   left: 272.04,
   width: 105.00,
   height: 12.36
}
,
{
   page: 14,
   name: "A list of all Inclusions to be conveyed to Buyer", 
   isText: false,
   type: "checkbox",
   top: 318.12,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Operating statements for the past", 
   isText: false,
   type: "checkbox",
   top: 335.16,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "A rent roll accurate and correct to the date of this Contract", 
   isText: false,
   type: "checkbox",
   top: 352.44,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "A schedule of any tenant improvement work Seller is obligated to complete but has not yet completed", 
   isText: false,
   type: "checkbox",
   top: 369.72,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "years_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 332.64,
   left: 290.04,
   width: 100.08,
   height: 12.36
}
,
{
   page: 14,
   name: "All insurance policies pertaining to the Property and copies of any claims which have been made for", 
   isText: false,
   type: "checkbox",
   top: 400.2,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "the past", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 410.88,
   left: 140.76,
   width: 109.92,
   height: 12.36
}
,
{
   page: 14,
   name: "Soils reports surveys and engineering reports or data pertaining to the Property if not delivered earlier", 
   isText: false,
   type: "checkbox",
   top: 430.68,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Any and all existing documentation and reports regarding Phase I and II environmental reports letters", 
   isText: false,
   type: "checkbox",
   top: 461.16,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Any Americans with Disabilities Act reports studies or surveys concerning the compliance of the", 
   isText: false,
   type: "checkbox",
   top: 531.24,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "All permits licenses and other building or use authorizations issued by any governmental authority", 
   isText: false,
   type: "checkbox",
   top: 561.72,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Other_4", 
   isText: false,
   type: "checkbox",
   top: 605.16,
   left: 139.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 14,
   name: "Text109", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 615.93,
   left: 83.1276,
   width: 484.4744,
   height: 87.4548
}
,
{
   page: 15,
   name: "Due Diligence  Environmental Buyer has the right to obtain environmental inspections of the Property including", 
   isText: false,
   type: "checkbox",
   top: 334.2,
   left: 242.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Site Assessment compliant with the most current version of the applicable ASTM E1527 standard practices for", 
   isText: false,
   type: "checkbox",
   top: 334.2,
   left: 290.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "undefined_12", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 358.32,
   left: 231,
   width: 334.92,
   height: 12.36
}
,
{
   page: 15,
   name: "Environmental Site Assessments andor", 
   isText: false,
   type: "checkbox",
   top: 374.04,
   left: 141.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Buyer Environmental Inspection", 
   isText: false,
   type: "checkbox",
   top: 374.04,
   left: 189.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "days Extended", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 402,
   left: 357.6,
   width: 95.04,
   height: 12.36
}
,
{
   page: 15,
   name: "extends beyond the Closing Date the Closing Date will be extended a like period of time In such event", 
   isText: false,
   type: "checkbox",
   top: 444.12,
   left: 69,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Notwithstanding Buyers right to obtain additional environmental inspections of the Property in this  1064 Buyer", 
   isText: false,
   type: "checkbox",
   top: 444.12,
   left: 117.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 15,
   name: "Text110", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 614.93,
   left: 66.7639,
   width: 479.8921,
   height: 12.182
}
,
{
   page: 16,
   name: "Are", 
   isText: false,
   type: "checkbox",
   top: 587.16,
   left: 408.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "Are Not executed with", 
   isText: false,
   type: "checkbox",
   top: 587.16,
   left: 448.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 16,
   name: "undefined_13", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 652.32,
   left: 63.12,
   width: 309.96,
   height: 11.88
}
,
{
   page: 17,
   name: "special warranty deed", 
   isText: false,
   type: "checkbox",
   top: 84.36,
   left: 59.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "general warranty deed", 
   isText: false,
   type: "checkbox",
   top: 84.36,
   left: 225,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "bargain and sale deed", 
   isText: false,
   type: "checkbox",
   top: 84.36,
   left: 337.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "quit claim deed", 
   isText: false,
   type: "checkbox",
   top: 84.36,
   left: 448.2,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller provided another deed is not selected must execute and deliver a good and sufficient special warranty deed to Buyer at", 
   isText: false,
   type: "checkbox",
   top: 98.04,
   left: 59.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "deed", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 96.24,
   left: 240.84,
   width: 200.04,
   height: 11.64
}
,
{
   page: 17,
   name: "Buyer_3", 
   isText: false,
   type: "checkbox",
   top: 312.12,
   left: 189,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_5", 
   isText: false,
   type: "checkbox",
   top: 312.12,
   left: 239.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_2", 
   isText: false,
   type: "checkbox",
   top: 312.12,
   left: 287.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Other_5", 
   isText: false,
   type: "checkbox",
   top: 327,
   left: 225,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "undefined_14", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 325.2,
   left: 268.92,
   width: 294.96,
   height: 11.64
}
,
{
   page: 17,
   name: "Buyer_4", 
   isText: false,
   type: "checkbox",
   top: 424.2,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_6", 
   isText: false,
   type: "checkbox",
   top: 424.2,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_3", 
   isText: false,
   type: "checkbox",
   top: 424.2,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA", 
   isText: false,
   type: "checkbox",
   top: 424.2,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_5", 
   isText: false,
   type: "checkbox",
   top: 470.28,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_7", 
   isText: false,
   type: "checkbox",
   top: 470.28,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_4", 
   isText: false,
   type: "checkbox",
   top: 470.28,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_2", 
   isText: false,
   type: "checkbox",
   top: 470.28,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_6", 
   isText: false,
   type: "checkbox",
   top: 502.44,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_8", 
   isText: false,
   type: "checkbox",
   top: 502.44,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_5", 
   isText: false,
   type: "checkbox",
   top: 502.44,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_3", 
   isText: false,
   type: "checkbox",
   top: 502.44,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_7", 
   isText: false,
   type: "checkbox",
   top: 534.6,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_9", 
   isText: false,
   type: "checkbox",
   top: 534.6,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_6", 
   isText: false,
   type: "checkbox",
   top: 534.6,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_4", 
   isText: false,
   type: "checkbox",
   top: 534.6,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_8", 
   isText: false,
   type: "checkbox",
   top: 566.52,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_10", 
   isText: false,
   type: "checkbox",
   top: 566.52,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_7", 
   isText: false,
   type: "checkbox",
   top: 566.52,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_5", 
   isText: false,
   type: "checkbox",
   top: 566.52,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_9", 
   isText: false,
   type: "checkbox",
   top: 613.56,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_11", 
   isText: false,
   type: "checkbox",
   top: 613.56,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_8", 
   isText: false,
   type: "checkbox",
   top: 613.56,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_6", 
   isText: false,
   type: "checkbox",
   top: 613.56,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "for", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 642.48,
   left: 73.44,
   width: 95.04,
   height: 11.64
}
,
{
   page: 17,
   name: "Water DistrictMunicipality", 
   isText: false,
   type: "checkbox",
   top: 661.08,
   left: 94.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Augmentation Membership", 
   isText: false,
   type: "checkbox",
   top: 677.64,
   left: 94.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Water Stock", 
   isText: false,
   type: "checkbox",
   top: 661.08,
   left: 243,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "undefined_15", 
   isText: false,
   type: "checkbox",
   top: 677.64,
   left: 243,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "undefined_16", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 675.84,
   left: 418.44,
   width: 144.96,
   height: 11.64
}
,
{
   page: 17,
   name: "Buyer_10", 
   isText: false,
   type: "checkbox",
   top: 708.12,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_12", 
   isText: false,
   type: "checkbox",
   top: 708.12,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_9", 
   isText: false,
   type: "checkbox",
   top: 708.12,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_7", 
   isText: false,
   type: "checkbox",
   top: 708.12,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Buyer_11", 
   isText: false,
   type: "checkbox",
   top: 739.56,
   left: 193.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Seller_13", 
   isText: false,
   type: "checkbox",
   top: 739.56,
   left: 243.72,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "OneHalf by Buyer and OneHalf by Seller_10", 
   isText: false,
   type: "checkbox",
   top: 739.56,
   left: 291.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "NA_8", 
   isText: false,
   type: "checkbox",
   top: 739.56,
   left: 498.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Check Box118", 
   isText: false,
   type: "checkbox",
   top: 98.04,
   left: 225.0,
   width: 10.08,
   height: 10.08
}
,
{
   page: 17,
   name: "Check Box119", 
   isText: false,
   type: "checkbox",
   top: 677.64,
   left: 404.238,
   width: 10.080,
   height: 10.08
}
,
{
   page: 18,
   name: "IS a", 
   isText: false,
   type: "checkbox",
   top: 98.76,
   left: 529.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Taxes for the Calendar Year Immediately Preceding Closing", 
   isText: false,
   type: "checkbox",
   top: 315.24,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Most Recent Mill Levy and Most Recent Assessed Valuation adjusted by any applicable qualifying seniors property", 
   isText: false,
   type: "checkbox",
   top: 329.16,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Other_6", 
   isText: false,
   type: "checkbox",
   top: 357.24,
   left: 66.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_17", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 355.44,
   left: 110.52,
   width: 444.96,
   height: 11.64
}
,
{
   page: 18,
   name: "Rents Actually Received", 
   isText: false,
   type: "checkbox",
   top: 381.48,
   left: 201,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Accrued At Closing Seller will transfer or credit to", 
   isText: false,
   type: "checkbox",
   top: 381.48,
   left: 326.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_18", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 440.16,
   left: 66,
   width: 484.92,
   height: 11.64
}
,
{
   page: 18,
   name: "Buyer_12", 
   isText: false,
   type: "checkbox",
   top: 540.6,
   left: 125.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "Seller Except however any special assessment by the Association for improvements that", 
   isText: false,
   type: "checkbox",
   top: 540.6,
   left: 175.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 18,
   name: "undefined_19", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 589.2,
   left: 66,
   width: 499.92,
   height: 11.64
}
,
{
   page: 18,
   name: "per day", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 681.12,
   left: 435.96,
   width: 84.96,
   height: 11.64
}
,
{
   page: 20,
   name: "Buyer will be paid to Seller and retained by Seller It is agreed that the Earnest Money is not a penalty and the parties agree", 
   isText: false,
   type: "checkbox",
   top: 75.48,
   left: 103.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 21,
   name: "undefined_20", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 348.48,
   left: 66,
   width: 360,
   height: 11.76
}
,
{
   page: 21,
   name: "of the Purchase Price or", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 613.68,
   left: 96.72,
   width: 35.04,
   height: 15.96
}
,
{
   page: 21,
   name: "by Seller Buyers brokerage firm is an intended thirdparty", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 613.68,
   left: 250.56,
   width: 75.00,
   height: 15.96
}
,
{
   page: 21,
   name: "beneficiary under this provision only The amount paid by Seller under this provision is in addition to any other amounts", 
   isText: false,
   type: "checkbox",
   top: 619.8,
   left: 78.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 21,
   name: "of the Purchase Price or_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 655.68,
   left: 96.72,
   width: 35.04,
   height: 15.96
}
,
{
   page: 21,
   name: "by Buyer pursuant to a separate agreement between Buyer", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 655.68,
   left: 250.56,
   width: 75.00,
   height: 15.96
}
,
{
   page: 21,
   name: "and Buyers brokerage firm This amount may be modified between Buyer and Buyers brokerage firm outside of this", 
   isText: false,
   type: "checkbox",
   top: 661.8,
   left: 78.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 21,
   name: "of the Purchase Price or_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 697.68,
   left: 96.72,
   width: 35.04,
   height: 15.96
}
,
{
   page: 21,
   name: "by a separate agreement between Buyers brokerage firm", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 697.68,
   left: 250.56,
   width: 75.00,
   height: 15.96
}
,
{
   page: 21,
   name: "and Sellers brokerage firm", 
   isText: false,
   type: "checkbox",
   top: 703.8,
   left: 78.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 22,
   name: "additionalProvisions", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 113.891,
   left: 40.582,
   width: 543.384,
   height: 531.893
}
,
{
   page: 22,
   name: "Text112", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 669.603,
   left: 50.4002,
   width: 534.2198,
   height: 79.6002
}
,
{
   page: 23,
   name: "Buyers Name", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 156.24,
   left: 110.16,
   width: 179.88,
   height: 12.12
}
,
{
   page: 23,
   name: "Buyers Name 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 156.24,
   left: 377.76,
   width: 180.00,
   height: 12.12
}
,
{
   page: 23,
   name: "1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 214.44,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 227.16,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Address 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 214.44,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Address 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 227.16,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Phone No", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 239.88,
   left: 386.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 23,
   name: "Phone No 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 239.88,
   left: 116.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 23,
   name: "Phone No 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 252.36,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Fax No", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 252.36,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Email Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 265.08,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Email Address_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 265.08,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Buyers Name 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 289.56,
   left: 117.12,
   width: 171.00,
   height: 12.12
}
,
{
   page: 23,
   name: "Buyers Name 4 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 289.56,
   left: 387.12,
   width: 166.56,
   height: 12.12
}
,
{
   page: 23,
   name: "Sellers Name", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 385.68,
   left: 109.08,
   width: 180.12,
   height: 12.12
}
,
{
   page: 23,
   name: "Sellers Name 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 385.68,
   left: 379.68,
   width: 177.00,
   height: 12.12
}
,
{
   page: 23,
   name: "1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 444.12,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 456.84,
   left: 116.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 23,
   name: "Address 1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 444.12,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Address 2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 456.84,
   left: 386.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 23,
   name: "Phone No_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 469.32,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Phone No 1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 469.32,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Phone No 2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 482.04,
   left: 116.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Fax No_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 482.04,
   left: 386.4,
   width: 171.0,
   height: 12.12
}
,
{
   page: 23,
   name: "Email Address_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 494.76,
   left: 116.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 23,
   name: "Email Address_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 494.76,
   left: 386.4,
   width: 171.0,
   height: 11.88
}
,
{
   page: 23,
   name: "Sellers Name 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 519.24,
   left: 118.44,
   width: 166.44,
   height: 12.12
}
,
{
   page: 23,
   name: "Sellers Name 4 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 519.24,
   left: 386.16,
   width: 166.44,
   height: 12.12
}
,
{
   page: 23,
   name: "Earnest Money Holder and except as provided in  23 if the Earnest Money has not already been returned following receipt of", 
   isText: false,
   type: "checkbox",
   top: 693.24,
   left: 90.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the", 
   isText: false,
   type: "checkbox",
   top: 693.24,
   left: 132.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 23,
   name: "docsNotPart", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 66.764,
   left: 50.4002,
   width: 516.5468,
   height: 52.109
}
,
{
   page: 24,
   name: "Buyers Agent", 
   isText: false,
   type: "checkbox",
   top: 74.52,
   left: 201.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "TransactionBroker in this transaction", 
   isText: false,
   type: "checkbox",
   top: 74.52,
   left: 287.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
   isText: false,
   type: "checkbox",
   top: 92.28,
   left: 56.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Brokerage Firms Name", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 181.8,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "Brokerage Firms License 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 195.24,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "Brokerage Firms License 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 208.44,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "Brokerage Firms License 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 221.64,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "Date_10", 
   fontSize: 9,
   type: "date",
   top: 248.04,
   left: 356.16,
   width: 125.04,
   height: 12.48
}
,
{
   page: 24,
   name: "Date_11", 
   fontSize: 9,
   type: "date",
   top: 282.48,
   left: 356.76,
   width: 126.00,
   height: 12.48
}
,
{
   page: 24,
   name: "1_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 307.8,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "2_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 321,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 334.2,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 347.4,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "Does_2", 
   isText: false,
   type: "checkbox",
   top: 428.04,
   left: 92.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the_2", 
   isText: false,
   type: "checkbox",
   top: 428.04,
   left: 139.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Sellers Agent", 
   isText: false,
   type: "checkbox",
   top: 504.6,
   left: 203.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "TransactionBroker in this transaction_2", 
   isText: false,
   type: "checkbox",
   top: 504.6,
   left: 289.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Customer Broker has no brokerage relationship with Seller See  A for Brokers brokerage relationship with Buyer", 
   isText: false,
   type: "checkbox",
   top: 523.8,
   left: 56.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Seller_14", 
   isText: false,
   type: "checkbox",
   top: 564.12,
   left: 89.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Buyer_13", 
   isText: false,
   type: "checkbox",
   top: 564.12,
   left: 138.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "Other_7", 
   isText: false,
   type: "checkbox",
   top: 564.12,
   left: 188.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 24,
   name: "This Brokers Acknowledgements", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 561.48,
   left: 235.68,
   width: 320.04,
   height: 12.48
}
,
{
   page: 24,
   name: "Brokerage Firms Name_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 637.8,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "Brokerage Firms License 1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 651,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "Brokerage Firms License 2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 664.44,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "Brokerage Firms License 3_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 677.64,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 24,
   name: "Date brokerSellerSigns", 
   fontSize: 9,
   type: "date",
   top: 704.04,
   left: 356.16,
   width: 125.04,
   height: 12.48
}
,
{
   page: 24,
   name: "signature brokerBuyerSigns", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 246.11,
   left: 181.964,
   width: 167.019,
   height: 13.491
}
,
{
   page: 24,
   name: "signature cobrokerBuyerSigns", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 280.801,
   left: 181.964,
   width: 167.019,
   height: 13.491
}
,
{
   page: 24,
   name: "signature brokerSellerSigns", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 702.3303,
   left: 182.619,
   width: 160.473,
   height: 13.4909
}
,
{
   page: 25,
   name: "Date cobrokerSellerSigns", 
   fontSize: 9,
   type: "date",
   top: 53.04,
   left: 356.76,
   width: 126.00,
   height: 12.48
}
,
{
   page: 25,
   name: "1_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 78.36,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "2_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 91.56,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "3_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 104.76,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "4_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 117.96,
   left: 181.2,
   width: 375.0,
   height: 12.48
}
,
{
   page: 25,
   name: "signature cobrokerSellerSIgns", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 51.709,
   left: 181.31,
   width: 167.673,
   height: 13.491
}
] }
