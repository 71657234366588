export function disclosureofRelationshipwithContractProvider() {
return [   //2024 Release 2024-03-13 10:25:13
{
   page: 0,
   name: "Other", 
   isText: true,
   type: "checkbox",
   top: 292.8,
   left: 58.2,
   width: 7.44,
   height: 7.68
}
,
{
   page: 0,
   name: "Listing", 
   isText: true,
   type: "checkbox",
   top: 292.92,
   left: 317.88,
   width: 7.20,
   height: 7.44
}
,
{
   page: 0,
   name: "Other BrokerSales Agent receives compensation", 
   isText: true,
   type: "checkbox",
   top: 324,
   left: 57.72,
   width: 7.44,
   height: 7.68
}
,
{
   page: 0,
   name: "Listing BrokerSales Agent receives compensation", 
   isText: true,
   type: "checkbox",
   top: 324,
   left: 318.72,
   width: 7.44,
   height: 7.68
}
,
{
   page: 0,
   name: "from the following Provider 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 352.004,
   left: 66.3891,
   width: 229.9199,
   height: 14.574
}
,
{
   page: 0,
   name: "from the following Provider 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 370.378,
   left: 66.3891,
   width: 229.9199,
   height: 13.920
}
,
{
   page: 0,
   name: "from the following Provider 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 352.658,
   left: 327.629,
   width: 230.040,
   height: 13.920
}
,
{
   page: 0,
   name: "from the following Provider 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 370.378,
   left: 327.629,
   width: 230.040,
   height: 13.920
}
,
{
   page: 0,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 415.785,
   left: 66.3891,
   width: 229.9199,
   height: 17.193
}
,
{
   page: 0,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 435.778,
   left: 66.3891,
   width: 229.9199,
   height: 13.920
}
,
{
   page: 0,
   name: "1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 415.785,
   left: 327.629,
   width: 230.040,
   height: 17.193
}
,
{
   page: 0,
   name: "2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 435.778,
   left: 327.629,
   width: 230.040,
   height: 13.920
}
,
{
   page: 0,
   name: "Other Brokers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 545.556,
   left: 58.3855,
   width: 180.9595,
   height: 14.575
}
,
{
   page: 0,
   name: "License No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 545.556,
   left: 243.905,
   width: 58.920,
   height: 14.575
}
,
{
   page: 0,
   name: "Listing Brokers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 545.556,
   left: 320.705,
   width: 175.560,
   height: 14.575
}
,
{
   page: 0,
   name: "License No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 545.556,
   left: 500.825,
   width: 63.000,
   height: 14.575
}
,
{
   page: 0,
   name: "The undersigned acknowledges receipt of this notice", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 581.411,
   left: 76.1455,
   width: 226.6795,
   height: 14.574
}
,
{
   page: 0,
   name: "By", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 581.411,
   left: 338.465,
   width: 226.800,
   height: 14.574
}
] }
