import { Formik, Form, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import {
  updateListingAgentPartyInDb,
  updateTransactionInDb,
} from "../../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";
import FormAddress from "../../../app/common/form/FormAddress";
import MySelectInput from "../../../app/common/form/MySelectInput";
import {
  transactionStatusOptionsBuyer,
  transactionStatusOptionsSeller,
} from "../../../app/common/categoryData/categoryOptions";
import MyDateInput from "../../../app/common/form/MyDateInput";
import { useMediaQuery } from "react-responsive";
import MyTextArea from "../../../app/common/form/MyTextArea";
import TransactionImportMlsForm from "./TransactionImportMlsForm";
import TransactionImportLegalAddressForm from "./TransactionImportLegalAddressForm";

export default function TransactionEditForm() {
  const dispatch = useDispatch();
  const { transaction, parties } = useSelector((state) => state.transaction);
  const [mlsData, setMlsData] = useState(null);
  const [legalAddressData, setLegalAddressData] = useState(null);

  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  let initialValues = transaction;

  const validationSchema = Yup.object({
    title: Yup.string().required("You must provide an address"),
  });

  function Watcher() {
    const { setFieldValue } = useFormikContext();
    useEffect(() => {
      if (mlsData) {
        if (mlsData.address?.street) {
          setFieldValue("address.street", mlsData.address.street);
        }
        if (mlsData.address?.unit) {
          setFieldValue("address.unit", mlsData.address.unit);
        }
        if (mlsData.address?.city) {
          setFieldValue("address.city", mlsData.address.city);
        }
        if (mlsData.address?.state) {
          setFieldValue("address.state", mlsData.address.state);
        }
        if (mlsData.address?.zipcode) {
          setFieldValue("address.zipcode", mlsData.address.zipcode);
        }
        if (mlsData.propertyDetails?.legalDescription) {
          setFieldValue(
            "propertyDetails.legalDescription",
            mlsData.propertyDetails.legalDescription
          );
        }
        if (mlsData.propertyDetails?.county) {
          setFieldValue(
            "propertyDetails.county",
            mlsData.propertyDetails.county
          );
        }
        if (mlsData.propertyDetails?.inclusions) {
          setFieldValue(
            "propertyDetails.inclusions",
            mlsData.propertyDetails.inclusions
          );
        }
        if (mlsData.propertyDetails?.exclusions) {
          setFieldValue(
            "propertyDetails.exclusions",
            mlsData.propertyDetails.exclusions
          );
        }
        if (mlsData.propertyDetails?.yearBuilt) {
          setFieldValue(
            "propertyDetails.yearBuilt",
            mlsData.propertyDetails.yearBuilt
          );
        }
        if (mlsData.pic) {
          setFieldValue("pic", mlsData.pic);
        }
      }
    }, [setFieldValue]);

    useEffect(() => {
      if (legalAddressData) {
        setFieldValue("propertyDetails.legalDescription", legalAddressData);
      }
    }, [setFieldValue]);

    return null;
  }

  function formContractDetails() {
    return (
      <Segment className="background-lightgrey">
        <h2 className="text blue">Contract Details</h2>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} computer={6}>
              {transaction.agentRepresents === "Buyer" && (
                <MySelectInput
                  name="status"
                  label="Status"
                  className="tiny bottom margin"
                  options={transactionStatusOptionsBuyer}
                />
              )}
              {transaction.agentRepresents === "Seller" && (
                <MySelectInput
                  name="status"
                  label="Status"
                  className="tiny bottom margin"
                  options={transactionStatusOptionsSeller}
                />
              )}
            </Grid.Column>
            <Grid.Column mobile={16} computer={10}>
              <MyTextInput
                name="title"
                label="Transaction Name"
                className="tiny bottom margin"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="zero top padding">
            <Grid.Column
              mobile={16}
              computer={6}
              className="tiny bottom margin"
            >
              <MyTextInput name="salesPrice" label="Sales price" />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              computer={5}
              className="tiny bottom margin"
            >
              <MyDateInput
                name="contractDateTime"
                label="Contract date"
                dateFormat="MMMM d, yyyy"
              />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              computer={5}
              className={isMobile ? "small bottom margin" : null}
            >
              <MyDateInput
                name="closingDateTime"
                label="Closing date"
                dateFormat="MMMM d, yyyy"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  function formMlsDetails() {
    return (
      <Segment className="background-lightgrey">
        <div className="small bottom margin">
          <h2 className="text blue">MLS</h2>
          <TransactionImportMlsForm mlsData={mlsData} setMlsData={setMlsData} />
          <Grid className="zero top margin">
            <Grid.Row>
              <Grid.Column mobile={16} computer={8}>
                <MyTextInput name="mlsNumbers[1]" label="MLS # (secondary)" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
    );
  }

  function formAddressDetails() {
    return (
      <Segment className="background-lightgrey">
        <div className="tiny bottom margin">
          <h2 className="text blue">Property Address</h2>
          <FormAddress />
          <Grid>
            <Grid.Row className="mini top padding">
              <Grid.Column mobile={16} computer={6}>
                <MyTextInput name="propertyDetails.county" label="County" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
    );
  }

  function formLegalDescription(values) {
    return (
      <Segment className="background-lightgrey">
        <div className="tiny bottom margin">
          <h2 className="text blue">Legal Description</h2>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <MyTextArea
                  rows="3"
                  name="propertyDetails.legalDescription"
                  label="Legal description"
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <TransactionImportLegalAddressForm
                  legalAddressData={legalAddressData}
                  setLegalAddressData={setLegalAddressData}
                  address={values.address}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
    );
  }

  return (
    <ModalWrapper size="large">
      <Segment clearing>
        <div
          className={
            isMobile
              ? "zero horizontal margin small top margin"
              : "medium horizontal margin small top margin"
          }
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                updateListingAgentPartyInDb(values, mlsData, parties);
                await updateTransactionInDb(transaction.id, values, true);
                setSubmitting(false);
                toast.success("Transaction successfully updated");
                dispatch(
                  closeModal({
                    modalType: "TransactionPropertyForm",
                  })
                );
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid, values }) => (
              <Form className="ui form" autoComplete="off">
                <Watcher />
                <Header size="huge" color="blue">
                  Edit Transaction
                </Header>
                <Divider />
                <Grid stackable>
                  <Grid.Column mobile={16} computer={16}>
                    {formContractDetails()}
                    {formMlsDetails()}
                    {formAddressDetails()}
                    {formLegalDescription(values)}
                  </Grid.Column>
                </Grid>
                <Divider className="medium top margin" />
                <Button
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting || !dirty}
                  type="submit"
                  floated={isMobile ? null : "right"}
                  primary
                  content="Submit"
                  className={isMobile ? "fluid medium bottom margin" : null}
                  data-test="submit"
                />
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "TransactionPropertyForm",
                      })
                    )
                  }
                  to="#"
                  type="button"
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium" : null}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </ModalWrapper>
  );
}
