export function acknowledgmentandConfirmationofDisclosures() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 12.96,
   left: 127.44,
   width: 466.56,
   height: 12.60
}
,
{
   page: 0,
   name: "Check Box 1", 
   isText: false,
   type: "checkbox",
   top: 143.104,
   left: 49.9725,
   width: 6.8400,
   height: 6.840
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 197.248,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 223.384,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Check Box 4", 
   isText: false,
   type: "checkbox",
   top: 249.52,
   left: 49.968,
   width: 6.840,
   height: 6.84
}
,
{
   page: 0,
   name: "Check Box 5", 
   isText: false,
   type: "checkbox",
   top: 264.712,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Check Box 6", 
   isText: false,
   type: "checkbox",
   top: 297.472,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Check Box 7", 
   isText: false,
   type: "checkbox",
   top: 334.336,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Check Box 8", 
   isText: false,
   type: "checkbox",
   top: 349.695,
   left: 49.968,
   width: 6.840,
   height: 6.840
}
,
{
   page: 0,
   name: "Text Field 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 401.472,
   left: 152.64,
   width: 156.96,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 401.472,
   left: 438.75,
   width: 138.33,
   height: 13.500
}
,
{
   page: 0,
   name: "Check Box 9", 
   isText: false,
   type: "checkbox",
   top: 500.944,
   left: 68.04,
   width: 6.84,
   height: 6.840
}
,
{
   page: 0,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 561.352,
   left: 157.68,
   width: 155.88,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 561.352,
   left: 442.842,
   width: 134.000,
   height: 13.500
}
] }
