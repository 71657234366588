export function licenseeDisclosureRPCD() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text2", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 243.012,
   left: 157.252,
   width: 416.795,
   height: 22.900
}
,
{
   page: 0,
   name: "Text3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 334.92,
   left: 32.0611,
   width: 543.9829,
   height: 21.374
}
,
{
   page: 0,
   name: "Text6", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 314.478,
   left: 31.496,
   width: 544.093,
   height: 20.473
}
,
{
   page: 0,
   name: "Text7", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 352.732,
   left: 32.2834,
   width: 544.0926,
   height: 25.197
}
,
{
   page: 0,
   name: "Text8", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 372.557,
   left: 31.496,
   width: 545.667,
   height: 25.985
}
,
{
   page: 0,
   name: "Text9", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 394.184,
   left: 30.6527,
   width: 545.6673,
   height: 24.410
}
,
{
   page: 0,
   name: "Text1100", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 418.451,
   left: 31.1071,
   width: 544.6699,
   height: 21.521
}
,
{
   page: 0,
   name: "Text11", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 503.777,
   left: 407.085,
   width: 170.866,
   height: 25.985
}
,
{
   page: 0,
   name: "Text1200", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 554.836,
   left: 31.8284,
   width: 544.0926,
   height: 22.047
}
,
{
   page: 0,
   name: "Text1400", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 439.702,
   left: 32.5237,
   width: 543.3053,
   height: 21.522
}
,
{
   page: 0,
   name: "Text112323", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 460.633,
   left: 32.5237,
   width: 543.3053,
   height: 21.522
}
,
{
   page: 0,
   name: "Text2343", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 506.589,
   left: 30.7036,
   width: 325.8084,
   height: 21.522
}
] }
