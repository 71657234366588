export function cBRadonDisclosure() {
   return [   //2023
   {
      page: 0,
      name: "logo",
      isText: false,
      type: "logo",
      top: 28.673,
      left: 53,
      width: 253,
      height: 43.2
   }
   ,
   {
      page: 0,
      name: "a1_date", 
      type: "date",
      fontName: "Helvetica",
      fontSize: 10,
      top: 466.32,
      left: 191.4,
      width: 161.4,
      height: 13.56
   }
   ,
   {
      page: 0,
      name: "a1_have", 
      isText: false,
      type: "checkbox",
      top: 452.921,
      left: 161.308,
      width: 13.939,
      height: 12.989
   }
   ,
   {
      page: 0,
      name: "a1_have_not", 
      isText: false,
      type: "checkbox",
      top: 452.341,
      left: 210.121,
      width: 13.939,
      height: 12.989
   }
   ,
   {
      page: 0,
      name: "a3_details", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 540.637,
      left: 77.9178,
      width: 469.9082,
      height: 57.633
   }
   ,
   {
      page: 0,
      name: "a4_radon_mitigation", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 642.4,
      left: 74.8633,
      width: 469.9087,
      height: 53.7058
   }
   ] }
   