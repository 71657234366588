export function addendumPropertyLocatedSeawardofGulfWaterway() {
return [   //2024 Release 2024-02-18 22:51:08
{
   page: 0,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 155.967,
   left: 76.44,
   width: 481.68,
   height: 14.553
}
] }
