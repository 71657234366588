import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { Button, Grid, Image } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import {
  functionFetchMlsDataFiniti,
  functionFetchMlsIres,
} from "../../../app/firestore/functionsService";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import stateNameToAbbreviation from "../../../app/common/util/util";

export default function TransactionImportMlsForm({ mlsData, setMlsData }) {
  const [importMlsStatus, setImportMlsStatus] = useState("Initial");
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [mlsId, setMlsId] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function mlsImportSuccess(formattedData) {
    setImportMlsStatus("Complete");
    setMlsData(formattedData);
    toast.success("MLS data imported successfully");
  }

  async function handleMlsImport() {
    if (mlsId) {
      let values = false;
      let agentState = "CO";
      if (currentUserProfile.state) {
        agentState = stateNameToAbbreviation(currentUserProfile.state);
      }
      setImportMlsStatus("Loading");
      try {
        console.log(currentUserProfile.cities);
        if (!currentUserProfile.cities)
          if (
            !currentUserProfile.state ||
            currentUserProfile.state === "Colorado"
          ) {
            values = await functionFetchMlsIres(mlsId);
            if (values) {
              mlsImportSuccess(values);
            }
          }
        if (!values) {
          values = await functionFetchMlsDataFiniti({
            mlsId: mlsId,
            state: agentState,
          });
          if (values) {
            mlsImportSuccess(values);
          } else {
            setImportMlsStatus("Error");
            toast.error(
              `Error when importing MLS data. Please check the MLS ID and try again or manually add details.`
            );
          }
        }
      } catch (error) {
        setImportMlsStatus("Error");
        toast.error(
          `Error when importing MLS data. Please check the MLS ID and try again or manually add details.`
        );
      }
    } else {
      toast.error("Please add an MLS number");
    }
  }

  function WatcherMlsNum() {
    const { values } = useFormikContext();
    useEffect(() => {
      if (values.mlsNumbers[0]) {
        setMlsId(values.mlsNumbers[0]);
      }
    }, [values.mlsNumbers]);
    return null;
  }

  return (
    <>
      <Grid>
        <Grid.Column mobile={16} computer={5} className="small padding top">
          <WatcherMlsNum />
          <MyTextInput name="mlsNumbers[0]" label="MLS #" />
        </Grid.Column>
        <Grid.Column mobile={16} computer={4} className="small padding top">
          <Button
            primary
            loading={importMlsStatus === "Loading"}
            disabled={
              importMlsStatus === "Loading" || importMlsStatus === "Complete"
            }
            className={isMobile ? "fluid" : null}
            style={{ marginTop: "20px" }}
            onClick={(e) => {
              e.preventDefault();
              handleMlsImport();
            }}
          >
            {importMlsStatus === "Initial" || importMlsStatus === "Error"
              ? "Import MLS Details"
              : `Import ${importMlsStatus}`}
          </Button>
        </Grid.Column>
        <Grid.Column mobile={16} computer={7}></Grid.Column>
        {mlsData?.address && (
          <>
            <Grid.Column mobile={16} computer={4}>
              <div className="large bottom margin">
                <Image
                  bordered
                  rounded
                  size="medium"
                  wrapped
                  src={mlsData?.pic}
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} computer={4}>
              <div className="large bottom margin">
                <h4>Property Details</h4>
                {mlsData.propertyDetails?.county && (
                  <p className="mini bottom margin">
                    County: {mlsData.propertyDetails?.county}
                  </p>
                )}
                {mlsData.propertyDetails?.subdivisionName && (
                  <p className="mini bottom margin">
                    Subdivision: {mlsData.propertyDetails?.subdivisionName}
                  </p>
                )}
                {mlsData.propertyDetails?.yearBuilt && (
                  <p className="mini bottom margin">
                    Year Built: {mlsData.propertyDetails?.yearBuilt}
                  </p>
                )}
                {mlsData.propertyDetails?.parcelNumber && (
                  <p className="mini bottom margin">
                    Parcel #: {mlsData.propertyDetails?.parcelNumber}
                  </p>
                )}
                {mlsData.propertyDetails?.legalDescription && (
                  <p className="mini bottom margin">
                    Legal Description:{" "}
                    {mlsData.propertyDetails?.legalDescription}
                  </p>
                )}
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} computer={4}>
              <div className="large bottom margin">
                <h4>Listing Agent</h4>
                <p className="mini bottom margin">
                  Name: {mlsData.listingAgent?.firstName}{" "}
                  {mlsData.listingAgent?.lastName}
                </p>
                {mlsData.listingAgent?.email && (
                  <p className="mini bottom margin">
                    Email: {mlsData.listingAgent?.email}
                  </p>
                )}
                {mlsData.listingAgent?.phone && (
                  <p className="mini bottom margin">
                    Phone: {mlsData.listingAgent?.phone}
                  </p>
                )}
                {mlsData.listingAgent?.brokerLicenseNumber && (
                  <p className="mini bottom margin">
                    License #: {mlsData.listingAgent?.brokerLicenseNumber}
                  </p>
                )}
                {mlsData.listingAgent?.brokerageName && (
                  <p className="mini bottom margin">
                    Brokerage: {mlsData.listingAgent?.brokerageName}
                  </p>
                )}
                {!mlsData.listingAgent?.email && (
                  <p className="mini bottom margin bold">
                    No email found. Manually add LA in Parties.
                  </p>
                )}
              </div>
            </Grid.Column>
          </>
        )}
      </Grid>
    </>
  );
}
