export function vALoan() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 101.384,
   left: 31.5,
   width: 549.0,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 185.624,
   left: 499.896,
   width: 77.760,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 329.984,
   left: 414.72,
   width: 27.648,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 99.44,
   left: 374.138,
   width: 95.040,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 197.336,
   left: 181.584,
   width: 395.280,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 211.016,
   left: 51.552,
   width: 525.312,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 225.128,
   left: 51.552,
   width: 525.312,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 239.24,
   left: 51.552,
   width: 525.312,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 253.352,
   left: 51.552,
   width: 525.312,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 267.32,
   left: 51.552,
   width: 525.312,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 281.288,
   left: 51.552,
   width: 525.312,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 295.256,
   left: 51.552,
   width: 525.312,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 309.224,
   left: 51.552,
   width: 525.312,
   height: 13.500
}
,
{
   page: 1,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 323.192,
   left: 51.552,
   width: 525.312,
   height: 13.500
}
] }
