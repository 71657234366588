import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { asyncActionError, asyncActionFinish, asyncActionStart } from "../async/asyncSlice";
import { dataFromSnapshot } from "../firestore/firestoreService";
import { onSnapshot } from '@firebase/firestore';

export default function useFirestoreDoc({query, data, deps}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncActionStart());
    const unsubscribe = onSnapshot(query(),
      snapshot => {
        if (!snapshot.exists) {
          dispatch(asyncActionError({
            code: "not-found",
            message: "Could not find document"
          }));
          return;
        }
        data(dataFromSnapshot(snapshot));
        dispatch(asyncActionFinish());
      },
      error => dispatch(asyncActionError())
    );
    return () => {
      unsubscribe()
    }
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}