export function exclusiveRighttoBuy2023() {
    return [   //2023
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
       page: 0,
       name: "BUYER AGENCY", 
       isText: false,
       type: "checkbox",
       top: 239.64,
       left: 158.28,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 0,
       name: "TRANSACTIONBROKERAGE", 
       isText: false,
       type: "checkbox",
       top: 239.64,
       left: 302.04,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 256.2,
       left: 459.24,
       width: 107.64,
       height: 16.68
    }
    ,
    {
       page: 0,
       name: "MultiplePerson Firm If th", 
       isText: false,
       type: "checkbox",
       top: 337.32,
       left: 90.36,
       width: 13.44,
       height: 11.76
    }
    ,
    {
       page: 0,
       name: "OnePerson Firm If this box", 
       isText: false,
       type: "checkbox",
       top: 416.76,
       left: 90.36,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 0,
       name: "Buyer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 481.56,
       left: 128.88,
       width: 437.64,
       height: 16.68
    }
    ,
    {
       page: 0,
       name: "Brokerage Firm", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 499.32,
       left: 174.12,
       width: 392.88,
       height: 16.68
    }
    ,
    {
       page: 0,
       name: "Broker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 517.08,
       left: 132.72,
       width: 432.00,
       height: 16.68
    }
    ,
    {
       page: 0,
       name: "If this box is checked Buyer authorizes Broker to negot", 
       isText: false,
       type: "checkbox",
       top: 711.72,
       left: 112.92,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 0,
       name: "Text5", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 564.221,
       left: 75.2731,
       width: 493.6379,
       height: 73.709
    }
    ,
    {
       page: 1,
       name: "ns on", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 32.88,
       left: 426.72,
       width: 147.48,
       height: 12.96
    }
    ,
    {
       page: 1,
       name: "2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 60.24,
       left: 95.88,
       width: 405.96,
       height: 12.96
    }
    ,
    {
       page: 1,
       name: "ncluded If any deadl", 
       isText: false,
       type: "checkbox",
       top: 216.12,
       left: 506.28,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 1,
       name: "be extended to the next day that is not a Saturday Sunday or Holiday Should neither box be checked the", 
       isText: false,
       type: "checkbox",
       top: 216.12,
       left: 460.44,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 1,
       name: "treat the sel", 
       isText: false,
       type: "checkbox",
       top: 502.44,
       left: 129.24,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 2,
       name: "Success Fee Brokerage Firm wi", 
       isText: false,
       type: "checkbox",
       top: 515.16,
       left: 110.28,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 2,
       name: "of the purchase pr", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 528.6,
       left: 247.2,
       width: 61.08,
       height: 14.04
    }
    ,
    {
       page: 2,
       name: "undefined", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 528.6,
       left: 500.76,
       width: 72.24,
       height: 14.04
    }
    ,
    {
       page: 2,
       name: "See  21 Additional Provisions or", 
       isText: false,
       type: "checkbox",
       top: 581.88,
       left: 112.92,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 2,
       name: "Other", 
       isText: false,
       type: "checkbox",
       top: 599.16,
       left: 112.92,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 2,
       name: "undefined_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 596.28,
       left: 161.88,
       width: 409.68,
       height: 14.04
    }
    ,
    {
       page: 2,
       name: "Hourly Fee Brokerage Firm wi", 
       isText: false,
       type: "checkbox",
       top: 696.6,
       left: 108.6,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 2,
       name: "per hour for time spent by Broker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 693.72,
       left: 318.84,
       width: 105.60,
       height: 14.04
    }
    ,
    {
       page: 2,
       name: "This hourly fee", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 708.84,
       left: 385.92,
       width: 105.72,
       height: 14.04
    }
    ,
    {
       page: 3,
       name: "Retainer Fee Buyer wil", 
       isText: false,
       type: "checkbox",
       top: 37.8,
       left: 107.4,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "ner fee of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 35.28,
       left: 476.52,
       width: 94.56,
       height: 13.68
    }
    ,
    {
       page: 3,
       name: "Will", 
       isText: false,
       type: "checkbox",
       top: 68.04,
       left: 76.92,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "Will Not be credited against other fees payable to Brokerage Firm under this section", 
       isText: false,
       type: "checkbox",
       top: 68.04,
       left: 127.08,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "Other Compensation", 
       isText: false,
       type: "checkbox",
       top: 88.68,
       left: 107.4,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "undefined_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 86.16,
       left: 229.92,
       width: 338.64,
       height: 13.68
    }
    ,
    {
       page: 3,
       name: "Amount", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 130.56,
       left: 165.96,
       width: 83.40,
       height: 13.68
    }
    ,
    {
       page: 3,
       name: "per square", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 130.56,
       left: 302.76,
       width: 243.84,
       height: 13.68
    }
    ,
    {
       page: 3,
       name: "except as provided in  722", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 145.32,
       left: 76.32,
       width: 361.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "See  21 Additional Provisions or_2", 
       isText: false,
       type: "checkbox",
       top: 182.28,
       left: 94.92,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "Other_2", 
       isText: false,
       type: "checkbox",
       top: 199.56,
       left: 94.92,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "undefined_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 197.04,
       left: 150.36,
       width: 420.48,
       height: 13.68
    }
    ,
    {
       page: 3,
       name: "Other_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 216.24,
       left: 148.32,
       width: 420.60,
       height: 13.68
    }
    ,
    {
       page: 3,
       name: "lows", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 259.92,
       left: 253.08,
       width: 316.80,
       height: 13.68
    }
    ,
    {
       page: 3,
       name: "Will_2", 
       isText: false,
       type: "checkbox",
       top: 305.88,
       left: 104.28,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "Will Not be paid a fee upon exercise of such extension or renewal option or expansion If", 
       isText: false,
       type: "checkbox",
       top: 305.88,
       left: 154.44,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "undefined_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 348.36,
       left: 76.32,
       width: 487.08,
       height: 13.68
    }
    ,
    {
       page: 3,
       name: "Listing Brokerage Firm or Seller May Pay Buyer IS Obligated to Pay Broker", 
       isText: false,
       type: "checkbox",
       top: 384.6,
       left: 98.52,
       width: 13.44,
       height: 11.76
    }
    ,
    {
       page: 3,
       name: "Buyer Will Pay Buyer is obl", 
       isText: false,
       type: "checkbox",
       top: 440.04,
       left: 98.52,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "Listing Brokerage Firm or Seller May Pay Buyer is NOT Obligated to Pay Broker", 
       isText: false,
       type: "checkbox",
       top: 482.52,
       left: 98.52,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "thin", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 576,
       left: 207.96,
       width: 83.52,
       height: 13.44
    }
    ,
    {
       page: 3,
       name: "Will_3", 
       isText: false,
       type: "checkbox",
       top: 621.48,
       left: 149.16,
       width: 13.44,
       height: 11.76
    }
    ,
    {
       page: 3,
       name: "Will Not owe the Brokerage F", 
       isText: false,
       type: "checkbox",
       top: 621.48,
       left: 198.6,
       width: 13.44,
       height: 11.76
    }
    ,
    {
       page: 4,
       name: "i", 
       isText: false,
       type: "checkbox",
       top: 81.24,
       left: 430.44,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "on or Purchase or Lease of", 
       isText: false,
       type: "checkbox",
       top: 81.24,
       left: 468.12,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "party to any agreement with any other broker to represent or assist Buyer", 
       isText: false,
       type: "checkbox",
       top: 111.72,
       left: 315.72,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "ng agreement to purchase or lease Property w", 
       isText: false,
       type: "checkbox",
       top: 111.72,
       left: 265.08,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "tten consent of Buyer", 
       isText: false,
       type: "checkbox",
       top: 539.64,
       left: 298.92,
       width: 12.96,
       height: 11.28
    }
    ,
    {
       page: 4,
       name: "Does Not have Buyer", 
       isText: false,
       type: "checkbox",
       top: 539.64,
       left: 351.48,
       width: 12.96,
       height: 11.28
    }
    ,
{
   page: 4,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 482.402,
   left: 80.5094,
   width: 445.8556,
   height: 12.182
}
    ,
    {
      page: 4,
      name: "Text3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 508.584,
      left: 77.2367,
      width: 496.2563,
      height: 29.855
   }
    ,
    {
       page: 4,
       name: "Text4", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 367.202,
       left: 74.6185,
       width: 500.8375,
       height: 78.291
    }
    ,
    {
       page: 5,
       name: "Text2", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 187.201,
       left: 53.0184,
       width: 525.7106,
       height: 570.5114
    }
    ,
{
   page: 6,
   name: "Text1", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 46.6,
   left: 56.237,
   width: 525.711,
   height: 39.018
}
,
{
   page: 6,
   name: "or", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 196.07,
   left: 385.813,
   width: 183.360,
   height: 13.68
}
,
{
   page: 6,
   name: "Buyer 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 465.6,
   left: 94.8,
   width: 207.6,
   height: 14.64
}
,
{
   page: 6,
   name: "Buyer 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 465.6,
   left: 352.08,
   width: 213.12,
   height: 14.64
}
,
{
   page: 6,
   name: "Buyer Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 514.32,
   left: 58.32,
   width: 248.04,
   height: 14.64
}
,
{
   page: 6,
   name: "Buyer Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 543.12,
   left: 58.32,
   width: 248.04,
   height: 14.64
}
,
{
   page: 6,
   name: "Buyer Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 571.92,
   left: 58.32,
   width: 248.04,
   height: 14.64
}
,
{
   page: 6,
   name: "Buyer Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 514.32,
   left: 314.52,
   width: 247.80,
   height: 14.64
}
,
{
   page: 6,
   name: "Buyer Phone_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 543.12,
   left: 314.52,
   width: 247.80,
   height: 14.64
}
,
{
   page: 6,
   name: "Buyer Email_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 571.92,
   left: 314.52,
   width: 247.80,
   height: 14.64
}
,
{
   page: 6,
   name: "signature Broker1", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 633.448,
   left: 56.4716,
   width: 159.1094,
   height: 14.640
}
,
{
   page: 6,
   name: "Date_4", 
   fontSize: 9,
   type: "date",
   top: 634.32,
   left: 223.24,
   width: 79.32,
   height: 14.64
}
,
{
   page: 6,
   name: "signature CoBroker", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 635.412,
   left: 314.222,
   width: 159.175,
   height: 12.676
}
,
{
   page: 6,
   name: "Date_5", 
   fontSize: 9,
   type: "date",
   top: 634.32,
   left: 483.16,
   width: 79.20,
   height: 14.64
}
,
{
   page: 6,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 670.2,
   left: 187.96,
   width: 360.96,
   height: 14.64
}
,
{
   page: 6,
   name: "s Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 686.04,
   left: 187.96,
   width: 360.96,
   height: 14.16
}
,
{
   page: 6,
   name: "Brokerage Firm Address 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 701.4,
   left: 187.96,
   width: 360.96,
   height: 14.64
}
,
{
   page: 6,
   name: "Brokerage Firm Address 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 717.24,
   left: 188.88,
   width: 360.96,
   height: 14.64
}
,
{
   page: 6,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 733.08,
   left: 188.88,
   width: 360.84,
   height: 14.64
}
] }