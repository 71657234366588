export function noticeofBuyersTermination() {
return [   //2024 Release 2024-02-18 22:21:33
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 114.542,
   left: 49.9637,
   width: 508.6803,
   height: 14.411
}
,
{
   page: 0,
   name: "SELLER", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 153.571,
   left: 264.393,
   width: 292.680,
   height: 10.876
}
,
{
   page: 0,
   name: "BETWEEN THE UNDERSIGNED BUYER AND", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 170.419,
   left: 48.3927,
   width: 450.1203,
   height: 12.185
}
,
{
   page: 0,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 211.523,
   left: 57.5037,
   width: 12.3386,
   height: 11.206
}
,
{
   page: 0,
   name: "Check Box2", 
   isText: false,
   type: "checkbox",
   top: 242.19,
   left: 57.5935,
   width: 11.8149,
   height: 11.206
}
,
{
   page: 0,
   name: "Check Box3", 
   isText: false,
   type: "checkbox",
   top: 273.423,
   left: 57.1172,
   width: 11.2912,
   height: 11.207
}
,
{
   page: 0,
   name: "Check Box4", 
   isText: false,
   type: "checkbox",
   top: 319.091,
   left: 57.2022,
   width: 11.2062,
   height: 11.206
}
,
{
   page: 0,
   name: "Check Box5", 
   isText: false,
   type: "checkbox",
   top: 350.324,
   left: 56.636,
   width: 11.7724,
   height: 11.206
}
,
{
   page: 0,
   name: "Check Box6", 
   isText: false,
   type: "checkbox",
   top: 381.859,
   left: 57.7305,
   width: 10.2441,
   height: 10.159
}
,
{
   page: 0,
   name: "Check Box7", 
   isText: false,
   type: "checkbox",
   top: 426.238,
   left: 58.218,
   width: 10.6691,
   height: 12.240
}
,
{
   page: 0,
   name: "Check Box8", 
   isText: false,
   type: "checkbox",
   top: 471.224,
   left: 58.7889,
   width: 10.6691,
   height: 12.763
}
,
{
   page: 0,
   name: "Other identify the paragraph number of contract or the addendum 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 468.469,
   left: 440.673,
   width: 109.592,
   height: 15.720
}
,
{
   page: 0,
   name: "Other identify the paragraph number of contract or the addendum 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 484.789,
   left: 90.1964,
   width: 460.5926,
   height: 14.356
}
,
{
   page: 0,
   name: "Other identify the paragraph number of contract or the addendum 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 499.058,
   left: 90.1964,
   width: 460.5926,
   height: 14.880
}
,
{
   page: 0,
   name: "Other identify the paragraph number of contract or the addendum 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 513.731,
   left: 90.1964,
   width: 460.5926,
   height: 13.625
}
,
{
   page: 0,
   name: "Other identify the paragraph number of contract or the addendum 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 526.865,
   left: 90.1964,
   width: 460.5926,
   height: 14.149
}
] }
