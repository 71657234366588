import React from "react";
import { Table } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { sortDocsForViewing } from "../partySlice";
import PartyDocViewListItem from "./PartyDocViewListItem";

export default function PartyDocViewList({ docs, column, direction }) {
  const dispatch = useDispatch();

  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header-grey">
            <Table.HeaderCell
              sorted={column === "title" ? direction : null}
              onClick={() => dispatch(sortDocsForViewing("title"))}
            >
              Name
            </Table.HeaderCell>
            {/* <Table.HeaderCell
              sorted={column === "subStatus" ? direction : null}
              onClick={() => dispatch(sortDocsForViewing("subStatus"))}
            >
              Status
            </Table.HeaderCell> */}
            <Table.HeaderCell
              sorted={column === "updatedAt" ? direction : null}
              onClick={() => dispatch(sortDocsForViewing("updatedAt"))}
            >
              Last Updated
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {docs &&
            docs.length !== 0 &&
            docs.map((doc) => <PartyDocViewListItem doc={doc} key={doc.id} />)}
        </Table.Body>
      </Table>
    </>
  );
}
