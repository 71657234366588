import React, { useState } from "react";
import { Grid, Input } from "semantic-ui-react";
import { useSelector } from "react-redux";
import HistoryList from "./historyComponents/HistoryList";
import { searchFilter } from "../../app/common/util/util";

export default function HistoryDashboard() {
  const { historyAll } = useSelector((state) => state.history);
  const [searchTerms, setSearchTerms] = useState("");
  const historyFiltered = searchFilter(historyAll?.history, searchTerms);

  return (
    <div className="secondary-page-wrapper">
      <Grid stackable>
        <Grid.Column computer={8}>
          <Input
            type="text"
            fluid
            placeholder="Search by role, name, action, or document"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          ></Input>
        </Grid.Column>
        <Grid.Column computer={8} tablet={8}></Grid.Column>
        <Grid.Column computer={16}>
          <h3>History</h3>
          {historyAll?.history?.length > 0 ? (
            <HistoryList
              history={historyFiltered}
              column={historyAll.column}
              direction={historyAll.direction}
            />
          ) : (
            <p>There is no history for this transaction</p>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
}
