export function inspectionObjectionNotice2023() {
    return [   //2023
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 240,
      height: 36,
    }
    ,
   {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 123.913,
       left: 398.64,
       width: 174.00,
       height: 13.767
    }
    ,
    {
       page: 0,
       name: "contractDate", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 144.629,
       left: 299.56,
       width: 191.673,
       height: 14.422
    }
    ,
    {
       page: 0,
       name: "sellersFullName", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 164.935,
       left: 51.3169,
       width: 455.6961,
       height: 13.332
    }
    ,
    {
       page: 0,
       name: "buyersFullName", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 181.942,
       left: 51.4034,
       width: 455.1066,
       height: 13.298
    }
    ,
    {
       page: 0,
       name: "addressFull", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 207.771,
       left: 50.4,
       width: 468.24,
       height: 13.953
    }
    ,
    {
       page: 0,
       name: "inspectionObjectionDetails", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 276.854,
       left: 47.9997,
       width: 528.6533,
       height: 474.9831
    }
    ,
    {
       page: 1,
       name: "additionalObjections", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 57.6,
       left: 47.1275,
       width: 519.1655,
       height: 124.764
    }
    ,
    {
       page: 1,
       name: "additionalPages", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 188.708,
       left: 204.356,
       width: 34.200,
       height: 13.985
    }
    ,
    {
       page: 1,
       name: "checkbox inspectionReportISattached", 
       isText: false,
       type: "checkbox",
       top: 299.834,
       left: 176.563,
       width: 13.309,
       height: 12.656
    }
    ,
    {
       page: 1,
       name: "checkbox inspectionReportNOTattached", 
       isText: false,
       type: "checkbox",
       top: 299.834,
       left: 206.909,
       width: 13.308,
       height: 12.656
    }
    ] }
    