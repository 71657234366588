import { Formik, Form } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  Header,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import MyDateInput from "../../../app/common/form/MyDateInput";
import MyNumberInput from "../../../app/common/form/MyNumberInput";
import MyRadioButtons from "../../../app/common/form/MyRadioButtons";
import {
  daysOfWeekArray,
  startingDateOptions,
  tableForDeadlineTemplates,
} from "../../../app/common/categoryData/categoryOptions";
import { addDays, format, getDay, subDays } from "date-fns";
import { updateDocInDb } from "../../../app/firestore/firestoreService";
import { deadlineTemplatesFormFieldMapping } from "./DeadlineTemplatesFormFieldMapping";

export default function DeadlineTemplatesApply({ deadlineTemplate }) {
  const dispatch = useDispatch();
  const { transaction } = useSelector((state) => state.transaction);
  const { doc } = useSelector((state) => state.doc);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const deadlines = tableForDeadlineTemplates;
  let initialValues = {
    ...deadlineTemplate,
    contractDateTime: transaction.contractDateTime || new Date(),
    mecDateTime: transaction.mecDateTime || null,
  };

  function applyDeadlineTemplateToForm(values) {
    let updateFields = {};
    const closingDateDays =
      values["closingDate"] && values["closingDate"]["days"];
    Object.values(values).forEach((value) => {
      if (value && value.days && value.startingDateType && value.event) {
        let startingDate = null;
        let deadlineDate = null;
        if (value.startingDateType === "Contract") {
          deadlineDate =
            values.contractDateTime &&
            addDays(values.contractDateTime, value.days);
        } else if (value.startingDateType === "Closing") {
          startingDate =
            values.contractDateTime &&
            closingDateDays &&
            addDays(values.contractDateTime, closingDateDays);
          deadlineDate = startingDate && subDays(startingDate, value.days);
        } else if (value.startingDateType === "MEC") {
          deadlineDate =
            values.mecDateTime && addDays(values.mecDateTime, value.days);
        }
        if (deadlineDate) {
          const formFieldName = deadlineTemplatesFormFieldMapping[value.event];
          updateFields[`formFieldValues.${formFieldName}`] = new Date(
            deadlineDate
          );
        }
      }
    });
    updateDocInDb(doc.id, updateFields);
  }

  function calculateDayOfWeek(type, values, deadlineName) {
    console.log(values[deadlineName]);
    const numberOfDays = values[deadlineName]["days"];
    if (!numberOfDays) {
      return;
    }
    const startingDateType = values[deadlineName]["startingDateType"];
    const closingDateDays =
      values["closingDate"] && values["closingDate"]["days"];
    let dayOfWeekAsNumber = null;
    let startingDate = null;
    let deadlineDate = null;
    if (startingDateType === "Contract") {
      deadlineDate =
        values.contractDateTime &&
        numberOfDays &&
        addDays(values.contractDateTime, numberOfDays);
    } else if (startingDateType === "Closing") {
      startingDate =
        values.contractDateTime &&
        closingDateDays &&
        addDays(values.contractDateTime, closingDateDays);
      deadlineDate = startingDate && subDays(startingDate, numberOfDays);
    } else if (startingDateType === "MEC") {
      deadlineDate =
        values.mecDateTime && addDays(values.mecDateTime, numberOfDays);
    }
    if (!deadlineDate) {
      return;
    }
    if (type === "date") {
      return format(new Date(deadlineDate), "MM/dd/yyyy");
    } else {
      dayOfWeekAsNumber = getDay(deadlineDate);
      return daysOfWeekArray[dayOfWeekAsNumber];
    }
  }

  return (
    <ModalWrapper size="large">
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                applyDeadlineTemplateToForm(values);
                setSubmitting(false);
                dispatch(
                  closeModal({
                    modalType: "DeadlineTemplatesApply",
                  })
                );
                toast.success("Deadline template successfully applied");
                setTimeout(function () {
                  window.location.reload();
                }, 100);
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid, values }) => (
              <Form className="ui form" autoComplete="off">
                <Header size="large" color="blue">
                  Apply Deadlines to Form
                </Header>
                <p color="grey">
                  Dates will be automatically calculated based on either: + X
                  days after contract date, - X days before closing date, or + X
                  days after MEC.
                  <br />
                  Adjust dates here if desired by modifying the # of days, for
                  example if it lands on a weekend.
                </p>
                <Divider />
                <Grid>
                  <Grid.Row className="small vertical padding">
                    <Grid.Column
                      mobile={16}
                      computer={16}
                      className="small top margin"
                    >
                      <Header as="h3" color="blue">
                        {deadlineTemplate.name}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="tiny vertical padding">
                    <Grid.Column mobile={16} computer={8}>
                      <MyDateInput
                        name="contractDateTime"
                        label="Contract Date"
                      />
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={8}>
                      <MyDateInput name="mecDateTime" label="MEC Date" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Table compact sortable celled>
                  <Table.Header className="white-blue-table-header">
                    <Table.Row>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>Event</Table.HeaderCell>
                      <Table.HeaderCell>
                        Contract | Closing | MEC
                      </Table.HeaderCell>
                      <Table.HeaderCell>Days</Table.HeaderCell>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      <Table.HeaderCell>Day of Week</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {deadlines.length !== 0 &&
                      deadlines.map((deadline) => (
                        <Table.Row
                          key={deadline.event}
                          className={deadline.isTopic ? "table-row-blue" : null}
                        >
                          {deadline.isTopic ? (
                            <>
                              <Table.Cell></Table.Cell>
                              <Table.Cell></Table.Cell>
                              <Table.Cell>{deadline.event}</Table.Cell>
                              <Table.Cell></Table.Cell>
                              <Table.Cell></Table.Cell>
                              <Table.Cell></Table.Cell>
                              <Table.Cell></Table.Cell>
                            </>
                          ) : (
                            <>
                              <Table.Cell collapsing>
                                {deadline.itemNumber}
                              </Table.Cell>
                              <Table.Cell collapsing>
                                {deadline.reference}
                              </Table.Cell>
                              <Table.Cell>{deadline.event}</Table.Cell>
                              <Table.Cell>
                                <MyRadioButtons
                                  name={deadline.name + ".startingDateType"}
                                  options={startingDateOptions}
                                  noLabels={true}
                                  size={"small"}
                                />
                              </Table.Cell>
                              <Table.Cell style={{ maxWidth: "80px" }}>
                                <MyNumberInput name={deadline.name + ".days"} />
                              </Table.Cell>
                              <Table.Cell>
                                {calculateDayOfWeek(
                                  "day",
                                  values,
                                  deadline.name
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {calculateDayOfWeek(
                                  "date",
                                  values,
                                  deadline.name
                                )}
                              </Table.Cell>
                            </>
                          )}
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
                <Divider className="medium top margin" />
                <Button
                  loading={isSubmitting}
                  type="submit"
                  floated={isMobile ? null : "right"}
                  primary
                  content="Apply"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "DeadlineTemplatesApply",
                      })
                    )
                  }
                  to="#"
                  type="button"
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </ModalWrapper>
  );
}
