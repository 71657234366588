export function earnestMoneyRelease() {
return [
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date", 
   top: 169.80,
   left: 384.24,
   width: 151.56,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller", 
   top: 215.76,
   left: 151.8,
   width: 384.0,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Buyer", 
   top: 227.28,
   left: 153,
   width: 382.8,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Property", 
   top: 238.80,
   left: 162.96,
   width: 372.84,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "date",
   name: "Date of Contract", 
   top: 250.32,
   left: 195.12,
   width: 340.68,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Earnest Money", 
   top: 261.84,
   left: 196.8,
   width: 339.0,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined", 
   top: 307.80,
   left: 133.56,
   width: 100.56,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "payable to", 
   top: 307.80,
   left: 283.2,
   width: 252.6,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "undefined_2", 
   top: 319.32,
   left: 131.04,
   width: 103.08,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "payable to_2", 
   top: 319.32,
   left: 283.2,
   width: 252.6,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text35", 
   top: 341.801,
   left: 99.6914,
   width: 439.1836,
   height: 25.404
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "textarea",
   name: "Text36", 
   top: 387.605,
   left: 101.616,
   width: 435.334,
   height: 60
}
,
{
   page: 0,
   type: "checkbox",
   name: "If this box is checked the parties do NOT release each other of liability from the Contract", 
   top: 504.84,
   left: 72.72,
   width: 9.96,
   height: 9.96
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Buyer_2", 
   top: 607.32,
   left: 102.0,
   width: 107.96,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Buyer_3", 
   top: 607.32,
   left: 354.0,
   width: 105.95,
   height: 11.69
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller_2", 
   top: 676.32,
   left: 102.0,
   width: 107.96,
   height: 10.92
}
,
{
   page: 0,
   fontName: "Helvetica",
   fontSize: 10,
   type: "text",
   name: "Seller_3", 
   top: 676.32,
   left: 354.0,
   width: 106.719,
   height: 10.92
}
] }