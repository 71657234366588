export function cBColoradoAffiliatedBusinessDisclosure() {
    return [   //2023
    {
      page: 0,
      name: "logo",
      isText: false,
      type: "logo",
      top: 28.673,
      left: 53,
      width: 253,
      height: 43.2
   }
   ,
   {
       page: 0,
       name: "AFFILIATED BUSINESS ARRANGEMENT DISCLOSURE STATEMENT", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 51.6,
       left: 108,
       width: 355.44,
       height: 12.12
    }
    ,
    {
       page: 0,
       name: "consider purchasing with the assistance of Coldwell Banker Realty", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 10,
       top: 126.48,
       left: 111.24,
       width: 115.92,
       height: 12.12
    }
    ] }
    