import React from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";

export default function DocShareAgentLinkSuccessfull() {
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(
      closeModal({
        modalType: "DocShareAgentLinkSuccessfull",
      })
    );
  }

  return (
    <>
      <ModalWrapper size="small">
        <Segment>
          <Grid>
            <Grid.Column>
              <Header size="large" color="green">
                Linking Successful
              </Header>
              <Divider />
              <p className="text-large text bold bold blue small bottom margin ">
                Your transaction is now linked with the other side's agent!
              </p>
              <p className="text-medium small bottom margin">
                When you link with another agent on TransActioner, shared
                documents are sent as copies to their account and designated
                with a purple button.
              </p>
              <p className="text-medium small bottom margin">
                When you click the button to share a document with the other
                agent , we will send them a copy of the document.
              </p>
              <p className="text-medium small bottom margin">
                If you update the document after sending it to them, then
                double-click their button to send them another copy of the
                updated version.
              </p>
              <Button floated="right" onClick={() => handleClose()}>
                Close
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </ModalWrapper>
    </>
  );
}
