export function amendment() {
return [   //2024 Release 2024-02-18 20:09:56
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 92.251,
   left: 103.814,
   width: 397.965,
   height: 12.499
}
,
{
   page: 0,
   name: "1 The Sales Price in Paragraph 3 of the contract is", 
   isText: false,
   type: "checkbox",
   top: 151.52,
   left: 37.44,
   width: 11.64,
   height: 8.554
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 158.6,
   left: 469.44,
   width: 90.12,
   height: 12.72
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 172.04,
   left: 469.56,
   width: 90.00,
   height: 11.40
}
,
{
   page: 0,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 184.16,
   left: 469.56,
   width: 90.00,
   height: 11.52
}
,
{
   page: 0,
   name: "2 In addition to any repairs and treatments otherwise required by the contract Seller at Sellers", 
   isText: false,
   type: "checkbox",
   top: 197.4,
   left: 38.0061,
   width: 11.0739,
   height: 9.12
}
,
{
   page: 0,
   name: "Text1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 228.869,
   left: 152.993,
   width: 404.906,
   height: 10.938
}
,
{
   page: 0,
   name: "Text 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 239.26,
   left: 68.2805,
   width: 488.2635,
   height: 11.65
}
,
{
   page: 0,
   name: "Text 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 250.599,
   left: 68.22,
   width: 488.263,
   height: 11.650
}
,
{
   page: 0,
   name: "Text 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 261.672,
   left: 68.6065,
   width: 488.2635,
   height: 11.650
}
,
{
   page: 0,
   name: "3 The date in Paragraph 9 of the contract is changed to", 
   isText: false,
   type: "checkbox",
   top: 276.28,
   left: 37.44,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 274.086,
   left: 342.0,
   width: 151.32,
   height: 12.720
}
,
{
   page: 0,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 274.52,
   left: 513.24,
   width: 38.64,
   height: 12.72
}
,
{
   page: 0,
   name: "4 The amount in Paragraph 12A1b of the contract is changed to", 
   isText: false,
   type: "checkbox",
   top: 290.68,
   left: 37.44,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 288.052,
   left: 416.126,
   width: 123.120,
   height: 12.720
}
,
{
   page: 0,
   name: "5 The cost of lender required repairs and treatment as itemized on the attached list will be paid", 
   isText: false,
   type: "checkbox",
   top: 305.08,
   left: 38.0061,
   width: 11.0739,
   height: 9.12
}
,
{
   page: 0,
   name: "as follows", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 315.12,
   left: 136.08,
   width: 137.28,
   height: 11.76
}
,
{
   page: 0,
   name: "for an extension of the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 315.12,
   left: 338.88,
   width: 144.24,
   height: 11.76
}
,
{
   page: 0,
   name: "6 Buyer has paid Seller an additional Option Fee of", 
   isText: false,
   type: "checkbox",
   top: 329.72,
   left: 37.44,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "contract", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 329.092,
   left: 331.492,
   width: 109.208,
   height: 12.720
}
,
{
   page: 0,
   name: "be credited to the Sales Price", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 353.6,
   left: 68.88,
   width: 148.937,
   height: 13.432
}
,
{
   page: 0,
   name: "20_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 353.6,
   left: 238.368,
   width: 33.451,
   height: 12.008
}
,
{
   page: 0,
   name: "will", 
   isText: false,
   type: "checkbox",
   top: 355.24,
   left: 412.12,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "will not", 
   isText: false,
   type: "checkbox",
   top: 355.24,
   left: 447.04,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "7 Buyer waives the unrestricted right to terminate the contract for which the Option Fee was paid", 
   isText: false,
   type: "checkbox",
   top: 379.88,
   left: 37.44,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "8 The date for Buyer to give written notice to Seller that Buyer cannot obtain Buyer Approval as", 
   isText: false,
   type: "checkbox",
   top: 394.28,
   left: 38.0061,
   width: 11.6400,
   height: 9.12
}
,
{
   page: 0,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 405.352,
   left: 385.157,
   width: 105.575,
   height: 12.376
}
,
{
   page: 0,
   name: "20_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 405.32,
   left: 511.08,
   width: 38.28,
   height: 11.76
}
,
{
   page: 0,
   name: "9 Other Modifications Insert only factual statements and business details applicable to this sale", 
   isText: false,
   type: "checkbox",
   top: 418.8,
   left: 37.44,
   width: 11.64,
   height: 9.12
}
,
{
   page: 0,
   name: "Text7 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 418.004,
   left: 523.111,
   width: 30.300,
   height: 11.815
}
,
{
   page: 0,
   name: "Text5_1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 429.748,
   left: 69.3345,
   width: 486.2715,
   height: 11.816
}
,
{
   page: 0,
   name: "Text4_1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 441.515,
   left: 68.5638,
   width: 486.2712,
   height: 10.506
}
,
{
   page: 0,
   name: "Text3_1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 452.527,
   left: 68.1773,
   width: 486.2717,
   height: 10.507
}
,
{
   page: 0,
   name: "DATE OF FINAL ACCEPTANCE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 508.04,
   left: 126.72,
   width: 38.40,
   height: 11.411
}
,
{
   page: 0,
   name: "BROKER FILL IN THE", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 508.04,
   left: 395.28,
   width: 34.56,
   height: 11.411
}
,
{
   page: 0,
   name: "20_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 508.606,
   left: 203.28,
   width: 170.40,
   height: 11.411
}
] }
