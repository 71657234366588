export function counterproposal2023() {
    return [   //2023
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 143.32,
       left: 398.64,
       width: 174.00,
       height: 10.68
    }
    ,
    {
       page: 0,
       name: "contract dated", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 177.88,
       left: 109.32,
       width: 121.20,
       height: 10.68
    }
    ,
    {
       page: 0,
       name: "Contract between", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 177.88,
       left: 312.36,
       width: 260.28,
       height: 10.68
    }
    ,
    {
       page: 0,
       name: "Seller and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 189.4,
       left: 99.84,
       width: 306.36,
       height: 10.56
    }
    ,
    {
       page: 0,
       name: "of the following legally described real estate in the County of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 200.8,
       left: 298.44,
       width: 107.64,
       height: 10.68
    }
    ,
    {
       page: 0,
       name: "Text1", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 212.856,
       left: 49.7457,
       width: 522.9843,
       height: 54.327
    }
    ,
    {
       page: 0,
       name: "known as", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 267.702,
       left: 96.24,
       width: 431.40,
       height: 13.298
    }
    ,
    {
       page: 0,
       name: "Time of Day Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 384.102,
       left: 394.605,
       width: 122.162,
       height: 10.527
    }
    ,
    {
       page: 0,
       name: "No ChangeTime of Day Deadline", 
       isText: true,
       type: "checkbox",
       top: 383.808,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedTime of Day Deadline", 
       isText: true,
       type: "checkbox",
       top: 383.407,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineAlternative Earnest Money Deadline", 
       fontSize: 9,
       type: "date",
       top: 394.82,
       left: 321.219,
       width: 68.572,
       height: 12.018
    }
    ,
    {
       page: 0,
       name: "Alternative Earnest Money Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 395.833,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeAlternative Earnest Money Deadline", 
       isText: true,
       type: "checkbox",
       top: 395.76,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedAlternative Earnest Money Deadline", 
       isText: true,
       type: "checkbox",
       top: 395.76,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineRecord Title Deadline and Tax Certificate", 
       fontSize: 9,
       type: "date",
       top: 421.411,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Record Title Deadline and Tax Certificate", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 421.411,
       left: 394.605,
       width: 122.162,
       height: 10.782
    }
    ,
    {
       page: 0,
       name: "No ChangeRecord Title Deadline and Tax Certificate", 
       isText: true,
       type: "checkbox",
       top: 420.72,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedRecord Title Deadline and Tax Certificate", 
       isText: true,
       type: "checkbox",
       top: 420.72,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineRecord Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 433.393,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Record Title Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 433.393,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeRecord Title Objection Deadline", 
       isText: true,
       type: "checkbox",
       top: 433.32,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedRecord Title Objection Deadline", 
       isText: true,
       type: "checkbox",
       top: 433.32,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineOffRecord Title Deadline", 
       fontSize: 9,
       type: "date",
       top: 445.873,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "OffRecord Title Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 445.873,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeOffRecord Title Deadline", 
       isText: true,
       type: "checkbox",
       top: 445.8,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedOffRecord Title Deadline", 
       isText: true,
       type: "checkbox",
       top: 445.8,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineOffRecord Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 458.353,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "OffRecord Title Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 458.353,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeOffRecord Title Objection Deadline", 
       isText: true,
       type: "checkbox",
       top: 458.28,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedOffRecord Title Objection Deadline", 
       isText: true,
       type: "checkbox",
       top: 458.28,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineTitle Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 470.833,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Title Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 470.833,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeTitle Resolution Deadline", 
       isText: true,
       type: "checkbox",
       top: 470.76,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedTitle Resolution Deadline", 
       isText: true,
       type: "checkbox",
       top: 470.76,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineThird Party Right to PurchaseApprove Deadline", 
       fontSize: 9,
       type: "date",
       top: 483.313,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Third Party Right to PurchaseApprove Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 483.313,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeThird Party Right to PurchaseApprove Deadline", 
       isText: true,
       type: "checkbox",
       top: 483.24,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedThird Party Right to PurchaseApprove Deadline", 
       isText: true,
       type: "checkbox",
       top: 483.24,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineAssociation Documents Deadline", 
       fontSize: 9,
       type: "date",
       top: 508.393,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Association Documents Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 508.393,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeAssociation Documents Deadline", 
       isText: true,
       type: "checkbox",
       top: 508.32,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedAssociation Documents Deadline", 
       isText: true,
       type: "checkbox",
       top: 508.32,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineAssociation Documents Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 520.873,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Association Documents Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 520.873,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeAssociation Documents Termination Deadline", 
       isText: true,
       type: "checkbox",
       top: 520.8,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedAssociation Documents Termination Deadline", 
       isText: true,
       type: "checkbox",
       top: 520.8,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineSellers Property Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 545.833,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Sellers Property Disclosure Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 545.833,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeSellers Property Disclosure Deadline", 
       isText: true,
       type: "checkbox",
       top: 545.76,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedSellers Property Disclosure Deadline", 
       isText: true,
       type: "checkbox",
       top: 545.76,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineLeadBased Paint Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 558.313,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "LeadBased Paint Disclosure Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 558.313,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeLeadBased Paint Disclosure Deadline", 
       isText: true,
       type: "checkbox",
       top: 558.24,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedLeadBased Paint Disclosure Deadline", 
       isText: true,
       type: "checkbox",
       top: 558.24,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineNew Loan Application Deadline", 
       fontSize: 9,
       type: "date",
       top: 583.393,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "New Loan Application Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 583.393,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeNew Loan Application Deadline", 
       isText: true,
       type: "checkbox",
       top: 583.32,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedNew Loan Application Deadline", 
       isText: true,
       type: "checkbox",
       top: 583.32,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineNew Loan Terms Deadline", 
       fontSize: 9,
       type: "date",
       top: 595.873,
       left: 321.219,
       width: 68.572,
       height: 10.799
    }
    ,
    {
       page: 0,
       name: "New Loan Terms Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 595.873,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeNew Loan Terms Deadline", 
       isText: true,
       type: "checkbox",
       top: 595.8,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedNew Loan Terms Deadline", 
       isText: true,
       type: "checkbox",
       top: 595.8,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineNew Loan Availability Deadline", 
       fontSize: 9,
       type: "date",
       top: 608.353,
       left: 321.219,
       width: 68.572,
       height: 10.799
    }
    ,
    {
       page: 0,
       name: "New Loan Availability Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 608.353,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeNew Loan Availability Deadline", 
       isText: true,
       type: "checkbox",
       top: 608.28,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedNew Loan Availability Deadline", 
       isText: true,
       type: "checkbox",
       top: 608.28,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineBuyers Credit Information Deadline", 
       fontSize: 9,
       type: "date",
       top: 620.833,
       left: 321.219,
       width: 68.572,
       height: 10.799
    }
    ,
    {
       page: 0,
       name: "Buyers Credit Information Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 620.833,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeBuyers Credit Information Deadline", 
       isText: true,
       type: "checkbox",
       top: 620.76,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedBuyers Credit Information Deadline", 
       isText: true,
       type: "checkbox",
       top: 620.76,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineDisapproval of Buyers Credit Information Deadline", 
       fontSize: 9,
       type: "date",
       top: 633.313,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Disapproval of Buyers Credit Information Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 633.313,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeDisapproval of Buyers Credit Information Deadline", 
       isText: true,
       type: "checkbox",
       top: 633.24,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedDisapproval of Buyers Credit Information Deadline", 
       isText: true,
       type: "checkbox",
       top: 633.24,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineExisting Loan Deadline", 
       fontSize: 9,
       type: "date",
       top: 645.793,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Existing Loan Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 645.793,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeExisting Loan Deadline", 
       isText: true,
       type: "checkbox",
       top: 645.72,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedExisting Loan Deadline", 
       isText: true,
       type: "checkbox",
       top: 645.72,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineExisting Loan Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 658.393,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Existing Loan Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 658.393,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeExisting Loan Termination Deadline", 
       isText: true,
       type: "checkbox",
       top: 658.32,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedExisting Loan Termination Deadline", 
       isText: true,
       type: "checkbox",
       top: 658.32,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineLoan Transfer Approval Deadline", 
       fontSize: 9,
       type: "date",
       top: 670.873,
       left: 321.219,
       width: 68.572,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "Loan Transfer Approval Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 670.873,
       left: 394.605,
       width: 122.162,
       height: 11.400
    }
    ,
    {
       page: 0,
       name: "No ChangeLoan Transfer Approval Deadline", 
       isText: true,
       type: "checkbox",
       top: 670.8,
       left: 527.702,
       width: 11.002,
       height: 9.872
    }
    ,
    {
       page: 0,
       name: "DeletedLoan Transfer Approval Deadline", 
       isText: true,
       type: "checkbox",
       top: 670.8,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineSeller or Private Financing Deadline", 
       fontSize: 9,
       type: "date",
       top: 683.353,
       left: 321.219,
       width: 68.572,
       height: 11.3998
    }
    ,
    {
       page: 0,
       name: "Seller or Private Financing Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 683.353,
       left: 394.605,
       width: 122.162,
       height: 11.4004
    }
    ,
    {
       page: 0,
       name: "No ChangeSeller or Private Financing Deadline", 
       isText: true,
       type: "checkbox",
       top: 683.28,
       left: 527.702,
       width: 11.002,
       height: 9.8723
    }
    ,
    {
       page: 0,
       name: "DeletedSeller or Private Financing Deadline", 
       isText: true,
       type: "checkbox",
       top: 683.28,
       left: 558.713,
       width: 10.675,
       height: 10.3091
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineAppraisal Deadline", 
       fontSize: 9,
       type: "date",
       top: 708.3133,
       left: 321.219,
       width: 68.572,
       height: 11.4000
    }
    ,
    {
       page: 0,
       name: "Appraisal Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 708.3133,
       left: 394.605,
       width: 122.162,
       height: 11.4000
    }
    ,
    {
       page: 0,
       name: "No ChangeAppraisal Deadline", 
       isText: true,
       type: "checkbox",
       top: 708.24,
       left: 527.702,
       width: 11.002,
       height: 9.8723
    }
    ,
    {
       page: 0,
       name: "DeletedAppraisal Deadline", 
       isText: true,
       type: "checkbox",
       top: 708.24,
       left: 558.713,
       width: 10.675,
       height: 10.309
    }
    ,
    {
       page: 0,
       name: "DateField Date or DeadlineAppraisal Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 720.7933,
       left: 321.219,
       width: 68.572,
       height: 11.4000
    }
    ,
    {
       page: 0,
       name: "Appraisal Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 720.7933,
       left: 394.605,
       width: 122.162,
       height: 11.4000
    }
    ,
    {
       page: 0,
       name: "No ChangeAppraisal Objection Deadline", 
       isText: true,
       type: "checkbox",
       top: 720.72,
       left: 527.702,
       width: 11.002,
       height: 9.8723
    }
    ,
    {
       page: 0,
       name: "DeletedAppraisal Objection Deadline", 
       isText: true,
       type: "checkbox",
       top: 720.72,
       left: 558.713,
       width: 10.675,
       height: 10.3091
    }
    ,
    {
       page: 1,
       name: "DateField Appraisal Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 59.062,
       left: 342.114,
       width: 60.766,
       height: 10.490
    }
    ,
    {
       page: 1,
       name: "Appraisal Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 59.062,
       left: 405.996,
       width: 113.526,
       height: 9.910
    }
    ,
    {
       page: 1,
       name: "Text2", 
       isText: true,
       type: "checkbox",
       top: 59.738,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text3", 
       isText: true,
       type: "checkbox",
       top: 58.927,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField New ILC or New Survey Deadline", 
       fontSize: 9,
       type: "date",
       top: 83.405,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "New ILC or New Survey Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 83.405,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text4", 
       isText: true,
       type: "checkbox",
       top: 84.8,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text5", 
       isText: true,
       type: "checkbox",
       top: 83.636,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField New ILC or New Survey Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 96.005,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "New ILC or New Survey Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 96.005,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text6", 
       isText: true,
       type: "checkbox",
       top: 97.564,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text7", 
       isText: true,
       type: "checkbox",
       top: 96.564,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField New ILC or New Survey Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 108.485,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "New ILC or New Survey Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 108.485,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text8", 
       isText: true,
       type: "checkbox",
       top: 110.0,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text9", 
       isText: true,
       type: "checkbox",
       top: 108.836,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Water Rights Examination Deadline", 
       fontSize: 9,
       type: "date",
       top: 133.445,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Water Rights Examination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 133.445,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text10", 
       isText: true,
       type: "checkbox",
       top: 134.709,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text11", 
       isText: true,
       type: "checkbox",
       top: 134.036,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Mineral Rights Examination Deadline", 
       fontSize: 9,
       type: "date",
       top: 145.925,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Mineral Rights Examination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 145.925,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text12", 
       isText: true,
       type: "checkbox",
       top: 147.309,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text13", 
       isText: true,
       type: "checkbox",
       top: 146.145,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Inspection Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 158.405,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Inspection Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 158.405,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text14", 
       isText: true,
       type: "checkbox",
       top: 159.909,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text15", 
       isText: true,
       type: "checkbox",
       top: 159.073,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Inspection Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 171.005,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Inspection Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 171.005,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text16", 
       isText: true,
       type: "checkbox",
       top: 172.591,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text17", 
       isText: true,
       type: "checkbox",
       top: 171.427,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Inspection Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 184.102,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Inspection Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 184.102,
       left: 405.996,
       width: 113.526,
       height: 9.910
    }
    ,
    {
       page: 1,
       name: "Text18", 
       isText: true,
       type: "checkbox",
       top: 185.109,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text19", 
       isText: true,
       type: "checkbox",
       top: 184.109,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Property Insurance Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 195.965,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Property Insurance Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 195.965,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text20", 
       isText: true,
       type: "checkbox",
       top: 197.464,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text21", 
       isText: true,
       type: "checkbox",
       top: 196.464,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Due Diligence Documents Delivery Deadline", 
       fontSize: 9,
       type: "date",
       top: 208.445,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Due Diligence Documents Delivery Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 208.445,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text22", 
       isText: true,
       type: "checkbox",
       top: 209.982,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text23", 
       isText: true,
       type: "checkbox",
       top: 208.818,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Due Diligence Documents Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 220.925,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Due Diligence Documents Objection Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 220.925,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text24", 
       isText: true,
       type: "checkbox",
       top: 222.5,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text25", 
       isText: true,
       type: "checkbox",
       top: 221.5,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Due Diligence Documents Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 233.405,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Due Diligence Documents Resolution Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 233.405,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text26", 
       isText: true,
       type: "checkbox",
       top: 234.936,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text27", 
       isText: true,
       type: "checkbox",
       top: 233.773,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Environmental Inspection Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 246.005,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Environmental Inspection Objection Deadline CBS2 3 4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 246.005,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text28", 
       isText: true,
       type: "checkbox",
       top: 247.373,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text29", 
       isText: true,
       type: "checkbox",
       top: 246.291,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField ADA Evaluation Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 258.485,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "ADA Evaluation Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 258.485,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text30", 
       isText: true,
       type: "checkbox",
       top: 260.136,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text31", 
       isText: true,
       type: "checkbox",
       top: 258.973,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Conditional Sale Deadline", 
       fontSize: 9,
       type: "date",
       top: 270.965,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Conditional Sale Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 270.965,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text32", 
       isText: true,
       type: "checkbox",
       top: 272.491,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text33", 
       isText: true,
       type: "checkbox",
       top: 271.654,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField LeadBased Paint Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 283.445,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "LeadBased Paint Termination Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 283.445,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text34", 
       isText: true,
       type: "checkbox",
       top: 285.009,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text35", 
       isText: true,
       type: "checkbox",
       top: 284.091,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Estoppel Statements Deadline CBS2 3 4", 
       fontSize: 9,
       type: "date",
       top: 295.925,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Estoppel Statements Deadline CBS2 3 4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 295.925,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text36", 
       isText: true,
       type: "checkbox",
       top: 297.527,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text37", 
       isText: true,
       type: "checkbox",
       top: 296.363,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Estoppel Statements Termination Deadline CBS2 3 4", 
       fontSize: 9,
       type: "date",
       top: 308.405,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Estoppel Statements Termination Deadline CBS2 3 4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 308.405,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text38", 
       isText: true,
       type: "checkbox",
       top: 310.045,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text39", 
       isText: true,
       type: "checkbox",
       top: 308.963,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Closing Date", 
       fontSize: 9,
       type: "date",
       top: 333.485,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Closing Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 333.485,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text40", 
       isText: true,
       type: "checkbox",
       top: 335.163,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text41", 
       isText: true,
       type: "checkbox",
       top: 333.918,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "DateField Possession Date", 
       fontSize: 9,
       type: "date",
       top: 345.965,
       left: 342.114,
       width: 60.766,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Possession Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 345.965,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text42", 
       isText: true,
       type: "checkbox",
       top: 347.682,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text43", 
       isText: true,
       type: "checkbox",
       top: 346.763,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "Possession Time", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 358.8,
       left: 405.996,
       width: 113.526,
       height: 10.527
    }
    ,
    {
       page: 1,
       name: "Text44", 
       isText: true,
       type: "checkbox",
       top: 360.118,
       left: 530.589,
       width: 9.278,
       height: 9.084
    }
    ,
    {
       page: 1,
       name: "Text45", 
       isText: true,
       type: "checkbox",
       top: 359.2,
       left: 559.445,
       width: 9.758,
       height: 9.845
    }
    ,
    {
       page: 1,
       name: "Text46", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 371.63,
       left: 106.617,
       width: 230.901,
       height: 11.501
    }
    ,
    {
       page: 1,
       name: "Text48", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 371.667,
       left: 342.875,
       width: 175.527,
       height: 11.501
    }
    ,
    {
       page: 1,
       name: "Text47", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 383.405,
       left: 106.617,
       width: 230.901,
       height: 11.501
    }
    ,
    {
       page: 1,
       name: "Text49", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 383.958,
       left: 342.875,
       width: 175.527,
       height: 11.502
    }
    ,
    {
       page: 1,
       name: "Text58", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 456.193,
       left: 375.827,
       width: 89.397,
       height: 11.400
    }
    ,
    {
       page: 1,
       name: "fill_53", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 468.84,
       left: 477.158,
       width: 92.442,
       height: 11.40
    }
    ,
    {
       page: 1,
       name: "fill_55", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 481.773,
       left: 477.358,
       width: 92.242,
       height: 10.947
    }
    ,
    {
       page: 1,
       name: "fill_57", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 493.8,
       left: 477.214,
       width: 92.386,
       height: 11.4
    }
    ,
    {
       page: 1,
       name: "fill_59", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 506.28,
       left: 477.214,
       width: 92.386,
       height: 11.40
    }
    ,
    {
       page: 1,
       name: "Text53", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 519.132,
       left: 477.215,
       width: 92.385,
       height: 11.400
    }
    ,
    {
       page: 1,
       name: "Text50", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 531.577,
       left: 166.123,
       width: 195.082,
       height: 10.884
    }
    ,
    {
       page: 1,
       name: "Text52", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 531.577,
       left: 365.595,
       width: 98.125,
       height: 10.884
    }
    ,
    {
       page: 1,
       name: "Text55", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 532.599,
       left: 467.77,
       width: 101.83,
       height: 10.884
    }
    ,
    {
       page: 1,
       name: "Text51", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 543.421,
       left: 166.123,
       width: 195.082,
       height: 10.884
    }
    ,
    {
       page: 1,
       name: "Text54", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 543.421,
       left: 365.595,
       width: 98.125,
       height: 10.884
    }
    ,
    {
       page: 1,
       name: "Text56", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 544.54,
       left: 467.77,
       width: 101.83,
       height: 10.884
    }
    ,
    {
       page: 1,
       name: "fill_61", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 556.32,
       left: 477.378,
       width: 92.222,
       height: 11.40
    }
    ,
    {
       page: 1,
       name: "fill_63", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 568.8,
       left: 375.955,
       width: 88.925,
       height: 11.4
    }
    ,
    {
       page: 1,
       name: "fill_64", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 568.8,
       left: 477.051,
       width: 92.549,
       height: 11.4
    }
    ,
    {
       page: 1,
       name: "Text59", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 603.215,
       left: 52.8808,
       width: 521.0172,
       height: 67.911
    }
    ,
    {
       page: 1,
       name: "Text60", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 687.344,
       left: 52.3635,
       width: 521.4535,
       height: 54.2215
    }
    ,
    {
       page: 2,
       name: "Text61", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 9,
       top: 67.196,
       left: 50.8362,
       width: 520.5808,
       height: 258.208
    }
    ,
    {
       page: 2,
       name: "Date Acceptance Deadline", 
       fontSize: 9,
       type: "date",
       top: 347.322,
       left: 49.3811,
       width: 78.2709,
       height: 11.159
    }
    ,
    {
       page: 2,
       name: "43", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 346.468,
       left: 130.644,
       width: 61.901,
       height: 12.293
    }
    ,
    {
       page: 2,
       name: "Buyers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 416.961,
       left: 120.491,
       width: 182.520,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Buyers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 416.961,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Address 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 480.281,
       left: 121.091,
       width: 181.920,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Address 1_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 480.281,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Address 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 492.761,
       left: 121.091,
       width: 181.920,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Address 2_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 492.761,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Phone No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 505.361,
       left: 121.091,
       width: 181.920,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Phone No_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 505.361,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Fax No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 517.841,
       left: 121.091,
       width: 181.920,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Fax No_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 517.841,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Email Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 530.321,
       left: 121.091,
       width: 181.920,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Email Address_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 530.321,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Sellers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 565.361,
       left: 120.491,
       width: 182.520,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Sellers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 565.361,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Address 1_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 625.201,
       left: 121.091,
       width: 181.920,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Address 1_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 625.201,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Address 2_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 637.681,
       left: 121.091,
       width: 181.920,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Address 2_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 637.681,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Phone No_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 650.281,
       left: 121.091,
       width: 181.920,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Phone No_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 650.281,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Fax No_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 662.761,
       left: 121.091,
       width: 181.920,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Fax No_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 662.761,
       left: 395.771,
       width: 180.360,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Email Address_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 675.241,
       left: 120.371,
       width: 182.640,
       height: 11.880
    }
    ,
    {
       page: 2,
       name: "Email Address_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 675.241,
       left: 395.051,
       width: 181.080,
       height: 11.880
    }
    ] }
    