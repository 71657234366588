export function realVitalizeSellerPropertyAgreement() {
return [   //2024 Release 2024-06-10 12:13:30
{
   page: 0,
   name: "Property Owner Names", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 307.56,
   left: 155.52,
   width: 420.60,
   height: 13.745
}
,
{
   page: 0,
   name: "Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 344.48,
   left: 69.24,
   width: 239.76,
   height: 13.745
}
,
{
   page: 0,
   name: "Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 344.96,
   left: 353.64,
   width: 222.48,
   height: 13.745
}
,
{
   page: 0,
   name: "Property Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 381.92,
   left: 121.44,
   width: 454.68,
   height: 13.745
}
,
{
   page: 0,
   name: "City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 419.84,
   left: 61.2,
   width: 224.76,
   height: 13.745
}
,
{
   page: 0,
   name: "State", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 419.84,
   left: 327.6,
   width: 106.68,
   height: 13.745
}
,
{
   page: 0,
   name: "Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 419.84,
   left: 469.44,
   width: 106.68,
   height: 13.745
}
,
{
   page: 0,
   name: "Brokerage Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 457.52,
   left: 121.68,
   width: 454.44,
   height: 13.745
}
,
{
   page: 0,
   name: "Brokerage Office Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 495.2,
   left: 160.56,
   width: 415.56,
   height: 13.745
}
,
{
   page: 0,
   name: "List Price", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 533.0,
   left: 85.44,
   width: 199.08,
   height: 13.745
}
,
{
   page: 0,
   name: "ListSide not total Real Estate Commission", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 533.36,
   left: 510.48,
   width: 65.40,
   height: 13.745
}
,
{
   page: 0,
   name: "date Listing Expiration Date", 
   fontSize: 9,
   type: "date",
   top: 598.04,
   left: 412.56,
   width: 163.56,
   height: 13.745
}
,
{
   page: 0,
   name: "By checking this box I confirm that there is a fully", 
   isText: false,
   type: "checkbox",
   top: 602.24,
   left: 36.0,
   width: 16.56,
   height: 17.64
}
,
{
   page: 0,
   name: "Listing Agent Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 654.8,
   left: 134.64,
   width: 441.48,
   height: 13.745
}
,
{
   page: 0,
   name: "Listing Agent Email", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 693.6801,
   left: 134.64,
   width: 441.48,
   height: 13.7454
}
,
{
   page: 4,
   name: "Property Owner", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 127.695,
   left: 92.9457,
   width: 197.6293,
   height: 13.091
}
,
{
   page: 4,
   name: "Property Owner_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 127.04,
   left: 373.8,
   width: 197.629,
   height: 13.091
}
,
{
   page: 4,
   name: "Property Owner_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 269.295,
   left: 92.9457,
   width: 197.6293,
   height: 13.091
}
,
{
   page: 4,
   name: "Guarantor if applicable", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 268.64,
   left: 373.68,
   width: 197.629,
   height: 13.091
}
] }
