import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { changePageScaleSign } from "../../../app/annots/annotSlice";
import { useMediaQuery } from "react-responsive";
import { goToNextAnnot } from "./partyDocSignUtils";
import { openModal } from "../../../app/common/modals/modalSlice";

export default function PartyDocSignActionButtons() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageScaleSign, annotsSigningStatus } = useSelector(
    (state) => state.annot
  );
  const { party } = useSelector((state) => state.party);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleClose() {
    navigate(`/party/${party.id}/documents`);
  }

  function handleZoomIn() {
    if (pageScaleSign < 4) {
      dispatch(changePageScaleSign(pageScaleSign * 1.25));
    }
  }

  function handleZoomOut() {
    if (pageScaleSign > 0.5) {
      dispatch(changePageScaleSign(pageScaleSign / 1.25));
    }
  }

  return (
    <>
      <div
        className="large horizontal padding background-white"
        style={{
          top: "auto",
          bottom: "0",
          position: "fixed",
          width: "100%",
          height: "80px",
          borderTop: "1px solid #e8e8e8",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            float: "left",
          }}
        >
          <Button className="medium right margin" onClick={() => handleClose()}>
            Close
          </Button>
          <Icon
            size="large"
            color="grey"
            name="minus circle"
            onClick={() => handleZoomOut()}
            style={{ cursor: "pointer" }}
          />
          <p
            className="zero bottom margin"
            style={{
              marginLeft: "3px",
              marginRight: "6px",
              fontWeight: "bold",
            }}
          >
            {Math.round(pageScaleSign * 100)}%
          </p>
          <Icon
            size="large"
            color="grey"
            name="plus circle"
            onClick={() => handleZoomIn()}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            float: "right",
          }}
        >
          <Button
            color="orange"
            size="large"
            disabled={annotsSigningStatus.annotsToSign?.length > 0}
            content="Submit Form"
            onClick={() =>
              dispatch(
                openModal({
                  modalType: "PartyDocSignComplete",
                })
              )
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            float: "right",
          }}
        >
          <Button
            primary
            size="large"
            disabled={annotsSigningStatus.annotsToSign?.length === 0}
            content={
              isMobile
                ? "Next"
                : annotsSigningStatus.annotsToSign?.length > 0
                ? "Go To Next Signature"
                : "All Signatures Complete"
            }
            onClick={() => goToNextAnnot(annotsSigningStatus.nextAnnotToSign)}
          />
        </div>
        <div
          className="large right margin"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            float: "right",
          }}
        >
          <h4>
            Signatures Complete: {annotsSigningStatus.annotsSigned?.length} /{" "}
            {annotsSigningStatus.annotsTotalToSign?.length}
          </h4>
        </div>
      </div>
    </>
  );
}
