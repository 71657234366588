import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
  Message,
} from "semantic-ui-react";
import { uploadBlobToStorage } from "../../../app/firestore/firebaseService";
import { updateTransactionInDb } from "../../../app/firestore/firestoreService";
import { useDropzone } from "react-dropzone";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";

export default function TransactionPhotoUpload() {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const { transaction } = useSelector((state) => state.transaction);

  const cropperRef = useRef(null);

  const dropzoneStyles = {
    textAlign: "center",
    cursor: "pointer",
    paddingTop: "36px",
    paddingBottom: "36px",
  };

  const dropzoneActive = {
    border: "dashed 3px #eee",
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const firstFile = acceptedFiles?.[0];
      if (!firstFile) {
        return;
      }
      setFile(
        Object.assign(firstFile, {
          preview: URL.createObjectURL(firstFile),
        })
      );
    },
    [setFile]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function handleCrop() {
    setLoading(true);
    setError(null);
    setMessage(null);
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      uploadBlob(blob);
    }, "image/jpeg");
  }

  async function uploadBlob(blob) {
    const docRef = `propertyPics/${transaction.id}/propertyPic.jpeg`;
    try {
      const imageUrl = await uploadBlobToStorage(docRef, blob);
      await updateTransactionInDb(transaction.id, { pic: imageUrl });
    } catch (e) {
      setLoading(false);
      setError(e.message);
      return;
    }
    setLoading(false);
    dispatch(
      closeModal({
        modalType: "TransactionPhotoUpload",
      })
    );
  }

  if (file) {
    return (
      <ModalWrapper>
        <div className="large padding">
          <Cropper
            src={file.preview}
            aspectRatio={4 / 3}
            preview=".img-preview"
            guides={false}
            viewMode={1}
            dragMode="move"
            scalable={true}
            cropBoxMovable={true}
            cropBoxResizable={true}
            ref={cropperRef}
          />
          <div
            className="medium top margin"
            style={{ display: "block", marginBottom: "70px" }}
          >
            {loading ? (
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            ) : (
              <Button
                primary
                size="large"
                floated="right"
                onClick={() => handleCrop()}
              >
                Crop and Save
              </Button>
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper>
      <Grid>
        <Grid.Column width={16}>
          <div
            {...getRootProps()}
            style={
              isDragActive
                ? { ...dropzoneStyles, ...dropzoneActive }
                : dropzoneStyles
            }
          >
            <input {...getInputProps()} />
            <Icon name="computer" size="huge" color="grey" />
            <Header color="grey" style={{ marginTop: "6px" }}>
              Drag and drop an image here
              <br />
              or click to select a file
            </Header>
          </div>
          {loading ? <LoadingComponent /> : ""}
          {(error || message) && (
            <Message
              negative
              status={error ? "error" : "success"}
              w={250}
              borderRadius={5}
              m={2}
            >
              <Message.Header w={200}>{error || message}</Message.Header>
            </Message>
          )}
        </Grid.Column>
      </Grid>
    </ModalWrapper>
  );
}
