export function residentialBuyerRepresentation() {
return [   //2024 Release 2024-03-12 22:03:13
{
   page: 0,
   name: "Client 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 164.52,
   left: 99,
   width: 472.68,
   height: 11.52
}
,
{
   page: 0,
   name: "Client 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 177,
   left: 99,
   width: 472.68,
   height: 11.4
}
,
{
   page: 0,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 189.36,
   left: 123.96,
   width: 447.72,
   height: 11.04
}
,
{
   page: 0,
   name: "City State Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 201.36,
   left: 155.4,
   width: 416.28,
   height: 11.40
}
,
{
   page: 0,
   name: "Phone", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 213.72,
   left: 115.32,
   width: 456.36,
   height: 11.40
}
,
{
   page: 0,
   name: "EmailFax", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 226.08,
   left: 132.24,
   width: 232.56,
   height: 11.52
}
,
{
   page: 0,
   name: "Email Fax", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 226.08,
   left: 418.32,
   width: 153.36,
   height: 11.52
}
,
{
   page: 0,
   name: "Broker", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 245.64,
   left: 102.6,
   width: 469.08,
   height: 11.52
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 258.24,
   left: 99,
   width: 472.68,
   height: 11.52
}
,
{
   page: 0,
   name: "Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 270.96,
   left: 124.08,
   width: 448.92,
   height: 11.52
}
,
{
   page: 0,
   name: "City State Zip_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 283.56,
   left: 156.36,
   width: 416.64,
   height: 11.52
}
,
{
   page: 0,
   name: "Phone_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 296.28,
   left: 115.44,
   width: 457.56,
   height: 11.52
}
,
{
   page: 0,
   name: "EmailFax_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 308.88,
   left: 129.24,
   width: 239.76,
   height: 11.52
}
,
{
   page: 0,
   name: "EmailFax_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 308.88,
   left: 424.08,
   width: 148.92,
   height: 11.52
}
,
{
   page: 0,
   name: "following areas 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 443.28,
   left: 171.72,
   width: 400.44,
   height: 11.52
}
,
{
   page: 0,
   name: "following areas 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 455.88,
   left: 95.16,
   width: 475.56,
   height: 11.52
}
,
{
   page: 0,
   name: "following areas 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 468.48,
   left: 95.16,
   width: 475.56,
   height: 11.52
}
,
{
   page: 0,
   name: "Text47", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 481.877,
   left: 94.3736,
   width: 476.7324,
   height: 11.276
}
,
{
   page: 0,
   name: "TERM This agreement commences on", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 542.04,
   left: 263.4,
   width: 289.32,
   height: 11.52
}
,
{
   page: 0,
   name: "ends at 1159 pm on", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 554.76,
   left: 174.96,
   width: 259.68,
   height: 11.52
}
,
{
   page: 0,
   name: "signature Initialed BrokerAssociate", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 719.28,
   left: 326.76,
   width: 42.60,
   height: 11.52
}
,
{
   page: 1,
   name: "BuyerTenant Representation Agreement between", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 30.84,
   left: 244.08,
   width: 335.04,
   height: 11.64
}
,
{
   page: 1,
   name: "acquiring property in the market area", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 158.4,
   left: 256.44,
   width: 290.16,
   height: 11.64
}
,
{
   page: 1,
   name: "signature Initialed BrokerAssociate_2", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 732.595,
   left: 326.76,
   width: 42.60,
   height: 10.925
}
,
{
   page: 1,
   name: "Check Box48", 
   isText: false,
   type: "checkbox",
   top: 224.64,
   left: 48.7615,
   width: 13.7103,
   height: 13.71
}
,
{
   page: 1,
   name: "Check Box49", 
   isText: false,
   type: "checkbox",
   top: 446.559,
   left: 49.4765,
   width: 13.7103,
   height: 13.711
}
,
{
   page: 2,
   name: "BuyerTenant Representation Agreement between_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 29.409,
   left: 244.08,
   width: 335.04,
   height: 13.071
}
,
{
   page: 2,
   name: "A Commission The parties agree that Broker will receive a commission calculated as follows 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 224.614,
   left: 541.68,
   width: 22.80,
   height: 16.346
}
,
{
   page: 2,
   name: "agrees to lease property in the market area a fee equal to check only one box", 
   isText: false,
   type: "checkbox",
   top: 261.24,
   left: 462.24,
   width: 9.84,
   height: 7.68
}
,
{
   page: 2,
   name: "months rent or", 
   isText: false,
   type: "checkbox",
   top: 275.4,
   left: 153.36,
   width: 9.84,
   height: 7.68
}
,
{
   page: 2,
   name: "fee equal to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 656.4,
   left: 175.08,
   width: 392.52,
   height: 11.52
}
,
{
   page: 2,
   name: "signature Initialed BrokerAssociate_3", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 726.9423,
   left: 326.76,
   width: 42.60,
   height: 16.5777
}
,
{
   page: 2,
   name: "Text50", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 256.667,
   left: 473.439,
   width: 47.760,
   height: 13.421
}
,
{
   page: 2,
   name: "Text51", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 269.822,
   left: 163.724,
   width: 34.893,
   height: 14.850
}
,
{
   page: 3,
   name: "BuyerTenant Representation Agreement between_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 30.84,
   left: 244.08,
   width: 335.04,
   height: 11.64
}
,
{
   page: 3,
   name: "3 Other", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 57.72,
   left: 144.24,
   width: 428.76,
   height: 11.64
}
,
{
   page: 3,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 70.32,
   left: 114,
   width: 459,
   height: 11.64
}
,
{
   page: 3,
   name: "Text52", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 83.649,
   left: 113.677,
   width: 458.859,
   height: 10.561
}
,
{
   page: 3,
   name: "and continuing for", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 120.72,
   left: 169.44,
   width: 32.52,
   height: 11.64
}
,
{
   page: 3,
   name: "County Amounts payable to Broker are to be paid in cash in", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 278.16,
   left: 373.56,
   width: 122.16,
   height: 11.64
}
,
{
   page: 3,
   name: "undefined_11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 645.84,
   left: 349.92,
   width: 226.20,
   height: 11.64
}
,
{
   page: 3,
   name: "signature Initialed BrokerAssociate_4", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 731.88,
   left: 326.76,
   width: 42.60,
   height: 11.64
}
,
{
   page: 3,
   name: "Check Box56", 
   isText: false,
   type: "checkbox",
   top: 595.839,
   left: 62.88,
   width: 11.2699,
   height: 10.540
}
,
{
   page: 3,
   name: "Check Box58", 
   isText: false,
   type: "checkbox",
   top: 596.269,
   left: 328.92,
   width: 11.27,
   height: 10.540
}
,
{
   page: 3,
   name: "Check Box53", 
   isText: false,
   type: "checkbox",
   top: 609.419,
   left: 62.88,
   width: 11.2699,
   height: 10.540
}
,
{
   page: 3,
   name: "Check Box55", 
   isText: false,
   type: "checkbox",
   top: 608.708,
   left: 328.92,
   width: 11.27,
   height: 10.540
}
,
{
   page: 3,
   name: "Check Box54", 
   isText: false,
   type: "checkbox",
   top: 622.293,
   left: 62.88,
   width: 11.2699,
   height: 10.539
}
,
{
   page: 3,
   name: "Check Box59", 
   isText: false,
   type: "checkbox",
   top: 621.008,
   left: 328.92,
   width: 11.27,
   height: 10.539
}
,
{
   page: 3,
   name: "Check Box57", 
   isText: false,
   type: "checkbox",
   top: 636.307,
   left: 62.88,
   width: 11.2699,
   height: 10.539
}
,
{
   page: 3,
   name: "Check Box60", 
   isText: false,
   type: "checkbox",
   top: 633.587,
   left: 328.92,
   width: 11.27,
   height: 10.540
}
,
{
   page: 3,
   name: "Check Box61", 
   isText: false,
   type: "checkbox",
   top: 646.886,
   left: 328.92,
   width: 11.27,
   height: 10.540
}
,
{
   page: 3,
   name: "Text62", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 683.449,
   left: 48.8892,
   width: 525.6318,
   height: 49.704
}
,
{
   page: 4,
   name: "BuyerTenant Representation Agree between_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 26.699,
   left: 244.08,
   width: 335.04,
   height: 15.781
}
,
{
   page: 4,
   name: "Brokers Printed Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 530.95,
   left: 27.0,
   width: 199.08,
   height: 14.935
}
,
{
   page: 4,
   name: "License No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 530.95,
   left: 231.36,
   width: 56.76,
   height: 14.935
}
,
{
   page: 4,
   name: "signature Brokersigns", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.495,
   left: 27.0,
   width: 198.379,
   height: 15.750
}
,
{
   page: 4,
   name: "Brokers Signature", 
   isText: false,
   type: "checkbox",
   top: 588.24,
   left: 27,
   width: 9.84,
   height: 7.68
}
,
{
   page: 4,
   name: "Brokers Associates Signature as an authorized", 
   isText: false,
   type: "checkbox",
   top: 600.48,
   left: 27,
   width: 9.84,
   height: 7.68
}
,
{
   page: 4,
   name: "Brokers Associates Printed Name if applicable", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 630.99,
   left: 27.0,
   width: 250.8,
   height: 14.935
}
,
{
   page: 4,
   name: "Clients Printed Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 530.39,
   left: 324.0,
   width: 252.12,
   height: 14.935
}
,
{
   page: 4,
   name: "Clients Printed Name_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 629.99,
   left: 324.0,
   width: 252.12,
   height: 14.935
}
,
{
   page: 4,
   name: "Date brokerSigns", 
   fontSize: 9,
   type: "date",
   top: 567.115,
   left: 228.965,
   width: 75.036,
   height: 17.111
}
] }
