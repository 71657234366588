export function gTARFHALoan() {
return [   //2024 Release 2024-04-18 19:56:46
{
   page: 0,
   name: "within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 376.08,
   left: 87.96,
   width: 27.24,
   height: 10.32
}
,
{
   page: 0,
   name: "1 LOAN The Contract is contingent upon Buyer qualifying for a FHA Loan in the amount of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 195.6,
   left: 441.72,
   width: 93.84,
   height: 10.32
}
,
{
   page: 0,
   name: "Buyer until", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 218.52,
   left: 93.84,
   width: 33.12,
   height: 10.32
}
,
{
   page: 0,
   name: "closing costs of not less than", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 636.84,
   left: 182.16,
   width: 187.80,
   height: 10.32
}
,
{
   page: 0,
   name: "Text55", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 127.673,
   left: 28.8001,
   width: 561.0569,
   height: 15.455
}
,
{
   page: 1,
   name: "In addition seller agrees to pay on behalf of Buyer loan discount points and FHA closing costs which include", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 185.52,
   left: 419.64,
   width: 149.04,
   height: 15.24
}
,
{
   page: 1,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 216.24,
   left: 425.16,
   width: 143.52,
   height: 10.32
}
,
{
   page: 1,
   name: "5ADDITIONAL PROVISIONS", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 330.36,
   left: 169.2,
   width: 417.36,
   height: 15.24
}
,
{
   page: 1,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 347.88,
   left: 33.84,
   width: 552.72,
   height: 15.24
}
,
{
   page: 1,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 365.28,
   left: 33.84,
   width: 552.72,
   height: 15.24
}
,
{
   page: 1,
   name: "3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 382.8,
   left: 33.84,
   width: 552.72,
   height: 15.24
}
,
{
   page: 1,
   name: "4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 400.32,
   left: 33.84,
   width: 552.72,
   height: 15.24
}
] }
