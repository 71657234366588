import { Formik, Form } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  Header,
  Segment,
  Table,
} from "semantic-ui-react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import {
  addDeadlineTemplateToDb,
  updateDeadlineTemplateInDb,
} from "../../../app/firestore/firestoreService";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyNumberInput from "../../../app/common/form/MyNumberInput";
import MyRadioButtons from "../../../app/common/form/MyRadioButtons";
import {
  startingDateOptions,
  tableForDeadlineTemplates,
} from "../../../app/common/categoryData/categoryOptions";

export default function DeadlineTemplatesEdit({ deadlineTemplate }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  let initialValues = deadlineTemplate
    ? deadlineTemplate
    : {
        name: "",
        alternativeEarnestMoneyDeadline: {
          event: "Alternative Earnest Money Deadline",
          startingDateType: "",
          days: "",
        },
        recordTitleDeadline: {
          event: "Record Title Deadline (and Tax Certificate)",
          startingDateType: "",
          days: "",
        },
        recordTitleObjectionDeadline: {
          event: "Record Title Objection Deadline",
          startingDateType: "",
          days: "",
        },
        offRecordTitleDeadline: {
          event: "Off-Record Title Deadline",
          startingDateType: "",
          days: "",
        },
        offRecordTitleObjectionDeadline: {
          event: "Off-Record Title Objection Deadline",
          startingDateType: "",
          days: "",
        },
        titleResolutionDeadline: {
          event: "Title Resolution Deadline",
          startingDateType: "",
          days: "",
        },
        thirdPartyRightToPurchaseApproveDeadline: {
          event: "Third Party Right to Purchase/Approve Deadline",
          startingDateType: "",
          days: "",
        },
        associationDocumentsDeadline: {
          event: "Association Documents Deadline",
          startingDateType: "",
          days: "",
        },
        associationDocumentsTerminationDeadline: {
          event: "Association Documents Termination Deadline",
          startingDateType: "",
          days: "",
        },
        sellersPropertyDisclosureDeadline: {
          event: "Seller's Property Disclosure Deadline",
          startingDateType: "",
          days: "",
        },
        leadBasedPaintDisclosureDeadline: {
          event: "Lead-Based Paint Disclosure Deadline",
          startingDateType: "",
          days: "",
        },
        newLoanApplicationDeadline: {
          event: "New Loan Application Deadline",
          startingDateType: "",
          days: "",
        },
        newLoanTermsDeadline: {
          event: "New Loan Terms Deadline",
          startingDateType: "",
          days: "",
        },
        newLoanAvailabilityDeadline: {
          event: "New Loan Availability Deadline",
          startingDateType: "",
          days: "",
        },
        buyersCreditInformationDeadline: {
          event: "Buyer's Credit Information Deadline",
          startingDateType: "",
          days: "",
        },
        disapprovalOfBuyersCreditInformationDeadline: {
          event: "Disapproval of Buyer's Credit Information Deadline",
          startingDateType: "",
          days: "",
        },
        existingLoanDeadline: {
          event: "Existing Loan Deadline",
          startingDateType: "",
          days: "",
        },
        existingLoanTerminationDeadline: {
          event: "Existing Loan Termination Deadline",
          startingDateType: "",
          days: "",
        },
        loanTransferApprovalDeadline: {
          event: "Loan Transfer Approval Deadline",
          startingDateType: "",
          days: "",
        },
        sellerOrPrivateFinancingDeadline: {
          event: "Seller or Private Financing Deadline",
          startingDateType: "",
          days: "",
        },
        appraisalDeadline: {
          event: "Appraisal Deadline",
          startingDateType: "",
          days: "",
        },
        appraisalObjectionDeadline: {
          event: "Appraisal Objection Deadline",
          startingDateType: "",
          days: "",
        },
        appraisalResolutionDeadline: {
          event: "Appraisal Resolution Deadline",
          startingDateType: "",
          days: "",
        },
        newIlcOrNewSurveyDeadline: {
          event: "New ILC or New Survey Deadline",
          startingDateType: "",
          days: "",
        },
        newIlcOrNewSurveyObjectionDeadline: {
          event: "New ILC or New Survey Objection Deadline",
          startingDateType: "",
          days: "",
        },
        newIlcOrNewSurveyResolutionDeadline: {
          event: "New ILC or New Survey Resolution Deadline",
          startingDateType: "",
          days: "",
        },
        waterRightsExaminationDeadline: {
          event: "Water Rights Examination Deadline",
          startingDateType: "",
          days: "",
        },
        mineralRightsExaminationDeadline: {
          event: "Mineral Rights Examination Deadline",
          startingDateType: "",
          days: "",
        },
        inspectionTerminationDeadline: {
          event: "Inspection Termination Deadline",
          startingDateType: "",
          days: "",
        },
        inspectionObjectionDeadline: {
          event: "Inspection Objection Deadline",
          startingDateType: "",
          days: "",
        },
        inspectionResolutionDeadline: {
          event: "Inspection Resolution Deadline",
          startingDateType: "",
          days: "",
        },
        propertyInsuranceTerminationDeadline: {
          event: "Property Insurance Termination Deadline",
          startingDateType: "",
          days: "",
        },
        dueDiligenceDocumentsDeliveryDeadline: {
          event: "Due Diligence Documents Delivery Deadline",
          startingDateType: "",
          days: "",
        },
        dueDiligenceDocumentsObjectionDeadline: {
          event: "Due Diligence Documents Objection Deadline",
          startingDateType: "",
          days: "",
        },
        dueDiligenceDocumentsResolutionDeadline: {
          event: "Due Diligence Documents Resolution Deadline",
          startingDateType: "",
          days: "",
        },
        conditionalSaleDeadline: {
          event: "Conditional Sale Deadline",
          startingDateType: "",
          days: "",
        },
        leadBasedPaintTerminationDeadline: {
          event: "Lead Based Paint Termination Deadline",
          startingDateType: "",
          days: "",
        },
        possessionDate: {
          event: "Possession Date",
          startingDateType: "",
          days: "",
        },
        acceptanceDeadlineDate: {
          event: "Acceptance Deadline Date",
          startingDateType: "",
          days: "",
        },
        closingDate: {
          event: "Closing Date",
          startingDateType: "",
          days: "",
        },
      };

  const validationSchema = Yup.object({
    name: Yup.string().required("You must provide a name"),
  });

  const deadlines = tableForDeadlineTemplates;

  return (
    <ModalWrapper>
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                deadlineTemplate
                  ? await updateDeadlineTemplateInDb(values.id, values)
                  : await addDeadlineTemplateToDb(values);
                setSubmitting(false);
                toast.success("Deadline template successfully updated");
                dispatch(
                  closeModal({
                    modalType: "DeadlineTemplatesEdit",
                  })
                );
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid, values }) => (
              <Form className="ui form" autoComplete="off">
                <Header size="large" color="blue">
                  {deadlineTemplate
                    ? "Edit Deadline Template"
                    : "Create Deadline Template"}
                </Header>
                <Divider />
                <Grid>
                  <Grid.Row className="small vertical padding">
                    <Grid.Column mobile={16} computer={16}>
                      <Header
                        as="h5"
                        color="blue"
                        className="mini bottom margin"
                      >
                        Template Name
                      </Header>
                      <MyTextInput
                        name="name"
                        placeholder="Add template name..."
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Table compact sortable celled>
                  <Table.Header className="white-blue-table-header">
                    <Table.Row>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>Event</Table.HeaderCell>
                      <Table.HeaderCell>
                        Contract | Closing | MEC
                      </Table.HeaderCell>
                      <Table.HeaderCell>Days</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {deadlines.length !== 0 &&
                      deadlines.map((deadline) => (
                        <Table.Row
                          key={deadline.event}
                          className={deadline.isTopic ? "table-row-blue" : null}
                        >
                          {deadline.isTopic ? (
                            <>
                              <Table.Cell></Table.Cell>
                              <Table.Cell></Table.Cell>
                              <Table.Cell>{deadline.event}</Table.Cell>
                              <Table.Cell></Table.Cell>
                              <Table.Cell></Table.Cell>
                            </>
                          ) : (
                            <>
                              <Table.Cell collapsing>
                                {deadline.itemNumber}
                              </Table.Cell>
                              <Table.Cell collapsing>
                                {deadline.reference}
                              </Table.Cell>
                              <Table.Cell>{deadline.event}</Table.Cell>
                              <Table.Cell>
                                <MyRadioButtons
                                  name={deadline.name + ".startingDateType"}
                                  options={startingDateOptions}
                                  noLabels={true}
                                  size={"small"}
                                />
                              </Table.Cell>
                              <Table.Cell style={{ maxWidth: "80px" }}>
                                <MyNumberInput name={deadline.name + ".days"} />
                              </Table.Cell>
                            </>
                          )}
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
                <Divider className="medium top margin" />
                <Button
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                  type="submit"
                  floated={isMobile ? null : "right"}
                  primary
                  content="Submit"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "DeadlineTemplatesEdit",
                      })
                    )
                  }
                  to="#"
                  type="button"
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </ModalWrapper>
  );
}
