import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getDocDownloadUrl } from "../../../app/firestore/firebaseService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { getDocAndUrl } from "../../docs/docSlice";
import { FormTemplateFillDashboard } from "./FormTemplateFillDashboard";

export default function FormTemplateEditPage() {
  const dispatch = useDispatch();
  const { formTemplates } = useSelector((state) => state.formTemplates);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { doc, docUrl } = useSelector((state) => state.doc);
  let { formId } = useParams();
  const location = useLocation();
  const lastPath = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    if (formTemplates?.length > 0) {
      const newFormTemplate = formTemplates.filter(
        (template) => template.id === formId
      )[0];

      if (newFormTemplate) {
        const agentState = currentUserProfile.state || "Colorado";
        getDocDownloadUrl(
          `forms${agentState}/${newFormTemplate.title}.pdf`
        ).then((url) => {
          dispatch(
            getDocAndUrl({
              doc: newFormTemplate,
              docUrl: url,
            })
          );
        });
      }
    }
  }, [formId, formTemplates, currentUserProfile, dispatch]);

  // doc.id !== docId is for switching documents and making sure we
  // have new document loaded in store

  if (!doc.id || doc.id !== formId || !docUrl) {
    return <LoadingComponent />;
  }

  return <>{lastPath === "fill" && <FormTemplateFillDashboard />}</>;
}
