export function listingTerminationAgreementHSPR() {
    return [   //2023
    {
       page: 0,
       name: "seller", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 121.08,
       left: 332.88,
       width: 213.109,
       height: 11.455
    }
    ,
    {
       page: 0,
       name: "compensation", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 458.593,
       left: 78.2436,
       width: 73.5604,
       height: 12.763
    }
    ,
    {
       page: 0,
       name: "brokerNameTop", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 133.67,
       left: 150.546,
       width: 275.019,
       height: 10.872
    }
    ,
    {
       page: 0,
       name: "date contractDate", 
       fontSize: 9,
       type: "date",
       top: 229.746,
       left: 232.365,
       width: 113.345,
       height: 10.219
    }
    ,
    {
       page: 0,
       name: "addressOneLine", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 241.528,
       left: 239.983,
       width: 311.673,
       height: 12.837
    }
    ,
    {
       page: 0,
       name: "date Termination", 
       fontSize: 9,
       type: "date",
       top: 399.275,
       left: 136.146,
       width: 95.673,
       height: 10.872
    }
    ,
    {
       page: 0,
       name: "Text48", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 265.747,
       left: 75.2731,
       width: 472.0379,
       height: 13.491
    }
    ,
    {
       page: 1,
       name: "signature BrokerageFirm", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 661.239,
       left: 324.12,
       width: 172.909,
       height: 13.756
    }
    ,
    {
       page: 1,
       name: "seller1Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 592,
       left: 88.364,
       width: 172.909,
       height: 12.186
    }
    ,
    {
       page: 1,
       name: "seller2Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 592,
       left: 339.056,
       width: 172.909,
       height: 12.185
    }
    ,
    {
       page: 1,
       name: "brokerName", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 677,
       left: 95.9822,
       width: 172.9088,
       height: 12.185
    }
    ,
    {
       page: 1,
       name: "signature Broker", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 12,
       top: 660.2,
       left: 72.964,
       width: 172.909,
       height: 13.757
    }
    ,
    {
       page: 1,
       name: "Text44", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 677,
       left: 350.365,
       width: 172.909,
       height: 12.186
    }
    ,
    {
       page: 1,
       name: "Text45", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 9,
       top: 689,
       left: 350.183,
       width: 172.910,
       height: 12.1851
    }
    ,
    {
       page: 1,
       name: "date BrokerSigns", 
       fontSize: 9,
       type: "date",
       top: 702,
       left: 95.5641,
       width: 172.9089,
       height: 12.1855
    }
    ,
    {
       page: 1,
       name: "date BrokerageFirmSigns", 
       fontSize: 9,
       type: "date",
       top: 702,
       left: 350.183,
       width: 172.910,
       height: 12.1854
    }
    ] }
    