export function referralAgreementReceivingAgentHSPR() {

   return [   //2023
   {
      page: 0,
      name: "Originating Agent", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 210.785,
      left: 170.538,
      width: 295.899,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Phone Number", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 234.52,
      left: 156.0,
      width: 123.12,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Email", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 233.447,
      left: 310.32,
      width: 159.36,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "undefined_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 258.443,
      left: 185.182,
      width: 284.652,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Text32", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 12,
      top: 282.361,
      left: 218.096,
      width: 161.127,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Percent of GCI gross commission agreed upon", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 305.6,
      left: 312.6,
      width: 67.167,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Receiving Agent", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 329.844,
      left: 162.109,
      width: 302.739,
      height: 13.112
   }
   ,
   {
      page: 0,
      name: "Phone Number_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 353.8,
      left: 154.68,
      width: 124.08,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Email_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 353.68,
      left: 309.84,
      width: 153.12,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Brokerage Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 376.88,
      left: 182.88,
      width: 282.72,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Referral Type Listing", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 424.709,
      left: 184.08,
      width: 32.28,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Buyer", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 424.709,
      left: 255.12,
      width: 27.36,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Client Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 447.76,
      left: 141.6,
      width: 324.24,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 471.88,
      left: 120.24,
      width: 347.04,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Home Phone", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 497.08,
      left: 143.76,
      width: 111.72,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Cell Phone", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 496.72,
      left: 311.28,
      width: 153.48,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Email_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 519.76,
      left: 107.04,
      width: 360.12,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "undefined_3", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 655.034,
      left: 77.8137,
      width: 152.2803,
      height: 13.113
   }
   ,
   {
      page: 0,
      name: "Date_3", 
      fontSize: 9,
      type: "date",
      top: 695.2,
      left: 102.72,
      width: 83.28,
      height: 13.1127
   }
   ,
   {
      page: 0,
      name: "Date_4", 
      fontSize: 9,
      type: "date",
      top: 694.52,
      left: 351.6,
      width: 70.68,
      height: 13.1127
   }
   ] }
   
