export function noticetoTerminateSeller() {
   return [   //2024 Release 2023-12-27 20:02:04
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
    {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 160.84,
      left: 398.52,
      width: 174.00,
      height: 12.513
   }
   ,
   {
      page: 0,
      name: "This Notice terminates the contract dated", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 186.807,
      left: 217.56,
      width: 138.96,
      height: 12.513
   }
   ,
   {
      page: 0,
      name: "between", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 202.916,
      left: 52.3931,
      width: 467.1089,
      height: 12.513
   }
   ,
   {
      page: 0,
      name: "Seller and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 222.16,
      left: 51.8391,
      width: 489.1209,
      height: 12.513
   }
   ,
   {
      page: 0,
      name: "addressKnownAs", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 256.375,
      left: 50.4,
      width: 477.285,
      height: 12.512
   }
   ,
   {
      page: 0,
      name: "undefined", 
      isText: false,
      type: "checkbox",
      top: 327.2,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_2", 
      isText: false,
      type: "checkbox",
      top: 327.2,
      left: 314.88,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_3", 
      isText: false,
      type: "checkbox",
      top: 342.08,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_4", 
      isText: false,
      type: "checkbox",
      top: 342.08,
      left: 314.88,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_5", 
      isText: false,
      type: "checkbox",
      top: 356.96,
      left: 57.12,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_6", 
      isText: false,
      type: "checkbox",
      top: 356.96,
      left: 314.88,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_7", 
      isText: false,
      type: "checkbox",
      top: 371.84,
      left: 57.12,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_8", 
      isText: false,
      type: "checkbox",
      top: 371.84,
      left: 314.88,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_9", 
      isText: false,
      type: "checkbox",
      top: 386.72,
      left: 57.12,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "Environmental ADA Evaluation CBS2 3 4", 
      isText: false,
      type: "checkbox",
      top: 386.72,
      left: 314.88,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_10", 
      isText: false,
      type: "checkbox",
      top: 401.72,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_11", 
      isText: false,
      type: "checkbox",
      top: 401.72,
      left: 314.88,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_12", 
      isText: false,
      type: "checkbox",
      top: 416.6,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "LeadBased Paint CBS1 2 F1 MHC1", 
      isText: false,
      type: "checkbox",
      top: 416.6,
      left: 314.88,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_13", 
      isText: false,
      type: "checkbox",
      top: 431.48,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Methamphetamine Laboratory CBS1 2 F1 MHC1", 
      isText: false,
      type: "checkbox",
      top: 431.48,
      left: 314.88,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_14", 
      isText: false,
      type: "checkbox",
      top: 446.36,
      left: 57.12,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_15", 
      isText: false,
      type: "checkbox",
      top: 446.36,
      left: 314.88,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "UCC and Certificate of Title MHC1", 
      isText: false,
      type: "checkbox",
      top: 461.24,
      left: 57.12,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_16", 
      isText: false,
      type: "checkbox",
      top: 461.24,
      left: 314.88,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "Record Title OffRecord Title or Tax Certificate", 
      isText: false,
      type: "checkbox",
      top: 476.12,
      left: 57.12,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_17", 
      isText: false,
      type: "checkbox",
      top: 476.12,
      left: 314.88,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_18", 
      isText: false,
      type: "checkbox",
      top: 491.0,
      left: 57.12,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_19", 
      isText: false,
      type: "checkbox",
      top: 491.0,
      left: 314.88,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_20", 
      isText: false,
      type: "checkbox",
      top: 506.0,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_21", 
      isText: false,
      type: "checkbox",
      top: 506.0,
      left: 314.88,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Other", 
      isText: false,
      type: "checkbox",
      top: 520.88,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Text1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 519.963,
      left: 103.854,
      width: 201.491,
      height: 11.527
   }
   ,
   {
      page: 0,
      name: "Other_2", 
      isText: false,
      type: "checkbox",
      top: 520.88,
      left: 314.88,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Text2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 519.963,
      left: 362.181,
      width: 216.764,
      height: 11.090
   }
   ,
   {
      page: 0,
      name: "undefined_22", 
      isText: false,
      type: "checkbox",
      top: 576.8,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_23", 
      isText: false,
      type: "checkbox",
      top: 591.68,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_24", 
      isText: false,
      type: "checkbox",
      top: 606.56,
      left: 57.12,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "undefined_25", 
      isText: false,
      type: "checkbox",
      top: 621.44,
      left: 57.12,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_26", 
      isText: false,
      type: "checkbox",
      top: 636.32,
      left: 57.12,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 0,
      name: "undefined_27", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 634.805,
      left: 106.8,
      width: 418.794,
      height: 11.115
   }
   ,
   {
      page: 0,
      name: "Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 684.971,
      left: 86.4,
      width: 214.8,
      height: 12.949
   }
   ,
   {
      page: 0,
      name: "Name_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 684.535,
      left: 347.4,
      width: 228.72,
      height: 13.385
   }
   ] }
   