export function landWithorWithoutDwelling() {
return [   //2024 Release 2024-02-28 11:25:09
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 100.8,
   width: 493.2,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 129", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 105.84,
   left: 398.44,
   width: 177.06,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 130", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 125.84,
   left: 36.28,
   width: 539.22,
   height: 18.00
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 201.205,
   left: 44.8592,
   width: 8.7709,
   height: 11.060
}
,
{
   page: 0,
   name: "Check Box 5", 
   isText: false,
   type: "checkbox",
   top: 262.405,
   left: 44.8592,
   width: 8.7709,
   height: 11.060
}
,
{
   page: 0,
   name: "Check Box 6", 
   isText: false,
   type: "checkbox",
   top: 293.085,
   left: 44.8592,
   width: 8.7709,
   height: 11.060
}
,
{
   page: 0,
   name: "Check Box 7", 
   isText: false,
   type: "checkbox",
   top: 323.765,
   left: 44.8592,
   width: 8.7709,
   height: 11.060
}
,
{
   page: 0,
   name: "Check Box 8", 
   isText: false,
   type: "checkbox",
   top: 343.415,
   left: 44.8592,
   width: 8.7709,
   height: 11.060
}
,
{
   page: 0,
   name: "Check Box 9", 
   isText: false,
   type: "checkbox",
   top: 394.031,
   left: 44.8592,
   width: 8.7709,
   height: 11.060
}
,
{
   page: 0,
   name: "Check Box 10", 
   isText: false,
   type: "checkbox",
   top: 435.125,
   left: 44.8592,
   width: 8.7709,
   height: 11.060
}
,
{
   page: 0,
   name: "Check Box 11", 
   isText: false,
   type: "checkbox",
   top: 455.285,
   left: 44.8592,
   width: 8.7709,
   height: 11.060
}
,
{
   page: 0,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 501.68,
   left: 125.94,
   width: 177.06,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 136", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 501.68,
   left: 398.086,
   width: 177.060,
   height: 18.00
}
,
{
   page: 0,
   name: "Check Box 87", 
   isText: false,
   type: "checkbox",
   top: 608.717,
   left: 43.8592,
   width: 8.7709,
   height: 11.060
}
,
{
   page: 0,
   name: "Text Field 137", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 665.84,
   left: 128.88,
   width: 174.12,
   height: 18.00
}
,
{
   page: 0,
   name: "Text Field 140", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 665.84,
   left: 401.026,
   width: 174.120,
   height: 18.00
}
,
{
   page: 1,
   name: "Text Field 120", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 100.8,
   width: 493.2,
   height: 18.00
}
,
{
   page: 1,
   name: "Check Box 64", 
   isText: false,
   type: "checkbox",
   top: 159.558,
   left: 34.615,
   width: 9.6396,
   height: 11.125
}
,
{
   page: 1,
   name: "Check Box 18", 
   isText: false,
   type: "checkbox",
   top: 157.249,
   left: 307.502,
   width: 9.640,
   height: 11.125
}
,
{
   page: 1,
   name: "Check Box 65", 
   isText: false,
   type: "checkbox",
   top: 169.005,
   left: 34.615,
   width: 9.6396,
   height: 11.126
}
,
{
   page: 1,
   name: "Check Box 19", 
   isText: false,
   type: "checkbox",
   top: 168.005,
   left: 307.502,
   width: 9.640,
   height: 11.126
}
,
{
   page: 1,
   name: "Check Box 66", 
   isText: false,
   type: "checkbox",
   top: 179.725,
   left: 34.615,
   width: 9.6396,
   height: 11.126
}
,
{
   page: 1,
   name: "Check Box 20", 
   isText: false,
   type: "checkbox",
   top: 178.725,
   left: 307.502,
   width: 9.640,
   height: 11.126
}
,
{
   page: 1,
   name: "Check Box 67", 
   isText: false,
   type: "checkbox",
   top: 190.445,
   left: 34.615,
   width: 9.6396,
   height: 11.126
}
,
{
   page: 1,
   name: "Check Box 21", 
   isText: false,
   type: "checkbox",
   top: 189.445,
   left: 307.502,
   width: 9.640,
   height: 11.126
}
,
{
   page: 1,
   name: "Check Box 68", 
   isText: false,
   type: "checkbox",
   top: 201.165,
   left: 34.615,
   width: 9.6396,
   height: 11.126
}
,
{
   page: 1,
   name: "Text Field 143", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 199.92,
   left: 46.0768,
   width: 256.9232,
   height: 13.50
}
,
{
   page: 1,
   name: "Check Box 22", 
   isText: false,
   type: "checkbox",
   top: 201.165,
   left: 307.502,
   width: 9.640,
   height: 11.126
}
,
{
   page: 1,
   name: "Check Box 69", 
   isText: false,
   type: "checkbox",
   top: 211.885,
   left: 34.615,
   width: 9.6396,
   height: 11.126
}
,
{
   page: 1,
   name: "Text Field 144", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 212.11,
   left: 46.0768,
   width: 256.9232,
   height: 13.50
}
,
{
   page: 1,
   name: "Check Box 23", 
   isText: false,
   type: "checkbox",
   top: 212.885,
   left: 307.502,
   width: 9.640,
   height: 11.126
}
,
{
   page: 1,
   name: "Text Field 145", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 212.11,
   left: 318.582,
   width: 256.924,
   height: 13.50
}
,
{
   page: 1,
   name: "Text Field 146", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 247.16,
   left: 36.5,
   width: 506.98,
   height: 18.00
}
,
{
   page: 1,
   name: "Text Field 147", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 266.16,
   left: 54.0,
   width: 489.48,
   height: 18.00
}
,
{
   page: 1,
   name: "Text Field 148", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 454.475,
   left: 156.96,
   width: 114.044,
   height: 18.000
}
,
{
   page: 1,
   name: "Text Field 150", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 473.499,
   left: 116.642,
   width: 458.504,
   height: 18.000
}
,
{
   page: 1,
   name: "Text Field 149", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 493.275,
   left: 46.0768,
   width: 529.0692,
   height: 18.000
}
,
{
   page: 1,
   name: "Text Field 151", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 533.074,
   left: 46.0768,
   width: 529.0692,
   height: 18.000
}
,
{
   page: 1,
   name: "Text Field 152", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 553.074,
   left: 46.0768,
   width: 529.0692,
   height: 18.000
}
,
{
   page: 1,
   name: "Text Field 153", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 573.074,
   left: 46.0768,
   width: 529.0692,
   height: 18.000
}
,
{
   page: 1,
   name: "Text Field 154", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 592.537,
   left: 108.0,
   width: 467.146,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box 24", 
   isText: false,
   type: "checkbox",
   top: 647.158,
   left: 64.0378,
   width: 9.4254,
   height: 12.369
}
,
{
   page: 2,
   name: "Text Field 121", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 100.8,
   width: 493.2,
   height: 18.00
}
,
{
   page: 2,
   name: "Check Box 25", 
   isText: false,
   type: "checkbox",
   top: 50.147,
   left: 63.3832,
   width: 10.0800,
   height: 11.060
}
,
{
   page: 2,
   name: "Text Field 155", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 59.4,
   left: 74.4632,
   width: 501.0368,
   height: 13.5
}
,
{
   page: 2,
   name: "Check Box 26", 
   isText: false,
   type: "checkbox",
   top: 93.216,
   left: 315.329,
   width: 9.425,
   height: 9.751
}
,
{
   page: 2,
   name: "Check Box 27", 
   isText: false,
   type: "checkbox",
   top: 93.216,
   left: 390.295,
   width: 9.425,
   height: 9.751
}
,
{
   page: 2,
   name: "Text Field 156", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 90.55,
   left: 401.375,
   width: 73.825,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 158", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 122.04,
   left: 187.92,
   width: 127.754,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 157", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 133.04,
   left: 414.0,
   width: 69.2,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 159", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 144.54,
   left: 46.8,
   width: 149.04,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 160", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 229.94,
   left: 254.358,
   width: 181.260,
   height: 13.50
}
,
{
   page: 2,
   name: "Text Field 161", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 266.94,
   left: 46.8,
   width: 528.7,
   height: 18.00
}
,
{
   page: 2,
   name: "Check Box 82", 
   isText: false,
   type: "checkbox",
   top: 639.128,
   left: 65.2281,
   width: 9.8708,
   height: 10.471
}
,
{
   page: 2,
   name: "Check Box 70", 
   isText: false,
   type: "checkbox",
   top: 638.237,
   left: 247.318,
   width: 9.871,
   height: 10.471
}
,
{
   page: 2,
   name: "Check Box 77", 
   isText: false,
   type: "checkbox",
   top: 638.237,
   left: 428.059,
   width: 9.871,
   height: 10.471
}
,
{
   page: 2,
   name: "Check Box 83", 
   isText: false,
   type: "checkbox",
   top: 650.447,
   left: 65.2281,
   width: 9.8708,
   height: 10.471
}
,
{
   page: 2,
   name: "Check Box 71", 
   isText: false,
   type: "checkbox",
   top: 650.447,
   left: 247.318,
   width: 9.871,
   height: 10.471
}
,
{
   page: 2,
   name: "Check Box 84", 
   isText: false,
   type: "checkbox",
   top: 662.658,
   left: 65.2281,
   width: 9.8708,
   height: 10.471
}
,
{
   page: 2,
   name: "Check Box 72", 
   isText: false,
   type: "checkbox",
   top: 662.658,
   left: 247.318,
   width: 9.871,
   height: 10.471
}
,
{
   page: 2,
   name: "Check Box 79", 
   isText: false,
   type: "checkbox",
   top: 658.658,
   left: 428.059,
   width: 9.871,
   height: 10.471
}
,
{
   page: 2,
   name: "Check Box 85", 
   isText: false,
   type: "checkbox",
   top: 674.868,
   left: 65.2281,
   width: 9.8708,
   height: 10.471
}
,
{
   page: 2,
   name: "Check Box 73", 
   isText: false,
   type: "checkbox",
   top: 674.868,
   left: 247.318,
   width: 9.871,
   height: 10.471
}
,
{
   page: 2,
   name: "Check Box 86", 
   isText: false,
   type: "checkbox",
   top: 686.079,
   left: 65.2281,
   width: 9.8708,
   height: 10.4711
}
,
{
   page: 2,
   name: "Check Box 74", 
   isText: false,
   type: "checkbox",
   top: 687.079,
   left: 247.318,
   width: 9.871,
   height: 10.4711
}
,
{
   page: 3,
   name: "Text Field 122", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 100.8,
   width: 493.2,
   height: 18.00
}
,
{
   page: 3,
   name: "Text Field 162", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 59.16,
   left: 65.52,
   width: 509.98,
   height: 18.00
}
,
{
   page: 3,
   name: "Text Field 163", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 79.16,
   left: 65.52,
   width: 509.98,
   height: 18.00
}
,
{
   page: 3,
   name: "Text Field 164", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 112.16,
   left: 65.52,
   width: 509.98,
   height: 18.00
}
,
{
   page: 3,
   name: "Text Field 165", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 132.16,
   left: 65.52,
   width: 509.98,
   height: 18.00
}
,
{
   page: 3,
   name: "Text Field 210", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 165.16,
   left: 104.4,
   width: 468.0,
   height: 13.50
}
,
{
   page: 3,
   name: "Check Box 43", 
   isText: false,
   type: "checkbox",
   top: 181.972,
   left: 202.7,
   width: 10.08,
   height: 11.715
}
,
{
   page: 3,
   name: "Check Box 44", 
   isText: false,
   type: "checkbox",
   top: 181.972,
   left: 240.14,
   width: 10.08,
   height: 11.715
}
,
{
   page: 3,
   name: "Text Field 166", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 210.68,
   left: 126.72,
   width: 375.12,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 167", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 347.76,
   left: 68.4,
   width: 507.1,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 168", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 414.668,
   left: 136.8,
   width: 77.04,
   height: 18.000
}
,
{
   page: 4,
   name: "Text Field 123", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 100.8,
   width: 493.2,
   height: 18.00
}
,
{
   page: 4,
   name: "Text Field 169", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 92.16,
   left: 93.6,
   width: 481.9,
   height: 13.50
}
,
{
   page: 4,
   name: "Text Field 171", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 369.0,
   left: 292.32,
   width: 45.36,
   height: 18.0
}
,
{
   page: 5,
   name: "Text Field 124", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 100.8,
   width: 493.2,
   height: 18.00
}
,
{
   page: 5,
   name: "Check Box 45", 
   isText: false,
   type: "checkbox",
   top: 389.053,
   left: 90.22,
   width: 8.1163,
   height: 10.406
}
,
{
   page: 5,
   name: "Check Box 46", 
   isText: false,
   type: "checkbox",
   top: 448.669,
   left: 90.22,
   width: 8.1163,
   height: 10.406
}
,
{
   page: 5,
   name: "Check Box 47", 
   isText: false,
   type: "checkbox",
   top: 479.773,
   left: 90.22,
   width: 8.1163,
   height: 10.406
}
,
{
   page: 5,
   name: "Text Field 172", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 472.888,
   left: 126.72,
   width: 30.604,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box 48", 
   isText: false,
   type: "checkbox",
   top: 506.125,
   left: 90.22,
   width: 8.1163,
   height: 10.406
}
,
{
   page: 5,
   name: "Text Field 173", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 500.1,
   left: 126.72,
   width: 30.604,
   height: 18.0
}
,
{
   page: 5,
   name: "Check Box 49", 
   isText: false,
   type: "checkbox",
   top: 532.765,
   left: 90.22,
   width: 8.1163,
   height: 10.406
}
,
{
   page: 5,
   name: "Text Field 174", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 526.6,
   left: 126.72,
   width: 30.604,
   height: 18.0
}
,
{
   page: 5,
   name: "Check Box 54", 
   isText: false,
   type: "checkbox",
   top: 554.797,
   left: 308.388,
   width: 8.116,
   height: 10.406
}
,
{
   page: 5,
   name: "Check Box 55", 
   isText: false,
   type: "checkbox",
   top: 554.797,
   left: 350.043,
   width: 8.116,
   height: 10.406
}
,
{
   page: 5,
   name: "Check Box 56", 
   isText: false,
   type: "checkbox",
   top: 565.741,
   left: 393.043,
   width: 8.116,
   height: 10.406
}
,
{
   page: 5,
   name: "Check Box 57", 
   isText: false,
   type: "checkbox",
   top: 565.741,
   left: 443.916,
   width: 8.116,
   height: 10.406
}
,
{
   page: 5,
   name: "Check Box 52", 
   isText: false,
   type: "checkbox",
   top: 586.629,
   left: 99.4202,
   width: 8.1168,
   height: 10.406
}
,
{
   page: 5,
   name: "Check Box 53", 
   isText: false,
   type: "checkbox",
   top: 586.629,
   left: 147.244,
   width: 8.116,
   height: 10.406
}
,
{
   page: 5,
   name: "Text Field 175", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 585.964,
   left: 247.68,
   width: 30.604,
   height: 13.500
}
,
{
   page: 5,
   name: "Check Box 50", 
   isText: false,
   type: "checkbox",
   top: 603.181,
   left: 90.22,
   width: 8.1163,
   height: 10.406
}
,
{
   page: 5,
   name: "Check Box 51", 
   isText: false,
   type: "checkbox",
   top: 618.733,
   left: 90.22,
   width: 8.1163,
   height: 10.406
}
,
{
   page: 5,
   name: "Text Field 176", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 613.016,
   left: 126.72,
   width: 437.689,
   height: 18.000
}
,
{
   page: 5,
   name: "Text Field 177", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 652.799,
   left: 158.324,
   width: 45.360,
   height: 18.000
}
,
{
   page: 6,
   name: "Text Field 125", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 100.8,
   width: 493.2,
   height: 18.00
}
,
{
   page: 6,
   name: "Text Field 178", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 146.44,
   left: 182.88,
   width: 41.04,
   height: 18.00
}
,
{
   page: 6,
   name: "Text Field 179", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 221.58,
   left: 93.6,
   width: 46.52,
   height: 13.50
}
,
{
   page: 6,
   name: "Text Field 180", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 338.56,
   left: 90.8,
   width: 484.7,
   height: 13.50
}
,
{
   page: 6,
   name: "Check Box 58", 
   isText: false,
   type: "checkbox",
   top: 656.565,
   left: 66.612,
   width: 10.080,
   height: 11.715
}
,
{
   page: 6,
   name: "Check Box 59", 
   isText: false,
   type: "checkbox",
   top: 672.135,
   left: 66.612,
   width: 10.080,
   height: 11.715
}
,
{
   page: 7,
   name: "Text Field 126", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 100.8,
   width: 493.2,
   height: 18.00
}
,
{
   page: 7,
   name: "Check Box 60", 
   isText: false,
   type: "checkbox",
   top: 48.903,
   left: 66.612,
   width: 9.4254,
   height: 12.369
}
,
{
   page: 7,
   name: "Text Field 182", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 60.256,
   left: 170.8,
   width: 50.24,
   height: 13.500
}
,
{
   page: 7,
   name: "Text Field 211", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 60.256,
   left: 325.88,
   width: 85.96,
   height: 13.500
}
,
{
   page: 7,
   name: "Text Field 183", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 139.52,
   left: 45.36,
   width: 529.92,
   height: 18.00
}
,
{
   page: 7,
   name: "Text Field 184", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 159.52,
   left: 45.36,
   width: 529.92,
   height: 18.00
}
,
{
   page: 7,
   name: "Text Field 185", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 179.52,
   left: 45.36,
   width: 529.92,
   height: 18.00
}
,
{
   page: 7,
   name: "Text Field 186", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 199.52,
   left: 45.36,
   width: 529.92,
   height: 18.00
}
,
{
   page: 8,
   name: "Text Field 127", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 100.8,
   width: 493.2,
   height: 18.00
}
,
{
   page: 8,
   name: "Text Field 187", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 298.8,
   left: 378.0,
   width: 186.48,
   height: 13.5
}
,
{
   page: 8,
   name: "Text Field 188", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 309.6,
   left: 50.14,
   width: 60.48,
   height: 13.5
}
,
{
   page: 8,
   name: "Check Box 61", 
   isText: false,
   type: "checkbox",
   top: 311.876,
   left: 113.62,
   width: 8.771,
   height: 9.751
}
,
{
   page: 8,
   name: "Check Box 62", 
   isText: false,
   type: "checkbox",
   top: 311.876,
   left: 145.444,
   width: 8.771,
   height: 9.751
}
,
{
   page: 8,
   name: "Text Field 189", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 325.84,
   left: 351.36,
   width: 160.56,
   height: 18.00
}
,
{
   page: 8,
   name: "Text Field 190", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 325.84,
   left: 525.68,
   width: 50.32,
   height: 18.00
}
,
{
   page: 8,
   name: "Text Field 193", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 430.2,
   left: 36.38,
   width: 199.06,
   height: 18.0
}
,
{
   page: 8,
   name: "Text Field 200", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 430.2,
   left: 309.38,
   width: 199.06,
   height: 18.0
}
,
{
   page: 8,
   name: "Text Field 195", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 505.36,
   left: 36.38,
   width: 199.06,
   height: 18.00
}
,
{
   page: 8,
   name: "Text Field 201", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 505.36,
   left: 309.38,
   width: 199.06,
   height: 18.00
}
,
{
   page: 8,
   name: "Text Field 197", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 580.52,
   left: 36.38,
   width: 199.06,
   height: 18.00
}
,
{
   page: 8,
   name: "Text Field 202", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 580.52,
   left: 309.38,
   width: 199.06,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 128", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 10.28,
   left: 100.8,
   width: 493.2,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 206", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 100.8,
   left: 36.38,
   width: 209.02,
   height: 17.345
}
,
{
   page: 9,
   name: "Text5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 100.8,
   left: 249.383,
   width: 54.436,
   height: 16.109
}
,
{
   page: 9,
   name: "Text Field 207", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 100.8,
   left: 309.38,
   width: 224.229,
   height: 18.0
}
,
{
   page: 9,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 101.455,
   left: 535.964,
   width: 46.581,
   height: 16.764
}
,
{
   page: 9,
   name: "Text Field 208", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 139.08,
   left: 36.38,
   width: 266.62,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 209", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 139.08,
   left: 309.38,
   width: 266.12,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 2010", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 176.36,
   left: 36.38,
   width: 266.62,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 2011", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 176.36,
   left: 309.38,
   width: 266.12,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 2012", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 213.64,
   left: 36.38,
   width: 266.62,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 2013", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 213.64,
   left: 309.38,
   width: 266.12,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 2014", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 250.92,
   left: 36.38,
   width: 266.62,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 2015", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 250.92,
   left: 309.38,
   width: 266.12,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 2016", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 288.2,
   left: 36.38,
   width: 266.62,
   height: 18.0
}
,
{
   page: 9,
   name: "Text Field 2017", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 288.2,
   left: 309.38,
   width: 266.12,
   height: 18.0
}
,
{
   page: 9,
   name: "Text Field 2018", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 326.48,
   left: 36.38,
   width: 266.62,
   height: 18.00
}
,
{
   page: 9,
   name: "Text Field 2019", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 326.48,
   left: 309.38,
   width: 266.12,
   height: 18.00
}
,
{
   page: 9,
   name: "Logo",
   isText: false,
   fontSize: 10,
   type: "logo",
   top: 360,
   left: 31.4,
   width: 200,
   height: 30,
}
] }
