import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { abbreviateDirection } from "../../app/common/util/util";

const initialState = {
  docsUpcoming: [],
  docsTransActive: {
    docs: [],
    column: "",
    direction: "",
  },
  docsTransArchived: {
    docs: [],
    column: "",
    direction: "",
  },
  docsTransInProgress: {},
  docsTransAwaitingSignature: [],
  forms: [],
  docsToSign: [],
  docUrl: "",
  doc: {},
  docsTransShared: {
    docs: [],
    column: "",
    direction: "",
  },
};

const docSlice = createSlice({
  name: "doc",
  initialState,
  reducers: {
    fetchDocsUpcoming(state, action) {
      state.docsUpcoming = action.payload;
    },
    fetchDocsTrans(state, action) {
      state.docsTrans = action.payload;
      if (state.docsTransActive.column && state.docsTransActive.direction) {
        state.docsTransActive.docs = _.orderBy(
          action.payload.filter(
            (doc) => doc.archived !== true && doc.shared !== true
          ),
          state.docsTransActive.column,
          abbreviateDirection(state.docsTransActive.direction)
        );
      } else {
        state.docsTransActive.docs = action.payload.filter(
          (doc) => doc.archived !== true && doc.shared !== true
        );
      }
      if (state.docsTransShared.column && state.docsTransShared.direction) {
        state.docsTransShared.docs = _.orderBy(
          action.payload.filter(
            (doc) => doc.archived !== true && doc.shared === true
          ),
          state.docsTransShared.column,
          abbreviateDirection(state.docsTransShared.direction)
        );
      } else {
        state.docsTransShared.docs = action.payload.filter(
          (doc) => doc.archived !== true && doc.shared === true
        );
      }
      if (state.docsTransArchived.column && state.docsTransArchived.direction) {
        state.docsTransArchived.docs = _.orderBy(
          action.payload.filter((doc) => doc.archived === true),
          state.docsTransArchived.column,
          abbreviateDirection(state.docsTransArchived.direction)
        );
      } else {
        state.docsTransArchived.docs = action.payload.filter(
          (doc) => doc.archived === true
        );
      }
      state.docsTransInProgress = {
        docs: action.payload.filter(
          (doc) => doc.archived !== true && doc.status === "In Progress"
        ),
      };
      state.docsTransAwaitingSignature = action.payload.filter(
        (doc) => doc.archived !== true && doc.status === "Awaiting Signature"
      );
    },
    fetchDocsTransShared(state, action) {
      if (state.docsTransShared.column && state.docsTransShared.direction) {
        state.docsTransShared.docs = _.orderBy(
          action.payload,
          state.docsTransShared.column,
          abbreviateDirection(state.docsTransShared.direction)
        );
      } else {
        state.docsTransShared.docs = action.payload;
      }
    },
    clearDocsTransShared(state, action) {
      state.docsTransShared = { docs: [] };
    },
    fetchStateForms(state, action) {
      state.forms = action.payload;
    },
    fetchDocsToSign(state, action) {
      state.docsToSign = action.payload;
    },
    getDocAndUrl(state, action) {
      state.doc = action.payload.doc;
      state.docUrl = action.payload.docUrl;
    },
    fetchDoc(state, action) {
      state.doc = action.payload;
    },
    sortDocsTransActive(state, action) {
      if (state.docsTransActive.column === action.payload) {
        state.docsTransActive = {
          docs: state.docsTransActive.docs.slice().reverse(),
          column: action.payload,
          direction:
            state.docsTransActive.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.docsTransActive = {
          docs: _.sortBy(state.docsTransActive.docs, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    sortDocsTransArchived(state, action) {
      if (state.docsTransArchived.column === action.payload) {
        state.docsTransArchived = {
          docs: state.docsTransArchived.docs.slice().reverse(),
          column: action.payload,
          direction:
            state.docsTransArchived.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.docsTransArchived = {
          docs: _.sortBy(state.docsTransArchived.docs, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
    sortDocsTransShared(state, action) {
      if (state.docsTransShared.column === action.payload) {
        state.docsTransShared = {
          docs: state.docsTransShared.docs.slice().reverse(),
          column: action.payload,
          direction:
            state.docsTransShared.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.docsTransShared = {
          docs: _.sortBy(state.docsTransShared.docs, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
  },
});

export const {
  fetchDocsUpcoming,
  fetchDocsTrans,
  fetchDocsTransShared,
  clearDocsTransShared,
  fetchStateForms,
  fetchDocsToSign,
  getDocAndUrl,
  fetchDoc,
  sortDocsTransActive,
  sortDocsTransArchived,
  sortDocsTransShared,
} = docSlice.actions;

export default docSlice.reducer;
