export function terminationofMarketingEffortsCB_DP() {
return [   //2024 Release 2024-02-29 14:49:47
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 147.916,
   left: 463.08,
   width: 111.84,
   height: 13.004
}
,
{
   page: 0,
   name: "RE Listing Contract dated", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 172.677,
   left: 186.142,
   width: 58.178,
   height: 13.003
}
,
{
   page: 0,
   name: "20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 173.331,
   left: 259.015,
   width: 62.345,
   height: 12.349
}
,
{
   page: 0,
   name: "fill_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 362.76,
   left: 89.28,
   width: 492.36,
   height: 13.56
}
,
{
   page: 0,
   name: "signature Broker", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 563.978,
   left: 77.64,
   width: 203.28,
   height: 15.622
}
,
{
   page: 0,
   name: "Date_2", 
   fontSize: 9,
   type: "date",
   top: 565.287,
   left: 309.72,
   width: 140.76,
   height: 14.968
}
,
{
   page: 0,
   name: "signature cobroke", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 590.393,
   left: 100.2,
   width: 182.52,
   height: 16.277
}
,
{
   page: 0,
   name: "Date_3", 
   fontSize: 9,
   type: "date",
   top: 591.393,
   left: 311.52,
   width: 140.64,
   height: 14.967
}
,
{
   page: 0,
   name: "thesellers", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 186.325,
   left: 157.146,
   width: 256.484,
   height: 13.364
}
,
{
   page: 0,
   name: "addressfull", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 202.395,
   left: 250.647,
   width: 329.150,
   height: 12.349
}
,
{
   page: 0,
   name: "Page 1 of 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 538.723,
   left: 60.7092,
   width: 314.0938,
   height: 13.003
}
,
{
   page: 0,
   name: "Check Box22", 
   isText: false,
   type: "checkbox",
   top: 280.419,
   left: 67.0367,
   width: 14.7272,
   height: 14.073
}
,
{
   page: 0,
   name: "Check Box23", 
   isText: false,
   type: "checkbox",
   top: 293.619,
   left: 67.0367,
   width: 14.7272,
   height: 14.073
}
,
{
   page: 0,
   name: "Check Box24", 
   isText: false,
   type: "checkbox",
   top: 307.674,
   left: 67.0367,
   width: 14.7272,
   height: 14.073
}
,
{
   page: 0,
   name: "Check Box25", 
   isText: false,
   type: "checkbox",
   top: 321.765,
   left: 67.0367,
   width: 14.7272,
   height: 14.073
}
,
{
   page: 0,
   name: "Check Box26", 
   isText: false,
   type: "checkbox",
   top: 335.474,
   left: 67.0367,
   width: 14.7272,
   height: 14.073
}
,
{
   page: 0,
   name: "Check Box27", 
   isText: false,
   type: "checkbox",
   top: 347.911,
   left: 67.0367,
   width: 14.7272,
   height: 14.072
}
,
{
   page: 0,
   name: "Check Box28", 
   isText: false,
   type: "checkbox",
   top: 360.383,
   left: 67.0367,
   width: 14.7272,
   height: 14.073
}
,
{
   page: 0,
   name: "Text29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 171.801,
   left: 373.093,
   width: 207.600,
   height: 13.491
}
] }
