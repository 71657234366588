import React from "react";
import { Image } from "semantic-ui-react";

export function FormFieldLogoDisplay({ formField, pageScale }) {
  return (
    <>
      {formField?.value && formField?.height > 10 && (
        <Image
          src={formField.value}
          style={{
            top: `${formField.top * pageScale}px`,
            left: `${formField.left * pageScale}px`,
            height: `${formField.height * pageScale}px`,
            width: `${formField.width * pageScale}px`,
          }}
        ></Image>
      )}
    </>
  );
}
