import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  emailTemplates: [],
};

const emailTemplatesSlice = createSlice({
  name: "emailTemplates",
  initialState,
  reducers: {
    fetchEmailTemplates(state, action) {
      state.emailTemplates = _.sortBy(action.payload, ["name"]);
    },
  },
});

export const { fetchEmailTemplates } = emailTemplatesSlice.actions;

export default emailTemplatesSlice.reducer;
