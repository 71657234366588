export function shortSaleAddendumCBS() {
    return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 168.271,
       left: 398.64,
       width: 173.88,
       height: 15.329
    }
    ,
    {
       page: 0,
       name: "Real Estate between Seller and Buyer Contract dated", 
       type: "date",
       fontName: "Helvetica",
       fontSize: 10,
       top: 207.12,
       left: 271.44,
       width: 114.84,
       height: 10.68
    }
    ,
    {
       page: 0,
       name: "Street Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 224.416,
       left: 95.4,
       width: 184.44,
       height: 15.544
    }
    ,
    {
       page: 0,
       name: "City", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 224.416,
       left: 284.4,
       width: 103.44,
       height: 15.544
    }
    ,
    {
       page: 0,
       name: "State", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 224.416,
       left: 392.4,
       width: 80.88,
       height: 15.544
    }
    ,
    {
       page: 0,
       name: "Zip", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 224.416,
       left: 477.84,
       width: 49.80,
       height: 15.544
    }
    ,
    {
       page: 1,
       name: "Event", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 307.8,
       left: 83.9082,
       width: 102.6918,
       height: 10.511
    }
    ,
    {
       page: 1,
       name: "From", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 307.8,
       left: 434.308,
       width: 152.612,
       height: 10.511
    }
    ,
    {
       page: 1,
       name: "DeadlineInitial Submission", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 321.24,
       left: 187.92,
       width: 213.48,
       height: 11.64
    }
    ,
    {
       page: 1,
       name: "51Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 307.587,
       left: 236.932,
       width: 163.115,
       height: 10.511
    }
    ,
    {
       page: 1,
       name: "OtherLien", 
       isText: false,
       type: "checkbox",
       top: 377.218,
       left: 508.793,
       width: 11.788,
       height: 12.353
    }
    ,
    {
       page: 1,
       name: "CheckboxBuyer", 
       isText: false,
       type: "checkbox",
       top: 390.769,
       left: 317.344,
       width: 11.788,
       height: 12.353
    }
    ,
    {
       page: 1,
       name: "CheckboxBuyerAttorney", 
       isText: false,
       type: "checkbox",
       top: 390.695,
       left: 363.269,
       width: 11.788,
       height: 12.353
    }
    ,
    {
       page: 1,
       name: "CheckboxBrokerorBrokerageFirmWithBuyer", 
       isText: false,
       type: "checkbox",
       top: 390.55,
       left: 96.942,
       width: 11.788,
       height: 12.353
    }
    ,
    {
       page: 2,
       name: "Event_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 76.355,
       left: 85.0376,
       width: 218.2024,
       height: 11.076
    }
    ,
    {
       page: 2,
       name: "DeadlineShort Sale Acceptance Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 90.36,
       left: 304.56,
       width: 271.80,
       height: 11.64
    }
    // ,
    // {
    //    page: 2,
    //    name: "Buyer", 
    //    type: "textarea",
    //    fontName: "Helvetica",
    //    fontSize: 10,
    //    top: 309.219,
    //    left: 50.4,
    //    width: 177.36,
    //    height: 23.181
    // }
    // ,
    // {
    //    page: 2,
    //    name: "Date_2", 
    //    fontSize: 9,
    //    type: "date",
    //    top: 309.219,
    //    left: 232.32,
    //    width: 66.60,
    //    height: 23.181
    // }
    // ,
    // {
    //    page: 2,
    //    name: "Seller", 
    //    type: "textarea",
    //    fontName: "Helvetica",
    //    fontSize: 10,
    //    top: 369.939,
    //    left: 50.4,
    //    width: 177.36,
    //    height: 23.181
    // }
    // ,
    // {
    //    page: 2,
    //    name: "Date_3", 
    //    fontSize: 9,
    //    type: "date",
    //    top: 369.939,
    //    left: 232.32,
    //    width: 66.60,
    //    height: 23.181
    // }
    // ,
    // {
    //    page: 2,
    //    name: "Buyer_2", 
    //    type: "textarea",
    //    fontName: "Helvetica",
    //    fontSize: 10,
    //    top: 309.219,
    //    left: 318.0,
    //    width: 181.68,
    //    height: 23.181
    // }
    // ,
    // {
    //    page: 2,
    //    name: "Date_4", 
    //    fontSize: 9,
    //    type: "date",
    //    top: 309.219,
    //    left: 504.24,
    //    width: 71.88,
    //    height: 23.181
    // }
    // ,
    // {
    //    page: 2,
    //    name: "Seller_2", 
    //    type: "textarea",
    //    fontName: "Helvetica",
    //    fontSize: 10,
    //    top: 369.939,
    //    left: 318.0,
    //    width: 181.68,
    //    height: 23.181
    // }
    // ,
    // {
    //    page: 2,
    //    name: "Date_5", 
    //    fontSize: 9,
    //    type: "date",
    //    top: 369.939,
    //    left: 504.24,
    //    width: 71.88,
    //    height: 23.181
    // }
    ,
    {
       page: 2,
       name: "81Deadline", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 76.626,
       left: 352.281,
       width: 222.671,
       height: 11.075
    }
    ,
    {
       page: 2,
       name: "Checkbox841", 
       isText: false,
       type: "checkbox",
       top: 240.29,
       left: 51.344,
       width: 10.6838,
       height: 11.402
    }
    ,
    {
       page: 2,
       name: "Checkbox842", 
       isText: false,
       type: "checkbox",
       top: 251.971,
       left: 51.8031,
       width: 10.6838,
       height: 11.402
    }
    ] }
    