export function inspectionObjectionNotice() {
   return [   //2023
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 121.949,
      left: 398.64,
      width: 174.00,
      height: 15.731
   }
   ,
   {
      page: 0,
      name: "Buyer relating to the sale and purchase of the Property known as", 
      type: "date",
      fontName: "Helvetica",
      fontSize: 10,
      top: 148.629,
      left: 298.56,
      width: 94.80,
      height: 15.731
   }
   ,
   {
      page: 0,
      name: "sellerpart1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 148.425,
      left: 434.4,
      width: 138.24,
      height: 15.295
   }
   ,
   {
      page: 0,
      name: "between", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 170.626,
      left: 51.3169,
      width: 455.6961,
      height: 14.641
   }
   ,
   {
      page: 0,
      name: "Seller and 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 188.978,
      left: 51.4034,
      width: 248.9236,
      height: 15.262
   }
   ,
   {
      page: 0,
      name: "Seller and 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 208.08,
      left: 50.4,
      width: 468.24,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Text1", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 303.69,
      left: 47.9997,
      width: 528.6533,
      height: 142.473
   }
   ,
   {
      page: 0,
      name: "additional pages", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 449.255,
      left: 205.32,
      width: 34.20,
      height: 13.985
   }
   ,
   {
      page: 0,
      name: "Check Box3", 
      isText: false,
      type: "checkbox",
      top: 559.381,
      left: 181.527,
      width: 9.600,
      height: 10.036
   }
   ,
   {
      page: 0,
      name: "Check Box4", 
      isText: false,
      type: "checkbox",
      top: 559.817,
      left: 208.145,
      width: 10.036,
      height: 10.037
   }
   ] }
   