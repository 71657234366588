import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import { closeModal } from "../../../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../../../app/common/modals/modalWrapper";
import { convertFullName } from "../../../../../app/common/util/util";
import { createSignerIndex } from "../annots/annotUtils";
import {
  addToSignerList,
  changeSelectedSigner,
  removeAnnotsFromSigner,
  removeFromSignerList,
} from "../../../../../app/annots/annotSlice";

export default function DocPrepareAddSigner() {
  const dispatch = useDispatch();
  const { transClients } = useSelector((state) => state.transaction);
  const { signerListPossible, signerListDisplay, selectedSigner, annots } =
    useSelector((state) => state.annot);
  const { transaction } = useSelector((state) => state.transaction);

  function handleSelectSigner(signer) {
    if (signerListDisplay.find(({ role }) => role === signer.role)) {
      dispatch(removeFromSignerList(signer));
      if (annots.filter((annot) => annot.signerRole === signer.role)[0]) {
        dispatch(removeAnnotsFromSigner(signer));
      }
      if (selectedSigner.role === signer.role) {
        dispatch(changeSelectedSigner(transClients[0]));
      }
    } else {
      const newSignerIndex = createSignerIndex(signer.role, transaction);
      let signerCopy = JSON.parse(JSON.stringify(signer));
      dispatch(
        addToSignerList({
          signer: signerCopy,
          index: newSignerIndex,
        })
      );
    }
  }

  function handleDone() {
    dispatch(
      closeModal({
        modalType: "DocPrepareAddSigner",
      })
    );
  }

  return (
    <ModalWrapper>
      <Segment>
        <Grid>
          <Grid.Column width="16">
            <Header size="huge" color="blue">
              Edit Signer List
            </Header>
            <Divider />
            <p
              className="medium bottom margin"
              style={{ fontSize: "16px", color: "#888" }}
            >
              Check the people below that you want to add to your list of
              signers.&nbsp;&nbsp;Note: If you uncheck someone who was
              previously checked, any signing fields you already assigned them
              will be removed.
            </p>
            {signerListPossible.map((signer) => (
              <React.Fragment key={signer.role}>
                {signer.role !== transClients[0].role && (
                  <div
                    key={signer.role}
                    onClick={() => handleSelectSigner(signer)}
                    className="small bottom margin"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Icon
                      className="small right margin"
                      name={
                        signerListDisplay.find(
                          ({ role }) => role === signer.role
                        )
                          ? "square check outline"
                          : "square outline"
                      }
                      size="large"
                    />
                    <h3
                      className="zero top margin small left margin"
                      style={{ color: "#666" }}
                    >
                      {signer.role}
                      {signer.lastName && ": " + convertFullName(signer)}
                    </h3>
                  </div>
                )}
              </React.Fragment>
            ))}
            <Divider className="tiny margin bottom" />
            <Button
              onClick={() => handleDone()}
              floated="right"
              primary
              content="Done"
            />
          </Grid.Column>
        </Grid>
      </Segment>
    </ModalWrapper>
  );
}
