import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../layout/LoadingComponent";
import { openModal } from "../modals/modalSlice";
import _ from "lodash";

export default function CalendarAll({ tasksUpcoming }) {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  useEffect(() => {
    if (tasksUpcoming?.length > 0) {
      let tasks = _.cloneDeep(tasksUpcoming);
      if (tasks?.length > 0) {
        tasks.forEach((task) => {
          if (!task.title.includes(task.transactionTitle.split(" -")[0])) {
            task.title =
              task.title + " - " + task.transactionTitle.split(" -")[0];
          }
        });
        setEvents(tasks);
      }
    }
  }, [tasksUpcoming]);

  if (!events) {
    return <LoadingComponent />;
  }

  function handleSelectEvent(event) {
    dispatch(
      openModal({
        modalType: "TaskViewModal",
        modalProps: { task: event, hideNavigate: false },
      })
    );
  }

  if (!events) return <LoadingComponent content="Loading calendar..." />;

  return (
    <Grid>
      <Grid.Column width={16}>
        <h2>Calendar of Upcoming Tasks and Deadlines</h2>
        <b>DL</b> = Deadline, <b>Obj</b> = Objection, <b>Reso</b> = Resolution,{" "}
        <b>EM</b> = Earnest Money, <b>Avail</b> = Availability,{" "}
        <b>Prop Insur</b> = Property Insurance
        <div className="App">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            showAllEvents={true}
            style={{ height: 700, marginTop: "30px" }}
            eventPropGetter={(event) => {
              const backgroundColor =
                event.status === "Complete" ? "#1dcec6" : "#57b2ff";
              return { style: { backgroundColor } };
            }}
            onSelectEvent={(event) => {
              handleSelectEvent(event);
            }}
            views={["month"]}
          />
        </div>
      </Grid.Column>
    </Grid>
  );
}
