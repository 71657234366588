import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deadlineTemplates: [],
};

const deadlineTemplatesSlice = createSlice({
  name: "deadlineTemplates",
  initialState,
  reducers: {
    fetchDeadlineTemplates(state, action) {
      state.deadlineTemplates = action.payload;
    },
  },
});

export const { fetchDeadlineTemplates } = deadlineTemplatesSlice.actions;

export default deadlineTemplatesSlice.reducer;
