import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../layout/LoadingComponent";
import { openModal } from "../modals/modalSlice";

export default function CalendarDisplay({ tasks, allowEdit }) {
  const dispatch = useDispatch();
  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  function handleSelectEvent(event) {
    dispatch(
      openModal({
        modalType: "TaskViewModal",
        modalProps: { task: event, hideNavigate: true, allowEdit: allowEdit },
      })
    );
  }

  if (!tasks) {
    return <LoadingComponent />;
  }

  return (
    <Grid>
      <Grid.Column width={16}>
        <h3>Calendar of Tasks and Deadlines</h3>
        <b>DL</b> = Deadline, <b>Obj</b> = Objection, <b>Reso</b> = Resolution,{" "}
        <b>EM</b> = Earnest Money, <b>Avail</b> = Availability,{" "}
        <b>Prop Insur</b> = Property Insurance
        <div className="App">
          <Calendar
            localizer={localizer}
            events={tasks}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={(event) => {
              handleSelectEvent(event);
            }}
            showAllEvents={true}
            eventPropGetter={(event) => {
              const backgroundColor =
                event.status === "Complete" ? "#1dcec6" : "#57b2ff";
              return { style: { backgroundColor } };
            }}
            style={{ height: 700, marginTop: "30px" }}
            views={["month"]}
          />
        </div>
      </Grid.Column>
    </Grid>
  );
}
