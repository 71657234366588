import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button, Segment, Header, Divider, Label } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { registerInFirebase } from "../../../app/firestore/firebaseService";
import { addPartyUserToDb } from "../../../app/firestore/firestoreService";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyCheckbox from "../../../app/common/form/MyCheckbox";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";

export default function PartyLoginNewUserForm({ email }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { partyInfoFromUrl } = useSelector((state) => state.party);

  return (
    <ModalWrapper size="tiny">
      <Segment>
        <Header color="blue" textAlign="center" as="h1">
          Create Password
        </Header>
        <Divider />
        <p className="text-medium" >Please create a password so you can always come back and access your documents.</p>
        <Formik
          initialValues={{
            password: "",
            passwordConfirmation: "",
            agreeTermsOfService: true,
          }}
          validationSchema={Yup.object({
            password: Yup.string().required("Password is required"),
            passwordConfirmation: Yup.string().required("Password is required").oneOf(
              [Yup.ref("password"), null],
              "Passwords do not match"
            ),
            agreeTermsOfService: Yup.boolean().required("Checkmark is required").oneOf([true], "You must accept the terms and conditions")
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              await registerInFirebase({email: email, password: values.password});
              addPartyUserToDb(partyInfoFromUrl);
              dispatch(closeModal({ modalType: "PartyLoginNewUserForm" }));
              navigate(`/overviewParty`);
            } catch (error) {
              toast.error(error.message);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => (
            <Form className="ui form">
              <MyTextInput
                name="password"
                placeholder="Password"
                type="password"
                autoComplete="new-password"
              />
              <MyTextInput
                name="passwordConfirmation"
                placeholder="Confirm Password"
                type="password"
                autoComplete="new-password"
              />
              <MyCheckbox
                label="I agree to the Terms of Service"
                name="agreeTermsOfService"
                style={{ color: "dimgray" }}
              />
              {errors.auth && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.auth}
                />
              )}
              <Button
                loading={isSubmitting}
                disabled={!isValid || isSubmitting || !dirty}
                type="submit"
                fluid
                size="large"
                color="blue"
                content="Create Password"
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </ModalWrapper>
  );
}