export function agencyDisclosureDualAgent() {
return [   //2024 Release 2024-06-06 16:55:24
{
   page: 0,
   name: "Insert names of licensees undertaking dual representation", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 331.986,
   left: 247.68,
   width: 299.88,
   height: 15.054
}
,
{
   page: 0,
   name: "address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 12,
   top: 404.511,
   left: 64.8003,
   width: 479.8927,
   height: 16.109
}
,
{
   page: 1,
   name: "Signature Licensee", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 501.12,
   left: 59.04,
   width: 231.96,
   height: 17.967
}
,
{
   page: 1,
   name: "Date_3", 
   fontSize: 9,
   type: "date",
   top: 546.207,
   left: 59.04,
   width: 231.96,
   height: 17.313
}
,
{
   page: 1,
   name: "Signature Licensee_2", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 501.807,
   left: 336.687,
   width: 233.149,
   height: 17.313
}
,
{
   page: 1,
   name: "Date_6", 
   fontSize: 9,
   type: "date",
   top: 545.553,
   left: 336.687,
   width: 233.149,
   height: 17.967
}
] }
