import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { Grid, Menu, Image } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { goToNextAnnot, updateAnnotSigningStatus } from "./partyDocSignUtils";
import PartyDocSignActionButtons from "./PartyDocSignActionButtons";
import {
  setAnnotsSigningStatus,
  changePageScaleSign,
} from "../../../app/annots/annotSlice";
import { AnnotTypeDisplay } from "./annots/AnnotTypeDisplay";
import { openModal } from "../../../app/common/modals/modalSlice";
import {
  getAndSavePdfDimensions,
  getFormFieldValues,
} from "../../../app/common/util/util";
import FormFieldSelector from "../../docs/docComponents/docPrepare/formFields/FormFieldSelector";
import DocViewAgentAnnotField from "../../docs/docComponents/docView/DocViewAgentAnnotField";
import DocViewSignedAnnotField from "../../docs/docComponents/docView/DocViewSignedAnnotField";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

export function PartyDocSignDashboard() {
  const dispatch = useDispatch();
  const { pageScaleSign, annotsToSign, annotsSigningStatus, autoNextAnnot } =
    useSelector((state) => state.annot);
  const { party } = useSelector((state) => state.party);
  const { doc, docUrl } = useSelector((state) => state.doc);
  const { transaction } = useSelector((state) => state.transaction);
  const pageScale = pageScaleSign;
  const [numPages, setNumPages] = useState(null);
  const [annotsLoaded, setAnnotsLoaded] = useState(false);
  const [completeMessageShowed, setCompleteMessageShowed] = useState(false);
  const [pageDimensions, setPageDimensions] = useState({
    width: 612,
    height: 792,
  });
  const [formFieldValues, setFormFieldValues] = useState();
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const signerRole = party.role;
  const [ipAddress, setIpAddress] = useState("");

  // Fetching the API once when the
  // component is mounted
  useEffect(() => {
    axios.get("https://api.ipify.org?format=json").then((res) => {
      setIpAddress(res?.data?.ip);
    });
  }, []);

  useEffect(() => {
    if (
      annotsLoaded &&
      annotsSigningStatus.annotsToSign?.length === 0 &&
      !completeMessageShowed
    ) {
      setCompleteMessageShowed(true);
      dispatch(
        openModal({
          modalType: "PartyDocSignComplete",
        })
      );
    }
  }, [
    dispatch,
    annotsSigningStatus.annotsToSign,
    completeMessageShowed,
    annotsLoaded,
  ]);

  useEffect(() => {
    let annotsForSigning = [];
    if (!doc.pdfBurnVersion) {
      annotsForSigning = annotsToSign;
    } else {
      annotsForSigning = Object.values(doc.annotsToSign);
      annotsForSigning = annotsForSigning.filter(
        (annot) =>
          annot.signed !== true &&
          annot.type !== "date" &&
          annot.signerRole === party.role
      );
    }
    const newStatus = updateAnnotSigningStatus(annotsForSigning);
    dispatch(setAnnotsSigningStatus(newStatus));
    if (numPages && autoNextAnnot) {
      setTimeout(() => {
        goToNextAnnot(newStatus.nextAnnotToSign);
      }, 700);
    }
  }, [
    annotsToSign,
    doc.annotsToSign,
    dispatch,
    autoNextAnnot,
    numPages,
    doc.pdfBurnVersion,
    party.role,
  ]);

  useEffect(() => {
    if (doc.pdfBurnVersion) {
      getFormFieldValues(doc, transaction, setFormFieldValues);
    }
  }, [doc, transaction]);

  async function onDocumentLoadSuccess(pdfObject) {
    getAndSavePdfDimensions(
      doc,
      pdfObject,
      setNumPages,
      setPageDimensions,
      "party"
    );
    if (isMobile) {
      dispatch(changePageScaleSign(0.5));
    }
    setTimeout(() => {
      setAnnotsLoaded(true);
      goToNextAnnot(annotsSigningStatus.nextAnnotToSign);
    }, 700);
  }

  function Canvas(props) {
    const canvasRef = useRef(null);
    useEffect(() => {
      const canvas = canvasRef.current;
      canvas.getContext("2d");
    }, []);
    return <canvas ref={canvasRef} {...props} />;
  }

  return (
    <>
      <Menu secondary stackable inverted className="main-menu">
        <Menu.Item header style={{ marginLeft: "0px", paddingLeft: "0px" }}>
          <Image src="/assets/logo-transactioner-white-medium.png" />
        </Menu.Item>
      </Menu>
      <div
        id="pdf-page"
        style={{
          position: "fixed",
          bottom: "80px",
          top: "75px",
          left: 0,
          right: 0,
          overflow: "auto",
          backgroundColor: "#f3f6f8",
        }}
      >
        <Grid className="zero horizontal margin">
          <Grid.Column width={16} className="zero top padding">
            <br />
            <div>
              <Document
                file={docUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                  <div style={{ height: window.innerHeight }}>
                    <LoadingComponent />
                  </div>
                }
                options={options}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    className="pdf-page-wrapper"
                    id={`page-${index}`}
                    key={`page-${index}`}
                  >
                    <div className="pdf-page-number">
                      Page {index + 1}/{doc.numPages}
                    </div>
                    <div
                      className="pdf-page-container"
                      style={{
                        height: `${pageDimensions.height * pageScale}px`,
                        width: `${pageDimensions.width * pageScale}px`,
                      }}
                    >
                      <Page
                        scale={pageScale}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                      />
                      <Canvas
                        className="pdf-canvas"
                        id={`canvas-${index}`}
                        style={{
                          width: `${pageDimensions.width * pageScale}px`,
                          height: `${pageDimensions.height * pageScale}px`,
                        }}
                      />
                      {!doc.pdfBurnVersion &&
                        annotsToSign.map((annot) => (
                          <React.Fragment key={annot.x + "-" + annot.y}>
                            {annot.docId === doc.id &&
                              annot.page === index &&
                              annot.signerRole === signerRole && (
                                <AnnotTypeDisplay
                                  annot={annot}
                                  doc={doc}
                                  pageScale={pageScale}
                                  ipAddress={ipAddress}
                                />
                              )}
                          </React.Fragment>
                        ))}
                      {numPages &&
                        doc.pdfBurnVersion &&
                        formFieldValues?.map((formField) => (
                          <React.Fragment key={formField.name}>
                            {formField.page === index && (
                              <FormFieldSelector
                                formField={formField}
                                pageScale={pageScale}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      {numPages &&
                        doc.pdfBurnVersion &&
                        doc.annotsByAgent &&
                        Object.entries(doc.annotsByAgent).map(
                          ([key, value]) => (
                            <React.Fragment key={key}>
                              {value.page === index && (
                                <DocViewAgentAnnotField
                                  annot={value}
                                  pageScale={pageScale}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {numPages &&
                        doc.pdfBurnVersion &&
                        doc.annotsToSign &&
                        Object.entries(doc.annotsToSign).map(([key, value]) => (
                          <React.Fragment key={key}>
                            {value.page === index && (
                              <>
                                {value.signed === true && (
                                  <DocViewSignedAnnotField
                                    annot={value}
                                    pageScale={pageScale}
                                  />
                                )}
                                {!value.signed &&
                                  value.signerRole === signerRole && (
                                    <AnnotTypeDisplay
                                      annot={value}
                                      doc={doc}
                                      pageScale={pageScale}
                                      ipAddress={ipAddress}
                                    />
                                  )}
                              </>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                ))}
              </Document>
            </div>
          </Grid.Column>
        </Grid>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#f3f6f8",
        }}
      >
        <PartyDocSignActionButtons />
      </div>
    </>
  );
}
