import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedSigner,
  fetchAnnotsToSign,
} from "../../../app/annots/annotSlice";
import { fetchDocAnnotsFromDb } from "../../../app/firestore/firestoreService";
import useFirestoreCollection from "../../../app/hooks/useFirestoreCollection";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { PartyDocSignDashboard } from "./PartyDocSignDashboard";

export default function PartyDocSignPage() {
  const dispatch = useDispatch();
  const { doc } = useSelector((state) => state.doc);
  const { party } = useSelector((state) => state.party);
  const { annotsToSign, selectedSigner } = useSelector((state) => state.annot);

  useFirestoreCollection({
    query: () => fetchDocAnnotsFromDb(doc.id, party.role),
    data: (annots) => dispatch(fetchAnnotsToSign(annots)),
    deps: [doc.id, party.role, dispatch],
  });

  useEffect(() => {
    dispatch(changeSelectedSigner(party));
  }, [party, dispatch]);

  if (!doc.pdfBurnVersion && (annotsToSign?.length === 0 || !selectedSigner)) {
    return <LoadingComponent />;
  }

  return <PartyDocSignDashboard />;
}
