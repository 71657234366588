import React from "react";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import CalendarDisplay from "../../../app/common/calendar/Calendar";
import { useMediaQuery } from "react-responsive";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default function PartyCalendar() {
  const { tasksTrans, party } = useSelector((state) => state.party);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  if (!tasksTrans || !party?.email) {
    return <LoadingComponent content="Loading calendar..." />;
  }

  return (
    <Grid stackable className={isMobile ? "small padding" : "big padding"}>
      <Grid.Column computer={16} tablet={16}>
        <div style={{ padding: "0px 30px" }}>
          <CalendarDisplay tasks={tasksTrans} allowEdit={false} />
        </div>
      </Grid.Column>
    </Grid>
  );
}
