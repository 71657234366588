import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Confirm, Dropdown } from "semantic-ui-react";
import {
  deleteTaskInDb,
  updateTaskInDb,
} from "../../../app/firestore/firestoreService";
import { openModal } from "../../../app/common/modals/modalSlice";
import { useMediaQuery } from "react-responsive";

export default function TaskActionButtons({ task, textStyle = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const { id } = useParams();

  function handleGoToTransaction() {
    navigate(`/transactions/${task.transactionId}/overview`);
  }

  async function handleMarkComplete() {
    try {
      await updateTaskInDb(task.id, { status: "Complete" });
      toast.success("Deadline successfully marked as complete");
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleMarkIncomplete() {
    try {
      await updateTaskInDb(task.id, { status: "Active" });
      toast.success("Deadline successfully marked as In Progress");
    } catch (error) {
      toast.error(error.message);
    }
  }

  function handleEdit() {
    dispatch(
      openModal({
        modalType: "TaskForm",
        modalProps: { task: task },
      })
    );
  }

  async function handleDelete(task) {
    try {
      await deleteTaskInDb(task.id);
      setConfirmOpen(false);
      toast.success("Deadline successfully deleted");
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className={isMobile || textStyle ? null : "text align right"}>
      <Dropdown
        button={textStyle ? false : true}
        icon={textStyle ? "dropdown" : "chevron down"}
        text={textStyle ? task.title : isMobile ? "Actions " : null}
        className={
          textStyle
            ? task.status === "Complete"
              ? "text green"
              : "text blue"
            : isMobile
            ? "button icon"
            : "button mini icon"
        }
        direction={isMobile ? "right" : "left"}
        data-test="task-action-buttons-dropdown"
      >
        <Dropdown.Menu>
          {!id && (
            <Dropdown.Item
              onClick={() => handleGoToTransaction()}
              data-test="task-view"
            >
              View Transaction
            </Dropdown.Item>
          )}
          {task.status !== "Complete" ? (
            <Dropdown.Item onClick={() => handleMarkComplete()}>
              Mark Complete
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => handleMarkIncomplete()}>
              Mark Incomplete
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => handleEdit()} data-test="task-edit">
            Edit
          </Dropdown.Item>
          {/* {task.archived === true ? (
            <Dropdown.Item
              onClick={() => handleUnarchive()}
              data-test="task-make-active"
            >
              Make Active
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              onClick={() => handleArchive()}
              data-test="task-archive"
            >
              Archive
            </Dropdown.Item>
          )} */}
          <Dropdown.Item
            onClick={() => setConfirmOpen(true)}
            data-test="task-delete"
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleDelete(task)}
      ></Confirm>
    </div>
  );
}
