import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  emailsAll: { emails: [], column: "", direction: "" },
  emailFromTemplate: {},
};

const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    fetchEmails(state, action) {
      state.emailsAll.emails = _.orderBy(action.payload, "createdAt", "desc");
    },
    fetchEmailFromTemplate(state, action) {
      state.emailFromTemplate = action.payload;
    },
    clearEmailFromTemplate(state, action) {
      state.emailFromTemplate = {};
    },
    sortEmails(state, action) {
      if (state.emailsAll.column === action.payload) {
        state.emailsAll = {
          emails: state.emailsAll.emails.slice().reverse(),
          column: action.payload,
          direction:
            state.emailsAll.direction === "ascending"
              ? "descending"
              : "ascending",
        };
      } else {
        state.emailsAll = {
          email: _.sortBy(state.emailsAll.emails, [action.payload]),
          column: action.payload,
          direction: "ascending",
        };
      }
    },
  },
});

export const {
  fetchEmails,
  fetchEmailFromTemplate,
  clearEmailFromTemplate,
  sortEmails,
} = emailSlice.actions;

export default emailSlice.reducer;
