import { createSlice } from '@reduxjs/toolkit'
import _ from "lodash";
import { abbreviateDirection } from '../../app/common/util/util';

const initialState = {
  party: {},
  partyInfoFromUrl: {},
  docsForViewing: {},
  docsForSigning: {},
  docsForParty: {},
  docUrl: "",
  doc: {},
  pageScale: 1.5,
  docToSign: {},
  docToView: {},
  tasksTrans: [],
  partiesForUser: {parties: [], column: "", direction: ""},
  transactionsForUser: [],
}

const partySlice = createSlice({
  name: 'party',
  initialState,
  reducers: {
    fetchParty(state, action) {
      state.party = action.payload
    },
    fetchPartiesForUser(state, action) {
      if (state.partiesForUser.column && state.partiesForUser.direction) {
        state.partiesForUser.parties = _.orderBy(action.payload.parties, state.partiesForUser.column, abbreviateDirection(state.partiesForUser.direction))
      } else {
        state.partiesForUser.parties = action.payload.parties
      }
    },
    fetchTransactionsForUser(state, action) {
      state.transactionsForUser = action.payload
    },
    sortPartiesForUser(state, action) {
      if (state.partiesForUser.column === action.payload) {
        state.partiesForUser = {
          tasks: state.partiesForUser.agents.slice().reverse(),
          column: action.payload,
          direction: state.partiesForUser.direction  === 'ascending' ? 'descending' : 'ascending'
      }
      } else {
        state.partiesForUser = {
          tasks: _.sortBy(state.tpartiesForUser.agents, [action.payload]),
          column: action.payload,
          direction: 'ascending'
        }
      }
    },
    extractPartyInfoFromUrl(state, action) {
      state.partyInfoFromUrl = action.payload
    },
    fetchDocsForParty(state, action) {
      if (action.payload.docs) {
        action.payload.docs = _.sortBy(action.payload.docs, ["updatedAt"], ["descending"]).slice().reverse();
      }
      state.docsForParty = {docs: action.payload.docs}
      state.docsForViewing = {docs: action.payload.docs.filter(doc => !doc.signingRequestedFor || !doc.signingRequestedFor.includes(action.payload.email))}
      state.docsForSigning = {docs: action.payload.docs.filter(doc => doc.signingRequestedFor && doc.signingRequestedFor.includes(action.payload.email))}
    },
    fetchTasksForParty(state, action) {
      state.tasksTrans = action.payload.filter(task => task.archived !== true)
    },
    getDocAndUrl(state, action) {
      state.doc = action.payload.doc
			state.docUrl = action.payload.docUrl
		},
    changePageScale(state, action) {
      state.pageScale = action.payload
    },
		sortDocsForViewing(state, action) {
      if (state.docsForViewing.column === action.payload) {
        state.docsForViewing = {
          docs: state.docsForViewing.docs.slice().reverse(),
          column: action.payload,
          direction: state.docsForViewing.direction  === 'ascending' ? 'descending' : 'ascending'
        }
      } else {
        state.docsForViewing = {
          docs: _.sortBy(state.docsForViewing.docs, [action.payload]),
          column: action.payload,
          direction: 'ascending'
        }
      }
    },
    sortDocsForSigning(state, action) {
      if (state.docsForSigning.column === action.payload) {
        state.docsForSigning = {
          docs: state.docsForSigning.docs.slice().reverse(),
          column: action.payload,
          direction: state.docsForSigning.direction  === 'ascending' ? 'descending' : 'ascending'
        }
      } else {
        state.docsForSigning = {
          docs: _.sortBy(state.docsForSigning.docs, [action.payload]),
          column: action.payload,
          direction: 'ascending'
        }
      }
    },
    setDocToSign(state, action) {
      state.docToSign = action.payload
    },
    setDocToView(state, action) {
      state.docToView = action.payload
    },
  }
});

export const { fetchParty, fetchPartiesForUser, fetchTransactionsForUser, sortPartiesForUser, extractPartyInfoFromUrl, fetchDocsForParty, fetchTasksForParty, getDocAndUrl, changePageScale, sortDocsForViewing, sortDocsForSigning, setDocToSign, setDocToView } = partySlice.actions

export default partySlice.reducer