export function standardClauses() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Buyer has disclosed heshe holds an Oklahoma Real Estate license number", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 294.72,
   left: 404.52,
   width: 88.68,
   height: 9.84
}
,
{
   page: 0,
   name: "s trust account shall be payable to", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 323.4,
   left: 394.2,
   width: 187.44,
   height: 9.48
}
,
{
   page: 0,
   name: "is related to or associated with the real estate sales associate Broker in the following manner", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 352.2,
   left: 484.68,
   width: 98.76,
   height: 9.84
}
,
{
   page: 0,
   name: "Inspection of Septic and Water Supply Within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 370.08,
   left: 281.52,
   width: 75.24,
   height: 9.84
}
,
{
   page: 0,
   name: "Contract and to cancel and terminate this Contract by notice in writing delivered to the Listing Broker within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 523.08,
   left: 539.52,
   width: 46.32,
   height: 9.84
}
,
{
   page: 0,
   name: "Division of the District Court for", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 575.64,
   left: 203.28,
   width: 160.32,
   height: 9.84
}
,
{
   page: 0,
   name: "has not been confirmed by said Court within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 587.4,
   left: 259.92,
   width: 97.92,
   height: 9.84
}
,
{
   page: 1,
   name: "within", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 221.52,
   left: 416.16,
   width: 128.40,
   height: 10.80
}
,
{
   page: 1,
   name: "days of the date set forth in the paragraph entitled Time Periods Specified in Contract of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 233.28,
   left: 62.76,
   width: 88.92,
   height: 11.04
}
,
{
   page: 1,
   name: "days from the date set forth in", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 364.92,
   left: 290.16,
   width: 130.32,
   height: 11.04
}
,
{
   page: 1,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 388.92,
   left: 65.4,
   width: 513.24,
   height: 11.04
}
,
{
   page: 1,
   name: "days", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 441.36,
   left: 511.2,
   width: 47.28,
   height: 11.04
}
,
{
   page: 1,
   name: "shall be guaranteed to be available at Closing by the lender locked in for a period of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 465.12,
   left: 452.52,
   width: 128.04,
   height: 11.04
}
,
{
   page: 1,
   name: "of the loan amount if applicable paid by the Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 477.12,
   left: 141.12,
   width: 65.64,
   height: 11.04
}
,
{
   page: 1,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 519,
   left: 479.52,
   width: 86.88,
   height: 11.04
}
,
{
   page: 1,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 614.76,
   left: 303.48,
   width: 125.40,
   height: 11.04
}
,
{
   page: 1,
   name: "days or Seller shall", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 633,
   left: 473.04,
   width: 19.92,
   height: 11.04
}
] }
