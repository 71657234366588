export function rPCDExemptionForm() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 125.191,
   left: 29.0077,
   width: 547.3293,
   height: 19.084
}
,
{
   page: 0,
   name: "Text4", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 141.222,
   left: 30.5344,
   width: 544.2756,
   height: 22.137
}
,
{
   page: 0,
   name: "Check Box9", 
   isText: false,
   type: "checkbox",
   top: 173.228,
   left: 50.3935,
   width: 12.5984,
   height: 13.386
}
,
{
   page: 0,
   name: "Check Box10", 
   isText: false,
   type: "checkbox",
   top: 200.787,
   left: 50.3935,
   width: 12.5984,
   height: 14.960
}
,
{
   page: 0,
   name: "Check Box11", 
   isText: false,
   type: "checkbox",
   top: 267.716,
   left: 49.6061,
   width: 13.3858,
   height: 12.598
}
,
{
   page: 0,
   name: "Check Box12", 
   isText: false,
   type: "checkbox",
   top: 296.849,
   left: 49.6061,
   width: 14.1732,
   height: 13.386
}
,
{
   page: 0,
   name: "Check Box13", 
   isText: false,
   type: "checkbox",
   top: 314.172,
   left: 48.8187,
   width: 15.7480,
   height: 12.599
}
,
{
   page: 0,
   name: "Check Box14", 
   isText: false,
   type: "checkbox",
   top: 342.519,
   left: 48.8187,
   width: 14.9606,
   height: 14.173
}
,
{
   page: 0,
   name: "Check Box15", 
   isText: false,
   type: "checkbox",
   top: 372.44,
   left: 50.3935,
   width: 13.3858,
   height: 12.598
}
,
{
   page: 0,
   name: "Check Box16", 
   isText: false,
   type: "checkbox",
   top: 389.762,
   left: 49.6061,
   width: 13.3858,
   height: 13.386
}
,
{
   page: 0,
   name: "Check Box17", 
   isText: false,
   type: "checkbox",
   top: 406.298,
   left: 48.0313,
   width: 14.9606,
   height: 13.386
}
] }
