export function brokerageDisclosuretoBuyer2024() {

return [   //2024 Release 2024-07-31 07:29:06
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 

{
   page: 0,
   name: "below is for a specific property described as", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 509.207,
   left: 57.6,
   width: 495.12,
   height: 12.633
}
,
{
   page: 0,
   name: "or real estate which substantially meets the following requirements", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.975,
   left: 57.6,
   width: 490.44,
   height: 24.414
}
,
{
   page: 0,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 619.904,
   left: 56.6366,
   width: 10.0800,
   height: 10.080
}
,
{
   page: 0,
   name: "Check Box2", 
   isText: false,
   type: "checkbox",

   top: 674.232,
   left: 57.2912,
   width: 10.0799,
   height: 10.080
}
   ,
{
   page: 1,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 1,
   name: "Customer Broker is the", 
   isText: false,
   type: "checkbox",
   top: 77.4,
   left: 188.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "sellers agent", 
   isText: false,
   type: "checkbox",
   top: 77.4,
   left: 257.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "perform the following list of tasks", 
   isText: false,
   type: "checkbox",
   top: 88.92,
   left: 200.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Show a property", 
   isText: false,
   type: "checkbox",
   top: 88.92,
   left: 283.2,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Buyer", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 463.428,
   left: 87.6687,
   width: 188.0513,
   height: 10.572
}
,
{
   page: 1,
   name: "Buyer_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 463.428,
   left: 375.9,
   width: 181.371,
   height: 10.572
}
,
{
   page: 1,
   name: "buyerNames", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 537.667,
   left: 242.837,
   width: 291.657,
   height: 12.223
}
,
{
   page: 1,
   name: "this document via", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 561.187,
   left: 153.16,
   width: 248.132,
   height: 12.773
}
,
{
   page: 1,
   name: "Brokerage Firm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 582.707,
   left: 126.48,
   width: 418.891,
   height: 12.773
}
,
{
   page: 1,
   name: "signature Broker", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 601.425,
   left: 57.6,
   width: 164.386,
   height: 14.975
}
,
{
   page: 1,
   name: "Check Box3", 
   isText: false,
   type: "checkbox",
   top: 80.193,
   left: 57.2912,
   width: 10.0799,
   height: 10.080
}
,
{
   page: 1,
   name: "Check Box4", 
   isText: false,
   type: "checkbox",
   top: 122.739,
   left: 57.2912,
   width: 10.0799,
   height: 10.080
}
,
{
   page: 1,
   name: "Check Box5", 
   isText: false,
   type: "checkbox",
   top: 166.939,
   left: 57.2912,
   width: 10.0799,
   height: 10.080
}
,
{
   page: 1,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 496.981,
   left: 97.4149,
   width: 177.5181,
   height: 10.993
}
,
{
   page: 1,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 498.082,
   left: 375.9,
   width: 181.371,
   height: 10.442
}
,
{
   page: 1,
   name: "Date brokerAckDate", 
   fontSize: 9,
   type: "date",
   top: 537.158,
   left: 71.5477,
   width: 97.7153,
   height: 13.194
}
,
{
   page: 1,
   name: "Date brokerSigns", 
   fontSize: 9,
   type: "date",
   top: 601.651,
   left: 225.1,
   width: 114.226,
   height: 14.846
}
,
{
   page: 1,
   name: "signature CoBroker", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 634.123,
   left: 57.7885,
   width: 161.5575,
   height: 13.745
}
,
{
   page: 1,
   name: "Date coBrokerSigns", 
   fontSize: 9,
   type: "date",
   top: 635.022,
   left: 227.852,
   width: 113.676,
   height: 12.644
}
,
{
   page: 2,
   name: "SuccessFee", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 164.76,
   left: 298.44,
   width: 34.78,
   height: 10.68
}
,
{
   page: 2,
   name: "successFeeDollars", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 164.76,
   left: 449.04,
   width: 100.08,
   height: 10.68
}
,
{
   page: 2,
   name: "signature BrokerSign2", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 10,
   top: 404.76,
   left: 310.248,
   width: 175.060,
   height: 10.13
}
,
{
   page: 2,
   name: "Date_2", 
   fontSize: 9,
   type: "date",
   top: 404.76,
   left: 489.6,
   width: 60.0,
   height: 10.68
}
,
{
   page: 2,
   name: "Street Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 407.8,
   left: 57.6,
   width: 235.08,
   height: 10.56
}
,
{
   page: 2,
   name: "Brokerage Firm Street Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 427.8,
   left: 309.6,
   width: 240.0,
   height: 10.56
}
,
{
   page: 2,
   name: "City State Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 442.24,
   left: 57.6,
   width: 235.08,
   height: 10.68
}
,
{
   page: 2,
   name: "Brokerage Firm City State Zip", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 462.24,
   left: 309.6,
   width: 240.0,
   height: 10.68
}
,
{
   page: 2,
   name: "Phone No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 465.28,
   left: 57.72,
   width: 234.96,
   height: 10.68
}
,
{
   page: 2,
   name: "Broker Phone No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 485.28,
   left: 309.72,
   width: 240.00,
   height: 10.68
}
,
{
   page: 2,
   name: "Fax No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 488.2,
   left: 57.72,
   width: 234.96,
   height: 10.68
}
,
{
   page: 2,
   name: "Broker Fax No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 508.2,
   left: 309.72,
   width: 240.00,
   height: 10.68
}
,
{
   page: 2,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 511.24,
   left: 57.72,
   width: 234.96,
   height: 10.68
}
,
{
   page: 2,
   name: "Broker Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 531.24,
   left: 309.72,
   width: 240.00,
   height: 10.68
}
,
{
   page: 2,
   name: "Street Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 574.36,
   left: 57.72,
   width: 235.08,
   height: 10.56
}
,
{
   page: 2,
   name: "City State Zip_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 597.28,
   left: 57.72,
   width: 235.08,
   height: 10.68
}
,
{
   page: 2,
   name: "Phone No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 620.32,
   left: 57.72,
   width: 235.08,
   height: 10.68
}
,
{
   page: 2,
   name: "Fax No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 643.24,
   left: 57.72,
   width: 235.08,
   height: 10.68
}
,
{
   page: 2,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 666.28,
   left: 57.72,
   width: 235.08,
   height: 10.68
}
] }
