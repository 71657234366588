import React from "react";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import TaskTemplatesList from "./taskTemplatesComponents/TaskTemplatesList";
import { openModal } from "../../../app/common/modals/modalSlice";
import { fetchTaskTemplate, fetchTaskTemplates } from "./taskTemplatesSlice";
import { fetchTaskTemplatesFromDb } from "../../../app/firestore/firestoreService";
import useFirestoreCollectionNoAsync from "../../../app/hooks/useFirestoreCollectionNoAsync";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default function TaskTemplatesDashboard() {
  const dispatch = useDispatch();
  const { taskTemplates } = useSelector((state) => state.taskTemplates);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  useFirestoreCollectionNoAsync({
    query: () => fetchTaskTemplatesFromDb(),
    data: (taskTemplates) => dispatch(fetchTaskTemplates(taskTemplates)),
    deps: [dispatch],
  });

  function handleAddTemplate() {
    dispatch(
      fetchTaskTemplate({
        title: "",
        items: [],
      })
    );
    dispatch(
      openModal({
        modalType: "TaskTemplatesEdit",
        modalProps: { newTemplate: true },
      })
    );
  }

  if (!taskTemplates) {
    return <LoadingComponent />;
  }

  return (
    <div className="main-page-wrapper">
      <Grid centered stackable className="small bottom margin">
        <Grid.Column width={10}>
          <Header color="blue" as="h2">
            Task Templates
          </Header>
          <p color="grey">
            Create templates for common tasks for a transaction
          </p>
          <Segment clearing>
            <Grid.Column width={10} className="large bottom margin">
              <div className={isMobile ? null : "float-right"}>
                <Button
                  to="#"
                  icon="plus"
                  size="small"
                  className={isMobile ? "fluid" : null}
                  onClick={() => handleAddTemplate()}
                  content="Add Template"
                  color="blue"
                />
              </div>
            </Grid.Column>
            <br />
            <Divider hidden className="large vertical margin" />
            <Grid.Column width={10}>
              {taskTemplates?.length > 0 ? (
                <TaskTemplatesList
                  taskTemplates={taskTemplates}
                  column={taskTemplates.column}
                  direction={taskTemplates.direction}
                />
              ) : (
                <p>There are no task templates.</p>
              )}
            </Grid.Column>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}
