import React, { useState } from "react";
import { Grid, Input, Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { searchFilter } from "../../../app/common/util/util";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import EmailsList from "./EmailList";
import useFirestoreCollectionNoAsync from "../../../app/hooks/useFirestoreCollectionNoAsync";
import { fetchEmailsFromDb } from "../../../app/firestore/firestoreService";
import { fetchEmails } from "../emailSlice";

export default function EmailHistoryPage() {
  const dispatch = useDispatch();
  const { emailsAll } = useSelector((state) => state.email);
  const [searchTerms, setSearchTerms] = useState("");
  const emails = searchFilter(emailsAll.emails, searchTerms);
  let { id } = useParams();

  useFirestoreCollectionNoAsync({
    query: () => fetchEmailsFromDb(id),
    data: (emailsFromDb) => dispatch(fetchEmails(emailsFromDb)),
    deps: [dispatch],
  });

  if (!emailsAll) {
    return <LoadingComponent />;
  }

  return (
    <div className="secondary-page-wrapper">
      <Grid stackable>
        <Grid.Column computer={7}>
          <Input
            type="text"
            fluid
            placeholder="Search by name, email, or text in a message"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          ></Input>
        </Grid.Column>
        <Grid.Column computer={3}>
          <Button.Group fluid size="small">
            <Button as={Link} to={`/transactions/${id}/emails`}>
              Send Email
            </Button>
            <Button active as={Link} to="">
              Email History
            </Button>
          </Button.Group>
        </Grid.Column>
        <Grid.Column computer={6}></Grid.Column>
        <Grid.Column computer={16}>
          <h3>Email History</h3>
          {emails.length > 0 ? (
            <EmailsList
              emails={emails}
              column={emailsAll.column}
              direction={emailsAll.direction}
            />
          ) : (
            <p>There are no emails for this transaction</p>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
}
