export function sellersDisclosureNotice() {
return [   //2024 Release 2024-03-12 21:12:54
{
   page: 0,
   name: "CONCERNING THE PROPERTY AT", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 123.742,
   left: 179.88,
   width: 396.48,
   height: 12.818
}
,
{
   page: 0,
   name: "Check Box34", 
   isText: false,
   type: "checkbox",
   top: 191.116,
   left: 74.073,
   width: 11.3673,
   height: 9.524
}
,
{
   page: 0,
   name: "isNotOccupy", 
   isText: true,
   type: "checkbox",
   top: 191.116,
   left: 99.32,
   width: 11.367,
   height: 9.524
}
,
{
   page: 0,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 193.276,
   left: 512.76,
   width: 63.60,
   height: 12.164
}
,
{
   page: 0,
   name: "Range", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.52,
   left: 62.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Oven", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Microwave", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.52,
   left: 396.24,
   width: 26.28,
   height: 12.818
}
,
{
   page: 0,
   name: "Dishwasher", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 245.52,
   left: 62.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Trash Compactor", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 245.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Disposal", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 245.52,
   left: 395.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "WasherDryer Hookups", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 263.52,
   left: 62.28,
   width: 27.00,
   height: 12.818
}
,
{
   page: 0,
   name: "Window Screens", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 263.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Rain Gutters", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 263.52,
   left: 395.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Security System", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 281.52,
   left: 62.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Fire Detection Equipment", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 281.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Intercom System", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 281.52,
   left: 395.28,
   width: 27.36,
   height: 12.818
}
,
{
   page: 0,
   name: "Smoke Detector", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 299.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Smoke DetectorHearing Impaired", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 317.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Carbon Monoxide Alarm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 335.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "TV Antenna", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 371.52,
   left: 62.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Emergency Escape Ladders", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 353.52,
   left: 215.28,
   width: 27.60,
   height: 12.818
}
,
{
   page: 0,
   name: "Cable TV Wiring", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 371.52,
   left: 215.28,
   width: 27.72,
   height: 12.818
}
,
{
   page: 0,
   name: "Satellite Dish", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 371.52,
   left: 395.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Ceiling Fans", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 389.52,
   left: 62.28,
   width: 27.00,
   height: 12.818
}
,
{
   page: 0,
   name: "Attic Fans", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 389.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Exhaust Fans", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 389.52,
   left: 395.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Central AC", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 407.52,
   left: 62.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Central Heating", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 407.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "WallWindow Air Conditioning", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 407.52,
   left: 395.28,
   width: 27.36,
   height: 12.818
}
,
{
   page: 0,
   name: "Plumbing System", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 425.52,
   left: 62.28,
   width: 26.76,
   height: 12.818
}
,
{
   page: 0,
   name: "Septic System", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 425.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Public Sewer System", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 425.52,
   left: 395.28,
   width: 27.60,
   height: 12.818
}
,
{
   page: 0,
   name: "PatioDecking", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 443.52,
   left: 62.28,
   width: 26.76,
   height: 12.818
}
,
{
   page: 0,
   name: "Outdoor Grill", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 443.52,
   left: 215.28,
   width: 27.60,
   height: 12.818
}
,
{
   page: 0,
   name: "Fences", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 443.52,
   left: 395.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Pool", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 461.52,
   left: 62.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Sauna", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 461.52,
   left: 215.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Spa", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 461.52,
   left: 395.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Hot Tub", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 461.52,
   left: 476.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Pool Equipment", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 479.52,
   left: 62.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Pool Heater", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 479.52,
   left: 215.28,
   width: 27.72,
   height: 12.818
}
,
{
   page: 0,
   name: "Automatic Lawn Sprinkler System", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 479.52,
   left: 395.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Wood burning", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 502.56,
   left: 62.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 502.56,
   left: 395.28,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Natural Gas Lines", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 520.56,
   left: 62.28,
   width: 27.72,
   height: 12.818
}
,
{
   page: 0,
   name: "LP Community Captive", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 539.16,
   left: 176.28,
   width: 28.68,
   height: 12.818
}
,
{
   page: 0,
   name: "LP on Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 539.16,
   left: 311.04,
   width: 27.84,
   height: 12.818
}
,
{
   page: 0,
   name: "Gas Fixtures", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 520.56,
   left: 395.28,
   width: 27.72,
   height: 12.818
}
,
{
   page: 0,
   name: "Liquid Propane Gas", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 538.56,
   left: 62.28,
   width: 27.96,
   height: 12.818
}
,
{
   page: 0,
   name: "Fuel Gas Piping", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 557.52,
   left: 62.52,
   width: 28.20,
   height: 12.818
}
,
{
   page: 0,
   name: "Black Iron Pipe", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 557.64,
   left: 158.76,
   width: 27.96,
   height: 12.818
}
,
{
   page: 0,
   name: "Corrugated Stainless Steel Tubing", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 557.64,
   left: 252.84,
   width: 28.08,
   height: 12.818
}
,
{
   page: 0,
   name: "Copper", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 558.12,
   left: 424.08,
   width: 26.88,
   height: 12.818
}
,
{
   page: 0,
   name: "Attached", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 574.68,
   left: 98.16,
   width: 28.08,
   height: 12.818
}
,
{
   page: 0,
   name: "Electronic", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 593.52,
   left: 169.92,
   width: 27.84,
   height: 12.818
}
,
{
   page: 0,
   name: "Not Attached", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 575.64,
   left: 214.08,
   width: 28.80,
   height: 12.818
}
,
{
   page: 0,
   name: "Carport", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 575.64,
   left: 340.2,
   width: 27.84,
   height: 12.818
}
,
{
   page: 0,
   name: "Controls", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 593.52,
   left: 288.24,
   width: 28.44,
   height: 12.818
}
,
{
   page: 0,
   name: "Gas", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 612.24,
   left: 170.4,
   width: 27.24,
   height: 12.818
}
,
{
   page: 0,
   name: "Electric", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 612.24,
   left: 288.24,
   width: 29.04,
   height: 12.818
}
,
{
   page: 0,
   name: "City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 630.6,
   left: 170.4,
   width: 28.2,
   height: 12.818
}
,
{
   page: 0,
   name: "Well", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 630.24,
   left: 288.0,
   width: 29.4,
   height: 12.818
}
,
{
   page: 0,
   name: "MUD", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 631.08,
   left: 403.32,
   width: 26.04,
   height: 12.818
}
,
{
   page: 0,
   name: "Coop", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 630.84,
   left: 508.44,
   width: 29.76,
   height: 12.818
}
,
{
   page: 0,
   name: "Age", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 658.702,
   left: 109.56,
   width: 238.44,
   height: 12.818
}
,
{
   page: 0,
   name: "approx", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 659.356,
   left: 373.855,
   width: 118.800,
   height: 12.164
}
,
{
   page: 0,
   name: "Yes", 
   isText: true,
   type: "checkbox",
   top: 697.92,
   left: 130.2,
   width: 7.44,
   height: 7.56
}
,
{
   page: 0,
   name: "No", 
   isText: true,
   type: "checkbox",
   top: 697.92,
   left: 166.2,
   width: 7.44,
   height: 7.56
}
,
{
   page: 0,
   name: "Unknown", 
   isText: true,
   type: "checkbox",
   top: 697.8,
   left: 202.2,
   width: 7.44,
   height: 7.68
}
,
{
   page: 0,
   name: "If yes then describe Attach additional sheets if necessary", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 694.8,
   left: 506.76,
   width: 66.72,
   height: 13.08
}
,
{
   page: 0,
   name: "need of repair 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 713.0618,
   left: 65.64,
   width: 507.84,
   height: 12.8182
}
,
{
   page: 0,
   name: "need of repair 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 731.7164,
   left: 65.64,
   width: 507.84,
   height: 12.1636
}
,
{
   page: 0,
   name: "need of repair 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 748.4073,
   left: 65.64,
   width: 507.84,
   height: 13.4727
}
,
{
   page: 1,
   name: "Page 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 23.956,
   left: 269.76,
   width: 256.036,
   height: 12.164
}
,
{
   page: 1,
   name: "Does the property have working smoke detectors installed in accordance with the smoke detector requirements of Chapter", 
   isText: false,
   type: "checkbox",
   top: 57.48,
   left: 191.88,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Attach additional sheets if necessary 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 67.8,
   left: 218.64,
   width: 354.84,
   height: 10.08
}
,
{
   page: 1,
   name: "Attach additional sheets if necessary 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 83.727,
   left: 56.64,
   width: 516.84,
   height: 12.153
}
,
{
   page: 1,
   name: "Attach additional sheets if necessary 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 101.062,
   left: 56.64,
   width: 516.84,
   height: 12.818
}
,
{
   page: 1,
   name: "Attach additional sheets if necessary 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 119.062,
   left: 56.64,
   width: 516.84,
   height: 12.818
}
,
{
   page: 1,
   name: "Interior Walls", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 283.8,
   left: 53.76,
   width: 28.80,
   height: 12.96
}
,
{
   page: 1,
   name: "Ceilings", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 279.36,
   left: 233.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "Floors", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 279.36,
   left: 404.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "Exterior Walls", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 297.36,
   left: 53.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "Doors", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 297.36,
   left: 233.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "Windows", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 297.36,
   left: 404.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "Roof", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 315.36,
   left: 53.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "FoundationSlabs", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 315.36,
   left: 233.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "Sidewalks", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 315.36,
   left: 404.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "WallsFences", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 333.36,
   left: 53.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "Driveways", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 333.36,
   left: 233.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "Intercom System_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 333.36,
   left: 404.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "PlumbingSewersSeptics", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 351.36,
   left: 53.76,
   width: 28.68,
   height: 17.40
}
,
{
   page: 1,
   name: "Electrical Systems", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 351.36,
   left: 233.76,
   width: 28.80,
   height: 17.40
}
,
{
   page: 1,
   name: "Lighting Fixtures", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 351.36,
   left: 404.76,
   width: 28.20,
   height: 17.40
}
,
{
   page: 1,
   name: "Other Structural Components Describe", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 369.36,
   left: 53.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "undefined_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 369.36,
   left: 254.64,
   width: 318.84,
   height: 14.52
}
,
{
   page: 1,
   name: "1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 385.44,
   left: 83.64,
   width: 489.84,
   height: 16.44
}
,
{
   page: 1,
   name: "2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 402.48,
   left: 83.64,
   width: 489.84,
   height: 17.40
}
,
{
   page: 1,
   name: "undefined_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 420.48,
   left: 416.64,
   width: 156.84,
   height: 17.40
}
,
{
   page: 1,
   name: "1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 438.96,
   left: 56.64,
   width: 516.84,
   height: 16.92
}
,
{
   page: 1,
   name: "2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 456.48,
   left: 56.64,
   width: 516.84,
   height: 17.40
}
,
{
   page: 1,
   name: "Active Termites includes wood destroying insects", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 496.8,
   left: 53.76,
   width: 28.44,
   height: 15.96
}
,
{
   page: 1,
   name: "Previous Structural or Roof Repair", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 496.8,
   left: 314.76,
   width: 28.44,
   height: 15.96
}
,
{
   page: 1,
   name: "Termite or Wood Rot Damage Needing Repair", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 513.36,
   left: 53.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Hazardous or Toxic Waste", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 513.36,
   left: 314.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Previous Termite Damage", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 531.36,
   left: 53.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Asbestos Components", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 531.36,
   left: 314.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Previous Termite Treatment", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 549.36,
   left: 53.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Ureaformaldehyde Insulation", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 549.36,
   left: 314.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Improper Drainage", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 567.36,
   left: 53.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Radon Gas", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 567.36,
   left: 314.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Water Damage Not Due to a Flood Event", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 585.36,
   left: 53.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Lead Based Paint", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 585.36,
   left: 314.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Landfill Settling Soil Movement Fault Lines", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 603.36,
   left: 53.76,
   width: 28.20,
   height: 17.40
}
,
{
   page: 1,
   name: "Aluminum Wiring", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 603.36,
   left: 314.76,
   width: 28.08,
   height: 17.40
}
,
{
   page: 1,
   name: "Single Blockable Main Drain in PoolHot TubSpa", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 621.36,
   left: 53.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Previous Fires", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 621.36,
   left: 314.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Unplatted Easements", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 639.36,
   left: 314.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Subsurface Structure or Pits", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 657.36,
   left: 314.76,
   width: 28.44,
   height: 17.40
}
,
{
   page: 1,
   name: "Methamphetamine", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 680.4,
   left: 314.76,
   width: 28.44,
   height: 17.4
}
,
{
   page: 1,
   name: "If the answer to any of the above is yes explain  Attach additional sheets if necessary 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 699.48,
   left: 416.64,
   width: 156.84,
   height: 17.40
}
,
{
   page: 1,
   name: "If the answer to any of the above is yes explain  Attach additional sheets if necessary 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 717.96,
   left: 56.64,
   width: 516.84,
   height: 16.92
}
,
{
   page: 1,
   name: "If the answer to any of the above is yes explain  Attach additional sheets if necessary 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 735.48,
   left: 56.64,
   width: 516.84,
   height: 17.40
}
,
{
   page: 1,
   name: "Check Box45", 
   isText: false,
   type: "checkbox",
   top: 57.48,
   left: 227.783,
   width: 10.080,
   height: 10.08
}
,
{
   page: 1,
   name: "Check Box46", 
   isText: false,
   type: "checkbox",
   top: 57.48,
   left: 265.092,
   width: 10.080,
   height: 10.08
}
,
{
   page: 2,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 23.182,
   left: 269.76,
   width: 256.691,
   height: 12.338
}
,
{
   page: 2,
   name: "Are you Seller aware of any item equipment or system in or on the Property that is in need of repair", 
   isText: false,
   type: "checkbox",
   top: 57.96,
   left: 57.36,
   width: 9.12,
   height: 9.12
}
,
{
   page: 2,
   name: "Yes if you are aware", 
   isText: true,
   type: "checkbox",
   top: 48,
   left: 483,
   width: 7.44,
   height: 7.68
}
,
{
   page: 2,
   name: "undefined_5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 56.88,
   left: 398.64,
   width: 174.84,
   height: 12.00
}
,
{
   page: 2,
   name: "If yes explain attach additional sheets if necessary 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 69.96,
   left: 56.64,
   width: 516.84,
   height: 16.92
}
,
{
   page: 2,
   name: "If yes explain attach additional sheets if necessary 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 87.96,
   left: 56.64,
   width: 516.84,
   height: 16.92
}
,
{
   page: 2,
   name: "Are you Seller aware of any of the following conditions  Write Yes Y if you are aware write No N if you are not aware", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 127.8,
   left: 53.76,
   width: 28.44,
   height: 15.96
}
,
{
   page: 2,
   name: "Previous flooding due to a failure or breach of a reservoir or a controlled or emergency release of water from a reservoir", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 144.84,
   left: 53.76,
   width: 28.44,
   height: 16.92
}
,
{
   page: 2,
   name: "Previous water penetration into a structure on the property due to a natural flood event", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.84,
   left: 53.76,
   width: 28.44,
   height: 16.92
}
,
{
   page: 2,
   name: "Write Yes Y if you are aware and check wholly or partly as applicable write No N if you are not aware", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 198.84,
   left: 53.76,
   width: 28.44,
   height: 16.92
}
,
{
   page: 2,
   name: "1_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 216.84,
   left: 53.76,
   width: 27.84,
   height: 16.92
}
,
{
   page: 2,
   name: "2_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 234.84,
   left: 53.76,
   width: 27.84,
   height: 16.92
}
,
{
   page: 2,
   name: "3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 252.84,
   left: 53.76,
   width: 27.84,
   height: 16.92
}
,
{
   page: 2,
   name: "Located", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 270.84,
   left: 53.76,
   width: 28.44,
   height: 16.92
}
,
{
   page: 2,
   name: "undefined_6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 288.578,
   left: 416.64,
   width: 156.84,
   height: 14.302
}
,
{
   page: 2,
   name: "If the answer to any of the above is yes explain attach additional sheets if necessary", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 307.887,
   left: 56.64,
   width: 516.84,
   height: 12.993
}
,
{
   page: 2,
   name: "Have you Seller ever filed a claim for flood damage to the property with any insurance provider including the National", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 622.8,
   left: 497.64,
   width: 75.96,
   height: 13.08
}
,
{
   page: 2,
   name: "Yes_2", 
   isText: true,
   type: "checkbox",
   top: 625.28,
   left: 201.2,
   width: 9.404,
   height: 9.644
}
,
{
   page: 2,
   name: "No If yes explain attach additional sheets as necessary", 
   isText: true,
   type: "checkbox",
   top: 625.28,
   left: 237.2,
   width: 9.404,
   height: 9.644
}
,
{
   page: 2,
   name: "Flood Insurance Program NFIP", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 640.233,
   left: 56.28,
   width: 517.32,
   height: 13.647
}
,
{
   page: 2,
   name: "Have you Seller ever received assistance from FEMA or the US Small Business Administration SBA for flood damage to the", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 730.8,
   left: 398.64,
   width: 174.96,
   height: 13.08
}
,
{
   page: 2,
   name: "Yes_3", 
   isText: true,
   type: "checkbox",
   top: 732.3164,
   left: 102.236,
   width: 9.404,
   height: 9.6436
}
,
{
   page: 2,
   name: "No If yes explain attach additional sheets as necessary_2", 
   isText: true,
   type: "checkbox",
   top: 732.3164,
   left: 138.2,
   width: 9.404,
   height: 9.6436
}
,
{
   page: 2,
   name: "property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 747.5782,
   left: 56.28,
   width: 517.32,
   height: 14.3018
}
,
{
   page: 2,
   name: "Check Box35", 
   isText: false,
   type: "checkbox",
   top: 199.637,
   left: 119.128,
   width: 14.727,
   height: 15.382
}
,
{
   page: 2,
   name: "Check Box36", 
   isText: false,
   type: "checkbox",
   top: 199.637,
   left: 162.983,
   width: 14.727,
   height: 15.382
}
,
{
   page: 2,
   name: "Check Box37", 
   isText: false,
   type: "checkbox",
   top: 216.656,
   left: 119.128,
   width: 14.727,
   height: 15.381
}
,
{
   page: 2,
   name: "Check Box38", 
   isText: false,
   type: "checkbox",
   top: 216.656,
   left: 162.983,
   width: 14.727,
   height: 15.381
}
,
{
   page: 2,
   name: "Check Box39", 
   isText: false,
   type: "checkbox",
   top: 233.674,
   left: 119.128,
   width: 14.727,
   height: 15.382
}
,
{
   page: 2,
   name: "Check Box40", 
   isText: false,
   type: "checkbox",
   top: 233.674,
   left: 162.983,
   width: 14.727,
   height: 15.382
}
,
{
   page: 2,
   name: "Check Box41", 
   isText: false,
   type: "checkbox",
   top: 252.656,
   left: 119.128,
   width: 14.727,
   height: 15.381
}
,
{
   page: 2,
   name: "Check Box42", 
   isText: false,
   type: "checkbox",
   top: 252.656,
   left: 162.983,
   width: 14.727,
   height: 15.381
}
,
{
   page: 2,
   name: "Check Box43", 
   isText: false,
   type: "checkbox",
   top: 271.638,
   left: 119.128,
   width: 14.727,
   height: 15.381
}
,
{
   page: 2,
   name: "Check Box44", 
   isText: false,
   type: "checkbox",
   top: 271.638,
   left: 162.983,
   width: 14.727,
   height: 15.381
}
,
{
   page: 3,
   name: "Page 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 24.131,
   left: 269.76,
   width: 243.60,
   height: 11.869
}
,
{
   page: 3,
   name: "compliance with building codes in effect at that time", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 68.04,
   left: 53.76,
   width: 27.00,
   height: 17.76
}
,
{
   page: 3,
   name: "Homeowners Association or maintenance fees or assessments", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 90,
   left: 53.76,
   width: 27.00,
   height: 17.76
}
,
{
   page: 3,
   name: "with others", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 113.04,
   left: 53.76,
   width: 27.00,
   height: 17.76
}
,
{
   page: 3,
   name: "Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 140.04,
   left: 53.76,
   width: 27.00,
   height: 17.76
}
,
{
   page: 3,
   name: "Any lawsuits directly or indirectly affecting the Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162,
   left: 53.76,
   width: 27.00,
   height: 17.76
}
,
{
   page: 3,
   name: "undefined_7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 180.36,
   left: 53.76,
   width: 27.00,
   height: 17.40
}
,
{
   page: 3,
   name: "supply as an auxiliary water source", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 203.04,
   left: 53.76,
   width: 27.00,
   height: 17.76
}
,
{
   page: 3,
   name: "Any portion of the property that is located in a groundwater conservation district or a subsidence district", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 225,
   left: 53.76,
   width: 27.00,
   height: 17.76
}
,
{
   page: 3,
   name: "If the answer to any of the above is yes explain  Attach additional sheets if necessary 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 249.12,
   left: 416.64,
   width: 156.84,
   height: 17.76
}
,
{
   page: 3,
   name: "If the answer to any of the above is yes explain  Attach additional sheets if necessary 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 267.96,
   left: 56.64,
   width: 516.84,
   height: 16.92
}
,
{
   page: 3,
   name: "If the answer to any of the above is yes explain  Attach additional sheets if necessary 3_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 285.48,
   left: 56.64,
   width: 516.84,
   height: 17.40
}
] }
