import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getDocDownloadUrl } from "../../../app/firestore/firebaseService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { getDocAndUrl } from "../../docs/docSlice";
import { PartyDocFillDashboard } from "../partyDocFill/PartyDocFillDashboard";
import PartyDocSignPage from "../partyDocSign/PartyDocSignPage";
import PartyDocViewDashboard from "../partyDocView/PartyDocViewDashboard";

export default function PartyDocEditPage() {
  const dispatch = useDispatch();
  const { doc, docUrl } = useSelector((state) => state.doc);
  const { docsForParty } = useSelector((state) => state.party);
  let { docId } = useParams();
  const location = useLocation();
  const lastPath = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    if (docsForParty?.docs?.length > 0) {
      const newDoc = docsForParty.docs.filter((doc) => doc.id === docId)[0];
      if (newDoc) {
        getDocDownloadUrl(newDoc.docRef).then((url) => {
          dispatch(getDocAndUrl({ doc: newDoc, docUrl: url }));
        });
      }
    }
  }, [doc.docRef, docsForParty, docId, dispatch]);

  // doc.id !== docId is for switching documents and making sure we
  // have new document loaded in store
  if (!doc.id || !doc.docRef || !lastPath || doc.id !== docId || !docUrl) {
    return <LoadingComponent />;
  }

  return (
    <>
      {lastPath === "fill" && <PartyDocFillDashboard />}
      {lastPath === "sign" && <PartyDocSignPage />}
      {lastPath === "view" && <PartyDocViewDashboard />}
    </>
  );
}
