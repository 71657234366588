export function homeWarrantyAHS2023() {
   return [   //2023
   {
      page: 1,
      name: "Property Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 363.52,
      left: 39.6436,
      width: 262.7564,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "City", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 382.36,
      left: 40.2982,
      width: 119.4218,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "State", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 382.36,
      left: 162.72,
      width: 66.84,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "ZIP", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 382.36,
      left: 232.56,
      width: 69.84,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "Home Sq Ft", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 404.24,
      left: 40.1237,
      width: 69.8403,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "Listing Exp", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 404.24,
      left: 111.971,
      width: 189.262,
      height: 12.36
   }
   ,
   {
      page: 1,
      name: "First Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 441.505,
      left: 40.2981,
      width: 117.7859,
      height: 12.361
   }
   ,
   {
      page: 1,
      name: "Last Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 441.505,
      left: 161.084,
      width: 139.680,
      height: 12.361
   }
   ,
   {
      page: 1,
      name: "Phone Number", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 462.266,
      left: 40.2981,
      width: 117.7859,
      height: 11.880
   }
   ,
   {
      page: 1,
      name: "Email Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 462.266,
      left: 161.084,
      width: 139.680,
      height: 11.880
   }
   ,
   {
      page: 1,
      name: "Mailing Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 482.665,
      left: 39.6436,
      width: 261.1204,
      height: 12.361
   }
   ,
   {
      page: 1,
      name: "First Name 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 519.942,
      left: 39.9709,
      width: 118.1131,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Last Name 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 519.942,
      left: 161.084,
      width: 139.680,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Phone Number 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 540.822,
      left: 39.9709,
      width: 118.1131,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Email Address 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 540.822,
      left: 161.084,
      width: 139.680,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Mailing Address 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 561.582,
      left: 39.6436,
      width: 261.1204,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Closing", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 598.835,
      left: 40.0712,
      width: 122.2858,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Main Office Phone Number 3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 598.664,
      left: 164.473,
      width: 136.440,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Closing Company Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 619.474,
      left: 40.1126,
      width: 120.3924,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Fax Phone Number 3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 619.102,
      left: 164.405,
      width: 136.570,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Closing Company City", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 640.092,
      left: 40.749,
      width: 121.047,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Closing Company State", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 641.038,
      left: 164.767,
      width: 65.411,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Closing Company Zip", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 640.674,
      left: 234.113,
      width: 66.720,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Estimated Closing Date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 660.406,
      left: 40.5155,
      width: 117.7795,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Closing Number", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 660.406,
      left: 161.295,
      width: 139.680,
      height: 12.360
   }
   ,
   {
      page: 1,
      name: "Closing Rep Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 681.286,
      left: 40.5155,
      width: 260.4705,
      height: 12.240
   }
   ,
   {
      page: 1,
      name: "Email Address 3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 701.708,
      left: 41.383,
      width: 259.462,
      height: 12.2401
   }
   ,
   {
      page: 1,
      name: "CheckboxBuyer", 
      isText: false,
      type: "checkbox",
      top: 365.24,
      left: 482.16,
      width: 6.84,
      height: 6.96
   }
   ,
   {
      page: 1,
      name: "CheckboxSeller", 
      isText: false,
      type: "checkbox",
      top: 365.24,
      left: 533.2,
      width: 6.84,
      height: 6.96
   }
   ,
   {
      page: 1,
      name: "Real Estate Company", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 376.2,
      left: 308.4,
      width: 127.44,
      height: 12.0
   }
   ,
   {
      page: 1,
      name: "Main Office Phone Number", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 376.2,
      left: 438.84,
      width: 130.68,
      height: 12.0
   }
   ,
   {
      page: 1,
      name: "Real Estate Office Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 397.3,
      left: 308.4,
      width: 261.12,
      height: 12.0
   }
   ,
   {
      page: 1,
      name: "Agent Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 417.24,
      left: 308.4,
      width: 261.12,
      height: 12.00
   }
   ,
   {
      page: 1,
      name: "Agent Mobile Phone Number", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 437.76,
      left: 308.4,
      width: 127.44,
      height: 12.00
   }
   ,
   {
      page: 1,
      name: "Agent Email", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 437.76,
      left: 438.84,
      width: 130.68,
      height: 12.00
   }
   ,
   {
      page: 1,
      name: "undefined", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 478.8,
      left: 506.4,
      width: 49.08,
      height: 12.0
   }
   ,
   {
      page: 1,
      name: "undefined_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 491.16,
      left: 506.4,
      width: 49.08,
      height: 10.68
   }
   ,
   {
      page: 1,
      name: "undefined_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 502.2,
      left: 506.4,
      width: 49.08,
      height: 10.68
   }
   ,
   {
      page: 1,
      name: "undefined_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 516.022,
      left: 506.4,
      width: 49.08,
      height: 9.818
   }
   ,
   {
      page: 1,
      name: "agentPresentedHW", 
      isText: false,
      type: "checkbox",
      top: 616.4,
      left: 318.24,
      width: 8.16,
      height: 8.4
   }
   ,
   {
      page: 1,
      name: "cb_accept", 
      isText: false,
      type: "checkbox",
      top: 550.493,
      left: 317.31,
      width: 10.146,
      height: 9.164
   }
   ,
   {
      page: 1,
      name: "cb_decline", 
      isText: false,
      type: "checkbox",
      top: 564.202,
      left: 317.31,
      width: 10.146,
      height: 9.164
   }
   ] }
   