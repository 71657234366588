import React from "react";
import { Form, Formik } from "formik";
import ModalWrapper from "../../app/common/modals/modalWrapper";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button, Container, Divider, Label } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../app/common/modals/modalSlice";
import { signInWithEmail } from "../../app/firestore/firebaseService";
import { useNavigate } from "react-router-dom";
import { updateLastSignIn } from "../../app/firestore/firestoreService";
import ReactGA from "react-ga";
import MyEmailInput from "../../app/common/form/MyEmailInput";

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <ModalWrapper size="mini">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            var result = await signInWithEmail(values);
            setSubmitting(false);
            updateLastSignIn(result.user.uid);
            dispatch(closeModal());
            ReactGA.event({
              category: "User",
              action: "Login",
            });
            navigate("overview");
          } catch (error) {
            setErrors({ auth: "Problem with username or password" });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MyEmailInput name="email" placeholder="Email address" />
            <MyTextInput
              name="password"
              placeholder="Password"
              type="password"
            />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Login"
            />
          </Form>
        )}
      </Formik>
      <Container textAlign="center" className="small top margin">
        <a
          href="/#"
          onClick={() =>
            dispatch(openModal({ modalType: "ResetPasswordForm" }))
          }
        >
          Forgot Password
        </a>
      </Container>
      <Divider horizontal>Or</Divider>
      {/* <SocialLogin /> */}
    </ModalWrapper>
  );
}
