export function manufacturedHomeAddendum2024() {
    return [   //2024 Release 2024-01-13 08:21:38
    {
        page: 0,
        name: "Logo",
        isText: false,
        fontSize: 10,
        type: "logo",
        top: 28.673,
        left: 52.9457,
        width: 240,
        height: 36,
      }
      ,
      {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 204.426,
       left: 434.88,
       width: 135.00,
       height: 14.814
    }
    ,
    {
       page: 0,
       name: "Contract to Buy and Sell Real Estate Land between Seller and Buyer Contract dated", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 254.105,
       left: 432.12,
       width: 110.04,
       height: 14.815
    }
    ,
    {
       page: 0,
       name: "relating to the sale of the Property or", 
       isText: false,
       type: "checkbox",
       top: 259.08,
       left: 60.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Contract to Buy and Sell Real Estate Commercial between Seller and Buyer Contract dated", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 282.229,
       left: 460.8,
       width: 110.04,
       height: 12.851
    }
    ,
    {
       page: 0,
       name: "relating to the sale of the Property", 
       isText: false,
       type: "checkbox",
       top: 285.24,
       left: 60.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "known as", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 316.505,
       left: 93.72,
       width: 430.08,
       height: 14.815
    }
    ,
    {
       page: 0,
       name: "Manufacturer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 454.32,
       left: 180.36,
       width: 396.12,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Model", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 472.32,
       left: 180.36,
       width: 396.12,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Serial No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 490.32,
       left: 180.36,
       width: 396.12,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Size", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 508.32,
       left: 180.36,
       width: 396.12,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Age", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 526.32,
       left: 180.36,
       width: 396.12,
       height: 16.56
    }
    ,
    {
       page: 0,
       name: "Certificate No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 544.08,
       left: 180.36,
       width: 396.12,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "Manufacturer Tag No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 562.08,
       left: 180.36,
       width: 396.12,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "VIN No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 580.08,
       left: 180.36,
       width: 396.12,
       height: 16.80
    }
    ,
    {
       page: 0,
       name: "appliances and builtin vacuum systems including accessories If checked the following are owned by the Seller and included", 
       isText: false,
       type: "checkbox",
       top: 690.12,
       left: 311.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Satellite Systems including satellite dishes If any additional items are attached to the Home after the date of this Contract", 
       isText: false,
       type: "checkbox",
       top: 690.12,
       left: 266.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Water Softeners", 
       isText: false,
       type: "checkbox",
       top: 690.12,
       left: 387.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Security Systems", 
       isText: false,
       type: "checkbox",
       top: 690.12,
       left: 479.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "such additional items are also included in the Purchase Price", 
       isText: false,
       type: "checkbox",
       top: 701.64,
       left: 51.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Date or DeadlineUCC and Certificate of Title Deadline", 
       fontSize: 9,
       type: "date",
       top: 273.295,
       left: 345.52,
       width: 71.028,
       height: 10.385
    }
    ,
    {
       page: 1,
       name: "Date or DeadlineUCC and Certificate of Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 284.64,
       left: 345.52,
       width: 71.028,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Date or DeadlineUCC and Certificate of Title Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 296.64,
       left: 345.52,
       width: 71.028,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "46Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 308.64,
       left: 51.72,
       width: 30.60,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: " 7Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 308.64,
       left: 83.4,
       width: 30.6,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "UCC and Certificate of Title Resolution DeadlineRow1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 308.64,
       left: 115.08,
       width: 228.36,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Date or DeadlineRow5", 
       fontSize: 9,
       type: "date",
       top: 308.64,
       left: 344.52,
       width: 235.32,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "undefined", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 329.564,
       left: 433.32,
       width: 144.96,
       height: 12.796
    }
    ,
    {
       page: 1,
       name: "acknowledge receipt of a copy of Sellers Property Disclosure or Source of Water Addendum disclosing the source of potable water", 
       isText: false,
       type: "checkbox",
       top: 666.36,
       left: 455.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Does Not", 
       isText: false,
       type: "checkbox",
       top: 666.36,
       left: 498.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Note to Buyer SOME WATER PROVIDERS RELY TO VARYING DEGREES ON NONRENEWABLE GROUND", 
       isText: false,
       type: "checkbox",
       top: 689.16,
       left: 147.96,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Does Not acknowledge receipt of a copy of the current well permit", 
       isText: false,
       type: "checkbox",
       top: 689.16,
       left: 191.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "There is No Well", 
       isText: false,
       type: "checkbox",
       top: 689.16,
       left: 483.48,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Text103", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 168.219,
       left: 49.7457,
       width: 532.2563,
       height: 44.909
    }
    ,
    {
       page: 1,
       name: "Text104", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 133.528,
       left: 54.3275,
       width: 508.6925,
       height: 11.527
    }
    ,
    {
       page: 1,
       name: "Text105", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 119.128,
       left: 427.42,
       width: 147.382,
       height: 14.145
    }
    ,
    {
       page: 1,
       name: "Text106", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 272.51,
       left: 421.529,
       width: 156.546,
       height: 10.873
    }
    ,
    {
       page: 1,
       name: "Text107", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 284.947,
       left: 421.529,
       width: 156.546,
       height: 10.872
    }
    ,
    {
       page: 1,
       name: "Text108", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 296.51,
       left: 421.529,
       width: 156.546,
       height: 10.873
    }
    ,
    {
       page: 2,
       name: "None", 
       isText: false,
       type: "checkbox",
       top: 643.08,
       left: 121.56,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Buyer", 
       isText: false,
       type: "checkbox",
       top: 643.08,
       left: 167.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Seller", 
       isText: false,
       type: "checkbox",
       top: 643.08,
       left: 216.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "OneHalf by Buyer and OneHalf by Seller", 
       isText: false,
       type: "checkbox",
       top: 643.08,
       left: 264.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Taxes for the Calendar Year Immediately Preceding Closing", 
       isText: false,
       type: "checkbox",
       top: 693,
       left: 66.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Most Recent Mill Levy and Most Recent Assessed Valuation", 
       isText: false,
       type: "checkbox",
       top: 706.2,
       left: 66.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Other", 
       isText: false,
       type: "checkbox",
       top: 719.4,
       left: 65.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "undefined_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 716.88,
       left: 109.56,
       width: 414.96,
       height: 12.36
    }
    ,
    {
       page: 3,
       name: "Buyer_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 152.695,
       left: 77.7165,
       width: 138.2185,
       height: 13.091
    }
    ,
    {
       page: 3,
       name: "Buyer_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 152.695,
       left: 339.36,
       width: 138.218,
       height: 13.091
    }
    ,
    {
       page: 3,
       name: "Seller_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 204.055,
       left: 75.0983,
       width: 138.2177,
       height: 13.091
    }
    ,
    {
       page: 3,
       name: "Seller_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 204.055,
       left: 342.633,
       width: 138.218,
       height: 13.091
    }
    ] }
    