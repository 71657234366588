export function buyerContractAmendExtend2023() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 240,
      height: 36,
    }
    ,
   {
      page: 0,
      name: "Date", 
      fontSize: 9,
      type: "date",
      top: 139.964,
      left: 398.64,
      width: 174.00,
      height: 14.596
   }
   ,
   {
      page: 0,
      name: "Check Box ERTSLC Sell", 
      isText: false,
      type: "checkbox",
      top: 190.055,
      left: 72.6549,
      width: 12.4364,
      height: 12.437
   }
   ,
   {
      page: 0,
      name: "Check Box2", 
      isText: false,
      type: "checkbox",
      top: 208.383,
      left: 72.6549,
      width: 12.4364,
      height: 12.436
   }
   ,
   {
      page: 0,
      name: "dated", 
      fontSize: 9,
      type: "date",
      top: 223.273,
      left: 74.52,
      width: 128.88,
      height: 13.287
   }
   ,
   {
      page: 0,
      name: "15", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 237.4,
      left: 50.4,
      width: 176.564,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Text1", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 248.638,
      left: 50.4002,
      width: 537.3838,
      height: 50.399
   }
   ,
   {
      page: 0,
      name: "known as", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 299.964,
      left: 90.96,
      width: 436.68,
      height: 14.596
   }
   ,
   {
      page: 0,
      name: "Check Box ERTBLC Buy", 
      isText: false,
      type: "checkbox",
      top: 328.583,
      left: 72.6549,
      width: 12.4364,
      height: 12.437
   }
   ,
   {
      page: 0,
      name: "Check Box ETLC Tenant", 
      isText: false,
      type: "checkbox",
      top: 347.874,
      left: 72.6549,
      width: 12.4364,
      height: 12.437
   }
   ,
   {
      page: 0,
      name: "dated_2", 
      fontSize: 9,
      type: "date",
      top: 361.887,
      left: 74.52,
      width: 119.88,
      height: 12.633
   }
   ,
   {
      page: 0,
      name: "The date ending the Listing Period is changed to", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 408.353,
      left: 269.4,
      width: 303.12,
      height: 13.287
   }
   ,
   {
      page: 0,
      name: "The price or rental rate is changed to", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 425.873,
      left: 227.28,
      width: 147.24,
      height: 13.287
   }
   ,
   {
      page: 0,
      name: "Text2", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 452.584,
      left: 42.5457,
      width: 538.6933,
      height: 216.001
   }
   ,
   {
      page: 0,
      name: "Seller", 
      isText: false,
      type: "checkbox",
      top: 685.12,
      left: 53.64,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Landlord", 
      isText: false,
      type: "checkbox",
      top: 685.12,
      left: 97.08,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Buyer", 
      isText: false,
      type: "checkbox",
      top: 685.12,
      left: 156.72,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Tenant", 
      isText: false,
      type: "checkbox",
      top: 685.12,
      left: 201.84,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Brokers Signature", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 10,
      top: 704.54,
      left: 337.977,
      width: 176.823,
      height: 16.56
   }
   ,
   {
      page: 0,
      name: "Date_2", 
      fontSize: 9,
      type: "date",
      top: 704.24,
      left: 518.52,
      width: 54.60,
      height: 16.56
   }
   ,
   {
      page: 0,
      name: "signature cobroker", 
      type: "signature",
      fontName: "Helvetica",
      fontSize: 12,
      top: 733.0942,
      left: 337.747,
      width: 172.909,
      height: 15.4545
   }
   ,
   {
      page: 0,
      name: "date cobroker sign", 
      fontSize: 9,
      type: "date",
      top: 733.0942,
      left: 515.784,
      width: 59.018,
      height: 14.8000
   }
   ] }
   