import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button, Segment, Header, Divider } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { signInWithEmail } from "../../../app/firestore/firebaseService";
import { closeModal, openModal } from "../../../app/common/modals/modalSlice";
import MyTextInput from "../../../app/common/form/MyTextInput";
import ModalWrapper from "../../../app/common/modals/modalWrapper";

export default function PartyLoginCurrentUserForm({ email }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <ModalWrapper size="tiny">
      <Segment>
        <Header color="blue" textAlign="center" as="h1">
          Please Login
        </Header>
        <Divider />
        <p className="text-medium">
          Please log in using the password you set up previously.
          <br />
          If you forgot your password, please click the "Forgot Password" link
          below.
        </p>
        <Formik
          initialValues={{
            password: "",
          }}
          validationSchema={Yup.object({
            password: Yup.string().required(),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              await signInWithEmail({
                email: email,
                password: values.password,
              });
              dispatch(closeModal({ modalType: "PartyLoginCurrentUserForm" }));
              navigate(`/overviewParty`);
            } catch (error) {
              if (error.message.includes("wrong-password)")) {
                toast.error("Your password is not correct");
              } else {
                toast.error(error.message);
              }
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => (
            <Form className="ui form">
              <MyTextInput
                name="password"
                placeholder="Password"
                type="password"
              />
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                fluid
                size="large"
                color="blue"
                content="Login"
                className="medium top margin"
              />
            </Form>
          )}
        </Formik>
        <div className="small top margin text-center">
          <p
            onClick={() =>
              dispatch(openModal({ modalType: "ResetPasswordForm" }))
            }
            style={{ color: "#4183c4", cursor: "pointer" }}
          >
            Forgot Password
          </p>
        </div>
      </Segment>
    </ModalWrapper>
  );
}
