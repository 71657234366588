import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Confirm, Dropdown } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { openModal } from "../../app/common/modals/modalSlice";
import { deleteClauseInDb } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";

export default function ClausesActionButtons({ clause }) {
  const dispatch = useDispatch();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function handleEdit() {
    dispatch(
      openModal({
        modalType: "ClausesEdit",
        modalProps: { clause: clause },
      })
    );
  }

  async function handleDelete() {
    try {
      await deleteClauseInDb(clause.id);
      toast.success("Clause successfully deleted");
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  }

  return (
    <div className={isMobile ? null : "text align right"}>
      <Dropdown
        button
        icon="chevron down"
        text={isMobile ? "Actions " : null}
        className={isMobile ? "button icon" : "button mini icon"}
        direction={isMobile ? "right" : "left"}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleEdit()}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => setConfirmOpen(true)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleDelete()}
      ></Confirm>
    </div>
  );
}
