export function addendumRighttoTerminateDuetoAppraisal() {
return [   //2024 Release 2024-02-18 22:35:40
{
   page: 0,
   name: "Street Address and City", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 125.738,
   left: 224.629,
   width: 352.800,
   height: 16.211
}
,
{
   page: 0,
   name: "1 WAIVER Buyer w aives Buyers right to terminate the contract under Paragraph 2B of the", 
   isText: false,
   type: "checkbox",
   top: 238.875,
   left: 46.839,
   width: 10.401,
   height: 9.405
}
,
{
   page: 0,
   name: "2 PARTIAL WAIVER Buyer w aives Buyers right to terminate the contract under Paragraph 2B", 
   isText: false,
   type: "checkbox",
   top: 319.155,
   left: 45.9181,
   width: 10.9619,
   height: 9.405
}
,
{
   page: 0,
   name: "ii the opinion of value is", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 379.632,
   left: 230.28,
   width: 101.64,
   height: 13.968
}
,
{
   page: 0,
   name: "3 ADDITIONAL", 
   isText: false,
   type: "checkbox",
   top: 440.514,
   left: 46.3591,
   width: 10.4009,
   height: 10.527
}
,
{
   page: 0,
   name: "days after the Effective Date if", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 461.637,
   left: 65.4,
   width: 44.52,
   height: 11.163
}
,
{
   page: 0,
   name: "than", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 493.116,
   left: 124.68,
   width: 95.16,
   height: 12.285
}
] }
