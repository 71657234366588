import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateFormTemplateInDb } from "../../../../app/firestore/firestoreService";

export function FormTemplateFieldCheckbox({
  formField,
  index,
  populatingFieldsStatus,
  newPopulatedFields,
}) {
  const { pageScaleFill } = useSelector((state) => state.annot);
  const { doc } = useSelector((state) => state.doc);
  const [value, setValue] = useState(
    (doc.formFieldValues && doc.formFieldValues[formField.name]) || ""
  );
  const pageScale = pageScaleFill;

  // This is a catch for prepopulating data to make sure it rerenders
  useEffect(() => {
    if (
      populatingFieldsStatus === "Complete" &&
      newPopulatedFields?.[formField.name]
    ) {
      setValue(newPopulatedFields[formField.name]);
    }
  }, [populatingFieldsStatus, formField.name, newPopulatedFields]);

  function handleChange(e) {
    setValue(!value);
    let newValue = false;
    if (formField.isText) {
      newValue = e.target.checked ? "X" : "";
    } else {
      newValue = e.target.checked;
    }
    updateFormTemplateInDb(doc.id, {
      [`formFieldValues.${formField.name}`]: newValue,
    });
  }

  return (
    <div
      className="pdfFormField checkbox"
      style={{
        top: `${formField.top * pageScale}px`,
        left: `${formField.left * pageScale}px`,
        height: `${formField.height * pageScale}px`,
        width: `${formField.width * pageScale}px`,
        fontSize: `${formField.fontSize * pageScale}px`,
      }}
    >
      <input
        className="fill-input-field"
        type={formField.type}
        name={formField.name}
        aria-label={formField.name}
        tabIndex={index}
        data-tabindex={index}
        autoComplete="off"
        onChange={(e) => handleChange(e)}
        checked={value}
        style={{
          borderWidth: "0px",
          fontSize: `${formField.fontSize * pageScale}px`,
          margin: "0px",
          textAlign: "left",
          cursor: "pointer",
          height: "100%",
        }}
      ></input>
    </div>
  );
}
