import {
  addAnnotToDb,
  addDocToDb,
  addUploadedDocToDb,
  addUploadedFormToDb,
} from "./firestoreService";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  createUserWithEmailAndPassword,
  updatePassword,
  sendPasswordResetEmail,
  signInAnonymously,
} from "firebase/auth";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { app } from "../config/firebase";
import { collection, doc, getFirestore } from "firebase/firestore";
import _ from "lodash";
import {
  createInitialSigningDetails,
  createSuggestedAnnots,
} from "../common/util/util";

const auth = getAuth(app);
const db = getFirestore(app);

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map((e) =>
      Object.assign({}, e[1], { id: e[0] })
    );
  }
}

export function signInWithEmail(creds) {
  return signInWithEmailAndPassword(auth, creds.email, creds.password);
}

export async function signInUserNoPass(email) {
  let pass = email.split("").reverse().join("");
  return await signInWithEmailAndPassword(auth, email, pass);
}

export function signInCustomAuth(token) {
  return signInWithCustomToken(auth, token);
}

export async function socialLogin(selectedProvider) {
  let provider;
  if (selectedProvider === "facebook") {
    provider = new auth.FacebookAuthProvider();
  }
  if (selectedProvider === "google") {
    provider = new auth.GoogleAuthProvider();
  }
  try {
    const result = await auth().signInWithPopup(provider);
    if (result.additionalUserInfo.isNewUser) {
      auth().currentUser.delete();
    }
    return result;
  } catch (error) {}
}

export async function createNewUserNoPass(email) {
  let pass = email.split("").reverse().join("");
  const result = await createUserWithEmailAndPassword(auth, email, pass);
  return result;
}

export function signInAnonymous() {
  return signInAnonymously(auth);
}

export function signOutFirebase() {
  return signOut(auth);
}

export async function registerInFirebase(creds) {
  return await createUserWithEmailAndPassword(
    auth,
    creds.email,
    creds.password
  );
}

export function updateUserPassword(creds) {
  const user = auth.currentUser;
  return updatePassword(user, creds.newPassword1);
}

export function resetPassword(email) {
  return sendPasswordResetEmail(auth, email);
}

export function getDocDownloadUrl(docRef) {
  const storage = getStorage(app);
  const storageRef = ref(storage, docRef);
  return getDownloadURL(storageRef);
}

export async function addFormsToTransaction(transaction, formsAdded) {
  const agentState = transaction?.agentProfile?.state
    ? transaction.agentProfile.state
    : "Colorado";
  await Promise.all(
    formsAdded.map(async (form) => {
      const docPath = `/forms${agentState}/${form.title}.pdf`;
      const docId = doc(collection(db, "documents")).id;
      return addUploadedFormToDb(transaction, docPath, docId, form);
    })
  )
    .then(() => {
      console.log("All files uploaded");
    })
    .catch((error) => {
      console.error(`Error occured during uploading: ${error}`);
    });
}

export async function addFormToTransaction(transaction, form) {
  const agentState = transaction?.agentProfile?.state
    ? transaction.agentProfile.state
    : "Colorado";
  const docPath = `/forms${agentState}/${form.title}.pdf`;
  const docId = doc(collection(db, "documents")).id;
  return addUploadedFormToDb(transaction, docPath, docId, form);
}

export async function duplicateDocAndAnnots(
  transaction,
  docToDuplicate,
  annots
) {
  const storage = getStorage(app);
  const originalDocRef = ref(storage, docToDuplicate.docRef);
  const docId = doc(collection(db, "documents")).id;
  let duplicateDocPath = "";
  if (docToDuplicate.pdfBurnVersion && docToDuplicate.isStateForm) {
    duplicateDocPath = docToDuplicate.docRef;
  } else {
    duplicateDocPath = `users/${transaction.userId}/${transaction.id}/${docId}/${docToDuplicate.title}.pdf`;
  }
  const duplicateDocRef = ref(storage, duplicateDocPath);
  try {
    if (!docToDuplicate.pdfBurnVersion || !docToDuplicate.isStateForm) {
      const url = await getDownloadURL(originalDocRef);
      const blob = await fetch(url).then((r) => r.blob());
      uploadBytesResumable(duplicateDocRef, blob);
    }
    let docCopy = _.cloneDeep(docToDuplicate);
    docCopy.annotsInProgressSuggested = [];
    docCopy.docRef = duplicateDocPath;
    docCopy.userId = transaction.userId;
    docCopy.sharingWith = [];
    docCopy.sharingWithRole = {};
    docCopy.subStatus = "Duplicated Form";
    docCopy.isDuplicate = true;
    docCopy.duplicatedAt = new Date();
    docCopy.name = "Duplicate of " + docToDuplicate.name;
    docCopy["signingDueAt"] = "";
    docCopy["signingEmailReminders"] = "";
    docCopy["signingIndex"] = "";
    docCopy["signingRequestedFor"] = [];
    docCopy["signingSentOutAt"] = "";
    docCopy["signed"] = false;
    docCopy["signingComplete"] = false;
    docCopy["signingDetails"] = {};
    await addDocToDb(docCopy, docId);
    if (!docToDuplicate.pdfBurnVersion && annots?.length > 0) {
      annots.forEach((annot) => {
        const annotId = doc(collection(db, "documents", docId, "annots")).id;
        addAnnotToDb(annot, annotId, docId);
      });
    }
    return;
  } catch (error) {
    throw error;
  }
}

export async function copyForm(transaction, formToCopy, originalForm) {
  const storage = getStorage(app);
  const agentState = transaction?.agentProfile?.state
    ? transaction.agentProfile.state
    : "Colorado";
  const storageRef = ref(
    storage,
    `/forms${agentState}/${formToCopy.title}.pdf`
  );
  const docId = doc(collection(db, "documents")).id;
  let docPath = "";
  if (formToCopy.pdfBurnVersion) {
    docPath = formToCopy.docRef;
  } else {
    docPath = `users/${transaction.userId}/${transaction.id}/${docId}/${formToCopy.title}.pdf`;
  }
  const formRef = ref(storage, docPath);
  let docCopy = _.cloneDeep(formToCopy);
  let newFormFields = formToCopy.formFieldValues;
  let newAnnotsInProgress = createSuggestedAnnots(transaction, formToCopy);
  if (
    !formToCopy.pdfBurnVersion &&
    formToCopy.formFieldValuesForCopy &&
    (formToCopy.status === "Complete" ||
      formToCopy.status === "Awaiting Signature")
  ) {
    newFormFields = formToCopy.formFieldValuesForCopy;
  }
  docCopy["userId"] = transaction.userId || "";
  docCopy["transactionId"] = transaction.id || "";
  docCopy["transactionTitle"] = transaction.title || "";
  docCopy["docRef"] = docPath;
  docCopy["formFieldValues"] = newFormFields || {};
  docCopy["annotsInProgress"] = newAnnotsInProgress || [];
  docCopy["annotsToSign"] = {};
  docCopy["annotsByAgent"] = {};
  docCopy["status"] = "In Progress";
  docCopy["statusPrevious"] = "";
  docCopy["subStatus"] = "Filling Out Form";
  docCopy["sharingWith"] = [];
  docCopy["sharingWithRole"] = {};
  docCopy["signingDueAt"] = "";
  docCopy["signingEmailReminders"] = "";
  docCopy["signingEnvelopeId"] = "";
  docCopy["signingIndex"] = "";
  docCopy["signingRequestedFor"] = [];
  docCopy["signingSentOutAt"] = "";
  docCopy["signed"] = false;
  docCopy["signingComplete"] = false;
  docCopy["signingDetails"] =
    createInitialSigningDetails(transaction, originalForm) || {};
  docCopy["signingDetailsHistory"] = [];
  docCopy["signerListInProgress"] = [];
  docCopy["name"] = "Copy of " + formToCopy.name;
  try {
    if (!formToCopy.pdfBurnVersion) {
      const url = await getDownloadURL(storageRef);
      const blob = await fetch(url).then((r) => r.blob());
      uploadBytesResumable(formRef, blob);
    }
    return await addDocToDb(docCopy, docId);
  } catch (error) {
    throw error;
  }
}

export async function addUploadedDocToTransaction(
  blobUrl,
  name,
  filetypeMIME,
  filesize,
  transaction
) {
  if (!blobUrl || !name) return null;
  const docId = doc(collection(db, "documents")).id;
  const docPath = `users/${transaction.userId}/${transaction.id}/${docId}/${name}`;
  try {
    const blob = await fetch(blobUrl).then((r) => r.blob());
    const fileUrl = await uploadBlobToStorage(docPath, blob);
    return await addUploadedDocToDb(
      transaction,
      docPath,
      docId,
      name,
      filetypeMIME,
      filesize,
      fileUrl
    );
  } catch (error) {
    throw error;
  }
}

export async function uploadBlobToStorage(docRef, blob) {
  try {
    const storage = getStorage(app);
    const storageRef = ref(storage, docRef);
    const snapshot = await uploadBytesResumable(storageRef, blob);
    return await getDownloadURL(snapshot.ref);
  } catch (error) {
    throw error;
  }
}

export function deleteDocFromStorage(docRef) {
  const storage = getStorage(app);
  const storageRef = ref(storage, docRef);
  return deleteObject(storageRef);
}
