export function addendumReleaseofLiabilityonAssumedLoan() {
return [   //2024 Release 2024-02-19 07:42:48
{
   page: 0,
   name: "Address of Property", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 146.736,
   left: 36.0,
   width: 540.12,
   height: 13.224
}
,
{
   page: 0,
   name: "Check Box1", 
   isText: false,
   type: "checkbox",
   top: 205.634,
   left: 34.7619,
   width: 9.6767,
   height: 9.187
}
,
{
   page: 0,
   name: "release of Sellers liability from a any conventional lender b VA and any lender whose loan has", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 223.464,
   left: 96.8208,
   width: 80.4002,
   height: 11.266
}
,
{
   page: 0,
   name: "Check Box3", 
   isText: false,
   type: "checkbox",
   top: 286.419,
   left: 62.1798,
   width: 11.1455,
   height: 11.145
}
,
{
   page: 0,
   name: "Check Box4", 
   isText: false,
   type: "checkbox",
   top: 305.024,
   left: 61.6902,
   width: 11.1455,
   height: 11.145
}
,
{
   page: 0,
   name: "Check Box2", 
   isText: false,
   type: "checkbox",
   top: 329.504,
   left: 34.7619,
   width: 10.6559,
   height: 10.656
}
,
{
   page: 0,
   name: "restoration of Sellers VA entitlement and shall furnish all information and documents required by VA", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 346.075,
   left: 98.16,
   width: 74.28,
   height: 12.245
}
,
{
   page: 0,
   name: "Check Box5", 
   isText: false,
   type: "checkbox",
   top: 389.236,
   left: 61.2006,
   width: 11.6351,
   height: 10.166
}
,
{
   page: 0,
   name: "Check Box6", 
   isText: false,
   type: "checkbox",
   top: 405.882,
   left: 60.711,
   width: 11.1455,
   height: 12.125
}
] }
