export function conventionalLoan() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 99.64,
   left: 31.5,
   width: 549.0,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 169.76,
   left: 495.0,
   width: 60.03,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 285.4,
   left: 195.12,
   width: 25.20,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 584.52,
   left: 181.44,
   width: 396.108,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 598.248,
   left: 31.5,
   width: 546.048,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 614.192,
   left: 31.5,
   width: 546.048,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 629.208,
   left: 31.5,
   width: 546.048,
   height: 13.500
}
,
{
   page: 0,
   name: "Text Field 14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 645.16,
   left: 31.5,
   width: 546.048,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 662.112,
   left: 31.5,
   width: 546.048,
   height: 13.500
}
] }
