import React from "react";

export function FormFieldCheckboxDisplay({ formField, pageScale }) {
  return (
    <div
      className="pdfFormFieldDisplay"
      style={{
        top: `${formField.top * pageScale}px`,
        left: `${formField.left * pageScale}px`,
        height: `${formField.height * pageScale}px`,
        width: `${formField.width * pageScale}px`,
        fontSize: `${formField.fontSize * pageScale}px`,
        textAlign: "center",
      }}
    >
      <input
        type="checkbox"
        checked
        readOnly
        className="textFieldDisplay"
        style={{
          fontFamily: "helvetica",
          fontSize: `${formField.fontSize * pageScale}px`,
          margin: "0px",
          textAlign: "center",
          pointerEvents: "none",
        }}
      ></input>
    </div>
  );
}
