import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDocsUpcoming,
  fetchStateForms,
} from "../../features/docs/docSlice";
import { fetchFormTemplates } from "../../features/formTemplates/formTemplatesSlice";
import { fetchPeople } from "../../features/people/peopleSlice";
import { fetchTasksUpcoming } from "../../features/tasks/taskSlice";
import { fetchTransActive } from "../../features/transactions/transactionSlice";
import useFirestoreCollection from "../hooks/useFirestoreCollection";
import {
  fetchDocsUpcomingFromDb,
  fetchStateFormsFromDb,
  fetchTasksUpcomingFromDb,
  fetchTransActiveFromDb,
  fetchPeopleFromDb,
  fetchFormTemplatesFromDb,
  fetchDeadlineTemplatesFromDb,
  fetchClausesFromDb,
  fetchAgentsForAssistantFromDb,
} from "./firestoreService";
import { fetchDeadlineTemplates } from "../../features/deadlineTemplates/deadlineTemplatesSlice";
import { fetchClauses } from "../../features/clauses/clausesSlice";
import { fetchAgentsForAssistant } from "../../features/profiles/profileSlice";

export default function OverallData() {
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);

  useFirestoreCollection({
    query: () => fetchTransActiveFromDb(),
    data: (transactions) => dispatch(fetchTransActive(transactions)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchTasksUpcomingFromDb(),
    data: (tasks) => dispatch(fetchTasksUpcoming(tasks)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchDocsUpcomingFromDb(),
    data: (docs) => dispatch(fetchDocsUpcoming(docs)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchPeopleFromDb(),
    data: (clients) => dispatch(fetchPeople(clients)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchStateFormsFromDb(currentUserProfile),
    data: (forms) => dispatch(fetchStateForms(forms)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchFormTemplatesFromDb(),
    data: (formTemplates) => dispatch(fetchFormTemplates(formTemplates)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchDeadlineTemplatesFromDb(),
    data: (deadlineTemplates) =>
      dispatch(fetchDeadlineTemplates(deadlineTemplates)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchClausesFromDb(currentUserProfile),
    data: (clauses) => dispatch(fetchClauses(clauses)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchAgentsForAssistantFromDb(),
    data: (agents) => dispatch(fetchAgentsForAssistant(agents)),
    deps: [dispatch],
  });

  return <></>;
}
