import { Formik, Form, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Confirm,
  Divider,
  Grid,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import {
  addPartyToDb,
  deletePartyInDb,
  updatePartyInDb,
} from "../../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";
import MySelectInput from "../../../app/common/form/MySelectInput";
import {
  convertRoleToPeopleType,
  partyHasCompany,
  partyIsAgent,
  partyIsBuyerOrSeller,
} from "../../../app/common/util/util";
import {
  partyRoleBuyerOptions,
  partyRoleSellerOptions,
} from "../../../app/common/categoryData/categoryOptions";
import FormAddress from "../../../app/common/form/FormAddress";
import MyCheckbox from "../../../app/common/form/MyCheckbox";
import { useMediaQuery } from "react-responsive";
import FormPeopleAutofill from "../../../app/common/form/FormPeopleAutofill";
import MyEmailInput from "../../../app/common/form/MyEmailInput";

export default function TransactionPartyForm({ transaction, party }) {
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { parties } = useSelector((state) => state.transaction);
  const { people } = useSelector((state) => state.people);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  function findAvialablePartyRoles(party) {
    let partyRolesAvailable =
      transaction.agentRepresents === "Buyer"
        ? partyRoleBuyerOptions
        : partyRoleSellerOptions;
    parties.forEach((person) => {
      if (person.role !== party?.role) {
        partyRolesAvailable = partyRolesAvailable.filter(
          (item) => item.value !== person.role
        );
      }
    });
    return partyRolesAvailable;
  }

  let initialValues = party
    ? party
    : {
        type: "Party",
        role: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        address: {
          street: "",
          unit: "",
          city: "",
          state: "",
          zipcode: "",
        },
        hasAssistant: false,
        assistant: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        hasTransactionCoordinator: false,
        transactionCoordinator: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        hasCoAgent: false,
        coAgent: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        isTrust: false,
        entityName: "",
        brokerLicenseNumber: "",
        brokerageName: "",
        brokerageId: "",
        brokerageLicenseNumber: "",
        transactionId: transaction?.id,
        userId: transaction.userId,
        isUser: false,
      };

  const [selectedRole, setSelectedRole] = useState(initialValues.role);
  const [isTrustField, setIsTrustField] = useState(
    initialValues.isTrust ? true : false
  );
  const [hasTransactionCoordinator, setHasTransactionCoordinator] = useState(
    initialValues.hasTransactionCoordinator
  );
  function handleTc() {
    setHasTransactionCoordinator(!hasTransactionCoordinator);
  }
  const [hasCoAgent, setHasCoAgent] = useState(initialValues.hasCoAgent);
  function handleCoAgent() {
    setHasCoAgent(!hasCoAgent);
  }

  const validationSchema = Yup.object({
    lastName: Yup.string().required("You must provide a last name"),
    role: Yup.string().required("You must provide a role"),
    email: Yup.string().notRequired().email("Must be a valid email"),
    hasTransactionCoordinator: Yup.boolean(),
    transactionCoordinator: Yup.object().when("hasTransactionCoordinator", {
      is: true,
      then: Yup.object().shape({
        email: Yup.string()
          .required("Email is required")
          .email("Must be a valid email"),
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
      }),
    }),
    hasCoAgent: Yup.boolean(),
    coAgent: Yup.object().when("hasCoAgent", {
      is: true,
      then: Yup.object().shape({
        email: Yup.string()
          .required("Email is required")
          .email("Must be a valid email"),
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
      }),
    }),
    hasAssistant: Yup.boolean(),
    assistant: Yup.object().when("hasAssistant", {
      is: true,
      then: Yup.object().shape({
        email: Yup.string()
          .required("Email is required")
          .email("Must be a valid email"),
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
      }),
    }),
  });

  function Watcher() {
    const { values } = useFormikContext();
    useEffect(() => {
      values.role && setSelectedRole(values.role);
    }, [values.role]);
    return null;
  }

  async function handleDelete() {
    try {
      await deletePartyInDb(party);
      toast.success("Party successfully deleted");
      dispatch(
        closeModal({
          modalType: "TransactionPartyForm",
        })
      );
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <ModalWrapper>
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                if (!values.hasAssistant) {
                  values.assistant = {};
                }
                party
                  ? await updatePartyInDb(party, values)
                  : await addPartyToDb(values, transaction, parties);
                setSubmitting(false);
                toast.success("Party successfully updated");
                dispatch(
                  closeModal({
                    modalType: "TransactionPartyForm",
                  })
                );
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid, values }) => (
              <Form className="ui form" autoComplete="off">
                <Watcher />
                <Header size="large" color="blue">
                  {party ? "Edit Party" : "Add Party"}
                </Header>
                <Divider />
                <Grid>
                  <Grid.Column mobile={16} computer={5}>
                    <MySelectInput
                      name="role"
                      placeholder="Role"
                      options={findAvialablePartyRoles(party)}
                    />
                  </Grid.Column>
                </Grid>
                <Divider />
                <FormPeopleAutofill
                  people={people}
                  type={convertRoleToPeopleType(selectedRole)}
                  hasAssistant={values.hasAssistant}
                  edit={party && party.email ? true : false}
                  setIsTrustField={setIsTrustField}
                  disableAutofill={party ? true : false}
                />
                {partyIsAgent(selectedRole) && (
                  <>
                    <Grid>
                      <Grid.Row className="tiny top padding tiny bottom padding">
                        <Grid.Column width={16}>
                          <MyCheckbox
                            name="hasTransactionCoordinator"
                            label="This party has a Transaction Coordinator"
                            onClick={() => handleTc()}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    {hasTransactionCoordinator && (
                      <Grid>
                        <Grid.Row className="tiny vertical padding">
                          <Grid.Column mobile={16} computer={4}>
                            <MyTextInput
                              name="transactionCoordinator.firstName"
                              label="TC first name"
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <MyTextInput
                              name="transactionCoordinator.lastName"
                              label="TC last name"
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <MyEmailInput
                              name="transactionCoordinator.email"
                              label="TC email"
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <MyTextInput
                              name="transactionCoordinator.phone"
                              label="TC phone"
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )}
                  </>
                )}
                {partyIsAgent(selectedRole) && (
                  <>
                    <Grid>
                      <Grid.Row className="tiny top padding tiny bottom padding">
                        <Grid.Column width={16}>
                          <MyCheckbox
                            name="hasCoAgent"
                            label="This party has a CoAgent"
                            onClick={() => handleCoAgent()}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    {hasCoAgent && (
                      <Grid>
                        <Grid.Row className="tiny vertical padding">
                          <Grid.Column mobile={16} computer={4}>
                            <MyTextInput
                              name="coAgent.firstName"
                              label="CoAgent first name"
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <MyTextInput
                              name="coAgent.lastName"
                              label="CoAgent last name"
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <MyEmailInput
                              name="coAgent.email"
                              label="CoAgent email"
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <MyTextInput
                              name="coAgent.phone"
                              label="CoAgent phone"
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )}
                  </>
                )}
                {partyIsBuyerOrSeller(selectedRole) && (
                  <>
                    <br />
                    <MyCheckbox
                      name="isTrust"
                      label="This client is acting on behalf of a company, trust, or other entity"
                      onClick={() => setIsTrustField(!isTrustField)}
                    />
                    {isTrustField && (
                      <MyTextInput
                        name="entityName"
                        label="Company, trust, or entity name"
                      />
                    )}
                  </>
                )}
                {partyHasCompany(selectedRole) && (
                  <>
                    <br />
                    <MyTextInput name="companyName" label="Company Name" />
                  </>
                )}
                <FormAddress />
                {partyIsAgent(selectedRole) && (
                  <>
                    <br />
                    <Divider />
                    <Grid stackable>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <MyTextInput
                            name="brokerLicenseNumber"
                            label="Agent License Number"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className="zero top padding">
                        <Grid.Column width={5}>
                          <MyTextInput
                            name="brokerageName"
                            label="Brokerage Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <MyTextInput
                            name="brokerageId"
                            label="Brokerage ID"
                          />
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <MyTextInput
                            name="brokerageLicenseNumber"
                            label="Brokerage License Number"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </>
                )}

                <Divider className="medium top margin" />
                <Button
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                  type="submit"
                  floated={isMobile ? null : "right"}
                  primary
                  content="Submit"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "TransactionPartyForm",
                      })
                    )
                  }
                  to="#"
                  type="button"
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                {party && !isMobile && (
                  <Icon
                    name="trash"
                    link
                    onClick={() => setConfirmOpen(true)}
                  />
                )}
                {party && isMobile && (
                  <Button
                    type="button"
                    basic
                    color="red"
                    content="Delete"
                    fluid
                    onClick={() => setConfirmOpen(true)}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleDelete()}
      ></Confirm>
    </ModalWrapper>
  );
}
