export function accessoriesSupplement() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 47", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 108.432,
   left: 36.0,
   width: 480.24,
   height: 16.200
}
,
{
   page: 0,
   name: "Text Field 48", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 126.72,
   left: 36.0,
   width: 501.84,
   height: 16.20
}
,
{
   page: 0,
   name: "Text Field 49", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 144.14,
   left: 282.24,
   width: 193.40,
   height: 16.20
}
,
{
   page: 0,
   name: "Text Field 50", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 160.84,
   left: 201.6,
   width: 370.08,
   height: 16.20
}
,
{
   page: 0,
   name: "Text Field 51", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 176.04,
   left: 36.0,
   width: 535.68,
   height: 16.20
}
,
{
   page: 0,
   name: "Text Field 52", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.744,
   left: 36.0,
   width: 535.68,
   height: 16.200
}
,
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 244.8,
   left: 30.816,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 24", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 244.8,
   left: 306.023,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 261.0,
   left: 30.816,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 25", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 261.0,
   left: 306.023,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 277.2,
   left: 30.8385,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 26", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 277.2,
   left: 306.045,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 293.4,
   left: 30.8385,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 27", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 293.4,
   left: 306.045,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 309.6,
   left: 30.8385,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 28", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 309.6,
   left: 306.045,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 325.8,
   left: 30.8385,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 29", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 325.8,
   left: 306.045,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 342.0,
   left: 30.861,
   width: 275.162,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 30", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 342.0,
   left: 306.068,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 358.2,
   left: 30.861,
   width: 275.162,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 31", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 358.2,
   left: 306.068,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 9", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 373.4,
   left: 30.7935,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 32", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 373.4,
   left: 306.0,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 389.6,
   left: 30.7935,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 33", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 389.6,
   left: 306.0,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 11", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 405.8,
   left: 30.816,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 34", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 405.8,
   left: 306.023,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 422.0,
   left: 30.816,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 35", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 422.0,
   left: 306.023,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 438.2,
   left: 30.816,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 36", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 438.2,
   left: 306.023,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 454.4,
   left: 30.816,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 37", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 454.4,
   left: 306.023,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 15", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 470.6,
   left: 30.8385,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 38", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 470.6,
   left: 306.045,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 16", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 486.8,
   left: 30.8385,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 39", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 486.8,
   left: 306.045,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 502.0,
   left: 30.8385,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 40", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 502.0,
   left: 306.045,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 18", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 518.2,
   left: 30.8385,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 41", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 518.2,
   left: 306.045,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 19", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.4,
   left: 30.861,
   width: 275.162,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 42", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.4,
   left: 306.068,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 550.6,
   left: 30.861,
   width: 275.162,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 43", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 550.6,
   left: 306.068,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.8,
   left: 30.861,
   width: 275.162,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 44", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 566.8,
   left: 306.068,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 22", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 583.0,
   left: 30.861,
   width: 275.162,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 45", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 583.0,
   left: 306.068,
   width: 275.161,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 23", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 599.2,
   left: 30.8835,
   width: 275.1615,
   height: 16.2
}
,
{
   page: 0,
   name: "Text Field 46", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 599.2,
   left: 306.09,
   width: 275.161,
   height: 16.2
}
] }
