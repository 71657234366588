import { Field, useField } from "formik";
import React from "react";
import { FormField } from "semantic-ui-react";

export default function MyRadioButtons({ label, ...props }) {
  const [meta] = useField(props);

  return props.options.map((o) => {
    return (
      <FormField
        key={o.value}
        error={meta.touched && !!meta.error}
        style={{ display: "inline-block" }}
      >
        <div className="ui radio checkbox">
          <Field type="radio" name={props.name} id={o.value} value={o.value} />
          <label htmlFor={o.value} className="small right margin">
            {!props.noLabels && o.value}
          </label>
        </div>
      </FormField>
    );
  });
}
