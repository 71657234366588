import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";
import asyncSlice from "../async/asyncSlice";
import authSlice from "../../features/auth/authSlice";
import modalSlice from "../common/modals/modalSlice";
import { verifyAuth } from "../../features/auth/authActions";
import profileSlice from "../../features/profiles/profileSlice";
import transactionSlice from "../../features/transactions/transactionSlice";
import taskSlice from "../../features/tasks/taskSlice";
import docSlice from "../../features/docs/docSlice";
import annotSlice from "../annots/annotSlice";
import navSlice from "../../features/nav/navSlice";
import peopleSlice from "../../features/people/peopleSlice";
import partySlice from "../../features/parties/partySlice";
import formTemplatesSlice from "../../features/formTemplates/formTemplatesSlice";
import deadlineTemplatesSlice from "../../features/deadlineTemplates/deadlineTemplatesSlice";
import clausesSlice from "../../features/clauses/clausesSlice";
import historySlice from "../../features/history/historySlice";
import taskTemplatesSlice from "../../features/tasks/taskTemplates/taskTemplatesSlice";
import emailTemplatesSlice from "../../features/emailTemplates/emailTemplatesSlice";
import emailSlice from "../../features/emails/emailSlice";

export const history = createBrowserHistory();

const store = configureStore({
  reducer: {
    router: connectRouter(history),
    modals: modalSlice,
    auth: authSlice,
    async: asyncSlice,
    transaction: transactionSlice,
    task: taskSlice,
    doc: docSlice,
    profile: profileSlice,
    people: peopleSlice,
    annot: annotSlice,
    nav: navSlice,
    party: partySlice,
    formTemplates: formTemplatesSlice,
    deadlineTemplates: deadlineTemplatesSlice,
    clauses: clausesSlice,
    history: historySlice,
    taskTemplates: taskTemplatesSlice,
    emailTemplates: emailTemplatesSlice,
    email: emailSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
store.dispatch(verifyAuth());
export default store;
