import React from "react";
import { Button, Divider, Header, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import useFirestoreCollectionNoAsync from "../../../app/hooks/useFirestoreCollectionNoAsync";
import { fetchEmailTemplatesFromDb } from "../../../app/firestore/firestoreService";
import { fetchEmailTemplates } from "../emailTemplatesSlice";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import EmailTemplatesList from "./EmailTemplatesList";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";

export default function EmailTemplatesSelect() {
  const dispatch = useDispatch();
  const { emailTemplates } = useSelector((state) => state.emailTemplates);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  useFirestoreCollectionNoAsync({
    query: () => fetchEmailTemplatesFromDb(),
    data: (emailTemplates) => dispatch(fetchEmailTemplates(emailTemplates)),
    deps: [dispatch],
  });

  if (!emailTemplates) {
    return <LoadingComponent />;
  }

  return (
    <ModalWrapper style={{ backgroundColor: "#f9fafb" }}>
      <Segment clearing style={{ backgroundColor: "#f9fafb" }}>
        <Header color="blue" size="large">
          Select Email Template to Add
          {!isMobile && (
            <Button
              primary
              floated="right"
              content="Close"
              onClick={() =>
                dispatch(
                  closeModal({
                    modalType: "EmailTemplatesSelect",
                  })
                )
              }
            ></Button>
          )}
        </Header>
        <Divider />{" "}
        {emailTemplates?.length > 0 ? (
          <EmailTemplatesList
            emailTemplates={emailTemplates}
            actionButtonMode="apply"
            // column={emailTemplates.column}
            // direction={emailTemplates.direction}
          />
        ) : (
          <p>There are no email templates.</p>
        )}
      </Segment>
    </ModalWrapper>
  );
}
