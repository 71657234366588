export function sellersPropertyDisclosureResidential2023() {
    return [   //2023
 {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 200,
      height: 30,
    }
    ,
   {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 319.56,
       left: 100.08,
       width: 455.28,
       height: 17.04
    }
    ,
    {
       page: 0,
       name: "Property", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 339.84,
       left: 115.32,
       width: 440.04,
       height: 17.04
    }
    ,
    {
       page: 0,
       name: "Seller", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 360.36,
       left: 104.04,
       width: 451.32,
       height: 17.04
    }
    ,
    {
       page: 0,
       name: "Year Built", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 380.52,
       left: 122.76,
       width: 432.60,
       height: 17.04
    }
    ,
    {
       page: 0,
       name: "Year Seller Acquired Property", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 400.8,
       left: 205.08,
       width: 350.28,
       height: 17.04
    }
    ,
    {
       page: 0,
       name: "i", 
       isText: false,
       type: "checkbox",
       top: 553.32,
       left: 249.72,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 0,
       name: "Commentsi", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 548.28,
       left: 272.88,
       width: 305.28,
       height: 26.08
    }
    ,
    {
       page: 0,
       name: "i_2", 
       isText: false,
       type: "checkbox",
       top: 576.84,
       left: 249.72,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 0,
       name: "Commentsi_2", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 571.92,
       left: 273,
       width: 305.04,
       height: 26.32
    }
    ,
    {
       page: 0,
       name: "i_3", 
       isText: false,
       type: "checkbox",
       top: 600.6,
       left: 249.72,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 0,
       name: "Commentsi_3", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 595.68,
       left: 273,
       width: 305.04,
       height: 26.32
    }
    ,
    {
       page: 0,
       name: "i_4", 
       isText: false,
       type: "checkbox",
       top: 624.36,
       left: 249.72,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 0,
       name: "Commentsi_4", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 619.32,
       left: 272.88,
       width: 305.28,
       height: 26.32
    }
    ,
    {
       page: 0,
       name: "i_5", 
       isText: false,
       type: "checkbox",
       top: 648.12,
       left: 249.72,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 0,
       name: "Commentsi_5", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 643.08,
       left: 272.88,
       width: 305.28,
       height: 26.32
    }
    ,
    {
       page: 0,
       name: "i_6", 
       isText: false,
       type: "checkbox",
       top: 667.56,
       left: 249.72,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 0,
       name: "Commentsi_6", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 666.6,
       left: 272.64,
       width: 305.76,
       height: 21
    }
    ,
    {
       page: 0,
       name: "i_7", 
       isText: false,
       type: "checkbox",
       top: 682.92,
       left: 249.72,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 0,
       name: "Commentsi_7", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 681.84,
       left: 272.64,
       width: 305.76,
       height: 21
    }
    ,
    {
       page: 0,
       name: "i_8", 
       isText: false,
       type: "checkbox",
       top: 699.6,
       left: 249.72,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 0,
       name: "Commentsi_8", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 696.96,
       left: 272.76,
       width: 305.52,
       height: 21
    }
    ,
    {
       page: 0,
       name: "Subfloors9", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 715.44,
       left: 61.08,
       width: 183.00,
       height: 17.28
    }
    ,
    {
       page: 0,
       name: "i_9", 
       isText: false,
       type: "checkbox",
       top: 718.08,
       left: 249.72,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 0,
       name: "Commentsi_9", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 715.44,
       left: 272.76,
       width: 305.52,
       height: 22
    }
    ,
    {
       page: 0,
       name: "Subfloors10", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 733.92,
       left: 61.08,
       width: 183.00,
       height: 17.28
    }
    ,
    {
       page: 0,
       name: "i_10", 
       isText: false,
       type: "checkbox",
       top: 736.56,
       left: 249.72,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 0,
       name: "Commentsi_10", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 733.92,
       left: 272.76,
       width: 305.52,
       height: 22
    }
    ,
    {
       page: 1,
       name: "i_11", 
       isText: false,
       type: "checkbox",
       top: 75.12,
       left: 277.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Commentsi_11", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 69.96,
       left: 300.72,
       width: 278.04,
       height: 28
    }
    ,
    {
       page: 1,
       name: "i_12", 
       isText: false,
       type: "checkbox",
       top: 98.88,
       left: 277.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Commentsi_12", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 93.96,
       left: 300.72,
       width: 278.04,
       height: 27
    }
    ,
    {
       page: 1,
       name: "i_13", 
       isText: false,
       type: "checkbox",
       top: 119.16,
       left: 277.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Commentsi_13", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 117.48,
       left: 300.48,
       width: 278.52,
       height: 22
    }
    ,
    {
       page: 1,
       name: "i_14", 
       isText: false,
       type: "checkbox",
       top: 135.96,
       left: 277.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Commentsi_14", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 134.04,
       left: 300.48,
       width: 278.52,
       height: 22
    }
    ,
    {
       page: 1,
       name: "i_15", 
       isText: false,
       type: "checkbox",
       top: 152.88,
       left: 277.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Commentsi_15", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 151.32,
       left: 300.48,
       width: 278.52,
       height: 22
    }
    ,
    {
       page: 1,
       name: "Other roof problems issues or concerns6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 167.458,
       left: 67.32,
       width: 204.60,
       height: 15.600
    }
    ,
    {
       page: 1,
       name: "i_16", 
       isText: false,
       type: "checkbox",
       top: 169.68,
       left: 277.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Commentsi_16", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 168,
       left: 300.48,
       width: 278.52,
       height: 22
    }
    ,
    {
       page: 1,
       name: "Other roof problems issues or concerns7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 184.8,
       left: 67.32,
       width: 204.60,
       height: 15.48
    }
    ,
    {
       page: 1,
       name: "i_17", 
       isText: false,
       type: "checkbox",
       top: 186.48,
       left: 277.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Commentsi_17", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 184.8,
       left: 300.48,
       width: 278.52,
       height: 22
    }
    ,
    {
       page: 1,
       name: "Roof under warranty until", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 224.6,
       left: 167.52,
       width: 100.92,
       height: 14
    }
    ,
    {
       page: 1,
       name: "i_18", 
       isText: false,
       type: "checkbox",
       top: 231.24,
       left: 277.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Commentsi_18", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 222.6,
       left: 300.84,
       width: 277.80,
       height: 33
    }
    ,
    {
       page: 1,
       name: "i_19", 
       isText: false,
       type: "checkbox",
       top: 253.68,
       left: 277.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Commentsi_19", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 252.72,
       left: 300.36,
       width: 278.76,
       height: 22
    }
    ,
    {
       page: 1,
       name: "Roof material", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 269.6,
       left: 127.08,
       width: 78.24,
       height: 14
    }
    ,
    {
       page: 1,
       name: "Age", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 269.6,
       left: 225.12,
       width: 45.12,
       height: 14
    }
    ,
    {
       page: 1,
       name: "i_20", 
       isText: false,
       type: "checkbox",
       top: 269.76,
       left: 277.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Commentsi_20", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 268.08,
       left: 300.48,
       width: 278.52,
       height: 22
    }
    ,
    {
       page: 1,
       name: "Roof material Age11", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 286.4,
       left: 67.2,
       width: 204.84,
       height: 14.64
    }
    ,
    {
       page: 1,
       name: "i_21", 
       isText: false,
       type: "checkbox",
       top: 285.72,
       left: 277.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Commentsi_21", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 284.4,
       left: 300.36,
       width: 278.76,
       height: 22
    }
    ,
    {
       page: 1,
       name: "i_22", 
       isText: false,
       type: "checkbox",
       top: 347.76,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 346.44,
       left: 301.32,
       width: 39.00,
       height: 14.40
    }
    ,
    {
       page: 1,
       name: "Commentsi_22", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 346.44,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_23", 
       isText: false,
       type: "checkbox",
       top: 363,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 361.92,
       left: 301.32,
       width: 39.00,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "Commentsi_23", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 361.92,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_24", 
       isText: false,
       type: "checkbox",
       top: 378.24,
       left: 278.04,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 377.16,
       left: 301.32,
       width: 39.00,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "Commentsi_24", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 377.16,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_25", 
       isText: false,
       type: "checkbox",
       top: 393.48,
       left: 278.04,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 392.52,
       left: 301.32,
       width: 39.00,
       height: 13.80
    }
    ,
    {
       page: 1,
       name: "Commentsi_25", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 392.52,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_26", 
       isText: false,
       type: "checkbox",
       top: 408.72,
       left: 278.04,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 407.52,
       left: 301.32,
       width: 39.00,
       height: 14.40
    }
    ,
    {
       page: 1,
       name: "Commentsi_26", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 407.52,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_27", 
       isText: false,
       type: "checkbox",
       top: 423.96,
       left: 278.04,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 423,
       left: 301.32,
       width: 39.00,
       height: 13.92
    }
    ,
    {
       page: 1,
       name: "Commentsi_27", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 423,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_28", 
       isText: false,
       type: "checkbox",
       top: 439.08,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 438,
       left: 301.32,
       width: 39.00,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "Commentsi_28", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 438,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_29", 
       isText: false,
       type: "checkbox",
       top: 454.32,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_8", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 453.24,
       left: 301.32,
       width: 39.00,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "Commentsi_29", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 453.24,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_30", 
       isText: false,
       type: "checkbox",
       top: 469.8,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_9", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 468.48,
       left: 301.32,
       width: 39.00,
       height: 14.40
    }
    ,
    {
       page: 1,
       name: "Commentsi_30", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 468.48,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_31", 
       isText: false,
       type: "checkbox",
       top: 485.04,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_10", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 483.96,
       left: 301.32,
       width: 39.00,
       height: 14.40
    }
    ,
    {
       page: 1,
       name: "Commentsi_31", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 483.96,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_32", 
       isText: false,
       type: "checkbox",
       top: 500.52,
       left: 278.04,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_11", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 499.44,
       left: 301.32,
       width: 39.00,
       height: 13.92
    }
    ,
    {
       page: 1,
       name: "Commentsi_32", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 499.44,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_33", 
       isText: false,
       type: "checkbox",
       top: 515.52,
       left: 278.04,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_12", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 514.56,
       left: 301.32,
       width: 39.00,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "Commentsi_33", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 514.56,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "Owned", 
       isText: false,
       type: "checkbox",
       top: 532.56,
       left: 123.72,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 1,
       name: "Leased", 
       isText: false,
       type: "checkbox",
       top: 532.56,
       left: 177.96,
       width: 8.52,
       height: 8.52
    }
    ,
    {
       page: 1,
       name: "i_34", 
       isText: false,
       type: "checkbox",
       top: 530.76,
       left: 278.04,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_13", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 529.8,
       left: 301.32,
       width: 39.00,
       height: 14.4
    }
    ,
    {
       page: 1,
       name: "Commentsi_34", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 529.8,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "Owned_2", 
       isText: false,
       type: "checkbox",
       top: 547.8,
       left: 178.2,
       width: 8.52,
       height: 8.52
    }
    ,
    {
       page: 1,
       name: "Leased_2", 
       isText: false,
       type: "checkbox",
       top: 547.8,
       left: 224.76,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 1,
       name: "i_35", 
       isText: false,
       type: "checkbox",
       top: 546.36,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_14", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 545.28,
       left: 301.32,
       width: 39.00,
       height: 13.92
    }
    ,
    {
       page: 1,
       name: "Commentsi_35", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 545.28,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_36", 
       isText: false,
       type: "checkbox",
       top: 561.36,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_15", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 560.28,
       left: 301.32,
       width: 39.00,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "Commentsi_36", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 560.28,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "Trash compactor16", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 575.52,
       left: 67.2,
       width: 204.84,
       height: 14.40
    }
    ,
    {
       page: 1,
       name: "i_37", 
       isText: false,
       type: "checkbox",
       top: 576.6,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_16", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 575.52,
       left: 301.32,
       width: 39.00,
       height: 14.40
    }
    ,
    {
       page: 1,
       name: "Commentsi_37", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 575.52,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "Trash compactor17", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 591,
       left: 67.2,
       width: 204.84,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "i_38", 
       isText: false,
       type: "checkbox",
       top: 592.08,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_17", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 591,
       left: 301.32,
       width: 39.00,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "Commentsi_38", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 591,
       left: 341.64,
       width: 237.48,
       height: 19
    }
    ,
    {
       page: 1,
       name: "Owned_3", 
       isText: false,
       type: "checkbox",
       top: 655.32,
       left: 138.72,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 1,
       name: "Leased_3", 
       isText: false,
       type: "checkbox",
       top: 655.32,
       left: 185.76,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 1,
       name: "i_39", 
       isText: false,
       type: "checkbox",
       top: 653.88,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_18", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 652.56,
       left: 301.32,
       width: 39.24,
       height: 14.40
    }
    ,
    {
       page: 1,
       name: "Commentsi_39", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 652.56,
       left: 341.88,
       width: 239.28,
       height: 19
    }
    ,
    {
       page: 1,
       name: "Battery", 
       isText: false,
       type: "checkbox",
       top: 670.92,
       left: 155.76,
       width: 8.40,
       height: 8.40
    }
    ,
    {
       page: 1,
       name: "Hardwire", 
       isText: false,
       type: "checkbox",
       top: 670.92,
       left: 202.92,
       width: 8.52,
       height: 8.40
    }
    ,
    {
       page: 1,
       name: "i_40", 
       isText: false,
       type: "checkbox",
       top: 669.12,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_19", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 668.04,
       left: 301.32,
       width: 39.24,
       height: 13.92
    }
    ,
    {
       page: 1,
       name: "Commentsi_40", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 668.04,
       left: 341.88,
       width: 239.28,
       height: 19
    }
    ,
    {
       page: 1,
       name: "Battery_2", 
       isText: false,
       type: "checkbox",
       top: 684.84,
       left: 170.52,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 1,
       name: "Hardwire_2", 
       isText: false,
       type: "checkbox",
       top: 684.84,
       left: 217.68,
       width: 8.52,
       height: 8.52
    }
    ,
    {
       page: 1,
       name: "i_41", 
       isText: false,
       type: "checkbox",
       top: 684.12,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_20", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 683.04,
       left: 301.32,
       width: 39.24,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "Commentsi_41", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 683.04,
       left: 341.88,
       width: 239.28,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_42", 
       isText: false,
       type: "checkbox",
       top: 699.36,
       left: 278.04,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_21", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 698.28,
       left: 301.32,
       width: 39.24,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "Commentsi_42", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 698.28,
       left: 341.88,
       width: 239.28,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_43", 
       isText: false,
       type: "checkbox",
       top: 714.6,
       left: 278.04,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_22", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 713.52,
       left: 301.32,
       width: 39.24,
       height: 14.16
    }
    ,
    {
       page: 1,
       name: "Commentsi_43", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 713.52,
       left: 341.88,
       width: 239.28,
       height: 19
    }
    ,
    {
       page: 1,
       name: "i_44", 
       isText: false,
       type: "checkbox",
       top: 729.84,
       left: 278.04,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 1,
       name: "Age If Knowni_23", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 728.88,
       left: 301.32,
       width: 39.24,
       height: 14.04
    }
    ,
    {
       page: 1,
       name: "Commentsi_44", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 728.88,
       left: 341.88,
       width: 239.28,
       height: 19
    }
    ,
    {
       page: 1,
       name: "Check Box4", 
       isText: false,
       type: "checkbox",
       top: 240.14,
       left: 166.96,
       width: 10.953,
       height: 9.327
    }
    ,
    {
       page: 1,
       name: "Check Box5", 
       isText: false,
       type: "checkbox",
       top: 240.14,
       left: 126.846,
       width: 10.953,
       height: 9.327
    }
    ,
    {
       page: 2,
       name: "i_45", 
       isText: false,
       type: "checkbox",
       top: 45.48,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "i_46", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 44.4,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "i_47", 
       isText: false,
       type: "checkbox",
       top: 60.96,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "i_48", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 59.64,
       left: 304.8,
       width: 43.68,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "i_49", 
       isText: false,
       type: "checkbox",
       top: 81.96,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "of remoteopeners", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 85.8,
       left: 151.32,
       width: 42.60,
       height: 11.28
    }
    ,
    {
       page: 2,
       name: "i_50", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 75.48,
       left: 305.16,
       width: 42.96,
       height: 25.08
    }
    ,
    {
       page: 2,
       name: "i_51", 
       isText: false,
       type: "checkbox",
       top: 102.72,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "i_52", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 101.64,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "i_53", 
       isText: false,
       type: "checkbox",
       top: 118.2,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "i_54", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 116.88,
       left: 304.8,
       width: 43.68,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "Inwall speakers12", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 132.36,
       left: 69.24,
       width: 207.24,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "i_55", 
       isText: false,
       type: "checkbox",
       top: 133.44,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "i_56", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 132.36,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "Inwall speakers13", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 147.6,
       left: 69.24,
       width: 207.24,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "i_57", 
       isText: false,
       type: "checkbox",
       top: 148.68,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "i_58", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 147.6,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "i_59a", 
       isText: false,
       type: "checkbox",
       top: 202.92,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_24", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 201.96,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "Commentsi_45z", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 201.96,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_60", 
       isText: false,
       type: "checkbox",
       top: 218.16,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_25", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 217.2,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "Commentsi_46z", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 217.2,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Owned_4", 
       isText: false,
       type: "checkbox",
       top: 235.2,
       left: 127.92,
       width: 8.52,
       height: 8.52
    }
    ,
    {
       page: 2,
       name: "Leased_4", 
       isText: false,
       type: "checkbox",
       top: 235.2,
       left: 177.24,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 2,
       name: "i_61", 
       isText: false,
       type: "checkbox",
       top: 233.52,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_26", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 232.44,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "Commentsi_47z", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 232.44,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Owned_5", 
       isText: false,
       type: "checkbox",
       top: 249.96,
       left: 142.92,
       width: 8.52,
       height: 8.52
    }
    ,
    {
       page: 2,
       name: "Leased_5", 
       isText: false,
       type: "checkbox",
       top: 249.96,
       left: 189.96,
       width: 8.52,
       height: 8.52
    }
    ,
    {
       page: 2,
       name: "i_62", 
       isText: false,
       type: "checkbox",
       top: 248.76,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_27", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 247.68,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "Commentsi_48z", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 247.68,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_63", 
       isText: false,
       type: "checkbox",
       top: 264,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_28", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 262.92,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "Commentsi_49z", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 262.92,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Electric Wiring or Panel18", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 278.16,
       left: 69.24,
       width: 207.24,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "i_64", 
       isText: false,
       type: "checkbox",
       top: 279.48,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_29", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 278.16,
       left: 304.8,
       width: 43.68,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "Commentsi_50z", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 278.16,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Electric Wiring or Panel19", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 293.64,
       left: 69.24,
       width: 207.24,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "i_65", 
       isText: false,
       type: "checkbox",
       top: 294.72,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_30", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 293.64,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "Commentsi_51z", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 293.64,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_66", 
       isText: false,
       type: "checkbox",
       top: 349.44,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_31", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 348.48,
       left: 304.8,
       width: 43.68,
       height: 13.92
    }
    ,
    {
       page: 2,
       name: "Commentsi_52", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 348.48,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Electrical Service Amps", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 364.8,
       left: 166.08,
       width: 104.16,
       height: 11.52
    }
    ,
    {
       page: 2,
       name: "i_67", 
       isText: false,
       type: "checkbox",
       top: 365.28,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_32", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 363.6,
       left: 304.92,
       width: 43.44,
       height: 15.24
    }
    ,
    {
       page: 2,
       name: "Commentsi_53", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 363.6,
       left: 350.04,
       width: 231.00,
       height: 19
    }
    ,
    {
       page: 2,
       name: "internetProvider", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 430.537,
       left: 167.86,
       width: 107.416,
       height: 12.952
    }
    ,
    {
       page: 2,
       name: "i_68", 
       isText: false,
       type: "checkbox",
       top: 381.48,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_33", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 380.04,
       left: 304.92,
       width: 43.44,
       height: 15.12
    }
    ,
    {
       page: 2,
       name: "Commentsi_54", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 380.04,
       left: 350.04,
       width: 231.00,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Electric Provider", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 397.56,
       left: 142.8,
       width: 128.16,
       height: 11.52
    }
    ,
    {
       page: 2,
       name: "i_69", 
       isText: false,
       type: "checkbox",
       top: 398.04,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_34", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 396.36,
       left: 304.92,
       width: 43.44,
       height: 15.24
    }
    ,
    {
       page: 2,
       name: "Commentsi_55", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 396.36,
       left: 350.04,
       width: 231.00,
       height: 19
    }
    ,
    {
       page: 2,
       name: "CableTV provider", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 414.12,
       left: 147.24,
       width: 123.72,
       height: 11.52
    }
    ,
    {
       page: 2,
       name: "i_70", 
       isText: false,
       type: "checkbox",
       top: 414.48,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_35", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 412.8,
       left: 304.92,
       width: 43.44,
       height: 15.36
    }
    ,
    {
       page: 2,
       name: "Commentsi_56", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 412.8,
       left: 350.04,
       width: 231.00,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_71", 
       isText: false,
       type: "checkbox",
       top: 430.8,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_36", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 429.36,
       left: 304.92,
       width: 43.44,
       height: 15.00
    }
    ,
    {
       page: 2,
       name: "Commentsi_57", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 429.36,
       left: 350.04,
       width: 231.00,
       height: 19
    }
    ,
    {
       page: 2,
       name: "26", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 445.44,
       left: 69.24,
       width: 207.24,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "i_72", 
       isText: false,
       type: "checkbox",
       top: 446.76,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_37", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 445.44,
       left: 304.8,
       width: 43.68,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "Commentsi_58", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 445.44,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_73", 
       isText: false,
       type: "checkbox",
       top: 511.56,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_38", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 510.24,
       left: 304.8,
       width: 43.68,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "Commentsi_59", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 510.24,
       left: 349.92,
       width: 234.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_74", 
       isText: false,
       type: "checkbox",
       top: 526.8,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_39", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 525.72,
       left: 304.8,
       width: 43.68,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "Commentsi_60", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 525.72,
       left: 349.92,
       width: 234.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_75", 
       isText: false,
       type: "checkbox",
       top: 542.28,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_40", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 541.32,
       left: 304.8,
       width: 43.68,
       height: 14.04
    }
    ,
    {
       page: 2,
       name: "Commentsi_61", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 541.32,
       left: 349.92,
       width: 234.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_76", 
       isText: false,
       type: "checkbox",
       top: 557.52,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_41", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 556.56,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "Commentsi_62", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 556.56,
       left: 349.92,
       width: 234.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "no_sump_pumps", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 571.68,
       left: 147.24,
       width: 46.92,
       height: 11.52
    }
    ,
    {
       page: 2,
       name: "i_77", 
       isText: false,
       type: "checkbox",
       top: 573,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_42", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 571.8,
       left: 304.8,
       width: 43.68,
       height: 14.4
    }
    ,
    {
       page: 2,
       name: "Commentsi_63", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 571.8,
       left: 349.92,
       width: 234.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_78", 
       isText: false,
       type: "checkbox",
       top: 588.36,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_43", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 587.28,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "Commentsi_64", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 587.28,
       left: 349.92,
       width: 234.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Recycle pump6b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 602.52,
       left: 69.24,
       width: 207.24,
       height: 13.92
    }
    ,
    {
       page: 2,
       name: "i_79", 
       isText: false,
       type: "checkbox",
       top: 603.6,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_44", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 602.52,
       left: 304.8,
       width: 43.68,
       height: 13.92
    }
    ,
    {
       page: 2,
       name: "Commentsi_65", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 602.52,
       left: 349.92,
       width: 234.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Recycle pump7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 617.52,
       left: 69.24,
       width: 207.24,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "i_80", 
       isText: false,
       type: "checkbox",
       top: 618.84,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_45", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 617.52,
       left: 304.8,
       width: 43.68,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "Commentsi_66", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 617.52,
       left: 349.92,
       width: 234.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_81", 
       isText: false,
       type: "checkbox",
       top: 683.4,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_46", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 682.08,
       left: 304.8,
       width: 43.68,
       height: 14.40
    }
    ,
    {
       page: 2,
       name: "Commentsi_67", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 682.08,
       left: 349.92,
       width: 237.96,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_82", 
       isText: false,
       type: "checkbox",
       top: 698.64,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_47", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 697.56,
       left: 304.8,
       width: 43.68,
       height: 13.92
    }
    ,
    {
       page: 2,
       name: "Commentsi_68", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 697.56,
       left: 349.92,
       width: 237.96,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_83", 
       isText: false,
       type: "checkbox",
       top: 713.64,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_48", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 712.56,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 2,
       name: "Commentsi_69", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 712.56,
       left: 349.92,
       width: 237.96,
       height: 19
    }
    ,
    {
       page: 2,
       name: "i_84", 
       isText: false,
       type: "checkbox",
       top: 729.12,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 2,
       name: "Age If Knowni_49", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 727.8,
       left: 304.8,
       width: 43.68,
       height: 14.4
    }
    ,
    {
       page: 2,
       name: "Commentsi_70", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 727.8,
       left: 349.92,
       width: 237.96,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Commentsi_45a", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 44.814,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Commentsi_46a", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 60.054,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Commentsi_47a", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 75.294,
       left: 349.92,
       width: 231.24,
       height: 28
    }
    ,
    {
       page: 2,
       name: "Commentsi_48a", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 101.534,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Commentsi_49a", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 116.774,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Commentsi_50a", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 132.014,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "Commentsi_51a", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 147.494,
       left: 349.92,
       width: 231.24,
       height: 19
    }
    ,
    {
       page: 2,
       name: "mechanicalExtra", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 555.952,
       left: 69.1808,
       width: 207.2402,
       height: 13.920
    }
    ,
    {
       page: 3,
       name: "i_85", 
       isText: false,
       type: "checkbox",
       top: 45.96,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "i_86", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 44.52,
       left: 304.92,
       width: 43.44,
       height: 15.00
    }
    ,
    {
       page: 3,
       name: "i_87", 
       isText: false,
       type: "checkbox",
       top: 62.16,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "i_88", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 60.72,
       left: 304.92,
       width: 43.44,
       height: 15.00
    }
    ,
    {
       page: 3,
       name: "i_89", 
       isText: false,
       type: "checkbox",
       top: 78.36,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "i_90", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 77.04,
       left: 304.92,
       width: 43.44,
       height: 15.00
    }
    ,
    {
       page: 3,
       name: "i_91", 
       isText: false,
       type: "checkbox",
       top: 94.92,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "i_92", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 93.24,
       left: 304.92,
       width: 43.44,
       height: 15.24
    }
    ,
    {
       page: 3,
       name: "i_93", 
       isText: false,
       type: "checkbox",
       top: 111.12,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "i_94", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 109.8,
       left: 304.92,
       width: 43.44,
       height: 15.0
    }
    ,
    {
       page: 3,
       name: "i_95", 
       isText: false,
       type: "checkbox",
       top: 127.44,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "i_96", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 126,
       left: 304.92,
       width: 43.44,
       height: 15
    }
    ,
    {
       page: 3,
       name: "i_97", 
       isText: false,
       type: "checkbox",
       top: 143.64,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "i_98", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 142.32,
       left: 304.92,
       width: 43.44,
       height: 15.00
    }
    ,
    {
       page: 3,
       name: "i_99", 
       isText: false,
       type: "checkbox",
       top: 159.96,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "i_100", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 158.52,
       left: 304.92,
       width: 43.44,
       height: 15.00
    }
    ,
    {
       page: 3,
       name: "Fuel tanks13b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 174.6,
       left: 69.24,
       width: 211.80,
       height: 14.16
    }
    ,
    {
       page: 3,
       name: "i_101b", 
       isText: false,
       type: "checkbox",
       top: 175.68,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "i_102", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 174.6,
       left: 304.8,
       width: 43.68,
       height: 14.16
    }
    ,
    {
       page: 3,
       name: "Fuel tanks14", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 189.84,
       left: 69.24,
       width: 211.80,
       height: 14.28
    }
    ,
    {
       page: 3,
       name: "i_103", 
       isText: false,
       type: "checkbox",
       top: 190.92,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "i_104", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 189.84,
       left: 304.8,
       width: 43.68,
       height: 14.28
    }
    ,
    {
       page: 3,
       name: "Type", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 248.92,
       left: 95.04,
       width: 85.56,
       height: 13.20
    }
    ,
    {
       page: 3,
       name: "Fuel", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 248.92,
       left: 202.32,
       width: 66.36,
       height: 13.20
    }
    ,
    {
       page: 3,
       name: "i_105", 
       isText: false,
       type: "checkbox",
       top: 249.72,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "Type_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 265.44,
       left: 95.04,
       width: 85.56,
       height: 13.20
    }
    ,
    {
       page: 3,
       name: "Fuel_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 265.44,
       left: 201.12,
       width: 67.56,
       height: 13.20
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_50", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 230.4,
       left: 305.52,
       width: 42.24,
       height: 51.24
    }
    ,
    {
       page: 3,
       name: "Commentsi_71", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 230.4,
       left: 350.64,
       width: 235.80,
       height: 40
    }
    ,
    {
       page: 3,
       name: "Fireplace Type", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 284.36,
       left: 135,
       width: 133.44,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "Fuel_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 302.84,
       left: 92.76,
       width: 175.44,
       height: 15
    }
    ,
    {
       page: 3,
       name: "i_106", 
       isText: false,
       type: "checkbox",
       top: 294.48,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_51", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 285.84,
       left: 305.28,
       width: 42.72,
       height: 30.48
    }
    ,
    {
       page: 3,
       name: "Commentsi_72", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 280.84,
       left: 350.4,
       width: 236.28,
       height: 36
    }
    ,
    {
       page: 3,
       name: "Heating Stove Type", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 320.84,
       left: 150.6,
       width: 115.32,
       height: 11.28
    }
    ,
    {
       page: 3,
       name: "Fuel_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 337.68,
       left: 92.76,
       width: 171.00,
       height: 14
    }
    ,
    {
       page: 3,
       name: "i_107", 
       isText: false,
       type: "checkbox",
       top: 328.44,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_52", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 319.8,
       left: 305.28,
       width: 42.72,
       height: 29.28
    }
    ,
    {
       page: 3,
       name: "Commentsi_73", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 319.8,
       left: 350.4,
       width: 236.28,
       height: 34
    }
    ,
    {
       page: 3,
       name: "Date_2", 
       fontSize: 8,
       type: "text",
       top: 369.4,
       left: 107.52,
       width: 92.40,
       height: 13.2
    }
    ,
    {
       page: 3,
       name: "i_108", 
       isText: false,
       type: "checkbox",
       top: 360.96,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_53", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 352.44,
       left: 305.28,
       width: 42.72,
       height: 29.88
    }
    ,
    {
       page: 3,
       name: "Commentsi_74", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 352.44,
       left: 350.4,
       width: 236.28,
       height: 33
    }
    ,
    {
       page: 3,
       name: "cb_fuelTanks", 
       isText: false,
       type: "checkbox",
       top: 386.76,
       left: 120.96,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 3,
       name: "cb_fuelLeased", 
       isText: false,
       type: "checkbox",
       top: 386.76,
       left: 168,
       width: 8.4,
       height: 8.52
    }
    ,
    {
       page: 3,
       name: "i_109", 
       isText: false,
       type: "checkbox",
       top: 387,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_54", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 384.6,
       left: 304.92,
       width: 43.44,
       height: 16.8
    }
    ,
    {
       page: 3,
       name: "Commentsi_75", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 384.6,
       left: 350.04,
       width: 237.00,
       height: 22
    }
    ,
    {
       page: 3,
       name: "radiant_Interior", 
       isText: false,
       type: "checkbox",
       top: 406.8,
       left: 167.76,
       width: 8.40,
       height: 8.4
    }
    ,
    {
       page: 3,
       name: "Radiant heating system", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 419.72,
       left: 140.76,
       width: 108.96,
       height: 14
    }
    ,
    {
       page: 3,
       name: "i_110", 
       isText: false,
       type: "checkbox",
       top: 413.76,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "radiant_Exterior Type", 
       isText: false,
       type: "checkbox",
       top: 422.28,
       left: 75.12,
       width: 8.52,
       height: 8.40
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_55", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 405.12,
       left: 305.28,
       width: 42.72,
       height: 30.24
    }
    ,
    {
       page: 3,
       name: "Commentsi_76", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 405.12,
       left: 350.4,
       width: 236.28,
       height: 33
    }
    ,
    {
       page: 3,
       name: "Fuel Provider", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 439.64,
       left: 130.8,
       width: 114.36,
       height: 14
    }
    ,
    {
       page: 3,
       name: "i_111", 
       isText: false,
       type: "checkbox",
       top: 441.48,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_56", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 438.24,
       left: 305.04,
       width: 43.20,
       height: 18.60
    }
    ,
    {
       page: 3,
       name: "Commentsi_77z", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 438.24,
       left: 350.16,
       width: 236.76,
       height: 22
    }
    ,
    {
       page: 3,
       name: "Fuel Provider22b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 458.16,
       left: 69.36,
       width: 211.56,
       height: 15.00
    }
    ,
    {
       page: 3,
       name: "i_112", 
       isText: false,
       type: "checkbox",
       top: 459.48,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_57", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 458.16,
       left: 304.92,
       width: 43.44,
       height: 15.00
    }
    ,
    {
       page: 3,
       name: "Commentsi_78", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 458.16,
       left: 350.04,
       width: 237.00,
       height: 22
    }
    ,
    {
       page: 3,
       name: "i_113", 
       isText: false,
       type: "checkbox",
       top: 524.04,
       left: 281.52,
       width: 12.00,
       height: 12.00
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_58", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 523.08,
       left: 304.92,
       width: 48.00,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Commentsi_79", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 523.08,
       left: 354.48,
       width: 226.56,
       height: 22
    }
    ,
    {
       page: 3,
       name: "i_114", 
       isText: false,
       type: "checkbox",
       top: 540.84,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_59", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 539.4,
       left: 304.92,
       width: 48.00,
       height: 15.0
    }
    ,
    {
       page: 3,
       name: "Commentsi_80", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 539.4,
       left: 354.48,
       width: 226.56,
       height: 19
    }
    ,
    {
       page: 3,
       name: "i_115", 
       isText: false,
       type: "checkbox",
       top: 557.28,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_60", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 555.6,
       left: 304.92,
       width: 48.00,
       height: 15.36
    }
    ,
    {
       page: 3,
       name: "Commentsi_81", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 555.6,
       left: 354.48,
       width: 226.56,
       height: 19
    }
    ,
    {
       page: 3,
       name: "i_116", 
       isText: false,
       type: "checkbox",
       top: 573.6,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_61", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 572.16,
       left: 304.92,
       width: 48.00,
       height: 15.00
    }
    ,
    {
       page: 3,
       name: "Commentsi_82", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 572.16,
       left: 354.48,
       width: 226.56,
       height: 19
    }
    ,
    {
       page: 3,
       name: "i_117", 
       isText: false,
       type: "checkbox",
       top: 589.8,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_62", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 588.36,
       left: 304.92,
       width: 48.00,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Commentsi_83", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 588.36,
       left: 354.48,
       width: 226.56,
       height: 19
    }
    ,
    {
       page: 3,
       name: "i_118", 
       isText: false,
       type: "checkbox",
       top: 606.12,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_63", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 604.68,
       left: 304.92,
       width: 48.00,
       height: 15.00
    }
    ,
    {
       page: 3,
       name: "Commentsi_84", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 604.68,
       left: 354.48,
       width: 226.56,
       height: 19
    }
    ,
    {
       page: 3,
       name: "i_119", 
       isText: false,
       type: "checkbox",
       top: 622.32,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_64", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 620.88,
       left: 304.92,
       width: 48.00,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Commentsi_85", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 620.88,
       left: 354.48,
       width: 226.56,
       height: 19
    }
    ,
    {
       page: 3,
       name: "i_120", 
       isText: false,
       type: "checkbox",
       top: 638.88,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_65", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 637.2,
       left: 304.92,
       width: 48.00,
       height: 15.24
    }
    ,
    {
       page: 3,
       name: "Commentsi_86", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 637.2,
       left: 354.48,
       width: 226.56,
       height: 19
    }
    ,
    {
       page: 3,
       name: "i_121", 
       isText: false,
       type: "checkbox",
       top: 655.08,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_66", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 653.64,
       left: 304.92,
       width: 48.00,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Commentsi_87", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 653.64,
       left: 354.48,
       width: 226.56,
       height: 19
    }
    ,
    {
       page: 3,
       name: "i_122", 
       isText: false,
       type: "checkbox",
       top: 671.4,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_67", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 669.96,
       left: 304.92,
       width: 48.00,
       height: 15.00
    }
    ,
    {
       page: 3,
       name: "Commentsi_88", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 669.96,
       left: 354.48,
       width: 226.56,
       height: 19
    }
    ,
    {
       page: 3,
       name: "i_123", 
       isText: false,
       type: "checkbox",
       top: 687.6,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_68", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 686.16,
       left: 304.92,
       width: 48.00,
       height: 15.12
    }
    ,
    {
       page: 3,
       name: "Commentsi_89", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 686,
       left: 354.48,
       width: 226.56,
       height: 22
    }
    ,
    {
       page: 3,
       name: "Irrigation pump12", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 702.36,
       left: 74.04,
       width: 202.44,
       height: 14.40
    }
    ,
    {
       page: 3,
       name: "i_124", 
       isText: false,
       type: "checkbox",
       top: 703.56,
       left: 282,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_69", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 702.36,
       left: 304.8,
       width: 48.24,
       height: 14.40
    }
    ,
    {
       page: 3,
       name: "Commentsi_90", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 702.36,
       left: 354.36,
       width: 226.80,
       height: 19
    }
    ,
    {
       page: 3,
       name: "Irrigation pump13", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 717.84,
       left: 74.04,
       width: 202.44,
       height: 14.16
    }
    ,
    {
       page: 3,
       name: "i_125", 
       isText: false,
       type: "checkbox",
       top: 718.92,
       left: 282,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 3,
       name: "Age If Knowni_70", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 717.84,
       left: 304.8,
       width: 48.24,
       height: 14.16
    }
    ,
    {
       page: 3,
       name: "Commentsi_91", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 717.84,
       left: 354.36,
       width: 226.80,
       height: 19
    }
    ,
    {
       page: 3,
       name: "Check Box3", 
       isText: false,
       type: "checkbox",
       top: 373.093,
       left: 202.91,
       width: 9.491,
       height: 9.490
    }
    ,
    {
       page: 3,
       name: "Commentsi_77j", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 190.337,
       left: 349.92,
       width: 236.76,
       height: 19
    }
    ,
    {
       page: 3,
       name: "Commentsi_77a", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 44.373,
       left: 349.92,
       width: 236.76,
       height: 19
    }
    ,
    {
       page: 3,
       name: "Commentsi_77b", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 58.337,
       left: 349.92,
       width: 236.76,
       height: 22
    }
    ,
    {
       page: 3,
       name: "Commentsi_77c", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 74.991,
       left: 349.92,
       width: 236.76,
       height: 22
    }
    ,
    {
       page: 3,
       name: "Commentsi_77d", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 91.646,
       left: 349.92,
       width: 236.76,
       height: 22
    }
    ,
    {
       page: 3,
       name: "Commentsi_77e", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 107.682,
       left: 349.92,
       width: 236.76,
       height: 22
    }
    ,
    {
       page: 3,
       name: "Commentsi_77f", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 124.337,
       left: 349.92,
       width: 236.76,
       height: 22
    }
    ,
    {
       page: 3,
       name: "Commentsi_77g", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 140.3,
       left: 349.92,
       width: 236.76,
       height: 22
    }
    ,
    {
       page: 3,
       name: "Commentsi_77h", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 156.955,
       left: 349.92,
       width: 236.76,
       height: 22
    }
    ,
    {
       page: 3,
       name: "Commentsi_77i", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 173.646,
       left: 349.92,
       width: 236.76,
       height: 19
    }
    ,
    {
       page: 4,
       name: "i_126", 
       isText: false,
       type: "checkbox",
       top: 91.92,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_71", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 90.24,
       left: 309.48,
       width: 47.88,
       height: 15.48
    }
    ,
    {
       page: 4,
       name: "Commentsi_92", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 90.24,
       left: 359.04,
       width: 222.00,
       height: 19
    }
    ,
    {
       page: 4,
       name: "i_127", 
       isText: false,
       type: "checkbox",
       top: 108.96,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_72", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 107.04,
       left: 309.48,
       width: 47.88,
       height: 15.72
    }
    ,
    {
       page: 4,
       name: "Commentsi_93", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 107.04,
       left: 359.04,
       width: 222.00,
       height: 19
    }
    ,
    {
       page: 4,
       name: "i_128", 
       isText: false,
       type: "checkbox",
       top: 125.64,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_73", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 123.96,
       left: 309.48,
       width: 47.88,
       height: 15.84
    }
    ,
    {
       page: 4,
       name: "Commentsi_94", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 123.96,
       left: 359.04,
       width: 222.00,
       height: 19
    }
    ,
    {
       page: 4,
       name: "i_129", 
       isText: false,
       type: "checkbox",
       top: 142.68,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_74", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 141,
       left: 309.48,
       width: 47.88,
       height: 15.6
    }
    ,
    {
       page: 4,
       name: "Commentsi_95", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 141,
       left: 359.04,
       width: 222.00,
       height: 19
    }
    ,
    {
       page: 4,
       name: "Irrigation system18", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 157.68,
       left: 66.24,
       width: 210.24,
       height: 14.16
    }
    ,
    {
       page: 4,
       name: "i_130", 
       isText: false,
       type: "checkbox",
       top: 158.64,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_75", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 157.68,
       left: 309.36,
       width: 48.12,
       height: 14.16
    }
    ,
    {
       page: 4,
       name: "Commentsi_96", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 157.68,
       left: 358.92,
       width: 222.24,
       height: 19
    }
    ,
    {
       page: 4,
       name: "Irrigation system19", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 172.92,
       left: 66.24,
       width: 210.24,
       height: 14.16
    }
    ,
    {
       page: 4,
       name: "i_131", 
       isText: false,
       type: "checkbox",
       top: 173.88,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_76", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 172.92,
       left: 309.36,
       width: 48.12,
       height: 14.16
    }
    ,
    {
       page: 4,
       name: "Commentsi_97", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 172.92,
       left: 358.92,
       width: 222.24,
       height: 19
    }
    ,
    {
       page: 4,
       name: "Water heater Number of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 7,
       top: 217.28,
       left: 163.08,
       width: 70.92,
       height: 13.32
    }
    ,
    {
       page: 4,
       name: "i_132", 
       isText: false,
       type: "checkbox",
       top: 225.24,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 4,
       name: "Fuel_type", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 233.24,
       left: 107.52,
       width: 76.56,
       height: 13.32
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_77", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 216.6,
       left: 309.84,
       width: 47.16,
       height: 29.52
    }
    ,
    {
       page: 4,
       name: "Commentsi_98", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 216.6,
       left: 359.4,
       width: 221.28,
       height: 29.52
    }
    ,
    {
       page: 4,
       name: "Owned_6", 
       isText: false,
       type: "checkbox",
       top: 250.2,
       left: 147.24,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 4,
       name: "Leased_6", 
       isText: false,
       type: "checkbox",
       top: 250.2,
       left: 194.16,
       width: 8.52,
       height: 8.52
    }
    ,
    {
       page: 4,
       name: "i_133", 
       isText: false,
       type: "checkbox",
       top: 249.48,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_78", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 247.92,
       left: 309.36,
       width: 48.12,
       height: 14.88
    }
    ,
    {
       page: 4,
       name: "Commentsi_99", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 247.92,
       left: 358.92,
       width: 222.24,
       height: 19
    }
    ,
    {
       page: 4,
       name: "Owned_7", 
       isText: false,
       type: "checkbox",
       top: 266.16,
       left: 133.2,
       width: 8.4,
       height: 8.52
    }
    ,
    {
       page: 4,
       name: "Leased_7", 
       isText: false,
       type: "checkbox",
       top: 266.16,
       left: 180.24,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 4,
       name: "i_134", 
       isText: false,
       type: "checkbox",
       top: 265.44,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_79", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 264,
       left: 309.48,
       width: 47.88,
       height: 14.88
    }
    ,
    {
       page: 4,
       name: "Commentsi_100", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 264,
       left: 359.04,
       width: 222.00,
       height: 19
    }
    ,
    {
       page: 4,
       name: "i_135", 
       isText: false,
       type: "checkbox",
       top: 287.76,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_80", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 280.44,
       left: 309.84,
       width: 47.16,
       height: 26.88
    }
    ,
    {
       page: 4,
       name: "Commentsi_101", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 280.44,
       left: 359.4,
       width: 221.28,
       height: 26.88
    }
    ,
    {
       page: 4,
       name: "i_136", 
       isText: false,
       type: "checkbox",
       top: 309.72,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_81", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 308.4,
       left: 309.36,
       width: 48.12,
       height: 14.64
    }
    ,
    {
       page: 4,
       name: "Commentsi_102", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 308.4,
       left: 358.92,
       width: 222.24,
       height: 19
    }
    ,
    {
       page: 4,
       name: "Date of last inspection", 
       fontSize: 6,
       type: "text",
       top: 336.32,
       left: 154.08,
       width: 69.84,
       height: 14
    }
    ,
    {
       page: 4,
       name: "i_137", 
       isText: false,
       type: "checkbox",
       top: 339.24,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 4,
       name: "Date of last service", 
       fontSize: 6,
       type: "text",
       top: 350.84,
       left: 142.32,
       width: 83.64,
       height: 14
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_82", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 324.96,
       left: 310.08,
       width: 46.68,
       height: 40.92
    }
    ,
    {
       page: 4,
       name: "Commentsi_103", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 324.96,
       left: 359.64,
       width: 220.80,
       height: 40.92
    }
    ,
    {
       page: 4,
       name: "i_138", 
       isText: false,
       type: "checkbox",
       top: 369.48,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_83", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 367.08,
       left: 309.48,
       width: 47.88,
       height: 16.80
    }
    ,
    {
       page: 4,
       name: "Commentsi_104", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 367.08,
       left: 359.04,
       width: 222.00,
       height: 19
    }
    ,
    {
       page: 4,
       name: "i_139", 
       isText: false,
       type: "checkbox",
       top: 387.48,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_84", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 385.08,
       left: 309.48,
       width: 47.88,
       height: 16.80
    }
    ,
    {
       page: 4,
       name: "Commentsi_105", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 385.08,
       left: 359.04,
       width: 222.00,
       height: 19
    }
    ,
    {
       page: 4,
       name: "Well Pump", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 402.6,
       left: 119.28,
       width: 58.56,
       height: 13
    }
    ,
    {
       page: 4,
       name: "GPM", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 402.6,
       left: 199.56,
       width: 74.40,
       height: 13
    }
    ,
    {
       page: 4,
       name: "Date_3", 
       fontSize: 6,
       type: "text",
       top: 416.84,
       left: 95.28,
       width: 80.88,
       height: 14
    }
    ,
    {
       page: 4,
       name: "i_140", 
       isText: false,
       type: "checkbox",
       top: 411.48,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_85", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 402.96,
       left: 309.84,
       width: 47.16,
       height: 28.92
    }
    ,
    {
       page: 4,
       name: "Commentsi_106", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 402.96,
       left: 359.4,
       width: 221.28,
       height: 33
    }
    ,
    {
       page: 4,
       name: "Cistern water storage", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 434.84,
       left: 149.88,
       width: 36.12,
       height: 14
    }
    ,
    {
       page: 4,
       name: "i_141", 
       isText: false,
       type: "checkbox",
       top: 435.84,
       left: 284.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_86", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 433.32,
       left: 309.48,
       width: 47.88,
       height: 16.80
    }
    ,
    {
       page: 4,
       name: "Commentsi_107", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 433.32,
       left: 359.04,
       width: 222.00,
       height: 22
    }
    ,
    {
       page: 4,
       name: "i_142", 
       isText: false,
       type: "checkbox",
       top: 452.52,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_87", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 451.2,
       left: 309.36,
       width: 48.12,
       height: 14.64
    }
    ,
    {
       page: 4,
       name: "Commentsi_108", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 451.2,
       left: 358.92,
       width: 222.24,
       height: 19
    }
    ,
    {
       page: 4,
       name: "Supplemental water purchased in past 2 years31", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 467.04,
       left: 66.24,
       width: 210.24,
       height: 14.16
    }
    ,
    {
       page: 4,
       name: "i_143", 
       isText: false,
       type: "checkbox",
       top: 468,
       left: 284.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 4,
       name: "Age If Knowni_88", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 467.04,
       left: 309.36,
       width: 48.12,
       height: 14.16
    }
    ,
    {
       page: 4,
       name: "Commentsi_109", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 467.04,
       left: 358.92,
       width: 222.24,
       height: 19
    }
    ,
    {
       page: 4,
       name: "ta_potableWater", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 648.175,
       left: 78.0975,
       width: 481.4405,
       height: 31.320
    }
    ,
    {
       page: 4,
       name: "Public", 
       isText: false,
       type: "checkbox",
       top: 526.32,
       left: 161.16,
       width: 8.52,
       height: 8.40
    }
    ,
    {
       page: 4,
       name: "Community", 
       isText: false,
       type: "checkbox",
       top: 526.32,
       left: 212.04,
       width: 8.40,
       height: 8.40
    }
    ,
    {
       page: 4,
       name: "Well", 
       isText: false,
       type: "checkbox",
       top: 526.32,
       left: 282.24,
       width: 8.52,
       height: 8.40
    }
    ,
    {
       page: 4,
       name: "Shared Well", 
       isText: false,
       type: "checkbox",
       top: 526.32,
       left: 327.24,
       width: 8.52,
       height: 8.40
    }
    ,
    {
       page: 4,
       name: "Other", 
       isText: false,
       type: "checkbox",
       top: 526.32,
       left: 399.36,
       width: 8.40,
       height: 8.40
    }
    ,
    {
       page: 4,
       name: "None", 
       isText: false,
       type: "checkbox",
       top: 526.32,
       left: 447.6,
       width: 8.52,
       height: 8.40
    }
    ,
    {
       page: 4,
       name: "Is", 
       isText: false,
       type: "checkbox",
       top: 543.6,
       left: 303.96,
       width: 8.52,
       height: 8.4
    }
    ,
    {
       page: 4,
       name: "Is Not attached Well Permit", 
       isText: false,
       type: "checkbox",
       top: 543.6,
       left: 338.76,
       width: 8.52,
       height: 8.4
    }
    ,
    {
       page: 4,
       name: "undefined_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 540.8,
       left: 474.72,
       width: 83.88,
       height: 14
    }
    ,
    {
       page: 4,
       name: "Are", 
       isText: false,
       type: "checkbox",
       top: 560.52,
       left: 146.16,
       width: 8.52,
       height: 8.52
    }
    ,
    {
       page: 4,
       name: "Are Not", 
       isText: false,
       type: "checkbox",
       top: 560.52,
       left: 186.24,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 4,
       name: "Yes", 
       isText: false,
       type: "checkbox",
       top: 560.52,
       left: 386.28,
       width: 8.52,
       height: 8.52
    }
    ,
    {
       page: 4,
       name: "No", 
       isText: false,
       type: "checkbox",
       top: 560.52,
       left: 431.04,
       width: 8.52,
       height: 8.52
    }
    ,
    {
       page: 4,
       name: "Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 7,
       top: 602.6,
       left: 99.6,
       width: 242.16,
       height: 14
    }
    ,
    {
       page: 4,
       name: "Address", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 602.6,
       left: 378.12,
       width: 181.92,
       height: 14
    }
    ,
    {
       page: 4,
       name: "Web Site", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 617.538,
       left: 111.24,
       width: 230.52,
       height: 14
    }
    ,
    {
       page: 4,
       name: "Phone No", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 618.08,
       left: 386.64,
       width: 173.40,
       height: 14
    }
    ,
    {
       page: 4,
       name: "There is neither a Well nor a Water Provider for the Property The source of potable water for the Property is describe source", 
       isText: true,
       type: "checkbox",
       top: 637.2,
       left: 78.96,
       width: 6.84,
       height: 6.84
    }
    ,
    {
       page: 4,
       name: "fuelCapacity", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 232.77,
       left: 216.496,
       width: 59.756,
       height: 13.862
    }
    ,
    {
       page: 4,
       name: "master_water_shutoff", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 292.753,
       left: 69.8643,
       width: 205.5747,
       height: 12.778
    }
    ,
    {
       page: 5,
       name: "i_144", 
       isText: false,
       type: "checkbox",
       top: 81.72,
       left: 293.52,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_110", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 77.88,
       left: 318.6,
       width: 255.6,
       height: 19.68
    }
    ,
    {
       page: 5,
       name: "i_145", 
       isText: false,
       type: "checkbox",
       top: 99.72,
       left: 293.52,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_111", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 98.64,
       left: 318.36,
       width: 256.08,
       height: 19
    }
    ,
    {
       page: 5,
       name: "Lift station sewage ejector pump3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 113.64,
       left: 66.24,
       width: 219.72,
       height: 14.16
    }
    ,
    {
       page: 5,
       name: "i_146", 
       isText: false,
       type: "checkbox",
       top: 114.72,
       left: 293.52,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_112", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 113.64,
       left: 318.36,
       width: 256.08,
       height: 19
    }
    ,
    {
       page: 5,
       name: "Lift station sewage ejector pump4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 128.88,
       left: 66.24,
       width: 219.72,
       height: 14.16
    }
    ,
    {
       page: 5,
       name: "i_147", 
       isText: false,
       type: "checkbox",
       top: 129.96,
       left: 293.52,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_113", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 128.88,
       left: 318.36,
       width: 256.08,
       height: 19
    }
    ,
    {
       page: 5,
       name: "Public_2", 
       isText: false,
       type: "checkbox",
       top: 185.88,
       left: 191.52,
       width: 8.40,
       height: 8.52
    }
    ,
    {
       page: 5,
       name: "Community_2", 
       isText: false,
       type: "checkbox",
       top: 198,
       left: 72.12,
       width: 8.52,
       height: 8.4
    }
    ,
    {
       page: 5,
       name: "Septic System", 
       isText: false,
       type: "checkbox",
       top: 198,
       left: 139.92,
       width: 8.52,
       height: 8.4
    }
    ,
    {
       page: 5,
       name: "None_2", 
       isText: false,
       type: "checkbox",
       top: 198,
       left: 217.2,
       width: 8.52,
       height: 8.4
    }
    ,
    {
       page: 5,
       name: "If the Property is served by an onsite septic system", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 207.36,
       left: 112.08,
       width: 164.16,
       height: 11.16
    }
    ,
    {
       page: 5,
       name: "Other_2", 
       isText: false,
       type: "checkbox",
       top: 210,
       left: 72.12,
       width: 8.52,
       height: 8.4
    }
    ,
    {
       page: 5,
       name: "i_148", 
       isText: false,
       type: "checkbox",
       top: 215.76,
       left: 291.24,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Tank", 
       isText: false,
       type: "checkbox",
       top: 248.76,
       left: 159,
       width: 8.4,
       height: 8.40
    }
    ,
    {
       page: 5,
       name: "Leach", 
       isText: false,
       type: "checkbox",
       top: 248.76,
       left: 198,
       width: 8.4,
       height: 8.40
    }
    ,
    {
       page: 5,
       name: "Lagoon", 
       isText: false,
       type: "checkbox",
       top: 248.76,
       left: 240.48,
       width: 8.52,
       height: 8.40
    }
    ,
    {
       page: 5,
       name: "Commentsi_114", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 183.84,
       left: 314.52,
       width: 254.64,
       height: 76.20
    }
    ,
    {
       page: 5,
       name: "Sewer service provider", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 272.36,
       left: 71.52,
       width: 208.68,
       height: 13.407
    }
    ,
    {
       page: 5,
       name: "i_149", 
       isText: false,
       type: "checkbox",
       top: 268.44,
       left: 291.24,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 5,
       name: "Commentsi_115", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 261.6,
       left: 314.28,
       width: 255.12,
       height: 26.04
    }
    ,
    {
       page: 5,
       name: "Sewer line scoped Date", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 290.4,
       left: 166.32,
       width: 70.68,
       height: 11.16
    }
    ,
    {
       page: 5,
       name: "i_150", 
       isText: false,
       type: "checkbox",
       top: 290.28,
       left: 291.24,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_116", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 288.84,
       left: 313.92,
       width: 255.84,
       height: 19
    }
    ,
    {
       page: 5,
       name: "If a septic system date latest Individual Use Permit", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 317.16,
       left: 98.76,
       width: 87.72,
       height: 11.16
    }
    ,
    {
       page: 5,
       name: "i_151", 
       isText: false,
       type: "checkbox",
       top: 312.24,
       left: 291.24,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_117", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 305.28,
       left: 314.16,
       width: 255.36,
       height: 26.04
    }
    ,
    {
       page: 5,
       name: "If a septic system date of latest inspection", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 345.24,
       left: 71.52,
       width: 81.12,
       height: 11.16
    }
    ,
    {
       page: 5,
       name: "i_152", 
       isText: false,
       type: "checkbox",
       top: 339.48,
       left: 291.24,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_118", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 332.88,
       left: 314.28,
       width: 255.12,
       height: 26.04
    }
    ,
    {
       page: 5,
       name: "If a septic system date of latest pumping", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 372.6,
       left: 71.52,
       width: 85.56,
       height: 11.16
    }
    ,
    {
       page: 5,
       name: "i_153", 
       isText: false,
       type: "checkbox",
       top: 366.72,
       left: 291.24,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 5,
       name: "Commentsi_119", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 360.36,
       left: 314.16,
       width: 255.36,
       height: 25.20
    }
    ,
    {
       page: 5,
       name: "i_154", 
       isText: false,
       type: "checkbox",
       top: 387.72,
       left: 291.24,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 5,
       name: "Commentsi_120", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 386.76,
       left: 313.8,
       width: 256.08,
       height: 19
    }
    ,
    {
       page: 5,
       name: "Gray water storageuse12", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 402,
       left: 66.24,
       width: 219.72,
       height: 13.92
    }
    ,
    {
       page: 5,
       name: "i_155", 
       isText: false,
       type: "checkbox",
       top: 402.96,
       left: 291.24,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 5,
       name: "Commentsi_121", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 402,
       left: 313.8,
       width: 256.08,
       height: 19
    }
    ,
    {
       page: 5,
       name: "i_156", 
       isText: false,
       type: "checkbox",
       top: 469.08,
       left: 289.8,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_122", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 466.08,
       left: 313.92,
       width: 255.12,
       height: 22
    }
    ,
    {
       page: 5,
       name: "Flooding or drainage2", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 485.16,
       left: 66.36,
       width: 216.84,
       height: 17.52
    }
    ,
    {
       page: 5,
       name: "i_157", 
       isText: false,
       type: "checkbox",
       top: 487.8,
       left: 289.8,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_123", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 485.16,
       left: 313.92,
       width: 255.12,
       height: 22
    }
    ,
    {
       page: 5,
       name: "Flooding or drainage3", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 503.88,
       left: 66.36,
       width: 216.84,
       height: 17.76
    }
    ,
    {
       page: 5,
       name: "i_158", 
       isText: false,
       type: "checkbox",
       top: 506.76,
       left: 289.8,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 5,
       name: "Commentsi_124", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 503.88,
       left: 313.92,
       width: 255.12,
       height: 22
    }
    ,
    {
       page: 5,
       name: "i_159", 
       isText: false,
       type: "checkbox",
       top: 564.6,
       left: 289.8,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_125", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 562.08,
       left: 313.92,
       width: 255.12,
       height: 20
    }
    ,
    {
       page: 5,
       name: "Drainage retention ponds5", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 580.08,
       left: 66.36,
       width: 216.84,
       height: 16.92
    }
    ,
    {
       page: 5,
       name: "i_160", 
       isText: false,
       type: "checkbox",
       top: 582.36,
       left: 289.8,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_126", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 580.08,
       left: 313.92,
       width: 255.12,
       height: 22
    }
    ,
    {
       page: 5,
       name: "i_161", 
       isText: false,
       type: "checkbox",
       top: 650.28,
       left: 291.24,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 5,
       name: "Commentsi_127", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 647.64,
       left: 313.92,
       width: 256.56,
       height: 22
    }
    ,
    {
       page: 5,
       name: "i_162", 
       isText: false,
       type: "checkbox",
       top: 669.12,
       left: 291.24,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_128", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 666.36,
       left: 313.92,
       width: 256.56,
       height: 22
    }
    ,
    {
       page: 5,
       name: "i_163", 
       isText: false,
       type: "checkbox",
       top: 687.84,
       left: 291.24,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 5,
       name: "Commentsi_129", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 685.2,
       left: 313.92,
       width: 256.56,
       height: 22
    }
    ,
    {
       page: 5,
       name: "Floors4", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 703.92,
       left: 66.36,
       width: 219.48,
       height: 17.52
    }
    ,
    {
       page: 5,
       name: "i_164", 
       isText: false,
       type: "checkbox",
       top: 706.56,
       left: 291.24,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 5,
       name: "Commentsi_130", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 703.92,
       left: 313.92,
       width: 256.56,
       height: 22
    }
    ,
    {
       page: 5,
       name: "Floors5", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 722.64,
       left: 66.36,
       width: 219.48,
       height: 17.28
    }
    ,
    {
       page: 5,
       name: "i_165", 
       isText: false,
       type: "checkbox",
       top: 725.4,
       left: 291.24,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 5,
       name: "Commentsi_131", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 722.64,
       left: 313.92,
       width: 256.56,
       height: 22
    }
    ,
    {
       page: 6,
       name: "i_166", 
       isText: false,
       type: "checkbox",
       top: 141.24,
       left: 308.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "Commentsi_132", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 136.2,
       left: 332.28,
       width: 240.96,
       height: 26.32
    }
    ,
    {
       page: 6,
       name: "i_167", 
       isText: false,
       type: "checkbox",
       top: 160.68,
       left: 308.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "Commentsi_133m", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 159.6,
       left: 331.92,
       width: 241.68,
       height: 21
    }
    ,
    {
       page: 6,
       name: "i_168", 
       isText: false,
       type: "checkbox",
       top: 180.24,
       left: 308.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "i_169", 
       isText: false,
       type: "checkbox",
       top: 201.24,
       left: 308.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "i_170", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 198.84,
       left: 332.04,
       width: 241.44,
       height: 22
    }
    ,
    {
       page: 6,
       name: "i_171", 
       isText: false,
       type: "checkbox",
       top: 219,
       left: 308.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "i_172", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 216.84,
       left: 332.04,
       width: 241.44,
       height: 19
    }
    ,
    {
       page: 6,
       name: "i_173", 
       isText: false,
       type: "checkbox",
       top: 241.68,
       left: 308.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 6,
       name: "i_174", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 235.08,
       left: 332.28,
       width: 240.96,
       height: 30
    }
    ,
    {
       page: 6,
       name: "i_175", 
       isText: false,
       type: "checkbox",
       top: 273.48,
       left: 308.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "i_176", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 265.08,
       left: 332.4,
       width: 240.72,
       height: 34.48
    }
    ,
    {
       page: 6,
       name: "i_177", 
       isText: false,
       type: "checkbox",
       top: 300.72,
       left: 308.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 6,
       name: "i_178", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 298.56,
       left: 332.04,
       width: 241.44,
       height: 19
    }
    ,
    {
       page: 6,
       name: "i_179", 
       isText: false,
       type: "checkbox",
       top: 323.52,
       left: 308.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "i_180", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 316.56,
       left: 332.28,
       width: 240.96,
       height: 34
    }
    ,
    {
       page: 6,
       name: "i_181", 
       isText: false,
       type: "checkbox",
       top: 345.96,
       left: 308.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 6,
       name: "i_182", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 343.8,
       left: 332.04,
       width: 241.44,
       height: 19
    }
    ,
    {
       page: 6,
       name: "i_183", 
       isText: false,
       type: "checkbox",
       top: 363.96,
       left: 308.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 6,
       name: "i_184", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 361.56,
       left: 332.04,
       width: 241.44,
       height: 19
    }
    ,
    {
       page: 6,
       name: "i_185", 
       isText: false,
       type: "checkbox",
       top: 381.72,
       left: 308.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 6,
       name: "i_186", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 379.56,
       left: 332.04,
       width: 241.44,
       height: 19
    }
    ,
    {
       page: 6,
       name: "i_187", 
       isText: false,
       type: "checkbox",
       top: 399.72,
       left: 308.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 6,
       name: "i_188", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 397.56,
       left: 332.04,
       width: 241.44,
       height: 19
    }
    ,
    {
       page: 6,
       name: "Grandfathered conditions or uses14", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 414.778,
       left: 66.36,
       width: 235.56,
       height: 17.280
    }
    ,
    {
       page: 6,
       name: "i_189", 
       isText: false,
       type: "checkbox",
       top: 417.96,
       left: 308.28,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 6,
       name: "i_190", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 415.32,
       left: 332.04,
       width: 241.44,
       height: 19
    }
    ,
    {
       page: 6,
       name: "Grandfathered conditions or uses15", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 433.378,
       left: 66.36,
       width: 235.56,
       height: 17.280
    }
    ,
    {
       page: 6,
       name: "i_191", 
       isText: false,
       type: "checkbox",
       top: 436.56,
       left: 308.28,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "i_192", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 433.92,
       left: 332.04,
       width: 241.44,
       height: 19
    }
    ,
    {
       page: 6,
       name: "i_193", 
       isText: false,
       type: "checkbox",
       top: 508.8,
       left: 309,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "Commentsi_134", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 501.72,
       left: 332.4,
       width: 238.2,
       height: 30.40
    }
    ,
    {
       page: 6,
       name: "i_194", 
       isText: false,
       type: "checkbox",
       top: 538.08,
       left: 309,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "Commentsi_135", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 529.44,
       left: 332.4,
       width: 238.2,
       height: 33.04
    }
    ,
    {
       page: 6,
       name: "i_195", 
       isText: false,
       type: "checkbox",
       top: 562.8,
       left: 309,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "Commentsi_136", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 559.92,
       left: 332.04,
       width: 238.92,
       height: 22
    }
    ,
    {
       page: 6,
       name: "i_196", 
       isText: false,
       type: "checkbox",
       top: 584.04,
       left: 309,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 6,
       name: "Commentsi_137", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 579.12,
       left: 332.28,
       width: 238.44,
       height: 26.32
    }
    ,
    {
       page: 6,
       name: "i_197", 
       isText: false,
       type: "checkbox",
       top: 609.84,
       left: 309,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "Commentsi_138", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 602.88,
       left: 332.28,
       width: 238.44,
       height: 30.04
    }
    ,
    {
       page: 6,
       name: "i_198", 
       isText: false,
       type: "checkbox",
       top: 632.88,
       left: 309,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "Commentsi_139", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 630.12,
       left: 332.04,
       width: 238.92,
       height: 19
    }
    ,
    {
       page: 6,
       name: "i_199", 
       isText: false,
       type: "checkbox",
       top: 651.36,
       left: 309,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "Commentsi_140", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 648.96,
       left: 332.04,
       width: 238.92,
       height: 19
    }
    ,
    {
       page: 6,
       name: "i_200", 
       isText: false,
       type: "checkbox",
       top: 674.16,
       left: 309,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 6,
       name: "Commentsi_141", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 667.2,
       left: 332.28,
       width: 238.44,
       height: 30.04
    }
    ,
    {
       page: 6,
       name: "tf_accessParking1", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 694.44,
       left: 66.36,
       width: 237.24,
       height: 17.28
    }
    ,
    {
       page: 6,
       name: "i_201", 
       isText: false,
       type: "checkbox",
       top: 697.08,
       left: 309,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 6,
       name: "Commentsi_142", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 694.44,
       left: 332.04,
       width: 238.92,
       height: 19
    }
    ,
    {
       page: 6,
       name: "tf_accessParking2", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 712.92,
       left: 66.36,
       width: 237.24,
       height: 19
    }
    ,
    {
       page: 6,
       name: "i_202", 
       isText: false,
       type: "checkbox",
       top: 715.56,
       left: 309,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 6,
       name: "Commentsi_143", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 712.92,
       left: 332.04,
       width: 238.92,
       height: 19
    }
    ,
    {
       page: 6,
       name: "Commentsi_133z", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 176.815,
       left: 331.92,
       width: 241.68,
       height: 23
    }
    ,
    {
       page: 7,
       name: "i_203", 
       isText: false,
       type: "checkbox",
       top: 94.2,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "Commentsi_144", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 78.12,
       left: 332.64,
       width: 244.80,
       height: 44.64
    }
    ,
    {
       page: 7,
       name: "i_204", 
       isText: false,
       type: "checkbox",
       top: 124.92,
       left: 305.76,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "Commentsi_145", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 123.84,
       left: 331.92,
       width: 246.24,
       height: 19
    }
    ,
    {
       page: 7,
       name: "i_205", 
       isText: false,
       type: "checkbox",
       top: 140.4,
       left: 305.76,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "Commentsi_146", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 139.2,
       left: 331.92,
       width: 246.24,
       height: 19
    }
    ,
    {
       page: 7,
       name: "i_206", 
       isText: false,
       type: "checkbox",
       top: 155.64,
       left: 305.76,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "Commentsi_147", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 154.68,
       left: 331.92,
       width: 246.24,
       height: 19
    }
    ,
    {
       page: 7,
       name: "i_207", 
       isText: false,
       type: "checkbox",
       top: 178.2,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "Commentsi_148", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 169.56,
       left: 332.4,
       width: 245.28,
       height: 32.92
    }
    ,
    {
       page: 7,
       name: "i_208", 
       isText: false,
       type: "checkbox",
       top: 200.76,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "Commentsi_149", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 199.68,
       left: 331.92,
       width: 246.24,
       height: 19
    }
    ,
    {
       page: 7,
       name: "i_209", 
       isText: false,
       type: "checkbox",
       top: 226.92,
       left: 305.76,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "Commentsi_150", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 215.64,
       left: 332.64,
       width: 244.80,
       height: 35.40
    }
    ,
    {
       page: 7,
       name: "i_210", 
       isText: false,
       type: "checkbox",
       top: 255,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "i_211", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 253.92,
       left: 331.92,
       width: 246.24,
       height: 19
    }
    ,
    {
       page: 7,
       name: "i_212", 
       isText: false,
       type: "checkbox",
       top: 275.52,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "i_213", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 269.52,
       left: 332.28,
       width: 245.52,
       height: 28.12
    }
    ,
    {
       page: 7,
       name: "i_214", 
       isText: false,
       type: "checkbox",
       top: 300.72,
       left: 305.76,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "i_215", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 295.08,
       left: 332.28,
       width: 245.52,
       height: 27.76
    }
    ,
    {
       page: 7,
       name: "i_216", 
       isText: false,
       type: "checkbox",
       top: 323.04,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "i_217", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 320.04,
       left: 332.04,
       width: 246.00,
       height: 22
    }
    ,
    {
       page: 7,
       name: "i_218", 
       isText: false,
       type: "checkbox",
       top: 345,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "i_219", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 339.36,
       left: 332.28,
       width: 245.52,
       height: 28.00
    }
    ,
    {
       page: 7,
       name: "i_220", 
       isText: false,
       type: "checkbox",
       top: 370.56,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "i_221", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 364.8,
       left: 332.28,
       width: 245.52,
       height: 27.76
    }
    ,
    {
       page: 7,
       name: "i_222", 
       isText: false,
       type: "checkbox",
       top: 396.72,
       left: 305.76,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "i_223", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 390.12,
       left: 332.28,
       width: 245.52,
       height: 30.04
    }
    ,
    {
       page: 7,
       name: "i_224", 
       isText: false,
       type: "checkbox",
       top: 420,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "i_225", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 417.36,
       left: 332.04,
       width: 246.00,
       height: 22
    }
    ,
    {
       page: 7,
       name: "i_226", 
       isText: false,
       type: "checkbox",
       top: 443.52,
       left: 305.76,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "i_227", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 436.44,
       left: 332.4,
       width: 245.28,
       height: 30.76
    }
    ,
    {
       page: 7,
       name: "i_228", 
       isText: false,
       type: "checkbox",
       top: 467.04,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "i_229", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 464.4,
       left: 332.04,
       width: 246.00,
       height: 22
    }
    ,
    {
       page: 7,
       name: "Odors18", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 482.88,
       left: 66.36,
       width: 230.52,
       height: 17.04
    }
    ,
    {
       page: 7,
       name: "i_230", 
       isText: false,
       type: "checkbox",
       top: 485.28,
       left: 305.76,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "i_231", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 482.88,
       left: 332.04,
       width: 246.00,
       height: 19
    }
    ,
    {
       page: 7,
       name: "Odors19", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 501.12,
       left: 66.36,
       width: 230.52,
       height: 16.56
    }
    ,
    {
       page: 7,
       name: "i_232", 
       isText: false,
       type: "checkbox",
       top: 503.28,
       left: 305.76,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "i_233", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 501.12,
       left: 332.04,
       width: 246.00,
       height: 19
    }
    ,
    {
       page: 7,
       name: "i_234", 
       isText: false,
       type: "checkbox",
       top: 581.04,
       left: 306.72,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "Commentsi_151", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 568.68,
       left: 332.64,
       width: 244.80,
       height: 37.56
    }
    ,
    {
       page: 7,
       name: "i_235", 
       isText: false,
       type: "checkbox",
       top: 613.32,
       left: 306.72,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "Commentsi_152", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 607.68,
       left: 332.28,
       width: 245.52,
       height: 28.00
    }
    ,
    {
       page: 7,
       name: "i_236", 
       isText: false,
       type: "checkbox",
       top: 644.28,
       left: 306.72,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 7,
       name: "Commentsi_153", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 635.76,
       left: 332.4,
       width: 245.28,
       height: 32.48
    }
    ,
    {
       page: 7,
       name: "radon_extra_box1", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 669.12,
       left: 66.36,
       width: 232.56,
       height: 17.40
    }
    ,
    {
       page: 7,
       name: "i_237", 
       isText: false,
       type: "checkbox",
       top: 671.88,
       left: 306.72,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "Commentsi_154", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 669.12,
       left: 332.04,
       width: 246.00,
       height: 19
    }
    ,
    {
       page: 7,
       name: "radon_extra_2", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 687.72,
       left: 66.36,
       width: 232.56,
       height: 17.28
    }
    ,
    {
       page: 7,
       name: "i_238", 
       isText: false,
       type: "checkbox",
       top: 690.36,
       left: 306.72,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 7,
       name: "Commentsi_155", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 687.72,
       left: 332.04,
       width: 246.00,
       height: 19
    }
    ,
    {
       page: 8,
       name: "i_239", 
       isText: false,
       type: "checkbox",
       top: 89.64,
       left: 304.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 8,
       name: "Commentsi_156", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 88.68,
       left: 327.36,
       width: 250.80,
       height: 19
    }
    ,
    {
       page: 8,
       name: "i_240", 
       isText: false,
       type: "checkbox",
       top: 112.68,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_157", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 104.04,
       left: 327.84,
       width: 249.84,
       height: 32.04
    }
    ,
    {
       page: 8,
       name: "i_241", 
       isText: false,
       type: "checkbox",
       top: 140.64,
       left: 304.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 8,
       name: "Commentsi_158", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 134.76,
       left: 327.72,
       width: 250.08,
       height: 28.00
    }
    ,
    {
       page: 8,
       name: "i_242", 
       isText: false,
       type: "checkbox",
       top: 236.16,
       left: 304.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 8,
       name: "Commentsi_159", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 211.92,
       left: 328.08,
       width: 249.36,
       height: 61.44
    }
    ,
    {
       page: 8,
       name: "hoa_extra_1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 274.44,
       left: 66.24,
       width: 232.80,
       height: 14.16
    }
    ,
    {
       page: 8,
       name: "i_243", 
       isText: false,
       type: "checkbox",
       top: 275.52,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_160", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 274.44,
       left: 327.36,
       width: 250.80,
       height: 19
    }
    ,
    {
       page: 8,
       name: "hoa_extra_box2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 289.68,
       left: 66.24,
       width: 232.80,
       height: 14.16
    }
    ,
    {
       page: 8,
       name: "i_244", 
       isText: false,
       type: "checkbox",
       top: 290.76,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_161", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 289.68,
       left: 327.36,
       width: 250.80,
       height: 19
    }
    ,
    {
       page: 8,
       name: "Owners Association 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 368.28,
       left: 71.52,
       width: 226.20,
       height: 12.36
    }
    ,
    {
       page: 8,
       name: "i_245", 
       isText: false,
       type: "checkbox",
       top: 364.56,
       left: 306,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_162", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 356.64,
       left: 327.84,
       width: 243.84,
       height: 31.72
    }
    ,
    {
       page: 8,
       name: "Owners Association 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 397.56,
       left: 71.52,
       width: 226.20,
       height: 12.36
    }
    ,
    {
       page: 8,
       name: "i_246", 
       isText: false,
       type: "checkbox",
       top: 393.72,
       left: 306.24,
       width: 11.04,
       height: 11.04
    }
    ,
    {
       page: 8,
       name: "Commentsi_163", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 385.08,
       left: 327.84,
       width: 243.84,
       height: 31.80
    }
    ,
    {
       page: 8,
       name: "Owners Association 3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 426.84,
       left: 71.52,
       width: 226.20,
       height: 12.36
    }
    ,
    {
       page: 8,
       name: "i_247", 
       isText: false,
       type: "checkbox",
       top: 423,
       left: 305.52,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 8,
       name: "Commentsi_164", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 415.44,
       left: 327.84,
       width: 243.84,
       height: 31.72
    }
    ,
    {
       page: 8,
       name: "Owners Association 4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 456,
       left: 71.52,
       width: 226.20,
       height: 12.36
    }
    ,
    {
       page: 8,
       name: "i_248", 
       isText: false,
       type: "checkbox",
       top: 452.28,
       left: 306,
       width: 11.04,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_165", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 444.72,
       left: 327.84,
       width: 243.84,
       height: 31.72
    }
    ,
    {
       page: 8,
       name: "i_249", 
       isText: false,
       type: "checkbox",
       top: 534.36,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_166", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 525.6,
       left: 327.84,
       width: 245.28,
       height: 32.48
    }
    ,
    {
       page: 8,
       name: "i_250", 
       isText: false,
       type: "checkbox",
       top: 563.04,
       left: 304.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 8,
       name: "Commentsi_167", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 559.2,
       left: 327.6,
       width: 245.76,
       height: 22.92
    }
    ,
    {
       page: 8,
       name: "i_251", 
       isText: false,
       type: "checkbox",
       top: 586.32,
       left: 304.56,
       width: 10.92,
       height: 11.04
    }
    ,
    {
       page: 8,
       name: "Commentsi_168", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 580.68,
       left: 327.72,
       width: 245.52,
       height: 27.76
    }
    ,
    {
       page: 8,
       name: "i_252", 
       isText: false,
       type: "checkbox",
       top: 611.88,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_169", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 605.88,
       left: 327.72,
       width: 245.52,
       height: 28.12
    }
    ,
    {
       page: 8,
       name: "i_253", 
       isText: false,
       type: "checkbox",
       top: 637.08,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_170", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 631.44,
       left: 327.72,
       width: 245.52,
       height: 27.76
    }
    ,
    {
       page: 8,
       name: "i_254", 
       isText: false,
       type: "checkbox",
       top: 662.64,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_171", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 656.64,
       left: 327.72,
       width: 245.52,
       height: 28.12
    }
    ,
    {
       page: 8,
       name: "i_255", 
       isText: false,
       type: "checkbox",
       top: 688.92,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_172", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 682.2,
       left: 327.72,
       width: 245.52,
       height: 29.8
    }
    ,
    {
       page: 8,
       name: "i_256", 
       isText: false,
       type: "checkbox",
       top: 710.4,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_173", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 709.08,
       left: 327.36,
       width: 246.24,
       height: 19
    }
    ,
    {
       page: 8,
       name: "Property is located in a historic district9", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 724.56,
       left: 66.24,
       width: 232.80,
       height: 14.16
    }
    ,
    {
       page: 8,
       name: "i_257", 
       isText: false,
       type: "checkbox",
       top: 725.64,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 8,
       name: "Commentsi_174", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 724.56,
       left: 327.36,
       width: 246.24,
       height: 19
    }
    ,
    {
       page: 9,
       name: "i_258", 
       isText: false,
       type: "checkbox",
       top: 43.2,
       left: 305.04,
       width: 9.72,
       height: 9.72
    }
    ,
    {
       page: 9,
       name: "i_259", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 41.16,
       left: 327.36,
       width: 246.24,
       height: 22
    }
    ,
    {
       page: 9,
       name: "LocationofMailbox", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 85.894,
       left: 179.152,
       width: 117.836,
       height: 21
    }
    ,
    {
       page: 9,
       name: "i_260", 
       isText: false,
       type: "checkbox",
       top: 87.96,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 9,
       name: "Commentsi_175", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 84.12,
       left: 327.6,
       width: 245.76,
       height: 24
    }
    ,
    {
       page: 9,
       name: "tf_general_extras_box", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 105.36,
       left: 66.48,
       width: 232.32,
       height: 19.92
    }
    ,
    {
       page: 9,
       name: "i_261", 
       isText: false,
       type: "checkbox",
       top: 109.2,
       left: 304.56,
       width: 10.92,
       height: 10.92
    }
    ,
    {
       page: 9,
       name: "Commentsi_176z", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 6,
       top: 105.36,
       left: 327.6,
       width: 245.76,
       height: 24
    }
    ,
    {
       page: 9,
       name: "tf_num10page10z", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 6,
       top: 41.095,
       left: 66.7681,
       width: 232.3199,
       height: 13.374
    }
    ] }
    