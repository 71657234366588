import { Formik, Form, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Confirm,
  Divider,
  Grid,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import {
  addPersonToDb,
  deletePartyInDb,
  updatePersonInDb,
} from "../../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";
import MySelectInput from "../../../app/common/form/MySelectInput";
import {
  partyHasCompany,
  partyIsAgent,
  partyIsBuyerOrSeller,
} from "../../../app/common/util/util";
import { peopleOptions } from "../../../app/common/categoryData/categoryOptions";
import FormParty from "../../../app/common/form/FormParty";
import FormAddress from "../../../app/common/form/FormAddress";
import MyCheckbox from "../../../app/common/form/MyCheckbox";
import { useMediaQuery } from "react-responsive";

export default function PersonForm({ person }) {
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  let initialValues = person
    ? person
    : {
        type: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        address: {
          street: "",
          unit: "",
          city: "",
          state: "",
          zipcode: "",
        },
        hasAssistant: false,
        assistant: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        isTrust: false,
        entityName: "",
        brokerLicenseNumber: "",
        brokerageName: "",
        brokerageId: "",
        brokerageLicenseNumber: "",
      };

  const [selectedType, setSelectedType] = useState(initialValues.type);
  const [isTrustField, setIsTrustField] = useState(
    initialValues.isTrust ? true : false
  );
  const [partyHasAssistant, setPartyHasAssistant] = useState(
    initialValues.hasAssistant
  );

  const validationSchema = Yup.object({
    lastName: Yup.string().required("You must provide a last name"),
    type: Yup.string().required("You must provide a role"),
    email: Yup.string().notRequired().email("Must be a valid email"),
  });

  function Watcher() {
    const { values } = useFormikContext();
    useEffect(() => {
      values.type && setSelectedType(values.type);
    }, [values.type]);
    return null;
  }

  function handleAssistant() {
    setPartyHasAssistant(!partyHasAssistant);
  }

  async function handleDelete() {
    try {
      await deletePartyInDb(person);
      toast.success("Person successfully deleted");
      dispatch(
        closeModal({
          modalType: "PersonForm",
        })
      );
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <ModalWrapper>
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                if (!values.hasAssistant) {
                  values.assistant = {};
                }
                person
                  ? await updatePersonInDb(person.id, values)
                  : await addPersonToDb(values);
                setSubmitting(false);
                toast.success("Person successfully updated");
                dispatch(
                  closeModal({
                    modalType: "PersonForm",
                  })
                );
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid }) => (
              <Form className="ui form">
                <Watcher />
                <Header size="large" color="blue">
                  {person ? "Edit Person" : "Add Person"}
                </Header>
                <Divider />
                <Grid>
                  <Grid.Column mobile={16} computer={5}>
                    <MySelectInput
                      name="type"
                      placeholder="Role"
                      options={peopleOptions}
                    />
                  </Grid.Column>
                </Grid>
                <Divider />
                <FormParty />

                {partyIsBuyerOrSeller(selectedType) && (
                  <>
                    <br />
                    <MyCheckbox
                      name="isTrust"
                      label="This client is acting on behalf of a company, trust, or other entity"
                      onClick={() => setIsTrustField(!isTrustField)}
                    />
                    {isTrustField && (
                      <MyTextInput
                        name="entityName"
                        placeholder="Company, trust, or entity name"
                      />
                    )}
                  </>
                )}
                <Divider />
                {partyHasCompany(selectedType) && (
                  <MyTextInput name="companyName" placeholder="Company Name" />
                )}
                <FormAddress />
                {partyIsAgent(selectedType) && (
                  <>
                    <br />
                    <Divider />
                    <Grid stackable>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <MyTextInput
                            name="brokerLicenseNumber"
                            label="Agent License Number"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className="zero top padding">
                        <Grid.Column width={5}>
                          <MyTextInput
                            name="brokerageName"
                            label="Brokerage Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <MyTextInput
                            name="brokerageId"
                            label="Brokerage ID"
                          />
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <MyTextInput
                            name="brokerageLicenseNumber"
                            label="Brokerage License Number"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </>
                )}
                {!partyIsBuyerOrSeller(selectedType) && (
                  <>
                    <Grid>
                      <Grid.Row className="tiny top padding tiny bottom padding">
                        <Grid.Column width={16}>
                          <MyCheckbox
                            name="hasAssistant"
                            label="This person has an assistant"
                            onClick={() => handleAssistant()}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    {partyHasAssistant && (
                      <Grid>
                        <Grid.Row className="tiny vertical padding">
                          <Grid.Column mobile={16} computer={4}>
                            <MyTextInput
                              name="assistant.firstName"
                              placeholder="Assistant first name"
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <MyTextInput
                              name="assistant.lastName"
                              placeholder="Assistant last name"
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <MyTextInput
                              name="assistant.email"
                              placeholder="Assistant email"
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <MyTextInput
                              name="assistant.phone"
                              placeholder="Assistant phone"
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )}
                  </>
                )}
                <Divider className="medium top margin" />
                <Button
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                  type="submit"
                  floated={isMobile ? null : "right"}
                  primary
                  content="Submit"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "PersonForm",
                      })
                    )
                  }
                  to="#"
                  type="button"
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                {person && !isMobile && (
                  <Icon
                    name="trash"
                    link
                    onClick={() => setConfirmOpen(true)}
                  />
                )}
                {person && isMobile && (
                  <Button
                    type="button"
                    basic
                    color="red"
                    content="Delete"
                    fluid
                    onClick={() => setConfirmOpen(true)}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleDelete()}
      ></Confirm>
    </ModalWrapper>
  );
}
