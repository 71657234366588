export function commissionDisbursementNextHomeVirtual() {
return [   //2024 Release 2024-06-05 08:30:25
{
   page: 0,
   name: "Text-QiC06OYED8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 214.655,
   left: 174.667,
   width: 378.425,
   height: 16.490
}
,
{
   page: 0,
   name: "Text-_ZH0Maf2sm", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 245.988,
   left: 174.667,
   width: 378.425,
   height: 16.490
}
,
{
   page: 0,
   name: "Text-gKAYy8vPl2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 280.655,
   left: 174.667,
   width: 378.425,
   height: 16.490
}
,
{
   page: 0,
   name: "Text-kb7BNW7Pfr", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 312.655,
   left: 174.667,
   width: 378.425,
   height: 16.490
}
,
{
   page: 0,
   name: "Text-3BKVPJKdV-", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 346.655,
   left: 174.667,
   width: 378.425,
   height: 16.490
}
,
{
   page: 0,
   name: "Text-m-dEK0JU3z", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 377.988,
   left: 174.667,
   width: 378.425,
   height: 16.490
}
,
{
   page: 0,
   name: "Text-cyFfr9IFDG", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 430,
   left: 428,
   width: 107.333,
   height: 13
}
,
{
   page: 0,
   name: "Text-D0eidaBSBW", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 447.333,
   left: 428.667,
   width: 107.333,
   height: 13.000
}
,
{
   page: 0,
   name: "Text-wy5hTNrt5w", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 574.0,
   left: 214.667,
   width: 318.861,
   height: 17.8
}
,
{
   page: 0,
   name: "Text-YkVDUSEDRx", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 606.0,
   left: 276.667,
   width: 256.024,
   height: 17.8
}
,
{
   page: 0,
   name: "Text-eKOHG7-2Qe", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 640.667,
   left: 207.333,
   width: 324.097,
   height: 17.800
}
,
{
   page: 0,
   name: "CheckBox-myw4VfuySr", 
   isText: false,
   type: "checkbox",
   top: 510.0,
   left: 450.667,
   width: 20.000,
   height: 18.036
}
,
{
   page: 0,
   name: "Text-jOU5Efxs9t", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 478.333,
   left: 285.333,
   width: 66.000,
   height: 14.479
}
,
{
   page: 0,
   name: "Text-1k9SMM3B9N", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 478.333,
   left: 395.333,
   width: 66.000,
   height: 14.479
}
,
{
   page: 0,
   name: "Text-6L1xAFNsvG", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 479.0,
   left: 504.0,
   width: 66.0,
   height: 14.479
}
] }
