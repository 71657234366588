export function agreementtoRevive() {
  return [
    //2023
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    },
    {
      page: 0,
      name: "Date",
      fontSize: 9,
      type: "date",
      top: 153.6,
      left: 398.64,
      width: 174.0,
      height: 11.16,
    },
    {
      page: 0,
      name: "contractDate",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 169.64,
      left: 235.92,
      width: 129.72,
      height: 11.16,
    },
    {
      page: 0,
      name: "sellersBoth",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 184.32,
      left: 50.4,
      width: 441.906,
      height: 11.989,
    },
    {
      page: 0,
      name: "buyersBoth",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 197.64,
      left: 50.2244,
      width: 441.9066,
      height: 11.989,
    },
    {
      page: 0,
      name: "county",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 213.847,
      left: 385.752,
      width: 149.323,
      height: 11.989,
    },
    {
      page: 0,
      name: "Text1",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 227.964,
      left: 50.4002,
      width: 528.8748,
      height: 53.673,
    },
    {
      page: 0,
      name: "known as",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 282.291,
      left: 96.24,
      width: 431.4,
      height: 12.469,
    },
    {
      page: 0,
      name: "date contractDateTerminated",
      fontSize: 9,
      type: "date",
      top: 310.32,
      left: 342.84,
      width: 130.32,
      height: 11.16,
    },
    {
      page: 0,
      name: "Text2",
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 358.692,
      left: 45.1636,
      width: 547.2034,
      height: 190.474,
    },
    {
      page: 0,
      name: "Date Revive Acceptance Deadline",
      fontSize: 9,
      type: "date",
      top: 619.182,
      left: 315.138,
      width: 66.838,
      height: 12.94,
    },
    {
      page: 0,
      name: "time acceptTime",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 618.831,
      left: 388.312,
      width: 129.695,
      height: 13.173,
    },
    {
      page: 0,
      name: "seller1FullName",
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 687.12,
      left: 76.5819,
      width: 186.1741,
      height: 13.1241,
    },
    {
      page: 0,
      name: "buyer1FullName",
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 687.276,
      left: 367.201,
      width: 147.929,
      height: 11.782,
    },
    {
      page: 0,
      name: "seller2FullName",
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 724.6476,
      left: 77.2365,
      width: 185.5195,
      height: 12.4692,
    },
    {
      page: 0,
      name: "buyer2FullName",
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 725.2399,
      left: 367.202,
      width: 149.891,
      height: 13.745,
    },
  ];
}