export function counterproposalWithoutDates() {
return [   //2024 Release 2024-02-29 09:38:53
{
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
    {
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 143.76,
   left: 398.88,
   width: 176.04,
   height: 10.56
}
,
{
   page: 0,
   name: "contract dated", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 178.08,
   left: 109.44,
   width: 123.36,
   height: 10.56
}
,
{
   page: 0,
   name: "Contract between", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 178.08,
   left: 312.24,
   width: 268.571,
   height: 10.56
}
,
{
   page: 0,
   name: "Seller and", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 189.6,
   left: 100.08,
   width: 308.40,
   height: 10.56
}
,
{
   page: 0,
   name: "of the following legally described real estate in the County of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 201.36,
   left: 297.36,
   width: 108.84,
   height: 10.56
}
,
{
   page: 0,
   name: "known as", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 270.48,
   left: 96.24,
   width: 433.68,
   height: 10.56
}
,
{
   page: 0,
   name: "legalDesc", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 212.728,
   left: 47.1275,
   width: 536.1835,
   height: 57.346
}
,
{
   page: 0,
   name: "Text8", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 388.147,
   left: 49.7457,
   width: 528.3293,
   height: 35.746
}
,
{
   page: 0,
   name: "Text9", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 435.275,
   left: 51.0548,
   width: 527.0202,
   height: 35.091
}
,
{
   page: 0,
   name: "Text10", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 480.439,
   left: 47.782,
   width: 532.256,
   height: 265.4915
}
,
{
   page: 1,
   name: "Date_2", 
   fontSize: 9,
   type: "date",
   top: 111.48,
   left: 49.2,
   width: 75.0,
   height: 12.84
}
,
{
   page: 1,
   name: "Time", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 111.48,
   left: 129.24,
   width: 60.00,
   height: 12.84
}
,
{
   page: 1,
   name: "Buyers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 180.36,
   left: 122.04,
   width: 181.68,
   height: 12.84
}
,
{
   page: 1,
   name: "Address 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 242.52,
   left: 122.04,
   width: 181.68,
   height: 12.84
}
,
{
   page: 1,
   name: "Address 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 256.2,
   left: 121.92,
   width: 181.80,
   height: 12.84
}
,
{
   page: 1,
   name: "Phone No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 269.64,
   left: 122.04,
   width: 181.68,
   height: 10.92
}
,
{
   page: 1,
   name: "Fax No", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 281.88,
   left: 122.04,
   width: 181.68,
   height: 12.84
}
,
{
   page: 1,
   name: "Email Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 295.32,
   left: 122.04,
   width: 181.68,
   height: 12.84
}
,
{
   page: 1,
   name: "Sellers Name", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 330.6,
   left: 122.04,
   width: 181.68,
   height: 12.84
}
,
{
   page: 1,
   name: "Address 1_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 391.32,
   left: 122.04,
   width: 181.68,
   height: 12.84
}
,
{
   page: 1,
   name: "Address 2_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 404.76,
   left: 121.92,
   width: 181.80,
   height: 12.84
}
,
{
   page: 1,
   name: "Phone No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 418.2,
   left: 122.04,
   width: 181.68,
   height: 10.92
}
,
{
   page: 1,
   name: "Fax No_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 429.72,
   left: 122.04,
   width: 181.68,
   height: 12.84
}
,
{
   page: 1,
   name: "Email Address_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 443.16,
   left: 122.04,
   width: 181.68,
   height: 12.84
}
,
{
   page: 1,
   name: "Buyers Name_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 180.36,
   left: 396.24,
   width: 180.36,
   height: 12.84
}
,
{
   page: 1,
   name: "Address", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 242.52,
   left: 396.24,
   width: 180.36,
   height: 12.84
}
,
{
   page: 1,
   name: "Phone No_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 268.44,
   left: 396.24,
   width: 180.36,
   height: 12.84
}
,
{
   page: 1,
   name: "Fax No_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 281.88,
   left: 396.24,
   width: 180.36,
   height: 12.84
}
,
{
   page: 1,
   name: "Email Address_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 295.32,
   left: 396.24,
   width: 180.36,
   height: 12.84
}
,
{
   page: 1,
   name: "Sellers Name_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 330.6,
   left: 396.24,
   width: 180.36,
   height: 12.84
}
,
{
   page: 1,
   name: "Address 1_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 391.32,
   left: 396.96,
   width: 179.64,
   height: 12.84
}
,
{
   page: 1,
   name: "Address 2_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 404.76,
   left: 396.48,
   width: 180.12,
   height: 12.84
}
,
{
   page: 1,
   name: "Phone No_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 418.2,
   left: 396.96,
   width: 179.64,
   height: 10.92
}
,
{
   page: 1,
   name: "Fax No_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 429.72,
   left: 396.96,
   width: 179.64,
   height: 12.84
}
,
{
   page: 1,
   name: "Email Address_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 443.16,
   left: 396.48,
   width: 180.12,
   height: 12.84
}
] }
