export function listingAgreement() {
return [   //2024 Release 2024-02-20 13:29:32
{
   page: 0,
   name: "Text Field 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 109.0,
   left: 109.72,
   width: 466.28,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 91", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 128.88,
   left: 109.72,
   width: 466.28,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 92", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 140.596,
   left: 31.5,
   width: 544.5,
   height: 33.137
}
,
{
   page: 0,
   name: "Text Field 93", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 216.72,
   left: 36.72,
   width: 218.16,
   height: 13.50
}
,
{
   page: 0,
   name: "date Text Field 3", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 327.04,
   left: 170.64,
   width: 200.16,
   height: 13.50
}
,
{
   page: 0,
   name: "date Text Field 94", 
   type: "date",
   fontName: "Helvetica",
   fontSize: 10,
   top: 338.4,
   left: 172.8,
   width: 233.28,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 95", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 357.84,
   left: 290.52,
   width: 52.34,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 110", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 369.34,
   left: 77.04,
   width: 46.08,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 111", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 369.34,
   left: 218.88,
   width: 87.12,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 139", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 379.84,
   left: 434.804,
   width: 32.293,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 132", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 392.25,
   left: 122.76,
   width: 46.08,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 133", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 392.25,
   left: 307.46,
   width: 32.292,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 134", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 392.25,
   left: 528.48,
   width: 41.04,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 97", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 477.2,
   left: 259.92,
   width: 61.20,
   height: 13.5
}
,
{
   page: 0,
   name: "Text Field 98", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 575.74,
   left: 330.5,
   width: 51.82,
   height: 13.50
}
,
{
   page: 3,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 258.632,
   left: 139.824,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 258.632,
   left: 162.144,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 4", 
   isText: false,
   type: "checkbox",
   top: 323.08,
   left: 136.08,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 5", 
   isText: false,
   type: "checkbox",
   top: 323.08,
   left: 173.16,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Text Field 100", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 354.21,
   left: 275.04,
   width: 77.04,
   height: 13.50
}
,
{
   page: 3,
   name: "Check Box 7", 
   isText: false,
   type: "checkbox",
   top: 371.92,
   left: 201.24,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 8", 
   isText: false,
   type: "checkbox",
   top: 371.92,
   left: 268.92,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 9", 
   isText: false,
   type: "checkbox",
   top: 399.52,
   left: 139.68,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Check Box 10", 
   isText: false,
   type: "checkbox",
   top: 399.52,
   left: 173.16,
   width: 9.216,
   height: 9.396
}
,
{
   page: 3,
   name: "Text Field 101", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 418.5,
   left: 154.08,
   width: 422.352,
   height: 13.5
}
,
{
   page: 3,
   name: "Text Field 102", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 434.88,
   left: 31.392,
   width: 545.040,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 103", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 455.32,
   left: 31.5,
   width: 544.932,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 104", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 480.52,
   left: 110.16,
   width: 34.56,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 105", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 480.52,
   left: 179.28,
   width: 119.52,
   height: 13.50
}
,
{
   page: 3,
   name: "Text Field 106", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 501.244,
   left: 31.5,
   width: 267.3,
   height: 13.500
}
,
{
   page: 3,
   name: "signature Text Field 146", 
   type: "signature",
   fontName: "Helvetica",
   fontSize: 12,
   top: 501.244,
   left: 353.08,
   width: 223.352,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 107", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 537.029,
   left: 31.5,
   width: 267.3,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 108", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 565.814,
   left: 31.392,
   width: 267.300,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 109", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 595.599,
   left: 31.392,
   width: 267.300,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 1010", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 595.599,
   left: 342.612,
   width: 233.820,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 1011", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 624.384,
   left: 31.392,
   width: 267.300,
   height: 13.500
}
,
{
   page: 3,
   name: "Text Field 1014", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 624.384,
   left: 342.612,
   width: 233.820,
   height: 13.500
}
, 
{
   page: 3,
   name: "Logo",
   isText: false,
   fontSize: 10,
   type: "logo",
   top: 664,
   left: 31.4,
   width: 200,
   height: 30,
}
] }
