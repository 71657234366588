import { format } from "date-fns";
import React from "react";

export function FormFieldDateDisplay({ formField, pageScale }) {
  return (
    <div
      className="pdfFormFieldDisplay"
      style={{
        top: `${
          (formField.top + formField.height - formField.fontSize) * pageScale
        }px`,
        left: `${formField.left * pageScale}px`,
        height: `${formField.height * pageScale}px`,
        width: `${formField.width * pageScale}px`,
        fontSize: `${formField.fontSize * pageScale}px`,
      }}
    >
      <span
        className="textFieldDisplay"
        style={{
          fontFamily: "helvetica",
          borderWidth: "0px",
          fontSize: `${formField.fontSize * pageScale}px`,
          margin: "0px",
          textAlign: "left",
        }}
      >
        {formField.value && format(formField.value.toDate(), "M/d/yyyy")}
      </span>
    </div>
  );
}
