import { Formik, Form } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";
import MyTextArea from "../../../app/common/form/MyTextArea";
import {
  addClauseToDb,
  updateClauseInDb,
} from "../../../app/firestore/firestoreService";

export default function ClausesEdit({ clause }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  let initialValues = clause
    ? clause
    : {
        text: "",
        title: "",
        type: "user",
        isBrokerageClause: false,
      };

  const validationSchema = Yup.object({
    text: Yup.string().required("You must text"),
    title: Yup.string().required("You must provide a name"),
  });

  return (
    <ModalWrapper>
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                clause
                  ? await updateClauseInDb(clause.id, values)
                  : await addClauseToDb(values);
                setSubmitting(false);
                clause
                  ? toast.success("Clause successfully updated")
                  : toast.success("Clause successfully created");
                dispatch(
                  closeModal({
                    modalType: "ClausesEdit",
                  })
                );
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty }) => (
              <Form className="ui form" autoComplete="off">
                <Header size="large" color="blue">
                  {clause ? "Edit Clause" : "Add Clause"}
                </Header>
                <Divider />
                <h4 className="zero top margin tiny bottom margin">Name</h4>
                <MyTextInput name="title" />
                <h4 className="zero top margin tiny bottom margin">Clause</h4>
                <MyTextArea name="text" />
                <div>
                  <Button
                    primary
                    loading={isSubmitting}
                    disabled={!dirty || isSubmitting}
                    type="submit"
                    floated={isMobile ? null : "right"}
                    content="Submit"
                    className={isMobile ? "fluid medium bottom margin" : null}
                  />
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    onClick={() =>
                      dispatch(
                        closeModal({
                          modalType: "ClausesEdit",
                        })
                      )
                    }
                    floated={isMobile ? null : "right"}
                    content="Cancel"
                    className={isMobile ? "fluid medium bottom margin" : null}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </ModalWrapper>
  );
}
