export function noticeofCancellation() {
return [   //2024 Release 2024-02-20 12:21:26
{
   page: 0,
   name: "Text Field 8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 98.36,
   left: 117.72,
   width: 462.024,
   height: 13.50
}
,
{
   page: 0,
   name: "Check Box 1", 
   isText: false,
   type: "checkbox",
   top: 148.224,
   left: 52.992,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 2", 
   isText: false,
   type: "checkbox",
   top: 179.648,
   left: 52.992,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 3", 
   isText: false,
   type: "checkbox",
   top: 196.824,
   left: 52.992,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 4", 
   isText: false,
   type: "checkbox",
   top: 214.0,
   left: 52.992,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 5", 
   isText: false,
   type: "checkbox",
   top: 232.176,
   left: 52.992,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 6", 
   isText: false,
   type: "checkbox",
   top: 250.352,
   left: 52.992,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 7", 
   isText: false,
   type: "checkbox",
   top: 281.648,
   left: 52.992,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 13", 
   isText: false,
   type: "checkbox",
   top: 374.296,
   left: 54.0,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 8", 
   isText: false,
   type: "checkbox",
   top: 432.066,
   left: 54.0,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 9", 
   isText: false,
   type: "checkbox",
   top: 449.324,
   left: 54.0,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 12", 
   isText: false,
   type: "checkbox",
   top: 466.582,
   left: 54.0,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Check Box 10", 
   isText: false,
   type: "checkbox",
   top: 578.376,
   left: 54.0,
   width: 9.216,
   height: 9.144
}
,
{
   page: 0,
   name: "Text Field 20", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 589.76,
   left: 241.2,
   width: 104.4,
   height: 13.50
}
,
{
   page: 0,
   name: "Text Field 21", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 589.76,
   left: 362.52,
   width: 27.72,
   height: 13.50
}
,
{
   page: 0,
   name: "Check Box 11", 
   isText: false,
   type: "checkbox",
   top: 670.408,
   left: 54.0,
   width: 9.216,
   height: 9.144
}
] }
