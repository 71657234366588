import React, { useState } from "react";
import { useSelector } from "react-redux";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Grid, Input, Message, Divider, Image } from "semantic-ui-react";
import {
  convertAddressStreet,
  searchFilter,
} from "../../../app/common/util/util";
import { useMediaQuery } from "react-responsive";
import PartyDocViewList from "./PartyDocViewList";
import PartyDocSignList from "./PartyDocSignList";

export default function PartyOverview() {
  const { docsForViewing, docsForSigning, party } = useSelector(
    (state) => state.party
  );
  const { transaction } = useSelector((state) => state.transaction);
  const [searchTerms, setSearchTerms] = useState("");
  const docsViewing = searchFilter(docsForViewing?.docs, searchTerms);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const { loading } = useSelector((state) => state.async);

  if (!party || !party.email || loading) {
    return <LoadingComponent content="Loading documents..." />;
  }

  return (
    <>
      <Grid
        stackable
        className={
          isMobile
            ? "small padding"
            : "big vertical padding huge horizontal padding"
        }
      >
        <Grid.Row columns="equal" className="zero bottom padding">
          <Grid.Column className="zero bottom padding">
            <h2>
              {party && party.firstName
                ? "Welcome " + party.firstName + "!"
                : ""}
            </h2>
          </Grid.Column>
          <Grid.Column className="zero bottom padding">
            <div className={isMobile ? "float-left" : "text-center"}>
              <h2>
                {transaction?.address &&
                  convertAddressStreet(transaction?.address)}
              </h2>
            </div>
          </Grid.Column>
          <Grid.Column className="zero bottom padding">
            <div className={isMobile ? "float-left" : "float-right"}>
              <h4>
                {(transaction?.agentName ||
                  transaction?.agentProfile?.firstName) && (
                  <>
                    Shared with you by: <br />[
                  </>
                )}
                {transaction?.agentRepresents === "Seller"
                  ? "Listing"
                  : transaction?.agentRepresents}{" "}
                Agent] <br />
                {transaction?.agentName ||
                  transaction?.agentProfile?.firstName +
                    " " +
                    transaction?.agentProfile?.lastName}
              </h4>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Column width={16} className="zero vertical padding mobile hidden">
          <Divider />
        </Grid.Column>
        {docsForSigning?.docs?.length > 0 ? (
          <>
            <Grid.Column computer={16} className="mini bottom margin">
              <Message
                fluid="true"
                color="orange"
                style={{ paddingTop: "26px", paddingBottom: "30px" }}
              >
                <Message.Header style={{ fontSize: "18px" }}>
                  You have {docsForSigning?.docs?.length} document
                  {docsForSigning?.docs?.length === 1 ? "" : "s"} to sign.
                </Message.Header>
                <p className="tiny top margin">
                  Please click "Sign" on each document below. After signing each
                  document, be sure to click on the "Submit Form" button.
                </p>
                <Grid.Column computer={16}>
                  <PartyDocSignList
                    docs={docsForSigning.docs}
                    column={docsForSigning.column}
                    direction={docsForSigning.direction}
                  />
                </Grid.Column>
              </Message>
            </Grid.Column>
          </>
        ) : (
          <Grid.Column computer={16} className="large bottom margin">
            <Message
              fluid="true"
              style={{ paddingTop: "26px", paddingBottom: "30px" }}
            >
              <Message.Header style={{ fontSize: "18px" }}>
                Status: There are currently no documents to sign.
              </Message.Header>
            </Message>
          </Grid.Column>
        )}

        <Grid.Column computer={16}>
          <Message
            fluid="true"
            style={{ paddingTop: "26px", paddingBottom: "30px" }}
          >
            <Message.Header style={{ fontSize: "18px" }}>
              Shared Documents
            </Message.Header>
            <p className="tiny top margin">
              The following documents are shared by your agent.
              {/* There is no action needed on them. */}
            </p>
            <Grid className="tiny bottom margin">
              <Grid.Column computer={8} mobile={16}>
                <Input
                  type="text"
                  fluid
                  placeholder="Search by document name or status"
                  value={searchTerms}
                  onChange={(e) => setSearchTerms(e.target.value)}
                ></Input>
              </Grid.Column>
              <Grid.Column width={8}></Grid.Column>
            </Grid>
            {docsViewing?.length > 0 ? (
              <Grid.Column computer={16}>
                <PartyDocViewList
                  docs={docsViewing}
                  column={docsForViewing.column}
                  direction={docsForViewing.direction}
                />
              </Grid.Column>
            ) : (
              <p>There are no shared documents</p>
            )}
          </Message>
        </Grid.Column>
        <Grid.Column
          computer={16}
          className="zero bottom padding large top margin"
        >
          {transaction?.agentProfile?.brokerageLogoRef && (
            <Image
              src={transaction.agentProfile.brokerageLogoRef}
              size="medium"
            />
          )}
        </Grid.Column>
        <Grid.Column computer={16} className="zero bottom padding">
          <div>
            <span className="bold">
              {transaction?.agentName ||
                transaction?.agentProfile?.firstName +
                  " " +
                  transaction?.agentProfile?.lastName}
            </span>
            <br />
            {transaction?.agentProfile?.brokerageName}
            <br />
            {transaction?.agentProfile?.phone}
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
}
