import React from "react";
import { useSelector } from "react-redux";
import { Grid, Table } from "semantic-ui-react";
import ManagerAnalyticsList from "./ManagerAnalyticsList";

export default function ManagerAnalyticsDashboard() {
  const {
    transActiveForManager,
    transUnderContractForManager,
    transActiveListingForManager,
    transActiveBuyerForManager,
    transClosedForManager,
  } = useSelector((state) => state.transaction);

  let closedTransactionsByAgent = {};
  transClosedForManager.forEach((transaction) => {
    if (
      transaction.agentProfile?.firstName &&
      transaction.agentProfile?.lastName
    ) {
      let salesPrice = 0;
      if (transaction.salesPrice) {
        salesPrice = parseFloat(transaction.salesPrice.replace(/[$,]/g, ""));
      }
      const agentName = `${transaction.agentProfile?.firstName} ${transaction.agentProfile?.lastName}`;
      if (closedTransactionsByAgent[agentName]) {
        closedTransactionsByAgent[agentName].count += 1;
        closedTransactionsByAgent[agentName].totalSalesPrice += salesPrice;
      } else {
        closedTransactionsByAgent[agentName] = {
          count: 1,
          totalSalesPrice: salesPrice,
        };
      }
    }
  });

  let numberUnderContract = "0";
  if (
    transUnderContractForManager?.length > 0 &&
    transActiveForManager?.length > 0
  ) {
    numberUnderContract = (
      (transUnderContractForManager.length / transActiveForManager.length) *
      100
    ).toString();
  }

  let numberActiveListing = "0";
  if (
    transActiveListingForManager?.length > 0 &&
    transActiveForManager?.length > 0
  ) {
    numberActiveListing = (
      (transActiveListingForManager.length / transActiveForManager.length) *
      100
    ).toString();
  }

  let numberActiveBuyer = "0";
  if (
    transActiveBuyerForManager?.length > 0 &&
    transActiveForManager?.length > 0
  ) {
    numberActiveBuyer = (
      (transActiveBuyerForManager.length / transActiveForManager.length) *
      100
    ).toString();
  }

  return (
    <div className="main-page-wrapper">
      <>
        <Grid stackable className="large bottom margin">
          <Grid.Row>
            <Grid.Column
              computer={16}
              className="large top margin small bottom margin"
            >
              <h1
                className="zero bottom margin"
                style={{ position: "absolute", bottom: "0" }}
              >
                Manager Dashboard
              </h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="small top margin">
            <Grid.Column
              computer={4}
              className="small bottom margin text-center"
            >
              <div className="progress-bar-wrapper">
                <div
                  className="progress-bar"
                  style={{
                    background: `radial-gradient(closest-side, white 79%, transparent 80% 100%),\n conic-gradient(deepskyblue 100%, lightgray 0)`,
                  }}
                >
                  <h2>{transActiveForManager.length}</h2>
                </div>
              </div>
              <h3>Active Transactions</h3>
            </Grid.Column>
            <Grid.Column
              computer={4}
              className="small bottom margin text-center"
            >
              <div className="progress-bar-wrapper">
                <div
                  className="progress-bar"
                  style={{
                    background: `radial-gradient(closest-side, white 79%, transparent 80% 100%),\n conic-gradient(darkorange ${numberUnderContract}%, lightgray 0)`,
                  }}
                >
                  <h2>{transUnderContractForManager.length}</h2>
                </div>
              </div>
              <h3>Under Contract</h3>
            </Grid.Column>
            <Grid.Column
              computer={4}
              className="small bottom margin text-center"
            >
              <div className="progress-bar-wrapper">
                <div
                  className="progress-bar"
                  style={{
                    background: `radial-gradient(closest-side, white 79%, transparent 80% 100%),\n conic-gradient(mediumspringgreen ${numberActiveListing}%, lightgray 0)`,
                  }}
                >
                  <h2>{transActiveListingForManager.length}</h2>
                </div>
              </div>
              <h3>Active Listings</h3>
            </Grid.Column>
            <Grid.Column
              computer={4}
              className="small bottom margin text-center"
            >
              <div className="progress-bar-wrapper">
                <div
                  className="progress-bar"
                  style={{
                    background: `radial-gradient(closest-side, white 79%, transparent 80% 100%),\n conic-gradient(fuchsia ${numberActiveBuyer}%, lightgray 0)`,
                  }}
                >
                  <h2>{transActiveBuyerForManager.length}</h2>
                </div>
              </div>
              <h3>Active Buyers</h3>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Column computer={16} className="small bottom margin">
            <h3 className="large bottom margin">Upcoming / Recent Closings</h3>
            <ManagerAnalyticsList transactions={transActiveForManager} />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column computer={16} className="small bottom margin">
            <h3 className="large bottom margin">Closings Year-to-Date</h3>
            <Table compact>
              <Table.Header className="mobile hidden">
                <Table.Row className="small-header">
                  <Table.HeaderCell>Agent</Table.HeaderCell>
                  <Table.HeaderCell>Number of Closings</Table.HeaderCell>
                  <Table.HeaderCell>Total Sales Price</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.entries(closedTransactionsByAgent).map(
                  ([key, value]) => (
                    <Table.Row key={key}>
                      <Table.Cell>{key}</Table.Cell>
                      <Table.Cell>{value.count}</Table.Cell>
                      <Table.Cell>
                        {value.totalSalesPrice.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid>
      </>
    </div>
  );
}
