export function fSBOCommissionAgreementHSPR() {
    return [   //2023
    {
       page: 0,
       name: "and is listing the Property for sale to potential purchasers without being represented by a real estate broker", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 231.84,
       left: 57.6,
       width: 414.0,
       height: 11.40
    }
    ,
    {
       page: 0,
       name: "the Prospective Buyer and HomeSmart represents the Prospective Buyer with respect to the Prospective", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 295.2,
       left: 231.6,
       width: 325.68,
       height: 11.4
    }
    ,
    {
       page: 0,
       name: "related to the purchase of the Property", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 450.96,
       left: 249.84,
       width: 204.24,
       height: 11.40
    }
    ,
    {
       page: 0,
       name: "the Property the Buyers Agent Commission The Buyers Agent", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 450.6,
       left: 504,
       width: 38.64,
       height: 11.4
    }
    ,
    {
       page: 0,
       name: "said Exclusive RighttoBuy Listing Contract the Term", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 602.76,
       left: 177.24,
       width: 149.04,
       height: 11.40
    }
    ,
    {
       page: 1,
       name: "Printed Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 575.56,
       left: 124.44,
       width: 172.80,
       height: 15.229
    }
    ,
    {
       page: 1,
       name: "Printed Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 580.84,
       left: 384.36,
       width: 171.12,
       height: 15.229
    }
    ,
    {
       page: 1,
       name: "SignatureAgent", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 10,
       top: 556.403,
       left: 365.183,
       width: 190.778,
       height: 15.229
    }
    ,
    {
       page: 1,
       name: "Date_2", 
       fontSize: 9,
       type: "date",
       top: 605.44,
       left: 346.8,
       width: 209.76,
       height: 15.229
    }
    ,
    {
       page: 1,
       name: "Printed Name_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 666.4,
       left: 124.44,
       width: 176.64,
       height: 15.229
    }
    ] }
    