import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { convertFullName } from "../../../../../app/common/util/util";
import { createAnnotColor } from "../annots/annotUtils";
import {
  changeEditMode,
  changeSelectedSigner,
} from "../../../../../app/annots/annotSlice";

export default function DocPrepareSignerListItem({ signer, index }) {
  const dispatch = useDispatch();
  const { selectedSigner } = useSelector((state) => state.annot);
  const { transaction } = useSelector((state) => state.transaction);
  const { editMode } = useSelector((state) => state.annot);

  function handleSelectSigner() {
    dispatch(changeEditMode("signer"));
    dispatch(changeSelectedSigner(signer));
  }

  return (
    <>
      <div
        onClick={() => handleSelectSigner()}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Icon
          className="small right margin"
          name={
            selectedSigner.role === signer.role && editMode !== "agent"
              ? "square check outline"
              : "square outline"
          }
          size="large"
        />
        <div style={{ width: "20px", height: "15px" }}>
          <div
            className="annot-outer-wrapper"
            style={{
              backgroundColor: `rgba(${createAnnotColor(
                signer.role,
                transaction
              )}, 0.2)`,
            }}
          >
            <div
              className="annot-inner-wrapper"
              style={{
                boxShadow: `rgb(${createAnnotColor(
                  signer.role,
                  transaction
                )}) 0px 0px 0px 1px`,
                zIndex: 5,
              }}
            ></div>
          </div>
        </div>
        <h5
          className="zero top margin small left margin"
          style={{ color: "#666" }}
        >
          {signer.role}
          {signer.lastName && ": " + convertFullName(signer)}
        </h5>
      </div>
      <br />
    </>
  );
}
