export function exclusiveRighttoSellCB2024Aug() {
return [   //2024 Release 2024-08-08 08:22:57
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "SELLER AGENCY", 
   isText: false,
   type: "checkbox",
   top: 221.64,
   left: 169.32,
   width: 10.56,
   height: 10.56
}
,
{
   page: 0,
   name: "TRANSACTIONBROKERAGE", 
   isText: false,
   type: "checkbox",
   top: 221.64,
   left: 281.64,
   width: 10.56,
   height: 10.56
}
,
{
   page: 0,
   name: "Date contract", 
   fontSize: 9,
   type: "date",
   top: 248.56,
   left: 396.24,
   width: 154.92,
   height: 12.44
}
,
{
   page: 0,
   name: "sellers", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 481.36,
   left: 111.0,
   width: 455.04,
   height: 12.44
}
,
{
   page: 0,
   name: "brokerNames", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 515.68,
   left: 117.12,
   width: 450.00,
   height: 12.44
}
,
{
   page: 0,
   name: "county", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 532.96,
   left: 422.88,
   width: 144.96,
   height: 12.44
}
,
{
   page: 0,
   name: "legalDesc", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 559.639,
   left: 56.9457,
   width: 518.5103,
   height: 123.455
}
,
{
   page: 0,
   name: "fullAddress", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 682.48,
   left: 143.16,
   width: 420.00,
   height: 12.44
}
,
{
   page: 1,
   name: "part of an affordable housing program", 
   isText: false,
   type: "checkbox",
   top: 47.4,
   left: 78.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Lease means", 
   isText: false,
   type: "checkbox",
   top: 155.4,
   left: 97.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "contractlistbeginsdate", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 185.04,
   left: 384,
   width: 180,
   height: 11.64
}
,
{
   page: 1,
   name: "contractendsby", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 210.24,
   left: 92.4,
   width: 474.96,
   height: 11.64
}
,
{
   page: 1,
   name: "willExtendDeadline", 
   isText: false,
   type: "checkbox",
   top: 376.44,
   left: 341.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "willNot", 
   isText: false,
   type: "checkbox",
   top: 376.44,
   left: 386.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "sellerAgency", 
   isText: false,
   type: "checkbox",
   top: 651.24,
   left: 122.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "willDisclose", 
   isText: false,
   type: "checkbox",
   top: 516.36,
   left: 170.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Will Not disclose", 
   isText: false,
   type: "checkbox",
   top: 516.36,
   left: 218.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "of the gross purchase price or 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 65.36,
   left: 199.56,
   width: 54.96,
   height: 12.84
}
,
{
   page: 3,
   name: "in US dollars", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 65.36,
   left: 452.04,
   width: 95.04,
   height: 12.84
}
,
{
   page: 3,
   name: "of the gross purchase price or", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 132.48,
   left: 344.04,
   width: 45.12,
   height: 12.84
}
,
{
   page: 3,
   name: "in US dollars to buyers brokerage firm", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 146.16,
   left: 104.16,
   width: 64.92,
   height: 12.84
}
,
{
   page: 3,
   name: "undefined_10", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 201.36,
   left: 509.16,
   width: 50.04,
   height: 12.84
}
,
{
   page: 3,
   name: "in US dollars payable as follows", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 215.28,
   left: 254.4,
   width: 99.96,
   height: 12.84
}
,
{
   page: 3,
   name: "Brokerage Firm agrees to contribute from the", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 228.96,
   left: 92.28,
   width: 274.92,
   height: 12.84
}
,
{
   page: 3,
   name: "of the gross rent or", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 242.88,
   left: 345,
   width: 44.88,
   height: 12.84
}
,
{
   page: 3,
   name: "in", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 242.88,
   left: 488.76,
   width: 65.04,
   height: 12.84
}
,
{
   page: 3,
   name: "otherCompensation", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 299.129,
   left: 68.7276,
   width: 507.3834,
   height: 28.545
}
,
{
   page: 3,
   name: "calendar days after the Listing Period", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 382.56,
   left: 354.72,
   width: 45.00,
   height: 12.84
}
,
{
   page: 3,
   name: "Will_2", 
   isText: false,
   type: "checkbox",
   top: 702.12,
   left: 162.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Will Not be submitted to one or more MLS and", 
   isText: false,
   type: "checkbox",
   top: 702.12,
   left: 202.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Will_3", 
   isText: false,
   type: "checkbox",
   top: 717,
   left: 162.6,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Will Not be submitted to one or more property information exchanges", 
   isText: false,
   type: "checkbox",
   top: 717,
   left: 202.44,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "marketingExcept", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 113.891,
   left: 69.3821,
   width: 507.3829,
   height: 25.928
}
,
{
   page: 4,
   name: "Will_4", 
   isText: false,
   type: "checkbox",
   top: 153.48,
   left: 189.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Will Not be displayed on the Internet", 
   isText: false,
   type: "checkbox",
   top: 153.48,
   left: 231.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Will_5", 
   isText: false,
   type: "checkbox",
   top: 168.6,
   left: 189.48,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Will Not be displayed on the Internet_2", 
   isText: false,
   type: "checkbox",
   top: 168.6,
   left: 231.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Electronic Lock Box", 
   isText: false,
   type: "checkbox",
   top: 218.52,
   left: 122.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Manual Lock Box", 
   isText: false,
   type: "checkbox",
   top: 236.04,
   left: 122.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Check Box74", 
   isText: false,
   type: "checkbox",
   top: 252.965,
   left: 122.04,
   width: 10.08,
   height: 10.080
}
,
{
   page: 4,
   name: "undefined_11", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 249.72,
   left: 137.88,
   width: 430.08,
   height: 13.68
}
,
{
   page: 4,
   name: "undefined_12", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 267.24,
   left: 162.6,
   width: 405.0,
   height: 13.68
}
,
{
   page: 4,
   name: "Actively Licensed Real Estate Brokers", 
   isText: false,
   type: "checkbox",
   top: 317.64,
   left: 122.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Unlicensed Broker Assistants", 
   isText: false,
   type: "checkbox",
   top: 334.92,
   left: 122.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 352.44,
   left: 122.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Licensed Appraisers", 
   isText: false,
   type: "checkbox",
   top: 317.64,
   left: 302.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Unlicensed Inspectors", 
   isText: false,
   type: "checkbox",
   top: 334.92,
   left: 302.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "undefined_13", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 348.6,
   left: 165.96,
   width: 399.96,
   height: 13.68
}
,
{
   page: 4,
   name: "Text56", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 392.729,
   left: 67.4185,
   width: 511.3105,
   height: 217.710
}
,
{
   page: 4,
   name: "Text57", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 622.475,
   left: 70.0367,
   width: 506.0743,
   height: 46.219
}
,
{
   page: 5,
   name: "Is", 
   isText: false,
   type: "checkbox",
   top: 137.16,
   left: 338.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Is Not currently a party to any listing", 
   isText: false,
   type: "checkbox",
   top: 137.16,
   left: 367.32,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Has", 
   isText: false,
   type: "checkbox",
   top: 150.84,
   left: 437.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Has Not received", 
   isText: false,
   type: "checkbox",
   top: 150.84,
   left: 475.32,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "111 Price US", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 398.16,
   left: 135.72,
   width: 129.96,
   height: 12.36
}
,
{
   page: 5,
   name: "Cash", 
   isText: false,
   type: "checkbox",
   top: 420.12,
   left: 119.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Conventional", 
   isText: false,
   type: "checkbox",
   top: 420.12,
   left: 169.8,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "FHA", 
   isText: false,
   type: "checkbox",
   top: 420.12,
   left: 255.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "VA", 
   isText: false,
   type: "checkbox",
   top: 420.12,
   left: 306.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "undefined_14", 
   isText: false,
   type: "checkbox",
   top: 420.12,
   left: 349.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Other_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 417.6,
   left: 396.24,
   width: 170.04,
   height: 12.36
}
,
{
   page: 5,
   name: "113 Loan Discount Points", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 436.8,
   left: 177.48,
   width: 389.88,
   height: 12.36
}
,
{
   page: 5,
   name: "114 Buyers Closing Costs", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 456,
   left: 447.12,
   width: 120.00,
   height: 12.36
}
,
{
   page: 5,
   name: "that Buyer is not allowed", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 469.2,
   left: 317.04,
   width: 250.08,
   height: 12.36
}
,
{
   page: 5,
   name: "115 Earnest Money Minimum", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 488.4,
   left: 356.52,
   width: 174.96,
   height: 12.36
}
,
{
   page: 5,
   name: "in the form of", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 501.6,
   left: 135.96,
   width: 430.08,
   height: 12.36
}
,
{
   page: 5,
   name: "Cashiers Check at Sellers expense", 
   isText: false,
   type: "checkbox",
   top: 539.16,
   left: 78.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Funds Electronically Transferred", 
   isText: false,
   type: "checkbox",
   top: 556.92,
   left: 78.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "Closing Companys Trust Account Check", 
   isText: false,
   type: "checkbox",
   top: 574.92,
   left: 78.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 5,
   name: "IS a foreign person", 
   isText: false,
   type: "checkbox",
   top: 645.48,
   left: 300.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "garage door openers including", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 174.96,
   left: 328.32,
   width: 94.92,
   height: 12.36
}
,
{
   page: 6,
   name: "nones", 
   isText: false,
   type: "checkbox",
   top: 203.88,
   left: 92.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "solarPanels", 
   isText: false,
   type: "checkbox",
   top: 203.88,
   left: 139.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Water Softeners", 
   isText: false,
   type: "checkbox",
   top: 203.88,
   left: 218.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Security Systems", 
   isText: false,
   type: "checkbox",
   top: 203.88,
   left: 312.36,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "Satellite Systems including satellite", 
   isText: false,
   type: "checkbox",
   top: 203.88,
   left: 406.2,
   width: 10.08,
   height: 10.08
}
,
{
   page: 6,
   name: "otherInclusions", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 339.056,
   left: 67.4185,
   width: 504.7655,
   height: 184.328
}
,
{
   page: 6,
   name: "encomberedInclExcept", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 558.984,
   left: 64.8003,
   width: 510.0017,
   height: 68.473
}
,
{
   page: 6,
   name: "leasedItemsYes", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 685.967,
   left: 76.5822,
   width: 498.8738,
   height: 70.4363
}
,
{
   page: 7,
   name: "exclusionsfromcontract", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 100.8,
   left: 58.9093,
   width: 510.0017,
   height: 71.746
}
,
{
   page: 7,
   name: "tradeFixtures", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 184.583,
   left: 57.6003,
   width: 510.0017,
   height: 25.927
}
,
{
   page: 7,
   name: "undefined_15", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 234.48,
   left: 78.24,
   width: 489.96,
   height: 11.64
}
,
{
   page: 7,
   name: "undefined_16", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 276.48,
   left: 78.24,
   width: 484.92,
   height: 11.64
}
,
{
   page: 7,
   name: "undefined_17", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 301.68,
   left: 78.24,
   width: 489.96,
   height: 11.64
}
,
{
   page: 7,
   name: "1351", 
   isText: false,
   type: "checkbox",
   top: 336.84,
   left: 101.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 7,
   name: "deededWaterRights", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 347.565,
   left: 68.7276,
   width: 500.8374,
   height: 53.419
}
,
{
   page: 7,
   name: "Seller agrees to convey any deeded water rights by a good and sufficient", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 402.48,
   left: 361.8,
   width: 205.08,
   height: 11.64
}
,
{
   page: 7,
   name: "1352", 
   isText: false,
   type: "checkbox",
   top: 433.56,
   left: 101.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 7,
   name: "Text64", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 456.875,
   left: 70.0367,
   width: 499.5283,
   height: 42.291
}
,
{
   page: 7,
   name: "1353", 
   isText: false,
   type: "checkbox",
   top: 500.76,
   left: 101.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 7,
   name: "undefined_18", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 498.96,
   left: 258.48,
   width: 309.96,
   height: 11.64
}
,
{
   page: 7,
   name: "1354", 
   isText: false,
   type: "checkbox",
   top: 517.32,
   left: 101.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 7,
   name: "Text65", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 530.184,
   left: 70.6912,
   width: 501.4928,
   height: 38.364
}
,
{
   page: 7,
   name: "Text66", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 577.966,
   left: 58.2548,
   width: 515.8922,
   height: 82.873
}
,
{
   page: 8,
   name: "special warranty deed", 
   isText: false,
   type: "checkbox",
   top: 62.28,
   left: 78.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "quit claim deed", 
   isText: false,
   type: "checkbox",
   top: 62.28,
   left: 194.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "general warranty deed", 
   isText: false,
   type: "checkbox",
   top: 77.16,
   left: 78.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "personal representatives deed", 
   isText: false,
   type: "checkbox",
   top: 77.16,
   left: 194.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "bargain and sale deed", 
   isText: false,
   type: "checkbox",
   top: 92.04,
   left: 78.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "Check Box73", 
   isText: false,
   type: "checkbox",
   top: 92.909,
   left: 194.04,
   width: 10.08,
   height: 10.081
}
,
{
   page: 8,
   name: "deed", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 89.76,
   left: 210.12,
   width: 334.92,
   height: 12.12
}
,
{
   page: 8,
   name: "Text67", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 187.201,
   left: 77.2367,
   width: 496.9103,
   height: 29.200
}
,
{
   page: 8,
   name: "Text68", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 257.892,
   left: 58.2548,
   width: 517.2012,
   height: 40.327
}
,
{
   page: 8,
   name: "An Abstract of Title certified to a current", 
   isText: false,
   type: "checkbox",
   top: 324.6,
   left: 382.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "and that there are no unpaid regular or special assessments against the Property except the current regular assessments and", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 366.96,
   left: 214.2,
   width: 90.0,
   height: 12.12
}
,
{
   page: 8,
   name: "per", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 366.96,
   left: 322.08,
   width: 239.88,
   height: 12.12
}
,
{
   page: 8,
   name: "promptly request the owners association to deliver to buyer before date of closing a current statement of assessments against", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 393.36,
   left: 93.24,
   width: 375.00,
   height: 12.12
}
,
{
   page: 8,
   name: "subject to leases and tenancies as described in  14", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 452.16,
   left: 64.56,
   width: 495.00,
   height: 12.12
}
,
{
   page: 8,
   name: "Agrees", 
   isText: false,
   type: "checkbox",
   top: 610.92,
   left: 278.04,
   width: 10.08,
   height: 10.08
}
,
{
   page: 8,
   name: "Does Not Agree to provide on or before the sale", 
   isText: false,
   type: "checkbox",
   top: 610.92,
   left: 329.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "Check Box71", 
   isText: false,
   type: "checkbox",
   top: 365.311,
   left: 81.96,
   width: 10.08,
   height: 10.080
}
,
{
   page: 9,
   name: "Check Box72", 
   isText: false,
   type: "checkbox",
   top: 379.947,
   left: 81.96,
   width: 10.08,
   height: 10.080
}
,
{
   page: 9,
   name: "3", 
   isText: false,
   type: "checkbox",
   top: 406.68,
   left: 81.96,
   width: 10.08,
   height: 10.08
}
,
{
   page: 9,
   name: "undefined_19", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 403.68,
   left: 125.76,
   width: 440.04,
   height: 12.84
}
,
{
   page: 10,
   name: "additionalProv", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 304.365,
   left: 42.5457,
   width: 534.2203,
   height: 340.765
}
,
{
   page: 10,
   name: "attachments", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 660.439,
   left: 43.8547,
   width: 532.9113,
   height: 94.6551
}
,
{
   page: 11,
   name: "the electronic address of the recipient by facsimile email or", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 158.4,
   left: 320.04,
   width: 245.04,
   height: 11.88
}
,
{
   page: 11,
   name: "Brokerage Firm", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 409.44,
   left: 121.08,
   width: 390.00,
   height: 14.76
}
,
{
   page: 11,
   name: "signature brokerSigns", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 432.96,
   left: 49.44,
   width: 219.96,
   height: 14.76
}
,
{
   page: 11,
   name: "Date brokerSign", 
   fontSize: 9,
   type: "date",
   top: 432.96,
   left: 281.88,
   width: 120.00,
   height: 14.76
}
,
{
   page: 11,
   name: "Brokerage Firm Street Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 471.12,
   left: 193.44,
   width: 320.04,
   height: 14.76
}
,
{
   page: 11,
   name: "Brokerage Firm City State Zip 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 486.96,
   left: 193.44,
   width: 320.04,
   height: 14.76
}
,
{
   page: 11,
   name: "Brokerage Firm City State Zip 2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 502.8,
   left: 193.44,
   width: 320.04,
   height: 14.76
}
,
{
   page: 11,
   name: "Brokerage Firm City State Zip 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 518.64,
   left: 193.44,
   width: 320.04,
   height: 14.76
}
,
{
   page: 11,
   name: "Brokerage Firm City State Zip 4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 534.48,
   left: 193.44,
   width: 320.04,
   height: 14.76
}
,
{
   page: 11,
   name: "signature cobrokerSig", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 571.92,
   left: 49.44,
   width: 215.04,
   height: 14.76
}
,
{
   page: 11,
   name: "Date brokerSigns", 
   fontSize: 9,
   type: "date",
   top: 571.92,
   left: 276.96,
   width: 120.00,
   height: 14.76
}
,
{
   page: 11,
   name: "Seller", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 618.96,
   left: 77.4,
   width: 390.0,
   height: 14.76
}
,
{
   page: 11,
   name: "1_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 672.96,
   left: 157.44,
   width: 320.04,
   height: 14.76
}
,
{
   page: 11,
   name: "2_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 688.44,
   left: 157.44,
   width: 320.04,
   height: 14.16
}
,
{
   page: 11,
   name: "3_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 703.32,
   left: 157.44,
   width: 320.04,
   height: 13.68
}
,
{
   page: 11,
   name: "4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 717.72,
   left: 157.44,
   width: 320.04,
   height: 13.68
}
,
{
   page: 12,
   name: "Seller_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 42.84,
   left: 77.4,
   width: 390.0,
   height: 14.16
}
,
{
   page: 12,
   name: "1_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 96.84,
   left: 157.44,
   width: 320.04,
   height: 14.16
}
,
{
   page: 12,
   name: "2_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 111.72,
   left: 157.44,
   width: 320.04,
   height: 14.16
}
,
{
   page: 12,
   name: "3_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 126.6,
   left: 157.44,
   width: 320.04,
   height: 13.68
}
,
{
   page: 12,
   name: "4_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 141,
   left: 157.44,
   width: 320.04,
   height: 13.92
}
,
{
   page: 12,
   name: "Seller 3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 166.68,
   left: 87.96,
   width: 384.96,
   height: 14.16
}
,
{
   page: 12,
   name: "Seller 4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 231.24,
   left: 87.96,
   width: 384.96,
   height: 14.16
}
] }
