import React from "react";
import { Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";

export default function PaymentsDashboard() {
  const { currentUserProfile } = useSelector((state) => state.profile);

  // const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <div className="main-page-wrapper">
      <Grid centered stackable className="small bottom margin">
        <Grid.Column width={10}>
          <Header color="blue" as="h2">
            Payments
          </Header>

          <p color="grey">Subscription Payments for TransActioner.</p>
          <>
            <h2>Annual Plan</h2>
            <p color="grey">
              <a href="https://buy.stripe.com/7sI3cB7ZUg3L6TC4gh">
                Link to Pay for 12 months for $199 for 1 year
              </a>{" "}
            </p>
            <h2>Monthly Plan</h2>
            <p color="grey">
              {/* ?prefilled_promo_code=SUCCESS Use the coupon promo code "SUCCESS" for $49 off the $199/year. */}
              <a href="https://buy.stripe.com/3cs7sR2FAbNv3Hq3ce">
                Link to Pay monthly instead at $19/month
              </a>
              <br /> <br />
            </p>
          </>
          <Segment clearing>
            <Grid.Column width={4}>
              <div>
                <Header color="blue" as="h2">
                  Payment History
                </Header>
                <br />
                {!currentUserProfile?.payments && (
                  <>Your History of Payments is Coming Soon....</>
                )}
                <br />
                <Table compact>
                  <Table.Header className="mobile hidden">
                    <Table.Row
                      key="TableHeadingsPayments"
                      className="small-header-grey"
                    >
                      <Table.HeaderCell>Amount</Table.HeaderCell>
                      <Table.HeaderCell> Date Paid</Table.HeaderCell>
                      <Table.HeaderCell>Date Expires</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {currentUserProfile?.payments?.map((payMap) => (
                      <>
                        <Table.Row key={payMap.dateExpires}>
                          <Table.Cell>${payMap.amount}</Table.Cell>
                          <Table.Cell>
                            {payMap.datePayment?.toDate()?.toDateString() || ""}
                          </Table.Cell>
                          <Table.Cell>
                            {payMap.dateExpires?.toDate()?.toDateString() || ""}
                          </Table.Cell>
                        </Table.Row>
                      </>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </Grid.Column>
            <Divider hidden />
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}
