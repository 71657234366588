import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button } from "semantic-ui-react";
import {
  registerInFirebase,
  signInWithEmail,
} from "../../app/firestore/firebaseService";
import { useNavigate, useParams } from "react-router-dom";
import { functionClientLogin } from "../../app/firestore/functionsService";
import { addPartyUserToDb } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import MyCheckbox from "../../app/common/form/MyCheckbox";
import { useDispatch } from "react-redux";
import { openModal } from "../../app/common/modals/modalSlice";
import MyEmailInput from "../../app/common/form/MyEmailInput";

export default function RegisterFormClient() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { partyId } = useParams();

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          agreeTermsOfService: true,
          partyId: partyId,
        }}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const result = await functionClientLogin(values);
            if (result.currentUser) {
              try {
                await signInWithEmail(values);
                setSubmitting(false);
                navigate(`/party/${partyId}/documents`);
              } catch (error) {
                toast.error(error.message);
              }
            } else if (result.currentUser === false) {
              try {
                await registerInFirebase(values);
                addPartyUserToDb();
                setSubmitting(false);
                navigate(`/party/${partyId}/documents`);
              } catch (error) {
                toast.error(error.message);
              }
            } else {
              toast.error("Something went wrong");
            }
          } catch (error) {
            toast.error("Email does not match our records");
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <div className="large bottom margin">
              <MyCheckbox
                label="I agree to the Terms of Service"
                name="agreeTermsOfService"
                style={{ color: "dimgray" }}
              />
            </div>
            <MyEmailInput name="email" placeholder="Email address" />
            <MyTextInput
              name="password"
              placeholder="Password"
              type="password"
            />
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="blue"
              content="Get Started"
            />
          </Form>
        )}
      </Formik>
      <div className="small top margin text-center medium bottom margin">
        <p
          onClick={() =>
            dispatch(openModal({ modalType: "ResetPasswordForm" }))
          }
          style={{ color: "#4183c4", cursor: "pointer" }}
        >
          Forgot Password
        </p>
      </div>
    </>
  );
}
