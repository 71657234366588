export function sellersPropertyDisclosureLand() {
   return [
   {
      page: 0,
      type: "checkbox",
      name: "LAND Supplement to Residential", 
      top: 148.20,
      left: 196.2,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "LAND  With Improvements  Excluding Residence", 
      top: 173.28,
      left: 196.2,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "LAND  Without Improvements", 
      top: 198.36,
      left: 196.2,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Date", 
      top: 405.96,
      left: 96.4473,
      width: 452.9997,
      height: 15.72
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Property", 
      top: 423.96,
      left: 111.72,
      width: 437.40,
      height: 15.72
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Seller", 
      top: 441.96,
      left: 100.56,
      width: 448.56,
      height: 15.72
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Year Built", 
      top: 459.96,
      left: 119.16,
      width: 429.96,
      height: 15.72
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Year Seller Acquired Property", 
      top: 477.96,
      left: 199.2,
      width: 349.92,
      height: 15.72
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "If this box is checked there are no structures or improvements on the Property do not complete Parts AI", 
      top: 558.00,
      left: 75,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "A1 Check", 
      top: 624.712,
      left: 293.419,
      width: 17.597,
      height: 17.269
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "A1 Comment", 
      top: 624.997,
      left: 317.497,
      width: 256.387,
      height: 15.966
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "A2 Check", 
      top: 643.639,
      left: 293.419,
      width: 17.597,
      height: 17.924
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "A2 Comment", 
      top: 644.810,
      left: 317.497,
      width: 256.387,
      height: 17.432
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "A3 Check", 
      top: 666.639,
      left: 293.419,
      width: 17.597,
      height: 17.924
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "3 Damage due to termites other insects birds animals or rodents", 
      top: 664.004,
      left: 317.497,
      width: 256.387,
      height: 22.887
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "A4 Check", 
      top: 689.6380,
      left: 293.419,
      width: 17.597,
      height: 17.9241
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "A4", 
      top: 690.2930,
      left: 317.497,
      width: 256.387,
      height: 17.1197
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 8,
      type: "text",
      name: "5", 
      top: 719.40,
      left: 42.72,
      width: 24.36,
      height: 8.64
   }
   ,
   {
      page: 0,
      type: "checkbox",
      name: "A5 Check", 
      top: 709.6385,
      left: 293.419,
      width: 17.597,
      height: 17.9236
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "A5", 
      top: 709.9175,
      left: 317.497,
      width: 256.387,
      height: 17.4323
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "SPD19L622  SELLERS PROPERTY DISCLOSURE LAND", 
      top: 728.76,
      left: 36,
      width: 481.44,
      height: 15.72
   }
   ,
   {
      page: 0,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Page 1 of 9", 
      top: 728.76,
      left: 521.88,
      width: 55.68,
      height: 15.72
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "A6 Check", 
      top: 44.619,
      left: 292.787,
      width: 18.000,
      height: 19.155
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "a6 Comments", 
      top: 45.004,
      left: 316.727,
      width: 257.852,
      height: 16.764
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "a7 Check", 
      top: 62.789,
      left: 292.787,
      width: 18.000,
      height: 18.000
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "a7 Comments", 
      top: 64.252,
      left: 316.727,
      width: 258.179,
      height: 16.387
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "A8 Issue", 
      top: 83.561,
      left: 68.9468,
      width: 216.5132,
      height: 15.967
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "a8 Check", 
      top: 82.499,
      left: 292.787,
      width: 18.000,
      height: 18.000
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "a8 Comments", 
      top: 82.977,
      left: 316.727,
      width: 257.852,
      height: 16.258
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "A9 Issue", 
      top: 101.987,
      left: 68.9468,
      width: 216.5132,
      height: 16.294
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "a9 Check", 
      top: 101.823,
      left: 292.787,
      width: 18.000,
      height: 18.000
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "a9 Comments", 
      top: 101.606,
      left: 316.727,
      width: 258.179,
      height: 16.087
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "b1 Check", 
      top: 157.124,
      left: 295.018,
      width: 18.000,
      height: 16.537
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "B1 Comments", 
      top: 157.891,
      left: 318.112,
      width: 256.599,
      height: 13.818
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "B2 Check", 
      top: 174.312,
      left: 295.018,
      width: 18.000,
      height: 16.364
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "B2 Comments", 
      top: 174.522,
      left: 318.112,
      width: 256.927,
      height: 16.058
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "B3 Check", 
      top: 191.386,
      left: 295.018,
      width: 18.000,
      height: 18.000
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "B3 Comments", 
      top: 193.196,
      left: 318.112,
      width: 256.272,
      height: 16.309
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "B4 Check", 
      top: 209.747,
      left: 295.018,
      width: 18.000,
      height: 17.019
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "B4 Comments", 
      top: 211.493,
      left: 318.112,
      width: 256.272,
      height: 15.106
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "B5 Check", 
      top: 227.685,
      left: 295.018,
      width: 18.000,
      height: 15.881
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "B5 Comments", 
      top: 228.761,
      left: 318.112,
      width: 256.599,
      height: 14.265
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "B6 ISSUE", 
      top: 245.153,
      left: 68.5413,
      width: 219.0107,
      height: 15.169
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "B6 Check", 
      top: 244.546,
      left: 295.018,
      width: 18.000,
      height: 15.709
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "B6 Comments", 
      top: 245.391,
      left: 318.112,
      width: 250.709,
      height: 14.714
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "B7 ISSUE", 
      top: 262.247,
      left: 68.5413,
      width: 219.3387,
      height: 14.451
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "B7 Check", 
      top: 261.910,
      left: 295.018,
      width: 18.000,
      height: 15.381
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "B7 Comments", 
      top: 261.974,
      left: 318.112,
      width: 251.036,
      height: 14.920
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "ROOF  Other Information Do you know of the following on the Property", 
      top: 280.44,
      left: 244.036,
      width: 325.364,
      height: 24.48
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_3", 
      top: 306.425,
      left: 169.08,
      width: 115.571,
      height: 10.255
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "undefined_4", 
      top: 320.04,
      left: 125.52,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "undefined_5", 
      top: 320.04,
      left: 159.6,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "B8 Check", 
      top: 310.403,
      left: 293.595,
      width: 18.000,
      height: 18.000
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "B8 Comments", 
      top: 307.102,
      left: 317.535,
      width: 252.287,
      height: 23.622
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "B9 Check", 
      top: 331.805,
      left: 293.595,
      width: 18.000,
      height: 14.727
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "B9 Comments", 
      top: 332.777,
      left: 317.535,
      width: 252.287,
      height: 13.860
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_6", 
      top: 349.20,
      left: 127.56,
      width: 47.04,
      height: 10.08
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_7", 
      top: 348.96,
      left: 196.32,
      width: 55.68,
      height: 10.08
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "B10 Check", 
      top: 347.823,
      left: 293.595,
      width: 18.000,
      height: 15.709
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "B10 Comments", 
      top: 348.531,
      left: 317.535,
      width: 252.287,
      height: 12.672
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "B11 ISSUES", 
      top: 364.949,
      left: 68.961,
      width: 219.744,
      height: 12.330
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "B11 CHECK", 
      top: 362.579,
      left: 293.595,
      width: 18.000,
      height: 16.036
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "b11 COMMENT", 
      top: 363.796,
      left: 317.535,
      width: 252.287,
      height: 13.981
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c1 check", 
      top: 427.238,
      left: 291.085,
      width: 18.000,
      height: 16.036
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c1 age", 
      top: 428.742,
      left: 316.335,
      width: 37.951,
      height: 13.108
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c1 comment", 
      top: 428.548,
      left: 357.426,
      width: 215.896,
      height: 13.704
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c2 check", 
      top: 442.771,
      left: 291.085,
      width: 18.000,
      height: 15.382
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c2 age", 
      top: 443.387,
      left: 316.335,
      width: 37.951,
      height: 13.704
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c2 comment", 
      top: 444.156,
      left: 357.426,
      width: 215.896,
      height: 12.550
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c3 check", 
      top: 458.380,
      left: 291.085,
      width: 18.000,
      height: 14.727
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c3 age", 
      top: 459.264,
      left: 316.335,
      width: 37.951,
      height: 14.970
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c3 comment", 
      top: 459.619,
      left: 357.426,
      width: 215.896,
      height: 12.709
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 11,
      type: "text",
      name: "c4 iSSUE", 
      top: 475.328,
      left: 68.9468,
      width: 211.6462,
      height: 11.847
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c4 check", 
      top: 473.754,
      left: 291.085,
      width: 18.000,
      height: 15.055
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c4 age", 
      top: 474.734,
      left: 316.335,
      width: 37.951,
      height: 13.400
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c4 comment", 
      top: 474.829,
      left: 357.426,
      width: 215.896,
      height: 13.070
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c5 issue", 
      top: 490.928,
      left: 68.9468,
      width: 211.6462,
      height: 12.736
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c5 check", 
      top: 489.435,
      left: 291.085,
      width: 18.000,
      height: 14.400
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c5 age", 
      top: 489.962,
      left: 316.335,
      width: 37.951,
      height: 13.353
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c5 comment", 
      top: 490.536,
      left: 357.426,
      width: 215.896,
      height: 13.295
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "ELECTRICAL  TELECOMMUNICATIONS If you know of any problems EVER EXISTING with the following check the Yes column", 
      top: 507.60,
      left: 269.76,
      width: 304.92,
      height: 36.12
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c6 check", 
      top: 528.031,
      left: 291.085,
      width: 18.000,
      height: 15.709
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c6 age", 
      top: 544.934,
      left: 316.489,
      width: 37.567,
      height: 13.435
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c6 comment", 
      top: 545.395,
      left: 357.829,
      width: 216.852,
      height: 12.723
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c7 check", 
      top: 545.718,
      left: 291.085,
      width: 18.000,
      height: 14.400
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c7 age", 
      top: 560.387,
      left: 316.489,
      width: 37.567,
      height: 13.436
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c7 comment", 
      top: 559.982,
      left: 357.829,
      width: 216.852,
      height: 13.877
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c8 check", 
      top: 559.151,
      left: 291.085,
      width: 18.000,
      height: 15.709
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c8 age", 
      top: 575.342,
      left: 316.489,
      width: 37.567,
      height: 13.435
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c8 comment", 
      top: 576.381,
      left: 357.829,
      width: 216.852,
      height: 12.839
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c9 issue", 
      top: 590.915,
      left: 68.9468,
      width: 212.8632,
      height: 14.294
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c9 check", 
      top: 573.893,
      left: 291.085,
      width: 18.000,
      height: 15.709
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c9 age", 
      top: 591.375,
      left: 316.489,
      width: 37.567,
      height: 13.436
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c9 comment", 
      top: 591.739,
      left: 357.829,
      width: 216.852,
      height: 13.107
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c10 issue", 
      top: 606.732,
      left: 69.3524,
      width: 211.6466,
      height: 13.483
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c10 check", 
      top: 605.922,
      left: 291.085,
      width: 18.000,
      height: 15.709
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c10 age", 
      top: 606.807,
      left: 316.489,
      width: 37.567,
      height: 13.436
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c10 comment", 
      top: 606.498,
      left: 357.829,
      width: 216.852,
      height: 14.148
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "ELECTRICAL  TELECOMMUNICATIONS  Other Information Do you know of the following on the Property", 
      top: 624.00,
      left: 263.28,
      width: 311.40,
      height: 36.12
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "undefined_10", 
      top: 664.20,
      left: 73.92,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "undefined_11", 
      top: 664.20,
      left: 148.44,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c11 check", 
      top: 659.363,
      left: 291.642,
      width: 18.000,
      height: 16.036
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c11 age", 
      top: 661.115,
      left: 316.011,
      width: 38.009,
      height: 14.205
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c11 comment", 
      top: 661.133,
      left: 357.523,
      width: 217.179,
      height: 13.762
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Amps", 
      top: 677.707,
      left: 165.878,
      width: 111.367,
      height: 10.644
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c12 check", 
      top: 676.246,
      left: 292.068,
      width: 18.000,
      height: 15.709
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c12 age", 
      top: 677.325,
      left: 316.011,
      width: 38.009,
      height: 12.896
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c12 comment", 
      top: 676.980,
      left: 357.523,
      width: 217.179,
      height: 13.107
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "undefined_14", 
      top: 693.1200,
      left: 141.48,
      width: 140.607,
      height: 9.7527
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c13 check", 
      top: 691.8640,
      left: 292.068,
      width: 18.000,
      height: 15.3819
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c13 age", 
      top: 692.3251,
      left: 316.011,
      width: 38.009,
      height: 13.5501
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c13 comment", 
      top: 691.9980,
      left: 357.523,
      width: 217.179,
      height: 14.0893
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c14 issues", 
      top: 707.7191,
      left: 68.1357,
      width: 214.0803,
      height: 12.6718
   }
   ,
   {
      page: 1,
      type: "checkbox",
      name: "c14 check", 
      top: 706.9639,
      left: 292.068,
      width: 18.000,
      height: 15.7091
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c14 age", 
      top: 707.4246,
      left: 316.011,
      width: 38.009,
      height: 13.5505
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "c14 comment", 
      top: 707.7519,
      left: 357.523,
      width: 217.179,
      height: 13.1079
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "SPD19L622  SELLERS PROPERTY DISCLOSURE LAND_2", 
      top: 722.88,
      left: 36.0,
      width: 481.56,
      height: 21.60
   }
   ,
   {
      page: 1,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "Page 2 of 9", 
      top: 722.88,
      left: 521.88,
      width: 55.68,
      height: 21.60
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "undefined_15", 
      top: 81.96,
      left: 147,
      width: 43.56,
      height: 9.96
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "d1 check", 
      top: 79.751,
      left: 290.794,
      width: 18.000,
      height: 15.054
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "D1 Age", 
      top: 80.917,
      left: 315.276,
      width: 38.850,
      height: 12.969
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "D1 Comments", 
      top: 80.993,
      left: 356.76,
      width: 217.814,
      height: 12.454
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "D2 Issue", 
      top: 96.035,
      left: 68.6563,
      width: 216.1287,
      height: 13.154
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "d2 check", 
      top: 95.751,
      left: 290.794,
      width: 18.000,
      height: 15.054
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "D2 Age", 
      top: 96.102,
      left: 315.187,
      width: 38.851,
      height: 13.625
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "D2 Comments", 
      top: 96.009,
      left: 356.76,
      width: 217.814,
      height: 13.763
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "d3 Issues", 
      top: 111.619,
      left: 68.6563,
      width: 214.8657,
      height: 12.782
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "d3 check", 
      top: 111.405,
      left: 290.794,
      width: 18.000,
      height: 14.727
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "D3 Age", 
      top: 111.121,
      left: 315.515,
      width: 38.523,
      height: 14.279
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "D3 Comments", 
      top: 112.242,
      left: 356.76,
      width: 217.814,
      height: 13.109
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E1 check", 
      top: 175.412,
      left: 289.576,
      width: 17.230,
      height: 15.151
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E1 Age", 
      top: 175.959,
      left: 310.886,
      width: 39.295,
      height: 14.269
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E1 comment", 
      top: 176.146,
      left: 352.149,
      width: 223.265,
      height: 14.262
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E2 check", 
      top: 191.406,
      left: 289.576,
      width: 17.230,
      height: 14.496
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E2 Age", 
      top: 191.647,
      left: 310.886,
      width: 39.295,
      height: 13.942
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E2 comment", 
      top: 191.905,
      left: 352.149,
      width: 223.265,
      height: 13.609
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E3 check", 
      top: 206.208,
      left: 289.576,
      width: 17.230,
      height: 16.461
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E3 Age", 
      top: 207.122,
      left: 310.886,
      width: 39.295,
      height: 14.269
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E3 comment", 
      top: 207.036,
      left: 352.149,
      width: 223.265,
      height: 14.262
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E4 check", 
      top: 220.881,
      left: 289.576,
      width: 17.230,
      height: 16.134
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E4 Age", 
      top: 222.122,
      left: 310.886,
      width: 39.295,
      height: 14.596
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "E4 comment", 
      top: 222.036,
      left: 352.149,
      width: 223.265,
      height: 16.226
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E5 check", 
      top: 237.363,
      left: 289.576,
      width: 17.230,
      height: 15.479
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E5 Age", 
      top: 238.295,
      left: 310.886,
      width: 39.295,
      height: 13.287
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E5 comment", 
      top: 238.536,
      left: 352.149,
      width: 223.265,
      height: 13.281
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E6 check", 
      top: 252.381,
      left: 289.576,
      width: 17.230,
      height: 14.825
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E6 Age", 
      top: 253.044,
      left: 310.886,
      width: 39.295,
      height: 13.942
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E6 comment", 
      top: 253.536,
      left: 352.149,
      width: 223.265,
      height: 13.281
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E7 check", 
      top: 267.890,
      left: 289.576,
      width: 17.230,
      height: 14.497
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E7 Age", 
      top: 268.677,
      left: 310.886,
      width: 39.295,
      height: 13.721
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E7 comment", 
      top: 268.642,
      left: 352.149,
      width: 223.265,
      height: 13.935
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E8issue", 
      top: 284.547,
      left: 68.9654,
      width: 213.9776,
      height: 12.499
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E8 check", 
      top: 284.884,
      left: 289.576,
      width: 17.230,
      height: 13.515
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E8 Age", 
      top: 283.852,
      left: 310.886,
      width: 39.295,
      height: 14.269
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E8 comment", 
      top: 284.075,
      left: 352.149,
      width: 223.265,
      height: 14.917
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "e9 issue", 
      top: 299.710,
      left: 69.0593,
      width: 214.3047,
      height: 13.763
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E9 check", 
      top: 300.014,
      left: 289.576,
      width: 17.230,
      height: 13.515
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E9 Age", 
      top: 299.310,
      left: 310.886,
      width: 39.295,
      height: 14.269
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E9 comment", 
      top: 299.532,
      left: 352.149,
      width: 223.265,
      height: 13.609
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "VENTILATION AIR  HEAT  Other Information Do you know of the following on the Property", 
      top: 321.6,
      left: 243.24,
      width: 331.68,
      height: 28.8
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "1", 
      top: 362.88,
      left: 93.96,
      width: 67.44,
      height: 9.96
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "1_2", 
      top: 362.88,
      left: 181.92,
      width: 99.84,
      height: 9.96
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "2_2", 
      top: 373.92,
      left: 93.96,
      width: 67.44,
      height: 9.96
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "2_3", 
      top: 363.96,
      left: 181.92,
      width: 99.84,
      height: 9.96
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E10 check", 
      top: 359.153,
      left: 289.179,
      width: 17.230,
      height: 16.460
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E10 Age", 
      top: 361.23,
      left: 311.165,
      width: 38.640,
      height: 12.96
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "E10 comment", 
      top: 351.339,
      left: 351.862,
      width: 223.489,
      height: 34.899
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "undefined_16", 
      top: 388.92,
      left: 149.88,
      width: 132.033,
      height: 9.36
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "undefined_17", 
      top: 399.36,
      left: 91.56,
      width: 104.302,
      height: 9.84
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E11 check", 
      top: 391.929,
      left: 289.179,
      width: 17.230,
      height: 16.461
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E11 Age", 
      top: 394.007,
      left: 311.165,
      width: 38.640,
      height: 12.960
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "E11 comment", 
      top: 388.043,
      left: 351.862,
      width: 223.489,
      height: 23.717
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "undefined_18", 
      top: 424.68,
      left: 129.48,
      width: 67.32,
      height: 9.96
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "undefined_19", 
      top: 426.48,
      left: 202.2,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E12 check", 
      top: 416.706,
      left: 289.179,
      width: 17.230,
      height: 16.460
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E12 Age", 
      top: 418.783,
      left: 311.165,
      width: 38.640,
      height: 12.960
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "textarea",
      name: "E12 comment", 
      top: 413.935,
      left: 351.862,
      width: 223.489,
      height: 23.063
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "undefined_20", 
      top: 441.60,
      left: 118.2,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "undefined_21", 
      top: 441.60,
      left: 163.44,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E13 check", 
      top: 437.572,
      left: 289.179,
      width: 17.230,
      height: 16.039
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E13 Age", 
      top: 440.649,
      left: 311.165,
      width: 38.640,
      height: 12.960
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E13 comment", 
      top: 424.023,
      left: 351.862,
      width: 223.489,
      height: 14.541
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "undefined_22", 
      top: 455.88,
      left: 129.48,
      width: 154.211,
      height: 9.96
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E14 check", 
      top: 454.312,
      left: 289.179,
      width: 17.230,
      height: 14.730
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E14 Age", 
      top: 456.179,
      left: 311.165,
      width: 38.640,
      height: 12.960
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E14 comment", 
      top: 439.943,
      left: 351.862,
      width: 223.489,
      height: 14.542
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E15 issues", 
      top: 471.489,
      left: 68.5776,
      width: 214.4444,
      height: 12.734
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "E15 check", 
      top: 470.401,
      left: 289.179,
      width: 17.230,
      height: 16.039
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "E15 Age", 
      top: 471.268,
      left: 311.165,
      width: 38.640,
      height: 12.960
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "E15 comment", 
      top: 471.334,
      left: 351.862,
      width: 223.489,
      height: 13.559
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "F1 check", 
      top: 535.312,
      left: 287.827,
      width: 17.230,
      height: 16.039
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "F1 Age", 
      top: 536.852,
      left: 311.122,
      width: 38.640,
      height: 12.960
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "1 Water heaters", 
      top: 522.971,
      left: 352.087,
      width: 223.313,
      height: 13.200
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "F2 check", 
      top: 550.520,
      left: 287.827,
      width: 17.230,
      height: 16.039
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "F2 Age", 
      top: 539.10,
      left: 311.122,
      width: 38.640,
      height: 12.96
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "2 Water filter system", 
      top: 538.209,
      left: 352.087,
      width: 223.313,
      height: 13.261
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "F3 check", 
      top: 566.520,
      left: 287.827,
      width: 17.230,
      height: 16.039
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "F3 Age", 
      top: 555.10,
      left: 311.122,
      width: 38.640,
      height: 12.96
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "3 Water softener", 
      top: 549.824,
      left: 352.087,
      width: 223.313,
      height: 15.788
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "F4 check", 
      top: 581.520,
      left: 287.827,
      width: 17.230,
      height: 16.039
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "F4 Age", 
      top: 570.10,
      left: 311.122,
      width: 38.640,
      height: 12.96
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "4 Water system pump", 
      top: 567.874,
      left: 352.087,
      width: 223.313,
      height: 14.503
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "f5 issues", 
      top: 583.666,
      left: 68.5287,
      width: 213.3793,
      height: 14.078
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "F5 check", 
      top: 597.520,
      left: 287.827,
      width: 17.230,
      height: 16.039
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "F5 Age", 
      top: 586.10,
      left: 311.122,
      width: 38.640,
      height: 12.96
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "5_3", 
      top: 583.662,
      left: 352.087,
      width: 223.313,
      height: 14.289
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "f6 issues", 
      top: 613.193,
      left: 68.5287,
      width: 213.3793,
      height: 14.078
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "F6 check", 
      top: 612.941,
      left: 287.827,
      width: 17.230,
      height: 15.618
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "F6 Age", 
      top: 600.773,
      left: 311.122,
      width: 38.640,
      height: 12.960
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "6", 
      top: 613.496,
      left: 352.087,
      width: 223.313,
      height: 14.104
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "WATER If you know of any problems EVER EXISTING with the following check the Yes column", 
      top: 630.84,
      left: 269.76,
      width: 304.92,
      height: 36.24
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "F7 check", 
      top: 667.520,
      left: 288.827,
      width: 17.230,
      height: 15.057
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "F7 Age", 
      top: 654.791,
      left: 311.158,
      width: 38.640,
      height: 12.960
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "7 Water system including lines and water pressure", 
      top: 668.476,
      left: 352.267,
      width: 222.157,
      height: 13.637
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "F8  check", 
      top: 683.5200,
      left: 288.827,
      width: 17.230,
      height: 13.7484
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "F8 Age", 
      top: 671.1002,
      left: 311.158,
      width: 38.640,
      height: 12.9598
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "8 Well", 
      top: 671.12,
      left: 352.267,
      width: 222.157,
      height: 12.84
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "F9 issues", 
      top: 699.6201,
      left: 69.0775,
      width: 212.7595,
      height: 12.7335
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "F9 check", 
      top: 698.5202,
      left: 288.827,
      width: 17.230,
      height: 14.7300
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "F9 Age", 
      top: 686.7723,
      left: 311.158,
      width: 38.640,
      height: 12.9601
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "9_4", 
      top: 686.36,
      left: 352.267,
      width: 222.157,
      height: 12.96
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "f10 issues", 
      top: 714.7835,
      left: 69.0775,
      width: 212.7595,
      height: 13.1547
   }
   ,
   {
      page: 2,
      type: "checkbox",
      name: "f10  check", 
      top: 713.5202,
      left: 288.827,
      width: 17.230,
      height: 14.7300
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "f10  Age", 
      top: 714.4052,
      left: 311.158,
      width: 38.640,
      height: 12.9600
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "10_2", 
      top: 698.9934,
      left: 352.267,
      width: 222.157,
      height: 14.3854
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "SPD19L622  SELLERS PROPERTY DISCLOSURE LAND_3", 
      top: 729.36,
      left: 36,
      width: 481.56,
      height: 15.12
   }
   ,
   {
      page: 2,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Page 3 of 9", 
      top: 729.36,
      left: 521.88,
      width: 55.68,
      height: 15.12
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "WATER  Other Information Do you know of the following on the Property", 
      top: 46.08,
      left: 284.64,
      width: 24.96,
      height: 24.48
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 9,
      type: "text",
      name: "undefined_24", 
      top: 72.72,
      left: 165.6,
      width: 67.56,
      height: 9.36
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_25", 
      top: 83.64,
      left: 109.2,
      width: 67.44,
      height: 10.20
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_26", 
      top: 83.64,
      left: 213.12,
      width: 66.785,
      height: 10.20
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box11", 
      top: 75.635,
      left: 287.165,
      width: 18.000,
      height: 16.076
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "11 age", 
      top: 78.197,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "11 Water heater Number of Fuel type Capacity", 
      top: 71.891,
      left: 352.022,
      width: 223.274,
      height: 23.760
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "undefined_27", 
      top: 101.16,
      left: 147.6,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "undefined_28", 
      top: 101.16,
      left: 192.96,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box12", 
      top: 97.635,
      left: 287.165,
      width: 18.000,
      height: 14.767
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "12 age", 
      top: 98.197,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "12 Water filter system Owned Leased", 
      top: 98.498,
      left: 352.022,
      width: 223.274,
      height: 13.560
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "undefined_30", 
      top: 117.12,
      left: 131.88,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "undefined_31", 
      top: 117.12,
      left: 177.24,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box13", 
      top: 113.635,
      left: 287.165,
      width: 18.000,
      height: 15.094
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "13 age", 
      top: 113.197,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "13 Water softener Owned Leased", 
      top: 114.251,
      left: 352.022,
      width: 223.274,
      height: 13.560
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_32", 
      top: 131.28,
      left: 190.56,
      width: 91.353,
      height: 10.08
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box14", 
      top: 129.635,
      left: 287.165,
      width: 18.000,
      height: 15.094
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "14 age", 
      top: 130.197,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "14 Master Water Shutoff Location", 
      top: 130.658,
      left: 352.022,
      width: 223.274,
      height: 12.840
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box15", 
      top: 145.635,
      left: 287.165,
      width: 18.000,
      height: 14.112
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "15 age", 
      top: 146.197,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "15 Well metered", 
      top: 146.00,
      left: 352.022,
      width: 223.274,
      height: 12.84
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Date of last inspection", 
      top: 174.0,
      left: 156.12,
      width: 67.56,
      height: 10.2
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Date of last service", 
      top: 186.0,
      left: 144.6,
      width: 67.44,
      height: 10.2
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box16", 
      top: 169.635,
      left: 287.492,
      width: 18.000,
      height: 16.076
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "16 age", 
      top: 172.197,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "16 Well Pump Date of last inspection Date of last service", 
      top: 162.08,
      left: 352.022,
      width: 223.274,
      height: 36.24
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box17", 
      top: 199.617,
      left: 287.492,
      width: 18.000,
      height: 15.094
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "17 age", 
      top: 200.197,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "17 Galvanized pipe", 
      top: 200.84,
      left: 352.022,
      width: 223.274,
      height: 12.84
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box18", 
      top: 214.635,
      left: 287.492,
      width: 18.000,
      height: 16.076
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "18 age", 
      top: 217.197,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "18 Polybutylene pipe", 
      top: 216.20,
      left: 352.022,
      width: 223.274,
      height: 12.84
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_33", 
      top: 232.2,
      left: 121.56,
      width: 26.88,
      height: 10.2
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_34", 
      top: 232.2,
      left: 172.44,
      width: 45.00,
      height: 10.2
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_35", 
      top: 244.2,
      left: 97.08,
      width: 69.851,
      height: 10.2
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box19", 
      top: 236.256,
      left: 287.492,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "19 age", 
      top: 238.818,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "19 Well Pump  GPM Date", 
      top: 231.92,
      left: 352.022,
      width: 223.274,
      height: 24.60
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_36", 
      top: 259.68,
      left: 151.92,
      width: 31.44,
      height: 10.08
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box20", 
      top: 257.583,
      left: 287.492,
      width: 18.000,
      height: 15.748
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "20 age", 
      top: 259.818,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "20 Cistern water storage gallons", 
      top: 259.04,
      left: 352.022,
      width: 223.274,
      height: 12.84
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box21", 
      top: 273.256,
      left: 287.492,
      width: 18.000,
      height: 15.093
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "21 age", 
      top: 274.818,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "21 Supplemental water purchased in past 2 years", 
      top: 274.40,
      left: 352.022,
      width: 223.274,
      height: 12.84
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "F22 Issues", 
      top: 289.167,
      left: 69.3209,
      width: 212.9831,
      height: 13.286
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check Box22", 
      top: 289.256,
      left: 287.492,
      width: 18.000,
      height: 15.093
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "22 age", 
      top: 289.818,
      left: 311.748,
      width: 38.437,
      height: 13.532
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "22", 
      top: 289.968,
      left: 352.022,
      width: 223.274,
      height: 12.960
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "G", 
      top: 330.24,
      left: 42.48,
      width: 24.72,
      height: 11.88
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "WATER SUPPLY Do you know of the following on the Property", 
      top: 319.80,
      left: 242.4,
      width: 332.52,
      height: 22.32
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "1_3", 
      top: 353.16,
      left: 43.44,
      width: 22.92,
      height: 23.88
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Type of water supply", 
      top: 345.48,
      left: 157.68,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Public", 
      top: 345.48,
      left: 197.76,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Community", 
      top: 345.48,
      left: 257.88,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Well", 
      top: 345.48,
      left: 292.44,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Shared Well", 
      top: 345.48,
      left: 354.24,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Other", 
      top: 345.48,
      left: 391.8,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "If the Property is served by a Well a copy of the Well Permit", 
      top: 355.80,
      left: 299.4,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Is", 
      top: 355.80,
      left: 323.4,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Is Not attached Well Permit", 
      top: 354.0,
      left: 455.76,
      width: 86.28,
      height: 10.2
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Drilling Records", 
      top: 366.24,
      left: 136.44,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Are", 
      top: 366.24,
      left: 169.2,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Are not attached Shared Well Agreement", 
      top: 366.24,
      left: 339.48,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Yes", 
      top: 366.24,
      left: 370.56,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH1", 
      top: 426.525,
      left: 291.804,
      width: 18.000,
      height: 15.748
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "1 Sewage system including sewer lines", 
      top: 428.36,
      left: 316.973,
      width: 258.427,
      height: 13.08
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH2", 
      top: 443.489,
      left: 291.804,
      width: 18.000,
      height: 14.111
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "2 Lift station sewage ejector pump", 
      top: 443.96,
      left: 316.973,
      width: 258.427,
      height: 12.96
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "H3 Issues", 
      top: 458.827,
      left: 68.1326,
      width: 216.1004,
      height: 13.561
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH3", 
      top: 457.539,
      left: 291.804,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "3_2", 
      top: 459.44,
      left: 316.973,
      width: 258.427,
      height: 12.84
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "H4 issues", 
      top: 474.759,
      left: 68.1326,
      width: 216.1004,
      height: 13.302
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH4", 
      top: 472.539,
      left: 291.804,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "4_2", 
      top: 474.80,
      left: 316.973,
      width: 258.427,
      height: 12.84
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "SEWER  Other Information Do you know of the following on the Property", 
      top: 491.52,
      left: 243.12,
      width: 331.92,
      height: 24.60
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Type of sanitary sewer service", 
      top: 520.20,
      left: 193.08,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Public_2", 
      top: 520.20,
      left: 235.44,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Community_2", 
      top: 532.20,
      left: 123.72,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Septic System", 
      top: 532.20,
      left: 194.28,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "None", 
      top: 532.20,
      left: 233.16,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "If the Property is served by an onsite septic system", 
      top: 542.4,
      left: 73.2,
      width: 206.64,
      height: 10.2
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Type of septic system", 
      top: 580.20,
      left: 159.6,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Tank", 
      top: 580.20,
      left: 195.24,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Leach", 
      top: 580.20,
      left: 234.36,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH5", 
      top: 546.621,
      left: 289.804,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "5 Type of sanitary sewer service Public Community Septic System None Other If the Property is served by an onsite septic system provide buyer with a copy of the permit Type of septic system Tank Leach Lagoon", 
      top: 518.36,
      left: 315.753,
      width: 259.486,
      height: 72.12
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_37", 
      top: 605.52,
      left: 73.2,
      width: 148.44,
      height: 10.20
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH6", 
      top: 595.621,
      left: 289.804,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "6 Sewer service provider", 
      top: 593.36,
      left: 315.753,
      width: 259.486,
      height: 24.48
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_38", 
      top: 621.0,
      left: 168.84,
      width: 67.44,
      height: 10.2
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH7", 
      top: 618.981,
      left: 289.804,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "7 Sewer line scoped Date", 
      top: 620.36,
      left: 315.753,
      width: 259.486,
      height: 12.96
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_39", 
      top: 648.36,
      left: 100.44,
      width: 85.44,
      height: 10.20
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH8", 
      top: 639.981,
      left: 289.804,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "8 If a septic system date of latest Individual Use Permit issued", 
      top: 636.20,
      left: 315.753,
      width: 259.486,
      height: 24.48
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_40", 
      top: 675.72,
      left: 73.2,
      width: 81.0,
      height: 10.20
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH9", 
      top: 666.501,
      left: 289.804,
      width: 18.000,
      height: 16.076
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "9 If a septic system date of latest inspection", 
      top: 663.56,
      left: 315.753,
      width: 259.486,
      height: 24.48
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_41", 
      top: 703.2,
      left: 73.2,
      width: 85.56,
      height: 10.2
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH10", 
      top: 694.5014,
      left: 289.804,
      width: 18.000,
      height: 16.0754
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "10 If a septic system date of latest pumping", 
      top: 690.92,
      left: 315.753,
      width: 259.486,
      height: 24.60
   }
   ,
   {
      page: 3,
      type: "checkbox",
      name: "Check BoxH11", 
      top: 716.5014,
      left: 289.804,
      width: 18.000,
      height: 16.0754
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Gray water storageuse", 
      top: 718.2478,
      left: 315.753,
      width: 259.486,
      height: 12.0477
   }
   ,
   {
      page: 3,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "11", 
      top: 732.60,
      left: 34.56,
      width: 543.00,
      height: 11.88
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "12 issues", 
      top: 44.532,
      left: 68.28,
      width: 216.72,
      height: 12.840
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "H12 Comments", 
      top: 44.951,
      left: 314.915,
      width: 259.931,
      height: 13.096
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxI1", 
      top: 109.324,
      left: 288.804,
      width: 18.000,
      height: 16.076
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "1 Included fixtures and equipment", 
      top: 110.48,
      left: 313.123,
      width: 254.971,
      height: 13.08
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "2 Stains on carpet", 
      top: 126.08,
      left: 313.123,
      width: 254.971,
      height: 12.84
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxI3", 
      top: 140.324,
      left: 288.804,
      width: 18.000,
      height: 16.076
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "3 Floors", 
      top: 141.44,
      left: 313.123,
      width: 254.971,
      height: 12.96
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "H4 Issues", 
      top: 156.279,
      left: 68.5287,
      width: 213.7753,
      height: 14.078
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxI4", 
      top: 156.324,
      left: 288.804,
      width: 18.000,
      height: 16.076
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "4_3", 
      top: 156.92,
      left: 313.123,
      width: 254.971,
      height: 12.84
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "5_4", 
      top: 172.28,
      left: 68.16,
      width: 214.20,
      height: 12.84
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxI5", 
      top: 171.324,
      left: 288.804,
      width: 18.000,
      height: 16.076
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "I5 Comments", 
      top: 171.520,
      left: 313.123,
      width: 254.971,
      height: 13.681
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxJ1", 
      top: 235.368,
      left: 291.804,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "1 Flooding or drainage", 
      top: 236.96,
      left: 315.389,
      width: 260.011,
      height: 13.08
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "J2 Issues", 
      top: 252.328,
      left: 68.5287,
      width: 216.2033,
      height: 12.493
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxJ2", 
      top: 251.368,
      left: 291.804,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "2_4", 
      top: 252.56,
      left: 315.389,
      width: 260.011,
      height: 12.96
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "J3 Issues", 
      top: 267.777,
      left: 68.9248,
      width: 215.7562,
      height: 12.889
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxJ3", 
      top: 266.368,
      left: 291.804,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "3_3", 
      top: 268.04,
      left: 315.389,
      width: 260.011,
      height: 12.84
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "DRAINAGE AND RETENTION PONDS  Other Information Do you know of the following on the Property", 
      top: 285.12,
      left: 264.84,
      width: 309.84,
      height: 36.12
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxJ4", 
      top: 321.396,
      left: 291.804,
      width: 18.000,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "4 Drainage retention ponds", 
      top: 322.76,
      left: 315.297,
      width: 258.933,
      height: 12.96
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "5_5", 
      top: 338.24,
      left: 68.4,
      width: 216.6,
      height: 12.84
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "J5 Comments", 
      top: 337.098,
      left: 315.311,
      width: 258.933,
      height: 14.077
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxK1", 
      top: 458.854,
      left: 303.002,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "1 Zoning violation variance conditional use violation of an enforceable PUD or nonconforming use", 
      top: 455.567,
      left: 327.195,
      width: 241.107,
      height: 22.680
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxK2", 
      top: 478.854,
      left: 303.002,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "2 Notice or threat of condemnation proceedings", 
      top: 480.767,
      left: 327.195,
      width: 241.107,
      height: 12.960
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "3 Notice of any adverse conditions from any governmental or quasigovernmental agency that have not been resolved", 
      top: 496.28,
      left: 327.195,
      width: 241.107,
      height: 22.44
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxK4", 
      top: 519.854,
      left: 303.002,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "4 Notice of zoning action related to the Property", 
      top: 521.24,
      left: 327.195,
      width: 241.107,
      height: 12.96
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxK5", 
      top: 535.854,
      left: 303.002,
      width: 17.604,
      height: 15.420
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "5 Building code city or county violations", 
      top: 536.72,
      left: 327.195,
      width: 241.107,
      height: 12.84
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxK6", 
      top: 555.854,
      left: 303.002,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "6 Violation of restrictive covenants or owners association rules or regulations", 
      top: 552.44,
      left: 327.195,
      width: 241.107,
      height: 22.56
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check BoxK7", 
      top: 588.854,
      left: 303.002,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "7 Any building or improvements constructed within the past one year before this Date without approval by the owners association or its designated approving body", 
      top: 577.807,
      left: 327.195,
      width: 241.107,
      height: 33.553
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check Boxk8", 
      top: 611.854,
      left: 303.002,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "8 Any additions or alterations made with a Building Permit", 
      top: 613.88,
      left: 327.195,
      width: 241.107,
      height: 12.84
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check Boxk9", 
      top: 633.854,
      left: 303.002,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "9 Any additions or nonaesthetic alterations made without a Building Permit", 
      top: 629.60,
      left: 327.195,
      width: 241.107,
      height: 22.56
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check Boxk10", 
      top: 652.854,
      left: 303.002,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "10 Notice of ADA complaint or report", 
      top: 654.68,
      left: 327.195,
      width: 241.107,
      height: 12.84
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check Boxk11", 
      top: 668.691,
      left: 303.002,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "11 Other legal action", 
      top: 670.04,
      left: 327.195,
      width: 241.107,
      height: 12.96
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check Boxk12", 
      top: 684.5680,
      left: 303.002,
      width: 17.604,
      height: 14.4387
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "12 Any part of the Property leased to others written or oral", 
      top: 685.52,
      left: 327.195,
      width: 241.107,
      height: 12.84
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check Boxk13", 
      top: 699.8279,
      left: 303.002,
      width: 17.604,
      height: 15.0937
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "13 Archeological or historical designation on the Property", 
      top: 700.88,
      left: 327.195,
      width: 241.107,
      height: 12.84
   }
   ,
   {
      page: 4,
      type: "checkbox",
      name: "Check Boxk14", 
      top: 715.5514,
      left: 303.002,
      width: 17.604,
      height: 15.0936
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "k14 Comments", 
      top: 716.5803,
      left: 327.195,
      width: 241.107,
      height: 12.0970
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "SPD19L622  SELLERS PROPERTY DISCLOSURE LAND_4", 
      top: 730.92,
      left: 36,
      width: 481.56,
      height: 13.56
   }
   ,
   {
      page: 4,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Page 5 of 9", 
      top: 730.92,
      left: 521.88,
      width: 55.68,
      height: 13.56
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkK15", 
      top: 43.062,
      left: 304.529,
      width: 17.604,
      height: 15.093
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "15 Grandfathered conditions or uses", 
      top: 44.324,
      left: 326.946,
      width: 241.684,
      height: 13.236
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "K16 Issue", 
      top: 59.814,
      left: 68.9248,
      width: 228.0352,
      height: 12.493
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkK16", 
      top: 58.925,
      left: 304.529,
      width: 17.604,
      height: 14.765
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "16", 
      top: 59.84,
      left: 326.946,
      width: 241.684,
      height: 13.20
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "K17 Issues", 
      top: 75.263,
      left: 68.5287,
      width: 228.0353,
      height: 12.889
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkK17", 
      top: 74.751,
      left: 304.529,
      width: 17.604,
      height: 14.767
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "17", 
      top: 75.56,
      left: 326.946,
      width: 241.684,
      height: 12.84
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkL1", 
      top: 138.682,
      left: 303.426,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "1 Any access problems issues or concerns", 
      top: 139.52,
      left: 327.066,
      width: 247.698,
      height: 13.20
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkL2", 
      top: 159.831,
      left: 303.426,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "2 Roads trails paths or driveways through the Property used by others", 
      top: 155.60,
      left: 327.066,
      width: 247.698,
      height: 24.48
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxL3", 
      top: 180.981,
      left: 303.426,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "3 Public highway or county road bordering the Property", 
      top: 182.60,
      left: 327.066,
      width: 247.698,
      height: 12.84
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkL4", 
      top: 201.509,
      left: 303.426,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "4 Any proposed or existing transportation project that affects or is expected to affect the Property", 
      top: 198.32,
      left: 327.066,
      width: 247.698,
      height: 22.56
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkL5", 
      top: 226.620,
      left: 303.426,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "5 Encroachments boundary disputes or unrecorded easements", 
      top: 223.76,
      left: 327.066,
      width: 247.698,
      height: 24.48
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkL6", 
      top: 249.871,
      left: 303.426,
      width: 17.604,
      height: 14.767
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "6 Shared or common areas with adjoining properties", 
      top: 250.76,
      left: 327.066,
      width: 247.698,
      height: 12.96
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxL7", 
      top: 265.770,
      left: 303.426,
      width: 17.604,
      height: 14.766
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "7 Requirements for curb gravelpaving landscaping", 
      top: 266.24,
      left: 327.066,
      width: 247.698,
      height: 12.84
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxL8", 
      top: 286.296,
      left: 303.426,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "8 Any limitations on parking or access due to size number of vehicles or type of vehicles in the past year", 
      top: 281.96,
      left: 327.066,
      width: 247.698,
      height: 24.48
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "L9 Issues", 
      top: 308.577,
      left: 69.717,
      width: 227.243,
      height: 14.474
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkL9", 
      top: 308.186,
      left: 303.426,
      width: 17.604,
      height: 14.767
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "9_5", 
      top: 308.96,
      left: 327.066,
      width: 247.698,
      height: 12.96
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "L10 Issues", 
      top: 324.422,
      left: 69.717,
      width: 226.451,
      height: 12.493
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkL10", 
      top: 323.273,
      left: 303.426,
      width: 17.604,
      height: 14.767
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "10_3", 
      top: 324.44,
      left: 327.066,
      width: 247.698,
      height: 12.84
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM1", 
      top: 405.046,
      left: 301.344,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "1 Hazardous materials on the Property such as radioactive toxic or biohazardous materials asbestos pesticides herbicides wastewater sludge radon methane mill tailings solvents or petroleum products", 
      top: 389.24,
      left: 326.277,
      width: 247.867,
      height: 44.40
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxkM2", 
      top: 435.169,
      left: 301.344,
      width: 17.604,
      height: 15.024
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "2 Underground storage tanks", 
      top: 436.16,
      left: 326.277,
      width: 247.867,
      height: 12.84
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM3", 
      top: 450.514,
      left: 301.344,
      width: 17.604,
      height: 14.766
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "3 Aboveground storage tanks", 
      top: 451.52,
      left: 326.277,
      width: 247.867,
      height: 12.84
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM4", 
      top: 465.256,
      left: 301.344,
      width: 17.604,
      height: 15.421
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "4 Underground transmission lines", 
      top: 466.88,
      left: 326.277,
      width: 247.867,
      height: 12.96
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM5", 
      top: 484.735,
      left: 301.344,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "5 Property used as situated on or adjoining a dump landfill or municipal solid waste landfill", 
      top: 482.72,
      left: 326.277,
      width: 247.867,
      height: 22.44
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM6", 
      top: 507.004,
      left: 301.344,
      width: 17.604,
      height: 14.766
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "6 Monitoring wells or test equipment", 
      top: 507.68,
      left: 326.277,
      width: 247.867,
      height: 12.96
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM7", 
      top: 527.430,
      left: 301.344,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "7 Sliding settling upheaval movement or instability of earth or expansive soils on the Property", 
      top: 523.52,
      left: 326.277,
      width: 247.867,
      height: 22.44
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM8", 
      top: 547.185,
      left: 301.344,
      width: 17.604,
      height: 15.420
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "8 Mine shafts tunnels or abandoned wells on the Property", 
      top: 548.480,
      left: 326.277,
      width: 247.867,
      height: 12.564
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM9", 
      top: 567.283,
      left: 301.344,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "9 Within a governmentally designated geological hazard or sensitive area", 
      top: 564.32,
      left: 326.277,
      width: 247.867,
      height: 22.44
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM10", 
      top: 592.876,
      left: 301.344,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "10 Within a governmentally designated floodplain or wetland area", 
      top: 589.64,
      left: 326.277,
      width: 247.867,
      height: 22.56
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM11", 
      top: 613.431,
      left: 301.344,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "11 Dead diseased or infested trees or shrubs", 
      top: 614.72,
      left: 326.277,
      width: 247.867,
      height: 12.84
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM12", 
      top: 633.392,
      left: 301.344,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "12 Environmental assessments studies or reports done involving the physical condition of the Property", 
      top: 630.44,
      left: 326.277,
      width: 247.867,
      height: 22.56
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM13", 
      top: 658.503,
      left: 301.344,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "13 Used for any mining graveling or other natural resource extraction operations such as oil and gas wells", 
      top: 655.88,
      left: 326.277,
      width: 247.867,
      height: 22.56
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM14", 
      top: 685.1980,
      left: 301.344,
      width: 17.604,
      height: 16.0756
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "14 Smoking inside improvements including garages unfinished space or detached buildings on Property", 
      top: 681.32,
      left: 326.277,
      width: 247.867,
      height: 24.48
   }
   ,
   {
      page: 5,
      type: "checkbox",
      name: "Check BoxM15", 
      top: 707.1400,
      left: 301.344,
      width: 17.604,
      height: 14.7664
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "15 Other environmental problems issues or concerns", 
      top: 708.32,
      left: 326.277,
      width: 247.867,
      height: 12.84
   }
   ,
   {
      page: 5,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Odors", 
      top: 723.68,
      left: 326.277,
      width: 247.867,
      height: 12.96
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "17_2 Issue", 
      top: 44.756,
      left: 68.6726,
      width: 225.4634,
      height: 13.262
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "17_2 Comment", 
      top: 44.72,
      left: 324.878,
      width: 249.702,
      height: 12.84
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "18 Issue", 
      top: 59.709,
      left: 68.6726,
      width: 225.4634,
      height: 13.261
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check 18", 
      top: 58.951,
      left: 302.199,
      width: 17.603,
      height: 15.421
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "18 Comment", 
      top: 59.84,
      left: 324.878,
      width: 249.702,
      height: 13.20
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check N1", 
      top: 134.119,
      left: 305.416,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "1 Property is part of an owners association", 
      top: 135.20,
      left: 329.463,
      width: 245.577,
      height: 13.08
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check N2", 
      top: 154.752,
      left: 305.416,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "2 Special assessments or increases in regular assessments approved by owners association but not yet implemented", 
      top: 151.16,
      left: 329.463,
      width: 245.577,
      height: 22.56
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check N3", 
      top: 180.440,
      left: 305.416,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "3 Problems or defects in the Common Elements or Limited Common Elements of the Association Property", 
      top: 176.60,
      left: 329.463,
      width: 245.577,
      height: 22.44
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "COMMON INTEREST COMMUNITY  ASSOCIATION PROPERTY If you know of any of the following EVER EXISTED check the Yes column", 
      top: 203.28,
      left: 273.84,
      width: 300.84,
      height: 48.24
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check N4", 
      top: 280.259,
      left: 305.399,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "4 Has the Association made demand or commenced a lawsuit against a builder or contractor alleging defective construction of improvements of the Association Property common area or property owned or controlled by the Association but outside the Sellers Property or unit", 
      top: 253.76,
      left: 330.234,
      width: 244.166,
      height: 60.12
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "5 issues", 
      top: 315.903,
      left: 68.6563,
      width: 229.6077,
      height: 13.155
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check N5", 
      top: 314.815,
      left: 305.399,
      width: 17.604,
      height: 14.767
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "5_6", 
      top: 316.40,
      left: 330.234,
      width: 244.166,
      height: 12.84
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "6 issues", 
      top: 331.703,
      left: 68.6563,
      width: 229.6077,
      height: 13.154
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check N6", 
      top: 331.018,
      left: 305.399,
      width: 17.604,
      height: 14.767
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "6_2", 
      top: 331.76,
      left: 330.234,
      width: 244.166,
      height: 12.96
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_42", 
      top: 387.00,
      left: 73.2,
      width: 221.913,
      height: 10.68
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check 7", 
      top: 390.621,
      left: 305.399,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "7 Owners Association 1", 
      top: 386.96,
      left: 331.389,
      width: 241.013,
      height: 23.52
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_43", 
      top: 413.40,
      left: 73.2,
      width: 221.913,
      height: 10.68
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "8 Owners Association 2", 
      top: 413.36,
      left: 331.389,
      width: 241.013,
      height: 23.52
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_44", 
      top: 439.80,
      left: 73.2,
      width: 221.913,
      height: 10.68
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "9 Owners Association 3", 
      top: 439.76,
      left: 331.389,
      width: 241.013,
      height: 23.52
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "10", 
      top: 475.80,
      left: 42.96,
      width: 23.88,
      height: 14.88
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Owners Association 4", 
      top: 476.88,
      left: 73.2,
      width: 221.913,
      height: 10.68
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "Owners Association 4_2", 
      top: 466.16,
      left: 331.389,
      width: 241.013,
      height: 23.52
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check O1", 
      top: 550.234,
      left: 299.462,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "1 Written reports of any building site roofing soils water sewer or engineering investigations or studies of the Property", 
      top: 546.527,
      left: 326.457,
      width: 251.443,
      height: 28.800
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check O2", 
      top: 581.397,
      left: 299.462,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "2 Any property insurance claim submitted whether paid or not", 
      top: 578.207,
      left: 326.457,
      width: 251.443,
      height: 24.480
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check O3", 
      top: 609.612,
      left: 299.462,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "3 Structural architectural and engineering plans andor specifications for any existing improvements", 
      top: 605.567,
      left: 326.457,
      width: 251.443,
      height: 22.560
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check O4", 
      top: 633.615,
      left: 299.462,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "4 Property was previously used as a methamphetamine laboratory and not remediated to state standards", 
      top: 630.68,
      left: 326.457,
      width: 251.443,
      height: 22.56
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check O5", 
      top: 659.724,
      left: 299.462,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "5 Government special improvements approved but not yet installed that may become a lien against the Property", 
      top: 656.12,
      left: 326.457,
      width: 251.443,
      height: 22.44
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check O6", 
      top: 683.7900,
      left: 299.462,
      width: 17.604,
      height: 16.0756
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "6 Pending 1 litigation or 2 other dispute resolution proceeding regarding the Property", 
      top: 681.44,
      left: 326.457,
      width: 251.443,
      height: 22.56
   }
   ,
   {
      page: 6,
      type: "checkbox",
      name: "Check O7", 
      top: 705.6928,
      left: 299.462,
      width: 17.604,
      height: 16.0754
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Signs Government or private restriction problems", 
      top: 706.52,
      left: 326.457,
      width: 251.443,
      height: 12.84
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "SPD19L622  SELLERS PROPERTY DISCLOSURE LAND_5", 
      top: 721.08,
      left: 36.0,
      width: 481.44,
      height: 23.40
   }
   ,
   {
      page: 6,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "Page 7 of 9", 
      top: 721.08,
      left: 521.88,
      width: 55.68,
      height: 23.40
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "8 Property is subject to Deed Restrictions other recorded document restrictions or Affordable Housing Restrictions", 
      top: 45.08,
      left: 324.532,
      width: 254.428,
      height: 24.48
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "9 issue", 
      top: 71.605,
      left: 69.2351,
      width: 224.1319,
      height: 13.527
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "9_6", 
      top: 71.84,
      left: 324.532,
      width: 254.428,
      height: 13.20
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "10 issues", 
      top: 87.212,
      left: 69.2351,
      width: 224.1319,
      height: 12.873
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "10_5", 
      top: 87.56,
      left: 324.532,
      width: 254.428,
      height: 12.84
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "GENERAL  Other Information", 
      top: 104.28,
      left: 201.6,
      width: 378.0,
      height: 24.48
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "11_2", 
      top: 130.28,
      left: 68.4,
      width: 225.204,
      height: 12.96
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "P", 
      top: 194.64,
      left: 42.72,
      width: 24.48,
      height: 12.48
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "CROPS LIVESTOCK  LEASES", 
      top: 194.64,
      left: 68.04,
      width: 210.84,
      height: 12.48
   }
   
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "11 comments", 
      top: 131.416,
      left: 325.17,
      width: 252.352,
      height: 11.048
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check P1", 
      top: 244.309,
      left: 284.35,
      width: 17.604,
      height: 14.766
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Comments", 
      top: 245.742,
      left: 310.402,
      width: 267.937,
      height: 11.498
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check P2", 
      top: 257.736,
      left: 284.35,
      width: 17.604,
      height: 16.076
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "2 Seller owns all crops", 
      top: 259.52,
      left: 310.402,
      width: 267.937,
      height: 11.52
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check P3", 
      top: 272.331,
      left: 284.35,
      width: 17.604,
      height: 14.111
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "3 Livestock on the Property", 
      top: 273.56,
      left: 310.402,
      width: 267.937,
      height: 11.16
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "undefined_47", 
      top: 289.08,
      left: 182.52,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "undefined_48", 
      top: 289.08,
      left: 217.68,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "undefined_49", 
      top: 300.12,
      left: 73.56,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "undefined_50", 
      top: 300.12,
      left: 117.6,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "undefined_51", 
      top: 300.12,
      left: 160.2,
      width: 8.76,
      height: 8.76
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "undefined_52", 
      top: 298.32,
      left: 194.76,
      width: 81.905,
      height: 10.20
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check P4", 
      top: 290.685,
      left: 284.35,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "4 Any land leased from others State BLM Federal Private Other", 
      top: 287.60,
      left: 310.402,
      width: 267.937,
      height: 22.56
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "5_7", 
      top: 312.68,
      left: 68.4,
      width: 210.24,
      height: 11.88
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check P5", 
      top: 311.374,
      left: 284.35,
      width: 17.604,
      height: 14.439
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "P5 Comment", 
      top: 312.534,
      left: 310.402,
      width: 267.937,
      height: 12.312
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "6_3", 
      top: 327.08,
      left: 68.4,
      width: 210.24,
      height: 11.88
   }
   
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "P6 Comment", 
      top: 326.806,
      left: 310.402,
      width: 267.937,
      height: 12.312
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check Q1", 
      top: 388.790,
      left: 300.35,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "1 Have any noxious weeds on the Property been identified", 
      top: 390.407,
      left: 324.933,
      width: 254.038,
      height: 13.200
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check Q2", 
      top: 393.927,
      left: 300.35,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "2 Have there been any weed enforcement actions on the Property", 
      top: 406.487,
      left: 324.933,
      width: 254.038,
      height: 22.440
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check Q3", 
      top: 434.005,
      left: 300.35,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "3 Has a noxious weed management plan for the Property been entered into", 
      top: 431.807,
      left: 324.933,
      width: 254.038,
      height: 22.560
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check Q4", 
      top: 444.460,
      left: 300.35,
      width: 17.604,
      height: 16.075
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "4 Have noxious weed management actions been implemented", 
      top: 456.247,
      left: 324.933,
      width: 254.038,
      height: 25.462
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check Q5", 
      top: 482.010,
      left: 300.35,
      width: 17.604,
      height: 14.766
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "5 Have herbicides been applied", 
      top: 483.247,
      left: 324.933,
      width: 254.038,
      height: 13.942
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "6_4", 
      top: 499.40,
      left: 68.4,
      width: 225.6,
      height: 12.84
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check Q6", 
      top: 498.432,
      left: 300.35,
      width: 17.604,
      height: 13.457
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "Q6 Comment", 
      top: 499.875,
      left: 324.933,
      width: 254.038,
      height: 12.734
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "7_2", 
      top: 514.76,
      left: 68.4,
      width: 225.6,
      height: 12.96
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check Q7", 
      top: 513.414,
      left: 300.35,
      width: 17.604,
      height: 15.093
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 12,
      type: "text",
      name: "Q7 Comment", 
      top: 514.779,
      left: 324.933,
      width: 254.038,
      height: 12.734
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check R1", 
      top: 645.461,
      left: 295.465,
      width: 17.604,
      height: 15.233
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "1 Any part of the Property enrolled in any governmental programs such as Conservation Reserve Program CRP Wetlands Reserve Program WRP etc", 
      top: 633.931,
      left: 321.013,
      width: 248.027,
      height: 33.709
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check R2", 
      top: 669.245,
      left: 295.302,
      width: 17.604,
      height: 13.924
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "2 Conservation easement", 
      top: 670.16,
      left: 321.013,
      width: 248.027,
      height: 11.28
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "3_4", 
      top: 683.564,
      left: 78.9658,
      width: 209.2402,
      height: 11.556
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check R3", 
      top: 682.9360,
      left: 295.296,
      width: 17.604,
      height: 13.9241
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "R3 Comment", 
      top: 683.4570,
      left: 321.013,
      width: 248.027,
      height: 11.7484
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "4_4", 
      top: 697.64,
      left: 78.9658,
      width: 209.2402,
      height: 11.28
   }
   ,
   {
      page: 7,
      type: "checkbox",
      name: "Check R4", 
      top: 696.6423,
      left: 295.133,
      width: 17.603,
      height: 14.5785
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "R4 Comment", 
      top: 697.4199,
      left: 321.013,
      width: 248.027,
      height: 11.0942
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "SPD19L622  SELLERS PROPERTY DISCLOSURE LAND_6", 
      top: 732.00,
      left: 36,
      width: 481.56,
      height: 12.48
   }
   ,
   {
      page: 7,
      fontName: "Helvetica",
      fontSize: 10,
      type: "text",
      name: "Page 8 of 9", 
      top: 732.00,
      left: 521.88,
      width: 55.68,
      height: 12.48
   }
   ,
   {
      page: 8,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "SPD19L622  SELLERS PROPERTY DISCLOSURE LAND_7", 
      top: 708.48,
      left: 36,
      width: 481.08,
      height: 36.00
   }
   ,
   {
      page: 8,
      fontName: "Helvetica",
      fontSize: 10,
      type: "textarea",
      name: "Page 9 of 9", 
      top: 708.48,
      left: 521.88,
      width: 55.68,
      height: 36.00
   }
   ] }
   