import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  Header,
  Input,
  Segment,
} from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { closeModal } from "../../../app/common/modals/modalSlice";
import { renameEmailTemplateInDb } from "../../../app/firestore/firestoreService";
import ModalWrapper from "../../../app/common/modals/modalWrapper";

export default function EmailTemplatesRename({ emailTemplate }) {
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const dispatch = useDispatch();
  const [textInput, setTextInput] = useState(emailTemplate.name);
  const inputRef = useRef(null);

  function handleTextInputChange(event) {
    setTextInput(event.target.value);
  }

  function handleSubmit() {
    try {
      renameEmailTemplateInDb(emailTemplate.id, { name: textInput });
      dispatch(
        closeModal({
          modalType: "EmailTemplatesRename",
        })
      );
    } catch (error) {
      throw error;
    }
  }

  return (
    <>
      <ModalWrapper size="small">
        <Segment>
          <Grid>
            <Grid.Column>
              <Header size="large" color="blue">
                Rename Email Template
              </Header>
              <Divider />
              <Input
                fluid
                ref={inputRef}
                value={textInput}
                onChange={handleTextInputChange}
              ></Input>
              <Divider />
              <div>
                <Button
                  primary
                  type="submit"
                  onClick={() => handleSubmit()}
                  floated={isMobile ? null : "right"}
                  content="Submit"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  type="button"
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "emailTemplatesRename",
                      })
                    )
                  }
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Segment>
      </ModalWrapper>
    </>
  );
}
