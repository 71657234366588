export function personalPropertyAgreement() {
  return [   //2023
  {
   page: 0,
   name: "Logo",
   isText: false,
   fontSize: 10,
   type: "logo",
   top: 28.673,
   left: 52.9457,
   width: 288.0003,
   height: 43.2,
 }
 ,
{
     page: 0,
     name: "Date", 
     fontSize: 9,
     type: "date",
     top: 184.669,
     left: 398.64,
     width: 174.00,
     height: 12.251
  }
  ,
  {
     page: 0,
     name: "Estate Residential for the sale and purchase of real estate dated", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 10,
     top: 231.218,
     left: 311.28,
     width: 117.36,
     height: 11.662
  }
  ,
  {
     page: 0,
     name: "16", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 9,
     top: 243.72,
     left: 50.4,
     width: 252.24,
     height: 10.73
  }
  ,
  {
     page: 0,
     name: "Seller and", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 9,
     top: 243.72,
     left: 357.0,
     width: 215.64,
     height: 10.239
  }
  ,
  {
     page: 0,
     name: "of", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 9,
     top: 266.76,
     left: 61.2,
     width: 79.2,
     height: 10.10
  }
  ,
  {
     page: 0,
     name: "Text1", 
     type: "textarea",
     fontName: "Helvetica",
     fontSize: 8,
     top: 268.101,
     left: 186.414,
     width: 388.537,
     height: 38.774
  }
  ,
  {
     page: 0,
     name: "known as No", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 10,
     top: 308.911,
     left: 108.12,
     width: 419.52,
     height: 15.929
  }
  ,
  {
     page: 0,
     name: "Text2", 
     type: "textarea",
     fontName: "Helvetica",
     fontSize: 9,
     top: 386.442,
     left: 56.0289,
     width: 531.9181,
     height: 278.341
  }
  ,
  {
     page: 0,
     name: "Price The Price for the Personal Property is", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 10,
     top: 670.016,
     left: 263.227,
     width: 90.120,
     height: 11.746
  }
  ,
  {
     page: 1,
     name: "Check Box3", 
     isText: false,
     type: "checkbox",
     top: 108.812,
     left: 126.956,
     width: 10.972,
     height: 11.925
  }
  ,
  {
     page: 1,
     name: "Check Box4", 
     isText: false,
     type: "checkbox",
     top: 108.812,
     left: 167.759,
     width: 10.971,
     height: 11.925
  }
  ,
  {
     page: 1,
     name: "undefined", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 9,
     top: 108.8,
     left: 179.677,
     width: 160.080,
     height: 10.757
  }
  ,
  {
     page: 1,
     name: "Check Box5", 
     isText: false,
     type: "checkbox",
     top: 131.721,
     left: 182.426,
     width: 11.564,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box6", 
     isText: false,
     type: "checkbox",
     top: 132.702,
     left: 207.51,
     width: 11.564,
     height: 12.326
  }
  ,
  {
     page: 1,
     name: "Check Box7", 
     isText: false,
     type: "checkbox",
     top: 180.594,
     left: 57.1717,
     width: 11.5642,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box8", 
     isText: false,
     type: "checkbox",
     top: 180.594,
     left: 99.4263,
     width: 11.5637,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box9", 
     isText: false,
     type: "checkbox",
     top: 180.594,
     left: 145.971,
     width: 11.565,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box10", 
     isText: false,
     type: "checkbox",
     top: 180.594,
     left: 190.299,
     width: 11.564,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box17", 
     isText: false,
     type: "checkbox",
     top: 180.594,
     left: 370.663,
     width: 11.564,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box18", 
     isText: false,
     type: "checkbox",
     top: 180.594,
     left: 410.209,
     width: 11.564,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box11", 
     isText: false,
     type: "checkbox",
     top: 229.463,
     left: 113.946,
     width: 11.565,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box12", 
     isText: false,
     type: "checkbox",
     top: 229.463,
     left: 155.201,
     width: 11.564,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box13", 
     isText: false,
     type: "checkbox",
     top: 229.463,
     left: 201.746,
     width: 11.565,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box14", 
     isText: false,
     type: "checkbox",
     top: 229.463,
     left: 246.074,
     width: 11.564,
     height: 12.327
  }
  ,
  {
     page: 1,
     name: "Check Box15", 
     isText: false,
     type: "checkbox",
     top: 229.607,
     left: 510.954,
     width: 12.031,
     height: 12.606
  }
  ,
  {
     page: 1,
     name: "Check Box16", 
     isText: false,
     type: "checkbox",
     top: 229.607,
     left: 549.699,
     width: 12.031,
     height: 12.606
  }
  ,
  {
     page: 1,
     name: "Buyers Name", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 10,
     top: 350.896,
     left: 122.925,
     width: 180.240,
     height: 14.400
  }
  ,
  {
     page: 1,
     name: "Buyers Name_2", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 10,
     top: 349.587,
     left: 393.045,
     width: 188.160,
     height: 15.709
  }
  ,
  {
     page: 1,
     name: "Sellers Name", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 10,
     top: 439.456,
     left: 122.925,
     width: 180.240,
     height: 14.400
  }
  ,
  {
     page: 1,
     name: "Sellers Name_2", 
     type: "text",
     fontName: "Helvetica",
     fontSize: 10,
     top: 438.801,
     left: 393.045,
     width: 188.160,
     height: 15.055
  }
  ] }
  