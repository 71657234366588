import React from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Segment,
  Image,
} from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { closeModal } from "../../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../../app/common/modals/modalWrapper";
import { useDispatch } from "react-redux";

export default function DocViewImageFileModal({ doc }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <>
      <ModalWrapper size="large">
        <Segment>
          <Grid>
            <Grid.Column>
              <Header size="large" color="blue">
                View Image
              </Header>
              <Divider />
              <Image src={doc.fileUrl} />
              <Divider />
              <div>
                <Button
                  primary
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "DocViewImageFileModal",
                      })
                    )
                  }
                  floated={isMobile ? null : "right"}
                  content="Close"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Segment>
      </ModalWrapper>
    </>
  );
}
