export function postClosingOccupancyAgreement() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 240,
      height: 36,
    }
    ,
   {
      page: 0,
      name: "Parties and Property  This PostClosing Occupancy Agreement Agreement is entered into between", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 197.808,
      left: 47.5918,
      width: 479.1092,
      height: 11.955
   }
   ,
   {
      page: 0,
      name: "and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 211.603,
      left: 67.32,
      width: 236.765,
      height: 11.197
   }
   ,
   {
      page: 0,
      name: "County of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 8,
      top: 223.848,
      left: 147.439,
      width: 144.966,
      height: 11.437
   }
   ,
   {
      page: 0,
      name: "Text5", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 8,
      top: 237.601,
      left: 51.7093,
      width: 515.1297,
      height: 34.068
   }
   ,
   {
      page: 0,
      name: "Text2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 274.256,
      left: 101.508,
      width: 185.839,
      height: 15.054
   }
   ,
   {
      page: 0,
      name: "Text3", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 274.256,
      left: 289.31,
      width: 115.855,
      height: 18.2
   }
   ,
   {
      page: 0,
      name: "Text4", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 9,
      top: 273.601,
      left: 449.02,
      width: 59.564,
      height: 18.2
   }
   ,
   {
      page: 0,
      name: "Agreement Buyer and Seller entered into that certain Contract to Buy and Sell Real Estate dated", 
      type: "date",
      fontName: "Helvetica",
      fontSize: 10,
      top: 316.08,
      left: 471.36,
      width: 90.24,
      height: 10.92
   }
   ,
   {
      page: 0,
      name: "Possession Seller may retain possession of the Property from date of Closing through", 
      type: "date",
      fontName: "Helvetica",
      fontSize: 8,
      top: 370.523,
      left: 425.88,
      width: 43.184,
      height: 11.437
   }
   ,
   {
      page: 0,
      name: "Rent Rent for the Term is", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 703.56,
      left: 190.68,
      width: 60.00,
      height: 10.92
   }
   ,
   {
      page: 0,
      name: "payable to Buyer in advance at Closing and delivery of deed Should Seller", 
      isText: false,
      type: "checkbox",
      top: 716.76,
      left: 263.04,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Will", 
      isText: false,
      type: "checkbox",
      top: 716.76,
      left: 297.84,
      width: 10.08,
      height: 9.96
   }
   ,
   {
      page: 1,
      name: "and additionally liable to Buyer for payment of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 112.32,
      left: 246,
      width: 60.12,
      height: 10.32
   }
   ,
   {
      page: 1,
      name: "Seller", 
      isText: false,
      type: "checkbox",
      top: 145.44,
      left: 453.72,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 1,
      name: "Buyer", 
      isText: false,
      type: "checkbox",
      top: 145.44,
      left: 497.16,
      width: 10.08,
      height: 10.08
   }
   ,
   {
      page: 1,
      name: "Seller_2", 
      isText: false,
      type: "checkbox",
      top: 165.96,
      left: 470.52,
      width: 10.08,
      height: 9.96
   }
   ,
   {
      page: 1,
      name: "Buyer_2", 
      isText: false,
      type: "checkbox",
      top: 165.96,
      left: 518.28,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 1,
      name: "Will_2", 
      isText: false,
      type: "checkbox",
      top: 209.4,
      left: 249.36,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 1,
      name: "Will Not maintain and pay the cost of a Sellers Renters Policy", 
      isText: false,
      type: "checkbox",
      top: 209.4,
      left: 285.6,
      width: 9.96,
      height: 10.08
   }
   ,
   {
      page: 1,
      name: "15 Security Deposit Seller agrees that a security deposit in the amount of", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 9,
      top: 290.313,
      left: 366.84,
      width: 69.96,
      height: 15.327
   }
   ,
   {
      page: 1,
      name: "Text6", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 8,
      top: 403.856,
      left: 51.4475,
      width: 516.4385,
      height: 104.728
   }
   ,
   {
      page: 1,
      name: "Buyers Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 513.72,
      left: 119.52,
      width: 177.48,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Buyers Name_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 513.72,
      left: 383.52,
      width: 179.16,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Date 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 586.56,
      left: 133.32,
      width: 163.68,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Date 1_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 586.56,
      left: 400.32,
      width: 162.36,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Date 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 598.56,
      left: 133.32,
      width: 163.68,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Date 2_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 598.56,
      left: 400.32,
      width: 162.36,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Phone No 1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 610.56,
      left: 133.32,
      width: 163.68,
      height: 11.28
   }
   ,
   {
      page: 1,
      name: "Phone No 1_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 610.56,
      left: 400.32,
      width: 162.36,
      height: 11.28
   }
   ,
   {
      page: 1,
      name: "Phone No 2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 622.44,
      left: 133.32,
      width: 163.68,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Phone No 2_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 622.44,
      left: 400.32,
      width: 162.36,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Email Address", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 634.44,
      left: 133.32,
      width: 163.68,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Email Address_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 634.44,
      left: 400.32,
      width: 162.36,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Sellers Name", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 668.04,
      left: 119.04,
      width: 177.96,
      height: 11.40
   }
   ,
   {
      page: 1,
      name: "Sellers Name_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 668.04,
      left: 383.52,
      width: 179.16,
      height: 11.40
   }
   ,
   {
      page: 2,
      name: "1", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 57.72,
      left: 133.32,
      width: 163.68,
      height: 11.40
   }
   ,
   {
      page: 2,
      name: "1_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 57.72,
      left: 400.32,
      width: 162.36,
      height: 11.40
   }
   ,
   {
      page: 2,
      name: "2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 69.72,
      left: 133.32,
      width: 163.68,
      height: 11.40
   }
   ,
   {
      page: 2,
      name: "2_2", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 69.72,
      left: 400.32,
      width: 162.36,
      height: 11.40
   }
   ,
   {
      page: 2,
      name: "Phone No 1_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 81.72,
      left: 133.32,
      width: 163.68,
      height: 11.40
   }
   ,
   {
      page: 2,
      name: "Phone No 1_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 81.72,
      left: 400.32,
      width: 162.36,
      height: 11.40
   }
   ,
   {
      page: 2,
      name: "Phone No 2_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 93.72,
      left: 133.32,
      width: 163.68,
      height: 11.40
   }
   ,
   {
      page: 2,
      name: "Phone No 2_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 93.72,
      left: 400.32,
      width: 162.36,
      height: 11.40
   }
   ,
   {
      page: 2,
      name: "Email Address_3", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 105.72,
      left: 132.6,
      width: 164.4,
      height: 11.40
   }
   ,
   {
      page: 2,
      name: "Email Address_4", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 105.72,
      left: 399.6,
      width: 163.08,
      height: 11.40
   }
   ] }
   