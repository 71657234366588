export function contracttoBuyandSellLand2024() {
    return [   //2024 Release 2023-12-17 16:59:45
    {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 288.0003,
      height: 43.2,
    }
    ,
   {
       page: 0,
       name: "Property with No Residences", 
       isText: false,
       type: "checkbox",
       top: 172.92,
       left: 231,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 0,
       name: "Property with Residences Residential Addendum Attached", 
       isText: false,
       type: "checkbox",
       top: 186.84,
       left: 151.32,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 0,
       name: "Date", 
       fontSize: 9,
       type: "date",
       top: 207.556,
       left: 447.36,
       width: 125.28,
       height: 14.804
    }
    ,
    {
       page: 0,
       name: "Buyer", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 309.382,
       left: 117.12,
       width: 420.00,
       height: 15.458
    }
    ,
    {
       page: 0,
       name: "Joint Tenants", 
       isText: false,
       type: "checkbox",
       top: 329.88,
       left: 274.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Tenants In Common", 
       isText: false,
       type: "checkbox",
       top: 329.88,
       left: 356.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "Other", 
       isText: false,
       type: "checkbox",
       top: 345,
       left: 76.2,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 0,
       name: "undefined", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 340.56,
       left: 119.4,
       width: 445.08,
       height: 14.28
    }
    ,
    {
       page: 0,
       name: "Seller", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 382.451,
       left: 115.44,
       width: 425.04,
       height: 14.149
    }
    ,
    {
       page: 0,
       name: "Property The Property is the following legally described real estate in the County of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 417.796,
       left: 423.12,
       width: 144.96,
       height: 14.804
    }
    ,
    {
       page: 0,
       name: "Text52", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 447.711,
       left: 58.9093,
       width: 513.9287,
       height: 58.655
    }
    ,
    {
       page: 0,
       name: "known as", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 506.116,
       left: 126.84,
       width: 425.04,
       height: 14.804
    }
    ,
    {
       page: 0,
       name: "Text53", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 602.839,
       left: 56.9457,
       width: 519.1653,
       height: 112.9823
    }
    ,
    {
       page: 1,
       name: "Text56", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 293.892,
       left: 76.5822,
       width: 492.9828,
       height: 103.164
    }
    ,
    {
       page: 1,
       name: "Text55", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 201.601,
       left: 75.9276,
       width: 492.9834,
       height: 80.909
    }
    ,
    {
       page: 1,
       name: "Text54", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 85.091,
       left: 75.2731,
       width: 494.9469,
       height: 67.819
    }
    ,
    {
       page: 1,
       name: "Deeded Water Rights The following legally described water rights", 
       isText: false,
       type: "checkbox",
       top: 411.72,
       left: 107.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Text57", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 423.493,
       left: 88.364,
       width: 481.201,
       height: 87.455
    }
    ,
    {
       page: 1,
       name: "deed at Closing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 513.36,
       left: 347.52,
       width: 160.08,
       height: 11.64
    }
    ,
    {
       page: 1,
       name: "Other Rights Relating to Water The following rights relating to water not included in  271 273", 
       isText: false,
       type: "checkbox",
       top: 538.92,
       left: 107.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "Text58", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 560.948,
       left: 73.964,
       width: 494.947,
       height: 93.346
    }
    ,
    {
       page: 1,
       name: "Well Rights Seller agrees to supply required information to Buyer about the well Buyer understands that if the", 
       isText: false,
       type: "checkbox",
       top: 656.04,
       left: 107.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 1,
       name: "undefined_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 730.08,
       left: 295.44,
       width: 275.04,
       height: 11.64
    }
    ,
    {
       page: 2,
       name: "Water Stock Certificates The water stock certificates to be transferred at Closing are as follows", 
       isText: false,
       type: "checkbox",
       top: 51.24,
       left: 107.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Text59", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 62.182,
       left: 73.3094,
       width: 484.4746,
       height: 58.655
    }
    ,
    {
       page: 2,
       name: "Text60", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 143.346,
       left: 72.0003,
       width: 486.4377,
       height: 69.128
    }
    ,
    {
       page: 2,
       name: "Does", 
       isText: false,
       type: "checkbox",
       top: 285.24,
       left: 234.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Does Not have a Right to Terminate if examination of the Water", 
       isText: false,
       type: "checkbox",
       top: 285.24,
       left: 278.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 2,
       name: "Text61", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 322.692,
       left: 71.3458,
       width: 489.0562,
       height: 83.528
    }
    ,
    {
       page: 2,
       name: "Time of Day Deadline_2b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 464.76,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 2,
       name: "Date or Deadline DetailsAlternative Earnest Money Deadline", 
       fontSize: 9,
       type: "date",
       top: 479.4,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 2,
       name: "Alternative Earnest Money Deadline_2b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 479.4,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 2,
       name: "Date Record Title Deadline and Tax Certificate", 
       fontSize: 9,
       type: "date",
       top: 506.28,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "Text62", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 505.966,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 2,
       name: "Date Record Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 520.68,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 2,
       name: "Text63", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 520.366,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 2,
       name: "Date OffRecord Title Deadline", 
       fontSize: 9,
       type: "date",
       top: 535.32,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "Text64", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 535.421,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 2,
       name: "Date OffRecord Title Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 549.72,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 2,
       name: "Text65", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 549.821,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 2,
       name: "Date Title Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 564.36,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 2,
       name: "Text66", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 564.875,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 2,
       name: "Date Third Party Right to PurchaseApprove Deadline", 
       fontSize: 9,
       type: "date",
       top: 579,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "Text67", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 578.621,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 2,
       name: "Date Association Documents Deadline", 
       fontSize: 9,
       type: "date",
       top: 605.64,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 2,
       name: "Text68", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 606.112,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 2,
       name: "Date Association Documents Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 620.28,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 2,
       name: "Text69", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 621.166,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 2,
       name: "Date Sellers Property Disclosure Deadline", 
       fontSize: 9,
       type: "date",
       top: 647.16,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "Text70", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 646.694,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 2,
       name: "Date LeadBased Paint Disclosure Deadline if Residential Addendum attached", 
       fontSize: 9,
       type: "date",
       top: 661.8,
       left: 314.4,
       width: 69.96,
       height: 13.909
    }
    ,
    {
       page: 2,
       name: "Text71", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 661.748,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 2,
       name: "Date New Loan Application Deadline", 
       fontSize: 9,
       type: "date",
       top: 695.16,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 2,
       name: "Text72", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 695.1304,
       left: 386.16,
       width: 183.00,
       height: 13.3200
    }
    ,
    {
       page: 2,
       name: "Date New Loan Terms Deadline", 
       fontSize: 9,
       type: "date",
       top: 709.56,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 2,
       name: "Text73", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 709.5304,
       left: 386.16,
       width: 183.00,
       height: 13.3200
    }
    ,
    {
       page: 2,
       name: "Date New Loan Availability Deadline", 
       fontSize: 9,
       type: "date",
       top: 724.2,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 2,
       name: "Text74", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 723.9305,
       left: 386.16,
       width: 183.00,
       height: 13.3200
    }
    ,
    {
       page: 3,
       name: "Date or Deadline DetailsBuyers Credit Information Deadline", 
       fontSize: 9,
       type: "date",
       top: 73.32,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Buyers Credit Information Deadline_2b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 73.32,
       left: 386.16,
       width: 183.00,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Date or Deadline DetailsDisapproval of Buyers Credit Information Deadline", 
       fontSize: 9,
       type: "date",
       top: 87.72,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Disapproval of Buyers Credit Information Deadline_2b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 87.72,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Date or Deadline DetailsExisting Loan Deadline", 
       fontSize: 9,
       type: "date",
       top: 102.36,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Existing Loan Deadline_2b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 102.36,
       left: 386.16,
       width: 183.00,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Date or Deadline DetailsExisting Loan Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 116.76,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Existing Loan Termination Deadline_2b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 116.76,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Date or Deadline DetailsLoan Transfer Approval Deadline", 
       fontSize: 9,
       type: "date",
       top: 131.4,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Loan Transfer Approval Deadline_2b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 131.4,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Date or Deadline DetailsSeller or Private Financing Deadline", 
       fontSize: 9,
       type: "date",
       top: 146.04,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Seller or Private Financing Deadline_2b", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 146.04,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Date Appraisal Deadline", 
       fontSize: 9,
       type: "date",
       top: 172.68,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text75", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 172.801,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Appraisal Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 187.32,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text76", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 188.51,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Date Appraisal Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 201.96,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Text77", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 202.255,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date New ILC or New Survey Deadline", 
       fontSize: 9,
       type: "date",
       top: 228.6,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text78", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 228.437,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date New ILC or New Survey Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 243.24,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Text79", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 244.147,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date New ILC or New Survey Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 257.64,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text80", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 258.547,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Water Rights Examination Deadline", 
       fontSize: 9,
       type: "date",
       top: 284.52,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text81", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 284.729,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Mineral Rights Examination Deadline", 
       fontSize: 9,
       type: "date",
       top: 299.16,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Text82", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 299.438,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Inspection Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 313.56,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text83", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 313.529,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Inspection Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 328.2,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text84", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 327.929,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Inspection Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 342.84,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Text85", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 343.638,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Property Insurance Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 357.24,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text86", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 358.038,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Due Diligence Documents Delivery Deadline", 
       fontSize: 9,
       type: "date",
       top: 371.88,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Text87", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 371.783,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Due Diligence Documents Objection Deadline", 
       fontSize: 9,
       type: "date",
       top: 386.28,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text88", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 385.529,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Due Diligence Documents Resolution Deadline", 
       fontSize: 9,
       type: "date",
       top: 400.92,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text89", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 401.238,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Environmental Inspection Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 415.56,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Text90", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 415.638,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date ADA Evaluation Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 429.96,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text91", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 430.038,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Conditional Sale Deadline", 
       fontSize: 9,
       type: "date",
       top: 444.6,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text92", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 445.093,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date LeadBased Paint Termination Deadline if Residential Addendum attached", 
       fontSize: 9,
       type: "date",
       top: 459.48,
       left: 314.4,
       width: 69.96,
       height: 13.669
    }
    ,
    {
       page: 3,
       name: "Text93", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 460.147,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Estoppel Statements Deadline", 
       fontSize: 9,
       type: "date",
       top: 480.36,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text94", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 482.402,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Estoppel Statements Termination Deadline", 
       fontSize: 9,
       type: "date",
       top: 495,
       left: 314.16,
       width: 70.44,
       height: 13.08
    }
    ,
    {
       page: 3,
       name: "Text95", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 494.839,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Closing Date", 
       fontSize: 9,
       type: "date",
       top: 521.64,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text96", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 522.33,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Date Possession Date", 
       fontSize: 9,
       type: "date",
       top: 536.28,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text97", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 536.73,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text98", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 550.475,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Date Acceptance Deadline Date", 
       fontSize: 9,
       type: "date",
       top: 565.32,
       left: 314.16,
       width: 70.44,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "Text99", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 564.875,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "Text100", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 580.584,
       left: 386.16,
       width: 183.00,
       height: 13.320
    }
    ,
    {
       page: 3,
       name: "47Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 594.24,
       left: 48.36,
       width: 26.04,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: " 27Row1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 594.24,
       left: 75.72,
       width: 30.12,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Acceptance Deadline TimeRow1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 594.24,
       left: 107.16,
       width: 205.56,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Closing and PossessionRow6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 594.24,
       left: 314.04,
       width: 70.68,
       height: 11.04
    }
    ,
    {
       page: 3,
       name: "Closing and PossessionRow6_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 594.24,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 3,
       name: "47Row2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 606.6,
       left: 48.48,
       width: 25.80,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: " 27Row2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 606.6,
       left: 75.84,
       width: 29.88,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "Acceptance Deadline TimeRow2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 606.6,
       left: 107.28,
       width: 205.32,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "Closing and PossessionRow7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 606.6,
       left: 314.16,
       width: 70.44,
       height: 11.16
    }
    ,
    {
       page: 3,
       name: "Closing and PossessionRow7_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 606.6,
       left: 386.16,
       width: 183.00,
       height: 13.32
    }
    ,
    {
       page: 4,
       name: "333", 
       isText: false,
       type: "checkbox",
       top: 125.64,
       left: 82.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Deadlines If any deadline falls on a Saturday Sunday or federal or Colorado state holiday Holiday such deadline", 
       isText: false,
       type: "checkbox",
       top: 125.64,
       left: 121.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 4,
       name: "Cash at Closing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 202.854,
       left: 309.6,
       width: 91.56,
       height: 13.080
    }
    ,
    {
       page: 4,
       name: "Earnest Money", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 217.145,
       left: 413.4,
       width: 87.6,
       height: 13.080
    }
    ,
    {
       page: 4,
       name: "New Loan", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 231.785,
       left: 413.4,
       width: 87.6,
       height: 13.080
    }
    ,
    {
       page: 4,
       name: "Assumption Balance", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 246.425,
       left: 413.4,
       width: 87.6,
       height: 13.080
    }
    ,
    {
       page: 4,
       name: "Private Financing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 260.826,
       left: 413.4,
       width: 87.6,
       height: 13.080
    }
    ,
    {
       page: 4,
       name: "Seller Financing", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 275.465,
       left: 413.4,
       width: 87.6,
       height: 13.080
    }
    ,
    {
       page: 4,
       name: " 477", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 290.76,
       left: 127.44,
       width: 52.92,
       height: 11.88
    }
    ,
    {
       page: 4,
       name: "Seller Financing7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 290.76,
       left: 181.92,
       width: 117.24,
       height: 11.88
    }
    ,
    {
       page: 4,
       name: "7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 290.76,
       left: 300.72,
       width: 100.44,
       height: 11.88
    }
    ,
    {
       page: 4,
       name: "7_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 290.76,
       left: 413.4,
       width: 87.6,
       height: 13.08
    }
    ,
    {
       page: 4,
       name: " 478", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 303.96,
       left: 127.44,
       width: 52.92,
       height: 12.12
    }
    ,
    {
       page: 4,
       name: "Seller Financing8", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 303.96,
       left: 181.92,
       width: 117.24,
       height: 12.12
    }
    ,
    {
       page: 4,
       name: "8", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 303.96,
       left: 300.72,
       width: 100.44,
       height: 12.12
    }
    ,
    {
       page: 4,
       name: "8_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 303.96,
       left: 413.4,
       width: 87.6,
       height: 13.08
    }
    ,
    {
       page: 4,
       name: "fill_24", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 317.4,
       left: 413.4,
       width: 87.6,
       height: 13.08
    }
    ,
    {
       page: 4,
       name: " 4410", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 332.28,
       left: 127.44,
       width: 52.92,
       height: 13.08
    }
    ,
    {
       page: 4,
       name: "fill_26", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 332.28,
       left: 309.6,
       width: 91.56,
       height: 13.08
    }
    ,
    {
       page: 4,
       name: "fill_27", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 332.28,
       left: 413.4,
       width: 87.6,
       height: 13.08
    }
    ,
    {
       page: 4,
       name: "Amount_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 349.364,
       left: 352.527,
       width: 134.727,
       height: 13.735
    }
    ,
    {
       page: 4,
       name: "Text117", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 419.565,
       left: 413.675,
       width: 154.581,
       height: 14.146
    }
    ,
    {
       page: 4,
       name: "Text116", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 433.966,
       left: 194.401,
       width: 311.019,
       height: 13.490
    }
    ,
    {
       page: 5,
       name: "or as otherwise agreed in writing between the parties to allow disbursement by Closing Company at Closing OR SUCH", 
       isText: false,
       type: "checkbox",
       top: 140.52,
       left: 428.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "funds that are immediately verifiable and available in an amount not less than the amount stated as Cash at Closing in  41", 
       isText: false,
       type: "checkbox",
       top: 140.52,
       left: 472.44,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "453", 
       isText: false,
       type: "checkbox",
       top: 261,
       left: 87,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Loan Limitations Buyer may purchase the Property using any of the following types of loans", 
       isText: false,
       type: "checkbox",
       top: 260.04,
       left: 170.04,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 5,
       name: "Other_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 258,
       left: 216.72,
       width: 320.04,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "Text118", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 288.656,
       left: 292.583,
       width: 83.236,
       height: 14.800
    }
    ,
    {
       page: 5,
       name: "per", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 288.96,
       left: 393.12,
       width: 129.96,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "principal and interest presently at the rate of", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 302.88,
       left: 251.64,
       width: 60.12,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "indicated", 
       isText: false,
       type: "checkbox",
       top: 334.44,
       left: 73.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Property Insurance Premium and", 
       isText: false,
       type: "checkbox",
       top: 334.44,
       left: 173.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "per annum and also including escrow for the following as", 
       isText: false,
       type: "checkbox",
       top: 333.48,
       left: 350.04,
       width: 11.28,
       height: 11.28
    }
    ,
    {
       page: 5,
       name: "undefined_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 331.44,
       left: 367.2,
       width: 195.0,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "Buyer agrees to pay a loan transfer fee not to exceed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 345.6,
       left: 310.8,
       width: 80.04,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "rate will not exceed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 359.52,
       left: 153.12,
       width: 50.04,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "per annum and the new payment will not exceed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 359.52,
       left: 423,
       width: 65.04,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "Closing is less than the Assumption Balance which causes the amount of cash required from Buyer at Closing to be", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 373.2,
       left: 73.2,
       width: 105.0,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "increased by more than", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 400.8,
       left: 176.4,
       width: 75.0,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "Terminate under  241 on or before Closing Date", 
       isText: false,
       type: "checkbox",
       top: 431.4,
       left: 116.04,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "release from liability will be evidenced by delivery", 
       isText: false,
       type: "checkbox",
       top: 431.4,
       left: 157.56,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Will Not be released from liability on said loan If applicable compliance with the requirements for", 
       isText: false,
       type: "checkbox",
       top: 445.32,
       left: 285.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "at Closing", 
       isText: false,
       type: "checkbox",
       top: 445.32,
       left: 506.76,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Text101", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 469.966,
       left: 71.3458,
       width: 231.8182,
       height: 14.800
    }
    ,
    {
       page: 5,
       name: "in an amount not to exceed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 469.92,
       left: 428.52,
       width: 80.04,
       height: 12.84
    }
    ,
    {
       page: 5,
       name: "471", 
       isText: false,
       type: "checkbox",
       top: 570.36,
       left: 79.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Seller Financing If Buyer is to pay all or any portion of the Purchase Price with Seller financing", 
       isText: false,
       type: "checkbox",
       top: 570.36,
       left: 129.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 5,
       name: "Seller will deliver the proposed Seller financing documents to the other party on or before", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 567.36,
       left: 516.36,
       width: 54.96,
       height: 12.84
    }
    ,
    {
       page: 7,
       name: "company lenders agent or all three", 
       isText: false,
       type: "checkbox",
       top: 178.68,
       left: 73.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 7,
       name: "Cost of Appraisal Cost of the Appraisal to be obtained after the date of this Contract must be timely paid by", 
       isText: false,
       type: "checkbox",
       top: 178.68,
       left: 123,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "company to furnish the owners title insurance policy at Sellers expense On or before Record Title Deadline Seller must", 
       isText: false,
       type: "checkbox",
       top: 258.6,
       left: 107.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "insurance policy to be issued and delivered to Buyer as soon as practicable at or after Closing", 
       isText: false,
       type: "checkbox",
       top: 293.16,
       left: 245.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "company to furnish the owners title insurance policy at Buyers expense On or before Record Title Deadline Buyer must", 
       isText: false,
       type: "checkbox",
       top: 319.8,
       left: 109.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "ExtendedCoverage OEC If the Title Commitment is to contain OEC it will commit to delete or insure over the standard", 
       isText: false,
       type: "checkbox",
       top: 379.32,
       left: 377.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "Will Not contain Owners", 
       isText: false,
       type: "checkbox",
       top: 379.32,
       left: 418.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "OneHalf by Buyer and OneHalf by Seller", 
       isText: false,
       type: "checkbox",
       top: 445.56,
       left: 275.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "Other_3", 
       isText: false,
       type: "checkbox",
       top: 445.56,
       left: 324.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "Regardless of whether the Contract requires OEC the Title Insurance Commitment may not provide OEC or delete or", 
       isText: false,
       type: "checkbox",
       top: 459.72,
       left: 79.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "Buyer_2", 
       isText: false,
       type: "checkbox",
       top: 459.72,
       left: 285.48,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 8,
       name: "undefined_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 456.24,
       left: 329.04,
       width: 240.00,
       height: 13.32
    }
    ,
    {
       page: 9,
       name: "Text102", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 436.584,
       left: 73.3094,
       width: 497.5656,
       height: 14.800
    }
    ,
    {
       page: 9,
       name: "metropolitan districts that affect the Property Tax Certificate must be delivered to Buyer on or before Record Title", 
       isText: false,
       type: "checkbox",
       top: 456.6,
       left: 277.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 9,
       name: "Buyer for the Property listing any special taxing or", 
       isText: false,
       type: "checkbox",
       top: 456.6,
       left: 325.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "Does_2", 
       isText: false,
       type: "checkbox",
       top: 489.24,
       left: 228.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "Does Not have a Right to Terminate if examination of the Mineral", 
       isText: false,
       type: "checkbox",
       top: 489.24,
       left: 272.04,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "New Improvement Location Certificate New ILC or", 
       isText: false,
       type: "checkbox",
       top: 552.36,
       left: 89.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "New Survey in the form of", 
       isText: false,
       type: "checkbox",
       top: 568.44,
       left: 89.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "undefined_5", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 565.44,
       left: 216.36,
       width: 350.04,
       height: 12.84
    }
    ,
    {
       page: 10,
       name: "Seller_2", 
       isText: false,
       type: "checkbox",
       top: 604.68,
       left: 276.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "Buyer will order the New ILC or New Survey The New", 
       isText: false,
       type: "checkbox",
       top: 604.68,
       left: 324.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "Seller_3", 
       isText: false,
       type: "checkbox",
       top: 661.08,
       left: 133.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "Buyer or", 
       isText: false,
       type: "checkbox",
       top: 661.08,
       left: 181.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 10,
       name: "Text103", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 671.567,
       left: 73.964,
       width: 495.601,
       height: 30.5087
    }
    ,
    {
       page: 10,
       name: "opinion of title if an Abstract of Title and", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 713.28,
       left: 248.16,
       width: 325.08,
       height: 12.84
    }
    ,
    {
       page: 12,
       name: "Text104", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 247.419,
       left: 96.2186,
       width: 474.6564,
       height: 80.255
    }
    ,
    {
       page: 12,
       name: "Will", 
       isText: false,
       type: "checkbox",
       top: 370.2,
       left: 130.92,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Will Not assume the Sellers obligations under such leases for the Leased Items  254", 
       isText: false,
       type: "checkbox",
       top: 370.2,
       left: 173.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Will_2", 
       isText: false,
       type: "checkbox",
       top: 443.16,
       left: 130.92,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Will Not assume the debt on the Encumbered Inclusions  252 Encumbered Inclusions", 
       isText: false,
       type: "checkbox",
       top: 443.16,
       left: 173.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "All contracts relating to the operation maintenance and management of the Property", 
       isText: false,
       type: "checkbox",
       top: 488.04,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Property tax bills for the last", 
       isText: false,
       type: "checkbox",
       top: 504.36,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "years", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 501.84,
       left: 282,
       width: 65.04,
       height: 12.36
    }
    ,
    {
       page: 12,
       name: "Asbuilt construction plans to the Property and the tenant improvements including architectural", 
       isText: false,
       type: "checkbox",
       top: 520.44,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "A list of all Inclusions to be conveyed to Buyer", 
       isText: false,
       type: "checkbox",
       top: 563.16,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Operating statements for the past", 
       isText: false,
       type: "checkbox",
       top: 579.48,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "years_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 576.96,
       left: 300,
       width: 65.04,
       height: 12.36
    }
    ,
    {
       page: 12,
       name: "A rent roll accurate and correct to the date of this Contract", 
       isText: false,
       type: "checkbox",
       top: 595.56,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "A schedule of any tenant improvement work Seller is obligated to complete but has not yet completed", 
       isText: false,
       type: "checkbox",
       top: 611.88,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "All insurance policies pertaining to the Property and copies of any claims which have been made for", 
       isText: false,
       type: "checkbox",
       top: 641.16,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "the past", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 651.84,
       left: 138.96,
       width: 65.04,
       height: 12.36
    }
    ,
    {
       page: 12,
       name: "Soils reports surveys and engineering reports or data pertaining to the Property if not delivered", 
       isText: false,
       type: "checkbox",
       top: 670.68,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 12,
       name: "Any and all existing documentation and reports regarding Phase I and II environmental reports", 
       isText: false,
       type: "checkbox",
       top: 700.2,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Any Americans with Disabilities Act reports studies or surveys concerning the compliance of the", 
       isText: false,
       type: "checkbox",
       top: 80.76,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "All permits licenses and other building or use authorizations issued by any governmental authority", 
       isText: false,
       type: "checkbox",
       top: 110.28,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Other_4", 
       isText: false,
       type: "checkbox",
       top: 153,
       left: 149.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Text105", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 165.255,
       left: 100.146,
       width: 460.910,
       height: 67.164
    }
    ,
    {
       page: 13,
       name: "Seller_4", 
       isText: false,
       type: "checkbox",
       top: 465.48,
       left: 77.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Buyer will order or provide", 
       isText: false,
       type: "checkbox",
       top: 465.48,
       left: 125.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Phase I Environmental Site Assessment", 
       isText: false,
       type: "checkbox",
       top: 479.16,
       left: 156.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Phase II Environmental Site Assessment compliant with most current version of the applicable", 
       isText: false,
       type: "checkbox",
       top: 493.08,
       left: 156.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "undefined_6", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 517.44,
       left: 184.08,
       width: 375.00,
       height: 13.08
    }
    ,
    {
       page: 13,
       name: "Seller_5", 
       isText: false,
       type: "checkbox",
       top: 534.36,
       left: 152.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Buyer Environmental Inspection In addition Buyer at Buyers expense may also", 
       isText: false,
       type: "checkbox",
       top: 534.36,
       left: 203.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "days Extended", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 600.24,
       left: 368.52,
       width: 129.96,
       height: 13.08
    }
    ,
    {
       page: 13,
       name: "Seller_6", 
       isText: false,
       type: "checkbox",
       top: 631.08,
       left: 473.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 13,
       name: "Buyer_3", 
       isText: false,
       type: "checkbox",
       top: 631.08,
       left: 522.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "undefined_7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 61.92,
       left: 244.56,
       width: 324.96,
       height: 12.36
    }
    ,
    {
       page: 14,
       name: "Addendum disclosing the source of potable water for the Property", 
       isText: false,
       type: "checkbox",
       top: 147.48,
       left: 100.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Source of Potable Water Residential Land and Residential Improvements Only", 
       isText: false,
       type: "checkbox",
       top: 147.48,
       left: 145.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Note to Buyer SOME WATER PROVIDERS RELY TO VARYING DEGREES ON NONRENEWABLE", 
       isText: false,
       type: "checkbox",
       top: 175.08,
       left: 73.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Does_3", 
       isText: false,
       type: "checkbox",
       top: 175.08,
       left: 199.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 14,
       name: "Does Not acknowledge receipt of a copy of the current well permit", 
       isText: false,
       type: "checkbox",
       top: 175.08,
       left: 243.96,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Are", 
       isText: false,
       type: "checkbox",
       top: 51,
       left: 413.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Are Not executed with", 
       isText: false,
       type: "checkbox",
       top: 51,
       left: 453.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "undefined_8", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 101.04,
       left: 325.08,
       width: 240.00,
       height: 12.60
    }
    ,
    {
       page: 15,
       name: "special warranty deed", 
       isText: false,
       type: "checkbox",
       top: 213.24,
       left: 63,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "general warranty deed", 
       isText: false,
       type: "checkbox",
       top: 213.24,
       left: 207.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "bargain and sale deed", 
       isText: false,
       type: "checkbox",
       top: 213.24,
       left: 320.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "quit claim deed", 
       isText: false,
       type: "checkbox",
       top: 213.24,
       left: 430.68,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "personal representatives deed", 
       isText: false,
       type: "checkbox",
       top: 228.36,
       left: 63,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Seller provided another deed is not selected must execute and deliver a good and sufficient special warranty deed to Buyer at", 
       isText: false,
       type: "checkbox",
       top: 228.36,
       left: 207.96,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "deed", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 225.6,
       left: 223.92,
       width: 315.12,
       height: 12.6
    }
    ,
    {
       page: 15,
       name: "Buyer_4", 
       isText: false,
       type: "checkbox",
       top: 422.76,
       left: 93.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Seller_7", 
       isText: false,
       type: "checkbox",
       top: 422.76,
       left: 151.32,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "OneHalf by Buyer and OneHalf by Seller_2", 
       isText: false,
       type: "checkbox",
       top: 422.76,
       left: 207.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Other_5", 
       isText: false,
       type: "checkbox",
       top: 422.76,
       left: 413.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "undefined_9", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 420,
       left: 457.92,
       width: 109.92,
       height: 12.6
    }
    ,
    {
       page: 15,
       name: "Buyer_5", 
       isText: false,
       type: "checkbox",
       top: 490.92,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Seller_8", 
       isText: false,
       type: "checkbox",
       top: 490.92,
       left: 255.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "OneHalf by Buyer and OneHalf by Seller_3", 
       isText: false,
       type: "checkbox",
       top: 490.92,
       left: 311.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "NA", 
       isText: false,
       type: "checkbox",
       top: 490.92,
       left: 518.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Buyer_6", 
       isText: false,
       type: "checkbox",
       top: 521.4,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Seller_9", 
       isText: false,
       type: "checkbox",
       top: 521.4,
       left: 255.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "OneHalf by Buyer and OneHalf by Seller_4", 
       isText: false,
       type: "checkbox",
       top: 521.4,
       left: 311.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "NA_2", 
       isText: false,
       type: "checkbox",
       top: 521.4,
       left: 518.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Buyer_7", 
       isText: false,
       type: "checkbox",
       top: 572.76,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Seller_10", 
       isText: false,
       type: "checkbox",
       top: 572.76,
       left: 255.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "OneHalf by Buyer and OneHalf by Seller_5", 
       isText: false,
       type: "checkbox",
       top: 572.76,
       left: 311.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "NA_3", 
       isText: false,
       type: "checkbox",
       top: 572.76,
       left: 518.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Buyer_8", 
       isText: false,
       type: "checkbox",
       top: 601.08,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Seller_11", 
       isText: false,
       type: "checkbox",
       top: 601.08,
       left: 255.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "OneHalf by Buyer and OneHalf by Seller_6", 
       isText: false,
       type: "checkbox",
       top: 601.08,
       left: 311.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "NA_4", 
       isText: false,
       type: "checkbox",
       top: 601.08,
       left: 518.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Buyer_9", 
       isText: false,
       type: "checkbox",
       top: 634.68,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Seller_12", 
       isText: false,
       type: "checkbox",
       top: 634.68,
       left: 255.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "OneHalf by Buyer and OneHalf by Seller_7", 
       isText: false,
       type: "checkbox",
       top: 634.68,
       left: 311.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "NA_5", 
       isText: false,
       type: "checkbox",
       top: 634.68,
       left: 518.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Buyer_10", 
       isText: false,
       type: "checkbox",
       top: 667.32,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Seller_13", 
       isText: false,
       type: "checkbox",
       top: 667.32,
       left: 255.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "OneHalf by Buyer and OneHalf by Seller_8", 
       isText: false,
       type: "checkbox",
       top: 667.32,
       left: 311.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "NA_6", 
       isText: false,
       type: "checkbox",
       top: 667.32,
       left: 518.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Buyer_11", 
       isText: false,
       type: "checkbox",
       top: 725.16,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "Seller_14", 
       isText: false,
       type: "checkbox",
       top: 725.16,
       left: 255.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "OneHalf by Buyer and OneHalf by Seller_9", 
       isText: false,
       type: "checkbox",
       top: 725.16,
       left: 311.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 15,
       name: "NA_7", 
       isText: false,
       type: "checkbox",
       top: 725.16,
       left: 518.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "for", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 62.4,
       left: 78.24,
       width: 84.96,
       height: 12.36
    }
    ,
    {
       page: 16,
       name: "Water StockCertificates", 
       isText: false,
       type: "checkbox",
       top: 78.84,
       left: 73.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Augmentation Membership", 
       isText: false,
       type: "checkbox",
       top: 92.52,
       left: 73.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Water District", 
       isText: false,
       type: "checkbox",
       top: 78.84,
       left: 237.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Small Domestic Water Company", 
       isText: false,
       type: "checkbox",
       top: 92.52,
       left: 237.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Buyer_12", 
       isText: false,
       type: "checkbox",
       top: 120.12,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Seller_15", 
       isText: false,
       type: "checkbox",
       top: 120.12,
       left: 255.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "OneHalf by Buyer and OneHalf by Seller_10", 
       isText: false,
       type: "checkbox",
       top: 120.12,
       left: 311.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "NA_8", 
       isText: false,
       type: "checkbox",
       top: 120.12,
       left: 518.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Buyer_13", 
       isText: false,
       type: "checkbox",
       top: 150.84,
       left: 192.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Seller_16", 
       isText: false,
       type: "checkbox",
       top: 150.84,
       left: 255.72,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "OneHalf by Buyer and OneHalf by Seller_11", 
       isText: false,
       type: "checkbox",
       top: 150.84,
       left: 311.64,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "NA_9", 
       isText: false,
       type: "checkbox",
       top: 150.84,
       left: 518.28,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "IS a foreign person for purposes of US income taxation If the box in this Section is not checked Seller", 
       isText: false,
       type: "checkbox",
       top: 216.6,
       left: 108.12,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Taxes for the Calendar Year Immediately Preceding Closing", 
       isText: false,
       type: "checkbox",
       top: 395.16,
       left: 77.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Most Recent Mill Levy and Most Recent Assessed Valuation", 
       isText: false,
       type: "checkbox",
       top: 409.08,
       left: 77.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Other_6", 
       isText: false,
       type: "checkbox",
       top: 422.76,
       left: 77.4,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "undefined_10", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 420.36,
       left: 121.44,
       width: 429.96,
       height: 12.36
    }
    ,
    {
       page: 16,
       name: "Rents Actually Received", 
       isText: false,
       type: "checkbox",
       top: 439.56,
       left: 207.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Accrued At Closing Seller will transfer or credit to", 
       isText: false,
       type: "checkbox",
       top: 439.56,
       left: 335.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Text106", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 487.639,
       left: 71.3458,
       width: 502.1472,
       height: 48.181
    }
    ,
    {
       page: 16,
       name: "Buyer_14", 
       isText: false,
       type: "checkbox",
       top: 597,
       left: 477,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Seller_17", 
       isText: false,
       type: "checkbox",
       top: 597,
       left: 527.16,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 16,
       name: "Text107", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 653.894,
       left: 72.6549,
       width: 495.6011,
       height: 10.872
    }
    ,
    {
       page: 16,
       name: "per day or any", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 718.56,
       left: 439.56,
       width: 65.04,
       height: 12.36
    }
    ,
    {
       page: 17,
       name: "Buyer will be paid to Seller and retained by Seller It is agreed that the Earnest Money is not a penalty and the parties", 
       isText: false,
       type: "checkbox",
       top: 703.8,
       left: 109.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 19,
       name: "undefined_11", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 159.84,
       left: 73.2,
       width: 450.0,
       height: 10.68
    }
    ,
    {
       page: 19,
       name: "Text108", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 405.82,
       left: 39.9274,
       width: 534.8746,
       height: 346.656
    }
    ,
    {
       page: 20,
       name: "Text109", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 62.182,
       left: 43.8547,
       width: 510.0013,
       height: 127.382
    }
    ,
    {
       page: 20,
       name: "Text110", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 216.001,
       left: 58.9093,
       width: 494.9467,
       height: 84.837
    }
    ,
    {
       page: 20,
       name: "Text111", 
       type: "textarea",
       fontName: "Helvetica",
       fontSize: 10,
       top: 312.874,
       left: 58.2548,
       width: 496.9102,
       height: 69.782
    }
    ,
    {
       page: 20,
       name: "Buyers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 409.08,
       left: 120,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Buyers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 409.08,
       left: 355.8,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Address 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 469.8,
       left: 120,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Address 1_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 469.8,
       left: 355.8,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Address 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 488.52,
       left: 120,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Address 2_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 488.52,
       left: 355.8,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Phone 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 502.68,
       left: 120,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Phone", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 502.68,
       left: 355.8,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Phone 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 516.84,
       left: 120,
       width: 160.08,
       height: 13.20
    }
    ,
    {
       page: 20,
       name: "Email", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 516.84,
       left: 355.8,
       width: 160.08,
       height: 13.20
    }
    ,
    {
       page: 20,
       name: "Sellers Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 576.36,
       left: 120,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Sellers Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 576.36,
       left: 355.8,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Address 1_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 637.08,
       left: 120,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Address 1_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 637.08,
       left: 355.8,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Address 2_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 655.8,
       left: 120,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Address 2_4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 655.8,
       left: 355.8,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Phone 1_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 669.96,
       left: 120,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Phone_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 669.96,
       left: 355.8,
       width: 160.08,
       height: 13.44
    }
    ,
    {
       page: 20,
       name: "Phone 2_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 684.12,
       left: 120,
       width: 160.08,
       height: 13.20
    }
    ,
    {
       page: 20,
       name: "Email_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 684.12,
       left: 355.8,
       width: 160.08,
       height: 13.20
    }
    ,
    {
       page: 21,
       name: "Does_4", 
       isText: false,
       type: "checkbox",
       top: 104.28,
       left: 83.88,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the", 
       isText: false,
       type: "checkbox",
       top: 104.28,
       left: 128.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Buyers Agent", 
       isText: false,
       type: "checkbox",
       top: 167.88,
       left: 193.8,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "TransactionBroker in this transaction", 
       isText: false,
       type: "checkbox",
       top: 167.88,
       left: 279.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Customer Broker has no brokerage relationship with Buyer See  B for Brokers brokerage relationship with Seller", 
       isText: false,
       type: "checkbox",
       top: 185.4,
       left: 48.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Listing Brokerage Firm", 
       isText: false,
       type: "checkbox",
       top: 223.32,
       left: 84.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Buyer_15", 
       isText: false,
       type: "checkbox",
       top: 223.32,
       left: 210.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Other_7", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 219.84,
       left: 292.8,
       width: 264.96,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "Brokerage Firms Name", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 289.8,
       left: 174,
       width: 378.12,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "Brokerage Firms License 1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 303.84,
       left: 174,
       width: 378.12,
       height: 13.20
    }
    ,
    {
       page: 21,
       name: "Brokerage Firms License 2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 317.88,
       left: 174,
       width: 378.12,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "Brokerage Firms License 3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 331.8,
       left: 174,
       width: 378.12,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "signature broker buyer", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 12,
       top: 352.147,
       left: 179.346,
       width: 150.000,
       height: 12.836
    }
    ,
    {
       page: 21,
       name: "Date_6", 
       fontSize: 9,
       type: "date",
       top: 349.68,
       left: 359.28,
       width: 96.00,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "signature cobroker buyer", 
       type: "signature",
       fontName: "Helvetica",
       fontSize: 12,
       top: 384.874,
       left: 179.346,
       width: 150.000,
       height: 12.837
    }
    ,
    {
       page: 21,
       name: "Date_7", 
       fontSize: 9,
       type: "date",
       top: 383.04,
       left: 359.28,
       width: 96.00,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "1", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 411.48,
       left: 174,
       width: 378.12,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 425.4,
       left: 174,
       width: 378.12,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 439.32,
       left: 174,
       width: 378.12,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "4", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 453.48,
       left: 173.28,
       width: 378.84,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "Does_5", 
       isText: false,
       type: "checkbox",
       top: 508.375,
       left: 83.88,
       width: 10.08,
       height: 9.425
    }
    ,
    {
       page: 21,
       name: "Does Not acknowledge receipt of Earnest Money deposit Broker agrees that if Brokerage Firm is the_2", 
       isText: false,
       type: "checkbox",
       top: 507.72,
       left: 128.52,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Sellers Agent", 
       isText: false,
       type: "checkbox",
       top: 571.08,
       left: 192.84,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "TransactionBroker in this transaction_2", 
       isText: false,
       type: "checkbox",
       top: 571.08,
       left: 276.36,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Customer Broker has no brokerage relationship with Seller See A for Brokers brokerage relationship with Buyer", 
       isText: false,
       type: "checkbox",
       top: 594.12,
       left: 48.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Seller_18", 
       isText: false,
       type: "checkbox",
       top: 632.04,
       left: 84.6,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Buyer_16", 
       isText: false,
       type: "checkbox",
       top: 632.04,
       left: 133.08,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "Other_8", 
       isText: false,
       type: "checkbox",
       top: 632.04,
       left: 183.24,
       width: 10.08,
       height: 10.08
    }
    ,
    {
       page: 21,
       name: "undefined_12", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 628.68,
       left: 227.04,
       width: 335.04,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "Brokerage Firms Name_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 705.48,
       left: 174,
       width: 378.12,
       height: 13.32
    }
    ,
    {
       page: 21,
       name: "Brokerage Firms License", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 719.52,
       left: 179.28,
       width: 365.04,
       height: 13.32
    }
    ,
    {
       page: 22,
       name: "1_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 50.52,
       left: 174,
       width: 378.12,
       height: 14.52
    }
    ,
    {
       page: 22,
       name: "2_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 65.64,
       left: 174,
       width: 378.12,
       height: 14.52
    }
    ,
    {
       page: 22,
       name: "1_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 146.28,
       left: 174,
       width: 378.12,
       height: 14.52
    }
    ,
    {
       page: 22,
       name: "2_3", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 161.4,
       left: 174,
       width: 378.12,
       height: 14.28
    }
    ,
    {
       page: 22,
       name: "3_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 176.28,
       left: 174,
       width: 378.12,
       height: 14.52
    }
    ,
    {
       page: 22,
       name: "4_2", 
       type: "text",
       fontName: "Helvetica",
       fontSize: 10,
       top: 191.4,
       left: 173.28,
       width: 378.84,
       height: 14.28
    }
    ] }
    