import { Formik, Form } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { addTransClientInDb } from "../../../app/firestore/firestoreService";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import MySelectInput from "../../../app/common/form/MySelectInput";
import {
  createClientRoleOptions,
  createRestrictedEmails,
} from "../../../app/common/util/util";
import FormAddress from "../../../app/common/form/FormAddress";
import FormPeopleAutofill from "../../../app/common/form/FormPeopleAutofill";
import MyCheckbox from "../../../app/common/form/MyCheckbox";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useMediaQuery } from "react-responsive";

export default function TransactionClientAddForm({
  client,
  transaction,
  people,
}) {
  const dispatch = useDispatch();
  const { allParties } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  const clientRoleOptions = createClientRoleOptions(client, transaction);

  const restrictedEmails = createRestrictedEmails(
    currentUserProfile,
    transaction,
    allParties
  );

  let initialValues = {
    type: "Client",
    role: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    address: {
      street: "",
      unit: "",
      city: "",
      state: "",
      zipcode: "",
    },
    isTrust: false,
    entityName: "",
    personSelected: "",
    userId: transaction.userId,
  };

  const [isTrustField, setIsTrustField] = useState(
    initialValues.isTrust ? true : false
  );

  const validationSchema = Yup.object({
    lastName: Yup.string().required("You must provide a last name"),
    email: Yup.string()
      .email("Must be a valid email")
      .notOneOf(restrictedEmails, "Can't use same email for different parties"),
    role: Yup.string().required("You must provide a role"),
  });

  return (
    <ModalWrapper>
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await addTransClientInDb(transaction, values);
                setSubmitting(false);
                toast.success("Client successfully updated");
                dispatch(
                  closeModal({
                    modalType: "TransactionClientAddForm",
                  })
                );
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid }) => (
              <Form className="ui form">
                <Header size="large" color="blue">
                  Add Client
                </Header>
                <Divider />
                <Grid>
                  <Grid.Column mobile={16} computer={5}>
                    <MySelectInput
                      name="role"
                      placeholder="Role"
                      options={clientRoleOptions}
                    />
                  </Grid.Column>
                </Grid>
                <Divider />
                <FormPeopleAutofill
                  people={people}
                  type={"Client"}
                  setIsTrustField={setIsTrustField}
                />
                <br />
                <MyCheckbox
                  name="isTrust"
                  label="This client is a company, trust, or other entity"
                  onClick={() => setIsTrustField(!isTrustField)}
                />
                {isTrustField && (
                  <MyTextInput
                    name="entityName"
                    label="Company, trust, or entity name"
                  />
                )}
                <Divider />
                <FormAddress />
                <Divider className="medium top margin" />
                <Button
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                  type="submit"
                  floated={isMobile ? null : "right"}
                  primary
                  content="Submit"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "TransactionClientAddForm",
                      })
                    )
                  }
                  to="#"
                  type="button"
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium" : null}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </ModalWrapper>
  );
}
